import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineCharts = ({ names, labels, list, list2, style, type, responsive = true }) => {

    const footer = (tooltipItems) => {
      let sum = 0;

      var value_1 = tooltipItems[0].parsed['y']

      var value_2 = tooltipItems[1].parsed['y']

      if(value_2 !== 0) {
        sum = Math.floor((value_2 / value_1) * 100)
      }
    
      return '재접속률: ' + sum + '%';
    };

    const footer2 = (tooltipItems) => {
      let sum = 0;

      var value_1 = tooltipItems[0].parsed['y']

      var value_2 = tooltipItems[1].parsed['y']

      if(value_2 !== 0) {
        sum = Math.round(value_1 / value_2)
      }
    
      return '판매 수: ' + sum;
    };

    if (type == 'transition') {
      var toolTip =  {
        callbacks: {
          footer: footer,
        }
      }
    } else if (type == 'payment') {
      var toolTip =  {
        callbacks: {
          footer: footer2,
        }
      }
    } else {
      var toolTip = false;
    }

  const options = {
    responsive,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip : toolTip
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          fontSize: 14,
        },
        grid: {
          drawOnChartArea: false,
        },
        beginAtZero: true,
      },
    },
  };


  const data = {
    labels,
    datasets: [
      {
        label : names[0],
        data: list,
        borderColor: "rgba(0,149,255,1)",
        backgroundColor: "rgba(0,149,255,1)",
      },
      {
        label : names[1],
        data: list2,
        borderColor: "rgba(255,51,51,1)",
        backgroundColor: "rgba(255,51,51,1)",
      }
    ],
  };

  return <Line options={options} data={data} style={style} />;
};

export default LineCharts;
