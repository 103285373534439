import styled from "styled-components";
import flexbox from "../../styles/func/flexbox";
import base from "../../styles/constants/base";
import colors from "../../styles/constants/colors";
import typography from "../../styles/constants/typography";

export const Main = styled.main`
  padding: 0 20px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 70px;
`;

export const Row = styled.div`
  width: 100%;
  ${flexbox("flex-start")}
  align-items: ${({ start }) => (start === 1 ? "flex-start" : null)};
  justify-content: ${({ between }) =>
    between === 1 ? "space-between;" : null};
  flex-direction: ${({ direction }) =>
    direction === "column" ? `column` : null};
  margin: 30px 0;

  ul {
    margin: 20px 0;
    padding-left: 150px;
    width: 100%;
    ${flexbox("flex-start")};
    flex-wrap: wrap;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  ${flexbox("space-between")}
`;

export const Wrapper2 = styled.div`
  width: 100%;
  ${flexbox("space-between")}
  flex-wrap : wrap;
`;

export const LikeDiv = styled.div`
  ${flexbox()}
  margin-left: 50px;

  p {
    flex-shrink: 0;
    font-size: ${typography.size.medium}px;
    font-weight: ${typography.weight.bold};
    margin-right: 100px;
  }
`;

export const StatusDiv = styled.div`
  height: 100%;
  width: 150px;
  ${flexbox("flex-end")};
  white-space: nowrap;

  button {
  }
`;

export const DiscountBox = styled.div`
  width: 100%;
  ${flexbox("flex-start")}

  select {
    width: 100%;
  }
`;

export const OptionBox = styled.div`
  width: 100%;
  ${flexbox("flex-start")}
`;

export const OptionWrapper = styled.div`
  width: 250px;
`;

export const DiscountContainer = styled.div`
  width: 100%;
`;

export const DiscountWrapper = styled.div`
  width: 100%;
  ${flexbox()}
  margin-left: 20px;
`;

export const RowTop = styled.div`
  width: 100%;
  ${flexbox("flex-start")}
  align-items : start;
`;

export const CategoryDiv = styled.div`
  width: 100%;
  ${flexbox("flex-start")}
`;

export const FAQRow = styled.div`
  width: 100%;
  margin: 20px 0;

  p {
    font-size: ${typography.size.medium}px;
    font-weight: ${typography.weight.bold};
    margin-bottom: 10px;
  }
`;

export const FAQTop = styled.div`
  width: 100%;
  margin: 20px 0;
  ${flexbox("space-between")};
`;

export const UseInfoRow = styled.div`
  margin: 20px 0;

  p {
    font-size: ${typography.size.medium}px;
    font-weight: ${typography.weight.bold};
    margin-bottom: 10px;
  }

  textarea {
    border: ${base.border};
    box-shadow: none;
    min-height: 90px;

    &:focus {
      background-color: ${colors.lightGray};
      box-shadow: none;
    }
  }
`;

export const Title = styled.div`
  width: 150px;
  height: 100%;
  flex-shrink: 0;
  font-size: ${typography.size.medium}px;
  font-weight: ${typography.weight.bold};
  ${flexbox("flex-start")}

  img {
    margin-left: 10px;
  }
`;

export const ButtonBox = styled.div`
  width: 150px;
  ${flexbox("flex-end")};
  white-space: nowrap;

  button {
    height: ${base.height.input}px;
    padding: 10px 20px;
    background-color: ${colors.blue};
    color: ${colors.white};
    border-radius: ${base.borderRadius.default}px;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  padding: 20px;
  ${flexbox("flex-end")}
  position: absolute;
  bottom: 0px;
  background-color: ${colors.white};

  button:first-child,
  button:nth-child(2),button:nth-child(3) {
    margin-right: 10px;
  }
`;

export const ModalText = styled.p`
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 20px 0;
`;


export const CurriculumContent = styled.textarea`
  display: inline-block;
  width : 100%;
  resize : none;
  border: 2px solid #DEDEDE;
  margin-top : 15px;
  border-radius : 8px;
  padding: 10px 20px;
  height : 200px;
  line-height : 1.4;
`;

export const CurriculumContainer = styled.div`
  display : inline-block;
  width : calc(100% - 112.2px);
  margin-bottom : 15px;
  background-color : rgba(233,242,254,1);
  padding : 10px;
  border-radius : 8px;

  .C_subject {
    width : 100%;
    display : inline-flex;
    align-items : start;
    margin-bottom : 15px;
    position : relative;

    .pin {
      width : 20px;
      display : inline-block;
      margin-right : 10px;
    }

    .xPin {
        width : 15px;
        display : inline-block;
        margin-left : 10px;
        position : absolute;
        top : 50%;
        right : 0;
        transform : translate(0, -50%);
        cursor : pointer;
    }

    span {
      width : calc(100% - 55px);
      display : inline-block;
    }
  }

  .C_content {
    width : calc(100% - 30px);
    margin-left : 30px;
    display : inline-block;
    flex-wrap : wrap;



    span {
      display : inline-block;
      width : 100%;
      line-height : 1.4;
    }
  }
`

export const ListContainer = styled.div`
  width : calc(50% - 10px);
  padding-right : 10px;
  display : inline-flex;
  justify-content : space-between;
  align-items : start;
  margin-bottom : 10px;
  position : relative;

  .pin {
    width : 20px;
    display : inline-block;
    margin-right : 10px;
  }

  span {
    width : calc(100% - 30px);
    display : inline-block;
    padding-right : 25px;
    line-height : 1.4;
  }


  .xPin {
    width : 15px;
    display : inline-block;
    margin-left : 10px;
    position : absolute;
    top : 50%;
    right : 10px;
    transform : translate(0, -50%);
    cursor : pointer;
}
`;