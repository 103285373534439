import { Link } from "react-router-dom";
import styled from "styled-components";
import ToggleButton from "../../modules/toggleButton";
import flexbox from "../../styles/func/flexbox";

const BannerRowCard = ({ data, statusToggleHandler, status }) => {
  return (
    <Card>
      <SLink>
        <div>{data.number}</div>

        <div>{data.subject}</div>

        <ALink onClick={() => window.open(data.full_link)}>{data.link}</ALink>

        <div>{data.date}</div>

        <div>{data.order}</div>
      </SLink>

      <StatusDiv>
        <ToggleButton
          toggleHandler={statusToggleHandler}
          status={status}
          type="banner"
        />
      </StatusDiv>
    </Card>
  );
};

export default BannerRowCard;

const Card = styled.li`
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
`;

const SLink = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  div {
    ${flexbox()}
    &:first-child {
      width: 5%;
    }

    &:nth-child(2) {
      width: 30%;
    }

    &:nth-child(3) {
      width: 25%;
    }

    &:nth-child(4) {
      width: 27%;
    }

    &:nth-child(5) {
      width: 13%;
    }
  }
`;

const ALink = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

const StatusDiv = styled.div`
  width: 10%;
  ${flexbox()}
`;
