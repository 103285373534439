import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

// components
import OverlayPortal from "../../overlayPortal";
import Overlay from "../../layouts/overlay";
import ModalLayout from "../../layouts/modal/layout";
import ImageInput from "../../components/imageInput";
import LocationBox from "../../components/facility/locationBox";
import Select from "../../modules/select";
import useList from "../../hooks/useList";
import MainLayout from "../../layouts/mainLayout";
import Button from "../../modules/button";
import Input from "../../modules/input";
import Textarea from "../../modules/textarea";
import MapService from "../../service/map";
import colors from "../../styles/constants/colors";
import CropperPopup from "../../components/cropperPopup";
import LoadingSpinner from "../../components/loadingSpinner";

// icon
import scuba from "../../assets/icon/scuba.png";
import freeDiv from "../../assets/icon/freediv.png";
import paddle from "../../assets/icon/paddle.png";
import swim from "../../assets/icon/swim.png";
import surf from "../../assets/icon/surf.png";
import ski from "../../assets/icon/ski_logo.png";
import snowboard from "../../assets/icon/snowboard_logo.png";
import infoPin from "../../assets/icon/info_pin.svg";
import deleteIcon from "../../assets/icon/close.svg";

import {
  ButtonBox,
  Footer,
  Main,
  Row,
  Title,
  UseInfoRow,
  StatusDiv,
  CategoryDiv,
  RowTop,
  FAQRow,
  FAQTop,
  DiscountBox,
  DiscountWrapper,
  DiscountContainer,
  OptionBox,
  OptionWrapper,
  LikeDiv,
  Wrapper,
  Wrapper2,
  ModalText,
  CurriculumContent,
  CurriculumContainer,
  ListContainer,
} from "./style";

import ToggleButton from "../../modules/toggleButton";

const PartnerLessonEditPage = () => {
  const { pid, fid } = useParams();

  const navigate = useNavigate();

  const [modal, setModal] = useState(false);

  const [loading, setLoading] = useState(false);



  const saveAndWriteUsageInfoHandler = () => {
    if (window.confirm("시설 정보를 수정하시겠습니까?")) { 
      var token = window.localStorage.getItem("token");
      setLoading(true);
      axios.post(
          "https://ai.semos.kr/semos_admin/facility/data",
          {
            mode: "facility_revise",
            token: token,
            facility_data: facility,
            facility_id: fid
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        ).then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            setLoading(false);
            alert(statusMessage);
            window.location.reload();
          } else {
            setLoading(false);
            alert(statusMessage);
          }
        });
    }
  };

  // 데이터 패칭

  const [imgPopup, setImgPopup] = useState(false);

  const [cropType, setCropType] = useState("");

  const [imgScale, setImgScale] = useState({
    width: 1,
    height: 1,
  });

  const [facilityThema, setFacilityThema] = useState();

  const [cropImg, setCropImg] = useState("");

  //  Location

  const targetLocationChangeHandler = (data) => {
    var locationData = facility.location;

    var locationSigungu = data.sigungu.split(" ");

    if (locationSigungu.length > 1) {
      var loGu =
        locationSigungu[1].length > 2
          ? locationSigungu[1].slice(0, -1)
          : locationSigungu[1];
      var loSi =
        locationSigungu[0].length > 2
          ? locationSigungu[0].slice(0, -1)
          : locationSigungu[0];
      var loDong = data.bname.length > 2 ? data.bname.slice(0, -1) : data.bname;
    } else {
      var loGu =
        data.sigungu.length > 2 ? data.sigungu.slice(0, -1) : data.sigungu;
      var loSi = data.sido;
      var loDong = data.bname.length > 2 ? data.bname.slice(0, -1) : data.bname;
    }

    locationData = {
      location: data.roadAddress,
      location_additional: "",
      location_dong: loDong,
      location_gu: loGu,
      location_si: loSi,
    };

    setFacility({ ...facility, location: locationData });
  };

  const additionalLocation = (value) => {
    var locationData = facility.location;

    locationData = {
      location: locationData.location,
      location_additional: value,
      location_dong: locationData.location_dong,
      location_gu: locationData.location_gu,
      location_si: locationData.location_si,
    };

    setFacility({ ...facility, location: locationData });
  };

  const mapService = new MapService(targetLocationChangeHandler);

  const searchAddressHandler = () => {
    mapService.open();
  };

  // 시설 삭제
  const deleteLessonHandler = () => {
    if (window.confirm("시설을 삭제하시겠습니까?")) {
      var token = window.localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "facility_delete");
      frm.append("token", token);
      frm.append("facility_id", fid);

      axios
        .post("https://ai.semos.kr/semos_admin/facility/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            alert(statusMessage);
            navigate(`/facility_list/1/all/all/all/all/all`);
          } else {
            alert(statusMessage);
          }
        });
    }
  };

  const [lesson, setLesson] = useState("");
  const [facility, setFacility] = useState("");
  const [deleteList, setDeleteList] = useState({
    bring_list: [],
    discount_list: [],
    exclude_list: [],
    faq_list: [],
    include_list: [],
    tag_list: [],
    keyword_list: [],
    notice_list: [],
    notice_list2: [],
    curriculum_list: [],
    recommend_list: [],
    add_img_list: [],
    facility_list: [],
    unique_list: [],
  });

  //const [activitySport, setActivitySport] = useState([]);

  // 시설 상세 로드
  useEffect(() => {
    if (fid == "register") {
      setFacility({
        name : "",
        profile : "",
        card_discount : "",
        preferential_discount : "",
        information : "",
        facility_category : [],
        sport_category : [],
        etc_facility : [],
        open : "no",
        location: {
          location: "",
          location_additional: "",
          location_dong: "",
          location_gu: "",
          location_si: "",
        },
      });
    } else {
      var token = window.localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "facility_specific");
      frm.append("token", token);
      frm.append("facility_number", fid);

      axios.post("https://ai.semos.kr/semos_admin/facility/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            setFacility(Value.facility_data);
          } else {
            alert(statusMessage);
          }
        });
    }
  }, []);



  // 시설 정보 수정
  const lessonSave = () => {
    if (window.confirm("시설 정보를 수정하시겠습니까?")) {
      var token = window.localStorage.getItem("token");
      setLoading(true);
      axios.post(
          "https://ai.semos.kr/semos_admin/facility/data",
          {
            mode: "facility_revise",
            token: token,
            facility_data: facility,
            facility_id: fid
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        ).then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            setLoading(false);
            alert(statusMessage);
            window.location.reload();
          } else {
            setLoading(false);
            alert(statusMessage);
          }
        });
    }
  };

  // 시설 등록
  const lessonAdd = () => {
    if (window.confirm("시설을 등록하시겠습니까?")) {
      var token = window.localStorage.getItem("token");
      setLoading(true);
      axios.post(
          "https://ai.semos.kr/semos_admin/facility/data",
          {
            mode: "facility_register",
            token: token,
            facility_data: facility,
          },
          { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
        )
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;
          var facilityId = Value.facility_id;

          if (status == "success") {
            setLoading(false);
            alert(statusMessage);
            window.location.replace(`/facility/${facilityId}`);

          } else if (status == "waring") {
            setLoading(false);
            alert(statusMessage);

          } else {
            setLoading(false);
            alert(statusMessage);

          }
        });
    }
  };

  // 시설 상태 변경
  const lessonStatusChange = () => {
    if (window.confirm("시설 상태를 변경하시겠습니까?")) {
      var token = window.localStorage.getItem('token');
      var facilityStatus = facility.open == 'yes';
      setLoading(true);

      const frm = new FormData();
      frm.append("mode", "facility_status");
      frm.append("number", fid);
      frm.append("token", token);
      frm.append("status", facilityStatus ? 'close' : 'yes');

      axios.post("https://ai.semos.kr/semos_admin/facility/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then((data) => {
        var Value = data.data;
        var status = Value.status
        var statusMessage = Value.status_message

        if(status == 'success') {

          if(facilityStatus) {
            setFacility({
              ...facility,
              open: 'close',
            });
          } else {
            setFacility({
              ...facility,
              open: 'yes',
            });
          }        

          alert(statusMessage)
          setLoading(false);
        } else if(status == 'waring') {
          alert(statusMessage)
        }

      });
    }
  };


  // 시설 상태 변경
  const lessonStatusConfirm = () => {
    if (window.confirm("시설 승인 상태를 변경하시겠습니까?")) {
      var token = window.localStorage.getItem("token");
      setLoading(true);
      axios
        .post(
          "https://ai.semos.kr/semos_admin/facility/data",
          {
            mode: "lesson_status_confirm",
            token: token,
            partner_number: pid,
            lesson_number: fid,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            alert(statusMessage);
            setLoading(false);
            setLesson({
              ...lesson,
              open: facility.open == "yes" ? "no" : "yes",
            });
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    }
  };

  const ValueChange = (name, value) => {

    if(name == 'name') {
      setFacility({ ...facility, [name]: value });
    } else if(name == 'etc_facility') {

      let data = facility;

      let etc_facility = data[name];

      etc_facility.push(value);

      setFacility({ ...facility, [name]: etc_facility });
      setFacilityThema("");
    } else {
      setFacility({ ...facility, [name]: value });
    }

  };

  const ImgChange = (value) => {
    setImgPopup(true);
    setImgScale({
      width: 660,
      height: 240,
    });
    setCropImg(value);
    setCropType("profile");

  };

  /*const ImgListChange = (value) => {
    setImgPopup(true);
    setImgScale({
      width: 8,
      height: 5,
    });
    setCropImg(value);
    setCropType("add_img");

    //setLesson({ ...lesson, profile_img: value });
  };*/

  const setImageFromFile = ({ file, setImageUrl }) => {

    let reader = new FileReader();
    reader.onload = function () {
      setImageUrl({ result: reader.result });
    };

    reader.readAsDataURL(file);
  };

  const FacilityAdd = (Facility) => {
    let lessonData = facility;

    let facilityAddStatus = lessonData.facility_category.indexOf(Facility) > -1;
    var facilityList = lessonData.facility_category;

    if (facilityAddStatus) {
      for (let i = 0; facilityList.length > i; i++) {
        let sameStatus = facilityList[i] === Facility;
        if (sameStatus) {
          facilityList.splice(i, 1);
        }
      }

      setLesson({
        ...facility,
        facility_category: facilityList,
      });
    } else {
      facilityList.push(Facility);
      setLesson({
        ...facility,
        facility_category: facilityList,
      });
    }
  };

  const SportAdd = (Facility) => {
    let lessonData = facility;

    let facilityAddStatus = lessonData.sport_category.indexOf(Facility) > -1;
    var facilityList = lessonData.sport_category;

    if (facilityAddStatus) {
      for (let i = 0; facilityList.length > i; i++) {
        let sameStatus = facilityList[i] === Facility;
        if (sameStatus) {
          facilityList.splice(i, 1);
        }
      }

      setLesson({
        ...facility,
        sport_category: facilityList,
      });
    } else {
      facilityList.push(Facility);
      setLesson({
        ...facility,
        sport_category: facilityList,
      });
    }
  };

  const CropImg = (value, type) => {
    switch (type) {
      case "profile":
        setFacility({ ...facility, profile: value });
        setImgPopup(false);
        setImgScale({
          width: 1,
          height: 1,
        });
        setCropImg("");
        setCropType("");
        break;

      case "add_img":
        var ImageList = lesson.add_img_list;

        ImageList[ImageList.length] = {
          number: "new",
          image: value,
          change: "yes",
        };

        setLesson({ ...lesson, add_img_list: ImageList });
        setImgPopup(false);
        setImgScale({
          width: 1,
          height: 1,
        });
        setCropImg("");
        setCropType("");
        break;
    }
  };

  /*
    const DeleteImage = (number) => {
      var lessonData = lesson;

      var deleteData = deleteList;

      var LessonDataValue = lessonData.add_img_list;

      var DeleteDataList = deleteData.add_img_list;

      var DeleteDataValue = LessonDataValue[number];

      DeleteDataList[DeleteDataList.length] = DeleteDataValue;

      LessonDataValue.splice(number, 1);

      setLesson({ ...lesson, add_img_list: LessonDataValue });

      setDeleteList({ ...deleteList, add_img_list: DeleteDataList });
    };

    const DeleteData = (type, number) => {
      var lessonData = lesson;

      var deleteData = deleteList;

      var LessonDataValue = lessonData[type];

      var DeleteDataList = deleteData[type];

      var DeleteDataValue = LessonDataValue[number];

      DeleteDataList[DeleteDataList.length] = DeleteDataValue;

      LessonDataValue.splice(number, 1);

      setLesson({ ...lesson, [type]: LessonDataValue });
      setDeleteList({ ...deleteList, [type]: DeleteDataList });
    };
  */

  /* const Facility = {
    cafeteria: {
      img: "https://ai.semos.kr/img/RNAPP/cafeteria.png",
      text: "음식점",
    },
    fitting_room: {
      img: "https://ai.semos.kr/img/RNAPP/fitting_room.png",
      text: "탈의실",
    },
    shower_room: {
      img: "https://ai.semos.kr/img/RNAPP/shower_room.png",
      text: "샤워장",
    },
    parking: {
      img: "https://ai.semos.kr/img/RNAPP/parking.png",
      text: "주차장",
    },
    shuttle_bus: {
      img: "https://ai.semos.kr/img/RNAPP/shuttle_bus.png",
      text: "셔틀버스",
    },
    fitness_center: {
      img: "https://ai.semos.kr/img/RNAPP/shower_room.png",
      text: "피트니스 센터",
    },
    pool: {
      img: "https://ai.semos.kr/img/RNAPP/pool.png",
      text: "수영장",
    },
    sauna: {
      img: "https://ai.semos.kr/img/RNAPP/sauna.png",
      text: "사우나",
    },
    kid_park: {
      img: "https://ai.semos.kr/img/RNAPP/kid_park.png",
      text: "키즈 파크",
    },
    game_room: {
      img: "https://ai.semos.kr/img/RNAPP/game_room.png",
      text: "게임룸",
    },
    karaoke: {
      img: "https://ai.semos.kr/img/RNAPP/karaoke.png",
      text: "노래방",
    },
    screen_golf: {
      img: "https://ai.semos.kr/img/RNAPP/sauna.png",
      text: "사우나",
    },
    kid_park: {
      img: "https://ai.semos.kr/img/RNAPP/kid_park.png",
      text: "키즈 파크",
    },
    game_room: {
      img: "https://ai.semos.kr/img/RNAPP/game_room.png",
      text: "게임룸",
    },
  };*/


  const FacilityList = {
    diving_pool: {
      img: scuba,
      text: "풀장",
    },
    ski_resort: {
      img: snowboard,
      text: "스키장",
    }
  };


  const SportList = {
    스쿠버다이빙: {
      img: scuba,
      text: "스쿠버다이빙",
    },
    프리다이빙: {
      img: freeDiv,
      text: "프리다이빙",
    },
    서핑: {
      img: surf,
      text: "서핑",
    },
    패들보드: {
      img: paddle,
      text: "패들보드",
    },
    수영: {
      img: swim,
      text: "수영",
    },
    스키: {
      img: ski,
      text: "스키",
    },
    스노보드: {
      img: snowboard,
      text: "스노보드",
    }
  };

  const DeleteData = (name, number) => { 

    if(name === 'etc_facility') {

      let data = facility;

      let etc_facility = data[name];
  
      etc_facility.splice(number, 1);
  
      setFacility({ ...facility, [name]: etc_facility });

    }

  }

  return (
    <>
      <LoadingSpinner show={loading} color={"rgba(0,149,255,1)"} />

      <MainLayout>
        <Main>

          <Row start={1}>
            <Title>시설 명</Title>

            <Wrapper>
  
              <CategoryDiv>
                <Input
                  width="100%"
                  type="text"
                  placeholder="시설 명을 입력해 주세요. (최대 17자)"
                  name="title"
                  value={facility ? facility.name : ""}
                  changeHandler={(e) =>
                    ValueChange("name", e.target.value)
                  }
                />
              </CategoryDiv>

              {/*<LikeDiv>
                <p>찜</p>
                <Input
                  width="100%"
                  type="text"
                  name="like"
                  placeholder="0개"
                  value={lesson ? lesson.jjim : ""}
                  changeHandler={(e) =>
                    ValueChange("text", "jjim", e.target.value)
                  }
                />
              </LikeDiv>*/}
            </Wrapper>

            <StatusDiv>
              {fid !== "register" &&
              (facility ? (facility.open !== "no" ? true : false) : false) ? (
                <ToggleButton
                  status={facility ? facility.open : ""}
                  toggleHandler={lessonStatusChange}
                  type="lesson"
                />
              ) : (
                ""
              )}
            </StatusDiv>
          </Row>

          <Row start={1}>
            <Title>대표 사진</Title>

            <ImageInput
              width="100%"
              height="400px"
              name="main"
              changeHandler={ImgChange}
              preview={facility ? facility.profile : ""}
              imagePro={setImageFromFile}
            >
              <p>업로드하기</p>
              <p>(660:240 비율 / JPG)</p>
            </ImageInput>

            <ButtonBox />
          </Row>

          <Row start={1}>
            <Title>시설 주소</Title>

            <LocationBox
              //addHandler={lessonLocation}
              //deleteHandler={deleteAddressHandler}
              searchAddressHandler={searchAddressHandler}
              data={facility ? facility.location : ""}
              changeHandler={(e) => additionalLocation(e.target.value)}
              //addressList={addressList}
            />
          </Row>

          <Row start={1}>
            <Title>시설 카테고리</Title>
            <Wrapper2>
              <SportBox>
                {facility.facility_category
                  ? Object.entries(FacilityList).map((item, idx) => (
                      <SportCategory
                        key={`${idx}_f_list`}
                        onClick={() => FacilityAdd(item[0])}
                        active={
                          facility.facility_category.indexOf(item[0]) > -1
                            ? true
                            : false
                        }
                      >
                        <img src={item[1].img} />
                        <span>{item[1].text}</span>
                      </SportCategory>
                    ))
                  : ""}
              </SportBox>
            </Wrapper2>
            <ButtonBox></ButtonBox>
          </Row>

          <Row start={1} direction={"column"}>
            <RowTop>
              <Title>부대시설</Title>

              <div
                style={{
                  width: "calc(100%)",
                  display: "inline-flex",
                  flexWrap: "wrap",
                }}
              >
                <Input
                  type="text"
                  placeholder="부대시설을 적어주세요!"
                  name="unique"
                  value={facilityThema}
                  changeHandler={(e) => setFacilityThema(e.target.value)}
                />
              </div>

              <ButtonBox>
                <button
                  type="button"
                  name="contain"
                  onClick={() => {
                    ValueChange("etc_facility", facilityThema);
                  }}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {facility
              ? facility.etc_facility.length > 0 && (
                  <ul>
                    {facility.etc_facility.map((item, idx) => (
                      <ListContainer>
                        <img className="pin" src={infoPin} />
                        <span>{item}</span>
                        <img
                          onClick={() => DeleteData("etc_facility", idx)}
                          className="xPin"
                          src={deleteIcon}
                        />
                      </ListContainer>
                    ))}
                  </ul>
                )
              : ""}
          </Row>

          <Row start={1}>
            <Title>스포츠</Title>
            <Wrapper2>
              <SportBox>
                {facility.sport_category
                  ? Object.entries(SportList).map((item, idx) => (
                      <SportCategory
                        key={`${idx}_f`}
                        onClick={() => SportAdd(item[0])}
                        active={
                          facility.sport_category.indexOf(item[0]) > -1
                            ? true
                            : false
                        }
                      >
                        <img src={item[1].img} />
                        <span>{item[1].text}</span>
                      </SportCategory>
                    ))
                  : ""}
              </SportBox>
            </Wrapper2>
            <ButtonBox></ButtonBox>
          </Row>

          <Row start={1}>
            <Title>시설 소개</Title>

            <Textarea
              placeholder={`*시설 일정: 원데이 3시간 시설\n-최대 900글자 이모티콘`}
              name="detail-info"
              value={facility ? facility.information : ""}
              changeHandler={(e) =>
                ValueChange("information", e.target.value)
              }
            />

            <ButtonBox />
          </Row>

          <Row start={1}>
            <Title>카드 할인</Title>

            <Wrapper2>
              <SportBox>
            
                <Input
                  width="100%"
                  type="text"
                  placeholder="카드 할인 정보를 입력해주세요!"
                  name="title"
                  value={facility ? facility.card_discount : ""}
                  changeHandler={(e) =>
                    ValueChange( "card_discount", e.target.value)
                  }
                />
              </SportBox>

            </Wrapper2>
            <ButtonBox />
          </Row>

          <Row start={1}>
            <Title>우대 할인</Title>

            <Wrapper2>
              <SportBox>
      
                <Input
                  width="100%"
                  type="text"
                  placeholder="우대 할인 정보를 입력해주세요!"
                  name="title"
                  value={facility ? facility.preferential_discount : ""}
                  changeHandler={(e) =>
                    ValueChange("preferential_discount", e.target.value)
                  }
                />
              </SportBox>

            </Wrapper2>
            <ButtonBox />
          </Row>

            

          {/*<Row>
            <Title>추가 사진</Title>
            <Wrapper2>
              <AddImgBox for="addImg">
                <img src={cameraWhiteIcon} />
                <input
                  accept="image/*"
                  type="file"
                  id="addImg"
                  onChange={({ target: { files } }) => {
                    if (files.length) {
                      setImageFromFile({
                        file: files[0],
                        setImageUrl: ({ result }) => {
                          ImgListChange(result);
                        },
                      });
                    }
                  }}
                />
              </AddImgBox>

              <AddImgListBox>
                {lesson
                  ? lesson.add_img_list.map((item, idx) => (
                      <div key={`addImage${idx}`}>
                        <img className="addImage" src={item.image} />

                        <span
                          onClick={() => DeleteImage(idx)}
                          className="deleteImage"
                        >
                          <img src={closeIcon} />
                        </span>
                      </div>
                    ))
                  : ""}
              </AddImgListBox>
            </Wrapper2>
            <ButtonBox></ButtonBox>
          </Row>*/}

          {/*<Row>
            <Title>가격</Title>
            <Wrapper2>
              <Input
                type="text"
                placeholder="숫자만 입력해 주세요."
                name="price"
                value={lesson ? lesson.price : ""}
                changeHandler={(e) =>
                  ValueChange("price", "price", e.target.value)
                }
                width={`calc(50% - 15px)`}
              />

              <RelativeView width={`calc(50% - 15px)`}>
                <Input
                  type="text"
                  placeholder="확정율 (입력 X)"
                  name="price"
                  value={lesson ? lesson.confrim_rating : ""}
                  maxLength={"3"}
                  readonly={"readonly"}
                />
                <AbsoluteView
                  translate={"translate(0, -50%)"}
                  right={"20px"}
                  top={"50%"}
                >
                  확정율 (%)
                </AbsoluteView>
              </RelativeView>
            </Wrapper2>
            <ButtonBox></ButtonBox>
          </Row>*/}

          {/*<Row>
            <DiscountContainer>
              <RowTop>
                <Title>동반 할인가격</Title>

                <DiscountBox>
                  <div
                    style={{
                      width: "calc(50% - 15px)",
                      display: "inline-flex",
                      justifyContent: "space-between",
                      marginRight: "30px",
                    }}
                  >
                    <Select
                      width="100%"
                      name="discount-personnel"
                      value={lesson ? lesson.discount_people : ""}
                      changeHandler={(e) =>
                        ValueChange("text", "discount_people", e.target.value)
                      }
                      optionList={PeopleOption}
                    />

                    <Select
                      width="calc(50% - 7.5px)"
                      name="discount-personnel"
                      value={lesson ? lesson.discount_people : ""}
                      changeHandler={(e) =>
                        ValueChange("text", "discount_max_people", e.target.value)
                      }
                      optionList={PeopleOption}
                    />
                  </div>

                  <div
                    style={{
                      width: "calc(50% - 15px)",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Input
                      placeholder="할인 되는 퍼센트를 입력해주세요."
                      type="text"
                      name="discount-price"
                      length="3"
                      value={lesson ? lesson.discount_percent : ""}
                      changeHandler={(e) => {
                        var regex = /[^0-9]/g;
                        var stateChangeValue = e.target.value.replace(
                          regex,
                          ""
                        );
                        var stateChangeValue = stateChangeValue.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );

                        var stateChangeValue = Number(stateChangeValue);

                        if (stateChangeValue > 100) {
                          ValueChange("text", "discount_percent", 100);
                        } else {
                          ValueChange(
                            "text",
                            "discount_percent",
                            e.target.value
                          );
                        }
                      }}
                    />
                  </div>
                </DiscountBox>

                <ButtonBox></ButtonBox>
              </RowTop>
            </DiscountContainer>
          </Row>*/}

          {/*<Row start={1}>
            <Title>기본 정보</Title>

            <Wrapper2>
              <SelectImg
                optionList={ActivityType}
                name="maximum"
                typeText="유형"
                placeholder="유형"
                value={lesson ? lesson.lesson_type : "체험"}
                changeHandler={(e) =>
                  ValueChange("text", "lesson_type", e.target.value)
                }
                width={`calc(50% - 15px)`}
                mb={true}
              />

              <SelectImg
                optionList={[
                  {
                    text: "실내",
                    value: "실내",
                  },
                  {
                    text: "야외",
                    value: "야외",
                  },
                  {
                    text: "실내+야외",
                    value: "실내+야외",
                  },
                ]}
                name="maximum"
                typeText="장소"
                placeholder="장소"
                value={lesson ? lesson.location_type : "실내"}
                changeHandler={(e) =>
                  ValueChange("text", "location_type", e.target.value)
                }
                width={`calc(50% - 15px)`}
                mb={true}
              />

              <div
                style={{
                  width: "calc(50% - 15px)",
                  display: "inline-flex",
                  justifyContent: "space-between",
                }}
              >
                <SelectImg
                  optionList={peopleOption2}
                  name="maximum"
                  typeText="최소"
                  placeholder="인원"
                  value={lesson ? lesson.min_people : ""}
                  changeHandler={(e) =>
                    ValueChange("text", "min_people", e.target.value)
                  }
                  width={`calc(50% - 7.5px)`}
                />

                <SelectImg
                  optionList={peopleOption2}
                  name="maximum"
                  typeText="최대"
                  placeholder="인원"
                  value={lesson ? lesson.max_people : ""}
                  changeHandler={(e) =>
                    ValueChange("text", "max_people", e.target.value)
                  }
                  width={`calc(50% - 7.5px)`}
                />
              </div>

              <div
                style={{
                  width: "calc(50% - 15px)",
                  display: "inline-flex",
                  justifyContent: "space-between",
                }}
              >
                <SelectImg
                  optionList={TimeType}
                  name="maximum"
                  typeText="시간"
                  placeholder="유형"
                  value={lesson ? lesson.time_type : "time"}
                  changeHandler={(e) =>
                    ValueChange("text", "time_type", e.target.value)
                  }
                  width={`calc(50% - 7.5px)`}
                />

                <SelectImg
                  optionList={
                    dateOptionList[lesson ? lesson.time_type : "time"]
                  }
                  name="maximum"
                  value={
                    lesson
                      ? lesson.time
                      : lesson.time_type == "time"
                      ? "30"
                      : "1"
                  }
                  changeHandler={(e) =>
                    ValueChange("text", "time", e.target.value)
                  }
                  width={`calc(50% - 7.5px)`}
                  paddingLeft={"30"}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "inline-flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Input
                  type="text"
                  placeholder="최소 연령 | 예-10세 이상"
                  name="price"
                  paddingLeft={"90px"}
                  value={lesson ? lesson.min_age : ""}
                  maxLength={"100"}
                  changeHandler={(e) =>
                    ValueChange("text", "min_age", e.target.value)
                  }
                >
                  <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "20px",
                      transform: "translate(0, -50%)",
                    }}
                  >
                    최소 연령:
                  </span>
                </Input>
              </div>
            </Wrapper2>

            <ButtonBox></ButtonBox>
          </Row>*/}

          {/*<Row start={1} direction={"column"}>
            <RowTop>
              <Title>이런 점이 특별해요</Title>

              <div
                style={{
                  width: "calc(100%)",
                  display: "inline-flex",
                  flexWrap: "wrap",
                }}
              >
                <Input
                  type="text"
                  placeholder="이 시설만의 특별점을 적어주세요. / 예-사진촬영 및 원본 전달까지"
                  name="unique"
                  value={tagValue.unique}
                  changeHandler={tagInputChangeHandler}
                />
              </div>

              <ButtonBox>
                <button
                  type="button"
                  name="contain"
                  onClick={() => {
                    ValueChange("list", "unique_list", {
                      content: tagValue.unique,
                      number: "new",
                    });
                    setTagValue({ ...tagValue, unique: "" });
                  }}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {lesson
              ? lesson.unique_list.length > 0 && (
                  <ul>
                    {lesson.unique_list.map((item, idx) => (
                      <ListContainer>
                        <img className="pin" src={infoUnique} />
                        <span>{item["content"]}</span>
                        <img
                          onClick={() => DeleteData("unique_list", idx)}
                          className="xPin"
                          src={deleteIcon}
                        />
                      </ListContainer>
                    ))}
                  </ul>
                )
              : ""}
          </Row>*/}

          {/*<Row start={1} direction={"column"}>
            <RowTop>
              <Title>추천 대상</Title>

              <div
                style={{
                  width: "calc(100%)",
                  display: "inline-flex",
                  flexWrap: "wrap",
                }}
              >
                <Input
                  type="text"
                  placeholder="추천 대상을 적어주세요. / 예-스쿠버다이빙 초보자"
                  name="recommend"
                  value={tagValue.recommend}
                  changeHandler={tagInputChangeHandler}
                />
              </div>

              <ButtonBox>
                <button
                  type="button"
                  name="contain"
                  onClick={() => {
                    ValueChange("list", "recommend_list", {
                      content: tagValue.recommend,
                      number: "new",
                    });
                    setTagValue({ ...tagValue, recommend: "" });
                  }}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {lesson
              ? lesson.recommend_list.length > 0 && (
                  <ul>
                    {lesson.recommend_list.map((item, idx) => (
                      <ListContainer>
                        <img className="pin" src={infoCheck} />
                        <span>{item["content"]}</span>
                        <img
                          onClick={() => DeleteData("recommend_list", idx)}
                          className="xPin"
                          src={deleteIcon}
                        />
                      </ListContainer>
                    ))}
                  </ul>
                )
              : ""}
          </Row>*/}

          {/*<Row start={1} direction={"column"}>
            <RowTop>
              <Title>자격요건</Title>

              <div
                style={{
                  width: "calc(100%)",
                  display: "inline-flex",
                  flexWrap: "wrap",
                }}
              >
                <Input
                  type="text"
                  placeholder="수강생 자격요건을 적어주세요. / 예-오픈워터 자격증 필수"
                  name="notice"
                  value={tagValue.notice}
                  changeHandler={tagInputChangeHandler}
                />
              </div>

              <ButtonBox>
                <button
                  type="button"
                  name="contain"
                  onClick={() => {
                    ValueChange("list", "notice_list", {
                      content: tagValue.notice,
                      number: "new",
                      type: "require",
                    });
                    setTagValue({ ...tagValue, notice: "" });
                  }}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {lesson
              ? lesson.notice_list.length > 0 && (
                  <ul>
                    {lesson.notice_list.map((item, idx) => (
                      <ListContainer>
                        <img className="pin" src={infoRequire} />
                        <span>{item["content"]}</span>
                        <img
                          onClick={() => DeleteData("notice_list", idx)}
                          className="xPin"
                          src={deleteIcon}
                        />
                      </ListContainer>
                    ))}
                  </ul>
                )
              : ""}
          </Row>*/}

          {/*<Row start={1} direction={"column"}>
            <RowTop>
              <Title>커리큘럼</Title>

              <div
                style={{
                  width: "calc(100%)",
                  display: "inline-flex",
                  flexWrap: "wrap",
                }}
              >
                <Input
                  type="text"
                  placeholder="커리큘럼 제목을 적어주세요. / 예-[1] 준비운동 "
                  name="subject"
                  value={tagValue.subject}
                  changeHandler={tagInputChangeHandler}
                />

                <CurriculumContent
                  value={tagValue.content}
                  name="content"
                  onChange={tagInputChangeHandler}
                  placeholder="커리큘럼 내용을 적어주세요. / 예-관절 돌리기, 호흡 연습"
                  type="text"
                  maxLength={"500"}
                />
              </div>

              <ButtonBox>
                <button
                  type="button"
                  name="contain"
                  onClick={() => {
                    ValueChange("list", "curriculum_list", {
                      content: tagValue.content,
                      subject: tagValue.subject,
                      number: "new",
                    });
                    setTagValue({ ...tagValue, content: "", subject: "" });
                  }}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {lesson
              ? lesson.curriculum_list.length > 0 && (
                  <ul>
                    {lesson.curriculum_list.map((item, idx) => (
                      <CurriculumContainer>
                        <div className="C_subject">
                          <img className="pin" src={infoPin} />
                          <span>{item["subject"]}</span>
                          <img
                            onClick={() => DeleteData("curriculum_list", idx)}
                            className="xPin"
                            src={deleteIcon}
                          />
                        </div>

                        <div className="C_content">
                          {item["content"].split("\n").map((item2, idx2) => (
                            <span>{item2}</span>
                          ))}
                        </div>
                      </CurriculumContainer>
                    ))}
                  </ul>
                )
              : ""}
          </Row>*/}

          {/*<Row direction={"column"}>
            <RowTop>
              <Title>준비물</Title>

              <Input
                type="text"
                placeholder="준비물을 적어주세요. / 예-수영복"
                name="materials"
                value={tagValue.materials}
                changeHandler={tagInputChangeHandler}
              />

              <ButtonBox>
                <button
                  type="button"
                  name="materials"
                  onClick={() => {
                    ValueChange("list", "bring_list", {
                      content: tagValue.materials,
                      number: "new",
                    });
                    setTagValue({ ...tagValue, materials: "" });
                  }}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {lesson
              ? lesson.bring_list.length > 0 && (
                  <ul>
                    {lesson.bring_list.map((item, idx) => (
                      <Tag
                        key={idx}
                        text={item.content}
                        deleteHandler={() => DeleteData("bring_list", idx)}
                      />
                    ))}
                  </ul>
                )
              : ""}
          </Row>*/}

          {/*<Row direction={"column"}>
            <RowTop>
              <Title>포함사항</Title>

              <Input
                type="text"
                placeholder="포함된 사항을 적어주세요. / 예-장비 렌탈비"
                name="contain"
                value={tagValue.contain}
                changeHandler={tagInputChangeHandler}
              />

              <ButtonBox>
                <button
                  type="button"
                  name="contain"
                  onClick={() => {
                    ValueChange("list", "include_list", {
                      content: tagValue.contain,
                      number: "new",
                    });
                    setTagValue({ ...tagValue, contain: "" });
                  }}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {lesson
              ? lesson.include_list.length > 0 && (
                  <ul>
                    {lesson.include_list.map((item, idx) => (
                      <Tag
                        key={idx}
                        text={item.content}
                        deleteHandler={() => DeleteData("include_list", idx)}
                      />
                    ))}
                  </ul>
                )
              : ""}
          </Row>*/}

          {/*<Row direction={"column"}>
            <RowTop>
              <Title>불포함사항</Title>

              <Input
                type="text"
                placeholder="포함되지 않은 사항을 금액과 함께 적어주세요. / 예-풀장 입장료(+15,000)"
                name="except"
                value={tagValue.except}
                changeHandler={tagInputChangeHandler}
              />

              <ButtonBox>
                <button
                  type="button"
                  name="except"
                  onClick={() => {
                    ValueChange("list", "exclude_list", {
                      content: tagValue.except,
                      number: "new",
                    });
                    setTagValue({ ...tagValue, except: "" });
                  }}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {lesson
              ? lesson.exclude_list.length > 0 && (
                  <ul>
                    {lesson.exclude_list.map((item, idx) => (
                      <Tag
                        key={idx}
                        text={item.content}
                        deleteHandler={() => DeleteData("exclude_list", idx)}
                      />
                    ))}
                  </ul>
                )
              : ""}
          </Row>*/}

          {/*<FAQRow direction={"column"}>
            <FAQTop>
              <p>자주 묻는 질문</p>

              <ButtonBox>
                <button
                  type="button"
                  onClick={() => {
                    ValueChange("list", "faq_list", {
                      name: faqValue.question,
                      content: faqValue.answer,
                      number: "new",
                    });
                    setTagValue({ ...faqValue, question: "", answer: "" });
                  }}
                >
                  추가
                </button>
              </ButtonBox>
            </FAQTop>

            <FAQCard
              value={faqValue}
              resetHandler={faqResetHandler}
              changeHandler={faqInputChangeHandler}
            />

            <ul>
              {lesson
                ? lesson.faq_list.length > 0 &&
                  lesson.faq_list.map((item, idx) => (
                    <FAQItem
                      key={idx}
                      data={item}
                      deleteHandler={() => DeleteData("faq_list", idx)}
                    />
                  ))
                : ""}
            </ul>
          </FAQRow>*/}

          {/*<Row start={1} direction={"column"}>
            <RowTop>
              <Title>유의사항</Title>

              <div
                style={{
                  width: "calc(100%)",
                  display: "inline-flex",
                  flexWrap: "wrap",
                }}
              >
                <Input
                  type="text"
                  placeholder="유의사항을 적어주세요. / 예-상황에 따라 장소가 변경될 수 있습니다."
                  name="notice2"
                  value={tagValue.notice2}
                  changeHandler={tagInputChangeHandler}
                />
              </div>

              <ButtonBox>
                <button
                  type="button"
                  name="contain"
                  onClick={() => {
                    ValueChange("list", "notice_list2", {
                      content: tagValue.notice2,
                      number: "new",
                      type: "notice",
                    });
                    setTagValue({ ...tagValue, notice2: "" });
                  }}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {lesson
              ? lesson.notice_list2.length > 0 && (
                  <ul>
                    {lesson.notice_list2.map((item, idx) => (
                      <ListContainer>
                        <img className="pin" src={infoNotice} />
                        <span>{item["content"]}</span>
                        <img
                          onClick={() => DeleteData("notice_list2", idx)}
                          className="xPin"
                          src={deleteIcon}
                        />
                      </ListContainer>
                    ))}
                  </ul>
                )
              : ""}
          </Row>*/}

          {/*<Row direction={"column"}>
            <RowTop>
              <Title>노출 키워드</Title>

              <Input
                type="text"
                placeholder="키워드로 입력해 주세요."
                name="keyword"
                value={tagValue.keyword}
                changeHandler={tagInputChangeHandler}
              />

              <ButtonBox>
                <button
                  type="button"
                  name="keyword"
                  onClick={() => {
                    ValueChange("list", "keyword_list", {
                      tag: tagValue.keyword,
                      number: "new",
                    });
                    setTagValue({ ...tagValue, keyword: "" });
                  }}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {lesson
              ? lesson.keyword_list.length > 0 && (
                  <ul>
                    {lesson.keyword_list.map((item, idx) => (
                      <Tag
                        key={idx}
                        text={item.tag}
                        deleteHandler={() => DeleteData("keyword_list", idx)}
                      />
                    ))}
                  </ul>
                )
              : ""}
          </Row>*/}

          {/*<Row direction={"column"}>
            <RowTop>
              <Title>검색 키워드</Title>

              <Input
                type="text"
                placeholder="키워드로 입력해 주세요."
                name="exposure"
                value={tagValue.exposure}
                changeHandler={tagInputChangeHandler}
              />

              <ButtonBox>
                <button
                  type="button"
                  name="exposure"
                  onClick={() => {
                    ValueChange("list", "tag_list", {
                      tag: tagValue.exposure,
                      number: "new",
                    });
                    setTagValue({ ...tagValue, exposure: "" });
                  }}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {lesson
              ? lesson.tag_list.length > 0 && (
                  <ul>
                    {lesson.tag_list.map((item, idx) => (
                      <Tag
                        key={idx}
                        text={item.tag}
                        deleteHandler={() => DeleteData("tag_list", idx)}
                      />
                    ))}
                  </ul>
                )
              : ""}
          </Row>*/}
        </Main>

        <Footer>
          {fid !== "register" ? (
            <Button
              text="삭제하기"
              clickHandler={deleteLessonHandler}
              width={`180px`}
              color={colors.white}
              fontcolor={colors.red}
            />
          ) : (
            ""
          )}

          {/*fid !== "register" &&
          (facility ? (facility.open == "no" ? true : false) : false) ? (
            <Button
              text={
                facility
                  ? facility.open == "no"
                    ? "시설 승인하기"
                    : "시설 승인취소"
                  : false
              }
              clickHandler={lessonStatusConfirm}
              width={`180px`}
              color={colors.white}
              fontcolor={colors.red}
            />
          ) : (
            ""
          )*/}

          {fid !== "register" ? (
            <Button
              text="상품 관리"
              clickHandler={() => navigate(`/facility_product/${fid}`)}
              width={`180px`}
              color={colors.white}
              fontcolor={colors.blue}
            />
          ) : (
            ""
          )}


          {fid !== "register" ? (
            <Button
              text="저장하기"
              clickHandler={saveAndWriteUsageInfoHandler}
              width={`180px`}
            />
          ) : (
            ""
          )}

          {fid == "register" ? (
            <Button text="등록하기" clickHandler={lessonAdd} width={`180px`} />
          ) : (
            ""
          )}
        </Footer>

        <CropperPopup
          cropType={cropType}
          status={imgPopup}
          OnOff={setImgPopup}
          WH={imgScale}
          imgData={cropImg}
          setImg={CropImg}
        />

        {/*<Row>
            <DiscountContainer>
              <RowTop>
                <Title>동반 할인가격</Title>

                <DiscountBox>
                  <Select
                    width="250px"
                    name="discount-personnel"
                    value={discount.people}
                    changeHandler={(e) =>
                      setDiscount({ ...discount, people: e.target.value })
                    }
                    optionList={[
                      {
                        text: "2인",
                        value: 2,
                      },
                      {
                        text: "3인",
                        value: 3,
                      },
                      {
                        text: "4인",
                        value: 4,
                      },
                      {
                        text: "5인",
                        value: 5,
                      },
                      {
                        text: "6인",
                        value: 6,
                      },
                      {
                        text: "7인",
                        value: 7,
                      },
                      {
                        text: "8인",
                        value: 8,
                      },
                      {
                        text: "9인",
                        value: 9,
                      },
                      {
                        text: "10인",
                        value: 10,
                      },
                      {
                        text: "11인",
                        value: 11,
                      },
                      {
                        text: "12인",
                        value: 12,
                      },
                      {
                        text: "13인",
                        value: 13,
                      },
                      {
                        text: "14인",
                        value: 14,
                      },
                      {
                        text: "15인",
                        value: 15,
                      },
                      {
                        text: "16인",
                        value: 16,
                      },
                      {
                        text: "17인",
                        value: 17,
                      },
                      {
                        text: "18인",
                        value: 18,
                      },
                      {
                        text: "19인",
                        value: 19,
                      },
                      {
                        text: "20인",
                        value: 20,
                      },
                    ]}
                  />

                  <DiscountWrapper>
                    <Input
                      placeholder="기존 가격에서 추가되는 금액을 입력해 주세요."
                      type="text"
                      name="discount-price"
                      value={discount.price}
                      changeHandler={(e) => {
                        var regex = /[^0-9]/g;
                        var stateChangeValue = e.target.value.replace(
                          regex,
                          ""
                        );
                        var stateChangeValue = stateChangeValue.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                        setDiscount({ ...discount, price: stateChangeValue });
                      }}
                    />
                  </DiscountWrapper>
                </DiscountBox>

                <ButtonBox>
                  <button
                    type="button"
                    onClick={(e) => {
                      ValueChange("list", "discount_list", {
                        number: "new",
                        price: discount.price,
                        people: discount.people,
                      });
                      setDiscount((prev) => ({
                        ...prev,
                        price: "",
                        people: "2",
                      }));
                    }}
                  >
                    입력
                  </button>
                </ButtonBox>
              </RowTop>

              {lesson
                ? lesson.discount_list.length > 0 && (
                    <ul>
                      {lesson.discount_list.map((item, idx) => (
                        <Tag
                          key={idx}
                          text={`${item.people}인 ${item.price}원`}
                          deleteHandler={() => DeleteData("discount_list", idx)}
                        />
                      ))}
                    </ul>
                  )
                : ""}
            </DiscountContainer>
          </Row>*/}
      </MainLayout>

      <OverlayPortal>
        {modal && (
          <>
            <Overlay toggleHandler={() => setModal(!modal)} />

            <ModalLayout
              subButtonText="저장하고 이동하기"
              subButtonHandler={lessonSave}
              buttonText="바로 이동하기"
              clickHandler={() =>
                navigate(`/partner/${pid}/lesson/${fid}/editor`)
              }
            >
              <ModalText>
                시설 정보를 저장하고 이용 정보 수정 페이지로
                이동하시겠습니까?
              </ModalText>
            </ModalLayout>
          </>
        )}
      </OverlayPortal>
    </>
  );
};

export default PartnerLessonEditPage;

const SportBox = styled.div`
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  overflow: hidden;
`;

const AddImgBox = styled.label`
  width: 200px;
  height: 125px;
  margin-right: 10px;
  display: inline-block;
  cursor: pointer;
  border: 3px solid rgba(132, 140, 148, 0.5);
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  background-color: rgba(132, 140, 148, 0.5);

  img {
    width: 40%;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }

  input {
    display: none;
    width: 0.1px;
    height: 0.1px;
    pointer-events: none;
  }
`;

const AddImgListBox = styled.div`
  width: calc(100% - 210px);
  height: 125px;
  display: inline-block;
  white-space: nowrap;
  overflow: auto hidden;

  div {
    width: 200px;
    height: 125px;
    margin-right: 10px;
    display: inline-block;
    position: relative;
    border: 1px solid rgba(132, 140, 148, 0.5);
    overflow: hidden;
    border-radius: 4px;

    .addImage {
      width: 100%;
      display: inline-block;
    }

    .deleteImage {
      position: absolute;
      top: 2px;
      right: 2px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 1px solid rgba(132, 140, 148, 1);

      img {
        width: 9px;
        display: inline-block;
      }
    }
  }
`;

const SportCategory = styled.div`
  width: 80px;
  height: 60px;
  display: inline-flex;
  flex-wrap: wrap;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: ${({ active }) =>
    active ? `3px solid rgba(0,149,255,1)` : "3px solid rgba(132,140,148, .5)"};
  font-size: 12px;
  cursor: pointer;

  img {
    width: 30px;
    display: inline-block;
  }

  span {
    width: 100%;
    display: inline-block;
    text-align: center;
  }
`;

const RelativeView = styled.span`
  display: inline-block;
  position: relative;
  width: ${({ width }) => (width ? width : "unset")};
`;

const AbsoluteView = styled.span`
  display: inline-block;
  position: absolute;
  top: ${({ top }) => (top ? top : "unset")};
  left: ${({ left }) => (left ? left : "unset")};
  right: ${({ right }) => (right ? right : "unset")};
  transform: ${({ translate }) => (translate ? translate : "unset")};
`;
