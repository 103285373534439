import { Link } from "react-router-dom";
import styled from "styled-components";
import base from "../../../styles/constants/base";
import colors from "../../../styles/constants/colors";
import typography from "../../../styles/constants/typography";
import flexbox from "../../../styles/func/flexbox";

const Card = styled.li`
  width: 100%;
  height: 120px;
  box-shadow: ${base.boxShadow.default};
  border-radius: ${base.borderRadius.default}px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const SLink = styled(Link)`
  width: 100%;
  height: 100%;
  padding: 20px;
  ${flexbox("center", "flex-start", "column")}

  p:first-child {
    margin-bottom: 10px;
    font-size: ${typography.size.medium}px;
  }

  strong {
    font-weight: ${typography.weight.thin};
    color: ${colors.red};
  }
`;

const LocationCard = ({ data, pid }) => {
  return (
    <Card>
      <SLink to={`/partner/${pid}/schedule/${data.location}/${data.location_additional}`}>
        <p>{data.location + " " + data.location_additional}</p>

        <p>
          등록 레슨 <strong>({data.lesson_count}건)</strong>
        </p>
      </SLink>
    </Card>
  );
};

export default LocationCard;
