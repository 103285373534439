import styled from "styled-components";
import Input from "../../modules/input";
import Select from "../../modules/select";
import Textarea from "../../modules/textarea";
import colors from "../../styles/constants/colors";
import flexbox from "../../styles/func/flexbox";
import deleteIcon from "../../assets/icon/circle-close.svg";
import { useEffect, useState } from "react";
import base from "../../styles/constants/base";
import { getCategoryText } from "./card";

const Card = styled.div`
  margin: 10px 0 20px 0;
  display: flex;
`;

const Wrapper = styled.div`
  margin-left: 20px;
`;

const Left = styled.div`
  width: 100%;
`;

const Right = styled.div`
  margin-left: 20px;
`;

const QuestionBox = styled.div`
  ${flexbox("flex-start")}
  margin-bottom: 20px;

  p {
    margin-right: 20px;
  }
`;

const AnswerBox = styled.div`
  display: flex;

  p:first-child {
    margin-right: 20px;
  }

  p:last-child {
    display: block;
    padding: 20px;
    width: 100%;
    min-height: 80px;
    border-radius: ${base.borderRadius.default}px;
    background-color: ${colors.lightBlue};
  }
`;

const RegisterItem = ({ value, deleteHandler }) => {
  return (
    <Card>
      <Left>
        <QuestionBox>
          <p>Q.</p>

          <p>
            [{getCategoryText(value.category)}] {value.question}
          </p>
        </QuestionBox>

        <AnswerBox>
          <p>A.</p>

          <p>{value.answer}</p>
        </AnswerBox>
      </Left>

      <Right>
        <button type="button" onClick={deleteHandler}>
          <img src={deleteIcon} />
        </button>
      </Right>
    </Card>
  );
};

export default RegisterItem;
