import styled from "styled-components";
import Input from "../../../modules/input";
import deleteIcon from "../../../assets/icon/delete-circle.svg";

const Container = styled.div`
  width: 100%;

  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    li {
      width: calc(50% - 10px);
      margin-bottom: 20px;

      &:nth-child(odd) {
        margin-right: 20px;
      }
    }
  }
`;

const Wrapper = styled.div`
  position: relative;

  button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display : inline-flex;
    align-items : center;
  }
`;

const CareerBox = ({
  careerList,
  inputValue,
  setInputValue,
  inputChangeHandler,
  deleteHandler,
}) => {
  const resetHandler = () => {
    setInputValue(() => ({
      career: "",
    }));
  };

  return (
    <Container>
      <ul>
        <li>
          <Wrapper>
            <Input
              type="text"
              name="career"
              value={inputValue}
              changeHandler={inputChangeHandler}
            />
            <button type="button" onClick={resetHandler}>
              <img src={deleteIcon} />
            </button>
          </Wrapper>
        </li>

        {careerList ? careerList.map((item, idx) => (
          <li key={idx}>
            <Wrapper>
              <Input
                type="text"
                name="career"
                value={item}
                readonly={true}
                cursor={false}
              />
              <button type="button" onClick={() => deleteHandler(idx)}>
                <img src={deleteIcon} />
              </button>
            </Wrapper>
          </li>
        )) : ""}
      </ul>
    </Container>
  );
};

export default CareerBox;
