const bannerList = [
  {
    id: 1,
    name: "프리다이빙 크루 할인",
    link: "crew_service_info/161",
    registerDate: "2022-03-25 12:00",
    order: 1,
    status: true,
    image: {
      base: "",
      files: "",
    },
  },
  {
    id: 2,
    name: "프리다이빙 크루 할인",
    link: "crew_service_info/161",
    registerDate: "2022-03-25 12:00",
    order: 3,
    status: true,
    image: {
      base: "",
      files: "",
    },
  },
  {
    id: 3,
    name: "프리다이빙 크루 할인",
    link: "crew_service_info/161",
    registerDate: "2022-03-25 12:00",
    order: 2,
    status: true,
    image: {
      base: "",
      files: "",
    },
  },
  {
    id: 4,
    name: "프리다이빙 크루 할인",
    link: "crew_service_info/161",
    registerDate: "2022-03-25 12:00",
    order: 0,
    status: false,
    image: {
      base: "",
      files: "",
    },
  },
  {
    id: 5,
    name: "프리다이빙 크루 할인",
    link: "crew_service_info/161",
    registerDate: "2022-03-25 12:00",
    order: 0,
    status: false,
    image: {
      base: "",
      files: "",
    },
  },
  {
    id: 6,
    name: "프리다이빙 크루 할인",
    link: "crew_service_info/161",
    registerDate: "2022-03-25 12:00",
    order: 0,
    status: false,
    image: {
      base: "",
      files: "",
    },
  },
  {
    id: 7,
    name: "프리다이빙 크루 할인",
    link: "crew_service_info/161",
    registerDate: "2022-03-25 12:00",
    order: 0,
    status: false,
    image: {
      base: "",
      files: "",
    },
  },
  {
    id: 8,
    name: "프리다이빙 크루 할인",
    link: "crew_service_info/161",
    registerDate: "2022-03-25 12:00",
    order: 0,
    status: false,
    image: {
      base: "",
      files: "",
    },
  },
  {
    id: 9,
    name: "프리다이빙 크루 할인",
    link: "crew_service_info/161",
    registerDate: "2022-03-25 12:00",
    order: 0,
    status: false,
    image: {
      base: "",
      files: "",
    },
  },
  {
    id: 10,
    name: "프리다이빙 크루 할인",
    link: "crew_service_info/161",
    registerDate: "2022-03-25 12:00",
    order: 0,
    status: false,
    image: {
      base: "",
      files: "",
    },
  },
  {
    id: 11,
    name: "프리다이빙 크루 할인",
    link: "crew_service_info/161",
    registerDate: "2022-03-25 12:00",
    order: 0,
    status: false,
    image: {
      base: "",
      files: "",
    },
  },
];

export default bannerList;
