import styled from "styled-components";
import selectChevron from "../assets/icon/select-chevron.svg";
import calendarIcon from "../assets/icon/calendar.svg";

const INPUT_PADDING = 20;

const Box = styled.div`
  position: relative;
  width: ${({ width }) => width};
  margin-left: ${({ margin }) => (margin ? "30px" : null)};

  select {
    width: 100%;
    ${({ isTransparent }) =>
      isTransparent
        ? `
        box-shadow: none;
        background-color: transparent;
    `
        : null};

    border: ${({ border }) => (!border ? `none` : null)};
    overflow : hidden;
    text-overflow : ellipsis;

    &:focus {
      ${({ isTransparent }) =>
        isTransparent
          ? `border: 2px solid transparent; box-shadow: none;
        background-color: transparent;`
          : null}

      border: ${({ border }) => (!border ? `none` : null)};
    }
  }

  img {
    position: absolute;
    right: ${INPUT_PADDING}px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Select = ({
  type,
  border = true,
  isTransparent = false,
  clna = false,
  name,
  value,
  changeHandler,
  changeHandler2,
  optionList = [],
  placeholder,
  width = "100%",
  margin = false,
  disabled = false,
}) => {
  if (type === "calendar") {
    return (
      <Box
        width={width}
        isTransparent={isTransparent}
        margin={margin}
        border={border}
      >
        <select
          value={value}
          name={name}
          onChange={changeHandler}
          disabled={disabled}
        >
          {placeholder && <option hidden value="">{placeholder}</option>}

          {optionList.map((item, idx) => (
            <option value={item.value} key={idx}>
              {item.text}
            </option>
          ))}
        </select>

        <img src={calendarIcon} alt="아이콘" />
      </Box>
    );
  }

  return (
    <Box
      width={width}
      isTransparent={isTransparent}
      margin={margin}
      border={border}
    >
      <select
        value={value}
        name={name}
        onChange={changeHandler}
        disabled={disabled}
        className={clna}
      >
        {placeholder && <option hidden value="">{placeholder}</option>}

        {optionList.map((item, idx) => (
          <option value={item.value} key={idx}>
            {item.text}
          </option>
        ))}
      </select>

      <img src={selectChevron} alt="아이콘" />
    </Box>
  );
};

export default Select;
