const lessonListData = [
  {
    id: 1,
    name: "원데이 체험 다이빙",
    location: [
      "경기도 가평군 청평면 고재길...",
      "경기 수원시 팔달구 창룡대로...",
      "인천 연수구 인천신항대로89...",
      "경기도 용인시 처인구 포곡읍...",
    ],
  },
  {
    id: 2,
    name: "오픈워터 다이버",
    location: [
      "경기도 가평군 청평면 고재길...",
      "경기 수원시 팔달구 창룡대로...",
    ],
  },
  {
    id: 3,
    name: "원데이 체험 다이빙",
    location: [
      "경기도 가평군 청평면 고재길...",
      "경기 수원시 팔달구 창룡대로...",
      "인천 연수구 인천신항대로89...",
      "경기도 용인시 처인구 포곡읍...",
    ],
  },
  {
    id: 4,
    name: "오픈워터 다이버",
    location: [
      "경기도 가평군 청평면 고재길...",
      "경기 수원시 팔달구 창룡대로...",
      "인천 연수구 인천신항대로89...",
      "경기도 용인시 처인구 포곡읍...",
    ],
  },
  {
    id: 5,
    name: "원데이 체험 다이빙",
    location: [
      "경기도 가평군 청평면 고재길...",
      "경기 수원시 팔달구 창룡대로...",
      "인천 연수구 인천신항대로89...",
      "경기도 용인시 처인구 포곡읍...",
    ],
  },
  {
    id: 6,
    name: "오픈워터 다이버",
    location: [
      "경기도 가평군 청평면 고재길...",
      "경기 수원시 팔달구 창룡대로...",
    ],
  },
];

export default lessonListData;

// {
//   id: 1,
//   img: "",
//   title: "체험 다이빙",
//   schedule: [1, 2, 3, 4, 5],
//   status: true,
//   price: 50000,
// },
// {
//   id: 2,
//   img: "",
//   title: "오픈워터 다이버",
//   schedule: [],
//   status: false,
//   price: 50000,
// },
// {
//   id: 3,
//   img: "",
//   title: "오픈워터 다이버",
//   schedule: [],
//   status: false,
//   price: 50000,
// },
