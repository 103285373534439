import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import XIcon from "../assets/icon/drawerClosing.png";

const DrawerModal = ({ open, setOpen, children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    closeModal();
  }, [pathname]);

  const closeModal = () => {
    setOpen(false);
    document.body.style.removeProperty("overflow");
    document.getElementById("root").style.pointerEvents = "auto";
  };

  if (open) {
    document.getElementById("root").style.overflow = "hidden";
    document.getElementById("drawer").style.overflow = "auto";
    document.getElementById("root").style.pointerEvents = "none";
  }

  return (
    <Container open={open}>
      <div style={{ position: "relative" }}>
        <Closing onClick={() => closeModal()}>
          <Icon src={XIcon} />
        </Closing>
      </div>
      <ContentBox id="drawer">{children}</ContentBox>
    </Container>
  );
};

export default DrawerModal;

const Container = styled.section`
  width: calc(1rem * 747 / 14 - 30px);
  max-width: calc(1rem * 747 / 14 - 30px) !important;

  right: calc(1rem * (-747) / 14);
  /* right: 0; */

  min-height: calc(100vh - 48px);
  display: flex;
  position: absolute;
  top: 48px;

  transform: ${({ open }) =>
    open ? "translateX(calc(1rem * (-747) / 14 ))" : "translateX(0)"};
  transition: all 0.3s ease;
  box-shadow: -4px 0px 10px 2px rgba(0, 0, 0, 0.1);
  pointer-events: auto !important;
`;

const Closing = styled.div`
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 30px;
  height: 102px;
  background-color: white;
  box-shadow: -4px 0px 10px 2px rgba(0, 0, 0, 0.1);

  position: absolute;
  left: -27px;
  cursor: pointer;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const ContentBox = styled.div`
  width: calc(1rem * 747 / 14 - 30px);
  max-width: calc(1rem * 747 / 14 - 30px) !important;
  background-color: white;
  z-index: 2;
  height: calc(100vh - 48px);
  overflow: hidden auto;
`;
