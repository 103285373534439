import styled from "styled-components";
import base from "../../styles/constants/base";
import colors from "../../styles/constants/colors";
import flexbox from "../../styles/func/flexbox";
import transition from "../../styles/func/transition";
import chevronIcon from "../../assets/icon/chevron-b.svg";
import { useEffect, useState } from "react";
import useInput from "../../hooks/useInput";
import Select from "../../modules/select";
import Input from "../../modules/input";

const Item = styled.li`
  width: calc(100% - 3px);
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const QuestionBox = styled.div`
  height: 50px;
  ${flexbox("space-between")}
  cursor: pointer;

  margin-bottom: ${({ editable }) => (editable === true ? `20px` : `0`)};
`;

const Img = styled.img`
  transition: ${transition("transform")};
  transform: ${({ open }) => (open ? `rotate(-90deg)` : `rotate(90deg)`)};
  margin-left: 20px;
`;

const QuestionWrapper = styled.div`
  width: 100%;
  ${flexbox("space-between")}

  input {
    margin: 0 20px;
    width: 95%;
  }

  select {
    margin-right: 20px;
  }
`;

const AnswerBox = styled.div`
  width: 100%;
  ${flexbox("flex-start", "flex-start", "column")};
  background-color: ${colors.white};
  border-radius: ${base.borderRadius.default}px;
  max-height: ${({ open }) => (open ? "170px" : 0)};
  overflow: hidden;
  transition: ${transition("max-height")};

  textarea {
    background-color: ${colors.lightBlue};
    width: 100%;
    min-height: 120px;
    height : auto;
    padding: 20px;
    border-radius: ${base.borderRadius.default}px;
    resize: none;
    padding-bottom: ${({ isPadding }) => (isPadding ? "50px" : "20px")};
    margin-bottom: 10px;
    display : inline-block;

    &:disabled {
      color: ${colors.black};
    }
  }
`;

const ButtonBox = styled.div`
  width: 100%;
  ${flexbox("flex-end")};

  button {
    background-color: ${({ editable }) =>
      editable ? colors.blue : colors.mediumGray};
    margin-left: 20px;
    padding: 7px 20px;
    border-radius: 40px;
    color: ${colors.white};
  }
`;

const FAQCard = ({
  data,
  idx,
  editHandler,
  deleteHandler,
  currentPage,
  tab,
  numberData,
  faqSaveHandler
}) => {
  const [open, setOpen] = useState(false);

  const [editable, setEditable] = useState(false);

  const [page, setPage] = useState(currentPage);

  const [category, setCategory] = useState(tab);

  const openToggleHandler = () => {
    setOpen(!open);
  };

  const taragetSaveHandler = () => {

    if(window.confirm('저장하시겠습니까?')) { 
        if(faqSaveHandler(numberData)) {
            setEditable(false);
        }
    }
  };


  useEffect(() => {
    if (currentPage !== page) {
      setOpen(false);
      setPage(currentPage);
      setEditable(false);
    }

    if (tab !== category) {
      setOpen(false);
      setCategory(tab);
      setEditable(false);
    }
  }, [currentPage, tab, category, page]);

  return (
    <Item>
      {!editable && (
        <QuestionBox
          onClick={() => openToggleHandler(data.id)}
          open={open}
          editable={editable}
        >
          {data.category && (
            <p>{`${data.number}. ${data.subject}`}</p>
          )}
          <Img src={chevronIcon} open={open} />
        </QuestionBox>
      )}

      {editable && (
        <QuestionBox open={open} editable={editable}>
          {data.category && (
            <QuestionWrapper>
              {`${data.number}.`}
              <Input
                type="text"
                name="question"
                placeholder="질문을 작성해 주세요.(최대 20자)"
                changeHandler={(e) => editHandler(numberData, 'subject', e.target.value)}
                value={data.subject}
              />
              <Select
                width="160px"
                name="category"
                value={data ? data.category : ""}
                optionList={[
                  {
                    text: "이용 안내",
                    value: "info",
                  },
                  {
                    text: "회원 정보",
                    value: "user",
                  },
                  {
                    text: "결제/환불",
                    value: "payment",
                  },
                ]}
                changeHandler={(e) => editHandler(numberData, 'category', e.target.value)}
              />
            </QuestionWrapper>
          )}
          <Img
            src={chevronIcon}
            open={open}
            onClick={() => openToggleHandler(data.id)}
          />
        </QuestionBox>
      )}

      <AnswerBox open={open}>
        <textarea
          name="answer"
          value={data ? data.content : ""}
          onChange={(e) => editHandler(numberData, 'content', e.target.value)}
          disabled={!editable}
        />

        {!editable && (
          <ButtonBox editable={editable}>
            <button type="button" onClick={() => setEditable(true)}>
              수정하기
            </button>

            <button type="button" onClick={() => deleteHandler(numberData)}>
              삭제하기
            </button>
          </ButtonBox>
        )}

        {editable && (
          <ButtonBox editable={editable}>
            <button type="button" onClick={taragetSaveHandler}>
              저장하기
            </button>
          </ButtonBox>
        )}
      </AnswerBox>
    </Item>
  );
};

export default FAQCard;

export const getCategoryText = (category) => {
  switch (category) {
    case "info":
      return `이용 안내`;

    case "user":
      return `회원 정보`;

    case "payment":
      return `결제/환불`;

    default:
      return;
  }
};
