import styled from "styled-components";
import { useState, useEffect } from "react";
import axios from "axios";

// style
import colors from "../../styles/constants/colors";

// icon
import closeIcon from "../../assets/icon/close.svg";

const SchedulePopup = ({ status, OnOff }) => {
  const [yoil, setYoil] = useState({
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  });

  const OpenTimeList = [
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];

  const [defaultSchedule, setDefaultSchedule] = useState({
    start_time: "06",
    start_min: "00",
    finish_time: "09",
    finish_min: "00",
  });

  const [DSS, setDSS] = useState({
    start_time: false,
    start_min: false,
    finish_time: false,
    finish_min: false,
  });

  const [ADS, setADS] = useState(false);

  const [defaultText, setDefaultText] = useState("");

  const [scheduleTime, setScheduleTime] = useState([]);

  const [activityList, setActivityList] = useState([]);

  const [selectedActivity, setSelectedActivity] = useState([]);

  const [activeActivity, setActiveActivity] = useState([]);

  useEffect(() => {
    if (defaultText) {
      var token = window.localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "activity_search");
      frm.append("token", token);
      frm.append("text", defaultText);

      axios
        .post("https://ai.semos.kr/semos_admin/activity/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            setActivityList(Value.activity_list);
          } else {
            alert(statusMessage);
            window.location.reload();
          }
        });
    }
  }, [defaultText]);

  const ScheduleAdd = () => {
    const startTime = `${defaultSchedule.start_time}:${defaultSchedule.start_min}`;
    const finishTime = `${defaultSchedule.finish_time}:${defaultSchedule.finish_min}`;

    const scheduleTimeData = {
      start_date: startTime,
      finish_date: finishTime,
    };

    setScheduleTime((scheduleTime) => [...scheduleTime, scheduleTimeData]);
  };

  const DefaultScheduleCheck = (type, value) => {
    setDefaultSchedule({ ...defaultSchedule, [type]: value });
    setDSS({ ...DSS, [type]: !DSS[type] });
  };

  const DefaultTextCheck = (value) => {
    setDefaultText(value);

    if (!ADS) {
      setADS(true);
    } else if (!value && !value.length) {
      setADS(false);
    }
  };

  const ActivityAdd = (number) => {
    const SelectActivityName = `${activityList[number].partner_number}_${activityList[number].activity_number}_${activityList[number].activity_name}`;

    setSelectedActivity((selectedActivity) => [
      ...selectedActivity,
      activityList[number],
    ]);
    setActiveActivity((activeActivity) => [
      ...activeActivity,
      SelectActivityName,
    ]);
  };

  return (
    <ScheduleContainer aria-hidden="true" status={status}>
      <div onClick={() => OnOff(!status)} className="backgroundContainer" />

      <div className="contentContainer">
        <div className="scheduleHeader">
          <h3>액티비티 일정 등록</h3>
          <img
            onClick={() => OnOff(!status)}
            className="popupClose"
            src={closeIcon}
          />
        </div>

        <div className="scheduleContent">
          <PopupHeader>액티비티 검색</PopupHeader>

          <ContentContainer>
            <ActivityContent>
              <div className="activitySearch2">
                <input
                  value={defaultText}
                  onChange={(e) => DefaultTextCheck(e.target.value)}
                  type="search"
                  placeholder=""
                />
                <ActivityDropDown status={ADS}>
                  {activityList.length ? (
                    activityList.map((item, idx) => (
                      <ActivityDropDownContent onClick={() => ActivityAdd(idx)}>
                        {item.activity_name}
                      </ActivityDropDownContent>
                    ))
                  ) : (
                    <ActivityDropDownContent style={{ pointerEvents: "none" }}>
                      검색된 액티비티가 없습니다.
                    </ActivityDropDownContent>
                  )}
                </ActivityDropDown>
              </div>

              <span onClick={() => setADS(!ADS)} className="activityAdd">
                {ADS ? "닫기" : "열기"}
              </span>
            </ActivityContent>
          </ContentContainer>

          <PopupHeader>액티비티 일정</PopupHeader>

          <ContentContainer>
            <YoilContent active={yoil.sun}>
              <span onClick={() => setYoil({ ...yoil, sun: !yoil.sun })}>
                일
              </span>
            </YoilContent>
            <YoilContent active={yoil.mon}>
              <span onClick={() => setYoil({ ...yoil, mon: !yoil.mon })}>
                월
              </span>
            </YoilContent>
            <YoilContent active={yoil.tue}>
              <span onClick={() => setYoil({ ...yoil, tue: !yoil.tue })}>
                화
              </span>
            </YoilContent>
            <YoilContent active={yoil.wed}>
              <span onClick={() => setYoil({ ...yoil, wed: !yoil.wed })}>
                수
              </span>
            </YoilContent>
            <YoilContent active={yoil.thu}>
              <span onClick={() => setYoil({ ...yoil, thu: !yoil.thu })}>
                목
              </span>
            </YoilContent>
            <YoilContent active={yoil.fri}>
              <span onClick={() => setYoil({ ...yoil, fri: !yoil.fri })}>
                금
              </span>
            </YoilContent>
            <YoilContent active={yoil.sat}>
              <span onClick={() => setYoil({ ...yoil, sat: !yoil.sat })}>
                토
              </span>
            </YoilContent>
          </ContentContainer>

          <ContentContainer>
            <TimeContent>
              <div className="scheduleTime">
                <span
                  className="timeValue"
                  onClick={() =>
                    setDSS({ ...DSS, start_time: !DSS.start_time })
                  }
                >
                  {defaultSchedule.start_time}
                </span>

                <ScheduleDropDown status={DSS.start_time}>
                  {OpenTimeList.map((item, idx) => (
                    <ScheduleDropDownOption
                      onClick={() => DefaultScheduleCheck("start_time", item)}
                      active={item === defaultSchedule.start_time}
                    >
                      {item}
                    </ScheduleDropDownOption>
                  ))}
                </ScheduleDropDown>
              </div>

              <span className="scheduleWave">:</span>

              <div className="scheduleMin">
                <span
                  className="minValue"
                  onClick={() => setDSS({ ...DSS, start_min: !DSS.start_min })}
                >
                  {defaultSchedule.start_min}
                </span>

                <ScheduleDropDown status={DSS.start_min}>
                  <ScheduleDropDownOption
                    onClick={() => DefaultScheduleCheck("start_min", "00")}
                    active={"00" === defaultSchedule.start_min}
                  >
                    00
                  </ScheduleDropDownOption>
                  <ScheduleDropDownOption
                    onClick={() => DefaultScheduleCheck("start_min", "30")}
                    active={"30" === defaultSchedule.start_min}
                  >
                    30
                  </ScheduleDropDownOption>
                </ScheduleDropDown>
              </div>

              <span className="scheduleWave">~</span>

              <div className="scheduleTime">
                <span
                  className="timeValue"
                  onClick={() =>
                    setDSS({ ...DSS, finish_time: !DSS.finish_time })
                  }
                >
                  {defaultSchedule.finish_time}
                </span>

                <ScheduleDropDown status={DSS.finish_time}>
                  {OpenTimeList.map((item, idx) => (
                    <ScheduleDropDownOption
                      onClick={() => DefaultScheduleCheck("finish_time", item)}
                      active={item === defaultSchedule.finish_time}
                    >
                      {item}
                    </ScheduleDropDownOption>
                  ))}
                </ScheduleDropDown>
              </div>

              <span className="scheduleWave">:</span>

              <div className="scheduleMin">
                <span
                  className="minValue"
                  onClick={() =>
                    setDSS({ ...DSS, finish_min: !DSS.finish_min })
                  }
                >
                  {defaultSchedule.finish_min}
                </span>

                <ScheduleDropDown status={DSS.finish_min}>
                  <ScheduleDropDownOption
                    onClick={() => DefaultScheduleCheck("finish_min", "00")}
                    active={"00" === defaultSchedule.finish_min}
                  >
                    00
                  </ScheduleDropDownOption>
                  <ScheduleDropDownOption
                    onClick={() => DefaultScheduleCheck("finish_min", "30")}
                    active={"30" === defaultSchedule.finish_min}
                  >
                    30
                  </ScheduleDropDownOption>
                </ScheduleDropDown>
              </div>

              <span onClick={() => ScheduleAdd()} className="timeAdd">
                추가
              </span>
            </TimeContent>
          </ContentContainer>
        </div>

        <div className="scheduleCheck"></div>
      </div>
    </ScheduleContainer>
  );
};

export default SchedulePopup;

const ScheduleContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  display: ${({ status }) => (status ? "inline-flex" : "none")};
  pointer-events: ${({ status }) => (status ? "unset" : "none")};

  .backgroundContainer {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 201;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
  }

  .contentContainer {
    width: 800px;
    align-items: start;
    position: fixed;
    display: inline-flex;
    flex-wrap: wrap;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 5px,
      rgb(0 0 0 / 15%) -0.5px -0.5px 5px;
    z-index: 300;
    overflow: hidden;
    background-color: ${colors.White};
  }

  .scheduleHeader {
    width: 100%;
    padding: 20px 20px 0 20px;
    display: inline-flex;
    position: relative;
    margin-bottom: 20px;

    h3 {
      font-size: 22px;
      font-weight: 700;
      display: inline-block;
    }

    .popupClose {
      position: absolute;
      display: inline-block;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
  }

  .scheduleContent {
    width: 100%;
    padding: 0 20px 20px 20px;
    display: inline-flex;
    border-bottom: 2px solid ${colors.Gr02};
    flex-wrap: wrap;
  }

  .scheduleCheck {
    width: 100%;
    display: inline-flex;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const YoilContent = styled.div`
  width: calc(100% / 7);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;

  span {
    width: 50px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 5px,
      rgb(0 0 0 / 15%) -0.5px -0.5px 5px;
    ${({ active }) =>
      active
        ? `color : ${colors.White}; font-weight : 500; background-color : ${colors.Bl06};`
        : `color : ${colors.Gr04}; font-weight : 400; background-color : ${colors.White}; border : ${colors.Gr04} 1px solid;`};
  }
`;

const TimeContent = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;

  .scheduleTime {
    box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 5px,
      rgb(0 0 0 / 15%) -0.5px -0.5px 5px;
    width: calc((100% - 50px) * 0.2);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: rgba(132, 140, 148, 0.5) 1px solid;
    border-radius: 4px;
    height: 50px;
    position: relative;

    .timeValue {
      cursor: pointer;
      width: 100%;
      height: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  .scheduleMin {
    box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 5px,
      rgb(0 0 0 / 15%) -0.5px -0.5px 5px;
    width: calc((100% - 50px) * 0.2);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: rgba(132, 140, 148, 0.5) 1px solid;
    border-radius: 4px;
    height: 50px;
    position: relative;

    .minValue {
      cursor: pointer;
      width: 100%;
      height: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  .scheduleWave {
    width: calc((100% - 50px) * 0.04);
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .timeAdd {
    pointer: cursor;
    width: 50px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 5px,
      rgb(0 0 0 / 15%) -0.5px -0.5px 5px;
    border-radius: 4px;
    color: #ffffff;
    font-weight: 500;
    background-color: rgba(0, 149, 255, 1);
    cursor: pointer;
  }
`;

const ScheduleDropDown = styled.div`
  width: calc(100% + 2.3px);
  padding: 5px;
  display: ${({ status }) => (status ? "inline-block" : "none")};
  border-radius: 0 0 4px 4px;
  height: 100px;
  position: absolute;
  bottom: 0;
  right: -1.15px;
  transform: translate(0, 100%);
  overflow: hidden auto;
  border: rgba(132, 140, 148, 0.5) 1px solid;
  background-color: #ffffff;
  z-index: 301;

  span {
    border-bottom: rgba(132, 140, 148, 0.3) 1px solid;
    padding: 5px 0;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: rgba(132, 140, 148, 1);
    font-size: 18px;
  }
`;

const ScheduleDropDownOption = styled.span`
  ${({ active }) => (active ? "color : rgba(0,149,255,1) !important;" : "")}
`;

const ActivityContent = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;

  .activitySearch2 {
    width: calc(100% - 58px);
    display: inline-block;
    position: relative;
  }

  input {
    width: 100%;
    display: inline-block;
    border: rgba(132, 140, 148, 0.5) 1px solid;
    font-weight: 500;
    border-radius: 4px;
    padding: 10px;
    box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 5px,
      rgb(0 0 0 / 15%) -0.5px -0.5px 5px;
    outline: none !important;
  }

  .activityAdd {
    pointer: cursor;
    width: 50px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 5px,
      rgb(0 0 0 / 15%) -0.5px -0.5px 5px;
    border-radius: 4px;
    color: #ffffff;
    font-weight: 500;
    background-color: rgba(0, 149, 255, 1);
    cursor: pointer;
  }
`;

const ActivityDropDown = styled.div`
  width: 100%;
  padding: 5px 10px;
  display: ${({ status }) => (status ? "inline-block" : "none")};
  border-radius: 0 0 4px 4px;
  height: 150px;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(0, 100%);
  overflow: hidden auto;
  border: rgba(132, 140, 148, 0.5) 1px solid;
  background-color: #ffffff;
  z-index: 301;
`;

const ActivityDropDownContent = styled.div`
  width: 100%;
  display: inline-flex;
  border-bottom: rgba(132, 140, 148, 0.3) 1px solid;
  padding: 5px 0;
  cursor: pointer;
`;

const PopupHeader = styled.div`
  width: 100%;
  margin-bottom: 16px;
  display: inline-block;
  font-size: 20px;
`;
