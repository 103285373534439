import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

import DropDown from "../designedComponents/dropDown";
import ToggleButton from "../../modules/toggleButton";
import DropDownBig from "../designedActivity/dropDownforActivity";
import MapService from "../../service/map";
import ImageCrop from "../../components/designedComponents/imageCrop";

import { Wrapper, WrapperRow, Icon, Label } from "../../layouts/layoutStorage";

//img
import Add from "../../assets/icon/careerAddButton.png";
import Delete from "../../assets/icon/careerDeleteButton.png";
import Arrow from "../../assets/icon/selectArrow.png";
import BlackCalender from "../../assets/icon/blackcalendermatters.png";
import wave from "../../assets/icon/wave.png";
import XIcon from "../../assets/icon/tagDelete.png";
import DesignedActivitySchedule from "../designedActivitySchedule";
import Plus from "../../assets/icon/plus.png";
import GreySun from "../../assets/icon/sun.png";
import GreyMoon from "../../assets/icon/moon.png";
import colors from "../../styles/constants/colors";

const W = window.innerWidth;

const PartnerDetail = ({ id, index, partner, setPartner, modal }) => {
  const [info, setInfo] = useState([]);
  const [sport, setSport] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [rest, setRest] = useState([]);
  const [statusState, setStatusState] = useState("");
  const [careerText, setCareerText] = useState("");

  const [newSchedule, setNewSchedule] = useState({
    id: "new",
    title: "",
    start: "",
    end: "",
    address: "",
    extra: "",
    si: "",
    gu: "",
    dong: "",
    day: [],
    time_list: [],
  });

  const [category, setCategory] = useState({
    big: "",
    small: "",
  });

  const [deleteD, setDelete] = useState({
    sport: [],
    career: [],
    schedule: [],
  });

  const [cropImage, setCropImage] = useState({
    width: 5,
    height: 5,
    type: "",
    number: "",
    image: "",
    status: false,
    scale: true,
  });

  useEffect(() => {
    if (id) {
      var token = localStorage.getItem("token");

      const frm = new FormData();

      frm.append("mode", "partner_detail");
      frm.append("token", token);
      frm.append("id", id);

      axios
        .post("https://ai.semos.kr/semos_admin/partner/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;

          if (Value.status == "success") {
            setInfo(Value.partner_data);
            setSport(Value.partner_sport);
            setSchedule(Value.partner_schedule);
            setRest(Value.partner_rest);
            setStatusState(Value.partner_data.status);
          }
        });
    }
  }, [id]);

  const statusChecker = () => {
    if (statusState == 1) {
      return (
        <StatusBox
          bc={colors.Bl07}
          bgc={colors.Bl02}
          c={colors.Bl07}
          className="f14"
        >
          <span className="f4">●</span>&nbsp;활성화
        </StatusBox>
      );
    } else if (statusState == 2) {
      return (
        <StatusBox
          bc={colors.Gr03}
          bgc={colors.Gr01}
          c={colors.Gr04}
          className="f14"
        >
          <span className="f4">■</span>&nbsp;비활성화
        </StatusBox>
      );
    } else {
      return (
        <StatusBox
          bc={colors.Gr03}
          bgc={colors.Gr01}
          c={colors.Gr04}
          className="f14"
        >
          <span className="f4">■</span>&nbsp;승인요청
        </StatusBox>
      );
    }
  };

  const careerAdd = () => {
    if (careerText) {
      var copy = info.career;

      copy.push({
        id: "new",
        text: careerText,
      });

      setInfo((prev) => ({ ...prev, career: copy }));

      setCareerText("");
    } else {
      alert("내용을 입력해 주세요");
    }
  };

  const careerDelete = (idx) => {
    var copy = info.career;
    var copy2 = deleteD.career;

    var cut = copy.splice(idx, 1);

    copy2.push(cut[0]);

    setDelete((prev) => ({ ...prev, career: copy2 }));

    setInfo((prev) => ({ ...prev, career: copy }));
  };

  const deleteCategory = (idx) => {
    var copy = [...sport];
    const copy2 = deleteD.sport;
    const cut = copy[idx];

    copy.splice(idx, 1);

    copy2.push(cut);

    setDelete((prev) => ({ ...prev, sport: copy2 }));

    setSport(copy);
  };

  const convert = (value) => {
    if (value === "1") {
      return "활성화";
    } else if (value === "2") {
      return "비활성화";
    } else if (value === "3") {
      return "승인요청";
    }
  };

  const infoChange = (value, type, number) => {
    if (type == "phone") {
      var phone_number = value
        .replace(/[^0-9]/g, "")
        .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
      setInfo((prev) => ({ ...prev, [type]: phone_number }));
    } else if (type == "sport") {
      var sport_list = [...sport];
      var sport_check = true;
      for (var i = 0; i < sport_list.length; i++) {
        if (sport_list[i].sport == value.sport) {
          window.alert(`중복된 카테고리가 있습니다.`);
          sport_check = false;
          break;
        }
      }
      if (sport_check) {
        sport_list[sport_list.length] = value;
        setSport(sport_list);
      }
    } else {
      setInfo((prev) => ({ ...prev, [type]: value }));
    }
  };

  const PartnerSave = () => {
    console.log(statusState);
    if (window.confirm("파트너 정보를 저장하시겠습니까?")) {
      var token = localStorage.getItem("token");

      axios
        .post(
          "https://ai.semos.kr/semos_admin/center/data",
          {
            mode: "partner_save",
            token: token,
            info: info,
            sport: sport,
            schedule: schedule,
            status: statusState,
            delete: deleteD,
            rest: rest,
            id: id,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((data) => {
          var Value = data.data;
          var Status = Value.status;
          var StatusMessage = Value.status_message;

          if (Status == "success") {
            window.alert("파트너 수정이 완료되었습니다.");
            window.location.reload();
          } else {
            window.alert(StatusMessage);
            // window.location.reload();
          }
        });
    }
  };

  const ImgChange = (value, type, number) => {
    setCropImage({
      width: 5,
      height: 5,
      type: type,
      number: number,
      image: value,
      status: true,
      scale: true,
    });
  };

  const setImageFromFile = ({ file, setImageUrl }) => {
    let reader = new FileReader();
    reader.onload = function () {
      setImageUrl({ result: reader.result });
    };

    reader.readAsDataURL(file);
  };

  return (
    <>
      <Container>
        <FirstSection>
          <TopDetailSection>
            <TitleAndStatus>
              <Title className="f24">파트너 정보</Title>
              {statusChecker()}
            </TitleAndStatus>

            <DropDownAndSave>
              <DropDown
                data={convert(statusState)}
                setData={setStatusState}
                option={[
                  {
                    id: 1,
                    text: "활성화",
                    value: "1",
                  },
                  {
                    id: 2,
                    text: "비활성화",
                    value: "2",
                  },
                  {
                    id: 3,
                    text: "승인요청",
                    value: "3",
                  },
                ]}
                type={false}
              />
              <SaveButton onClick={() => PartnerSave()} className="f12">
                저장
              </SaveButton>
            </DropDownAndSave>
          </TopDetailSection>

          <PartnerProfileSection>
            <Label
              key={`profile_img`}
              cursor={`pointer`}
              htmlFor={`profile_img`}
              width={`calc(1rem * 98 / 14)`}
              height={`calc(1rem * 98 / 14)`}
              margin={`0 20px 0 0`}
              jc={`flex-end`}
            >
              <input
                onChange={({ target: { files } }) => {
                  if (files.length) {
                    setImageFromFile({
                      file: files[0],
                      setImageUrl: ({ result }) => {
                        ImgChange(result, "profile_img", 0);
                      },
                    });
                  }
                }}
                id={`profile_img`}
                accept="image/*"
                type="file"
              />
              <ProfileImage src={info.profile_img} />
            </Label>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <PartnerProfileLine>
                <PartnerProfileCategory className="f14">
                  파트너 이름
                </PartnerProfileCategory>
                <PartnerProfileBox
                  maxLength={`50`}
                  onChange={(e) => infoChange(e.target.value, "name")}
                  type={`text`}
                  value={info.name}
                />
              </PartnerProfileLine>
              <PartnerProfileLine>
                <PartnerProfileCategory className="f14">
                  전화번호
                </PartnerProfileCategory>
                <PartnerProfileBox
                  maxLength={`13`}
                  onChange={(e) => infoChange(e.target.value, "phone")}
                  type={`text`}
                  value={info.phone}
                />
              </PartnerProfileLine>
            </div>
          </PartnerProfileSection>

          <PartnerInfoSection>
            <PartnerInfoCategory>액티비티 카테고리</PartnerInfoCategory>

            <WrapperRow ai="center" gap="8px" width="100%" jc="space-between">
              <DropDownBig
                width="100%"
                data={category.big}
                type="object"
                setData={setCategory}
                doubleDropDown={true}
                reset={() =>
                  setCategory({
                    big: category.big,
                    small: "소분류 선택",
                  })
                }
                option={[
                  {
                    text: "워터스포츠",
                    value: "워터스포츠",
                  },
                  {
                    text: "윈터스포츠",
                    value: "윈터스포츠",
                  },
                  {
                    text: "실내스포츠",
                    value: "실내스포츠",
                  },
                ]}
                no={false}
                keyName="big"
                defaultText="대분류 선택"
              />

              <DropDownBig
                width="100%"
                type="object"
                data={category.small}
                setData={setCategory}
                option={
                  category.big
                    ? category.big == "워터스포츠"
                      ? [
                          {
                            text: "스쿠버다이빙",
                            value: "스쿠버다이빙",
                          },
                          {
                            text: "프리다이빙",
                            value: "프리다이빙",
                          },
                          {
                            text: "서핑",
                            value: "서핑",
                          },
                          {
                            text: "패들 보드",
                            value: "패들 보드",
                          },
                          {
                            text: "수영 / 스노클링",
                            value: "수영 / 스노클링",
                          },
                        ]
                      : [
                          {
                            text: "스노보드",
                            value: "스노보드",
                          },
                          {
                            text: "스키",
                            value: "스키",
                          },
                        ]
                    : [
                        {
                          text: "사격",
                          value: "사격",
                        },
                        {
                          text: "피트니스",
                          value: "피트니스",
                        },
                      ]
                }
                no={false}
                keyName="small"
                defaultText="소분류 선택"
              />

              <Icon
                width={`calc(1rem * 32 / 14)`}
                height={`calc(1rem * 32 / 14)`}
                cursor={`pointer`}
                src={Add}
                onClick={() =>
                  infoChange(
                    {
                      big_category: category.big,
                      sport: category.small,
                      id: "new",
                    },
                    "sport"
                  )
                }
              />
            </WrapperRow>

            <TagContainer>
              {sport.map((el, idx) => (
                <TagItem key={`${el.big_category}_${el.sport}`}>
                  <Wrapper jc="center" ai="center">
                    {el.big_category ? (
                      <>
                        <TagText className="f13">{el.big_category}</TagText>
                        <Wrapper
                          ai="center"
                          width="1px"
                          margin="0 7px"
                          height="16px"
                          bgc={colors.Gr02}
                        />
                      </>
                    ) : (
                      ""
                    )}

                    <TagText className="f13">{el.sport}</TagText>
                  </Wrapper>

                  <Xbutton src={XIcon} onClick={() => deleteCategory(idx)} />
                </TagItem>
              ))}
            </TagContainer>
          </PartnerInfoSection>

          <div style={{ height: "52px" }} />

          <PartnerInfoSection>
            <PartnerInfoCategory>파트너 소개</PartnerInfoCategory>
            <PartnerIntro
              maxLength={`1000`}
              type={`text`}
              onChange={(e) => infoChange(e.target.value, "info")}
              value={info.info}
              className="f14"
            />
          </PartnerInfoSection>

          <div style={{ height: "52px" }} />

          <PartnerInfoSection>
            <PartnerInfoCategory>강사 경력</PartnerInfoCategory>
            <PartnerCareer>
              <CareerInput
                placeholder="강사 경력을 입력해 주세요 *21자 이내"
                value={careerText}
                onChange={(e) => setCareerText(e.target.value)}
              />
              <CareerButton src={Add} onClick={() => careerAdd()} />
            </PartnerCareer>

            {info.career?.map((el, i) => (
              <PartnerCareer key={`career_${i}`}>
                <PartnerCareerText className="f14">{el.text}</PartnerCareerText>
                <CareerButton src={Delete} onClick={() => careerDelete(i)} />
              </PartnerCareer>
            ))}
          </PartnerInfoSection>

          <div style={{ height: "52px" }} />

          <PartnerInfoSection>
            <PartnerInfoCategory>소속 센터</PartnerInfoCategory>
            <CenterContainer>
              {info.center?.map((el, i) => (
                <CenterItem key={el.center_id}>{el.center_name}</CenterItem>
              ))}
            </CenterContainer>
          </PartnerInfoSection>
        </FirstSection>

        <DividingLine />

        <ScheduleSection
          data={schedule}
          setData={setSchedule}
          newS={newSchedule}
          setNew={setNewSchedule}
          deleteD={deleteD}
          setDelete={setDelete}
          id={info.id}
          modal={modal}
        />

        <DividingLine />

        <RestingSection rest={rest} setRest={setRest} />
      </Container>

      <ImageCrop
        info={cropImage}
        setInfo={setCropImage}
        setImg={setInfo}
        img={info}
      />
    </>
  );
};

export default PartnerDetail;

const ScheduleSection = ({
  data,
  setData,
  newS,
  setNew,
  id,
  modal,
  deleteD,
  setDelete,
}) => {
  const [open, setOpen] = useState(true);

  const [open2, setOpen2] = useState(false);

  let SunTime = [];
  let MoonTime = [];

  let sunTimSplit = newS.start.split(":");
  let moonTimSplit = newS.end.split(":");
  let sunH = Number(sunTimSplit[0]);
  let sunM = Number(sunTimSplit[1]);
  let moonH = Number(moonTimSplit[0]);
  let moonM = Number(moonTimSplit[1]);
  let sunAM = sunH * 60 + sunM;
  let moonAM = moonH * 60 + moonM;

  let TimeCount = ((moonH - sunH) * 60 + (moonM - sunM)) / 30;

  for (var i = 0; i < TimeCount; i++) {
    let ScheduleTime = sunAM + 30 * i;

    let ScheduleHour = parseInt(ScheduleTime / 60);
    let ScheduleMinute = ScheduleTime % 60;

    let ScheduleHourText = ScheduleHour;
    let ScheduleMinuteText = ScheduleMinute;

    if (ScheduleHourText < 10) {
      ScheduleHourText = `0${ScheduleHourText}`;
    }

    if (ScheduleMinuteText == 0 || !ScheduleMinuteText) {
      ScheduleMinuteText = `00`;
    }

    let TimeData = {
      text: `${ScheduleHourText} : ${ScheduleMinuteText}`,
      value: `${ScheduleHourText}:${ScheduleMinuteText}`,
    };

    if (ScheduleHour < 12) {
      SunTime.push(TimeData);
    } else {
      MoonTime.push(TimeData);
    }
  }

  const Change = (type, value) => {
    setNew((prev) => ({ ...prev, [type]: value }));
  };

  const SettingYoil = (value) => {
    var copy = newS.day;

    if (copy.includes(value)) {
      copy = copy.filter((x) => x !== value);
    } else {
      copy.push(value);
    }

    setNew((prev) => ({ ...prev, day: copy }));
  };

  const targetLocationChangeHandler = (data) => {
    var locationSigungu = data.sigungu.split(" ");

    if (locationSigungu.length > 1) {
      var loGu =
        locationSigungu[1].length > 2
          ? locationSigungu[1].slice(0, -1)
          : locationSigungu[1];
      var loSi =
        locationSigungu[0].length > 2
          ? locationSigungu[0].slice(0, -1)
          : locationSigungu[0];
      var loDong = data.bname.length > 2 ? data.bname.slice(0, -1) : data.bname;
    } else {
      var loGu =
        data.sigungu.length > 2 ? data.sigungu.slice(0, -1) : data.sigungu;
      var loSi = data.sido;
      var loDong = data.bname.length > 2 ? data.bname.slice(0, -1) : data.bname;
    }

    setNew((prev) => ({
      ...prev,
      address: data.roadAddress,
      si: loSi,
      gu: loGu,
      dong: loDong,
    }));
  };

  const mapService = new MapService(targetLocationChangeHandler);

  const SearchAddress = () => {
    mapService.open();
  };

  const addPartnerSchedule = () => {
    var schedule_data = [...data];

    schedule_data[schedule_data.length] = newS;

    setData(schedule_data);

    setOpen2(false);
    setNew({
      id: "new",
      title: "",
      start: "",
      end: "",
      address: "",
      extra: "",
      si: "",
      gu: "",
      dong: "",
      day: [],
      time_list: [],
    });
  };

  const SettingStartTime = (value) => {
    var list = newS.time_list;
    if (list.includes(value)) {
      list = list.filter((x) => x != value);
    } else {
      list.push(value);
    }

    setNew((prev) => ({ ...prev, time_list: list }));
  };

  useEffect(() => {
    setOpen2(false);
  }, [modal]);

  return (
    <BigContainer>
      <TopSection className="f16">
        액티비티 스케줄
        <SectionArrow
          src={Arrow}
          open={open}
          onClick={() => setOpen((prev) => !prev)}
        />
      </TopSection>
      {open ? (
        open2 ? (
          <NewCon>
            <BackgroundContent jc={`space-between`} padding={`0`} ai={`center`}>
              <InputView
                onChange={(e) => Change("title", e.target.value)}
                value={`${newS.title}`}
                placeholder={`일정 이름을 작성해 주세요 (예 : [평일] 강남 일정)`}
                br={`4`}
                padding={`calc(1rem * 10 / 14) calc(1rem * 12 / 14)`}
                pc={colors.Gr03}
                color={colors.Gr05}
                width={`80%`}
                border={`1px solid ${colors.Gr02}`}
              />
              <SectionArrow
                src={Arrow}
                open={open}
                onClick={() => setOpen2((prev) => !prev)}
              />
            </BackgroundContent>

            <Divider height={`8`} color={colors.White} />

            <BackgroundContent padding={`0`} ai={`flex-end`}>
              <StartTime>
                <div
                  style={{
                    color: `${colors.Bl06}`,
                    fontWeight: "500",
                    marginBottom: "4px",
                  }}
                >
                  활동시작
                </div>
                <DateBox
                  placeholder="활동 시작 시간 입력"
                  value={newS.start}
                  onChange={(e) => Change("start", e.target.value)}
                />
              </StartTime>

              <Wave src={wave} />

              <EndTime>
                <div
                  style={{
                    color: `${colors.Bl06}`,
                    fontWeight: "500",
                    marginBottom: "4px",
                  }}
                >
                  활동종료
                </div>
                <DateBox
                  placeholder="활동 종료 시간 입력"
                  value={newS.end}
                  onChange={(e) => Change("end", e.target.value)}
                />
              </EndTime>
            </BackgroundContent>

            <Divider height={`8`} color={colors.White} />

            <BackgroundContent jc={`space-between`} padding={`0`} ai={`center`}>
              <TextContent
                onClick={() => SearchAddress()}
                jc={`center`}
                ai={`center`}
                width={`97px`}
                padding={`calc(1rem * 7.5/14) 0`}
                br={`8`}
                border={`1px solid ${colors.Bl07}`}
              >
                <TextView color={colors.Bl07} fw={`600`}>{`주소찾기`}</TextView>
              </TextContent>

              <InputView
                value={newS.address}
                readOnly
                br={`4`}
                padding={`calc(1rem * 6/14) calc(1rem *18/14)`}
                pc={colors.Gr03}
                color={colors.Gr05}
                width={`calc(100% - 112px)`}
                bgc={colors.Gr01}
                border={`1px solid ${colors.Gr02}`}
              />
            </BackgroundContent>

            <Divider height={`8`} color={colors.White} />

            <BackgroundContent jc={`space-between`} padding={`0`} ai={`center`}>
              <InputView
                value={newS.extra}
                maxLength={`100`}
                placeholder={`상세 주소를 입력해 주세요`}
                br={`4`}
                padding={`calc(1rem * 6/14) calc(1rem *18/14)`}
                pc={colors.Gr03}
                color={colors.Gr05}
                width={`100%`}
                onChange={(e) => Change("extra", e.target.value)}
                border={`1px solid ${colors.Gr02}`}
              />
            </BackgroundContent>

            <Divider height={`8`} color={colors.White} />

            <BackgroundContent padding={`0`} ai={`center`}>
              <TextContent
                onClick={(e) => SettingYoil("일")}
                jc={`center`}
                width={`calc(1rem * 40/14)`}
                padding={`calc(1rem * 8/14) 0`}
                br={`8`}
                bgc={
                  newS.day.includes("일")
                    ? `${colors.Red02}`
                    : `${colors.White}`
                }
                border={`1px solid ${colors.Gr02}`}
                mr={"calc(1rem * 9 / 14)"}
              >
                <TextView
                  color={
                    newS.day.includes("일")
                      ? `${colors.White}`
                      : `${colors.Gr03}`
                  }
                  fw={`500`}
                >{`일`}</TextView>
              </TextContent>

              <TextContent
                onClick={(e) => SettingYoil("월")}
                jc={`center`}
                width={`calc(1rem * 40/14)`}
                padding={`calc(1rem * 8/14) 0`}
                br={`8`}
                border={`1px solid ${colors.Gr02}`}
                bgc={
                  newS.day.includes("월") ? `${colors.Bl07}` : `${colors.White}`
                }
                mr={"calc(1rem * 9 / 14)"}
              >
                <TextView
                  color={
                    newS.day.includes("월")
                      ? `${colors.White}`
                      : `${colors.Gr03}`
                  }
                  fw={`500`}
                >{`월`}</TextView>
              </TextContent>

              <TextContent
                onClick={(e) => SettingYoil("화")}
                jc={`center`}
                width={`calc(1rem * 40/14)`}
                padding={`calc(1rem * 8/14) 0`}
                br={`8`}
                bgc={
                  newS.day.includes("화") ? `${colors.Bl07}` : `${colors.White}`
                }
                border={`1px solid ${colors.Gr02}`}
                mr={"calc(1rem * 9 / 14)"}
              >
                <TextView
                  color={
                    newS.day.includes("화")
                      ? `${colors.White}`
                      : `${colors.Gr03}`
                  }
                  fw={`500`}
                >{`화`}</TextView>
              </TextContent>

              <TextContent
                onClick={(e) => SettingYoil("수")}
                jc={`center`}
                width={`calc(1rem * 40/14)`}
                padding={`calc(1rem * 8/14) 0`}
                br={`8`}
                bgc={
                  newS.day.includes("수") ? `${colors.Bl07}` : `${colors.White}`
                }
                border={`1px solid ${colors.Gr02}`}
                mr={"calc(1rem * 9 / 14)"}
              >
                <TextView
                  color={
                    newS.day.includes("수")
                      ? `${colors.White}`
                      : `${colors.Gr03}`
                  }
                  fw={`500`}
                >{`수`}</TextView>
              </TextContent>

              <TextContent
                onClick={(e) => SettingYoil("목")}
                jc={`center`}
                width={`calc(1rem * 40/14)`}
                padding={`calc(1rem * 8/14) 0`}
                br={`8`}
                bgc={
                  newS.day.includes("목") ? `${colors.Bl07}` : `${colors.White}`
                }
                border={`1px solid ${colors.Gr02}`}
                mr={"calc(1rem * 9 / 14)"}
              >
                <TextView
                  color={
                    newS.day.includes("목")
                      ? `${colors.White}`
                      : `${colors.Gr03}`
                  }
                  fw={`500`}
                >{`목`}</TextView>
              </TextContent>

              <TextContent
                onClick={(e) => SettingYoil("금")}
                jc={`center`}
                width={`calc(1rem * 40/14)`}
                padding={`calc(1rem * 8/14) 0`}
                br={`8`}
                bgc={
                  newS.day.includes("금") ? `${colors.Bl07}` : `${colors.White}`
                }
                border={`1px solid ${colors.Gr02}`}
                mr={"calc(1rem * 9 / 14)"}
              >
                <TextView
                  color={
                    newS.day.includes("금")
                      ? `${colors.White}`
                      : `${colors.Gr03}`
                  }
                  fw={`500`}
                >{`금`}</TextView>
              </TextContent>

              <TextContent
                onClick={(e) => SettingYoil("토")}
                jc={`center`}
                width={`calc(1rem * 40/14)`}
                padding={`calc(1rem * 8/14) 0`}
                br={`8`}
                bgc={
                  newS.day.includes("토")
                    ? `${colors.Red02}`
                    : `${colors.White}`
                }
                border={`1px solid ${colors.Gr02}`}
                // mr={"calc(1rem * 9 / 14)"}
              >
                <TextView
                  color={
                    newS.day.includes("토")
                      ? `${colors.White}`
                      : `${colors.Gr03}`
                  }
                  fw={`500`}
                >{`토`}</TextView>
              </TextContent>

              <TextContent
                onClick={(e) => SettingYoil("공휴일")}
                jc={`center`}
                width={`calc(1rem * 40/7)`}
                padding={`calc(1rem * 8/14) 0`}
                br={`8`}
                bgc={
                  newS.day.includes("공휴일")
                    ? `${colors.Red02}`
                    : `${colors.White}`
                }
                border={`1px solid ${colors.Gr02}`}
                mr={"calc(1rem * 9 / 14)"}
              >
                <TextView
                  color={
                    newS.day.includes("공휴일")
                      ? `${colors.White}`
                      : `${colors.Gr03}`
                  }
                  fw={`500`}
                >{`공휴일 포함`}</TextView>
              </TextContent>

              <TextContent
                onClick={(e) => SettingYoil("공휴일")}
                jc={`center`}
                width={`calc(1rem * 40/7)`}
                padding={`calc(1rem * 8/14) 0`}
                br={`8`}
                bgc={
                  !newS.day.includes("공휴일")
                    ? `${colors.Bl07}`
                    : `${colors.White}`
                }
                border={`1px solid ${colors.Gr02}`}
                // mr={"calc(1rem * 9 / 14)"}
              >
                <TextView
                  color={
                    !newS.day.includes("공휴일")
                      ? `${colors.White}`
                      : `${colors.Gr03}`
                  }
                  fw={`500`}
                >{`공휴일 제거`}</TextView>
              </TextContent>
            </BackgroundContent>

            <Divider height={`24`} color={colors.White} />

            <BackgroundContent jc={`flex-start`} padding={`0`} ai={`center`}>
              <TextContent ai={`center`} width={`100%`}>
                <ImageIcon100
                  style={{ marginRight: "6px" }}
                  width={`11px`}
                  height={`11px`}
                >
                  <img src={GreySun} />
                </ImageIcon100>

                <TextView color={colors.Gr03} fw={`500`}>{`오전`}</TextView>
              </TextContent>
            </BackgroundContent>

            <BackgroundContent
              width={`100%`}
              wrap={`wrap`}
              jc={`flex-start`}
              padding={`0`}
              ai={`center`}
            >
              {SunTime.map((item, idx) => (
                <TextContentT
                  index={(idx + 1) % 4}
                  key={`${idx}_sunTime`}
                  onClick={() => SettingStartTime(item.value)}
                  jc={`center`}
                  width={`calc(1rem * 72 / 14)`}
                  padding={`calc(1rem * 8/14) 0`}
                  br={`7`}
                  bgc={
                    newS.time_list.includes(item.value)
                      ? `${colors.Bl07}`
                      : `${colors.White}`
                  }
                  border={`1px solid ${colors.Gr02}`}
                >
                  <TextView
                    color={
                      newS.time_list.includes(item.value)
                        ? `${colors.White}`
                        : `${colors.Gr03}`
                    }
                    fw={`500`}
                  >{`${item.text}`}</TextView>
                </TextContentT>
              ))}
            </BackgroundContent>

            <BackgroundContent jc={`flex-start`} padding={`0`} ai={`center`}>
              <TextContent ai={`center`} width={`100%`}>
                <ImageIcon100
                  style={{ marginRight: "6px" }}
                  width={`11px`}
                  height={`11px`}
                >
                  <img src={GreyMoon} />
                </ImageIcon100>

                <TextView color={colors.Gr03} fw={`500`}>{`오후`}</TextView>
              </TextContent>
            </BackgroundContent>

            <Divider height={`10`} color={colors.White} />

            <BackgroundContent
              width={`100%`}
              wrap={`wrap`}
              jc={`flex-start`}
              padding={`0`}
              ai={`center`}
            >
              {MoonTime.map((item, idx) => (
                <TextContentT
                  // index={(idx + 1) % 4}
                  key={`${idx}_moonTime`}
                  onClick={() => SettingStartTime(item.value)}
                  jc={`center`}
                  width={`calc(1rem * 72 / 14)`}
                  padding={`calc(1rem * 8/14) 0`}
                  br={`7`}
                  bgc={
                    newS.time_list.includes(item.value)
                      ? `${colors.Bl07}`
                      : `${colors.White}`
                  }
                  border={`1px solid ${colors.Gr02}`}
                >
                  <TextView
                    color={
                      newS.time_list.includes(item.value)
                        ? `${colors.White}`
                        : `${colors.Gr03}`
                    }
                    fw={`500`}
                  >{`${item.text}`}</TextView>
                </TextContentT>
              ))}
            </BackgroundContent>

            <BackgroundContent jc={`space-between`} padding={`0`} ai={`center`}>
              <Cancel
                color={colors.Red02}
                onClick={() => {
                  setOpen2(false);
                  setNew({
                    id: "new",
                    title: "",
                    start: "",
                    end: "",
                    address: "",
                    extra: "",
                    day: [],
                    time_list: [],
                  });
                }}
              >
                취소
              </Cancel>
              <Button color={colors.Bl05} onClick={() => addPartnerSchedule()}>
                액티비티 스케줄 추가하기 <Symbol src={Plus} />
              </Button>
            </BackgroundContent>
          </NewCon>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "24px",
            }}
          >
            <Button color={colors.Bl05} onClick={() => setOpen2(true)}>
              액티비티 스케줄 추가하기 <Symbol src={Plus} />
            </Button>
          </div>
        )
      ) : (
        ""
      )}
      {open &&
        data?.map((el, i) => (
          <DesignedActivitySchedule
            data={el}
            key={el.id}
            idx={i}
            setData={setData}
            setDelete={setDelete}
            deleteD={deleteD}
            every={data}
            SearchAddress={SearchAddress}
          />
        ))}
    </BigContainer>
  );
};

const RestingSection = ({ rest, setRest }) => {
  const [open, setOpen] = useState(true);

  return (
    <BigContainer>
      <TopSection className="f16">
        휴무 정보
        <SectionArrow
          src={Arrow}
          open={open}
          onClick={() => setOpen((prev) => !prev)}
        />
      </TopSection>
      {open && rest?.length
        ? rest.map((el, i) => (
            <SingleRest el={el} idx={i} every={rest} setRest={setRest} />
          ))
        : ""}
    </BigContainer>
  );
};

const SingleRest = ({ el, every, idx, setRest }) => {
  const [calender, setCalender] = useState(false);

  const [data, setData] = useState({
    id: el.id,
    start: el.start,
    end: el.end,
    status: el.status,
    date: el.date,
    change: "yes",
  });

  const [date, setDate] = useState({
    y: el.date.split(".")[0],
    m: el.date.split(".")[1],
    d: el.date.split(".")[2],
  });

  useEffect(() => {}, []);

  const DateSelect = (d, month, next) => {
    if (month) {
      let Year = date.y;
      if (next) {
        var Mon = Number(date.m);
      } else {
        var Mon = Number(date.m) - 2;
      }

      let Day = Number(1);

      let NewDate = new Date(Year, Mon, Day);

      let NewYear = NewDate.getFullYear();
      let NewMonth = NewDate.getMonth() + 1;
      let NewDay = NewDate.getDate();

      if (NewMonth < 10) {
        NewMonth = `0${NewMonth}`;
      }

      if (NewDay < 10) {
        NewDay = `0${NewDay}`;
      }

      setDate({
        y: NewYear,
        m: NewMonth,
        d: NewDay,
      });
    } else {
      if (d < 10) {
        d = `0${d}`;
      }

      setDate({ ...date, d: d });
    }
  };

  const statusToggleHandler = (id, status) => {
    var token = localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", "rest_status");
    frm.append("token", token);
    frm.append("id", id);
    frm.append("status", status);

    axios
      .post("https://ai.semos.kr/semos_admin/partner/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        if (Value.status == "success") {
          if (status == "1") {
            alert(Value.status_message);
            setData((prev) => ({ ...prev, status: "0" }));
          } else {
            alert(Value.status_message);
            setData((prev) => ({ ...prev, status: "1" }));
          }
        } else {
          alert(Value.status_message);
        }
      });
  };

  useEffect(() => {
    setCalender(false);
  }, [date.d]);

  const ChangeCalendar = (type, value) => {
    var copy = [...every];
    var copyValue = copy[idx];

    copyValue[type] = value;
    copy[idx] = copyValue;

    setRest(copy);
  };

  return (
    <>
      <RestItem>
        <RestTimeSection>
          <DateDiv>
            <DateBox2
              onChange={(e) => ChangeCalendar("date", e.target.value)}
              value={el.date}
            />
            <Cal src={BlackCalender} />
          </DateDiv>

          <div style={{ width: "12px" }} />

          <StartTime>
            <div
              style={{
                color: `${colors.Bl06}`,
                fontWeight: "500",
                marginBottom: "4px",
              }}
            >
              휴무시작
            </div>
            <DateBox
              onChange={(e) => ChangeCalendar("start", e.target.value)}
              value={el.start}
            />
          </StartTime>

          <Wave src={wave} />

          <EndTime>
            <div
              style={{
                color: `${colors.Bl06}`,
                fontWeight: "500",
                marginBottom: "4px",
              }}
            >
              휴무종료
            </div>
            <DateBox
              onChange={(e) => ChangeCalendar("end", e.target.value)}
              value={el.end}
            />
          </EndTime>
        </RestTimeSection>

        <ToggleButton
          toggleHandler={() => statusToggleHandler(el.id, el.status)}
          status={el.status}
          type="rest"
        />
      </RestItem>
      <div style={{ height: "calc(1rem * 24/14)" }} />
    </>
  );
};

const Container = styled.div``;

const FirstSection = styled.div`
  padding: 36px;
  padding-bottom: 52px;
`;

const TopDetailSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 31px;
`;

const TitleAndStatus = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 700;
  color: ${colors.Gr05};
  margin-right: 10px;
`;

const StatusBox = styled.div`
  padding: 3px 14px;
  border: 1px solid;
  border-color: ${({ bc }) => bc};
  background-color: ${({ bgc }) => bgc};
  color: ${({ c }) => c};
  border-radius: 16px;
  display: flex;
  align-items: center;
`;

const DropDownAndSave = styled.div`
  display: flex;
  align-items: center;
`;

const SaveButton = styled.div`
  background-color: ${colors.Bl07};
  padding: 6.5px 12px;
  font-weight: 500;
  color: ${colors.White};
  border-radius: 8px;
  cursor: pointer;
`;

const PartnerProfileSection = styled.div`
  display: flex;
  /* width: 100%; */
  margin-bottom: 45px;
`;

const ProfileImage = styled.img`
  width: calc(1rem * 98 / 14) !important;
  height: calc(1rem * 98 / 14) !important;
  border-radius: 50%;
`;

const PartnerProfileLine = styled.div`
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
  align-items: center;
  /* margin-bottom: 12px; */
`;

const PartnerProfileCategory = styled.div`
  font-weight: 500;
  color: ${colors.Gr05};
  width: calc(1rem * 80 / 14);
  min-width: calc(1rem * 80 / 14);
`;

const PartnerProfileBox = styled.input`
  border: 1px solid ${colors.Gr02};
  padding: 8px 16px;
  font-weight: 500;
  color: ${colors.Gr05};
  width: 100%;
`;

const PartnerInfoSection = styled.div``;

const PartnerInfoCategory = styled.div`
  font-weight: 700;
  font-size: calc(1rem * 16 / 14);
  margin-bottom: 14px;
`;

const PartnerIntro = styled.textarea`
  border: 1px solid ${colors.Gr02};
  padding: 16px;
  font-weight: 500;
  resize: none;
  width: 100%;
  height: 200px;
`;

const PartnerCareer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const PartnerCareerText = styled.div`
  padding: 0 16px;
  border: 1px solid ${colors.Gr02};
  width: 100%;
  height: calc(1rem * 34 / 14);
  display: flex;
  align-items: center;
  color: ${colors.Gr05};
  font-weight: 500;
`;

const DividingLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.Gr02};
`;

const CareerButton = styled.img`
  width: calc(1rem * 36 / 14);
  height: calc(1rem * 36 / 14);
  margin-left: 8px;
  cursor: pointer;
`;

const CareerInput = styled.input`
  width: 100%;
  height: calc(1rem * 34 / 14);
  border: 1px solid ${colors.Gr02};
  padding: 0 16px;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: ${colors.Gr05};
`;

const CenterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const CenterItem = styled.div`
  width: 32%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: calc(1rem * 36 / 14) 0;
  color: ${colors.Bl06};
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
  font-weight: 500;
`;

const BigContainer = styled.div`
  padding: 36px;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  color: ${colors.Gr05};
  margin-bottom: 16px;
`;

const SectionArrow = styled.img`
  width: calc(1rem * 18 / 14);
  height: calc(1rem * 18 / 14);
  transform: ${({ open }) => (open ? "rotate(-180deg)" : "")};
  transition: all 0.3s ease;
  cursor: pointer;
`;

const RestItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const RestTimeSection = styled.div`
  display: inline-flex;
  align-items: flex-end;
  position: relative;
  width: calc(100% - (1rem * 52 / 14));
`;

const Cal = styled.img`
  width: calc(1rem * 20 / 14);
  height: calc(1rem * 20 / 14);
  margin-left: 4px;
`;

const TagContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  /* margin-top: 10px; */
`;

const TagItem = styled.div`
  position: relative;
  border: 1.2px solid ${colors.Gr02};
  padding: 6px 12px;
  display: flex;
  align-items: center;
  background-color: ${colors.Gr01};
  border-radius: 24px;
  margin-right: 9px;
  margin-top: 8px;
`;

const TagText = styled.span`
  font-weight: 500;
  /* align-items: center; */
  /* text-align: center; */
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  max-width: ${W - 64}px;
  word-break: break-all;
  text-overflow: ellipsis;
`;

const Xbutton = styled.img`
  /* position: absolute;
  right: 6px;
  top: 6px; */
  width: calc(1rem * 12 / 14);
  height: calc(1rem * 18 / 14);
  margin-left: 4px;
`;

const Button = styled.div`
  border: 1px solid ${({ color }) => color};
  color: ${({ color }) => color};
  padding: calc(1rem * 7 / 14) calc(1rem * 12 / 14);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
`;

const Symbol = styled.img`
  width: 1rem;
  height: 1rem;
  margin-left: calc(1rem * 4 / 14);
`;

const BackgroundContent = styled.div`
  width: 100%;
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  position: relative;
`;

const TextContent = styled.div`
  width: ${({ width }) => width && `${width}`};
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
  margin-right:${({ mr }) => mr && `${mr}`};
`;

const TextContentT = styled.div`
  width: ${({ width }) => width && `${width}`};
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
  margin-right : 9px;
  margin-bottom: 9px;
`;

const TextView = styled.span`
  width: ${({ width }) => width && `${width}`};
  text-align: ${({ ta }) => ta && `${ta}`};
  color: ${({ color }) => color && `${color}`};
  font-weight: ${({ fw }) => fw && `${fw}`};
  font-size: ${({ fs }) => fs && `${fs}`};
  line-height: ${({ lh }) => lh && `${lh}`};
  display: inline-block;
  text-overflow: ${({ to }) => to && `${to}`};
  overflow: hidden;
  white-space: ${({ ws }) => ws && `${ws}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
`;

const InputView = styled.input`
  width: ${({ width }) => width && `${width}`};
  border: ${({ border }) => border && `${border}`};
  text-align: ${({ ta }) => ta && `${ta}`};
  color: ${({ color }) => color && `${color}`};
  font-weight: ${({ fw }) => fw && `${fw}`};
  font-size: ${({ fs }) => fs && `${fs}`};
  line-height: ${({ lh }) => lh && `${lh}`};
  border-radius: ${({ br }) => br && `${br}px`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  display: inline-block;
  overflow: hidden;
  padding: ${({ padding }) => padding && `${padding}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
  ::placeholder {
    color: ${({ pc }) => pc && `${pc}`};
  }
  ::-ms-input-placeholder {
    color: ${({ pc }) => pc && `${pc}`};
  }
`;

const Divider = styled.div`
  height: ${({ height }) => height && `${height}px`};
  background-color: ${({ color }) => color && `${color}`};
  width: 100%;
`;

const StartTime = styled.div``;

const EndTime = styled.div``;

const Wave = styled.img`
  width: 2rem;
  height: 2rem;
`;

const DateBox = styled.input`
  display: flex;
  align-items: center;
  padding: calc(1rem * 8 / 14) calc(1rem * 8 / 14);
  border: 1px solid ${colors.Gr02};
  font-weight: 500;
  line-height: 100% !important;
  height: calc(1rem * 28 / 14);
`;

const DateBox2 = styled.input`
  display: flex;
  align-items: center;
  padding: calc(1rem * 8 / 14) calc(1rem * 5 / 14);
  font-weight: 500;
  line-height: 100% !important;
  height: calc(1rem * 28 / 14);
`;

const Cancel = styled.div`
  color: ${({ color }) => color};
  font-weight: 500;
`;

const NewCon = styled.div`
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
  padding: calc(1rem * 24 / 14) calc(1rem * 16 / 14);
  margin-bottom: 10px;
`;

const DateDiv = styled.div`
  display: flex;
  align-items: center;
  padding: calc(1rem * 8 / 14) calc(1rem * 8 / 14);
  border: 1px solid ${colors.Gr02};
  font-weight: 500;
  line-height: 100% !important;
  height: calc(1rem * 28 / 14);
  width: calc((100% - (2rem + 22px)) / 3);

  input {
    width: calc((100% - (1rem * 22 / 14)));
  }
`;

const ImageIcon100 = styled.div`
  width: ${({ width }) => width && `${width}`};
  height: ${({ height }) => height && `${height}`};
  display: inline-block;

  img {
    width: ${({ width }) => width && `${width}`};
    display: inline-block;
  }
`;
