import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import colors from "../../styles/constants/colors";

//img
import Arrow from "../../assets/icon/selectArrow.png";

const DropDownCenter = ({
  data,
  setData,
  option,
  type,
  defaultText,
  no,
  keyName,
  width,
}) => {
  const [open, setOpen] = useState(false);

  const ref = useRef();

  const handleClose = (e) => {
    if (open && (!ref.current || !ref.current.contains(e.target))) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, [open]);

  const changeValue = (value) => {
    if (type == "object") {
      setData((prev) => ({ ...prev, [keyName]: value }));
    } else if (type == "sport" || type == "category") {
      setData(type, value);
    } else {
      setData(value);
    }
    setOpen(false);
  };

  return (
    <SelectBoxDiv onBlur={() => setOpen(false)} ref={ref} width={width}>
      <SelectBox
        className="f13"
        no={no}
        onClick={() => setOpen((prev) => !prev)}
        width={width}
      >
        {data ? data : defaultText}
        <SelectIcon src={Arrow} open={open} />
      </SelectBox>
      {open && option && (
        <OptionContainer>
          {option.map((el, i) => (
            <Option
              className="f12"
              key={el.text}
              last={option.length - 1 == i}
              onClick={() => changeValue(el.value)}
            >
              {el.text}
            </Option>
          ))}
        </OptionContainer>
      )}
    </SelectBoxDiv>
  );
};

export default DropDownCenter;

const SelectBoxDiv = styled.div`
  position: relative;
  cursor: pointer;
  ${({ width }) => width && `width : ${width};`}
`;

const SelectBox = styled.div`
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.Gr02};
  margin-right: ${({ no }) => (no ? 0 : 12)}px;
  line-height: 100%;
  background-color: ${colors.White};
  min-width: 7rem !important;
  white-space: nowrap;
  ${({ width }) => width && `width : ${width};`}
`;

const SelectIcon = styled.img`
  width: calc(1rem * 17 / 14);
  height: calc(1rem * 17 / 14);
  margin-left: 8px;

  transform: ${({ open }) => (open ? "rotate(-180deg)" : "")};
  transition: all 0.7s;
`;

const OptionContainer = styled.div`
  width: 100%;
  min-width: 7rem;
  position: absolute;
  bottom: 1px;
  transform: translate(0, 100%);
  left: 0;
  z-index: 2;
  border: 1px solid ${colors.Gr02};
  background-color: ${colors.White};
`;

const Option = styled.div`
  padding: 8px 16px;
  font-weight: 500;
  color: ${colors.Gr05};
  border-bottom: ${({ last }) => (last ? "none" : `1px solid ${colors.Gr02}`)};
  display: flex;
  align-items: center;
  justify-content: center;
`;
