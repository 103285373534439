import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useNavigate, useParams, Link } from "react-router-dom";

// components
import DesignedMainHeader from "../../components/designedHeader";
import DesignedMainLayout from "../../layouts/designedMainLayout";
import Table from "../../layouts/table";
import DrawerModal from "../../components/drawerModal";
import DropDown from "../../components/designedComponents/dropDown";
import SalesDetail from "../../components/designedSales/salesDetail";
import {
    TableFilterContainer,
    TableFilterContent,
    TableWrapper,
    TableWrapper2,
    Wrapper,
    AbsoluteWrapper,
    Icon,
    Text,
    TableContent,
    TableFooter,
    TableColumn,
    TableFooterPaging,
} from "../../layouts/layoutStorage";

// icon
import DoublePaging from "../../assets/icon/double_paging.png";
import OnePaging from "../../assets/icon/one_paging.png";
import ReservationCheck from "../../assets/icon/reservation_check.png";
import ReservationConfirm from "../../assets/icon/reservation_confirm.png";
import ReservationCancel from "../../assets/icon/reservation_cancel.png";
import ReservationClear from "../../assets/icon/reservation_clear.png";
import ReservationDone from "../../assets/icon/reservation_done.png";
import colors from "../../styles/constants/colors";

// import ArrowIcon from "../../assets/icon/arrow_black.png";

const Calculate = () => {
    const { p, y, m, n } = useParams();
    const navigate = useNavigate();

    // desinged height
    const [Height, setHeight] = useState({
        TableFilter: 0,
        TableHeader: 0,
        TableFooter: 0,
        Layout: 0,
        LayoutHeader: 0,
    });

    useEffect(() => {
        setTimeout(() => {
        const Layout = document.getElementById("Layout").offsetHeight - 36;
        const LayoutHeader = document.getElementById("LayoutHeader").offsetHeight + 65;
        const TableFilter = document.getElementById("TableFilter").offsetHeight + 19;
        const TableHeader = document.getElementById("TableHeader").offsetHeight;
        const TableFooter = document.getElementById("TableFooter").offsetHeight;

        setHeight({
            TableFilter: TableFilter,
            TableHeader: TableHeader,
            TableFooter: TableFooter,
            Layout: Layout,
            LayoutHeader: LayoutHeader,
        });
        }, 500);
    }, []);

    // filter
    const [filter, setFilter] = useState({
        partner : (p !== "all") ? p : "",
        year : y,
        month : m
    });

    const [page, setPage] = useState(n);
    const [popup, setPopup] = useState(false);

    // data
    const [payment, setPayment] = useState("");
    const [paymentCount, setPaymentCount] = useState(0);
    const [token, setToken] = useState();
    const [amountList, setAmountList] = useState();

    const loadData = () => {
        var token = window.localStorage.getItem("token");

        const frm = new FormData();
        frm.append("mode", "calculate_update");
        frm.append("token", token);
        frm.append("page", n);
        frm.append("partner", p);
        frm.append("month", m);
        frm.append("year", y);

        axios
        .post("https://ai.semos.kr/semos_admin/payment/data", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;

            if (Status == "success") {
                setPayment(Value.payment_list);
                setPaymentCount(Value.payment_count);
                setToken(token);

                setAmountList(Value.partner_price);
            } else if (Status == "waring") {
                window.alert(StatusMessage);
            }
        });
    };

    useEffect(() => {
        loadData();
    }, []);

    const PageInput = (num) => {
        if (num) {
        var token = window.localStorage.getItem("token");

        const frm = new FormData();
        frm.append("mode", "calculate_update");
        frm.append("token", token);
        frm.append("page", num);
        frm.append("partner", filter?.partner);
        frm.append("month", filter?.month);
        frm.append("year", filter?.year);

        axios
            .post("https://ai.semos.kr/semos_admin/payment/data", frm, {
            headers: { "Content-Type": "multipart/form-data" },
            })
            .then((data) => {
            var Value = data.data;
            var status = Value.status;
            var statusMessage = Value.status_message;

            if (status == "success") {
                setPayment(Value.payment_list);
                setPaymentCount(Value.payment_count);
                setPage(num);
                navigate(
                    `/calculate/${num}/${filter.partner ? filter.partner : "all"}/${filter?.year}/${filter?.month}`
                );

                setAmountList(Value.partner_price);
            } else if (status == "waring") {
                alert(statusMessage);
            }
            });
        }
    };

    const numPages = Math.ceil(paymentCount / 12);
    let PageList = [];

    if (page < 11) {
        for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
            PageList.push(i + 1);
        }
    } else {
        if (numPages - 10 < page) {
            var startPage = numPages - 19;
            if (startPage < 1) {
                startPage = 1;
            }

            for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
                PageList.push(startPage + i);
            }
        } else {
        var startPage = page - 9;
            if (startPage < 1) {
                startPage = 1;
            }

            for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
                PageList.push(startPage + i);
            }
        }
    }

    const StatusComponent = (status) => {
        switch (status) {
        case "예약확정":
            return (
            <Wrapper bgc={`#66A2FF`} padding={`4px 10px`} radius={`16px`}>
                <Icon margin={`0 2px 0 0`} width={`calc(1rem * (20 / 14))`} src={ReservationCheck} />
                <Text color={`#ffffff`} weight={`500`}>
                    {`${status}`}
                </Text>
            </Wrapper>
            );

        case "결제완료":
            return (
            <Wrapper bgc={`#A9BA82`} padding={`4px 10px`} radius={`16px`}>
                <Icon margin={`0 2px 0 0`} width={`calc(1rem * (20 / 14))`} src={ReservationConfirm} />
                <Text color={`#ffffff`} weight={`500`}>
                {`${status}`}
                </Text>
            </Wrapper>
            );

        case "정산 완료":
            return (
            <Wrapper bgc={`#E5F0FF`} padding={`4px 10px`} radius={`16px`}>
                <Icon margin={`0 2px 0 0`} width={`calc(1rem * (20 / 14))`} src={ReservationClear} />
                <Text color={`#80B2FF`} weight={`500`}>
                {`${status}`}
                </Text>
            </Wrapper>
            );

        case "액티비티 완료":
            return (
            <Wrapper bgc={`#E5F0FF`} padding={`4px 10px`} radius={`16px`}>
                <Icon margin={`0 2px 0 0`} width={`calc(1rem * (20 / 14))`} src={ReservationDone} />
                <Text color={`#80B2FF`} weight={`500`}>
                {`${status}`}
                </Text>
            </Wrapper>
            );

        case "환불 및 취소":
            return (
            <Wrapper bgc={`#FFE4E1`} padding={`4px 10px`} radius={`16px`}>
                <Icon margin={`0 2px 0 0`} width={`calc(1rem * (20 / 14))`} src={ReservationCancel} />
                <Text color={`#FF9187`} weight={`500`}>
                {`${status}`}
                </Text>
            </Wrapper>
            );

        default :
            return (
                <></>
            )
        }
    };

    const TextSplit = (text) => {
        if (!text?.length || text == "all") {
            return "all";
        } else {
            var value = "";

            for (var i = 0; i < text.length; i++) {
                if (i == 0) {
                    value = `${text[i]}`;
                } else {
                    value = `${value},${text[i]}`;
                }
            }
            return value;
        }
    };

    const PaymentType = () => {

        var token = window.localStorage.getItem("token");

        const frm = new FormData();
        frm.append("mode", "calculate_update");
        frm.append("token", token);
        frm.append("page", "1");
        frm.append("partner", filter?.partner);
        frm.append("month", filter?.month);
        frm.append("year", filter?.year);

        axios
        .post("https://ai.semos.kr/semos_admin/payment/data", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var status = Value.status;
            var statusMessage = Value.status_message;

            if (status == "success") {
                setPayment(Value.payment_list);
                setPaymentCount(Value.payment_count);
                setPage("1");
                setPopup(false);

                navigate(
                    `/calculate/${page}/${filter.partner ? filter.partner : "all"}/${filter?.year}/${filter?.month}`
                );

                setAmountList(Value.partner_price);
            } else if (status == "waring") {
                alert(statusMessage);
            }
        });

    }

    useEffect(() => {
        if(token) {
            PaymentType();
        }
    }, [filter?.partner, filter?.month, filter?.year]);

    return (
        <DesignedMainLayout>
            <DesignedMainHeader title={`정산 내역`} subtitle={`전체 가격 : ${amountList?.all_price_text}원 | (마우스 올릴 경우 : 파란색 - 파트너 상세 페이지 이동 / 빨간색 - 내용 복사 / 초록색 - 이미지 열기)`} />

            <Table>
                <TableFilterContainer id="TableFilter" margin={`0 0 19px 0`}>
                    <Wrapper width={`50%`} jc={`flex-start`}>

                        <DropDown
                            data={filter?.partner}
                            setData={setFilter}
                            option={[
                                { text: "파트너 전체", value: "파트너 전체" }
                            ]}
                            type={`object`}
                            defaultText={`파트너 전체`}
                            no={false}
                            keyName={`partner`}
                        />

                        <DropDown
                            data={filter?.year}
                            setData={setFilter}
                            option={[
                                { text: "2022년", value: "2022" },
                                { text: "2023년", value: "2023" },
                                { text: "2024년", value: "2024" },
                                { text: "2025년", value: "2025" },
                                { text: "2026년", value: "2026" },
                                { text: "2027년", value: "2027" },
                                { text: "2028년", value: "2028" },
                                { text: "2029년", value: "2029" },
                                { text: "2030년", value: "2030" },
                                { text: "2031년", value: "2031" },
                                { text: "2032년", value: "2032" }
                            ]}
                            type={`object`}
                            defaultText={`2024년`}
                            no={false}
                            keyName={`year`}
                        />

                        <DropDown
                            data={filter?.month}
                            setData={setFilter}
                            option={[
                                { text: "01월", value: "01" },
                                { text: "02월", value: "02" },
                                { text: "03월", value: "03" },
                                { text: "04월", value: "04" },
                                { text: "05월", value: "05" },
                                { text: "06월", value: "06" },
                                { text: "07월", value: "07" },
                                { text: "08월", value: "08" },
                                { text: "09월", value: "09" },
                                { text: "10월", value: "10" },
                                { text: "11월", value: "11" },
                                { text: "12월", value: "12" }
                            ]}
                            type={`object`}
                            defaultText={`01월`}
                            no={false}
                            keyName={`month`}
                        />
                    </Wrapper>

                    <Wrapper width={`50%`} jc={`flex-end`}>

                    </Wrapper>
                </TableFilterContainer>

                <TableContent height={Height.Layout - (Height.LayoutHeader + Height.TableFilter + Height.TableFooter)} contentHeight={Height.TableHeader}>
                <section id="TableHeader">
                    <TableColumn ta={`left`} width={`12`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        파트너명
                    </TableColumn>

                    <TableColumn width={`7`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        구독 파트너
                    </TableColumn>

                    <TableColumn width={`7`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        완료된 예약 
                    </TableColumn>

                    <TableColumn width={`12`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        정산 전 가격
                    </TableColumn>

                    <TableColumn width={`12`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        사업자 가격
                    </TableColumn>

                    {/*<TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        프리랜서 가격
                    </TableColumn>*/}

                    <TableColumn width={`9`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        은행
                    </TableColumn>

                    <TableColumn width={`12`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        계좌번호
                    </TableColumn>

                    <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        통장사본
                    </TableColumn>

                    <TableColumn width={`9`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        신분증
                    </TableColumn>

                    <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        사업자등록증
                    </TableColumn>

                </section>

                <section>
                    {payment
                    ? payment.map((item, idx) => (
                            <TableWrapper2
                                cursor={`pointer`}
                                key={`${idx}_reservation`}
                                jc={`flex-start`}
                                bl={`1px solid transparent`}
                                br={`1px solid transparent`}
                                bb={`1px solid ${colors.Gr02}`}
                                bt={`1px solid transparent`}
                                width={`100%`}
                                bgc={`#ffffff`}
                            >
                                <TableColumn className={'Func'} onClick={() => window.open(`/center_detail/${item.center_id}`, "_blank")} ta={`left`} width={`12`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                                    {`${item.name}`}
                                </TableColumn>

                                <TableColumn className={'Func'} onClick={() => window.open(`/center_detail/${item.center_id}`, "_blank")} width={`7`} weight={`500`} br={`1px solid ${colors.Gr02}`} color={(item.subscribe === true) ? colors.Bl07 : colors.Red03}>
                                    {`${(item.subscribe === true) ? "YES" : "NO"}`}
                                </TableColumn>

                                <TableColumn className={'Func'} onClick={() => window.open(`/center_detail/${item.center_id}`, "_blank")} width={`7`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                                    {`${item.count}개`}
                                </TableColumn>

                                <TableColumn className={'Copy'} onClick={() => navigator.clipboard.writeText(`${item.amount}`)} width={`12`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                                    {`${item.amount_text}원`}
                                </TableColumn>

                                <TableColumn className={'Copy'} onClick={() => navigator.clipboard.writeText(`${item.business_price}`)} width={`12`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                                    {`${item.business_price_text}원`}
                                </TableColumn>

                                {/*<TableColumn className={'Copy'} onClick={() => navigator.clipboard.writeText(`${item.free_price}`)} width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                                    {`${item.free_price_text}원`}
                                </TableColumn>*/}

                                <TableColumn className={'Copy'} onClick={() => navigator.clipboard.writeText(`${item.bank}`)} width={`9`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                                    {`${item.bank ? item.bank : "미입력"}`}
                                </TableColumn>

                                <TableColumn className={'Copy'} onClick={() => navigator.clipboard.writeText(`${item.account_number}`)} width={`12`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                                    {`${item.account_number ? item.account_number : '미입력'}`}
                                </TableColumn>

                                <TableColumn className={'Open'} onClick={() => window.open(`${item.account_card}`, "_blank")} width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                                    {`${item.account_card ? item.account_card : '미입력'}`}
                                </TableColumn>

                                <TableColumn className={'Open'} onClick={() => window.open(`${item.id_card}`, "_blank")} width={`9`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                                    {`${item.id_card ? item.id_card : '미입력'}`}
                                </TableColumn>

                                <TableColumn className={'Open'} onClick={() => window.open(`${item.business_card}`, "_blank")} width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                                    {`${item.business_card ? item.business_card : '미입력'}`}
                                </TableColumn>

                            </TableWrapper2>
                        ))
                    : ""}

                    <TableFooter id="TableFooter">
                    <Icon
                        margin={`0 16px 0 0`}
                        width={`calc(1rem * (20 / 14))`}
                        cursor={`pointer`}
                        src={DoublePaging}
                        onClick={() => {
                        if (Number(page) < 11) {
                            PageInput(1);
                        } else {
                            PageInput(Number(page) - 10);
                        }
                        }}
                    />
                    <Icon
                        margin={`0 16px 0 0`}
                        width={`calc(1rem * (20 / 14))`}
                        cursor={`pointer`}
                        src={OnePaging}
                        onClick={() => {
                        if (page > 1) {
                            PageInput(n - 1);
                        } else {
                            window.alert("첫번째 페이지 입니다.");
                        }
                        }}
                    />

                    {PageList.length
                        ? PageList.map((item, idx) => (
                            <TableFooterPaging
                            key={`paging_number_${idx}`}
                            margin={`0 16px 0 0`}
                            color={`${n == item ? "#3383FF" : "#ACAFB5"}`}
                            onClick={() => PageInput(item)}
                            >
                            {item}
                            </TableFooterPaging>
                        ))
                        : ""}

                    <Icon
                        margin={`0 16px 0 0`}
                        width={`calc(1rem * (20 / 14))`}
                        cursor={`pointer`}
                        transform={`rotate(180deg)`}
                        src={OnePaging}
                        onClick={() => {
                        if (n < numPages) {
                            PageInput(Number(n) + 1);
                        } else {
                            window.alert("마지막 페이지 입니다.");
                        }
                        }}
                    />
                    <Icon
                        width={`calc(1rem * (20 / 14))`}
                        cursor={`pointer`}
                        transform={`rotate(180deg)`}
                        src={DoublePaging}
                        onClick={() => {
                        if (Number(page) + 10 > numPages) {
                            PageInput(numPages);
                        } else {
                            PageInput(Number(page) + 10);
                        }
                        }}
                    />
                    </TableFooter>
                </section>

                <TableFooter id="TableFooter" style={{ display: "none" }}>
                    <Icon
                    margin={`0 16px 0 0`}
                    width={`calc(1rem * (20 / 14))`}
                    cursor={`pointer`}
                    src={DoublePaging}
                    onClick={() => {
                        if (Number(page) < 11) {
                        PageInput(1);
                        } else {
                        PageInput(Number(page) - 10);
                        }
                    }}
                    />
                    <Icon
                    margin={`0 16px 0 0`}
                    width={`calc(1rem * (20 / 14))`}
                    cursor={`pointer`}
                    src={OnePaging}
                    onClick={() => {
                        if (page > 1) {
                        PageInput(n - 1);
                        } else {
                        window.alert("첫번째 페이지 입니다.");
                        }
                    }}
                    />

                    {PageList.length
                    ? PageList.map((item, idx) => (
                        <TableFooterPaging
                            key={`paging_number_${idx}`}
                            margin={`0 16px 0 0`}
                            color={`${n == item ? "#3383FF" : "#ACAFB5"}`}
                            onClick={() => PageInput(item)}
                        >
                            {item}
                        </TableFooterPaging>
                        ))
                    : ""}

                    <Icon
                    margin={`0 16px 0 0`}
                    width={`calc(1rem * (20 / 14))`}
                    cursor={`pointer`}
                    transform={`rotate(180deg)`}
                    src={OnePaging}
                    onClick={() => {
                        if (n < numPages) {
                        PageInput(Number(n) + 1);
                        } else {
                        window.alert("마지막 페이지 입니다.");
                        }
                    }}
                    />
                    <Icon
                    width={`calc(1rem * (20 / 14))`}
                    cursor={`pointer`}
                    transform={`rotate(180deg)`}
                    src={DoublePaging}
                    onClick={() => {
                        if (Number(page) + 10 > numPages) {
                        PageInput(numPages);
                        } else {
                        PageInput(Number(page) + 10);
                        }
                    }}
                    />
                </TableFooter>
                </TableContent>
            </Table>
        </DesignedMainLayout>
    );
};

export default Calculate;

const Input = styled.input`
    width: ${({ width }) => `${width}`};
    display: inline-block;
`;
