import styled from "styled-components";
import base from "../styles/constants/base";
import typography from "../styles/constants/typography";

const Header = styled.header`
  width: 100%;
  padding: 0 ${base.spacing.main}px;
  display: flex;
  align-items: center;
  position : relative;

  h1 {
    font-size: ${typography.size.large}px;
  }

  p {
    font-size: ${typography.size.small}px;
    margin-top: 5px;
  }
`;

const Div = styled.div`
  flex-shrink: 0;
`;

const MainHeader = ({ title, subtitle, children }) => {
  return (
    <Header>
      <Div>
        <h1>{title}</h1>
        <p>{subtitle}</p>
      </Div>

      {children}
    </Header>
  );
};

export default MainHeader;
