import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";

import colors from "../../styles/constants/colors";

//img
import FaqQ from "../../assets/icon/faqQ.png";
import FaqA from "../../assets/icon/faqA.png";
import GreyPlus from "../../assets/icon/grayPlus.png";
import SearchIcon from "../../assets/icon/search.png";
import BluePlus from "../../assets/icon/bluePlus.png";
import XIcon from "../../assets/icon/tagDelete.png";
import Delete from "../../assets/icon/careerDeleteButton.png";
import AddButton from "../../assets/icon/careerAddButton.png";

const W = window.innerWidth;

function ActivityIntroInput({
  open: detailOpen,
  faq,
  setData,
  bigData,
  textRecommend,
  red,
}) {
  const ref = useRef();

  const [faqContent, setFaqContent] = useState({
    id: "new",
    q: "",
    a: "",
  });

  const [open, setOpen] = useState(false);

  const curriculumContentSet = (text) => {
    ref.current.style.height = "auto";
    ref.current.style.height = ref.current.scrollHeight + "px";
    setFaqContent((prev) => ({ ...prev, a: text }));
  };

  const settingKeyWords = () => {
    setFaqContent((prev) => ({ ...prev, q: faqContent.q }));
    setOpen(false);
  };

  const settingKeyWordsRecommend = (value) => {
    setOpen(false);
    setFaqContent((prev) => ({ ...prev, q: value }));
  };

  const addItem = () => {
    var copy = [...faq];
    var cop = faqContent;
    if (faqContent.q && faqContent.a) {
      copy.push(cop);
      setData((prev) => ({ ...prev, faq: copy }));
      setFaqContent({
        id: "new",
        q: "",
        a: "",
      });
      ref.current.style.height = "auto";
    } else {
      alert("입력하지 않은 항목이 있습니다.");
    }
  };

  const SearchKeyword = (value, category) => {
    setFaqContent((prev) => ({ ...prev, q: value }));

    if (value.length > 1) {
      bigData(category, value);
    }
  };

  useEffect(() => {
    setFaqContent({
      id: "new",
      q: "",
      a: "",
    });
  }, [detailOpen]);

  return (
    <Container>
      <FaqLine>
        <FaqIcon src={FaqQ} />
        <TagInputDiv red={red == "empty"}>
          <Sharp value={faqContent.q}>#</Sharp>
          <TagInput
            placeholder="태그로 검색해 보세요. 예) 뭘 입고 가야하나요"
            value={faqContent.q}
            onChange={(e) => SearchKeyword(e.target.value, "faq")}
            onFocus={() => setOpen(true)}
            onBlur={() =>
              setTimeout(() => {
                setOpen(false);
              }, 200)
            }
          />
        </TagInputDiv>
      </FaqLine>
      {faqContent.q && open && (
        <Styler>
          <SearchBox>
            <SearchList>
              {textRecommend.faq &&
                textRecommend.faq.map((el, idx) => (
                  <SearchItem
                    onClick={() => settingKeyWordsRecommend(el)}
                    key={`${idx}_faq_search`}
                  >
                    <SearchText>{el}</SearchText>
                    <PlusIcon src={GreyPlus} />
                  </SearchItem>
                ))}
            </SearchList>
            <AddSearchItem onClick={() => settingKeyWords()}>
              <AddSearchText>{faqContent.q} 항목 추가하기</AddSearchText>
              <PlusIcon src={BluePlus} />
            </AddSearchItem>
          </SearchBox>
        </Styler>
      )}
      <FaqLine noAlign={true}>
        <FaqIcon src={FaqA} />
        <CurriculumContent red={red == "empty"}>
          <CurriculumContentInput
            ref={ref}
            rows="1"
            placeholder="답변 내용을 작성해 주세요"
            value={faqContent.a}
            onChange={(e) => curriculumContentSet(e.target.value)}
          />
        </CurriculumContent>
        <Button src={AddButton} onClick={() => addItem()} />
      </FaqLine>
    </Container>
  );
}

export default ActivityIntroInput;

const Container = styled.div`
  width: 100%;
`;

const Styler = styled.div`
  padding-bottom: 8px;
  margin-left: 29px;
`;

const FaqLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: ${({ noAlign }) => !noAlign && "center"};
  width: 100%;
  margin-bottom: 8px;
`;

const CurriculumTitle = styled.div`
  padding: 9px 12px;
  border: 1px solid ${colors.Gr02};
  width: 100%;
  font-weight: 500;
  font-size: 1rem;
  line-height: 142%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  height: 40.45px;
`;

const CurriculumTitleInput = styled.input`
  width: 100%;
  white-space: nowrap;
`;

const CurriculumTimeSelector = styled.div`
  width: 30%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: calc(1rem * 13 / 14);
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  border: 1px solid ${colors.Gr02};
  position: relative;
`;

const CurriculumContentInput = styled.textarea`
  outline: none;
  resize: none;
  border: none;
  width: 100%;
  height: "auto";
`;

const CurriculumContent = styled.div`
  padding: 8px 16px;
  border: 1px solid ${({ red }) => (red ? colors.Red02 : colors.Gr02)};
  width: 100%;
  font-weight: 500;
  font-size: 1rem;
  line-height: 142%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  /* height: 40.45px; */
`;

const Button = styled.img`
  cursor: pointer;
  width: calc(1rem * (17 / 14) + 16px);
  height: calc(1rem * (17 / 14) + 16px);
  margin-left: 8px;
`;

const FaqIcon = styled.img`
  width: calc(1rem * 25 / 14);
  height: calc(1rem * 36 / 14);
  margin-right: 14px;
`;

const TagInputDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;

  border: 1px solid ${({ red }) => (red ? colors.Red02 : colors.Gr02)};
  width: 100%;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  font-weight: 500;
  font-size: 1rem;
  line-height: 142%;
`;

const TagInput = styled.input`
  font-weight: 500;
  font-size: 1rem;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  width: 100%;

  &::placeholder {
    /* color: ${colors.Gr02}; */
  }
`;

const Sharp = styled.span`
  color: ${({ value }) => (value ? colors.Gr03 : colors.Gr02)};
`;

const SearchBox = styled.div`
  border: 1px solid ${colors.Gr02};
`;

const SearchList = styled.div``;

const SearchItem = styled.div`
  border-bottom: 1px solid ${colors.Gr02};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 8px 10px 18px;
`;

const AddSearchItem = styled(SearchItem)`
  border: none;
  background-color: ${colors.Bl01};
`;

const SearchText = styled.div`
  font-weight: 500;
  font-size: calc(1rem * 12 / 14);
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
`;

const AddSearchText = styled(SearchText)`
  color: ${colors.Bl07};
  max-width: ${W - 127}px;
  word-break: break-all;
  text-overflow: ellipsis;
`;

const PlusIcon = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;
