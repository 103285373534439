import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";

import DesignedMainHeader from "../../components/designedHeader";
import DesignedMainLayout from "../../layouts/designedMainLayout";
import DrawerModal from "../../components/drawerModal";
import Table from "../../layouts/table";
import {
  TableFilterContainer,
  TableFilterContent,
  TableWrapper,
  Wrapper,
  AbsoluteWrapper,
  Icon,
  Text,
  TableContent,
  TableFooter,
  TableColumn,
  TableFooterPaging,
} from "../../layouts/layoutStorage";
import DropDown from "../../components/designedComponents/dropDown";
import AnnounceDetail from "../../components/designedAnnouncementFAQ/announceDetail";

import SearchIcon from "../../assets/icon/search.png";
import DoublePaging from "../../assets/icon/double_paging.png";
import OnePaging from "../../assets/icon/one_paging.png";
import NewAnnounce from "../../components/designedAnnouncementFAQ/newAnnounce";
import colors from "../../styles/constants/colors";

const Announcement = () => {
  const { n, t, type, status } = useParams();
  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    type: type,
    status: status,
  });

  const [search, setSearch] = useState(t != "all" ? t : "");

  const [notice, setNotice] = useState([]);

  const [loading, setLoading] = useState(true);

  const [selected, setSelected] = useState(0);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [count, setCount] = useState(0);
  const [Height, setHeight] = useState({
    TableFilter: 0,
    TableHeader: 0,
    TableFooter: 0,
    Layout: 0,
    LayoutHeader: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const Layout = document.getElementById("Layout")?.offsetHeight - 36;
      const LayoutHeader =
        document.getElementById("LayoutHeader")?.offsetHeight + 65;
      const TableFilter =
        document.getElementById("TableFilter")?.offsetHeight + 19;
      const TableHeader = document.getElementById("TableHeader")?.offsetHeight;
      const TableFooter = document.getElementById("TableFooter")?.offsetHeight;

      setHeight({
        TableFilter: TableFilter,
        TableHeader: TableHeader,
        TableFooter: TableFooter,
        Layout: Layout,
        LayoutHeader: LayoutHeader,
      });
    }, 500);
  }, []);

  useEffect(() => {
    var token = localStorage.getItem("token");

    if (token) {
      if(loading === false) {
        navigate(`/announcement/${1}/${t}/${filter.type}/${filter.status}`);
      }
    } else {
      alert("권한이 없습니다.");
    }
  }, [filter]);

  useEffect(() => {
    var token = window.localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", "list");
    frm.append("token", token);
    frm.append("page", n);
    frm.append("type", type);
    frm.append("text", search);
    frm.append("status", status == "all" ? "" : status == "활성화" ? 1 : 0);

    axios
      .post("https://ai.semos.kr/semos_admin/announcement/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var status = Value.status;
        var statusMessage = Value.status_message;

        if (status == "success") {
          setNotice(Value.announcement_list);
          setCount(Value.announcement_count);
          setLoading(false);
        } else if (status == "waring") {
          alert(statusMessage);
        }
      });
  }, [n, t, type, status]);

  const PageInput = (num) => {
    var token = localStorage.getItem("token");

    if (token) {
      navigate(`/announcement/${num}/${t}/${type}/${status}`);
    } else {
      alert("권한이 없습니다.");
    }
  };

  const numPages = Math.ceil(count / 16);

  let PageList = [];

  if (n < 11) {
    for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
      PageList.push(i + 1);
    }
  } else {
    if (numPages - 10 < n) {
      var startPage = numPages - 19;
      if (startPage < 1) {
        startPage = 1;
      }

      for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    } else {
      var startPage = n - 9;
      if (startPage < 1) {
        startPage = 1;
      }

      for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    }
  }

  const categoryMaker = (category) => {
    var copy = category;

    if (copy == "all") {
      return "상태 전체";
    } else {
      return copy;
    }
  };

  const navigateTo = () => {
    navigate(
      `/announcement/${1}/${search ? search : "all"}/${filter.type}/${
        filter.status
      }`
    );
  };

  const EnterSearch = (key) => {
    if (key == "Enter") {
      navigateTo();
    }
  };

  const showDetail = (id) => {
    // setSelected(id);
    // setOpen(true);
    window.open(`/announcement_detail/${type}/${id}`, "_blank");
  };

  const statusConvert = (status) => {
    switch (status) {
      case "활성화":
        return <div style={{ color: "#0e6dff" }}>활성화</div>;
        break;

      case "비활성화":
        return <div style={{ color: "#acafb5" }}>비활성화</div>;
        break;
    }
  };

  const searchTextInput = (value) => {
    var copy = value;
    var regExp = /[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi;

    if (regExp.test(copy)) {
      alert("특수문자는 입력하실수 없습니다.");
      copy = copy.substring(0, copy.length - 1); // 입력한 특수문자 한자리 지움
    }
    setSearch(copy);
  };

  return (
    <>
      <DesignedMainLayout>
        <DesignedMainHeader title="공지사항" subtitle={`전체 공지사항 : ${count}개 (현재 리스트)`} />

        <FloatingButton>
          <Button
            style={{ marginRight: "12px" }}
            focus={type == "customer"}
            onClick={() => setFilter((prev) => ({ ...prev, type: "customer" }))}
          >
            세모스
          </Button>
          <Button
            focus={type == "partner"}
            onClick={() => setFilter((prev) => ({ ...prev, type: "partner" }))}
          >
            파트너
          </Button>
        </FloatingButton>

        <Table>
          <TableFilterContainer id="TableFilter" margin={`0 0 19px 0`}>
            <div style={{ display: "flex" }}>
              <DropDown
                option={[
                  {
                    text: "상태 전체",
                    value: "all",
                  },
                  {
                    text: "활성화",
                    value: "활성화",
                  },
                  {
                    text: "비활성화",
                    value: "비활성화",
                  },
                ]}
                data={categoryMaker(filter.status)}
                setData={setFilter}
                type="object"
                keyName="status"
              />

              <TableFilterContent margin={`0 24px 0 0`}>
                <Input
                  onKeyUp={(e) => EnterSearch(e.key)}
                  value={search ? search : ""}
                  onChange={(e) => searchTextInput(e.target.value)}
                  width={`350px`}
                  type={`text`}
                  maxLength={40}
                  placeholder="검색어를 입력해주세요"
                />
                <Icon
                  width={`calc(1rem * (17 / 14))`}
                  cursor={`pointer`}
                  src={SearchIcon}
                  onClick={() => navigateTo()}
                />
              </TableFilterContent>
            </div>
            <AddFaq onClick={() => showDetail("new")}>공지 사항 추가</AddFaq>
          </TableFilterContainer>
          <TableContent
            height={
              Height.Layout -
              (Height.LayoutHeader + Height.TableFilter + Height.TableFooter)
            }
            contentHeight={Height.TableHeader}
          >
            <section id="TableHeader">
              <TableColumn
                width={`10`}
                weight={`500`}
                br={`1px solid ${colors.Gr02}`}
              >
                공지 ID
              </TableColumn>

              <TableColumn
                width={`20`}
                weight={`500`}
                br={`1px solid ${colors.Gr02}`}
              >
                공지 제목
              </TableColumn>

              <TableColumn
                width={`30`}
                weight={`500`}
                br={`1px solid ${colors.Gr02}`}
              >
                공지 내용
              </TableColumn>

              <TableColumn
                width={`20`}
                weight={`500`}
                br={`1px solid ${colors.Gr02}`}
              >
                등록일
              </TableColumn>

              <TableColumn
                width={`20`}
                weight={`500`}
                br={`1px solid transparent`}
              >
                상태
              </TableColumn>
            </section>

            <section>
              {notice
                ? notice.map((item, idx) => (
                    <Link to={`/announcement_detail/${type}/${item.id}`}>
                      <TableWrapper
                        cursor={`pointer`}
                        key={`${idx}_reservation`}
                        jc={`flex-start`}
                        bl={`1px solid transparent`}
                        br={`1px solid transparent`}
                        bb={`1px solid ${colors.Gr02}`}
                        bt={`1px solid transparent`}
                        width={`100%`}
                        bgc={`#ffffff`}
                      >
                        <TableColumn
                          width={`10`}
                          weight={`500`}
                          br={`1px solid ${colors.Gr02}`}
                        >
                          {item.id}
                        </TableColumn>

                        <TableColumn
                          width={`20`}
                          weight={`500`}
                          br={`1px solid ${colors.Gr02}`}
                        >
                          {`${item.title}`}
                        </TableColumn>

                        <TableColumn
                          width={`30`}
                          weight={`500`}
                          br={`1px solid ${colors.Gr02}`}
                        >
                          {`${item.content}`}
                        </TableColumn>

                        <TableColumn
                          width={`20`}
                          weight={`500`}
                          br={`1px solid ${colors.Gr02}`}
                        >
                          {`${item.date}`}
                        </TableColumn>

                        <TableColumn
                          width={`20`}
                          weight={`500`}
                          br={`1px solid transparent`}
                        >
                          {statusConvert(item.status)}
                        </TableColumn>
                      </TableWrapper>
                    </Link>
                  ))
                : ""}

              <TableFooter id="TableFooter">
                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  src={DoublePaging}
                  onClick={() => {
                    if (Number(n) < 11) {
                      PageInput(1);
                    } else {
                      PageInput(Number(n) - 10);
                    }
                  }}
                />
                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  src={OnePaging}
                  onClick={() => {
                    if (n > 1) {
                      PageInput(n - 1);
                    } else {
                      window.alert("첫번째 페이지 입니다.");
                    }
                  }}
                />

                {PageList.length
                  ? PageList.map((item, idx) => (
                      <TableFooterPaging
                        key={`paging_number_${idx}`}
                        margin={`0 16px 0 0`}
                        color={`${n == item ? "#3383FF" : "#ACAFB5"}`}
                        onClick={() => PageInput(item)}
                      >
                        {item}
                      </TableFooterPaging>
                    ))
                  : ""}

                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  transform={`rotate(180deg)`}
                  src={OnePaging}
                  onClick={() => {
                    if (n < numPages) {
                      PageInput(Number(n) + 1);
                    } else {
                      window.alert("마지막 페이지 입니다.");
                    }
                  }}
                />
                <Icon
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  transform={`rotate(180deg)`}
                  src={DoublePaging}
                  onClick={() => {
                    if (Number(n) + 10 > numPages) {
                      PageInput(numPages);
                    } else {
                      PageInput(Number(n) + 10);
                    }
                  }}
                />
              </TableFooter>
            </section>
          </TableContent>
        </Table>
      </DesignedMainLayout>

      {/* <DrawerModal open={open} setOpen={setOpen}>
        <AnnounceDetail id={selected} />
      </DrawerModal>

      <DrawerModal open={open2} setOpen={setOpen2}>
        <NewAnnounce type={type} />
      </DrawerModal> */}
    </>
  );
};

export default Announcement;

const FloatingButton = styled.div`
  display: flex;
  position: absolute;
  top: 43px;
  right: 36px;
`;

const Button = styled.div`
  padding: 8px 20px;
  text-align: center;
  border: 1px solid;
  border-color: ${({ focus }) => (focus ? "#3383FF" : "#dcdcdc")};
  background-color: ${({ focus }) => (focus ? "#F2F7FF" : "#FFFFFF")};
  border-radius: 8px;
  color: #3383ff;
  cursor: pointer;
`;

const Input = styled.input`
  width: ${({ width }) => `${width}`};
  display: inline-block;
`;

const AddFaq = styled.div`
  padding: 8px 20px;
  color: #ffffff;
  font-weight: 700;
  background-color: #0e6dff;
  border-radius: 8px;
  cursor: pointer;
`;
