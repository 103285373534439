import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

// components
import MainHeader from "../../components/header";
import MainLayout from "../../layouts/mainLayout";
import Button from "../../modules/button";
import LoadingSpinner from "../../components/loadingSpinner";
import SelectImg from "../../modules/select_img";

// icon
import scuba from "../../assets/icon/scuba.png";
import freeDiv from "../../assets/icon/freediv.png";
import snowboard from "../../assets/icon/snowboard_logo.png";

import {
  Footer,
  Main,
} from "./style";

const PartnerLessonEditPage = () => {
  const { fid } = useParams();

  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState();
  const [facilityData, setFacilityData] = useState();
  const [deleteProduct, setDeleteProduct] = useState([]);

  // 상품 리스트 로드
  useEffect(() => {
      var token = window.localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "product_list");
      frm.append("token", token);
      frm.append("facility_id", fid);

      axios.post("https://ai.semos.kr/semos_admin/facility/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            setProduct(Value.product_list);
            setFacilityData(Value.facility_data);
          } else {
            alert(statusMessage);
          }
      });    

  }, []);

  // 상품 정보 저장
  const ProductSave = () => {
    if (window.confirm("상품 정보를 수정하시겠습니까?")) {
      var token = window.localStorage.getItem("token");
      setLoading(true);
      axios.post(
          "https://ai.semos.kr/semos_admin/facility/data",
          {
            mode: "product_revise",
            token: token,
            product_list: product,
            delete_product_list: deleteProduct,
            facility_id: fid
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        ).then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            setLoading(false);
            alert(statusMessage);
            window.location.reload();
          } else {
            setLoading(false);
            alert(statusMessage);
          }
        });
    }
  };


  const DateTypeList = [
    {
      img : scuba,
      value : "all",
      text : "전체",
    },
    {
      img : scuba,
      value : "week",
      text : "주중",
    },
    {
      img : freeDiv,
      value : "weekend",
      text : "주말"
    }
  ];

  const dateOptionList = [
      {
        text: "시간을 선택해주세요!",
        value: "",
      },
      {
        text: "30분",
        value: "30",
      },
      {
        text: "1시간",
        value: "60",
      },
      {
        text: "1시간 30분",
        value: "90",
      },
      {
        text: "2시간",
        value: "120",
      },
      {
        text: "2시간 30분",
        value: "150",
      },
      {
        text: "3시간",
        value: "180",
      },
      {
        text: "3시간 30분",
        value: "210",
      },
      {
        text: "4시간",
        value: "240",
      },
      {
        text: "4시간 30분",
        value: "270",
      },
      {
        text: "5시간",
        value: "300",
      },
      {
        text: "5시간 30분",
        value: "330",
      },
      {
        text: "6시간",
        value: "360",
      },
      {
        text: "6시간 30분",
        value: "390",
      },
      {
        text: "7시간",
        value: "420",
      },
      {
        text: "7시간 30분",
        value: "450",
      },
      {
        text: "8시간",
        value: "480",
      },
      {
        text: "8시간 30분",
        value: "510",
      },
      {
        text: "9시간",
        value: "540",
      },
      {
        text: "9시간 30분",
        value: "570",
      },
      {
        text: "10시간",
        value: "600",
      },
      {
        text: "10시간 30분",
        value: "630",
      },
      {
        text: "11시간",
        value: "660",
      },
      {
        text: "11시간 30분",
        value: "690",
      },
      {
        text: "12시간",
        value: "720",
      },
      {
        text: "12시간 30분",
        value: "750",
      },
      {
        text: "13시간",
        value: "780",
      },
      {
        text: "13시간 30분",
        value: "810",
      },
      {
        text: "14시간",
        value: "840",
      },
      {
        text: "14시간 30분",
        value: "870",
      },
      {
        text: "15시간",
        value: "900",
      },
    ];


  const timeOptionList = [
    {
      text: "선택해주세요!",
      value: "",
    },
    {
      text: "00:00",
      value: "00:00",
    },
    {
      text: "00:30",
      value: "00:30",
    },
    {
      text: "01:00",
      value: "01:00",
    },
    {
      text: "01:30",
      value: "01:30",
    },
    {
      text: "02:00",
      value: "02:00",
    },
    {
      text: "02:30",
      value: "02:30",
    },
    {
      text: "03:00",
      value: "03:00",
    },
    {
      text: "03:30",
      value: "03:30",
    },
    {
      text: "04:00",
      value: "04:00",
    },
    {
      text: "04:30",
      value: "04:30",
    },
    {
      text: "05:00",
      value: "05:00",
    },
    {
      text: "05:30",
      value: "05:30",
    },
    {
      text: "06:00",
      value: "06:00",
    },
    {
      text: "06:30",
      value: "06:30",
    },
    {
      text: "07:00",
      value: "07:00",
    },
    {
      text: "07:30",
      value: "07:30",
    },
    {
      text: "08:00",
      value: "08:00",
    },
    {
      text: "08:30",
      value: "08:30",
    },
    {
      text: "09:00",
      value: "09:00",
    },
    {
      text: "09:30",
      value: "09:30",
    },
    {
      text: "10:00",
      value: "10:00",
    },
    {
      text: "10:30",
      value: "10:30",
    },
    {
      text: "11:00",
      value: "11:00",
    },
    {
      text: "11:30",
      value: "11:30",
    },
    {
      text: "12:00",
      value: "12:00",
    },
    {
      text: "12:30",
      value: "12:30",
    },
    {
      text: "13:00",
      value: "13:00",
    },
    {
      text: "13:30",
      value: "13:30",
    },
    {
      text: "14:00",
      value: "14:00",
    },
    {
      text: "14:30",
      value: "14:30",
    },
    {
      text: "15:00",
      value: "15:00",
    },
    {
      text: "15:30",
      value: "15:30",
    },
    {
      text: "16:00",
      value: "16:00",
    },
    {
      text: "16:30",
      value: "16:30",
    },
    {
      text: "17:00",
      value: "17:00",
    },
    {
      text: "17:30",
      value: "17:30",
    },
    {
      text: "18:00",
      value: "18:00",
    },
    {
      text: "18:30",
      value: "18:30",
    },
    {
      text: "19:00",
      value: "19:00",
    },
    {
      text: "19:30",
      value: "19:30",
    },
    {
      text: "20:00",
      value: "20:00",
    },
    {
      text: "20:30",
      value: "20:30",
    },
    {
      text: "21:00",
      value: "21:00",
    },
    {
      text: "21:30",
      value: "21:30",
    },
    {
      text: "22:00",
      value: "22:00",
    },
    {
      text: "22:30",
      value: "22:30",
    },
    {
      text: "23:00",
      value: "23:00",
    },
    {
      text: "23:30",
      value: "23:30",
    }
  ];

  const DataChange = (type, number, value) => {
    if(type === 'price' || type === 'child_price' || type === 'adult_equipment' || type === 'child_equipment' || type === 'adult_clothes' || type === 'child_clothes') {
      var regex = /[^0-9]/g;

      var ValueD = value.replace(regex, "");
  
      var ValueD = ValueD.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );
    } else {
      var ValueD = value;
    }

    let Data = [...product];

    let DataTarget = Data[number];

    DataTarget[type] = ValueD;
    DataTarget['change'] = 'yes';

    Data[number] = DataTarget;

    setProduct(Data);
  };

  const ProductPlus = () => {

    let Data = [...product];

    Data.push({
      change : "no",
      id : "new",
      price : "",
      child_price : "",
      adult_equipment : "",
      child_equipment : "",
      adult_clothes : "",
      child_clothes : "",
      date_type : "week",
      time : "",
      category : "",
      start_time : "",
      end_time : ""
    });

    setProduct(Data);

  }

  const ProductDelete = (number) => {

    let Data = [...product];
    let DData = [...deleteProduct];
    let DeleteData = Data[number];

    DData.push(DeleteData)
    setDeleteProduct(DData);

    Data.splice(number, 1);

    setProduct(Data);

  }


  return (
    <>
      <LoadingSpinner show={loading} color={"rgba(0,149,255,1)"} />

      <MainLayout>
        <MainHeader title={facilityData && `${facilityData.name}`} >

          <ProductAdd onClick={() => ProductPlus()}>
            추가하기
          </ProductAdd>
        </MainHeader>
        <Main>

          {product && product.map((data, id) => (
            <ProductView key={`${id}_product`}>

              <ProductSplice onClick={() => ProductDelete(id)}>
                삭제하기
              </ProductSplice>

              <div className="content">
          
                <div className="priceContainer">
                  <span className="subject">상품 카테고리</span>

                  <div className="innerContent">
                    <span className="title">카테고리: </span>

                    <input onChange={(e) => DataChange('category', id, e.target.value)} 
                      value={data.category} maxLength="20" type='text' className="inputContent" />
                  </div>
                </div>

                <div className="typeContainer">
                  <span className="title">이용 날짜</span>
                  <div className="innerContent">

                    {DateTypeList.map((item, idx) => (
                      <ProductType
                        key={`${id}_${idx}_date_type`}
                        onClick={() => DataChange('date_type', id, item.value)}
                        active={data.date_type == item.value}
                      >
                        <img src={snowboard} />
                        <span>{item.text}</span>
                      </ProductType>
                    ))}
                    
                  </div>
                </div>

              </div>

              <div className="content">
          
                <div className="timeContainer">
                  <span className="title">이용 시간</span>
                  <div className="innerContent">
                    <SelectImg
                      optionList={dateOptionList}
                      name="maximum"
                      value={data.time}
                      typeText="이용시간"
                      changeHandler={(e) =>
                        DataChange('time', id, e.target.value)
                      }
                      width={`100%`}
                      paddingLeft={"80px"}
                    />
                  </div>
                </div>

                <div className="timeContainer">
                  <span className="title"></span>
                  <div className="innerContent">
                    <SelectImg
                      optionList={timeOptionList}
                      name="maximum"
                      value={data.start_time}
                      typeText="시작시간"
                      changeHandler={(e) =>
                        DataChange('start_time', id, e.target.value)
                      }
                      width={`calc(50% - 10px)`}
                      paddingLeft={"80px"}
                    />

                    <SelectImg
                      optionList={timeOptionList}
                      name="maximum"
                      value={data.end_time}
                      typeText="종료시간"
                      changeHandler={(e) =>
                        DataChange('end_time', id, e.target.value)
                      }
                      width={`calc(50% - 10px)`}
                      paddingLeft={"80px"}
                    />
                  </div>
                </div>

              </div>

              <div className="content">

                <div className="priceContainer">
                  <span className="subject">리프트</span>

                  <div className="innerContent">
                    <span className="title">리프트 대인: </span>

                    <input onChange={(e) => DataChange('price', id, e.target.value)} 
                      value={data.price} maxLength="10" type='text' className="inputContent" />
                  </div>
                </div>

                <div className="priceContainer">
                  <div className="innerContent">
                    <span className="title">리프트 소인: </span>

                    <input onChange={(e) => DataChange('child_price', id, e.target.value)} 
                      value={data.child_price} maxLength="10" type='text' className="inputContent" />
                  </div>
                </div>
              </div>

              <div className="content">

                <div className="priceContainer">
                  <span className="subject">장비</span>

                  <div className="innerContent">
                    <span className="title">장비 대인: </span>

                    <input onChange={(e) => DataChange('adult_equipment', id, e.target.value)} 
                      value={data.adult_equipment} maxLength="10" type='text' className="inputContent" />
                  </div>
                </div>

                <div className="priceContainer">
                  <div className="innerContent">
                    <span className="title">장비 소인: </span>

                    <input onChange={(e) => DataChange('child_equipment', id, e.target.value)} 
                      value={data.child_equipment} maxLength="10" type='text' className="inputContent" />
                  </div>
                </div>
              </div>

              <div className="content">

                <div className="priceContainer">

                  <span className="subject">의류</span>

                  <div className="innerContent">
                    <span className="title">의류 대인: </span>

                    <input onChange={(e) => DataChange('adult_clothes', id, e.target.value)} 
                      value={data.adult_clothes} maxLength="10" type='text' className="inputContent" />
                  </div>
                </div>

                <div className="priceContainer">
                  <div className="innerContent">
                    <span className="title">의류 소인: </span>

                    <input onChange={(e) => DataChange('child_clothes', id, e.target.value)} 
                      value={data.child_clothes} maxLength="10" type='text' className="inputContent" />
                  </div>
                </div>
              </div>
            </ProductView>            
          ))}
        </Main>

        <Footer>

          {fid !== "register" ? (
            <Button
              text="저장하기"
              clickHandler={ProductSave}
              width={`180px`}
            />
          ) : (
            ""
          )}

        </Footer>

        {/*<CropperPopup
          cropType={cropType}
          status={imgPopup}
          OnOff={setImgPopup}
          WH={imgScale}
          imgData={cropImg}
          setImg={CropImg}
        />*/}

      </MainLayout>
    </>
  );
};

export default PartnerLessonEditPage;

const ProductView = styled.div`
  width : 100%;
  display : inline-block;
  flex-wrap : wrap;
  margin-top : 20px;
  border-radius : 8px;
  border: 2px solid rgba(132, 140, 148, 0.5);
  padding : 10px;
  position : relative;

  .content {
    width : 100%;
    display : inline-flex;
    justify-content : space-between;
    align-items : flex-end;
    margin-bottom : 10px;
    padding : 0 2px;
  }

  .typeContainer {
    width : calc(50% - 10px);
    display : inline-block;

    .title {
      width : 100%;
      display : inline-flex;
      font-size : 18px;
      font-weight : 600;
      margin-bottom : 5px;
    }

    .innerContent {
      width : 100%;
      display : inline-flex;
      overflow : auto hidden;
      justify-content : flex-start;
    }
  }

  .ageContainer {
    width : calc(50% - 10px);
    display : inline-block;

    .title {
      width : 100%;
      display : inline-flex;
      font-size : 18px;
      font-weight : 600;
      margin-bottom : 5px;
    }

    .innerContent {
      width : 100%;
      display : inline-flex;
      padding-bottom : 10px;
      overflow : auto hidden;
      justify-content : flex-start;
    }
  }

  .timeContainer {
    width : calc(50% - 10px);
    display : inline-block;

    .title {
      width : 100%;
      display : inline-flex;
      font-size : 18px;
      font-weight : 600;
      margin-bottom : 15px;
      padding-left : 5px;
    }

    .innerContent {
      width : 100%;
      display : inline-flex;
      overflow : auto hidden;
      justify-content : space-between;
      position : relative;

      .inputContent {
        width : 100%;
        padding : 10px;
        border-radius : 8px;
        text-align : right;
      }
    }
  }

  .priceContainer {
    width : calc(50% - 10px);
    display : inline-block;

    .subject {
      width : 100%;
      display : inline-flex;
      font-size : 18px;
      font-weight : 600;
      margin-bottom : 15px;
      padding-left : 5px;
    }

    .innerContent {
      width : 100%;
      display : inline-flex;
      overflow : auto hidden;
      justify-content : flex-start;
      position : relative;

      .title {
        position : absolute;
        top : 50%;
        left : 20px;
        transform : translate(0, -50%);
        color : #666d75;
      }

      .inputContent {
        width : 100%;
        padding : 10px 20px 10px 20px;
        border-radius : 8px;
        text-align : right;
      }
    }
  }

  .childPriceContainer {
    width : calc(33.3% - 10px);
    display : inline-block;

    .innerContent {
      width : 100%;
      display : inline-flex;
      overflow : auto hidden;
      justify-content : flex-start;
      position : relative;

      .title {
        position : absolute;
        top : 50%;
        left : 20px;
        transform : translate(0, -50%);
        color : #666d75;
      }

      .inputContent {
        width : 100%;
        padding : 10px 20px 10px 20px;
        border-radius : 8px;
        text-align : right;
      }
    }
  }
`

const ProductType = styled.div`
  width: 80px;
  height: 60px;
  display: inline-flex;
  flex-wrap: wrap;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: ${({ active }) =>
    active ? `3px solid rgba(0,149,255,1)` : "3px solid rgba(132,140,148, .5)"};
  font-size: 12px;
  cursor: pointer;

  img {
    width: 30px;
    display: inline-block;
  }

  span {
    width: 100%;
    display: inline-block;
    text-align: center;
  }
`;

const ProductAdd = styled.div`
  padding : 6px 10px;
  cursor : pointer;
  color : #ffffff;
  background-color : rgba(0,149,255,1);
  position : absolute;
  top : 50%;
  right : 20px;
  transform : translate(0, -50%);
  border-radius : 8px;
  font-size : 14px;
`;

const ProductSplice = styled.div`
  padding : 6px 10px;
  cursor : pointer;
  color : #ffffff;
  background-color : #E63553;
  position : absolute;
  top : 10px;
  right : 10px;
  border-radius : 8px;
  font-size : 14px;
`;