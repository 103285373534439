import styled from "styled-components";
import colors from "../../styles/constants/colors";
import regionIcon from "../../assets/icon/region.svg";
import flexbox from "../../styles/func/flexbox";
import typography from "../../styles/constants/typography";
import base from "../../styles/constants/base";
import { useState } from "react";
import OverlayPortal from "../../overlayPortal";
import Overlay from "../../layouts/overlay";
import Tag from "../../modules/tag";
import AddressModal from "../../layouts/modal/address";

const Box = styled.div`
  width: ${({ type }) => (type === "modal" ? `100%` : `calc(100% - 150px)`)};
  margin-top: 0px;

  ul {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0 !important;
  }
`;

const MainAddress = styled.div`
  width: 100%;
  ${flexbox("flex-start")}
  margin-bottom: 20px;

  button {
    background-color: ${colors.blue};
    color: ${colors.white};
    padding: 5px 10px;
    border-radius: 8px;
    font-size: ${typography.size.small}px;
    ${flexbox()}
    margin-right: 10px;

    img {
      margin-right: 5px;
    }
  }

  p {
    color: ${colors.mediumGray};
  }
`;

const SubAddress = styled.div`
  width: 100%;
  ${flexbox()}
  margin-bottom: 20px;

  input {
    width: 100%;
  }
`;

const ButtonBox = styled.div`
  width: 130px;
  ${flexbox("flex-end")};
  white-space: nowrap;

  button {
    height: ${base.height.input}px;
    padding: 10px 20px;
    background-color: ${colors.blue};
    color: ${colors.white};
    border-radius: ${base.borderRadius.default}px;
  }
`;

const LocationBox = ({
  type = "base",
  searchAddressHandler,
  addHandler,
  changeHandler,
  deleteHandler,
  addressList,
  data,
}) => {
  const [open, setOpen] = useState(false);

  const [targetAddress, setTargetAddress] = useState("");

  const showDetailAddressHandler = (address) => {
    setTargetAddress({
      main: address.main,
      detail: address.detail,
    });

    setOpen(true);
  };

  if (type === "modal") {
    return (
      <>
        <Box type={type}>
          <MainAddress>
            <button onClick={searchAddressHandler}>
              <img src={regionIcon} />
              <span>주소 검색</span>
            </button>

            <p>{data.location}</p>
          </MainAddress>

          <SubAddress>
            <input
              width="100%"
              type="text"
              placeholder="상세주소 입력"
              name="detail"
              onChange={changeHandler}
              value={data.location_additional}
            />
          </SubAddress>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box>
        <MainAddress>
          <button onClick={searchAddressHandler}>
            <img src={regionIcon} />
            <span>주소 검색</span>
          </button>

          <p>{data.location}</p>
        </MainAddress>

        <SubAddress>
          <input
            type="text"
            placeholder="상세주소 입력"
            name="detail"
            onChange={changeHandler}
            value={data.location_additional}
          />

          <ButtonBox>
            {/* <button type="button" onClick={addHandler}>
              입력
            </button> */}
          </ButtonBox>
        </SubAddress>

        {/* 데이터 가져오기 
        <ul>
          {addressList.map((item, idx) => (
            <li key={idx}>
              <Tag
                underline={true}
                text={shortenAddress(item.main)}
                deleteHandler={() => deleteHandler(item.id)}
                clickHandler={() => showDetailAddressHandler(item)}
                ispointer="true"
              />
            </li>
          ))}
        </ul>*/}
      </Box>

      {open && (
        <AddressModal
          closeHandler={() => setOpen(false)}
          address={targetAddress}
        ></AddressModal>
      )}

      <OverlayPortal>
        {open && <Overlay toggleHandler={() => setOpen(false)}></Overlay>}
      </OverlayPortal>
    </>
  );
};

export default LocationBox;

export const shortenAddress = (address) => {
  const [first, second, third] = address.split(" ");

  return `${first.slice(0, 2)} ${second.slice(0, 2)} ${third.slice(0, 2)}`;
};
