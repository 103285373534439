import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Profile from "../../components/customer/profile";
import MainHeader from "../../components/header";
import customerDetailData from "../../data/customer/detail";
import useInput from "../../hooks/useInput";
import MainLayout from "../../layouts/mainLayout";
import Select from "../../modules/select";
import Textarea from "../../modules/textarea";
import typography from "../../styles/constants/typography";
import Button from "../../modules/button";
import flexbox from "../../styles/func/flexbox";
import colors from "../../styles/constants/colors";

const Main = styled.main`
  height: 100%;
  padding: 20px;
  margin-top: 20px;
  padding-bottom: 150px;
  overflow-y: scroll;

  section:not(:last-child) {
    margin-bottom: 50px;
  }

  section:nth-child(2) {
    display: flex;
    font-weight: ${typography.weight.bold};
    font-size: ${typography.size.medium}px;
    align-items: center;

    p {
      white-space: nowrap;
      width: 100px;
    }
  }

  section:last-child {
    p:first-child {
      margin-bottom: 20px;
    }
  }
`;

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90px;
  padding-right: 20px;
  ${flexbox("flex-end")}
  background-color: ${colors.white};
`;

const ProfileSection = styled.section`
  display: flex;
  white-space: nowrap;
  margin-right: 20px;

  p {
    width: 100px;
    font-weight: ${typography.weight.bold};
  }
`;

const InputField = styled.div``;

const InputBox = styled.div`
  display: flex;
  margin-bottom: 20px;

  img {
    margin-left: 20px;
  }
`;

const CustomerEditPage = () => {
  const { name, type, comment, profile, age } = customerDetailData;

  const defaultState = {
    age,
    type,
    comment,
    profile,
  };

  const { value, inputChangeHandler } = useInput(defaultState);

  const saveHandler = () => {
    var token = window.localStorage.getItem("token");

    axios
      .post(
        "https://ai.semos.kr/semos_admin/user/data",
        {
          mode: "profile_revise",
          token: token,
          number: cid,
          profile_data : customer
        },
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((data) => {
        var Value = data.data;
        var status = Value.status;
        var statusMessage = Value.status_message;

        if (status == "success") {
          alert(statusMessage);
        } else if (status == "waring") {
          alert(statusMessage);
        }
      });
  };

  const { cid } = useParams();

  const [customer, setCustomer] = useState("");
  const [profileData, setProfile] = useState("");

      // 고객 리스트 로드
      useEffect(() => {
    
        var token = window.localStorage.getItem('token')
  
        const frm = new FormData();
        frm.append("mode", "specific");
        frm.append("token", token);
        frm.append("number", cid);
  
          axios.post("https://ai.semos.kr/semos_admin/user/data", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          }).then((data) => {
            var Value = data.data;
            var status = Value.status
            var statusMessage = Value.status_message
  
            if(status == 'success') {
              setProfile(Value.profile_list)
              setCustomer(Value.customer_data)
            } else if(status == 'waring') {
              alert(statusMessage)
            }
  
          });
  
      },[]);

  return (
    <MainLayout
      bottomButton={
        type === "general" && { text: "저장하기", clickHandler: saveHandler }
      }
    >
      <MainHeader title={customer ? customer.name : ""} />

      <Main>
        <Profile
          data={customer}
          type="edit"
          select={{
            name: "age",
            value: customer ? customer.age : "",
            changeHandler: (e) => setCustomer({...customer, age : e.target.value}),
          }}
        />

        <section>
          <p>타입</p>
          <Select
            name="type"
            value={customer.type}
            optionList={[
              {
                text: "일반",
                value: "일반회원",
              },
              {
                text: "파트너",
                value: "파트너",
              },
            ]}
            changeHandler={(e) => setCustomer({...customer, 
                tier : e.target.value,
                type : e.target.value
            })}
            width="210px"
          />
        </section>

        {/*type === "partner" && (
          <ProfileSection>
            <p>프로필</p>

            <InputField>
              <InputBox>
                <Select
                  width="450px"
                  optionList={profile.map((item) => ({
                    text: item.title,
                    value: item.title,
                  }))}
                  name="profile"
                  changeHandler={inputChangeHandler}
                  value={value.profile}
                />
              </InputBox>
            </InputField>
          </ProfileSection>
        )*/}

        <section>
          <p>메모</p>

          <Textarea
            value={customer.memo}
            changeHandler={(e) => setCustomer({...customer, memo : e.target.value})}
            name="comment"
          />
        </section>
      </Main>

      {type === "partner" && (
        <Footer>
          <Button clickHandler={saveHandler} text="저장하기" width={`230px`} />
        </Footer>
      )}
    </MainLayout>
  );
};

export default CustomerEditPage;
