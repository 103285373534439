import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import {
  Wrapper,
  Text,
  WrapperRow,
  WrapperColmun,
} from "../../layouts/layoutStorage";

//import Pagination from "../../components/pagination";
import colors from "../../styles/constants/colors";

// const CUSTOMER_PER_PAGE = 10;
// const CUSTOMER_CARD_HEIGHT = 50;

const Container = styled.div`
  padding: 28px 36px 0 36px;
  /* position: relative; */
  width: 100%;
  height: 100%;
`;
const Main = styled.main`
  width: 100%;
  height: calc(100% - 48px);
  /* padding: 0 20px;
  margin-top: 20px; */

  ul {
    width: 100%;
  }
`;

const Layout = styled.div`
  /* min-width: 646px; */

  width: 100%;
  height: 100%;
  max-width: 1000px;
  /* height: 100%; */
  position: relative;
`;

const Bar = styled.div`
  width: 1px;
  height: 16px;
  margin: 0 8px;
  background: ${colors.Gr01};
`;

const BottomButtonBox = styled.div`
  position: absolute;
  bottom: 52px;
  right: 20px;
  /* align-self: flex-end; */

  button:first-child {
    margin-right: 17px;
  }
`;

const PaymentCXBox = styled.div`
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  .cxContentBox {
    width: 100%;

    display: flex;
    align-items: top;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    p:first-child {
      color: ${colors.mediumGray};
    }
    .cxButtonBox {
      width: calc(100% - 8rem);
      display: flex;
      justify-content: space-between;
      gap: 12px;
    }

    .cxContent {
      width: calc(100% - 8rem);
      display: inline-block;
      background-color: ${colors.Gr01};

      .cxType {
        padding: 10px 12px;
        display: inline-block;
        border: 3px solid rgba(132, 140, 148, 1);
        cursor: pointer;
        font-size: 17px;
        font-weight: 500;
      }
      .cxType:first-child {
        margin-right: 15px;
      }
      .typeActive {
        border: 3px solid rgba(0, 149, 255, 1);
      }
      .cxBottomBox {
        min-height: 355px;
        max-height: 650px;
        overflow: auto;
      }
      .cxManeger {
        width: 100%;
        display: inline-block;
        white-space: pre-wrap;

        padding: 10px 12px;
        border: 1px solid #dcdcdc;
        /* font-size: 17px;
        font-weight: 500; */
        /* background-color: #ffff; */
      }

      .csSerch {
        display: inline-flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        justify-content: space-between;
        align-items: center;
        input {
          width: 100%;
        }
        display: flex;
        flex-direction: row;

        justify-content: space-between;
      }
      .cxMessage {
        width: 100%;
        display: inline-block;
        padding: 10px 12px;
        border: 1px solid rgba(132, 140, 148, 1);
        font-size: 17px;
        font-weight: 500;
        background-color: #f9f9f9;
        height: 300px;
        resize: none;
      }
    }
  }
`;

const BackgroundImageDiv = styled.div``;

const BackgroundImage = styled.img`
  width: calc(1rem * 17);
  height: calc(1rem * 10);
  /* width: calc(1rem * (16 / 1));
  height: calc(1rem * (17 / 1));
  width: 199px; 
  height: 124px; */

  background: #dcdcdc;
  border-radius: 8px;
`;

const AlarmTalkDetail = () => {
  const { id, n } = useParams();

  const [data, setData] = useState([]);

  useEffect(() => {
    if (id && n) {
      var token = window.localStorage.getItem("token");
      const frm = new FormData();
      frm.append("mode", "specific");
      frm.append("token", token);
      frm.append("number", id);
      axios
        .post("https://ai.semos.kr/semos_admin/user/send", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          setData(Value.payment_data);
          console.log(Value.payment_data);
          var status = Value.status;
        });
    }
    return setData([]);
  }, [n, id]);
  const ThisDate = new Date();
  const ThisMonth = ThisDate.getMonth();
  const ThisDay = ThisDate.getDate();

  const [all, setAll] = useState(false);
  // 개별 푸시 전송 (all = false)
  const [partner, setPartner] = useState("");
  const [oneManager, setOneManager] = useState("Victor");
  const [oneTitle, setOneTitle] = useState("");
  const [link, setLink] = useState("");

  // 전체 푸시 전송 (all = true)
  const [allManager, setAllManager] = useState("Victor");
  const [allTitle, setAllTitle] = useState("");
  const [allContent, setAllContent] = useState("");

  //----------------

  const [phone, setPhone] = useState("");
  const [template, setTemplate] = useState("PREGISTER4");
  const [name, setName] = useState("");
  const [user, setUser] = useState("alimtalk");
  const [message, setMessage] = useState("[세모스]");

  const [month, setMonth] = useState(
    ThisMonth > 7
      ? String(ThisMonth + 2) + "월"
      : "0" + String(ThisMonth + 2) + "월"
  );
  const [monthAday, setMonthAday] = useState(
    `${ThisMonth > 8 ? ThisMonth + 1 : "0" + String(ThisMonth + 1)}월 ${
      ThisDay > 9 ? ThisDay : "0" + String(ThisDay)
    }일`
  );

  const ContentReset = () => {
    if (window.confirm("작성한 내용을 초기화 하시겠습니까?")) {
      setOneManager("Victor");
      setOneTitle("");
      setLink("");
      setAllManager("Victor");
      setAllTitle("");
      setAllContent("");
    }
  };

  const messageSend = () => {
    if (window.confirm("작성한 내용을 전송하시겠습니까?")) {
      //   if (!all) {
      //     const pCheck = partner.length > 1;
      //   } else {
      //     const ctCheck = oneContent.length > 10;
      //   }

      const mCheck = allManager.length > 2;
      const phCheck = phone.length > 9;
      // const lCheck = link.length > 8;

      if (mCheck && phCheck) {
        var token = window.localStorage.getItem("token");

        // const frm = new FormData();
        // frm.append("mode", "partnerInfo");
        // frm.append("token", token);
        // frm.append("manager", CXManager);
        // frm.append("partner", partner);
        // frm.append("template", template);
        // frm.append("link", link);
        // frm.append("phone", phone);
        // frm.append(
        //   "content",
        //   user == "alimtalk" ? messageContent[template] : message
        // );
        // frm.append("user", user);
        // frm.append("name", name);

        // axios
        //   .post("https://ai.semos.kr/semos_admin/user/send", frm, {
        //     headers: { "Content-Type": "multipart/form-data" },
        //   })
        //   .then((data) => {
        //     var Value = data.data;
        //     var status = Value.status;
        //     var statusMessage = Value.status_message;

        //     if (status == "success") {
        //       alert(statusMessage);
        //     } else if (status == "waring") {
        //       alert(statusMessage);
        //     } else if (status == "fail") {
        //       alert(statusMessage);
        //     }
        //   });
      } else {
        if (!mCheck) {
          alert("담당자명을 확인해주세요!");
        } else if (!phCheck) {
          alert("전화번호를 확인해주세요!");
        } /*else if(!pCheck) {
        alert('강사명을 확인해주세요!')
      } else if(!lCheck) {
        alert('노션 링크를 확인해주세요!')
      }*/
      }
    }
  };

  const DataChange = (type, value) => {
    switch (type) {
      // user
      case "partner":
        setPartner(value);
        break;

      case "month":
        setMonth(value);
        break;

      case "mnd":
        setMonthAday(value);
        break;

      case "link":
        setLink(value);
        break;
    }
  };
  console.log((data.use_date || "").split(" ~ ")[0]);
  //   <Container>
  //   <Titlesection>
  //     <MainTitle className="f24">{info.name}</MainTitle>

  //     {StatusConverter(info.open)}
  //   </Titlesection>
  // </Container>

  return (
    <Wrapper
      bgc="#ffffff"
      of={`hidden auto`}
      margin="10px 0 0 0"
      display={`inline-block`}
      width={`100%`}
      mw={`1000px`}
      mh={`calc(100vh - 72px)`}
      padding={`10px 0`}
    >
      <Container>
        {/* <Header>
        <Div
          all={!all}
          onClick={() => {
            setAll(false);
          }}
        >
          <p className="f16 weight_700">알림톡 전송</p>
        </Div>

        <Div
          all={all}
          onClick={() => {
            setAll(true);
          }}
        >
          <p className="f16 weight_700">문자메시지 전송</p>
        </Div>
      </Header> */}

        <Main>
          <Layout>
            <section>
              <h1 className="f24 weight_700">
                {data.message_send_type === "alimTalk"
                  ? "알림톡 정보"
                  : "문자메시지 정보"}
              </h1>
              <Wrapper margin="20px 0 0 0" width="100%" ai="flex-top">
                <BackgroundImageDiv>
                  <BackgroundImage
                    src={data.service_thumbnail}
                  ></BackgroundImage>
                </BackgroundImageDiv>
                <WrapperColmun
                  width="100%"
                  gap="0.5rem"
                  margin="0 0 0 20px"
                  className="f12"
                >
                  <Text className="f16">{data.service}</Text>
                  <WrapperRow ai="flex-top" jc="space-between">
                    <Text color="#818792" ai="flex-top">
                      결제번호
                    </Text>
                    <Wrapper width="calc(100% - 4.5rem)">
                      <Text>{data.payment_number}</Text>
                    </Wrapper>
                  </WrapperRow>
                  <WrapperRow ai="flex-top" jc="space-between">
                    <Text color="#818792" ai="flex-top">
                      결제일
                    </Text>
                    <Wrapper width="calc(100% - 4.5rem)">
                      <Text>{data.date}</Text>
                    </Wrapper>
                  </WrapperRow>
                  <WrapperRow ai="flex-top" jc="space-between">
                    <Text color="#818792" ai="flex-top">
                      이용정보
                    </Text>
                    <Wrapper width="calc(100% - 4.5rem)">
                      <Text>{(data.use_date || "").split(" ~ ")[0]}</Text>
                    </Wrapper>
                  </WrapperRow>
                  <WrapperRow ai="flex-top" jc="space-between">
                    <Text color="#818792" ai="flex-top">
                      레슨 장소
                    </Text>
                    <Wrapper width="calc(100% - 4.5rem)">
                      <Text>{data.service_location}</Text>
                    </Wrapper>
                  </WrapperRow>
                </WrapperColmun>
              </Wrapper>
              <PaymentCXBox className="f14">
                {/* <div className="cxContentBox">
                  <p className="f14">유저 검색</p>
                  <span className="cxContent">
                    <div className="cxManeger csSerch">
                      <input
                        onChange={(e) => setPartner(e.target.value)}
                        value={partner}
                        type="text"
                        maxLength={20}
                        className="f14"
                        placeholder="닉네임, 전화번호, 실명으로 검색"
                      />
                      <Icon
                        width={`calc(1rem * (17 / 14))`}
                        cursor={`pointer`}
                        src={SearchIcon}
                        // onClick={() => TextSearch()}
                      />
                    </div>
                  </span>
                </div> */}
                {/* <Ima */}
                <div className="cxContentBox">
                  <Text color="#3C3E41">유저</Text>
                  <span className="cxContent">
                    <WrapperRow
                      width="100%"
                      padding="10px 12px"
                      border="1px solid #dcdcdc"
                    >
                      <Text style={{ whiteSpace: "pre" }} color="#818792">
                        {data.user}
                        {`  `}
                        {data.user_phone}
                        {/* <Bar /> */}
                      </Text>
                    </WrapperRow>
                  </span>
                </div>
                <div className="cxContentBox">
                  <Text color="#3C3E41">강사</Text>
                  <span className="cxContent">
                    <WrapperRow
                      width="100%"
                      padding="10px 12px"
                      border="1px solid #dcdcdc"
                    >
                      <Text style={{ whiteSpace: "pre" }} color="#818792">
                        {data.partner}
                        {`  `}
                        {/* <Bar /> */}
                        {data.partner_phone}
                      </Text>
                    </WrapperRow>
                  </span>
                </div>
                <div className="cxContentBox">
                  <Text color="#3C3E41">발송시간</Text>
                  <span className="cxContent">
                    <Wrapper className="cxManeger f14">
                      <Text color="#818792">{data.message_date}</Text>
                    </Wrapper>
                  </span>
                </div>
                <div className="cxContentBox">
                  <Text color="#3C3E41">담당자 명</Text>
                  <span className="cxContent">
                    <Wrapper className="cxManeger">
                      <Text color="#818792">{data.message_manager}</Text>
                    </Wrapper>
                  </span>
                </div>
                <div className="cxContentBox">
                  <Text
                    color="#3C3E41"
                    style={{
                      alignSelf: "self-start",
                      marginTop: "1rem",
                    }}
                  >
                    알림톡 내용
                  </Text>

                  {/*  <span className="cxContent">
                    
                    <Template
                      type={template}
                      change={DataChange}
                      data={{
                        partner: partner,
                        month: month,
                        mnd: monthAday,
                        link: link,
                      }}
                    />
                  </span> */}

                  <span className="cxContent">
                    {/* <textarea
                      style={{
                        height: "355px",
                        width: "100%",
                        resize: "none",
                      }}
                      type="text"
                      value={data.message_content}
                      disabled
                      //   readonly
                      className="cxManeger f14"
                      maxLength={300}
                      placeholder="푸시 알림 내용을 적어주세요 *최대 300글자"
                    /> */}

                    <Wrapper
                      width="100%"
                      //   height="355px"
                      className="cxManeger cxBottomBox f14"
                    >
                      <Text>{data.message_content}</Text>
                    </Wrapper>
                  </span>
                </div>
              </PaymentCXBox>
            </section>

            {/* <BottomButtonBox>
            <Button
              text="초기화"
              clickHandler={ContentReset}
              width={`166px`}
              color={colors.white}
              fontcolor={colors.blue}
              className="f14"
            />

            <Button
              text={user == "alimtalk" ? "알림톡 전송" : "문자 전송"}
              clickHandler={messageSend}
              width={`166px`}
              className="f14"
            />
          </BottomButtonBox> */}
          </Layout>
        </Main>

        {/* </MainLayout> */}
      </Container>
    </Wrapper>
  );
};

export default AlarmTalkDetail;
