import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import MapService from "../service/map";

import wave from "../assets/icon/wave.png";
import Minus from "../assets/icon/minus.png";
import Arrow from "../assets/icon/selectArrow.png";
import GreySun from "../assets/icon/sun.png";
import GreyMoon from "../assets/icon/moon.png";

const DesignedActivitySchedule = ({ data, setData, idx, setDelete, deleteD, every }) => {
  const [open, setOpen] = useState(false);

  let SunTime = [];
  let MoonTime = [];

  let sunTimSplit = data.start.split(":");
  let moonTimSplit = data.end.split(":");
  let sunH = Number(sunTimSplit[0]);
  let sunM = Number(sunTimSplit[1]);
  let moonH = Number(moonTimSplit[0]);
  let moonM = Number(moonTimSplit[1]);
  let sunAM = sunH * 60 + sunM;
  let moonAM = moonH * 60 + moonM;

  let TimeCount = ((moonH - sunH) * 60 + (moonM - sunM)) / 30;

  for (var i = 0; i < TimeCount; i++) {
    let ScheduleTime = sunAM + 30 * i;

    let ScheduleHour = parseInt(ScheduleTime / 60);
    let ScheduleMinute = ScheduleTime % 60;

    let ScheduleHourText = ScheduleHour;
    let ScheduleMinuteText = ScheduleMinute;

    if (ScheduleHourText < 10) {
      ScheduleHourText = `0${ScheduleHourText}`;
    }

    if (ScheduleMinuteText == 0 || !ScheduleMinuteText) {
      ScheduleMinuteText = `00`;
    }

    let TimeData = {
      text: `${ScheduleHourText} : ${ScheduleMinuteText}`,
      value: `${ScheduleHourText}:${ScheduleMinuteText}`,
    };

    if (ScheduleHour < 12) {
      SunTime.push(TimeData);
    } else {
      MoonTime.push(TimeData);
    }
  }

  const dataa = { ...data };

  const copy = {
    id: dataa.id,
    title: dataa.title,
    start: dataa.start,
    end: dataa.end,
    address: dataa.address,
    extra: dataa.extra,
    day: dataa.day,
    time_list: dataa.time_list,
  };

  var keyText = "";

  for (let i = 0; i < data.day.length; i++) {
    if (i) {
      keyText = keyText + `, ${data.day[i]}`;
    } else {
      keyText = `${data.day[i]}`;
    }
  }

  useEffect(() => {
    //console.log(copy);
  }, [data]);

  const Change = (type, value) => {
    var co = [...every];
    co[idx][type] = value;
    setData(co);
  };

  const SettingYoil = (value) => {
    var cop = [...every];
    var cop2 = cop[idx];
    var cop3 = cop2.day;

    if (cop3.includes(value)) {
      cop3 = cop3.filter((x) => x != value);
    } else {
      cop3.push(value);
    }

    cop2.day = cop3;
    cop[idx] = cop2;

    setData(cop);
  };

  const SettingStartTime = (value) => {
    var cop = [...every];
    var cop2 = cop[idx];
    var list = data.time_list;
    if (list.includes(value)) {
      list = list.filter((x) => x != value);
    } else {
      list.push(value);
    }

    cop2.time_list = list;

    cop[idx] = cop2;
    setData(cop);
  };

  const DeleteSchedule = () => {
    var copy = [...every];
    var copyD = [...deleteD.schedule];

    copy.splice(idx, 1);

    copyD[copyD.length] = data;

    setDelete((prev) => ({ ...prev, schedule: copyD }));
    setData(copy);
  };

  const targetLocationChangeHandler = (input) => {
    var locationSigungu = input.sigungu.split(" ");

    if (locationSigungu.length > 1) {
      var loGu = locationSigungu[1].length > 2 ? locationSigungu[1].slice(0, -1) : locationSigungu[1];
      var loSi = locationSigungu[0].length > 2 ? locationSigungu[0].slice(0, -1) : locationSigungu[0];
      var loDong = input.bname.length > 2 ? input.bname.slice(0, -1) : input.bname;
    } else {
      var loGu = input.sigungu.length > 2 ? input.sigungu.slice(0, -1) : input.sigungu;
      var loSi = input.sido;
      var loDong = input.bname.length > 2 ? input.bname.slice(0, -1) : input.bname;
    }

    var copytotal = [...every];
    var copy = { ...data };

    copy.address = input.roadAddress;
    copy.si = loSi;
    copy.gu = loGu;
    copy.dong = loDong;

    copytotal[idx] = copy;

    setData(copytotal);
  };

  const mapService = new MapService(targetLocationChangeHandler);

  const SearchAddress = () => {
    mapService.open();
  };

  const ViewChanger = () => {
    if (open) {
      return (
        <div>
          <BackgroundContent jc={`space-between`} padding={`0`} ai={`center`}>
            <InputView
              onChange={(e) => Change("title", e.target.value)}
              value={`${data.title}`}
              placeholder={`일정 이름을 작성해 주세요 (예 : [평일] 강남 일정)`}
              br={`4`}
              padding={`calc(1rem * 10 / 14) calc(1rem * 12 / 14)`}
              pc={`#ACAFB5`}
              color={`#3C3E41`}
              width={`80%`}
              border={`1px solid #DCDCDC`}
            />

            <SectionArrow src={Arrow} open={open} onClick={() => setOpen((prev) => !prev)} />
          </BackgroundContent>

          <Divider height={`8`} color={"#ffffff"} />

          <BackgroundContent padding={`0`} ai={`flex-end`}>
            <StartTime>
              <div
                style={{
                  color: "#3383FF",
                  fontWeight: "500",
                  marginBottom: "4px",
                }}
              >
                활동시작
              </div>
              <Date placeholder="활동 시작 시간 입력" value={data.start} onChange={(e) => Change("start", e.target.value)} />
            </StartTime>

            <Wave src={wave} />

            <EndTime>
              <div
                style={{
                  color: "#3383FF",
                  fontWeight: "500",
                  marginBottom: "4px",
                }}
              >
                활동종료
              </div>
              <Date placeholder="활동 종료 시간 입력" value={data.end} onChange={(e) => Change("end", e.target.value)} />
            </EndTime>
          </BackgroundContent>

          <Divider height={`8`} color={"#ffffff"} />

          <BackgroundContent jc={`space-between`} padding={`0`} ai={`center`}>
            <TextContent
              cursor="pointer"
              onClick={() => SearchAddress()}
              jc={`center`}
              ai={`center`}
              width={`97px`}
              padding={`calc(1rem * 7.5/14) 0`}
              br={`8`}
              border={`1px solid #0E6DFF`}
            >
              <TextView color={`#0E6DFF`} fw={`600`}>{`주소찾기`}</TextView>
            </TextContent>

            <InputView
              value={data.address}
              readOnly
              br={`4`}
              padding={`calc(1rem * 6/14) calc(1rem *18/14)`}
              pc={`#ACAFB5`}
              color={`#3C3E41`}
              width={`calc(100% - 112px)`}
              bgc={`#F9F9F9`}
              border={`1px solid #DCDCDC`}
            />
          </BackgroundContent>

          <Divider height={`8`} color={"#ffffff"} />

          <BackgroundContent jc={`space-between`} padding={`0`} ai={`center`}>
            <InputView
              value={data.extra}
              maxLength={`100`}
              placeholder={`상세 주소를 입력해 주세요`}
              br={`4`}
              padding={`calc(1rem * 6/14) calc(1rem *18/14)`}
              pc={`#ACAFB5`}
              color={`#3C3E41`}
              width={`100%`}
              onChange={(e) => Change("extra", e.target.value)}
              border={`1px solid #DCDCDC`}
            />
          </BackgroundContent>

          <Divider height={`8`} color={"#ffffff"} />

          <BackgroundContent padding={`0`} ai={`center`}>
            <TextContent
              cursor="pointer"
              onClick={(e) => SettingYoil("일")}
              jc={`center`}
              width={`calc(1rem * 40/14)`}
              padding={`calc(1rem * 8/14) 0`}
              br={`8`}
              bgc={data.day.includes("일") ? `#FF9187` : "#ffffff"}
              border={"1px solid #dcdcdc"}
              mr={"calc(1rem * 9 / 14)"}
            >
              <TextView color={data.day.includes("일") ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`일`}</TextView>
            </TextContent>

            <TextContent
              cursor="pointer"
              onClick={(e) => SettingYoil("월")}
              jc={`center`}
              width={`calc(1rem * 40/14)`}
              padding={`calc(1rem * 8/14) 0`}
              br={`8`}
              border={"1px solid #dcdcdc"}
              bgc={data.day.includes("월") ? `#0E6DFF` : "#ffffff"}
              mr={"calc(1rem * 9 / 14)"}
            >
              <TextView color={data.day.includes("월") ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`월`}</TextView>
            </TextContent>

            <TextContent
              cursor="pointer"
              onClick={(e) => SettingYoil("화")}
              jc={`center`}
              width={`calc(1rem * 40/14)`}
              padding={`calc(1rem * 8/14) 0`}
              br={`8`}
              bgc={data.day.includes("화") ? `#0E6DFF` : "#ffffff"}
              border={`1px solid  #DCDCDC`}
              mr={"calc(1rem * 9 / 14)"}
            >
              <TextView color={data.day.includes("화") ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`화`}</TextView>
            </TextContent>

            <TextContent
              cursor="pointer"
              onClick={(e) => SettingYoil("수")}
              jc={`center`}
              width={`calc(1rem * 40/14)`}
              padding={`calc(1rem * 8/14) 0`}
              br={`8`}
              bgc={data.day.includes("수") ? `#0E6DFF` : "#ffffff"}
              border={`1px solid  #DCDCDC`}
              mr={"calc(1rem * 9 / 14)"}
            >
              <TextView color={data.day.includes("수") ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`수`}</TextView>
            </TextContent>

            <TextContent
              cursor="pointer"
              onClick={(e) => SettingYoil("목")}
              jc={`center`}
              width={`calc(1rem * 40/14)`}
              padding={`calc(1rem * 8/14) 0`}
              br={`8`}
              bgc={data.day.includes("목") ? `#0E6DFF` : "#ffffff"}
              border={`1px solid  #DCDCDC`}
              mr={"calc(1rem * 9 / 14)"}
            >
              <TextView color={data.day.includes("목") ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`목`}</TextView>
            </TextContent>

            <TextContent
              cursor="pointer"
              onClick={(e) => SettingYoil("금")}
              jc={`center`}
              width={`calc(1rem * 40/14)`}
              padding={`calc(1rem * 8/14) 0`}
              br={`8`}
              bgc={data.day.includes("금") ? `#0E6DFF` : "#ffffff"}
              border={`1px solid  #DCDCDC`}
              mr={"calc(1rem * 9 / 14)"}
            >
              <TextView color={data.day.includes("금") ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`금`}</TextView>
            </TextContent>

            <TextContent
              cursor="pointer"
              onClick={(e) => SettingYoil("토")}
              jc={`center`}
              width={`calc(1rem * 40/14)`}
              padding={`calc(1rem * 8/14) 0`}
              br={`8`}
              bgc={data.day.includes("토") ? `#FF9187` : "#ffffff"}
              border={`1px solid  #DCDCDC`}
              mr={"calc(1rem * 9 / 14)"}
            >
              <TextView color={data.day.includes("토") ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`토`}</TextView>
            </TextContent>

            <TextContent
              cursor="pointer"
              onClick={(e) => SettingYoil("공휴일")}
              jc={`center`}
              width={`calc(1rem * 40/7)`}
              padding={`calc(1rem * 8/14) 0`}
              br={`8`}
              bgc={data.day.includes("공휴일") ? `#FF9187` : "#ffffff"}
              border={`1px solid  #DCDCDC`}
              mr={"calc(1rem * 9 / 14)"}
            >
              <TextView color={data.day.includes("공휴일") ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`공휴일 포함`}</TextView>
            </TextContent>

            <TextContent
              cursor="pointer"
              onClick={(e) => SettingYoil("공휴일")}
              jc={`center`}
              width={`calc(1rem * 40/7)`}
              padding={`calc(1rem * 8/14) 0`}
              br={`8`}
              bgc={!data.day.includes("공휴일") ? `#0E6DFF` : "#ffffff"}
              border={`1px solid  #DCDCDC`}
              // mr={"calc(1rem * 9 / 14)"}
            >
              <TextView color={!data.day.includes("공휴일") ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`공휴일 제거`}</TextView>
            </TextContent>
          </BackgroundContent>

          <Divider height={`16`} color={"#ffffff"} />

          <BackgroundContent jc={`flex-start`} padding={`0`} ai={`center`}>
            <TextContent ai={`center`} width={`100%`}>
              <ImageIcon100 style={{ marginRight: "6px" }} width={`11px`} height={`11px`}>
                <img src={GreySun} />
              </ImageIcon100>

              <TextView color={`#ACAFB5`} fw={`500`}>{`오전`}</TextView>
            </TextContent>
          </BackgroundContent>

          <BackgroundContent width={`100%`} wrap={`wrap`} jc={`flex-start`} padding={`0`} ai={`center`}>
            {SunTime.map((item, idx) => (
              <TextContentT
                cursor="pointer"
                // index={(idx + 1) % 4}
                key={`${idx}_sunTime`}
                onClick={() => SettingStartTime(item.value)}
                jc={`center`}
                width={`calc(1rem * 72 / 14)`}
                padding={`calc(1rem * 8/14) 0`}
                br={`7`}
                bgc={data.time_list.includes(item.value) ? `#0E6DFF` : "#ffffff"}
                border={`1px solid  #DCDCDC`}
              >
                <TextView color={data.time_list.includes(item.value) ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`${item.text}`}</TextView>
              </TextContentT>
            ))}
          </BackgroundContent>

          <BackgroundContent jc={`flex-start`} padding={`0`} ai={`center`}>
            <TextContent ai={`center`} width={`100%`}>
              <ImageIcon100 style={{ marginRight: "6px" }} width={`11px`} height={`11px`}>
                <img src={GreyMoon} />
              </ImageIcon100>

              <TextView color={`#ACAFB5`} fw={`500`}>{`오후`}</TextView>
            </TextContent>
          </BackgroundContent>

          <Divider height={`10`} color={"#ffffff"} />

          <BackgroundContent width={`100%`} wrap={`wrap`} jc={`flex-start`} padding={`0`} ai={`center`}>
            {MoonTime.map((item, idx) => (
              <TextContentT
                cursor="pointer"
                // index={(idx + 1) % 4}
                key={`${idx}_moonTime`}
                onClick={() => SettingStartTime(item.value)}
                jc={`center`}
                width={`calc(1rem * 72 / 14)`}
                padding={`calc(1rem * 8/14) 0`}
                br={`7`}
                bgc={data.time_list.includes(item.value) ? `#0E6DFF` : "#ffffff"}
                border={`1px solid  #DCDCDC`}
              >
                <TextView color={data.time_list.includes(item.value) ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`${item.text}`}</TextView>
              </TextContentT>
            ))}
          </BackgroundContent>

          <Divider height={`24`} color={"#ffffff"} />

          <BackgroundContent cursor="pointer" onClick={() => DeleteSchedule()} jc={`flex-end`} padding={`0`} ai={`center`}>
            <Button color="#FF9187">
              액티비티 스케줄 삭제하기 <Symbol src={Minus} />
            </Button>
          </BackgroundContent>
        </div>
      );
    } else {
      return (
        <ShortBox onClick={() => setOpen((prev) => !prev)}>
          <div style={{ width: "95%" }}>
            <ShortFirstLine>
              <ShortScheduleName>{data.title}</ShortScheduleName>
              <ShortWorkingTime>
                {data.start} ~ {data.end}
              </ShortWorkingTime>
            </ShortFirstLine>
            <div style={{ height: "6px" }} />
            <ShortSecondLine>
              <ShortLocation className="f12">{`${data.address} ${data.extra}`}</ShortLocation>
              <TimeInfoCollector className="f12">
                <DayPart>
                  <span style={{ color: "#3383FF", marginRight: "5px" }}>{keyText}</span>
                  액티비티 시간:
                </DayPart>
                <TimePart></TimePart>
              </TimeInfoCollector>
            </ShortSecondLine>
          </div>
          <SectionArrow src={Arrow} open={open} />
        </ShortBox>
      );
    }
  };
  return <Container>{ViewChanger()}</Container>;
};

export default DesignedActivitySchedule;

const Container = styled.div`
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  padding: calc(1rem * 24 / 14) calc(1rem * 16 / 14);
  margin-bottom: 10px;
`;

const ShortBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const ShortFirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ShortScheduleName = styled.div`
  font-weight: 500;
  color: #3c3e41;
`;

const ShortWorkingTime = styled.div`
  font-weight: 500;
  color: #3383ff;
`;

const ShortSecondLine = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ShortLocation = styled.div`
  font-weight: 500;
  color: #818792;
`;

const TimeInfoCollector = styled.div`
  display: flex;
  font-weight: 500;
`;

const DayPart = styled.div`
  color: #818792;
`;

const TimePart = styled.div`
  color: #818792;
`;

const BackgroundContent = styled.div`
  width: 100%;
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  position: relative;
  cursor: ${({ cursor }) => cursor && `${cursor}`};
`;

const TextContent = styled.div`
  width: ${({ width }) => width && `${width}`};
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
  margin-right:${({ mr }) => mr && `${mr}`};
  cursor: ${({ cursor }) => cursor && `${cursor}`};
`;

const TextContentT = styled.div`
  width: ${({ width }) => width && `${width}`};
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
  margin-right : 9px;
  margin-bottom: 9px;
  cursor: ${({ cursor }) => cursor && `${cursor}`};
`;

const TextView = styled.span`
  width: ${({ width }) => width && `${width}`};
  text-align: ${({ ta }) => ta && `${ta}`};
  color: ${({ color }) => color && `${color}`};
  font-weight: ${({ fw }) => fw && `${fw}`};
  font-size: ${({ fs }) => fs && `${fs}`};
  line-height: ${({ lh }) => lh && `${lh}`};
  display: inline-block;
  text-overflow: ${({ to }) => to && `${to}`};
  overflow: hidden;
  white-space: ${({ ws }) => ws && `${ws}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
`;

const InputView = styled.input`
  width: ${({ width }) => width && `${width}`};
  border: ${({ border }) => border && `${border}`};
  text-align: ${({ ta }) => ta && `${ta}`};
  color: ${({ color }) => color && `${color}`};
  font-weight: ${({ fw }) => fw && `${fw}`};
  font-size: ${({ fs }) => fs && `${fs}`};
  line-height: ${({ lh }) => lh && `${lh}`};
  border-radius: ${({ br }) => br && `${br}px`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  display: inline-block;
  overflow: hidden;
  padding: ${({ padding }) => padding && `${padding}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
  ::placeholder {
    color: ${({ pc }) => pc && `${pc}`};
  }
  ::-ms-input-placeholder {
    color: ${({ pc }) => pc && `${pc}`};
  }
`;

const Divider = styled.div`
  height: ${({ height }) => height && `${height}px`};
  background-color: ${({ color }) => color && `${color}`};
  width: 100%;
`;

const StartTime = styled.div``;

const EndTime = styled.div``;

const Wave = styled.img`
  width: 2rem;
  height: 2rem;
`;

const Date = styled.input`
  display: flex;
  align-items: center;
  padding: calc(1rem * 8 / 14) calc(1rem * 8 / 14);
  border: 1px solid #dcdcdc;
  font-weight: 500;
  line-height: 100% !important;
  height: calc(1rem * 28 / 14);
`;

const Button = styled.div`
  border: 1px solid ${({ color }) => color};
  color: ${({ color }) => color};
  padding: calc(1rem * 7 / 14) calc(1rem * 12 / 14);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
`;

const Symbol = styled.img`
  width: 1rem;
  height: 1rem;
  margin-left: calc(1rem * 4 / 14);
`;

const SectionArrow = styled.img`
  width: calc(1rem * 18 / 14);
  height: calc(1rem * 18 / 14);
  transform: ${({ open }) => (open ? "rotate(-180deg)" : "")};
  transition: all 0.3s ease;
  cursor: pointer;
`;

const ImageIcon100 = styled.div`
  width: ${({ width }) => width && `${width}`};
  height: ${({ height }) => height && `${height}`};
  display: inline-block;

  img {
    width: ${({ width }) => width && `${width}`};
    display: inline-block;
  }
`;
