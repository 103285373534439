import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({
  type = "wau",
  labels,
  list,
  style,
  responsive = true,
  color = "#8F8FE6",
}) => {
  const ref = useRef();

  const options = {
    responsive,
    interaction: {
        intersect: false,
        mode: 'index',
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        data: list,
        backgroundColor: color,
      },
    ],
  };

  if (type === "wau") {
    return <Bar ref={ref} options={options} data={data} style={style} />;
  }
};

export default BarChart;
