import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LocalStorage from "../service/localStorage";
import { useKakao } from "./kakao";
import axios from "axios";

const publicRoutes = ["/"];

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const localStorage = new LocalStorage();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [uid, setUid] = useState(localStorage.getItem());
  const [lc, setLc] = useState(window.localStorage.getItem("lc"));
  const [token, setToken] = useState(window.localStorage.getItem("token"));

  const { kakaoService } = useKakao();

  const loginHandler = (provider) => {
    /**
     * 카카오 로그인
     */

    kakaoService.login(
      (success) => {
        // 로컬 스토리지 저장
        // state에 저장
        // viewmore로 리다이렉션
        // 에러시 /로 리다이렉션

        const { access_token } = success;

        const frm = new FormData();
        frm.append("mode", "login");
        frm.append("kakao_code", access_token);

        axios
          .post("https://ai.semos.kr/semos_admin/auth/kakao", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((data) => {
            var Value = data.data;
            var status = Value.status;

            if (status) {
              window.localStorage.setItem("token", Value.token);
              window.localStorage.setItem("name", Value.user_data.user_name);
              window.localStorage.setItem("age", Value.user_data.user_age);
              window.localStorage.setItem(
                "gender",
                Value.user_data.user_gender
              );
              window.localStorage.setItem("email", Value.user_data.user_email);
              window.localStorage.setItem("phone", Value.user_data.user_phone);
              window.localStorage.setItem("lc", "yes");
              setUid(access_token);
              setLc("yes");
              setToken(Value.token);
              localStorage.setItem(access_token);
              navigate("/dashboard", { replace: true });
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              alert(Value.status_message);
            }
          });

        /*if (uid) {
          navigate("/dashboard");
        } else {
          navigate("/");
        }*/
      },
      (error) => {
        console.log(error);
        navigate("/");
      }
    );
  };

  const logoutHandler = () => {
    /**
     * 카카오 로그아웃
     */
    kakaoService.logout((res) => {
      // 로컬 스토리지 삭제
      window.localStorage.setItem("login-token", "");

      // state에 삭제
      setUid("");

      // /로 리다이렉션
      if (res) {
        navigate("/");
      }
    });
  };

  // 로그인 기능 구현
  /*useEffect(() => {
    if (uid && lc === "yes" && token) {
      navigate("/dashboard");
    } else {
      navigate(pathname);
    }
  }, [uid, lc, token]);*/

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!uid,
        loginHandler,
        logoutHandler,
        localStorage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);

/* ProtectRouter: auth 인증 체크 */
export const ProtectRouter = ({ children }) => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated && !publicRoutes.some((route) => route === pathname)) {
    return <div>권한이 없습니다.</div>;
  }

  return children;
};
