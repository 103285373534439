import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";

// components
import MainHeader from "../../components/header";
import MainLayout from "../../layouts/mainLayout";
import Border from "../../modules/border";
import Button from "../../modules/button";
import DropDown from "../../components/designedComponents/dropDown";
import {
  TableFilterContainer,
  TableFilterContent,
  TableWrapper,
  Wrapper,
  AbsoluteWrapper,
  Icon,
  Text,
  TableContent,
  TableFooter,
  TableColumn,
  TableFooterPaging,
  WrapperRow,
  WrapperColmun,
} from "../../layouts/layoutStorage";
import colors from "../../styles/constants/colors";

// icon
import PaymentSport from "../../assets/icon/payment_sport.png";
import PaymentLocation from "../../assets/icon/payment_location.png";
import PaymentPeople from "../../assets/icon/payment_people.png";
import EmptyStar from "../../assets/icon/star_empty.png";
import FullStar from "../../assets/icon/star_full.png";

// const CUSTOMER_PER_PAGE = 10;
// const CUSTOMER_CARD_HEIGHT = 50;

const Container = styled.div`
  padding: 28px 36px 0 36px;
  /* position: relative; */
  width: 100%;
  height: 100%;
`;
const Main = styled.main`
  width: 100%;
  height: calc(100% - 48px);
  /* padding: 0 20px;
  margin-top: 20px; */

  ul {
    width: 100%;
  }
`;

const Layout = styled.div`
  /* min-width: 646px; */

  width: 100%;
  height: 100%;
  max-width: 1000px;
  /* height: 100%; */
  position: relative;
`;

const Bar = styled.div`
  width: 1px;
  height: 16px;
  margin: 0 8px;
  background: #e6e6e6;
`;

const BottomButtonBox = styled.div`
  position: absolute;
  bottom: 52px;
  right: 20px;
  /* align-self: flex-end; */

  button:first-child {
    margin-right: 17px;
  }
`;

const PaymentCXBox = styled.div`
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  .cxContentBox {
    width: 100%;

    display: flex;
    align-items: top;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    p:first-child {
      color: ${colors.mediumGray};
    }
    .cxButtonBox {
      width: calc(100% - 8rem);
      display: flex;
      justify-content: space-between;
      gap: 12px;
    }

    .cxContent {
      width: calc(100% - 8rem);
      display: inline-block;
      background-color: #f9f9f9;

      .cxType {
        padding: 10px 12px;
        display: inline-block;
        border: 3px solid rgba(132, 140, 148, 1);
        cursor: pointer;
        font-size: 17px;
        font-weight: 500;
      }
      .cxType:first-child {
        margin-right: 15px;
      }
      .typeActive {
        border: 3px solid rgba(0, 149, 255, 1);
      }
      .cxBottomBox {
        min-height: 355px;
        max-height: 650px;
        overflow: auto;
      }
      .cxManeger {
        width: 100%;
        display: inline-block;
        white-space: pre-wrap;

        padding: 10px 12px;
        border: 1px solid #dcdcdc;
        /* font-size: 17px;
        font-weight: 500; */
        /* background-color: #ffff; */
      }

      .csSerch {
        display: inline-flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        justify-content: space-between;
        align-items: center;
        input {
          width: 100%;
        }
        display: flex;
        flex-direction: row;

        justify-content: space-between;
      }
      .cxMessage {
        width: 100%;
        display: inline-block;
        padding: 10px 12px;
        border: 1px solid rgba(132, 140, 148, 1);
        font-size: 17px;
        font-weight: 500;
        background-color: #f9f9f9;
        height: 300px;
        resize: none;
      }
    }
  }
`;
const SaveButton = styled.div`
  background-color: #0e6dff;
  padding: 6.5px 12px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 8px;
  line-height: 142%;
  cursor: pointer;
`;

const StatusBox = styled.div`
  padding: 3px 14px;
  border: 1px solid;
  border-color: ${({ bc }) => bc};
  background-color: ${({ bgc }) => bgc};
  color: ${({ c }) => c};
  border-radius: 16px;
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

const ReviewImg = styled.img`
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
`;

const BackgroundImage = styled.img`
  width: calc(1rem * 17);
  height: calc(1rem * 10);
  background: #dcdcdc;
  border-radius: 8px;
`;

const ReviewDetail = () => {
  const { n } = useParams();
  const [data, setData] = useState([]);
  const [star, setStar] = useState(0);
  const [status, setStatus] = useState("0");

  const Axios = () => {
    let token = window.localStorage.getItem("token");
    const frm = new FormData();
    frm.append("mode", "main");
    frm.append("token", token);
    frm.append("review_id", n);
    axios
      .post("https://ai.semos.kr/semos_admin/v2/review/ReviewDetail", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        setData(Value.review_data);
        setStatus(Value.review_data.status);
        setStar(Number(Value.review_data.rating));
      });
  };

  useEffect(() => {
    Axios();
  }, [n]);

  const changeStatus = () => {
    var token = window.localStorage.getItem("token");
    const frm = new FormData();
    frm.append("mode", "status_change");
    frm.append("token", token);
    frm.append("id", n);
    frm.append("status", status !== data.status ? data.status : status === "1" ? "0" : "1");
    axios
      .post("https://ai.semos.kr/semos_admin/review/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var status = Value.status;
        var statusMessage = Value.status_message;
        if (status == "success") {
          alert(statusMessage);
          Axios();
        } else if (status == "waring") {
          alert(statusMessage);
        }
      });
  };

  const statusChecker = () => {
    if (data.status == "1") {
      return (
        <StatusBox bc="#3383FF" bgc="#E5F0FF" c="#3383FF" className="f14">
          <span className="f4">●</span>&nbsp;노출
        </StatusBox>
      );
    } else {
      return (
        <StatusBox bc="#ACAFB5" bgc="#F9F9F9" c="#818792" className="f14">
          <span className="f4">■</span>&nbsp;비노출
        </StatusBox>
      );
    }
  };

  const starComment = (type) => {
    switch (type) {
      case 5:
        return "정말 최고예요";

      case 4:
        return "좋았어요";

      case 3:
        return "괜찮아요";

      case 2:
        return "아쉬워요";

      case 1:
        return "개선이 필요해요";
    }
  };

  const statusCase = (status) => {
    switch (status) {
      case "1":
        return "노출";

      case "0":
        return "비노출";
    }
  };

  return (
    <Wrapper
      bgc="#ffffff"
      of={`hidden auto`}
      margin="10px 0 0 0"
      display={`inline-block`}
      width={`100%`}
      mw={`1000px`}
      mh={`calc(100vh - 72px)`}
      padding={`10px 0`}
    >
      <Container>
        <Main>
          <Layout>
            <section>
              <Wrapper width={`100%`} jc={`space-between`}>
                <Wrapper style={{ whiteSpace: "pre" }}>
                  <h1 className="f24 weight_700">리뷰 내용 </h1>
                  {statusChecker()}
                </Wrapper>
                <Wrapper>
                  <Wrapper className="f14" margin="0 10px 0 0">
                    <Text ws="pre" color="#ACAFB5">
                      최종 수정일 :{" "}
                    </Text>
                    <Text color="#818792">{data.updated}</Text>
                  </Wrapper>
                  <DropDown
                    data={statusCase(status)}
                    setData={setStatus}
                    option={[
                      { text: "노출", value: "1" },
                      { text: "비노출", value: "0" },
                    ]}
                    // type={`object`}
                    defaultText={statusCase(data.status)}
                    no={false}
                  />
                  <SaveButton className="f12" onClick={changeStatus}>
                    저장
                  </SaveButton>
                  {/* <Button
                  text="전송"
                  //   clickHandler={messageSend}
                  width={`calc(1rem * (17 / 14))`}
                  height={`calc(1rem * (17 / 14))`}
                  //   height={`30px`}
                  className="f14"
                /> */}
                </Wrapper>
              </Wrapper>

              <WrapperColmun>
                <Wrapper margin="34px 0 16px 0">
                  <Text color={`#3C3E41`} weight={`700`} className="f16">
                    액티비티 정보
                  </Text>
                </Wrapper>
                <Wrapper width="100%" ai="flex-top">
                  <div>
                    <BackgroundImage src={data.thumbnail}></BackgroundImage>
                  </div>
                  <WrapperColmun width="100%" gap="0.5rem" margin="0 0 0 20px" className="f12">
                    <Text
                      //   ai={`flex-start`}
                      color={`#3C3E41`}
                      weight={`600`}
                      className={`f16`}
                    >
                      {data.name}
                    </Text>
                    <Wrapper width={`100%`}>
                      <Wrapper width={`10.5rem`}>
                        <Icon margin={`0 4px 0 0`} src={PaymentLocation} width={`16px`} />
                        <Text color={`#818792`} weight={`500`} className={`f12`}>
                          예약 장소{" "}
                        </Text>
                      </Wrapper>

                      <Wrapper width={`calc(100% - 4.5rem)`}>
                        <Text color={`#3C3E41`} weight={`500`} className={`f12`}>
                          {data.location?.city} {data.location?.district}
                        </Text>
                      </Wrapper>
                    </Wrapper>

                    <Wrapper width={`100%`}>
                      <Wrapper width={`10.5rem`}>
                        <Icon margin={`0 4px 0 0`} src={PaymentSport} width={`16px`} />
                        <Text ws="pre" color={`#0E6DFF`} weight={`500`} className={`f12`}>
                          스포츠{" "}
                        </Text>
                        <Text ws="pre" color={`#818792`} weight={`500`} className={`f12`}>
                          &nbsp;| 유형{" "}
                        </Text>
                      </Wrapper>

                      <Wrapper width={`calc(100% - 4.5rem)`}>
                        <Text color={`#0E6DFF`} weight={`500`} className={`f12`}>
                          {data.sport}
                        </Text>
                        {data.duration ? (
                          <Text color={`#818792`} weight={`500`} className={`f12`}>
                            &nbsp;| {data.duration}{" "}
                          </Text>
                        ) : (
                          <></>
                        )}
                        {data.type ? (
                          <Text color={`#818792`} weight={`500`} className={`f12`}>
                            &nbsp;| {data.type}{" "}
                          </Text>
                        ) : (
                          <></>
                        )}
                      </Wrapper>
                    </Wrapper>

                    <Wrapper width={`100%`}>
                      <Wrapper width={`10.5rem`}>
                        <Icon margin={`0 4px 0 0`} src={PaymentPeople} width={`16px`} />
                        <Text color={`#818792`} weight={`500`} className={`f12`}>
                          예약 인원{" "}
                        </Text>
                      </Wrapper>

                      <Wrapper width={`calc(100% - 4.5rem)`}>
                        {data.people ? (
                          <Text color={`#3C3E41`} weight={`500`} className={`f12`}>
                            {data.people}인
                          </Text>
                        ) : (
                          <></>
                        )}
                      </Wrapper>
                    </Wrapper>
                  </WrapperColmun>
                </Wrapper>
              </WrapperColmun>

              <WrapperColmun width="100%">
                <Wrapper margin="52px 0 31px 0">
                  <Text color={`#3C3E41`} weight={`700`} className="f16">
                    리뷰 정보
                  </Text>
                </Wrapper>

                <Wrapper width={`100%`} jc={`space-between`} margin="0 0 16px 0">
                  <WrapperRow gap="8px" ai="center" jc="center" className="f14">
                    <WrapperRow gap="2px">
                      {data.rating == 5 ? (
                        <>{Array(5).fill(<Icon width={`1.3rem`} src={FullStar} />)}</>
                      ) : (
                        <>
                          {Array(star).fill(<Icon width={`1.3rem`} src={FullStar} />)}
                          {Array(5 - star).fill(<Icon width={`1.3rem`} src={EmptyStar} />)}
                        </>
                      )}
                      {/* {Array(5 - parseInt(data?.rating && data?.rating)).fill(
                      <Icon width={`16px`} src={FullStar} />
                    )} */}
                    </WrapperRow>
                    <Text color="#3383FF">{starComment(star)}</Text>
                  </WrapperRow>
                  <WrapperRow>
                    <Text ws="pre" color="#ACAFB5">
                      리뷰 등록일 :{" "}
                    </Text>
                    <Text color="#818792">{data.created}</Text>
                  </WrapperRow>
                </Wrapper>

                <Wrapper
                  position="relative"
                  width="100%"
                  padding="16px 16px 48px 16px"
                  border="1px solid #DCDCDC"
                  radius="8px"
                  bgc="#F9F9F9"
                  margin="0 0 16px 0"
                >
                  <Text ws="pre-line" color="#3C3E41" className="f10">
                    {data.content}
                  </Text>

                  <AbsoluteWrapper right="12px" bottom="10px" className="f12">
                    <Text color="#3C3E41">{data.content?.length}</Text>
                    <Text ws="pre" color="#ACAFB5">{` / 500 (최소 20자)`}</Text>
                  </AbsoluteWrapper>
                </Wrapper>

                <ReviewImgBox>
                  {data.image?.map((item, idx) => (
                    <ReviewImg src={item}></ReviewImg>
                  ))}
                </ReviewImgBox>
              </WrapperColmun>

              <WrapperColmun width="100%">
                <Wrapper margin="52px 0 31px 0">
                  <Text color={`#3C3E41`} weight={`700`} className="f16">
                    파트너 피드백
                  </Text>
                </Wrapper>

                <Wrapper width={`100%`} jc={`space-between`} margin="0 0 16px 0">
                  <WrapperRow gap="8px" ai="center" jc="center" className="f14">

                  </WrapperRow>

                  <WrapperRow>
                    <Text ws="pre" color="#ACAFB5">
                      피드백 등록일 :{" "}
                    </Text>
                    <Text color="#818792">{data.partner_created}</Text>
                  </WrapperRow>
                </Wrapper>

                <Wrapper
                  position="relative"
                  width="100%"
                  padding="16px 16px 48px 16px"
                  border="1px solid #DCDCDC"
                  radius="8px"
                  bgc="#F9F9F9"
                  margin="0 0 16px 0"
                >
                  <Text ws="pre-line" color="#3C3E41" className="f10">
                    {data.partner_content}
                  </Text>

                  {/*<AbsoluteWrapper right="12px" bottom="10px" className="f12">
                    <Text color="#3C3E41">{data.content?.length}</Text>
                    <Text ws="pre" color="#ACAFB5">{` / 500 (최소 20자)`}</Text>
                  </AbsoluteWrapper>*/}
                </Wrapper>
              </WrapperColmun>

            </section>
          </Layout>
        </Main>
      </Container>
    </Wrapper>
  );
};

export default ReviewDetail;

const ReviewImgBox = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
`;
