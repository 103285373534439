import styled from "styled-components";
import base from "../../styles/constants/base";

const Container = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  max-width: calc(1050px + 20px);
  height: 600px;
  margin: 0 auto;
  padding: 0 0 10px 0;
`;

const DashboardLayout = ({ children }) => {
  return (
    <Container>
      <Wrapper>{children}</Wrapper>
    </Container>
  );
};

export default DashboardLayout;
