import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import useList from "../../../../hooks/useList";
import useInput from "../../../../hooks/useInput";
import useImage from "../../../../hooks/useImage";

import MainLayout from "../../../../layouts/mainLayout";
import MainHeader from "../../../../components/header";
import ImageInput from "../../../../components/imageInput";
import Button from "../../../../modules/button";

import {
  Main,
  Toolbar,
  SelectBox,
  TextBox,
  Textarea,
  Preview,
  InputBox,
  ColorInputBox,
  BoldButton,
  ItalicButton,
  UnderlineButton,
  WhitespaceButton,
  BackgroundColorInputBox,
  Footer,
} from "./style";

import selectChevron from "../../../../assets/icon/select-chevron.svg";
import cameraIcon from "../../../../assets/icon/camera.svg";
import deleteIcon from "../../../../assets/icon/delete-circle.svg";

const PartnerLessonUsageInfoEditorPage = () => {
  /**
   * lid가 있으면 기존의 레슨 이용정보 수정
   */
  const { lid, pid } = useParams();

  const colorRef = useRef();
  const bgColorRef = useRef();

  const colorHandler = () => {
    colorRef.current.click();
  };

  const bgColorHandler = () => {
    bgColorRef.current.click();
  };

  const {
    list: contentsList,
    setList: setContentsList,
    addHandler: contentsAddHandler,
    editHandler: contentsEditHandler,
    deleteHandler: contentsDeleteHandler,
  } = useList([]);

  const [deleteData, setDeleteData] = useState([]);

  const contentDelete = (number) => {
    var contentList = [...contentsList]

    var deleteList = [...deleteData]

    var deleteContent = contentList[number]

    deleteList[deleteList.length] = deleteContent

    contentList.splice(number, 1)

    setDeleteData(deleteList)

    setContentsList(contentList)
  }

  const { image, setImage, imageChangeHandler, change } = useImage({
    img: {
      base: "",
      files: "",
      width : "100"
    },
  });

  const { value, setValue, inputChangeHandler } = useInput({
    type: "text",
    size: "font_17",
    align: "align_center",
    color: "color_black",
    bold: "",
    italic: "",
    underline: "",
    backgroundColor: "bg_transparent",
    text: "",
  });

  /*const deleteHandler = () => {
    console.log("이용 정보 삭제하기");
  };

  const saveHandler = () => {
    console.log("이용 정보 저장하기");
  };*/

  const addHandler = () => {
    if (value.type === "text" && value.text.trim() === "") {
      return;
    }

    if (value.type === "image" && value.base === "" && value.files === "") {
      return;
    }

    contentsAddHandler({
      id: new Date(),
      status : 'new',
      ...value,
    });

    setImage({
      img: {
        base: "",
        files: "",
        width : "100"
      },
    });

    setValue({
      type: "text",
      size: "font_17",
      align: "align_center",
      color: "color_black",
      bold: false,
      italic: false,
      underline: false,
      backgroundColor: "bg_transparent",
      text: "",
    });
  };

  const addSpaceHandler = () => {
    contentsAddHandler({
      id: new Date(),
      status : 'new',
      type: "space",
    });
  };

  const imageHandler = (e) => {
    const { name, files } = e.target;

    let reader = new FileReader();

    if (files[0]) {
      reader.readAsDataURL(files[0]);
    }

    reader.onloadend = () => {
      const previewImgUrl = reader.result;

      contentsAddHandler({
        id: new Date(),
        status : 'new',
        type: "image",
        align: value.align,
        base: previewImgUrl,
        files,
      });
    };

    setValue({
      type: "text",
      size: "font_17",
      align: "align_center",
      color: "color_black",
      bold: false,
      italic: false,
      underline: false,
      backgroundColor: "bg_transparent",
      text: "",
    });
  };

  /*useEffect(() => {
    setValue((prev) => ({
      ...prev,
      base: image.img.base,
      files: image.img.files,
    }));
  }, [image]);*/

  useEffect(() => {
    var token = window.localStorage.getItem("token");

    axios.post("https://ai.semos.kr/semos_admin/partner/data", {
          mode: "lesson_page_info_load",
          token: token,
          partner_number: pid,
          lesson_number: lid,
    },
        {headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((data) => {
        var Value = data.data;
        var status = Value.status;
        var statusMessage = Value.status_message;

        if (status == "success") {
          setContentsList(Value.lesson_content);
        } else if (status == "waring") {
          alert(statusMessage);
        }
    });
  }, []);

    // 레슨 상세 정보 저장
    const lessonSave = () => {
      if(window.confirm('이용 상세 정보를 저장하시겠습니까?')) {
        var token = window.localStorage.getItem("token");

        axios.post("https://ai.semos.kr/semos_admin/partner/data", {
              mode: "lesson_page_info",
              token: token,
              partner_number: pid,
              lesson_number: lid,
              lesson_content : contentsList,
              delete_content : deleteData
        },
            {headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }).then((data) => {
            var Value = data.data;
            var status = Value.status;
            var statusMessage = Value.status_message;

            if (status == "success") {
              alert(statusMessage);
            } else if (status == "waring") {
              alert(statusMessage);
            }
        });
      }
    };

 

    var FontSize = value.size;
    var Align = value.align;
    var Color = value.color;
    var BackgroundColor = value.backgroundColor;
    var Bold = value.bold == true ? 'weight_500' : "weight_400" ;
    var Italic = value.italic == true ? 'font_italic' : "" ;
    var Underline = value.underline == true ? 'font_underline' : "" ;

  return (
    <MainLayout>
      <MainHeader title="이용 상세 정보"></MainHeader>

      <Main>
        <Toolbar>
          <SelectBox>
            <select
              name="size"
              defaultValue="font_17"
              value={value.size}
              onChange={inputChangeHandler}
            >
              <option value="font_28">28(24)px</option>
              <option value="font_24">24(18)px</option>
              <option value="font_20">20(16)px</option>
              <option value="font_17">17(14)px</option>
              <option value="font_15">15(12)px</option>
              <option value="font_13">13(10)px</option>
            </select>

            <img src={selectChevron} alt="아이콘" />
          </SelectBox>

          <SelectBox>
            <select
              name="align"
              defaultValue="center"
              value={value.align}
              onChange={inputChangeHandler}
            >
              <option value="align_left">left</option>
              <option value="align_center">center</option>
              <option value="align_right">right</option>
            </select>

            <img src={selectChevron} alt="아이콘" />
          </SelectBox>

          <SelectBox>
            <select
              name="color"
              defaultValue="color_black"
              value={value.color}
              onChange={inputChangeHandler}
            >
              <option value="color_blue">Blue</option>
              <option value="color_light_blue">Light Blue</option>
              <option value="color_red">Red</option>
              <option value="color_pink">Pink</option>
              <option value="color_black">Black</option>
              <option value="color_light_black">Light Black</option>
              <option value="color_grey">Grey</option>
              <option value="color_yellow">Yellow</option>
              <option value="color_green">Green</option>
              <option value="color_purple">Purple</option>
              <option value="color_white">White</option>
            </select>

            <img src={selectChevron} alt="아이콘" />
          </SelectBox>


          <SelectBox>
            <select
              name="backgroundColor"
              defaultValue="bg_transparent"
              value={value.backgroundColor}
              onChange={inputChangeHandler}
            >
              <option value="bg_transparent">선택안함 (B)</option>
              <option value="bg_blue">Blue (B)</option>
              <option value="bg_light_blue">Light Blue (B)</option>
              <option value="bg_red">Red (B)</option>
              <option value="bg_pink">Pink (B)</option>
              <option value="bg_black">Black (B)</option>
              <option value="bg_grey">Grey (B)</option>
              <option value="bg_yellow">Yellow (B)</option>
              <option value="bg_green">Green (B)</option>
              <option value="bg_purple">Purple (B)</option>
            </select>

            <img src={selectChevron} alt="아이콘" />
          </SelectBox>

          <BoldButton
            active={value.bold}
            type="button"
            onClick={() =>
              setValue((prev) => ({
                ...prev,
                bold: !prev.bold,
              }))
            }
          >
            B
          </BoldButton>

          <ItalicButton
            active={value.italic}
            type="button"
            onClick={() =>
              setValue((prev) => ({
                ...prev,
                italic: !prev.italic,
              }))
            }
          >
            I
          </ItalicButton>

          <UnderlineButton
            active={value.underline}
            type="button"
            onClick={() =>
              setValue((prev) => ({
                ...prev,
                underline: !prev.underline,
              }))
            }
          >
            U
          </UnderlineButton>

          {/*<ColorInputBox>
            <input
              type="color"
              name="color"
              value={value.color}
              onChange={inputChangeHandler}
              ref={colorRef}
            />

            <div onClick={colorHandler}>
              <span>T</span>
            </div>
          </ColorInputBox>

          <BackgroundColorInputBox>
            <input
              type="color"
              name="backgroundColor"
              value={value.backgroundColor}
              onChange={inputChangeHandler}
            />

            <div onClick={bgColorHandler}>
              <span>T</span>
            </div>
          </BackgroundColorInputBox>*/}

          <ImageInput
            type="editor"
            name="img"
            width="40px"
            height="40px"
            changeHandler={imageHandler}
          />

          <WhitespaceButton type="button" onClick={addSpaceHandler}>
            여백
          </WhitespaceButton>
        </Toolbar>

        <Preview>
          <ul>
            {contentsList.map((item, idx) =>
              renderContents(item, idx, contentDelete)
            )}
          </ul>
        </Preview>

        <InputBox>
          <TextBox>
            <Textarea
              name="text"
              type="text"
              value={value.text}
              onChange={inputChangeHandler}
              className={FontSize + ' ' + Align + ' ' + Color + ' ' + Bold + ' ' + Italic + ' ' + Underline + ' ' + BackgroundColor}
            />
            <button type="button" onClick={(e) => addHandler(e)}>
              추가
            </button>
          </TextBox>
        </InputBox>
      </Main>

      <Footer>
        {/* <Button
          text="이용 정보 삭제하기"
          clickHandler={deleteHandler}
          width={`230px`}
        /> */}
        <Button
          text="이용 정보 저장하기"
          clickHandler={lessonSave}
          width={`230px`}
        />
      </Footer>
    </MainLayout>
  );
};

export default PartnerLessonUsageInfoEditorPage;

// 컨텐츠 프리뷰 렌더링 함수
const renderContents = (contents, idx, contentDelete) => {
  switch (contents.type) {
    case "text":
      const textList = contents.text.split("\n");

      var infoContentFontWeight = contents.bold == '1' || contents.bold == true ? "weight_500" :  "weight_400"
      var infoContentFontItalic = contents.italic == '1' || contents.italic == true ? "font_italic" :  ""
      var infoContentFontUnderline = contents.underline == '1' || contents.underline == true ? "font_underline" :  ""

      return (
        <div
          className={contents.size + ' ' + infoContentFontWeight + ' ' + contents.align}
          key={idx}
          style={{
            width: "100%",
          }}
        >
          <div style={{ position: "relative", padding : '0 20px' }}>
            {textList.map((text, idx) => (
              <>
                <p
                  className={contents.color + ' ' + contents.backgroundColor + ' ' + infoContentFontItalic + ' ' + infoContentFontUnderline}
                  key={idx}
                  style={{
                    margin: "10px 0", display : 'inline-block'
                  }}
                >
                  {text}
                </p>
                <br></br>
              </>
            ))}

            <button
              type="button"
              onClick={() => contentDelete(idx)}
              style={{ position: "absolute", right: "0px", top: "0px" }}
            >
              <img src={deleteIcon} />
            </button>
          </div>
        </div>
      );

    case "image":
      return (
        <div
          key={idx}
          style={{
            width: "100%",
            textAlign: 'center',
          }}
        >
          <div style={{ position: "relative", padding : '0 20px'  }}>
            <img
              src={contents.base}
              style={{ width: "100%", objectFit: "contain", margin: "20px 0" }}
            />

            <button
              type="button"
              onClick={() => contentDelete(idx)}
              style={{ position: "absolute", right: "0px", top: "0px" }}
            >
              <img src={deleteIcon} />
            </button>
          </div>
        </div>
      );

    case "space":
      return (
        <div
          style={{
            position: "relative",
            height: "50px",
            width: "100%",
            backgroundColor: "#ffffcc",
          }}
        >
          <button
            type="button"
            onClick={() => contentDelete(idx)}
            style={{ position: "absolute", right: "0px", top: "0px" }}
          >
            <img src={deleteIcon} />
          </button>
        </div>
      );
  }
};
