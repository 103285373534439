import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

// components
import DesignedMainLayout from "../../layouts/designedMainLayout";
import {
  Wrapper,
  AbsoluteWrapper,
  Icon,
  Text,
  WrapperColmun,
  WrapperRow,
} from "../../layouts/layoutStorage";
import Button from "../../modules/button";

// icon
import CancelIcon from "../../assets/icon/cancelIcon.png";
import SearchIcon from "../../assets/icon/search.png";
import Template from "../../components/designedMessage/template";

const Main = styled.main`
  width: 100%;
  height: calc(100% - 48px);

  ul {
    width: 100%;
  }
`;

const Layout = styled.div`
  min-width: 646px;

  width: 50%;
  max-width: 1000px;
  height: 100%;
  position: relative;
`;

const BottomButtonBox = styled.div`
  position: absolute;
  bottom: 52px;
  right: 20px;

  button:first-child {
    margin-right: 17px;
  }
`;

const TemplateButton = styled.div`
  width: 100%;
  padding: 8px 0;
  text-align: center;
  cursor: pointer;
`;

const PaymentCXBox = styled.div`
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  .cxContentBox {
    width: 100%;

    display: flex;
    align-items: top;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 9px;

    p:first-child {
      color: "#979797";
    }
    .cxButtonBox {
      width: calc(100% - 8rem);
      display: flex;
      justify-content: space-between;
      gap: 12px;
    }
    .csSerchBox {
      position: relative;
    }
    .cxContent {
      width: calc(100% - 8rem);
      display: inline-block;

      .cxType {
        padding: 10px 12px;
        display: inline-block;
        border: 3px solid rgba(132, 140, 148, 1);
        cursor: pointer;
        font-size: 17px;
        font-weight: 500;
      }
      .cxType:first-child {
        margin-right: 15px;
      }
      .typeActive {
        border: 3px solid rgba(0, 149, 255, 1);
      }
      .cxManeger {
        width: 100%;
        display: inline-block;

        padding: 10px 12px;
        border: 1px solid #dcdcdc;
        background-color: #ffff;
      }
      .csSerch {
        display: inline-flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        justify-content: space-between;
        align-items: center;
        input {
          width: 100%;
        }
        display: flex;
        flex-direction: row;

        justify-content: space-between;
      }
      .cxMessage {
        width: 100%;
        display: inline-block;
        padding: 10px 12px;
        border: 1px solid rgba(132, 140, 148, 1);
        font-size: 17px;
        font-weight: 500;
        background-color: #f9f9f9;
        height: 300px;
        resize: none;
      }
    }
  }
`;

const SelectBox = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  padding: 14px 18px 16px 18px;
  background-color: #ffff;
  border-bottom: 1px solid #dcdcdc;
  :hover {
    background-color: #f2f7ff;
  }
`;

const Header = styled.header`
  display: flex;
`;

const Div = styled.div`
  border-bottom: ${({ talk }) => (talk ? `1px solid #0E6DFF` : "none")};
  color: ${({ talk }) => (talk ? `#0E6DFF` : `#000`)};
  flex-shrink: 0;
  cursor: pointer;
  padding: 10px;
  margin-bottom: 36px;
`;

const IndividualMessage = () => {
  const [talk, setTalk] = useState(true);

  // 알림톡 talk = true
  const [talkPartner, setTalkPartner] = useState("");
  const [talkPhone, setTalkPhone] = useState("");
  const [talkManager, setTalkManager] = useState("Victor");
  const [link, setLink] = useState("");
  //   const [talkTitle, setTalkTitle] = useState("");
  //   const [talkContent, setTalkContent] = useState("");

  // 문자 메시지 talk = false
  const [user, setUser] = useState("");
  const [manager, setManager] = useState("Victor");
  const [phone, setPhone] = useState("");
  const [content, setContent] = useState("");
  //   const [partner, setPartner] = useState("");
  //   const [title, setTitle] = useState("");

  const talkContentReset = () => {
    if (window.confirm("작성한 내용을 초기화 하시겠습니까?")) {
      setTalkPartner("");
      setTalkPhone("");
      setTalkManager("Victor");
      setLink("");
      //   setTalkTitle("");
      //   setTalkContent("");
    }
  };

  const contentReset = () => {
    if (window.confirm("작성한 내용을 초기화 하시겠습니까?")) {
      setUser("");
      setManager("Victor");
      setPhone("");
      setContent("");
      //   setPartner("");
      //   setTitle("");
      //   setSelectedPartner({
      //     realName: "",
      //     phone: "",
      //     nickname: "",
      //   });
    }
  };

  const ThisDate = new Date();
  const ThisMonth = ThisDate.getMonth();
  const ThisDay = ThisDate.getDate();

  const [month, setMonth] = useState(
    ThisMonth > 7
      ? String(ThisMonth + 2) + "월"
      : "0" + String(ThisMonth + 2) + "월"
  );
  const [monthAday, setMonthAday] = useState(
    `${ThisMonth > 8 ? ThisMonth + 1 : "0" + String(ThisMonth + 1)}월 ${
      ThisDay > 9 ? ThisDay : "0" + String(ThisDay)
    }일`
  );

  //   const [searchData, setSearchData] = useState([]);
  //   const [searching, setSearching] = useState(false);
  //   const [selectedPartner, setSelectedPartner] = useState({
  //     realName: "",
  //     phone: "",
  //     nickname: "",
  //   });

  const [template, setTemplate] = useState("PREGISTER4");
  const messageContent = {
    PREGISTER4: `${talkPartner} 파트너님, 파트너 등록을 신청해주셔서 감사합니다 :)
    
    액티비티 등록을 위해 세모스 담당 매니저가 유선 연락드릴 예정이오니, 아래 내용을 회신해주세요.
    
    - 파트너님의 실명
    - 통화 가능한 날짜와 시간
    
    
    레저스포츠 1등 예약 앱, 세모스!
    ☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)`,

    PSCHEDULE3: `${talkPartner} 파트너님! 세모스 어플 내 ${month} 일정을 등록하고 예약시스템을 오픈하기 위해 아래 정보가 필요해요.
    
    - ${month} 진행 불가능 일정
    - 액티비티 정보(금액, 활동 지역 등) 변동사항
    
    ***파트너님의 액티비티를 고객님들이 정상적으로 결제할 수 있도록 ${monthAday}까지 회신주시기 바랍니다.
    
    레저스포츠 1등 예약 앱, 세모스!
    ☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)`,

    PARTNERIN3: `${talkPartner} 파트너님! 아래 등록 요청해주신 액티비티에 대한 정보 입력 링크를 전달드려요.
    
    ${link}
    
    ${monthAday}까지 작성해주시기 바라며, 작성 기한 연장이나 전화상담을 통한 대리입력 등 도움이 필요하신 부분은 언제든 본 채널로 편히 말씀주세요.
    
    카카오톡 알림톡은 PC에서 확인이 불가합니다. 보내드린 링크를 ‘나와의 채팅’으로 복사하신 후 PC에서 확인하여 작성해주세요.
    
    ***액티비티 등록은 선완료 순으로 진행해드립니다. 완료 후 본 채널로 말씀주시면 빠르게 등록 도와드릴게요. :)
    
    레저스포츠 1등 예약 앱, 세모스!
    ☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)`,
  };

  //   const search = () => {
  //     setSearching(true);
  //     var token = window.localStorage.getItem("token");
  //     const frm = new FormData();
  //     frm.append("mode", "search");
  //     frm.append("token", token);
  //     frm.append("text", talkPartner);

  //     axios
  //       .post("https://ai.semos.kr/semos_admin/user/data", frm, {
  //         headers: { "Content-Type": "multipart/form-data" },
  //       })
  //       .then((data) => {
  //         var Value = data.data;
  //         setSearchData(Value.search_list);
  //         console.log(Value.search_list);
  //       });
  //   };
  //   console.log(searching);

  //   const selectPartner = (data) => {
  //     setSelectedPartner({
  //       realName: data.real_name,
  //       phone: data.phone,
  //       nickname: data.user_name,
  //       device_token: data.token,
  //       id: data.id,
  //     });
  //     setSearching(false);
  //     setSearchData([]);
  //   };

  const talkMessageSend = () => {
    if (window.confirm("작성한 내용을 전송하시겠습니까?")) {
      const mCheck = talkManager.length > 2;
      //const pCheck = partner.length > 1;
      const lCheck = link.length > 8;
      const phCheck = talkPhone.length > 9;

      if (mCheck && phCheck) {
        var token = window.localStorage.getItem("token");

        const frm = new FormData();
        frm.append("mode", "partnerInfo");
        frm.append("token", token);
        frm.append("manager", talkManager);
        frm.append("partner", talkPartner);
        frm.append("template", template);
        frm.append("link", link);
        frm.append("phone", talkPhone);
        frm.append("content", messageContent[template]);
        frm.append("user", "alimtalk");
        frm.append("name", talkPartner);

        axios
          .post("https://ai.semos.kr/semos_admin/user/send", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((data) => {
            var Value = data.data;
            var status = Value.status;
            var statusMessage = Value.status_message;
            if (status == "success") {
              alert(statusMessage);

              setTalkPartner("");
              setTalkPhone("");
              setTalkManager("Victor");
              setLink("");
              //   setTalkTitle("");
              //   setTalkContent("");
            } else if (status == "waring") {
              alert(statusMessage);
            } else if (status == "fail") {
              alert(statusMessage);
            }
          });
      } else {
        if (!mCheck) {
          alert("담당자명을 확인해주세요!");
        } else if (!phCheck) {
          alert("전화번호를 확인해주세요!");
        }
        /*else if(!pCheck) {
        alert('강사명을 확인해주세요!')
      } else if(!lCheck) {
        alert('노션 링크를 확인해주세요!')
      }*/
      }
    }
  };

  const messageSend = () => {
    if (window.confirm("작성한 내용을 전송하시겠습니까?")) {
      const mCheck = manager.length > 2;
      //const pCheck = partner.length > 1;
      const lCheck = link.length > 8;
      const phCheck = phone.length > 9;

      if (mCheck && phCheck) {
        var token = window.localStorage.getItem("token");

        const frm = new FormData();
        frm.append("mode", "partnerInfo");
        frm.append("token", token);
        frm.append("manager", manager);
        // frm.append("partner", partner);
        frm.append("template", template);
        frm.append("link", link);
        frm.append("phone", phone);
        frm.append("content", content);
        frm.append("user", "message");
        frm.append("name", user);

        axios
          .post("https://ai.semos.kr/semos_admin/user/send", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((data) => {
            var Value = data.data;
            var status = Value.status;
            var statusMessage = Value.status_message;

            if (status == "success") {
              alert(statusMessage);

              setUser("");
              setManager("Victor");
              setPhone("");
              setContent("");
              //   setPartner("");
              //   setTitle("");
              //   setSelectedPartner({
              //     realName: "",
              //     phone: "",
              //     nickname: "",
              //   });
            } else if (status == "waring") {
              alert(statusMessage);
            } else if (status == "fail") {
              alert(statusMessage);
            }
          });
      } else {
        if (!mCheck) {
          alert("담당자명을 확인해주세요!");
        } else if (!phCheck) {
          alert("전화번호를 확인해주세요!");
        } /*else if(!pCheck) {
        alert('강사명을 확인해주세요!')
      } else if(!lCheck) {
        alert('노션 링크를 확인해주세요!')
      }*/
      }
    }
  };
  const DataChange = (type, value) => {
    switch (type) {
      // user
      case "partner":
        setTalkPartner(value);
        break;

      case "month":
        setMonth(value);
        break;

      case "mnd":
        setMonthAday(value);
        break;

      case "link":
        setLink(value);
        break;
    }
  };

  return (
    <DesignedMainLayout>
      <Header>
        <Div
          talk={talk}
          onClick={() => {
            setTalk(true);
          }}
        >
          <p className="f16 weight_700">알림톡 전송</p>
        </Div>

        <Div
          talk={!talk}
          onClick={() => {
            setTalk(false);
          }}
        >
          <p className="f16 weight_700">문자 메시지 전송</p>
        </Div>
      </Header>

      <Main>
        <Layout>
          {talk ? (
            <section>
              <h1 className="f24 weight_700">알림톡 정보</h1>

              <PaymentCXBox>
                <div className="cxContentBox">
                  {/* <p className="f14">유저 검색</p> */}
                  <p className="f14">유저 명</p>
                  <span className="cxContent">
                    <div
                      //   style={
                      //     searching || selectedPartner.realName
                      //       ? { border: "1px solid #818792" }
                      //       : {}
                      //   }
                      className="cxManeger csSerch"
                    >
                      <>
                        <input
                          autoFocus
                          onChange={(e) => setTalkPartner(e.target.value)}
                          value={talkPartner}
                          type="text"
                          maxLength={20}
                          className="f14"
                          placeholder="유저명을 입력해주세요"
                        />
                      </>
                      {/* {selectedPartner.realName ? (
                        <>
                          <Text
                            style={{
                              whiteSpace: "pre",
                            }}
                            color="#3C3E41"
                          >
                            {selectedPartner.realName}
                            <Text color="#dcdcdc"> | </Text>
                            {selectedPartner.phone
                              ? selectedPartner.phone
                              : "없음"}
                            <Text color="#dcdcdc"> | </Text>
                            {selectedPartner.nickname}
                          </Text>
                          <Icon
                            width={`calc(1rem * (12 / 14))`}
                            cursor={`pointer`}
                            src={CancelIcon}
                            onClick={() => {
                              setSelectedPartner({
                                realName: "",
                                phone: "",
                                nickname: "",
                              });
                              setTalkPartner("");
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <input
                            autoFocus
                            onChange={(e) => setTalkPartner(e.target.value)}
                            onKeyDown={(e) => {
                              //   console.log(e.key);
                              if (e.key === "Enter") search();
                            }}
                            value={talkPartner}
                            type="text"
                            maxLength={20}
                            className="f14"
                            placeholder="닉네임, 전화번호, 실명으로 검색"
                          />

                          <Icon
                            width={`calc(1rem * (17 / 14))`}
                            cursor={`pointer`}
                            src={SearchIcon}
                            onClick={search}
                          />
                        </>
                      )} */}
                    </div>
                  </span>
                </div>

                <div className="cxContentBox">
                  <p className="f14">유저 전화번호</p>
                  <span className="cxContent csSerchBox">
                    <input
                      type="text"
                      maxLength={20}
                      value={talkPhone}
                      onChange={(e) => setTalkPhone(e.target.value)}
                      className="cxManeger f14"
                      placeholder="유저 전화번호를 입력해 주세요(- 제외)"
                    />
                  </span>
                </div>

                <div className="cxContentBox">
                  <p className="f14">담당자명</p>
                  <span className="cxContent csSerchBox">
                    <input
                      type="text"
                      maxLength={20}
                      value={talkManager}
                      onChange={(e) => setTalkManager(e.target.value)}
                      className="cxManeger f14"
                      placeholder="담당자명을 입력해 주세요"
                    />
                    {/* {talkPartner && searching ? (
                      <AbsoluteWrapper width="100%" top="0" left="0">
                        <WrapperColmun
                          width="100%"
                          border="1px solid #dcdcdc"
                          style={{
                            borderBottom: "1px solid transparent",
                            overflow: "auto",
                            maxHeight: "500px",
                          }}
                        >
                          {searchData.map((item, idx) => (
                            <SelectBox
                              key={idx}
                              onClick={() => selectPartner(item)}
                            >
                              <Wrapper className="f14">
                                {item.real_name}
                              </Wrapper>
                              <WrapperRow gap="4px">
                                <Text color="#818792" className="f12">
                                  전화번호
                                </Text>
                                <Text className="f12">
                                  {item.phone ? item.phone : "없음"}
                                </Text>
                                <Text color="#818792" className="f12">
                                  닉네임
                                </Text>
                                <Text className="f12">{item.user_name}</Text>
                              </WrapperRow>
                            </SelectBox>
                          ))}
                        </WrapperColmun>
                      </AbsoluteWrapper>
                    ) : (
                      <></>
                    )} */}
                  </span>
                </div>

                {/* <div className="cxContentBox">
                  <p className="f14">푸시 알림 제목</p>
                  <span className="cxContent">
                    <input
                      type="text"
                      maxLength={50}
                      value={talkTitle}
                      onChange={(e) => setTalkTitle(e.target.value)}
                      className="cxManeger f14"
                      placeholder="푸시 알림 제목을 적어주세요 *최대 50글자"
                    />
                  </span>
                </div> */}

                <div className="cxContentBox">
                  <p className="f14">알림톡 템플릿</p>
                  <div className="cxButtonBox">
                    <TemplateButton
                      className="f14"
                      onClick={() => setTemplate("PREGISTER4")}
                      style={{
                        backgroundColor:
                          template == "PREGISTER4" ? "#F2F7FF" : "#ffff",
                        border:
                          template == "PREGISTER4"
                            ? "1px solid #3383FF"
                            : "1px solid #dcdcdc",
                      }}
                    >
                      파트너 등록
                    </TemplateButton>

                    <TemplateButton
                      className="f14"
                      style={{
                        backgroundColor:
                          template == "PSCHEDULE3" ? "#F2F7FF" : "#ffff",
                        border:
                          template == "PSCHEDULE3"
                            ? "1px solid #3383FF"
                            : "1px solid #dcdcdc",
                      }}
                      onClick={() => setTemplate("PSCHEDULE3")}
                    >
                      파트너 일정 등록
                    </TemplateButton>

                    <TemplateButton
                      className="f14"
                      onClick={() => setTemplate("PARTNERIN3")}
                      style={{
                        backgroundColor:
                          template == "PARTNERIN3" ? "#F2F7FF" : "#ffff",
                        border:
                          template == "PARTNERIN3"
                            ? "1px solid #3383FF"
                            : "1px solid #dcdcdc",
                      }}
                    >
                      파트너 정보 등록
                    </TemplateButton>
                  </div>
                </div>

                <div className="cxContentBox">
                  <p
                    className="f14"
                    style={{
                      alignSelf: "self-start",
                      marginTop: "1rem",
                    }}
                  >
                    알림톡 내용
                  </p>
                  {/* <span className="cxContent">
                    <textarea
                      style={{
                        height: "355px",
                        width: "100%",
                        resize: "none",
                      }}
                      type="text"
                      value={talkContent}
                      onChange={(e) => setTalkContent(e.target.value)}
                      className="cxManeger f14"
                      maxLength={200}
                      placeholder="푸시 알림 내용을 적어주세요 *최대 200글자"
                    />
                  </span> */}
                  <span className="cxContent">
                    <Template
                      type={template}
                      change={DataChange}
                      data={{
                        partner: talkPartner,
                        month: month,
                        mnd: monthAday,
                        link: link,
                      }}
                    />
                  </span>
                </div>

                <Wrapper
                  color="#FF9187"
                  margin="0 5px"
                  cursor="pointer"
                  jc="end"
                  onClick={talkContentReset}
                >
                  <p style={{ color: "#FF9187" }} className="f14">
                    초기화
                  </p>
                </Wrapper>
              </PaymentCXBox>
            </section>
          ) : (
            <section>
              <h1 className="f24 weight_700">문자 메시지 정보</h1>

              <PaymentCXBox>
                <div className="cxContentBox">
                  <p className="f14">유저 명</p>
                  <span className="cxContent">
                    <div className="cxManeger csSerch">
                      <>
                        <input
                          autoFocus
                          onChange={(e) => setUser(e.target.value)}
                          value={user}
                          type="text"
                          maxLength={20}
                          className="f14"
                          placeholder="유저명을 입력해주세요"
                        />
                      </>
                    </div>
                  </span>
                </div>

                <div className="cxContentBox">
                  <p className="f14">유저 전화번호</p>
                  <span className="cxContent csSerchBox">
                    <input
                      type="text"
                      maxLength={20}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      className="cxManeger f14"
                      placeholder="유저 전화번호를 입력해 주세요(- 제외)"
                    />
                  </span>
                </div>

                <div className="cxContentBox">
                  <p className="f14"> 담당자 명</p>
                  <span className="cxContent">
                    <input
                      type="text"
                      maxLength={20}
                      value={manager}
                      onChange={(e) => setManager(e.target.value)}
                      className="cxManeger"
                      placeholder="담당자명을 입력해 주세요!"
                    />
                  </span>
                </div>

                {/* <div className="cxContentBox">
                  <p className="f14">푸시 알림 제목</p>
                  <span className="cxContent">
                    <input
                      type="text"
                      maxLength={50}
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      className="cxManeger"
                      placeholder="푸시 알림 제목을 적어주세요 *최대 50글자"
                    />
                  </span>
                </div> */}

                <div className="cxContentBox">
                  <p
                    className="f14"
                    style={{
                      alignSelf: "self-start",
                      marginTop: "1rem",
                    }}
                  >
                    푸시 알림 내용
                  </p>

                  <span className="cxContent">
                    <textarea
                      style={{
                        height: "355px",
                        width: "100%",
                        resize: "none",
                      }}
                      type="text"
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                      className="cxManeger f14"
                      maxLength={200}
                      placeholder="푸시 알림 내용을 적어주세요 *최대 200글자"
                    />
                  </span>
                </div>

                <Wrapper
                  color="#FF9187"
                  margin="0 5px"
                  cursor="pointer"
                  jc="end"
                  onClick={contentReset}
                >
                  <p style={{ color: "#FF9187" }} className="f14">
                    초기화
                  </p>
                </Wrapper>
              </PaymentCXBox>
            </section>
          )}

          <BottomButtonBox>
            {/* <Button
              text="초기화"
              clickHandler={ContentReset}
              width={`166px`}
              color={colors.white}
              fontcolor={colors.blue}
              className="f14"
            /> */}

            <Button
              text="전송"
              clickHandler={talk ? talkMessageSend : messageSend}
              width={`166px`}
              className="f14"
            />
          </BottomButtonBox>
        </Layout>
      </Main>

      {/* </MainLayout> */}
    </DesignedMainLayout>
  );
};

export default IndividualMessage;
