import React, { useEffect, useState } from "react";
import { WrapperColmun, Icon, Wrapper, Text } from "../../layouts/layoutStorage";
import axios from "axios";

import IphoneHeader from "../../assets/images/iphoneHeader.png";
import colors from "../../styles/constants/colors";
import Item from "./Item";

const ExhibitionTemplate = ({ data }) => {
  const wSize = document.getElementById("preview")?.offsetWidth;
  const headerSize = document.getElementById("iphone")?.offsetHeight;
  const [list, setList] = useState([]);

  useEffect(() => {
    axios
      .post(
        "https://ai.semos.kr/semos_admin/exhibition/data",
        {
          mode: "activity_list",
          ids: data.activity.map((ac) => ac.id),
        },
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((res) => {
        let Value = res.data;
        setList(Value.list);
      });
  }, [data.activity]);

  return (
    <WrapperColmun width="100%" height="100%" id="preview">
      <Icon src={IphoneHeader} width="100%" id="iphone" />

      <WrapperColmun
        width="100%"
        // height={`calc(100vh - 220px - ${headerSize}px)`}
        height={`100%`}
        of="auto"
      >
        {data.main_image ? (
          <Icon src={data.main_image} width="100%" />
        ) : (
          <Wrapper width="100%" height={`${(wSize * 600) / 375}px`} bgc={colors.Gr01} jc="center" style={{ minHeight: `${(wSize * 600) / 375}px` }}>
            <Text className="f14" weight="500" color={colors.Gr06}>
              기획전 상단 이미지
            </Text>
          </Wrapper>
        )}
        <WrapperColmun width="100%" margin="32px 0 0 0" padding="0 24px">
          {list.length > 0 ? (
            <>
              {list.map((item) => (
                <Item item={item} />
              ))}
            </>
          ) : (
            <Wrapper radius="12px" width="100%" height={`290px`} bgc={colors.Gr01} jc="center">
              <Text className="f14" weight="500" color={colors.Gr06}>
                액티비티를 등록해주세요
              </Text>
            </Wrapper>
          )}
        </WrapperColmun>
      </WrapperColmun>
    </WrapperColmun>
  );
};

export default ExhibitionTemplate;
