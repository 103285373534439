import styled from "styled-components";

const Container = styled.div`
  display: inline-block;
  width: 100%;
  border-radius: 8px;
  padding: 10px 12px;
  border: 1px solid rgba(132, 140, 148, 1);
  font-weight: 500;
  background-color: #f9f9f9;
  height: 250px;
  line-height: 1.4;
  overflow: hidden auto;
  font-size: 17px;
`;

const Content = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  width: 100%;
  font-size: 15px;
  font-weight: 400;

  span {
    display: inline-block;
  }

  input,
  textarea {
    display: inline-flex;
    font-weight: 500;
    border: unset !important;
    // text-decoration : underline;
    width: ${({ size }) => (size ? size : "600px")};
    text-align: ${({ align }) => (align ? align : "unset")};
    padding: 0 5px;
    height: unset !important;
    background-color: transparent !important;
    border-radius: unset !important;
    outline: none !important;
    border-bottom: #eeeeee solid 1px !important;
  }
`;

const Template = ({ type, data, change }) => {
  switch (type) {
    case "PREGISTER4":
      return (
        <Container>
          <Content size="150px" align="center">
            <input
              onChange={(e) => change(e.target.name, e.target.value)}
              type="text"
              value={data.partner}
              name="partner"
            />
            <span>파트너님, 파트너 등록을 신청해주셔서 감사합니다 :)</span>
          </Content>

          <Content>
            <span></span>
          </Content>

          <Content size="600px">
            <span>
              액티비티 등록을 위해 세모스 담당 매니저가 유선 연락드릴
              예정이오니, 아래 내용을 회신해주세요.
            </span>
          </Content>

          <Content>
            <span></span>
          </Content>

          <Content size="600px">
            <span>- 파트너님의 실명</span>
          </Content>

          <Content size="600px">
            <span>- 통화 가능한 날짜와 시간</span>
          </Content>

          <Content>
            <span></span>
          </Content>

          <Content>
            <span></span>
          </Content>

          <Content>
            <span>레저스포츠 1등 예약 앱, 세모스!</span>
          </Content>

          <Content>
            <span>
              ☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~
              오후 7시)
            </span>
          </Content>
        </Container>
      );
      break;

    case "PSCHEDULE3":
      return (
        <Container>
          <Content size="150px" align="center">
            <input
              onChange={(e) => change(e.target.name, e.target.value)}
              type="text"
              value={data.partner}
              name="partner"
            />
            <span>파트너님! 세모스 어플 내</span>
            <input
              onChange={(e) => change(e.target.name, e.target.value)}
              placeholder="**월"
              maxLength="10"
              type="text"
              value={data.month}
              name="month"
            />
            <span>
              일정을 등록하고 예약시스템을 오픈하기 위해 아래 정보가 필요해요.
            </span>
          </Content>

          <Content>
            <span></span>
          </Content>

          <Content size="150px" align="center">
            <span>-</span>
            <input
              onChange={(e) => change(e.target.name, e.target.value)}
              placeholder="**월"
              maxLength="10"
              type="text"
              value={data.month}
              name="month"
            />
            <span>진행 불가능 일정</span>
          </Content>

          <Content>
            <span>- 액티비티 정보(금액, 활동 지역 등) 변동사항</span>
          </Content>

          <Content>
            <span></span>
          </Content>

          <Content size="150px" align="center">
            <span>
              파트너님의 레슨을 고객님들이 정상적으로 결제할 수 있도록
            </span>
            <input
              onChange={(e) => change(e.target.name, e.target.value)}
              placeholder="**월 **일"
              maxLength="10"
              type="text"
              value={data.mnd}
              name="mnd"
            />
            <span>까지 회신주시기 바랍니다.</span>
          </Content>

          <Content>
            <span></span>
          </Content>

          <Content>
            <span>레저스포츠 1등 예약 앱, 세모스!</span>
          </Content>

          <Content>
            <span>
              ☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~
              오후 7시)
            </span>
          </Content>
        </Container>
      );
      break;

    case "PARTNERIN3":
      return (
        <Container>
          <Content size="150px" align="center">
            <input
              onChange={(e) => change(e.target.name, e.target.value)}
              type="text"
              value={data.partner}
              name="partner"
            />
            <span>
              파트너님! 아래 등록 요청해주신 액티비티에 대한 정보 입력 링크를
              전달드려요.
            </span>
          </Content>

          <Content>
            <span></span>
          </Content>

          <Content size="600px" align="center">
            <input
              onChange={(e) => change(e.target.name, e.target.value)}
              placeholder="링크 입력"
              maxLength="100"
              type="text"
              value={data.link}
              name="link"
            />
          </Content>

          <Content>
            <span></span>
          </Content>

          <Content size="150px" align="center">
            <input
              onChange={(e) => change(e.target.name, e.target.value)}
              placeholder="**월 **일"
              maxLength="10"
              type="text"
              value={data.mnd}
              name="mnd"
            />
            <span>
              까지 작성해주시기 바라며, 작성 기한 연장이나 전화상담을 통한
              대리입력 등 도움이 필요하신 부분은 언제든 본 채널로 편히
              말씀주세요.
            </span>
          </Content>

          <Content>
            <span></span>
          </Content>

          <Content>
            <span>
              카카오톡 알림톡은 PC에서 확인이 불가합니다. 보내드린 링크를
              ‘나와의 채팅’으로 복사하신 후 PC에서 확인하여 작성해주세요.
            </span>
          </Content>

          <Content>
            <span></span>
          </Content>

          <Content>
            <span>
              ***액티비티 등록은 선완료 순으로 진행해드립니다. 완료 후 본 채널로
              말씀주시면 빠르게 등록 도와드릴게요. :)
            </span>
          </Content>

          <Content>
            <span></span>
          </Content>

          <Content>
            <span>레저스포츠 1등 예약 앱, 세모스!</span>
          </Content>

          <Content>
            <span>
              ☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~
              오후 7시)
            </span>
          </Content>
        </Container>
      );
      break;
  }
};

export default Template;
