import styled from "styled-components";
import colors from "../../../../styles/constants/colors";
import flexbox from "../../../../styles/func/flexbox";

export const ButtonBox = styled.div`
  width: 100%;
  ${flexbox("flex-end")}

  button {
    background-color: ${colors.blue};
    color: ${colors.white};
    padding: 10px 20px;
    border-radius: 40px;
  }
`;

export const Main = styled.main`
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;
  overflow-y: scroll;
  padding-bottom: 120px;
  height: 100%;
`;

export const ListBox = styled.div``;
