import React, { useState, useRef } from "react";
import axios from "axios";
import styled from "styled-components";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";

import { Wrapper, WrapperColmun, Icon, Text, HoverButton, Label, AbsoluteWrapper, Input } from "../../layouts/layoutStorage";
import DesignedMainHeader from "../../components/designedHeader";
import DesignedMainLayout from "../../layouts/designedMainLayout";

import colors from "../../styles/constants/colors";

import { ReactComponent as DropDownIcon } from "../../assets/designedIcons/dropdown.svg";
import { ReactComponent as Photo } from "../../assets/designedIcons/photo.svg";
import { ReactComponent as Magnify } from "../../assets/designedIcons/magnify.svg";
import { ReactComponent as Plus } from "../../assets/designedIcons/plus.svg";
import { ReactComponent as Close } from "../../assets/designedIcons/close.svg";
import { useEffect } from "react";
import ImageCrop from "../../components/designedComponents/imageCrop";
import ToggleButton from "../../modules/toggleButton";
import DropDown from "../../components/designedComponents/dropDown";
import ExhibitionTemplate from "../../components/designedExhibition/exhibitionTemplate";

const Exhibition = () => {
  const TEMPLATE = [
    { value: "1", text: "1" },
    // { value: "02", text: "02" },
    // { value: "03", text: "03" },
  ];
  const { RangePicker } = DatePicker;

  const [templateModal, setTemplateModal] = useState(false);
  const [activityListModal, setActivityListModal] = useState(false);
  const [searchActivity, setSearchActivity] = useState("");

  const [cropImage, setCropImage] = useState({
    width: 1,
    height: 1,
    type: "",
    number: "",
    image: "",
    status: false,
    scale: true,
  });

  const [show, setShow] = useState(0);
  const [deleteD, setDeleteD] = useState([]);
  const [data, setData] = useState({
    title: "",
    template: "",
    main_image: "",
    modal_image: "",
    start: "",
    end: "",
    activity: [],
    status: "",
  });
  const [copyJSONData, setCopyJSONData] = useState({});
  const [activityList, setActivityList] = useState([]);
  const [list, setList] = useState([]);

  const ref = useRef();
  const activityRef = useRef();

  const handleClose = (e) => {
    if (templateModal && (!ref.current || !ref.current.contains(e.target))) {
      setTemplateModal(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, [templateModal]);

  const handleCloseActivity = (e) => {
    if (activityListModal && (!activityRef.current || !activityRef.current.contains(e.target))) {
      setActivityListModal(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleCloseActivity);

    return () => {
      window.removeEventListener("click", handleCloseActivity);
    };
  }, [activityListModal]);

  useEffect(() => {
    getExhibition();
  }, []);

  useEffect(() => {
    activitySearch();
  }, [searchActivity, activityListModal]);

  useEffect(() => {
    getData();
  }, [show]);

  const getData = () => {
    const token = window.localStorage.getItem("token");

    axios
      .post(
        "https://ai.semos.kr/semos_admin/exhibition/data",
        {
          mode: "detail",
          token: token,
          id: show,
        },
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((data) => {
        let Value = data.data;
        let status = Value.status;
        let statusMessage = Value.status_message;

        if (status == "success") {
          setData(Value.data);
          setCopyJSONData(JSON.stringify(Value.data));
        } else if (status == "waring") {
          window.alert(statusMessage);
        }
      });
  };

  const canSave = () => {
    let JSONData = JSON.stringify(data);

    if (JSONData !== copyJSONData) {
      return true;
    } else {
      return false;
    }
  };

  const saveData = () => {
    const token = window.localStorage.getItem("token");

    if (canSave()) {
      axios
        .post(
          "https://ai.semos.kr/semos_admin/exhibition/data",
          {
            mode: "save",
            token: token,
            id: show,
            delete: deleteD,
            data: data,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((res) => {
          let Value = res.data;
          let status = Value.status;
          let statusMessage = Value.status_message;

          if (status == "success") {
            window.location.reload();
          } else if (status == "waring") {
            window.alert(statusMessage);
          }
        });
    }
  };

  const activitySearch = (copy) => {
    const token = window.localStorage.getItem("token");
    const frm = new FormData();

    frm.append("mode", "activity_search");
    frm.append("token", token);
    frm.append("text", searchActivity);

    axios
      .post("https://ai.semos.kr/semos_admin/exhibition/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        let Value = res.data;
        let status = Value.status;
        let statusMessage = Value.status_message;
        let ListCopy = [...Value.list];

        if (status == "success") {
          let totalValues = ListCopy.map((item) => Number(item.id));
          let addValues = copy ? copy.map((item) => Number(item.id)) : data.activity.map((item) => Number(item.id));
          let notAddValues = totalValues.filter((item) => !addValues.includes(item));
          let list = ListCopy.filter((item) => notAddValues.includes(Number(item.id)));

          setActivityList(list);
        } else if (status == "waring") {
          window.alert(statusMessage);
        }
      });
  };

  const newExhibition = () => {
    const token = window.localStorage.getItem("token");
    const frm = new FormData();

    frm.append("mode", "insert");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_admin/exhibition/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        let Value = data.data;
        let status = Value.status;
        let statusMessage = Value.status_message;

        if (status == "success") {
          setList(Value.list);
        } else if (status == "waring") {
          window.alert(statusMessage);
        }
      });
  };

  const getExhibition = () => {
    const token = window.localStorage.getItem("token");
    const frm = new FormData();

    frm.append("mode", "list");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_admin/exhibition/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        let Value = data.data;
        let status = Value.status;
        let statusMessage = Value.status_message;

        if (status == "success") {
          setList(Value.list);
        } else if (status == "waring") {
          window.alert(statusMessage);
        }
      });
  };

  const setImageFromFile = ({ file, setImageUrl }) => {
    let reader = new FileReader();
    reader.onload = function () {
      setImageUrl({ result: reader.result });
    };
    reader.readAsDataURL(file);
  };

  const addImg = (files, type) => {
    setImageFromFile({
      file: files[0],
      setImageUrl: ({ result }) => {
        setData((prev) => ({ ...prev, [type]: result }));
      },
    });
  };

  const ImgChange = (value, type, number) => {
    if (type == "main_image") {
      setCropImage({
        width: 375,
        height: 600,
        type: type,
        number: number,
        image: value,
        status: true,
        scale: true,
      });
    } else {
      setCropImage({
        width: 375,
        height: 347,
        type: type,
        number: number,
        image: value,
        status: true,
        scale: false,
      });
    }
  };

  // height: calc(100% - 1rem * 33 / 14 - 133px);
  return (
    <>
      <Wrapper bgc="#ffffff" of={`hidden auto`} height="100%" display={`inline-block`} width={`100%`} mh={`calc(100vh - 48px)`}>
        <Container>
          <DesignedMainHeader title="기획전" />

          <BannerMenuConatiner>
            <BannerMenu className="f16" active={true}>
              기획전
            </BannerMenu>
          </BannerMenuConatiner>

          <Wrapper width="100%" height="calc(100% - 1rem * 33 / 14 - 133px)" jc="space-between">
            <WrapperColmun width="20%" height="100%" of="auto" bgc={colors.White} border={`1px solid ${colors.Gr02}`}>
              {list.map((item, index) => (
                <WrapperColmun
                  cursor="pointer"
                  key={`exhibition_list_${index}`}
                  onClick={() => {
                    setShow(item.id);
                  }}
                  bgc={show === item.id ? colors.Bl01 : colors.White}
                  gap="5px"
                  jc="space-between"
                  padding="16px 24px"
                  style={{ borderBottom: `1px solid ${colors.Gr01}` }}
                >
                  <Wrapper jc="space-between" ai="center">
                    <Text className="f16" weight="600" color={colors.Gr05}>
                      {item.title ? item.title : "제목 없음"}
                    </Text>
                    <Text className="f14" weight="600" color={item.status === "1" ? colors.Bl07 : colors.Red03}>
                      {item.status === "1" ? "활성화" : "비활성화"}
                    </Text>
                  </Wrapper>
                  <Wrapper jc="space-between" ai="flex-start" gap="5px">
                    <Text ws="pre" className="f14" weight="600" color={colors.Gr05}>
                      기간
                    </Text>
                    <Text className="f14" weight="500" color={colors.Gr03}>
                      {item.start && item.end ? `${item.start.split(" ")[0]} ~ ${item.end.split(" ")[0]}` : ""}
                    </Text>
                  </Wrapper>
                  <Wrapper jc="space-between" ai="center">
                    <Text className="f14" weight="600" color={colors.Gr05}>
                      템플릿
                    </Text>
                    <Text className="f14" weight="500" color={colors.Gr03}>
                      {item.template !== "0" ? item.template : ""}
                    </Text>
                  </Wrapper>
                </WrapperColmun>
              ))}
              <Wrapper
                onClick={() => {
                  newExhibition();
                }}
                cursor="pointer"
                radius="8px"
                padding="14px"
                jc="center"
                ai="center"
                gap="8px"
              >
                <Plus fill={colors.Bl07} width="calc(1rem * 20 / 14)" height="calc(1rem * 20 / 14)" />
                <Text className="f16" weight="600" color={colors.Bl07}>
                  기획전 추가하기
                </Text>
              </Wrapper>
            </WrapperColmun>

            <Wrapper width="79%" height="100%" bgc={colors.White} border={`1px solid ${colors.Gr02}`}>
              {show ? (
                <>
                  <WrapperColmun width="70%" height="100%" jc="space-between">
                    <WrapperColmun height="calc(100% - 92px)">
                      <Wrapper padding="24px 24px 0 24px" jc="space-between" ai="center">
                        <Text className="f20" weight="700" color={colors.Gr06}>
                          기획전 이름
                        </Text>
                        <ToggleButton
                          status={data.status}
                          toggleHandler={() =>
                            setData((prev) => ({
                              ...prev,
                              status: data.status === "1" ? "0" : "1",
                            }))
                          }
                          type="status"
                        />
                      </Wrapper>
                      <WrapperColmun height="calc(100% - 1rem * 20 / 14 - 24px)" of="auto" padding="24px">
                        <Text className="f16" weight="600" color={colors.Gr05}>
                          기획전 이름
                        </Text>

                        <Wrapper margin="8px 0" border={`1px solid ${colors.Gr02}`} radius="8px">
                          <Input
                            width="100%"
                            padding="11px 16px"
                            value={data.title}
                            onChange={(e) =>
                              setData((prev) => ({
                                ...prev,
                                title: e.target.value,
                              }))
                            }
                            placeholder="기획전 이름을 입력해 주세요."
                          />
                        </Wrapper>

                        <Wrapper margin="24px 0 0 0" gap="40px">
                          <WrapperColmun width="230px">
                            <Text className="f16" weight="600" color={colors.Gr05}>
                              템플릿
                            </Text>

                            <Wrapper
                              ref={ref}
                              cursor="pointer"
                              onClick={() => setTemplateModal(!templateModal)}
                              width="100%"
                              jc="space-between"
                              border={templateModal ? `1px solid ${colors.Bl07}` : `1px solid ${colors.Gr02}`}
                              padding="0 8px"
                              margin="8px 0"
                              height="calc(1rem + 22px)"
                              radius="8px"
                              ws="nowrap"
                            >
                              <Text className="f14" weight="500" color={colors.Gr04}>
                                {Number(data.template) !== 0 ? data.template : "템플릿을 선택해주세요"}
                              </Text>

                              <DropDownIcon
                                width="20px"
                                height="20px"
                                fill={colors.Gr03}
                                style={{
                                  flexShrink: "0",

                                  transform: templateModal ? "rotate(-180deg)" : "",
                                  transition: "all 0.7s",
                                }}
                              />
                            </Wrapper>
                            {templateModal && (
                              <Wrapper position="relative">
                                <AbsoluteWrapper bgc={colors.White} z={20} fd="column" top="7px" width="100%" radius="8px" border={`1px solid ${colors.Gr02}`}>
                                  {TEMPLATE.map((item, index) => (
                                    <Wrapper
                                      cursor="pointer"
                                      width="100%"
                                      padding="8px 11px"
                                      style={{
                                        borderBottom: index + 1 !== TEMPLATE.length && `1px solid ${colors.Gr02}`,
                                      }}
                                      key={index}
                                      onClick={() => {
                                        setData((prev) => ({
                                          ...prev,
                                          template: item.value,
                                        }));
                                        setTemplateModal(false);
                                      }}
                                    >
                                      <Text className="f14" weight="500" color={colors.Gr04}>
                                        {item.text}
                                      </Text>
                                    </Wrapper>
                                  ))}
                                </AbsoluteWrapper>
                              </Wrapper>
                            )}

                            {/* <DropDown data={data.template} setData={setData} option={TEMPLATE} keyName={"template"} type="object" /> */}
                          </WrapperColmun>

                          <WrapperColmun width="calc(100% - 270px)">
                            <Text className="f16" weight="600" color={colors.Gr05}>
                              콘텐츠 이미지
                            </Text>

                            <Wrapper width="100%" gap="12px" jc="space-between">
                              <Label
                                cursor="pointer"
                                htmlFor="main_image"
                                width="48%"
                                margin="8px 0"
                                border={`1px solid ${colors.Gr02}`}
                                padding="0 8px"
                                height="calc(1rem + 22px)"
                                radius="8px"
                              >
                                <Photo
                                  width="20px"
                                  height="20px"
                                  fill={colors.Gr03}
                                  style={{
                                    flexShrink: "0",
                                    marginRight: "12px",
                                  }}
                                />

                                <Text ellipsis={true} className="f14" weight="500" color={colors.Gr04}>
                                  {data.main_image ? data.main_image.slice(0, 100) : "기획전 상단 이미지 (375 * 600)"}
                                </Text>
                                <Input
                                  width="100%"
                                  padding="11px 16px"
                                  type="file"
                                  id="main_image"
                                  style={{ display: "none" }}
                                  accept="image/*"
                                  onChange={({ target: { files } }) => addImg(files, "main_image")}
                                />
                              </Label>

                              <Label
                                cursor="pointer"
                                width="48%"
                                margin="8px 0"
                                border={`1px solid ${colors.Gr02}`}
                                padding="0 8px"
                                height="calc(1rem + 22px)"
                                radius="8px"
                              >
                                <Photo
                                  width="20px"
                                  height="20px"
                                  fill={colors.Gr03}
                                  style={{
                                    flexShrink: "0",
                                    marginRight: "12px",
                                  }}
                                />

                                <Text ellipsis={true} className="f14" weight="500" color={colors.Gr04}>
                                  {data.modal_image ? data.modal_image : "모달 이미지 (375 * 347)"}
                                </Text>

                                <input
                                  type="file"
                                  id="modal_image"
                                  style={{ display: "none" }}
                                  accept="image/*"
                                  onChange={({ target: { files } }) => addImg(files, "modal_image")}
                                />
                              </Label>
                            </Wrapper>
                          </WrapperColmun>
                        </Wrapper>

                        <WrapperColmun margin="24px 0 0 0" width="100%">
                          <Text className="f16" weight="600" color={colors.Gr05}>
                            업로드 기간
                          </Text>

                          <PickerWrap>
                            <RangePicker
                              value={[data.start ? dayjs(data.start) : dayjs(), data.end ? dayjs(data.end) : dayjs()]}
                              onChange={(e, date) =>
                                setData((prev) => ({
                                  ...prev,
                                  start: date[0],
                                  end: date[1],
                                }))
                              }
                              showTime={{ format: "HH:mm" }}
                              format="YYYY-MM-DD HH:mm"
                              size="large"
                            />
                          </PickerWrap>
                        </WrapperColmun>

                        <WrapperColmun margin="24px 0 0 0" width="100%">
                          <Text className="f16" weight="600" color={colors.Gr05}>
                            등록 액티비티 리스트
                          </Text>

                          <Wrapper
                            position="relative"
                            width="100%"
                            ref={activityRef}
                            bgc={colors.White}
                            padding="0 0 0 8px"
                            margin="8px 0"
                            border={activityListModal ? `1px solid ${colors.Bl07}` : `1px solid ${colors.Gr02}`}
                            radius="8px"
                          >
                            <Magnify fill={colors.Gr04} width="20px" height="20px" />
                            <Input
                              width="100%"
                              padding="11px 16px 11px 4px"
                              placeholder="액티비티 ID, 이름, 강사명으로 검색하세요."
                              value={searchActivity}
                              onChange={(e) => {
                                setSearchActivity(e.target.value);
                              }}
                              onFocus={() => setActivityListModal(true)}
                            />
                            {activityListModal && activityList.length > 0 && (
                              <AbsoluteWrapper
                                width="100%"
                                fd="column"
                                top="calc(1rem * 24 / 14 + 22px)"
                                left={0}
                                bgc={colors.White}
                                border={`1px solid ${colors.Gr02}`}
                                style={{ overflow: "auto", maxHeight: "250px" }}
                              >
                                {activityList.map((item) => {
                                  let locationText = "";

                                  for (let i = 0; i < item.location?.length; i++) {
                                    if (i) {
                                      locationText = locationText + ", " + item.location[i];
                                    } else {
                                      locationText = item.location[i];
                                    }
                                  }

                                  return (
                                    <HoverButton
                                      key={item.id}
                                      style={{ width: "100%" }}
                                      bgch={colors.Bl01}
                                      onClick={() => {
                                        let addValues = data.activity.map((item) => Number(item.id));
                                        let copy = [...data.activity];
                                        if (addValues.includes(Number(item.id))) {
                                          window.alert("이미 추가된 액티비티입니다.");
                                        } else {
                                          copy.push(item);
                                          setData((prev) => ({
                                            ...prev,
                                            activity: copy,
                                          }));
                                        }
                                        activitySearch(copy);
                                      }}
                                    >
                                      <Wrapper padding="8px" width="100%">
                                        <Icon src={item.image} width="calc(1rem * 48 / 14 + 8px)" height="calc(1rem * 48 / 14 + 8px)" margin="0 12px 0 0" />
                                        <WrapperColmun>
                                          <Text className="f14">{item.title}</Text>
                                          <Wrapper height="4px" />
                                          <Wrapper>
                                            <Wrapper>
                                              <Text className="f12" weight="600" color={colors.Gr04}>
                                                ID
                                              </Text>
                                              <Wrapper width="4px" />
                                              <Text className="f12" weight="500" color={colors.Gr05}>
                                                {item.id}
                                              </Text>
                                            </Wrapper>
                                          </Wrapper>
                                          <Wrapper height="4px" />
                                          <Wrapper>
                                            <Text className="f12" weight="600" color={colors.Gr04}>
                                              장소
                                            </Text>
                                            <Wrapper width="4px" />
                                            <Text className="f12" weight="500" color={colors.Gr05}>
                                              {locationText}
                                            </Text>
                                          </Wrapper>
                                        </WrapperColmun>
                                      </Wrapper>
                                    </HoverButton>
                                  );
                                })}
                              </AbsoluteWrapper>
                            )}
                          </Wrapper>

                          <Glid>
                            {data.activity?.map((item, idx) => (
                              <Wrapper key={item.id} style={{ width: "100%" }} border={`1px solid ${colors.Gr02}`} bgc={colors.Gr01}>
                                <Wrapper padding="8px" width="100%">
                                  <WrapperColmun width="100%">
                                    <Wrapper jc="space-between" width="100%" ai="center">
                                      <Text ellipsis={true} width="100%" className="f14">
                                        {item.title}
                                      </Text>
                                      <Close
                                        style={{
                                          cursor: "pointer",
                                          flexShrink: "0",
                                        }}
                                        onClick={() => {
                                          let copy = [...data.activity];
                                          let copyD = [...deleteD];
                                          copy.splice(idx, 1);
                                          copyD.push(item);

                                          setDeleteD(copyD);
                                          setData((prev) => ({
                                            ...prev,
                                            activity: copy,
                                          }));
                                        }}
                                        fill={colors.Gr04}
                                        width="calc(1rem * 16 / 14)"
                                        height="calc(1rem * 16 / 14)"
                                      />
                                    </Wrapper>
                                    <Wrapper height="4px" />
                                    <Wrapper>
                                      <Wrapper>
                                        <Text className="f12" weight="600" color={colors.Gr04}>
                                          ID
                                        </Text>
                                        <Wrapper width="4px" />
                                        <Text className="f12" weight="500" color={colors.Gr05}>
                                          {item.id}
                                        </Text>

                                        <Wrapper width="8px" />

                                        <Text className="f12" weight="600" color={colors.Gr04}>
                                          강사 명
                                        </Text>
                                        <Wrapper width="4px" />
                                        <Text className="f12" weight="500" color={colors.Gr05}>
                                          {item.partner}
                                        </Text>
                                      </Wrapper>
                                    </Wrapper>
                                  </WrapperColmun>
                                </Wrapper>
                              </Wrapper>
                            ))}
                          </Glid>
                        </WrapperColmun>
                      </WrapperColmun>
                    </WrapperColmun>
                    <Wrapper height="92px" jc="flex-end" ai="center" padding="16px 24px" style={{ borderTop: `1px solid ${colors.Gr02}` }}>
                      <Wrapper
                        onClick={() => saveData()}
                        cursor={canSave() ? "pointer" : "default"}
                        jc="center"
                        ai="center"
                        padding="14px"
                        width="160px"
                        bgc={canSave() ? colors.Bl07 : colors.Gr01}
                        radius="8px"
                      >
                        <Text className="f16" weight="600" color={canSave() ? colors.White : colors.Gr03}>
                          저장하기
                        </Text>
                      </Wrapper>
                    </Wrapper>
                  </WrapperColmun>

                  <WrapperColmun width="30%" height="100%" bl={`1px solid ${colors.Gr02}`}>
                    <ExhibitionTemplate data={data} />
                  </WrapperColmun>
                </>
              ) : (
                <Wrapper width="100%" height="100%" jc="center" ai="center">
                  <Text className="f16" weight="500" color={colors.Gr04}>
                    기획전을 선택해주세요
                  </Text>
                </Wrapper>
              )}
            </Wrapper>
          </Wrapper>
        </Container>
      </Wrapper>
      <ImageCrop info={cropImage} setInfo={setCropImage} setImg={setData} img={data} />
    </>
  );
};

export default Exhibition;

const BannerMenuConatiner = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
`;

const BannerMenu = styled.div`
  padding: 12px 20px;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: ${({ active }) => (active ? colors.Bl06 : colors.Gr05)};
  margin-right: 38px;
  border-bottom: ${({ active }) => (active ? `1px solid ${colors.Bl06}` : "none")};
  cursor: pointer;
`;

const Container = styled.div`
  padding: 28px 36px 0 36px;
  width: 100%;
  /* height: calc(100vh - 217px); */
  height: 100%;
`;
const Glid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
`;

const PickerWrap = styled.div`
  width: 100%;
  margin: 8px 0;
  .ant-picker {
    cursor: pointer;
    height: calc(1rem + 22px);
    input {
      cursor: pointer;
      font-size: 1rem;
    }
  }
  .ant-picker-input {
  }
`;
