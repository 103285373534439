import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

// components
import { Wrapper, AbsoluteWrapper, Icon, Text, PaddingText } from "../../layouts/layoutStorage";

// icon
import OnePaging from "../../assets/icon/one_paging.png";
import colors from "../../styles/constants/colors";

const DatePicker = ({ Y, M, D, setDate, status, setStatus }) => {
  const DateWidth = (260 - 46) / 7;
  const calRef = useRef();

  const CalenderClose = (e) => {
    if (status && (!calRef.current || !calRef.current.contains(e.target))) {
      setStatus(false);
    }
  };

  useEffect(() => {
    if (status) {
      window.addEventListener("click", CalenderClose);

      return () => {
        window.removeEventListener("click", CalenderClose);
      };
    }
  }, [status]);

  const DateCheck = () => {
    var Calendar = [];

    let Year = Y;
    let Month = Number(M);
    let Day = Number(D);

    var LastDate = new Date(Year, Month, 0);
    let LastDay = LastDate.getDate();
    let LastYoil = 6 - LastDate.getDay();

    var FisrtDate = new Date(Year, Month - 1, 1);
    let FisrtYoil = FisrtDate.getDay();

    var BeforeDate = new Date(Year, Month - 1, 0);
    let BeforeDay = BeforeDate.getDate();

    for (let i = 0; i < LastDay; i++) {
      let ThisDay = i + 1;
      let Today = ThisDay == Day;
      let ThisDate = new Date(Year, Month - 1, ThisDay);
      let ThisYoil = ThisDate.getDay();
      let DateColor = colors.Gr04;

      if (ThisYoil == 0) {
        DateColor = colors.Red02;
      } else if (ThisYoil == 6) {
        DateColor = colors.Bl07;
      }

      if (ThisDay == 1) {
        for (let fi = 0; fi < FisrtYoil; fi++) {
          Calendar.push(
            <Wrapper bgc={colors.White} radius={`50%`} jc={`center`} width={`${DateWidth}px`} height={`${DateWidth}px`}>
              <PaddingText color={colors.Gr02} className="f12">
                {BeforeDay - fi}
              </PaddingText>
            </Wrapper>
          );
        }
        Calendar.push(
          <Wrapper
            onClick={() => {
              setDate(ThisDay);
            }}
            cursor={`pointer`}
            bgc={`${Today ? colors.Bl07 : colors.White}`}
            radius={`50%`}
            jc={`center`}
            width={`${DateWidth}px`}
            height={`${DateWidth}px`}
          >
            <PaddingText color={`${Today ? colors.White : DateColor}`} className="f12">
              {ThisDay}
            </PaddingText>
          </Wrapper>
        );
      } else if (ThisDay == LastDay) {
        Calendar.push(
          <Wrapper
            onClick={() => {
              setDate(ThisDay);
            }}
            cursor={`pointer`}
            bgc={`${Today ? colors.Bl07 : colors.White}`}
            radius={`50%`}
            jc={`center`}
            width={`${DateWidth}px`}
            height={`${DateWidth}px`}
          >
            <PaddingText color={`${Today ? colors.White : DateColor}`} className="f12">
              {ThisDay}
            </PaddingText>
          </Wrapper>
        );
        for (let li = 0; li < LastYoil; li++) {
          Calendar.push(
            <Wrapper bgc={colors.White} radius={`50%`} jc={`center`} width={`${DateWidth}px`} height={`${DateWidth}px`}>
              <PaddingText color={colors.Gr02} className="f12">
                {li + 1}
              </PaddingText>
            </Wrapper>
          );
        }
      } else {
        Calendar.push(
          <Wrapper
            onClick={() => {
              setDate(ThisDay);
            }}
            cursor={`pointer`}
            bgc={`${Today ? colors.Bl07 : colors.White}`}
            radius={`50%`}
            jc={`center`}
            width={`${DateWidth}px`}
            height={`${DateWidth}px`}
          >
            <PaddingText color={`${Today ? colors.White : DateColor}`} className="f12">
              {ThisDay}
            </PaddingText>
          </Wrapper>
        );
      }
    }

    return Calendar;
  };

  return (
    <AbsoluteWrapper
      display={status ? `inline-flex` : `none`}
      z={`150`}
      left={`0`}
      bottom={`-8px`}
      border={`1px solid ${colors.Gr02}`}
      transform={`translate(0, 100%)`}
      width={`260px`}
      padding={`13px 22px`}
      bgc={colors.White}
    >
      <Wrapper wrap={`wrap`} width={`100%`} position={`relative`} ref={calRef}>
        <Wrapper padding={`0 22px`} jc={`space-between`} margin={`0 0 13px 0`} wrap={`wrap`} width={`100%`}>
          <Icon onClick={() => setDate(1, true, false)} width={`17px`} cursor={`pointer`} src={OnePaging} />

          <Text className="f14" weight={`500`}>{`${Y}년 ${M}월`}</Text>

          <Icon onClick={() => setDate(1, true, true)} width={`17px`} transform={`rotate(180deg)`} cursor={`pointer`} src={OnePaging} />
        </Wrapper>

        <Wrapper margin={`0 0 13px 0`} jc={`space-between`} width={`100%`}>
          <Text width={`calc(100% / 7)`} jc={`center`} className="f14" color={colors.Red02} weight={`500`}>{`일`}</Text>
          <Text width={`calc(100% / 7)`} jc={`center`} className="f14" color={colors.Gr03} weight={`500`}>{`월`}</Text>
          <Text width={`calc(100% / 7)`} jc={`center`} className="f14" color={colors.Gr03} weight={`500`}>{`화`}</Text>
          <Text width={`calc(100% / 7)`} jc={`center`} className="f14" color={colors.Gr03} weight={`500`}>{`수`}</Text>
          <Text width={`calc(100% / 7)`} jc={`center`} className="f14" color={colors.Gr03} weight={`500`}>{`목`}</Text>
          <Text width={`calc(100% / 7)`} jc={`center`} className="f14" color={colors.Gr03} weight={`500`}>{`금`}</Text>
          <Text width={`calc(100% / 7)`} jc={`center`} className="f14" color={colors.Bl07} weight={`500`}>{`토`}</Text>
        </Wrapper>

        <Wrapper jc={`space-between`} wrap={`wrap`} width={`100%`}>
          {Y && M && D && DateCheck()}
        </Wrapper>
      </Wrapper>
    </AbsoluteWrapper>
  );
};

export default DatePicker;
