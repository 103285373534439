import styled from "styled-components";
import Button from "../modules/button";
import FixedButton from "../modules/fixedButton";
import base from "../styles/constants/base";
import colors from "../styles/constants/colors";
import flexbox from "../styles/func/flexbox";

const Container = styled.div`
  width: 100%;
`;

const OuterWrapper = styled.div`
  position: relative;
  width : 100%;
  max-width: 1000px;
  //height: ${ ({hc}) => (hc ? '870px' : 'calc(100vh - 150px)') };
  height : calc(100vh - 48px);
  margin: 0 auto;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  border-radius: ${base.borderRadius.default}px;
  box-shadow: ${base.boxShadow.default};
  padding: ${base.spacing.main}px 0 80px 0;
  overflow: hidden;
`;

const ButtonBox = styled.div`
  width: 100%;
  height: 90px;
  background: ${colors.white};
  position: absolute;
  bottom: 0px;
`;

const MainLayout = ({ heightType, children, button = null, bottomButton = null }) => {
  return (
    <Container>
      <OuterWrapper hc={heightType}>
        <Wrapper>
          {children}

          {bottomButton && (
            <ButtonBox>
              <Button
                pos={{ value: "absolute", bottom: 20, right: 20 }}
                text={bottomButton.text}
                clickHandler={bottomButton.clickHandler}
                width={`230px`}
              />
            </ButtonBox>
          )}
        </Wrapper>

        {button && (
          <FixedButton type={button.type} clickHandler={button.clickHandler} />
        )}
      </OuterWrapper>
    </Container>
  );
};

export default MainLayout;
