const customerDetailData = {
  name: "고크리스틴",
  participantDate: "2022-02-20",
  thumbnail: "",
  // general, partner
  type: "partner",
  number: 975,
  email: "semos0px@gmail.com",
  mobile: "010-0000-0000",
  age: "20~30대",
  comment: "워터스포츠에 관심이 많음",
  profile: [
    {
      id: 1,
      title: "[프리다이빙] 고크리스틴(크리스다이브)",
    },
    {
      id: 2,
      title: "[스쿠버다이빙] 고크리스틴(크리스다이브)",
    },
    {
      id: 3,
      title: "[서핑] 고크리스틴(크리스다이브)",
    },
  ],
};

export default customerDetailData;
