const partnerProfileData = {
  base: {
    img: {
      base: "",
      files: "",
    },
    main: "워터스포츠",
    sub: "프리다이빙",
    title: "핸강사(김현주)",
  },
  career: [
    {
      id: 1,
      text: "SDI 스쿠버다이빙 강사",
    },
    {
      id: 2,
      text: "SDI 스쿠버다이빙 강사",
    },
    {
      id: 3,
      text: "수중 사진 작가",
    },
  ],
};

export default partnerProfileData;
