import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// components
import DesignedMainHeader from "../../components/designedHeader";
import DesignedMainLayout from "../../layouts/designedMainLayout";
import DropDown from "../../components/designedComponents/dropDown";
import DrawerModal from "../../components/drawerModal";
import Table from "../../layouts/table";
import { TableFilterContainer, Wrapper, Icon, TableContent, TableFooter, TableColumn, TableFooterPaging } from "../../layouts/layoutStorage";
import BannerInput from "../../components/designedBanner/bannerInput";
import MainBannerPage from "../../components/designedBanner/MainBannerPage";
// icon
import DoublePaging from "../../assets/icon/double_paging.png";
import OnePaging from "../../assets/icon/one_paging.png";

const MENU = ["메인", "띠", "하단"];

const Banner = () => {
  const { n, t, o, s, m } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [filter, setFilter] = useState({
    order: o,
    status: s,
    method: m,
  });

  const [page, setPage] = useState(n);
  const [type, setType] = useState(t);
  const [count, setCount] = useState(0);

  const [banner, setBanner] = useState([]);
  const [bannerM, setBannerM] = useState([]);
  const [bannerP, setBannerP] = useState([]);
  const [bannerInfo, setBannerInfo] = useState();

  const [Height, setHeight] = useState({
    TableFilter: 0,
    TableHeader: 0,
    TableFooter: 0,
    Layout: 0,
    LayoutHeader: 0,
  });

  const SlideSetting = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    setTimeout(() => {
      const Layout = document.getElementById("Layout")?.offsetHeight - 36;
      const LayoutHeader = document.getElementById("LayoutHeader")?.offsetHeight + 65;
      const TableFilter = document.getElementById("TableFilter")?.offsetHeight + 30;
      const TableHeader = document.getElementById("TableHeader")?.offsetHeight;
      const TableFooter = document.getElementById("TableFooter")?.offsetHeight;

      setHeight({
        TableFilter: TableFilter ? TableFilter : 0,
        TableHeader: TableHeader,
        TableFooter: TableFooter,
        Layout: Layout,
        LayoutHeader: LayoutHeader ? LayoutHeader : 0,
      });
    }, 500);
  }, []);

  useEffect(() => {
    var token = window.localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", "banner_update");
    frm.append("token", token);
    frm.append("page", n);
    frm.append("type", t);
    frm.append("method", m);
    frm.append("status", s);
    frm.append("order", o);

    axios
      .post("https://ai.semos.kr/semos_admin/banner/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var status = Value.status;
        var statusMessage = Value.status_message;
        var BannerList = Value.banner_list;
        var BannerCount = Value.banner_count;
        var BannerM = Value.banner_mobile;
        var BannerP = Value.banner_pc;

        if (status == "success") {
          setCount(BannerCount);
          setBanner(BannerList);
          setBannerM(BannerM);
          setBannerP(BannerP);
        } else if (status == "waring") {
          alert(statusMessage);
        }
      });
  }, []);

  const PageInput = (num) => {
    if (num) {
      var token = localStorage.getItem("token");

      const frm = new FormData();

      frm.append("mode", "banner_update");
      frm.append("token", token);
      frm.append("page", num);
      frm.append("type", type);
      frm.append("method", filter.method);
      frm.append("status", filter.status);
      frm.append("order", filter.order);

      axios
        .post("https://ai.semos.kr/semos_admin/banner/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var Status = Value.status;
          var BannerList = Value.banner_list;
          var BannerCount = Value.banner_count;

          if (Status == "success") {
            setCount(BannerCount);
            setBanner(BannerList);
            setPage(num);
            navigate(
              `/banner/${num}/${type}/${filter.order ? filter.order : "all"}/${filter.status ? filter.status : "all"}/${filter.method ? filter.method : "all"}`
            );
          }
        });

      if (open) {
        setOpen(false);
        document.body.style.removeProperty("overflow");
        document.getElementById("root").style.pointerEvents = "auto";
      }
    }
  };

  const numPages = Math.ceil(count / 12);

  let PageList = [];

  if (n < 11) {
    for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
      PageList.push(i + 1);
    }
  } else {
    if (numPages - 10 < n) {
      var startPage = numPages - 19;
      if (startPage < 1) {
        startPage = 1;
      }

      for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    } else {
      var startPage = n - 9;
      if (startPage < 1) {
        startPage = 1;
      }

      for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    }
  }

  const OrderList = {
    order: "노출 순위",
    date: "등록일시",
  };

  const StatusList = {
    all: "상태 전체",
    1: "활성화",
    2: "비활성화",
  };

  const MethodList = {
    all: "연결 방식 전체",
    1: "페이지 연결",
    2: "리스팅 연결",
  };

  const FilterSearch = (types, value) => {
    if (value) {
      if (types == "method") {
        var token = localStorage.getItem("token");

        const frm = new FormData();

        frm.append("mode", "banner_update");
        frm.append("token", token);
        frm.append("page", 1);
        frm.append("type", type);
        frm.append("method", value);
        frm.append("status", filter.status);
        frm.append("order", filter.order);

        axios
          .post("https://ai.semos.kr/semos_admin/banner/data", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var BannerList = Value.banner_list;
            var BannerCount = Value.banner_count;

            if (Status == "success") {
              setCount(BannerCount);
              setBanner(BannerList);
              setPage(1);
              setFilter((prev) => ({ ...prev, [types]: value }));
              navigate(`/banner/${1}/${type}/${filter.order ? filter.order : "all"}/${filter.status ? filter.status : "all"}/${value ? value : "all"}`);
            }
          });
      } else if (types == "status") {
        var token = localStorage.getItem("token");

        const frm = new FormData();

        frm.append("mode", "banner_update");
        frm.append("token", token);
        frm.append("page", 1);
        frm.append("type", type);
        frm.append("method", filter.method);
        frm.append("status", value);
        frm.append("order", filter.order);

        axios
          .post("https://ai.semos.kr/semos_admin/banner/data", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var BannerList = Value.banner_list;
            var BannerCount = Value.banner_count;

            if (Status == "success") {
              setCount(BannerCount);
              setBanner(BannerList);
              setPage(1);
              setFilter((prev) => ({ ...prev, [types]: value }));
              navigate(`/banner/${1}/${type}/${filter.order ? filter.order : "all"}/${value ? value : "all"}/${filter.method ? filter.method : "all"}`);
            }
          });
      } else if (types == "order") {
        var token = localStorage.getItem("token");

        const frm = new FormData();

        frm.append("mode", "banner_update");
        frm.append("token", token);
        frm.append("page", 1);
        frm.append("type", type);
        frm.append("method", filter.method);
        frm.append("status", filter.status);
        frm.append("order", value);

        axios
          .post("https://ai.semos.kr/semos_admin/banner/data", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var BannerList = Value.banner_list;
            var BannerCount = Value.banner_count;

            if (Status == "success") {
              setCount(BannerCount);
              setBanner(BannerList);
              setPage(1);
              setFilter((prev) => ({ ...prev, [types]: value }));
              navigate(`/banner/${1}/${type}/${value ? value : "all"}/${filter.status ? filter.status : "all"}/${filter.method ? filter.method : "all"}`);
            }
          });
      } else if (types == "type") {
        var token = localStorage.getItem("token");

        const frm = new FormData();

        frm.append("mode", "banner_update");
        frm.append("token", token);
        frm.append("page", 1);
        frm.append("type", value);
        frm.append("method", filter.method);
        frm.append("status", filter.status);
        frm.append("order", filter.order);

        axios
          .post("https://ai.semos.kr/semos_admin/banner/data", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var BannerList = Value.banner_list;
            var BannerCount = Value.banner_count;
            var BannerM = Value.banner_mobile;
            var BannerP = Value.banner_pc;

            if (Status == "success") {
              setCount(BannerCount);
              setBanner(BannerList);
              setBannerM(BannerM);
              setBannerP(BannerP);
              setPage(1);
              setType(value);
              navigate(
                `/banner/${1}/${value}/${filter.order ? filter.order : "all"}/${filter.status ? filter.status : "all"}/${filter.method ? filter.method : "all"}`
              );
            }
          });
      }

      if (open) {
        setOpen(false);
        document.body.style.removeProperty("overflow");
        document.getElementById("root").style.pointerEvents = "auto";
      }
    }
  };

  const BannerSpecific = (number) => {
    window.open(`/banner_detail/${number}/${type}`, "_blank");
    // if (number == "new") {
    //   setBannerInfo({
    //     id: "new",
    //     title: "",
    //     content: "",
    //     image_m: "",
    //     image_p: "",
    //     method: "",
    //     link: "",
    //     order: "",
    //     status: "",
    //   });
    //   setOpen(true);
    // } else {
    //   var token = localStorage.getItem("token");

    //   const frm = new FormData();

    //   frm.append("mode", "banner_specific");
    //   frm.append("token", token);
    //   frm.append("number", number);

    //   axios
    //     .post("https://ai.semos.kr/semos_admin/banner/data", frm, {
    //       headers: { "Content-Type": "multipart/form-data" },
    //     })
    //     .then((data) => {
    //       var Value = data.data;
    //       var Status = Value.status;
    //       var BannerInfo = Value.banner_info;

    //       if (Status == "success") {
    //         setBannerInfo(BannerInfo);
    //         setOpen(true);
    //       }
    //     });
    // }
  };

  return (
    <>
      <DesignedMainLayout style={{ minWidth: "1300px" }}>
        {/*<BigTitle className="f24">배너 관리</BigTitle>*/}
        <DesignedMainHeader title="배너 관리" />

        <Table>
          <TableFilterContainer id="TableFilter" margin={`0 0 30px 0`} style={{ justifyContent: "flex-start", flexWrap: "wrap" }}>
            <BannerMenuConatiner>
              {MENU.map((el, i) => (
                <BannerMenu onClick={() => FilterSearch("type", i + 1)} key={el} className="f16" active={i + 1 == type}>
                  {el} 배너
                </BannerMenu>
              ))}
            </BannerMenuConatiner>

            {type == 1 ? (
              <MainBannerPage />
            ) : (
              <>
                <BannerPreview>
                  <BannerContainer>
                    <BannerTitle className="f16">PC 배너 미리보기</BannerTitle>
                    <BannerPhotoBox w={496} h={0.365}>
                      <Slider {...SlideSetting}>
                        {bannerP.map((item, idx) => (
                          <BannerPhotoContainer w={496} h={0.365}>
                            <Photo key={`${idx}_bannerP`} src={item} w={496} h={0.365} />
                            <NumberBox>
                              <span className="first_number">{idx + 1}</span>
                              <span className="last_number">/</span>
                              <span className="last_number">{bannerM.length}</span>
                            </NumberBox>
                          </BannerPhotoContainer>
                        ))}
                      </Slider>
                    </BannerPhotoBox>
                  </BannerContainer>

                  <BannerContainer>
                    <BannerTitle className="f16">모바일 배너 미리보기</BannerTitle>
                    <BannerPhotoBox w={238.21} h={0.76}>
                      <Slider {...SlideSetting}>
                        {bannerM.map((item, idx) => (
                          <BannerPhotoContainer w={238.21} h={0.76}>
                            <Photo key={`${idx}_bannerM`} src={item} w={238.21} h={0.76} />
                            <NumberBox>
                              <span className="first_number f14">{idx + 1}</span>
                              <span className="last_number f14">/</span>
                              <span className="last_number f14">{bannerM.length}</span>
                            </NumberBox>
                          </BannerPhotoContainer>
                        ))}
                      </Slider>
                    </BannerPhotoBox>
                  </BannerContainer>
                </BannerPreview>

                <JustifyBetween>
                  <FilterArea>
                    <AlignText className="f13">정렬</AlignText>
                    <DropDown
                      defaultText="노출순서"
                      option={[
                        { text: "노출순서", value: "order" },
                        { text: "등록일시", value: "date" },
                      ]}
                      data={OrderList[filter.order]}
                      setData={FilterSearch}
                      type={"custom"}
                      keyName={"order"}
                    />
                    <DropDown
                      defaultText="연결 방식 전체"
                      option={[
                        { text: "연결 방식 전체", value: "all" },
                        { text: "페이지 연결", value: 1 },
                        { text: "리스팅 연결", value: 2 },
                      ]}
                      data={filter.method ? MethodList[filter.method] : "연결 방식 전체"}
                      setData={FilterSearch}
                      type={"custom"}
                      keyName={"method"}
                    />
                    <DropDown
                      defaultText="상태 전체"
                      option={[
                        { text: "상태 전체", value: "all" },
                        { text: "활성화", value: 1 },
                        { text: "비활성화", value: 2 },
                      ]}
                      data={filter.status ? StatusList[filter.status] : "상태 전체"}
                      setData={FilterSearch}
                      type={"custom"}
                      keyName={"status"}
                    />
                  </FilterArea>
                  <NewBannerButton onClick={() => BannerSpecific("new")} className="f14">
                    배너 추가
                  </NewBannerButton>
                </JustifyBetween>
              </>
            )}
          </TableFilterContainer>

          {Number(type) !== 1 && (
            <TableContent height={Height.Layout - (Height.LayoutHeader + Height.TableFilter + Height.TableFooter)} contentHeight={Height.TableHeader}>
              <section id="TableHeader">
                <TableColumn width={`10`} weight={`500`} br={`1px solid #E6E6E6`}>
                  노출순서
                </TableColumn>

                <TableColumn width={`50`} weight={`500`} br={`1px solid #E6E6E6`}>
                  배너이름
                </TableColumn>

                <TableColumn width={`10`} weight={`500`} br={`1px solid #E6E6E6`}>
                  연결 방식
                </TableColumn>

                <TableColumn width={`10`} weight={`500`} br={`1px solid #E6E6E6`}>
                  상태
                </TableColumn>

                <TableColumn width={`20`} weight={`500`} br={`1px solid #E6E6E6`}>
                  등록일시
                </TableColumn>
              </section>

              <section>
                {banner.length
                  ? banner.map((item, idx) => (
                      <Link to={`/banner_detail/${item.id}/${type}`}>
                        <Wrapper
                          cursor={`pointer`}
                          key={`${idx}_reservation`}
                          jc={`flex-start`}
                          bl={`1px solid #E6E6E6`}
                          br={`1px solid #E6E6E6`}
                          bb={`1px solid #E6E6E6`}
                          width={`100%`}
                          bgc={`#ffffff`}
                        >
                          <TableColumn width={`10`} weight={`500`} br={`1px solid #E6E6E6`}>
                            {`${item.order ? item.order : "비활성"}`}
                          </TableColumn>

                          <TableColumn width={`50`} weight={`500`} br={`1px solid #E6E6E6`}>
                            {`${item.title ? item.title : "제목 미작성"}`}
                          </TableColumn>

                          <TableColumn width={`10`} weight={`500`} br={`1px solid #E6E6E6`}>
                            {`${item.link_method !== "1" ? "리스팅 연결" : "페이지 연결"}`}
                          </TableColumn>

                          <TableColumn
                            color={item.link_method == 1 ? `#3383FF` : `#FF9187`}
                            width={`10`}
                            weight={`500`}
                            br={`1px solid #E6E6E6`}
                            padding={`9px 20px`}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {item.link_method == 1 ? "활성화" : "비활성화"}
                          </TableColumn>

                          <TableColumn width={`20`} weight={`500`} br={`1px solid #E6E6E6`} color={"#ACAFB5"}>
                            {`${item.date}`}
                          </TableColumn>
                        </Wrapper>
                      </Link>
                    ))
                  : ""}

                <TableFooter id="TableFooter">
                  <Icon
                    margin={`0 16px 0 0`}
                    width={`calc(1rem * (20 / 14))`}
                    cursor={`pointer`}
                    src={DoublePaging}
                    onClick={() => {
                      if (Number(n) < 11) {
                        PageInput(1);
                      } else {
                        PageInput(Number(n) - 10);
                      }
                    }}
                  />
                  <Icon
                    margin={`0 16px 0 0`}
                    width={`calc(1rem * (20 / 14))`}
                    cursor={`pointer`}
                    src={OnePaging}
                    onClick={() => {
                      if (page > 1) {
                        PageInput(n - 1);
                      } else {
                        window.alert("첫번째 페이지 입니다.");
                      }
                    }}
                  />

                  {PageList.length
                    ? PageList.map((item, idx) => (
                        <TableFooterPaging
                          key={`paging_number_${idx}`}
                          margin={`0 16px 0 0`}
                          color={`${n == item ? "#3383FF" : "#ACAFB5"}`}
                          onClick={() => PageInput(item)}
                        >
                          {item}
                        </TableFooterPaging>
                      ))
                    : ""}

                  <Icon
                    margin={`0 16px 0 0`}
                    width={`calc(1rem * (20 / 14))`}
                    cursor={`pointer`}
                    transform={`rotate(180deg)`}
                    src={OnePaging}
                    onClick={() => {
                      if (n < numPages) {
                        PageInput(Number(n) + 1);
                      } else {
                        window.alert("마지막 페이지 입니다.");
                      }
                    }}
                  />
                  <Icon
                    width={`calc(1rem * (20 / 14))`}
                    cursor={`pointer`}
                    transform={`rotate(180deg)`}
                    src={DoublePaging}
                    onClick={() => {
                      if (Number(n) + 10 > numPages) {
                        PageInput(numPages);
                      } else {
                        PageInput(Number(n) + 10);
                      }
                    }}
                  />
                </TableFooter>
              </section>
            </TableContent>
          )}
        </Table>
      </DesignedMainLayout>

      <DrawerModal open={open} setOpen={setOpen}>
        <BannerInput types={type} data={bannerInfo} />
      </DrawerModal>
    </>
  );
};

export default Banner;

const BigTitle = styled.div`
  font-weight: 700;
  line-height: 142%;
  color: #3c3e41;
  margin-bottom: 25px;
  letter-spacing: -0.02em;
`;

const BannerMenuConatiner = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 39px;
  width: 100%;
`;

const BannerMenu = styled.div`
  padding: 10px;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: ${({ active }) => (active ? "#3383FF" : "#3c3e41")};
  margin-right: 38px;
  border-bottom: ${({ active }) => (active ? "1px solid #3383ff" : "none")};
  cursor: pointer;
`;

const BannerPreview = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 39px;
  width: 100%;
`;

const BannerContainer = styled.div`
  margin-right: 24px;
`;

const BannerTitle = styled.div`
  margin-bottom: 16px;
  color: #3c3e41;
  font-weight: 700;
`;

const BannerPhotoBox = styled.div`
  width: ${({ w }) => `calc(1rem * ${w} / 14)`};
  height: ${({ w, h }) => `calc(1rem * ${w * h} / 14)`};
  background-color: aliceblue;
  overflow: hidden;
`;

const BannerPhotoContainer = styled.div`
  width: ${({ w }) => `calc(1rem * ${w} / 14)`};
  height: ${({ w, h }) => `calc(1rem * ${w * h} / 14)`};
  overflow: hidden;
  display: inline-block;
  position: relative;
`;

const NumberBox = styled.div`
  padding: 2px 10px;
  display: inline-flex;
  justify-contetnt: center;
  align-items: center;
  white-space: nowrap;
  position: absolute;
  bottom: 16px;
  right: 50%;
  transform: translate(50%, 0%);
  background-color: rgba(0, 0, 0, 0.36);
  border-radius: 17px;

  .first_number {
    color: #ffffff;
    display: inline-block;
  }

  .last_number {
    color: #818792;
    margin-left: 3.5px;
    display: inline-block;
  }
`;

const Photo = styled.img`
  width: ${({ w }) => `calc(1rem * ${w} / 14)`};
  height: ${({ w, h }) => `calc(1rem * ${w * h} / 14)`};
`;

const JustifyBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const FilterArea = styled.div`
  display: flex;
  align-items: center;
`;

const AlignText = styled.div`
  font-weight: 500;
  letter-spacing: -0.02em;
  color: #3c3e41;
  margin-right: 10px;
`;

const NewBannerButton = styled.div`
  padding: calc(1rem * 8.5 / 14) calc(1rem * 31.5 / 14);
  color: #ffffff;
  background-color: #0e6dff;
  font-weight: 700;
  letter-spacing: -0.02em;
  cursor: pointer;
  border-radius: 8px;
`;
