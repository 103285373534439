import { useState } from "react";
import styled from "styled-components";
import base from "../../styles/constants/base";
import colors from "../../styles/constants/colors";
import typography from "../../styles/constants/typography";
import flexbox from "../../styles/func/flexbox";
import ScheduleCard from "../scheduleCard";

const Container = styled.div`
  width: 100%;
`;

const Top = styled.div`
  width: 100%;
  ${flexbox("space-between")}
  margin-bottom: 20px;

  div {
    label {
      font-size: ${typography.size.medium}px;
      font-weight: ${typography.weight.bold};
    }

    p {
      margin-top: 10px;
    }
  }

  button {
    background-color: ${colors.blue};
    color: ${colors.white};
    padding: 10px 20px;
    height: ${base.height.input}px;
    border-radius: ${base.borderRadius.default}px;
  }
`;

const ScheduleBox = ({
  addScheduleHandler,
  location,
  week,
  clock,
  schedule,
}) => {
  const { scheduleList, deleteScheduleHandler } = schedule;

  return (
    <Container>
      <Top>
        <div>
          <label>기본 일정</label>
          <p>매달 자동으로 입력되는 일정입니다.</p>
        </div>

        <button type="button" onClick={addScheduleHandler}>
          추가
        </button>
      </Top>

      <ScheduleCard location={location} week={week} clock={clock} />

      {scheduleList &&
        scheduleList.map((item, idx) => (
          <ScheduleCard
            key={idx}
            type="read"
            location={item.location}
            week={item.week}
            clock={item.clock}
          />
        ))}
    </Container>
  );
};

export default ScheduleBox;
