import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ labels, list, style, responsive = true }) => {
  const options = {
    responsive,
    interaction: {
        intersect: false,
        mode: 'index',
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          fontSize: 14,
        },
        grid: {
          drawOnChartArea: false,
        },
        beginAtZero: true,
      },
    },
  };


  const data = {
    labels,
    datasets: [
      {
        data: list,
        borderColor: "#2C84EE",
        backgroundColor: "#2C84EE",
      }
    ],
  };

  return <Line options={options} data={data} style={style} />;
};

export default LineChart;
