import styled from "styled-components";
import colors from "../../styles/constants/colors";
import sendIcon from "../../assets/icon/send.svg";
import flexbox from "../../styles/func/flexbox";
import base from "../../styles/constants/base";

const Container = styled.div`
  width: 100%;
  padding: 20px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  box-shadow: ${base.boxShadow.default};
  background-color: ${colors.white};
  border-bottom-left-radius: ${base.borderRadius.default}px;
  border-bottom-right-radius: ${base.borderRadius.default}px;

  textarea {
    width: 100%;
    height: 170px;
    padding: 20px;
    background-color: ${colors.white};
    border: 1px solid ${colors.gray};

    border-radius: 10px;
    resize: none;

    &:focus {
      border-radius: 10px;
      border: 1px solid ${colors.gray};
    }
  }

  button {
    width: 76px;
    background-color: ${colors.blue};
    border-radius: 10px;
    ${flexbox()}
    margin-left: 20px;
  }
`;

const ChatTextarea = ({ value, changeHandler, sendHandler }) => {
  return (
    <Container>
      <textarea name="chat" value={value} onChange={(e) => changeHandler(e.target.value)} />
      <button type="button" onClick={() => sendHandler(value)}>
        <img src={sendIcon} />
      </button>
    </Container>
  );
};

export default ChatTextarea;
