import styled from "styled-components";
import colors from "../styles/constants/colors";

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${colors.lightGray};
  margin: ${({ margin }) => margin} 0;
`;

const Border = ({ margin = "20px" }) => {
  return <Line margin={margin}></Line>;
};

export default Border;
