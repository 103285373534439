import { useNavigate } from "react-router-dom";
import RegisterItem from "../../../../components/faq/faqItem";
import RegisterCard from "../../../../components/faq/registerCard";
import MainHeader from "../../../../components/header";
import faqList from "../../../../data/faq";
import useInput from "../../../../hooks/useInput";
import useList from "../../../../hooks/useList";
import MainLayout from "../../../../layouts/mainLayout";
import { Main, ButtonBox, ListBox } from "./style";
import axios from "axios";

const FAQRegisterPage = () => {
  const navigate = useNavigate();

  const { setList: setFaqList } = useList(faqList);

  const {
    list: newFaqList,
    addHandler: addNewFaqHandler,
    editHandler: editNewFaqHandler,
    deleteHandler: deleteNewFaqHandler,
  } = useList([]);

  const { value, inputChangeHandler, setValue } = useInput({
    category: "",
    question: "",
    answer: "",
  });

  const faqAddHandler = () => {
    addNewFaqHandler({
      id: new Date(),
      ...value,
    });

    setValue({
      category: "",
      question: "",
      answer: "",
    });
  };

  const resetHandler = () => {
    setValue({
      category: "",
      question: "",
      answer: "",
    });
  };

  const registerHandler = () => {
    //setFaqList((prev) => [...prev, ...newFaqList]);

    if(window.confirm('공지사항을 등록하시겠습니까?')) {
        var token = window.localStorage.getItem('token')
  
        axios.post("https://ai.semos.kr/semos_admin/notice/data",  {
                token: token,
                mode : 'faq_upload',
                faq_data : newFaqList
      }, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }).then(data => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message
  
          if(status == 'success') {
            //setPartner(Value.partner_data)
            alert('faq 등록이 완료되었습니다.')
            //window.location.replace('/notice')
          } else if(status == 'waring') {
            alert(statusMessage)
          }
  
        });
      }

   //navigate("/faq");
  };

  return (
    <MainLayout
      bottomButton={{ text: "등록하기", clickHandler: registerHandler }}
    >
      <MainHeader title="자주 묻는 질문 등록">
        <ButtonBox>
          <button type="button" onClick={faqAddHandler}>
            질문 추가하기
          </button>
        </ButtonBox>
      </MainHeader>

      <Main>
        <RegisterCard
          value={value}
          changeHandler={inputChangeHandler}
          resetHandler={resetHandler}
        />

        <ListBox>
          {newFaqList.map((item, idx) => (
            <RegisterItem
              key={idx}
              value={item}
              deleteHandler={() => deleteNewFaqHandler(item.id)}
            />
          ))}
        </ListBox>
      </Main>
    </MainLayout>
  );
};

export default FAQRegisterPage;
