import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";

import useInput from "../../hooks/useInput";

import DropDown from "../../components/designedComponents/dropDown";
import ToggleButton from "../../modules/toggleButton";
import DesignedMainHeader from "../../components/designedHeader";
import DesignedMainLayout from "../../layouts/designedMainLayout";
import DrawerModal from "../../components/drawerModal";
import Table from "../../layouts/table";
import {
  TableFilterContainer,
  TableFilterContent,
  TableWrapper,
  Wrapper,
  WrapperColmun,
  AbsoluteWrapper,
  TextArea,
  Text,
  TableContent,
  TableFooter,
  TableColumn,
  TableFooterPaging,
} from "../../layouts/layoutStorage";
import colors from "../../styles/constants/colors";

const CalculateGuide = () => {
  const [data, setData] = useState({
    info: "",
    method: "",
  });
  const [inputOpen, setInputOpen] = useState({
    left: false,
    right: false,
  });

  return (
    <>
      <DesignedMainLayout>
        <Wrapper width="100%" margin="0 0 28px 0">
          <Text className="f24" weight="700" color={colors.Gr05}>
            정산내역 가이드
          </Text>
        </Wrapper>

        <Wrapper width="100%" fd="row" gap="32px">
          <WrapperColmun width="100%" gap="14px">
            <Wrap>
              <Text margin="0 0 16px 0" cursor="pointer" lh="145%" ls="-0.36px" className="f18" weight="700" color={colors.Gr05}>
                안내사항
              </Text>
              {inputOpen.left ? (
                <TextArea value={data.info} onChange={(e) => setData((prev) => ({ ...prev, info: e.target.value }))} height="100%"></TextArea>
              ) : (
                <ul>{data.info}</ul>
              )}
            </Wrap>

            <Text
              onClick={() =>
                setInputOpen({
                  left: !inputOpen.left,
                  right: inputOpen.right,
                })
              }
              cursor="pointer"
              className="f16"
              weight="600"
              color={colors.Bl07}
            >
              {inputOpen.left ? "저장하기" : "수정하기"}
            </Text>
          </WrapperColmun>
          <WrapperColmun width="100%" gap="14px">
            <Wrap>
              <Text margin="0 0 16px 0" cursor="pointer" lh="145%" ls="-0.36px" className="f18" weight="700" color={colors.Gr05}>
                정산 계산 방법
              </Text>
              {inputOpen.right ? (
                <TextArea value={data.method} onChange={(e) => setData((prev) => ({ ...prev, method: e.target.value }))} height="100%"></TextArea>
              ) : (
                <ul>{data.method}</ul>
              )}
            </Wrap>

            <Text
              onClick={() =>
                setInputOpen({
                  left: inputOpen.left,
                  right: !inputOpen.right,
                })
              }
              cursor="pointer"
              className="f16"
              weight="600"
              color={colors.Bl07}
            >
              {inputOpen.right ? "저장하기" : "수정하기"}
            </Text>
          </WrapperColmun>
        </Wrapper>
      </DesignedMainLayout>
    </>
  );
};

export default CalculateGuide;

const Input = styled.input`
  width: ${({ width }) => `${width}`};
  display: inline-block;
`;

const Wrap = styled.div`
  display: flex;
  height: 650px;
  flex-direction: column;
  background-color: ${colors.White};
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${colors.Gr02};
  word-break: keep-all;
  ul,
  span {
    font-size: calc(1rem * (16 / 14));
    line-height: 150%;
    letter-spacing: -0.32px;
  }
`;
