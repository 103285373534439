import { Link } from "react-router-dom";
import styled from "styled-components";
import colors from "../../styles/constants/colors";
import typography from "../../styles/constants/typography";
import flexbox from "../../styles/func/flexbox";
import MessageBubble from "./message";

const Card = styled.li`
  width: 100%;
  padding: 15px 0;

  header {
    width: 100%;
    ${flexbox("space-between")}
    padding-bottom: 10px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;

const Bottom = styled.div`
  ${flexbox("space-between")}

  time {
    font-size: ${typography.size.small}px;
  }
`;

const SLink = styled(Link)`
  width: 100%;
  height: 100%;
`;

const Status = styled.span`
  color: ${colors.white};
  background-color: ${({ color }) => color};
  white-space: nowrap;
  padding: 5px 10px;
  border-radius: 40px;
  font-size: ${typography.size.small}px;
`;

const Border = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${colors.lightGray};
`;

const InquiryCard = ({ idx, data }) => {
  const removeBorder = parseInt(idx + 1) % 6 === 0 ? true : false;

  return (
    <>
      <Card>
        <SLink to={`/inquiry/${data.id}`}>
          <header>
            {data.service ? 
              <Wrapper>
                <span>{data.user}</span> | <span>{data.partner}</span> |
                <span> {data.service}</span>
              </Wrapper> 
              : 
              <Wrapper>
              <span>{data.user}</span> | <span>{data.partner}</span>
              </Wrapper>
            }

            {/*<Status color={data.status === "read" ? colors.gray : colors.red}>
              {data.status === "read" ? "확인" : "미확인"}
            </Status>*/}
          </header>

          <Bottom>
            <MessageBubble
              type="list"
              text={data.last_chat}
              isMe={false}
              color={true}
            />

            <time>{data.date}</time>
          </Bottom>
        </SLink>
      </Card>

      {!removeBorder && <Border />}
    </>
  );
};

export default InquiryCard;
