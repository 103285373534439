import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import colors from "../../styles/constants/colors";

//data
import { SportArray, FacilityArray } from "../../data/activity";

//components
import DropDown from "./dropDownforActivity";
import {
  TableFilterContainer,
  TableFilterContent,
  TableWrapper,
  Wrapper,
  WrapperRow,
  AbsoluteWrapper,
  Icon,
  Text,
  TableContent,
  TableFooter,
  TableColumn,
  TableFooterPaging,
  WrapperColmun,
} from "../../layouts/layoutStorage";
import ImageCrop from "../../components/designedComponents/imageCrop";

//img
import XIcon from "../../assets/icon/tagDelete.png";
import AddIcon from "../../assets/icon/careerAddButton.png";
import DeleteIcon from "../../assets/icon/careerDeleteButton.png";
import CameraAdd from "../../assets/icon/camerAdd.png";
import ImageDelete from "../../assets/icon/imageDeleteIcon.png";
import Magnifying from "../../assets/icon/magnify.png";
import CloseButton from "../../assets/icon/modalCloseButton.png";
import BlueCheck from "../../assets/icon/bluecheck.png";
import SearchIcon from "../../assets/icon/search.png";
import SearchIconB from "../../assets/icon/searchIconB.png";
import LocationIcon from "../../assets/icon/locationGrey.png";
import ClockIcon from "../../assets/icon/clockIcon.png";
import PlusIconGrey from "../../assets/icon/plusIconGrey.png";
import { curriculumData } from "./activityData";
import Curriculum from "./curriculum";
import CurriculumSection from "./curriculumSection";

const W = window.innerWidth;

const ActivityNormalInfo = ({ open, data, setData, deleteD, setDelete, setImageFromFile, ImgChange, setCropImage, cropImage }) => {
  //커리큘럼 입력
  const ref = useRef();

  const [curriculum, setCurriculum] = useState({
    content: "",
    time: "",
    title: "",
  });
  const { content, title } = curriculum;

  //   console.log(data.curriculum["day1"]);

  const onChangeCurriculum = (e) => {
    ref.current.style.height = "auto";
    ref.current.style.height = ref.current.scrollHeight + "px";
    const { value, name } = e.target;
    setCurriculum({
      ...curriculum,
      [name]: value,
    });
  };

  //커리큘럼 추가
  const addCurriculum = (day) => {
    if (curriculum.content.length > 0 && curriculum.title.length > 0 && curriculum.time.length > 0) {
      let copy = [...data.curriculum[day]];
      copy.push(curriculum);
      setData((prev) => ({ ...prev, curriculum: copy }));
      setCurriculum({
        content: "",
        time: "",
        title: "",
      });
    }
  };

  //커리큘럼 삭제
  const deleteCurriculum = (idx, item, date) => {
    let copy = [...data.curriculum[date]];
    let obj = { ...data.curriculum };
    let result = copy.filter((_, id) => id !== idx);
    obj[date] = result;
    setData((prev) => ({ ...prev, curriculum: obj }));

    var copyDel = deleteD;
    var copyDelValue = copyDel.curriculum;
    copyDelValue[copyDelValue.length] = item;

    setDelete((prev) => ({ ...prev, curriculum: copyDelValue }));
  };

  //커리큘럼 수정
  const curriculumChange = (key, value, idx, date) => {
    let copy = [...data.curriculum[date]];
    let obj = { ...data.curriculum };
    copy[idx][key] = value;
    obj[date] = copy;
    setData((prev) => ({ ...prev, curriculum: obj }));
  };

  //액티비티 장소 선택
  const [location, setLocation] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [LcSearching, setLcSearching] = useState(false);
  const [partner, setPartner] = useState("");

  const [category, setCategory] = useState({
    big: "대분류 선택",
    small: "소분류 선택",
  });

  const [box, setBox] = useState(false);
  const partnerRef = useRef();

  const handleClose = (e) => {
    if (box && (!partnerRef.current || !partnerRef.current.contains(e.target))) {
      setBox(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, [box]);

  //센터 선택

  const [searchCenter, setSearchCenter] = useState("");
  const [searchBox, setSearchBox] = useState(false);
  const [searching, setSearching] = useState(false);
  const [centerData, setCenterData] = useState([]);
  const [partnerData, setPartnerData] = useState([]);

  const selectCenter = () => {
    setSearching(true);
    let token = localStorage.getItem("token");
    const frm = new FormData();
    frm.append("mode", "center_search");
    frm.append("token", token);
    frm.append("search", searchCenter);
    axios
      .post("https://ai.semos.kr/semos_admin/activity/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data.result;
        setCenterData(Value);
        // var Status = Value.status;
        // var StatusMessage = Value.status_message;
        // var Location = Value.location;
      });
  };

  useEffect(() => {
    if (data.center_id) {
      let token = localStorage.getItem("token");
      const frm = new FormData();
      frm.append("mode", "partner_listing");
      frm.append("token", token);
      frm.append("center", data.center_id);
      axios
        .post("https://ai.semos.kr/semos_admin/activity/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          setPartnerData(data.data.partner_list);
        });
    }
  }, [data.center_id]);

  useEffect(() => {
    getScheduleList();
  }, [data.partner?.length, location]);

  const getScheduleList = () => {
    if (data.partner?.length) {
      let token = localStorage.getItem("token");
      axios
        .post(
          "https://ai.semos.kr/semos_admin/activity/data",
          {
            mode: "schedule_list",
            token: token,
            search: location,
            partner: data.partner,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((res) => {
          let Value = res.data;
          let Status = Value.status;
          let Location = Value.schedule_list;

          if (Status == "success") {
            let totalValues = Location.map((item) => item.id); //전체 장소 리스트의 id 배열
            let addValues = data.place.map((item) => item.schedule_id); //선택된 장소 리스트의 id 배열
            let notAddValues = totalValues.filter((item) => !addValues.includes(item)); // 선택되지 않은 장소 리스트의 id 배열
            let list = Location.filter((item) => notAddValues.includes(item.id)); // 선택되지 않은 장소 리스트의 객체 배열
            setLocationList(list);
            console.log(list);
          } else {
          }
        });
    }
  };

  useEffect(() => {
    setCurriculum({
      content: "",
      time: "",
      title: "",
    });
    setLocation("");
    setLcSearching(false);
    setPartner("");
    setSearchCenter("");
    setSearchBox(false);
    setSearching(false);
    setCenterData([]);
    setCategory({
      big: "대분류 선택",
      small: "소분류 선택",
    });
  }, [open]);

  //이미지 업로드
  const addImg = ({ target: { files } }) => {
    if (data.background) {
      if (files.length + data.background?.length > 4) {
        return alert("배경 이미지는 최대 4개까지 등록하실 수 있습니다.");
      } else {
        setImageFromFile({
          file: files[0],
          setImageUrl: ({ result }) => {
            ImgChange(result, "activity", 0);
          },
        });
      }
    } else {
      setImageFromFile({
        file: files[0],
        setImageUrl: ({ result }) => {
          ImgChange(result, "activity", 0);
        },
      });
    }
  };

  //카테고리 추가
  const addCategory = () => {
    let copy = [...data.sport];

    let result = copy.filter((item) => item.big_category == category.big && item.sport == category.small);

    if (result.length > 0) {
      return;
    } else if (category.big == "대분류 선택" || category.small == "소분류 선택") {
      return;
    } else {
      copy.push({
        id: "new",
        big_category: category.big,
        sport: category.small,
      });
      setData((prev) => ({ ...prev, sport: copy }));
    }

    setCategory({
      big: "대분류 선택",
      small: "소분류 선택",
    });
  };

  const deleteCategory = (select, idx) => {
    let copy = data.sport;
    let copyD = deleteD.sport;

    copyD[copyD.length] = select;

    copy.splice(idx, 1);

    setData((prev) => ({ ...prev, sport: copy }));
    setDelete((prev) => ({ ...prev, sport: copyD }));
  };

  const deletePartner = (select, idx) => {
    let copy = data.partner;
    let copyD = deleteD.partner;

    copyD[copyD.length] = select;

    copy.splice(idx, 1);

    setData((prev) => ({ ...prev, partner: copy }));
    setDelete((prev) => ({ ...prev, partner: copyD }));
  };

  const deleteImage = (index) => {
    if (window.confirm("사진을 삭제하시겠습니까?")) {
      var copy = [...data.background];

      var deleteData = copy[index];
      var deleted = [...deleteD.background];
      deleted.push(deleteData);

      copy.splice(index, 1);

      setDelete((prev) => ({ ...prev, background: deleted }));
      setData((prev) => ({ ...prev, background: copy }));
    }
  };

  const facilitySelect = (item) => {
    var copy = { ...data.facility_list };
    var tf = Number(data.facility_list[item]);

    if (tf) {
      copy[item] = 0;
    } else {
      copy[item] = 1;
    }

    setData((prev) => ({ ...prev, facility_list: copy }));
  };

  const statusConvert = (status) => {
    switch (status) {
      case "활성화":
        return <span style={{ color: colors.Bl06, fontWeight: "500" }}>활성화</span>;
      case "승인 요청":
        return <span style={{ color: colors.Red03, fontWeight: "500" }}>승인 요청</span>;

      case "비노출":
        return <span style={{ color: colors.Gr03, fontWeight: "500" }}>비노출</span>;
    }
  };

  const fullPack = () => {
    if (data.full_package == 1) {
      setData((prev) => ({ ...prev, full_package: 0 }));
    } else {
      setData((prev) => ({ ...prev, full_package: 1 }));
    }
  };

  const semosOnly = () => {
    if (data.semos_only == 1) {
      setData((prev) => ({ ...prev, semos_only: 0 }));
    } else {
      setData((prev) => ({ ...prev, semos_only: 1 }));
    }
  };

  const settingPartner = (id, name) => {
    var copy = [...data.partner];
    var copyC = true;

    for (var i = 0; i < copy.length; i++) {
      if (copy[i].partner_id == id) {
        window.alert("중복된 파트너가 있습니다.");
        copyC = false;
        break;
      }
    }

    if (copyC) {
      copy[copy.length] = {
        change: "yes",
        id: "new",
        partner_id: id,
        partner_name: name,
      };

      setData((prev) => ({
        ...prev,
        partner: copy,
      }));

      setPartner("");
    }
  };

  const getLocation = (loc) => {
    var co = { ...data };
    var copy = co.place;
    var obj = { ...loc };
    var scheid = obj.id;
    obj.id = "new";
    obj.schedule_id = scheid;
    copy.push(obj);
    setData((prev) => ({ ...prev, place: copy }));
    setLocation("");
  };

  const locationSet = (item) => {
    const copy = [...data.place];
    var obj = { ...item };
    var scheid = obj.id;
    obj.id = "new";
    obj.schedule_id = scheid;
    for (let i = 0; i < copy.length; i++) {
      let content = copy[i];
      let loid = content.schedule_id;
      if (loid == item.id) {
        window.alert("중복되는 일정이 있습니다.");
        return;
      }
    }
    copy.push(obj);

    setLocationList((prev) => prev.filter((val) => val.id !== item.id));
    setData((prev) => ({ ...prev, place: copy }));
    setLocation("");
  };
  const deleteLocation = (id, idx) => {
    var co = { ...data };
    var copy = co.place;
    var copyD = copy[idx];
    copy.splice(idx, 1);
    setData((prev) => ({ ...prev, place: copy }));

    var copyDel = deleteD;
    var copyDelValue = copyDel.place;
    copyDelValue[copyDelValue.length] = copyD;

    setDelete((prev) => ({ ...prev, place: copyDelValue }));
    getScheduleList();
  };


  const curriculumComponent = () => {
    if (data.duration === "다회차") {
      return Array.from(
        {
          length: Number(data.lesson_day) === 0 || !data.lesson_day ? 2 : Number(data.lesson_day),
        },
        (_, index) => (
          <>
            <Text color={colors.Gr04} style={{ marginTop: 16, fontSize: 12 }}>{`${index + 1}회차`}</Text>

            <CurriculumSection index={index} length={index * 40} day={`day${index + 1}`} data={data} setData={setData} />

            {data.curriculum[`day${index + 1}`]?.map((item, idx) => (
              <Curriculum
                length={index * 40 + index}
                iconImg={DeleteIcon}
                titleValue={item.title}
                titleOnChange={(e) => {
                  const value = e.target.value;
                  curriculumChange("title", value, idx, `day${index + 1}`);
                }}
                dropData={item.time}
                dropSetData={curriculumChange}
                idx={idx}
                type="curriculum"
                contentValue={item.content}
                contentOnChange={(e) => {
                  const value = e.target.value;
                  curriculumChange("content", value, idx, `day${index + 1}`);
                }}
                curriculumFunc={() => deleteCurriculum(idx, item, `day${index + 1}`)}
                day={`day${index + 1}`}
              />
            ))}
          </>
        )
      );
    } else if (data.duration === "원데이") {
      return (
        <>
          <CurriculumSection day={`day1`} index={0} data={data} setData={setData} />

          {Object.keys(data.curriculum).length > 0 &&
            data.curriculum[`day1`].map((item, idx) => {
              return (
                <Curriculum
                  key={`curriculum_lets_go_${idx}`}
                  iconImg={DeleteIcon}
                  titleValue={item.title}
                  titleOnChange={(e) => {
                    const value = e.target.value;
                    curriculumChange("title", value, idx, `day1`);
                  }}
                  dropData={item.time}
                  dropSetData={curriculumChange}
                  idx={idx}
                  type="curriculum"
                  contentValue={item.content}
                  contentOnChange={(e) => {
                    const value = e.target.value;
                    curriculumChange("content", value, idx, `day1`);
                  }}
                  day={`day1`}
                  curriculumFunc={() => deleteCurriculum(idx, item, `day1`)}
                />
              );
            })}
        </>
      );
    }
  };

  const curriculumComponentCopy = () => {
    if (data.duration_copy === "다회차") {
      return Array.from(
        {
          length: Number(data.lesson_day_copy) === 0 || !data.lesson_day_copy ? 2 : Number(data.lesson_day_copy),
        },
        (_, index) => (
          <>
            <Text color={colors.Gr04} style={{ marginTop: 16, fontSize: 12 }}>{`${index + 1}회차`}</Text>

            <CurriculumSection index={index} length={index * 40} day={`day${index + 1}`} data={data} setData={setData} />

            {data.curriculum_copy[`day${index + 1}`]?.map((item, idx) => (
              <Curriculum
                key={`curriculum_lets_go_${idx}_copy`}
                length={index * 40 + index}
                iconImg={DeleteIcon}
                titleValue={item.title}
                titleOnChange={(e) => {
                  const value = e.target.value;
                  curriculumChange("title", value, idx, `day${index + 1}`, 'copy');
                }}
                dropData={item.time}
                dropSetData={curriculumChange}
                idx={idx}
                type="curriculum_copy"
                contentValue={item.content}
                contentOnChange={(e) => {
                  const value = e.target.value;
                  curriculumChange("content", value, idx, `day${index + 1}`, 'copy');
                }}
                curriculumFunc={() => deleteCurriculum(idx, item, `day${index + 1}`, 'copy')}
                day={`day${index + 1}`}
              />
            ))}
          </>
        )
      );
    } else if (data.duration_copy === "원데이") {
      return (
        <>
          <CurriculumSection day={`day1`} index={0} data={data} setData={setData} />

          {Object.keys(data.curriculum_copy).length > 0 &&
            data.curriculum_copy[`day1`].map((item, idx) => {
              console.log(data.curriculum_copy[`day1`])
              return (
                <Curriculum
                  key={`curriculum_lets_go_${idx}_copy`}
                  iconImg={DeleteIcon}
                  titleValue={item.title}
                  titleOnChange={(e) => {
                    const value = e.target.value;
                    curriculumChange("title", value, idx, `day1`, 'copy');
                  }}
                  dropData={item.time}
                  dropSetData={curriculumChange}
                  idx={idx}
                  type="curriculum_copy"
                  contentValue={item.content}
                  contentOnChange={(e) => {
                    const value = e.target.value;
                    curriculumChange("content", value, idx, `day1`, 'copy');
                  }}
                  day={`day1`}
                  curriculumFunc={() => deleteCurriculum(idx, item, `day1`, 'copy')}
                />
              );
            })}
        </>
      );
    }
  };

  const SportsList = {
    "아웃도어스포츠" : [
      {
        text: "소분류 선택",
        value: "소분류 선택",
      },
      {
        text: "테니스",
        value: "테니스",
      },
      {
        text: "등산",
        value: "등산",
      },
      {
        text: "골프",
        value: "골프",
      }
    ],
    "인도어스포츠" : [
      {
        text: "소분류 선택",
        value: "소분류 선택",
      },
      {
        text: "클라이밍",
        value: "클라이밍",
      },
      {
        text: "피트니스",
        value: "피트니스",
      },
      {
        text: "필라테스",
        value: "필라테스",
      },
      {
        text: "요가",
        value: "요가",
      },
      {
        text: "GX",
        value: "GX",
      },
      {
        text: "스쿼시",
        value: "스쿼시",
      }
    ],
    "원터스포츠" : [
      {
        text: "소분류 선택",
        value: "소분류 선택",
      },
      {
        text: "스노보드",
        value: "스노보드",
      },
      {
        text: "스키",
        value: "스키",
      }
    ],
    "워터스포츠" : [
      {
        text: "소분류 선택",
        value: "소분류 선택",
      },
      {
        text: "스쿠버다이빙",
        value: "스쿠버다이빙",
      },
      {
        text: "프리다이빙",
        value: "프리다이빙",
      },
      {
        text: "서핑",
        value: "서핑",
      },
      {
        text: "패들보드",
        value: "패들보드",
      },
      {
        text: "수영/스노클링",
        value: "수영/스노클링",
      },
      {
        text: "수상레저",
        value: "수상레저",
      },
    ]
  }

  return (
    <div id="normal">
      <TitleBox>
        <Styler pl={36} pb={24} pr={36} flex={true} justify="space-between" align="center">
          <Title className="f16">액티비티 기본 정보 ({data && data.activity_id})</Title>
          {/* <SmallText className="f12">{data ? `최근 수정일 : ${data.updated}` : ""}</SmallText> */}
        </Styler>
      </TitleBox>

      <Styler pl={36} pr={36}>
        <Styler flex={true} align="center" pb={8}>
          <SmallTitle>이름</SmallTitle>
          <Wrapper
            // jc="center"
            width="26rem"
            padding="9px 16px"
            border={`1px solid ${colors.Gr02}`}
          >
            <input
              style={{ width: "100%", color: colors.Gr05 }}
              placeholder="액티비티 이름을 입력해 주세요."
              value={data.name}
              onChange={(e) => setData((prev) => ({ ...prev, name: e.target.value }))}
            />
          </Wrapper>
        </Styler>

        {(data.status == '승인 요청') &&
          <>
            <div style={{backgroundColor : colors.Red03, height : 2, width : '100%', marginBottom : 5}} />
            <Styler flex={true} align="center" pb={8}>
              <SmallTitle style={{color : colors.Red03}}>이름 (승인 요청)</SmallTitle>
              <Wrapper
                // jc="center"
                width="26rem"
                padding="9px 16px"
                border={`1px solid ${colors.Gr02}`}
              >
                <input
                  style={{ width: "100%", color: colors.Gr05 }}
                  placeholder="액티비티 이름을 입력해 주세요."
                  value={data.name_copy}
                  onChange={(e) => setData((prev) => ({ ...prev, name_copy: e.target.value }))}
                />
              </Wrapper>
            </Styler>
            <div style={{backgroundColor : colors.Red03, height : 2, width : '100%', marginBottom : 5}} />
          </>
        }

        <Styler flex={true} align="center" pb={8}>
          <SmallTitle>센터</SmallTitle>
          <Wrapper jc="center" cursor="pointer" width="26rem" padding="9px 0" border={`1px solid ${colors.Gr02}`} onClick={() => setSearchBox(true)}>
            <Text>{data?.center ? data.center : "센터 선택"}</Text>
          </Wrapper>
        </Styler>

        <Styler flex={true} justify="space-between" align="center" pb={8}>
          <SmallTitle>등록된 파트너</SmallTitle>

          <WrapperColmun width="100%">
            <Wrapper ref={partnerRef} position="relative" width="100%" padding="9px 16px" border={`1px solid ${colors.Gr02}`}>
              <input
                style={{ width: "100%", color: colors.Gr05 }}
                placeholder="파트너명, 파트너ID검색"
                value={partner}
                onFocus={(e) => {
                  setBox(true);
                }}
                onChange={(e) => setPartner(e.target.value)}
              />
              <Icon width={`calc(1rem * (17 / 14))`} cursor={`pointer`} src={data.partner ? CloseButton : SearchIcon} onClick={() => setPartner("")} />

              {partner && box && (
                <Box>
                  {partnerData.map((el) => (
                    <PartItem onClick={() => settingPartner(el.partner_id, el.partner_name)}>{`${el.partner_name} (${el.partner_id})`}</PartItem>
                  ))}
                </Box>
              )}
              {searchBox ? (
                <AbsoluteWrapper
                  radius="16px"
                  padding="24px"
                  ai="flex-start"
                  z="50"
                  top="-1px"
                  left="-1px"
                  bgc={colors.White}
                  width="31rem"
                  height="39rem"
                  style={{
                    boxShadow: `0px 4px 16px ${colors.B20}`,
                  }}
                >
                  <WrapperColmun width="100%">
                    <WrapperRow jc="space-between" margin="0 0 2rem 0">
                      <Text className="f18">센터 선택</Text>
                      <Icon
                        width="calc(1rem * (24 / 14))"
                        height="calc(1rem * (24 / 14))"
                        cursor="pointer"
                        onClick={() => {
                          setSearchCenter("");
                          setSearchBox(false);
                        }}
                        src={CloseButton}
                      />
                    </WrapperRow>

                    <Wrapper
                      // jc="center"
                      width="100%"
                      padding="9px 16px"
                      border={searching ? `1px solid ${colors.Gr04}` : `1px solid ${colors.Gr02}`}
                    >
                      <input
                        className="f14"
                        style={{ width: "100%", color: colors.Gr05 }}
                        placeholder="센터명, 센터ID 검색"
                        value={searchCenter}
                        onKeyDown={(e) => {
                          if (e.key == "Enter") {
                            selectCenter();
                          }
                        }}
                        onChange={(e) => setSearchCenter(e.target.value)}
                      />
                      <Icon width={`calc(1rem * (17 / 14))`} cursor={`pointer`} src={searching ? SearchIconB : SearchIcon} onClick={selectCenter} />
                    </Wrapper>

                    <WrapperColmun
                      margin="8px 0 0 0"
                      height="calc(39rem - (1rem * (18 / 14) + 4rem + 50px))"
                      style={{
                        overflow: "auto",
                      }}
                    >
                      {centerData.map((item) => (
                        <WrapperColmun
                          border={`1px solid ${colors.Gr02}`}
                          padding="14px 18px"
                          width="100%"
                          height="65px"
                          margin="0 0 8px 0"
                          cursor="pointer"
                          hvBgc={colors.Bl01}
                          hvBorder={`1px solid ${colors.Bl04}`}
                          onClick={() => {
                            setData((prev) => ({
                              ...prev,
                              center: item.center_name,
                              center_id: item.center_id,
                            }));
                            setSearchBox(false);
                            setSearching(false);
                            setSearchCenter("");
                            setCenterData([]);
                          }}
                        >
                          <Text className="f12">{item.center_name}</Text>
                          <WrapperRow className="f10">
                            <Text ws="pre" color={colors.Gr04}>
                              ID{" "}
                            </Text>
                            <Text>{item.center_id}</Text>
                          </WrapperRow>
                        </WrapperColmun>
                      ))}
                    </WrapperColmun>
                  </WrapperColmun>
                </AbsoluteWrapper>
              ) : (
                ""
              )}
            </Wrapper>

            <TagContainer>
              {data.partner?.map((el, idx) => (
                <TagItem key={`${el.name}_${idx}`}>
                  <Wrapper jc="center" ai="center">
                    <TagText>{el.partner_name}</TagText>
                  </Wrapper>

                  <Xbutton src={XIcon} onClick={() => deletePartner(el, idx)} />
                </TagItem>
              ))}
            </TagContainer>
          </WrapperColmun>
        </Styler>

        <Styler flex={true} justify="flex-start" align="center" pb={8}>
          <SmallTitle>액티비티 상태</SmallTitle>

          <Wrapper position="relative" width="100%">
            <DropDown
              width="26rem"
              data={statusConvert(data.status)}
              setData={setData}
              option={[
                {
                  text: "활성화",
                  value: "활성화",
                  color: colors.Bl06,
                },
                {
                  text: "승인 요청",
                  value: "승인 요청",
                  color: colors.Red03,
                },
                {
                  text: "비노출",
                  value: "비노출",
                  color: colors.Gr03,
                },
              ]}
              no={false}
              keyName="status"
              defaultText="상태"
            />
          </Wrapper>
        </Styler>
      </Styler>

      <Styler pl={36} pr={36}>
        <Wrapper margin="0 0 16px 0" jc="center" ai="center">
          <SmallTitle style={{ minWidth: "0" }} className="f14">
            배경 이미지
          </SmallTitle>
          <div style={{ width: "8px" }} />
          <Indicator>{data.background?.length}/4</Indicator>
        </Wrapper>

        <WrapperRow width="100%">
          <BackgroundImageBox htmlFor="background" id="problem">
            <BackgroundImageSelector src={CameraAdd} />
            <span className="f10">배경 이미지</span>
          </BackgroundImageBox>
          <BackgroundImageInput type="file" id="background" accept="image/*" onChange={addImg} />

          <WrapperRow>
            {data.background?.map((img, i) => (
              <BackgroundImageDiv key={`${img}_${i}`}>
                <BackgroundImage onClick={() => window.open(img.image, "_blank")} src={img.image ? img.image : img} />
                <ImageDeletion src={ImageDelete} onClick={() => deleteImage(i)} />
              </BackgroundImageDiv>
            ))}
          </WrapperRow>
        </WrapperRow>

        <Styler flex={true} justify="space-between" align="center" pb={16} pt={24}>
          <SmallTitle>액티비티 카테고리</SmallTitle>
        </Styler>
        <WrapperRow ai="center" gap="8px" width="100%" jc="space-between">
          <DropDown
            width="100%"
            data={category.big}
            type="object"
            setData={setCategory}
            doubleDropDown={true}
            reset={() =>
              setCategory({
                big: category.big,
                small: "소분류 선택",
              })
            }
            option={[
              {
                text: "대분류 선택",
                value: "대분류 선택",
              },
              {
                text: "워터스포츠",
                value: "워터스포츠",
              },
              {
                text: "윈터스포츠",
                value: "윈터스포츠",
              },
              {
                text: "아웃도어스포츠",
                value: "아웃도어스포츠",
              },
              {
                text: "인도어스포츠",
                value: "인도어스포츠",
              },
            ]}
            no={false}
            keyName="big"
            defaultText="대분류 선택"
          />
          <DropDown
            width="100%"
            type="object"
            data={category.small}
            setData={setCategory}
            option={
              category.big == "대분류 선택"
                ? [
                    {
                      text: "소분류 선택",
                      value: "소분류 선택",
                    },
                  ]
                : category.big == "윈터스포츠"
                ? [
                    {
                      text: "소분류 선택",
                      value: "소분류 선택",
                    },
                    {
                      text: "스노보드",
                      value: "스노보드",
                    },
                    {
                      text: "스키",
                      value: "스키",
                    },
                  ]
                : [
                    {
                      text: "소분류 선택",
                      value: "소분류 선택",
                    },
                    {
                      text: "스쿠버다이빙",
                      value: "스쿠버다이빙",
                    },
                    {
                      text: "프리다이빙",
                      value: "프리다이빙",
                    },
                    {
                      text: "서핑",
                      value: "서핑",
                    },
                    {
                      text: "패들보드",
                      value: "패들보드",
                    },
                    {
                      text: "수영/스노클링",
                      value: "수영/스노클링",
                    },
                    {
                      text: "수상레저",
                      value: "수상레저",
                    },
                  ]
            }
            no={false}
            keyName="small"
            defaultText="소분류 선택"
          />

          <Icon width={`ccalc(1rem * (17 / 14) + 16px)`} height={`calc(1rem * (17 / 14) + 16px)`} cursor={`pointer`} src={AddIcon} onClick={addCategory} />
        </WrapperRow>
        <TagContainer>
          {data.sport?.map((el, idx) => (
            <TagItem key={`${el.big_category}_${el.sport}`}>
              <Wrapper jc="center" ai="center">
                {el.big_category ? (
                  <>
                    <TagText className="f13">{el.big_category}</TagText>
                    <Wrapper ai="center" width="1px" margin="0 7px" height="16px" bgc={colors.Gr02} />
                  </>
                ) : (
                  ""
                )}

                <TagText>{el.sport}</TagText>
              </Wrapper>

              <Xbutton
                src={XIcon}
                onClick={() => deleteCategory(el, idx)}
                // onClick={() => deleteKeyWords("recommend", el, idx)}
              />
            </TagItem>
          ))}
        </TagContainer>

        {(data.status == '승인 요청') &&
          <>
            <div style={{backgroundColor : colors.Red03, height : 2, width : '100%', marginTop : 20}} />
            <Styler flex={true} justify="space-between" align="center" pb={16} pt={24}>
              <SmallTitle style={{color : colors.Red03}}>액티비티 카테고리(승인 요청)</SmallTitle>
            </Styler>
            <WrapperRow ai="center" gap="8px" width="100%" jc="space-between">
              <DropDown
                width="100%"
                data={category.big}
                type="object"
                setData={setCategory}
                doubleDropDown={true}
                reset={() =>
                  setCategory({
                    big: category.big,
                    small: "소분류 선택",
                  })
                }
                option={[
                  {
                    text: "대분류 선택",
                    value: "대분류 선택",
                  },
                  {
                    text: "워터스포츠",
                    value: "워터스포츠",
                  },
                  {
                    text: "윈터스포츠",
                    value: "윈터스포츠",
                  },
                ]}
                no={false}
                keyName="big_copy"
                defaultText="대분류 선택"
              />
              <DropDown
                width="100%"
                type="object"
                data={category.small}
                setData={setCategory}
                option={
                  category.big ? SportsList[category.big] : [
                    {
                      text: "소분류 선택",
                      value: "소분류 선택",
                    }
                  ]
                }
                no={false}
                keyName="small_copy"
                defaultText="소분류 선택"
              />

              <Icon width={`ccalc(1rem * (17 / 14) + 16px)`} height={`calc(1rem * (17 / 14) + 16px)`} cursor={`pointer`} src={AddIcon} onClick={addCategory} />
            </WrapperRow>
            <TagContainer>
              {data.sport_copy?.map((el, idx) => (
                <TagItem key={`${el.big_category}_${el.sport}`}>
                  <Wrapper jc="center" ai="center">
                    {el.big_category ? (
                      <>
                        <TagText className="f13">{el.big_category}</TagText>
                        <Wrapper ai="center" width="1px" margin="0 7px" height="16px" bgc={colors.Gr02} />
                      </>
                    ) : (
                      ""
                    )}

                    <TagText>{el.sport}</TagText>
                  </Wrapper>

                  <Xbutton
                    src={XIcon}
                    onClick={() => deleteCategory(el, idx, 'copy')}
                  />
                </TagItem>
              ))}
            </TagContainer>
            <div style={{backgroundColor : colors.Red03, height : 2, width : '100%', marginTop : 20}} />
          </>
        }
      </Styler>

      <Styler pl={36} pr={36} pt={24} flex={true} justify="space-between" align="center">
        <SmallTitle>옵션 정보</SmallTitle>

        <Styler flex={true} justify="space-between" align="center" style={{ width: "100%" }}>
          <OptionBox checked={data.semos_only} onClick={() => semosOnly()}>
            세모스 ONLY
            <CheckBox left="10.5px" checked={data.semos_only}>
              {data.semos_only > 0 && <Checked src={BlueCheck} />}
            </CheckBox>
          </OptionBox>

          <OptionBox checked={data.full_package} onClick={() => fullPack()}>
            풀패키지
            <CheckBox left="10.5px" checked={data.full_package}>
              {data.full_package > 0 && <Checked src={BlueCheck} />}
            </CheckBox>
          </OptionBox>
        </Styler>
      </Styler>

      <Styler pl={36} pr={36} pt={16}>
        <Styler flex={true} justify="space-between" align="center">
          <SmallTitle
            style={{
              alignSelf: "flex-start",
              paddingTop: "calc(1rem * 10 / 14)",
            }}
          >
            액티비티 장소
          </SmallTitle>
          <WrapperColmun width="100%">
            <SearchBox
              style={{
                border: LcSearching ? `1px solid ${colors.Gr04}` : `1px solid ${colors.Gr02}`,
              }}
            >
              <SearchInput
                onFocus={() => setLcSearching(true)}
                onBlur={() => {
                  setTimeout(() => {
                    setLcSearching(false);
                  }, 100);
                }}
                placeholder="불러올 일정이름 혹은 지역을 검색해 보세요."
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
              <SearchIconImg
                src={Magnifying}
                //   onClick={() => input && setInput("")}
              />
            </SearchBox>
            {LcSearching && (
              <>
                {locationList.length > 0 ? (
                  <Styler pb={2} pt={8}>
                    <SearchListBox length={2}>
                      {locationList?.map((el, i) => {
                        let dayText = "";

                        for (let i = 0; i < el.day.length; i++) {
                          if (i) {
                            dayText = dayText + `, ${el.day[i]}`;
                          } else {
                            dayText = `${el.day[i]}`;
                          }
                        }

                        return (
                          <SearchItem onClick={() => locationSet(el)} last={i + 1 === locationList.length}>
                            <LocationContainer>
                              <SearchText className="f12">{el.title}</SearchText>
                              <Styler align="center" className="f10" flex={true} pt={4}>
                                <Styler align="center" flex={true} pr={4}>
                                  <SmallIcon src={LocationIcon} />
                                  <SmallText color={colors.Gr04}>{`${el.si} ${el.gu}`}</SmallText>
                                </Styler>
                                <Styler align="center" flex={true} pr={4}>
                                  <SmallIcon src={ClockIcon} />
                                  <SmallText color={colors.Gr05}>{dayText}</SmallText>
                                </Styler>
                                <SmallText color={colors.Bl07}>{`${el.start} ~ ${el.end}`}</SmallText>
                              </Styler>
                            </LocationContainer>
                            <Icon width="calc(1rem * 24/14)" height="calc(1rem * 24/14)" src={PlusIconGrey} cursor="pointer" />
                          </SearchItem>
                        );
                      })}
                    </SearchListBox>
                  </Styler>
                ) : (
                  <SearchListEmpty>
                    {data.partner?.length ? (
                      <p>
                        등록된 액티비티 <span className="text-blue">일정</span>이 없어요! <br /> 일정 등록/수정에서&nbsp;
                        <span className="text-blue">일정 등록 후 </span> 액티비티를 추가해주세요.
                      </p>
                    ) : (
                      <p>
                        파트너 <span className="text-blue">검색&nbsp;</span>후 장소를 검색해주세요.
                      </p>
                    )}
                  </SearchListEmpty>
                )}
              </>
            )}
            <TagContainer>
              {data.place?.map((el, idx) => (
                <TagItem>
                  <TagText>{el.title}</TagText>
                  <Xbutton src={XIcon} onClick={() => deleteLocation(el.id, idx)} />
                </TagItem>
              ))}
            </TagContainer>
          </WrapperColmun>
        </Styler>

        <Styler pt={16} flex={true} justify="space-between" align="center">
          <SmallTitle>편의시설</SmallTitle>

          {data.facility_list && (
            <FacilityBox>
              {FacilityArray.map((el, i) => (
                <FacilityItem
                  key={`${el.id}_${i}_facility`}
                  selected={Number(data.facility_list[el.value])}
                  last={el.id % 4 === 1}
                  onClick={() => {
                    facilitySelect(el.value);
                  }}
                >
                  <FacilityText className="f12" checked={Number(data.facility_list[el.value])}>
                    {el.name}
                  </FacilityText>
                  <CheckBox left="6.5px" checked={Number(data.facility_list[el.value])}>
                    {Number(data.facility_list[el.value]) > 0 && <Checked src={BlueCheck} />}
                  </CheckBox>
                </FacilityItem>
              ))}
            </FacilityBox>
          )}
        </Styler>

        <Styler flex={true} pb={16} pt={24}>
          <SmallTitle>액티비티 요약 정보</SmallTitle>
        </Styler>

        <Styler flex={true} justify="space-between" align="center">
          {(window.location.pathname.includes("new") || true) ? (
            <DropDown
              data={data.duration}
              setData={setData}
              option={[
                {
                  text: "원데이",
                  value: "원데이",
                },
                {
                  text: "다회차",
                  value: "다회차",
                },
              ]}
              no={false}
              keyName="duration"
              defaultText="액티비티 기간"
            />
          ) : (
            <SelectBox>{data.duration}</SelectBox>
          )}

          <div style={{ width: "calc(1rem * 8/14)" }} />

          <DropDown
            data={data.type}
            setData={setData}
            option={[
              {
                text: "레슨",
                value: "레슨",
              },
              {
                text: "자격증",
                value: "자격증",
              },
              {
                text: "이용권",
                value: "이용권",
              },
              {
                text: "투어",
                value: "투어",
              },
            ]}
            no={false}
            keyName="type"
            defaultText="액티비티 유형"
          />

          <div style={{ width: "calc(1rem * 8 / 14)" }} />
            <DropDown
              z={62}
              data={data.location_type}
              setData={setData}
              option={[
                {
                  text: "실내",
                  value: "실내",
                },
                {
                  text: "실외",
                  value: "실외",
                },
                {
                  text: "실내+야외",
                  value: "실내+야외",
                },
              ]}
              no={false}
              keyName="location_type"
              defaultText="시설 유형"
            />
          </Styler>

          {data.duration == "다회차" && (
            <Styler pt={20} direction="row">
              <Wrapper border={`1px solid ${colors.Gr02}`} width="calc(1rem * 200/14)" padding="8px 0" margin="0 8px 0 0">
                <input
                  style={{ width: "100%", textAlign: "center" }}
                  value={data.lesson_day}
                  onChange={(e) => setData((prev) => ({ ...prev, lesson_day: e.target.value }))}
                />
              </Wrapper>

              <Text>회차 과정</Text>
            </Styler>
          )}

          {(data.status == '승인 요청') &&
            <>
              <div style={{backgroundColor : colors.Red03, height : 2, width : '100%', marginTop : 20}} />

              <Styler flex={true} pb={16} pt={24}>
                <SmallTitle style={{color : colors.Red03}}>액티비티 요약 정보 (승인 요청)</SmallTitle>
              </Styler>

              <Styler flex={true} justify="space-between" align="center">
                {(window.location.pathname.includes("new") || true) ? (
                  <DropDown
                    data={data.duration_copy}
                    setData={setData}
                    option={[
                      {
                        text: "원데이",
                        value: "원데이",
                      },
                      {
                        text: "다회차",
                        value: "다회차",
                      },
                    ]}
                    no={false}
                    keyName="duration_copy"
                    defaultText="액티비티 기간"
                  />
                ) : (
                  <SelectBox>{data.duration_copy}</SelectBox>
                )}

                <div style={{ width: "calc(1rem * 8/14)" }} />

                <DropDown
                  data={data.type_copy}
                  setData={setData}
                  option={[
                    {
                      text: "레슨",
                      value: "레슨",
                    },
                    {
                      text: "자격증",
                      value: "자격증",
                    },
                    {
                      text: "이용권",
                      value: "이용권",
                    },
                    {
                      text: "투어",
                      value: "투어",
                    },
                  ]}
                  no={false}
                  keyName="type_copy"
                  defaultText="액티비티 유형"
                />

                <div style={{ width: "calc(1rem * 8 / 14)" }} />

                <DropDown
                  z={62}
                  data={data.location_type}
                  setData={setData}
                  option={[
                    {
                      text: "실내",
                      value: "실내",
                    },
                    {
                      text: "실외",
                      value: "실외",
                    },
                    {
                      text: "실내+야외",
                      value: "실내+야외",
                    },
                  ]}
                  no={false}
                  keyName="location_type"
                  defaultText="시설 유형"
                />
              </Styler>

              {data.duration == "다회차" && (
                <Styler pt={20} direction="row">
                  <Wrapper border={`1px solid ${colors.Gr02}`} width="calc(1rem * 200/14)" padding="8px 0" margin="0 8px 0 0">
                    <input
                      style={{ width: "100%", textAlign: "center" }}
                      value={data.lesson_day}
                      onChange={(e) => setData((prev) => ({ ...prev, lesson_day: e.target.value }))}
                    />
                  </Wrapper>

                  <Text>회차 과정</Text>
                </Styler>
              )}
              <div style={{backgroundColor : colors.Red03, height : 2, width : '100%', marginTop : 20}} />
            </>
          }

        <Styler direction="column" flex={true} pb={36} pt={24}>
          <SmallTitle>커리큘럼 등록</SmallTitle>
          {data.curriculum && curriculumComponent()}
        </Styler>

        {(data.status == '승인 요청') &&
            <>
              <div style={{backgroundColor : colors.Red03, height : 2, width : '100%'}} />
              <Styler direction="column" flex={true} pb={36} pt={24}>
                <SmallTitle style={{color : colors.Red03}}>커리큘럼 등록(승인요청)</SmallTitle>
                {data.curriculum_copy && curriculumComponentCopy()}
              </Styler>
              <div style={{backgroundColor : colors.Red03, height : 2, width : '100%', marginBottom : 20}} />
            </>
        }

      </Styler>
    </div>
  );
};

export default ActivityNormalInfo;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  padding-top: ${({ pt }) => `calc(1rem * ${pt} / 14)`};
  padding-bottom: ${({ pb }) => `calc(1rem * ${pb} / 14)`};
  padding-left: ${({ pl }) => `calc(1rem * ${pl} / 14)`};
  padding-right: ${({ pr }) => `calc(1rem * ${pr} / 14)`};
  height: ${({ height }) => height}px;
  color: ${colors.Gr05};
`;

const TitleBox = styled.div``;

const Title = styled.div`
  font-weight: 600;

  letter-spacing: -0.02em;
  color: ${({ red }) => (red ? colors.Red02 : colors.Gr05)};
`;

const Indicator = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${({ status }) => (status == "empty" ? colors.Red02 : colors.Gr03)};
`;

const Index = styled.span`
  color: ${({ red }) => (red ? colors.Red02 : colors.Gr05)};
`;

const SmallTitle = styled.div`
  font-weight: 500;
  line-height: 142%;
  display: flex;
  font-size: 1rem;

  color: ${({ red }) => (red ? colors.Red02 : colors.Gr05)};
  white-space: pre;
  min-width: 7rem;
`;

const BackgroundImageInput = styled.input`
  display: none;
`;

const BackgroundImageBox = styled.label`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  line-height: 132%;
  flex-direction: column;
  text-align: center;
  letter-spacing: -0.022em;
  color: ${colors.Gr03};
  border: 1px solid ${({ red }) => (red ? colors.Red02 : colors.Gr02)};
  border-radius: 8px;
  width: calc(1rem * 78 / 14);
  height: calc(1rem * 78 / 14);
  cursor: pointer;
`;

const BackgroundImageSelector = styled.img`
  object-fit: cover;
  width: calc(1rem * 32 / 14);
  height: calc(1rem * 32 / 14);

  margin-bottom: 2px;
`;

const SearchListEmpty = styled.div`
  padding: 16px 30px;
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
  margin-top: 10px;

  p {
    color: ${colors.Gr04};
    letter-spacing: -0.02em;
    line-height: 17.04px;
    font-size: 12px;
    font-weight: 500;
  }

  .text-blue {
    color: ${colors.Bl05};
  }
`;

const BlueIndicator = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${({ status }) => (status == "empty" ? colors.Red02 : colors.Bl07)};
`;

const SportBoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
`;

const SportBox = styled.div`
  /* width: ${(W - 90) / 4}px; */
  width: 5.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 14px;
  padding: calc(1rem * 10 / 14) 0;
  border: 1px solid;
  border-radius: 8px;
  border-color: ${({ selected }) => (selected ? colors.Bl07 : colors.Gr02)};

  opacity: ${({ unselected }) => (unselected ? 0.3 : 1)};
  color: ${({ unselected }) => (unselected ? colors.Gr03 : colors.Gr05)};
`;

const SportIcon = styled.img`
  width: calc(1rem * 42 / 14);
  height: calc(1rem * 42 / 14);
`;

const SportText = styled.div`
  font-weight: 500;

  line-height: 142%;
  letter-spacing: -0.02em;
`;

const FullPackageContainer = styled.div`
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${colors.Gr02};
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RadioItem = styled.div`
  margin-left: 24px;
  display: flex;

  align-items: center;
`;

const RadioButton = styled.input`
  width: 1rem;
  height: 1rem;
  margin-left: 10px;
  border-color: ${({ status }) => status && colors.Red02};
  /* margin-bottom: 1px; */
`;

const SearchBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0px;
  padding-right: 12px;
  border-color: ${({ status }) => (status == "empty" ? colors.Red02 : colors.Gr02)};
`;

const SearchInput = styled.input`
  width: 90%;
  padding: calc(1rem * 12 / 14) 16px;

  &::placeholder {
    color: ${colors.Gr02};
  }
`;

const SearchIconImg = styled.img`
  width: calc(1rem * 24 / 14);
  height: calc(1rem * 24 / 14);
  cursor: pointer;
`;

const FacilityBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

/*  편의 시설 늘어나면 Marginbottom추가하기 */
const FacilityItem = styled.div`
  cursor: pointer;
  width: calc(1rem * 84 / 14);
  height: calc(1rem * 36 / 14);
  border: 1px solid;
  /* padding: calc(1rem * 8 / 14) calc(1rem * 12 / 14); */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${({ last }) => (last ? 0 : 12)}px;
  border-radius: 8px;
  border-color: ${({ selected, status }) => (status ? colors.Red02 : selected ? colors.Bl07 : colors.Gr02)};
`;

const FacilityText = styled.div`
  font-weight: 500;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;

  color: ${({ checked }) => (checked ? colors.Bl07 : colors.Gr03)};
`;

const TypeBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.2px solid;
  border-radius: 8px;
  padding: 10px 0;
  border-color: ${({ status }) => (status ? colors.Red02 : colors.Gr02)};
`;

const ActivityTypeBox = styled(TypeBox)`
  width: 57%;
`;

const FacilityTypeBox = styled(TypeBox)`
  width: 39%;
`;

const TypeText = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 142%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr04};
`;

const ModalOpener = styled.img`
  width: 14px;
  height: 18px;
  margin-left: 4px;
`;

const BackgroundImageDiv = styled.div`
  position: relative;
  width: calc(1rem * 78 / 14);
  height: calc(1rem * 78 / 14);
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-left: 16px;
  border-radius: 8px;
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* width: calc(1rem * 78 / 70 * 8);
  height: calc(1rem * 78 / 14); */
  border-radius: 8px;
`;

const ImageDeletion = styled.img`
  position: absolute;
  cursor: pointer;
  width: calc(1rem * 24 / 14);
  height: calc(1rem * 24 / 14);
  right: calc(1rem * -10 / 14);
  top: calc(1rem * -12 / 14);
  z-index: 3;
`;

const ScrollBox = styled.div`
  display: flex;
  /* max-width: ${({ width }) => (width ? `calc(100% - ${width})` : 0)}; */
  width: 100%;
  /* width: calc(var(--vw, 1vw) * 100); */
  overflow-y: hidden;
  /* max-width: calc(1rem * 747 / 14 - 30px) !important; */
  /* padding-top: 12px; */

  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SearchListBox = styled.div`
  ${({ length }) => length && `border: 1px solid ${colors.Gr02}`};
  max-height: 400px;
  overflow: hidden auto;
  /* border-radius: 6px; */
`;

const LocationContainer = styled.div``;

const SearchItem = styled.div`
  border-bottom: 1px solid ${colors.Gr02};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 8px 17px 18px;
  ${({ last }) => last && "border: none;"}
  cursor: pointer;
  :hover {
    background-color: ${colors.Bl01};
  }
`;

const AddSearchItem = styled(SearchItem)`
  border: none;
  background-color: ${colors.Bl01};
  border-radius: 6px;
`;

const SearchText = styled.div`
  font-weight: 500;
  /* font-size: 12px; */
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
`;

const PlusIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const SmallIcon = styled.img`
  width: calc(1rem * 13 / 14);
  height: calc(1rem * 13 / 14);
  margin-right: 2px;
`;

const SmallText = styled.div`
  font-weight: 500;
  /* font-size: 10px; */
  line-height: 132%;
  display: flex;
  align-items: center;
  letter-spacing: -0.022em;
  color: ${({ color }) => color};
`;

const TagContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  /* margin-top: 10px; */
`;

const TagItem = styled.div`
  position: relative;
  border: 1.2px solid ${colors.Gr02};
  padding: 6px 12px;
  display: flex;
  align-items: center;
  background-color: ${colors.Gr01};
  border-radius: 24px;
  margin-right: 9px;
  margin-top: 8px;
`;

const TagText = styled.span`
  font-weight: 500;
  /* font-size: 13px; */
  line-height: 142%;
  /* align-items: center; */
  /* text-align: center; */
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  max-width: ${W - 64}px;
  word-break: break-all;
  text-overflow: ellipsis;
`;

const Xbutton = styled.img`
  cursor: pointer;
  /* position: absolute;
  right: 6px;
  top: 6px; */
  width: 12px;
  height: 18px;
  margin-left: 4px;
`;

const NameBox = styled.div`
  border: 1px solid ${({ red }) => (red ? colors.Red02 : colors.Gr02)};
  /* border-radius: 4px; */
  /* padding-left: 24px; */
  width: 100%;
`;

const CheckBox = styled.div`
  width: calc(1rem * 15 / 14);
  height: calc(1rem * 15 / 14);
  border: 1px solid;
  border-color: ${({ checked }) => (checked ? colors.Bl07 : colors.Gr03)};
  border-radius: 4px;
  margin-left: ${({ left }) => left};
`;

const Checked = styled.img`
  width: 100%;
  height: 100%;
`;

const OptionBox = styled.div`
  cursor: pointer;
  width: 49%;
  color: ${({ checked }) => (checked ? colors.Bl07 : colors.Gr03)};
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-color: ${({ checked }) => (checked ? colors.Bl07 : colors.Gr02)};
  padding: calc(1rem * 8 / 14) 0;
  border-radius: 8px;
`;
const Textarea = styled.textarea`
  outline: none;
  border: none;
  resize: none;
  width: 100%;
  color: ${colors.Gr05};
`;

const Box = styled.div`
  width: 100%;
  position: absolute;
  top: calc(1rem * (21 / 14) + 24px);
  left: 0;
  z-index: 300000;
  height: 200px;
  background-color: white;
  border: 1px solid ${colors.Gr02};
  /* border-radius: 8px; */
`;

const PartItem = styled.div`
  cursor: pointer;
  border-bottom: 1px solid ${colors.Gr02};
  margin-bottom: 10px;
  padding: calc(1rem * 8 / 14);
  /* z-index: 3; */
  :hover {
    background-color: ${colors.Bl01};
  }
`;

const SelectBox = styled.div`
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.Gr02};
  line-height: 100%;
  background-color: ${colors.White};
  /* min-width: 7rem !important; */
  width: 100%;
  white-space: nowrap;
`;
