import styled from "styled-components";
import { useState, useEffect } from "react";
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

// icon
import closeIcon from "../assets/icon/close.svg";

const CropperPopup = ({ cropType, status, OnOff, WH, imgData, setImg }) => {

  const image = new Image();

  let cropper = "";

  useEffect(() => {

    if(cropper) {
      cropper.reset();
      cropper.destroy();
    }

    if(imgData) {

      const image = document.getElementById('cropImg');

      cropper = new Cropper(image, {
        aspectRatio: WH.width / WH.height
      })
      
    } 

  }, [imgData])

  const CropImage = () => {

    if(window.confirm('이미지를 크롭하시겠습니까?')) {

      cropper.getCroppedCanvas().toBlob((blob) => {

        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          var base64data = reader.result;
          setImg(base64data, cropType);
          cropper.reset();
          cropper.destroy();
        }
  
      })
    }

  }

  return (
    <ScheduleContainer aria-hidden="true" status={status}>
      <div onClick={() => {
        OnOff(!status)
        if(cropper) {
          cropper.reset();
          cropper.destroy();
        }
      }} className="backgroundContainer"/>

      <div className="contentContainer">

        <div className="scheduleHeader">
            <h3>이미지 크롭</h3>
            <img onClick={() => {
              OnOff(!status)
              if(cropper) {
                  cropper.reset();
                  cropper.destroy();
              }
            }}  className="popupClose" src={closeIcon}/>
        </div>

        <div className="cropper_content">
          <img src={imgData} id="cropImg"/>
        </div>

        <div className="cropper_footer">
          <FuncButton onClick={CropImage}>
            크롭하기
          </FuncButton>
        </div>

      </div>
    </ScheduleContainer>
  );


};

export default CropperPopup;


const ScheduleContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  display : ${({status}) => (status ? "inline-flex" : 'none') };
  pointer-events :  ${({status}) => (status ? "unset" : 'none') };

  .backgroundContainer {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 201;
    cursor: pointer;
    background-color: rgba(0,0,0,0.2);
    backdrop-filter: blur(2px);
  }

  .contentContainer {
    width: 800px;
    align-items : start;
    position: fixed;
    display : inline-flex;
    flex-wrap : wrap;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius : 8px;
    box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 5px, rgb(0 0 0 / 15%) -0.5px -0.5px 5px;
    z-index: 300;
    overflow: hidden;
    background-color: #ffffff;
  }

  .scheduleHeader {
    width : 100%;
    padding : 20px 20px 0 20px;
    display : inline-flex;
    position : relative;
    margin-bottom : 20px;

    h3 {
      font-size : 22px;
      font-weight: 700;
      display : inline-block;
    }

    .popupClose {
      position : absolute;
      display : inline-block;
      top : 20px;
      right : 20px;
      cursor : pointer;

    }
  }

 .cropper_content {
    width : 100%;
    height : 500px;
    display : inline-block;
    overflow : hidden;

    img {
      display: block;
      max-width: 100%;
    }
 }
 
 .cropper_footer {
  width : 100%;
  padding : 10px 5px;
  display : inline-flex;
  justify-content : end;
  align-items : center;
 }
`;

const FuncButton = styled.div`
  width: 230px;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 5px, rgb(0 0 0 / 15%) -0.5px -0.5px 5px;
  height: 56px;
  background-color: #2C84EE;
  color: #fff;
  cursor : pointer;
  display : inline-flex;
  align-items : center;
  justify-content : center;
`