import styled from "styled-components";
import base from "../../../styles/constants/base";
import colors from "../../../styles/constants/colors";
import typography from "../../../styles/constants/typography";
import flexbox from "../../../styles/func/flexbox";

export const Container = styled.div`
  width: 100%;

  section {
    margin: 20px 0;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 20px;
  display: flex;

  ol {
    width: 100%;

    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }

    li {
      padding: 10px 20px;
      box-shadow: ${base.boxShadow.default};
      border-radius: 8px;
      margin-bottom: 10px;

      p {
          font-size : 14px;
      }

      ${flexbox("space-between")}
    }
  }
`;

export const Title = styled.p`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-weight: ${typography.weight.bold};
`;

export const FlexWrapper = styled.div`
  ${flexbox("flex-end")}
  width: 100%;
`;

export const AUSection = styled.section`
  width: 100%;
  display: flex;
`;

export const DoughnutWrapper = styled.div`
  width: 97%;
  height: 88%;
`;

export const RankingSection = styled.section`
  margin: 20px 0;
  width: calc(50% - 11px);
  height: 600px;
  box-shadow: ${base.boxShadow.default};
  border-radius: ${base.borderRadius.default}px;
  background-color: ${colors.white};
  margin-right: 10px !important;
  padding: 10px 10px 20px 20px;
  display : inline-block;
`;

export const RankingSection2 = styled.section`
  margin: 20px 0;
  width: calc(50% - 11px);
  height: 600px;
  box-shadow: ${base.boxShadow.default};
  border-radius: ${base.borderRadius.default}px;
  background-color: ${colors.white};
  margin-left: 10px !important;
  padding: 10px 10px 20px 20px;
  display : inline-block;
`;

export const TransitionAndTime = styled.section`
  width: 100%;
  height: 370px;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: ${base.boxShadow.default};
  border-radius: ${base.borderRadius.default}px;
  background-color: ${colors.white};
  position : relative;
`;

export const TransitionAndTime2 = styled.section`
  width: 100%;
  height: 480px;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: ${base.boxShadow.default};
  border-radius: ${base.borderRadius.default}px;
  background-color: ${colors.white};
  position : relative;
`;

export const TransitionBox = styled.div`
  position: relative;
  width: calc(50% - 10px);
  height: 100%;
  margin-bottom: 20px;
  padding: 20px;
`;

export const TimeBox = styled.div`
  position: relative;
  width: calc(50% - 10px);
  height: 100%;
  padding: 20px;
`;

export const TimeBox2 = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
`;


export const TimeBox100 = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
`;

export const TransitionWrapper = styled.div`
  height: 80%;
  ${flexbox("flex-start", "flex-end")}
`;

export const TimeWrapper = styled.div`
  height: 80%;
  width: 100%;
  ${flexbox("flex-start", "flex-end")}
`;


export const TimeWrapper2 = styled.div`
  height: 85%;
  width: 70%;
  ${flexbox("flex-start", "flex-end")}
`;

export const Footer = styled.footer`
  position: absolute;
  width: 100%;
  ${flexbox("flex-end")}
  bottom: 10px;
  right: 10px;
`;


export const QuestionContainer = styled.div`
  display : inline-flex;
  align-items : center;
  position : absolute;
  top : 50%;
  left : ${({ left }) => left};
  transform : translate(0, -50%);
  z-index : 55;

  div {
    display : inline-flex;
    align-items : center;
    position : relative;
  }

  img {
      display : inline-block;
      width : 20px;
      height : 20px;
  }

  .noticeBubble {
    display : none;
    width : 250px;
    padding : 4px 9px;
    line-height : 1.4;
    position : absolute;
    border-radius : 5px 5px 5px 0;
    top : -8px;
    left : 3px;
    transform : translate(0, -100%);
    font-size : 12px;
    font-weight : 500;
    background-color : rgba(132,140,148,.3);
    z-index : 55;
  }

  &:hover {

    .noticeBubble {
        display : inline-block;
    }

  }
`;


export const QuestionContainer2 = styled.div`
  display : inline-flex;
  align-items : center;
  position : absolute;
  top : 50%;
  left : ${({ left }) => left};
  transform : translate(0, -50%);
  z-index : 55;

  div {
    display : inline-flex;
    align-items : center;
    position : relative;
  }

  img {
      display : inline-block;
      width : 20px;
      height : 20px;
  }

  .noticeBubble {
    display : none;
    width : 250px;
    padding : 4px 9px;
    line-height : 1.4;
    position : absolute;
    border-radius : 0 5px 5px 5px;
    top : -8px;
    left : 3px;
    transform : translate(0, 0);
    font-size : 12px;
    font-weight : 500;
    background-color : rgba(132,140,148,.3);
    z-index : 55;
  }

  &:hover {

    .noticeBubble {
        display : inline-block;
    }

  }
`;