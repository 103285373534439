import styled from "styled-components";
import colors from "../../styles/constants/colors";
import calendarIcon from "../../assets/icon/calendarIcon.svg";
import Select from "../select";

const INPUT_PADDING = 20;

const CalendarBox = styled.div`
  position: relative;

  width: ${({ width }) => width};

  input {
    width: 100%;
    box-shadow: none;
    border: none;

    &:focus {
      border: none;
    }

    &::-webkit-calendar-picker-indicator {
      background: transparent;
      z-index: 1;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
    }
  }

  img {
    position: absolute;
    top: 50%;
    right: ${INPUT_PADDING}px;
    transform: translateY(-50%);
    background-color: ${colors.white};
    cursor: pointer;
  }
`;

const YearCalendarBox = styled.div`
  position: relative;
  width: ${({ width }) => width};

  select {
    width: 100%;
    box-shadow: none;
    border: none;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }
`;

const DateInput = ({
  name,
  value,
  changeHandler,
  width = "100%",
  min,
  max,
  margin = false,
  padding = false,
  istransparent = "false",
  type = "month",
  optionList = [],
}) => {
  if (type === "date") {
    return (
      <CalendarBox width={width} margin={margin} padding={padding}>
        <input
          type="date"
          min={min}
          max={max}
          name={name}
          value={value}
          onChange={changeHandler}
          istransparent={istransparent}
        />

        <img src={calendarIcon} />
      </CalendarBox>
    );
  }

  if (type === "week") {
    return (
      <CalendarBox width={width} margin={margin} padding={padding}>
        <input
          type="week"
          min={min}
          max={max}
          name={name}
          value={value}
          onChange={changeHandler}
          istransparent={istransparent}
        />

        <img src={calendarIcon} />
      </CalendarBox>
    );
  }

  if (type === "month") {
    return (
      <CalendarBox width={width} margin={margin} padding={padding}>
        <input
          type="month"
          min={min}
          max={max}
          name={name}
          value={value}
          onChange={changeHandler}
          istransparent={istransparent}
        />

        <img src={calendarIcon} />
      </CalendarBox>
    );
  }

  if (type === "year") {
    return (
      <YearCalendarBox width={width} margin={margin} padding={padding}>
        <Select
          width="100%"
          name={name}
          value={value}
          changeHandler={changeHandler}
          optionList={optionList}
        />
      </YearCalendarBox>
    );
  }

  if (type === "week") {
    return (
      <CalendarBox width={width} margin={margin} padding={padding}>
        <input
          type="month"
          min={min}
          max={max}
          name={name}
          value={value}
          onChange={changeHandler}
          istransparent={istransparent}
        />

        <img src={calendarIcon} />
      </CalendarBox>
    );
  }
};

export default DateInput;
