import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import styled from "styled-components";
import colors from "../styles/constants/colors";
import typography from "../styles/constants/typography";
import flexbox from "../styles/func/flexbox";

ChartJS.register(ArcElement, Tooltip, Legend);

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Box = styled.div`
  width: 60%;
  height: 100%;
  ${flexbox("center", "flex-end")}
`;

const Label = styled.div`
  padding: 20px;
  padding-left: 0px;
  width: 40%;
  align-self: start;
`;

const Title = styled.p`
  font-weight: ${typography.weight.bold};
  margin-bottom: 20px;
`;

const Rank = styled.ul`
  li {
    margin: 10px 0;
    color: ${colors.gray};
    white-space: nowrap;

    &:first-child {
      span::before {
        background-color: ${colors.violet};
      }
    }

    &:nth-child(2) {
      span::before {
        background-color: ${colors.orange};
      }
    }

    &:nth-child(3) {
      span::before {
        background-color: ${colors.lightGreen};
      }
    }
  }

  span {
    color: ${colors.black};
    font-weight: ${typography.weight.bold};

    &::before {
      display: inline-block;
      content: "";
      width: 15px;
      height: 15px;
      background-color: pink;
      margin: 0 10px 0 5px;
    }
  }
`;

const DougnutChart = ({
  labels,
  list,
  responsive = true,
  originData = [],
  style,
}) => {
  const data = {
    labels,
    datasets: [
      {
        data: list,
        backgroundColor: [
            '#8F8FE6',
            `${colors.orange}`,
            `${colors.lightGreen}`,
            '#FF80BB',
            '#FFC817',
            'rgba(255,51,51,.5)',
            'rgba(0,149,255,.5)',
            `${colors.white}`,
        ],
        borderColor: [`${colors.black}`],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Container>
      <Label>
        <Rank>
          {originData ? originData.map((item, idx) => {
            if (idx < 3) {
              return (
                <li key={idx}>
                  {idx + 1}위{" "}
                  <span color={'#8F8FE6'}>
                    {item ? item.length > 5 ? item.slice(0, 10) + '...' : item : ""}
                  </span>{" "}
                </li>
              );
            }
          }) : ""}
        </Rank>
      </Label>
      <Box>
        <Doughnut data={data} options={options} style={style} />
      </Box>
    </Container>
  );
};

export default DougnutChart;
