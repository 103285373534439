import styled from "styled-components";
import base from "../../../styles/constants/base";
import colors from "../../../styles/constants/colors";
import typography from "../../../styles/constants/typography";
import flexbox from "../../../styles/func/flexbox";

export const Container = styled.div`
  width: 100%;

  section {
    margin: 20px 0;
  }
`;

export const Title = styled.p`
  text-align: center;
  margin-bottom: 20px;
  font-weight: ${typography.weight.bold};
`;

export const FlexWrapper = styled.div`
  ${flexbox("flex-end")}
  width: 100%;
`;

export const MainWrapper = styled.div`
  ${flexbox("flex-start")}
  width: 100%;
  height: calc(100% - 50px);
`;

export const Box = styled.div`
  width: 100%;
  height: 100%;

  &:first-child {
    width: 75%;
    height: 100%;
    ${flexbox("center", "center", "column")} strong {
      font-size: 48px;
      color: ${colors.red};
    }
  }

  &:last-child {
    padding-left: 20px;
  }
`;

export const MarginSection = styled.section`
  width: 100%;
  height: 450px;
  box-shadow: ${base.boxShadow.default};
  border-radius: ${base.borderRadius.default}px;
  background-color: ${colors.white};
  padding: 15px;
`;

export const BottomSection = styled.section`
  width: 100%;
  height: 370px;
  ${flexbox("space-between")}
`;

export const RankingBox = styled.div`
  width: calc(50% - 10px);
  height: 100%;
  box-shadow: ${base.boxShadow.default};
  border-radius: ${base.borderRadius.default}px;
  background-color: ${colors.white};
  margin-right: 10px;
  padding: 10px 20px;
`;

export const RankingBox2 = styled.div`
  width: calc(50% - 10px);
  height: 100%;
  box-shadow: ${base.boxShadow.default};
  border-radius: ${base.borderRadius.default}px;
  background-color: ${colors.white};
  margin-left: 10px;
  padding: 10px 20px;
`;

export const RankingListWrapper = styled.div`
  width: 100%;
  display: flex;
  max-height: 280px;
  overflow: hidden auto;
  padding-bottom: 2px;

  ol {
    width: 50%;
    margin-top: 20px;
    padding: 0 3px;

    &:first-child {
      li {
        margin-right: 5px;
      }
    }

    &:last-child {
      li {
        margin-left: 5px;
      }
    }

    li {
      padding: 7px 20px;
      box-shadow: ${base.boxShadow.default};
      border-radius: 8px;
      margin-bottom: 10px;
      width: calc(100% - 5px);
      ${flexbox("space-between")}
    }
  }
`;

export const BottomRightWrapper = styled.div`
  width: calc(50% - 10px);
  height: 100%;
`;

export const LocationBox = styled.div`
  width: 100%;
  height: 370px;
  margin-bottom: 20px;
  box-shadow: ${base.boxShadow.default};
  border-radius: ${base.borderRadius.default}px;
  background-color: ${colors.white};
  padding: 20px;
`;

export const LikeAndRatingBox = styled.div`
  width: 100%;
  height: 350px;
  box-shadow: ${base.boxShadow.default};
  border-radius: ${base.borderRadius.default}px;
  background-color: ${colors.white};
  padding: 20px 0 20px 20px;

  ol {
    width: 1005;
    padding-right: 20px;
    margin-top: 30px;

    li {
      ${flexbox("space-between")}
      padding: 7px 20px;
      box-shadow: ${base.boxShadow.default};
      border-radius: 40px;
      margin-bottom: 10px;
    }
  }
`;

export const DougnutChartWrapper = styled.div`
  height: 85%;
  margin-top: 10px;
  width: 100%;
  display: inline-block;
`;

export const QuestionContainer = styled.div`
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: ${({ left }) => left};
  transform: translate(0, -50%);
  z-index: 55;

  div {
    display: inline-flex;
    align-items: center;
    position: relative;
  }

  img {
    display: inline-block;
    width: 20px;
    height: 20px;
  }

  .noticeBubble {
    display: none;
    width: 250px;
    padding: 4px 9px;
    line-height: 1.4;
    position: absolute;
    border-radius: 5px 5px 5px 0;
    top: -8px;
    left: 3px;
    transform: translate(0, -100%);
    font-size: 12px;
    font-weight: 500;
    background-color: rgba(132, 140, 148, 0.3);
    z-index: 55;
  }

  &:hover {
    .noticeBubble {
      display: inline-block;
    }
  }
`;

export const QuestionContainer2 = styled.div`
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: ${({ left }) => left};
  transform: translate(0, -50%);
  z-index: 55;

  div {
    display: inline-flex;
    align-items: center;
    position: relative;
  }

  img {
    display: inline-block;
    width: 20px;
    height: 20px;
  }

  .noticeBubble {
    display: none;
    width: 250px;
    padding: 4px 9px;
    line-height: 1.4;
    position: absolute;
    border-radius: 0 5px 5px 5px;
    top: -8px;
    left: 3px;
    transform: translate(0, 0);
    font-size: 12px;
    font-weight: 500;
    background-color: rgba(132, 140, 148, 0.3);
    z-index: 55;
  }

  &:hover {
    .noticeBubble {
      display: inline-block;
    }
  }
`;
