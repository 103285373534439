import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

import colors from "../../styles/constants/colors";

//img
import Q from "../../assets/icon/faqQ.png";
import A from "../../assets/icon/faqA.png";
import Delete from "../../assets/icon/careerDeleteButton.png";

const ActivityFAQ = ({
  open: detailOpen,
  el,
  faq,
  setData,
  deleteD,
  setDelete,
  index,
  bigData,
  textRecommend,
}) => {
  const ref = useRef();

  const [faqContent, setFaqContent] = useState({
    id: el.id,
    q: el.q,
    a: el.a,
  });

  const [open, setOpen] = useState(false);

  const curriculumContentSet = (text) => {
    ref.current.style.height = "auto";
    ref.current.style.height = ref.current.scrollHeight + "px";
    setFaqContent((prev) => ({ ...prev, a: text }));
  };

  const settingKeyWords = () => {
    setOpen(false);
    setFaqContent((prev) => ({ ...prev, q: faqContent.q }));
  };

  const settingKeyWordsRecommend = (value) => {
    setOpen(false);
    setFaqContent((prev) => ({ ...prev, q: value }));
  };

  useEffect(() => {
    curriculumContentSet(el.a);
  }, []);

  const deleteItem = () => {
    var copy = [...faq];

    var deleteData = copy[index];
    var deleted = [...deleteD.faq];
    deleted.push(deleteData);

    copy.splice(index, 1);

    setDelete((prev) => ({ ...prev, faq: deleted }));
    setData((prev) => ({ ...prev, faq: copy }));
  };

  useEffect(() => {
    if (!open) {
      var copy = [...faq];
      var newArr = [];
      for (let i = 0; i < copy.length; i++) {
        if (i === index) {
          newArr.push({ ...faqContent, change: "yes" });
        } else {
          newArr.push(copy[i]);
        }
      }

      setData((prev) => ({ ...prev, faq: newArr }));
    }
  }, [faqContent, open]);

  useEffect(() => {
    setFaqContent({
      id: el.id,
      q: el.q,
      a: el.a,
    });
  }, [detailOpen]);
  const SearchKeyword = (value, category) => {
    setFaqContent((prev) => ({ ...prev, q: value }));

    if (value.length > 1) {
      bigData(category, value);
    }
  };

  return (
    <Container>
      <QuestionArea>
        <QAIcon src={Q} />
        <TagInputDiv>
          <TagInput
            placeholder="# 태그로 검색해 보세요. 예) 스쿠버 다이빙 초보자"
            value={faqContent.q}
            disabled
            // onChange={(e) => SearchKeyword(e.target.value, "caution")}
          />
        </TagInputDiv>
      </QuestionArea>
      <div style={{ height: "8px" }} />
      <QuestionArea>
        <QAIcon src={A} />
        <TagInputDiv>
          <CurriculumContentInput
            ref={ref}
            rows="1"
            placeholder="답변 내용을 작성해 주세요"
            value={faqContent.a}
            // onChange={(e) => curriculumContentSet(e.target.value)}
            disabled
          />
        </TagInputDiv>
        <AddDeleteButton src={Delete} onClick={() => deleteItem()} />
      </QuestionArea>
    </Container>
  );
};

export default ActivityFAQ;

const Container = styled.div`
  margin-bottom: 10px;
  width : 100%;
`;

const QuestionArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

const QAIcon = styled.img`
  width: calc(1rem * 25 / 14);
  height: calc(1rem * 36 / 14);
  margin-right: 14px;
`;

const TagInputDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 18px;
  /* gap: 10px; */
  background-color: ${colors.Gr01};
  border: 1px solid ${({ red }) => (red ? colors.Red02 : colors.Gr02)};
  border-radius: 6px;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  font-weight: 500;
  line-height: 142%;
`;

const TagInput = styled.input`
  font-weight: 500;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  width: 100%;

  &::placeholder {
    color: ${colors.Gr02};
  }
`;

const CurriculumContentInput = styled.textarea`
  outline: none;
  resize: none;
  border: none;
  width: 100%;
  height: "auto";
  font-weight: 500;
  line-height: 142%;
  /* identical to box height, or 20px */

  letter-spacing: -0.02em;

  /* Greyscale/Gr 03 */

  color: ${colors.Gr05};
`;

const AddDeleteButton = styled.img`
  width: calc(1rem * (17 / 14) + 16px);
  height: calc(1rem * (17 / 14) + 16px);
  margin-left: 8px;
  cursor: pointer;
`;
