import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

// components
import { TableFilterContent, Wrapper, DetailHeader, Icon, Text, TextArea, Devive, AbsoluteWrapper, Label } from "../../layouts/layoutStorage";
import DropDown from "../../components/designedComponents/dropDown";
import DropDownCenter from "../../components/designedComponents/dropDownCenter";
import ImageCrop from "../../components/designedComponents/imageCrop";
import MapService from "../../service/map";
import DesignedActivitySchedule from "../../components/designedActivitySchedule";
import ToggleButton from "../../modules/toggleButton";
import ActivityIntroduceFaqItem from "../../components/designedActivity/activityFAQ";
import ActivityIntroInput from "../../components/designedActivity/activityIntroInput";
import colors from "../../styles/constants/colors";

// icon
import EmptyImage from "../../assets/icon/emptyImage.png";
import EmptyProfile from "../../assets/icon/emptyProfile.png";
import XArrowIcon from "../../assets/icon/xArrowIcon.png";
import Arrow from "../../assets/icon/selectArrow.png";
import WhitePlus from "../../assets/icon/whitePlus.png";
import SemosLogoGrey from "../../assets/icon/semosLogoGrey.png";
import LocationGrey from "../../assets/icon/locationGrey.png";
import ArrowIconBlue from "../../assets/icon/arrowIconBlue.png";
import CloseIcon from "../../assets/icon/closeIcon.png";
import BlackCalender from "../../assets/icon/blackcalendermatters.png";
import wave from "../../assets/icon/wave.png";
import GreySun from "../../assets/icon/sun.png";
import GreyMoon from "../../assets/icon/moon.png";

import KbBank from "../../assets/icon/kbBank.png";
import KakaoBank from "../../assets/icon/kakaoBank.png";
import WooriBank from "../../assets/icon/wooriBank.png";
import HanaBank from "../../assets/icon/hanaBank.png";
import ShBank from "../../assets/icon/shBank.png";
import NhBank from "../../assets/icon/nhBank.png";
import IbkBank from "../../assets/icon/ibkBank.png";
import MgBank from "../../assets/icon/mgBank.png";
import DesignedMainLayout from "../../layouts/designedMainLayout";

const CenterDetail = () => {
  const { n } = useParams();
  const navigate = useNavigate();

  const BankList = [
    {
      text: "국민은행",
      icon: KbBank,
    },
    {
      text: "카카오뱅크",
      icon: KakaoBank,
    },
    {
      text: "우리은행",
      icon: WooriBank,
    },
    {
      text: "신한은행",
      icon: ShBank,
    },
    {
      text: "하나은행",
      icon: HanaBank,
    },
    {
      text: "농협은행",
      icon: NhBank,
    },
    {
      text: "기업은행",
      icon: IbkBank,
    },
    {
      text: "새마을금고",
      icon: MgBank,
    },
    {
      text: "케이뱅크",
      icon: "",
    },
    {
      text: "토스뱅크",
      icon: "",
    },
    {
      text: "지역농협",
      icon: "",
    },
    {
      text: "SC제일은행",
      icon: "",
    },
    {
      text: "우체극",
      icon: "",
    },
    {
      text: "수협은행",
      icon: "",
    },
    {
      text: "지역수협",
      icon: "",
    },
    {
      text: "신협",
      icon: "",
    },
    {
      text: "씨티은행",
      icon: "",
    },
    {
      text: "KDB산업은행",
      icon: "",
    },
    {
      text: "대구은행",
      icon: "",
    },
    {
      text: "부산은행",
      icon: "",
    },
    {
      text: "경남은행",
      icon: "",
    },
    {
      text: "광주은행",
      icon: "",
    },
    {
      text: "전북은행",
      icon: "",
    },
    {
      text: "제주은행",
      icon: "",
    },
  ];

  const [category, setCategory] = useState(0);
  const [sport, setSport] = useState();
  const [bankPicker, setBankPicker] = useState(false);
  const [faqSearch, setFaqSearch] = useState([]);
  const [search, setSearch] = useState("");
  const [partnerList, setPartnerList] = useState([]);
  const [searchInfo, setSearchInfo] = useState(false);
  const [careerInfo, setCareerInfo] = useState("");
  const [centerInfo, setCenterInfo] = useState("");
  const [schedule, setSchedule] = useState([]);
  const [rest, setRest] = useState([]);
  const [deleteInfo, setDeleteInfo] = useState({
    sport: [],
    partner: [],
    career: [],
  });
  const [cropImage, setCropImage] = useState({
    width: 8,
    height: 5,
    type: "",
    number: "",
    image: "",
    status: false,
    scale: true,
  });
  const [newSchedule, setNewSchedule] = useState({
    id: "new",
    title: "",
    start: "",
    end: "",
    address: "",
    extra: "",
    si: "",
    gu: "",
    dong: "",
    day: [],
    time_list: [],
  });
  const [deleteD, setDelete] = useState({
    schedule: [],
    faq: [],
  });

  const BigDataSearch = (category, value) => {
    var token = localStorage.getItem("token");

    const frm = new FormData();

    frm.append("mode", "register_recommend");
    frm.append("token", token);
    frm.append("category", category);
    frm.append("text", value);

    axios
      .post("https://ai.semos.kr/semos_partner/coaching/activity", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;
        var RegisterText = Value.text_list;

        if (Status == "success") {
          if (category == "faq") {
            setFaqSearch(RegisterText);
          }
        } else {
          window.alert(StatusMessage);
        }
      });
  };

  const CenterSpecific = (number) => {
    var token = localStorage.getItem("token");

    const frm = new FormData();

    frm.append("mode", "main");
    frm.append("token", token);
    frm.append("center_id", number);

    axios
      .post("https://ai.semos.kr/semos_admin/v2/center/CenterDetail", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status == "success";
        var CenterInfo = Value.center_info;
        var CenterRest = Value.rest;
        var CenterSchedule = Value.schedule;

        if (Status) {
          setCenterInfo(CenterInfo);
          setSchedule(CenterSchedule);
          setRest(CenterRest);
        }
      });
  };

  useEffect(() => {
    if (n != "new") {
      CenterSpecific(n);
    } else {
      setSchedule([]);
      setRest([]);
      setCenterInfo({
        account: {
          bank: "",
          account: "",
          first_id: "",
          last_id: "",
          business_number: "",
        },
        faq : [],
        activity: [],
        bank_image: { image: "", change: "no" },
        business_image: { image: "", change: "no" },
        career: [],
        id: "new",
        id_card_image: { image: "", change: "no" },
        image: [],
        intro: "",
        owner: "",
        memo: "",
        location: "",
        partner: [],
        profile: "",
        scale: "",
        sport: [],
        status: "",
        title: "",
      });
    }
  }, []);

  const StatusText = {
    0: "승인대기",
    1: "활성화",
    2: "비활성화",
    4: "전담코디",
  };

  const SportList = {
    1: "워터스포츠",
    2: "스노우스포츠",
    3: "실내스포츠",
    4: "야외스포츠",
  };

  const SportOptionList = [
    [{ text: "대분류를 선택해주세요", value: "" }],
    [
      { text: "프리다이빙", value: "프리다이빙" },
      { text: "스쿠버다이빙", value: "스쿠버다이빙" },
      { text: "패들보드", value: "패들보드" },
      { text: "서핑", value: "서핑" },
      { text: "웨이크보드", value: "웨이크보드" },
      { text: "수상스키", value: "수상스키" },
      { text: "수영/스노클링", value: "수영/스노클링" },
    ],
    [
      { text: "스노보드", value: "스노보드" },
      { text: "스키", value: "스키" },
    ],
    [
      { text: "필라테스", value: "필라테스" },
      { text: "피트니스", value: "피트니스" },
    ],
    [
      { text: "축구", value: "축구" },
      { text: "야구", value: "야구" },
      { text: "농구", value: "농구" },
    ],
  ];

  const bankPickerRef = useRef();
  const modalRef = useRef();

  const bankPickerClose = (e) => {
    if (bankPicker && modalRef.current.contains(e.target)) {
      setBankPicker(true);
    } else if (bankPicker && (!bankPickerRef.current || !bankPickerRef.current.contains(e.target))) {
      setBankPicker(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", bankPickerClose);

    return () => {
      window.removeEventListener("click", bankPickerClose);
    };
  }, [bankPicker]);

  const StatusComponent = (status) => {
    switch (status) {
      case "0":
        return (
          <Wrapper margin={`0 0 0 9px`} bgc={`#FFFFFF`} border={`1px solid #3383FF`} padding={`3px 14px`} radius={`16px`}>
            <Text color={`#3383FF`} weight={`500`}>
              {`- 승인대기`}
            </Text>
          </Wrapper>
        );
        break;

      case "1":
        return (
          <Wrapper margin={`0 0 0 9px`} bgc={`#E5F0FF`} border={`1px solid #3383FF`} padding={`3px 14px`} radius={`16px`}>
            <Text color={`#3383FF`} weight={`500`}>
              {`• 활성화`}
            </Text>
          </Wrapper>
        );
        break;

      case "2":
        return (
          <Wrapper margin={`0 0 0 9px`} bgc={`#F9F9F9`} border={`1px solid #ACAFB5`} padding={`3px 14px`} radius={`16px`}>
            <Text color={`#818792`} weight={`500`}>
              {`• 비활성화`}
            </Text>
          </Wrapper>
        );
        break;

      case "4":
        return (
          <Wrapper margin={`0 0 0 9px`} bgc={`#F9F9F9`} border={`1px solid #ACAFB5`} padding={`3px 14px`} radius={`16px`}>
            <Text color={`#818792`} weight={`500`}>
              {`• 전담코디`}
            </Text>
          </Wrapper>
        );
        break;
    }
  };

  const setImageFromFile = ({ file, setImageUrl }) => {
    let reader = new FileReader();
    reader.onload = function () {
      setImageUrl({ result: reader.result });
    };

    reader.readAsDataURL(file);
  };

  const SportHandler = (type, value) => {
    if (type == "category") {
      setCategory(value);
      setSport("");
    } else {
      setSport(value);
    }
  };

  const AddSport = () => {
    if (category != 0 && category && sport) {
      let CenterInfo = centerInfo;
      let sportList = CenterInfo.sport;

      for (var i = 0; i < sportList.length; i++) {
        var category_og = sportList[i].category;
        var sport_og = sportList[i].sport;

        if (category_og == category && sport_og == sport) {
          window.alert(`중복된 액티비티 카테고리가 있습니다. 확인해주세요!`);
          break;
        }
      }

      sportList[sportList.length] = {
        id: "new",
        category: category,
        sport: sport,
        change: "yes",
      };
      CenterInfo["sport"] = sportList;

      setCenterInfo(CenterInfo);
      setCategory(0);
      setSport("");
    } else {
      window.alert(`액티비티 카테고리 대분류, 소분류 모두 선택해주세요!`);
    }
  };

  const DeleteInfo = (type, item, number) => {
    let DInfo = deleteInfo;
    let DInfoType = DInfo[type];

    DInfoType[DInfoType.length] = item;

    setDeleteInfo((prev) => ({ ...prev, [type]: DInfoType }));

    let CInfo = centerInfo;
    let CInfoType = CInfo[type];
    CInfoType.splice(number, 1);

    setCenterInfo((prev) => ({ ...prev, [type]: CInfoType }));
  };

  const AccountInfo = (type, value) => {
    let CInfo = centerInfo;
    let CInfoType = CInfo.account;

    if (type == "business_number") {
      CInfoType[type] = value.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");
    } else {
      CInfoType[type] = value;
    }

    setCenterInfo((prev) => ({ ...prev, account: CInfoType }));
  };

  const InfoHandler = (type, value) => {
    if (type == "career") {
      let CInfo = centerInfo;
      let CInfoType = CInfo[type];

      if (careerInfo.length > 1) {
        CInfoType[CInfoType.length] = {
          id: "new",
          career: careerInfo,
          change: "yes",
        };

        setCareerInfo("");
        setCenterInfo((prev) => ({ ...prev, [type]: CInfoType }));
      } else {
        window.alert("파트너 경력이 올바르지 않습니다.");
      }
    } else if (type == "partner") {
      let CInfo = centerInfo;
      let CInfoType = CInfo[type];

      var check = false;

      for (var i = 0; i < CInfoType.length; i++) {
        if (CInfoType[i].id == value.id) {
          window.alert("중복된 파트너가 있습니다. 파트너를 확인해주세요!");
          check = true;
          break;
        }
      }

      if (!check) {
        CInfoType[CInfoType.length] = value;
        setCareerInfo("");
        setCenterInfo((prev) => ({ ...prev, [type]: CInfoType }));
      }
    } else if (type == "phone") {
      var phone_number = value.replace(/[^0-9]/g, "").replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

      setCenterInfo((prev) => ({ ...prev, [type]: phone_number }));
    } else {
      setCenterInfo((prev) => ({ ...prev, [type]: value }));
    }
  };

  const ImgChange = (value, type, number) => {
    if (type == "center_image_1" || type == "center_image_2" || type == "center_image_3") {
      setCropImage({
        width: 8,
        height: 5,
        type: type,
        number: number,
        image: value,
        status: true,
        scale: true,
      });
    } else {
      setCropImage({
        width: 1,
        height: 1,
        type: type,
        number: number,
        image: value,
        status: true,
        scale: false,
      });
    }
  };

  const ActivityReplace = (param) => {
    document.body.style.removeProperty("overflow");
    document.getElementById("root").style.pointerEvents = "auto";

    setTimeout(() => {
      navigate(`/activity/1/${param}/all/all/all`);
    }, 300);
  };

  const CenterSave = () => {
    if (window.confirm("정보를 저장하시겠습니까?")) {
      var token = window.localStorage.getItem("token");

      axios
        .post(
          "https://ai.semos.kr/semos_admin/v2/center/CenterDetail",
          {
            mode: "save",
            token: token,
            info: centerInfo,
            delete: deleteInfo,
            deleteD: deleteD,
            rest: rest,
            schedule: schedule,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            alert(statusMessage);
            if (n === "new") {
              navigate(`/center/1/all/카테고리%20전체/all/all/all`);
            } else {
              window.location.reload();
            }
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    }
  };

  useEffect(() => {
    if (centerInfo) {
      if (centerInfo.type == 0) {
        setCenterInfo((prev) => ({ ...prev, scale: 1 }));
      }
    }
  }, [centerInfo && centerInfo.type]);

  return (
    <>
      <Wrapper
        bgc="#ffffff"
        of={`hidden auto`}
        margin="10px 0 0 0"
        display={`inline-block`}
        width={`100%`}
        mw={`1000px`}
        mh={`calc(100vh - 72px)`}
        padding={`10px 0`}
      >
        <DetailHeader padding={`0 36px`} margin={`0 0 41px 0`}>
          <Wrapper jc={`flex-start`}>
            <Text weight={`500`} className={`f24`} color={`#000000`}>
              파트너 관리
            </Text>

            {StatusComponent(`${centerInfo && centerInfo.status}`)}
          </Wrapper>

          <Wrapper jc={`flex-start`}>
            <DropDown
              data={centerInfo && StatusText[centerInfo.status]}
              setData={setCenterInfo}
              option={[
                { text: `활성화`, value: `1` },
                { text: `비활성화`, value: `2` },
                { text: `승인대기`, value: `0` },
                { text: `전담코디`, value: `4` },
              ]}
              type={`object`}
              defaultText={`승인대기`}
              no={false}
              keyName={`status`}
            />

            <Wrapper onClick={() => CenterSave()} cursor={`pointer`} radius={`8px`} bgc={`#0E6DFF`} padding={`6.5px 12px`} jc={`center`}>
              <Text lh={`none`} color={`#ffffff`} weight={`500`} className={`f12`}>
                {centerInfo && (centerInfo.id == "new" ? `추가` : `저장`)}
              </Text>
            </Wrapper>
          </Wrapper>
        </DetailHeader>

        <Wrapper wrap={`nowrap`} padding={`0 36px`} width={`100%`} ai={`flex-start`}>
          <Wrapper transition={`all 0.3s ease`} mh={`unset`} width={`100px`} margin={`0 20px 0 0`} wrap={`nowrap`}>
            <Label key={`profile`} htmlFor={`profile`} cursor={`pointer`} width={`100px`} height={`100px`} jc="center" radius={`50%`} of={`hidden`}>
              <input
                onChange={({ target: { files } }) => {
                  if (files.length) {
                    setImageFromFile({
                      file: files[0],
                      setImageUrl: ({ result }) => {
                        ImgChange(result, "profile", 0);
                      },
                    });
                  }
                }}
                id={`profile`}
                accept="image/*"
                type="file"
              />
              <img
                style={{
                  display: "inline-block",
                  height: "100px",
                }}
                src={centerInfo ? (centerInfo.profile ? centerInfo.profile : EmptyProfile) : EmptyProfile}
              />
            </Label>
          </Wrapper>

          <Wrapper transition={`all 0.3s ease`} mh={`unset`} width={`calc(100% - 120px)`} wrap={`wrap`}>
            <Wrapper margin={`0 0 12px 0`} width={`100%`} jc={`flex-start`}>
              <Text width={`160px`} color={`#818792`} weight={`500`} className={`f14`}>
                파트너 이름
              </Text>
              <TableFilterContent width={`calc(100% - 160px)`} margin={`0`}>
                <Input
                  value={centerInfo && `${centerInfo.title}`}
                  onChange={(e) => InfoHandler("title", e.target.value)}
                  width={`100%`}
                  type={`text`}
                  maxLength={50}
                  placeholder="파트너명을 입력해주세요!"
                />
              </TableFilterContent>
            </Wrapper>

            <Wrapper margin={`0 0 12px 0`} width={`100%`} jc={`flex-start`}>
              <Text width={`160px`} color={`#818792`} weight={`500`} className={`f14`}>
                파트너 전화번호
              </Text>
              <TableFilterContent width={`calc(100% - 160px)`} margin={`0`}>
                <Input
                  value={centerInfo && `${centerInfo.phone}`}
                  onChange={(e) => InfoHandler("phone", e.target.value)}
                  width={`100%`}
                  type={`text`}
                  maxLength={13}
                  placeholder="파트너 전화번호를 입력해주세요!"
                />
              </TableFilterContent>
            </Wrapper>

            <Wrapper margin={`0 0 52px 0`} width={`100%`} jc={`flex-start`}>
              <Text width={`160px`} color={`#818792`} weight={`500`} className={`f14`}>
                센터/강사
              </Text>

              <DropDown
                data={centerInfo && `${centerInfo.type == 0 ? `강사` : `센터`}`}
                setData={setCenterInfo}
                option={[
                  { text: `강사`, value: `0` },
                  { text: `센터`, value: `1` },
                ]}
                type={`object`}
                defaultText={`강사`}
                no={false}
                keyName={`type`}
              />

              <DropDown
                data={centerInfo && `${centerInfo.scale}명`}
                setData={setCenterInfo}
                option={
                  centerInfo && centerInfo.type == 0
                    ? [{ text: `1명`, value: `1` }]
                    : [
                        { text: `1명`, value: `1` },
                        { text: `3명`, value: `3` },
                        { text: `5명`, value: `5` },
                        { text: `10명`, value: `10` },
                        { text: `15명`, value: `15` },
                        { text: `20명`, value: `20` },
                      ]
                }
                type={`object`}
                defaultText={`승인대기`}
                no={false}
                keyName={`scale`}
              />
            </Wrapper>
          </Wrapper>
        </Wrapper>
        
        <Wrapper margin={`0 0 42px 0`} wrap={`wrap`} padding={`0 36px`} width={`100%`} jc={`space-between`}>
          <Text margin={`0 0 16px 0`} color={`#3C3E41`} width={`100%`} weight={`700`} className={`f16`}>
            배경 이미지
          </Text>

          <Wrapper width={`100%`} jc={`space-between`}>
            <Wrapper ai={`flex-start`} jc={`center`} width={`calc(100% / 3)`} wrap={`wrap`}>
              <Wrapper
                bgc={`#F5F5F5`}
                radius={`8px`}
                margin={`14px 0 10px 0`}
                width={`calc(100% - 10px)`}
                height={`calc( (1000px / 3) * (5 / 8) )`}
                onClick={() => {
                  if (centerInfo.image[0]) {
                    window.open(centerInfo.image[0].image, "_blank");
                  }
                }}
              >
                <img
                  style={{
                    display: "inline-block",
                    width: "100%",
                    height: "100%",
                    borderRadius: "8px",
                  }}
                  src={centerInfo ? (centerInfo.image[0] ? centerInfo.image[0].image : EmptyImage) : EmptyImage}
                />
              </Wrapper>

              <Label key={`center_image_1`} htmlFor={`center_image_1`} cursor={`pointer`} width={`calc(100% - 10px)`} jc="flex-end">
                <input
                  onChange={({ target: { files } }) => {
                    if (files.length) {
                      setImageFromFile({
                        file: files[0],
                        setImageUrl: ({ result }) => {
                          ImgChange(result, "center_image_1", 0);
                        },
                      });
                    }
                  }}
                  id={`center_image_1`}
                  accept="image/*"
                  type="file"
                />
                <Text lh={`unset`} color={`#3383FF`} weight={`500`} className={`f14`}>
                  이미지 등록하기
                </Text>
              </Label>
            </Wrapper>

            <Wrapper ai={`flex-start`} jc={`center`} width={`calc(100% / 3)`} wrap={`wrap`}>
              <Wrapper
                bgc={`#F5F5F5`}
                radius={`8px`}
                margin={`14px 0 10px 0`}
                width={`calc(100% - 10px)`}
                height={`calc( (1000px / 3) * (5 / 8) )`}
                onClick={() => {
                  if (centerInfo.image[1]) {
                    window.open(centerInfo.image[1].image, "_blank");
                  }
                }}
              >
                <img
                  style={{
                    display: "inline-block",
                    width: "100%",
                    height: "100%",
                    borderRadius: "8px",
                  }}
                  src={centerInfo ? (centerInfo.image[1] ? centerInfo.image[1].image : EmptyImage) : EmptyImage}
                />
              </Wrapper>

              <Label key={`center_image_2`} htmlFor={`center_image_2`} cursor={`pointer`} width={`calc(100% - 10px)`} jc="flex-end">
                <input
                  onChange={({ target: { files } }) => {
                    if (files.length) {
                      setImageFromFile({
                        file: files[0],
                        setImageUrl: ({ result }) => {
                          ImgChange(result, "center_image_2", 1);
                        },
                      });
                    }
                  }}
                  id={`center_image_2`}
                  accept="image/*"
                  type="file"
                />
                <Text lh={`unset`} color={`#3383FF`} weight={`500`} className={`f14`}>
                  이미지 등록하기
                </Text>
              </Label>
            </Wrapper>

            <Wrapper ai={`flex-start`} jc={`center`} width={`calc(100% / 3)`} wrap={`wrap`}>
              <Wrapper
                bgc={`#F5F5F5`}
                radius={`8px`}
                margin={`14px 0 10px 0`}
                width={`calc(100% - 10px)`}
                height={`calc( (1000px / 3) * (5 / 8) )`}
                onClick={() => {
                  if (centerInfo.image[2]) {
                    window.open(centerInfo.image[2].image, "_blank");
                  }
                }}
              >
                <img
                  style={{
                    display: "inline-block",
                    width: "100%",
                    height: "100%",
                    borderRadius: "8px",
                  }}
                  src={centerInfo ? (centerInfo.image[2] ? centerInfo.image[2].image : EmptyImage) : EmptyImage}
                />
              </Wrapper>

              <Label key={`center_image_3`} htmlFor={`center_image_3`} cursor={`pointer`} width={`calc(100% - 10px)`} jc="flex-end">
                <input
                  onChange={({ target: { files } }) => {
                    if (files.length) {
                      setImageFromFile({
                        file: files[0],
                        setImageUrl: ({ result }) => {
                          ImgChange(result, "center_image_3", 2);
                        },
                      });
                    }
                  }}
                  id={`center_image_3`}
                  accept="image/*"
                  type="file"
                />
                <Text lh={`unset`} color={`#3383FF`} weight={`500`} className={`f14`}>
                  이미지 등록하기
                </Text>
              </Label>
            </Wrapper>
          </Wrapper>
        </Wrapper>

        <Wrapper margin={`0 0 42px 0`} wrap={`wrap`} padding={`0 36px`} width={`100%`} jc={`space-between`}>
          <Text margin={`0 0 16px 0`} color={`#3C3E41`} width={`100%`} className={`f16`} weight={`700`}>
            지역
          </Text>

          <Wrapper border={`1px solid #DCDCDC`} padding={`16px`} width={`100%`} jc={`center`}>
            <Text color={`#3C3E41`} width={`100%`} weight={`500`} className={`f14`}>
              {centerInfo && `${centerInfo.location}`}
            </Text>
          </Wrapper>
        </Wrapper>

        <Wrapper margin={`0 0 42px 0`} wrap={`wrap`} padding={`0 36px`} width={`100%`} jc={`space-between`}>
          <Text margin={`0 0 16px 0`} color={`#3C3E41`} width={`100%`} weight={`700`} className={`f16`}>
            파트너 메모
          </Text>

          <Wrapper border={`1px solid #DCDCDC`} width={`100%`} jc={`center`}>
            <TextArea
              onChange={(e) => InfoHandler("memo", e.target.value)}
              width={`100%`}
              height={`300px`}
              padding={`16px`}
              type={`text`}
              value={centerInfo && `${centerInfo.memo}`}
            />
          </Wrapper>
        </Wrapper>

        <Wrapper margin={`0 0 42px 0`} wrap={`wrap`} padding={`0 36px`} width={`100%`} jc={`space-between`}>
          <Text margin={`0 0 16px 0`} color={`#3C3E41`} width={`100%`} weight={`700`} className={`f16`}>
            액티비티 카테고리
          </Text>

          <Wrapper width={`100%`} jc={`space-between`}>
            <Wrapper width={`calc((100% - 52px) / 2)`} jc={`flex-start`}>
              <DropDownCenter
                data={category && category != 0 && SportList[category]}
                setData={SportHandler}
                option={[
                  { text: `워터스포츠`, value: 1 },
                  { text: `스노우스포츠`, value: 2 },
                  { text: `실내스포츠`, value: 3 },
                  { text: `야외스포츠`, value: 4 },
                ]}
                type={`category`}
                defaultText={`대분류 선택`}
                no={false}
                keyName={`status`}
                width={`100%`}
              />
            </Wrapper>

            <Wrapper width={`calc((100% - 52px) / 2)`} jc={`flex-start`}>
              <DropDownCenter
                data={sport && sport}
                setData={SportHandler}
                option={SportOptionList[category]}
                type={`sport`}
                defaultText={`소분류 선택`}
                no={false}
                keyName={`status`}
                width={`100%`}
              />
            </Wrapper>

            <Wrapper onClick={() => AddSport()} cursor={`pointer`} border={`1px solid #66A2FF`} radius={`6px`} width={`36px`} height={`36px`} jc={`center`}>
              <Text lh={`unset`} color={`#66A2FF`} weight={`500`} className={`f18`}>
                +
              </Text>
            </Wrapper>
          </Wrapper>

          <Wrapper margin={`18px 0 0 0`} width={`100%`} jc={`flex-end`}>
            {centerInfo &&
              centerInfo.sport.map((item, idx) => (
                <Wrapper
                  key={`center_sport_${idx}`}
                  margin={`0 0 10px 8px`}
                  bgc={`#F9F9F9`}
                  padding={`6px 12px`}
                  border={`1.2px solid #DCDCDC`}
                  radius={`24px`}
                  jc={`center`}
                >
                  <Text lh={`unset`} color={`#3C3E41`} weight={`500`} className={`f13`}>{`${SportList[item.category]}`}</Text>
                  <Text lh={`unset`} margin={`0 7px`} color={`#DCDCDC`} weight={`500`} className={`f13`}>
                    |
                  </Text>
                  <Text lh={`unset`} color={`#3C3E41`} weight={`500`} className={`f13`}>{`${item.sport}`}</Text>
                  <Icon
                    width={`calc(1rem * (12 / 14))`}
                    cursor={`pointer`}
                    margin={`0 0 0 6px`}
                    src={XArrowIcon}
                    onClick={() => DeleteInfo("sport", item, idx)}
                  />
                </Wrapper>
              ))}
          </Wrapper>
        </Wrapper>

        <Wrapper margin={`0 0 42px 0`} wrap={`wrap`} padding={`0 36px`} width={`100%`} jc={`space-between`}>
          <Text margin={`0 0 16px 0`} color={`#3C3E41`} width={`100%`} weight={`700`} className={`f16`}>
            파트너 소개
          </Text>

          <Wrapper border={`1px solid #DCDCDC`} width={`100%`} jc={`center`}>
            <TextArea
              onChange={(e) => InfoHandler("intro", e.target.value)}
              width={`100%`}
              height={`300px`}
              padding={`16px`}
              type={`text`}
              value={centerInfo ? `${centerInfo.intro}` : ""}
            />
          </Wrapper>
        </Wrapper>

        <Wrapper wrap={`wrap`} padding={`0 36px`} width={`100%`} jc={`space-between`}>
          <Text margin={`0 0 16px 0`} color={`#3C3E41`} width={`100%`} weight={`700`} className={`f16`}>
            파트너 경력
          </Text>

          <Wrapper margin={`0 0 8px 0`} width={`100%`} jc={`space-between`}>
            <TableFilterContent width={`calc(100% - 44px)`} margin={`0`}>
              <Input
                value={careerInfo}
                onChange={(e) => setCareerInfo(e.target.value)}
                width={`100%`}
                type={`text`}
                maxLength={50}
                placeholder="파트너 경력을 입력해 주세요"
              />
            </TableFilterContent>

            <Wrapper
              onClick={() => InfoHandler("career")}
              cursor={`pointer`}
              border={`1px solid #66A2FF`}
              radius={`6px`}
              width={`36px`}
              height={`36px`}
              jc={`center`}
            >
              <Text lh={`unset`} color={`#66A2FF`} weight={`500`} className={`f18`}>
                +
              </Text>
            </Wrapper>
          </Wrapper>

          {centerInfo &&
            centerInfo.career.map((item, idx) => (
              <Wrapper key={`center_career_${idx}`} margin={`0 0 8px 0`} width={`100%`} jc={`space-between`}>
                <TableFilterContent width={`calc(100% - 44px)`} margin={`0`}>
                  <Input
                    value={item.career}
                    //onChange={(e) => PartnerSearch(e.target.value)}
                    width={`100%`}
                    readOnly={true}
                    type={`text`}
                    maxLength={50}
                    placeholder="파트너 경력을 입력해 주세요"
                  />
                </TableFilterContent>

                <Wrapper
                  onClick={() => DeleteInfo("career", item, idx)}
                  cursor={`pointer`}
                  border={`1px solid #FF9187`}
                  radius={`6px`}
                  width={`36px`}
                  height={`36px`}
                  jc={`center`}
                >
                  <Text lh={`unset`} color={`#FF9187`} weight={`500`} className={`f18`}>
                    -
                  </Text>
                </Wrapper>
              </Wrapper>
          ))}
        </Wrapper>

        <RestingSection rest={rest} setRest={setRest} />

        <Wrapper wrap={`wrap`} padding={`0 36px`} width={`100%`} jc={`space-between`}>
          <Text margin={`0 0 16px 0`} color={`#3C3E41`} width={`100%`} weight={`700`} className={`f16`}>
            자주 묻는 질문
          </Text>

          <Wrapper wrap={`wrap`} width={`100%`} jc={`space-between`}>
            <ActivityIntroInput open={true} bigData={BigDataSearch} faq={centerInfo.faq} setData={setCenterInfo} textRecommend={{faq: faqSearch}} />

            {centerInfo.faq?.map((el, i) => (
              <ActivityIntroduceFaqItem
                open={true}
                key={`${i}_faq`}
                faq={centerInfo.faq}
                setData={setCenterInfo}
                deleteD={deleteD}
                setDelete={setDelete}
                bigData={BigDataSearch}
                index={i}
                textRecommend={{faq: faqSearch}}
                el={el}
              />
            ))}
          </Wrapper>
        </Wrapper>


        <Devive width={`100%`} height={`1px`} bgc={`#E6E6E6`} margin={`52px 0 36px 0`} />

        <Wrapper wrap={`wrap`} margin={`0 0 52px 0`} padding={`0 36px`} width={`100%`} jc={`space-between`}>
          <Text margin={`0 0 16px 0`} color={`#3C3E41`} width={`100%`} weight={`700`} className={`f16`}>
            액티비티 목록
          </Text>

          <Wrapper wrap={`wrap`} width={`100%`} jc={`space-between`}>
            {centerInfo &&
              centerInfo.activity.map((item, idx) => (
                <Wrapper
                  cursor={`pointer`}
                  key={`center_partner_${idx}`}
                  margin={`0 0 24px 0`}
                  width={`100%`}
                  wrap={`wrap`}
                  onClick={() => ActivityReplace(item.title)}
                >
                  <Wrapper margin={`0 0 12px 0`} width={`100%`} jc={`space-between`}>
                    <Wrapper>
                      <Icon margin={`0 4px 0 0`} width={`calc(1rem * (16 / 14))`} src={SemosLogoGrey} />
                      <Text ws={`nowrap`} color={`#0E6DFF`} width={`100%`} weight={`500`} className={`f12`}>
                        {item.sport && item.sport[0] ? item.sport[0].sport : "미등록"}
                      </Text>
                      <Text ws={`nowrap`} margin={`0 6px`} color={`#818792`} width={`100%`} weight={`500`} className={`f12`}>
                        |
                      </Text>
                      <Text ws={`nowrap`} color={`#818792`} width={`100%`} weight={`500`} className={`f12`}>
                        {item.duration ? item.duration : "미등록"}
                      </Text>
                      <Text ws={`nowrap`} margin={`0 6px`} color={`#818792`} width={`100%`} weight={`500`} className={`f12`}>
                        |
                      </Text>
                      <Text ws={`nowrap`} color={`#818792`} width={`100%`} weight={`500`} className={`f12`}>
                        {item.type ? item.type : "미등록"}
                      </Text>
                    </Wrapper>

                    <Wrapper>
                      <Icon margin={`0 4px 0 0`} width={`calc(1rem * (16 / 14))`} src={LocationGrey} />
                      <Text color={`#818792`} width={`100%`} weight={`500`} className={`f12`}>
                        {item.schecule ? item.schecule.map((item, idx2) => (idx2 == 0 ? `${item}` : `, ${item}`)) : "스케줄 미등록"}
                      </Text>
                    </Wrapper>
                  </Wrapper>

                  <Wrapper width={`100%`} jc={`space-between`}>
                    <Wrapper of={`hidden`} height={`calc(1rem * (65 / 14))`} width={`calc(1rem * (65 / 14))`} jc={`center`}>
                      <Icon height={`calc(1rem * (65 / 14))`} width={`auto`} src={item.profile ? item.profile : EmptyImage} />
                    </Wrapper>

                    <Wrapper
                      ai={`normal`}
                      wrap={`wrap`}
                      padding={`0 0 0 12px`}
                      height={`calc(1rem * (65 / 14))`}
                      width={`calc(100% - (1rem * (65 / 14)) )`}
                      jc={`center`}
                    >
                      <Text ai={`flex-start`} color={`#3C3E41`} width={`100%`} weight={`500`} className={`f14`}>
                        {item.title ? item.title : "액티비티명 미등록"}
                      </Text>

                      <Text ai={`flex-end`} color={`#3383FF`} width={`100%`} weight={`500`} className={`f15`}>
                        {item.price_text ? `${item.price_text} 원` : "판매 가격 미등록"}
                      </Text>
                    </Wrapper>
                  </Wrapper>
                </Wrapper>
              ))}
          </Wrapper>

          <Wrapper wrap={`wrap`} width={`100%`} jc={`flex-end`}>
            <Wrapper onClick={() => ActivityReplace(centerInfo.title)} wrap={`wrap`} cursor={`pointer`} jc={`flex-end`}>
              <Text lh={`unset`} color={`#3383FF`} weight={`500`} className={`f16`}>
                액티비티 관리로 이동
              </Text>
              <Icon
                width={`calc(1rem * (20 / 14))`}
                cursor={`pointer`}
                src={ArrowIconBlue}
                //onClick={(e) => PartnerSearch(partnerText)}
              />
            </Wrapper>
          </Wrapper>
        </Wrapper>
        
        <ScheduleSection
          data={schedule}
          setData={setSchedule}
          newS={newSchedule}
          setNew={setNewSchedule}
          deleteD={deleteD}
          setDelete={setDelete}
          id={1}
          modal={false}
        />

        <Devive width={`100%`} height={`1px`} bgc={`#E6E6E6`} margin={`52px 0 36px 0`} />

        <Wrapper margin={`0 0 52px 0`} wrap={`wrap`} padding={`0 36px`} width={`100%`} jc={`space-between`}>
          <Text margin={`0 0 16px 0`} color={`#3C3E41`} width={`100%`} weight={`700`} className={`f16`}>
            정산 정보
          </Text>

          <Wrapper wrap={`wrap`} width={`100%`} jc={`space-between`}>
            <Wrapper margin={`0 0 8px 0`} width={`100%`} jc={`flex-start`}>
              <Text width={`160px`} color={`#818792`} weight={`500`} className={`f14`}>
                계좌 정보
              </Text>

              <TableFilterContent cursor={`pointer`} width={`140px`} margin={`0 10px 0 0`}>
                <Wrapper onClick={() => setBankPicker(!bankPicker)} ref={bankPickerRef} width={`100%`} jc={`center`}>
                  <Text lh={`138%`} color={`#3C3E41`} weight={`500`} className={`f13`}>
                    {centerInfo && `${centerInfo.account.bank ? centerInfo.account.bank : "은행선택"}`}
                  </Text>
                </Wrapper>
                <AbsoluteWrapper
                  ref={modalRef}
                  onClick={() => setBankPicker(false)}
                  display={bankPicker ? `inline-flex` : `none`}
                  border={`1px solid rgba(0, 0, 0, 0.16)`}
                  z={`100`}
                  wrap={`wrap`}
                  bgc={`#ffffff`}
                  radius={`16px`}
                  padding={`24px`}
                  width={`calc(1rem * (380 / 14))`}
                  bottom={`-8px`}
                  left={`0`}
                  transform={`translate(0, 100%)`}
                >
                  <Wrapper wrap={`wrap`} width={`100%`} jc={`space-between`}>
                    <Text color={`#1A1C1F`} weight={`500`} className={`f18`}>
                      은행 선택
                    </Text>

                    <Icon width={`calc(1rem * (28 / 14))`} cursor={`pointer`} src={CloseIcon} onClick={() => setBankPicker(false)} />
                  </Wrapper>

                  <Wrapper wrap={`wrap`} margin={`26px 0 0 0`} width={`100%`} jc={`space-between`}>
                    {BankList.map((item, idx) => (
                      <Wrapper
                        onClick={() => {
                          AccountInfo("bank", item.text);
                          setBankPicker(false);
                        }}
                        padding={`18px`}
                        key={`bank_${idx}`}
                        cursor={`pointer`}
                        radius={`8px`}
                        border={centerInfo ? (item.text == centerInfo.account.bank ? `1px solid #3383FF` : `1px solid #DCDCDC`) : `1px solid #DCDCDC`}
                        wrap={`wrap`}
                        margin={`0 0 12px 0`}
                        width={`calc( (100% - 30px) / 4)`}
                        jc={`center`}
                      >
                        <Icon width={`calc(1rem * (27 / 14))`} src={item.icon} margin={`0 0 10px 0`} />
                        <Text
                          jc={`center`}
                          color={centerInfo ? (item.text == centerInfo.account.bank ? `#3383FF` : `#ACAFB5`) : `#ACAFB5`}
                          width={`100%`}
                          weight={`500`}
                          className={`f12`}
                        >
                          {item.text}
                        </Text>
                      </Wrapper>
                    ))}
                  </Wrapper>
                </AbsoluteWrapper>
              </TableFilterContent>

              <TableFilterContent width={`calc(100% - 310px)`} margin={`0`}>
                <Input
                  value={centerInfo && `${centerInfo.account.account}`}
                  onChange={(e) => AccountInfo("account", e.target.value)}
                  width={`100%`}
                  type={`text`}
                  maxLength={20}
                  placeholder="계좌번호를 입력해 주세요."
                />
              </TableFilterContent>
            </Wrapper>

            <Wrapper margin={`0 0 8px 0`} width={`100%`} jc={`flex-start`}>
              <Text width={`160px`} color={`#818792`} weight={`500`} className={`f14`}>
                주민등록번호
              </Text>

              <TableFilterContent cursor={`pointer`} width={`calc(50% - 98px)`}>
                <Input
                  value={centerInfo && `${centerInfo.account.first_id}`}
                  onChange={(e) => AccountInfo("first_id", e.target.value)}
                  width={`100%`}
                  type={`text`}
                  maxLength={6}
                  placeholder="970822"
                />
              </TableFilterContent>

              <Text jc={`center`} width={`36px`} color={`#818792`} weight={`700`} className={`f18`}>
                -
              </Text>

              <TableFilterContent width={`calc(50% - 98px)`} margin={`0`}>
                <InputSub
                  value={centerInfo && `${centerInfo.account.last_id}`}
                  onChange={(e) => AccountInfo("last_id", e.target.value)}
                  width={`100%`}
                  type="text"
                  maxLength={7}
                  placeholder="*******"
                />
              </TableFilterContent>
            </Wrapper>

            <Wrapper margin={`0 0 24px 0`} width={`100%`} jc={`flex-start`}>
              <Text width={`160px`} color={`#818792`} weight={`500`} className={`f14`}>
                사업자등록번호
              </Text>

              <TableFilterContent cursor={`pointer`} width={`calc(100% - 160px)`}>
                <Input
                  value={centerInfo && `${centerInfo.account.business_number}`}
                  onChange={(e) => AccountInfo("business_number", e.target.value)}
                  width={`100%`}
                  type={`text`}
                  maxLength={11}
                  placeholder="000-00-00000"
                />
              </TableFilterContent>
            </Wrapper>
          </Wrapper>

          <Wrapper width={`100%`} jc={`space-between`}>
            <Wrapper ai={`flex-start`} jc={`center`} width={`calc(100% / 3)`} wrap={`wrap`}>
              <Text width={`100%`} color={`#3C3E41`} weight={`500`} className={`f14`}>
                통장사본
              </Text>

              <Wrapper
                bgc={`#F5F5F5`}
                radius={`8px`}
                margin={`14px 0 10px 0`}
                width={`calc(100% - 10px)`}
                height={`calc( (1000px / 3) * (5 / 8) )`}
                onClick={() => {
                  if (centerInfo.bank_image.image) {
                    window.open(centerInfo.bank_image.image, "_blank");
                  }
                }}
              >
                <img
                  style={{
                    display: "inline-block",
                    width: "100%",
                    height: "100%",
                    borderRadius: "8px",
                  }}
                  src={centerInfo ? (centerInfo.bank_image.image ? centerInfo.bank_image.image : EmptyImage) : EmptyImage}
                />
              </Wrapper>

              <Label key={`bank_image`} htmlFor={`bank_image`} cursor={`pointer`} width={`calc(100% - 10px)`} jc="flex-end">
                <input
                  onChange={({ target: { files } }) => {
                    if (files.length) {
                      setImageFromFile({
                        file: files[0],
                        setImageUrl: ({ result }) => {
                          ImgChange(result, "bank_image", 0);
                        },
                      });
                    }
                  }}
                  id={`bank_image`}
                  accept="image/*"
                  type="file"
                />
                <Text lh={`unset`} color={`#3383FF`} weight={`500`} className={`f14`}>
                  이미지 등록하기
                </Text>
              </Label>
            </Wrapper>

            <Wrapper ai={`flex-start`} jc={`center`} width={`calc(100% / 3)`} wrap={`wrap`}>
              <Text width={`100%`} color={`#3C3E41`} weight={`500`} className={`f14`}>
                신분증
              </Text>

              <Wrapper
                bgc={`#F5F5F5`}
                radius={`8px`}
                margin={`14px 0 10px 0`}
                width={`calc(100% - 10px)`}
                height={`calc( (1000px / 3) * (5 / 8) )`}
                onClick={() => {
                  if (centerInfo.id_card_image.image) {
                    window.open(centerInfo.id_card_image.image, "_blank");
                  }
                }}
              >
                <img
                  style={{
                    display: "inline-block",
                    width: "100%",
                    height: "100%",
                    borderRadius: "8px",
                  }}
                  src={centerInfo ? (centerInfo.id_card_image.image ? centerInfo.id_card_image.image : EmptyImage) : EmptyImage}
                />
              </Wrapper>

              <Label cursor={`pointer`} key={`id_card_image`} htmlFor={`id_card_image`} width={`calc(100% - 10px)`} jc="flex-end">
                <input
                  onChange={({ target: { files } }) => {
                    if (files.length) {
                      setImageFromFile({
                        file: files[0],
                        setImageUrl: ({ result }) => {
                          ImgChange(result, "id_card_image", 0);
                        },
                      });
                    }
                  }}
                  id={`id_card_image`}
                  accept="image/*"
                  type="file"
                />
                <Text lh={`unset`} color={`#3383FF`} weight={`500`} className={`f14`}>
                  이미지 등록하기
                </Text>
              </Label>
            </Wrapper>

            <Wrapper ai={`flex-start`} jc={`center`} width={`calc(100% / 3)`} wrap={`wrap`}>
              <Text width={`100%`} color={`#3C3E41`} weight={`500`} className={`f14`}>
                사업자등록증
              </Text>

              <Wrapper
                bgc={`#F5F5F5`}
                radius={`8px`}
                margin={`14px 0 10px 0`}
                width={`calc(100% - 10px)`}
                height={`calc( (1000px / 3) * (5 / 8) )`}
                onClick={() => {
                  if (centerInfo.business_image.image) {
                    window.open(centerInfo.business_image.image, "_blank");
                  }
                }}
              >
                <img
                  style={{
                    display: "inline-block",
                    width: "100%",
                    height: "100%",
                    borderRadius: "8px",
                  }}
                  src={centerInfo ? (centerInfo.business_image.image ? centerInfo.business_image.image : EmptyImage) : EmptyImage}
                />
              </Wrapper>
              <Label key={`business_image`} cursor={`pointer`} htmlFor={`business_image`} width={`calc(100% - 10px)`} jc={`flex-end`}>
                <input
                  onChange={({ target: { files } }) => {
                    if (files.length) {
                      setImageFromFile({
                        file: files[0],
                        setImageUrl: ({ result }) => {
                          ImgChange(result, "business_image", 0);
                        },
                      });
                    }
                  }}
                  id={`business_image`}
                  accept="image/*"
                  type="file"
                />
                <Text lh={`unset`} color={`#3383FF`} weight={`500`} className={`f14`}>
                  이미지 등록하기
                </Text>
              </Label>
            </Wrapper>
          </Wrapper>
        </Wrapper>

      </Wrapper>
      <ImageCrop info={cropImage} setInfo={setCropImage} setImg={setCenterInfo} img={centerInfo} />
    </>
  );
};

export default CenterDetail;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  padding-top: ${({ pt }) => `calc(1rem * ${pt} / 14)`};
  padding-bottom: ${({ pb }) => `calc(1rem * ${pb} / 14)`};
  padding-left: ${({ pl }) => `calc(1rem * ${pl} / 14)`};
  padding-right: ${({ pr }) => `calc(1rem * ${pr} / 14)`};
  height: ${({ height }) => height}px;
`;

const SmallTitle = styled.div`
  font-weight: 500;
  font-size: 1rem;
  line-height: 142%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ status }) => (status == "empty" ? colors.Red02 : colors.Gr05)};
`;

const Input = styled.input`
  width: ${({ width }) => `${width}`};
  display: inline-block;
`;
const InputSub = styled.input`
  width: ${({ width }) => `${width}`};
  display: inline-block;
  -webkit-text-security: disc;
`;

const BigContainer = styled.div`
  padding: 36px;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  color: #3c3e41;
  margin-bottom: 16px;
`;

const SectionArrow = styled.img`
  width: calc(1rem * 18 / 14);
  height: calc(1rem * 18 / 14);
  transform: ${({ open }) => (open ? "rotate(-180deg)" : "")};
  transition: all 0.3s ease;
  cursor: pointer;
`;

const RestItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const RestTimeSection = styled.div`
  display: inline-flex;
  align-items: flex-end;
  position: relative;
  width: calc(100% - (1rem * 52 / 14));
`;

const Cal = styled.img`
  width: calc(1rem * 20 / 14);
  height: calc(1rem * 20 / 14);
  margin-left: 4px;
`;

const Button = styled.div`
  border: 1px solid ${({ color }) => color};
  color: ${({ color }) => color};
  padding: calc(1rem * 7 / 14) calc(1rem * 12 / 14);
  display: flex;
  background-color: #3383ff;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
`;

const Symbol = styled.img`
  width: calc(1rem * 22 / 14);
  width: calc(1rem * 22 / 14);
  margin-left: calc(1rem * 4 / 14);
`;

const BackgroundContent = styled.div`
  width: 100%;
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  position: relative;
`;

const TextContent = styled.div`
  width: ${({ width }) => width && `${width}`};
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
  margin-right:${({ mr }) => mr && `${mr}`};
  cursor: ${({ cursor }) => cursor && `${cursor}`};
`;

const TextContentT = styled.div`
  width: ${({ width }) => width && `${width}`};
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
  margin-right : 9px;
  margin-bottom: 9px;
  cursor: ${({ cursor }) => cursor && `${cursor}`};
`;

const TextView = styled.span`
  width: ${({ width }) => width && `${width}`};
  text-align: ${({ ta }) => ta && `${ta}`};
  color: ${({ color }) => color && `${color}`};
  font-weight: ${({ fw }) => fw && `${fw}`};
  font-size: ${({ fs }) => fs && `${fs}`};
  line-height: ${({ lh }) => lh && `${lh}`};
  display: inline-block;
  text-overflow: ${({ to }) => to && `${to}`};
  overflow: hidden;
  white-space: ${({ ws }) => ws && `${ws}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
`;

const InputView = styled.input`
  width: ${({ width }) => width && `${width}`};
  border: ${({ border }) => border && `${border}`};
  text-align: ${({ ta }) => ta && `${ta}`};
  color: ${({ color }) => color && `${color}`};
  font-weight: ${({ fw }) => fw && `${fw}`};
  font-size: ${({ fs }) => fs && `${fs}`};
  line-height: ${({ lh }) => lh && `${lh}`};
  border-radius: ${({ br }) => br && `${br}px`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  display: inline-block;
  overflow: hidden;
  padding: ${({ padding }) => padding && `${padding}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
  ::placeholder {
    color: ${({ pc }) => pc && `${pc};`};
  }
  ::-ms-input-placeholder {
    color: ${({ pc }) => pc && `${pc};`};
  }
`;

const Divider = styled.div`
  height: ${({ height }) => height && `${height}px`};
  background-color: ${({ color }) => color && `${color}`};
  width: 100%;
`;

const StartTime = styled.div``;

const EndTime = styled.div``;

const Wave = styled.img`
  width: 2rem;
  height: 2rem;
`;

const DateBox = styled.input`
  display: flex;
  align-items: center;
  padding: calc(1rem * 8 / 14) calc(1rem * 8 / 14);
  border: 1px solid #dcdcdc;
  font-weight: 500;
  line-height: 100% !important;
  height: calc(1rem * 28 / 14);
`;

const DateBox2 = styled.input`
  display: flex;
  align-items: center;
  padding: calc(1rem * 8 / 14) calc(1rem * 5 / 14);
  font-weight: 500;
  line-height: 100% !important;
  height: calc(1rem * 28 / 14);
`;

const Cancel = styled.div`
  color: ${({ color }) => color};
  font-weight: 500;
  cursor: pointer;
`;

const NewCon = styled.div`
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  padding: calc(1rem * 24 / 14) calc(1rem * 16 / 14);
  margin-bottom: 10px;
`;

const DateDiv = styled.div`
  display: flex;
  align-items: center;
  padding: calc(1rem * 8 / 14) calc(1rem * 8 / 14);
  border: 1px solid #dcdcdc;
  font-weight: 500;
  line-height: 100% !important;
  height: calc(1rem * 28 / 14);
  width: calc((100% - (2rem + 22px)) / 3);

  input {
    width: calc((100% - (1rem * 22 / 14)));
  }
`;

const ImageIcon100 = styled.div`
  width: ${({ width }) => width && `${width}`};
  height: ${({ height }) => height && `${height}`};
  display: inline-block;

  img {
    width: ${({ width }) => width && `${width}`};
    display: inline-block;
  }
`;

const ScheduleSection = ({ data, setData, newS, setNew, id, modal, deleteD, setDelete }) => {
  const [open, setOpen] = useState(true);

  const [open2, setOpen2] = useState(false);

  let SunTime = [];
  let MoonTime = [];

  let sunTimSplit = newS.start.split(":");
  let moonTimSplit = newS.end.split(":");
  let sunH = Number(sunTimSplit[0]);
  let sunM = Number(sunTimSplit[1]);
  let moonH = Number(moonTimSplit[0]);
  let moonM = Number(moonTimSplit[1]);
  let sunAM = sunH * 60 + sunM;
  let moonAM = moonH * 60 + moonM;

  let TimeCount = ((moonH - sunH) * 60 + (moonM - sunM)) / 30;

  for (var i = 0; i < TimeCount; i++) {
    let ScheduleTime = sunAM + 30 * i;

    let ScheduleHour = parseInt(ScheduleTime / 60);
    let ScheduleMinute = ScheduleTime % 60;

    let ScheduleHourText = ScheduleHour;
    let ScheduleMinuteText = ScheduleMinute;

    if (ScheduleHourText < 10) {
      ScheduleHourText = `0${ScheduleHourText}`;
    }

    if (ScheduleMinuteText == 0 || !ScheduleMinuteText) {
      ScheduleMinuteText = `00`;
    }

    let TimeData = {
      text: `${ScheduleHourText} : ${ScheduleMinuteText}`,
      value: `${ScheduleHourText}:${ScheduleMinuteText}`,
    };

    if (ScheduleHour < 12) {
      SunTime.push(TimeData);
    } else {
      MoonTime.push(TimeData);
    }
  }

  const Change = (type, value) => {
    setNew((prev) => ({ ...prev, [type]: value }));
  };

  const SettingYoil = (value) => {
    var copy = newS.day;

    if (copy.includes(value)) {
      copy = copy.filter((x) => x !== value);
    } else {
      copy.push(value);
    }

    setNew((prev) => ({ ...prev, day: copy }));
  };

  const targetLocationChangeHandler = (data) => {
    var locationSigungu = data.sigungu.split(" ");

    if (locationSigungu.length > 1) {
      var loGu = locationSigungu[1].length > 2 ? locationSigungu[1].slice(0, -1) : locationSigungu[1];
      var loSi = locationSigungu[0].length > 2 ? locationSigungu[0].slice(0, -1) : locationSigungu[0];
      var loDong = data.bname.length > 2 ? data.bname.slice(0, -1) : data.bname;
    } else {
      var loGu = data.sigungu.length > 2 ? data.sigungu.slice(0, -1) : data.sigungu;
      var loSi = data.sido;
      var loDong = data.bname.length > 2 ? data.bname.slice(0, -1) : data.bname;
    }

    setNew((prev) => ({
      ...prev,
      address: data.roadAddress,
      si: loSi === "강원특별자치도" ? "강원" : loSi,
      gu: loGu,
      dong: loDong,
    }));
  };

  const mapService = new MapService(targetLocationChangeHandler);

  const SearchAddress = () => {
    mapService.open();
  };

  const addPartnerSchedule = () => {
    var schedule_data = [...data];

    schedule_data[schedule_data.length] = newS;

    setData(schedule_data);

    setOpen2(false);
    setNew({
      id: "new",
      title: "",
      start: "",
      end: "",
      address: "",
      extra: "",
      si: "",
      gu: "",
      dong: "",
      day: [],
      time_list: [],
    });
  };

  const SettingStartTime = (value) => {
    var list = newS.time_list;
    if (list.includes(value)) {
      list = list.filter((x) => x != value);
    } else {
      list.push(value);
    }

    setNew((prev) => ({ ...prev, time_list: list }));
  };

  useEffect(() => {
    setOpen2(false);
  }, [modal]);

  return (
    <BigContainer>
      <TopSection className="f16">
        액티비티 스케줄
        <SectionArrow src={Arrow} open={open} onClick={() => setOpen((prev) => !prev)} />
      </TopSection>
      {open ? (
        open2 ? (
          <NewCon>
            <BackgroundContent jc={`space-between`} padding={`0`} ai={`center`}>
              <InputView
                onChange={(e) => Change("title", e.target.value)}
                value={`${newS.title}`}
                placeholder={`일정 이름을 작성해 주세요 (예 : [평일] 강남 일정)`}
                br={`4`}
                padding={`calc(1rem * 10 / 14) calc(1rem * 12 / 14)`}
                pc={`#ACAFB5`}
                color={`#3C3E41`}
                width={`80%`}
                border={`1px solid #DCDCDC`}
              />
              <SectionArrow src={Arrow} open={open} onClick={() => setOpen2((prev) => !prev)} />
            </BackgroundContent>

            <Divider height={`8`} color={"#ffffff"} />

            <BackgroundContent padding={`0`} ai={`flex-end`}>
              <StartTime>
                <div
                  style={{
                    color: "#3383FF",
                    fontWeight: "500",
                    marginBottom: "4px",
                  }}
                >
                  활동시작
                </div>
                <DateBox placeholder="활동 시작 시간 입력" value={newS.start} onChange={(e) => Change("start", e.target.value)} />
              </StartTime>

              <Wave src={wave} />

              <EndTime>
                <div
                  style={{
                    color: "#3383FF",
                    fontWeight: "500",
                    marginBottom: "4px",
                  }}
                >
                  활동종료
                </div>
                <DateBox placeholder="활동 종료 시간 입력" value={newS.end} onChange={(e) => Change("end", e.target.value)} />
              </EndTime>
            </BackgroundContent>

            <Divider height={`8`} color={"#ffffff"} />

            <BackgroundContent jc={`space-between`} padding={`0`} ai={`center`}>
              <TextContent
                cursor="pointer"
                onClick={() => SearchAddress()}
                jc={`center`}
                ai={`center`}
                width={`97px`}
                padding={`calc(1rem * 7.5/14) 0`}
                br={`8`}
                border={`1px solid #0E6DFF`}
              >
                <TextView color={`#0E6DFF`} fw={`600`}>{`주소찾기`}</TextView>
              </TextContent>

              <InputView
                value={newS.address}
                readOnly
                br={`4`}
                padding={`calc(1rem * 6/14) calc(1rem *18/14)`}
                pc={`#ACAFB5`}
                color={`#3C3E41`}
                width={`calc(100% - 112px)`}
                bgc={`#F9F9F9`}
                border={`1px solid #DCDCDC`}
              />
            </BackgroundContent>

            <Divider height={`8`} color={"#ffffff"} />

            <BackgroundContent jc={`space-between`} padding={`0`} ai={`center`}>
              <InputView
                value={newS.extra}
                maxLength={`100`}
                placeholder={`상세 주소를 입력해 주세요`}
                br={`4`}
                padding={`calc(1rem * 6/14) calc(1rem *18/14)`}
                pc={`#ACAFB5`}
                color={`#3C3E41`}
                width={`100%`}
                onChange={(e) => Change("extra", e.target.value)}
                border={`1px solid #DCDCDC`}
              />
            </BackgroundContent>

            <Divider height={`8`} color={"#ffffff"} />

            <BackgroundContent padding={`0`} ai={`center`}>
              <TextContent
                cursor="pointer"
                onClick={(e) => SettingYoil("일")}
                jc={`center`}
                width={`calc(1rem * 40/14)`}
                padding={`calc(1rem * 8/14) 0`}
                br={`8`}
                bgc={newS.day.includes("일") ? `#FF9187` : "#ffffff"}
                border={"1px solid #dcdcdc"}
                mr={"calc(1rem * 9 / 14)"}
              >
                <TextView color={newS.day.includes("일") ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`일`}</TextView>
              </TextContent>

              <TextContent
                cursor="pointer"
                onClick={(e) => SettingYoil("월")}
                jc={`center`}
                width={`calc(1rem * 40/14)`}
                padding={`calc(1rem * 8/14) 0`}
                br={`8`}
                border={"1px solid #dcdcdc"}
                bgc={newS.day.includes("월") ? `#0E6DFF` : "#ffffff"}
                mr={"calc(1rem * 9 / 14)"}
              >
                <TextView color={newS.day.includes("월") ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`월`}</TextView>
              </TextContent>

              <TextContent
                cursor="pointer"
                onClick={(e) => SettingYoil("화")}
                jc={`center`}
                width={`calc(1rem * 40/14)`}
                padding={`calc(1rem * 8/14) 0`}
                br={`8`}
                bgc={newS.day.includes("화") ? `#0E6DFF` : "#ffffff"}
                border={`1px solid  #DCDCDC`}
                mr={"calc(1rem * 9 / 14)"}
              >
                <TextView color={newS.day.includes("화") ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`화`}</TextView>
              </TextContent>

              <TextContent
                cursor="pointer"
                onClick={(e) => SettingYoil("수")}
                jc={`center`}
                width={`calc(1rem * 40/14)`}
                padding={`calc(1rem * 8/14) 0`}
                br={`8`}
                bgc={newS.day.includes("수") ? `#0E6DFF` : "#ffffff"}
                border={`1px solid  #DCDCDC`}
                mr={"calc(1rem * 9 / 14)"}
              >
                <TextView color={newS.day.includes("수") ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`수`}</TextView>
              </TextContent>

              <TextContent
                cursor="pointer"
                onClick={(e) => SettingYoil("목")}
                jc={`center`}
                width={`calc(1rem * 40/14)`}
                padding={`calc(1rem * 8/14) 0`}
                br={`8`}
                bgc={newS.day.includes("목") ? `#0E6DFF` : "#ffffff"}
                border={`1px solid  #DCDCDC`}
                mr={"calc(1rem * 9 / 14)"}
              >
                <TextView color={newS.day.includes("목") ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`목`}</TextView>
              </TextContent>

              <TextContent
                cursor="pointer"
                onClick={(e) => SettingYoil("금")}
                jc={`center`}
                width={`calc(1rem * 40/14)`}
                padding={`calc(1rem * 8/14) 0`}
                br={`8`}
                bgc={newS.day.includes("금") ? `#0E6DFF` : "#ffffff"}
                border={`1px solid  #DCDCDC`}
                mr={"calc(1rem * 9 / 14)"}
              >
                <TextView color={newS.day.includes("금") ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`금`}</TextView>
              </TextContent>

              <TextContent
                cursor="pointer"
                onClick={(e) => SettingYoil("토")}
                jc={`center`}
                width={`calc(1rem * 40/14)`}
                padding={`calc(1rem * 8/14) 0`}
                br={`8`}
                bgc={newS.day.includes("토") ? `#FF9187` : "#ffffff"}
                border={`1px solid  #DCDCDC`}
                mr={"calc(1rem * 9 / 14)"}
              >
                <TextView color={newS.day.includes("토") ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`토`}</TextView>
              </TextContent>

              <TextContent
                cursor="pointer"
                onClick={(e) => SettingYoil("공휴일")}
                jc={`center`}
                width={`calc(1rem * 40/7)`}
                padding={`calc(1rem * 8/14) 0`}
                br={`8`}
                bgc={newS.day.includes("공휴일") ? `#FF9187` : "#ffffff"}
                border={`1px solid  #DCDCDC`}
                mr={"calc(1rem * 9 / 14)"}
              >
                <TextView color={newS.day.includes("공휴일") ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`공휴일 포함`}</TextView>
              </TextContent>

              <TextContent
                cursor="pointer"
                onClick={(e) => SettingYoil("공휴일")}
                jc={`center`}
                width={`calc(1rem * 40/7)`}
                padding={`calc(1rem * 8/14) 0`}
                br={`8`}
                bgc={!newS.day.includes("공휴일") ? `#0E6DFF` : "#ffffff"}
                border={`1px solid  #DCDCDC`}
                // mr={"calc(1rem * 9 / 14)"}
              >
                <TextView color={!newS.day.includes("공휴일") ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`공휴일 제거`}</TextView>
              </TextContent>
            </BackgroundContent>

            <Divider height={`24`} color={"#ffffff"} />

            <BackgroundContent jc={`flex-start`} padding={`0`} ai={`center`}>
              <TextContent ai={`center`} width={`100%`}>
                <ImageIcon100 style={{ marginRight: "6px" }} width={`11px`} height={`11px`}>
                  <img src={GreySun} />
                </ImageIcon100>

                <TextView color={`#ACAFB5`} fw={`500`}>{`오전`}</TextView>
              </TextContent>
            </BackgroundContent>

            <BackgroundContent width={`100%`} wrap={`wrap`} jc={`flex-start`} padding={`0`} ai={`center`}>
              {SunTime.map((item, idx) => (
                <TextContentT
                  cursor="pointer"
                  index={(idx + 1) % 4}
                  key={`${idx}_sunTime`}
                  onClick={() => SettingStartTime(item.value)}
                  jc={`center`}
                  width={`calc(1rem * 72 / 14)`}
                  padding={`calc(1rem * 8/14) 0`}
                  br={`7`}
                  bgc={newS.time_list.includes(item.value) ? `#0E6DFF` : "#ffffff"}
                  border={`1px solid  #DCDCDC`}
                >
                  <TextView color={newS.time_list.includes(item.value) ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`${item.text}`}</TextView>
                </TextContentT>
              ))}
            </BackgroundContent>

            <BackgroundContent jc={`flex-start`} padding={`0`} ai={`center`}>
              <TextContent ai={`center`} width={`100%`}>
                <ImageIcon100 style={{ marginRight: "6px" }} width={`11px`} height={`11px`}>
                  <img src={GreyMoon} />
                </ImageIcon100>

                <TextView color={`#ACAFB5`} fw={`500`}>{`오후`}</TextView>
              </TextContent>
            </BackgroundContent>

            <Divider height={`10`} color={"#ffffff"} />

            <BackgroundContent width={`100%`} wrap={`wrap`} jc={`flex-start`} padding={`0`} ai={`center`}>
              {MoonTime.map((item, idx) => (
                <TextContentT
                  cursor="pointer"
                  // index={(idx + 1) % 4}
                  key={`${idx}_moonTime`}
                  onClick={() => SettingStartTime(item.value)}
                  jc={`center`}
                  width={`calc(1rem * 72 / 14)`}
                  padding={`calc(1rem * 8/14) 0`}
                  br={`7`}
                  bgc={newS.time_list.includes(item.value) ? `#0E6DFF` : "#ffffff"}
                  border={`1px solid  #DCDCDC`}
                >
                  <TextView color={newS.time_list.includes(item.value) ? `#ffffff` : `#ACAFB5`} fw={`500`}>{`${item.text}`}</TextView>
                </TextContentT>
              ))}
            </BackgroundContent>

            <BackgroundContent jc={`space-between`} padding={`0`} ai={`center`}>
              <Cancel
                color="#FF9187"
                onClick={() => {
                  setOpen2(false);
                  setNew({
                    id: "new",
                    title: "",
                    start: "",
                    end: "",
                    address: "",
                    extra: "",
                    day: [],
                    time_list: [],
                  });
                }}
              >
                취소
              </Cancel>
              <Button color="#ffffff" onClick={() => addPartnerSchedule()}>
                액티비티 스케줄 추가하기 <Symbol src={WhitePlus} />
              </Button>
            </BackgroundContent>
          </NewCon>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "24px",
            }}
          >
            <Button color="#ffffff" onClick={() => setOpen2(true)}>
              액티비티 스케줄 추가하기 <Symbol src={WhitePlus} />
            </Button>
          </div>
        )
      ) : (
        ""
      )}
      {open &&
        data?.map((el, i) => (
          <DesignedActivitySchedule
            data={el}
            key={el.id}
            idx={i}
            setData={setData}
            setDelete={setDelete}
            deleteD={deleteD}
            every={data}
            SearchAddress={SearchAddress}
          />
        ))}
    </BigContainer>
  );
};

const RestingSection = ({ rest, setRest }) => {
  const [open, setOpen] = useState(true);

  return (
    <BigContainer>
      <TopSection className="f16">
        휴무 정보
        <SectionArrow src={Arrow} open={open} onClick={() => setOpen((prev) => !prev)} />
      </TopSection>
      {open && rest?.length ? rest.map((el, i) => <SingleRest el={el} idx={i} every={rest} setRest={setRest} />) : ""}
    </BigContainer>
  );
};

const SingleRest = ({ el, every, idx, setRest }) => {
  const [calender, setCalender] = useState(false);

  const [data, setData] = useState({
    id: el.id,
    start: el.start,
    end: el.end,
    status: el.status,
    date: el.date,
    change: "yes",
  });

  const [date, setDate] = useState({
    y: el.date.split(".")[0],
    m: el.date.split(".")[1],
    d: el.date.split(".")[2],
  });

  useEffect(() => {}, []);

  const DateSelect = (d, month, next) => {
    if (month) {
      let Year = date.y;
      if (next) {
        var Mon = Number(date.m);
      } else {
        var Mon = Number(date.m) - 2;
      }

      let Day = Number(1);

      let NewDate = new Date(Year, Mon, Day);

      let NewYear = NewDate.getFullYear();
      let NewMonth = NewDate.getMonth() + 1;
      let NewDay = NewDate.getDate();

      if (NewMonth < 10) {
        NewMonth = `0${NewMonth}`;
      }

      if (NewDay < 10) {
        NewDay = `0${NewDay}`;
      }

      setDate({
        y: NewYear,
        m: NewMonth,
        d: NewDay,
      });
    } else {
      if (d < 10) {
        d = `0${d}`;
      }

      setDate({ ...date, d: d });
    }
  };

  const statusToggleHandler = (id, status) => {
    var token = localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", "rest_status");
    frm.append("token", token);
    frm.append("id", id);
    frm.append("status", status);

    axios
      .post("https://ai.semos.kr/semos_admin/partner/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        if (Value.status == "success") {
          if (status == "1") {
            alert(Value.status_message);
            setData((prev) => ({ ...prev, status: "0" }));
          } else {
            alert(Value.status_message);
            setData((prev) => ({ ...prev, status: "1" }));
          }
        } else {
          alert(Value.status_message);
        }
      });
  };

  useEffect(() => {
    setCalender(false);
  }, [date.d]);

  const ChangeCalendar = (type, value) => {
    var copy = [...every];
    var copyValue = copy[idx];

    copyValue[type] = value;
    copy[idx] = copyValue;

    setRest(copy);
  };

  return (
    <>
      <RestItem>
        <RestTimeSection>
          <DateDiv>
            <DateBox2 onChange={(e) => ChangeCalendar("date", e.target.value)} value={el.date} />
            <Cal src={BlackCalender} />
          </DateDiv>

          <div style={{ width: "12px" }} />

          <StartTime>
            <div
              style={{
                color: "#3383FF",
                fontWeight: "500",
                marginBottom: "4px",
              }}
            >
              휴무시작
            </div>
            <DateBox onChange={(e) => ChangeCalendar("start", e.target.value)} value={el.start} />
          </StartTime>

          <Wave src={wave} />

          <EndTime>
            <div
              style={{
                color: "#3383FF",
                fontWeight: "500",
                marginBottom: "4px",
              }}
            >
              휴무종료
            </div>
            <DateBox onChange={(e) => ChangeCalendar("end", e.target.value)} value={el.end} />
          </EndTime>
        </RestTimeSection>

        <ToggleButton toggleHandler={() => statusToggleHandler(el.id, el.status)} status={el.status} type="rest" />
      </RestItem>
      <div style={{ height: "calc(1rem * 24/14)" }} />
    </>
  );
};
