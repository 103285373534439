import styled from "styled-components";
import MainHeader from "../../../components/header";
import HorizonLayout from "../../../layouts/horizonLayout";
import MainLayout from "../../../layouts/mainLayout";
import NoticeDetailLayout from "../../../layouts/notice";

import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const Main = styled.main`
  width: 100%;
  padding-top: 20px;
`;

const NoticeDetailPage = () => {
  const saveHandler = () => {
    console.log("저장하기");
  };

  const { nid } = useParams();

  const [notice, setNotice] = useState();

      // FAQ 리스트 로드
      useEffect(() => {
    
        var token = window.localStorage.getItem('token')
  
        const frm = new FormData();
        frm.append("mode", 'notice_intro');
        frm.append("token", token);
        frm.append("number", nid);

          axios.post("https://ai.semos.kr/semos_admin/notice/data", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          }).then((data) => {
            var Value = data.data;
            var status = Value.status
            var statusMessage = Value.status_message
  
            if(status == 'success') {
  
              setNotice(Value.notice_list)

            } else if(status == 'waring') {
              alert(statusMessage)
            }
  
          });
  
    }, []);


  return (
    <MainLayout bottomButton={{ text: "저장하기", clickHandler: saveHandler }}>
      <MainHeader title="공지사항" />

      <HorizonLayout>
        <Main>
          <NoticeDetailLayout type="edit" setNotice={setNotice} data={notice} />
        </Main>
      </HorizonLayout>
    </MainLayout>
  );
};

export default NoticeDetailPage;
