import React, { useState, useRef } from "react";
import styled from "styled-components";

import {
  TableFilterContainer,
  TableFilterContent,
  TableWrapper,
  Wrapper,
  WrapperRow,
  AbsoluteWrapper,
  Icon,
  Text,
  TableContent,
  TableFooter,
  TableColumn,
  TableFooterPaging,
  WrapperColmun,
} from "../../layouts/layoutStorage";
import { curriculumData } from "./activityData";
import DropDown from "./dropDownforActivity";

import colors from "../../styles/constants/colors";

import AddIcon from "../../assets/icon/careerAddButton.png";

function CurriculumSection({ day, data, setData, length, index }) {
  const [curriculum, setCurriculum] = useState({
    content: "",
    time: "",
    title: "",
  });

  const { content, title } = curriculum;

  const ref = useRef();

  const onChangeCurriculum = (e) => {
    ref.current.style.height = "auto";
    ref.current.style.height = ref.current.scrollHeight + "px";
    const { value, name } = e.target;
    setCurriculum({
      ...curriculum,
      [name]: value,
    });
  };

  const addCurriculum = (date) => {
    if (curriculum.content.length > 0 && curriculum.title.length > 0 && curriculum.time.length > 0) {
      let copy = [...data.curriculum[date]];
      let obj = { ...data.curriculum };
      copy.push(curriculum);
      obj[date] = copy;
      setData((prev) => ({ ...prev, curriculum: obj }));
      setCurriculum({
        content: "",
        time: "",
        title: "",
      });
    }
  };

  return (
    <WrapperColmun>
      <Wrapper padding="calc(1rem * 16 / 14) 0 0 0" width="100%">
        <Wrapper width="100%" padding="8px 16px" border={`1px solid ${colors.Gr02}`}>
          <input
            style={{ width: "100%", color: `${colors.Gr05}` }}
            placeholder="커리큘럼 제목을 작성해 주세요"
            value={title}
            name="title"
            onChange={onChangeCurriculum}
          />
        </Wrapper>
        <Wrapper width="20rem" margin="0 0 0 8px">
          <DropDown
            z={length ? 600 - index - length : 600 - index}
            height="20rem"
            data={curriculum.time}
            setData={setCurriculum}
            option={curriculumData}
            no={false}
            type="object"
            keyName="time"
            defaultText="소요 시간"
          />
        </Wrapper>
      </Wrapper>
      <Wrapper margin="8px 0 0 0">
        <Wrapper width="100%" margin="0 8px 0 0" padding="8px 16px" border={`1px solid ${colors.Gr02}`}>
          <Textarea ref={ref} rows="1" placeholder="커리큘럼을 간단하게 소개해 주세요" value={content} name="content" onChange={onChangeCurriculum} />
        </Wrapper>
        <Icon
          width={`ccalc(1rem * (17 / 14) + 16px)`}
          height={`calc(1rem * (17 / 14) + 16px)`}
          cursor={`pointer`}
          src={AddIcon}
          onClick={() => addCurriculum(day)}
        />
      </Wrapper>
    </WrapperColmun>
  );
}

export default CurriculumSection;

const Textarea = styled.textarea`
  outline: none;
  border: none;
  resize: none;
  width: 100%;
  color: ${colors.Gr05};
`;
