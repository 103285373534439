import styled from "styled-components";
import calendarIcon from "../assets/icon/calendar.svg";
import base from "../styles/constants/base";
import colors from "../styles/constants/colors";
import flexbox from "../styles/func/flexbox";

const INPUT_PADDING = 20;

const TextBox = styled.div`
  position: relative;
  width: ${({ width }) => width};

  input {
    width: 100%;
    margin: ${({ margin }) => (margin ? "0 20px" : null)};
    padding-left: ${({ padding }) => (padding ? "40px" : null)};
    border: ${({ border }) => (border ? `${base.border}` : "none !important")};
    ${({ paddingLeft }) => (paddingLeft ? `padding-left : ${paddingLeft}` : "")};

    ${({ readonly }) =>
      readonly &&
      ` &:focus {
     border: 2px solid transparent;
   }`}

    &:focus {
      border: 2px solid ${colors.blue};
    }
  }
`;

const CalendarBox = styled.div`
  width: ${({ width }) => width};

  ${flexbox()}

  div {
    width: 50%;
    position: relative;

    input {
      width: 100%;
      padding-right: 0px;
      border: ${({ border }) =>
        border ? `${base.border}` : "none !important"};
      ${({ paddingLeft }) => (paddingLeft ? `padding-left : ${paddingLeft}` : "")};

      &::-webkit-calendar-picker-indicator {
        background: transparent;
        z-index: 1;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
      }

      &:focus {
        border: ${({ border }) =>
          border ? `2px solid ${colors.blue}` : "none !important"};
      }
    }

    img {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background-color: ${colors.white};
      cursor: pointer;
      pointer-events: none;
    }
  }
`;

const Input = ({
  border = true,
  istransparent = "false",
  type,
  placeholder,
  name,
  value,
  changeHandler,
  keyPressHandler,
  width = "100%",
  length = "300",
  min,
  max,
  children,
  margin = false,
  padding = false,
  paddingLeft,
  readonly = false,
  cursor = true,
}) => {
  // 아이콘의 유무

  if (type === "text") {
    return (
      keyPressHandler ? 
      <TextBox
        width={width}
        margin={margin}
        padding={padding}
        readonly={readonly}
        border={border}
        paddingLeft={paddingLeft}
      >
        <input
          type="text"
          placeholder={placeholder}
          maxLength={length}
          name={name}
          value={value}
          onChange={changeHandler}
          istransparent={istransparent}
          readOnly={readonly}
          onKeyPress={keyPressHandler ? (e) => keyPressHandler(e.key) : ""}
        />

        {children}
      </TextBox> : 
        <TextBox
          width={width}
          margin={margin}
          padding={padding}
          readonly={readonly}
          border={border}
          paddingLeft={paddingLeft}
        >
          <input
            type="text"
            placeholder={placeholder}
            name={name}
            value={value}
            maxLength={length}
            onChange={changeHandler}
            istransparent={istransparent}
            readOnly={readonly}
          />

          {children}
        </TextBox> 
    );
  }

  if (type === "calendar") {
    return (
      <CalendarBox width={width} margin={margin} padding={padding} paddingLeft={paddingLeft}>
        <div>
          <input
            type="date"
            min={min}
            max={max}
            name="start-date"
            value={value["start-date"]}
            onChange={changeHandler}
            istransparent={istransparent}
          />
          <img src={calendarIcon} />
        </div>

        <div>
          <input
            type="date"
            min={min}
            max={max}
            name="finish-date"
            value={value["finish-date"]}
            onChange={changeHandler}
            istransparent={istransparent}
          />

          <img src={calendarIcon} />
        </div>
      </CalendarBox>
    );
  }
};

export default Input;
