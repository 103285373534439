export const ageGroupOptionList = [
  {
    text: "20대(20 ~ 29)",
    value: "20 ~ 29",
  },
  {
    text: "30대(30 ~ 39)",
    value: "30 ~ 39",
  },
  {
    text: "40대(40 ~ 49)",
    value: "40 ~ 49",
  },
  {
    text: "50대(50 ~ 59)",
    value: "50 ~ 59",
  },
  {
    text: "60대 이상(60 ~)",
    value: "60 ~ 69",
  },
];
