import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

// component
import { Text, Wrapper } from "../../layouts/layoutStorage";
import ImageCrop from "../../components/designedComponents/imageCrop";
import ActivityNormalInfo from "../../components/designedPBActivity/PBActivityNormalInfo";
import ActivityPriceInfo from "../../components/designedPBActivity/PBActivityPriceInfo";
import ActivityIntro from "../../components/designedPBActivity/PBActivityIntro";
import ActivityKeyword from "../../components/designedPBActivity/PBActivityKeyword";
import colors from "../../styles/constants/colors";
import LoadingSpinner from "../../components/loadingSpinner";

// icon

const PBActivityDetail = ({ open }) => {
  const { n } = useParams();

  const navigate = useNavigate();

  //이미지 크롭 함수
  const [cropImage, setCropImage] = useState({
    width: 8,
    height: 5,
    type: "",
    number: "",
    image: "",
    status: false,
    scale: true,
  });

  const [loading, setLoading] = useState(false);

  const setImageFromFile = ({ file, setImageUrl }) => {
    let reader = new FileReader();
    reader.onload = function () {
      setImageUrl({ result: reader.result });
    };

    reader.readAsDataURL(file);
  };

  const ImgChange = (value, type, number) => {
    setCropImage({
      width: 8,
      height: 5,
      type: type,
      number: number,
      image: value,
      status: true,
      scale: true,
    });
  };

  useEffect(() => {
    if (n == "new") {
      setInfo({
        activity_id: "",
        updated: "",
        name: "",
        partner: [],
        start_date: "",
        end_date: "",
        status: "",
        background: [],
        sport: [],
        place: [],
        facility_list: {
          parking: 0,
          cafeteria: 0,
          shower_room: 0,
          fitting_room: 0,
        },
        duration: "원데이",
        lesson_day: "1",
        type: "",
        location_type: "",
        curriculum: {
          day1: [],
        },
        initial_capacity: "",
        confirm_capacity: "",
        max_capacity: "",
        min_age: "",
        price: "",
        recommend: [],
        qualified: [],
        main_image: "",
        prepare: [],
        include: [],
        exclude: [],
        faq: [],
        caution: [],
        exposed: [],
        search: [],
      });
    } else {
      getData();
    }
  }, []);

  const [info, setInfo] = useState([]);

  const [recommendSearch, setRecommendSearch] = useState([]);
  const [requireSearch, setRequireSearch] = useState([]);
  const [bringSearch, setBringSearch] = useState([]);
  const [includeSearch, setIncludeSearch] = useState([]);
  const [excludeSearch, setExcludeSearch] = useState([]);
  const [faqSearch, setFaqSearch] = useState([]);
  const [noticeSearch, setNoticeSearch] = useState([]);
  const [tagSearch, setTagSearch] = useState([]);
  const [keywordSearch, setKeywordSearch] = useState([]);

  const [deleteD, setDeleteD] = useState({
    place: [],
    background: [],
    caution: [],
    activity_location: [],
    qualified: [],
    curriculum: [],
    faq: [],
    exclude: [],
    include: [],
    exposed: [],
    recommend: [],
    search: [],
    sport: [],
    prepare: [],
    partner: [],
  });

  const BigDataSearch = (category, value) => {
    var token = localStorage.getItem("token");

    const frm = new FormData();

    frm.append("mode", "register_recommend");
    frm.append("token", token);
    frm.append("category", category);
    frm.append("text", value);

    axios
      .post("https://ai.semos.kr/semos_partner/coaching/activity", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;
        var RegisterText = Value.text_list;

        if (Status == "success") {
          if (category == "recommend") {
            setRecommendSearch(RegisterText);
          } else if (category == "qualified") {
            setRequireSearch(RegisterText);
          } else if (category == "prepare") {
            setBringSearch(RegisterText);
          } else if (category == "include") {
            setIncludeSearch(RegisterText);
          } else if (category == "exclude") {
            setExcludeSearch(RegisterText);
          } else if (category == "faq") {
            setFaqSearch(RegisterText);
          } else if (category == "caution") {
            setNoticeSearch(RegisterText);
          } else if (category == "search") {
            setTagSearch(RegisterText);
          } else if (category == "exposed") {
            setKeywordSearch(RegisterText);
          }
        } else {
          window.alert(StatusMessage);
        }
      });
  };

  const getData = () => {
    setLoading(true);
    var token = localStorage.getItem("token");

    const frm = new FormData();

    frm.append("mode", "pb_activity_detail");
    frm.append("token", token);
    frm.append("id", n);

    axios
      .post("https://ai.semos.kr/semos_admin/activity/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        setInfo(Value.activity_data);

        setLoading(false);
      });
  };

  const saveData = () => {
    setLoading(true);
    var token = localStorage.getItem("token");

    var copy = info.background;

    for (let i = 0; i < copy.length; i++) {
      if (typeof copy[i] == "string") {
        var copy2 = copy[i];
        copy2 = { id: "new", image: copy[i] };

        copy[i] = copy2;

        setInfo((prev) => ({ ...prev, background: copy }));
      }
    }

    if (info.activity_id) {
      axios
        .post(
          "https://ai.semos.kr/semos_admin/activity/data",
          {
            mode: "pb_activity_revise",
            token: token,
            data: info,
            delete: deleteD,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((data) => {
          var Value = data.data;
          if (Value.status == "success") {
            alert(Value.status_message);
            if (n === "new") {
              navigate(`/pb_activity/1/all/all`);
            } else {
              window.location.reload();
            }
            setLoading(false);
          } else {
            alert(Value.status_message);
          }
        });
    } else {
      axios
        .post(
          "https://ai.semos.kr/semos_admin/activity/data",
          {
            mode: "pb_activity_register",
            token: token,
            data: info,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((data) => {
          var Value = data.data;
          var ActivityId = Value.activity_id;

          if (Value.status == "success") {
            alert(Value.status_message);
            navigate(`/pb_activity/1/all/all`);
            window.location.reload();
            setLoading(false);
          } else {
            alert(Value.status_message);
          }
        });
    }
  };

  let NewDate = new Date();
  let NewYear = NewDate.getFullYear();
  let NewMonth = NewDate.getMonth() + 1;
  let NewDay = NewDate.getDate();
  if (NewMonth < 10) {
    NewMonth = `0${NewMonth}`;
  }

  if (NewDay < 10) {
    NewDay = `0${NewDay}`;
  }

  const [startDate, setStartDate] = useState({
    date_y: NewYear,
    date_m: NewMonth,
    date_d: NewDay,
  });
  const [endDate, setEndDate] = useState({
    date_y: NewYear,
    date_m: NewMonth,
    date_d: NewDay,
  });

  useEffect(() => {
    if (window.location.pathname.includes("new")) {
      var obj = {};
      for (let i = 1; i < Number(info.lesson_day) + 1; i++) {
        const eachDay = `day${i}`;
        obj[eachDay] = [];
      }
      setInfo((prev) => ({ ...prev, curriculum: obj }));
    } else {
      var token = localStorage.getItem("token");
      const frm = new FormData();
      frm.append("mode", "pb_activity_detail");
      frm.append("token", token);
      frm.append("id", n);
      axios
        .post("https://ai.semos.kr/semos_admin/activity/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;

          setStartDate({
            date_y: Value.activity_data.start_date
              ? Value.activity_data.start_date.split(".")[0]
              : NewYear,
            date_m: Value.activity_data.start_date
              ? Value.activity_data.start_date.split(".")[1]
              : NewMonth,
            date_d: Value.activity_data.start_date
              ? Value.activity_data.start_date.split(".")[2]
              : NewDay,
          });
          setEndDate({
            date_y: Value.activity_data.end_date
              ? Value.activity_data.end_date.split(".")[0]
              : NewYear,
            date_m: Value.activity_data.end_date
              ? Value.activity_data.end_date.split(".")[1]
              : NewMonth,
            date_d: Value.activity_data.end_date
              ? Value.activity_data.end_date.split(".")[2]
              : NewDay,
          });
          var newObj = {};
          const day = Value.activity_data.lesson_day
            ? Value.activity_data.lesson_day
            : 1;
          for (let i = 1; i < Number(day) + 1; i++) {
            const eachDay = `day${i}`;
            if (Value.activity_data.curriculum[eachDay]) {
              newObj[eachDay] = Value.activity_data.curriculum[eachDay];
            } else {
              newObj[eachDay] = [];
            }
          }
          setInfo((prev) => ({ ...prev, curriculum: newObj }));
        });
    }
  }, [info.lesson_day, info.duration]);

  return (
    <>
      <Wrapper
        bgc="#ffffff"
        of={`hidden auto`}
        margin="10px auto 0 auto"
        display={`inline-block`}
        width={`100%`}
        mw={`1000px`}
        mh={`calc(100vh - 72px)`}
        padding={`10px 0`}
      >
        <Titlesection>
          <Wrapper width="100%" jc="space-between" ai="flex-start">
            <Wrapper width="80%">
              <MainTitle className="f24">액티비티 정보</MainTitle>
              <SmallText className="f14">
                {window.location.pathname.includes("new")
                  ? ""
                  : `최근 수정일 : ${info.updated}`}
              </SmallText>
            </Wrapper>
            <SaveButton
              width="calc(1rem * 124 / 14)"
              className="f16"
              onClick={() => saveData()}
            >
              저장
            </SaveButton>
          </Wrapper>
        </Titlesection>

        <ActivityNormalInfo
          open={open}
          data={info}
          setData={setInfo}
          deleteD={deleteD}
          setDelete={setDeleteD}
          setCropImage={setCropImage}
          cropImage={cropImage}
          setImageFromFile={setImageFromFile}
          ImgChange={ImgChange}
          endDate={endDate}
          startDate={startDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
        />

        <ActivityPriceInfo
          open={open}
          data={info}
          setData={setInfo}
          deleteD={deleteD}
          setDelete={setDeleteD}
        />

        <ActivityIntro
          open={open}
          data={info}
          setData={setInfo}
          deleteD={deleteD}
          setDelete={setDeleteD}
          bigData={BigDataSearch}
          textRecommend={{
            recommend: recommendSearch,
            qualified: requireSearch,
            prepare: bringSearch,
            include: includeSearch,
            exclude: excludeSearch,
            faq: faqSearch,
            caution: noticeSearch,
          }}
        />

        <ActivityKeyword
          open={open}
          data={info}
          setData={setInfo}
          deleteD={deleteD}
          setDelete={setDeleteD}
          bigData={BigDataSearch}
          textRecommend={{
            search: tagSearch,
            exposed: keywordSearch,
          }}
        />
        <Wrapper padding="calc(1rem * 36 / 14)" width="100%">
          <SaveButton className="f16" onClick={() => saveData()}>
            저장
          </SaveButton>
        </Wrapper>
      </Wrapper>
      <ImageCrop
        info={cropImage}
        setInfo={setCropImage}
        setImg={setInfo}
        img={info}
      />
      {loading && <LoadingSpinner show={loading} />}
    </>
  );
  //   }
};

export default PBActivityDetail;

const Container = styled.div`
  padding-bottom: calc(1rem * 52 / 14);
`;

const Titlesection = styled.div`
  padding: calc(1rem * 36 / 14);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MainTitle = styled.span`
  white-space: pre-wrap;
  word-break: break-all;
  font-weight: 700;
  color: ${colors.Gr05};
`;

const SmallText = styled.span`
  font-weight: 500;
  color: ${colors.Gr04};
  margin-left: 8px;
`;

const SaveButton = styled.div`
  padding: 1rem;
  width: ${({ width }) => (width ? width : "100%")};
  background-color: ${colors.Bl07};
  color: ${colors.White};
  font-weight: 700;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
`;
