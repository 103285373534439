import axios from "axios";
import { useEffect, useState } from "react";

import useData from "../../../hooks/data/useData";
import useDate, {
  getCurrentMonth,
  getCurrentYear,
} from "../../../hooks/useDate";

import BarChart from "../../../chart/data/bar";
import LineCharts from "../../../chart/data/lines";
import DougnutChart from "../../../chart/doughnut";

import TitleBox from "../../../components/dashboard/titleBox";
import DateInput from "../../../modules/date/input";

import DashboardLayout from "../../../layouts/dashboard";

import QuestionMark from "../../../assets/icon/questionMark.svg";

import Select from "../../../modules/select";
import {
  Container,
  AUSection,
  TransitionSection,
  PathLocationSection,
  WAUBox,
  MAUBox,
  TransitionBox,
  PathBox,
  LocationBox,
  FlexWrapper,
  WAUWrapper,
  UserWrapper,
  DoughnutWrapper,
  TransitionWrapper,
  ColorContainer,
  QuestionContainer,
} from "./style";

const getTextFilter = (filter) => {
  switch (filter.filter) {
    case "m":
      return "month";

    case "w":
      return "week";

    case "d":
      return "day";
    default:
  }
};

// const transformDate = (fullDate) => {
//   const [year, month, day] = fullDate.split("-");

//   return `${year.slice(2, 4)}-${month}-${day}`;
// };

const ConnectTab = () => {
  const today = new Date();

  const [status, setStatus] = useState({
    wau: false,
    mau: false,
    connect: false,
    history: false,
    location: false,
    user: false,
  });

  const AppIstallData = {
    IOS: [
      "50",
      "29",
      "33",
      "22",
      "25",
      "17",
      "23",
      "53",
      "24",
      "26",
      "46",
      "1757",
    ],
    ANDROID: [
      "26",
      "44",
      "16",
      "6",
      "10",
      "7",
      "16",
      "30",
      "10",
      "6",
      "26",
      "1610",
    ],
  };

  const [connectData, setConnectData] = useState([]);

  const [valueList, setValueList] = useState({});

  const [labelsList, setLabelsList] = useState({});

  const [appType, setAppType] = useState("IOS");

  const { getFilter, getDataByCategory, transformDatetime } = useData(
    setValueList,
    setLabelsList
  );

  const { value: wauFilter, changeHandler: wauFilterChangeHandler } = useDate({
    wau: getCurrentMonth(),
  });

  const { value: mauFilter, changeHandler: mauFilterChangeHandler } = useDate({
    mau: getCurrentYear(),
  });

  const mauOptionList = Array.from(
    { length: today.getFullYear() - 2021 + 1 },
    (_, i) => {
      return 2021 + i;
    }
  ).map((item) => ({
    text: item,
    value: item,
  }));

  const { value: userFilter, changeHandler: userChangeHandler } = useDate({
    filter: "m",
    date: transformDatetime("m"),
  });

  const { value: reconnectFilter, changeHandler: reconnectChangeHandler } =
    useDate({
      filter: "m",
      date: transformDatetime("m"),
    });

  const { value: transitionFilter, changeHandler: transitionChangeHandler } =
    useDate({
      filter: "m",
      date: transformDatetime("m"),
    });

  const { value: pathFilter, changeHandler: pathChangeHandler } = useDate({
    filter: "m",
    date: transformDatetime("m"),
  });

  const { value: locationFilter, changeHandler: locationChangeHandler } =
    useDate({
      filter: "m",
      date: transformDatetime("m"),
    });

  useEffect(() => {
    const frm = new FormData();

    frm.append("mode", "Load");
    frm.append("page", "connect");

    axios
      .post("https://ai.semos.kr/semos_admin/board/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        const {
          WAU_DATA,
          MAU_DATA,
          CONNECT_DATA,
          HISTORY_DATA,
          LOCATION_DATA,
          USER_DATA,
          RECONNECT_DATA,
          MEMBER_COUNT,
        } = res.data;

        setConnectData(res.data);

        setValueList({
          wau: WAU_DATA.map((item) => item.value),
          mau: MAU_DATA.map((item) => item.value),
          connect: CONNECT_DATA.map((item) => item.value),
          reconnect: RECONNECT_DATA.map((item) => item.value),
          user: USER_DATA.map((item) => item.value),
          history: HISTORY_DATA.map((item) => item.value),
          location: LOCATION_DATA.map((item) => item.value),

          member_count: MEMBER_COUNT,
        });

        setLabelsList({
          mau: MAU_DATA.map((item) => item.date.substr(2).replace(/-/gi, "/")),
          connect: CONNECT_DATA.map((item) =>
            item.date.substr(2).replace(/-/gi, "/")
          ),
          reconnect: RECONNECT_DATA.map((item) =>
            item.date.substr(2).replace(/-/gi, "/")
          ),
          user: USER_DATA.map((item) =>
            item.date.substr(2).replace(/-/gi, "/")
          ),
          history: HISTORY_DATA.map((item) => item.type),
          location: LOCATION_DATA.map((item) => item.type),
        });
      });
  }, []);

  useEffect(() => {
    if (status.wau) {
      const frm = new FormData();

      frm.append("mode", "Update");
      frm.append("page", "connect");
      frm.append("date", `${wauFilter.wau}-01`);
      frm.append("type", "wau");

      axios
        .post("https://ai.semos.kr/semos_admin/board/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          const wau = res.data.map((item) => item.value);
          setValueList((prev) => ({ ...prev, wau }));
        });
    }
  }, [wauFilter]);

  useEffect(() => {
    if (status.mau) {
      const frm = new FormData();

      frm.append("mode", "Update");
      frm.append("page", "connect");
      frm.append("date", `${mauFilter.mau}-12-31`);
      frm.append("type", "mau");

      axios
        .post("https://ai.semos.kr/semos_admin/board/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          const mau = res.data.map((item) => item.value);
          const mauLabels = res.data.map((item) => item.date);

          setValueList((prev) => ({ ...prev, mau }));
          setLabelsList((prev) => ({ ...prev, mau: mauLabels }));
        });
    }
  }, [mauFilter]);

  useEffect(() => {
    if (status.connect) {
      getDataByCategory(
        "connect",
        "connect",
        getFilter({
          type: transitionFilter.filter,
          date: transitionFilter.date,
        })
      );
    }
  }, [transitionFilter]);

  useEffect(() => {
    if (status.reconnect) {
      getDataByCategory(
        "connect",
        "reconnect",
        getFilter({
          type: transitionFilter.filter,
          date: transitionFilter.date,
        })
      );
    }
  }, [transitionFilter]);

  useEffect(() => {
    if (status.user) {
      getDataByCategory(
        "connect",
        "user",
        getFilter({
          type: userFilter.filter,
          date: userFilter.date,
        })
      );
    }
  }, [userFilter]);

  useEffect(() => {
    if (status.history) {
      getDataByCategory(
        "connect",
        "history",
        getFilter({ type: pathFilter.filter, date: pathFilter.date })
      );
    }
  }, [pathFilter]);

  useEffect(() => {
    if (status.location) {
      const frm = new FormData();

      frm.append("mode", "Update");
      frm.append("page", "connect");
      frm.append("date", locationFilter.date);
      frm.append("date_type", getTextFilter(locationFilter));
      frm.append("type", "location");

      axios
        .post("https://ai.semos.kr/semos_admin/board/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          setValueList((prev) => ({
            ...prev,
            location: res.data.map((item) => item.value),
          }));

          setLabelsList((prev) => ({
            ...prev,
            location: res.data.map((item) => item.type),
          }));
        });
    }
  }, [locationFilter]);

  return (
    <DashboardLayout>
      <Container>
        <AUSection>
          <MAUBox>
            <TitleBox title="MAU" unit={{ text: "단위", value: "명" }}>
              <DateInput
                name="mau"
                changeHandler={(e) => {
                  setStatus((prev) => ({ ...prev, mau: true }));
                  mauFilterChangeHandler(e);
                }}
                type="year"
                width="120px"
                value={mauFilter.mau}
                optionList={mauOptionList}
              />
            </TitleBox>

            {valueList.mau?.length > 0 && (
              <BarChart
                list={valueList.mau}
                labels={labelsList.mau}
                style={{ width: "100%", height: "calc(70% - 50px)" }}
              />
            )}
          </MAUBox>

          <WAUBox>
            <TitleBox title="WAU" unit={{ text: "단위", value: "명" }}>
              <DateInput
                width="170px"
                name="wau"
                min="2021-05"
                max={`${today.getFullYear()}-${
                  today.getMonth() + 1 < 10
                    ? `0${today.getMonth() + 1}`
                    : today.getMonth() + 1
                }`}
                value={wauFilter.wau}
                changeHandler={(e) => {
                  setStatus((prev) => ({ ...prev, wau: true }));
                  wauFilterChangeHandler(e);
                }}
              />
            </TitleBox>

            <WAUWrapper>
              {valueList.wau?.length > 0 && (
                <BarChart
                  list={valueList.wau}
                  labels={["1주차", "2주차", "3주차", "4주차", "5주차"]}
                />
              )}
            </WAUWrapper>
          </WAUBox>
        </AUSection>

        <TransitionSection>
          <TransitionBox>
            <TitleBox title="접속" unit={{ text: "단위", value: "회" }}>
              <QuestionContainer left="115px">
                <img src={QuestionMark} />

                <div>
                  <span className="noticeBubble font_13">
                    그래프 값에 마우스 오버하여 재접속률을 확인하세요.
                  </span>
                </div>
              </QuestionContainer>

              <ColorContainer>
                <div className="colorDiv">
                  <span
                    style={{ backgroundColor: "rgba(0,149,255,1)" }}
                    className="colorRound"
                  />
                  <span className="colorText">총 접속</span>
                </div>

                <div className="colorDiv">
                  <span
                    style={{ backgroundColor: "rgba(255,51,51,1)" }}
                    className="colorRound"
                  />
                  <span className="colorText">재접속</span>
                </div>
              </ColorContainer>

              <FlexWrapper>
                {(transitionFilter.filter === "d" ||
                  transitionFilter.filter === "w") && (
                  <DateInput
                    width="170px"
                    min="2021-05-01"
                    max={`${today.getFullYear()}-${
                      today.getMonth() + 1 < 10
                        ? `0${today.getMonth() + 1}`
                        : today.getMonth() + 1
                    }-${
                      today.getDate() < 10
                        ? `0${today.getDate()}`
                        : today.getDate()
                    }`}
                    type="date"
                    name="date"
                    value={
                      transitionFilter.date.length < 8
                        ? transformDatetime("d")
                        : transitionFilter.date
                    }
                    changeHandler={(e) => {
                      setStatus((prev) => ({
                        ...prev,
                        connect: true,
                        reconnect: true,
                      }));
                      transitionChangeHandler(e);
                    }}
                  />
                )}

                {transitionFilter.filter === "m" && (
                  <DateInput
                    width="170px"
                    type="month"
                    name="date"
                    min="2021-05"
                    max={`${today.getFullYear()}-${
                      today.getMonth() + 1 < 10
                        ? `0${today.getMonth() + 1}`
                        : today.getMonth() + 1
                    }`}
                    value={transitionFilter.date}
                    changeHandler={(e) => {
                      setStatus((prev) => ({
                        ...prev,
                        connect: true,
                        reconnect: true,
                      }));
                      transitionChangeHandler(e);
                    }}
                  />
                )}

                <Select
                  border={false}
                  isTransparent={true}
                  width="110px"
                  name="filter"
                  value={transitionFilter.filter}
                  changeHandler={(e) => {
                    setStatus((prev) => ({
                      ...prev,
                      connect: true,
                      reconnect: true,
                    }));
                    transitionChangeHandler(e);
                  }}
                  optionList={[
                    {
                      text: "일간",
                      value: "d",
                    },
                    {
                      text: "주간",
                      value: "w",
                    },
                    {
                      text: "월간",
                      value: "m",
                    },
                  ]}
                />
              </FlexWrapper>
            </TitleBox>

            <TransitionWrapper>
              {valueList.connect?.length > 0 && (
                <LineCharts
                  type="transition"
                  names={["총 접속", "재접속"]}
                  list={valueList.connect}
                  list2={valueList.reconnect}
                  labels={labelsList.connect}
                />
              )}
            </TransitionWrapper>
          </TransitionBox>
        </TransitionSection>

        <AUSection>
          <PathBox>
            <TitleBox title={"앱설치"} unit={{ text: "단위", value: "회" }}>
              <FlexWrapper>
                {/*{(userFilter.filter === "d" ||
                userFilter.filter === "w") && (
                <DateInput
                  width="170px"
                  min="2021-05-01"
                  max={`${today.getFullYear()}-${
                    today.getMonth() + 1 < 10
                      ? `0${today.getMonth() + 1}`
                      : today.getMonth() + 1
                  }-${
                    today.getDate() < 10
                      ? `0${today.getDate()}`
                      : today.getDate()
                  }`}
                  type="date"
                  name="date"
                  value={
                    userFilter.date.length < 8
                      ? transformDatetime("d")
                      : userFilter.date
                  }
                  changeHandler={(e) => {
                    setStatus((prev) => ({
                      ...prev,
                      user: true,
                    }));
                    userChangeHandler(e);
                  }}
                />
              )}

              {userFilter.filter === "m" && (
                <DateInput
                  width="170px"
                  type="month"
                  name="date"
                  min="2021-05"
                  max={`${today.getFullYear()}-${
                    today.getMonth() + 1 < 10
                      ? `0${today.getMonth() + 1}`
                      : today.getMonth() + 1
                  }`}
                  value={userFilter.date}
                  changeHandler={(e) => {
                    setStatus((prev) => ({
                      ...prev,
                      user: true,
                    }));
                    userChangeHandler(e);
                  }}
                />
              )}*/}

                <Select
                  border={false}
                  isTransparent={true}
                  width="145px"
                  name="filter"
                  value={appType}
                  changeHandler={(e) => setAppType(e.target.value)}
                  optionList={[
                    {
                      text: "IOS",
                      value: "IOS",
                    },
                    {
                      text: "ANDROID",
                      value: "ANDROID",
                    },
                  ]}
                />
              </FlexWrapper>
            </TitleBox>

            <UserWrapper>
              <BarChart
                list={AppIstallData[appType]}
                labels={[
                  "21/09",
                  "21/10",
                  "21/11",
                  "21/12",
                  "22/01",
                  "22/02",
                  "22/03",
                  "22/04",
                  "22/05",
                  "22/06",
                  "22/07",
                  "22/08",
                ]}
              />
            </UserWrapper>
          </PathBox>

          <PathBox style={{ marginRight: "0", marginLeft: "10px" }}>
            <TitleBox title={"회원가입"} unit={{ text: "단위", value: "명" }}>
              <FlexWrapper>
                {(userFilter.filter === "d" || userFilter.filter === "w") && (
                  <DateInput
                    width="170px"
                    min="2021-05-01"
                    max={`${today.getFullYear()}-${
                      today.getMonth() + 1 < 10
                        ? `0${today.getMonth() + 1}`
                        : today.getMonth() + 1
                    }-${
                      today.getDate() < 10
                        ? `0${today.getDate()}`
                        : today.getDate()
                    }`}
                    type="date"
                    name="date"
                    value={
                      userFilter.date.length < 8
                        ? transformDatetime("d")
                        : userFilter.date
                    }
                    changeHandler={(e) => {
                      setStatus((prev) => ({
                        ...prev,
                        user: true,
                      }));
                      userChangeHandler(e);
                    }}
                  />
                )}

                {userFilter.filter === "m" && (
                  <DateInput
                    width="170px"
                    type="month"
                    name="date"
                    min="2021-05"
                    max={`${today.getFullYear()}-${
                      today.getMonth() + 1 < 10
                        ? `0${today.getMonth() + 1}`
                        : today.getMonth() + 1
                    }`}
                    value={userFilter.date}
                    changeHandler={(e) => {
                      setStatus((prev) => ({
                        ...prev,
                        user: true,
                      }));
                      userChangeHandler(e);
                    }}
                  />
                )}

                <Select
                  border={false}
                  isTransparent={true}
                  width="110px"
                  name="filter"
                  value={userFilter.filter}
                  changeHandler={(e) => {
                    setStatus((prev) => ({
                      ...prev,
                      user: true,
                    }));
                    userChangeHandler(e);
                  }}
                  optionList={[
                    {
                      text: "일간",
                      value: "d",
                    },
                    {
                      text: "주간",
                      value: "w",
                    },
                    {
                      text: "월간",
                      value: "m",
                    },
                  ]}
                />
              </FlexWrapper>
            </TitleBox>

            <UserWrapper>
              {valueList.user?.length > 0 && (
                <BarChart list={valueList.user} labels={labelsList.user} />
              )}
            </UserWrapper>
          </PathBox>
        </AUSection>

        <PathLocationSection>
          <PathBox>
            <TitleBox title="접속 경로" unit={{ text: "단위", value: "회" }}>
              <FlexWrapper>
                {(pathFilter.filter === "d" || pathFilter.filter === "w") && (
                  <DateInput
                    width="170px"
                    type="date"
                    name="date"
                    min="2021-05-01"
                    max={`${today.getFullYear()}-${
                      today.getMonth() + 1 < 10
                        ? `0${today.getMonth() + 1}`
                        : today.getMonth() + 1
                    }-${
                      today.getDate() < 10
                        ? `0${today.getDate()}`
                        : today.getDate()
                    }`}
                    value={
                      pathFilter.date.length < 8
                        ? transformDatetime("d")
                        : pathFilter.date
                    }
                    changeHandler={(e) => {
                      setStatus((prev) => ({
                        ...prev,
                        history: true,
                      }));
                      pathChangeHandler(e);
                    }}
                  />
                )}

                {pathFilter.filter === "m" && (
                  <DateInput
                    width="170px"
                    type="month"
                    name="date"
                    min="2021-05"
                    max={`${today.getFullYear()}-${
                      today.getMonth() + 1 < 10
                        ? `0${today.getMonth() + 1}`
                        : today.getMonth() + 1
                    }`}
                    value={pathFilter.date}
                    changeHandler={(e) => {
                      setStatus((prev) => ({
                        ...prev,
                        history: true,
                      }));
                      pathChangeHandler(e);
                    }}
                  />
                )}

                <Select
                  border={false}
                  isTransparent={true}
                  width="110px"
                  name="filter"
                  value={pathFilter.filter}
                  changeHandler={(e) => {
                    setStatus((prev) => ({
                      ...prev,
                      history: true,
                    }));
                    pathChangeHandler(e);
                  }}
                  optionList={[
                    {
                      text: "일간",
                      value: "d",
                    },
                    {
                      text: "주간",
                      value: "w",
                    },
                    {
                      text: "월간",
                      value: "m",
                    },
                  ]}
                />
              </FlexWrapper>
            </TitleBox>
            <DoughnutWrapper>
              {valueList.history?.length > 0 && (
                <DougnutChart
                  labels={labelsList.history}
                  list={valueList.history}
                  originData={labelsList.history}
                  style={{ width: "100%", height: "100%" }}
                />
              )}
            </DoughnutWrapper>
          </PathBox>

          <LocationBox>
            <TitleBox title="접속 지역" unit={{ text: "단위", value: "회" }}>
              <FlexWrapper>
                {(locationFilter.filter === "d" ||
                  locationFilter.filter === "w") && (
                  <DateInput
                    width="170px"
                    type="date"
                    name="date"
                    min="2021-05-01"
                    max={`${today.getFullYear()}-${
                      today.getMonth() + 1 < 10
                        ? `0${today.getMonth() + 1}`
                        : today.getMonth() + 1
                    }-${
                      today.getDate() < 10
                        ? `0${today.getDate()}`
                        : today.getDate()
                    }`}
                    value={
                      locationFilter.date.length < 8
                        ? transformDatetime("d")
                        : locationFilter.date
                    }
                    changeHandler={(e) => {
                      setStatus((prev) => ({
                        ...prev,
                        location: true,
                      }));
                      locationChangeHandler(e);
                    }}
                  />
                )}

                {locationFilter.filter === "m" && (
                  <DateInput
                    width="170px"
                    type="month"
                    name="date"
                    min="2021-05"
                    max={`${today.getFullYear()}-${
                      today.getMonth() + 1 < 10
                        ? `0${today.getMonth() + 1}`
                        : today.getMonth() + 1
                    }`}
                    value={locationFilter.date}
                    changeHandler={(e) => {
                      setStatus((prev) => ({
                        ...prev,
                        location: true,
                      }));
                      locationChangeHandler(e);
                    }}
                  />
                )}

                <Select
                  border={false}
                  isTransparent={true}
                  width="110px"
                  name="filter"
                  value={locationFilter.filter}
                  changeHandler={(e) => {
                    setStatus((prev) => ({
                      ...prev,
                      location: true,
                    }));
                    locationChangeHandler(e);
                  }}
                  optionList={[
                    {
                      text: "일간",
                      value: "d",
                    },
                    {
                      text: "주간",
                      value: "w",
                    },
                    {
                      text: "월간",
                      value: "m",
                    },
                  ]}
                />
              </FlexWrapper>
            </TitleBox>

            <DoughnutWrapper>
              {valueList.location?.length > 0 && (
                <DougnutChart
                  labels={labelsList.location}
                  list={valueList.location}
                  originData={labelsList.location}
                  style={{ width: "100%", height: "100%" }}
                />
              )}
            </DoughnutWrapper>
          </LocationBox>
        </PathLocationSection>
      </Container>
    </DashboardLayout>
  );
};

export default ConnectTab;
