import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import { Wrapper } from "../../layouts/layoutStorage";
import DropDown from "../designedComponents/dropDown";
import NewFaq from "./newFaq";
import colors from "../../styles/constants/colors";

const FaqDetail = () => {
  const { n, t } = useParams();
  const [info, setInfo] = useState({});

  const loadData = () => {
    var token = window.localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", "detail");
    frm.append("token", token);
    frm.append("id", n);

    axios
      .post("https://ai.semos.kr/semos_admin/faq/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var status = Value.status;
        var statusMessage = Value.status_message;

        if (status == "success") {
          setInfo(Value.data);
        } else if (status == "waring") {
          alert(statusMessage);
        }
      });
  };

  useEffect(() => {
    loadData();
  }, [n]);

  const faqSaveHandler = () => {
    var token = window.localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", "edit");
    frm.append("token", token);
    frm.append("id", n);
    frm.append("category", info.category);
    frm.append("q", info.q);
    frm.append("a", info.a);

    axios
      .post("https://ai.semos.kr/semos_admin/faq/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var status = Value.status;
        var statusMessage = Value.status_message;

        if (status == "success") {
          alert(statusMessage);
          window.location.reload();
        } else {
          alert(statusMessage);
        }
      });
  };

  const faqDeleteHandler = () => {
    if (info.status == "활성화") {
      if (window.confirm("비활성화하시겠습니까?")) {
        var token = window.localStorage.getItem("token");

        const frm = new FormData();
        frm.append("mode", "delete");
        frm.append("token", token);
        frm.append("id", n);

        axios
          .post("https://ai.semos.kr/semos_admin/faq/data", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((data) => {
            var Value = data.data;
            var status = Value.status;
            var statusMessage = Value.status_message;

            if (status == "success") {
              alert(statusMessage);
              window.location.reload();
            } else {
              alert(statusMessage);
            }
          });
      }
    } else {
      if (window.confirm("활성화하시겠습니까?")) {
        var token = window.localStorage.getItem("token");

        const frm = new FormData();
        frm.append("mode", "respawn");
        frm.append("token", token);
        frm.append("id", n);

        axios
          .post("https://ai.semos.kr/semos_admin/faq/data", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((data) => {
            var Value = data.data;
            var status = Value.status;
            var statusMessage = Value.status_message;

            if (status == "success") {
              alert(statusMessage);
              window.location.reload();
            } else {
              alert(statusMessage);
            }
          });
      }
    }
  };

  return (
    <Wrapper
      bgc={colors.White}
      of={`hidden auto`}
      margin="10px 0 0 0"
      display={`inline-block`}
      width={`100%`}
      mw={`1000px`}
      mh={`calc(100vh - 72px)`}
      padding={`10px 0`}
    >
      {n === "new" ? (
        <NewFaq />
      ) : (
        <Container>
          <div>
            <Title className="f24">자주 묻는 질문 등록</Title>
            <QuestionLine>
              <QAArea>Q</QAArea>
              <QuestionArea style={{ marginRight: "12px", width: "77%" }}>
                <Input
                  placeholder="제목을 작성해 주세요"
                  value={info.q}
                  onChange={(e) =>
                    setInfo((prev) => ({ ...prev, q: e.target.value }))
                  }
                />
              </QuestionArea>
              <DropDown
                no={true}
                data={info.category}
                option={[
                  {
                    text: t == "customer" ? "회원 정보" : "입점 안내",
                    value: t == "customer" ? "회원 정보" : "입점 안내",
                  },
                  { text: "결제 및 환불", value: "결제 및 환불" },
                  { text: "이용 안내", value: "이용 안내" },
                ]}
                setData={setInfo}
                type="object"
                keyName="category"
              />
            </QuestionLine>
            <div style={{ height: "8px" }} />
            <QuestionLine>
              <QAArea>A</QAArea>
              <QuestionArea>
                <TextArea
                  placeholder="내용을 작성해 주세요"
                  value={info.a}
                  rows={20}
                  onChange={(e) =>
                    setInfo((prev) => ({ ...prev, a: e.target.value }))
                  }
                />
              </QuestionArea>
            </QuestionLine>
          </div>

          <ButtonArea>
            <DeleteButton
              onClick={() => faqDeleteHandler()}
              color={info.status == "활성화"}
            >
              {info.status == "활성화" ? "비활성화" : "활성화"}
            </DeleteButton>
            <SaveButton onClick={() => faqSaveHandler()}>저장</SaveButton>
          </ButtonArea>
        </Container>
      )}
    </Wrapper>
  );
};

export default FaqDetail;

const Container = styled.div`
  padding: 36px;
  padding-bottom: 52px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  font-weight: 700;
  margin-bottom: 36px;
`;

const QuestionLine = styled.div`
  display: flex;
  /* align-items: center; */
`;

const QAArea = styled.div`
  width: 2rem;
  padding: 8px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
`;

const QuestionArea = styled.div`
  padding: 8px 16px;
  border: 1px solid ${colors.Gr02};
  width: 100%;
`;

const Input = styled.input`
  color: ${colors.Gr05};
  width: 100%;
  font-weight: 500;
`;

const TextArea = styled.textarea`
  resize: none;
  outline: none;
  border: none;
  width: 100%;
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  letter-spacing: -0.02em;
`;

const DeleteButton = styled.div`
  padding: 12px 0;
  color: ${({ color }) => (color ? colors.Red03 : colors.Bl07)};
  cursor: pointer;
`;

const SaveButton = styled.div`
  margin: 30px 0 0 0;
  background-color: ${colors.Bl07};
  color: ${colors.White};
  padding: 12px 0;
  width: 80%;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
`;
