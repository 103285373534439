import styled from "styled-components";
import Input from "../../../modules/input";
import Select from "../../../modules/select";
import flexbox from "../../../styles/func/flexbox";
import ImageInput from "../../imageInput";

const Container = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
`;

const InfoBox = styled.div`
  width: 610px;
  height: 100%;
  margin-left: 40px;
  ${flexbox("space-around", "flex-start", "column")}
`;

const Wrapper = styled.div`
  width: 50%;

  &:first-child {
    margin-right: 20px;
  }
`;

const Category = styled.div`
  width: 100%;
  display: flex;
`;

const BaseBox = ({ value, changeHandler, changeHandler2, imagePro }) => {


const waterList = [
  {
    text: "스쿠버다이빙",
    value: "스쿠버다이빙",
  },
  {
    text: "프리다이빙",
    value: "프리다이빙",
  },
  {
    text: "서핑",
    value: "서핑",
  },
  {
    text: "수영",
    value: "수영",
  },
  {
    text: "웨이크보드",
    value: "웨이크보드",
  },
  {
    text: "수상스키",
    value: "수상스키",
  },
  {
    text: "조정",
    value: "조정",
  },
  {
    text: "카누/카약",
    value: "카누/카약",
  },
  {
    text: "수상레저",
    value: "수상레저",
  },
];

const outdoorList = [
  {
    text: "스키",
    value: "스키",
  },
  {
    text: "스노보드",
    value: "스노보드",
  }
];

const indoorList = [
  {
    text: "필라테스",
    value: "필라테스",
  },
  {
    text: "요가",
    value: "요가",
  }
];


  return (
    <Container>
      <ImageInput
        border={true}
        name="base"
        width="150px"
        height="150px"
        changeHandler={changeHandler2}
        preview={value.profile_img}
        imagePro={imagePro}
      />

      <InfoBox>
        <Category>
          <Wrapper>
            <Select
              name="main"
              value={value.sport_category}
              placeholder="대분류"
              optionList={[
                {
                  text: "워터스포츠",
                  value: "워터스포츠",
                },
                {
                  text: "야외스포츠",
                  value: "야외스포츠",
                },
                {
                  text: "실내스포츠",
                  value: "실내스포츠",
                },
              ]}
              changeHandler={(e) => changeHandler('sport_category', e.target.value)}
            />
          </Wrapper>

          <Wrapper>
            <Select
              name="sub"
              value={value.sport}
              placeholder="소분류"
              optionList={
                value.sport_category ? 
                value.sport_category == '워터스포츠' ? 
                waterList : value.sport_category == '야외스포츠' ? 
                outdoorList : indoorList : []
              }
              changeHandler={(e) => changeHandler('sport', e.target.value)}
            />
          </Wrapper>
        </Category>

        <Input
          type="text"
          name="title"
          value={value.name}
          changeHandler={(e) => changeHandler('name', e.target.value)}
          placeholder="프로필명을 작성해주세요"
        />
      </InfoBox>
    </Container>
  );
};

export default BaseBox;
