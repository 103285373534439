import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

//components
import colors from "../../styles/constants/colors";

import { Icon } from "../../layouts/layoutStorage";
// img
import GreyPlus from "../../assets/icon/grayPlus.png";
import SearchIcon from "../../assets/icon/search.png";
import BluePlus from "../../assets/icon/bluePlus.png";
import XIcon from "../../assets/icon/tagDelete.png";

const W = window.innerWidth;

const ActivityIntro = ({ open, data, setData, deleteD, setDelete, bigData, textRecommend }) => {
  const [input, setInput] = useState({
    recommend: "",
    qualified: "",
    prepare: "",
    include: "",
    exclude: "",
    caution: "",
    unique: "",
    prepare_copy: "",
    include_copy: "",
    exclude_copy: "",
  });

  //   const ref = useRef();

  const settingKeyWords = (key) => {
    let arr = data ? [...data[key]] : [];
    if (!arr.includes(input[key])) {
      arr.push({ id: "new", change: "yes", content: input[key] });
      setData((prev) => ({ ...prev, [key]: arr }));
    } else {
      alert("키워드를 중복해서 추가 할 수 없습니다.");
    }
    setInput((prev) => ({ ...prev, [key]: "" }));
  };

  const settingKeyWordsRecommend = (value, key) => {
    var arr = [...data[key]];
    if (!arr.includes(value)) {
      arr.push({ id: "new", change: "yes", content: value });
      setData((prev) => ({ ...prev, [key]: arr }));
    } else {
      alert("키워드를 중복해서 추가 할 수 없습니다.");
    }
    setInput((prev) => ({ ...prev, [key]: "" }));
  };

  const deleteKeyWords = (key, value, index) => {
    var arr = [...data[key]];
    var deleted = [...deleteD[key]];
    deleted.push(arr[index]);

    arr = arr.filter((x) => x !== value);
    setDelete((prev) => ({ ...prev, [key]: deleted }));
    setData((prev) => ({ ...prev, [key]: arr }));
  };

  const SearchKeyword = (value, category) => {
    setInput((prev) => ({ ...prev, [category]: value }));

    if (value.length > 1) {
      bigData(category, value);
    }
  };

  useEffect(() => {
    setInput({
      recommend: "",
      qualified: "",
      prepare: "",
      include: "",
      exclude: "",
      caution: "",
      unique: "",
      prepare_copy: "",
      include_copy: "",
      exclude_copy: "",
    });
  }, [open]);

  return (
    <div id="introduce">
      <TitleBox>
        <Styler pt={36} pl={36} pb={24} pr={36} flex={true} justify="space-between" align="center">
          <Title className="f16">액티비티 소개</Title>
        </Styler>
      </TitleBox>

      <Styler pl={36} pr={36}>
        <Styler flex={true} justify="space-between" align="center" pb={12}>
          <SmallTitle className="f14">추천대상</SmallTitle>
          <BlueIndicator>다중 입력이 가능해요</BlueIndicator>
        </Styler>

        <TagInputDiv length={input.recommend.length}>
          <Sharp value={input.recommend}>#</Sharp>
          <TagInput
            placeholder="태그로 검색해 보세요. 예) 스쿠버 다이빙 초보자"
            value={input.recommend}
            onChange={(e) => SearchKeyword(e.target.value, "recommend")}
          />

          <Icon width={`calc(1rem * (17 / 14))`} src={SearchIcon} />
        </TagInputDiv>

        {input.recommend && (
          <Styler pt={4}>
            <SearchBox>
              <SearchList>
                {textRecommend.recommend &&
                  textRecommend.recommend.map((el, idx) => (
                    <SearchItem onClick={() => settingKeyWordsRecommend(el, "recommend")} key={`${idx}_recommend_search`}>
                      <SearchText>{el}</SearchText>
                      <PlusIcon src={GreyPlus} />
                    </SearchItem>
                  ))}
              </SearchList>
              <AddSearchItem onClick={() => settingKeyWords("recommend")}>
                <AddSearchText>’{input.recommend}’ 항목 추가하기</AddSearchText>
                <PlusIcon src={BluePlus} />
              </AddSearchItem>
            </SearchBox>
          </Styler>
        )}

        <TagContainer>
          {data.recommend?.map((el, idx) => (
            <TagItem key={`${el.content}_${idx}_recommend`}>
              <TagText>{el.content}</TagText>
              <Xbutton src={XIcon} onClick={() => deleteKeyWords("recommend", el, idx)} />
            </TagItem>
          ))}
        </TagContainer>

        <Styler flex={true} justify="space-between" align="center" pb={12} pt={18}>
          <SmallTitle>자격요건</SmallTitle>
          <BlueIndicator>다중 입력이 가능해요</BlueIndicator>
        </Styler>
        <TagInputDiv length={input.qualified.length}>
          <Sharp value={input.qualified}>#</Sharp>
          <TagInput
            placeholder="태그로 검색해 보세요. 예) 오픈워터자격이상"
            value={input.qualified}
            onChange={(e) => SearchKeyword(e.target.value, "qualified")}
          />
          <Icon width={`calc(1rem * (17 / 14))`} src={SearchIcon} />
        </TagInputDiv>

        {input.qualified && (
          <Styler pt={4}>
            <SearchBox>
              <SearchList>
                {textRecommend.qualified &&
                  textRecommend.qualified.map((el, idx) => (
                    <SearchItem onClick={() => settingKeyWordsRecommend(el, "qualified")} key={`${idx}_qualified_search`}>
                      <SearchText>{el}</SearchText>
                      <PlusIcon src={GreyPlus} />
                    </SearchItem>
                  ))}
              </SearchList>
              <AddSearchItem onClick={() => settingKeyWords("qualified")}>
                <AddSearchText>’{input.qualified}’ 항목 추가하기</AddSearchText>
                <PlusIcon src={BluePlus} />
              </AddSearchItem>
            </SearchBox>
          </Styler>
        )}

        <TagContainer>
          {data.qualified?.map((el, idx) => (
            <TagItem key={`${el.content}_${idx}_qualified`}>
              <TagText>{el.content}</TagText>
              <Xbutton src={XIcon} onClick={() => deleteKeyWords("qualified", el, idx)} />
            </TagItem>
          ))}
        </TagContainer>

        <Styler flex={true} justify="space-between" align="center" pb={10} pt={14}>
          <SmallTitle>액티비티 소개</SmallTitle>
        </Styler>

        <IntroduceBox>
          <IntroduceInput
            rows="6"
            placeholder={`액티비티를 소개해 주세요!\n\n예) 액티비티 일정 등`}
            onChange={(e) => setData((prev) => ({ ...prev, info: e.target.value }))}
            value={data.info}
          />
        </IntroduceBox>

        <Styler flex={true} justify="space-between" align="center" pb={10} pt={24}>
          <SmallTitle>준비물</SmallTitle>
          <BlueIndicator>다중 입력이 가능해요</BlueIndicator>
        </Styler>

        <TagInputDiv length={input.prepare.length}>
          <Sharp value={input.prepare}>#</Sharp>
          <TagInput placeholder="태그로 검색해 보세요. 예) 수영복" value={input.prepare} onChange={(e) => SearchKeyword(e.target.value, "prepare")} />

          <Icon width={`calc(1rem * (17 / 14))`} src={SearchIcon} />
        </TagInputDiv>

        {input.prepare && (
          <Styler pt={4}>
            <SearchBox>
              <SearchList>
                {textRecommend.prepare &&
                  textRecommend.prepare.map((el, idx) => (
                    <SearchItem onClick={() => settingKeyWordsRecommend(el, "prepare")} key={`${idx}_prepare_search`}>
                      <SearchText>{el}</SearchText>
                      <PlusIcon src={GreyPlus} />
                    </SearchItem>
                  ))}
              </SearchList>
              <AddSearchItem onClick={() => settingKeyWords("prepare")}>
                <AddSearchText>’{input.prepare}’ 항목 추가하기</AddSearchText>
                <PlusIcon src={BluePlus} />
              </AddSearchItem>
            </SearchBox>
          </Styler>
        )}

        <TagContainer>
          {data.prepare?.map((el, idx) => (
            <TagItem key={`${el.content}_${idx}_prepare`}>
              <TagText>{el.content}</TagText>
              <Xbutton src={XIcon} onClick={() => deleteKeyWords("prepare", el, idx)} />
            </TagItem>
          ))}
        </TagContainer>

        <Styler flex={true} justify="space-between" align="center" pb={12} pt={18}>
          <SmallTitle>포함사항</SmallTitle>
          <BlueIndicator>다중 입력이 가능해요</BlueIndicator>
        </Styler>

        <TagInputDiv length={input.include.length}>
          <Sharp value={input.include}>#</Sharp>
          <TagInput placeholder="태그로 검색해 보세요. 예) 장비렌탈" value={input.include} onChange={(e) => SearchKeyword(e.target.value, "include")} />

          <Icon width={`calc(1rem * (17 / 14))`} src={SearchIcon} />
        </TagInputDiv>

        {input.include && (
          <Styler pt={4}>
            <SearchBox>
              <SearchList>
                {textRecommend.include &&
                  textRecommend.include.map((el, idx) => (
                    <SearchItem onClick={() => settingKeyWordsRecommend(el, "include")} key={`${idx}_include_search`}>
                      <SearchText>{el}</SearchText>
                      <PlusIcon src={GreyPlus} />
                    </SearchItem>
                  ))}
              </SearchList>
              <AddSearchItem onClick={() => settingKeyWords("include")}>
                <AddSearchText>’{input.include}’ 항목 추가하기</AddSearchText>
                <PlusIcon src={BluePlus} />
              </AddSearchItem>
            </SearchBox>
          </Styler>
        )}

        <TagContainer>
          {data.include?.map((el, idx) => (
            <TagItem key={`${el.content}_${idx}_include`}>
              <TagText>{el.content}</TagText>
              <Xbutton src={XIcon} onClick={() => deleteKeyWords("include", el, idx)} />
            </TagItem>
          ))}
        </TagContainer>

        <Styler flex={true} justify="space-between" align="center" pb={12} pt={18}>
          <SmallTitle>불포함사항</SmallTitle>
          <BlueIndicator>다중 입력이 가능해요</BlueIndicator>
        </Styler>

        <TagInputDiv length={input.exclude.length}>
          <Sharp value={input.exclude}>#</Sharp>
          <TagInput placeholder="태그로 검색해 보세요. 예) 풀장입장료" value={input.exclude} onChange={(e) => SearchKeyword(e.target.value, "exclude")} />

          <Icon width={`calc(1rem * (17 / 14))`} src={SearchIcon} />
        </TagInputDiv>

        {input.exclude && (
          <Styler pt={4}>
            <SearchBox>
              <SearchList>
                {textRecommend.exclude &&
                  textRecommend.exclude.map((el, idx) => (
                    <SearchItem onClick={() => settingKeyWordsRecommend(el, "exclude")} key={`${idx}_exclude_search`}>
                      <SearchText>{el}</SearchText>
                      <PlusIcon src={GreyPlus} />
                    </SearchItem>
                  ))}
              </SearchList>
              <AddSearchItem onClick={() => settingKeyWords("exclude")}>
                <AddSearchText>’{input.exclude}’ 항목 추가하기</AddSearchText>
                <PlusIcon src={BluePlus} />
              </AddSearchItem>
            </SearchBox>
          </Styler>
        )}

        <TagContainer>
          {data.exclude?.map((el, idx) => (
            <TagItem key={`${el.content}_${idx}_exclude`}>
              <TagText>{el.content}</TagText>
              <Xbutton src={XIcon} onClick={() => deleteKeyWords("exclude", el, idx)} />
            </TagItem>
          ))}
        </TagContainer>

          {(data.status == '승인 요청') &&
            <>
              <div style={{backgroundColor : colors.Red03, height : 2, width : '100%', marginTop : 20}} />

                <Styler flex={true} justify="space-between" align="center" pb={10} pt={24}>
                  <SmallTitle style={{color : colors.Red03}}>준비물 (승인 요청)</SmallTitle>
                  <BlueIndicator>다중 입력이 가능해요</BlueIndicator>
                </Styler>

                <TagInputDiv length={input.prepare_copy.length}>
                  <Sharp value={input.prepare_copy}>#</Sharp>
                  <TagInput placeholder="태그로 검색해 보세요. 예) 수영복" value={input.prepare_copy} onChange={(e) => SearchKeyword(e.target.value, "prepare_copy")} />

                  <Icon width={`calc(1rem * (17 / 14))`} src={SearchIcon} />
                </TagInputDiv>

                {input.prepare_copy && (
                  <Styler pt={4}>
                    <SearchBox>
                      <SearchList>
                        {textRecommend.prepare &&
                          textRecommend.prepare.map((el, idx) => (
                            <SearchItem onClick={() => settingKeyWordsRecommend(el, "prepare_copy")} key={`${idx}_prepare_search_copy`}>
                              <SearchText>{el}</SearchText>
                              <PlusIcon src={GreyPlus} />
                            </SearchItem>
                          ))}
                      </SearchList>
                      <AddSearchItem onClick={() => settingKeyWords("prepare_copy")}>
                        <AddSearchText>’{input.prepare_copy}’ 항목 추가하기</AddSearchText>
                        <PlusIcon src={BluePlus} />
                      </AddSearchItem>
                    </SearchBox>
                  </Styler>
                )}

                <TagContainer>
                  {data.prepare_copy?.map((el, idx) => (
                    <TagItem key={`${el.content}_${idx}_prepare_copy`}>
                      <TagText>{el.content}</TagText>
                      <Xbutton src={XIcon} onClick={() => deleteKeyWords("prepare_copy", el, idx)} />
                    </TagItem>
                  ))}
                </TagContainer>

                <Styler flex={true} justify="space-between" align="center" pb={12} pt={18}>
                  <SmallTitle style={{color : colors.Red03}}>포함사항 (승인 요청)</SmallTitle>
                  <BlueIndicator>다중 입력이 가능해요</BlueIndicator>
                </Styler>

                <TagInputDiv length={input.include_copy.length}>
                  <Sharp value={input.include_copy}>#</Sharp>
                  <TagInput placeholder="태그로 검색해 보세요. 예) 장비렌탈" value={input.include_copy} onChange={(e) => SearchKeyword(e.target.value, "include_copy")} />

                  <Icon width={`calc(1rem * (17 / 14))`} src={SearchIcon} />
                </TagInputDiv>

                {input.include_copy && (
                  <Styler pt={4}>
                    <SearchBox>
                      <SearchList>
                        {textRecommend.include &&
                          textRecommend.include.map((el, idx) => (
                            <SearchItem onClick={() => settingKeyWordsRecommend(el, "include_copy")} key={`${idx}_include_search_copy`}>
                              <SearchText>{el}</SearchText>
                              <PlusIcon src={GreyPlus} />
                            </SearchItem>
                          ))}
                      </SearchList>
                      <AddSearchItem onClick={() => settingKeyWords("include_copy")}>
                        <AddSearchText>’{input.include_copy}’ 항목 추가하기</AddSearchText>
                        <PlusIcon src={BluePlus} />
                      </AddSearchItem>
                    </SearchBox>
                  </Styler>
                )}

                <TagContainer>
                  {data.include_copy?.map((el, idx) => (
                    <TagItem key={`${el.content}_${idx}_include_copy`}>
                      <TagText>{el.content}</TagText>
                      <Xbutton src={XIcon} onClick={() => deleteKeyWords("include_copy", el, idx)} />
                    </TagItem>
                  ))}
                </TagContainer>

                <Styler flex={true} justify="space-between" align="center" pb={12} pt={18}>
                  <SmallTitle style={{color : colors.Red03}}>불포함사항 (승인 요청)</SmallTitle>
                  <BlueIndicator>다중 입력이 가능해요</BlueIndicator>
                </Styler>

                <TagInputDiv length={input.exclude_copy.length}>
                  <Sharp value={input.exclude_copy}>#</Sharp>
                  <TagInput placeholder="태그로 검색해 보세요. 예) 풀장입장료" value={input.exclude_copy} onChange={(e) => SearchKeyword(e.target.value, "exclude_copy")} />

                  <Icon width={`calc(1rem * (17 / 14))`} src={SearchIcon} />
                </TagInputDiv>

                {input.exclude_copy && (
                  <Styler pt={4}>
                    <SearchBox>
                      <SearchList>
                        {textRecommend.exclude &&
                          textRecommend.exclude.map((el, idx) => (
                            <SearchItem onClick={() => settingKeyWordsRecommend(el, "exclude")} key={`${idx}_exclude_search_copy`}>
                              <SearchText>{el}</SearchText>
                              <PlusIcon src={GreyPlus} />
                            </SearchItem>
                          ))}
                      </SearchList>
                      <AddSearchItem onClick={() => settingKeyWords("exclude_copy")}>
                        <AddSearchText>’{input.exclude_copy}’ 항목 추가하기</AddSearchText>
                        <PlusIcon src={BluePlus} />
                      </AddSearchItem>
                    </SearchBox>
                  </Styler>
                )}

                <TagContainer>
                  {data.exclude_copy?.map((el, idx) => (
                    <TagItem key={`${el.content}_${idx}_exclude_copy`}>
                      <TagText>{el.content}</TagText>
                      <Xbutton src={XIcon} onClick={() => deleteKeyWords("exclude_copy", el, idx)} />
                    </TagItem>
                  ))}
                </TagContainer>
              
              <div style={{backgroundColor : colors.Red03, height : 2, width : '100%', marginTop : 20}} />
            </>
          }


        <Styler flex={true} justify="space-between" align="center" pb={12} pt={18}>
          <SmallTitle>특별한 점</SmallTitle>
          <BlueIndicator>다중 입력이 가능해요</BlueIndicator>
        </Styler>
        
        <TagInputDiv length={input.unique.length}>
          <Sharp value={input.unique}>#</Sharp>
          <TagInput
            placeholder="태그로 검색해 보세요. 예) 스쿠버 다이빙 초보자"
            value={input.unique}
            onChange={(e) => SearchKeyword(e.target.value, "unique")}
          />

          <Icon width={`calc(1rem * (17 / 14))`} src={SearchIcon} />
        </TagInputDiv>

        {input.unique && (
          <Styler pt={4}>
            <SearchBox>
              <SearchList>
                {textRecommend.unique &&
                  textRecommend.unique.map((el, idx) => (
                    <SearchItem onClick={() => settingKeyWordsRecommend(el, "unique")} key={`${idx}_unique_search`}>
                      <SearchText>{el}</SearchText>
                      <PlusIcon src={GreyPlus} />
                    </SearchItem>
                  ))}
              </SearchList>
              <AddSearchItem onClick={() => settingKeyWords("unique")}>
                <AddSearchText>’{input.unique}’ 항목 추가하기</AddSearchText>
                <PlusIcon src={BluePlus} />
              </AddSearchItem>
            </SearchBox>
          </Styler>
        )}

        <TagContainer>
          {data.unique?.map((el, idx) => (
            <TagItem key={`${el.content}_${idx}_unique`}>
              <TagText>{el.content}</TagText>
              <Xbutton src={XIcon} onClick={() => deleteKeyWords("unique", el, idx)} />
            </TagItem>
          ))}
        </TagContainer>

        <Styler flex={true} justify="space-between" align="center" pb={12} pt={18}>
          <SmallTitle>유의사항</SmallTitle>
          <BlueIndicator>다중 입력이 가능해요</BlueIndicator>
        </Styler>
        <TagInputDiv length={input.caution.length}>
          <Sharp value={input.caution}>#</Sharp>
          <TagInput
            placeholder="태그로 검색해 보세요. 예) 스쿠버 다이빙 초보자"
            value={input.caution}
            onChange={(e) => SearchKeyword(e.target.value, "caution")}
          />

          <Icon width={`calc(1rem * (17 / 14))`} src={SearchIcon} />
        </TagInputDiv>

        {input.caution && (
          <Styler pt={4}>
            <SearchBox>
              <SearchList>
                {textRecommend.caution &&
                  textRecommend.caution.map((el, idx) => (
                    <SearchItem onClick={() => settingKeyWordsRecommend(el, "caution")} key={`${idx}_caution_search`}>
                      <SearchText>{el}</SearchText>
                      <PlusIcon src={GreyPlus} />
                    </SearchItem>
                  ))}
              </SearchList>
              <AddSearchItem onClick={() => settingKeyWords("caution")}>
                <AddSearchText>’{input.caution}’ 항목 추가하기</AddSearchText>
                <PlusIcon src={BluePlus} />
              </AddSearchItem>
            </SearchBox>
          </Styler>
        )}

        <TagContainer>
          {data.caution?.map((el, idx) => (
            <TagItem key={`${el.content}_${idx}_caution`}>
              <TagText>{el.content}</TagText>
              <Xbutton src={XIcon} onClick={() => deleteKeyWords("caution", el, idx)} />
            </TagItem>
          ))}
        </TagContainer>
      </Styler>
    </div>
  );
};

export default ActivityIntro;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  padding-top: ${({ pt }) => `calc(1rem * ${pt} / 14)`};
  padding-bottom: ${({ pb }) => `calc(1rem * ${pb} / 14)`};
  padding-left: ${({ pl }) => `calc(1rem * ${pl} / 14)`};
  padding-right: ${({ pr }) => `calc(1rem * ${pr} / 14)`};
  height: ${({ height }) => height}px;
`;

const TitleBox = styled.div`
  border-top: 1px solid ${colors.Gr02};
`;

const Title = styled.div`
  font-weight: 600;

  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${({ red }) => (red ? colors.Red02 : colors.Gr05)};
`;

const Indicator = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr04};
`;

const Index = styled.span`
  color: ${({ red }) => (red ? colors.Red02 : colors.Gr05)};
`;

const SmallTitle = styled.div`
  font-weight: 500;
  font-size: 1rem;
  line-height: 142%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ status }) => (status == "empty" ? colors.Red02 : colors.Gr05)};
`;

const BlueIndicator = styled.div`
  font-weight: 500;

  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${({ status }) => (status == "empty" ? colors.Red02 : colors.Bl07)};
`;

const TagInputDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  /* gap: 10px; */
  /* background-color: ${colors.Gr01}; */
  /* border: 1px solid ${({ red }) => (red ? colors.Red02 : colors.Gr02)}; */
  border: 1px solid ${({ length }) => (length ? colors.Gr04 : colors.Gr02)};
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  font-weight: 500;
  font-size: 1rem;
  line-height: 142%;
`;

const TagInput = styled.input`
  font-weight: 500;
  font-size: 1rem;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  width: 100%;

  &::placeholder {
    color: ${colors.Gr02};
  }
`;

const SearchBox = styled.div`
  border: 1px solid ${colors.Gr02};
`;

const SearchList = styled.div``;

const SearchItem = styled.div`
  border-bottom: 1px solid ${colors.Gr02};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 8px 10px 18px;
`;

const AddSearchItem = styled(SearchItem)`
  border: none;
  background-color: ${colors.White};
  border-radius: 6px;
  white-space: normal;
`;

const SearchText = styled.div`
  font-weight: 500;
  font-size: calc(1rem * 12 / 14);
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  max-width: ${W - 98}px;
  word-break: break-all;
  text-overflow: ellipsis;
`;

const AddSearchText = styled(SearchText)`
  color: ${colors.Bl07};
`;

const PlusIcon = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(1rem * (10 / 14));
  margin-bottom: calc(1rem * (26 / 14));
`;

const TagItem = styled.div`
  border: 1.2px solid ${colors.Gr02};
  padding: 6px 12px;
  display: flex;
  align-items: center;
  background-color: ${colors.Gr01};
  border-radius: 24px;
  margin-right: 9px;
  margin-bottom: 8px;
`;

const TagText = styled.div`
  font-weight: 500;
  font-size: calc(1rem * 13 / 14);
  line-height: 100%;
  display: flex;
  align-items: center;
  /* text-align: center; */
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  max-width: ${W - 64}px;
  word-break: break-all;
  text-overflow: ellipsis;
`;

const Xbutton = styled.img`
  cursor: pointer;
  width: 12px;
  height: 18px;
  margin-left: 4px;
`;

const IntroduceBox = styled.div`
  border: 1px solid ${({ red }) => (red ? colors.Red02 : colors.Gr02)};
`;

const IntroduceInput = styled.textarea`
  outline: none;
  resize: none;
  border: none;
  width: 100%;
  height: "auto";
  padding: 16px;
  font-weight: 500;
  font-size: calc(1rem * 12 / 14);
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color:${colors.Gr05}

  &::placeholder {
    color: ${colors.Gr03};
  }
`;

const Sharp = styled.span`
  color: ${({ value }) => (value ? colors.Gr05 : colors.Gr02)};
`;
