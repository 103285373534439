import { useState } from "react";
import styled from "styled-components";
import chevronIcon from "../../assets/icon/chevron-b.svg";
import base from "../../styles/constants/base";
import colors from "../../styles/constants/colors";
import flexbox from "../../styles/func/flexbox";
import transition from "../../styles/func/transition";
import deleteIcon from "../../assets/icon/close.svg";

const Wrapper = styled.div`
  width: 100%;
  ${flexbox("space-between", "flex-start")};
  margin: 20px 0;
`;

const ButtonBox = styled.div`
  width: 150px;
  height: 100%;

  ${flexbox("flex-end", "flex-start")};

  button {
    padding: 0 0 10px 10px;
  }
`;

const Card = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 0px;
  }
`;

const QuestionBox = styled.div`
  width: 100%;
  height: 50px;
  ${flexbox("space-between")}
  margin-bottom: 20px;

  input {
    width: 100%;
    margin-left: 0px;
  }

  div {
    padding: 20px 0 20px 20px;
    cursor: pointer;
  }
`;

const ChevronImg = styled.img`
  transform: rotate(90deg);
  transition: ${transition("transform")};
  transform: ${({ open }) => (open ? `rotate(-90deg)` : null)};
  margin-left: 20px;
`;

const AnswerBox = styled.div`
  width: 100%;
  height: 90px;
  position: relative;
  max-height: ${({ open }) => (open ? "90px" : 0)};
  overflow: hidden;
  transition: ${transition("max-height")};
  ${flexbox("flex-start")}

  span {
    display: block;
    height: 100%;
    width: 50px;
  }

  div {
    width: 100%;
    height: 100%;
    ${flexbox()};
    border: ${base.border};
    border-radius: ${base.borderRadius.default}px;

    textarea {
      width: 100%;
      min-height: 100px;
      padding: 20px;
      border-radius: ${base.borderRadius}px;
      resize: none;
    }
  }
`;

const InputBox = styled.div`
  position: relative;
  width: 100%;

  button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-45%);

    img {
      width: 15px;
    }
  }
`;

const FAQCard = ({
  type = "write",
  changeHandler,
  value,
  data = "",
  deleteHandler = "",
  resetHandler = "",
}) => {
  const [open, setOpen] = useState(false);

  const openToggleHandler = () => {
    setOpen(!open);
  };

  if (type === "write") {
    return (
      <Wrapper>
        <Card>
          <QuestionBox open={open}>
            <span>Q. </span>

            <InputBox>
              <input
                type="text"
                placeholder="수영을 못해도 괜찮을까요?"
                value={value.question}
                name="question"
                onChange={changeHandler}
              />

              <button onClick={resetHandler}>
                <img src={deleteIcon} />
              </button>
            </InputBox>

            <div onClick={openToggleHandler}>
              <ChevronImg src={chevronIcon} open={open} />
            </div>
          </QuestionBox>

          <AnswerBox open={open}>
            <span>A.</span>
            <div>
              <textarea
                name="answer"
                value={value.answer}
                placeholder="답변 내용"
                onChange={changeHandler}
              />
            </div>
          </AnswerBox>
        </Card>

        <ButtonBox></ButtonBox>
      </Wrapper>
    );
  }

  if (type === "read") {
    return (
      <Wrapper>
        <Card>
          <QuestionBox open={open}>
            <span>Q. </span>

            <InputBox>
              <input
                type="text"
                placeholder="수영을 못해도 괜찮을까요?"
                value={data.question}
                name="question"
                disabled={true}
              />

              <button onClick={() => deleteHandler(data.id)}>
                <img src={deleteIcon} />
              </button>
            </InputBox>

            <div onClick={openToggleHandler}>
              <ChevronImg src={chevronIcon} open={open} />
            </div>
          </QuestionBox>

          <AnswerBox open={open}>
            <div>
              <span>A.</span>
              <textarea
                name="answer"
                value={data.answer}
                placeholder="답변 내용"
                disabled={true}
              />
            </div>
          </AnswerBox>
        </Card>

        <ButtonBox></ButtonBox>
      </Wrapper>
    );
  }
};

export default FAQCard;
