import axios from "axios";

const BASE_URL = "https://ai.semos.kr/semos_admin/board/data";

const transformDate = (fullDate) => {
  const [year, month, day] = fullDate.split("-");

  return `${year.slice(2, 4)}-${month}-${day}`;
};

const useData = (setValueList, setLabelsList) => {
  const today = new Date();

  const getFilter = (targetFilter) => {
    switch (targetFilter.type) {
      case "m":
        return { type: "month", date: targetFilter.date };

      case "w":
        return { type: "week", date: targetFilter.date };

      case "d":
        return { type: "day", date: targetFilter.date };
      default:
    }
  };

  const getDataByCategory = (page, type, filter) => {
    if (page === "connect") {
      const frm = new FormData();

      frm.append("mode", "Update");
      frm.append("page", page);
      frm.append("date_type", filter.type);
      frm.append("date", filter.date);
      frm.append("type", type);

      axios
        .post(BASE_URL, frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          setValueList((prev) => ({
            ...prev,
            [type]: res?.data.map((item) => item.value),
          }));

          setLabelsList((prev) => {
            if ((type === "connect" || type === "user" || type === "reconnect") && filter.type === "week") {
              return {
                ...prev,
                [type]: res?.data.map(
                  (item) =>
                    `${transformDate(item.start_date)} ~ ${transformDate(
                      item.finish_date
                    )}`
                ),
              };
            } else {
              return {
                ...prev,
                [type]: res?.data.map((item) => item.type || item.date),
              };
            }
          });
        });

    } else if (page === "page_connect") {
      const frm = new FormData();

      frm.append("mode", "Update");
      frm.append("page", page);
      frm.append("date_type", filter.type);
      frm.append("date", filter.date);
      frm.append("type", type);

      axios
        .post(BASE_URL, frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          console.log(res.data);

          setValueList((prev) => {
            if (type === "lesson") {
              return {
                ...prev,
                [type]: res.data.map((item) => ({
                  type: item.type,
                  partner_name: item.partner_name,
                  value: item.value,
                })),
              };
            } else {
              return {
                ...prev,
                [type]: res.data.map((item) => ({
                  type: item.type,
                  value: item.value,
                })),
              };
            }
          });

          setLabelsList((prev) => {
            return {
              ...prev,
              [type]: res.data.map((item) => item.type || item.date),
            };
          });
        });
    } else if (page === "lesson") {
      const frm = new FormData();

      frm.append("mode", "Update");
      frm.append("page", page);
      frm.append("date_type", filter.type);
      frm.append("date", filter.date);
      frm.append("type", type);

      axios
        .post(BASE_URL, frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          console.log(res.data);

          setValueList((prev) => ({
            ...prev,
            [type]: res.data.map((item) => ({
              type: item.type,
              value: item.value,
            })),
          }));

          setLabelsList((prev) => {
            if (type === "search" || type === "tag") {
              return {
                ...prev,
                [type]: { type: res.data.type, value: res.data.value },
              };
            }
          });
        });
    }
  };

  const transformDatetime = (filter) => {
    if (filter === "m") {
      return `${today.getFullYear()}-${
        today.getMonth() + 1 < 10
          ? `0${today.getMonth() + 1}`
          : today.getMonth() + 1
      }`;
    } else {
      return `${today.getFullYear()}-${
        today.getMonth() + 1 < 10
          ? `0${today.getMonth() + 1}`
          : today.getMonth() + 1
      }-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()}`;
    }
  };

  return { today, getFilter, getDataByCategory, transformDatetime };
};

export default useData;
