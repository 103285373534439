import styled from "styled-components";
import typography from "../../styles/constants/typography";
import ModalLayout from "./layout";

const Comment = styled.p`
  margin: 30px 0;
  font-size: ${typography.size.medium}px;
`;

const DeleteNoticeModal = ({ closeHandler, deleteHandler }) => {
  return (
    <ModalLayout
      title="정말 삭제하시겠습니까?"
      buttonText="삭제하기"
      subButtonText="취소하기"
      subButtonHandler={closeHandler}
      clickHandler={deleteHandler}
    >
      <Comment></Comment>
    </ModalLayout>
  );
};

export default DeleteNoticeModal;
