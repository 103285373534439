import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import colors from "../../styles/constants/colors";

//data
import { SportArray, FacilityArray } from "../../data/activity";

//components
import DropDown from "../designedActivity/dropDownforActivity";
import {
  TableFilterContainer,
  TableFilterContent,
  TableWrapper,
  Wrapper,
  WrapperRow,
  AbsoluteWrapper,
  Icon,
  Text,
  TableContent,
  TableFooter,
  TableColumn,
  TableFooterPaging,
  WrapperColmun,
} from "../../layouts/layoutStorage";
import ImageCrop from "../../components/designedComponents/imageCrop";
import PaymentCalendar from "../../assets/icon/payment_calendar.png";

//img
import XIcon from "../../assets/icon/tagDelete.png";
import AddIcon from "../../assets/icon/careerAddButton.png";
import DeleteIcon from "../../assets/icon/careerDeleteButton.png";
import CameraAdd from "../../assets/icon/camerAdd.png";
import ImageDelete from "../../assets/icon/imageDeleteIcon.png";
import Magnifying from "../../assets/icon/magnify.png";
import CloseButton from "../../assets/icon/modalCloseButton.png";
import BlueCheck from "../../assets/icon/bluecheck.png";
import SearchIcon from "../../assets/icon/search.png";
import SearchIconB from "../../assets/icon/searchIconB.png";
import LocationIcon from "../../assets/icon/locationGrey.png";
import ClockIcon from "../../assets/icon/clockIcon.png";
import PlusIconGrey from "../../assets/icon/plusIconGrey.png";
import Curriculum from "../designedActivity/curriculum";
import CurriculumSection from "../designedActivity/curriculumSection";
import DatePicker from "../designedComponents/datePicker";

const W = window.innerWidth;

const PBActivityNormalInfo = ({ open, data, setData, deleteD, setDelete, setImageFromFile, ImgChange, startDate, setStartDate, endDate, setEndDate }) => {
  //커리큘럼 입력
  const ref = useRef();
  const boxRef = useRef();
  // const { content, title } = curriculum;

  // let NewDate = new Date();
  // let NewYear = NewDate.getFullYear();
  // let NewMonth = NewDate.getMonth() + 1;
  // let NewDay = NewDate.getDate();
  // if (NewMonth < 10) {
  //   NewMonth = `0${NewMonth}`;
  // }

  // if (NewDay < 10) {
  //   NewDay = `0${NewDay}`;
  // }

  // const [startDate, setStartDate] = useState({
  //   date_y: data.start_date ? data?.start_date.split(".")[0] : NewYear,
  //   date_m: data.start_date ? data?.start_date.split(".")[1] : NewMonth,
  //   date_d: data.start_date ? data?.start_date.split(".")[2] : NewDay,
  // });
  // const [endDate, setEndDate] = useState({
  //   date_y: data.end_date ? data?.end_date.split(".")[0] : NewYear,
  //   date_m: data.end_date ? data?.end_date.split(".")[1] : NewMonth,
  //   date_d: data.end_date ? data?.end_date.split(".")[2] : NewDay,
  // });

  const [curriculum, setCurriculum] = useState({
    content: "",
    time: "",
    title: "",
  });
  const [startDatePicker, setStartDatePicker] = useState(false);
  const [endDatePicker, setEndDatePicker] = useState(false);
  const [location, setLocation] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [LcSearching, setLcSearching] = useState(false);
  const [partner, setPartner] = useState("");
  const [searching, setSearching] = useState(false);
  const [partnerData, setPartnerData] = useState([]);
  const [category, setCategory] = useState({
    big: "대분류 선택",
    small: "소분류 선택",
  });
  const [searchBox, setSearchBox] = useState(false);

  const onChangeCurriculum = (e) => {
    ref.current.style.height = "auto";
    ref.current.style.height = ref.current.scrollHeight + "px";
    const { value, name } = e.target;
    setCurriculum({
      ...curriculum,
      [name]: value,
    });
  };

  //커리큘럼 추가
  const addCurriculum = (day) => {
    if (curriculum.content.length > 0 && curriculum.title.length > 0 && curriculum.time.length > 0) {
      let copy = [...data.curriculum[day]];
      copy.push(curriculum);
      setData((prev) => ({ ...prev, curriculum: copy }));
      setCurriculum({
        content: "",
        time: "",
        title: "",
      });
    }
  };

  //커리큘럼 삭제
  const deleteCurriculum = (idx, item, date) => {
    let copy = [...data.curriculum[date]];
    let obj = { ...data.curriculum };
    let result = copy.filter((_, id) => id !== idx);
    obj[date] = result;
    setData((prev) => ({ ...prev, curriculum: obj }));

    var copyDel = deleteD;
    var copyDelValue = copyDel.curriculum;
    copyDelValue[copyDelValue.length] = item;

    setDelete((prev) => ({ ...prev, curriculum: copyDelValue }));
  };

  //커리큘럼 수정
  const curriculumChange = (key, value, idx, date) => {
    let copy = [...data.curriculum[date]];
    let obj = { ...data.curriculum };
    copy[idx][key] = value;
    obj[date] = copy;
    setData((prev) => ({ ...prev, curriculum: obj }));
  };

  const boxHandleClose = (e) => {
    if (searchBox && (!boxRef.current || !boxRef.current.contains(e.target))) {
      setSearchBox(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", boxHandleClose);

    return () => {
      window.removeEventListener("click", boxHandleClose);
    };
  }, [searchBox]);

  const searchPartnerFunc = () => {
    setSearching(true);
    let token = localStorage.getItem("token");
    const frm = new FormData();
    frm.append("mode", "pb_partner_search");
    frm.append("token", token);
    frm.append("text", partner);
    axios
      .post("https://ai.semos.kr/semos_admin/activity/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data.list;
        setPartnerData(Value);
      });
  };

  useEffect(() => {
    searchPartnerFunc();
  }, [partner]);

  useEffect(() => {
    let token = localStorage.getItem("token");
    axios
      .post(
        "https://ai.semos.kr/semos_admin/activity/data",
        {
          mode: "schedule_list",
          token: token,
          search: location,
          partner: data.partner,
        },
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var List = Value.schedule_list;

        let ListCopy = [...Value.schedule_list];
        if (Status == "success") {
          setLocationList(List);
          // const totalValues = ListCopy.map((item) => item.id); //전체 장소 리스트의 id 배열
          // const addValues = data.place.map((item) => item.schedule_id); //선택된 장소 리스트의 id 배열
          // console.log(addValues);
          // console.log(totalValues);
          // let notAddValues = totalValues.filter((item) => !addValues.includes(item)); // 선택되지 않은 장소 리스트의 id 배열
          // let list = ListCopy.filter((item) => notAddValues.includes(item.id)); // 선택되지 않은 장소 리스트의 객체 배열
          // console.log(list);
        } else {
        }
      });
  }, [data.partner?.length, location]);

  useEffect(() => {
    setCurriculum({
      content: "",
      time: "",
      title: "",
    });
    setLocation("");
    setLcSearching(false);
    setPartner("");
    setSearchBox(false);
    setSearching(false);
    setPartnerData([]);
    setCategory({
      big: "대분류 선택",
      small: "소분류 선택",
    });
  }, [open]);

  //이미지 업로드
  const addImg = ({ target: { files } }) => {
    if (data.background) {
      if (files.length + data.background?.length > 4) {
        return alert("배경 이미지는 최대 4개까지 등록하실 수 있습니다.");
      } else {
        setImageFromFile({
          file: files[0],
          setImageUrl: ({ result }) => {
            let copiedBackground = [...data.background];
            copiedBackground.push({
              id: "new",
              image: result,
            });
            setData((prev) => ({ ...prev, background: copiedBackground }));
          },
        });
      }
    } else {
      setImageFromFile({
        file: files[0],
        setImageUrl: ({ result }) => {
          let copiedBackground = [...data.background];
          copiedBackground.push({
            id: "new",
            image: result,
          });
          setData((prev) => ({ ...prev, background: copiedBackground }));
        },
      });
    }
  };

  //카테고리 추가
  const addCategory = () => {
    let copy = [...data.sport];

    let result = copy.filter((item) => item.big_category == category.big && item.sport == category.small);

    if (result.length > 0) {
      return;
    } else if (category.big == "대분류 선택" || category.small == "소분류 선택") {
      return;
    } else {
      copy.push({
        id: "new",
        big_category: category.big,
        sport: category.small,
      });
      setData((prev) => ({ ...prev, sport: copy }));
    }

    setCategory({
      big: "대분류 선택",
      small: "소분류 선택",
    });
  };

  const deleteCategory = (select, idx) => {
    let copy = data.sport;
    let copyD = deleteD.sport;

    copyD[copyD.length] = select;

    copy.splice(idx, 1);

    setData((prev) => ({ ...prev, sport: copy }));
    setDelete((prev) => ({ ...prev, sport: copyD }));
  };

  const deletePartner = (select, idx) => {
    let copy = data.partner;
    let copyD = deleteD.partner;

    copyD[copyD.length] = select;

    copy.splice(idx, 1);

    setData((prev) => ({ ...prev, partner: copy }));
    setDelete((prev) => ({ ...prev, partner: copyD }));
  };

  const deleteImage = (index) => {
    if (window.confirm("사진을 삭제하시겠습니까?")) {
      var copy = [...data.background];

      var deleteData = copy[index];
      var deleted = [...deleteD.background];
      deleted.push(deleteData);

      copy.splice(index, 1);

      setDelete((prev) => ({ ...prev, background: deleted }));
      setData((prev) => ({ ...prev, background: copy }));
    }
  };

  const facilitySelect = (item) => {
    var copy = { ...data.facility_list };
    var tf = Number(data.facility_list[item]);

    if (tf) {
      copy[item] = 0;
    } else {
      copy[item] = 1;
    }

    setData((prev) => ({ ...prev, facility_list: copy }));
  };

  const statusConvert = (status) => {
    switch (status) {
      case "활성화":
        return <span style={{ color: colors.Bl06, fontWeight: "500" }}>활성화</span>;
      case "승인 요청":
        return <span style={{ color: colors.Red03, fontWeight: "500" }}>승인 요청</span>;

      case "비노출":
        return <span style={{ color: colors.Gr03, fontWeight: "500" }}>비노출</span>;
    }
  };

  const settingPartner = (id, name) => {
    var copy = [...data.partner];
    if (data.partner.length === 0) {
      copy[copy.length] = {
        change: "yes",
        id: "new",
        partner_id: id,
        partner_name: name,
      };

      setData((prev) => ({
        ...prev,
        partner: copy,
      }));

      setPartner("");
    } else {
      window.alert("파트너 선택은 1명만 가능합니다.");
    }
  };

  // const getLocation = (loc) => {
  //   var co = { ...data };
  //   var copy = co.place;
  //   var obj = { ...loc };
  //   var scheid = obj.id;
  //   obj.id = "new";
  //   obj.schedule_id = scheid;
  //   copy.push(obj);
  //   setData((prev) => ({ ...prev, place: copy }));
  //   setLocation("");
  // };

  const getLocation = (item) => {
    var co = { ...data };
    var copy = co.place;
    var obj = { ...item };
    var scheid = obj.id;
    obj.id = "new";
    obj.schedule_id = scheid;
    copy.push(obj);

    for (let i = 0; i < copy.length; i++) {
      let content = copy[i];
      let loid = content.id;
      if (loid == item.id) {
        window.alert("중복되는 장소가 있습니다.");
        return;
      }
    }

    setLocationList((prev) => prev.filter((val) => val.id !== item.id));
    setData((prev) => ({ ...prev, place: copy }));
    setLocation("");
  };
  console.log(data.place)

  const deleteLocation = (id, idx) => {
    var co = { ...data };
    var copy = co.place;
    var copyD = copy[idx];
    copy.splice(idx, 1);
    setData((prev) => ({ ...prev, place: copy }));

    var copyDel = deleteD;
    var copyDelValue = copyDel.place;
    copyDelValue[copyDelValue.length] = copyD;

    setDelete((prev) => ({ ...prev, place: copyDelValue }));
  };

  const curriculumComponent = () => {
    if (data.duration === "다회차") {
      return Array.from(
        {
          length: Number(data.lesson_day) === 0 || !data.lesson_day ? 2 : Number(data.lesson_day),
        },
        (_, index) => (
          <>
            <Text className="f12" color={colors.Gr04} style={{ marginTop: 16 }}>{`${index + 1}회차`}</Text>

            <CurriculumSection index={index} length={index * 40} day={`day${index + 1}`} data={data} setData={setData} />

            {data.curriculum[`day${index + 1}`]?.map((item, idx) => (
              <Curriculum
                length={index * 40 + index}
                iconImg={DeleteIcon}
                titleValue={item.title}
                titleOnChange={(e) => {
                  const value = e.target.value;
                  curriculumChange("title", value, idx, `day${index + 1}`);
                }}
                dropData={item.time}
                dropSetData={curriculumChange}
                idx={idx}
                type="curriculum"
                contentValue={item.content}
                contentOnChange={(e) => {
                  const value = e.target.value;
                  curriculumChange("content", value, idx, `day${index + 1}`);
                }}
                curriculumFunc={() => deleteCurriculum(idx, item, `day${index + 1}`)}
                day={`day${index + 1}`}
              />
            ))}
          </>
        )
      );
    } else if (data.duration === "원데이") {
      return (
        <>
          <CurriculumSection day={`day1`} index={0} data={data} setData={setData} />

          {Object.keys(data.curriculum).length > 0 &&
            data.curriculum[`day1`].map((item, idx) => {
              return (
                <Curriculum
                  key={`curriculum_lets_go_${idx}`}
                  iconImg={DeleteIcon}
                  titleValue={item.title}
                  titleOnChange={(e) => {
                    const value = e.target.value;
                    curriculumChange("title", value, idx, `day1`);
                  }}
                  dropData={item.time}
                  dropSetData={curriculumChange}
                  idx={idx}
                  type="curriculum"
                  contentValue={item.content}
                  contentOnChange={(e) => {
                    const value = e.target.value;
                    curriculumChange("content", value, idx, `day1`);
                  }}
                  day={`day1`}
                  curriculumFunc={() => deleteCurriculum(idx, item, `day1`)}
                />
              );
            })}
        </>
      );
    }
  };

  const startDateSelect = (d, month, next) => {
    if (month) {
      let Year = startDate.date_y;
      let Mon = 0;
      if (next) {
        Mon = Number(startDate.date_m);
      } else {
        Mon = Number(startDate.date_m) - 2;
      }

      let Day = Number(1);

      let NewDate = new Date(Year, Mon, Day);

      let NewYear = NewDate.getFullYear();
      let NewMonth = NewDate.getMonth() + 1;
      let NewDay = NewDate.getDate();

      if (NewMonth < 10) {
        NewMonth = `0${NewMonth}`;
      }

      if (NewDay < 10) {
        NewDay = `0${NewDay}`;
      }

      setTimeout(() => {
        setStartDatePicker(true);
      }, 10);

      setStartDate({
        ...startDate,
        date_y: NewYear,
        date_m: NewMonth,
        date_d: NewDay,
      });
      setData((prev) => ({
        ...prev,
        start_date: `${NewYear}.${NewMonth}.${NewDay}`,
      }));
    } else {
      let Year = startDate.date_y;
      let Mon = Number(startDate.date_m);
      let Day = Number(1);

      let NewDate = new Date(Year, Mon, Day);

      let NewYear = NewDate.getFullYear();
      let NewMonth = NewDate.getMonth();
      if (NewMonth < 10) {
        NewMonth = `0${NewMonth}`;
      }
      if (d < 10) {
        d = `0${d}`;
      }

      setStartDate({ ...startDate, date_d: d });
      setData((prev) => ({
        ...prev,
        start_date: `${NewYear}.${NewMonth}.${d}`,
      }));
    }
  };

  const endDateSelect = (d, month, next) => {
    if (month) {
      let Year = endDate.date_y;
      if (next) {
        var Mon = Number(endDate.date_m);
      } else {
        var Mon = Number(endDate.date_m) - 2;
      }

      let Day = Number(1);

      let NewDate = new Date(Year, Mon, Day);

      let NewYear = NewDate.getFullYear();
      let NewMonth = NewDate.getMonth() + 1;
      let NewDay = NewDate.getDate();

      if (NewMonth < 10) {
        NewMonth = `0${NewMonth}`;
      }

      if (NewDay < 10) {
        NewDay = `0${NewDay}`;
      }

      setTimeout(() => {
        setEndDatePicker(true);
      }, 10);

      setEndDate({
        ...endDate,
        date_y: NewYear,
        date_m: NewMonth,
        date_d: NewDay,
      });
      setData((prev) => ({
        ...prev,
        end_date: `${NewYear}.${NewMonth}.${NewDay}`,
      }));
    } else {
      let Year = endDate.date_y;
      let Mon = Number(endDate.date_m);
      let Day = Number(1);

      let NewDate = new Date(Year, Mon, Day);

      let NewYear = NewDate.getFullYear();
      let NewMonth = NewDate.getMonth();
      if (NewMonth < 10) {
        NewMonth = `0${NewMonth}`;
      }
      if (d < 10) {
        d = `0${d}`;
      }

      setEndDate({ ...endDate, date_d: d });
      setData((prev) => ({ ...prev, end_date: `${NewYear}.${NewMonth}.${d}` }));
    }
  };

  return (
    <div id="normal">
      <Styler pl={36} pr={36}>
        {/* 액티비티 이름 */}
        <Styler flex={true} align="center" pb={8}>
          <SmallTitle>이름</SmallTitle>
          <Wrapper
            // jc="center"
            width="26rem"
            padding="calc(1rem * (9 / 14)) calc(1rem * (16 / 14))"
            border={`1px solid ${colors.Gr02}`}
          >
            <input
              style={{ width: "100%", color: colors.Gr05 }}
              placeholder="액티비티 이름을 입력해 주세요."
              value={data.name}
              onChange={(e) => setData((prev) => ({ ...prev, name: e.target.value }))}
            />
          </Wrapper>
        </Styler>

        <Styler flex={true} justify="space-between" align="flex-start" pb={8}>
          <SmallTitle style={{ marginTop: "calc(1rem * (9 / 14))" }}>파트너</SmallTitle>

          <WrapperColmun width="100%">
            <Wrapper
              ref={boxRef}
              position="relative"
              width="calc(1rem * 298 / 14)"
              padding="calc(1rem * (9 / 14)) calc(1rem * (16 / 14))"
              border={searchBox ? `1px solid ${colors.Gr04}` : `1px solid ${colors.Gr02}`}
            >
              <input
                style={{ width: "100%", color: colors.Gr05 }}
                placeholder="파트너명, 파트너ID검색"
                value={partner}
                onFocus={() => setSearchBox(true)}
                onChange={(e) => setPartner(e.target.value)}
              />
              <Icon width={`calc(1rem * (17 / 14))`} cursor={`pointer`} src={data.partner ? CloseButton : SearchIcon} onClick={() => setPartner("")} />
              {searchBox && (
                <Box>
                  {partnerData.map((el) => (
                    <PartItem
                      onClick={() => {
                        settingPartner(el.id, el.name);
                        setSearchBox(false);
                      }}
                    >{`${el.name} (${el.id})`}</PartItem>
                  ))}
                </Box>
              )}
              {/* {box ? (
                <AbsoluteWrapper
                  radius="16px"
                  padding="24px"
                  ai="flex-start"
                  z="50"
                  top="-1px"
                  left="-1px"
                  bgc={colors.White}
                  width="31rem"
                  height="39rem"
                  style={{
                    boxShadow: `0px 4px 16px ${colors.B20}`,
                  }}
                >
                  <WrapperColmun width="100%">
                    <WrapperRow jc="space-between" margin="0 0 2rem 0">
                      <Text className="f18">센터 선택</Text>
                      <Icon
                        width="calc(1rem * (24 / 14))"
                        height="calc(1rem * (24 / 14))"
                        cursor="pointer"
                        onClick={() => {
                          setSearchCenter("");
                          setSearchBox(false);
                        }}
                        src={CloseButton}
                      />
                    </WrapperRow>

                    <Wrapper
                      // jc="center"
                      width="100%"
                      padding="calc(1rem * (9 / 14)) calc(1rem * (16 / 14))"
                      border={searching ? `1px solid ${colors.Gr04}` : `1px solid ${colors.Gr02}`}
                    >
                      <input
                        className="f14"
                        style={{ width: "100%", color: colors.Gr05 }}
                        placeholder="센터명, 센터ID 검색"
                        value={searchCenter}
                        onKeyDown={(e) => {
                          if (e.key == "Enter") {
                            selectCenter();
                          }
                        }}
                        onChange={(e) => setSearchCenter(e.target.value)}
                      />
                      <Icon width={`calc(1rem * (17 / 14))`} cursor={`pointer`} src={searching ? SearchIconB : SearchIcon} onClick={selectCenter} />
                    </Wrapper>

                    <WrapperColmun
                      margin="8px 0 0 0"
                      height="calc(39rem - (1rem * (18 / 14) + 4rem + 50px))"
                      style={{
                        overflow: "auto",
                      }}
                    >
                      {partnerData.map((item) => (
                        <WrapperColmun
                          border={`1px solid ${colors.Gr02}`}
                          padding="14px 18px"
                          width="100%"
                          height="65px"
                          margin="0 0 8px 0"
                          cursor="pointer"
                          hvBgc={colors.Bl07}
                          hvBorder={`1px solid ${colors.Bl04}`}
                          onClick={() => {
                            // setData((prev) => ({
                            //   ...prev,
                            //   center: item.center_name,
                            //   center_id: item.center_id,
                            // }));
                            setSearchBox(false);
                            setSearching(false);
                            setSearchCenter("");
                            setPartnerData([]);
                          }}
                        >
                          <Text className="f12">{item.name}</Text>
                          <WrapperRow className="f10">
                            <Text ws="pre" color={colors.Gr04}>
                              ID{" "}
                            </Text>
                            <Text>{item.id}</Text>
                          </WrapperRow>
                        </WrapperColmun>
                      ))}
                    </WrapperColmun>
                  </WrapperColmun>
                </AbsoluteWrapper>
              ) : (
                ""
              )} */}
            </Wrapper>

            <TagContainer>
              {data.partner?.map((el, idx) => (
                <TagItem key={`${el.name}_${idx}`}>
                  <Wrapper jc="center" ai="center">
                    <TagText>{el.partner_name}</TagText>
                  </Wrapper>

                  <Xbutton src={XIcon} onClick={() => deletePartner(el, idx)} />
                </TagItem>
              ))}
            </TagContainer>
          </WrapperColmun>
        </Styler>

        <Styler flex={true} align="center" pb={8}>
          <SmallTitle>예약 마감</SmallTitle>
          <Wrapper
            cursor={`pointer`}
            position="relative"
            width="26rem"
            padding="calc(1rem * (9 / 14)) calc(1rem * (16 / 14)) calc(1rem * (9 / 14)) 0"
            border={`1px solid ${colors.Gr02}`}
            style={{ borderRight: "none" }}
            onClick={(e) => {
              e.stopPropagation();
              setStartDatePicker(true);
              setEndDatePicker(false);
            }}
          >
            <Icon margin={`0 0 0 6px`} width={`calc(1rem * (20 / 14))`} src={PaymentCalendar} />
            <div>
              <DatePicker
                Y={startDate && startDate.date_y}
                M={startDate && startDate.date_m}
                D={startDate && startDate.date_d}
                setDate={startDateSelect}
                status={startDatePicker}
                setStatus={setStartDatePicker}
              />
            </div>
            <div style={{ width: "100%", color: colors.Gr05, marginLeft: "4px" }}>{data.start_date ? data.start_date : "시작일"}</div>
          </Wrapper>
          <Wrapper
            position="relative"
            width="26rem"
            cursor="pointer"
            padding="calc(1rem * (9 / 14)) calc(1rem * (16 / 14)) calc(1rem * (9 / 14)) 0"
            border={`1px solid ${colors.Gr02}`}
            onClick={(e) => {
              e.stopPropagation();
              setEndDatePicker(true);
              setStartDatePicker(false);
            }}
          >
            <Icon margin={`0 0 0 6px`} width={`calc(1rem * (20 / 14))`} src={PaymentCalendar} />
            <div>
              <DatePicker
                Y={endDate && endDate.date_y}
                M={endDate && endDate.date_m}
                D={endDate && endDate.date_d}
                setDate={endDateSelect}
                status={endDatePicker}
                setStatus={setEndDatePicker}
              />
            </div>
            <div style={{ width: "100%", color: colors.Gr05, marginLeft: "4px" }}>{data.end_date ? data.end_date : "마감일"}</div>
          </Wrapper>
        </Styler>

        {/* 액티비티 상태 */}
        <Styler flex={true} justify="flex-start" align="center" pb={8}>
          <SmallTitle>액티비티 상태</SmallTitle>

          <Wrapper position="relative" width="100%">
            <DropDown
              width="26rem"
              data={statusConvert(data.status)}
              setData={setData}
              option={[
                {
                  text: "활성화",
                  value: "활성화",
                  color: colors.Bl06,
                },
                {
                  text: "승인 요청",
                  value: "승인 요청",
                  color: colors.Red03,
                },
                {
                  text: "비노출",
                  value: "비노출",
                  color: colors.Gr03,
                },
              ]}
              no={false}
              keyName="status"
              defaultText="상태"
            />
          </Wrapper>
        </Styler>

        {/* 회차 설정 */}
        {/* <Styler flex={true} align="center" pb={8}>
          <SmallTitle>회차 설정</SmallTitle>
          <Wrapper ai="flex-end">
            <Wrapper width="calc(1rem * 124 / 14)" padding="calc(1rem * (9 / 14)) calc(1rem * (16 / 14))" border={`1px solid ${colors.Gr02}`}>
              <input
                style={{ width: "100%", color: colors.Gr05 }}
                placeholder="액티비티 이름을 입력해 주세요."
                value={data.name}
                onChange={(e) => setData((prev) => ({ ...prev, name: e.target.value }))}
              />
            </Wrapper>
            <Text margin="0 0 0 4px" className="f14" weight="500" color={colors.Gr03}>
              회차
            </Text>
          </Wrapper>
        </Styler> */}

        <Styler>
          {/* 배경 이미지 */}
          <Wrapper margin="0 0 16px 0" jc="center" ai="center">
            <SmallTitle style={{ minWidth: "0" }} className="f14">
              배경 이미지
            </SmallTitle>
            <div style={{ width: "8px" }} />
            <Indicator>{data.background?.length}/4</Indicator>
          </Wrapper>

          <WrapperRow width="100%">
            <BackgroundImageBox htmlFor="background" id="problem">
              <BackgroundImageSelector src={CameraAdd} />
              <span className="f10">배경 이미지</span>
            </BackgroundImageBox>
            <BackgroundImageInput type="file" id="background" accept="image/*" onChange={addImg} />

            <WrapperRow>
              {data.background?.map((img, i) => (
                <BackgroundImageDiv key={`${img}_${i}`}>
                  <BackgroundImage onClick={() => window.open(img.image, "_blank")} src={img.image ? img.image : img} />
                  <ImageDeletion src={ImageDelete} onClick={() => deleteImage(i)} />
                </BackgroundImageDiv>
              ))}
            </WrapperRow>
          </WrapperRow>

          <Styler flex={true} justify="space-between" align="center" pb={16} pt={24}>
            <SmallTitle>액티비티 카테고리</SmallTitle>
          </Styler>
          <WrapperRow ai="center" gap="8px" width="100%" jc="space-between">
            <DropDown
              z={65}
              width="100%"
              data={category.big}
              type="object"
              setData={setCategory}
              doubleDropDown={true}
              reset={() =>
                setCategory({
                  big: category.big,
                  small: "소분류 선택",
                })
              }
              option={[
                {
                  text: "대분류 선택",
                  value: "대분류 선택",
                },
                {
                  text: "워터스포츠",
                  value: "워터스포츠",
                },
                {
                  text: "윈터스포츠",
                  value: "윈터스포츠",
                },
              ]}
              no={false}
              keyName="big"
              defaultText="대분류 선택"
            />
            <DropDown
              width="100%"
              type="object"
              z={65}
              data={category.small}
              setData={setCategory}
              option={
                category.big == "대분류 선택"
                  ? [
                      {
                        text: "소분류 선택",
                        value: "소분류 선택",
                      },
                    ]
                  : category.big == "윈터스포츠"
                  ? [
                      {
                        text: "소분류 선택",
                        value: "소분류 선택",
                      },
                      {
                        text: "스노보드",
                        value: "스노보드",
                      },
                      {
                        text: "스키",
                        value: "스키",
                      },
                    ]
                  : [
                      {
                        text: "소분류 선택",
                        value: "소분류 선택",
                      },
                      {
                        text: "스쿠버다이빙",
                        value: "스쿠버다이빙",
                      },
                      {
                        text: "프리다이빙",
                        value: "프리다이빙",
                      },
                      {
                        text: "서핑",
                        value: "서핑",
                      },
                      {
                        text: "패들보드",
                        value: "패들보드",
                      },
                      {
                        text: "수영/스노클링",
                        value: "수영/스노클링",
                      },
                      {
                        text: "수상레저",
                        value: "수상레저",
                      },
                    ]
              }
              no={false}
              keyName="small"
              defaultText="소분류 선택"
            />

            <Icon width={`ccalc(1rem * (17 / 14) + 16px)`} height={`calc(1rem * (17 / 14) + 16px)`} cursor={`pointer`} src={AddIcon} onClick={addCategory} />
          </WrapperRow>
          <TagContainer>
            {data.sport?.map((el, idx) => (
              <TagItem key={`${el.big_category}_${el.sport}`}>
                <Wrapper jc="center" ai="center">
                  {el.big_category ? (
                    <>
                      <TagText className="f13">{el.big_category}</TagText>
                      <Wrapper ai="center" width="1px" margin="0 7px" height="16px" bgc={colors.Gr02} />
                    </>
                  ) : (
                    ""
                  )}

                  <TagText>{el.sport}</TagText>
                </Wrapper>

                <Xbutton
                  src={XIcon}
                  onClick={() => deleteCategory(el, idx)}
                  // onClick={() => deleteKeyWords("recommend", el, idx)}
                />
              </TagItem>
            ))}
          </TagContainer>
        </Styler>

        <Styler pt={16}>
          <Styler flex={true} justify="space-between" align="center">
            <SmallTitle
              style={{
                alignSelf: "flex-start",
                paddingTop: "calc(1rem * 10 / 14)",
              }}
            >
              액티비티 장소
            </SmallTitle>
            <WrapperColmun width="100%">
              <SearchBox
                style={{
                  border: LcSearching ? `1px solid ${colors.Gr04}` : `1px solid ${colors.Gr02}`,
                }}
              >
                <SearchInput
                  onFocus={() => setLcSearching(true)}
                  onBlur={() => {
                    setTimeout(() => {
                      setLcSearching(false);
                    }, 100);
                  }}
                  placeholder="불러올 일정이름 혹은 지역을 검색해 보세요."
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
                <SearchIconImg src={Magnifying} onClick={() => location && setLocation("")} />
              </SearchBox>
              {LcSearching && (
                <Styler pb={2} pt={8}>
                  {locationList.length ? (
                    <SearchListBox length={2}>
                      {locationList?.map((el, i) => {
                        let dayDuplicate = false;

                        for (let i2 = 0; i2 < data.place.length; i2++) {
                          if (data.place[i2].schedule_id == el.id) {
                            dayDuplicate = true;
                          }
                        }

                        if (!dayDuplicate) {
                          let dayText = "";

                          for (let i = 0; i < el.day.length; i++) {
                            if (i) {
                              dayText = dayText + `, ${el.day[i]}`;
                            } else {
                              dayText = `${el.day[i]}`;
                            }
                          }

                          return (
                            <SearchItem key={`location_${i}`} last={i + 1 === locationList.length} onClick={() => getLocation(el)}>
                              <LocationContainer>
                                <SearchText className="f12">{el.title}</SearchText>
                                <Styler align="center" className="f10" flex={true} pt={4}>
                                  <Styler align="center" flex={true} pr={4}>
                                    <SmallIcon src={LocationIcon} />
                                    <SmallText color={colors.Gr04}>{`${el.si} ${el.gu}`}</SmallText>
                                  </Styler>
                                  <Styler align="center" flex={true} pr={4}>
                                    <SmallIcon src={ClockIcon} />
                                    <SmallText color={colors.Gr05}>{dayText}</SmallText>
                                  </Styler>
                                  <SmallText color={colors.Bl07}>{`${el.start} ~ ${el.end}`}</SmallText>
                                </Styler>
                              </LocationContainer>
                              <Icon width="calc(1rem * 24/14)" height="calc(1rem * 24/14)" src={PlusIconGrey} cursor="pointer" />
                            </SearchItem>
                          );
                        }
                      })}
                    </SearchListBox>
                  ) : (
                    <SearchListEmpty>
                      {data.partner ? (
                        <p>
                          등록된 액티비티 <span className="text-blue">일정</span>이 없어요! <br /> 일정 등록/수정에서&nbsp;
                          <span className="text-blue">일정 등록 후 </span> 액티비티를 추가해주세요.
                        </p>
                      ) : (
                        <p>
                          파트너&nbsp;
                          <span className="text-blue">선택&nbsp;</span>후 장소를 검색해 주세요.
                        </p>
                      )}
                    </SearchListEmpty>
                  )}
                </Styler>
              )}
              <TagContainer>
                {data.place?.map((el, idx) => (
                  <TagItem>
                    <TagText>{el.title}</TagText>
                    <Xbutton src={XIcon} onClick={() => deleteLocation(el.id, idx)} />
                  </TagItem>
                ))}
              </TagContainer>
            </WrapperColmun>
          </Styler>

          <Styler pt={16} flex={true} justify="space-between" align="center">
            <SmallTitle>편의시설</SmallTitle>

            {data.facility_list && (
              <FacilityBox>
                {FacilityArray.map((el, i) => (
                  <FacilityItem
                    key={`${el.id}_${i}_facility`}
                    selected={Number(data.facility_list[el.value])}
                    last={el.id % 4 === 1}
                    onClick={() => {
                      facilitySelect(el.value);
                    }}
                  >
                    <FacilityText className="f12" checked={Number(data.facility_list[el.value])}>
                      {el.name}
                    </FacilityText>
                    <CheckBox left="6.5px" checked={Number(data.facility_list[el.value])}>
                      {Number(data.facility_list[el.value]) > 0 && <Checked src={BlueCheck} />}
                    </CheckBox>
                  </FacilityItem>
                ))}
              </FacilityBox>
            )}
          </Styler>

          <Styler flex={true} pb={16} pt={24}>
            <SmallTitle>액티비티 요약 정보</SmallTitle>
          </Styler>

          <Styler flex={true} justify="space-between" align="center">
            {window.location.pathname.includes("new") ? (
              <DropDown
                data={data.duration}
                setData={setData}
                option={[
                  {
                    text: "원데이",
                    value: "원데이",
                  },
                  {
                    text: "다회차",
                    value: "다회차",
                  },
                ]}
                no={false}
                keyName="duration"
                defaultText="액티비티 기간"
              />
            ) : (
              <SelectBox>{data.duration}</SelectBox>
            )}

            <div style={{ width: "calc(1rem * 8/14)" }} />

            <DropDown
              data={data.type}
              setData={setData}
              option={[
                {
                  text: "레슨",
                  value: "레슨",
                },
                {
                  text: "자격증",
                  value: "자격증",
                },
                {
                  text: "이용권",
                  value: "이용권",
                },
                {
                  text: "투어",
                  value: "투어",
                },
              ]}
              no={false}
              keyName="type"
              defaultText="액티비티 유형"
            />

            <div style={{ width: "calc(1rem * 8 / 14)" }} />

            <DropDown
              z={62}
              data={data.location_type}
              setData={setData}
              option={[
                {
                  text: "실내",
                  value: "실내",
                },
                {
                  text: "실외",
                  value: "실외",
                },
                {
                  text: "실내+야외",
                  value: "실내+야외",
                },
              ]}
              no={false}
              keyName="location_type"
              defaultText="시설 유형"
            />
          </Styler>

          {data.duration == "다회차" && (
            <Styler pt={20} direction="row">
              <Wrapper border={`1px solid ${colors.Gr02}`} width="calc(1rem * 200/14)" padding="8px 0" margin="0 8px 0 0">
                <input
                  maxLength={3}
                  style={{ width: "100%", textAlign: "center" }}
                  value={data.lesson_day}
                  onChange={(e) => setData((prev) => ({ ...prev, lesson_day: e.target.value }))}
                />
              </Wrapper>

              <Text>회차 과정</Text>
            </Styler>
          )}

          <Styler direction="column" flex={true} pb={36} pt={24}>
            <SmallTitle>커리큘럼 등록</SmallTitle>

            {data.curriculum && curriculumComponent()}
          </Styler>
        </Styler>
      </Styler>
    </div>
  );
};

export default PBActivityNormalInfo;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  padding-top: ${({ pt }) => `calc(1rem * ${pt} / 14)`};
  padding-bottom: ${({ pb }) => `calc(1rem * ${pb} / 14)`};
  padding-left: ${({ pl }) => `calc(1rem * ${pl} / 14)`};
  padding-right: ${({ pr }) => `calc(1rem * ${pr} / 14)`};
  height: ${({ height }) => height}px;
  color: ${colors.Gr05};
`;

const TitleBox = styled.div``;

const Title = styled.div`
  font-weight: 600;

  letter-spacing: -0.02em;
  color: ${({ red }) => (red ? colors.Red02 : colors.Gr05)};
`;

const Indicator = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${({ status }) => (status == "empty" ? colors.Red02 : colors.Gr03)};
`;

const Index = styled.span`
  color: ${({ red }) => (red ? colors.Red02 : colors.Gr05)};
`;

const SmallTitle = styled.div`
  font-weight: 500;
  line-height: 142%;
  display: flex;
  font-size: 1rem;

  color: ${({ red }) => (red ? colors.Red02 : colors.Gr05)};
  white-space: pre;
  min-width: 7rem;
`;

const BackgroundImageInput = styled.input`
  display: none;
`;

const BackgroundImageBox = styled.label`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  line-height: 132%;
  flex-direction: column;
  text-align: center;
  letter-spacing: -0.022em;
  color: ${colors.Gr03};
  border: 1px solid ${({ red }) => (red ? colors.Red02 : colors.Gr02)};
  border-radius: 8px;
  width: calc(1rem * 78 / 14);
  height: calc(1rem * 78 / 14);
  cursor: pointer;
`;

const BackgroundImageSelector = styled.img`
  object-fit: cover;
  width: calc(1rem * 32 / 14);
  height: calc(1rem * 32 / 14);

  margin-bottom: 2px;
`;

const BlueIndicator = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${({ status }) => (status == "empty" ? colors.Red02 : colors.Bl07)};
`;

const SportBoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
`;

const SportBox = styled.div`
  /* width: ${(W - 90) / 4}px; */
  width: 5.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 14px;
  padding: calc(1rem * 10 / 14) 0;
  border: 1px solid;
  border-radius: 8px;
  border-color: ${({ selected }) => (selected ? colors.Bl07 : colors.Gr02)};

  opacity: ${({ unselected }) => (unselected ? 0.3 : 1)};
  color: ${({ unselected }) => (unselected ? colors.Gr03 : colors.Gr05)};
`;

const SportIcon = styled.img`
  width: calc(1rem * 42 / 14);
  height: calc(1rem * 42 / 14);
`;

const SportText = styled.div`
  font-weight: 500;

  line-height: 142%;
  letter-spacing: -0.02em;
`;

const FullPackageContainer = styled.div`
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${colors.Gr02};
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RadioItem = styled.div`
  margin-left: 24px;
  display: flex;

  align-items: center;
`;

const RadioButton = styled.input`
  width: 1rem;
  height: 1rem;
  margin-left: 10px;
  border-color: ${({ status }) => status && colors.Red02};
  /* margin-bottom: 1px; */
`;

const SearchBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0px;
  padding-right: 12px;
  border-color: ${({ status }) => (status == "empty" ? colors.Red02 : colors.Gr02)};
`;

const SearchInput = styled.input`
  width: 90%;
  padding: calc(1rem * 12 / 14) 16px;

  &::placeholder {
    color: ${colors.Gr02};
  }
`;

const SearchIconImg = styled.img`
  width: calc(1rem * 24 / 14);
  height: calc(1rem * 24 / 14);
  cursor: pointer;
`;

const FacilityBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

/*  편의 시설 늘어나면 Marginbottom추가하기 */
const FacilityItem = styled.div`
  cursor: pointer;
  width: calc(1rem * 84 / 14);
  height: calc(1rem * 36 / 14);
  border: 1px solid;
  /* padding: calc(1rem * 8 / 14) calc(1rem * 12 / 14); */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${({ last }) => (last ? 0 : 12)}px;
  border-radius: 8px;
  border-color: ${({ selected, status }) => (status ? colors.Red02 : selected ? colors.Bl07 : colors.Gr02)};
`;

const FacilityText = styled.div`
  font-weight: 500;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;

  color: ${({ checked }) => (checked ? colors.Bl07 : colors.Gr03)};
`;

const TypeBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.2px solid;
  border-radius: 8px;
  padding: 10px 0;
  border-color: ${({ status }) => (status ? colors.Red02 : colors.Gr02)};
`;

const ActivityTypeBox = styled(TypeBox)`
  width: 57%;
`;

const FacilityTypeBox = styled(TypeBox)`
  width: 39%;
`;

const TypeText = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 142%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr04};
`;

const ModalOpener = styled.img`
  width: 14px;
  height: 18px;
  margin-left: 4px;
`;

const BackgroundImageDiv = styled.div`
  position: relative;
  width: calc(1rem * 78 / 14);
  height: calc(1rem * 78 / 14);
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-left: 16px;
  border-radius: 8px;
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* width: calc(1rem * 78 / 70 * 8);
  height: calc(1rem * 78 / 14); */
  border-radius: 8px;
`;

const ImageDeletion = styled.img`
  position: absolute;
  cursor: pointer;
  width: calc(1rem * 24 / 14);
  height: calc(1rem * 24 / 14);
  right: calc(1rem * -10 / 14);
  top: calc(1rem * -12 / 14);
  z-index: 3;
`;

const ScrollBox = styled.div`
  display: flex;
  /* max-width: ${({ width }) => (width ? `calc(100% - ${width})` : 0)}; */
  width: 100%;
  /* width: calc(var(--vw, 1vw) * 100); */
  overflow-y: hidden;
  /* max-width: calc(1rem * 747 / 14 - 30px) !important; */
  /* padding-top: 12px; */

  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SearchListBox = styled.div`
  ${({ length }) => length && `border: 1px solid ${colors.Gr02}`};
  max-height: 400px;
  overflow: hidden auto;
  /* border-radius: 6px; */
`;

const LocationContainer = styled.div``;

const SearchItem = styled.div`
  border-bottom: 1px solid ${colors.Gr02};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 8px 17px 18px;
  ${({ last }) => last && "border: none;"}
  cursor: pointer;
  :hover {
    background-color: ${colors.Bl01};
  }
`;

const AddSearchItem = styled(SearchItem)`
  border: none;
  background-color: ${colors.Bl01};
  border-radius: 6px;
`;

const SearchText = styled.div`
  font-weight: 500;
  /* font-size: 12px; */
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
`;

const PlusIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const SmallIcon = styled.img`
  width: calc(1rem * 13 / 14);
  height: calc(1rem * 13 / 14);
  margin-right: 2px;
`;

const SmallText = styled.div`
  font-weight: 500;
  /* font-size: 10px; */
  line-height: 132%;
  display: flex;
  align-items: center;
  letter-spacing: -0.022em;
  color: ${({ color }) => color};
`;

const TagContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  /* margin-top: 10px; */
`;

const TagItem = styled.div`
  position: relative;
  border: 1.2px solid ${colors.Gr02};
  padding: 6px 12px;
  display: flex;
  align-items: center;
  background-color: ${colors.Gr01};
  border-radius: 24px;
  margin-right: 9px;
  margin-top: 8px;
`;

const TagText = styled.span`
  font-weight: 500;
  /* font-size: 13px; */
  line-height: 142%;
  /* align-items: center; */
  /* text-align: center; */
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  max-width: ${W - 64}px;
  word-break: break-all;
  text-overflow: ellipsis;
`;

const Xbutton = styled.img`
  cursor: pointer;
  /* position: absolute;
  right: 6px;
  top: 6px; */
  width: 12px;
  height: 18px;
  margin-left: 4px;
`;

const NameBox = styled.div`
  border: 1px solid ${({ red }) => (red ? colors.Red02 : colors.Gr02)};
  /* border-radius: 4px; */
  /* padding-left: 24px; */
  width: 100%;
`;

const CheckBox = styled.div`
  width: calc(1rem * 15 / 14);
  height: calc(1rem * 15 / 14);
  border: 1px solid;
  border-color: ${({ checked }) => (checked ? colors.Bl07 : colors.Gr03)};
  border-radius: 4px;
  margin-left: ${({ left }) => left};
`;

const Checked = styled.img`
  width: 100%;
  height: 100%;
`;

const OptionBox = styled.div`
  cursor: pointer;
  width: 49%;
  color: ${({ checked }) => (checked ? colors.Bl07 : colors.Gr03)};
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-color: ${({ checked }) => (checked ? colors.Bl07 : colors.Gr02)};
  padding: calc(1rem * 8 / 14) 0;
  border-radius: 8px;
`;
const Textarea = styled.textarea`
  outline: none;
  border: none;
  resize: none;
  width: 100%;
  color: ${colors.Gr05};
`;

const Box = styled.div`
  width: 100%;
  position: absolute;
  top: calc(1rem * (46 / 14));
  left: 0;
  overflow: auto;
  z-index: 300000;
  height: 200px;
  background-color: white;
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
`;

const PartItem = styled.div`
  border-bottom: 1px solid ${colors.Gr02};
  padding: calc(1rem * 8 / 14);
  :hover {
    background-color: ${colors.Bl01};
  }
  cursor: pointer;
  /* z-index: 3; */
`;

const SelectBox = styled.div`
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.Gr02};
  line-height: 100%;
  background-color: ${colors.White};
  /* min-width: 7rem !important; */
  width: 100%;
  white-space: nowrap;
`;
const SearchListEmpty = styled.div`
  padding: 16px 30px;
  border: 1px solid ${colors.Gr02};

  p {
    color: ${colors.Gr04};
    letter-spacing: -0.02em;
    line-height: 17.04px;
    font-size: 12px;
    font-weight: 500;
  }

  .text-blue {
    color: ${colors.Bl05};
  }
`;
