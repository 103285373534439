import { useNavigate, useParams } from "react-router-dom";
import OverlayPortal from "../../../../overlayPortal";
import Overlay from "../../../../layouts/overlay";
import ModalLayout from "../../../../layouts/modal/layout";
import ImageInput from "../../../../components/imageInput";
import CategoryBox from "../../../../components/lesson/categoryBox";
import FAQCard from "../../../../components/lesson/faqCard";
import LocationBox from "../../../../components/lesson/locationBox";
import RecommendationBox from "../../../../components/lesson/recommendationBox";
import ScheduleBox from "../../../../components/lesson/scheduleBox";
import useImage from "../../../../hooks/useImage";
import useInput from "../../../../hooks/useInput";
import useList from "../../../../hooks/useList";
import MainLayout from "../../../../layouts/mainLayout";
import Button from "../../../../modules/button";
import Input from "../../../../modules/input";
import Select from "../../../../modules/select";
import Tag from "../../../../modules/tag";
import Textarea from "../../../../modules/textarea";
import MapService from "../../../../service/map";
import colors from "../../../../styles/constants/colors";
import bangIcon from "../../../../assets/icon/bang.svg";
import {
  ButtonBox,
  Footer,
  Main,
  Row,
  Title,
  UseInfoRow,
  CategoryDiv,
  RowTop,
  FAQRow,
  FAQTop,
  DiscountBox,
  DiscountWrapper,
  DiscountContainer,
  OptionBox,
  OptionWrapper,
  LikeDiv,
  Wrapper,
  ModalText,
} from "./style";
import FAQItem from "../../../../components/lesson/faqItem";
import { useState } from "react";

const PartnerLessonRegisterPage = () => {
  const navigate = useNavigate();

  const { pid } = useParams();

  const [modal, setModal] = useState(false);

  const saveAndOpenModal = () => {
    // 레슨 등록 로직
    // 모달 오픈
    setModal(true);
  };

  const {
    value: lessonValue,
    setValue: setLessonValue,
    inputChangeHandler: lessonInputChangeHandler,
  } = useInput({
    like: 0,
    category: "general",
    curriculum: "1",
    title: "",
    recommendation: "false",
    price: "",
    maximum: 1,
    "discount-personnel": "",
    "discount-price": "",
    "option-title": "",
    "option-price": "",
    "detail-info": "",
    "additional-info": "",
  });

  const { image, imageChangeHandler } = useImage({
    main: {
      base: "",
      files: "",
    },
    detail: {
      base: "",
      files: "",
    },
  });

  // Tag
  const {
    value: tagValue,
    setValue: setTagValue,
    inputChangeHandler: tagInputChangeHandler,
  } = useInput({
    exposure: "",
    search: "",
    materials: "",
    contain: "",
    except: "",
  });

  const {
    list: containList,
    addHandler: containAddHandler,
    deleteHandler: containDeleteHandler,
  } = useList([]);

  const {
    list: exceptList,
    addHandler: exceptAddHandler,
    deleteHandler: exceptDeleteHandler,
  } = useList([]);

  const {
    list: exposureList,
    addHandler: exposureAddHandler,
    deleteHandler: exposureDeleteHandler,
  } = useList([]);

  const {
    list: searchList,
    addHandler: searchAddHandler,
    deleteHandler: searchDeleteHandler,
  } = useList([]);

  const {
    list: materialsList,
    addHandler: materialsAddHandler,
    deleteHandler: materialsDeleteHandler,
  } = useList([]);

  const tagAddHandler = (e) => {
    const { name } = e.target;

    switch (name) {
      case "exposure":
        exposureAddHandler({ id: new Date(), text: tagValue.exposure });
        break;

      case "search":
        searchAddHandler({ id: new Date(), text: tagValue.search });
        break;

      case "materials":
        materialsAddHandler({ id: new Date(), text: tagValue.materials });
        break;

      case "contain":
        containAddHandler({ id: new Date(), text: tagValue.contain });
        break;

      case "except":
        exceptAddHandler({ id: new Date(), text: tagValue.except });
        break;
    }

    setTagValue((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  // Discount
  const {
    list: discountList,
    addHandler: discountListAddHandler,
    deleteHandler: discountDeleteHandler,
  } = useList([]);

  const discountAddHandler = () => {
    discountListAddHandler({
      id: new Date(),
      "discount-personnel": lessonValue["discount-personnel"],
      "discount-price": lessonValue["discount-price"],
    });

    setLessonValue((prev) => ({
      ...prev,
      "discount-personnel": 2,
      "discount-price": "",
    }));
  };

  // Option
  const {
    list: optionList,
    addHandler: optionListAddHandler,
    deleteHandler: optionDeleteHandler,
  } = useList([]);

  const optionAddHandler = () => {
    optionListAddHandler({
      id: new Date(),
      "option-title": lessonValue["option-title"],
      "option-price": lessonValue["option-price"],
    });

    setLessonValue((prev) => ({
      ...prev,
      "option-title": "",
      "option-price": "",
    }));
  };

  // FAQ
  const {
    value: faqValue,
    setValue: setFaqValue,
    inputChangeHandler: faqInputChangeHandler,
  } = useInput({
    question: "",
    answer: "",
  });

  const faqResetHandler = () => {
    setFaqValue({
      question: "",
      answer: "",
    });
  };

  const {
    list: faqList,
    addHandler: faqListAddHandler,
    deleteHandler: faqDeleteHandler,
  } = useList([]);

  const faqAddHandler = () => {
    faqListAddHandler({
      id: new Date(),
      question: faqValue.question,
      answer: faqValue.answer,
    });

    setFaqValue({
      question: "",
      answer: "",
    });
  };

  //
  const saveHandler = () => {
    console.log(" 저장하기");
  };

  const categoryChangeHandler = (e) => {
    const { name } = e.target;

    setLessonValue((prev) => ({
      ...prev,
      category: name,
    }));
  };

  const recommendationChangeHandler = (e) => {
    const { name } = e.target;

    setLessonValue((prev) => ({
      ...prev,
      recommendation: name,
    }));
  };

  //  Location
  const {
    value: addressValue,
    setValue: setAddressValue,
    inputChangeHandler: addressInputChangeHandler,
  } = useInput({
    main: "",
    detail: "",
  });

  const {
    list: addressList,
    addHandler: addAddressListHandler,
    deleteHandler: deleteAddressHandler,
  } = useList([]);

  const targetLocationChangeHandler = (data) => {
    const { address } = data;

    setAddressValue((prev) => ({
      ...prev,
      main: address,
    }));
  };

  const mapService = new MapService(targetLocationChangeHandler);

  const searchAddressHandler = () => {
    mapService.open();
  };

  const addAddressHandler = () => {
    addAddressListHandler({
      id: new Date(),
      main: addressValue.main,
      detail: addressValue.detail,
    });

    setAddressValue({
      main: "",
      detail: "",
    });
  };

  // Schedule
  const {
    value: locationValue,
    setValue: setLocationValue,
    inputChangeHandler: locationInputHandler,
  } = useInput("");

  const {
    value: clockValue,
    setValue: setClockValue,
    inputChangeHandler: clockChangeHandler,
  } = useInput({
    "start-hour": "",
    "start-minute": "",
    "finish-hour": "",
    "finish-minute": "",
  });

  const {
    list: scheduleList,
    addHandler: addScheduleHandler,
    deleteHandler: deleteScheduleHandler,
  } = useList([]);

  const {
    list: locationList,
    setList: setLocationList,
    addHandler: addLocationHandler,
    deleteHandler: deleteLocationHandler,
  } = useList([]);

  const {
    list: weekList,
    setList: setWeekList,
    addHandler: addWeekHandler,
    deleteHandler: deleteWeekHandler,
  } = useList([]);

  const {
    list: clockList,
    setList: setClockList,
    addHandler: addClockHandler,
    deleteHandler: deleteClockHandler,
  } = useList([]);

  const addScheduleListHandler = () => {
    addScheduleHandler({
      id: new Date(),
      location: locationList,
      week: weekList,
      clock: clockList,
    });

    setLocationList([]);
    setWeekList([]);
    setClockList([]);
  };

  const locationChangeHandler = (e) => {
    const { value } = e.target;

    addLocationHandler({
      id: new Date(),
      value,
    });
  };

  const weekToggleHandler = (idx) => {
    // 중복 체크
    if (weekList.includes(idx)) {
      const list = weekList.filter((item) => item !== idx);
      setWeekList(list);
    } else {
      addWeekHandler(idx);
    }
  };

  const addClockListHandler = () => {
    addClockHandler({
      id: new Date(),
      "start-hour": clockValue["start-hour"],
      "start-minute": clockValue["start-minute"],
      "finish-hour": clockValue["finish-hour"],
      "finish-minute": clockValue["finish-minute"],
    });

    setClockValue({
      "start-hour": "",
      "start-minute": "",
      "finish-hour": "",
      "finish-minute": "",
    });
  };

  const copyLessonHandler = () => {
    console.log("레슨 복사하기");
  };

  return (
    <>
      <MainLayout>
        <Main>
          <Row between={1}>
            {/* <CategoryDiv>
            <Title>레슨 종류</Title>

            <CategoryBox
              value={lessonValue.category}
              changeHandler={categoryChangeHandler}
            />
          </CategoryDiv> */}

            <Wrapper>
              <CategoryDiv>
                <Title>레슨 명*</Title>
                <Input
                  width="100%"
                  type="text"
                  placeholder="레슨 명을 입력해 주세요. (최대 17자)"
                  name="title"
                  value={lessonValue.title}
                  changeHandler={lessonInputChangeHandler}
                />
              </CategoryDiv>

              <LikeDiv>
                <p>찜</p>
                <Input
                  width="100%"
                  type="text"
                  name="like"
                  placeholder="0개"
                  value={lessonValue.like}
                  changeHandler={lessonInputChangeHandler}
                />
              </LikeDiv>
            </Wrapper>

            <ButtonBox />
          </Row>

          {/* <Row>
          <Title>레슨명*</Title>
          <Input
            type="text"
            placeholder="레슨명을 입력해 주세요."
            name="title"
            value={lessonValue.title}
            changeHandler={lessonInputChangeHandler}
          />

          <ButtonBox></ButtonBox>
        </Row> */}

          {/* <Row>
          <Title>예약 형태</Title>

          <RecommendationBox
            value={lessonValue.recommendation}
            changeHandler={recommendationChangeHandler}
          />
        </Row> */}

          <Row>
            <Title>가격*</Title>

            <Input
              type="text"
              placeholder="숫자만 입력해 주세요."
              name="price"
              value={lessonValue.price}
              changeHandler={lessonInputChangeHandler}
              width={`250px`}
            />

            <ButtonBox></ButtonBox>
          </Row>

          <Row>
            <Title>커리큘럼 회차</Title>

            <Select
              optionList={[
                {
                  text: "1일 차",
                  value: 1,
                },
              ]}
              name="curriculum"
              value={lessonValue.curriculum}
              changeHandler={lessonInputChangeHandler}
              width={`250px`}
            />

            <ButtonBox></ButtonBox>
          </Row>

          <Row>
            <Title>최대 인원</Title>

            <Select
              optionList={[
                {
                  text: "1인",
                  value: 1,
                },
                {
                  text: "2인",
                  value: 2,
                },
                {
                  text: "3인",
                  value: 3,
                },
                {
                  text: "4인",
                  value: 4,
                },
              ]}
              name="maximum"
              value={lessonValue.maximum}
              changeHandler={lessonInputChangeHandler}
              width={`250px`}
            />

            <ButtonBox></ButtonBox>
          </Row>

          <Row>
            <DiscountContainer>
              <RowTop>
                <Title>동반 할인가격</Title>

                <DiscountBox>
                  <Select
                    width="250px"
                    name="discount-personnel"
                    value={lessonValue["discount-personnel"]}
                    changeHandler={lessonInputChangeHandler}
                    optionList={[
                      {
                        text: "2인",
                        value: 2,
                      },
                      {
                        text: "3인",
                        value: 3,
                      },
                      {
                        text: "4인",
                        value: 4,
                      },
                    ]}
                  />

                  <DiscountWrapper>
                    <Input
                      placeholder="기존 가격에서 추가되는 금액을 입력해 주세요."
                      type="text"
                      name="discount-price"
                      value={lessonValue["discount-price"]}
                      changeHandler={lessonInputChangeHandler}
                    />
                  </DiscountWrapper>
                </DiscountBox>

                <ButtonBox>
                  <button type="button" onClick={discountAddHandler}>
                    입력
                  </button>
                </ButtonBox>
              </RowTop>

              {discountList.length > 0 && (
                <ul>
                  {discountList.map((item, idx) => (
                    <Tag
                      key={idx}
                      text={`${item["discount-personnel"]}인 ${parseInt(
                        item["discount-price"]
                      ).toLocaleString()}원`}
                      deleteHandler={() => discountDeleteHandler(item.id)}
                    />
                  ))}
                </ul>
              )}
            </DiscountContainer>
          </Row>

          <Row>
            <DiscountContainer>
              <RowTop>
                <Title>옵션</Title>

                <OptionBox>
                  <OptionWrapper>
                    <Input
                      width="250px"
                      placeholder="옵션명"
                      type="text"
                      name="option-title"
                      value={lessonValue["option-title"]}
                      changeHandler={lessonInputChangeHandler}
                    />
                  </OptionWrapper>

                  <DiscountWrapper>
                    <Input
                      placeholder="옵션 선택 시 추가되는 금액을 입력해 주세요."
                      type="text"
                      name="option-price"
                      value={lessonValue["option-price"]}
                      changeHandler={lessonInputChangeHandler}
                    />
                  </DiscountWrapper>
                </OptionBox>

                <ButtonBox>
                  <button type="button" onClick={optionAddHandler}>
                    입력
                  </button>
                </ButtonBox>
              </RowTop>

              {optionList.length > 0 && (
                <ul>
                  {optionList.map((item, idx) => (
                    <Tag
                      key={idx}
                      text={`${item["option-title"]} ${parseInt(
                        item["option-price"]
                      ).toLocaleString()}원`}
                      deleteHandler={() => optionDeleteHandler(item.id)}
                    />
                  ))}
                </ul>
              )}
            </DiscountContainer>
          </Row>

          <Row start={1}>
            <Title>레슨 위치</Title>

            <LocationBox
              addHandler={addAddressHandler}
              deleteHandler={deleteAddressHandler}
              searchAddressHandler={searchAddressHandler}
              data={addressValue}
              changeHandler={addressInputChangeHandler}
              addressList={addressList}
            />
          </Row>

          <Row direction={"column"}>
            <RowTop>
              <Title>노출 태그</Title>

              <Input
                type="text"
                placeholder="키워드로 입력해 주세요."
                name="exposure"
                value={tagValue.exposure}
                changeHandler={tagInputChangeHandler}
              />

              <ButtonBox>
                <button
                  type="button"
                  name="exposure"
                  onClick={(e) => tagAddHandler(e)}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {exposureList.length > 0 && (
              <ul>
                {exposureList.map((item, idx) => (
                  <Tag
                    key={idx}
                    text={item.text}
                    deleteHandler={() => exposureDeleteHandler(item.id)}
                  />
                ))}
              </ul>
            )}
          </Row>
          {/* 
        <Row>
          <ScheduleBox
            addScheduleHandler={addScheduleListHandler}
            location={{
              locationChangeHandler,
              locationList,
              locationValue,
              deleteLocationHandler,
            }}
            week={{ weekToggleHandler, weekList }}
            clock={{
              clockValue,
              clockChangeHandler,
              addClockListHandler,
              deleteClockHandler,
              clockList,
            }}
            schedule={{ scheduleList, deleteScheduleHandler }}
          />
        </Row> */}

          <Row start={1}>
            <Title>대표 사진*</Title>

            <ImageInput
              width="100%"
              height="370px"
              name="main"
              changeHandler={imageChangeHandler}
              preview={image.main.base}
            >
              <p>업로드하기</p>
              <p>(8:5 비율 / 5MB 이하 / JPG)</p>
            </ImageInput>

            <ButtonBox />
          </Row>

          {/* <Row start={1}>
          <Title>이용 상세 정보</Title>

          <ImageInput
            type="long"
            width="100%"
            height="370px"
            name="detail"
            changeHandler={imageChangeHandler}
            preview={image.detail.base}
          >
            <p>업로드하기</p>
            <p>(5MB 이하 / JPG)</p>
          </ImageInput>

          <ButtonBox />
        </Row> */}

          <Row direction={"column"}>
            <RowTop>
              <Title>포함사항</Title>

              <Input
                type="text"
                placeholder="포함된 사항을 적어주세요. / 예-장비 렌탈비"
                name="contain"
                value={tagValue.contain}
                changeHandler={tagInputChangeHandler}
              />

              <ButtonBox>
                <button
                  type="button"
                  name="contain"
                  onClick={(e) => tagAddHandler(e)}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {containList.length > 0 && (
              <ul>
                {containList.map((item, idx) => (
                  <Tag
                    key={idx}
                    text={item.text}
                    deleteHandler={() => containDeleteHandler(item.id)}
                  />
                ))}
              </ul>
            )}
          </Row>

          <Row direction={"column"}>
            <RowTop>
              <Title>
                불포함사항 <img src={bangIcon} />
              </Title>

              <Input
                type="text"
                placeholder="포함되지 않은 사항을 금액과 함께 적어주세요. / 예-풀장 입장료(+15,000)"
                name="except"
                value={tagValue.except}
                changeHandler={tagInputChangeHandler}
              />

              <ButtonBox>
                <button
                  type="button"
                  name="except"
                  onClick={(e) => tagAddHandler(e)}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {exceptList.length > 0 && (
              <ul>
                {exceptList.map((item, idx) => (
                  <Tag
                    key={idx}
                    text={item.text}
                    deleteHandler={() => exceptDeleteHandler(item.id)}
                  />
                ))}
              </ul>
            )}
          </Row>

          <Row direction={"column"}>
            <RowTop>
              <Title>준비물</Title>

              <Input
                type="text"
                placeholder="준비물을 적어주세요. / 예-수영복"
                name="materials"
                value={tagValue.materials}
                changeHandler={tagInputChangeHandler}
              />

              <ButtonBox>
                <button
                  type="button"
                  name="materials"
                  onClick={(e) => tagAddHandler(e)}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {materialsList.length > 0 && (
              <ul>
                {materialsList.map((item, idx) => (
                  <Tag
                    key={idx}
                    text={item.text}
                    deleteHandler={() => materialsDeleteHandler(item.id)}
                  />
                ))}
              </ul>
            )}
          </Row>

          <FAQRow direction={"column"}>
            <FAQTop>
              <p>자주 묻는 질문</p>

              <ButtonBox>
                <button type="button" onClick={faqAddHandler}>
                  추가
                </button>
              </ButtonBox>
            </FAQTop>

            <FAQCard
              value={faqValue}
              changeHandler={faqInputChangeHandler}
              resetHandler={faqResetHandler}
            />

            <ul>
              {faqList.length > 0 &&
                faqList.map((item, idx) => (
                  <FAQItem
                    key={idx}
                    data={item}
                    deleteHandler={faqDeleteHandler}
                  />
                ))}
            </ul>
          </FAQRow>

          <UseInfoRow>
            <p>이용 상세 정보(추가)</p>

            <Textarea
              placeholder="레슨 일정: 원데이 3시간 레슨"
              name="detail-info"
              value={lessonValue["detail-info"]}
              changeHandler={lessonInputChangeHandler}
            />
          </UseInfoRow>

          <UseInfoRow>
            <p>이용 추가 정보</p>

            <Textarea
              placeholder="*백신패스가 의무화 됨에 따라 어플 인증 후 입점이 가능합니다."
              name="additional-info"
              value={lessonValue["additional-info"]}
              changeHandler={lessonInputChangeHandler}
            />
          </UseInfoRow>

          {/* <Row direction={"column"}>
          <RowTop>
            <Title>검색 태그</Title>

            <Input
              type="text"
              placeholder="키워드로 입력해 주세요."
              name="search"
              value={tagValue.search}
              changeHandler={tagInputChangeHandler}
            />

            <ButtonBox>
              <button
                type="button"
                name="search"
                onClick={(e) => tagAddHandler(e)}
              >
                입력
              </button>
            </ButtonBox>
          </RowTop>

          {searchList.length > 0 && (
            <ul>
              {searchList.map((item, idx) => (
                <Tag
                  key={idx}
                  text={item.text}
                  deleteHandler={() => searchDeleteHandler(item.id)}
                />
              ))}
            </ul>
          )}
        </Row> */}
        </Main>
        <Footer>
          <Button
            text="레슨 복사하기"
            clickHandler={copyLessonHandler}
            width={`230px`}
            color={colors.white}
            fontcolor={colors.gray}
          />

          <Button
            text="저장하고 이용 정보 등록"
            clickHandler={saveAndOpenModal}
            width={`230px`}
          />

          {/* <Button
          text="레슨 저장하기"
          clickHandler={saveHandler}
          width={`230px`}
        /> */}
        </Footer>
      </MainLayout>

      <OverlayPortal>
        {modal && (
          <>
            <Overlay toggleHandler={() => setModal(!modal)} />

            <ModalLayout
              subButtonText="뒤로 가기"
              subButtonHandler={() => setModal(!modal)}
              buttonText="이용 정보 작성하기"
              clickHandler={() =>
                navigate(`/partner/${pid}/lesson/register/editor`)
              }
            >
              <ModalText>레슨이 등록되었습니다.</ModalText>
            </ModalLayout>
          </>
        )}
      </OverlayPortal>
    </>
  );
};

export default PartnerLessonRegisterPage;
