import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import MainHeader from "../../../components/header";
import MessageBubble from "../../../components/inquiry/message";
import ChatTextarea from "../../../components/inquiry/textarea";
import useInput from "../../../hooks/useInput";
import Border from "../../../modules/border";
import MainLayout from "../../../layouts/mainLayout";
import colors from "../../../styles/constants/colors";
import typography from "../../../styles/constants/typography";
import io from 'socket.io-client';

// const ENDPOINT = "http://localhost:8080";

// const socket = socketIOClient(ENDPOINT);

const Section = styled.section`
  text-align: center;

  time {
    color: ${colors.mediumGray};
    font-size: ${typography.size.small}px;
    margin-bottom: 50px;
  }
`;

const LessonInfo = styled.div`
  width : 100%;
  display : inline-flex;
  justify-content : space-between;
  padding : 20px;

  img {
    width : 180px;
    border-radius: 8px;
    background-color: rgb(112, 112, 112);
    margin-right: 20px;
    cursor: pointer;
  }

  .lessonInfoBox {
    width : calc(100% - 200px);
    display : inline-flex;
    flex-wrap : wrap;
    justify-content : space-between;
    align-items: flex-start;
    flex-direction: column;

    span {
      width : 100%;
      display : inline-block;
      overflow : hidden;
      white-space : nowrap;
    }
  }
` 

const Wrapper = styled.div`
  padding: 20px;
  height : calc(870px - 290px);
  overflow : hidden;
  overflow-x : hidden;
  overflow-y : auto; 
`;

const socket = io.connect('https://dt.semos.kr');

const InquiryChatPage = () => {
  const { qid } = useParams();

  /*const userName = `고크리스틴${qid}`;

  const {
    value: chatValue,
    setValue: setChatValue,
    inputChangeHandler: chatChangeHandler,
  } = useInput({ chat: "" });

  const { pathname } = useLocation();
  const [category, uid] = pathname.slice(1, pathname.length).split("/");
  const [socketId, setSocketId] = useState("");

  const [roomName, setRooName] = useState(uid);

  const date = new Date();

  const [state, setState] = useState({
    message: "",
    name: "",
    time: "",
  });

  const [chat, setChat] = useState([]);*/

  // console.log(chat, socketId);

  /*const textChangeHandler = (e) => {
    const { name, value } = e.target;

    setState({ ...state, [name]: value });
  };*/

  // const messageSubmitHandler = (e) => {
  //   e.preventDefault();

  //   const { name, message } = state;

  //   socket.emit("message", { name, message, time: new Date() });

  //   setState({ message: "", name: "", time: "" });
  // };

  /*const renderChat = () => {
    return chat.map(({ name, message, time }, index) => (
      <MessageBubble
        key={index}
        text={message}
        time={`${new Date(time).getHours()}:${new Date(time).getMinutes()}`}
        isMe={socketId === name ? true : false}
      />
    ));
  };

  const chatSendHandler = () => {
    setChatValue({ chat: "" });
  };*/

  // useEffect(() => {
  //   socket.on("send_msg", ({ name, message, time }, id) => {
  //     setChat([...chat, { name: id, message, time }]);
  //   });

  //   socket.on("connect", (res) => {
  //     setSocketId(socket.id);
  //   });
  // });

          const [questionD, setQuestion] = useState();

          const [chatId, setChatId] = useState();

          const [questionData, setQuestionData] = useState([]);

          const [chatData, setChatData] = useState("");

          const chatSubmit = ( messageContent ) => {

            if( !messageContent ) {

            } else {

              const messageData = {
                userId : chatId,
                message : messageContent,
                userType : 'partner',
                userToken : window.localStorage.getItem('token'),
                serviceId : questionData.service_number,
                appType : 'partnerApp'
              }
        
              socket.emit("submit", messageData )
            }
          }

          // 문의 상세 로드
          useEffect(() => {
    
            var token = window.localStorage.getItem('token')
      
            const frm = new FormData();
            frm.append("mode", 'specific');
            frm.append("token", token);
            frm.append("number", qid);
    
              axios.post("https://ai.semos.kr/semos_admin/question/data", frm, {
                headers: { "Content-Type": "multipart/form-data" },
              }).then((data) => {
                
                var Value = data.data;
                var status = Value.status
                var statusMessage = Value.status_message
      
                if(status == 'success') {
                  setQuestion(Value.question_chat_data)
                  setQuestionData(Value.question_data)
                  setChatId(Value.question_data.partner_number + '_' + Value.question_data.number)
                  
                  socket.emit("chatJoin", (Value.question_data.partner_number + '_' + Value.question_data.number) );

                  socket.on("message", (massage) => {
                    receiveMessage(massage)
                  })

                } else if(status == 'waring') {
                  alert(statusMessage)
                }
      
              });
      
          }, []);


          var chatMessage = [];
          var PrevChatDate = ""
          for(var i=0; i < (questionD ? questionD.length : 0); i++) {

            if(PrevChatDate !== questionD[i].date) {
              chatMessage.push(
                <>
                <time>{questionD[i].date}</time> 
                <MessageBubble
                  margin={true}
                  key={"question_" + i}
                  text={questionD[i].content}
                  time={questionD[i].time}
                  isMe={questionD[i].type == 'partner' ? true : false}
                />
                </>
              )
              PrevChatDate = questionD[i].date;
            } else {
              chatMessage.push(
                <MessageBubble
                  margin={true}
                  key={"question_" + i}
                  text={questionD[i].content}
                  time={questionD[i].time}
                  isMe={questionD[i].type == 'partner' ? true : false}
                />
              )
            }

          }


          const receiveMessage = (msg) => {

            var week = ['일', '월', '화', '수', '목', '금', '토'];

            var chatDate =  msg.inquire_date.split(' ')[0]
            var chatTime =  msg.inquire_date.split(' ')[1]

            var chatDateYmd = chatDate.split('.')

            var chatDateNew = new Date(chatDateYmd[0] + '/' + chatDateYmd[1] + '/' + chatDateYmd[2])

            var dayOfWeek = week[chatDateNew.getDay()];

            var chatDateText = chatDateYmd[0] + '년 ' + chatDateYmd[1] + '월 ' + chatDateYmd[2] + '일(' + dayOfWeek + ')';
    
            var questionChatMessage = {
              'content' : msg.inquire_content,
              'date' : chatDateText,
              'type' : msg.inquire_type,
              'time' : chatTime,
            }
      
            setQuestion((questionD) => [...questionD, questionChatMessage])

            setChatData('')

            setTimeout(() => {
              document.getElementById('scrollEvent').scrollTo({
                top : document.getElementById('scrollEvent').scrollHeight,
                behavior: "smooth"
              })
            }, 300)


            /*if (msg.inquire_type == 'partner') {
       
              this.setState({
                question_list : nowMessageState,
                chatLoading : 'Bottom',
                chatCount : (this.state.chatCount + 1)
              })
      
            } else {
       
              this.setState({
                question_list : nowMessageState,
                chatLoading : 'Bottom',
                chatCount : (this.state.chatCount + 1)
              })
      
            }*/
      
          }

  return (
    <MainLayout>
      <MainHeader title={questionData ? questionData.user + '님' : ""} />

      <LessonInfo >
        <img onClick={() => window.open(`https://semos.kr/coaching/${questionData ? questionData.partner_number + '/' + questionData.service_number + '/normal' : ""}`, "_blank",)} src={questionData ? questionData.img : ""}/>

        <div className="lessonInfoBox">

          <span><b>종목 : </b>{questionData ? questionData.sport : ""}</span>
          <span><b>레슨명 : </b>{questionData ? questionData.service : ""}</span>
          <span><b>강사 : </b>{questionData ? questionData.partner : ""}</span>
          <span><b>장소 : </b>{questionData ? questionData.location : ""}</span>

        </div>
      </LessonInfo>

      <Border/>

      <Wrapper id='scrollEvent'>
        <Section>

          {chatMessage}
          {/*renderChat()*/}
        </Section>
      </Wrapper>

      <ChatTextarea
        value={chatData}
        changeHandler={setChatData}
        sendHandler={chatSubmit}
      />
    </MainLayout>
  );
};

export default InquiryChatPage;
