import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";

import useInput from "../../hooks/useInput";
import usePagination from "../../hooks/usePagination";
// import Input from "../../modules/input";
import salesList from "../../data/sales";
import DropDown from "../../components/designedComponents/dropDown";
import { CXMessageTypeValue } from "../../layouts/textStorage";
import DesignedMainHeader from "../../components/designedHeader";
import DesignedMainLayout from "../../layouts/designedMainLayout";
import DrawerModal from "../../components/drawerModal";
import Table from "../../layouts/table";
import {
  TableFilterContainer,
  TableFilterContent,
  TableWrapper,
  Wrapper,
  AbsoluteWrapper,
  Icon,
  Text,
  TableContent,
  TableFooter,
  TableColumn,
  TableFooterPaging,
} from "../../layouts/layoutStorage";

// icon
import SearchIcon from "../../assets/icon/search.png";
import DoublePaging from "../../assets/icon/double_paging.png";
import OnePaging from "../../assets/icon/one_paging.png";
import AlarmTalkDetail from "../../components/designedAlarmTalk/alarmTalkDetail";
import colors from "../../styles/constants/colors";

const AlarmTalk = () => {
  //   const { n, t } = useParams();
  //   const navigate = useNavigate();

  const [activity, setActivity] = useState([]);
  const [count, setCount] = useState(0);
  const [Height, setHeight] = useState({
    TableFilter: 0,
    TableHeader: 0,
    TableFooter: 0,
    Layout: 0,
    LayoutHeader: 0,
  });

  const [selected, setSelected] = useState({
    partner: "",
    service: "",
  });

  const [open, setOpen] = useState(false);
  const [sendOpen, setSendOpen] = useState(false);

  //   const [search, setSearch] = useState(t !== "all" ? t : "");

  useEffect(() => {
    setTimeout(() => {
      const Layout = document.getElementById("Layout")?.offsetHeight - 36;
      const LayoutHeader = document.getElementById("LayoutHeader")?.offsetHeight + 65;
      const TableFilter = document.getElementById("TableFilter")?.offsetHeight + 19;
      const TableHeader = document.getElementById("TableHeader")?.offsetHeight;
      const TableFooter = document.getElementById("TableFooter")?.offsetHeight;

      setHeight({
        TableFilter: TableFilter,
        TableHeader: TableHeader,
        TableFooter: TableFooter,
        Layout: Layout,
        LayoutHeader: LayoutHeader,
      });
    }, 500);
  }, []);

  useEffect(() => {
    if (!open) {
      setSelected({
        partner: "",
        service: "",
      });
    }
  }, [open]);
  //--------------
  const { num, y, m, t, f } = useParams();
  const navigate = useNavigate();
  const numCheck = num > 1 || y || m || t || f !== "all";

  const { value, setValue, inputChangeHandler } = useInput({
    filter: f !== "all" ? f : "all",
    month: m !== "all" ? m : "",
    year: y !== "all" ? y : "",
  });

  const salesData = salesList;

  const [search, setSearch] = useState(t !== "all" ? t : "");

  const { offset, limit, currentPage, setCurrentPage } = usePagination(salesData, 10);

  const [modal, setModal] = useState(false);

  const openModalHandler = () => {
    setModal(!modal);
  };

  const [message, setMessage] = useState("");
  const [messageCount, setMessageCount] = useState(0);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [page, setPage] = useState(num);

  useEffect(() => {
    var token = window.localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", updateStatus || numCheck ? "message_update" : "list");
    frm.append("token", token);
    frm.append("page", updateStatus ? 1 : num);
    frm.append("year", updateStatus || numCheck ? value.year : "all");
    frm.append("month", updateStatus || numCheck ? value.month : "all");
    frm.append("text", updateStatus || numCheck ? search : "all");
    frm.append("type", updateStatus || numCheck ? value.filter : "all");

    axios
      .post("https://ai.semos.kr/semos_admin/user/send", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var status = Value.status;
        var statusMessage = Value.status_message;

        if (status == "success") {
          setMessage(Value.message_list);
          setMessageCount(Value.message_count);
          setUpdateStatus(true);

          if (updateStatus) {
            setPage(1);
            navigate(`/message_list/${1}/${value.year ? value.year : "all"}/${value.month ? value.month : "all"}/${search ? search : "all"}/${value.filter}`, {
              replace: true,
            });
          } else {
            setPage(num);
          }
        } else if (status == "waring") {
          alert(statusMessage);
        }
      });
  }, [value.year, value.month, value.filter]);
  const prevHandler = () => {
    if (page > 1) {
      pageChangeFunc(Number(page) - 1);
    } else {
      alert("첫 페이지입니다.");
    }
  };

  const nextHandler = () => {
    if (page < numPages) {
      pageChangeFunc(Number(page) + 1);
    } else {
      alert("마지막 페이지입니다.");
    }
  };

  const PageChange = (number) => {
    if (number < numPages + 1 && number > 0) {
      pageChangeFunc(number);
    }
  };

  const TextSearch = (e) => {
    if (e.key === "Enter") {
      pageChangeFunc(1);
    }
  };

  const pageChangeFunc = (number) => {
    var token = window.localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", "message_update");
    frm.append("token", token);
    frm.append("page", number);
    frm.append("year", value.year);
    frm.append("month", value.month);
    frm.append("text", search);
    frm.append("type", value.filter);

    axios
      .post("https://ai.semos.kr/semos_admin/user/send", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var status = Value.status;
        var statusMessage = Value.status_message;

        if (status == "success") {
          setMessage(Value.message_list);
          setMessageCount(Value.message_count);
          setPage(number);
          navigate(
            `/message_list/${number}/${value.year ? value.year : "all"}/${value.month ? value.month : "all"}/${search ? search : "all"}/${value.filter}`,
            { replace: true }
          );
        } else if (status == "waring") {
          alert(statusMessage);
        }
      });
  };

  //   하단 페이지 목록
  const numPages = Math.ceil(messageCount / 10);
  //페이지를 10으로 나눈다 10개씩 나오니까 10으로나눔

  let PageList = [];

  if (Number(page) < 11) {
    // 현재 페이지가 11미만일 때 20개까지만 ++되게 하기
    for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
      PageList.push(i + 1);
    }
  } else {
    // 현재 페이지가 11이상일 때
    if (numPages - 10 < Number(page)) {
      var startPage = numPages - 19;
      if (startPage < 1) {
        startPage = 1;
      }

      for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    } else {
      var startPage = Number(page) - 9;
      if (startPage < 1) {
        startPage = 1;
      }

      for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    }
  }

  const PhoneHyphen = (value) => {
    return String(value)
      .replace(/[^0-9]/g, "")
      .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3")
      .replace("--", "-");
  };

  /*const CXMessageTypeValue = {
    cancelp: "유저 - 취소 (강사 미확정)",
    cancelp2: "강사 - 환불 (고객 요청)",
    cancelu: "유저 - 취소 (고객 요청)",
    refundu: "유저 - 환불 (고객 요청)",
    refundp: "강사 - 환불 (고객 요청)",
    reminder: "유저 - 리마인더",
    reminder2: "유저 - 리마인더",
    payment: "유저 - 결제 안내",
    confirm: "유저 - 일정 확정",
    confirm2: "유저 - 일정 확정",

    UCANCELPS: "유저 - 취소 (강사 미확정)",
    UCANCELS: "유저 - 취소 (고객 요청)",
    PCANCELS: "강사 - 환불 (고객 요청)",
    PCANCELS2: "강사 - 환불 (고객 요청)",
    UREMINDERS: "유저 - 리마인더",
    PREMINDERS: "강사 - 리마인더",
    PAYMENTS: "유저 - 결제 안내",
    PPAYMENTS: "강사 - 결제 안내",
    UCONFIRMS: "유저 - 일정 확정",
    PCONFIRMS: "강사 - 일정 확정",
    UREVIEW: "유저 - 리뷰 안내",
  };*/

  const showDetail = (id, number) => {
    // setSelected({
    //   id,
    //   number,
    // });

    // setOpen(true);
    window.open(`/message_detail/${id}/${number}`, "_blank");
  };

  const StatusComponent = (status) => {
    switch (status) {
      case "success":
        return (
          <Wrapper bgc={`#E5F0FF`} padding={`4px 12px`} radius={`30px`}>
            <Text color={`#80B2FF`} weight={`500`}>
              성공
            </Text>
          </Wrapper>
        );
        break;

      case "fail":
        return (
          <Wrapper bgc={`#FFE4E1`} padding={`4px 12px`} radius={`30px`}>
            <Text color={`#FF9187`} weight={`500`}>
              실패
            </Text>
          </Wrapper>
        );
        break;
    }
  };
  const statusCase = (status) => {
    switch (status) {
      case "all":
        return "전체";
        break;

      case "success":
        return "성공";
        break;

      case "fail":
        return "실패";
        break;
    }
  };
  //   useEffect(() => {
  //     var token = localStorage.getItem("token");

  //     const frm = new FormData();

  //     frm.append("mode", "partner_listing");
  //     frm.append("token", token);
  //     frm.append("center", "1");

  //     axios
  //       .post("https://ai.semos.kr/semos_admin/activity/data", frm, {
  //         headers: { "Content-Type": "multipart/form-data" },
  //       })
  //       .then((data) => {
  //         var Value = data.data;
  //         console.log(Value);
  //       });
  //   }, []);

  const searchTextInput = (value) => {
    var copy = value;
    var regExp = /[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi;

    if (regExp.test(copy)) {
      alert("특수문자는 입력하실수 없습니다.");
      copy = copy.substring(0, copy.length - 1);
    }
    setSearch(copy);
  };

  return (
    <>
      <DesignedMainLayout>
        <Wrapper position="relative">
          <DesignedMainHeader title="알림 / 친구톡" />
          <AbsoluteWrapper top={0} right={0}></AbsoluteWrapper>
        </Wrapper>

        <Table>
          <TableFilterContainer id="TableFilter" margin={`0 0 19px 0`}>
            <TableFilterContent margin={`0 24px 0 0`}>
              <Input
                width={`350px`}
                type={`text`}
                maxLength={40}
                name="search"
                value={search}
                onKeyUp={TextSearch}
                onChange={(e) => searchTextInput(e.target.value)}
                placeholder="검색어를 입력해 주세요"
              />
              <Icon width={`calc(1rem * (17 / 14))`} cursor={`pointer`} src={SearchIcon} onClick={() => pageChangeFunc(1)} />
            </TableFilterContent>
            <Wrapper width={`50%`} jc={`flex-end`}>
              <DropDown
                data={statusCase(value.filter)}
                setData={setValue}
                option={[
                  { text: "전체", value: "all" },
                  { text: "성공", value: "success" },
                  { text: "실패", value: "fail" },
                ]}
                type={`object`}
                defaultText="전체"
                no={false}
                keyName={`filter`}
              />

              <DropDown
                data={value.year ? (value.year === "all" ? "전체(년)" : `${value.year}년`) : "전체(년)"}
                setData={setValue}
                option={[
                  { text: "전체(년)", value: "" },
                  { text: "2021년", value: "2021" },
                  { text: "2022년", value: "2022" },
                  { text: "2023년", value: "2023" },
                  { text: "2024년", value: "2024" },
                  { text: "2025년", value: "2025" },
                ]}
                type={`object`}
                defaultText="전체(년)"
                no={false}
                keyName={`year`}
              />
              <DropDown
                data={value.month ? (value.month === "all" ? "전체(월)" : value.month > 9 ? `${value.month}월` : `0${value.month}월`) : "전체(월)"}
                setData={setValue}
                option={[
                  { text: "전체(월)", value: "" },
                  { text: "01월", value: "1" },
                  { text: "02월", value: "2" },
                  { text: "03월", value: "3" },
                  { text: "04월", value: "4" },
                  { text: "05월", value: "5" },
                  { text: "06월", value: "6" },
                  { text: "07월", value: "7" },
                  { text: "08월", value: "8" },
                  { text: "09월", value: "9" },
                  { text: "10월", value: "10" },
                  { text: "11월", value: "11" },
                  { text: "12월", value: "12" },
                ]}
                type={`object`}
                defaultText="전체(월)"
                no={true}
                keyName={`month`}
              />
            </Wrapper>
          </TableFilterContainer>
          <TableContent height={Height.Layout - (Height.LayoutHeader + Height.TableFilter + Height.TableFooter)} contentHeight={Height.TableHeader}>
            <section id="TableHeader">
              <TableColumn width={`6`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                NO.
              </TableColumn>

              <TableColumn width={`8`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                담당자
              </TableColumn>

              <TableColumn width={`13`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                결제 번호
              </TableColumn>

              <TableColumn width={`12`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                닉네임
              </TableColumn>

              <TableColumn width={`30`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                내용
              </TableColumn>

              <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                카테고리
              </TableColumn>

              <TableColumn width={`13`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                발송일
              </TableColumn>

              <TableColumn width={`8`} weight={`500`} br={`1px solid transparent`}>
                상태
              </TableColumn>
            </section>

            <section>
              {message
                ? message.map((item, idx) => (
                    <Link to={`/message_detail/${item.id}/${item.number}`}>
                      <TableWrapper
                        cursor={`pointer`}
                        key={`${idx}_reservation`}
                        jc={`flex-start`}
                        bgc={selected.number === item.number ? "#F2F7FF" : "white"}
                        bl={`1px solid transparent`}
                        br={`1px solid transparent`}
                        bb={`1px solid ${colors.Gr02}`}
                        bt={`1px solid transparent`}
                        width={`100%`}
                      >
                        <TableColumn width={`6`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                          {item.number}
                        </TableColumn>

                        <TableColumn width={`8`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                          {item.manager}
                        </TableColumn>

                        <TableColumn width={`13`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                          {item.OID ? item.OID : "test"}
                        </TableColumn>

                        <TableColumn width={`12`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                          {item.user ? item.user : "test"}
                        </TableColumn>

                        <TableColumn width={`30`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                          {/* {PhoneHyphen(item.phone)} */}
                          {item.content ? item.content : "test"}
                        </TableColumn>

                        <TableColumn
                          width={`10`}
                          weight={`500`}
                          // padding={"8px 16px 8px 10px"}
                          br={`1px solid ${colors.Gr02}`}
                        >
                          {CXMessageTypeValue[item.category]}
                        </TableColumn>

                        <TableColumn
                          width={`13`}
                          weight={`500`}
                          // padding={"8px 16px 8px 10px"}
                          br={`1px solid ${colors.Gr02}`}
                        >
                          {item.date ? item.date : "test"}
                        </TableColumn>

                        <TableColumn width={`8`} weight={`500`} padding={"7px 16px"} br={`1px solid transparent`}>
                          {item.status ? StatusComponent(item.status) : "test"}
                        </TableColumn>
                      </TableWrapper>
                    </Link>
                  ))
                : ""}

              <TableFooter id="TableFooter">
                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  src={DoublePaging}
                  onClick={() => {
                    if (Number(page) < 11) {
                      PageChange(1);
                    } else {
                      PageChange(Number(page) - 10);
                    }
                  }}
                />
                <Icon margin={`0 16px 0 0`} width={`calc(1rem * (20 / 14))`} cursor={`pointer`} src={OnePaging} onClick={prevHandler} />

                {PageList.length
                  ? PageList.map((item, idx) => (
                      <TableFooterPaging
                        key={`paging_number_${idx}`}
                        margin={`0 16px 0 0`}
                        color={`${num == item ? "#3383FF" : "#ACAFB5"}`}
                        onClick={() => PageChange(item)}
                      >
                        {item}
                      </TableFooterPaging>
                    ))
                  : ""}

                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  transform={`rotate(180deg)`}
                  src={OnePaging}
                  onClick={nextHandler}
                />
                <Icon
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  transform={`rotate(180deg)`}
                  src={DoublePaging}
                  onClick={() => {
                    if (Number(page) + 10 > numPages) {
                      PageChange(numPages);
                    } else {
                      PageChange(Number(page) + 10);
                    }
                  }}
                />
              </TableFooter>
            </section>
          </TableContent>
        </Table>
      </DesignedMainLayout>
      <DrawerModal open={open} setOpen={setOpen}>
        {selected.id && selected.number && <AlarmTalkDetail selected={selected} />}
      </DrawerModal>
    </>
  );
};

export default AlarmTalk;

const Input = styled.input`
  width: ${({ width }) => `${width}`};
  display: inline-block;
`;
