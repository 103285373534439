import FreeDive from "../../assets/icon/freedivingIcon.png";
import ScubaDive from "../../assets/icon/scubaDivingIcon.png";
import Surf from "../../assets/icon/surfingIcon.png";
import Paddle from "../../assets/icon/paddleBoardIcon.png";
import Swim from "../../assets/icon/swimingIcon.png";
import SnowBoard from "../../assets/icon/snowBoardIcon.png";
import Ski from "../../assets/icon/skiIcon.png";

export const SportArray = [
  {
    id: 1,
    name: "프리다이빙",
    src: FreeDive,
  },
  {
    id: 2,
    name: "스쿠버다이빙",
    src: ScubaDive,
  },
  {
    id: 3,
    name: "서핑",
    src: Surf,
  },
  {
    id: 4,
    name: "패들보드",
    src: Paddle,
  },
  {
    id: 5,
    name: "수영",
    src: Swim,
  },
  {
    id: 6,
    name: "스노보드",
    src: SnowBoard,
  },
  {
    id: 7,
    name: "스키",
    src: Ski,
  },
];

export const FacilityArray = [
  {
    id: 1,
    name: "주차장",
    value: "parking",
  },
  {
    id: 2,
    name: "샤워실",
    value: "shower_room",
  },
  {
    id: 3,
    name: "탈의실",
    value: "fitting_room",
  },
  {
    id: 4,
    name: "음식점",
    value: "cafeteria",
  },
];
