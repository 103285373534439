import React, { useState, useEffect, useRef } from "react";

import { Wrapper, AbsoluteWrapper, Text } from "../../layouts/layoutStorage";

import colors from "../../styles/constants/colors";

const TimePicker = ({ time, setTime, status, setStatus }) => {
  const ref = useRef();

  const TimeList = [
    `00:00`,
    `00:30`,
    `01:00`,
    `01:30`,
    `02:00`,
    `02:30`,
    `03:00`,
    `03:30`,
    `04:00`,
    `04:30`,
    `05:00`,
    `05:30`,
    `06:00`,
    `06:30`,
    `07:00`,
    `07:30`,
    `08:00`,
    `08:30`,
    `09:00`,
    `09:30`,
    `10:00`,
    `10:30`,
    `11:00`,
    `11:30`,
    `12:00`,
    `12:30`,
    `13:00`,
    `13:30`,
    `14:00`,
    `14:30`,
    `15:00`,
    `15:30`,
    `16:00`,
    `16:30`,
    `17:00`,
    `17:30`,
    `18:00`,
    `18:30`,
    `19:00`,
    `19:30`,
    `20:00`,
    `20:30`,
    `21:00`,
    `21:30`,
    `22:00`,
    `22:30`,
    `23:00`,
    `23:30`,
  ];

  const CalenderClose = (e) => {
    if (status && (!ref.current || !ref.current.contains(e.target))) {
      setStatus(false);
    }
    // console.log(calRef.current.contains(e.target), calRef.current);
  };

  useEffect(() => {
    if (status) {
      window.addEventListener("click", CalenderClose);

      return () => {
        window.removeEventListener("click", CalenderClose);
      };
    }
  }, [status]);

  return (
    <AbsoluteWrapper
      ai={`flex-start`}
      height={`200px`}
      of={`hidden auto`}
      display={status ? `inline-flex` : `none`}
      z={`150`}
      left={`0`}
      bottom={`-8px`}
      border={`1px solid ${colors.Gr02}`}
      transform={`translate(0, 100%)`}
      width={`100%`}
      padding={`13px 2px`}
      bgc={colors.White}
      ref={ref}
    >
      <Wrapper wrap={`wrap`} width={`100%`} position={`relative`}>
        {TimeList.map((item, idx) => (
          <Wrapper
            onClick={() => setTime(item)}
            key={`${idx}_time_picker`}
            border={time == item && `1px solid ${colors.Bl07}`}
            padding={`5px 0`}
            cursor={`pointer`}
            width={`100%`}
            jc={`center`}
          >
            <Text
              color={time == item && `${colors.Bl07}`}
              className={`f13`}
            >{`${item}`}</Text>
          </Wrapper>
        ))}
      </Wrapper>
    </AbsoluteWrapper>
  );
};

export default TimePicker;
