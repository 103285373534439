import React, { useState, useEffect } from "react";
import styled from "styled-components";

// img
import GreyPlus from "../../assets/icon/grayPlus.png";
import BluePlus from "../../assets/icon/bluePlus.png";
import XIcon from "../../assets/icon/tagDelete.png";
import SearchIcon from "../../assets/icon/search.png";
import colors from "../../styles/constants/colors";
import {
  TableFilterContainer,
  TableFilterContent,
  TableWrapper,
  Wrapper,
  WrapperRow,
  AbsoluteWrapper,
  Icon,
  Text,
  TableContent,
  TableFooter,
  TableColumn,
  TableFooterPaging,
  WrapperColmun,
} from "../../layouts/layoutStorage";

const H = window.innerHeight;
const W = window.innerWidth;

const ActivityKeyword = ({ open, data, setData, deleteD, setDelete, textRecommend, bigData }) => {
  const [input, setInput] = useState({
    exposed: "",
    search: "",
  });

  const settingKeyWords = (key) => {
    var arr = [...data[key]];
    if (!arr.includes(input[key])) {
      arr.push({ id: "new", change: "yes", content: input[key] });
      //   arr.push({ id: "new", change: "yes", tag_name: input[key] });
      setData((prev) => ({ ...prev, [key]: arr }));
    } else {
      alert("키워드를 중복해서 추가 할 수 없습니다.");
    }
    setInput((prev) => ({ ...prev, [key]: "" }));
  };

  const settingKeyWordsRecommend = (value, key) => {
    var arr = [...data[key]];
    if (!arr.includes(value)) {
      arr.push({ id: "new", change: "yes", content: value });
      setData((prev) => ({ ...prev, [key]: arr }));
    } else {
      alert("키워드를 중복해서 추가 할 수 없습니다.");
    }
    setInput((prev) => ({ ...prev, [key]: "" }));
  };

  const deleteKeyWords = (key, value, index) => {
    var arr = [...data[key]];
    var deleted = [...deleteD[key]];
    deleted.push(arr[index]);

    arr = arr.filter((x) => x !== value);
    setDelete((prev) => ({ ...prev, [key]: deleted }));
    setData((prev) => ({ ...prev, [key]: arr }));
  };

  const SearchKeyword = (value, category) => {
    setInput((prev) => ({ ...prev, [category]: value }));

    if (value.length > 1) {
      bigData(category, value);
    }
  };

  useEffect(() => {
    setInput({
      exposed: "",
      search: "",
    });
  }, [open]);

  return (
    <div style={{ paddingBottom: "24px" }}>
      <TitleBox>
        <Styler pt={36} pl={36} pb={24} pr={36} flex={true} justify="space-between" align="center">
          <Title className="f16">키워드 설정</Title>
        </Styler>
      </TitleBox>

      <Styler pt={24} pl={36} pr={36}>
        <Styler flex={true} justify="space-between" align="center" pb={12}>
          <SmallTitle className="f14">노출 키워드</SmallTitle>
          <BlueIndicator>2개까지 입력이 가능해요</BlueIndicator>
        </Styler>

        {/* <TagInputDiv>
              <TagInputDiv
                placeholder="# 태그로 검색해 보세요"
                value={input.exposed}
                onChange={(e) =>
                  setInput((prev) => ({ ...prev, exposed: e.target.value }))
                }
              />
            </TagInputDiv> */}

        <TagInputDiv>
          <TagInput
            disabled={data.exposed?.length === 2}
            placeholder="# 태그로 검색해 보세요"
            value={input.exposed}
            onChange={(e) => SearchKeyword(e.target.value, "exposed")}
          />
          <Icon width={`calc(1rem * (17 / 14))`} src={SearchIcon} />
        </TagInputDiv>

        {input.exposed && (
          <Styler pt={4}>
            <SearchBox>
              <SearchList>
                {textRecommend.exposed &&
                  textRecommend.exposed.map((el, idx) => (
                    <SearchItem key={`${idx}_exposed_search`} onClick={() => settingKeyWordsRecommend(el, "exposed")}>
                      <SearchText>{el}</SearchText>
                      <PlusIcon src={GreyPlus} />
                    </SearchItem>
                  ))}
              </SearchList>
              <AddSearchItem onClick={() => settingKeyWords("exposed")}>
                <AddSearchText>{input.exposed} 항목 추가하기</AddSearchText>
                <PlusIcon src={BluePlus} />
              </AddSearchItem>
            </SearchBox>
          </Styler>
        )}
        <TagContainer>
          {data.exposed?.map((el, idx) => (
            <TagItem key={el.content}>
              <TagText>{el.content}</TagText>
              <Xbutton src={XIcon} onClick={() => deleteKeyWords("exposed", el, idx)} />
            </TagItem>
          ))}
        </TagContainer>
      </Styler>

      <Styler pt={18} pl={36} pr={36}>
        <Styler flex={true} justify="space-between" align="center" pb={12}>
          <SmallTitle>검색 키워드</SmallTitle>
          <BlueIndicator>다중 입력이 가능해요</BlueIndicator>
        </Styler>
        <TagInputDiv>
          <TagInput placeholder="# 태그로 검색해 보세요" value={input.search} onChange={(e) => SearchKeyword(e.target.value, "search")} />
          <Icon width={`calc(1rem * (17 / 14))`} src={SearchIcon} />
        </TagInputDiv>
        {input.search && (
          <Styler pt={4}>
            <SearchBox>
              <SearchList>
                {textRecommend.search &&
                  textRecommend.search.map((el, idx) => (
                    <SearchItem key={`${idx}_search_search`} onClick={() => settingKeyWordsRecommend(el, "search")}>
                      <SearchText>{el}</SearchText>
                      <PlusIcon src={GreyPlus} />
                    </SearchItem>
                  ))}
              </SearchList>
              <AddSearchItem onClick={() => settingKeyWords("search")}>
                <AddSearchText>{input.search} 항목 추가하기</AddSearchText>
                <PlusIcon src={BluePlus} />
              </AddSearchItem>
            </SearchBox>
          </Styler>
        )}
        <TagContainer>
          {data.search?.map((el, idx) => (
            <TagItem key={`${idx}_content`}>
              <TagText>{el.content}</TagText>
              <Xbutton src={XIcon} onClick={() => deleteKeyWords("search", el, idx)} />
            </TagItem>
          ))}
        </TagContainer>
      </Styler>
    </div>
  );
};

export default ActivityKeyword;

// const MinHeight = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   min-height: ${H - 105}px;
// `;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  padding-top: ${({ pt }) => `calc(1rem * ${pt} / 14)`};
  padding-bottom: ${({ pb }) => `calc(1rem * ${pb} / 14)`};
  padding-left: ${({ pl }) => `calc(1rem * ${pl} / 14)`};
  padding-right: ${({ pr }) => `calc(1rem * ${pr} / 14)`};
  height: ${({ height }) => height}px;
`;

const TitleBox = styled.div`
  border-top: 1px solid ${colors.Gr02};
`;

const Title = styled.div`
  font-weight: 600;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${({ red }) => (red ? colors.Red02 : colors.Gr05)};
`;

const Indicator = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr04};
`;

const Index = styled.span`
  color: ${({ red }) => (red ? colors.Red02 : colors.Gr05)};
`;

const SmallTitle = styled.div`
  font-weight: 500;
  line-height: 142%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ status }) => (status == "empty" ? colors.Red02 : colors.Gr05)};
`;

const BlueIndicator = styled.div`
  font-weight: 500;

  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${({ status }) => (status == "empty" ? colors.Red02 : colors.Bl07)};
`;

const TagInputDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;

  border: 1px solid ${({ red }) => (red ? colors.Red02 : colors.Gr02)};
`;

const TagInput = styled.input`
  font-weight: 500;
  font-size: 1rem;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  width: 100%;

  &::placeholder {
    color: ${colors.Gr02};
  }
`;

const SearchBox = styled.div`
  border: 1px solid ${colors.Gr02};
`;

const SearchList = styled.div``;

const SearchItem = styled.div`
  border-bottom: 1px solid ${colors.Gr02};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 8px 10px 18px;
`;

const AddSearchItem = styled(SearchItem)`
  border: none;
  background-color: ${colors.Bl01};
`;

const SearchText = styled.div`
  font-weight: 500;
  font-size: calc(1rem * 12 / 14);
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  max-width: ${W - 98}px;
  word-break: break-all;
  text-overflow: ellipsis;
`;

const AddSearchText = styled(SearchText)`
  color: ${colors.Bl07};
`;

const PlusIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const TagItem = styled.div`
  border: 1.2px solid ${colors.Gr02};
  padding: 6px 12px;
  display: flex;
  align-items: center;
  background-color: ${colors.Gr01};
  border-radius: 24px;
  margin-right: 9px;
  margin-bottom: 8px;
`;

const TagText = styled.div`
  font-weight: 500;
  font-size: calc(1rem * 13 / 14);
  line-height: 142%;
  display: flex;
  align-items: center;

  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  max-width: ${W - 64}px;
  word-break: break-all;
  text-overflow: ellipsis;
`;

const Xbutton = styled.img`
  width: 12px;
  height: 18px;
  margin-left: 4px;
  cursor: pointer;
`;

const SubmitButton = styled.div`
  background-color: ${colors.Bl07};
  color: ${colors.White};
  font-weight: 600;
  font-size: 16px;
  line-height: 142%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  margin: 0 24px 20px 24px;
  padding: 16.5px 0;
`;
