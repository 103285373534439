export const curriculumData = [
  {
    text: "소요 시간",
    value: "소요 시간",
  },
  {
    text: "30분",
    value: "30분",
  },
  {
    text: "1시간",
    value: "1시간",
  },
  {
    text: "1시간 30분",
    value: "1시간 30분",
  },
  {
    text: "2시간",
    value: "2시간",
  },
  {
    text: "2시간 30분",
    value: "2시간 30분",
  },
  {
    text: "3시간",
    value: "3시간",
  },
  {
    text: "3시간 30분",
    value: "3시간 30분",
  },
  {
    text: "4시간",
    value: "4시간",
  },
  {
    text: "4시간 30분",
    value: "4시간 30분",
  },
  {
    text: "5시간",
    value: "5시간",
  },
  {
    text: "5시간 30분",
    value: "5시간 30분",
  },
  {
    text: "6시간",
    value: "6시간",
  },
  {
    text: "6시간 30분",
    value: "6시간 30분",
  },
  {
    text: "7시간",
    value: "7시간",
  },
  {
    text: "7시간 30분",
    value: "7시간 30분",
  },
  {
    text: "8시간",
    value: "8시간",
  },
  {
    text: "8시간 30분",
    value: "8시간 30분",
  },
  {
    text: "9시간",
    value: "9시간",
  },
  {
    text: "9시간 30분",
    value: "9시간 30분",
  },
  {
    text: "10시간",
    value: "10시간",
  },
];
