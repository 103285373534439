import styled from "styled-components";
import ImageInput from "../../imageInput";

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const Wrapper = styled.div`
  &:not(:last-child) {
    margin-right: 20px;
  }
`;

const BgBox = ({ imageChangeHandler, imageChangeHandler1, imageChangeHandler2, imageChangeHandler3, image, imagePro }) => {
  return (
    <Container>
      <Wrapper>
        <ImageInput
          border={true}
          name="bg-first"
          changeHandler={         
            imageChangeHandler1}
          preview={image ? image[0] : ""}
          width="240px"
          height="170px"
          imagePro={imagePro}
        />
      </Wrapper>

      <Wrapper>
        <ImageInput
          border={true}
          name="bg-second"
          changeHandler={         
            imageChangeHandler2}
          preview={image ? image[1] : ""}
          width="240px"
          height="170px"
          imagePro={imagePro}
        />
      </Wrapper>

      <Wrapper>
        <ImageInput
          border={true}
          name="bg-third"
          changeHandler={         
            imageChangeHandler3}
          preview={image ? image[2] : ""}
          width="240px"
          height="170px"
          imagePro={imagePro}
        />
      </Wrapper>
    </Container>
  );
};

export default BgBox;
