import { Link } from "react-router-dom";
import styled from "styled-components";
import ToggleButton from "../../modules/toggleButton";
import flexbox from "../../styles/func/flexbox";

const Card = styled.li`
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
`;

const SLink = styled(Link)`
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;

  div {
    white-space : nowrap;
    overflow : hidden;
    text-overflow : ellipsis;

    &:first-child {
      width: calc(10% + ((10 / 90) * 10%) );
      padding-right : 2px;
    }
  
    &:nth-child(2) {
      width: calc(25% + ((25 / 90) * 10%) );
      padding-right : 2px;
    }
  
    &:nth-child(3) {
      width: calc(8% + ((8 / 90) * 10%) );
      padding-right : 2px;
    }
  
    &:nth-child(4) {
      width: calc(11% + ((11 / 90) * 10%) );
      padding-right : 2px;
    }
  
    &:nth-child(5) {
      width: calc(8% + ((8 / 90) * 10%) );
      padding-right : 2px;
    }
  
    &:nth-child(6) {
      width: calc(18% + ((18 / 90) * 10%) );
      padding-right : 2px;
    }
  
    &:nth-child(7) {
      width: calc(10% + ((10 / 90) * 10%) );
      padding-right : 2px;
    }
  }
`;

const StatusDiv = styled.div`
  width: 10%;
  ${flexbox()}
`;
const ConfirmDiv = styled.div`
  display : inline-block;
  pointer-events : none;
  color : #E53553;
`


const ActivityRowCard = ({ data, statusToggleHandler, status }) => {
  return (
    <Card>
      <SLink to={`/partner/${data.partner_id}/lesson/${data.service_id}`}>
        <div>{data.number}</div>

        <div>{data.name}</div>

        <div>{data.sport}</div>

        <div>{data.service_type}</div>

        <div>{data.price}</div>

        <div>{`${data.location} ${data.location_extra}`}</div>

        <div>{data.partner_name}</div>

      </SLink>

      <StatusDiv>
        {data.status == 'yes' || data.status == 'close' ? <ToggleButton
          toggleHandler={() =>
            statusToggleHandler(data.id)
          }
          status={data.status}
          type="activity"
        /> : <ConfirmDiv>미승인</ConfirmDiv>}
      </StatusDiv>
    </Card>
  );
};

export default ActivityRowCard;
