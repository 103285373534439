import styled from "styled-components";
import MainHeader from "../../../components/header";
import usePagination from "../../../hooks/usePagination";
import MainLayout from "../../../layouts/mainLayout";
import noticeList from "../../../data/notice/index";
import NoticeCard from "../../../components/notice/card";
import base from "../../../styles/constants/base";
import flexbox from "../../../styles/func/flexbox";
import typography from "../../../styles/constants/typography";
import colors from "../../../styles/constants/colors";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

import Pagination from "../../../components/pagination";
import FixedButton from "../../../modules/fixedButton";

const NOTICE_PER_PAGE = 6;
const NOTICE_CARD_HEIGHT = 200;

const Main = styled.main`
  max-width: 100%;
  ${flexbox()}

  ul {
    margin-top: 20px;
    width: 80%;
    display: inline-block;
    flex-wrap: wrap;
    min-height: ${(NOTICE_PER_PAGE / 2) * NOTICE_CARD_HEIGHT + 60}px;
  }
`;

const Footer = styled.footer`
  width: 100%;
  padding: 0 ${base.spacing.main}px;
  position: absolute;
  bottom: 0px;
  height: 90px;
`;

// pagination
const Div = styled.div`
  width: 100%;
  height: 100%;
  ${flexbox("space-between")};
  font-weight: ${typography.weight.regular};
`;

const SectionButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: ${colors.white};
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};

  &:focus {
    box-shadow: ${base.boxShadow.default};
  }
`;

const List = styled.ul`
  ${flexbox()}
`;

const PageButton = styled.li`
  width: 40px;
  height: 40px;
  border: ${({ active }) => (active ? `2px solid ${colors.blue}` : null)};
  color: ${({ active }) => (active ? colors.blue : null)};
  ${flexbox()};
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};
  margin: 0 5px;
  cursor: pointer;
`;

const NoticePage = () => {
  const navigate = useNavigate();
  const { num } = useParams();
  const numCheck = num > 1;

  const { offset, limit, currentPage, setCurrentPage } = usePagination(
    noticeList,
    6
  );

  const [notice, setNotice] = useState("");
  const [noticeCount, setNoticeCount] = useState(0);
  const [page, setPage] = useState(num);

    // 공지사항 리스트 로드
    useEffect(() => {
    
        var token = window.localStorage.getItem('token')
  
        const frm = new FormData();
        frm.append("mode", numCheck ? "notice_update" : "notice");
        frm.append("token", token);
        frm.append("page", num);
  
          axios.post("https://ai.semos.kr/semos_admin/notice/data", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          }).then((data) => {
            var Value = data.data;
            var status = Value.status
            var statusMessage = Value.status_message
  
            if(status == 'success') {
  
              setNotice(Value.notice_list)
              setNoticeCount(Value.notice_count)

              navigate(`/notice_list/${num}`)
  
            } else if(status == 'waring') {
              alert(statusMessage)
            }
  
          });
  
    }, []);

  const goToNoticeRegisterHandler = () => {
    navigate("/notice/register");
  };

  const prevHandler = () => {
    if (page > 1) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "notice_update");
      frm.append("token", token);
      frm.append("page", page - 1);

        axios.post("https://ai.semos.kr/semos_admin/notice/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setNotice(Value.notice_list)
            setNoticeCount(Value.notice_count)
            setPage(page - 1)
            navigate(`/notice_list/${page - 1}`)
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    } else {
      alert('첫 페이지입니다.');
    }
  };

  const nextHandler = () => {
    if (page < numPages) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "notice_update");
      frm.append("token", token);
      frm.append("page", page + 1);

        axios.post("https://ai.semos.kr/semos_admin/notice/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setNotice(Value.notice_list)
            setNoticeCount(Value.notice_count)
            setPage(page + 1)
            navigate(`/notice_list/${page + 1}`)
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    } else {
      alert('마지막 페이지입니다.');
    }
  };

  const PageChange = (number) => {
    if ( number < (numPages + 1) && number > 0 ) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "notice_update");
      frm.append("token", token);
      frm.append("page", number);

        axios.post("https://ai.semos.kr/semos_admin/notice/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setNotice(Value.notice_list)
            setNoticeCount(Value.notice_count)
            setPage(number)
            navigate(`/notice_list/${number}`)
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    }
  };

  const numPages = Math.ceil(noticeCount / 8);
  let PageList = [];
  
  if(page < 6) {
    //PageList = [1,2,3,4,5,6,7,8,9,10];
    for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
      PageList.push(i+1);
    }

  } else {
    if( (numPages - 5) < page ) {
      var startPage = numPages - 9;
      if(startPage < 1) {
        startPage = 1;
      }

      for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
        PageList.push(startPage + i);
      }

    } else {
      var startPage = page - 4;
      if(startPage < 1) {
        startPage = 1;
      }
      
      for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
        PageList.push(startPage + i);
      }
    }
  }

  return (
    <MainLayout
      button={{ type: "plus", clickHandler: goToNoticeRegisterHandler }}
    >
      <MainHeader
        title="공지사항"
        subtitle="수정하고 싶은 공지사항을 클릭해 주세요."
      />

      <Main>
        <ul>
          {notice ? notice.map((item, idx) => (
            <NoticeCard key={idx} notice={item} idx={idx} />
          )) : ""}
        </ul>
      </Main>

      <Footer>
        {/*<Pagination
          total={noticeList.length}
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />*/}

          <Div>
            <SectionButton onClick={prevHandler}>&lt;</SectionButton>

            <List>
              {PageList.length ? PageList.map((item, idx) => 
                ( <PageButton
                    key={idx}
                    onClick={() => PageChange(item)}
                    aria-current={page == item ? "page" : null}
                    active={page == item ? true : false}
                  >
                    {item}
                  </PageButton>
                )) : ""}
            </List>

            <SectionButton onClick={nextHandler}>&gt;</SectionButton>
          </Div>

      </Footer>
    </MainLayout>
  );
};

export default NoticePage;
