import { useState } from "react";
import styled from "styled-components";
import chevronIcon from "../../assets/icon/chevron-b.svg";
import calendarData from "../../data/calendar";
import base from "../../styles/constants/base";
import colors from "../../styles/constants/colors";
import typography from "../../styles/constants/typography";
import flexbox from "../../styles/func/flexbox";
import DateBoxAll from "./dateBoxAll";

const Container = styled.div`
  margin: 50px 0;
  width: 600px;

  header {
    width: 100%;
    position: relative;
    ${flexbox()}
    margin-bottom: 20px;

    button {
      padding: 15px;
    }

    button:first-child {
      text-decoration: underline;
      color: ${colors.blue};
      position: absolute;
      left: 0;
      padding-left: 0;
      font-size: ${typography.size.small}px;
    }
  }

  footer {
    ${flexbox("flex-end")}

    p:first-child {
      position: relative;
      margin-right: 70px;

      &::before {
        position: absolute;
        content: "";
        width: 15px;
        height: 15px;
        border: 3px solid ${colors.red};
        left: -30px;
      }
    }

    p:last-child {
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 21px;
        height: 21px;
        background-color: ${colors.blue};
        left: -30px;
      }
    }
  }
`;

const DatetimeInfo = styled.div`
  font-weight: ${typography.weight.regular};

  span:first-child {
    margin-right: 10px;
  }
`;

const PrevButton = styled.button`
  height: 40px;
  width: 40px;
  margin-right: 20px;
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};
  ${flexbox()}

  &:focus {
    box-shadow: ${base.boxShadow.default};
  }

  img {
    transform: rotate(180deg);
  }
`;

const NextButton = styled.button`
  margin-left: 20px;
  height: 40px;
  width: 40px;
  margin-right: 20px;
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};
  ${flexbox()};

  &:focus {
    box-shadow: ${base.boxShadow.default};
  }

  img {
    transform: rotate(0deg);
  }
`;

const CalendarAll = ({
  allData,
  targetDay,
  targetDayList = "",
  currentDate,
  targetDatetime,
  setTargetDatetime,
  setTargetDay,
  addTargetDayListHandler = "",
}) => {
  const [targetLastDate, setTargetLastDate] = useState({
    date: new Date(targetDatetime.year, targetDatetime.month + 1, 0).getDate(),
    day: new Date(targetDatetime.year, targetDatetime.month, 1).getDay(),
  });

  // TODO: data fetching 필요
  const data = calendarData;

  const [dataOfTargetDay, setDataOfTargetDay] = useState(data[1]);

  // 이전 달
  const movePrevMonth = () => {
    const { year, month } = targetDatetime;

    /*if (
      year === currentDate.getFullYear() &&
      month - 1 < currentDate.getMonth()
    ) {
      return;
    }*/

    /*if (month - 1 === currentDate.getMonth()) {
      setTargetDatetime((prev) => ({
        ...prev,
        date: currentDate.getDate(),
      }));
      setTargetDatetime(targetDatetime.year, , 1)
    }*/

    if (month - 1 < 0) {
      /*setTargetDatetime((prev) => ({
        ...prev,
        year: prev.year - 1,
        month: 11,
      }));*/
      setTargetDatetime(targetDatetime.year, 11, 1);
    } else {
      /*setTargetDatetime((prev) => ({
        ...prev,
        month: prev.month - 1,
      }));*/
      setTargetDatetime(targetDatetime.year, targetDatetime.month - 1, 1);
    }

    setTargetLastDate({
      date: new Date(targetDatetime.year, targetDatetime.month, 0).getDate(),
      day: new Date(targetDatetime.year, targetDatetime.month - 1, 1).getDay(),
    });

    setTargetDay([]);
  };

  // 다음 달
  const moveNextMonth = () => {
    if (targetDatetime.month + 1 > 11) {
      setTargetDatetime(targetDatetime.year + 1, 0, 1);
    } else {
      setTargetDatetime(targetDatetime.year, targetDatetime.month + 1, 1);
    }

    setTargetLastDate({
      date: new Date(
        targetDatetime.year,
        targetDatetime.month + 2,
        0
      ).getDate(),
      day: new Date(targetDatetime.year, targetDatetime.month + 1, 1).getDay(),
    });

    setTargetDay([]);
  };

  return (
    <Container>
      <header>
        <button type="button" onClick={"" /*comeBackTodayHandler*/}>
          {/*오늘*/}
        </button>

        <PrevButton type="button" onClick={movePrevMonth}>
          <img src={chevronIcon} />
        </PrevButton>

        <DatetimeInfo>
          <span>{targetDatetime.year}년</span>
          <span>{targetDatetime.month + 1}월</span>
        </DatetimeInfo>

        <NextButton type="button" onClick={moveNextMonth}>
          <img src={chevronIcon} />
        </NextButton>
      </header>

      <DateBoxAll
        targetDayList={targetDayList}
        lastDay={targetLastDate.day}
        lastDate={targetLastDate.date}
        datetime={targetDatetime}
        targetDay={targetDay}
        setTargetDate={setTargetDatetime}
        setTargetDay={setTargetDay}
        data={allData}
        addTargetDayListHandler={addTargetDayListHandler}
      />

      <footer>
        <p>등록한 일정</p>
        <p>선택한 일정</p>
      </footer>
    </Container>
  );
};

export default CalendarAll;
