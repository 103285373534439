import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { Link } from "react-router-dom";

import { Wrapper, WrapperColmun, Text, Icon, AbsoluteWrapper } from "../../layouts/layoutStorage";
import ToggleButton from "../../modules/toggleButton";

import { ReactComponent as Plus } from "../../assets/icon/plus.svg";
import CameraAdd from "../../assets/icon/whiteCameraAdd.png";

import colors from "../../styles/constants/colors";

const MainBannerPage = () => {
  const [banner, setBanner] = useState(["", "", "", "", ""]);
  const [boxSize, setBoxSize] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setBoxSize(document.getElementById("banner")?.offsetWidth / 5);
    }, 500);
  }, [boxSize]);

  const getData = () => {
    const token = localStorage.getItem("token");
    const frm = new FormData();

    frm.append("mode", "main_banner");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_admin/banner/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        setBanner(data.data.list);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const changeStatus = (target) => {
    const token = localStorage.getItem("token");
    const frm = new FormData();

    frm.append("mode", "main_banner_status");
    frm.append("token", token);
    frm.append("id", target);

    axios
      .post("https://ai.semos.kr/semos_admin/banner/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        setBanner(data.data.list);
      });
  };

  const deleteBanner = (target) => {
    const token = localStorage.getItem("token");
    const frm = new FormData();

    frm.append("mode", "main_banner_delete");
    frm.append("token", token);
    frm.append("id", target);

    axios
      .post("https://ai.semos.kr/semos_admin/banner/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        setBanner(data.data.list);
      });
  };

  return (
    <Wrapper width="100%" id="banner" of="auto">
      {boxSize > 0 &&
        banner.map((item, index) =>
          item ? (
            <Wrapper
              width={`${boxSize}px`}
              height={`${boxSize}px`}
              style={{ minWidth: "250px", minHeight: "250px" }}
              padding="12px"
              bgc={colors.White}
              jc="center"
              ai="center"
              //   cursor="pointer"
              // onClick={() => window.open(`/main_banner/${index + 1}`)}
              bl={index && `2px solid ${colors.Gr01}`}
            >
              <WrapperColmun width="100%" height="100%" padding="0 8.5px 6px 8.5px" ai="center">
                <Wrapper width="100%" jc="flex-end">
                  <ToggleButton type="main_banner" status={item.status} toggleHandler={() => changeStatus(index + 1)} />
                </Wrapper>

                <Wrapper height="12px" />

                <Wrapper width="178px" height="135px" radius="8px" position="relative">
                  <Icon width="100%" height="100%" src={item.image} radius="8px" />
                  <AbsoluteWrapper
                    width="100%"
                    height="100%"
                    bgc={colors.B50}
                    radius="8px"
                    cursor="pointer"
                    // onClick={() => window.open(`/main_banner/${index + 1}`)}
                  >
                    <Link to={`/main_banner/${index + 1}`} style={{ width: "100%", height: "100%" }}>
                      <WrapperColmun width="100%" height="100%" jc="center" ai="center">
                        <Icon src={CameraAdd} width="40px" height="40px" />

                        <Wrapper height="8px" />

                        <Text className="f12" weight="500" color={colors.White}>
                          사진 편집하기
                        </Text>
                      </WrapperColmun>
                    </Link>
                  </AbsoluteWrapper>
                </Wrapper>

                <Wrapper height="12px" />

                <Wrapper jc="space-between" width="100%">
                  <Text className="f14" weight="600" color={colors.Black}>
                    배너 명
                  </Text>
                  <Text className="f14" weight="500" color={colors.Gr03}>
                    {item.title}
                  </Text>
                </Wrapper>

                <Wrapper height="8px" />

                <Wrapper jc="space-between" width="100%">
                  <Text
                    className="f14"
                    weight="600"
                    color={colors.Black}
                    ws="nowrap"
                    // margin="0 16px 0 0"
                  >
                    업로드 기간
                  </Text>
                  <Text className="f14" weight="500" color={colors.Gr03} ws="nowrap" ellipsis>
                    {`${item.start?.split(" ")[0]} ~ ${item.end?.split(" ")[0]}`}
                  </Text>
                </Wrapper>

                <Wrapper height="8px" />

                <Wrapper jc="space-between" width="100%">
                  <Text className="f14" weight="600" color={colors.Black}>
                    업로드 타입
                  </Text>
                  <Text className="f14" weight="500" color={colors.Gr03}>
                    {`${item.page.includes("Activity") ? "액티비티 상세페이지" : item.page.includes("Partner") ? "파트너 프로필" : "기획전"}`}
                  </Text>
                </Wrapper>

                <Wrapper height="8px" />

                <Wrapper jc="space-between" width="100%">
                  <Text className="f14" weight="600" color={colors.Black}>
                    {`${item.page.includes("Activity") ? "액티비티 ID" : item.page.includes("Partner") ? "파트너 ID" : "기획전 ID"}`}
                  </Text>
                  <Text className="f14" weight="500" color={colors.Gr03}>
                    {`${item.target}`}
                  </Text>
                </Wrapper>

                <Wrapper height="20px" />

                <Wrapper jc="flex-end" width="100%">
                  {/* <Wrapper padding="6px 12px"> */}
                  <Text className="f14" weight="500" color={colors.Gr04} style={{ cursor: "pointer" }} onClick={() => deleteBanner(index + 1)}>
                    삭제하기
                  </Text>
                </Wrapper>
                {/* </Wrapper> */}
              </WrapperColmun>
            </Wrapper>
          ) : (
            <Wrapper
              width={`${boxSize}px`}
              height={`${boxSize}px`}
              style={{ minWidth: "250px", minHeight: "250px" }}
              padding="12px"
              bgc={colors.White}
              jc="center"
              ai="center"
              cursor="pointer"
              onClick={() => window.open(`/main_banner/${index + 1}`)}
              bl={index && `2px solid ${colors.Gr01}`}
            >
              <WrapperColmun jc="center" ai="center" width="100%" height="100%">
                <Plus width="40px" height="40px" fill={colors.Gr03} />
                <Wrapper height="8px" />
                <Text className="f12" weight="500" color={colors.Gr03}>
                  배너 등록
                </Text>
              </WrapperColmun>
            </Wrapper>
          )
        )}
    </Wrapper>
  );
};

export default MainBannerPage;
