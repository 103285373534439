import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

//img
import Link from "../../assets/icon/link.png";
import EmptyImage from "../../assets/icon/emptyImage.png";

// components
import { Wrapper } from "../../layouts/layoutStorage";
import ImageCrop from "../../components/designedComponents/imageCrop";
import colors from "../../styles/constants/colors";

const BannerInput = () => {
  const { n, t } = useParams();
  const navigate = useNavigate();

  const [input, setInput] = useState({});

  useEffect(() => {
    BannerSpecific();
  }, [n]);

  const BannerSpecific = () => {
    if (n == "new") {
      setInput({
        id: "new",
        title: "",
        content: "",
        image_m: "",
        image_p: "",
        method: "",
        link: "",
        order: "",
        status: "",
      });
    } else {
      var token = localStorage.getItem("token");

      const frm = new FormData();

      frm.append("mode", "banner_specific");
      frm.append("token", token);
      frm.append("number", n);

      axios
        .post("https://ai.semos.kr/semos_admin/banner/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var Status = Value.status;
          var BannerInfo = Value.banner_info;

          if (Status == "success") {
            setInput(BannerInfo);
          }
        });
    }
  };

  const WHList = {
    1: {
      pc: { width: 860, height: 278 },
      mobile: { width: 314, height: 181 },
    },
    2: {
      pc: { width: 315, height: 202 },
      mobile: { width: 315, height: 202 },
    },
    3: {
      pc: { width: 315, height: 75 },
      mobile: { width: 315, height: 75 },
    },
    4: {
      pc: { width: 315, height: 202 },
      mobile: { width: 315, height: 202 },
    },
  };

  const [cropImage, setCropImage] = useState({
    width: 1,
    height: 1,
    type: "",
    number: "",
    image: "",
    status: false,
    scale: true,
  });

  const BannerSave = () => {
    if (window.confirm("배너를 저장하시겠습니까?")) {
      var token = window.localStorage.getItem("token");

      axios
        .post(
          "https://ai.semos.kr/semos_admin/banner/data",
          {
            mode: "banner_save",
            token: token,
            info: input,
            type: t,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            alert(statusMessage);
            if (n === "new") {
              navigate(`/banner/1/1/order/all/all`);
            } else {
              window.location.reload();
            }
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    }
  };

  const setImageFromFile = ({ file, setImageUrl }) => {
    let reader = new FileReader();
    reader.onload = function () {
      setImageUrl({ result: reader.result });
    };
    reader.readAsDataURL(file);
  };

  const ImgChange = (value, type, number, PM) => {
    setCropImage({
      width: WHList[t][PM].width,
      height: WHList[t][PM].height,
      type: type,
      number: number,
      image: value,
      status: true,
      scale: true,
    });
  };

  return (
    <>
      <Wrapper
        bgc={colors.White}
        of={`hidden auto`}
        margin="10px 0 0 0"
        display={`inline-block`}
        width={`100%`}
        mw={`1000px`}
        mh={`calc(100vh - 72px)`}
        padding={`10px 0`}
      >
        <BannerContainer>
          <div>
            <NameInputBox>
              <NameInput
                className="f20"
                placeholder="배너 이름을 작성해 주세요"
                value={input ? input.title : ""}
                onChange={(e) =>
                  setInput((prev) => ({ ...prev, title: e.target.value }))
                }
              />
            </NameInputBox>
            <ImageBoxes>
              <ImageBox>
                <Category className="f16">PC 버전</Category>
                <ImageContainer
                  src={input && (input.image_p ? input.image_p : EmptyImage)}
                  w={WHList[t]["pc"].width}
                  h={0.365}
                />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <input
                    type="file"
                    id="image_p"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={({ target: { files } }) => {
                      if (files.length) {
                        setImageFromFile({
                          file: files[0],
                          setImageUrl: ({ result }) => {
                            setInput((prev) => ({ ...prev, image_p: result }));
                          },
                        });
                      }
                    }}
                  />
                  <AddButton htmlFor="image_p" className="f13">
                    이미지 등록하기
                  </AddButton>
                </div>
              </ImageBox>
              <ImageBox>
                <Category className="f16">모바일 버전</Category>
                <ImageContainer
                  src={input && (input.image_m ? input.image_m : EmptyImage)}
                  w={WHList[t]["mobile"].width}
                  h={0.76}
                />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <input
                    type="file"
                    id="image_m"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={({ target: { files } }) => {
                      if (files.length) {
                        setImageFromFile({
                          file: files[0],
                          setImageUrl: ({ result }) => {
                            setInput((prev) => ({ ...prev, image_m: result }));
                          },
                        });
                      }
                    }}
                  />
                  <AddButton htmlFor="image_m" className="f13">
                    이미지 등록하기
                  </AddButton>
                </div>
              </ImageBox>
            </ImageBoxes>
            <Category className="f16">연결 방식</Category>
            <LinkingContainer>
              <ContainerDiv>
                <LinkButton
                  active={input && input.method == 2}
                  className="f11"
                  onClick={() => setInput((prev) => ({ ...prev, method: 2 }))}
                >
                  리스팅 연결
                </LinkButton>

                <LinkButton
                  active={input && input.method == 1}
                  className="f11"
                  onClick={() => setInput((prev) => ({ ...prev, method: 1 }))}
                >
                  페이지 연결
                </LinkButton>
              </ContainerDiv>

              <LinkLocationContainer>
                <LinkIcon src={Link} />
                <LinkLocationInput
                  className="f12"
                  placeholder={
                    input &&
                    (input.method == 2
                      ? "노출될 액티비티 ID를 작성해 주세요."
                      : "노출될 액티비티 ID를 쉼표 (,)로 구분하여 입력해 주세요.")
                  }
                  onChange={(e) =>
                    setInput((prev) => ({ ...prev, link: e.target.value }))
                  }
                />
              </LinkLocationContainer>
            </LinkingContainer>
          </div>

          <SaveButton onClick={() => BannerSave()}>저장</SaveButton>
        </BannerContainer>
      </Wrapper>
      <ImageCrop
        info={cropImage}
        setInfo={setCropImage}
        setImg={setInput}
        img={input}
      />
    </>
  );
};

export default BannerInput;

const BannerContainer = styled.div`
  padding: 36px 36px 52px 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const NameInputBox = styled.div`
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 36px;
`;

const NameInput = styled.input`
  color: ${colors.Gr05};
  font-weight: 700;

  &::placeholder {
    color: ${colors.Gr03};
  }
`;

const SaveButton = styled.div`
  width: 100%;
  background-color: #0e6dff;
  padding: 12px 0;
  text-align: center;
  color: ${colors.White};
  font-weight: 700;
  border-radius: 8px;
  cursor: pointer;
`;

const ImageBoxes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 52px;
`;

const ImageBox = styled.div`
  /* width: 49%; */
`;

const Category = styled.div`
  color: ${colors.Gr05};
  margin-bottom: 16px;
  font-weight: 700;
`;

const ImageContainer = styled.img`
  width: ${({ w }) => `calc(39vw * ${w} / 747)`};
  //height: ${({ w, h }) => `calc(39vw * ${w * h} / 747)`};
  margin-bottom: 10px;
  border-radius: 8px;
`;

const AddButton = styled.label`
  font-weight: 500;
  color: ${colors.Bl06};
  cursor: pointer;
`;

const LinkingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
`;

const LinkButton = styled.div`
  border: 1px solid;
  border-color: ${({ active }) => (active ? colors.Bl06 : colors.Gr02)};
  border-radius: 8px;
  padding: 9.5px 0;
  color: ${colors.Bl06};
  background-color: ${({ active }) => (active ? colors.Bl01 : colors.White)};
  margin-right: calc(1rem * 10 / 14);
  cursor: pointer;
  width: 95px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  line-height: 100%;
  align-items: center;

  &:hover {
    border-color: ${colors.Bl06};
  }
`;

const LinkLocationContainer = styled.div`
  border: 1px solid ${colors.Gr02};
  border-radius: 22px;
  width: calc(39vw * 435 / 747);
  padding: calc(39vw * 8 / 747) calc(39vw * 16 / 747);
  display: flex;
  align-items: center;
`;

const LinkLocationInput = styled.input`
  width: 100%;
`;

const LinkIcon = styled.img`
  width: calc(1rem * 17 / 14);
  height: calc(1rem * 17 / 14);
  margin-right: 8px;
`;

const ContainerDiv = styled.div`
  display: inline-flex;
`;
