import { Link } from "react-router-dom";
import styled from "styled-components";
import base from "../../styles/constants/base";
import colors from "../../styles/constants/colors";
import typography from "../../styles/constants/typography";

const Card = styled.li`
  width: calc(50% - 20px);
  height: 200px;
  border-radius: ${base.borderRadius.default}px;
  background-color: ${colors.white};
  box-shadow: ${base.boxShadow.default};
  overflow: hidden;
  margin: 10px;
  display : inline-block;
`;

const SLink = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
`;

const ImgBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow : hidden;
  background: linear-gradient(transparent, transparent 0px, rgba(75,75,75,.5));
  z-index : 8;
  
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;

  }
`;

const ImgContent = styled.img`
  width: 100%;
  display : inline-block;
  position : absolute;
  top : 20%;
  right : 0;
  transform : translate(0, -20%);
`;

const MetaData = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, transparent 0px, rgba(75,75,75,.2));
`;

const Date = styled.span`
  position: absolute;
  top: 20px;
  right: 20px;
  color: ${colors.white};
  background-color: rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
  border-radius: 40px;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
`;

const NoticeIcon = styled.span`
  display: inline-block;
  background: ${colors.gradient};
  color: ${colors.white};
  padding: 5px 10px;
  border-radius: 40px;
  font-weight: ${typography.weight.regular};
  margin-bottom: 10px;
  opacity: 0.9;
`;

const Title = styled.p`
  font-size: ${typography.size.medium}px;
  color: ${colors.white};
`;

const NoticeCard = ({ notice, idx }) => {
  return (
    <Card key={idx}>
      <SLink to={`/notice/${notice.number}`}>
        <ImgContent src={notice.thumbnail} />
        {/*<ImgBox />*/}

        <MetaData>
          <Date>{`${notice.date}`}</Date>

          <Footer>
            <NoticeIcon>{notice.category}</NoticeIcon>
            <Title>{notice.subject}</Title>
          </Footer>
        </MetaData>
      </SLink>
    </Card>
  );
};

export default NoticeCard;
