/**
 * lg: Desktop
 */

export const breakpoint = {
  lg: 1050 + 200,
};

const responsive = {
  gutter: 10,

  columns: 12,

  margin: 20,

  maxWidth: 1050,

  // 고민을 좀 더 해봐야겠다
  range: {
    "lg-only": `
    @media screen and (max-width: ${breakpoint.lg - 1}px) {
      display: none !important;
    }
  `,
  },
};

export default responsive;
