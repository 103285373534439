import styled from "styled-components";
import base from "../styles/constants/base";
import colors from "../styles/constants/colors";

const SButton = styled.button`
  position: ${({ pos }) => (pos ? pos.value : null)};
  bottom: ${({ pos }) => (pos ? pos.bottom : null)}px;
  right: ${({ pos }) => (pos ? pos.right : null)}px;
  width: ${({ width }) => width};
  /* box-shadow: ${base.boxShadow.default}; */
  border-radius: ${base.borderRadius.default}px;
  height: ${({ height }) => (height ? height : `44px`)};
  background-color: ${({ color }) => color};
  color: ${({ fontcolor }) => fontcolor};

  &:focus {
    /* box-shadow: ${base.boxShadow.default}; */
    /* border-radius: ${base.borderRadius.default}px; */
  }
`;

const Button = ({
  type = "base",
  pos = null,
  clickHandler,
  text,
  color = `${colors.blue}`,
  fontcolor = `${colors.white}`,
  width = "100%",
  isChange = "",
  pointerEvents,
  className,
  height,
}) => {
  if (type === "base") {
    return (
      <SButton
        type="button"
        onClick={clickHandler}
        width={width}
        color={color}
        fontcolor={fontcolor}
        pos={pos}
        isChange={isChange}
        style={{ pointerEvents: pointerEvents }}
        className={className}
        height={height}
      >
        {text}
      </SButton>
    );
  }
};

export default Button;
