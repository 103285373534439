const colors = {
  // Primary color
  Bl08: "#003C99",
  Bl07: "#0E6DFF", // primary rgba(14, 109, 255, 1)
  Bl06: "#3383FF", // rgba(51, 131, 255, 1)
  Bl05: "#66A2FF", // rgba(102, 162, 255, 1)
  Bl04: "#80B2FF",
  Bl03: "#CCE0FF",
  Bl02: "#E5F0FF", // rgba(229, 240, 255, 1)
  Bl01: "#F2F7FF", // rgba(242, 247, 255, 1)

  // Greyscale color
  Black: "#000000",
  Gr06: "#1A1C1F", // rgba(26, 28, 31, 1)
  Gr05: "#3C3E41", // rgba(60, 62, 65, 1)
  Gr04: "#818792", // rgba(129, 135, 146, 1)
  Gr03: "#ACAFB5", // rgba(172, 175, 181, 1)
  Gr02: "#DCDCDC", // rgba(220, 220, 220, 1) || #E6E6E6
  Gr01: "#F9F9F9", // rgba(249, 249, 249, 1) || rgba(245, 245, 245, 1)
  White: "#FFFFFF",

  // white Transparent color
  W90: "rgba(255, 255, 255, 0.9)",
  W80: "rgba(255, 255, 255, 0.8)",
  W70: "rgba(255, 255, 255, 0.7)",
  W60: "rgba(255, 255, 255, 0.6)",
  W50: "rgba(255, 255, 255, 0.5)",
  W40: "rgba(255, 255, 255, 0.4)",
  W30: "rgba(255, 255, 255, 0.3)",
  W20: "rgba(255, 255, 255, 0.2)",
  W10: "rgba(255, 255, 255, 0.1)",

  // Black Transparent color
  B90: "rgba(0, 0, 0, 0.9)",
  B80: "rgba(0, 0, 0, 0.8)",
  B70: "rgba(0, 0, 0, 0.7)",
  B60: "rgba(0, 0, 0, 0.6)",
  B50: "rgba(0, 0, 0, 0.5)",
  B40: "rgba(0, 0, 0, 0.4)",
  B30: "rgba(0, 0, 0, 0.3)",
  B20: "rgba(0, 0, 0, 0.2)",
  B10: "rgba(0, 0, 0, 0.1)",

  // Functional color
  Red03: "#FF6052", // rgba(255, 103, 89, 1)
  Red02: "#FFB0A8", // rgba(255, 161, 153, 1) || #FF9187
  Red01: "#FFE7E5", // rgba(255, 243, 242, 1)
  Green03: "#96F24E",
  Green02: "#C9EEAC",
  Green01: "#E4F7D5",
  Yellow03: "#FFD966",
  Yellow02: "#FFE8A3",
  Yellow01: "#FFF4D1",

  // Brand color
  Kakao: "#FEE500",
  Naver: "#03C75A", // rgba(3, 199, 90, 1)
  Apple_Black: "#000000",
  Apple_White: "#FFFFFF",

  // Gradient color
  Gradient: `radial-gradient(100% 843.76% at 0% 52.38%, #FF6759 0%, #8D4EF2 44.79%, #0E6DFF 100%)`,
};

export default colors;
