import styled from "styled-components";
import typography from "../../styles/constants/typography";
import flexbox from "../../styles/func/flexbox";

const Box = styled.div`
  width: 100%;
  position: relative;
  ${flexbox("space-between")}
  margin-bottom: 20px;
`;

const Title = styled.span`
  flex-shrink: 0;
  font-size: ${typography.size.me_new}px;
  font-weight: ${typography.weight.regular};
  display: inline-flex;
  align-items: center;

  span {
    font-weight: ${typography.weight.thin};
    font-size: ${typography.size.small}px;
    margin-left: 5px;
  }
`;

const TitleBox = ({ title, unit = "", children }) => {
  return (
    <Box>
      <Title>
        {title}{" "}
        {unit && (
          <span>
            ({unit.text}: {unit.value})
          </span>
        )}
      </Title>
      {children}
    </Box>
  );
};

export default TitleBox;
