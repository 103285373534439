const locationList = [
  {
    id: 1,
    location: "경기 가평군 청평면 고재길 262-57 가평 k26",
    cnt: 3,
  },
  {
    id: 2,
    location: "경기 수원시 팔달구 창룡대로210번길 41 1층 수영장 스포츠아일랜드",
    cnt: 3,
  },
  {
    id: 3,
    location: "인천 연수구 인천신항대로892번길 40 인천환경공단스포츠파크",
    cnt: 3,
  },
  {
    id: 4,
    location: "경기 시흥시 거북섬둘레길 42 시흥 웨이브파크",
    cnt: 3,
  },
  {
    id: 5,
    location: "서울 송파구 올림픽로 25 서울종합운동장 제2수영장",
    cnt: 3,
  },
  {
    id: 6,
    location: "경기 수원시 팔달구 창룡대로210번길 41 1층 수영장 스포츠아일랜드",
    cnt: 3,
  },
  {
    id: 7,
    location: "인천 연수구 인천신항대로892번길 40 인천환경공단스포츠파크",
    cnt: 3,
  },
  {
    id: 8,
    location: "경기 시흥시 거북섬둘레길 42 시흥 웨이브파크",
    cnt: 3,
  },
  {
    id: 9,
    location: "서울 송파구 올림픽로 25 서울종합운동장 제2수영장",
    cnt: 3,
  },
];

export default locationList;
