import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - (48px + 36px + 95px));
  display : inline-block;
`;



const Table = ({ children }) => {
  return (
    <Container>
          {children}
    </Container>
  );
};

export default Table;
