import LocationBox from "../../components/lesson/locationBox";
import useInput from "../../hooks/useInput";
import useList from "../../hooks/useList";
import MapService from "../../service/map";
import ModalLayout from "./layout";

const LocationModal = ({ loAdd, name, loList, setLoList, closeHandler, }) => {
  const {
    value: addressValue,
    setValue: setAddressValue,
    inputChangeHandler: addressInputChangeHandler,
  } = useInput({
    main: "",
    detail: "",
  });
  
  const {
    list: addressList,
    addHandler: addAddressListHandler,
    deleteHandler: deleteAddressHandler,
  } = useList([]);

  /*const targetLocationChangeHandler = (data) => {
    const { address } = data;

    setAddressValue((prev) => ({
      ...prev,
      main: address,
    }));
  };*/

  const targetLocationChangeHandler = (data) => {
    
    var locationSigungu = data.sigungu.split(" ");

    if (locationSigungu.length > 1) {
      var loGu =
        locationSigungu[1].length > 2
          ? locationSigungu[1].slice(0, -1)
          : locationSigungu[1];
      var loSi =
        locationSigungu[0].length > 2
          ? locationSigungu[0].slice(0, -1)
          : locationSigungu[0];
      var loDong = data.bname.length > 2 ? data.bname.slice(0, -1) : data.bname;
    } else {
      var loGu =
        data.sigungu.length > 2 ? data.sigungu.slice(0, -1) : data.sigungu;
      var loSi = data.sido;
      var loDong = data.bname.length > 2 ? data.bname.slice(0, -1) : data.bname;
    }

      setLoList({...loList,       
        location: data.roadAddress,
        location_dong: loDong,
        location_gu: loGu,
        location_si: loSi,
        lesson_name : name
      })
    
  };

  const ChangeHandler = (value) => {
    setLoList({...loList,
      location_additional: value,
      lesson_name : name
    })
  }

  const mapService = new MapService(targetLocationChangeHandler);

  const searchAddressHandler = () => {
    mapService.open();
  };

  const addAddressHandler = () => {
    addAddressListHandler({
      id: new Date(),
      main: addressValue.main,
      detail: addressValue.detail,
    });

    setAddressValue({
      main: "",
      detail: "",
    });
  };

  return (
    <ModalLayout
      title="장소를 입력해 주세요."
      closeHandler={closeHandler}
      buttonText="추가하기"
      clickHandler={loAdd}
    >
      <LocationBox
        type="modal"
        addHandler={addAddressHandler}
        deleteHandler={deleteAddressHandler}
        searchAddressHandler={searchAddressHandler}
        data={loList}
        changeHandler={(e) => ChangeHandler(e.target.value)}
        addressList={addressList}
      />
    </ModalLayout>
  );
};

export default LocationModal;
