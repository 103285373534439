import styled from "styled-components";
import flexbox from "../../../styles/func/flexbox";

export const Main = styled.main`
  min-height: calc(140px * 5 + 20px);
  padding: 20px;
`;

export const Footer = styled.footer`
  width: 100%;
  position: absolute;
  bottom: 0px;
  height: 90px;
  ${flexbox()}
  padding: 0 20px;
`;
