import MainHeader from "../../../components/header";
import InquiryCard from "../../../components/inquiry/card";
import inquiryList from "../../../data/inquiry";
import usePagination from "../../../hooks/usePagination";
import { useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../../layouts/mainLayout";
import Input from "../../../modules/input";
import Select from "../../../modules/select";
import { FilterBox, Footer, Main, SearchIcon } from "./style";
import searchIcon from "../../../assets/icon/search.svg";
import useInput from "../../../hooks/useInput";
import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

//import Pagination from "../../../components/pagination";
import flexbox from "../../../styles/func/flexbox";
import typography from "../../../styles/constants/typography";
import colors from "../../../styles/constants/colors";
import base from "../../../styles/constants/base";

// pagination
const Div = styled.div`
  width: 100%;
  height: 100%;
  ${flexbox("space-between")};
  font-weight: ${typography.weight.regular};
`;

const SectionButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: ${colors.white};
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};

  &:focus {
    box-shadow: ${base.boxShadow.default};
  }
`;

const List = styled.ul`
  ${flexbox()}
`;

const PageButton = styled.li`
  width: 40px;
  height: 40px;
  border: ${({ active }) => (active ? `2px solid ${colors.blue}` : null)};
  color: ${({ active }) => (active ? colors.blue : null)};
  ${flexbox()};
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};
  margin: 0 5px;
  cursor: pointer;
`;

const InquiryPage = () => {
  // TODO: /api/chat으로 전체 chat data 가져오기

  const { num, y, m, t, f } = useParams();
  const navigate = useNavigate();
  const numCheck = num > 1 || (y || m || t || f !== 'all');

  const { value, inputChangeHandler } = useInput({
    search: t !== 'all' ? t : "" ,
    filter: f !== 'all' ? f : "all" ,
    month: m !== 'all' ? m : "" ,
    year : y !== 'all' ? y : "" ,
  });

  const inquiryHistoryList = inquiryList;

  const { offset, list, limit, setLimit, currentPage, setCurrentPage } =
    usePagination(inquiryHistoryList, 6);

    const [question, setQuestion] = useState("");
    const [questionCount, setQuestionCount] = useState(0);
    const [updateStatus, setUpdateStatus] = useState(false);
    const [page, setPage] = useState(num);
  
        // 문의 리스트 로드
        useEffect(() => {
      
          var token = window.localStorage.getItem('token')
    
          const frm = new FormData();
          frm.append("mode", (updateStatus || numCheck ? "question_update" : "list"));
          frm.append("token", token);
          frm.append("page", updateStatus ? 1 : num);
          frm.append("year", (updateStatus || numCheck ? value.year : "all"));
          frm.append("month", (updateStatus || numCheck ? value.month : "all"));
          frm.append("text", (updateStatus || numCheck ? value.search : 'all'));
          frm.append("type", (updateStatus || numCheck ? value.filter : 'all'));
    
            axios.post("https://ai.semos.kr/semos_admin/question/data", frm, {
              headers: { "Content-Type": "multipart/form-data" },
            }).then((data) => {
              var Value = data.data;
              var status = Value.status
              var statusMessage = Value.status_message
    
              if(status == 'success') {
    
                setQuestion(Value.question_list)
                setQuestionCount(Value.question_count)
                setUpdateStatus(true)

                if(updateStatus) {
                    setPage(1)
                    navigate(`/inquiry_list/${1}/${value.year ? value.year : "all" }/${value.month ? value.month : "all" }/${value.search ? value.search : "all" }/${value.filter}`,  { replace: true})
                  } else {
                    setPage(num)
                  }

              } else if(status == 'waring') {
                alert(statusMessage)
              }
    
            });
    
        }, [value.year, value.month, value.filter]);


  const prevHandler = () => {
    if (page > 1) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "question_update");
      frm.append("token", token);
      frm.append("page", page - 1);
      frm.append("year", value.year);
      frm.append("month", value.month);
      frm.append("text", value.search);
      frm.append("type", value.filter);

        axios.post("https://ai.semos.kr/semos_admin/question/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setQuestion(Value.question_list)
            setQuestionCount(Value.question_count)
            setPage(page - 1)
            navigate(`/inquiry_list/${page - 1}/${value.year ? value.year : "all" }/${value.month ? value.month : "all" }/${value.search ? value.search : "all" }/${value.filter}`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    } else {
      alert('첫 페이지입니다.');
    }
  };

  const nextHandler = () => {
    if (page < numPages) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "question_update");
      frm.append("token", token);
      frm.append("page", page + 1);
      frm.append("year", value.year);
      frm.append("month", value.month);
      frm.append("text", value.search);
      frm.append("type", value.filter);

        axios.post("https://ai.semos.kr/semos_admin/question/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setQuestion(Value.question_list)
            setQuestionCount(Value.question_count)
            setPage(page + 1)
            navigate(`/inquiry_list/${page + 1}/${value.year ? value.year : "all" }/${value.month ? value.month : "all" }/${value.search ? value.search : "all" }/${value.filter}`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    } else {
      alert('마지막 페이지입니다.');
    }
  };

  const PageChange = (number) => {
    if ( number < (numPages + 1) && number > 0 ) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "question_update");
      frm.append("token", token);
      frm.append("page", number);
      frm.append("year", value.year);
      frm.append("month", value.month);
      frm.append("text", value.search);
      frm.append("type", value.filter);

        axios.post("https://ai.semos.kr/semos_admin/question/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setQuestion(Value.question_list)
            setQuestionCount(Value.question_count)
            setPage(number)
            navigate(`/inquiry_list/${number}/${value.year ? value.year : "all" }/${value.month ? value.month : "all" }/${value.search ? value.search : "all" }/${value.filter}`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    }
  };

  const TextSearch = (keyValue) => {

    if(keyValue === 'Enter') {
        var token = window.localStorage.getItem('token')

        const frm = new FormData();
        frm.append("mode", "question_update");
        frm.append("token", token);
        frm.append("page", 1);
        frm.append("year", value.year);
        frm.append("month", value.month);
        frm.append("text", value.search);
        frm.append("type", value.filter);

        axios.post("https://ai.semos.kr/semos_admin/question/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setQuestion(Value.question_list)
            setQuestionCount(Value.question_count)
            setPage(1)
            navigate(`/inquiry_list/${1}/${value.year ? value.year : "all" }/${value.month ? value.month : "all" }/${value.search ? value.search : "all" }/${value.filter}`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    }
  
  };



  const numPages = Math.ceil(questionCount / 6);
  let PageList = [];
  
  if(page < 6) {
    //PageList = [1,2,3,4,5,6,7,8,9,10];
    for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
      PageList.push(i+1);
    }

  } else {
    if( (numPages - 5) < page ) {
      var startPage = numPages - 9;
      if(startPage < 1) {
        startPage = 1;
      }

      for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
        PageList.push(startPage + i);
      }

    } else {
      var startPage = page - 4;
      if(startPage < 1) {
        startPage = 1;
      }
      
      for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
        PageList.push(startPage + i);
      }
    }
  }

  return (
    <MainLayout heightType={true}>
      <MainHeader title="문의 내역">
        <FilterBox>
          <div>
            <Input
              width="400px"
              type="text"
              name="search"
              value={value.search}
              changeHandler={inputChangeHandler}
              placeholder="이용자 명, 파트너 명으로 검색"
              keyPressHandler={TextSearch}
              padding={true}
              margin={true}
            >
              <SearchIcon src={searchIcon} />
            </Input>

            <Select
              border={false}
              width="100px"
              isTransparent={true}
              value={value.filter}
              name="filter"
              changeHandler={inputChangeHandler}
              optionList={[
                {
                  text: "전체",
                  value: "all",
                },
                {
                  text: "파트너",
                  value: "partner",
                },
                {
                  text: "서비스",
                  value: "service",
                },
              ]}
            />
          </div>

          <Select
              type="calendar"
              name="year"
              border={false}
              width="150px"
              isTransparent={true}
              value={value.year}
              changeHandler={inputChangeHandler}
              optionList={[
                {
                    text: "전체(년)",
                    value: "",
                },
                {
                    text: "2021년",
                    value: "2021",
                },
                {
                    text: "2022년",
                    value: "2022",
                },
                {
                    text: "2023년",
                    value: "2023",
                },
                {
                  text: "2024년",
                  value: "2024",
                },
                {
                  text: "2025년",
                  value: "2025",
                },
              ]}
            />

            <Select
              type="calendar"
              name="month"
              border={false}
              width="150px"
              isTransparent={true}
              value={value.month}
              changeHandler={inputChangeHandler}
              optionList={[
                {
                    text: "전체(월)",
                    value: "",
                },
                {
                    text: "01월",
                    value: "01",
                },
                {
                    text: "02월",
                    value: "02",
                },
                {
                    text: "03월",
                    value: "03",
                },
                {
                    text: "04월",
                    value: "04",
                },
                {
                    text: "05월",
                    value: "05",
                },
                {
                    text: "06월",
                    value: "06",
                },
                {
                    text: "07월",
                    value: "07",
                },
                {
                    text: "08월",
                    value: "08",
                },
                {
                    text: "09월",
                    value: "09",
                },
                {
                    text: "10월",
                    value: "10",
                },
                {
                    text: "11월",
                    value: "11",
                },
                {
                    text: "12월",
                    value: "12",
                },
              ]}
            />
        </FilterBox>
      </MainHeader>

      <Main>
        <ul>
          {question ? question.map((item, idx) => (
            <InquiryCard key={idx} data={item} idx={idx} />
          )) : ""}
        </ul>
      </Main>

      <Footer>
        {/*<Pagination
          total={inquiryHistoryList.length}
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />*/}
          <Div>
            <SectionButton onClick={prevHandler}>&lt;</SectionButton>

            <List>
              {PageList.length ? PageList.map((item, idx) => 
                ( <PageButton
                    key={idx}
                    onClick={() => PageChange(item)}
                    aria-current={page == item ? "page" : null}
                    active={page == item ? true : false}
                  >
                    {item}
                  </PageButton>
                )) : ""}
            </List>

            <SectionButton onClick={nextHandler}>&gt;</SectionButton>
          </Div>
      </Footer>
    </MainLayout>
  );
};

export default InquiryPage;
