import styled from "styled-components";
import Input from "../../modules/input";
import Select from "../../modules/select";
import Textarea from "../../modules/textarea";
import colors from "../../styles/constants/colors";
import flexbox from "../../styles/func/flexbox";
import deleteIcon from "../../assets/icon/circle-close.svg";
import { useEffect, useState } from "react";

const Card = styled.div`
  margin: 10px 0 20px 0;
  display: flex;
`;

const Wrapper = styled.div`
  margin-left: 20px;
`;

const Left = styled.div`
  width: 100%;
`;

const Right = styled.div`
  margin-left: 20px;
`;

const QuestionBox = styled.div`
  ${flexbox("flex-start")}
  margin-bottom: 20px;

  p {
    margin-right: 20px;
  }
`;

const AnswerBox = styled.div`
  display: flex;

  p {
    margin-right: 20px;
  }

  textarea {
    background-color: ${colors.white};
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  }
`;

const RegisterCard = ({
  type = "base",
  value,
  changeHandler,
  deleteHandler,
  resetHandler,
}) => {
  // const [categoryValue, setCategoryValue] = useState(value.category);

  // console.log(categoryValue);

  // useEffect(() => {
  //   setCategoryValue(value.category);
  // }, [value.category]);

  console.log(value.category);

  if (type === "base") {
    return (
      <Card>
        <Left>
          <QuestionBox>
            <p>Q.</p>

            <Input
              type="text"
              name="question"
              placeholder="질문을 작성해 주세요.(최대 20자)"
              changeHandler={changeHandler}
              value={value.question}
            />

            <Wrapper>
              <Select
                name="category"
                placeholder="카테고리"
                optionList={[
                  {
                    text: "이용 안내",
                    value: "use",
                  },
                  {
                    text: "회원 정보",
                    value: "member",
                  },
                  {
                    text: "결제/환불",
                    value: "payment",
                  },
                ]}
                changeHandler={changeHandler}
                value={value.category}
                width="160px"
              />
            </Wrapper>
          </QuestionBox>

          <AnswerBox>
            <p>A.</p>

            <Textarea
              name="answer"
              placeholder="답변을 작성해 주세요."
              changeHandler={changeHandler}
              value={value.answer}
            />
          </AnswerBox>
        </Left>

        <Right>
          <button type="button" onClick={resetHandler}>
            <img src={deleteIcon} />
          </button>
        </Right>
      </Card>
    );
  }

  if (type === "edit") {
    return (
      <Card>
        <Left>
          <QuestionBox>
            <p>Q.</p>

            <Input
              type="text"
              name="question"
              placeholder="질문을 작성해 주세요.(최대 20자)"
              changeHandler={changeHandler}
              value={value.question}
            />

            <Wrapper>
              <Select
                name="category"
                placeholder="카테고리"
                optionList={[
                  {
                    text: "이용 안내",
                    value: "use",
                  },
                  {
                    text: "회원 정보",
                    value: "member",
                  },
                  {
                    text: "결제/환불",
                    value: "payment",
                  },
                ]}
                changeHandler={changeHandler}
                value={value.category}
                width="160px"
              />
            </Wrapper>
          </QuestionBox>

          <AnswerBox>
            <p>A.</p>

            <Textarea
              name="answer"
              placeholder="답변을 작성해 주세요."
              changeHandler={changeHandler}
              value={value.answer}
            />
          </AnswerBox>
        </Left>

        <Right>
          <button type="button" onClick={deleteHandler}>
            <img src={deleteIcon} />
          </button>
        </Right>
      </Card>
    );
  }
};

export default RegisterCard;
