import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import {
  Wrapper,
  WrapperColmun,
  Text,
  Icon,
  AbsoluteWrapper,
  Label,
  TextArea,
} from "../../layouts/layoutStorage";

import DesignedMainHeader from "../../components/designedHeader";

import CameraAdd from "../../assets/icon/camerAdd.png";

import colors from "../../styles/constants/colors";

const BulletinDetail = () => {
  const { n } = useParams();
  const [info, setInfo] = useState("");

  const getData = () => {
    const token = localStorage.getItem("token");
    const frm = new FormData();

    frm.append("mode", "detail");
    frm.append("id", n);
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_admin/event/bulletin", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        setInfo(data.data.data);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const selectPhoto = (file, key) => {
    let reader = new FileReader();
    reader.onload = function () {
      setInfo((prev) => ({ ...prev, [key]: reader.result }));
    };

    reader.readAsDataURL(file);
  };

  const saveData = () => {
    const token = localStorage.getItem("token");
    const frm = new FormData();

    frm.append("token", token);
    frm.append("mode", "edit");
    frm.append("id", n);
    frm.append("title", info.title);
    frm.append("memo", info.memo);
    frm.append("thumbnail", info.thumbnail);
    frm.append("image", info.image);

    axios
      .post("https://ai.semos.kr/semos_admin/event/bulletin", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        // console.log(data.data);
        // if (data.data.status == "success") {
        window.alert("등록이 완료되었습니다.");
        window.location.reload();
        // }
        // setInfo(data.data.data);
      });
  };

  console.log(info);

  return (
    <Wrapper
      bgc={colors.Gr01}
      of={`hidden auto`}
      height="100%"
      display={`inline-block`}
      width={`100%`}
      mh={`calc(100vh - 48px)`}
    >
      <Container>
        <DesignedMainHeader title="게시글 수정" />

        <WrapperColmun width="718px" height="100%">
          <WrapperColmun width="100%">
            <Text className="f16" weight="600" color={colors.Gr05}>
              배너 명
            </Text>
            <Wrapper height="8px" />
            <Wrapper
              bgc={colors.White}
              border={`1px solid ${colors.Gr02}`}
              radius="8px"
            >
              <Input
                maxLength="20"
                placeholder="20자 제한입니다."
                onChange={(e) =>
                  setInfo((prev) => ({ ...prev, title: e.target.value }))
                }
                value={info.title}
              />
            </Wrapper>

            <Wrapper height="8px" />
          </WrapperColmun>

          <Wrapper height="18px" />

          <Wrapper width="100%" height="60%" gap="8px" ai="flex-start">
            <Wrapper
              width="50%"
              border={`1px solid ${colors.Gr02}`}
              height="100%"
              bgc={colors.White}
              radius="8px"
              of="scroll"
            >
              <Label
                htmlFor="image"
                width="100%"
                height="100%"
                cursor="pointer"
              >
                {info.image ? (
                  <Icon
                    src={info.image}
                    width="100%"
                    height="100%"
                    radius="8px"
                  />
                ) : (
                  <WrapperColmun
                    width="100%"
                    height="100%"
                    jc="center"
                    ai="center"
                  >
                    <Icon src={CameraAdd} width="40px" height="40px" />
                    <Wrapper height="8px" />
                    <Text className="f12" weight="500" color={colors.Gr03}>
                      375*327이상
                    </Text>
                    <Text className="f12" weight="500" color={colors.Gr03}>
                      본문 이미지 등록하기
                    </Text>
                  </WrapperColmun>
                )}
                <input
                  type="file"
                  id="image"
                  accept="image/*"
                  onChange={({ target: { files } }) =>
                    selectPhoto(files[0], "image")
                  }
                />
              </Label>
            </Wrapper>

            <WrapperColmun width="50%" height="100%" style={{ gap: "8px" }}>
              <Wrapper
                width="100%"
                border={`1px solid ${colors.Gr02}`}
                height="50%"
                bgc={colors.White}
                radius="8px"
              >
                <Label
                  htmlFor="thumbnail"
                  width="100%"
                  height="100%"
                  cursor="pointer"
                >
                  {info.thumbnail ? (
                    <Icon
                      src={info.thumbnail}
                      width="100%"
                      height="100%"
                      radius="8px"
                    />
                  ) : (
                    <WrapperColmun
                      width="100%"
                      height="100%"
                      jc="center"
                      ai="center"
                    >
                      <Icon src={CameraAdd} width="40px" height="40px" />
                      <Wrapper height="8px" />
                      <Text className="f12" weight="500" color={colors.Gr03}>
                        264*244
                      </Text>
                      <Text className="f12" weight="500" color={colors.Gr03}>
                        썸네일 등록하기
                      </Text>
                    </WrapperColmun>
                  )}
                  <input
                    type="file"
                    id="thumbnail"
                    accept="image/*"
                    onChange={({ target: { files } }) =>
                      selectPhoto(files[0], "thumbnail")
                    }
                  />
                </Label>
              </Wrapper>

              <WrapperColmun width="100%" height="50%">
                <Wrapper margin="0 0 8px 0">
                  <Text className="f16" weight="600" color={colors.Gr05}>
                    메모
                  </Text>
                </Wrapper>

                <Wrapper
                  width="100%"
                  radius="8px"
                  height="100%"
                  padding="8px"
                  bgc={colors.White}
                  border={`1px solid ${colors.Gr02}`}
                >
                  <TextArea
                    placeholder="메모를 입력해주세요. 최대 1000자"
                    width="100%"
                    height="100%"
                    onChange={(e) =>
                      setInfo((prev) => ({ ...prev, memo: e.target.value }))
                    }
                    value={info.memo}
                  />
                </Wrapper>
              </WrapperColmun>
            </WrapperColmun>
          </Wrapper>

          <Wrapper width="100%" jc="flex-end" margin="24px 0 0 0">
            <Button
              active={info.image && info.title && info.memo && info.thumbnail}
              onClick={() =>
                info.image &&
                info.title &&
                info.memo &&
                info.thumbnail &&
                saveData()
              }
            >
              <Text
                className="f16"
                weight="600"
                color={
                  info.image && info.title && info.memo && info.thumbnail
                    ? colors.White
                    : colors.Gr03
                }
              >
                등록하기
              </Text>
            </Button>
          </Wrapper>
        </WrapperColmun>
      </Container>
    </Wrapper>
  );
};

export default BulletinDetail;

const Container = styled.div`
  padding: 28px 36px 0 36px;
  width: 100%;
  /* height: calc(100vh - 217px); */
  height: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 11px 16px;
`;

const Button = styled.div`
  width: 160px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ active }) => (active ? colors.Bl07 : colors.Gr01)};
  cursor: pointer;
  border-radius: 8px;
`;
