import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import MainHeader from "../../../components/header";
import LessonCard from "../../../components/partner/lesson/card";
import LocationCard from "../../../components/partner/lesson/locationCard";
import lessonListData from "../../../data/partner/lesson";
import useList from "../../../hooks/useList";
import usePagination from "../../../hooks/usePagination";
import MainLayout from "../../../layouts/mainLayout";
import colors from "../../../styles/constants/colors";

//import Pagination from "../../../components/pagination";
import flexbox from "../../../styles/func/flexbox";
import typography from "../../../styles/constants/typography";
import base from "../../../styles/constants/base";

const Main = styled.main`
  padding: 20px 20px 80px 20px;
  height: 750px;
  overflow-y: scroll;

  ul {
    padding-bottom: 80px;
  }
`;

const Footer = styled.footer`
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 20px;
  background-color: ${colors.white};
`;

// pagination
const Div = styled.div`
  width: 100%;
  height: 100%;
  ${flexbox("space-between")};
  font-weight: ${typography.weight.regular};
`;

const SectionButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: ${colors.white};
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};

  &:focus {
    box-shadow: ${base.boxShadow.default};
  }
`;

const List = styled.ul`
  ${flexbox()}
`;

const PageButton = styled.li`
  width: 40px;
  height: 40px;
  border: ${({ active }) => (active ? `2px solid ${colors.blue}` : null)};
  color: ${({ active }) => (active ? colors.blue : null)};
  ${flexbox()};
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};
  margin: 0 5px;
  cursor: pointer;
`;


const PartnerLessonPage = () => {
  const navigate = useNavigate();
  const { pid, num } = useParams();
  const numCheck = num > 1;

  const goToLessonRegisterHandler = () => {
    navigate(`/partner/${pid}/lesson/new`);
  };

  const { list: lessonList, editHandler } = useList(lessonListData);

  const { offset, limit, currentPage, setCurrentPage } = usePagination(
    lessonList,
    2
  );

  const [lesson, setLesson] = useState([]);
  const [lessonNameList, setLessonNameList] = useState([]);
  const [lessonCount, setLessonCount] = useState(0);
  const [page, setPage] = useState(num);
  const [partnerName, setPartnerName] = useState([]);

   // 문의 상세 로드
   useEffect(() => {

    var token = window.localStorage.getItem('token')

    const frm = new FormData(); 
    frm.append("mode", numCheck ? 'location_list_update' : 'location_list');
    frm.append("token", token);
    frm.append("number", pid);
    frm.append("page", num);

      axios.post("https://ai.semos.kr/semos_admin/partner/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then((data) => {
        var Value = data.data;
        var status = Value.status
        var statusMessage = Value.status_message

        if(status == 'success') {
          setLesson(Value.lesson_list)
          setLessonNameList(Value.lesson_name_list)
          setLessonCount(Value.lesson_count)
          setPartnerName(Value.partner_name)
          navigate(`/partner/${pid}/lesson_list/${num}`)

        } else if(status == 'waring') {
          alert(statusMessage)
        }

      });

  }, []);


  const prevHandler = () => {
    if (page > 1) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", 'location_list_update');
      frm.append("token", token);
      frm.append("number", pid);
      frm.append("page", page - 1);

        axios.post("https://ai.semos.kr/semos_admin/partner/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setLesson(Value.lesson_list)
            setLessonNameList(Value.lesson_name_list)
            setLessonCount(Value.lesson_count)
            setPage(page - 1)
            navigate(`/partner/${pid}/lesson_list/${page - 1}`)
          } else if(status == 'waring') {
            alert(statusMessage)
          }
        });
    } else {
      alert('첫 페이지입니다.');
    }
  };

  const nextHandler = () => {
    if (page < numPages) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", 'location_list_update');
      frm.append("token", token);
      frm.append("number", pid);
      frm.append("page", page + 1);

        axios.post("https://ai.semos.kr/semos_admin/partner/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setLesson(Value.lesson_list)
            setLessonNameList(Value.lesson_name_list)
            setLessonCount(Value.lesson_count)
            setPage(page + 1)
            navigate(`/partner/${pid}/lesson_list/${page + 1}`)
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    } else {
      alert('마지막 페이지입니다.');
    }
  };

  const PageChange = (number) => {
    if ( number < (numPages + 1) && number > 0 ) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", 'location_list_update');
      frm.append("token", token);
      frm.append("number", pid);
      frm.append("page", number);

        axios.post("https://ai.semos.kr/semos_admin/partner/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setLesson(Value.lesson_list)
            setLessonNameList(Value.lesson_name_list)
            setLessonCount(Value.lesson_count)
            setPage(number)
            navigate(`/partner/${pid}/lesson_list/${number}`)
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    }
  };

  const locationAdd = (data) => {
    if(window.confirm('액티비티 장소를 추가하시겠습니까?')) {
      var token = window.localStorage.getItem('token')


        axios.post("https://ai.semos.kr/semos_admin/partner/data", {    
          mode: "location_add",
          token: token,
          number: pid,
          location_data: data,
        },
        { headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            alert(statusMessage)
            window.location.reload();
          } else if(status == 'waring') {
            alert(statusMessage)
          }
        });
    }
  }


  const numPages = Math.ceil(lessonCount / 3);
  let PageList = [];
  
  if(page < 6) {
    //PageList = [1,2,3,4,5,6,7,8,9,10];
    for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
      PageList.push(i+1);
    }

  } else {
    if( (numPages - 5) < page ) {
      var startPage = numPages - 9;
      if(startPage < 1) {
        startPage = 1;
      }

      for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
        PageList.push(startPage + i);
      }

    } else {
      var startPage = page - 4;
      if(startPage < 1) {
        startPage = 1;
      }
      
      for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
        PageList.push(startPage + i);
      }
    }
  }

  return (
    <MainLayout button={{ type: "plus", clickHandler: goToLessonRegisterHandler }}>
      <MainHeader title={partnerName} />

      <Main>
        <ul>
          {lesson ? Object.entries(lesson).map((item, idx) => (
            <LocationCard addHandler={locationAdd} key={idx} pid={pid} name={item[0]} data={item[1]} />
          )) : ""}
        </ul>
      </Main>

      <Footer>
      {/*<Pagination
          total={inquiryHistoryList.length}
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />*/}
          <Div>
            <SectionButton onClick={prevHandler}>&lt;</SectionButton>

            <List>
              {PageList.length ? PageList.map((item, idx) => 
                ( <PageButton
                    key={idx}
                    onClick={() => PageChange(item)}
                    aria-current={page == item ? "page" : null}
                    active={page == item ? true : false}
                  >
                    {item}
                  </PageButton>
                )) : ""}
            </List>

            <SectionButton onClick={nextHandler}>&gt;</SectionButton>
          </Div>
      </Footer>
    </MainLayout>
  );
};

export default PartnerLessonPage;
