import styled from "styled-components";
import base from "../styles/constants/base";
import colors from "../styles/constants/colors";
import alignCenter from "../styles/func/alignCenter";
import flexbox from "../styles/func/flexbox";
import plusIcon from "../assets/icon/plus.svg";
import schedulePlusIcon from "../assets/icon/schdule_plus.svg";
import inquiryIcon from "../assets/icon/inquiry-notice-w.svg";

const BUTTON_SIZE = 76;

const Button = styled.button`
  position: absolute;
  width: ${BUTTON_SIZE}px;
  height: ${BUTTON_SIZE}px;
  background-color: ${colors.blue};
  color: ${colors.white};
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};
  ${flexbox()};
  bottom: 0;
  right: -100px;
`;

const ScheduleIcon = styled.img`
  width : 30px;
  display : inline-block;
`;

const InquiryIcon = styled.img`
  transform: translateX(10%);
`;

const FixedButton = ({ type, clickHandler }) => {
  if (type === "plus") {
    return (
      <Button type="button" onClick={clickHandler}>
        <img src={plusIcon} />
      </Button>
    );
  }

  if (type === "alarm") {
    return (
      <Button type="button" onClick={clickHandler}>
        <InquiryIcon src={inquiryIcon} type={type} />
      </Button>
    );
  };

  if (type === "schedule") {
    return (
      <Button type="button" onClick={clickHandler}>
        <ScheduleIcon src={schedulePlusIcon} />
      </Button>
    );
  }
};

export default FixedButton;
