import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

// components
import OverlayPortal from "../../../../overlayPortal";
import Overlay from "../../../../layouts/overlay";
import ModalLayout from "../../../../layouts/modal/layout";
import ImageInput from "../../../../components/imageInput";
import FAQCard from "../../../../components/lesson/faqCard";
import LocationBox from "../../../../components/lesson/locationBox";
import useImage from "../../../../hooks/useImage";
import useInput from "../../../../hooks/useInput";
import useList from "../../../../hooks/useList";
import MainLayout from "../../../../layouts/mainLayout";
import Button from "../../../../modules/button";
import Input from "../../../../modules/input";
import Select from "../../../../modules/select";
import SelectImg from "../../../../modules/select_img";
import Tag from "../../../../modules/tag";
import Textarea from "../../../../modules/textarea";
import MapService from "../../../../service/map";
import colors from "../../../../styles/constants/colors";
// import CopyPopup from "../../../../components/partner/lesson/copyPopup";
import CropperPopup from "../../../../components/cropperPopup";
import LoadingSpinner from "../../../../components/loadingSpinner";

// icon
//import bangIcon from "../../../../assets/icon/bang.svg";
import infoPin from "../../../../assets/icon/info_pin.svg";
import infoUnique from "../../../../assets/icon/nuique_list.svg";
import infoCheck from "../../../../assets/icon/info_check.svg";
import infoNotice from "../../../../assets/icon/info_notice.svg";
import infoRequire from "../../../../assets/icon/info_require.svg";
import deleteIcon from "../../../../assets/icon/close.svg";
import scuba from "../../../../assets/icon/scuba.png";
import freeDiv from "../../../../assets/icon/freediv.png";
import paddle from "../../../../assets/icon/paddle.png";
import swim from "../../../../assets/icon/swim.png";
import surf from "../../../../assets/icon/surf.png";
import ski from "../../../../assets/icon/ski_logo.png";
import snowboard from "../../../../assets/icon/snowboard_logo.png";
import cameraWhiteIcon from "../../../../assets/icon/camera-w.svg";
import closeIcon from "../../../../assets/icon/close.svg";
import semosOnly from "../../../../assets/icon/semos_logo.png";
import fullPackage from "../../../../assets/icon/full_package.svg";

/*import CategoryBox from "../../../../components/lesson/categoryBox";
import RecommendationBox from "../../../../components/lesson/recommendationBox";
import ScheduleBox from "../../../../components/lesson/scheduleBox";*/

import {
  ButtonBox,
  Footer,
  Main,
  Row,
  Title,
  UseInfoRow,
  StatusDiv,
  CategoryDiv,
  RowTop,
  FAQRow,
  FAQTop,
  DiscountBox,
  DiscountWrapper,
  DiscountContainer,
  OptionBox,
  OptionWrapper,
  LikeDiv,
  Wrapper,
  Wrapper2,
  ModalText,
  CurriculumContent,
  CurriculumContainer,
  ListContainer,
} from "./style";

import ToggleButton from "../../../../modules/toggleButton";
import FAQItem from "../../../../components/lesson/faqItem";

const PartnerLessonEditPage = () => {
  const { pid, lid } = useParams();

  const navigate = useNavigate();

  const [modal, setModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const saveAndWriteUsageInfoHandler = () => {
    setLoading(true);
    if (window.confirm("액티비티를 저장하시겠습니까?")) {
      var token = window.localStorage.getItem("token");

      axios
        .post(
          "https://ai.semos.kr/semos_admin/partner/data",
          {
            mode: "lesson_revise",
            token: token,
            partner_number: pid,
            service_number: lid,
            lesson_data: lesson,
            delete_data: deleteList,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          console.log(Value);

          if (status == "success") {
            alert(statusMessage);
            setLoading(false);
            window.location.reload();
          } else if (status == "waring") {
            setLoading(false);
            alert(statusMessage);
            window.location.reload();
          }
        });
    }
  };

  // 데이터 패칭

  const [imgPopup, setImgPopup] = useState(false);

  const [cropType, setCropType] = useState("");

  const [imgScale, setImgScale] = useState({
    width: 1,
    height: 1,
  });

  const [cropImg, setCropImg] = useState("");

  // Tag
  const {
    value: tagValue,
    setValue: setTagValue,
    inputChangeHandler: tagInputChangeHandler,
  } = useInput({
    exposure: "",
    keyword: "",
    search: "",
    materials: "",
    contain: "",
    except: "",
    recommend: "",
    notice: "",
    notice2: "",
    unique: "",
    subject: "",
    content: "",
  });

  // FAQ
  const {
    value: faqValue,
    setValue: setFaqValue,
    inputChangeHandler: faqInputChangeHandler,
  } = useInput({
    question: "",
    answer: "",
  });

  const {
    list: faqList,
    addHandler: faqListAddHandler,
    deleteHandler: faqDeleteHandler,
  } = useList([]);

  const faqAddHandler = () => {
    faqListAddHandler({
      id: new Date(),
      question: faqValue.question,
      answer: faqValue.answer,
    });

    setFaqValue({
      question: "",
      answer: "",
    });
  };

  const faqResetHandler = () => {
    setFaqValue({
      question: "",
      answer: "",
    });
  };

  //  Location

  const {
    list: addressList,
    addHandler: addAddressListHandler,
    deleteHandler: deleteAddressHandler,
  } = useList([]);

  const targetLocationChangeHandler = (data) => {
    var locationData = lesson.location;

    var locationSigungu = data.sigungu.split(" ");

    if (locationSigungu.length > 1) {
      var loGu =
        locationSigungu[1].length > 2
          ? locationSigungu[1].slice(0, -1)
          : locationSigungu[1];
      var loSi =
        locationSigungu[0].length > 2
          ? locationSigungu[0].slice(0, -1)
          : locationSigungu[0];
      var loDong = data.bname.length > 2 ? data.bname.slice(0, -1) : data.bname;
    } else {
      var loGu =
        data.sigungu.length > 2 ? data.sigungu.slice(0, -1) : data.sigungu;
      var loSi = data.sido;
      var loDong = data.bname.length > 2 ? data.bname.slice(0, -1) : data.bname;
    }

    locationData = {
      location: data.roadAddress,
      location_additional: "",
      location_dong: loDong,
      location_gu: loGu,
      location_si: loSi,
    };

    setLesson({ ...lesson, location: locationData });
  };

  const additionalLocation = (value) => {
    var locationData = lesson.location;

    locationData = {
      location: locationData.location,
      location_additional: value,
      location_dong: locationData.location_dong,
      location_gu: locationData.location_gu,
      location_si: locationData.location_si,
    };

    setLesson({ ...lesson, location: locationData });
  };

  const mapService = new MapService(targetLocationChangeHandler);

  const searchAddressHandler = () => {
    mapService.open();
  };

  const deleteLessonHandler = () => {
    if (window.confirm("액티비티를 삭제하시겠습니까?")) {
      var token = window.localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "lesson_delete");
      frm.append("token", token);
      frm.append("partner_number", pid);
      frm.append("service_number", lid);

      axios
        .post("https://ai.semos.kr/semos_admin/partner/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            alert(statusMessage);
            navigate(`/partner/${pid}/lesson`);
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    }
  };

  const [lesson, setLesson] = useState("");
  const [deleteList, setDeleteList] = useState({
    bring_list: [],
    discount_list: [],
    exclude_list: [],
    faq_list: [],
    include_list: [],
    tag_list: [],
    keyword_list: [],
    notice_list: [],
    notice_list2: [],
    curriculum_list: [],
    recommend_list: [],
    add_img_list: [],
    facility_list: [],
    unique_list: [],
  });

  const [discount, setDiscount] = useState({
    number: "new",
    people: "2",
    price: "",
    change: "yes",
  });

  const [activitySport, setActivitySport] = useState([]);

  // 액티비티 상세 로드
  useEffect(() => {
    if (lid == "new") {
      setLesson({
        bring_list: [],
        discount_list: [],
        exclude_list: [],
        faq_list: [],
        include_list: [],
        curriculum_list: [],
        notice_list: [],
        notice_list2: [],
        recommend_list: [],
        facility_list: [],
        unique_list: [],
        confrim_rating: "",
        information: "",
        information_additional: "",
        information_img: "",
        jjim: "0",
        location: {
          location: "",
          location_additional: "",
          location_dong: "",
          location_gu: "",
          location_si: "",
        },
        lesson_type: "",
        duration: "",
        discount_percent: "",
        discount_people: "",
        max_people: "1",
        min_people: "1",
        min_age: "",
        name: "",
        number: "",
        open: "yes",
        price: "0",
        profile_img: "",
        profile_img_small: "",
        tag_list: [],
        keyword_list: [],
        add_img_list: [],
      });
    } else {
      var token = window.localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "lesson_specific");
      frm.append("token", token);
      frm.append("partner_number", pid);
      frm.append("service_number", lid);

      axios
        .post("https://ai.semos.kr/semos_admin/partner/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            setLesson(Value.lesson_data);
            setActivitySport(Value.lesson_data.activity_sport);
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    }
  }, []);

  const ValueChange = (type, name, value) => {
    var lessonData = lesson;

    if (
      type == "list" &&
      (name == "bring_list" ||
        name == "exclude_list" ||
        name == "include_list" ||
        name == "curriculum_list" ||
        name == "notice_list" ||
        name == "notice_list2" ||
        name == "recommend_list" ||
        name == "unique_list")
    ) {
      var LessonDataValue = lessonData[name];

      var checkValue = value.content;

      /*for (var i = 0; LessonDataValue.length > i; i++) {
        if (LessonDataValue[i].content == checkValue) {
          alert("중복되는 콘텐츠가 있습니다!");
          return false;
        }
      }*/

      LessonDataValue[LessonDataValue.length] = value;

      setLesson({ ...lesson, [name]: LessonDataValue });
    } else if (type == "list" && name == "discount_list") {
      var LessonDataValue = lessonData[name];

      var checkValue = value.people;
      var checkValue2 = value.price;
      if (!checkValue || !checkValue2 || checkValue == 0 || checkValue2 == 0) {
        alert("입력된 값이 없습니다!");
        return false;
      }

      for (var i = 0; LessonDataValue.length > i; i++) {
        if (LessonDataValue[i].people == checkValue) {
          alert("중복되는 콘텐츠가 있습니다!");
          return false;
        }
      }

      LessonDataValue[LessonDataValue.length] = value;

      setLesson({ ...lesson, [name]: LessonDataValue });
    } else if (type == "list" && name == "faq_list") {
      var LessonDataValue = lessonData[name];

      var checkValue = value.content;
      var checkValue2 = value.name;

      for (var i = 0; LessonDataValue.length > i; i++) {
        if (
          LessonDataValue[i].content == checkValue ||
          LessonDataValue.name == checkValue2
        ) {
          alert("중복되는 콘텐츠가 있습니다!");
          return false;
        }
      }

      LessonDataValue[LessonDataValue.length] = value;

      setLesson({ ...lesson, [name]: LessonDataValue });
    } else if (
      type == "list" &&
      (name == "tag_list" || name == "keyword_list")
    ) {
      var LessonDataValue = lessonData[name];

      var checkValue = value.tag;

      if (name == "keyword_list" && LessonDataValue.length > 1) {
        alert("노출 키워드는 최대 2개까지 입력이 가능합니다.");
        return false;
      }

      for (var i = 0; lessonData["tag_list"].length > i; i++) {
        if (lessonData["tag_list"][i].tag == checkValue) {
          alert("중복되는 콘텐츠가 있습니다!");
          return false;
        }
      }

      for (var i = 0; lessonData["keyword_list"].length > i; i++) {
        if (lessonData["keyword_list"][i].tag == checkValue) {
          alert("중복되는 콘텐츠가 있습니다!");
          return false;
        }
      }

      LessonDataValue[LessonDataValue.length] = value;

      setLesson({ ...lesson, [name]: LessonDataValue });
    } else if (type == "price") {
      var regex = /[^0-9]/g;

      var stateChangeValue = value.replace(regex, "");

      var stateChangeValue = stateChangeValue.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      setLesson({ ...lesson, [name]: stateChangeValue });
    } else if (name == "discount_people") {
      if (!value || value == "0") {
        setLesson({ ...lesson, [name]: value, discount_percent: 0 });
      } else {
        setLesson({ ...lesson, [name]: value });
      }
    } else {
      setLesson({ ...lesson, [name]: value });
    }
  };

  const ImgChange = (value) => {
    setImgPopup(true);
    setImgScale({
      width: 8,
      height: 5,
    });
    setCropImg(value);
    setCropType("profile_img");

    //setLesson({ ...lesson, profile_img: value });
  };

  const ImgListChange = (value) => {
    setImgPopup(true);
    setImgScale({
      width: 8,
      height: 5,
    });
    setCropImg(value);
    setCropType("add_img");

    //setLesson({ ...lesson, profile_img: value });
  };

  const DeleteImage = (number) => {
    var lessonData = lesson;

    var deleteData = deleteList;

    var LessonDataValue = lessonData.add_img_list;

    var DeleteDataList = deleteData.add_img_list;

    var DeleteDataValue = LessonDataValue[number];

    DeleteDataList[DeleteDataList.length] = DeleteDataValue;

    LessonDataValue.splice(number, 1);

    setLesson({ ...lesson, add_img_list: LessonDataValue });

    setDeleteList({ ...deleteList, add_img_list: DeleteDataList });
  };

  const setImageFromFile = ({ file, setImageUrl }) => {
    var uploadFileSize = file.size;

    var maxFileSize = 1024 * 1024 * 5;

    let reader = new FileReader();
    reader.onload = function () {
      setImageUrl({ result: reader.result });
    };

    reader.readAsDataURL(file);
  };

  const DeleteData = (type, number) => {
    var lessonData = lesson;

    var deleteData = deleteList;

    var LessonDataValue = lessonData[type];

    var DeleteDataList = deleteData[type];

    var DeleteDataValue = LessonDataValue[number];

    DeleteDataList[DeleteDataList.length] = DeleteDataValue;

    LessonDataValue.splice(number, 1);

    setLesson({ ...lesson, [type]: LessonDataValue });
    setDeleteList({ ...deleteList, [type]: DeleteDataList });
  };

  console.log(deleteList);

  // 액티비티 상세 저장
  const lessonSave = () => {
    var token = window.localStorage.getItem("token");
    setLoading(true);
    axios
      .post(
        "https://ai.semos.kr/semos_admin/partner/data",
        {
          mode: "lesson_revise",
          token: token,
          partner_number: pid,
          service_number: lid,
          lesson_data: lesson,
          delete_data: deleteList,
        },
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((data) => {
        var Value = data.data;
        var status = Value.status;
        var statusMessage = Value.status_message;

        if (status == "success") {
          setLoading(false);
          alert(statusMessage);
          navigate(`/partner/${pid}/lesson/${lid}/editor`);
        } else if (status == "waring") {
          setLoading(false);
          alert(statusMessage);
        }
      });
  };

  const lessonAdd = () => {
    if (window.confirm("액티비티를 등록하시겠습니까?")) {
      var token = window.localStorage.getItem("token");
      setLoading(true);
      console.log(pid);
      axios
        .post(
          "https://ai.semos.kr/semos_admin/partner/data",
          {
            mode: "lesson_register",
            token: token,
            partner_number: pid,
            service_number: "new",
            lesson_data: lesson,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;
          var lessonId = Value.lesson_id;

          if (status == "success") {
            setLoading(false);
            alert(statusMessage);
            window.location.replace(`/partner/${pid}/lesson/${lessonId}`);
          } else if (status == "waring") {
            setLoading(false);
            alert(statusMessage);
          } else {
            setLoading(false);
            alert(statusMessage);
          }
        });
    }
  };

  const lessonStatusChange = () => {
    if (window.confirm("액티비티 상태를 변경하시겠습니까?")) {
      var token = window.localStorage.getItem("token");
      setLoading(true);
      axios
        .post(
          "https://ai.semos.kr/semos_admin/partner/data",
          {
            mode: "lesson_status_change",
            token: token,
            partner_number: pid,
            lesson_number: lid,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            setLoading(false);
            alert(statusMessage);
            setLesson({
              ...lesson,
              open: lesson.open == "yes" ? "close" : "yes",
            });
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    }
  };

  const lessonStatusConfirm = () => {
    if (window.confirm("액티비티 승인 상태를 변경하시겠습니까?")) {
      var token = window.localStorage.getItem("token");
      setLoading(true);
      axios
        .post(
          "https://ai.semos.kr/semos_admin/partner/data",
          {
            mode: "lesson_status_confirm",
            token: token,
            partner_number: pid,
            lesson_number: lid,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            alert(statusMessage);
            setLoading(false);
            setLesson({
              ...lesson,
              open: lesson.open == "yes" ? "no" : "yes",
            });
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    }
  };

  const OptionAdd = (option) => {
    var option_check = lesson[option] === "yes";

    if (option_check) {
      setLesson({
        ...lesson,
        [option]: "no",
      });
    } else {
      setLesson({
        ...lesson,
        [option]: "yes",
      });
    }
  };

  const FacilityAdd = (Facility) => {
    let lessonData = lesson;

    let facilityAddStatus = lessonData.facility_list.indexOf(Facility) > -1;
    var facilityList = lessonData.facility_list;

    if (facilityAddStatus) {
      for (let i = 0; facilityList.length > i; i++) {
        let sameStatus = facilityList[i] === Facility;
        if (sameStatus) {
          facilityList.splice(i, 1);
        }
      }

      setLesson({
        ...lesson,
        facility_list: facilityList,
      });
    } else {
      facilityList.push(Facility);
      setLesson({
        ...lesson,
        facility_list: facilityList,
      });
    }
  };

  const SportAdd = (sport) => {
    let sportAddStatus = activitySport.indexOf(sport) > -1;
    let sportAddLengthStatus = activitySport.length > 2;
    var sportList = activitySport;

    if (sportAddStatus) {
      for (let i = 0; sportList.length > i; i++) {
        let sameStatus = sportList[i] === sport;
        if (sameStatus) {
          sportList.splice(i, 1);
        }
      }

      setActivitySport(sportList);
      setLesson({
        ...lesson,
        activity_sport: sportList,
      });
    } else {
      if (sportAddLengthStatus) {
        alert("스포츠 종목은 최대 3개까지 추가 가능합니다.");
      } else {
        sportList.push(sport);
        setActivitySport(sportList);
        setLesson({
          ...lesson,
          activity_sport: sportList,
        });
      }
    }
  };

  const CropImg = (value, type) => {
    switch (type) {
      case "profile_img":
        setLesson({ ...lesson, profile_img: value });
        setImgPopup(false);
        setImgScale({
          width: 1,
          height: 1,
        });
        setCropImg("");
        setCropType("");
        break;

      case "add_img":
        var ImageList = lesson.add_img_list;

        ImageList[ImageList.length] = {
          number: "new",
          image: value,
          change: "yes",
        };

        setLesson({ ...lesson, add_img_list: ImageList });
        setImgPopup(false);
        setImgScale({
          width: 1,
          height: 1,
        });
        setCropImg("");
        setCropType("");
        break;
    }
  };

  const dateOptionList = {
    time: [
      {
        text: "30분",
        value: "30",
      },
      {
        text: "1시간",
        value: "60",
      },
      {
        text: "1시간 30분",
        value: "90",
      },
      {
        text: "2시간",
        value: "120",
      },
      {
        text: "2시간 30분",
        value: "150",
      },
      {
        text: "3시간",
        value: "180",
      },
      {
        text: "3시간 30분",
        value: "210",
      },
      {
        text: "4시간",
        value: "240",
      },
      {
        text: "4시간 30분",
        value: "270",
      },
      {
        text: "5시간",
        value: "300",
      },
      {
        text: "5시간 30분",
        value: "330",
      },
      {
        text: "6시간",
        value: "360",
      },
      {
        text: "6시간 30분",
        value: "390",
      },
      {
        text: "7시간",
        value: "420",
      },
      {
        text: "7시간 30분",
        value: "450",
      },
      {
        text: "8시간",
        value: "480",
      },
      {
        text: "8시간 30분",
        value: "510",
      },
      {
        text: "9시간",
        value: "540",
      },
      {
        text: "9시간 30분",
        value: "570",
      },
      {
        text: "10시간",
        value: "600",
      },
    ],

    day: [
      {
        text: "1일",
        value: "1",
      },
      {
        text: "2일",
        value: "2",
      },
      {
        text: "3일",
        value: "3",
      },
      {
        text: "4일",
        value: "4",
      },
      {
        text: "5일",
        value: "5",
      },
      {
        text: "6일",
        value: "6",
      },
      {
        text: "7일",
        value: "7",
      },
      {
        text: "8일",
        value: "8",
      },
      {
        text: "9일",
        value: "9",
      },
      {
        text: "10일",
        value: "10",
      },
      {
        text: "11일",
        value: "11",
      },
      {
        text: "12일",
        value: "12",
      },
      {
        text: "13일",
        value: "13",
      },
      {
        text: "14일",
        value: "14",
      },
      {
        text: "15일",
        value: "15",
      },
      {
        text: "16일",
        value: "16",
      },
      {
        text: "17일",
        value: "17",
      },
      {
        text: "18일",
        value: "18",
      },
      {
        text: "19일",
        value: "19",
      },
      {
        text: "20일",
        value: "20",
      },
    ],

    week: [
      {
        text: "1주",
        value: "1",
      },
      {
        text: "2주",
        value: "2",
      },
      {
        text: "3주",
        value: "3",
      },
      {
        text: "4주",
        value: "4",
      },
      {
        text: "5주",
        value: "5",
      },
      {
        text: "6주",
        value: "6",
      },
      {
        text: "7주",
        value: "7",
      },
      {
        text: "8주",
        value: "8",
      },
      {
        text: "9주",
        value: "9",
      },
      {
        text: "10주",
        value: "10",
      },
      {
        text: "11주",
        value: "11",
      },
      {
        text: "12주",
        value: "12",
      },
    ],

    month: [
      {
        text: "1달",
        value: "1",
      },
      {
        text: "2달",
        value: "2",
      },
      {
        text: "3달",
        value: "3",
      },
      {
        text: "4달",
        value: "4",
      },
      {
        text: "5달",
        value: "5",
      },
      {
        text: "6달",
        value: "6",
      },
      {
        text: "7달",
        value: "7",
      },
      {
        text: "8달",
        value: "8",
      },
      {
        text: "9달",
        value: "9",
      },
      {
        text: "10달",
        value: "10",
      },
      {
        text: "11달",
        value: "11",
      },
      {
        text: "12달",
        value: "12",
      },
    ],

    year: [
      {
        text: "1년",
        value: "1",
      },
      {
        text: "2년",
        value: "2",
      },
      {
        text: "3년",
        value: "3",
      },
    ],
  };

  const PeopleOption = [
    {
      text: "미할인",
      value: "",
    },
    {
      text: "2인 이상",
      value: 2,
    },
    {
      text: "3인 이상",
      value: 3,
    },
    {
      text: "4인 이상",
      value: 4,
    },
    {
      text: "5인 이상",
      value: 5,
    },
    {
      text: "6인 이상",
      value: 6,
    },
    {
      text: "7인 이상",
      value: 7,
    },
    {
      text: "8인 이상",
      value: 8,
    },
    {
      text: "9인 이상",
      value: 9,
    },
    {
      text: "10인 이상",
      value: 10,
    },
    {
      text: "11인 이상",
      value: 11,
    },
    {
      text: "12인 이상",
      value: 12,
    },
    {
      text: "13인 이상",
      value: 13,
    },
    {
      text: "14인 이상",
      value: 14,
    },
    {
      text: "15인 이상",
      value: 15,
    },
    {
      text: "16인 이상",
      value: 16,
    },
    {
      text: "17인 이상",
      value: 17,
    },
    {
      text: "18인 이상",
      value: 18,
    },
    {
      text: "19인 이상",
      value: 19,
    },
    {
      text: "20인 이상",
      value: 20,
    },
  ];

  const ActivityType = [
    {
      text: "레슨",
      value: "레슨",
    },
    {
      text: "자격증",
      value: "자격증",
    },
    {
      text: "이용권",
      value: "이용권",
    },
    {
      text: "투어",
      value: "투어",
    },
  ];

  const ActivityDuration = [
    {
      text: "원데이",
      value: "원데이",
    },
    {
      text: "다회차",
      value: "다회차",
    },
  ];

  const peopleOption2 = [
    {
      text: "1인",
      value: 1,
    },
    {
      text: "2인",
      value: 2,
    },
    {
      text: "3인",
      value: 3,
    },
    {
      text: "4인",
      value: 4,
    },
    {
      text: "5인",
      value: 5,
    },
    {
      text: "6인",
      value: 6,
    },
    {
      text: "7인",
      value: 7,
    },
    {
      text: "8인",
      value: 8,
    },
    {
      text: "9인",
      value: 9,
    },
    {
      text: "10인",
      value: 10,
    },
    {
      text: "11인",
      value: 11,
    },
    {
      text: "12인",
      value: 12,
    },
    {
      text: "13인",
      value: 13,
    },
    {
      text: "14인",
      value: 14,
    },
    {
      text: "15인",
      value: 15,
    },
    {
      text: "16인",
      value: 16,
    },
    {
      text: "17인",
      value: 17,
    },
    {
      text: "18인",
      value: 18,
    },
    {
      text: "19인",
      value: 19,
    },
    {
      text: "20인",
      value: 20,
    },
  ];

  const TimeType = [
    {
      text: "시간",
      value: "time",
    },
    {
      text: "일",
      value: "day",
    },
    {
      text: "주",
      value: "week",
    },
    {
      text: "월",
      value: "month",
    },
    {
      text: "년",
      value: "year",
    },
  ];

  const Facility = {
    cafeteria: {
      img: "https://ai.semos.kr/img/RNAPP/cafeteria.png",
      text: "음식점",
    },
    fitting_room: {
      img: "https://ai.semos.kr/img/RNAPP/fitting_room.png",
      text: "탈의실",
    },
    shower_room: {
      img: "https://ai.semos.kr/img/RNAPP/shower_room.png",
      text: "샤워장",
    },
    parking: {
      img: "https://ai.semos.kr/img/RNAPP/parking.png",
      text: "주차장",
    },
  };

  const DiscountPrice = (value) => {
    var regex = /[^0-9]/g;
    var stateChangeValue = value.replace(regex, "");
    var stateChangeValue = stateChangeValue.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );

    setDiscount({ ...discount, price: stateChangeValue });
  };

  return (
    <>
      <LoadingSpinner show={loading} color={"rgba(0,149,255,1)"} />

      <MainLayout>
        <Main>
          <Row between={1}>
            <Wrapper>
              <CategoryDiv>
                <Title>액티비티 명</Title>
                <Input
                  width="100%"
                  type="text"
                  placeholder="액티비티 명을 입력해 주세요. (최대 17자)"
                  name="title"
                  value={lesson ? lesson.name : ""}
                  changeHandler={(e) =>
                    ValueChange("text", "name", e.target.value)
                  }
                />
              </CategoryDiv>

              <LikeDiv>
                <p>찜</p>
                <Input
                  width="100%"
                  type="text"
                  name="like"
                  placeholder="0개"
                  value={lesson ? lesson.jjim : ""}
                  changeHandler={(e) =>
                    ValueChange("text", "jjim", e.target.value)
                  }
                />
              </LikeDiv>
            </Wrapper>

            <StatusDiv>
              {lid !== "new" &&
              (lesson ? (lesson.open !== "no" ? true : false) : false) ? (
                <ToggleButton
                  status={lesson ? lesson.open : ""}
                  toggleHandler={lessonStatusChange}
                  type="lesson"
                />
              ) : (
                ""
              )}
            </StatusDiv>
          </Row>

          <Row start={1}>
            <Title>대표 사진</Title>

            <ImageInput
              width="100%"
              height="400px"
              name="main"
              changeHandler={ImgChange}
              preview={lesson ? lesson.profile_img : ""}
              imagePro={setImageFromFile}
            >
              <p>업로드하기</p>
              <p>(8:5 비율 / JPG)</p>
            </ImageInput>

            <ButtonBox />
          </Row>

          <Row>
            <Title>추가 사진</Title>
            <Wrapper2>
              <AddImgBox for="addImg">
                <img src={cameraWhiteIcon} />
                <input
                  accept="image/*"
                  type="file"
                  id="addImg"
                  onChange={({ target: { files } }) => {
                    if (files.length) {
                      setImageFromFile({
                        file: files[0],
                        setImageUrl: ({ result }) => {
                          ImgListChange(result);
                        },
                      });
                    }
                  }}
                />
              </AddImgBox>

              <AddImgListBox>
                {lesson
                  ? lesson.add_img_list.map((item, idx) => (
                      <div key={`addImage${idx}`}>
                        <img className="addImage" src={item.image} />

                        <span
                          onClick={() => DeleteImage(idx)}
                          className="deleteImage"
                        >
                          <img src={closeIcon} />
                        </span>
                      </div>
                    ))
                  : ""}
              </AddImgListBox>
            </Wrapper2>
            <ButtonBox></ButtonBox>
          </Row>

          <Row start={1}>
            <Title>액티비티 장소</Title>

            <LocationBox
              //addHandler={lessonLocation}
              deleteHandler={deleteAddressHandler}
              searchAddressHandler={searchAddressHandler}
              data={lesson ? lesson.location : ""}
              changeHandler={(e) => additionalLocation(e.target.value)}
              addressList={addressList}
            />
          </Row>

          <Row>
            <Title>부대시설</Title>
            <Wrapper2>
              <SportBox>
                {lesson.facility_list
                  ? Object.entries(Facility).map((item, idx) => (
                      <SportCategory
                        onClick={() => FacilityAdd(item[0])}
                        active={
                          lesson.facility_list.indexOf(item[0]) > -1
                            ? true
                            : false
                        }
                      >
                        <img src={item[1].img} />
                        <span>{item[1].text}</span>
                      </SportCategory>
                    ))
                  : ""}
              </SportBox>
            </Wrapper2>
            <ButtonBox></ButtonBox>
          </Row>

          <Row>
            <Title>옵션 정보</Title>
            <Wrapper2>
              <SportBox>
                <SportCategory
                  onClick={() => OptionAdd("semos_only")}
                  active={
                    lesson
                      ? lesson.semos_only === "yes"
                        ? true
                        : false
                      : false
                  }
                >
                  <img src={semosOnly} />
                  <span>세모스 ONLY</span>
                </SportCategory>

                <SportCategory
                  onClick={() => OptionAdd("full_package")}
                  active={
                    lesson
                      ? lesson.full_package === "yes"
                        ? true
                        : false
                      : false
                  }
                >
                  <img src={fullPackage} />
                  <span>풀패키지</span>
                </SportCategory>
              </SportBox>
            </Wrapper2>
            <ButtonBox></ButtonBox>
          </Row>

          <Row>
            <Title>스포츠</Title>
            <Wrapper2>
              <SportBox>
                <SportCategory
                  onClick={() => SportAdd("스쿠버다이빙")}
                  active={
                    activitySport.indexOf("스쿠버다이빙") > -1 ? true : false
                  }
                >
                  <img src={scuba} />
                  <span>스쿠버다이빙</span>
                </SportCategory>

                <SportCategory
                  onClick={() => SportAdd("프리다이빙")}
                  active={
                    activitySport.indexOf("프리다이빙") > -1 ? true : false
                  }
                >
                  <img src={freeDiv} />
                  <span>프리다이빙</span>
                </SportCategory>

                <SportCategory
                  onClick={() => SportAdd("서핑")}
                  active={activitySport.indexOf("서핑") > -1 ? true : false}
                >
                  <img src={surf} />
                  <span>서핑</span>
                </SportCategory>

                <SportCategory
                  onClick={() => SportAdd("수영/스노클링")}
                  active={
                    activitySport.indexOf("수영/스노클링") > -1 ? true : false
                  }
                >
                  <img src={swim} />
                  <span>수영/스노클링</span>
                </SportCategory>

                <SportCategory
                  onClick={() => SportAdd("패들보드")}
                  active={activitySport.indexOf("패들보드") > -1 ? true : false}
                >
                  <img src={paddle} />
                  <span>패들보드</span>
                </SportCategory>

                <SportCategory
                  onClick={() => SportAdd("스키")}
                  active={activitySport.indexOf("스키") > -1 ? true : false}
                >
                  <img src={ski} />
                  <span>스키</span>
                </SportCategory>

                <SportCategory
                  onClick={() => SportAdd("스노보드")}
                  active={activitySport.indexOf("스노보드") > -1 ? true : false}
                >
                  <img src={snowboard} />
                  <span>스노보드</span>
                </SportCategory>
              </SportBox>
            </Wrapper2>
            <ButtonBox></ButtonBox>
          </Row>

          <Row>
            <Title>가격</Title>
            <Wrapper2>
              <Input
                type="text"
                placeholder="숫자만 입력해 주세요."
                name="price"
                value={lesson ? lesson.price : ""}
                changeHandler={(e) =>
                  ValueChange("price", "price", e.target.value)
                }
                width={`calc(50% - 15px)`}
              />

              <RelativeView width={`calc(50% - 15px)`}>
                <Input
                  type="text"
                  placeholder="확정율 (입력 X)"
                  name="price"
                  value={lesson ? lesson.confrim_rating : ""}
                  maxLength={"3"}
                  readonly={"readonly"}
                />
                <AbsoluteView
                  translate={"translate(0, -50%)"}
                  right={"20px"}
                  top={"50%"}
                >
                  확정율 (%)
                </AbsoluteView>
              </RelativeView>
            </Wrapper2>
            <ButtonBox></ButtonBox>
          </Row>

          <Row>
            <DiscountContainer>
              <RowTop>
                <Title>동반 할인가격</Title>

                <DiscountBox>
                  <div
                    style={{
                      width: "calc(50% - 15px)",
                      display: "inline-flex",
                      justifyContent: "space-between",
                      marginRight: "30px",
                    }}
                  >
                    <Select
                      width="100%"
                      name="discount-personnel"
                      value={discount ? discount.people : ""}
                      changeHandler={(e) =>
                        setDiscount({ ...discount, people: e.target.value })
                      }
                      optionList={PeopleOption}
                    />
                  </div>

                  <div
                    style={{
                      width: "calc(50% - 15px)",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Input
                      placeholder="할인 되는 금액을 입력해주세요."
                      type="text"
                      name="discount-price"
                      length="20"
                      value={discount ? discount.price : ""}
                      changeHandler={(e) => DiscountPrice(e.target.value)}
                    />
                  </div>
                </DiscountBox>

                <ButtonBox>
                  <button
                    type="button"
                    name="contain"
                    onClick={() => {
                      ValueChange("list", "discount_list", discount);
                      setDiscount({ ...discount, price: 0, people: 2 });
                    }}
                  >
                    입력
                  </button>
                </ButtonBox>
              </RowTop>

              {lesson
                ? lesson.discount_list.length > 0 && (
                    <ul>
                      {lesson.discount_list.map((item, idx) => (
                        <ListContainer>
                          <img className="pin" src={infoUnique} />
                          <span>{`${item["people"]}인 : ${item["price"]}원`}</span>
                          <img
                            onClick={() => DeleteData("discount_list", idx)}
                            className="xPin"
                            src={deleteIcon}
                          />
                        </ListContainer>
                      ))}
                    </ul>
                  )
                : ""}
            </DiscountContainer>
          </Row>

          <Row start={1}>
            <Title>기본 정보</Title>

            <Wrapper2>
              <div
                style={{
                  width: "calc(50% - 15px)",
                  display: "inline-flex",
                  justifyContent: "space-between",
                }}
              >
                <SelectImg
                  optionList={ActivityType}
                  name="maximum"
                  typeText="유형"
                  placeholder="유형"
                  value={lesson ? lesson.lesson_type : ""}
                  changeHandler={(e) =>
                    ValueChange("text", "lesson_type", e.target.value)
                  }
                  width={`calc(50% - 7.5px)`}
                  mb={true}
                />

                <SelectImg
                  optionList={ActivityDuration}
                  name="maximum"
                  typeText="기간"
                  placeholder="기간"
                  value={lesson ? lesson.duration : ""}
                  changeHandler={(e) =>
                    ValueChange("text", "duration", e.target.value)
                  }
                  width={`calc(50% - 7.5px)`}
                  mb={true}
                />
              </div>

              <SelectImg
                optionList={[
                  {
                    text: "실내",
                    value: "실내",
                  },
                  {
                    text: "야외",
                    value: "야외",
                  },
                  {
                    text: "실내+야외",
                    value: "실내+야외",
                  },
                ]}
                name="maximum"
                typeText="장소"
                placeholder="장소"
                value={lesson ? lesson.location_type : "실내"}
                changeHandler={(e) =>
                  ValueChange("text", "location_type", e.target.value)
                }
                width={`calc(50% - 15px)`}
                mb={true}
              />

              <div
                style={{
                  width: "calc(50% - 15px)",
                  display: "inline-flex",
                  justifyContent: "space-between",
                }}
              >
                <SelectImg
                  optionList={peopleOption2}
                  name="maximum"
                  typeText="최소"
                  placeholder="인원"
                  value={lesson ? lesson.min_people : ""}
                  changeHandler={(e) =>
                    ValueChange("text", "min_people", e.target.value)
                  }
                  width={`calc(50% - 7.5px)`}
                />

                <SelectImg
                  optionList={peopleOption2}
                  name="maximum"
                  typeText="최대"
                  placeholder="인원"
                  value={lesson ? lesson.max_people : ""}
                  changeHandler={(e) =>
                    ValueChange("text", "max_people", e.target.value)
                  }
                  width={`calc(50% - 7.5px)`}
                />
              </div>

              <div
                style={{
                  width: "calc(50% - 15px)",
                  display: "inline-flex",
                  justifyContent: "space-between",
                }}
              >
                <SelectImg
                  optionList={TimeType}
                  name="maximum"
                  typeText="시간"
                  placeholder="유형"
                  value={lesson ? lesson.time_type : "time"}
                  changeHandler={(e) =>
                    ValueChange("text", "time_type", e.target.value)
                  }
                  width={`calc(50% - 7.5px)`}
                />

                <SelectImg
                  optionList={
                    dateOptionList[lesson ? lesson.time_type : "time"]
                  }
                  name="maximum"
                  value={
                    lesson
                      ? lesson.time
                      : lesson.time_type == "time"
                      ? "30"
                      : "1"
                  }
                  changeHandler={(e) =>
                    ValueChange("text", "time", e.target.value)
                  }
                  width={`calc(50% - 7.5px)`}
                  paddingLeft={"30"}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "inline-flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Input
                  type="text"
                  placeholder="최소 연령 | 예-10세 이상"
                  name="price"
                  paddingLeft={"90px"}
                  value={lesson ? lesson.min_age : ""}
                  maxLength={"100"}
                  changeHandler={(e) =>
                    ValueChange("text", "min_age", e.target.value)
                  }
                >
                  <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "20px",
                      transform: "translate(0, -50%)",
                    }}
                  >
                    최소 연령:
                  </span>
                </Input>
              </div>
            </Wrapper2>

            <ButtonBox></ButtonBox>
          </Row>

          <Row start={1} direction={"column"}>
            <RowTop>
              <Title>이런 점이 특별해요</Title>

              <div
                style={{
                  width: "calc(100%)",
                  display: "inline-flex",
                  flexWrap: "wrap",
                }}
              >
                <Input
                  type="text"
                  placeholder="이 액티비티만의 특별점을 적어주세요. / 예-사진촬영 및 원본 전달까지"
                  name="unique"
                  value={tagValue.unique}
                  changeHandler={tagInputChangeHandler}
                />
              </div>

              <ButtonBox>
                <button
                  type="button"
                  name="contain"
                  onClick={() => {
                    ValueChange("list", "unique_list", {
                      content: tagValue.unique,
                      number: "new",
                    });
                    setTagValue({ ...tagValue, unique: "" });
                  }}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {lesson
              ? lesson.unique_list.length > 0 && (
                  <ul>
                    {lesson.unique_list.map((item, idx) => (
                      <ListContainer>
                        <img className="pin" src={infoUnique} />
                        <span>{item["content"]}</span>
                        <img
                          onClick={() => DeleteData("unique_list", idx)}
                          className="xPin"
                          src={deleteIcon}
                        />
                      </ListContainer>
                    ))}
                  </ul>
                )
              : ""}
          </Row>

          <Row start={1}>
            <Title>액티비티 소개</Title>

            <Textarea
              placeholder={`*액티비티 일정: 원데이 3시간 액티비티\n-최대 900글자 이모티콘`}
              name="detail-info"
              value={lesson ? lesson.information : ""}
              changeHandler={(e) =>
                ValueChange("text", "information", e.target.value)
              }
            />

            <ButtonBox />
          </Row>

          <Row start={1} direction={"column"}>
            <RowTop>
              <Title>추천 대상</Title>

              <div
                style={{
                  width: "calc(100%)",
                  display: "inline-flex",
                  flexWrap: "wrap",
                }}
              >
                <Input
                  type="text"
                  placeholder="추천 대상을 적어주세요. / 예-스쿠버다이빙 초보자"
                  name="recommend"
                  value={tagValue.recommend}
                  changeHandler={tagInputChangeHandler}
                  length={25}
                />
              </div>

              <ButtonBox>
                <button
                  type="button"
                  name="contain"
                  onClick={() => {
                    ValueChange("list", "recommend_list", {
                      content: tagValue.recommend,
                      number: "new",
                    });
                    setTagValue({ ...tagValue, recommend: "" });
                  }}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {lesson
              ? lesson.recommend_list.length > 0 && (
                  <ul>
                    {lesson.recommend_list.map((item, idx) => (
                      <ListContainer>
                        <img className="pin" src={infoCheck} />
                        <span>{item["content"]}</span>
                        <img
                          onClick={() => DeleteData("recommend_list", idx)}
                          className="xPin"
                          src={deleteIcon}
                        />
                      </ListContainer>
                    ))}
                  </ul>
                )
              : ""}
          </Row>

          <Row start={1} direction={"column"}>
            <RowTop>
              <Title>자격요건</Title>

              <div
                style={{
                  width: "calc(100%)",
                  display: "inline-flex",
                  flexWrap: "wrap",
                }}
              >
                <Input
                  type="text"
                  placeholder="수강생 자격요건을 적어주세요. / 예-오픈워터 자격증 필수"
                  name="notice"
                  value={tagValue.notice}
                  changeHandler={tagInputChangeHandler}
                />
              </div>

              <ButtonBox>
                <button
                  type="button"
                  name="contain"
                  onClick={() => {
                    ValueChange("list", "notice_list", {
                      content: tagValue.notice,
                      number: "new",
                      type: "require",
                    });
                    setTagValue({ ...tagValue, notice: "" });
                  }}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {lesson
              ? lesson.notice_list.length > 0 && (
                  <ul>
                    {lesson.notice_list.map((item, idx) => (
                      <ListContainer>
                        <img className="pin" src={infoRequire} />
                        <span>{item["content"]}</span>
                        <img
                          onClick={() => DeleteData("notice_list", idx)}
                          className="xPin"
                          src={deleteIcon}
                        />
                      </ListContainer>
                    ))}
                  </ul>
                )
              : ""}
          </Row>

          <Row start={1} direction={"column"}>
            <RowTop>
              <Title>커리큘럼</Title>

              <div
                style={{
                  width: "calc(100%)",
                  display: "inline-flex",
                  flexWrap: "wrap",
                }}
              >
                <Input
                  type="text"
                  placeholder="커리큘럼 제목을 적어주세요. / 예-[1] 준비운동 "
                  name="subject"
                  value={tagValue.subject}
                  changeHandler={tagInputChangeHandler}
                />

                <CurriculumContent
                  value={tagValue.content}
                  name="content"
                  onChange={tagInputChangeHandler}
                  placeholder="커리큘럼 내용을 적어주세요. / 예-관절 돌리기, 호흡 연습"
                  type="text"
                  maxLength={"500"}
                />
              </div>

              <ButtonBox>
                <button
                  type="button"
                  name="contain"
                  onClick={() => {
                    ValueChange("list", "curriculum_list", {
                      content: tagValue.content,
                      subject: tagValue.subject,
                      number: "new",
                    });
                    setTagValue({ ...tagValue, content: "", subject: "" });
                  }}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {lesson
              ? lesson.curriculum_list.length > 0 && (
                  <ul>
                    {lesson.curriculum_list.map((item, idx) => (
                      <CurriculumContainer>
                        <div className="C_subject">
                          <img className="pin" src={infoPin} />
                          <span>{item["subject"]}</span>
                          <img
                            onClick={() => DeleteData("curriculum_list", idx)}
                            className="xPin"
                            src={deleteIcon}
                          />
                        </div>

                        <div className="C_content">
                          {item["content"].split("\n").map((item2, idx2) => (
                            <span>{item2}</span>
                          ))}
                        </div>
                      </CurriculumContainer>
                    ))}
                  </ul>
                )
              : ""}
          </Row>

          <Row direction={"column"}>
            <RowTop>
              <Title>준비물</Title>

              <Input
                type="text"
                placeholder="준비물을 적어주세요. / 예-수영복"
                name="materials"
                value={tagValue.materials}
                changeHandler={tagInputChangeHandler}
              />

              <ButtonBox>
                <button
                  type="button"
                  name="materials"
                  onClick={() => {
                    ValueChange("list", "bring_list", {
                      content: tagValue.materials,
                      number: "new",
                    });
                    setTagValue({ ...tagValue, materials: "" });
                  }}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {lesson
              ? lesson.bring_list.length > 0 && (
                  <ul>
                    {lesson.bring_list.map((item, idx) => (
                      <Tag
                        key={idx}
                        text={item.content}
                        deleteHandler={() => DeleteData("bring_list", idx)}
                      />
                    ))}
                  </ul>
                )
              : ""}
          </Row>

          <Row direction={"column"}>
            <RowTop>
              <Title>포함사항</Title>

              <Input
                type="text"
                placeholder="포함된 사항을 적어주세요. / 예-장비 렌탈비"
                name="contain"
                value={tagValue.contain}
                changeHandler={tagInputChangeHandler}
              />

              <ButtonBox>
                <button
                  type="button"
                  name="contain"
                  onClick={() => {
                    ValueChange("list", "include_list", {
                      content: tagValue.contain,
                      number: "new",
                    });
                    setTagValue({ ...tagValue, contain: "" });
                  }}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {lesson
              ? lesson.include_list.length > 0 && (
                  <ul>
                    {lesson.include_list.map((item, idx) => (
                      <Tag
                        key={idx}
                        text={item.content}
                        deleteHandler={() => DeleteData("include_list", idx)}
                      />
                    ))}
                  </ul>
                )
              : ""}
          </Row>

          <Row direction={"column"}>
            <RowTop>
              <Title>불포함사항</Title>

              <Input
                type="text"
                placeholder="포함되지 않은 사항을 금액과 함께 적어주세요. / 예-풀장 입장료(+15,000)"
                name="except"
                value={tagValue.except}
                changeHandler={tagInputChangeHandler}
              />

              <ButtonBox>
                <button
                  type="button"
                  name="except"
                  onClick={() => {
                    ValueChange("list", "exclude_list", {
                      content: tagValue.except,
                      number: "new",
                    });
                    setTagValue({ ...tagValue, except: "" });
                  }}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {lesson
              ? lesson.exclude_list.length > 0 && (
                  <ul>
                    {lesson.exclude_list.map((item, idx) => (
                      <Tag
                        key={idx}
                        text={item.content}
                        deleteHandler={() => DeleteData("exclude_list", idx)}
                      />
                    ))}
                  </ul>
                )
              : ""}
          </Row>

          <FAQRow direction={"column"}>
            <FAQTop>
              <p>자주 묻는 질문</p>

              <ButtonBox>
                <button
                  type="button"
                  onClick={() => {
                    ValueChange("list", "faq_list", {
                      name: faqValue.question,
                      content: faqValue.answer,
                      number: "new",
                    });
                    setTagValue({ ...faqValue, question: "", answer: "" });
                  }}
                >
                  추가
                </button>
              </ButtonBox>
            </FAQTop>

            <FAQCard
              value={faqValue}
              resetHandler={faqResetHandler}
              changeHandler={faqInputChangeHandler}
            />

            <ul>
              {lesson
                ? lesson.faq_list.length > 0 &&
                  lesson.faq_list.map((item, idx) => (
                    <FAQItem
                      key={idx}
                      data={item}
                      deleteHandler={() => DeleteData("faq_list", idx)}
                    />
                  ))
                : ""}
            </ul>
          </FAQRow>

          <Row start={1} direction={"column"}>
            <RowTop>
              <Title>유의사항</Title>

              <div
                style={{
                  width: "calc(100%)",
                  display: "inline-flex",
                  flexWrap: "wrap",
                }}
              >
                <Input
                  type="text"
                  placeholder="유의사항을 적어주세요. / 예-상황에 따라 장소가 변경될 수 있습니다."
                  name="notice2"
                  value={tagValue.notice2}
                  changeHandler={tagInputChangeHandler}
                />
              </div>

              <ButtonBox>
                <button
                  type="button"
                  name="contain"
                  onClick={() => {
                    ValueChange("list", "notice_list2", {
                      content: tagValue.notice2,
                      number: "new",
                      type: "notice",
                    });
                    setTagValue({ ...tagValue, notice2: "" });
                  }}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {lesson
              ? lesson.notice_list2.length > 0 && (
                  <ul>
                    {lesson.notice_list2.map((item, idx) => (
                      <ListContainer>
                        <img className="pin" src={infoNotice} />
                        <span>{item["content"]}</span>
                        <img
                          onClick={() => DeleteData("notice_list2", idx)}
                          className="xPin"
                          src={deleteIcon}
                        />
                      </ListContainer>
                    ))}
                  </ul>
                )
              : ""}
          </Row>

          <Row direction={"column"}>
            <RowTop>
              <Title>노출 키워드</Title>

              <Input
                type="text"
                placeholder="키워드로 입력해 주세요."
                name="keyword"
                value={tagValue.keyword}
                changeHandler={tagInputChangeHandler}
              />

              <ButtonBox>
                <button
                  type="button"
                  name="keyword"
                  onClick={() => {
                    ValueChange("list", "keyword_list", {
                      tag: tagValue.keyword,
                      number: "new",
                    });
                    setTagValue({ ...tagValue, keyword: "" });
                  }}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {lesson
              ? lesson.keyword_list.length > 0 && (
                  <ul>
                    {lesson.keyword_list.map((item, idx) => (
                      <Tag
                        key={idx}
                        text={item.tag}
                        deleteHandler={() => DeleteData("keyword_list", idx)}
                      />
                    ))}
                  </ul>
                )
              : ""}
          </Row>

          <Row direction={"column"}>
            <RowTop>
              <Title>검색 키워드</Title>

              <Input
                type="text"
                placeholder="키워드로 입력해 주세요."
                name="exposure"
                value={tagValue.exposure}
                changeHandler={tagInputChangeHandler}
              />

              <ButtonBox>
                <button
                  type="button"
                  name="exposure"
                  onClick={() => {
                    ValueChange("list", "tag_list", {
                      tag: tagValue.exposure,
                      number: "new",
                    });
                    setTagValue({ ...tagValue, exposure: "" });
                  }}
                >
                  입력
                </button>
              </ButtonBox>
            </RowTop>

            {lesson
              ? lesson.tag_list.length > 0 && (
                  <ul>
                    {lesson.tag_list.map((item, idx) => (
                      <Tag
                        key={idx}
                        text={item.tag}
                        deleteHandler={() => DeleteData("tag_list", idx)}
                      />
                    ))}
                  </ul>
                )
              : ""}
          </Row>
        </Main>

        <Footer>
          {lid !== "new" ? (
            <Button
              text="삭제하기"
              clickHandler={deleteLessonHandler}
              width={`180px`}
              color={colors.white}
              fontcolor={colors.red}
            />
          ) : (
            ""
          )}

          {lid !== "new" &&
          (lesson ? (lesson.open == "no" ? true : false) : false) ? (
            <Button
              text={
                lesson
                  ? lesson.open == "no"
                    ? "액티비티 승인하기"
                    : "액티비티 승인취소"
                  : false
              }
              clickHandler={lessonStatusConfirm}
              width={`180px`}
              color={colors.white}
              fontcolor={colors.red}
            />
          ) : (
            ""
          )}

          {lid !== "new" &&
          (lesson ? (lesson.open !== "no" ? true : false) : false) ? (
            <Button
              text="일정 확인하기"
              clickHandler={() =>
                navigate("/partner/" + pid + "/schedule/detail/" + lid)
              }
              width={`180px`}
              color={colors.white}
              fontcolor={colors.gray}
            />
          ) : (
            ""
          )}

          {lid !== "new" ? (
            <Button
              text="저장하기"
              clickHandler={saveAndWriteUsageInfoHandler}
              width={`180px`}
            />
          ) : (
            ""
          )}

          {lid == "new" ? (
            <Button text="등록하기" clickHandler={lessonAdd} width={`180px`} />
          ) : (
            ""
          )}
        </Footer>

        <CropperPopup
          cropType={cropType}
          status={imgPopup}
          OnOff={setImgPopup}
          WH={imgScale}
          imgData={cropImg}
          setImg={CropImg}
        />

        {/*<Row>
            <DiscountContainer>
              <RowTop>
                <Title>동반 할인가격</Title>

                <DiscountBox>
                  <Select
                    width="250px"
                    name="discount-personnel"
                    value={discount.people}
                    changeHandler={(e) =>
                      setDiscount({ ...discount, people: e.target.value })
                    }
                    optionList={[
                      {
                        text: "2인",
                        value: 2,
                      },
                      {
                        text: "3인",
                        value: 3,
                      },
                      {
                        text: "4인",
                        value: 4,
                      },
                      {
                        text: "5인",
                        value: 5,
                      },
                      {
                        text: "6인",
                        value: 6,
                      },
                      {
                        text: "7인",
                        value: 7,
                      },
                      {
                        text: "8인",
                        value: 8,
                      },
                      {
                        text: "9인",
                        value: 9,
                      },
                      {
                        text: "10인",
                        value: 10,
                      },
                      {
                        text: "11인",
                        value: 11,
                      },
                      {
                        text: "12인",
                        value: 12,
                      },
                      {
                        text: "13인",
                        value: 13,
                      },
                      {
                        text: "14인",
                        value: 14,
                      },
                      {
                        text: "15인",
                        value: 15,
                      },
                      {
                        text: "16인",
                        value: 16,
                      },
                      {
                        text: "17인",
                        value: 17,
                      },
                      {
                        text: "18인",
                        value: 18,
                      },
                      {
                        text: "19인",
                        value: 19,
                      },
                      {
                        text: "20인",
                        value: 20,
                      },
                    ]}
                  />

                  <DiscountWrapper>
                    <Input
                      placeholder="기존 가격에서 추가되는 금액을 입력해 주세요."
                      type="text"
                      name="discount-price"
                      value={discount.price}
                      changeHandler={(e) => {
                        var regex = /[^0-9]/g;
                        var stateChangeValue = e.target.value.replace(
                          regex,
                          ""
                        );
                        var stateChangeValue = stateChangeValue.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                        setDiscount({ ...discount, price: stateChangeValue });
                      }}
                    />
                  </DiscountWrapper>
                </DiscountBox>

                <ButtonBox>
                  <button
                    type="button"
                    onClick={(e) => {
                      ValueChange("list", "discount_list", {
                        number: "new",
                        price: discount.price,
                        people: discount.people,
                      });
                      setDiscount((prev) => ({
                        ...prev,
                        price: "",
                        people: "2",
                      }));
                    }}
                  >
                    입력
                  </button>
                </ButtonBox>
              </RowTop>

              {lesson
                ? lesson.discount_list.length > 0 && (
                    <ul>
                      {lesson.discount_list.map((item, idx) => (
                        <Tag
                          key={idx}
                          text={`${item.people}인 ${item.price}원`}
                          deleteHandler={() => DeleteData("discount_list", idx)}
                        />
                      ))}
                    </ul>
                  )
                : ""}
            </DiscountContainer>
          </Row>*/}
      </MainLayout>

      <OverlayPortal>
        {modal && (
          <>
            <Overlay toggleHandler={() => setModal(!modal)} />

            <ModalLayout
              subButtonText="저장하고 이동하기"
              subButtonHandler={lessonSave}
              buttonText="바로 이동하기"
              clickHandler={() =>
                navigate(`/partner/${pid}/lesson/${lid}/editor`)
              }
            >
              <ModalText>
                액티비티 정보를 저장하고 이용 정보 수정 페이지로
                이동하시겠습니까?
              </ModalText>
            </ModalLayout>
          </>
        )}
      </OverlayPortal>
    </>
  );
};

export default PartnerLessonEditPage;

const SportBox = styled.div`
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  overflow: hidden;
`;

const AddImgBox = styled.label`
  width: 200px;
  height: 125px;
  margin-right: 10px;
  display: inline-block;
  cursor: pointer;
  border: 3px solid rgba(132, 140, 148, 0.5);
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  background-color: rgba(132, 140, 148, 0.5);

  img {
    width: 40%;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }

  input {
    display: none;
    width: 0.1px;
    height: 0.1px;
    pointer-events: none;
  }
`;

const AddImgListBox = styled.div`
  width: calc(100% - 210px);
  height: 125px;
  display: inline-block;
  white-space: nowrap;
  overflow: auto hidden;

  div {
    width: 200px;
    height: 125px;
    margin-right: 10px;
    display: inline-block;
    position: relative;
    border: 1px solid rgba(132, 140, 148, 0.5);
    overflow: hidden;
    border-radius: 4px;

    .addImage {
      width: 100%;
      display: inline-block;
    }

    .deleteImage {
      position: absolute;
      top: 2px;
      right: 2px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 1px solid rgba(132, 140, 148, 1);

      img {
        width: 9px;
        display: inline-block;
      }
    }
  }
`;

const SportCategory = styled.div`
  width: 80px;
  height: 60px;
  display: inline-flex;
  flex-wrap: wrap;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: ${({ active }) =>
    active ? `3px solid rgba(0,149,255,1)` : "3px solid rgba(132,140,148, .5)"};
  font-size: 12px;
  cursor: pointer;

  img {
    width: 30px;
    display: inline-block;
  }

  span {
    width: 100%;
    display: inline-block;
    text-align: center;
  }
`;

const RelativeView = styled.span`
  display: inline-block;
  position: relative;
  width: ${({ width }) => (width ? width : "unset")};
`;

const AbsoluteView = styled.span`
  display: inline-block;
  position: absolute;
  top: ${({ top }) => (top ? top : "unset")};
  left: ${({ left }) => (left ? left : "unset")};
  right: ${({ right }) => (right ? right : "unset")};
  transform: ${({ translate }) => (translate ? translate : "unset")};
`;
