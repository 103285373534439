import styled from "styled-components";
import colors from "../../../styles/constants/colors";
import flexbox from "../../../styles/func/flexbox";

export const BannerSection = styled.section`
  width: 100%;
  ${flexbox("center", "center", "column")}
  margin-top: 20px;
  margin-bottom: 70px;

  p {
    margin-bottom: 20px;
  }
`;

export const Main = styled.main`
  width: 100%;
  padding: 0 20px;

  ul {
    min-height: calc(6 * 65px);
  }
`;

export const EditButtonBox = styled.div`
  width: 100%;
  text-align: end;
  justify-self: flex-end;

  button {
    background-color: ${colors.gray};
    color: ${colors.white};
    padding: 5px 15px;
    border-radius: 40px;
  }
`;

export const Footer = styled.footer`
  padding: 0 20px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  height: 90px;
  background-color: ${colors.white};
`;
