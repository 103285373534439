import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import MainHeader from "../../components/header";
import useInput from "../../hooks/useInput";
import usePagination from "../../hooks/usePagination";
import Index from "../../layouts";
import MainLayout from "../../layouts/mainLayout";
import NoticeModal from "../../layouts/modal/notice";
import Overlay from "../../layouts/overlay";
import Input from "../../modules/input";
import OverlayPortal from "../../overlayPortal";
import base from "../../styles/constants/base";
import searchIcon from "../../assets/icon/search.svg";
import Select from "../../modules/select";
import salesList from "../../data/sales";
import MessageListCard from "../../components/message/card";

//import Pagination from "../../components/pagination";
import flexbox from "../../styles/func/flexbox";
import typography from "../../styles/constants/typography";
import colors from "../../styles/constants/colors";

// const CUSTOMER_PER_PAGE = 10;
// const CUSTOMER_CARD_HEIGHT = 50;

const Main = styled.main`
  width: 100%;
  height: 100%;
  padding: 0 20px;
  margin-top: 20px;
  overflow-y: scroll;

  ul {
    width: 100%;
  }
`;

const FilterBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  div {
    display: flex;
  }
`;

const SerachIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
  pointer-events: none;
`;

const Footer = styled.footer`
  width: 100%;
  padding: 0 ${base.spacing.main}px;
  position: absolute;
  bottom: 20px;
`;


// pagination
const Div = styled.div`
  width: 100%;
  height: 100%;
  ${flexbox("space-between")};
  font-weight: ${typography.weight.regular};
`;

const SectionButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: ${colors.white};
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};

  &:focus {
    box-shadow: ${base.boxShadow.default};
  }
`;

const List = styled.ul`
  ${flexbox()}
`;

const PageButton = styled.li`
  width: 40px;
  height: 40px;
  border: ${({ active }) => (active ? `2px solid ${colors.blue}` : null)};
  color: ${({ active }) => (active ? colors.blue : null)};
  ${flexbox()};
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};
  margin: 0 5px;
  cursor: pointer;
`;

const SalesPage = () => {

  const { num, y, m, t, f } = useParams();
  const navigate = useNavigate();
  const numCheck = num > 1 || (y || m || t || f !== 'all');

  const { value, inputChangeHandler } = useInput({
    search: t !== 'all' ? t : "" ,
    filter: f !== 'all' ? f : "all" ,
    month: m !== 'all' ? m : "" ,
    year : y !== 'all' ? y : "" ,
  });

  const salesData = salesList;

  const { offset, limit, currentPage, setCurrentPage } = usePagination(
    salesData,
    10
  );

  const [modal, setModal] = useState(false);

  const openModalHandler = () => {
    setModal(!modal);
  };

  const [message, setMessage] = useState("");
  const [messageCount, setMessageCount] = useState(0);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [page, setPage] = useState(num);

      // 알림톡 내역 로드
      useEffect(() => {
    
        var token = window.localStorage.getItem('token')
  
        const frm = new FormData();
        frm.append("mode", (updateStatus || numCheck ? "message_update" : "list"));
        frm.append("token", token);
        frm.append("page", updateStatus ? 1 : num);
        frm.append("year", (updateStatus || numCheck ? value.year : "all"));
        frm.append("month", (updateStatus || numCheck ? value.month : "all"));
        frm.append("text", (updateStatus || numCheck ? value.search : 'all'));
        frm.append("type", (updateStatus || numCheck ? value.filter : 'all'));
  
          axios.post("https://ai.semos.kr/semos_admin/user/send", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          }).then((data) => {
            var Value = data.data;
            var status = Value.status
            var statusMessage = Value.status_message
  
            if(status == 'success') {
  
              setMessage(Value.message_list)
              setMessageCount(Value.message_count)
              setUpdateStatus(true)

              if(updateStatus) {
                setPage(1)
                navigate(`/message_list/${1}/${value.year ? value.year : "all" }/${value.month ? value.month : "all" }/${value.search ? value.search : "all" }/${value.filter}`,  { replace: true})
              } else {
                setPage(num)
              }
  
            } else if(status == 'waring') {
              alert(statusMessage)
            }
  
          });
  
      }, [value.year, value.month, value.filter]);


  const prevHandler = () => {
    if (page > 1) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "message_update");
      frm.append("token", token);
      frm.append("page", page - 1);
      frm.append("year", value.year);
      frm.append("month", value.month);
      frm.append("text", value.search);
      frm.append("type", value.filter);

        axios.post("https://ai.semos.kr/semos_admin/user/send", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setMessage(Value.message_list)
            setMessageCount(Value.message_count)
            setPage(page - 1)
            navigate(`/message_list/${page - 1}/${value.year ? value.year : "all" }/${value.month ? value.month : "all" }/${value.search ? value.search : "all" }/${value.filter}`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    } else {
      alert('첫 페이지입니다.');
    }
  };

  const nextHandler = () => {
    if (page < numPages) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "message_update");
      frm.append("token", token);
      frm.append("page", page + 1);
      frm.append("year", value.year);
      frm.append("month", value.month);
      frm.append("text", value.search);
      frm.append("type", value.filter);

        axios.post("https://ai.semos.kr/semos_admin/user/send", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setMessage(Value.message_list)
            setMessageCount(Value.message_count)
            setPage(page + 1)
            navigate(`/message_list/${page + 1}/${value.year ? value.year : "all" }/${value.month ? value.month : "all" }/${value.search ? value.search : "all" }/${value.filter}`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    } else {
      alert('마지막 페이지입니다.');
    }
  };

  const PageChange = (number) => {
    if ( number < (numPages + 1) && number > 0 ) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "message_update");
      frm.append("token", token);
      frm.append("page", number);
      frm.append("year", value.year);
      frm.append("month", value.month);
      frm.append("text", value.search);
      frm.append("type", value.filter);

        axios.post("https://ai.semos.kr/semos_admin/user/send", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setMessage(Value.message_list)
            setMessageCount(Value.message_count)
            setPage(number)
            navigate(`/message_list/${number}/${value.year ? value.year : "all" }/${value.month ? value.month : "all" }/${value.search ? value.search : "all" }/${value.filter}`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    }
  };

  const TextSearch = (keyValue) => {

    if(keyValue === 'Enter') {
        var token = window.localStorage.getItem('token')

        const frm = new FormData();
        frm.append("mode", "message_update");
        frm.append("token", token);
        frm.append("page", 1);
        frm.append("year", value.year);
        frm.append("month", value.month);
        frm.append("text", value.search);
        frm.append("type", value.filter);

        axios.post("https://ai.semos.kr/semos_admin/user/send", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setMessage(Value.message_list)
            setMessageCount(Value.message_count)
            setPage(1)
            navigate(`/message_list/${1}/${value.year ? value.year : "all" }/${value.month ? value.month : "all" }/${value.search ? value.search : "all" }/${value.filter}`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    }
  
  };

  const numPages = Math.ceil(messageCount / 10);
  let PageList = [];
  
  if(page < 6) {
    //PageList = [1,2,3,4,5,6,7,8,9,10];
    for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
      PageList.push(i+1);
    }

  } else {
    if( (numPages - 5) < page ) {
      var startPage = numPages - 9;
      if(startPage < 1) {
        startPage = 1;
      }

      for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
        PageList.push(startPage + i);
      }

    } else {
      var startPage = page - 4;
      if(startPage < 1) {
        startPage = 1;
      }
      
      for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
        PageList.push(startPage + i);
      }
    }
  }


  return (
    <>
      <MainLayout heightType={true}>
        <MainHeader title="알림/친구톡 내역">
          <FilterBox>
            <div>
              <Input
                width="400px"
                type="text"
                name="search"
                value={value.search}
                changeHandler={inputChangeHandler}
                keyPressHandler={TextSearch}
                placeholder="구매자 명, 알림톡 내용으로 검색"
                padding={true}
                margin={true}
              >
                <SerachIcon src={searchIcon} />
              </Input>

              <Select
                border={false}
                width="150px"
                isTransparent={true}
                value={value.filter}
                name="filter"
                changeHandler={inputChangeHandler}
                optionList={[
                  {
                    text: "전체",
                    value: "all",
                  },
                  {
                    text: "성공",
                    value: "success",
                  },
                  {
                    text: "실패",
                    value: "fail",
                  },
                  {
                    text: "예약",
                    value: "reservation",
                  },
                ]}
              />
            </div>

            <Select
              type="calendar"
              name="year"
              border={false}
              width="150px"
              isTransparent={true}
              value={value.year}
              changeHandler={inputChangeHandler}
              optionList={[
                {
                    text: "전체(년)",
                    value: "",
                },
                {
                    text: "2021년",
                    value: "2021",
                },
                {
                    text: "2022년",
                    value: "2022",
                },
                {
                    text: "2023년",
                    value: "2023",
                },
                {
                  text: "2024년",
                  value: "2024",
                },
                {
                  text: "2025년",
                  value: "2025",
                },
              ]}
            />

            <Select
              type="calendar"
              name="month"
              border={false}
              width="150px"
              isTransparent={true}
              value={value.month}
              changeHandler={inputChangeHandler}
              optionList={[ 
                {
                    text: "전체(월)",
                    value: "",
                },
                {
                    text: "01월",
                    value: "01",
                },
                {
                    text: "02월",
                    value: "02",
                },
                {
                    text: "03월",
                    value: "03",
                },
                {
                    text: "04월",
                    value: "04",
                },
                {
                    text: "05월",
                    value: "05",
                },
                {
                    text: "06월",
                    value: "06",
                },
                {
                    text: "07월",
                    value: "07",
                },
                {
                    text: "08월",
                    value: "08",
                },
                {
                    text: "09월",
                    value: "09",
                },
                {
                    text: "10월",
                    value: "10",
                },
                {
                    text: "11월",
                    value: "11",
                },
                {
                    text: "12월",
                    value: "12",
                },
              ]}
            />
          </FilterBox>
        </MainHeader>

        <Main>
          <Index
            type="message"
            indexList={[
              "NO.",
              "담당자",
              "결제 번호",
              "닉네임",
              "전화번호",
              "카테고리",
              "내용",
              "상태",
              "발송일",
            ]}
          />
          <ul>
            {message ? message.map((item, idx) => (
              <MessageListCard key={idx} data={item} idx={idx} />
            )) : ""}
          </ul>
        </Main>

        <Footer>
        {/*<Pagination
            total={salesData.length}
            limit={limit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />*/}
            
          <Div>
            <SectionButton onClick={prevHandler}>&lt;</SectionButton>

            <List>
              {PageList.length ? PageList.map((item, idx) => 
                ( <PageButton
                    key={idx}
                    onClick={() => PageChange(item)}
                    aria-current={page == item ? "page" : null}
                    active={page == item ? true : false}
                  >
                    {item}
                  </PageButton>
                )) : ""}
            </List>

            <SectionButton onClick={nextHandler}>&gt;</SectionButton>
          </Div>

        </Footer>
      </MainLayout>

      <OverlayPortal>
        {modal && (
          <>
            <Overlay toggleHandler={openModalHandler} />
            <NoticeModal closeHandler={openModalHandler} />
          </>
        )}
      </OverlayPortal>
    </>
  );
};

export default SalesPage;
