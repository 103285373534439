import styled from "styled-components";
import base from "../../styles/constants/base";
import colors from "../../styles/constants/colors";

const Box = styled.div`
  button {
    height: ${base.height.input}px;
    background-color: ${colors.white};
    border-radius: ${base.borderRadius.default}px;
    box-shadow: ${base.boxShadow.default};
    padding: 0 10px;
    border: 2px solid transparent;

    &:focus {
      border-radius: ${base.borderRadius.default}px;
      box-shadow: ${base.boxShadow.default};
    }

    &:not(:first-child) {
      margin-left: 10px;
    }

    &:first-child {
      color: ${({ active }) => active === 1 && colors.blue};
      border: 2px solid
        ${({ active }) => (active === 1 ? colors.blue : `transparent`)};
    }

    &:last-child {
      color: ${({ active }) => active === 0 && colors.blue};
      border: 2px solid
        ${({ active }) => (active === 0 ? colors.blue : `transparent`)};
    }
  }
`;

const RecommendationBox = ({ value, changeHandler }) => {
  return (
    <Box active={value === "true" ? 1 : 0}>
      <button type="button" name="true" onClick={changeHandler}>
        확정 예약
      </button>

      <button
        type="button"
        name="false"
        active={value === "false" ? 0 : 1}
        onClick={changeHandler}
      >
        확인 예약
      </button>
    </Box>
  );
};

export default RecommendationBox;
