import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import MainHeader from "../../components/header";
import MainLayout from "../../layouts/mainLayout";
import Border from "../../modules/border";
import Button from "../../modules/button";
import Template from "./template";

//import Pagination from "../../components/pagination";
import base from "../../styles/constants/base";
import flexbox from "../../styles/func/flexbox";
import typography from "../../styles/constants/typography";
import colors from "../../styles/constants/colors";

// const CUSTOMER_PER_PAGE = 10;
// const CUSTOMER_CARD_HEIGHT = 50;

const Main = styled.main`
  width: 100%;
  height: 100%;
  padding: 0 20px;
  margin-top: 20px;
  overflow-y: scroll;

  ul {
    width: 100%;
  }
`;

const FilterBox = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;

  div {
    display: inline-flex;
    align-items : center;
    justify-content : center;
    box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 5px, rgb(0 0 0 / 15%) -0.5px -0.5px 5px;
    border-radius: 8px;
    cursor : pointer;
    margin-right : 10px;
    padding : 10px 15px;
  }

  div:last-child { 
    margin-right : unset;
  }
`;


const MetaSection = styled.section`
  display: flex;

  img {
    width: 180px;
    border-radius: ${base.borderRadius.default}px;
    background-color: ${colors.gray};
    margin-right: 20px;
    cursor : pointer;
  }

  div {
    ${flexbox("space-between", "flex-start", "column")}
  }

  p {
    overflow : hidden;
    text-overflow: ellipsis;
    white-space : nowrap;
  }
`;

const ParticipantSection = styled.section`
  div {
    display: flex;
    padding: 10px 0;

    p:first-child {
      font-weight: ${typography.weight.bold};
      margin-right: 70px;
      flex-shrink: 0;
    }

    p:nth-child(2) {
      flex-shrink: 0;
    }

    p:last-child {
      width: 100%;
      text-align: end;
    }
  }
`;


const BottomButtonBox = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;

  button:first-child {
    margin-right: 10px;
  }
`;

const PaymentCXBox = styled.div`
  margin-top: 30px;

  .cxContentBox {
    width : 100%;
    display : inline-flex;
    align-items : top;
    justify-content : space-between;
    font-size: ${typography.size.small}px;
    margin-bottom: 20px;

    p:first-child {
      color: ${colors.mediumGray};
    }

    .cxContent {
        width : calc(100% - 100px);
        display : inline-block;

        .cxType {
            border-radius : 8px;
            padding : 10px 12px;
            display : inline-block;
            border : 3px solid rgba(132,140,148,1);
            cursor : pointer;
            font-size: 17px;
            font-weight : 500;
        }
        .cxType:first-child  {
            margin-right : 15px;
        }
        .typeActive {
            border : 3px solid rgba(0,149,255,1);
        }
        .cxManeger {
            width : 100%;
            display : inline-block;
            border-radius : 8px;
            padding : 10px 12px;
            border : 1px solid rgba(132,140,148,1);
            font-size: 17px;
            font-weight : 500;
            background-color : #F9F9F9;
        }
        .cxMessage {
            width : 100%;
            display : inline-block;
            border-radius : 8px;
            padding : 10px 12px;
            border : 1px solid rgba(132,140,148,1);
            font-size: 17px;
            font-weight : 500;
            background-color : #F9F9F9;
            height : 300px;
            resize : none;
        }
    }
  }
`;

const TemplateButton = styled.span`
  height : 50px;
  display : inline-flex;
  align-items : center;
  border-radius : 8px;
  padding : 10px 12px;
  box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 5px, rgb(0 0 0 / 15%) -0.5px -0.5px 5px;
  font-size: 17px;
  font-weight : 500;
  cursor : pointer;
  margin-right : 10px;
`

const Message = () => {

const ThisDate = new Date();
const ThisMonth = ThisDate.getMonth();
const ThisDay = ThisDate.getDate();

const [CXManager, setCXManager] = useState("Victor");
const [partner, setPartner] = useState("***");
const [link, setLink] = useState("");
const [phone, setPhone] = useState("");
const [template, setTemplate] = useState("PREGISTER4");
const [name, setName] = useState("");
const [user, setUser] = useState("alimtalk");
const [message, setMessage] = useState("[세모스]");

const [month, setMonth] = useState(ThisMonth > 7 ? String(ThisMonth + 2) + "월" : "0" + String(ThisMonth + 2) + "월" );
const [monthAday, setMonthAday] = useState(`${ThisMonth > 8 ? ThisMonth + 1 : "0" + String(ThisMonth + 1)}월 ${ThisDay > 9 ? ThisDay : "0" + String(ThisDay)}일` );

const messageContent = {

PREGISTER4 : `${partner} 파트너님, 파트너 등록을 신청해주셔서 감사합니다 :)
  
액티비티 등록을 위해 세모스 담당 매니저가 유선 연락드릴 예정이오니, 아래 내용을 회신해주세요.

- 파트너님의 실명
- 통화 가능한 날짜와 시간


레저스포츠 1등 예약 앱, 세모스!
☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)`,

PSCHEDULE3 : `${partner} 파트너님! 세모스 어플 내 ${month} 일정을 등록하고 예약시스템을 오픈하기 위해 아래 정보가 필요해요.

- ${month} 진행 불가능 일정
- 액티비티 정보(금액, 활동 지역 등) 변동사항

***파트너님의 액티비티를 고객님들이 정상적으로 결제할 수 있도록 ${monthAday}까지 회신주시기 바랍니다.

레저스포츠 1등 예약 앱, 세모스!
☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)`,

PARTNERIN3 : `${partner} 파트너님! 아래 등록 요청해주신 액티비티에 대한 정보 입력 링크를 전달드려요.

${link}

${monthAday}까지 작성해주시기 바라며, 작성 기한 연장이나 전화상담을 통한 대리입력 등 도움이 필요하신 부분은 언제든 본 채널로 편히 말씀주세요.

카카오톡 알림톡은 PC에서 확인이 불가합니다. 보내드린 링크를 ‘나와의 채팅’으로 복사하신 후 PC에서 확인하여 작성해주세요.

***액티비티 등록은 선완료 순으로 진행해드립니다. 완료 후 본 채널로 말씀주시면 빠르게 등록 도와드릴게요. :)

레저스포츠 1등 예약 앱, 세모스!
☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)`,

};

const ContentReset = () => {

  if(window.confirm("작성한 내용을 초기화 하시겠습니까?")) {

    setCXManager("Victor");
    setPartner("");
    setLink("");
    setPhone("");

  }

}

const messageSend = () => {

  if(window.confirm("작성한 내용을 전송하시겠습니까?")) { 

    const mCheck = CXManager.length > 2;
    //const pCheck = partner.length > 1;
    const lCheck = link.length > 8;
    const phCheck = phone.length > 9;

    if(mCheck && phCheck) {

        var token = window.localStorage.getItem('token')
  
        const frm = new FormData();
        frm.append("mode", "partnerInfo");
        frm.append("token", token);
        frm.append("manager", CXManager);
        frm.append("partner", partner);
        frm.append("template", template);
        frm.append("link", link);
        frm.append("phone", phone);
        frm.append("content", user == 'alimtalk' ? messageContent[template] : message );
        frm.append("user", user);
        frm.append("name", name);
  
        axios.post("https://ai.semos.kr/semos_admin/user/send", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message
  
          if(status == 'success') {
            alert(statusMessage)
  
          } else if(status == 'waring') {
            alert(statusMessage)

          } else if(status == 'fail') {
            alert(statusMessage)

          }
  
        });

    } else {
      if(!mCheck) {
        alert('담당자명을 확인해주세요!')
      } else if(!phCheck) {
        alert('전화번호를 확인해주세요!')
      }/*else if(!pCheck) {
        alert('강사명을 확인해주세요!')
      } else if(!lCheck) {
        alert('노션 링크를 확인해주세요!')
      }*/
    }
  }

}

const PhoneHyphen = (value) => {
  var phoneNumberHyphen = value;
  
  phoneNumberHyphen = String(phoneNumberHyphen).replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-");

  setPhone(phoneNumberHyphen)
}


const DataChange = (type, value) => {

  switch(type) {
    
    // user
    case "partner":
      setPartner(value)
    break;

    case "month":
      setMonth(value)
    break;

    case "mnd":
      setMonthAday(value)
    break;

    case "link":
      setLink(value)
    break;
    
  }

}

  return (
      <MainLayout heightType={true}>
        <MainHeader title={user == 'alimtalk' ? "알림톡 전송" : "문자 전송"} /*title="친구톡 전송"*/>
          <FilterBox>
            <div onClick={() => setUser('message')} className={ user == 'message' ? "bg_blue_100 color_white font_15" : "bg_white font_15" }>
              문자
            </div>

            <div onClick={() => setUser('alimtalk')} className={ user == 'alimtalk' ? "bg_blue_100 color_white font_15" : "bg_white font_15" }>
              알림톡
            </div>
          </FilterBox>
        </MainHeader>

        <Main>
          {/*<MetaSection>
              <img  />

              <div>
   
              </div>
          </MetaSection>*/}

          {/*<ParticipantSection>
              <div>
              <p>구매자</p>
              <p></p>

              <p></p>
              </div>

              <div>
              <p>판매자</p>
              <p></p>
              <p></p>
              </div>

          </ParticipantSection>
        <Border />*/}

          {user == 'alimtalk' ? 
              <section>
                 <p style={{ fontSize : "20px", fontWeight : "500" }} >알림톡 정보{/*친구톡 정보*/}</p>
   
                 <PaymentCXBox>
                     <div className="cxContentBox">
                         <p>유저 명</p>
                         <span className="cxContent">
                             <input type="text" value={partner} onChange={(e) => setPartner(e.target.value)} className="cxManeger" placeholder="강사명을 입력해주세요!" />
                         </span>
                     </div>
   
                     <div className="cxContentBox">
                         <p>유저 전화번호</p>
                         <span className="cxContent">
                             <input type="text" value={phone} onChange={(e) => PhoneHyphen(e.target.value)} className="cxManeger" placeholder="- 을 제외하고 입력해주세요!" />
                         </span>
                     </div>
   
                     <div className="cxContentBox">
                         <p>담당자 명</p>
                         <span className="cxContent">
                             <input type="text" value={CXManager} onChange={(e) => setCXManager(e.target.value)} className="cxManeger" placeholder="담당자명을 입력해 주세요!" />
                         </span>
                     </div>
   
                     {/*<div className="cxContentBox">
                         <p>노션 링크</p>
                         <span className="cxContent">
                             <input type="text" value={link} onChange={(e) => setLink(e.target.value)} className="cxManeger" placeholder="강사 정보가 등록된 노션 링크를 입력해주세요! *권한 확인!" />
                         </span>
                      </div>*/}

                      <div className="cxContentBox">
                         <p>알림톡 템플릿</p>
                         <span className="cxContent">
                             <TemplateButton onClick={() => setTemplate('PREGISTER4')} className={ template == 'PREGISTER4' ? "bg_blue_100 color_white" : "bg_white" }>파트너 등록</TemplateButton>
                             <TemplateButton onClick={() => setTemplate('PSCHEDULE3')}  className={ template == 'PSCHEDULE3' ? "bg_blue_100 color_white" : "bg_white" }>파트너 일정 등록</TemplateButton>
                             <TemplateButton onClick={() => setTemplate('PARTNERIN3')}  className={ template == 'PARTNERIN3' ? "bg_blue_100 color_white" : "bg_white" }>파트너 정보 입력</TemplateButton>
                         </span>
                     </div>
   
                     <div className="cxContentBox">
                         <p>알림톡 내용</p>
                         <span className="cxContent">
                             {/*<textarea value={messageContent[template]} type="text" className="cxMessage" readOnly maxLength="300" />*/}
                             <Template type={template} change={DataChange}
                              data={{
                                "partner" : partner,
                                "month" : month,
                                "mnd" : monthAday,
                                "link" : link
                              }} />
                         </span>
                     </div>
                 </PaymentCXBox>
   
             </section> 

             :               
             
              <section>
                <p style={{ fontSize : "20px", fontWeight : "500" }} >문자 정보{/*친구톡 정보*/}</p>

                <PaymentCXBox>

                    <div className="cxContentBox">
                         <p>유저 명</p>
                         <span className="cxContent">
                             <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="cxManeger" placeholder="유저명을 입력해 주세요!" />
                         </span>
                     </div>
   
                     <div className="cxContentBox"> 
                         <p>유저 전화번호</p>
                         <span className="cxContent">
                             <input type="text" maxLength={"13"} value={phone} onChange={(e) => PhoneHyphen(e.target.value)} className="cxManeger" placeholder="- 을 제외하고 입력해주세요!" />
                         </span>
                     </div>
   
                     <div className="cxContentBox">
                         <p>담당자 명</p>
                         <span className="cxContent">
                             <input type="text" value={CXManager} onChange={(e) => setCXManager(e.target.value)} className="cxManeger" placeholder="담당자명을 입력해 주세요!" />
                         </span>
                     </div>
   
   
                     <div className="cxContentBox">
                         <p>문자 내용</p>
                         <span className="cxContent">
                             <textarea onChange={(e) => setMessage(e.target.value)} value={message} type="text" className="cxMessage" maxLength="1000" />
                         </span>
                     </div>
                 </PaymentCXBox>
              </section> 
          }
   
          </Main>

          <BottomButtonBox>
              <Button text="초기화" clickHandler={ContentReset} width={`180px`} color={colors.white} fontcolor={colors.gray}/>

              <Button text={user == 'alimtalk' ? "알림톡 전송" : "문자 전송"} clickHandler={messageSend} width={`180px`} />

          </BottomButtonBox>

      </MainLayout>
  );
};

export default Message;
