import { useState } from "react";
import styled from "styled-components";
import base from "../../../styles/constants/base";
import colors from "../../../styles/constants/colors";
import ScheduleCard from "../../partner/schedule/scheduleCard";

const weekData = {
  1: "월",
  2: "화",
  3: "수",
  4: "목",
  5: "금",
  6: "토",
  7: "일",
};

const Card = styled.div`
  width: 100%;
  position: relative;
  border-radius: ${base.borderRadius.default}px;
  box-shadow: ${base.boxShadow.default};
  padding: 20px;
  margin-bottom : 20px;

  p {
    color: ${colors.mediumGray};
    margin-bottom: 10px;
  }

  button {
    position: absolute;
    right: 20px;
    top: 20px;
    color: ${colors.blue};
  }
`;

const DayBox = styled.div`
  margin-bottom: 20px;

  ul {
    display: flex;

    li {
      background-color: ${colors.lightBlue};
      padding: 15px 30px;
      border-radius: 40px;
      margin-right: 20px;
    }
  }
`;

const ClockBox = styled.div`
  ul {
    display: flex;

    li {
      background-color: ${colors.lightBlue};
      padding: 10px 20px;
      border-radius: 20px;
      margin-right: 20px;
    }
  }
`;

const BaseCard = ({
  data = "",
  number,
  weekList,
  deleteHandler,
  editHandler,
  clock,
  weekToggleHandler,
}) => {
  const [edit, setEdit] = useState(false);

  if (edit === false) {
    return (
      <Card>
        <DayBox>
          <p>요일</p>
          <ul>
            {data.yoil.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
        </DayBox>

        <ClockBox>
          <p>시간</p>
          <ul>
            {data.time.map((item, idx) => (
              <li key={idx}>
                {item["start_time"]}:{item["start_min"]} ~{" "}
                {item["finish_time"]}:{item["finish_min"]}
              </li>
            ))}
          </ul>
        </ClockBox>

        <button type="button" onClick={() => setEdit(true)}>
          수정하기
        </button>
      </Card>
    );
  }

  if (edit === true) {

    var yoilWeek = data.yoil 
    var dateWeek = data.time 
    return (
      <ScheduleCard
        week={{
          yoilWeek,
          weekToggleHandler,
          number
        }}
        clock={clock}
        data={data}
        deleteHandler={deleteHandler}
        editHandler={editHandler}
        saveHandler={() => setEdit(false)}
        number={number}
      />
    );
  }
};

export default BaseCard;
