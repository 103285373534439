import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";

import DropDown from "./dropDown";


const Rating = () => { 

  const navigate = useNavigate();
  const [data, setData] = useState({
    ACTIVITY_COUNT : [],
    PARTNER_COUNT : [],
    PAYMENT_COUNT : [],
    SEARCH_COUNT : [],
  });

  const Today = new Date();
  const TodayYear = Today.getFullYear();
  const TodayMonth = Today.getMonth() + 1 > 9 ? Today.getMonth() + 1 : `0${Today.getMonth() + 1}`;
  const TodayDay = Today.getDate() + 1 > 9 ? Today.getDate() + 1 : `0${Today.getDate() + 1}`;

  const [date, setDate] = useState({
    ACTIVITY : {
      "year" : TodayYear,
      "month" : TodayMonth,
      "day" : TodayDay
    },
    PARTNER : {
      "year" : TodayYear,
      "month" : TodayMonth,
      "day" : TodayDay
    },
    PAYMENT : {
      "year" : TodayYear,
      "month" : TodayMonth,
      "day" : TodayDay
    },
    SEARCH : {
      "year" : TodayYear,
      "month" : TodayMonth,
      "day" : TodayDay
    }
  });

  useEffect(() => {
    const frm = new FormData();

    frm.append("mode", "rating");
    frm.append("token", localStorage.getItem("token"));
    frm.append("year", TodayYear);
    frm.append("month", TodayMonth);
    frm.append("day", TodayDay);

    axios.post("https://ai.semos.kr/semos_admin/board/data", frm, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      var Value = res.data;
      var Status = Value.status;
      var StatusMessage = Value.status_message;


      if(Status === 'success') {
        setData(Value.rating_list)

      } else if(Status === 'fail') {
        alert(StatusMessage)
        window.location.reload();
      } else if(Status === 'waring') {
        alert(StatusMessage)
        window.location.replace('/');
      } 
    });

    const frm2 = new FormData();

    frm2.append("mode", "value_update");
    frm2.append("token", localStorage.getItem("token"));
    frm2.append("year", "2022");
    frm2.append("month", "07");
    frm2.append("type", "wau");

    axios.post("https://ai.semos.kr/semos_admin/board/data", frm2, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      var Value = res.data;
      var Status = Value.status;
      var StatusMessage = Value.status_message;


      if(Status === 'success') {
        //setData(Value.rating_list)

      } 
    });

  }, []);

  const FilterHandler = (category, type, value) => {

    var dateOG = date[type];
    dateOG[category] = value;

    var listName = `${type}_COUNT`;

    const frm = new FormData();
    frm.append("mode", "rating_update");
    frm.append("token", localStorage.getItem("token"));
    frm.append("type", type);
    frm.append("year", dateOG.year);
    frm.append("month", dateOG.month);
    frm.append("day", dateOG.day);

    axios.post("https://ai.semos.kr/semos_admin/board/data", frm, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      var Value = res.data;
      var Status = Value.status;
      var StatusMessage = Value.status_message;
    
      if(Status === 'success') {
 
        setDate({...date, [type] : dateOG})
        setData({...data, [listName] : Value.rating_data})
      } else if(Status === 'fail') {
        alert(StatusMessage)
        window.location.reload();
      } else if(Status === 'waring') {
        alert(StatusMessage)
        window.location.replace('/');
      } 
    });


    var dateOG = date[type];
    dateOG[category] = value;

    setDate({
      ...date,
      [type] : dateOG
    })

    

  }

  return (
    <CardWrapper>
      <CardArea>

        <RatingContent>
          <div className="ratingHeader">
            <span className="ratingSubject font_24 weight_500">판매 순위</span>
            <div className="ratingFilter">
              <DropDown Handler={FilterHandler} year={date.PAYMENT.year} month={date.PAYMENT.month} type={"PAYMENT"} />
            </div>
          </div>

          <div className="ratingListContainer">
            {data.PAYMENT_COUNT.map((item, idx) => (
              <div key={`activity_${idx}`} className="ratingList">
                <span className="listSubject font_17 weight_400 color_black">
                  {`${idx+1}.`}
                  <span className="weight_400 color_light_black">{` [${item.partner_name}]` }</span> 
                  &nbsp;{ `${item.activity_name}`}
                </span>
                <span className="listValue weight_400 bg_blue_100">{item.payment_count}</span>
              </div>
            ))}
          </div>
        </RatingContent>

        <RatingContent>
          <div className="ratingHeader">
            <span className="ratingSubject font_24 weight_500">파트너 순위 <span className="font_17 weight_400 color_grey">(VIEW 기준)</span></span>
            <div className="ratingFilter">
              <DropDown Handler={FilterHandler} year={date.PARTNER.year} month={date.PARTNER.month} type={"PARTNER"} />
            </div>
          </div>

          <div className="ratingListContainer">
            {data.PARTNER_COUNT.map((item, idx) => (
              <div key={`partner_${idx}`} className="ratingList">
                <span className="listSubject font_17 weight_400">
                  {`${idx+1}.`}
                  <span className="weight_400 color_light_black">{` [${item.partner_sport}]`}</span> 
                  &nbsp;{`${item.partner_name}`}
                </span>
                <span className="listValue weight_400 bg_blue_100">{item.partner_count}</span>
              </div>
            ))}
          </div>
        </RatingContent>

        <RatingContent>
          <div className="ratingHeader">
            <span className="ratingSubject font_24 weight_500">액티비티 순위 <span className="font_17 weight_400 color_grey">(VIEW 기준)</span></span>
            <div className="ratingFilter">
              <DropDown Handler={FilterHandler} year={date.ACTIVITY.year} month={date.ACTIVITY.month} type={"ACTIVITY"} />
            </div>
          </div>

          <div className="ratingListContainer">
            {data.ACTIVITY_COUNT.map((item, idx) => (
              <div key={`activity_${idx}`} className="ratingList">
                <span className="listSubject font_17 weight_400 color_black">
                  {`${idx+1}.`}
                  <span className="weight_400 color_light_black">{` [${item.partner_name}]` }</span> 
                  &nbsp;{ `${item.activity_name}`}
                </span>
                <span className="listValue weight_400 bg_blue_100">{item.activity_count}</span>
              </div>
            ))}
          </div>
        </RatingContent>

        <RatingContent>
          <div className="ratingHeader">
            <span className="ratingSubject font_24 weight_500">검색어 순위</span>
            <div className="ratingFilter">
              <DropDown Handler={FilterHandler} year={date.SEARCH.year} month={date.SEARCH.month} type={"SEARCH"} />
            </div>
          </div>

          <div className="ratingListContainer">
            <div className="searchList" >
              {data.SEARCH_COUNT.slice(0, 20).map((item, idx) => (
                <div key={`search_${idx}`} className="ratingList">
                  <span className="listSubject font_17 weight_400 color_black">
                    {`${idx+1}. ${item.text}`}
                  </span>
                  <span className="listValue weight_400 bg_blue_100">{item.text_count}</span>
                </div>
              ))}
            </div>

            <div className="searchList" >
              {data.SEARCH_COUNT.slice(20, 41).map((item, idx) => (
                <div key={`search_${idx}`} className="ratingList">
                  <span className="listSubject font_17 weight_400 color_black">
                    {`${idx+21}. ${item.text}`}
                  </span>
                  <span className="listValue weight_400 bg_blue_100">{item.text_count}</span>
                </div>
              ))}
            </div>
          </div>
        </RatingContent>

      </CardArea>
    </CardWrapper>
  );

};

export default Rating;

// style
const CardWrapper = styled.div`
  width : 100%;
  display : inline-flex;
  align-items : center;
  justify-content: center;
  height : calc(100vh - 42px);
  background-color: #dedede;
`;

const CardArea = styled.div`
  width: 1340px;
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 1400px) {
    width: 1040px;
  }
`;

const RatingContent = styled.div`
  width : calc(50% - 10px);
  display: inline-flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  padding : 10px;
  border-radius : 8px;
  box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.16);
  margin-bottom : 20px;

  .ratingHeader {
    width : 100%;
    display : inline-flex;
    justify-content : space-between;
    margin-bottom : 16px;

    .ratingSubject {
      max-width : 40%;
      display : inline-block;
      overflow : hidden;
      white-space : nowrap;
    }

    .ratingFilter {
      max-width : 70%;
      display : inline-flex;
      align-items : center;
    }
  }

  .ratingListContainer { 
    width : 100%;
    display : inline-flex;
    flex-wrap : wrap;
    align-items: start;
    justify-content : space-between;
    height : 400px;
    overflow : hidden auto;
  }

  .searchList {
    width : calc(50% - 5px) !important;
  }

  .ratingList {
    width : 100%;
    display : inline-flex;
    justify-content : space-between;
    align-items : center;
    margin-bottom : 10px;
    border-bottom : 1px solid rgba(132, 140, 148, .1);
    padding : 3px 0 0 0;

    .listSubject { 
      width : 80%;
      display : inline-block;
      overflow : hidden;
      white-space : nowrap;
      text-overflow: ellipsis;
    }

    .listValue { 
      display : inline-flex;
      overflow : hidden;
      justify-content : end;
      padding : 5px;
      border-radius : 5px 7px;
      color : #ffffff;
    }
  }
`
