import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import colors from "../../styles/constants/colors";

const NewAnnounce = () => {
  const { n } = useParams();
  const navigate = useNavigate();

  const [item, setItem] = useState({
    title: "",
    content: "",
    image: "",
  });

  const saveHandler = () => {
    var token = window.localStorage.getItem("token");

    const frm = new FormData();

    frm.append("mode", "upload");
    frm.append("token", token);
    frm.append("content", item.content);
    frm.append("image", item.image);
    frm.append("title", item.title);
    frm.append("type", n.includes("partner") ? "partner" : "customer");

    axios
      .post("https://ai.semos.kr/semos_admin/announcement/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var status = Value.status;
        var statusMessage = Value.status_message;

        if (status == "success") {
          alert(statusMessage);
          // window.location.reload();
          navigate(`/announcement/1/all/customer/all`);

          return true;
        } else {
          alert(statusMessage);
          return false;
        }
      });
  };

  const setImagetoState = (result) => {
    setItem((prev) => ({ ...prev, image: result }));
    var item = document.getElementById("newannounce");
    item.value = "";
  };

  const setImageFromFile = ({ file, setImageUrl }) => {
    var uploadFileSize = file.size;
    var maxFileSize = 1024 * 1024 * 5;
    let reader = new FileReader();
    reader.onload = function () {
      setImageUrl({ result: reader.result });
    };
    reader.readAsDataURL(file);
  };

  return (
    <Container>
      <Title className="f24">세모스 공지</Title>
      <SmallTitle className="f16">공지 사진</SmallTitle>
      <ImageContainer>
        <BannerImage src={item.image} />
      </ImageContainer>
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        id="newannounce"
        onChange={({ target: { files } }) => {
          if (files.length) {
            setImageFromFile({
              file: files[0],
              setImageUrl: ({ result }) => {
                setImagetoState(result);
              },
            });
          }
        }}
      />
      <ImageAddLabel htmlFor="newannounce">이미지 등록하기</ImageAddLabel>

      <TitleSection>
        제목
        <InputContainer style={{ width: "95%" }}>
          <Input
            placeholder="제목을 작성해 주세요."
            value={item.title}
            onChange={(e) =>
              setItem((prev) => ({ ...prev, title: e.target.value }))
            }
          />
        </InputContainer>
      </TitleSection>

      <div style={{ height: "8px" }} />

      <InputContainer>
        <TextArea
          placeholder="내용을 작성해 주세요"
          value={item.content}
          rows={10}
          onChange={(e) =>
            setItem((prev) => ({ ...prev, content: e.target.value }))
          }
        />
      </InputContainer>

      <div style={{ height: "30px" }} />

      <SaveButton onClick={() => saveHandler()}>저장</SaveButton>
    </Container>
  );
};

export default NewAnnounce;

const Container = styled.div`
  padding: 36px;
  padding-bottom: 52px;
`;

const Title = styled.div`
  color: ${colors.Gr05};
  font-weight: 700;
  margin-bottom: 36px;
`;

const SmallTitle = styled.div`
  margin-bottom: 16px;
  color: ${colors.Gr05};
  font-weight: 700;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 500px;
  overflow: hidden;
  border: 1px solid ${colors.Gr02};
`;

const BannerImage = styled.img`
  width: 100%;
`;

const ImageAddLabel = styled.label`
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
  color: ${colors.Bl06};
  margin-top: 10px;
  margin-bottom: 56px;
  cursor: pointer;
`;

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
`;

const InputContainer = styled.div`
  padding: 8px 16px;
  border: 1px solid ${colors.Gr02};
  width: 100%;
`;

const Input = styled.input`
  color: ${colors.Gr05};
  width: 100%;
  font-weight: 500;
`;

const TextArea = styled.textarea`
  resize: none;
  outline: none;
  border: none;
  width: 100%;
`;

const SaveButton = styled.div`
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.Bl07};
  color: ${colors.White};
  font-weight: 700;
  border-radius: 8px;
  cursor: pointer;
`;
