import { Link, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import colors from "../../styles/constants/colors";
import typography from "../../styles/constants/typography";
import flexbox from "../../styles/func/flexbox";

const Nav = styled.nav`
  width: 200px;
  position: fixed;
  top: 72px;
  left: 225px;
  ul {
    width: 200px !important;
    display: inline-flex !important;
    justify-content: center !important;
    flex-wrap: wrap !important;
    border-radius: 8px !important;
    overflow: hidden !important;
    padding: 5px 0 !important;
    background-color: rgba(132, 140, 148, 0.5);

    li {
      width: 55% !important;
      ${flexbox("space-around")}
      font-size: ${typography.size.medium}px;
      font-weight: ${typography.weight.bold};
      height: auto !important;
    }
  }
`;

const NormalItem = styled.li`
  color: rgba(132, 140, 148, 1);
  border-bottom: unset;
  font-weight: 400;
`;

const RelativeContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ActiveItem = styled.li`
  border-bottom: 3px solid rgba(0, 149, 255, 0.5) !important;
  color: rgba(0, 149, 255, 1) !important;
  font-weight: 500 !important;
`;

const SLink = styled(Link)`
  width: 100% !important;
  padding: 13px 0 !important;
  display: flex !important;
  align-items: center !important;
  flex-direction: row !important;
  justify-content: center !important;
  height: auto !important;
`;

const Triangle = styled.div`
  width: 0px;
  height: 0px;
  border-bottom: 20px solid transparent;
  border-right: 20px solid rgba(132, 140, 148, 0.5);
  border-top-left-radius: 6px;
  position: absolute;
  top: 15px;
  left: 0;
  transform: translate(-100%, 0);
`;

const TabBar = ({ setShow }) => {
  const { pathname } = useLocation();

  return (
    <Nav>
      <RelativeContainer>
        <Triangle />

        <ul>
          {tabList.map((item, idx) =>
            pathname === item.path ? (
              <ActiveItem onClick={() => setShow((prev) => !prev)} key={idx}>
                <SLink to={item.path}>{item.text}</SLink>
              </ActiveItem>
            ) : (
              <NormalItem onClick={() => setShow((prev) => !prev)} key={idx}>
                <SLink to={item.path}>{item.text}</SLink>
              </NormalItem>
            )
          )}
        </ul>
      </RelativeContainer>
    </Nav>
  );
};

export default TabBar;

const tabList = [
  {
    path: "/dashboard",
    text: "지표",
  },
  {
    path: "/dashboard/rating",
    text: "순위",
  },
  {
    path: "/dashboard/data",
    text: "접속",
  },
  {
    path: "/dashboard/page",
    text: "페이지",
  },
  {
    path: "/dashboard/lesson-partner",
    text: "레슨/강사",
  },
];
