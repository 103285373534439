import { useState } from "react";
import styled from "styled-components";
import useInput from "../../hooks/useInput";
import Border from "../../modules/border";
import Select from "../../modules/select";
import typography from "../../styles/constants/typography";
import flexbox from "../../styles/func/flexbox";
import ModalLayout from "./layout";
import circleIcon from "../../assets/icon/circle.svg";
import circleActiveIcon from "../../assets/icon/circle-active.svg";

const USER_CLASSIFICATION = [
  {
    text: "전체 파트너 보기",
    value: "all",
  },
  {
    text: "활동 중인 파트너만 보기",
    value: "open",
  },
  {
    text: "활동을 중지한 파트너만 보기",
    value: "close",
  },
];

const FilterSection = styled.section``;

const Wrapper = styled.div`
  ${flexbox("space-between")}
  margin: 30px 0;

  p {
    font-weight: ${typography.weight.bold};
  }
`;

const ClassificationBox = styled.div`
  ul {
    li {
      padding: 20px 0;
      ${flexbox("space-between")};
      cursor: pointer;
    }
  }
`;


const FilterModal = ({
  value,
  //changeHandler,
  applyHandler,
  resetHandler,
  //activeHandler,
  closeHandler,
}) => {

  const [categoryValue, setCategoryValue] = useState("all");
  const [filterValue, setFilterValue] = useState("all");

  return (
    <ModalLayout
      title="아래 항목을 선택해 주세요."
      buttonText="적용하기"
      clickHandler={() => {applyHandler(categoryValue, filterValue)
        console.log(categoryValue)
        console.log(filterValue)}}
      closeHandler={closeHandler}
    >
      <FilterSection>
        <Wrapper>
          <p>필터</p>
          <button type="button" onClick={resetHandler}>
            초기화
          </button>
        </Wrapper>

        <Select
          name="category"
          value={value.category}
          changeHandler={(e) => {setCategoryValue(e.target.value)
          console.log(e.target.value)
          console.log(categoryValue)}}
          optionList={  [
            {
              text: "스쿠버다이빙",
              value: "스쿠버다이빙",
            },
            {
              text: "프리다이빙",
              value: "프리다이빙",
            },
            {
              text: "수영",
              value: "수영",
            },
            {
              text: "패들보드",
              value: "패들보드",
            },
            {
              text: "서핑",
              value: "서핑",
            },
            {
              text: "스키",
              value: "스키",
            },
            {
              text: "스노보드",
              value: "스노보드",
            },
          ]}
          placeholder="전체"
        />

        <Border />

        <ClassificationBox>
          <ul>
            {USER_CLASSIFICATION.map((item, idx) => (
              <li
                key={idx}
                onClick={() => setFilterValue(item.value)}
                active={filterValue === item.value ? "true" : "false"}
              >
                <p>{item.text}</p>

                <img
                  src={
                    filterValue === item.value ? circleActiveIcon : circleIcon
                  }
                />
              </li>
            ))}
          </ul>
        </ClassificationBox>
      </FilterSection>
    </ModalLayout>
  );
};

export default FilterModal;
