import { useState } from "react";

const useImage = (state) => {
  const [image, setImage] = useState(state);
  const [change, setChange] = useState(false);

  const changeHandler = (e) => {
    const { name, files } = e.target;

    var uploadFileSize = files.size;

    var maxFileSize = 1024 * 1024 * 5;

    if (uploadFileSize > maxFileSize) {
      alert(
        "업로드 가능한 이미지 최대 용량은 5MB입니다.\n이미지 용량을 확인해주세요"
      );
    } else {
      let reader = new FileReader();

      if (files[0]) {
        reader.readAsDataURL(files[0]);
      }

      reader.onloadend = () => {
        const previewImgUrl = reader.result;

        setImage((prev) => ({
          ...prev,
          [name]: {
            base: previewImgUrl,
            files,
          },
        }));

        setChange(true);
      };
    }
  };

  return { image, change, imageChangeHandler: changeHandler, setImage };
};

export default useImage;
