import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import { Wrapper } from "../../layouts/layoutStorage";
import NewEvent from "./newEvent";
import colors from "../../styles/constants/colors";

const EventDetail = () => {
  const [item, setItem] = useState({});
  const { n } = useParams();

  useEffect(() => {
    if (n !== "new") {
      var token = window.localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "detail");
      frm.append("token", token);
      frm.append("id", n);

      axios
        .post("https://ai.semos.kr/semos_admin/event/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            setItem(Value.data);
          } else if (status == "waring") {
            window.alert(statusMessage);
          }
        });
    }
  }, [n]);

  const setImageFromFile = ({ file, setImageUrl }) => {
    var uploadFileSize = file.size;
    var maxFileSize = 1024 * 1024 * 5;
    let reader = new FileReader();
    reader.onload = function () {
      setImageUrl({ result: reader.result });
    };
    reader.readAsDataURL(file);
  };

  const ratio = document.getElementById("ratio")?.offsetWidth;

  const saveHandler = () => {
    var token = window.localStorage.getItem("token");

    const frm = new FormData();

    frm.append("mode", "edit");
    frm.append("token", token);
    frm.append("id", item.id);
    frm.append("image", item.image);
    frm.append("content", item.content);
    frm.append("title", item.title);
    frm.append("thumbnail", item.thumbnail);

    axios
      .post("https://ai.semos.kr/semos_admin/event/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var status = Value.status;
        var statusMessage = Value.status_message;

        if (status == "success") {
          alert(statusMessage);
          window.location.reload();
          return true;
        } else {
          alert(statusMessage);
          return false;
        }
      });
  };

  return (
    <Wrapper
      bgc={colors.White}
      of={`hidden auto`}
      margin="10px 0 0 0"
      display={`inline-block`}
      width={`100%`}
      mw={`1000px`}
      mh={`calc(100vh - 72px)`}
      padding={`10px 0`}
    >
      {n === "new" ? (
        <NewEvent />
      ) : (
        <Container>
          <Title className="f24">이벤트 수정</Title>
          <ImageArea>
            <div style={{ width: "100%" }}>
              <SmallTitle className="f16">썸네일 이미지</SmallTitle>
              <ImageContainer width={ratio}>
                {item.thumbnail ? (
                  <BannerImage src={item.thumbnail} />
                ) : (
                  <NoImageBox>
                    <div>썸네일 이미지</div>
                    <div>8:5 비율 / 5MB 이하 / JPG</div>
                  </NoImageBox>
                )}
              </ImageContainer>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="eventthumb"
                onChange={({ target: { files } }) => {
                  if (files.length) {
                    setImageFromFile({
                      file: files[0],
                      setImageUrl: ({ result }) => {
                        setItem((prev) => ({ ...prev, thumbnail: result }));
                      },
                    });
                  }
                }}
              />
              <ImageAddLabel htmlFor="eventthumb">
                이미지 등록하기
              </ImageAddLabel>
            </div>
            <div style={{ width: "100%" }}>
              <SmallTitle className="f16">콘텐츠 이미지</SmallTitle>
              <ImageContainer width={ratio}>
                {item.image ? (
                  <BannerImage src={item.image} />
                ) : (
                  <NoImageBox>
                    <div>컨텐츠 이미지</div>
                    <div>5MB 이하 / JPG</div>
                  </NoImageBox>
                )}
              </ImageContainer>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="eventcontent"
                onChange={({ target: { files } }) => {
                  if (files.length) {
                    setImageFromFile({
                      file: files[0],
                      setImageUrl: ({ result }) => {
                        setItem((prev) => ({ ...prev, image: result }));
                      },
                    });
                  }
                }}
              />
              <ImageAddLabel htmlFor="eventcontent">
                이미지 등록하기
              </ImageAddLabel>
            </div>
          </ImageArea>

          <TitleSection>
            제목
            <InputContainer style={{ width: "95%" }}>
              <Input
                placeholder="제목을 작성해 주세요."
                value={item.title}
                onChange={(e) =>
                  setItem((prev) => ({ ...prev, title: e.target.value }))
                }
              />
            </InputContainer>
          </TitleSection>

          <div style={{ height: "8px" }} />

          <InputContainer>
            <TextArea
              placeholder="내용을 작성해 주세요"
              value={item.content}
              rows={10}
              onChange={(e) =>
                setItem((prev) => ({ ...prev, content: e.target.value }))
              }
            />
          </InputContainer>

          <div style={{ height: "30px" }} />

          <SaveButton onClick={() => saveHandler()}>저장</SaveButton>
        </Container>
      )}
    </Wrapper>
  );
};

export default EventDetail;

const Container = styled.div`
  padding: 36px;
  padding-bottom: 52px;
`;

const Title = styled.div`
  color: ${colors.Gr05};
  font-weight: 700;
  margin-bottom: 36px;
`;

const SmallTitle = styled.div`
  margin-bottom: 16px;
  color: ${colors.Gr05};
  font-weight: 700;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: ${({ width }) => width && (width * 5) / 8}px;
  overflow: hidden;
  border: 1px solid ${colors.Gr02};
`;

const BannerImage = styled.img`
  width: 100%;
`;

const ImageArea = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-direction: column;
`;

const ImageAddLabel = styled.label`
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
  color: ${colors.Bl06};
  margin-top: 10px;
  margin-bottom: 56px;
  cursor: pointer;
`;

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
`;

const InputContainer = styled.div`
  padding: 8px 16px;
  border: 1px solid ${colors.Gr02};
  width: 100%;
`;

const Input = styled.input`
  color: ${colors.Gr05};
  width: 100%;
  font-weight: 500;
`;

const TextArea = styled.textarea`
  resize: none;
  outline: none;
  border: none;
  width: 100%;
`;

const SaveButton = styled.div`
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.Bl07};
  color: ${colors.White};
  font-weight: 700;
  border-radius: 8px;
  cursor: pointer;
`;

const NoImageBox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.Gr01};
  color: ${colors.Gr03};
`;
