import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Wrapper, WrapperColmun, Text, Icon, AbsoluteWrapper } from "../../layouts/layoutStorage";
import { Link } from "react-router-dom";

import DesignedMainHeader from "../../components/designedHeader";
import axios from "axios";
import ToggleButton from "../../modules/toggleButton";

import colors from "../../styles/constants/colors";

import { ReactComponent as Plus } from "../../assets/icon/plus.svg";
import CameraAdd from "../../assets/icon/whiteCameraAdd.png";

const Bulletin = () => {
  const [bulletin, setBulletin] = useState(["", "", "", "", ""]);
  const [boxSize, setBoxSize] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setBoxSize(document.getElementById("banner")?.offsetWidth / 5);
    }, 500);
  }, [boxSize]);

  const getData = () => {
    const token = localStorage.getItem("token");
    const frm = new FormData();

    frm.append("mode", "list");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_admin/event/bulletin", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        setBulletin(data.data.list);
      });
  };

  const deleteData = (id) => {
    const token = localStorage.getItem("token");
    const frm = new FormData();

    frm.append("mode", "delete");
    frm.append("id", id);
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_admin/event/bulletin", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        setBulletin(data.data.list);
      });
  };

  const changeStatus = (id) => {
    const token = localStorage.getItem("token");
    const frm = new FormData();

    frm.append("mode", "change");
    frm.append("id", id);
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_admin/event/bulletin", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        setBulletin(data.data.list);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Wrapper bgc={colors.Gr01} of={`hidden auto`} height="100%" display={`inline-block`} width={`100%`} mh={`calc(100vh - 48px)`}>
      <Container>
        <DesignedMainHeader title="게시글 관리" />

        <BannerMenuConatiner>
          <BannerMenu className="f16" active={true}>
            게시글
          </BannerMenu>
        </BannerMenuConatiner>

        <Wrapper width="100%" id="banner" of="auto">
          {boxSize > 0 &&
            bulletin.map((item, index) =>
              item.title ? (
                <Wrapper
                  key={item.id}
                  width={`${boxSize}px`}
                  height={`${boxSize}px`}
                  style={{ minWidth: "250px", minHeight: "250px" }}
                  padding="12px"
                  bgc={colors.White}
                  jc="center"
                  ai="center"
                  bl={index && `2px solid ${colors.Gr01}`}
                >
                  <WrapperColmun width="100%" height="100%" padding="0 8.5px 6px 8.5px" ai="center">
                    <Wrapper width="100%" jc="flex-end">
                      <ToggleButton type="bulletin" status={item.status} toggleHandler={() => changeStatus(index + 1)} />
                    </Wrapper>

                    <Wrapper height="12px" />

                    <Wrapper width="178px" height="135px" radius="8px" position="relative">
                      <Icon width="100%" height="100%" src={item.thumbnail} radius="8px" />
                      <AbsoluteWrapper width="100%" height="100%" bgc={colors.B50} radius="8px" cursor="pointer">
                        <Link to={`/bulletin_detail/${index + 1}`} style={{ width: "100%", height: "100%" }}>
                          <WrapperColmun width="100%" height="100%" jc="center" ai="center">
                            <Icon src={CameraAdd} width="40px" height="40px" />

                            <Wrapper height="8px" />

                            <Text className="f12" weight="500" color={colors.White}>
                              사진 편집하기
                            </Text>
                          </WrapperColmun>
                        </Link>
                      </AbsoluteWrapper>
                    </Wrapper>

                    <Wrapper height="24px" />

                    <Wrapper jc="space-between" width="100%">
                      <Text className="f14" weight="600" color={colors.Black}>
                        배너 명
                      </Text>
                      <Text className="f14" weight="500" color={colors.Gr03}>
                        {item.title}
                      </Text>
                    </Wrapper>

                    <Wrapper height="8px" />

                    <Wrapper jc="flex-end" width="100%">
                      <Text className="f14" weight="500" color={colors.Gr04} style={{ cursor: "pointer" }} onClick={() => deleteData(index + 1)}>
                        삭제하기
                      </Text>
                    </Wrapper>
                  </WrapperColmun>
                </Wrapper>
              ) : (
                <Wrapper
                  key={item.id}
                  width={`${boxSize}px`}
                  height={`${boxSize}px`}
                  style={{ minWidth: "250px", minHeight: "250px" }}
                  padding="12px"
                  bgc={colors.White}
                  jc="center"
                  ai="center"
                  cursor="pointer"
                  onClick={() => window.open(`/bulletin_detail/${index + 1}`)}
                  bl={index && `2px solid ${colors.Gr01}`}
                >
                  <WrapperColmun jc="center" ai="center" width="100%" height="100%">
                    <Plus width="40px" height="40px" fill={colors.Gr03} />
                    <Wrapper height="8px" />
                    <Text className="f12" weight="500" color={colors.Gr03}>
                      게시글 등록
                    </Text>
                  </WrapperColmun>
                </Wrapper>
              )
            )}
        </Wrapper>
      </Container>
    </Wrapper>
  );
};

export default Bulletin;

const Container = styled.div`
  padding: 28px 36px 0 36px;
  width: 100%;
  /* height: calc(100vh - 217px); */
  height: 100%;
`;

const BannerMenuConatiner = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
`;

const BannerMenu = styled.div`
  padding: 12px 20px;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: ${({ active }) => (active ? colors.Bl06 : colors.Gr05)};
  margin-right: 38px;
  border-bottom: ${({ active }) => (active ? `1px solid ${colors.Bl06}` : "none")};
  cursor: pointer;
`;
