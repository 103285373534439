import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

// components
import DesignedMainLayout from "../../layouts/designedMainLayout";
import {
  Wrapper,
  AbsoluteWrapper,
  Icon,
  Text,
  WrapperColmun,
  WrapperRow,
} from "../../layouts/layoutStorage";
import Button from "../../modules/button";
import colors from "../../styles/constants/colors";

// icon
import CancelIcon from "../../assets/icon/cancelIcon.png";
import SearchIcon from "../../assets/icon/search.png";

const LIST = [
  {
    title: "세모스 ONLY TOUR",
    page: "PBTourList",
    how: "/PBTourList",
  },
  {
    title: "세모스 ONLY LESSON",
    page: "PBLessonList",
    how: "/PBLessonList",
  },
  {
    title: "기획전",
    page: "Event",
    how: "/Event/{랜딩시키고 싶은 기획전 id값}",
  },
];

const PushParams = {
  ActivityList: ['ST', 'sport', 'type', 'where', 'start_date', 'end_date', 'duration', 'sort', 'price' ],
  ActivityCategory: [],
  PBLessonList: [],
  PBActivityDetail: ['service_id'],
  Profile: [],
  ActivityDetail: ['service_id'],
  PartnerDetail: ['service_coach'],
  ContentsDetail: ["id"],
};

const PushParamsDescription = {
  ActivityList: ['검색어', '스포츠 카테고리', '레자이투', '지역', '시작일', '종료일', '원데이/다회차', '정렬', '가격' ],
  ActivityCategory: [],
  PBLessonList: [],
  PBActivityDetail: ['액티비티 테이블 id'],
  Profile: [],
  ActivityDetail: ['액티비티 테이블 id'],
  PartnerDetail: ['센터 테이블 id'],
  ContentsDetail: ["콘텐츠 테이블 id"],
}

const Main = styled.main`
  width: 100%;
  height: calc(100% - 48px);
  display: flex;

  ul {
    width: 100%;
  }
`;

const Layout = styled.div`
  min-width: 646px;

  width: 50%;
  max-width: 1000px;
  height: 100%;
  position: relative;
`;

const BottomButtonBox = styled.div`
  position: absolute;
  bottom: 52px;
  right: 20px;

  button:first-child {
    margin-right: 17px;
  }
`;

const PaymentCXBox = styled.div`
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  .cxContentBox {
    width: 100%;

    display: flex;
    align-items: top;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 9px;

    p:first-child {
      color: "#979797";
    }
    .cxButtonBox {
      width: calc(100% - 8rem);
      display: flex;
      justify-content: space-between;
      gap: 12px;
    }
    .csSerchBox {
      position: relative;
    }
    .cxContent {
      width: calc(100% - 8rem);
      display: inline-block;

      .cxType {
        padding: 10px 12px;
        display: inline-block;
        border: 3px solid rgba(132, 140, 148, 1);
        cursor: pointer;
        font-size: 17px;
        font-weight: 500;
      }
      .cxType:first-child {
        margin-right: 15px;
      }
      .typeActive {
        border: 3px solid rgba(0, 149, 255, 1);
      }
      .cxManeger {
        width: 100%;
        display: inline-block;

        padding: 10px 12px;
        border: 1px solid #dcdcdc;
        background-color: #ffff;
      }
      .csSerch {
        display: inline-flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        justify-content: space-between;
        align-items: center;
        input {
          width: 100%;
        }
        display: flex;
        flex-direction: row;

        justify-content: space-between;
      }
      .cxMessage {
        width: 100%;
        display: inline-block;
        padding: 10px 12px;
        border: 1px solid rgba(132, 140, 148, 1);
        font-size: 17px;
        font-weight: 500;
        background-color: #f9f9f9;
        height: 300px;
        resize: none;
      }
    }
    .cxContentBottom {
      width: 100% ;
      display: inline-flex;
      overflow : auto hidden;
    }
    .cxContentBottom2 {
      width: 100% ;
      display: inline-block;
    }
  }
  .width100 {
    width : 100%;
  }
`;

const SelectBox = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  padding: 14px 18px 16px 18px;
  background-color: #ffff;
  border-bottom: 1px solid #dcdcdc;
  :hover {
    background-color: #f2f7ff;
  }
`;

const Header = styled.header`
  display: flex;
`;

const Div = styled.div`
  border-bottom: ${({ all }) => (all ? `1px solid #0E6DFF` : "none")};
  color: ${({ all }) => (all ? `#0E6DFF` : `#000`)};
  flex-shrink: 0;
  cursor: pointer;
  padding: 10px;
  margin-bottom: 36px;
`;

const PageListBox = styled.div`
  width: 40%;
  margin-left: 100px;
  border: 1px solid;
  border-radius: 16px;
  padding: 20px;
`;

const PageSelect = styled.div`
  margin-right: 8px;
  border: 1px solid ${({ active }) => (active ? `${colors.Bl07}` : `${colors.Gr02}`)};
  border-radius: 4px;
  padding: 6px 12px;
  display : inline-flex;
  cursor : pointer;
  margin-bottom : 8px;
  color : ${colors.Gr05};
  background-color : ${({ active }) => (active ? `${colors.Bl02}` : `${colors.White}`)};
`;

const PageInput = styled.div`
  width: 100%;
  position : relative;
  margin-bottom : 8px;
  
  input {
    width: 100%;
    border: 1px solid ${colors.Gr02};
    border-radius : 8px;
    padding : 11px 8px;
    background-color : ${colors.White};
    padding-left : 100px;
  }

  .key_text {
    display: inline-block;
    position : absolute;
    top : 50%;
    left : 8px;
    translate : 0 -50%;
  }
`;

const SendMessage = () => {
  const [all, setAll] = useState(true);

  // 개별 푸시 전송 (all = false)
  const [partner, setPartner] = useState("");
  const [oneManager, setOneManager] = useState("Semoring");
  const [oneTitle, setOneTitle] = useState("");
  const [oneContent, setOneContent] = useState("");
  const [link, setLink] = useState("");

  // 전체 푸시 전송 (all = true)
  const [allManager, setAllManager] = useState("Semoring");
  const [allTitle, setAllTitle] = useState("");
  const [allDate, setAllDate] = useState("");
  const [allPage, setAllPage] = useState("");
  const [allParams, setAllParams] = useState({});
  const [allContent, setAllContent] = useState("");
  const [allLanding, setAllLanding] = useState("");

  const allContentReset = () => {
    if (window.confirm("작성한 내용을 초기화 하시겠습니까?")) {
      setAllManager("Semoring");
      setAllTitle("");
      setAllContent("");
    }
  };

  const oneContentReset = () => {
    if (window.confirm("작성한 내용을 초기화 하시겠습니까?")) {
      setOneManager("Semoring");
      setOneTitle("");
      setLink("");
      setPartner("");
      setOneContent("");
      setSelectedPartner({
        realName: "",
        phone: "",
        nickname: "",
      });
    }
  };

  const [searchData, setSearchData] = useState([]);
  const [searching, setSearching] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState({
    realName: "",
    phone: "",
    nickname: "",
  });

  const search = () => {
    setSearching(true);
    var token = window.localStorage.getItem("token");
    const frm = new FormData();
    frm.append("mode", "search");
    frm.append("token", token);
    frm.append("text", partner);

    axios
      .post("https://ai.semos.kr/semos_admin/user/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        setSearchData(Value.search_list);
      });
  };

  const selectPartner = (data) => {
    setSelectedPartner({
      realName: data.real_name,
      phone: data.phone,
      nickname: data.user_name,
      device_token: data.token,
      id: data.id,
    });
    setSearching(false);
    setSearchData([]);
  };

  const messageSend = () => {
    var token = window.localStorage.getItem("token");
    const frm = new FormData();
    if (all) {
      if (allManager && allTitle && allContent) {
        if (window.confirm("푸시알림을 보내시겠습니까?")) {
          frm.append("mode", "push_notify_all");
          frm.append("token", token);
          frm.append("title", allTitle);
          frm.append("message", allContent);
          frm.append("target", allLanding);
        }

        axios
          .post("https://ai.semos.kr/semos_admin/user/send", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((data) => {
            var Value = data.data;
            var status = Value.status;
            var statusMessage = Value.status_message;

            if (status == "success") {
              alert(statusMessage);
            } else if (status == "waring") {
              alert(statusMessage);
            } else if (status == "fail") {
              alert(statusMessage);
            }
          });
      }
    } else {
      if (partner && oneTitle && oneContent) {
        if (window.confirm("푸시알림을 보내시겠습니까?")) {
          frm.append("mode", "push_notify");
          frm.append("token", token);
          frm.append("target_token", selectedPartner.device_token);
          frm.append("target_user", selectedPartner.id);
          frm.append("target_nickname", selectedPartner.nickname);
          frm.append("title", oneTitle);
          frm.append("message", oneContent);

          axios
            .post("https://ai.semos.kr/semos_admin/user/send", frm, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then((data) => {
              var Value = data.data;
              var status = Value.status;
              var statusMessage = Value.status_message;

              if (status == "success") {
                alert(statusMessage);
              } else if (status == "waring") {
                alert(statusMessage);
              } else if (status == "fail") {
                alert(statusMessage);
              }
            });
        }
      }
    }
  };

  const SetParams = (key) => { 
    setAllPage(key)

    const ParamsList = PushParams[key];
    let ParamsObject = {};

    for(let i=0; i < ParamsList.length; i++) {
      let Key = ParamsList[i];
      ParamsObject[Key] = "";
    }

    setAllParams(ParamsObject)
  }

  const setParamsData = ( value, key ) => {
    setAllParams((prev) => ({ ...prev, [key]: value }));
  }


  const SendPush = () => {

    if(allParams && allPage) {
      
    }

  }

  return (
    <DesignedMainLayout>
      <Header>
        <Div
          all={!all}
          onClick={() => {
            setAll(false);
          }}
        >
          <p className="f16 weight_700">개별 푸시 전송</p>
        </Div>

        <Div
          all={all}
          onClick={() => {
            setAll(true);
          }}
        >
          <p className="f16 weight_700">전체 푸시 전송</p>
        </Div>
      </Header>
      <Main>
        <Layout>
          {!all ? (
            <section>
              <h1 className="f24 weight_700">개별 푸시 알림 정보</h1>

              <PaymentCXBox>
                <div className="cxContentBox">
                  <p className="f14">유저 검색</p>
                  <span className="cxContent">
                    <div
                      style={
                        searching || selectedPartner.realName
                          ? { border: "1px solid #818792" }
                          : {}
                      }
                      className="cxManeger csSerch"
                    >
                      {selectedPartner.realName ? (
                        <>
                          <Text
                            style={{
                              whiteSpace: "pre",
                            }}
                            color="#3C3E41"
                          >
                            {selectedPartner.realName}
                            <Text color="#dcdcdc"> | </Text>
                            {selectedPartner.phone
                              ? selectedPartner.phone
                              : "없음"}
                            <Text color="#dcdcdc"> | </Text>
                            {selectedPartner.nickname}
                          </Text>
                          <Icon
                            width={`calc(1rem * (12 / 14))`}
                            cursor={`pointer`}
                            src={CancelIcon}
                            onClick={() => {
                              setSelectedPartner({
                                realName: "",
                                phone: "",
                                nickname: "",
                              });
                              setPartner("");
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <input
                            autoFocus
                            onChange={(e) => setPartner(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") search();
                            }}
                            value={partner}
                            type="text"
                            maxLength={20}
                            className="f14"
                            placeholder="닉네임, 전화번호, 실명으로 검색"
                          />

                          <Icon
                            width={`calc(1rem * (17 / 14))`}
                            cursor={`pointer`}
                            src={SearchIcon}
                            onClick={search}
                          />
                        </>
                      )}
                    </div>
                  </span>
                </div>

                <div className="cxContentBox">
                  <p className="f14"> 담당자명</p>
                  <span className="cxContent csSerchBox">
                    <input
                      type="text"
                      maxLength={20}
                      value={oneManager}
                      onChange={(e) => setOneManager(e.target.value)}
                      className="cxManeger f14"
                      placeholder="담당자명을 입력해 주세요!"
                    />
                    {partner && searching ? (
                      <AbsoluteWrapper width="100%" top="0" left="0">
                        <WrapperColmun
                          width="100%"
                          border="1px solid #dcdcdc"
                          style={{
                            borderBottom: "1px solid transparent",
                            overflow: "auto",
                            maxHeight: "500px",
                          }}
                        >
                          {searchData.map((item, idx) => (
                            <SelectBox
                              key={idx}
                              onClick={() => selectPartner(item)}
                            >
                              <Wrapper className="f14">
                                {item.real_name}
                              </Wrapper>
                              <WrapperRow gap="4px">
                                <Text color="#818792" className="f12">
                                  전화번호
                                </Text>
                                <Text className="f12">
                                  {item.phone ? item.phone : "없음"}
                                </Text>
                                <Text color="#818792" className="f12">
                                  닉네임
                                </Text>
                                <Text className="f12">{item.user_name}</Text>
                              </WrapperRow>
                            </SelectBox>
                          ))}
                        </WrapperColmun>
                      </AbsoluteWrapper>
                    ) : (
                      <></>
                    )}
                  </span>
                </div>

                <div className="cxContentBox">
                  <p className="f14">푸시 알림 제목</p>
                  <span className="cxContent">
                    <input
                      type="text"
                      maxLength={50}
                      value={oneTitle}
                      onChange={(e) => setOneTitle(e.target.value)}
                      className="cxManeger f14"
                      placeholder="푸시 알림 제목을 적어주세요 *최대 50글자"
                    />
                  </span>
                </div>

                <div className="cxContentBox">
                  <p
                    className="f14"
                    style={{
                      alignSelf: "self-start",
                      marginTop: "1rem",
                    }}
                  >
                    푸시 알림 내용
                  </p>
                  <span className="cxContent">
                    <textarea
                      style={{
                        height: "200px",
                        width: "100%",
                        resize: "none",
                      }}
                      type="text"
                      value={oneContent}
                      onChange={(e) => setOneContent(e.target.value)}
                      className="cxManeger f14"
                      maxLength={200}
                      placeholder="푸시 알림 내용을 적어주세요 *최대 200글자"
                    />
                  </span>
                </div>

                <Wrapper
                  color="#FF9187"
                  margin="0 5px"
                  cursor="pointer"
                  jc="end"
                  onClick={oneContentReset}
                >
                  <p style={{ color: "#FF9187" }} className="f14">
                    초기화
                  </p>
                </Wrapper>
              </PaymentCXBox>
            </section>
          ) : (
            <section>
              <h1 className="f24 weight_700">전체 푸시 알림 정보</h1>

              <PaymentCXBox>
                <div className="cxContentBox">
                  <p className="f14"> 담당자 명</p>
                  <span className="cxContent">
                    <input
                      type="text"
                      maxLength={20}
                      value={allManager}
                      onChange={(e) => setAllManager(e.target.value)}
                      className="cxManeger"
                      placeholder="담당자명을 입력해 주세요!"
                    />
                  </span>
                </div>

                <div className="cxContentBox">
                  <p className="f14">푸시 알림 제목</p>
                  <span className="cxContent">
                    <input
                      type="text"
                      maxLength={50}
                      value={allTitle}
                      onChange={(e) => setAllTitle(e.target.value)}
                      className="cxManeger"
                      placeholder="푸시 알림 제목을 적어주세요 *최대 50글자"
                    />
                  </span>
                </div>

                <div className="cxContentBox">
                  <p
                    className="f14"
                    style={{
                      alignSelf: "self-start",
                      marginTop: "1rem",
                    }}
                  >
                    푸시 알림 내용
                  </p>

                  <span className="cxContent">
                    <textarea
                      style={{
                        height: "200px",
                        width: "100%",
                        resize: "none",
                      }}
                      type="text"
                      value={allContent}
                      onChange={(e) => setAllContent(e.target.value)}
                      className="cxManeger f14"
                      maxLength={200}
                      placeholder="푸시 알림 내용을 적어주세요 *최대 200글자"
                    />
                  </span>
                </div>

                <div className="cxContentBox">
                  <p className="f14">푸시 발송 날짜</p>
                  <span className="cxContent">
                    <input
                      type="text"
                      maxLength={50}
                      value={allDate}
                      onChange={(e) => setAllDate(e.target.value)}
                      className="cxManeger"
                      placeholder="YYYY.MM.DD HH:00"
                    />
                  </span>
                </div>
                
                <div style={{flexWrap:'wrap'}} className="cxContentBox">
                  <p style={{marginBottom : '8px'}} className="f14">랜딩페이지 연결</p>
                  <span className="cxContentBottom">
                    {
                      Object.keys(PushParams).map((item) => (
                        <PageSelect active={item == allPage} key={item} onClick={() => SetParams(item)}>
                          <p>{item}</p>
                        </PageSelect>
                      ))
                    }
                  </span>

                  <span className="cxContentBottom2">
                    {allPage && (
                        PushParams[allPage] &&
                          PushParams[allPage].map((item, idx) => (
                            <PageInput key={item}>
                              <input value={allParams[item]} onChange={(e) => setParamsData(e.target.value, item)} placeholder={PushParamsDescription[allPage][idx]} id={item} />
                              <span className="key_text" >{`${item} :`}</span>
                            </PageInput>
                          ))
                      )
                    }
                  </span>
                </div>

                <Wrapper
                  color="#FF9187"
                  margin="0 5px"
                  cursor="pointer"
                  jc="end"
                  onClick={allContentReset}
                >
                  <p style={{ color: "#FF9187" }} className="f14">
                    초기화
                  </p>
                </Wrapper>
              </PaymentCXBox>
            </section>
          )}
          <BottomButtonBox>
            <Button
              text="전송"
              clickHandler={messageSend}
              width={`166px`}
              className="f14"
            />
          </BottomButtonBox>
        </Layout>

        {all && (
          <PageListBox>
            <Wrapper width="100%" jc="center" margin="0 0 40px 0">
              <Text
                display="inline-block"
                weight="700"
                size="24px"
                color="#3c3e41"
              >
                푸시 알림 가이드 리스트
              </Text>
            </Wrapper>

            {LIST.map((item) => (
              <Wrapper
                cursor="pointer"
                width="100%"
                margin="0 0 24px 0"
                onClick={() => setAllLanding(item.how)}
              >
                <Text weight="500" size="20px" color="#3c3e41">
                  {`${item.title}: 연결칸에 ${item.how}의 형태로 만드세요`}
                </Text>
              </Wrapper>
            ))}
          </PageListBox>
        )}
      </Main>
    </DesignedMainLayout>
  );
};

export default SendMessage;
