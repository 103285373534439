import { createGlobalStyle } from "styled-components";
import normalize from "styled-normalize";
import base from "./constants/base";
import colors from "./constants/colors";
import fonts from "./constants/fonts";
import typography from "./constants/typography";

const GlobalStyles = createGlobalStyle`
    ${normalize};
    
    /* Additional Customizing reset CSS */
    * {
        margin: 0;
        font-family: "Pretendard",  BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        box-sizing: border-box;
    }

    html {
        font-family:  "Pretendard", BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', "GmarketSans", sans-serif; 
        font-size: 65.625%;
    
        @media screen and (min-width: 1920px) {
            font-size: 87.5% !important;
            /* font-size: 110% !important; */
        }

        @media screen and (min-width: 1920px) {
          font-size: 109.375% !important;
          /* font-size: 110% !important; */
        }

         @media screen and (min-width: 2400px) {
          font-size: 131.25% !important;
          /* font-size: 200% !important; */

        } 

       @media screen and (min-width: 3360px) {
          font-size: 153.125% !important;
          /* font-size: 200% !important; */
        } 


    body {
        min-height: 100vh;
        width: 100vw;
        max-width: 100vw;
        font-family: "Pretendard", BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', "GmarketSans", sans-serif;
        color: ${colors.lightBlack};
        background-color: white;

        /* -webkit-user-select: none !important;
        -moz-user-select: -moz-none !important;
        -ms-user-select: none !important;
        user-select: none !important; */

        overflow-x: hidden;
        /* overflow-y: auto; */

    }

    .slick-prev, .slick-next { 
      top : 0 !important;
      width : 40px;
      height : 100%;
      transform : unset !important;
      padding : 0 10px;
      z-index : 100;
    }

    .slick-next { 
      right : 0 !important;
    }

    .slick-prev { 
      left : 0 !important;
    }

    h1 {
        margin: 0;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    button,
    input,
    select,
    textarea {
        background-color: transparent;
        border: 0;
    }

    button:focus,
    input:focus,
    select:focus,
    textarea:focus {
        outline: none;
        box-shadow: none;
    }

    a,
    button {
        cursor: pointer;
    }
    
    button {
        padding: 0;
    }

    ul,
    ol {
        padding-left: 0;
        list-style: none;
    }

    address {
        all: unset;
    }

    fieldset {
        all: unset;
    }

    input, textarea {
      outline : none !important;
    }

    input::placeholder, textarea::placeholder {
      color: #ACAFB5;
    }

    /* custom style */
    input, select {
        /*height: ${base.height.input}px;
        background-color: ${colors.white};
        border: 2px solid ${colors.lightGray};
        border-radius: ${base.borderRadius.default}px;
        padding: 10px 20px; */
    }

    .select_mini {
        padding: 10px !important; 
    }

    /*input:focus,*/
    select:focus {
        border: 2px solid ${colors.blue};
    }

    select {
        -webkit-appearance: none; /* for chrome */
        -moz-appearance: none; /*for firefox*/
        appearance: none;
    }

    select::-ms-expand {
      display: none; /*for IE10,11*/
    }

    .ql-toolbar:first-child {
  display: none !important;
    }
    }
`;

export default GlobalStyles;
