import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

// component
import { Text, Wrapper } from "../../layouts/layoutStorage";
import ActivityNormalInfo from "../../components/designedActivity/activityNormalInfo";
import ActivityPriceInfo from "../../components/designedActivity/activityPriceInfo";
import ActivityIntro from "../../components/designedActivity/activityIntro";
import ActivityKeyword from "../../components/designedActivity/activityKeyword";
import ImageCrop from "../../components/designedComponents/imageCrop";
import colors from "../../styles/constants/colors";

// icon

const ActivityDetail = ({ open }) => {
  const { n } = useParams();

  const navigate = useNavigate();

  //이미지 크롭 함수
  const [cropImage, setCropImage] = useState({
    width: 8,
    height: 5,
    type: "",
    number: "",
    image: "",
    status: false,
    scale: true,
  });

  const [loading, setLoading] = useState(true);

  const setImageFromFile = ({ file, setImageUrl }) => {
    let reader = new FileReader();
    reader.onload = function () {
      setImageUrl({ result: reader.result });
    };

    reader.readAsDataURL(file);
  };

  const ImgChange = (value, type, number) => {
    setCropImage({
      width: 8,
      height: 5,
      type: type,
      number: number,
      image: value,
      status: true,
      scale: true,
    });
  };

  useEffect(() => {
    if (n == "new") {
      setInfo({
        activity_id: "",
        background: [],
        caution: [],
        center: "",
        center_id: "",
        curriculum: {
          day1: [],
        },
        duration: "원데이",
        exclude: [],
        exposed: [],
        facility_list: {
          parking: 0,
          cafeteria: 0,
          shower_room: 0,
          fitting_room: 0,
        },
        faq: [],
        full_package: false,
        include: [],
        unique: [],
        info: "",
        max_capacity: "",
        min_age: "",
        min_capacity: "",
        name: "",
        partner: [],
        partner_id: "",
        place: [],
        prepare: [],
        price: [],
        event_price: [],
        qualified: [],
        recommend: [],
        search: [],
        semos_only: false,
        sport: [],
        status: "",
        type: "",
        updated: "",
        lesson_day: "1",
      });
    } else {
      getData();
    }
  }, []);

  const [info, setInfo] = useState([]);

  const [recommendSearch, setRecommendSearch] = useState([]);
  const [uniqueSearch, setUniqueSearch] = useState([]);
  const [requireSearch, setRequireSearch] = useState([]);
  const [bringSearch, setBringSearch] = useState([]);
  const [includeSearch, setIncludeSearch] = useState([]);
  const [excludeSearch, setExcludeSearch] = useState([]);
  const [faqSearch, setFaqSearch] = useState([]);
  const [noticeSearch, setNoticeSearch] = useState([]);
  const [tagSearch, setTagSearch] = useState([]);
  const [keywordSearch, setKeywordSearch] = useState([]);
  const [deleteD, setDeleteD] = useState({
    place: [],
    background: [],
    caution: [],
    activity_location: [],
    qualified: [],
    curriculum: [],
    price: [],
    // facility_list: [],
    faq: [],
    exclude: [],
    include: [],
    exposed: [],
    unique: [],
    recommend: [],
    search: [],
    sport: [],
    prepare: [],
    partner: [],

    sport_copy : [],
    curriculum_copy : [],
    price_copy : [],
    include_copy : [],
    exclude_copy : [],
    prepare_copy : []
  });

  const BigDataSearch = (category, value) => {
    var token = localStorage.getItem("token");

    const frm = new FormData();

    frm.append("mode", "register_recommend");
    frm.append("token", token);
    frm.append("category", category);
    frm.append("text", value);

    axios
      .post("https://ai.semos.kr/semos_partner/coaching/activity", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;
        var RegisterText = Value.text_list;

        if (Status == "success") {
          if (category == "recommend") {
            setRecommendSearch(RegisterText);
          } else if (category == "qualified") {
            setRequireSearch(RegisterText);
          } else if (category == "prepare") {
            setBringSearch(RegisterText);
          } else if (category == "include") {
            setIncludeSearch(RegisterText);
          } else if (category == "exclude") {
            setExcludeSearch(RegisterText);
          } else if (category == "faq") {
            setFaqSearch(RegisterText);
          } else if (category == "caution") {
            setNoticeSearch(RegisterText);
          } else if (category == "search") {
            setTagSearch(RegisterText);
          } else if (category == "exposed") {
            setKeywordSearch(RegisterText);
          } else if (category == "unique") {
            setUniqueSearch(RegisterText);
          }
        } else {
          window.alert(StatusMessage);
        }
      });
  };

  const getData = () => {
    setLoading(true);
    var token = localStorage.getItem("token");

    const frm = new FormData();

    frm.append("mode", "main");
    frm.append("token", token);
    frm.append("id", n);

    axios
      .post("https://ai.semos.kr/semos_admin/v2/activity/ActivityDetail", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        setInfo(Value.activity_data);

        setLoading(false);
      });
  };

  const StatusConverter = (status) => {
    switch (status) {
      case "활성화":
        return <span style={{ fontWeight: "500", color: "#3383FF" }}>활성화</span>;
        break;
      case "승인 요청":
        return <span style={{ fontWeight: "500", color: "#FF6052" }}>승인 요청</span>;
        break;
      case "비노출":
        return <span style={{ fontWeight: "500", color: "#ACAFB5" }}>비노출</span>;
        break;
    }
  };

  const saveData = () => {
    var token = localStorage.getItem("token");
    var copy = info.background;

    for (let i = 0; i < copy.length; i++) {
      if (typeof copy[i] == "string") {
        var copy2 = copy[i];
        copy2 = { id: "new", image: copy[i] };

        copy[i] = copy2;

        setInfo((prev) => ({ ...prev, background: copy }));
      }
    }
      axios
        .post(
          "https://ai.semos.kr/semos_admin/activity/data",
          {
            mode: "new_activity_revise",
            token: token,
            data: info,
            delete: deleteD,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((data) => {
          var Value = data.data;
          if (Value.status == "success") {
            alert(Value.status_message);
            if (n == "new") {
              navigate(`/activity/1/all/all/all/all`);
            } else {
              window.location.reload();
            }
          } else {
            alert(Value.status_message);
          }
        });
  };

  const changeData = (type) => {
    var token = localStorage.getItem("token");


    if(type == '승인') {
      var copy = info.background;

      for (let i = 0; i < copy.length; i++) {
        if (typeof copy[i] == "string") {
          var copy2 = copy[i];
          copy2 = { id: "new", image: copy[i] };
  
          copy[i] = copy2;
  
          setInfo((prev) => ({ ...prev, background: copy }));
        }
      }

      axios
        .post(
          "https://ai.semos.kr/semos_admin/v2/activity/ActivityDetail",
          {
            mode: "confirm",
            token: token,
            data: info,
            delete: deleteD,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((data) => {
          var Value = data.data;
          if (Value.status == "success") {
            alert(Value.status_message);
            window.location.reload();
          } else {
            alert(Value.status_message);
          }
        });
    } else if(type == '반려') {
      axios
        .post(
          "https://ai.semos.kr/semos_admin/v2/activity/ActivityDetail",
          {
            mode: "cancel",
            token: token,
            id : info.activity_id,
            center_id :info.center_id,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((data) => {
          var Value = data.data;
          if (Value.status == "success") {
            alert(Value.status_message);
            window.location.reload();
          } else {
            alert(Value.status_message);
          }
        });
    }

  };

  useEffect(() => {
    if (window.location.pathname.includes("new")) {
      var obj = {};
      for (let i = 1; i < Number(info.lesson_day) + 1; i++) {
        const eachDay = `day${i}`;
        obj[eachDay] = [];
      }
      setInfo((prev) => ({ ...prev, curriculum: obj }));

      var obj_copy = {};
      for (let i = 1; i < Number(info.lesson_day_copy) + 1; i++) {
        const eachDay_copy = `day${i}`;
        obj_copy[eachDay_copy] = [];
      }
      setInfo((prev) => ({ ...prev, curriculum_copy: obj_copy }));
    } else {
      var token = localStorage.getItem("token");
      const frm = new FormData();
      frm.append("mode", "main");
      frm.append("token", token);
      frm.append("id", n);
      axios
        .post("https://ai.semos.kr/semos_admin/v2/activity/ActivityDetail", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var newObj = {};
          const day = Value.activity_data.lesson_day ? Value.activity_data.lesson_day : 1;
          for (let i = 1; i < Number(day) + 1; i++) {
            const eachDay = `day${i}`;
            if (Value.activity_data.curriculum[eachDay]) {
              newObj[eachDay] = Value.activity_data.curriculum[eachDay];
            } else {
              newObj[eachDay] = [];
            }
          }
          setInfo((prev) => ({ ...prev, curriculum: newObj }));

          var newObj_copy = {};
          const day_copy = Value.activity_data.lesson_day_copy ? Value.activity_data.lesson_day_copy : 1;
          for (let i = 1; i < Number(day_copy) + 1; i++) {
            const eachDay_copy = `day${i}`;
            if (Value.activity_data.curriculum_copy[eachDay_copy]) {
              newObj_copy[eachDay_copy] = Value.activity_data.curriculum_copy[eachDay_copy];
            } else {
              newObj_copy[eachDay_copy] = [];
            }
          }
          setInfo((prev) => ({ ...prev, curriculum_copy: newObj_copy }));
        });
    }
  }, [info.lesson_day, info.duration]);


  return (
    <>
      <Wrapper
        bgc="#ffffff"
        of={`hidden auto`}
        margin="10px auto 0 auto"
        display={`inline-block`}
        width={`100%`}
        mw={`1000px`}
        mh={`calc(100vh - 72px)`}
        padding={`10px 0`}
      >
        <Titlesection>
          <Wrapper width="100%" jc="space-between" ai="flex-start">

            <Wrapper width="60%">
              <MainTitle className="f24">액티비티 정보</MainTitle>
              <SmallText className="f14">{info ? `최근 수정일 : ${info.updated}` : ""}</SmallText>
            </Wrapper>

            <Wrapper jc={'flex-end'} width="40%">
              {(info.status == '승인 요청') ?
                  <>
                    <CancelButton style={{marginRight : 10}}   width="calc(1rem * 124 / 14)" className="f16" onClick={() => changeData('반려')}>
                      반려
                    </CancelButton>

                    <SaveButton width="calc(1rem * 124 / 14)" className="f16" onClick={() => changeData('승인')}>
                      승인
                    </SaveButton>
                  </>
                :
                  <SaveButton width="calc(1rem * 124 / 14)" className="f16" onClick={() => saveData()}>
                    저장
                  </SaveButton>
              }
            </Wrapper>

            {/* <Wrapper width="80%">
              <MainTitle className="f24">{info?.name}</MainTitle>
            </Wrapper> */}
            {/* <Text ws="pre">{StatusConverter(info?.status)}</Text> */}
          </Wrapper>
        </Titlesection>
        <ActivityNormalInfo
          open={open}
          data={info}
          setData={setInfo}
          deleteD={deleteD}
          setDelete={setDeleteD}
          setCropImage={setCropImage}
          cropImage={cropImage}
          setImageFromFile={setImageFromFile}
          ImgChange={ImgChange}
        />
        <ActivityPriceInfo open={open} data={info} setData={setInfo} deleteD={deleteD} setDelete={setDeleteD} />
        <ActivityIntro
          open={open}
          data={info}
          setData={setInfo}
          deleteD={deleteD}
          setDelete={setDeleteD}
          bigData={BigDataSearch}
          textRecommend={{
            recommend: recommendSearch,
            qualified: requireSearch,
            prepare: bringSearch,
            include: includeSearch,
            exclude: excludeSearch,
            faq: faqSearch,
            caution: noticeSearch,
            unique: uniqueSearch,
          }}
        />
        <ActivityKeyword
          open={open}
          data={info}
          setData={setInfo}
          deleteD={deleteD}
          setDelete={setDeleteD}
          bigData={BigDataSearch}
          textRecommend={{
            search: tagSearch,
            exposed: keywordSearch,
          }}
        />
        <Wrapper padding="calc(1rem * 36 / 14)" width="100%">
          {
            info.status == '승인 요청' ?
              <>
                <CancelButton style={{marginRight : 20}} className="f16" onClick={() => changeData('반려')}>
                  반려
                </CancelButton>
                
                <SaveButton className="f16" onClick={() => changeData('승인')}>
                  승인
                </SaveButton>
              </>
            : 
              <SaveButton className="f16" onClick={() => saveData()}>
                저장
              </SaveButton>
          }

        </Wrapper>
      </Wrapper>
      <ImageCrop info={cropImage} setInfo={setCropImage} setImg={setInfo} img={info} />
    </>
  );
  //   }
};

export default ActivityDetail;

const Container = styled.div`
  padding-bottom: calc(1rem * 52 / 14);
`;

const Titlesection = styled.div`
  padding: calc(1rem * 36 / 14);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MainTitle = styled.span`
  white-space: pre-wrap;
  word-break: break-all;
  font-weight: 700;
  color: #3c3e41;
`;

const SmallText = styled.span`
  font-weight: 500;
  color: ${colors.Gr04};
  margin-left: 8px;
`;

const SaveButton = styled.div`
  padding: 1rem;
  width: ${({ width }) => (width ? width : "100%")};
  background-color: ${colors.Bl07};
  color: ${colors.White};
  font-weight: 700;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
`;

const CancelButton = styled.div`
  padding: 1rem;
  width: ${({ width }) => (width ? width : "100%")};
  background-color: ${colors.Red03};
  color: ${colors.White};
  font-weight: 700;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
`;