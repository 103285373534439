import styled from "styled-components";
import { ageGroupOptionList } from "../../data/select";
import Select from "../../modules/select";
import base from "../../styles/constants/base";
import colors from "../../styles/constants/colors";
import flexbox from "../../styles/func/flexbox";

const SECTION_HEIGHT = 135;

const Section = styled.section`
  width: 100%;
  display: flex;
`;

const Thumbnail = styled.img`
  width: 135px;
  height: ${SECTION_HEIGHT}px;
  border-radius: ${base.borderRadius.default}px;
  margin-right: 20px;
  background-color: ${colors.gray};
  flex-shrink: 0;
  overflow: hidden;
`;

const MetaBox = styled.div`
  width: 100%;
  height: ${SECTION_HEIGHT}px;
  ${flexbox("space-between", "space-between", "column")}

  div {
    ${flexbox("space-between")}
  }
`;

const Profile = ({ type = "read", data, select = {} }) => {
  if (type === "read") {
    return (
      <Section>
        <Thumbnail src={data.thumbnail} />

        <MetaBox>
          <div>
            <p>NO.{data.user_number}</p>
            <p>{data.date}</p>
          </div>

          <p>{data.e_mail}</p>
          <p>{data.phone}</p>
          <p>{data.age ? data.age + "세" : ""}</p>
        </MetaBox>
      </Section>
    );
  }

  if (type === "edit") {
    return (
      <Section>
        <Thumbnail src={data.thumbnail} />

        <MetaBox>
          <div>
            <p>NO.{data.user_number}</p>
            <p>{data.date}</p>
          </div>

          <p>{data.e_mail}</p>
          <p>{data.phone}</p>
          <Select
            optionList={ageGroupOptionList}
            width="210px"
            name={select.name}
            value={select.value}
            changeHandler={select.changeHandler}
          />
        </MetaBox>
      </Section>
    );
  }
};

export default Profile;
