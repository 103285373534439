import { useState } from "react";
import styled from "styled-components";
import DeleteNoticeModal from "../../../layouts/modal/deleteNotice";
import Overlay from "../../../layouts/overlay";
import OverlayPortal from "../../../overlayPortal";
import base from "../../../styles/constants/base";
import colors from "../../../styles/constants/colors";
import ClockField from "../../clockField";
import WeekField from "../../weekField";

const Box = styled.div`
  position: relative;
  width: 100%;
  margin: 20px 0;
  padding: 20px;
  box-shadow: ${base.boxShadow.default};
  border-radius: ${base.borderRadius.default}px;
`;

const ButtonBox = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  button {
    padding: 10px;

    &:first-child {
      color: ${colors.gray};
    }

    &:last-child {
      color: ${colors.blue};
      margin-left: 10px;
    }
  }
`;

const ScheduleCard = ({ week, clock, deleteHandler, saveHandler, number, data }) => {
  const [open, setOpen] = useState(false);

  const DeleteHandle = () => {
    deleteHandler(number);
    setOpen(!open)
  }

  return (
    <>
      <Box>
        <section>
          <WeekField type="write" week={week} />
        </section>

        <section>
          <ClockField type="write" clock={clock} data={data} />
        </section>

        <ButtonBox>
          <button type="button" onClick={() => setOpen(!open)}>
            삭제하기
          </button>
          <button type="button" onClick={saveHandler}>
            완료하기
          </button>
        </ButtonBox>
      </Box>

      {open && <DeleteNoticeModal deleteHandler={DeleteHandle} closeHandler={() => setOpen(!open)} />}

      <OverlayPortal>
        {open && <Overlay toggleHandler={() => setOpen(!open)} />}
      </OverlayPortal>
    </>
  );
};

export default ScheduleCard;
