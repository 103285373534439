import styled from "styled-components";
import deleteIcon from "../assets/icon/circle-close.svg";
import selectIcon from "../assets/icon/select-chevron.svg";
import addIcon from "../assets/icon/add.svg";
import base from "../styles/constants/base";
import typography from "../styles/constants/typography";
import flexbox from "../styles/func/flexbox";
import Select from "../modules/select";

const Box = styled.div`
  button {
    padding: 5px;

    img {
      width: 20px;
    }
  }
`;

const Box2 = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  width: calc(100% - 150px);

  button {
    padding: 5px;

    img {
      width: 20px;
    }
  }
`;

const List = styled.ul``;

const Item = styled.li``;

const Time = styled.div`
  ${flexbox("flex-start")}

  span {
    margin: 0 10px;
  }
`;

const AddBox = styled.div`
  ${flexbox("flex-start")}
  margin-bottom: 10px;
`;

const ClockBox = styled.div`
  ${flexbox("flex-start")}
  margin-bottom: 10px;

  ul {
    padding-left: 0px !important;
  }
`;

const Clock = styled.div`
  ${flexbox("flex-start")}
  margin-right: 10px;
`;

const MiddleIcon = styled.span`
  margin: 0 10px;
`;

const AddButton = styled.button``;

const DeleteButton = styled.button``;

const ClockField = ({
  type = "write",
  clock,
  data,
  timeList,
  timeCheck,
  clockChangeHandler,
}) => {
  const {
    timeListAddHandler,
    timeAddHandler,
    deleteClockHandler,
    setTimeDefaultFun,
    timeDeleteHandler,
    clockList,
    number,
    timeDefault,
  } = clock;

  const hour = Array.from({ length: 18 }, (_, i) => {
    if (i + 6 < 10) {
      return `0${i + 6}`;
    } else {
      return i + 6;
    }
  });

  const TimeList = {
    오전: ["06", "07", "08", "09", "10", "11"],
    오후: [
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
    ],
    min: ["00", "30"],
  };

  // const minute = Array.from({ length: 60 }, (_, i) => {
  //   if (i < 10) {
  //     return `0${i}`;
  //   } else {
  //     return i;
  //   }
  // });

  if (type === "write") {
    return (
      <Box>
        <AddBox>
          <Clock>
            <Time>
              <Select
                width="100px"
                name="start_time"
                changeHandler={(e) =>
                  setTimeDefaultFun("start_time", e.target.value, number)
                }
                optionList={hour.map((item) => ({
                  text: item,
                  value: item,
                }))}
                value={timeDefault ? timeDefault[number].start_time : ""}
              />

              <span>:</span>

              <Select
                width="100px"
                name="start_min"
                changeHandler={(e) =>
                  setTimeDefaultFun("start_min", e.target.value, number)
                }
                optionList={[
                  {
                    text: "00",
                    value: "00",
                  },
                  {
                    text: "30",
                    value: 30,
                  },
                ]}
                value={timeDefault ? timeDefault[number].start_min : ""}
              />
            </Time>

            <MiddleIcon>~</MiddleIcon>

            <Time>
              <Select
                width="100px"
                name="finish_time"
                changeHandler={(e) =>
                  setTimeDefaultFun("finish_time", e.target.value, number)
                }
                optionList={hour.map((item) => ({
                  text: item,
                  value: item,
                }))}
                value={timeDefault ? timeDefault[number].finish_time : ""}
              />
              <span>:</span>
              <Select
                width="100px"
                name="finish_min"
                changeHandler={(e) =>
                  setTimeDefaultFun("finish_min", e.target.value, number)
                }
                optionList={[
                  {
                    text: "00",
                    value: "00",
                  },
                  {
                    text: "30",
                    value: 30,
                  },
                ]}
                value={timeDefault ? timeDefault[number].finish_min : ""}
              />
            </Time>
          </Clock>

          <AddButton type="button" onClick={() => timeListAddHandler(number)}>
            <img src={addIcon} />
          </AddButton>
        </AddBox>

        <ul>
          {data &&
            data.time.map((item, idx) => (
              <ClockBox key={idx}>
                <Clock>
                  <Time>
                    <Select
                      width="100px"
                      name="start_time"
                      changeHandler={(e) =>
                        timeAddHandler(
                          number,
                          idx,
                          "start_time",
                          e.target.value
                        )
                      }
                      optionList={hour.map((item) => ({
                        text: item,
                        value: item,
                      }))}
                      value={item["start_time"]}
                    />

                    <span>:</span>

                    <Select
                      width="100px"
                      name="start_min"
                      changeHandler={(e) =>
                        timeAddHandler(number, idx, "start_min", e.target.value)
                      }
                      optionList={[
                        {
                          text: "00",
                          value: "00",
                        },
                        {
                          text: "30",
                          value: 30,
                        },
                      ]}
                      value={item["start_min"]}
                    />
                  </Time>

                  <MiddleIcon>~</MiddleIcon>

                  <Time>
                    <Select
                      width="100px"
                      name="finish_time"
                      changeHandler={(e) =>
                        timeAddHandler(
                          number,
                          idx,
                          "finish_time",
                          e.target.value
                        )
                      }
                      optionList={hour.map((item) => ({
                        text: item,
                        value: item,
                      }))}
                      value={item["finish_time"]}
                    />
                    <span>:</span>
                    <Select
                      width="100px"
                      name="finish_min"
                      changeHandler={(e) =>
                        timeAddHandler(
                          number,
                          idx,
                          "finish_min",
                          e.target.value
                        )
                      }
                      optionList={[
                        {
                          text: "00",
                          value: "00",
                        },
                        {
                          text: "30",
                          value: 30,
                        },
                      ]}
                      value={item["finish_min"]}
                    />
                  </Time>
                </Clock>

                <DeleteButton
                  type="button"
                  onClick={() => timeDeleteHandler(number, idx)}
                >
                  <img src={deleteIcon} />
                </DeleteButton>
              </ClockBox>
            ))}
        </ul>
      </Box>
    );
  }

  if (type === "read") {
    return (
      <Box>
        <ul>
          {data &&
            data.time.map((item, idx) => (
              <ClockBox key={idx}>
                <Clock>
                  <Time>
                    <Select
                      width="100px"
                      name="start_time"
                      changeHandler={clockChangeHandler}
                      optionList={hour.map((item) => ({
                        text: item,
                        value: item,
                      }))}
                      value={item["start_time"]}
                    />

                    <span>:</span>

                    <Select
                      width="100px"
                      name="start_min"
                      changeHandler={clockChangeHandler}
                      optionList={[
                        {
                          text: "00",
                          value: "00",
                        },
                        {
                          text: "30",
                          value: 30,
                        },
                      ]}
                      value={item["start_min"]}
                    />
                  </Time>

                  <MiddleIcon>~</MiddleIcon>

                  <Time>
                    <Select
                      width="100px"
                      name="finish_time"
                      changeHandler={clockChangeHandler}
                      optionList={hour.map((item) => ({
                        text: item,
                        value: item,
                      }))}
                      value={item["finish_time"]}
                    />
                    <span>:</span>
                    <Select
                      width="100px"
                      name="finish_min"
                      changeHandler={clockChangeHandler}
                      optionList={[
                        {
                          text: "00",
                          value: "00",
                        },
                        {
                          text: "30",
                          value: 30,
                        },
                      ]}
                      value={item["finish_min"]}
                    />
                  </Time>
                </Clock>

                <DeleteButton
                  type="button"
                  onClick={() => deleteClockHandler(item.id)}
                >
                  <img src={deleteIcon} />
                </DeleteButton>
              </ClockBox>
            ))}
        </ul>
      </Box>
    );
  }

  if (type === "click") {
    return (
      <Box2>
        <TimeSlider>
          <div className="timeCategory">오전</div>
          <div className="timeList">
            {TimeList.오전.map((item, idx) => (
              <TimeContent
                onClick={() => timeCheck(item)}
                key={`오전_${idx}`}
                active={timeList[item] ? true : false}
              >
                {item}
              </TimeContent>
            ))}
          </div>
        </TimeSlider>

        <TimeSlider>
          <div className="timeCategory">오후</div>
          <div className="timeList">
            {TimeList.오후.map((item, idx) => (
              <TimeContent
                onClick={() => timeCheck(item)}
                key={`오후_${idx}`}
                active={timeList[item] ? true : false}
              >
                {item}
              </TimeContent>
            ))}
          </div>
        </TimeSlider>
      </Box2>
    );
  }

  if (type === "click2") {
    return (
      <Box2>
        <TimeSlider>
          <div className="timeCategory">오전</div>
          <div className="timeList">
            {TimeList.오전.map((item, idx) => (
              <TimeContent
                onClick={() => timeCheck(item)}
                key={`오전_${idx}`}
                active={timeList[item] ? true : false}
              >
                {item}
              </TimeContent>
            ))}
          </div>
        </TimeSlider>

        <TimeSlider>
          <div className="timeCategory">오후</div>
          <div className="timeList">
            {TimeList.오후.map((item, idx) => (
              <TimeContent
                onClick={() => timeCheck(item)}
                key={`오후_${idx}`}
                active={timeList[item] ? true : false}
              >
                {item}
              </TimeContent>
            ))}
          </div>
        </TimeSlider>
      </Box2>
    );
  }
};

export default ClockField;

const TimeSlider = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 20px;

  .timeCategory {
    width: 70px;
    display: inline-block;
    font-size: 17px;
    font-weight: 400;
  }

  .timeList {
    width: calc(100% - 70px);
    display: inline-flex;
    flex-wrap: nowrap;
    overflow: auto hidden;
  }
`;

const TimeContent = styled.div`
  padding: 5px 10px;
  display: inline-flex;
  border-radius: 4px;
  font-size: 19px;
  margin-right: 10px;
  cursor: pointer;
  ${({ active }) =>
    active
      ? "color : #ffffff; font-weight : 500; background-color : rgba(0,149,255,1); border : rgba(0,149,255,1) 1px solid;"
      : "color : #666d75; font-weight : 400; background-color : #ffffff; border : rgba(132, 140, 148, .5) 1px solid;"}
`;
