import styled from "styled-components";
import base from "../styles/constants/base";
import colors from "../styles/constants/colors";

const weekData = [
  
    "월",
    "화",
    "수",
    "목",
    "금",
    "토",
    "일",
];

const Week = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  padding-left: 0 !important;
`;

const Day = styled.li`
  box-shadow: ${base.boxShadow.default};
  border-radius: ${base.borderRadius.default}px;
  color: ${colors.mediumGray};
  padding: 5px 15px;
  cursor: pointer;
  border: 2px solid
    ${({ isCheck }) => (isCheck ? `${colors.blue}` : `transparent`)};
  margin-bottom: 10px;

  &:not(:last-child) {
    margin-right: 20px;
  }
`;

const WeekField = ({ type = "write", week }) => {
  const { yoilWeek, weekToggleHandler, number } = week;

  if (type === "write") {
    return (
      <Week>
        {weekData.map((day) => (
          <Day
            key={day.idx}
            idx={day.idx}
            isCheck={yoilWeek.includes(day)}
            onClick={() => weekToggleHandler(day, number)}
          >
            {day}
          </Day>
        ))}
      </Week>
    );
  }

  if (type === "read") {
    return (
      <Week>
        {weekData.map((day) => (
          <Day key={day.idx} idx={day.idx} isCheck={week.includes(day.idx)}>
            {day.text}
          </Day>
        ))}
      </Week>
    );
  }
};

export default WeekField;
