import styled from "styled-components";
import base from "../../../styles/constants/base";

export const FilterBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  div {
    display: flex;
  }
`;

export const SearchIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
  pointer-events: none;
`;

export const Main = styled.main`
  padding: 20px;
  margin: 20px 0;
`;

export const Footer = styled.footer`
  width: 100%;
  padding: 0 ${base.spacing.main}px;
  position: absolute;
  bottom: 20px;
`;
