export const CXMessageTypeValue = {
  "cancelp" : "액티비티 취소/환불 안내 - 강사 요청",
  "cancelp2" : "액티비티 환불/취소",
  "cancelu" : "액티비티 취소/환불 안내 - 고객 요청",
  "refundu" : "유저 - 환불 (고객 요청)",
  "refundp" : "액티비티 환불/취소",
  "reminder" : "액티비티 이틀 전 리마인더",
  "reminder2" : "액티비티 이틀 전 리마인더",
  "payment" : "액티비티 결제 안내",
  "confirm" : "액티비티 확정 안내",
  "confirm2" : "액티비티 확정 안내",

  "UCANCELPS" : "액티비티 취소/환불 안내 - 강사 요청",
  "UCANCELS" : "액티비티 취소/환불 안내 - 고객 요청",
  "PCANCELS" : "액티비티 환불/취소",
  "PCANCELS2" : "액티비티 환불/취소",
  "UREMINDERS" : "액티비티 이틀 전 리마인더",
  "PREMINDERS" : "액티비티 이틀 전 리마인더",
  "PAYMENTS" : "액티비티 결제 안내",
  "PPAYMENTS" : "구매 발생 (예약 확정 필요)",
  "UCONFIRMS" : "액티비티 확정 안내",
  "PCONFIRMS" : "예약 확정",
  
  "UCANCELPS2" : "액티비티 취소/환불 안내 - 강사 요청",
  "UCANCELS2" : "액티비티 취소/환불 안내 - 고객 요청",
  "PCANCELS" : "액티비티 환불/취소",
  "PCANCELS3" : "액티비티 환불/취소",
  "UREMINDER2" : "액티비티 이틀 전 리마인더",
  "PREMINDER2" : "액티비티 이틀 전 리마인더",
  "PAYMENTS2" : "액티비티 결제 안내",
  "PPAYMENTS2" : "구매 발생 (예약 확정 필요)",
  "UCONFIRMS2" : "액티비티 확정 안내",
  "PCONFIRMS2" : "예약 확정",
  "UREVIEW" : "리뷰 안내",

  "PREGISTER2" : "세모스 파트너 등록",
  "PREGISTER" : "세모스 파트너 등록",
  "PSCHEDULE" : "월별 일정 등록",
  "REVIEWJOME" : "리뷰 독려 요청",
  "UREMINDER3" : "액티비티 이틀 전 리마인더",
  "PREMiNDER3" : "액티비티 이틀 전 리마인더",

  "PSCHEDULE2" : "월별 일정 등록",
  "PREGISTER3" : "세모스 파트너 등록",
  "REVIEWJOM2" : "리뷰 독려 요청",
  "UCANCELPS3" : "액티비티 취소/환불 안내 - 강사 요청",
  "UCANCELS3" : "액티비티 취소/환불 안내 - 고객 요청",
  "PCANCELS4" : "액티비티 환불/취소",
  "UREMINDER4" : "액티비티 이틀 전 리마인더",
  "PREMINDER4" : "액티비티 이틀 전 리마인더",
  "PAYMENTS3" : "액티비티 결제 안내",
  "PPAYMENTS3" : "구매 발생 (예약 확정 필요)",
  "UCONFIRMS3" : "액티비티 확정 안내",
  "PCONFIRMS3" : "예약 확정",
  "UREVIEW2" : "리뷰 안내",

  "PREGISTER4" : "세모스 파트너 등록",
  "REVIEWJOM3" : "리뷰 독려 요청",
  "UCANCELPS4" : "액티비티 취소/환불 안내 - 강사 요청",
  "UCANCELS4" : "액티비티 취소/환불 안내 - 고객 요청",
  "PCANCELS5" : "액티비티 환불/취소",
  "UREMINDER5" : "액티비티 이틀 전 리마인더",
  "PREMINDER5" : "액티비티 이틀 전 리마인더",
  "PAYMENTS4" : "액티비티 결제 안내",
  "PPAYMENTS4" : "구매 발생 (예약 확정 필요)",
  "UCONFIRMS4" : "액티비티 확정 안내",
  "PCONFIRMS4" : "예약 확정",
  "UREVIEW3" : "리뷰 안내",

  "PAYMENTS3" : "액티비티 결제 안내",
  "PAYMENT6" : "액티비티 결제 안내",
  "PAYMENT5" : "액티비티 결제 안내",
  "PAYMENT4" : "액티비티 결제 안내",
  "UCONFIRMS5" : "액티비티 확정 안내",
  "UCANCELS5" : "액티비티 취소/환불 안내 - 고객 요청",
  "UCANCELPS5" : "액티비티 취소/환불 안내 - 강사 요청",
  "UREMINDER6" : "액티비티 이틀 전 리마인더",
  "PCANCEL" : "액티비티 환불/취소",
  "PCONFIRM" : "예약 확정",
  "PREMINDER" : "액티비티 이틀 전 리마인더",
  "PPAYMENT" : "구매 발생 (예약 확정 필요)",
  "SOPAYMENT" : "세모스ONLY결제발생",
  "SOPAYMENT2" : "세모스ONLY결제발생",
  "ActivityDenied" : "액티비티반려",
  "ActivityAccepted" : "액티비티승인",

    "CPAYMENT" : "크루_액티비티_결제",
    "CSOPAYMENT" : "크루_세모스온리_액티비티_결제",
    "CCONFIRM" : "크루_예약일정_확정",
    "CREMINDER" : "크루_예약일정_리마인더",
    "CREVIEW" : "크루_리뷰_요청",
    "CCREFUND" : "크루_예약일정_환불_크루",
    "CPREFUND" : "크루_예약일정_환불_파트너",
    "CAREFUND" : "크루_예약일정_환불_자동",

    "PSOPAYMENT" : "파트너_액티비티_결제",
    "PSOCONFIRM" : "파트너_세모스온리_결제_확정",
    "PAPASS" : "파트너_액티비티_심사승인",
    "PPAYMENT2" : "파트너_액티비티_결제",
    "PCONFIRM2" : "파트너_예약일정_확정",
    "PREMINDER3" : "파트너_예약일정_리마인더",
    "PREFUND" : "파트너_예약일정_환불"
}

