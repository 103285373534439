import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import colors from "../styles/constants/colors";
import zIndex from "../styles/constants/z-index";
import { useState } from "react";
import flexbox from "../styles/func/flexbox";
import transition from "../styles/func/transition";
import TabBar from "../components/dashboard/tabBar";

import { newNavList } from "../data/navbar";
import SideNavigate from "../components/sideNavigate";

// icon
import AdminLogo from "../assets/icon/admin_logo.png";

const ITEM_HEIGHT = 56;

const Nav = styled.nav`
  position: fixed;
  display: inline-flex;
  flex-wrap: wrap;
  top: 0;
  left: 0;
  width: 210px;
  height: 100vh;
  background-color: #ffffff;
  z-index: ${zIndex.navbar};
  /* overflow-y: scroll; */

  .admin_logo {
    width: 210px;
    display: inline-block;
  }

  header {
    display: inline-flex;
    width: 210px;
    height: 92px;
    border-width: 0px 1px 1px 0px;
    border-style: solid;
    border-color: #f5f5f5;
  }

  ul {
    width: 100%;
    height: calc(100vh - 92px);

    li {
      width: 100%;
      color: ${colors.white};
      height: ${ITEM_HEIGHT}px;

      a {
        ${flexbox("flex-start")}
        width: 100%;
        height: 100%;
        padding-left: 30px;

        img {
          margin-right: 20px;
        }
      }

      .fake_link {
        ${flexbox("flex-start")}
        width: 100%;
        height: 100%;
        padding-left: 30px;

        img {
          margin-right: 20px;
        }
      }
    }
  }
`;

const ServiceButton = styled.li`
  cursor: pointer;
  width: 100%;
  height: 100%;
  ${flexbox("flex-start")}
  padding-left: 20px;

  span {
    padding-left: 20px;
    padding-right: 10px;
  }
`;

const ChevronBox = styled.div`
  transition: ${transition("transform")};
  transform: ${({ open }) => (open ? `rotate(-180deg)` : null)};
`;

const ServiceMenu = styled.div`
  height: ${ITEM_HEIGHT * 6}px;
  max-height: ${({ open }) => (open ? `${ITEM_HEIGHT * 6}px` : "0")};
  transition: ${transition("max-height")};
  overflow: hidden;
`;

const MLink = styled(Link)`
  background-color: ${({ active }) => (active === "true" ? `${colors.black}` : `${colors.lightBlack}`)};
  transition: ${transition("background-color", "0.2s")};
`;

const MLink_span = styled.span`
  background-color: ${({ active }) => (active === "true" ? `${colors.black}` : `${colors.lightBlack}`)};
  transition: ${transition("background-color", "0.2s")};
  cursor: pointer;
`;

const SLink = styled(Link)`
  ${flexbox("flex-start")}
  width: 100%;
  height: ${ITEM_HEIGHT}px;
  color: ${colors.white};
  padding-left: ${30 + 20 + 18}px;
  background-color: ${({ active }) => (active === "true" ? `${colors.black}` : `${colors.lightBlack}`)};
  transition: ${transition("background-color", "0.2s")};
`;

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();

  const [show, setShow] = useState(false);

  const serviceMenuToggleHandler = () => {
    setOpen(!open);
  };

  if (pathname !== "/") {
    return (
      <Nav>
        <header>
          <img className="admin_logo" src={AdminLogo} alt="세모스 로고" />
        </header>

        <ul style={{ borderRightColor: "#f5f5f5", borderRightWidth: "2px", borderRightStyle: "solid" }}>
          {/*{navbarList.map((item, idx) =>
            item.path !== "/dashboard/data" ? (
              <li key={idx}>
                <MLink
                  to={item.path}
                  active={
                    pathname.includes(`${item.path.split("/")[1]}`)
                      ? "true"
                      : "false"
                  }
                  onClick={() => setShow(false)}
                >
                  <img src={item.img} />
                  <span>{item.text}</span>
                </MLink>
              </li>
            ) : (
              <li key={idx}>
                <MLink_span
                  className="fake_link"
                  active={pathname.includes(`${item.path}`) ? "true" : "false"}
                  onClick={() => setShow(true)}
                >
                  <img src={item.img} />
                  <span>{item.text}</span>
                </MLink_span>
              </li>
            )
          )}
          <ServiceButton onClick={serviceMenuToggleHandler}>
            <img src={serviceIcon} />
            <span>서비스 관리</span>

            <ChevronBox open={open}>
              <img src={chevronIcon} />
            </ChevronBox>
          </ServiceButton>

          <ServiceMenu open={open}>
            {serviceList.map((item, idx) => (
              <SLink
                key={idx}
                to={item.path}
                active={pathname.includes(`${item.path}`) ? "true" : "false"}
              >
                <p>{item.text}</p>
              </SLink>
            ))}
          </ServiceMenu>*/}

          {newNavList.map((el, i) => (
            <SideNavigate el={el} key={el.text} pathname={pathname} active={el.path && pathname.includes(`${el.path.split("/")[1]}`)} />
          ))}
        </ul>

        {show ? <TabBar setShow={setShow} /> : ""}
      </Nav>
    );
  }
};

export default Navbar;
