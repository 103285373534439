export const faqCategoryList = [
  {
    text: "전체",
    value: "all",
  },
  {
    text: "이용 안내",
    value: "info",
  },
  {
    text: "회원 정보",
    value: "user",
  },
  {
    text: "결제/환불",
    value: "payment",
  },
];

const faqList = [
  {
    id: 1,
    category: "use",
    question: "크루 클래스 인원 미달로 강습이 취소되면 어떻게 하나요?",
    answer:
      "최소 인원 미달로 인한 취소 시 강습일 기준 3일 전에 강사님께 진행 여부를 확인합니다. 최소 인원이라도 진행을 원하시면 강습이 개설 되며, 진행을 원하지 않으면강습이 취소됩니다.",
  },
  {
    id: 2,
    category: "use",
    question: "크루 클래스 인원 미달로 강습이 취소되면 어떻게 하나요?",
    answer:
      "최소 인원 미달로 인한 취소 시 강습일 기준 3일 전에 강사님께 진행 여부를 확인합니다. 최소 인원이라도 진행을 원하시면 강습이 개설 되며, 진행을 원하지 않으면강습이 취소됩니다.",
  },
  {
    id: 3,
    category: "use",
    question: "크루 클래스 인원 미달로 강습이 취소되면 어떻게 하나요?",
    answer:
      "최소 인원 미달로 인한 취소 시 강습일 기준 3일 전에 강사님께 진행 여부를 확인합니다. 최소 인원이라도 진행을 원하시면 강습이 개설 되며, 진행을 원하지 않으면강습이 취소됩니다.",
  },
  {
    id: 4,
    category: "use",
    question: "크루 클래스 인원 미달로 강습이 취소되면 어떻게 하나요?",
    answer:
      "최소 인원 미달로 인한 취소 시 강습일 기준 3일 전에 강사님께 진행 여부를 확인합니다. 최소 인원이라도 진행을 원하시면 강습이 개설 되며, 진행을 원하지 않으면강습이 취소됩니다.",
  },
  {
    id: 5,
    category: "use",
    question: "크루 클래스 인원 미달로 강습이 취소되면 어떻게 하나요?",
    answer:
      "최소 인원 미달로 인한 취소 시 강습일 기준 3일 전에 강사님께 진행 여부를 확인합니다. 최소 인원이라도 진행을 원하시면 강습이 개설 되며, 진행을 원하지 않으면강습이 취소됩니다.",
  },
  {
    id: 6,
    category: "use",
    question: "크루 클래스 인원 미달로 강습이 취소되면 어떻게 하나요?",
    answer:
      "최소 인원 미달로 인한 취소 시 강습일 기준 3일 전에 강사님께 진행 여부를 확인합니다. 최소 인원이라도 진행을 원하시면 강습이 개설 되며, 진행을 원하지 않으면강습이 취소됩니다.",
  },
  {
    id: 7,
    category: "use",
    question: "크루 클래스 인원 미달로 강습이 취소되면 어떻게 하나요?",
    answer:
      "최소 인원 미달로 인한 취소 시 강습일 기준 3일 전에 강사님께 진행 여부를 확인합니다. 최소 인원이라도 진행을 원하시면 강습이 개설 되며, 진행을 원하지 않으면강습이 취소됩니다.",
  },
  {
    id: 8,
    category: "use",
    question: "크루 클래스 인원 미달로 강습이 취소되면 어떻게 하나요?",
    answer:
      "최소 인원 미달로 인한 취소 시 강습일 기준 3일 전에 강사님께 진행 여부를 확인합니다. 최소 인원이라도 진행을 원하시면 강습이 개설 되며, 진행을 원하지 않으면강습이 취소됩니다.",
  },
  {
    id: 9,
    category: "use",
    question: "크루 클래스 인원 미달로 강습이 취소되면 어떻게 하나요?",
    answer:
      "최소 인원 미달로 인한 취소 시 강습일 기준 3일 전에 강사님께 진행 여부를 확인합니다. 최소 인원이라도 진행을 원하시면 강습이 개설 되며, 진행을 원하지 않으면강습이 취소됩니다.",
  },
  {
    id: 10,
    category: "member",
    question: "크루 클래스 인원 미달로 강습이 취소되면 어떻게 하나요?",
    answer:
      "최소 인원 미달로 인한 취소 시 강습일 기준 3일 전에 강사님께 진행 여부를 확인합니다. 최소 인원이라도 진행을 원하시면 강습이 개설 되며, 진행을 원하지 않으면강습이 취소됩니다.",
  },
  {
    id: 11,
    category: "member",
    question: "크루 클래스 인원 미달로 강습이 취소되면 어떻게 하나요?",
    answer:
      "최소 인원 미달로 인한 취소 시 강습일 기준 3일 전에 강사님께 진행 여부를 확인합니다. 최소 인원이라도 진행을 원하시면 강습이 개설 되며, 진행을 원하지 않으면강습이 취소됩니다.",
  },
  {
    id: 12,
    category: "member",
    question: "크루 클래스 인원 미달로 강습이 취소되면 어떻게 하나요?",
    answer:
      "최소 인원 미달로 인한 취소 시 강습일 기준 3일 전에 강사님께 진행 여부를 확인합니다. 최소 인원이라도 진행을 원하시면 강습이 개설 되며, 진행을 원하지 않으면강습이 취소됩니다.",
  },
  {
    id: 13,
    category: "payment",
    question: "크루 클래스 인원 미달로 강습이 취소되면 어떻게 하나요?",
    answer:
      "최소 인원 미달로 인한 취소 시 강습일 기준 3일 전에 강사님께 진행 여부를 확인합니다. 최소 인원이라도 진행을 원하시면 강습이 개설 되며, 진행을 원하지 않으면강습이 취소됩니다.",
  },
  {
    id: 14,
    category: "payment",
    question: "크루 클래스 인원 미달로 강습이 취소되면 어떻게 하나요?",
    answer:
      "최소 인원 미달로 인한 취소 시 강습일 기준 3일 전에 강사님께 진행 여부를 확인합니다. 최소 인원이라도 진행을 원하시면 강습이 개설 되며, 진행을 원하지 않으면강습이 취소됩니다.",
  },
  {
    id: 15,
    category: "payment",
    question: "크루 클래스 인원 미달로 강습이 취소되면 어떻게 하나요?",
    answer:
      "최소 인원 미달로 인한 취소 시 강습일 기준 3일 전에 강사님께 진행 여부를 확인합니다. 최소 인원이라도 진행을 원하시면 강습이 개설 되며, 진행을 원하지 않으면강습이 취소됩니다.",
  },
];

export default faqList;
