import styled from "styled-components";
import base from "../../styles/constants/base";
import colors from "../../styles/constants/colors";

const Box = styled.div`
  button {
    background-color: ${colors.white};
    border-radius: ${base.borderRadius.default}px;
    border: ${base.border};
    padding: 0 10px;
    border: 2px solid transparent;

    &:focus {
      border-radius: ${base.borderRadius.default}px;
      border: ${base.border};
    }

    &:not(:first-child) {
      margin-left: 10px;
    }

    &:first-child {
      color: ${({ active }) => active === 1 && colors.blue};
      border: 2px solid
        ${({ active }) => (active === 1 ? colors.blue : colors.lightGray)};
    }

    &:last-child {
      color: ${({ active }) => active === 0 && colors.blue};
      border: 2px solid
        ${({ active }) => (active === 0 ? colors.blue : colors.lightGray)};
    }
  }
`;

const UserFilterBox = ({ value, changeHandler }) => {
  return (
    <Box active={value === "customer" ? 1 : 0}>
      <button type="button" name="customer" onClick={changeHandler}>
        고객
      </button>

      <button
        type="button"
        name="partner"
        active={value === "customer" ? 0 : 1}
        onClick={changeHandler}
      >
        파트너
      </button>
    </Box>
  );
};

export default UserFilterBox;
