import styled from "styled-components";
import chevron from "../../assets/icon/chevron-b.svg";
import typography from "../../styles/constants/typography";
import colors from "../../styles/constants/colors";
import base from "../../styles/constants/base";
import flexbox from "../../styles/func/flexbox";
import { useAuth } from "../../context/auth";
import { useKakao } from "../../context/kakao";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/logo/semosLogo.png";
import Kakao from "../../assets/logo/kakao_logo.png";

const Container = styled.div`
  width: 28vw;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 100px;

  header {
    text-align: center;
    margin-bottom: 30px;

    h2 {
      font-size: ${typography.size.medium}px;
      margin-bottom: 5px;
    }
  }

  section {
    width: 100%;
    background-color: ${colors.white};
    border-radius: 13px;
    /* box-shadow: ${base.boxShadow.default}; */
    border: 1px solid #e6e6e6;
    text-align: center;
    padding: 20% 15%;
    margin-bottom: 30px;
  }

  footer {
    div {
      font-weight: 500;
      /* font-size: 1.1rem; */
      line-height: 142%;
      letter-spacing: -0.02em;
      color: #acafb5;
      text-align: start;
    }
  }
`;

const Logo = styled.img`
  width: calc(28vw * 0.09);
  height: calc(28vw * 0.09);
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: calc(28vw * 0.05);
  /* flex-direction: column; */
`;

const Title = styled.div`
  font-size: ${({ fs }) => fs}rem;
  font-family: "GMarket Sans" !important;
  color: #0e6dff;
  font-weight: 600;
`;

const Greeting = styled.p`
  font-weight: ${typography.weight.regular};
  margin: 30px 0;
`;

const LoginButton = styled.button`
  width: 100%;
  background-color: ${colors.Kakao};
  padding: 15px;
  border-radius: 16px;
  margin: 26px 0 14px 0;
  /* font-size: 1.6rem; */
  font-weight: ${typography.weight.regular};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OuterLink = styled.a`
  width: 100%;
  padding: 20px 40px;
  ${flexbox("space-between")}
  background-color: ${colors.white};
  border-radius: ${base.borderRadius.default}px;
  box-shadow: ${base.boxShadow.default};
  margin-bottom: 10px;
  font-weight: ${typography.weight.regular};
`;

const Middle = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  margin-top: 21px;
  /* background-color: blue; */
  height: 58px;

  text-align: center;
`;

const Line = styled.div`
  margin: 0 24px;
  width: 100%;
  height: 1px;
  background-color: #dcdcdc;
`;

const Topping = styled.div`
  position: absolute;
  /* float: center; */
  background-color: white;
  padding: 12px 34px;

  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: #acafb5;
  left: 50%;
  top: -20%;
  transform: translate(-50%, 50%);
`;

const KakaoIcon = styled.img`
  width: calc(28vw * 0.045);
  height: calc(28vw * 0.045);
  margin-right: 14px;
`;

const KAKAO_CHANNEL_PUBLIC_ID = "_YxfVxfK";

const LoginPage = () => {
  const { loginHandler } = useAuth();
  const { kakaoService } = useKakao();

  const navigate = useNavigate();

  const chatHandler = () => {
    kakaoService.chat(KAKAO_CHANNEL_PUBLIC_ID);
  };

  useEffect(() => {
    var token = window.localStorage.getItem("token");

    if (token) {
      navigate("/dashboard");
      window.location.reload();
    }
  }, []);

  return (
    <Container>
      {/* <header>
        <h2>세상의 모든 스포츠 슈퍼관리자 페이지입니다.</h2>
        <p>일반 회원은 아래 사이트를 확인해 주세요.</p>
    </header> */}

      <section>
        <TitleBox>
          <Logo src={logo} />
          <div>
            <Title className="f22">세모스</Title>
            <Title className="f9" style={{ letterSpacing: "-0.02em" }}>
              슈퍼관리자페이지
            </Title>
          </div>
        </TitleBox>

        <Middle>
          <Line />
          <Topping>login</Topping>
        </Middle>

        <LoginButton type="button" onClick={loginHandler} className="f16">
          <KakaoIcon src={Kakao} />
          카카오톡으로 로그인하기
        </LoginButton>

        <footer>
          <div className="f12">세모스의 슈퍼관리자 페이지 입니다.</div>
          <div className="f12">로그인 되지 않을 시, 슈퍼관리자 담당자에게 권한을 요청해주세요!</div>
        </footer>
      </section>

      {/* <OuterLink href="https://semos.kr" target="_blank">
        <p>세모스에서 레슨 구경하기</p>
        <img src={chevron} />
        </OuterLink>
        
        <OuterLink href="https://partner.semos.kr" target="_blank">
        <p>세모스 파트너로 이동하기</p>
        <img src={chevron} />
    </OuterLink> */}
    </Container>
  );
};

export default LoginPage;
