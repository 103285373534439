import axios from "axios";
import { useEffect, useState } from "react";

import useData from "../../../hooks/data/useData";
import useDate from "../../../hooks/useDate";

import BarChart from "../../../chart/data/bar";
import LineChart from "../../../chart/data/line";

import TabBar from "../../../components/dashboard/tabBar";
import TitleBox from "../../../components/dashboard/titleBox";
import pageTabData from "../../../data/dashboard/page";
import DougnutChart from "../../../chart/doughnut";

import DashboardLayout from "../../../layouts/dashboard";
import DateInput from "../../../modules/date/input";
import Select from "../../../modules/select";

import QuestionMark from "../../../assets/icon/questionMark.svg";

import {
  Container,
  Title,
  FlexWrapper,
  Wrapper,
  Footer,
  RankingSection,
  RankingSection2,
  TimeBox,
  TimeBox2,
  TransitionAndTime,
  TransitionAndTime2,
  TransitionBox,
  TimeWrapper,
  TimeWrapper2,
  TransitionWrapper,
  DoughnutWrapper,
  AUSection,
  QuestionContainer,
  QuestionContainer2
} from "./style";

const getTextFilter = (filter) => {
  switch (filter.filter) {
    case "m":
      return "month";

    case "w":
      return "week";

    case "d":
      return "day";
    default:
  }
};

const transformDate = (fullDate) => {
  const [year, month, day] = fullDate.split("-");

  return `${year.slice(2, 4)}-${month}-${day}`;
};

const PageTab = () => {
  const [status, setStatus] = useState({
    connectAndDetention: false,
    connect2: false,
    detention: false,
    lessonAndPartner: false,
    journey: false,
    event: false,
  });

  const [pageData, setPageData] = useState([]);

  const [valueList, setValueList] = useState({});

  const [labelsList, setLabelsList] = useState({});

  const { today, getFilter, getDataByCategory, transformDatetime } = useData(
    setValueList,
    setLabelsList
  );

  const { value: rankingFilter, changeHandler: rankingChangeHandler } = useDate(
    {
      filter: "m",
      date: transformDatetime("m"),
      type: "lesson"
    }
  );

  const { value: eventFilter, changeHandler: eventChangeHandler } = useDate(
    {
      filter: "m",
      date: transformDatetime("m"),
    }
  );

  const { value: detentionFilter, changeHandler: detentionChangeHandler } =
    useDate({
      filter: "m",
      date: transformDatetime("m"),
      page: "home",
      type: "view_count"
    });


  const { value: journeyFilter, changeHandler: journeyChangeHandler } =
  useDate({
    filter: "m",
    date: transformDatetime("m"),
    type: "view_count"
  });

  const { value: connectFilter, changeHandler: connectChangeHandler } =
    useDate({
      filter: "m",
      date: transformDatetime("m"),
      page: "home",
      page2: "product" 
    });

  const frm = new FormData();

  useEffect(() => {
    frm.append("mode", "Load");
    frm.append("page", "page_connect");

    axios
      .post("https://ai.semos.kr/semos_admin/board/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        const {
          LESSON_RATING_DATA,
          PARTNER_RATING_DATA,
          PAGE_CONNECT_DATA,
          PAGE_CONNECT_DATA2,
          DETENTION_DATA,
          JOURNEY_DATA,
          CLICK_DATA
        } = res.data;

        setPageData(res.data);

        setValueList({
          lesson: LESSON_RATING_DATA.map((item) => ({
            type: item.type,
            partner_name: item.partner_name,
            value: item.value,
          })),
          partner: PARTNER_RATING_DATA.map((item) => ({
            type: item.type,
            value: item.value,
          })),
          connect: PAGE_CONNECT_DATA.map((item) => item.value),
          connect2: PAGE_CONNECT_DATA2.map((item) => item.value),
          detention: DETENTION_DATA.map((item) => item.value),
          journey: JOURNEY_DATA.map((item) => item.value),
          event: CLICK_DATA.map((item) => item.value),
        });

        setLabelsList({
          detention: DETENTION_DATA.map((item) => item.date.substr(2).replace(/-/gi, '/')),
          connect: PAGE_CONNECT_DATA.map((item) => item.date.substr(2).replace(/-/gi, '/')),
          journey: JOURNEY_DATA.map((item) => item.type),
          event: CLICK_DATA.map((item) => item.type),
        });
      });
  }, []);

  // page_view
  useEffect(() => {
    if (status.connectAndDetention) {
      const frm = new FormData();

      frm.append("mode", "Update");
      frm.append("page", "page_connect");
      frm.append("date", connectFilter.date);
      frm.append("date_type", getTextFilter(connectFilter));
      frm.append("type", "page_view");
      frm.append("page_type", connectFilter.page);
      frm.append("page_type2", connectFilter.page2);

      axios
        .post("https://ai.semos.kr/semos_admin/board/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          const { data } = res;

          setValueList((prev) => ({
            ...prev,
            connect: data[0].map((item) => item.value),
            connect2: data[1].map((item) => item.value),
          }));

          setLabelsList((prev) => {
            if (detentionFilter.filter === "w") {
              return {
                ...prev,
                connect: data[0].map(
                  (item) =>
                    `${transformDate(item.start_date)} ~ ${transformDate(
                      item.finish_date
                    )}`
                ),
                connect2: data[1].map(
                    (item) =>
                      `${transformDate(item.start_date)} ~ ${transformDate(
                        item.finish_date
                      )}`
                  ),
              };
            } else {
              return {
                ...prev,
                connect: data[0].map((item) => item.date),
                connect2: data[1].map((item) => item.date),
              };
            }
          });
        });
    }
  }, [connectFilter.page, connectFilter.page2, connectFilter.filter, connectFilter]);

  // page_detention
  useEffect(() => {
    if (status.detention) {
      const frm = new FormData();

      frm.append("mode", "Update");
      frm.append("page", "page_connect");
      frm.append("date", detentionFilter.date);
      frm.append("date_type", getTextFilter(detentionFilter));
      frm.append("type", "page_detention");
      frm.append("page_type", detentionFilter.page);

      axios
        .post("https://ai.semos.kr/semos_admin/board/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          const { data } = res;

          setValueList((prev) => ({
            ...prev,
            detention: data.map((item) => item.value),
          }));

          setLabelsList((prev) => {
            if (detentionFilter.filter === "w") {
              return {
                ...prev,
                detention: data.map(
                  (item) =>
                    `${transformDate(item.start_date)} ~ ${transformDate(
                      item.finish_date
                    )}`
                ),
              };
            } else {
              return {
                ...prev,
                detention: data.map((item) => item.date),
              };
            }
          });
        });
    }
  }, [detentionFilter.page, detentionFilter.filter, detentionFilter]);

    // page_journey
    useEffect(() => {
        if (status.journey) {
          const frm = new FormData();
    
          frm.append("mode", "Update");
          frm.append("page", "page_connect");
          frm.append("date", journeyFilter.date);
          frm.append("date_type", getTextFilter(journeyFilter));
          frm.append("type", "journey");
          frm.append("page_type", journeyFilter.type);
    
          axios
            .post("https://ai.semos.kr/semos_admin/board/data", frm, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
              const { data } = res;
    
              setValueList((prev) => ({
                ...prev,
                journey: data.map((item) => Math.round(item.value)),
              }));
    
              setLabelsList((prev) => {
                  return {
                    ...prev,
                    journey: data.map((item) => item.type),
                  };
              });
            });
        }
      }, [journeyFilter.type, journeyFilter.filter, journeyFilter]);

  // ranking: lesson
  useEffect(() => {
    if (status.lessonAndPartner) {
      const frm = new FormData();

      frm.append("mode", "Update");
      frm.append("page", "page_connect");
      frm.append("date", rankingFilter.date);
      frm.append("date_type", getTextFilter(rankingFilter));
      frm.append("type", "lesson");

      axios
        .post("https://ai.semos.kr/semos_admin/board/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          const { data } = res;

          setValueList((prev) => ({
            ...prev,
            lesson: data.map((item) => ({
              type: item.type,
              partner_name: item.partner_name,
              value: item.value,
            })),
          }));
        });
    }
  }, [rankingFilter]);

  // ranking: partner
  useEffect(() => {
    if (status.lessonAndPartner) {
      const frm = new FormData();

      frm.append("mode", "Update");
      frm.append("page", "page_connect");
      frm.append("date", rankingFilter.date);
      frm.append("date_type", getTextFilter(rankingFilter));
      frm.append("type", "partner");

      axios
        .post("https://ai.semos.kr/semos_admin/board/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          const { data } = res;

          setValueList((prev) => ({
            ...prev,
            partner: data.map((item) => ({
              type: item.type,
              value: item.value,
            })),
          }));
        });
    }
  }, [rankingFilter]);


  // first event
  useEffect(() => {
    if (status.event) {
      const frm = new FormData();

      frm.append("mode", "Update");
      frm.append("page", "page_connect");
      frm.append("date", eventFilter.date);
      frm.append("date_type", getTextFilter(eventFilter));
      frm.append("type", "event");

      axios
        .post("https://ai.semos.kr/semos_admin/board/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          const { data } = res;

          setValueList((prev) => ({
            ...prev,
            event: data.map((item) => Math.round(item.value)),
          }));

          setLabelsList((prev) => {
              return {
                ...prev,
                event: data.map((item) => item.type),
              };
          });
        });
    }
  }, [eventFilter]);

  return (
    <DashboardLayout>

      <Container>
        <TransitionAndTime>
          <TransitionBox>
            <TitleBox
              title="접속 추이"
              unit={{ text: "단위", value: "회" }}
            >

            <QuestionContainer2 left='160px'>
                <img src={QuestionMark} />

                <div>

                    <span className="noticeBubble font_13">우측 드롭다운 메뉴에서 1개 페이지를 선택하여 중복 접속 추이를 확인하세요.<br></br> 또는, 2개 페이지를 선택하여 첫번째 페이지로부터 두번째 페이지로 유입되는 양을 확인하세요.</span>

                </div>
            </QuestionContainer2>

            </TitleBox>

            <TransitionWrapper>
              <BarChart
                list={valueList.connect}
                labels={labelsList.connect}
                style={{ width: "100%", height: "calc(90% - 50px)" }}
              />
            </TransitionWrapper>
          </TransitionBox>

          <TimeBox>
            <TitleBox
              title="접속 유입"
              unit={{ text: "단위", value: "회" }}
            ></TitleBox>

            <TimeWrapper>
              <BarChart
                list={valueList.connect2}
                labels={labelsList.connect}
                style={{ width: "100%", height: "calc(90% - 50px)" }}
              />
            </TimeWrapper>

          </TimeBox>

          <Footer>
              {(connectFilter.filter === "d" ||
                connectFilter.filter === "w") && (
                <DateInput
                  width="180px"
                  type="date"
                  name="date"
                  min="2021-05-01"
                  max={`${today.getFullYear()}-${
                    today.getMonth() + 1 < 10
                      ? `0${today.getMonth() + 1}`
                      : today.getMonth() + 1
                  }-${
                    today.getDate() < 10
                      ? `0${today.getDate()}`
                      : today.getDate()
                  }`}
                  value={connectFilter.date}
                  changeHandler={(e) => {
                    setStatus((prev) => ({
                      ...prev,
                      connectAndDetention: true,
                    }));
                    connectChangeHandler(e);
                  }}
                />
              )}
              {connectFilter.filter === "m" && (
                <DateInput
                  width="180px"
                  type="month"
                  name="date"
                  min="2021-05"
                  max={`${today.getFullYear()}-${
                    today.getMonth() + 1 < 10
                      ? `0${today.getMonth() + 1}`
                      : today.getMonth() + 1
                  }`}
                  value={connectFilter.date}
                  changeHandler={(e) => {
                    setStatus((prev) => ({
                      ...prev,
                      connectAndDetention: true,
                    }));
                    connectChangeHandler(e);
                  }}
                />
              )}

              <Select
                border={false}
                isTransparent={true}
                width="110px"
                name="filter"
                value={connectFilter.filter}
                changeHandler={(e) => {
                  setStatus((prev) => ({
                    ...prev,
                    connectAndDetention: true,
                  }));
                  connectChangeHandler(e);
                }}
                optionList={[
                  {
                    text: "일간",
                    value: "d",
                  },
                  {
                    text: "주간",
                    value: "w",
                  },
                  {
                    text: "월간",
                    value: "m",
                  },
                ]}
              />

              <Select
                border={false}
                isTransparent={true}
                width="200px"
                name="page"
                value={connectFilter.page}
                changeHandler={(e) => {
                  setStatus((prev) => ({
                    ...prev,
                    connectAndDetention: true,
                  }));
                  connectChangeHandler(e);
                }}
                optionList={[
                  {
                    text: "홈",
                    value: "home",
                  },
                  {
                    text: "레슨 나열",
                    value: "product",
                  },
                  {
                    text: "레슨",
                    value: "coaching",
                  },
                  {
                    text: "강사",
                    value: "partner",
                  },
                  {
                    text: "회원가입",
                    value: "register",
                  },
                  {
                    text: "공지사항",
                    value: "notice",
                  },
                  {
                    text: "결제",
                    value: "payment",
                  },
                  {
                    text: "문의",
                    value: "question_list",
                  },
                  {
                    text: "검색",
                    value: "search",
                  },
                  {
                    text: "기획전",
                    value: "event",
                  },
                  /*{
                    text: "레슨 리포트",
                    value: "coaching_report_list",
                  },
                  {
                    text: "로그북",
                    value: "log_book",
                  },*/
                  {
                    text: "내 레슨",
                    value: "my_lesson",
                  },
                  {
                    text: "리뷰",
                    value: "review",
                  },
                  {
                    text: "마이 페이지",
                    value: "my_semos",
                  },
                  {
                    text: "포인트",
                    value: "my_point",
                  },
                ]}
              />

            <Select
                border={false}
                isTransparent={true}
                width="200px"
                name="page2"
                value={connectFilter.page2}
                changeHandler={(e) => {
                  setStatus((prev) => ({
                    ...prev,
                    connectAndDetention: true,
                  }));
                  connectChangeHandler(e);
                }}
                optionList={[
                  {
                    text: "홈",
                    value: "home",
                  },
                  {
                    text: "레슨 나열",
                    value: "product",
                  },
                  {
                    text: "레슨",
                    value: "coaching",
                  },
                  {
                    text: "강사",
                    value: "partner",
                  },
                  {
                    text: "회원가입",
                    value: "register",
                  },
                  {
                    text: "공지사항",
                    value: "notice",
                  },
                  {
                    text: "결제",
                    value: "payment",
                  },
                  {
                    text: "문의",
                    value: "question_list",
                  },
                  {
                    text: "검색",
                    value: "search",
                  },
                  {
                    text: "기획전",
                    value: "event",
                  },
                  /*{
                    text: "레슨 리포트",
                    value: "coaching_report_list",
                  },
                  {
                    text: "로그북",
                    value: "log_book",
                  },*/
                  {
                    text: "내 레슨",
                    value: "my_lesson",
                  },
                  {
                    text: "리뷰",
                    value: "review",
                  },
                  {
                    text: "마이 페이지",
                    value: "my_semos",
                  },
                  {
                    text: "포인트",
                    value: "my_point",
                  },
                ]}
              />
            </Footer>
        </TransitionAndTime>

    <AUSection>
        <RankingSection>
          <TitleBox title="첫 클릭" unit={{ text: "단위", value: "회" }}>

          <QuestionContainer left='140px'>
                <img src={QuestionMark} />

                <div>

                    <span className="noticeBubble font_13">이용자가 앱을 실행한 후 발생시키는 첫 이벤트의 클릭 순위를 주차 별로 확인하세요.<br></br> 첫 이벤트는: 종목 선택, 검색창, 키워드검색, 지역검색, 내주변검색, 기획전 탭, 배너, 추천레슨이 있습니다.</span>

                </div>
            </QuestionContainer>

            <FlexWrapper>
              {(eventFilter.filter === "d" ||
                eventFilter.filter === "w") && (
                <DateInput
                  width="180px"
                  type="date"
                  name="date"
                  min="2021-05-01"
                  max={`${today.getFullYear()}-${
                    today.getMonth() + 1 < 10
                      ? `0${today.getMonth() + 1}`
                      : today.getMonth() + 1
                  }-${
                    today.getDate() < 10
                      ? `0${today.getDate()}`
                      : today.getDate()
                  }`}
                  value={eventFilter.date}
                  changeHandler={(e) => {
                    eventChangeHandler(e);
                    setStatus((prev) => ({
                      ...prev,
                      event: true,
                    }));
                  }}
                />
              )}

              {eventFilter.filter === "m" && (
                <DateInput
                  width="180px"
                  type="month"
                  name="date"
                  min="2021-05"
                  max={`${today.getFullYear()}-${
                    today.getMonth() + 1 < 10
                      ? `0${today.getMonth() + 1}`
                      : today.getMonth() + 1
                  }`}
                  value={eventFilter.date}
                  changeHandler={(e) => {
                    eventChangeHandler(e);
                    setStatus((prev) => ({
                      ...prev,
                      event: true,
                    }));
                  }}
                />
              )}

              <Select
                border={false}
                isTransparent={true}
                width="90px"
                name="filter"
                value={eventFilter.filter}
                changeHandler={(e) => {
                  eventChangeHandler(e);
                  setStatus((prev) => ({
                    ...prev,
                    event: true,
                  }));
                }}
                optionList={[
                  {
                    text: "일간",
                    value: "d",
                  },
                  {
                    text: "주간",
                    value: "w",
                  },
                  {
                    text: "월간",
                    value: "m",
                  },
                ]}
              />
            </FlexWrapper>
          </TitleBox>

     
          <DoughnutWrapper>
            {valueList.event?.length > 0 && (
              <DougnutChart
                labels={labelsList.event}
                list={valueList.event}
                originData={labelsList.event}
                style={{ width: "100%", height: "100%" }}
              />
            )}
          </DoughnutWrapper>
          
        </RankingSection>


        <RankingSection2>
          <TitleBox title="레슨/강사 순위">
            <FlexWrapper>
              {(rankingFilter.filter === "d" ||
                rankingFilter.filter === "w") && (
                <DateInput
                  width="180px"
                  type="date"
                  name="date"
                  min="2021-05-01"
                  max={`${today.getFullYear()}-${
                    today.getMonth() + 1 < 10
                      ? `0${today.getMonth() + 1}`
                      : today.getMonth() + 1
                  }-${
                    today.getDate() < 10
                      ? `0${today.getDate()}`
                      : today.getDate()
                  }`}
                  value={rankingFilter.date}
                  changeHandler={(e) => {
                    rankingChangeHandler(e);
                    setStatus((prev) => ({
                      ...prev,
                      lessonAndPartner: true,
                    }));
                  }}
                />
              )}

              {rankingFilter.filter === "m" && (
                <DateInput
                  width="180px"
                  type="month"
                  name="date"
                  min="2021-05"
                  max={`${today.getFullYear()}-${
                    today.getMonth() + 1 < 10
                      ? `0${today.getMonth() + 1}`
                      : today.getMonth() + 1
                  }`}
                  value={rankingFilter.date}
                  changeHandler={(e) => {
                    rankingChangeHandler(e);
                    setStatus((prev) => ({
                      ...prev,
                      lessonAndPartner: true,
                    }));
                  }}
                />
              )}

              <Select
                border={false}
                isTransparent={true}
                width="90px"
                name="filter"
                value={rankingFilter.filter}
                changeHandler={(e) => {
                  rankingChangeHandler(e);
                  setStatus((prev) => ({
                    ...prev,
                    lessonAndPartner: true,
                  }));
                }}
                optionList={[
                  {
                    text: "일간",
                    value: "d",
                  },
                  {
                    text: "주간",
                    value: "w",
                  },
                  {
                    text: "월간",
                    value: "m",
                  },
                ]}
              />

            <Select
                border={false}
                isTransparent={true}
                width="90px"
                name="type"
                value={rankingFilter.type}
                changeHandler={(e) => {
                  rankingChangeHandler(e);
                  setStatus((prev) => ({
                    ...prev,
                    lessonAndPartner: true,
                  }));
                }}
                optionList={[
                  {
                    text: "레슨",
                    value: "lesson",
                  },
                  {
                    text: "강사",
                    value: "partner",
                  }
                ]}
              />
            </FlexWrapper>
          </TitleBox>

          <Wrapper>
            <ol>
              <Title>{rankingFilter.type == 'lesson' ? '레슨' : '강사'}</Title>

              {valueList[rankingFilter.type]?.map((item, idx) => (
                <li key={idx}>
                  <p>
                    {idx + 1}. {item.type}
                  </p>
                  <p>{item.value}</p>
                </li>
              ))}
            </ol>

            {/*<ol>
              <Title>강사</Title>

              {valueList.partner?.map((item, idx) => (
                <li key={idx}>
                  <p>
                    {idx + 1}. {item.type}
                  </p>
                  <p>{item.value}</p>
                </li>
              ))}
            </ol>*/}
          </Wrapper>
        </RankingSection2>
    </AUSection>
        <TransitionAndTime2>

          <TimeBox2>
            <TitleBox
              title="체류 시간"
              unit={{ text: "단위", value: "초" }}
            >

            <QuestionContainer left='160px'>
                <img src={QuestionMark} />

                <div>

                    <span className="noticeBubble font_13">페이지별로 월간, 주간, 일간 평균 체류시간 추이와, 0~2분 사이 10초 간격으로 체류시간의 분포도를 확인해 보세요.</span>

                </div>
            </QuestionContainer>

             <FlexWrapper>
             {(detentionFilter.filter === "d" ||
                detentionFilter.filter === "w") && (
                <DateInput
                  width="180px"
                  type="date"
                  name="date"
                  min="2021-05-01"
                  max={`${today.getFullYear()}-${
                    today.getMonth() + 1 < 10
                      ? `0${today.getMonth() + 1}`
                      : today.getMonth() + 1
                  }-${
                    today.getDate() < 10
                      ? `0${today.getDate()}`
                      : today.getDate()
                  }`}
                  value={detentionFilter.date}
                  changeHandler={(e) => {
                    setStatus((prev) => ({
                      ...prev,
                      detention: true,
                    }));
                    detentionChangeHandler(e);
                  }}
                />
              )}
              {detentionFilter.filter === "m" && (
                <DateInput
                  width="180px"
                  type="month"
                  name="date"
                  min="2021-05"
                  max={`${today.getFullYear()}-${
                    today.getMonth() + 1 < 10
                      ? `0${today.getMonth() + 1}`
                      : today.getMonth() + 1
                  }`}
                  value={detentionFilter.date}
                  changeHandler={(e) => {
                    setStatus((prev) => ({
                      ...prev,
                      detention: true,
                    }));
                    detentionChangeHandler(e);
                  }}
                />
              )}

              <Select
                border={false}
                isTransparent={true}
                width="110px"
                name="filter"
                value={detentionFilter.filter}
                changeHandler={(e) => {
                  setStatus((prev) => ({
                    ...prev,
                    detention: true,
                  }));
                  detentionChangeHandler(e);
                }}
                optionList={[
                  {
                    text: "일간",
                    value: "d",
                  },
                  {
                    text: "주간",
                    value: "w",
                  },
                  {
                    text: "월간",
                    value: "m",
                  },
                ]}
              />

              {/*<Select
                border={false}
                isTransparent={true}
                width="110px"
                name="type"
                value={detentionFilter.filter}
                changeHandler={(e) => {
                  setStatus((prev) => ({
                    ...prev,
                    connectAndDetention: true,
                  }));
                  detentionChangeHandler(e);
                }}
                optionList={[
                  {
                    text: "추이",
                    value: "count",
                  },
                  {
                    text: "분포도",
                    value: "range",
                  }
                ]}
                />*/}

              <Select
                border={false}
                isTransparent={true}
                width="200px"
                name="page"
                value={detentionFilter.page}
                changeHandler={(e) => {
                  setStatus((prev) => ({
                    ...prev,
                    detention: true,
                  }));
                  detentionChangeHandler(e);
                }}
                optionList={[
                  {
                    text: "홈",
                    value: "home",
                  },
                  {
                    text: "레슨 나열",
                    value: "product",
                  },
                  {
                    text: "레슨",
                    value: "coaching",
                  },
                  {
                    text: "강사",
                    value: "partner",
                  },
                  {
                    text: "회원가입",
                    value: "register",
                  },
                  {
                    text: "공지사항",
                    value: "notice",
                  },
                  {
                    text: "결제",
                    value: "payment",
                  },
                  {
                    text: "문의",
                    value: "question_list",
                  },
                  {
                    text: "검색",
                    value: "search",
                  },
                  {
                    text: "기획전",
                    value: "event",
                  },
                  /*{
                    text: "레슨 리포트",
                    value: "coaching_report_list",
                  },
                  {
                    text: "로그북",
                    value: "log_book",
                  },*/
                  {
                    text: "내 레슨",
                    value: "my_lesson",
                  },
                  {
                    text: "리뷰",
                    value: "review",
                  },
                  {
                    text: "마이 페이지",
                    value: "my_semos",
                  },
                  {
                    text: "포인트",
                    value: "my_point",
                  },
                ]}
              />
             </FlexWrapper>

            </TitleBox>

            <TimeWrapper2>
              <BarChart
                list={valueList.detention}
                labels={labelsList.detention}
                style={{ width: "100%", height: "calc(100% - 50px)" }}
              />
            </TimeWrapper2>

            <Footer>
           
            </Footer>
          </TimeBox2>
        </TransitionAndTime2>

        <TransitionAndTime2>

            <TimeBox2>
            <TitleBox
                title="구매 여정"
                unit={{ text: "단위", value: "회" }}
            >
            <QuestionContainer left='160px'>
                <img src={QuestionMark} />

                <div>

                    <span className="noticeBubble font_13">레슨을 구매한 이용자들이 구매하기 전까지 방문했던 페이지들의 총 (중복) 접속 수, 평균 접속 수, 평균 스크롤뎁스, 그리고 평균 체류시간을 확인하여 구매 여정을 파악해 보세요.</span>

                </div>
            </QuestionContainer>

            <FlexWrapper>
            {(journeyFilter.filter === "d" ||
                journeyFilter.filter === "w") && (
                <DateInput
                    width="180px"
                    type="date"
                    name="date"
                    min="2021-05-01"
                    max={`${today.getFullYear()}-${
                    today.getMonth() + 1 < 10
                        ? `0${today.getMonth() + 1}`
                        : today.getMonth() + 1
                    }-${
                    today.getDate() < 10
                        ? `0${today.getDate()}`
                        : today.getDate()
                    }`}
                    value={journeyFilter.date}
                    changeHandler={(e) => {
                    setStatus((prev) => ({
                        ...prev,
                        journey: true,
                    }));
                    journeyChangeHandler(e);
                    }}
                />
                )}
                {journeyFilter.filter === "m" && (
                <DateInput
                    width="180px"
                    type="month"
                    name="date"
                    min="2021-05"
                    max={`${today.getFullYear()}-${
                    today.getMonth() + 1 < 10
                        ? `0${today.getMonth() + 1}`
                        : today.getMonth() + 1
                    }`}
                    value={journeyFilter.date}
                    changeHandler={(e) => {
                    setStatus((prev) => ({
                        ...prev,
                        journey: true,
                    }));
                    journeyChangeHandler(e);
                    }}
                />
                )}

                <Select
                border={false}
                isTransparent={true}
                width="110px"
                name="filter"
                value={journeyFilter.filter}
                changeHandler={(e) => {
                    setStatus((prev) => ({
                    ...prev,
                    journey: true,
                    }));
                    journeyChangeHandler(e);
                }}
                optionList={[
                    {
                    text: "일간",
                    value: "d",
                    },
                    {
                    text: "주간",
                    value: "w",
                    },
                    {
                    text: "월간",
                    value: "m",
                    },
                ]}
                />

                {/*<Select
                border={false}
                isTransparent={true}
                width="110px"
                name="type"
                value={detentionFilter.filter}
                changeHandler={(e) => {
                    setStatus((prev) => ({
                    ...prev,
                    connectAndDetention: true,
                    }));
                    detentionChangeHandler(e);
                }}
                optionList={[
                    {
                    text: "추이",
                    value: "count",
                    },
                    {
                    text: "분포도",
                    value: "range",
                    }
                ]}
                />*/}

                <Select
                border={false}
                isTransparent={true}
                width="200px"
                name="type"
                value={journeyFilter.type}
                changeHandler={(e) => {
                    setStatus((prev) => ({
                    ...prev,
                    journey: true,
                    }));
                    journeyChangeHandler(e);
                }}
                optionList={[
                    {
                    text: "총 접속 수",
                    value: "view_count",
                    },
                    {
                    text: "평균 접속 수",
                    value: "view_round",
                    },
                    {
                    text: "평균 체류시간",
                    value: "detention",
                    }
                ]}
                />
            </FlexWrapper>

            </TitleBox>

            <TimeWrapper2>
                <BarChart
                list={valueList.journey}
                labels={labelsList.journey}
                style={{ width: "100%", height: "calc(100% - 50px)" }}
                />
            </TimeWrapper2>

            <Footer>
            
            </Footer>
            </TimeBox2>
        </TransitionAndTime2>
      
      </Container>
    </DashboardLayout>
  );
};

export default PageTab;
