import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import BaseBox from "../../components/partner/profile/base";
import BgBox from "../../components/partner/profile/bg";
import CareerBox from "../../components/partner/profile/career";
//import LessonBox from "../../components/partner/profile/lesson";
import partnerProfileData from "../../data/partner/profile";
//import useImage from "../../hooks/useImage";
import useInput from "../../hooks/useInput";
import useList from "../../hooks/useList";
import MainLayout from "../../layouts/mainLayout";
import Button from "../../modules/button";
import colors from "../../styles/constants/colors";
import typography from "../../styles/constants/typography";
import flexbox from "../../styles/func/flexbox";
import CropperPopup from "../../components/cropperPopup";
import LoadingSpinner from "../../components/loadingSpinner";

// icon
import PartnerId from "../../assets/icon/person-badge.svg";
import PartnerPhone from "../../assets/icon/telephone.svg";
import DropCaret from "../../assets/icon/caret-down-fill.svg";

const Main = styled.main`
  width: 100%;
  height: 100%;
  padding: 0 20px 20px;
  overflow-y: scroll;

  section {
    margin-bottom: 50px;

    header {
      ${flexbox("flex-start")}
      margin-bottom: 20px;

      p {
        font-size: ${typography.size.medium}px;
        font-weight: ${typography.weight.bold};
        padding-right: 10px;
      }

      span {
        color: ${colors.gray};
        font-size: ${typography.size.small}px;
      }
    }
  }
`;

const BaseSection = styled.section``;

const BgSection = styled.section``;

const CareerSection = styled.section`
  width: 100%;

  header {
    width: 100%;
    display: flex;
    justify-content: space-between !important;

    div {
      display: inline-flex;
      align-items: center;
    }

    button {
      color: ${colors.white};
      background-color: ${colors.blue};
      padding: 5px 15px;
      border-radius: 40px;
      justify-self: end;
    }
  }
`;

const LessonSection = styled.section``;

const Footer = styled.footer`
  width: 100%;
  height: 90px;
  padding: 20px;
  bottom: 0;
  ${flexbox("flex-end")}
  position: absolute;
  background-color: ${colors.white};

  button:first-child,
  button:nth-child(2) {
    margin-right: 10px;
  }
`;

const InfoTextArea = styled.textarea`
  width: 100%;
  display: inline-block;
  border: 1px solid #dedede;
  background-color: #fff;
  height: 150px;
  border-radius: 16px;
  resize: none;
  padding: 10px 20px;
`;

const PartnerProfilePage = () => {
  const [change, setChange] = useState(false);

  const { pid } = useParams();

  const navigate = useNavigate();

  const { base, career } = partnerProfileData;

  const [imgPopup, setImgPopup] = useState(false);

  const [cropType, setCropType] = useState("");

  const [imgScale, setImgScale] = useState({
    width: 1,
    height: 1,
  });

  const [cropImg, setCropImg] = useState("");

  const [nameSearchText, setNameSearchText] = useState("");

  const [partnerId, setPartnerId] = useState({
    status: false,
    nickname: "",
    realName: "",
    phone: "",
    token: "",
  });

  const [idSearch, setIdSearch] = useState([]);

  const [loading, setLoading] = useState(false);

  /*const {
    value: baseValue,
    change: baseChange,
    inputChangeHandler: baseInputChangeHandler,
  } = useInput({
    main: base.main,
    sub: "프리다이빙",
    title: "핸강사(김현주)",
  });

  const {
    image,
    change: imageChange,
    imageChangeHandler,
  } = useImage({
    base: {
      base: "",
      files: "",
    },
    "bg-first": {
      base: "",
      files: "",
    },
    "bg-second": {
      base: "",
      files: "",
    },
    "bg-third": {
      base: "",
      files: "",
    },
  });*/

  /*const {
    list: lessonImage,
    setList: setLessonImage,
    deleteHandler: lessongDeleteHandler,
    addHandler: lessonAddHandler,
  } = useList([]);*/

  const {
    value: careerInputValue,
    change: careerChange,
    inputChangeHandler: careerInputChangeHandler,
    setValue: setCareerValue,
  } = useInput({
    career: "",
  });

  const {
    list: careerList,
    deleteHandler: careerDeleteHandler,
    addHandler: careerAddHandler,
  } = useList(career);

  const saveHandler = () => {
    if (window.confirm("프로필을 저장하시겠습니까?")) {
      setLoading(true);
      var token = window.localStorage.getItem("token");

      axios
        .post(
          "https://ai.semos.kr/semos_admin/partner/data",
          {
            token: token,
            mode: "edit",
            number: pid,
            profile_data: partner,
            partner_id: partnerId,
            //delete_picture : deletePicture
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((data) => {
          console.log(data);
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            setLoading(false);
            //setPartner(Value.partner_data)
            alert("프로필 저장이 완료되었습니다.");
            console.log(Value);
          } else if (status == "waring") {
            setLoading(false);
            alert(statusMessage);
          }
        });
    }
  };

  const registerHandler = () => {
    if (window.confirm("프로필을 등록하시겠습니까?")) {
      var token = window.localStorage.getItem("token");

      axios
        .post(
          "https://ai.semos.kr/semos_admin/partner/data",
          {
            token: token,
            mode: "registerCheck",
            number: pid,
            profile_data: partner,
            partner_id: partnerId,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            //setPartner(Value.partner_data)
            alert("프로필 등록이 완료되었습니다.");
            window.location.replace(`/partner/${Value.partner_number}`);
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    }
  };

  const goToRegionListHandler = () => {
    navigate(`/partner/${pid}/location`);
  };

  const goToLessonHandler = () => {
    navigate(`/partner/${pid}/lesson_list/1`);
  };

  const careerListAddHandler = () => {
    if (careerInputValue.career) {
      var facility_data = partner;
      var facility_data_list = facility_data.facility;

      facility_data_list.push(careerInputValue.career);

      facility_data.facility = facility_data_list;

      setPartner(facility_data);

      setCareerValue({
        career: "",
      });
    } else {
      alert("강사 경력을 입력해주세요!");
    }
  };

  const careerListDeleteHandler = (number) => {
    var facility_data = partner;
    var facility_data_list = facility_data.facility;

    facility_data_list.splice(number, 1);

    facility_data.facility = facility_data_list;

    setPartner({ ...partner, facility_data });
  };

  const CropImg = (value, type) => {
    switch (type) {
      case "profile_img":
        setPartner((prev) => ({
          ...prev,
          profile_img: value,
        }));
        setImgPopup(false);
        setImgScale({
          width: 1,
          height: 1,
        });
        setCropImg("");
        setCropType("");
        break;

      case "backgroundImg_1":
        var partner_data = partner.img_list;
        partner_data[0] = value;

        setPartner({ ...partner, img_list: partner_data });

        setImgPopup(false);
        setImgScale({
          width: 1,
          height: 1,
        });
        setCropImg("");
        setCropType("");
        break;

      case "backgroundImg_2":
        var partner_data = partner.img_list;
        partner_data[1] = value;

        setPartner({ ...partner, img_list: partner_data });

        setImgPopup(false);
        setImgScale({
          width: 1,
          height: 1,
        });
        setCropImg("");
        setCropType("");
        break;

      case "backgroundImg_3":
        var partner_data = partner.img_list;
        partner_data[2] = value;

        setPartner({ ...partner, img_list: partner_data });

        setImgPopup(false);
        setImgScale({
          width: 1,
          height: 1,
        });
        setCropImg("");
        setCropType("");
        break;
    }
  };

  /*const pictureListDeleteHandler = (number) => {

      var picture_data = partner
      var picture_data_list = picture_data.picture_list

      let deletePictureData = picture_data_list[number]
      var delete_picture_list = [...deletePicture]

      delete_picture_list[delete_picture_list.length] = deletePictureData

      setDeletePicture(delete_picture_list)

      picture_data_list.splice(number, 1)

      picture_data.picture_list = picture_data_list

      setPartner({...partner, picture_data})

  };*/

  /*useEffect(() => {
    if (baseChange || careerChange || imageChange) {
      setChange(true);
    }
  }, [baseChange, careerChange, imageChange]);*/

  const [partner, setPartner] = useState([]);
  const [deletePicture, setDeletePicture] = useState([]);

  const ValueChange = (type, value) => {
    setPartner((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const ValueChange2 = (value) => {
    setImgPopup(true);
    setImgScale({
      width: 1,
      height: 1,
    });
    setCropImg(value);
    setCropType("profile_img");

    /*setPartner((prev) => ({
          ...prev,
          profile_img: value,
        }));*/
  };

  const ImgChange = (number, value) => {
    var partner_data = partner.img_list;
    partner_data[number] = value;

    setPartner({ ...partner, img_list: partner_data });
  };

  const ImgChange1 = (value) => {
    setImgPopup(true);
    setImgScale({
      width: 8,
      height: 5,
    });
    setCropImg(value);
    setCropType("backgroundImg_1");

    /*var partner_data = partner.img_list
        partner_data[0] = value

        setPartner({...partner, img_list : partner_data})*/
  };

  const ImgChange2 = (value) => {
    setImgPopup(true);
    setImgScale({
      width: 8,
      height: 5,
    });
    setCropImg(value);
    setCropType("backgroundImg_2");

    /*var partner_data = partner.img_list
        partner_data[1] = value

        setPartner({...partner, img_list : partner_data})*/
  };

  const ImgChange3 = (value) => {
    setImgPopup(true);
    setImgScale({
      width: 8,
      height: 5,
    });
    setCropImg(value);
    setCropType("backgroundImg_3");

    /*var partner_data = partner.img_list
        partner_data[2] = value

        setPartner({...partner, img_list : partner_data})*/
  };

  const ImgChangeLesson = (number, value) => {
    var partner_data = partner.picture_list;
    partner_data[number] = {
      number: "new",
      img: value,
    };

    setPartner({ ...partner, picture_list: partner_data });
  };

  // 파트너 상세 로드
  useEffect(() => {
    if (pid == "register") {
      var partnerProfileData = {
        facility: [],
        img_list: [],
        info_img: "",
        name: "",
        picture_list: [],
        profile_img: "",
        sport: "",
        sport_category: "",
        status: "",
        partner_id: "",
        partner_nickname: "",
        partner_real_name: "",
        phone: "",
      };

      setPartner(partnerProfileData);
    } else {
      var token = window.localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "specific");
      frm.append("token", token);
      frm.append("number", pid);

      axios
        .post("https://ai.semos.kr/semos_admin/partner/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            setPartner(Value.partner_data);
            setPartnerId({
              status: false,
              nickname: Value.partner_data.partner_nickname,
              realName: Value.partner_data.partner_real_name,
              phone: Value.partner_data.phone,
              token: Value.partner_data.partner_id,
            });
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    }
  }, []);

  const setImageFromFile = ({ file, setImageUrl }) => {
    var uploadFileSize = file.size;

    var maxFileSize = 1024 * 1024 * 5;

    let reader = new FileReader();
    reader.onload = function () {
      setImageUrl({ result: reader.result });
    };

    reader.readAsDataURL(file);
  };

  const partnerConfirm = () => {
    if (window.confirm("승인하시겠습니까?")) {
      var token = window.localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "register_confirm");
      frm.append("token", token);
      frm.append("number", pid);

      axios
        .post("https://ai.semos.kr/semos_admin/partner/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            alert(statusMessage);
            window.location.replace("/partner/" + pid);
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    }
  };

  const SetId = (name, nickname, phone, token) => {
    setPartnerId({
      status: false,
      nickname: nickname,
      realName: name,
      phone: phone,
      token: token,
    });
  };

  const NameSearch = (text) => {
    setNameSearchText(text);

    var token = window.localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", "partner_search");
    frm.append("token", token);
    frm.append("text", text);

    axios
      .post("https://ai.semos.kr/semos_admin/partner/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var status = Value.status;
        var statusMessage = Value.status_message;

        if (status == "success") {
          setIdSearch(Value.user_id_list);
        } else if (status == "waring") {
          alert(statusMessage);
        }
      });
  };

  const PartnerPhoneAdd = (phone) => {
    var valueCheck = String(phone)
      .replace(/[^0-9]/g, "")
      .replace(
        /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
        "$1-$2-$3"
      )
      .replace("--", "-");
    setPartner({ ...partner, phone: valueCheck });
  };

  const DeletePartner = () => {
    if (window.confirm("파트너를 삭제하시겠습니까?\n신중히 결정해주세요!")) {
      var token = window.localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "delete_partner");
      frm.append("token", token);
      frm.append("number", pid);

      axios
        .post("https://ai.semos.kr/semos_admin/partner/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            alert(statusMessage);
            window.location.replace("/partner_list/1/all/all/all/all/all");
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    }
  };

  return (
    <MainLayout>
      <LoadingSpinner show={loading} color={"rgba(0,149,255,1)"} />
      <Main>
        <BaseSection>
          <header>
            <p>기본 정보</p>
            <span>(1:1 비율 / JPG)</span>
          </header>

          <BaseBox
            value={partner}
            changeHandler={ValueChange}
            changeHandler2={ValueChange2}
            imagePro={setImageFromFile}
          />
        </BaseSection>

        <BgSection>
          <header>
            <p>강사 정보</p>
            <span></span>
          </header>

          <PartnerInfoContainer PIS={partnerId.status}>
            <div className="info_container">
              <input
                onChange={(e) => PartnerPhoneAdd(e.target.value)}
                maxLength="13"
                value={partner ? partner.phone : ""}
                className="info_input"
                type="text"
              />
              <img src={PartnerPhone} className="info_icon" />
            </div>

            <div className="info_container">
              <input
                readOnly
                value={`${partnerId.realName} (${partnerId.nickname})`}
                className="info_input_RO"
                type="text"
              />
              <img src={PartnerId} className="info_icon" />
              <img
                onClick={() =>
                  setPartnerId({ ...partnerId, status: !partnerId.status })
                }
                src={DropCaret}
                className="info_caret"
              />

              <div className="name_search">
                <input
                  placeholder="닉네임, 실명, 전화번호로 검색해주세요!"
                  className="name_search_input"
                  onChange={(e) => NameSearch(e.target.value)}
                  value={nameSearchText}
                  type="text"
                />

                <div className="scrollContainer">
                  {idSearch.map((item, idx) => (
                    <span
                      className={
                        partnerId.token == item.token ? "color_blue" : ""
                      }
                      onClick={() =>
                        SetId(
                          item.realName,
                          item.nickname,
                          item.phone,
                          item.token
                        )
                      }
                      key={`user_list_${idx}`}
                    >
                      {`${item.realName} (${item.nickname}) | ${item.phone} | ${item.tier_update}`}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </PartnerInfoContainer>
        </BgSection>

        <BgSection>
          <header>
            <p>강사 소개</p>
            <span></span>
          </header>

          <InfoTextArea
            placeholder="최대 900글자, 이모티콘 미지원"
            maxLength="900"
            onChange={(e) => setPartner({ ...partner, info: e.target.value })}
            value={partner ? partner.info : ""}
          />
        </BgSection>

        <BgSection>
          <header>
            <p>배경 사진</p>
            <span>(8:5 비율/JPG)</span>
          </header>

          <BgBox
            imageChangeHandler={ImgChange}
            imageChangeHandler1={ImgChange1}
            imageChangeHandler2={ImgChange2}
            imageChangeHandler3={ImgChange3}
            image={partner.img_list}
            imagePro={setImageFromFile}
          />
        </BgSection>

        <CareerSection>
          <header>
            <div>
              <p>강사 경력</p>
              <span>(최대 21자)</span>
            </div>

            <button type="button" onClick={careerListAddHandler}>
              + 추가하기
            </button>
          </header>

          <CareerBox
            careerList={partner.facility}
            inputValue={careerInputValue.career}
            inputChangeHandler={careerInputChangeHandler}
            setInputValue={setCareerValue}
            deleteHandler={careerListDeleteHandler}
          />
        </CareerSection>

        {/*<LessonSection>
          <header>
            <p>포토</p>
            <span>(5MB 이하/JPG)</span>
          </header>

          <LessonBox
            value={partner.picture_list}
            setValue={setLessonImage}
            deleteHandler={pictureListDeleteHandler}
            addHandler={ImgChangeLesson}
            imagePro={setImageFromFile}
          />
        </LessonSection>*/}
      </Main>

      <Footer>
        {/* pid !== 'register' && partner.confirm !== 'no' ? <Button
          text="기본 일정 등록하기"
          clickHandler={goToRegionListHandler}
          width={`230px`}
          color={colors.white}
          fontcolor={colors.gray}
        /> : "" */}

        {pid !== "register" ? (
          <Button
            text="파트너 삭제하기"
            clickHandler={DeletePartner}
            width={`230px`}
            color={colors.white}
            fontcolor={colors.red}
          />
        ) : (
          ""
        )}

        {pid !== "register" && partner.confirm !== "no" ? (
          <Button
            text="액티비티 확인하기"
            clickHandler={goToLessonHandler}
            width={`230px`}
            color={colors.white}
            fontcolor={colors.gray}
          />
        ) : (
          ""
        )}

        {pid !== "register" && partner.confirm !== "no" ? (
          <Button
            text="프로필 저장하기"
            clickHandler={saveHandler}
            width={`230px`}
            isChange={change}
          />
        ) : (
          ""
        )}

        {pid == "register" && partner.confirm !== "no" ? (
          <Button
            text="프로필 등록하기"
            clickHandler={registerHandler}
            width={`230px`}
            isChange={change}
          />
        ) : (
          ""
        )}

        {partner.confirm == "no" ? (
          <Button
            text="승인하기"
            clickHandler={partnerConfirm}
            width={`230px`}
          />
        ) : (
          ""
        )}
      </Footer>

      <CropperPopup
        cropType={cropType}
        status={imgPopup}
        OnOff={setImgPopup}
        WH={imgScale}
        imgData={cropImg}
        setImg={CropImg}
      />
    </MainLayout>
  );
};

export default PartnerProfilePage;

const PartnerInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .info_container {
    width: calc(50% - 10px);
    display: inline-flex;
    background-color: #fff;
    position: relative;

    .info_input {
      width: 100%;
      padding: 10px 50px 10px 45px;
      border: 2px solid #dedede;
      border-radius: 8px;
    }

    .info_input_RO {
      width: 100%;
      padding: 10px 50px 10px 45px;
      border: 2px solid #dedede;
      border-radius: ${({ PIS }) => (PIS ? "8px 8px 0 0" : "8px")};
    }

    .info_icon {
      position: absolute;
      top: 50%;
      left: 10px;
      width: 25px;
      display: inline-block;
      transform: translate(0, -50%);
    }

    .info_caret {
      position: absolute;
      top: 50%;
      right: 10px;
      width: 25px;
      display: inline-block;
      transform: translate(0, -50%)
        rotate(${({ PIS }) => (PIS ? "180deg" : "0")});
      transition: all 0.3s ease 0s;
      cursor: pointer;
    }

    .name_search {
      background-color: #fff;
      border-radius: 0 0 8px 8px;
      border: 2px solid #dedede;
      border-top: 2px solid transparent !important;
      width: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(0, 100%);
      display: ${({ PIS }) => (PIS ? "inline-flex" : "none")};
      flex-wrap: wrap;
      padding: 10px;

      .name_search_input {
        padding: 5px 0 !important;
        display: inline-block;
        width: 100%;
        border: unset;
        border-bottom: 1px solid #dedede !important;
        height: unset !important;
        border-radius: unset !important;
      }

      .scrollContainer {
        max-height: 180px;
        overflow: hidden auto;
        display: inline-block;
        width: 100%;
      }

      span {
        padding: 5px 0 !important;
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid #dedede !important;
        cursor: pointer;
      }
    }
  }
`;
