import styled from "styled-components";
import base from "../../styles/constants/base";
import colors from "../../styles/constants/colors";
import zIndex from "../../styles/constants/z-index";
import closeIcon from "../../assets/icon/close.svg";
import typography from "../../styles/constants/typography";
import flexbox from "../../styles/func/flexbox";

const Container = styled.div`
  width: 700px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${colors.white};
  border-radius: ${base.borderRadius.default}px;
  box-shadow: ${base.boxShadow.default};
  z-index: ${zIndex.modal};
  overflow: hidden;
`;

const Main = styled.main`
  width: 100%;
  padding: 20px;
`;

const ButtonBox = styled.div`
  border-top: ${`2px solid ${colors.lightGray}`};
  height: 60px;
  ${flexbox()}

  button {
    color: ${colors.blue};
  }
`;

const TwoButtonBox = styled.div`
  border-top: ${`2px solid ${colors.lightGray}`};
  height: 60px;

  button {
    width: 50%;
    height: 100%;

    &:first-child {
      color: ${colors.red};
      border-right: ${`2px solid ${colors.lightGray}`};
    }

    &:last-child {
      color: ${colors.blue};
      border-right: ${`2px solid ${colors.lightGray}`};
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
`;

const Title = styled.p`
  font-size: 22px;
  font-weight: ${typography.weight.bold};
  margin-bottom: 20px;
`;

const ModalLayout = ({
  children,
  title = "",
  buttonText,
  subButtonText = "",
  closeHandler,
  clickHandler,
  subButtonHandler,
}) => {
  return (
    <Container>
      <Main>
        {closeHandler && (
          <CloseButton type="button" onClick={closeHandler}>
            <img src={closeIcon} />
          </CloseButton>
        )}

        <Title>{title}</Title>

        {children}
      </Main>

      {!subButtonText && (
        <ButtonBox>
          <button onClick={clickHandler}>{buttonText}</button>
        </ButtonBox>
      )}

      {subButtonText && (
        <TwoButtonBox>
          <button type="button" onClick={subButtonHandler}>
            {subButtonText}
          </button>
          <button type="button" onClick={clickHandler}>
            {buttonText}
          </button>
        </TwoButtonBox>
      )}
    </Container>
  );
};

export default ModalLayout;
