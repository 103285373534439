import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import Calendar from "../../../components/calendar";
import CalendarAll from "../../../components/calendar/popup";
import ClockField from "../../../components/clockField";
import MainHeader from "../../../components/header";
import useInput from "../../../hooks/useInput";
import useList from "../../../hooks/useList";
import MainLayout from "../../../layouts/mainLayout";
import Button from "../../../modules/button";
import colors from "../../../styles/constants/colors";
import typography from "../../../styles/constants/typography";
import flexbox from "../../../styles/func/flexbox";

const getFilteredData = (scheduleData, targetDatetime) => {
  const { year, month, date } = targetDatetime;

  const index = `${year}-${
    parseInt(month) + 1 < 10 ? `0${month + 1}` : month + 1
  }-${parseInt(date) < 10 ? `0${date}` : date}`;

  return scheduleData[index];
};

const Main = styled.main`
  width: 100%;
  padding: 20px;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 120px;

  section {
    width: 100%;

    header {
      width: 100%;
      ${flexbox("space-between")};
      margin-bottom: 20px;

      time {
        font-size: ${typography.size.medium}px;
        font-weight: ${typography.weight.bold};
      }

      button {
        color: ${colors.gray};
      }
    }

    .header2 {
      width: 100%;
      display: flex;
      justify-content: end !important;
      align-items: center;
      flex-direction: row;
      margin-bottom: 20px;

      time {
        font-size: ${typography.size.medium}px;
        font-weight: ${typography.weight.bold};
      }

      button {
        color: ${colors.gray};
        margin-right: 20px;
      }
    }
  }
`;

const Title = styled.p`
  font-size: ${typography.size.medium}px;
  margin-bottom: 10px;
`;

const CalendarBox = styled.div`
  width: 100%;
  ${flexbox("center", "flex-start")}
`;

const ClockBox = styled.div`
  display: flex;

  p {
    margin-right: 50px;
    font-size: ${typography.size.medium}px;
  }
`;

const Footer = styled.div`
  width: 100%;
  ${flexbox("flex-end")}
  padding-right: 20px;
  height: 90px;
  background-color: ${colors.white};
  position: absolute;
  bottom: 0px;

  button:first-child {
    margin-right: 10px;
  }
`;

const DataPopup = styled.div`
  width: calc(100vw - 200px);
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  display: inline-flex;
  z-index: 100;
  align-items: center;
  justify-content: center;
  padding-top: 62px;
  padding-bottom: 20px;
`;

const DataPopupClose = styled.span`
  width: calc(100vw - 200px);
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  display: inline-flex;
  z-index: 101;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  cursor: pointer;
`;

const DataPopupContainer = styled.div`
  position: relative;
  max-width: 1050px;
  width: 100%;
  height: 870px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 5px,
    rgb(0 0 0 / 15%) -0.5px -0.5px 5px;
  padding: 20px 0;
  overflow: hidden;
  z-index: 102;
`;

const DataPopupHeader = styled.header`
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 26px;
  font-weight: 700;
`;

const week = ["일", "월", "화", "수", "목", "금", "토"];

const PartnerScheduleEditDetailPage = () => {
  const saveHandler = () => {
    console.log("일정 저장하기");
  };

  const scheduleData = {
    "2022-05-03": {
      time: [
        {
          id: 0,
          "start-hour": "08",
          "start-minute": "00",
          "finish-hour": "11",
          "finish-minute": "00",
        },
        {
          id: 1,
          "start-hour": "08",
          "start-minute": "00",
          "finish-hour": "11",
          "finish-minute": "00",
        },
      ],
    },
    "2022-05-15": {
      time: [
        {
          id: 0,
          "start-hour": "08",
          "start-minute": "00",
          "finish-hour": "11",
          "finish-minute": "00",
        },
        {
          id: 1,
          "start-hour": "08",
          "start-minute": "00",
          "finish-hour": "11",
          "finish-minute": "00",
        },
      ],
    },
    "2022-05-30": {
      time: [
        {
          id: 0,
          "start-hour": "08",
          "start-minute": "00",
          "finish-hour": "11",
          "finish-minute": "00",
        },
        {
          id: 1,
          "start-hour": "08",
          "start-minute": "00",
          "finish-hour": "11",
          "finish-minute": "00",
        },
      ],
    },
  };

  const currentDate = new Date();

  const [targetDatetime, setTargetDatetime] = useState({
    year: currentDate.getFullYear(),
    month: currentDate.getMonth(),
    date: currentDate.getDate(),
  });

  const [targetDay, setTargetDay] = useState(currentDate.getDate());

  const [targetDayAll, setTargetDayAll] = useState([]);

  const [targetDayAllList, setTargetDayAllList] = useState([]);

  const [timeList, setTimeList] = useState([]);

  const [timeList2, setTimeList2] = useState([]);

  const [targetDayList, setTargetDayList] = useState([]);

  const [targetDayData, setTargetDayData] = useState(
    getFilteredData(scheduleData, {
      year: targetDatetime.year,
      month: targetDatetime.month,
      date: targetDay,
    })
  );

  const targetDayDataChangeHandler = (day) => {
    setTargetDay(day);

    /*setTargetDayData(
      getFilteredData(scheduleData, {
        year: targetDatetime.year,
        month: targetDatetime.month,
        date: day,
      })
    );*/
    setTargetDatetime({ ...targetDatetime, date: day });
  };

  const CalendarChange = (yearValue, monthValue, dayValue) => {
    setTargetDatetime({
      ...targetDatetime,
      year: yearValue,
      month: monthValue,
      date: dayValue,
    });
  };

  /*const addTargetDayListHandler = (datetime) => {
    const index = `${datetime.year}-${
      parseInt(datetime.month) < 10 ? `0${datetime.month}` : datetime.month
    }-${parseInt(datetime.date) < 10 ? `0${datetime.date}` : datetime.date}`;

    if (targetDayList[index]) {
      setTargetDayList((prev) => {
        const updated = { ...prev };
        delete updated[index];
        return updated;
      });
    } else {
      setTargetDayList((prev) => ({
        ...prev,
        [index]: datetime,
      }));
    }
  };*/

  const {
    value: clockValue,
    setValue: setClockValue,
    inputChangeHandler: clockInputChangeHandler,
  } = useInput({
    "start-hour": "",
    "start-minute": "",
    "finish-hour": "",
    "finish-minute": "",
  });

  const {
    list: clockList,
    setList: setClockList,
    addHandler: addClockListHandler,
    deleteHandler: deleteClockHandler,
  } = useList([]);

  /*const resetHandler = () => {
    if (window.confirm("입력한 일정을 초기화하시겠습니까?")) {
      var token = window.localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "lesson_calendar");
      frm.append("token", token);
      frm.append("partner_number", pid);
      frm.append("lesson_number", lid);
      frm.append("year", targetDatetime.year);
      frm.append("month", targetDatetime.month + 1);
      frm.append("day", targetDatetime.date);

      axios
        .post("https://ai.semos.kr/semos_admin/partner/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            setSchedule(Value.date_array);
            setPartner(Value.partner);
            setLessonTime(Value.time_array);
            TimeListCreated(Value.time_array);
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    }
  };*/

  const addClockHandler = (e) => {
    const { name, value } = e.target;

    addClockListHandler({
      id: new Date(),
      ...clockValue,
    });

    setClockValue({
      "start-hour": "",
      "start-minute": "",
      "finish-hour": "",
      "finish-minute": "",
    });
  };

  /*useEffect(() => {
    if (targetDayData) {
      setClockList(targetDayData.time);
    } else {
      setClockList([]);
    }
  }, [targetDayData, targetDay]);*/

  const [schedule, setSchedule] = useState();

  const [lessonTime, setLessonTime] = useState();

  const [partner, setPartner] = useState();

  const [deleteList, setDeleteList] = useState([]);

  const [datePopup, setDatePopup] = useState(false);

  const [timeDefault, setTimeDefault] = useState([
    {
      start_time: "06",
      start_min: "00",
      finish_time: "06",
      finish_min: "00",
    },
  ]);

  const { pid, lid } = useParams();

  // 기본 일정 상세 로드
  useEffect(() => {
    var token = window.localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", "lesson_calendar");
    frm.append("token", token);
    frm.append("partner_number", pid);
    frm.append("lesson_number", lid);
    frm.append("year", targetDatetime.year);
    frm.append("month", targetDatetime.month + 1);
    frm.append("day", targetDatetime.date);

    axios
      .post("https://ai.semos.kr/semos_admin/partner/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var status = Value.status;
        var statusMessage = Value.status_message;

        if (status == "success") {
          setSchedule(Value.date_array);
          setPartner(Value.partner);
          setLessonTime(Value.time_array);
          TimeListCreated(Value.time_array);
        } else if (status == "waring") {
          alert(statusMessage);
        }
      });
  }, [targetDatetime.year, targetDatetime.month]);

  var DateFull =
    String(targetDatetime.year) +
    (targetDatetime.month + 1 < 10
      ? "0" + String(targetDatetime.month + 1)
      : String(targetDatetime.month + 1)) +
    (targetDatetime.date < 10
      ? "0" + String(targetDatetime.date)
      : String(targetDatetime.date));

  var DateFullText =
    String(targetDatetime.year) +
    "." +
    (targetDatetime.month + 1 < 10
      ? "0" + String(targetDatetime.month + 1)
      : String(targetDatetime.month + 1)) +
    "." +
    (targetDatetime.date < 10
      ? "0" + String(targetDatetime.date)
      : String(targetDatetime.date));

  var number = 0;

  const setTimeDefaultFun = (name, value, number) => {
    var defaultData = [...timeDefault];

    defaultData[number][name] = value;

    setTimeDefault(defaultData);
  };

  const timeAddHandler = (LiNum, DaNum, type, value) => {
    var scheduleData = lessonTime[DateFull];

    scheduleData[DaNum][type] = value;

    scheduleData[DaNum]["change"] = "yes";

    if (type == "start_time") {
      scheduleData[DaNum]["start_date"] =
        DateFullText + " " + value + ":" + scheduleData[DaNum]["start_min"];
    } else if (type == "start_min") {
      scheduleData[DaNum]["start_date"] =
        DateFullText + " " + scheduleData[DaNum]["start_time"] + ":" + value;
    } else if (type == "finish_time") {
      scheduleData[DaNum]["finish_date"] =
        DateFullText + " " + value + ":" + scheduleData[DaNum]["finish_min"];
    } else if (type == "finish_min") {
      scheduleData[DaNum]["finish_date"] =
        DateFullText + " " + scheduleData[DaNum]["finish_time"] + ":" + value;
    }

    setLessonTime({ ...lessonTime, [DateFull]: scheduleData });
  };

  const timeAddHandlerAll = (LiNum, DaNum, type, value) => {
    var scheduleData = [...targetDayAllList];

    scheduleData[DaNum][type] = value;

    scheduleData[DaNum]["change"] = "yes";

    setTargetDayAllList(scheduleData);
  };

  const timeDeleteHandler = (LiNum, DaNum) => {
    var scheduleData = lessonTime[DateFull];

    var deleteData = [...deleteList];

    deleteData[deleteData.length] = scheduleData[DaNum];

    scheduleData.splice(DaNum, 1);

    setLessonTime({ ...lessonTime, [DateFull]: scheduleData });

    setDeleteList(deleteData);
  };

  const timeDeleteHandlerAll = (LiNum, DaNum) => {
    var scheduleData = [...targetDayAllList];

    scheduleData.splice(DaNum, 1);

    setTargetDayAllList(scheduleData);
  };

  const timeListAddHandler = (LiNum) => {
    var scheduleList = lessonTime;

    var scheduleData = scheduleList[DateFull];

    if (scheduleData) {
      scheduleData[scheduleData.length] = {
        current_people: "0",
        date_id: "new",
        finish_date:
          DateFullText +
          " " +
          timeDefault[0].finish_time +
          ":" +
          timeDefault[0].finish_min,
        finish_min: timeDefault[0].finish_min,
        finish_time: timeDefault[0].finish_time,
        max_people: partner.max_people,
        service_full: "no",
        start_date:
          DateFullText +
          " " +
          timeDefault[0].start_time +
          ":" +
          timeDefault[0].start_min,
        start_min: timeDefault[0].start_min,
        start_time: timeDefault[0].start_time,
        change: "yes",
      };
    } else {
      scheduleList[DateFull] = [];

      scheduleData = scheduleList[DateFull];

      scheduleData[scheduleData.length] = {
        current_people: "0",
        date_id: "new",
        finish_date:
          DateFullText +
          " " +
          timeDefault[0].finish_time +
          ":" +
          timeDefault[0].finish_min,
        finish_min: timeDefault[0].finish_min,
        finish_time: timeDefault[0].finish_time,
        max_people: partner.max_people,
        service_full: "no",
        start_date:
          DateFullText +
          " " +
          timeDefault[0].start_time +
          ":" +
          timeDefault[0].start_min,
        start_min: timeDefault[0].start_min,
        start_time: timeDefault[0].start_time,
        change: "yes",
      };
    }

    setLessonTime({ ...lessonTime, [DateFull]: scheduleData });

    /*var scheduleData = [...schedule]

    var timeList = scheduleData[LiNum].time

    scheduleData[LiNum].time[timeList.length] = timeDefault[LiNum]

    if(timeList.length > 4) {

      alert('기본일정 시간은 최대 5개까지 등록이 가능합니다!')
    } else {

      setSchedule(scheduleData)
    }*/
  };

  const timeListAddHandlerAll = (LiNum) => {
    var scheduleList = [...targetDayAllList];

    var scheduleData = scheduleList;

    scheduleData[scheduleData.length] = {
      current_people: "0",
      date_id: "new",
      finish_min: timeDefault[0].finish_min,
      finish_time: timeDefault[0].finish_time,
      max_people: partner.max_people,
      service_full: "no",
      start_min: timeDefault[0].start_min,
      start_time: timeDefault[0].start_time,
      change: "yes",
    };

    setTargetDayAllList(scheduleData);
  };

  // 레슨 일정 저장
  const lessonDateSave = () => {
    if (window.confirm("일정을 수정하시겠습니까?")) {
      var token = window.localStorage.getItem("token");

      axios
        .post(
          "https://ai.semos.kr/semos_admin/partner/data",
          {
            mode: "lesson_schedule_revise",
            token: token,
            partner_number: pid,
            lesson_number: lid,
            lesson_schedule: lessonTime,
            delete_date: deleteList,
            year: targetDatetime.year,
            month: targetDatetime.month + 1,
            day: targetDatetime.date,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            alert(statusMessage);
            setSchedule(Value.date_array);
            setLessonTime(Value.time_array);
            TimeListCreated(Value.time_array);
            window.location.reload();
          } else if (status == "waring") {
            alert(statusMessage);
          } else if (status == "fail") {
            alert(statusMessage);
            window.location.reload();
          }
        });
    }
  };

  // 레슨 일정 저장
  const lessonDateAllSave = () => {
    if (window.confirm("일정을 추가하시겠습니까?")) {
      var token = window.localStorage.getItem("token");

      axios
        .post(
          "https://ai.semos.kr/semos_admin/partner/data",
          {
            mode: "lesson_schedule_revise_all",
            token: token,
            partner_number: pid,
            lesson_number: lid,
            lesson_date: targetDayAll,
            lesson_time: targetDayAllList,
            year: targetDatetime.year,
            month: targetDatetime.month + 1,
            day: targetDatetime.date,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            alert(statusMessage);
            setSchedule(Value.date_array);
            setLessonTime(Value.time_array);
            TimeListCreated(Value.time_array);
            setTargetDayAll([]);
            setTargetDayAllList([]);
            setDatePopup(false);
            window.location.reload();
          } else if (status == "waring") {
            alert(statusMessage);
          } else if (status == "fail") {
            alert(statusMessage);
            window.location.reload();
          }
        });
    }
  };

  const getDateIndexDot = (year, month, date) => {
    return `${year}.${parseInt(month) < 10 ? `0${month}` : month}.${
      parseInt(date) < 10 ? `0${date}` : date
    }`;
  };

  /*const [targetLastDate, setTargetLastDate] = useState({
      date: new Date(targetDatetime.year, targetDatetime.month + 1, 0).getDate(),
      day: new Date(targetDatetime.year, targetDatetime.month, 1).getDay(),
    });*/

  const daysList = Array.from(
    {
      length:
        new Date(targetDatetime.year, targetDatetime.month + 1, 0).getDate() +
        new Date(targetDatetime.year, targetDatetime.month, 1).getDay(),
    },
    (_, i) =>
      i < new Date(targetDatetime.year, targetDatetime.month, 1).getDay()
        ? ""
        : i -
          new Date(targetDatetime.year, targetDatetime.month, 1).getDay() +
          1
  );

  const AllCheck = () => {
    var DateAllList = [];

    for (var i = 0; i < daysList.length; i++) {
      if (daysList[i]) {
        DateAllList.push(
          getDateIndexDot(
            targetDatetime.year,
            targetDatetime.month + 1,
            daysList[i]
          )
        );
      }
    }

    setTargetDayAll(DateAllList);
  };

  const AllDelete = () => {
    if (window.confirm("이번달 일정을 삭제하시겠습니까?")) {
      var token = window.localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "month_delete");
      frm.append("token", token);
      frm.append("partner_number", pid);
      frm.append("lesson_number", lid);
      frm.append("year", targetDatetime.year);
      frm.append(
        "month",
        targetDatetime.month + 1 > 9
          ? targetDatetime.month + 1
          : `0${targetDatetime.month + 1}`
      );
      //frm.append("day", (targetDatetime.date));

      axios
        .post("https://ai.semos.kr/semos_admin/activity/schedule", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            alert(statusMessage);
            window.location.reload();
          } else if (status == "waring") {
            alert(statusMessage);
          } else if (status == "fail") {
            alert(statusMessage);
          }
        });
    }
  };

  const TimeListCreated = (list) => {
    var TimeListAll = new Object();

    Object.entries(list).map((item, idx) => {
      let TimeDate = item[0];
      var TimeVale = new Object();

      item[1].map((item2, idx2) => {
        TimeVale[item2.start_time] = {
          number: idx2,
          fullDate: item2.start_date.split(" ")[0],
          time: item2.start_time,
        };
      });

      TimeListAll[TimeDate] = TimeVale;
    });

    setTimeList(TimeListAll);
  };

  const TimeListCreated2 = (list) => {
    var TimeListAll = new Object();

    list.map((item, idx) => {
      TimeListAll[item.start_time] = {
        number: idx,
        time: item.start_time,
      };
    });

    setTimeList2(TimeListAll);
  };

  const TimeButtonChecker = (value) => {
    var TimeData = timeList[DateFull] ? timeList[DateFull][value] : false;

    var scheduleList = lessonTime;

    var scheduleTimeList = timeList;

    var scheduleData = scheduleList[DateFull];

    var scheduleTimeListData = scheduleTimeList[DateFull];

    var deleteData = [...deleteList];

    if (TimeData) {
      var scheduleNumber = TimeData.number;

      deleteData[deleteData.length] = scheduleData[scheduleNumber];

      scheduleData.splice(scheduleNumber, 1);

      setLessonTime({ ...lessonTime, [DateFull]: scheduleData });

      setDeleteList(deleteData);

      scheduleList[DateFull] = scheduleData;

      TimeListCreated(scheduleList);
    } else {
      var scheduleStart = `${DateFullText} ${value}:00`;
      var scheduleFinish = `${DateFullText} ${value}:00`;

      var scheduleTimeData = {
        change: "yes",
        current_people: "0",
        date_id: "new",
        finish_date: scheduleStart,
        finish_min: "00",
        finish_time: value,
        max_people: partner.max_people,
        service_full: "no",
        start_date: scheduleFinish,
        start_min: "00",
        start_time: value,
      };

      if (!scheduleData) {
        scheduleList[DateFull] = [];

        scheduleData = scheduleList[DateFull];

        scheduleTimeList[DateFull] = new Object();

        scheduleTimeListData = scheduleTimeList[DateFull];
      }

      scheduleData[scheduleData.length] = scheduleTimeData;

      setLessonTime({ ...lessonTime, [DateFull]: scheduleData });

      scheduleList[DateFull] = scheduleData;

      TimeListCreated(scheduleList);
    }
  };

  const TimeButtonChecker2 = (value) => {
    var TimeData = timeList2[value] ? timeList2[value] : false;

    var scheduleList = targetDayAllList;

    if (TimeData) {
      var scheduleNumber = TimeData.number;

      scheduleList.splice(scheduleNumber, 1);

      setTargetDayAllList(scheduleList);

      TimeListCreated2(scheduleList);
    } else {
      var scheduleTimeData = {
        change: "yes",
        current_people: "0",
        date_id: "new",
        finish_min: "00",
        finish_time: value,
        max_people: partner.max_people,
        service_full: "no",
        start_min: "00",
        start_time: value,
      };

      scheduleList[scheduleList.length] = scheduleTimeData;

      setTargetDayAllList(scheduleList);

      TimeListCreated2(scheduleList);
    }
  };

  return (
    <MainLayout>
      <MainHeader
        title={partner ? partner.lesson_name : ""}
        subtitle={partner ? partner.lesson_location : ""}
      />

      <Main>
        <header>
          <div>
            <Title>등록한 일정을 확인해 보세요.</Title>
            <p>날짜를 클릭해서 확인할 수 있어요.</p>
          </div>
        </header>

        <CalendarBox>
          <Calendar
            allData={schedule ? schedule : ""}
            targetDay={targetDay}
            currentDate={currentDate}
            targetDatetime={targetDatetime}
            setTargetDatetime={CalendarChange}
            setTargetDay={setTargetDay}
          />
        </CalendarBox>

        <section>
          <header>
            {targetDay && (
              <time>{`${targetDatetime.year}년 ${
                targetDatetime.month + 1
              }월 ${targetDay}일 (${
                week[
                  new Date(
                    targetDatetime.year,
                    targetDatetime.month,
                    targetDay
                  ).getDay()
                ]
              }요일)`}</time>
            )}
            <div>
              <button style={{ marginRight: "20px" }} onClick={AllDelete}>
                이번달 일정 삭제
              </button>
              {/*<button onClick={resetHandler}>입력 초기화</button>*/}
            </div>
          </header>

          <ClockBox>
            <p>레슨 시간</p>
            <ClockField
              clock={{
                clockChangeHandler: clockInputChangeHandler,
                timeAddHandler,
                timeListAddHandler,
                addClockListHandler: addClockHandler,
                deleteClockHandler: deleteClockHandler,
                setTimeDefaultFun,
                timeDeleteHandler,
                clockList,
                number,
                timeDefault,
              }}
              type="click"
              data={{
                time: lessonTime
                  ? lessonTime[DateFull]
                    ? lessonTime[DateFull]
                    : []
                  : [],
              }}
              timeList={
                timeList ? (timeList[DateFull] ? timeList[DateFull] : []) : []
              }
              timeCheck={TimeButtonChecker}
            />
          </ClockBox>
        </section>
      </Main>

      <Footer>
        <Button
          text="일괄 추가"
          clickHandler={() => setDatePopup(true)}
          color={colors.white}
          fontcolor={colors.gray}
          width="210px"
        />

        <Button
          text="일정 저장하기"
          clickHandler={lessonDateSave}
          width="210px"
        />
      </Footer>

      {datePopup ? (
        <DataPopup>
          <DataPopupClose onClick={() => setDatePopup(false)} />

          <DataPopupContainer>
            <DataPopupHeader>
              <span
                style={{
                  display: "inline-block",
                  width: "100%",
                  marginBottom: "5px",
                }}
              >
                {partner ? partner.lesson_name : ""}
              </span>
              <span
                className="color_light_black font_15 weight_400"
                style={{
                  display: "inline-block",
                  width: "100%",
                  marginBottom: "5px",
                }}
              >
                {partner ? partner.lesson_location : ""}
              </span>
            </DataPopupHeader>

            <Main>
              <header>
                <div>
                  <Title>
                    여러 날짜를 선택해 일정을 일괄 추가할 수 있습니다.
                  </Title>
                </div>
              </header>

              <CalendarBox>
                <CalendarAll
                  allData={schedule ? schedule : ""}
                  targetDay={targetDayAll}
                  currentDate={currentDate}
                  targetDatetime={targetDatetime}
                  setTargetDatetime={CalendarChange}
                  setTargetDay={setTargetDayAll}
                />
              </CalendarBox>

              <section>
                <header className="header2">
                  {targetDay && <time></time>}
                  <button onClick={AllCheck}>전체 선택</button>
                  {/*<button onClick={resetHandler}>입력 초기화</button>*/}
                </header>

                <ClockBox>
                  <p>레슨 시간</p>
                  <ClockField
                    clock={{
                      clockChangeHandler: clockInputChangeHandler,
                      timeAddHandler: timeAddHandlerAll,
                      timeListAddHandler: timeListAddHandlerAll,
                      addClockListHandler: addClockHandler,
                      deleteClockHandler: deleteClockHandler,
                      setTimeDefaultFun,
                      timeDeleteHandler: timeDeleteHandlerAll,
                      clockList,
                      number,
                      timeDefault,
                    }}
                    type="click2"
                    data={{ time: targetDayAllList }}
                    timeList={timeList2}
                    timeCheck={TimeButtonChecker2}
                  />
                </ClockBox>
              </section>
            </Main>

            <Footer>
              <Button
                text="일괄 저장하기"
                clickHandler={lessonDateAllSave}
                width="210px"
              />
            </Footer>
          </DataPopupContainer>
        </DataPopup>
      ) : (
        ""
      )}
    </MainLayout>
  );
};

export default PartnerScheduleEditDetailPage;
