import styled from "styled-components";
import flexbox from "../../../styles/func/flexbox";

export const Main = styled.main`
  padding: 20px;

  section {
    width: 100%;
    ${flexbox()}
  }
`;

export const Box = styled.div`
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  p {
    margin-bottom: 20px;
  }
`;
