import styled from "styled-components";
import typography from "../../styles/constants/typography";
import flexbox from "../../styles/func/flexbox";
import checkIcon from "../../assets/icon/check.svg";

import colors from "../../styles/constants/colors";

const Box = styled.div`
  font-size: ${typography.size.tiny}px;

  ul,
  ol {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin: 0 auto;
    max-width: 64em;
    padding: 0;
  }

  li {
    ${flexbox()}

    &:nth-child(7n + 1) {
      color: ${colors.blue};
    }

    &:nth-child(7n) {
      color: ${colors.blue};
    }
  }
`;

const Weekdays = styled.ul`
  height: 40px;
  border-bottom: 2px dashed ${colors.blue};

  li {
    font-weight: ${typography.weight.bold};
    color: ${colors.blue} !important;
  }
`;

const Month = styled.ol`
  li {
    height: 70px;
    cursor: pointer;
  }
`;

const Day = styled.li`
  ${({ selectable }) =>
    !selectable
      ? `color: ${colors.mediumGray} !important; ; pointer-events: none;`
      : null}

  p {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    ${flexbox()}
    ${({ isactive }) =>
      isactive && `color: ${colors.white}; background-color: ${colors.blue}; `}
    
      ${({ check }) =>
      check && `color: ${colors.white}; background-color: ${colors.blue}; `} 

    &::before {
      position: absolute;
      top: -3px;
      right: 0px;
      content: url(${checkIcon});
      transform: scale(1.2);
      opacity: 0;

      ${({ isData }) => isData && `opacity: 1`}
    }
  }
`;

const DateBoxAll = ({
  lastDay,
  lastDate,
  datetime,
  targetDay,
  targetDayList = "",
  setTargetDay,
  setTargetDate,
  addTargetDayListHandler = null,
  data,
}) => {
  // 선택한 날은 파란색 동그라미
  // 일정이 있는 경우 체크 표시

  const daysList = Array.from({ length: lastDate + lastDay }, (_, i) =>
    i < lastDay ? "" : i - lastDay + 1
  );

  const weekdays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  const isData = (date) => {
    if (data[date]) {
      return true;
    } else {
      return false;
    }
  };

  const getDateIndex = (year, month, date) => {
    return `${year}-${parseInt(month) < 10 ? `0${month}` : month}-${
      parseInt(date) < 10 ? `0${date}` : date
    }`;
  };

  const getDateIndexDot = (year, month, date) => {
    return `${year}.${parseInt(month) < 10 ? `0${month}` : month}.${
      parseInt(date) < 10 ? `0${date}` : date
    }`;
  };

  return (
    <Box>
      <Weekdays>
        {weekdays.map((day, idx) => (
          <li key={idx}>{day}</li>
        ))}
      </Weekdays>

      <Month>
        {daysList.map((day, idx) => (
          <Day
            key={idx}
            //selectable={day >= datetime.date ? true : false}
            selectable={true}
            onClick={() => {
              //setTargetDate(datetime.year, datetime.month, day);
              if (
                targetDay.indexOf(
                  getDateIndexDot(datetime.year, datetime.month + 1, day)
                ) == -1
              ) {
                setTargetDay((targetDay) => [
                  ...targetDay,
                  getDateIndexDot(datetime.year, datetime.month + 1, day),
                ]);
              } else {
                setTargetDay(
                  targetDay.filter(
                    (item) =>
                      item !==
                      getDateIndexDot(datetime.year, datetime.month + 1, day)
                  )
                );
              }
            }}
            check={
              targetDayList[
                getDateIndex(datetime.year, datetime.month + 1, day)
              ]
                ? true
                : false
            }
            isactive={
              targetDay.indexOf(
                getDateIndexDot(datetime.year, datetime.month + 1, day)
              ) !== -1
                ? true
                : false
            }
            isData={isData(
              String(datetime.year) +
                (datetime.month + 1 < 10
                  ? "0" + String(datetime.month + 1)
                  : String(datetime.month + 1)) +
                (day < 10 ? "0" + String(day) : String(day))
            )}
          >
            <p>{day}</p>
          </Day>
        ))}
      </Month>
    </Box>
  );
};

export default DateBoxAll;
