import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import MainLayout from "../../../layouts/mainLayout";
import MainHeader from "../../../components/header";
import Button from "../../../modules/button";
import colors from "../../../styles/constants/colors";
import typography from "../../../styles/constants/typography";
import base from "../../../styles/constants/base";
import flexbox from "../../../styles/func/flexbox";
import useList from "../../../hooks/useList";
import BaseCard from "../../../components/partner/schedule/baseCard";
import useInput from "../../../hooks/useInput";

const Main = styled.main`
  width: 100%;
  padding: 20px;
  height: 100%;
  overflow-y: scroll;

  header {
    width: 100%;
    ${flexbox("space-between")}
    margin-bottom: 20px;

    button {
      color: ${colors.white};
      background-color: ${colors.blue};
      box-shadow: ${base.boxShadow.default};
      border-radius: 10px;
      padding: 10px;
    }
  }
`;

const Footer = styled.footer`
  width: 100%;
  height: 90px;
  ${flexbox("flex-end")}
  position: absolute;
  bottom: 0px;
  padding: 0 20px;

  button:first-child {
    margin-right: 10px;
  }
`;

const Title = styled.p`
  font-size: ${typography.size.medium}px;
  margin-bottom: 10px;
`;

const PartnerScheduleEditPage = () => {
  const navigate = useNavigate();

  const { pid, lt, lat } = useParams();

  const { addHandler } = useList([]);

  const goToEditDateHandler = () => {
    navigate("/partner/" + pid +  "/schedule/detail/" + lt + "/" + lat);
  };

  const goToRegisterDateHandler = () => {
    navigate("/partner/" + pid +  "/schedule/register/" + lt + "/" + lat);
  };

  const saveHandler = () => {
    var scheduleData = [...schedule]

    scheduleData[scheduleData.length] = {
      time : [],
      yoil : []
    }

    setSchedule(scheduleData)

    var defaultData = [...timeDefault]

    defaultData[defaultData.length] = {
      start_time : "06",
      start_min : "00",
      finish_time : "06",
      finish_min : "00"
    }
    
    setTimeDefault(defaultData)
  };

  // Schedule
  const {
    list: scheduleList,
    setList: setScheduleList,
    addHandler: addScheduleHandler,
    deleteHandler: deleteScheduleHandler,
  } = useList([
    {
      id: 1,
      week: [1, 2, 3, 5, 6],
      clock: [
        {
          id: 1,
          "start-hour": "09",
          "start-minute": "00",
          "finish-hour": "12",
          "finish-minute": "00",
        },
        {
          id: 2,
          "start-hour": "13",
          "start-minute": "00",
          "finish-hour": "21",
          "finish-minute": "00",
        },
      ],
    },
  ]);

  const {
    value: clockValue,
    setValue: setClockValue,
    inputChangeHandler: clockChangeHandler,
  } = useInput({
    "start-hour": "",
    "start-minute": "",
    "finish-hour": "",
    "finish-minute": "",
  });

  const {
    list: weekList,
    setList: setWeekList,
    addHandler: addWeekHandler,
    deleteHandler: deleteWeekHandler,
  } = useList(scheduleList[0].week);

  const {
    list: clockList,
    setList: setClockList,
    addHandler: addClockHandler,
    deleteHandler: deleteClockHandler,
  } = useList(scheduleList[0].clock);

  const addScheduleListHandler = () => {
    addScheduleHandler({
      id: new Date(),
      week: weekList,
      clock: clockList,
    });

    setWeekList([]);
    setClockList([]);
  };

  const weekToggleHandler = (value, number) => {
    // 중복 체크

    var schedule_og = [...schedule]

    var yoliCheckData = schedule_og[number].yoil;

    if(yoliCheckData.indexOf(value) > -1) {
      for(var i=0; i < yoliCheckData.length; i++) {
        if(value == yoliCheckData[i]) {
          schedule_og[number].yoil.splice(i, 1);
          i = i + 999;
        }
      }
      setSchedule(schedule_og)
    } else {

      yoliCheckData.push(value)

      schedule_og[number].yoil = yoliCheckData;

      setSchedule(schedule_og)
    }

 
  };

  const addClockListHandler = () => {
    addClockHandler({
      id: new Date(),
      "start-hour": clockValue["start-hour"],
      "start-minute": clockValue["start-minute"],
      "finish-hour": clockValue["finish-hour"],
      "finish-minute": clockValue["finish-minute"],
    });

    setClockValue({
      "start-hour": "",
      "start-minute": "",
      "finish-hour": "",
      "finish-minute": "",
    });
  };

  const timeAddHandler = (LiNum, DaNum, type, value) => {

    var scheduleData = [...schedule]

    scheduleData[LiNum].time[DaNum][type] = value

    setSchedule(scheduleData)

  }

  const timeDeleteHandler = (LiNum) => {

    var scheduleData = [...schedule]

    scheduleData.splice(LiNum, 1);

    setSchedule(scheduleData)

  }


  const timeListAddHandler = (LiNum) => {

    var scheduleData = [...schedule]

    var timeList = scheduleData[LiNum].time

    if(timeList.length > 4) {

      alert('기본일정 시간은 최대 5개까지 등록이 가능합니다!')
    } else {

      var startTime = timeDefault[LiNum].start_time
      var startMin = timeDefault[LiNum].start_min
      var finishTime = timeDefault[LiNum].finish_time
      var finishMin = timeDefault[LiNum].finish_min

      for(var i=0; i < timeList.length; i++) {
          var startTimeCheck = timeList[i].start_time == startTime
          var startMinCheck = timeList[i].start_min == startMin
          var finishTimeCheck = timeList[i].finish_time == finishTime
          var finishMinCheck = timeList[i].finish_min == finishMin
          
          if(startTimeCheck && startMinCheck && finishTimeCheck && finishMinCheck ) {
            alert('중복되는 일정이 있습니다.')
            return false;
          }
      }

      scheduleData[LiNum].time[timeList.length] = timeDefault[LiNum]

      setSchedule(scheduleData)
    }


  }

  const [schedule, setSchedule] = useState([]);

  const [timeDefault, setTimeDefault] = useState();

  const setTimeDefaultFun = (name, value, number) => {

    var defaultData = [...timeDefault]

    defaultData[number][name] = value

    setTimeDefault(defaultData)

  }

  // 기본 일정 상세 로드
  useEffect(() => {

   var token = window.localStorage.getItem('token')

   const frm = new FormData();
   frm.append("mode", 'schedule');
   frm.append("token", token);
   frm.append("number", pid);
   frm.append("location", lt);
   frm.append("location_additional", lat);

     axios.post("https://ai.semos.kr/semos_admin/partner/data", frm, {
       headers: { "Content-Type": "multipart/form-data" },
     }).then((data) => {
       var Value = data.data;
       var status = Value.status
       var statusMessage = Value.status_message

       if(status == 'success') {
         setSchedule(Value.default_schedule)
         var scheduleLength =  Value.default_schedule.length
         var scheduleDefault = [];

         for(var i=0; i < scheduleLength; i++) {
          scheduleDefault.push({
            start_time : "06",
            start_min : "00",
            finish_time : "06",
            finish_min : "00"
          })
         }

         setTimeDefault(scheduleDefault);

       } else if(status == 'waring') {
         alert(statusMessage)
       }

     });

 }, []);


 var scheduleCard = []

 for(var number=0; number < schedule.length; number++) {
  scheduleCard.push(
    <BaseCard
      key={number + '_schedule'}
      number={number}
      data={schedule[number]}
      weekList={weekList}
      weekToggleHandler={weekToggleHandler}
      deleteHandler={timeDeleteHandler}
      clock={{
        timeListAddHandler,
        timeAddHandler,
        setTimeDefaultFun,
        deleteClockHandler,
        clockList,
        number,
        timeDefault
      }}
    />
  )

}

  // 기본 일정 저장
  const defaultDateSave = () => {
    if (window.confirm("기본 일정을 저장하시겠습니까?")) {
      var token = window.localStorage.getItem("token");

      axios.post(
          "https://ai.semos.kr/semos_admin/partner/data",
          {
            mode: "schedule_edit",
            token: token,
            partner_number: pid,
            location: lt,
            location_additional: lat,
            default_date: schedule,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            alert(statusMessage);
            window.location.reload();
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    }
  };

  return (
    <MainLayout
      //button={{ type: "plus", clickHandler: goToRegisterDateHandler }}
    >
      <MainHeader title={lt + " " + lat} />

      <Main>
        <header>
          <div>
            <Title>기본 일정 등록</Title>
            <p>등록 된 일정은 매 월 10일에 이용자 서비스에 적용됩니다.</p>
          </div>

          <button type="button" onClick={saveHandler}>
            기본 일정 추가
          </button>
        </header>

        <ul>
          {scheduleCard}
        </ul>
      </Main>

      <Footer>

        <Button
          text="기본 일정 저장하기"
          clickHandler={defaultDateSave}
          width={`230px`}
        />

        {/*<Button
          text="등록 일정 확인하기"
          clickHandler={goToEditDateHandler}
          width={`230px`}
        />*/}
      </Footer>
    </MainLayout>
  );
};

export default PartnerScheduleEditPage;
