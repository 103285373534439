import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

// components
import DesignedMainHeader from "../../components/designedHeader";
import DesignedMainLayout from "../../layouts/designedMainLayout";
import DrawerModal from "../../components/drawerModal";
import Table from "../../layouts/table";
import {
  TableFilterContainer,
  TableFilterContent,
  TableWrapper,
  Wrapper,
  AbsoluteWrapper,
  Icon,
  Text,
  TableContent,
  TableFooter,
  TableColumn,
  TableFooterPaging,
} from "../../layouts/layoutStorage";
import DropDown from "../../components/designedComponents/dropDown";
import PartnerDetail from "../../components/designedMember/partnerDetail";

import SearchIcon from "../../assets/icon/search.png";
import DoublePaging from "../../assets/icon/double_paging.png";
import OnePaging from "../../assets/icon/one_paging.png";
import FilterIcon from "../../assets/icon/filter.png";
import colors from "../../styles/constants/colors";

const Partner = () => {
  const { n, t, sport, status, loc } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [locationPopup, setLocationPopup] = useState(false);

  const [partner, setPartner] = useState([]);
  const [count, setCount] = useState(0);

  const [partnerId, setPartnerId] = useState("");
  const [partnerIndex, setPartnerIndex] = useState("");

  const [Height, setHeight] = useState({
    TableFilter: 0,
    TableHeader: 0,
    TableFooter: 0,
    Layout: 0,
    LayoutHeader: 0,
  });

  // filter
  const [filter, setFilter] = useState({
    sport: sport !== "all" ? sport : "스포츠 전체",
    status: status !== "all" ? status : "",
    location: loc !== "all" ? loc : "",
  });

  const [search, setSearch] = useState(t !== "all" ? t : "");
  const [start, setStart] = useState(false);

  const LocationList = ["all", "서울", "경기", "인천", "수원", "용인", "고양", "부산", "대구", "강원", "울산", "경북", "경남", "제주"];

  useEffect(() => {
    if (start) {
      PageInput(1);
    }
  }, [filter]);

  useEffect(() => {
    loadData();
  }, [n, t, sport, status, loc]);

  useEffect(() => {
    setTimeout(() => {
      const Layout = document.getElementById("Layout")?.offsetHeight - 36;
      const LayoutHeader = document.getElementById("LayoutHeader")?.offsetHeight + 65;
      const TableFilter = document.getElementById("TableFilter")?.offsetHeight + 19;
      const TableHeader = document.getElementById("TableHeader")?.offsetHeight;
      const TableFooter = document.getElementById("TableFooter")?.offsetHeight;

      setHeight({
        TableFilter: TableFilter,
        TableHeader: TableHeader,
        TableFooter: TableFooter,
        Layout: Layout,
        LayoutHeader: LayoutHeader,
      });
    }, 500);
  }, []);

  const loadData = () => {
    var token = localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", "partner_update");
    frm.append("token", token);
    frm.append("page", n);
    frm.append("location_type", loc);
    frm.append("text", t);
    frm.append("type", status);
    frm.append("category", sport != "all" ? sport : "");

    axios
      .post("https://ai.semos.kr/semos_admin/center/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;

        setPartner(Value.partner_list);
        setCount(Value.partner_count);

        setStart(true);
      });
  };

  const EnterSearch = (key) => {
    if (key == "Enter") {
      PageInput(1);
    }
  };

  const PageInput = (num) => {
    navigate(
      `/partner/${num}/${search ? search : "all"}/${filter.sport !== "스포츠 전체" ? filter.sport : "all"}/${filter.status ? filter.status : "all"}/${
        filter.location ? filter.location : "all"
      }`
    );
  };

  const numPages = Math.ceil(count / 12);

  let PageList = [];

  if (n < 11) {
    for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
      PageList.push(i + 1);
    }
  } else {
    if (numPages - 10 < n) {
      var startPage = numPages - 19;
      if (startPage < 1) {
        startPage = 1;
      }

      for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    } else {
      var startPage = n - 9;
      if (startPage < 1) {
        startPage = 1;
      }

      for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    }
  }

  const showDetail = (id, num) => {
    setOpen(true);
    setPartnerId(id);
    setPartnerIndex(num);
  };

  const statusMaker = (value) => {
    var copy = value;

    switch (copy) {
      case "all":
        return "상태 전체";

      case "open":
        return "활성";

      case "close":
        return "비활성";

      case "confirm":
        return "승인요청";
    }
  };

  const FilterSearch = (value) => {
    setFilter((prev) => ({ ...prev, location: value }));
  };

  const statusConvert = (value) => {
    var copy = value;

    switch (copy) {
      case "1":
        return <div style={{ color: "#0e6dff" }}>활성</div>;
        break;

      case "2":
        return <div style={{ color: "#acafb5" }}>비활성</div>;
        break;

      case "3":
        return <div style={{ color: "#FF9187" }}>승인요청</div>;
        break;
    }
  };

  const locationRef = useRef();

  const locations_return = (value) => {
    if (!value.length) {
      return "지역 정보 미등록";
    }

    var locations = "";

    for (var i = 0; i < value.length; i++) {
      var si_data = value[i].si;
      var gu_data = value[i].gu;

      if (i == 0) {
        locations = `${si_data} ${gu_data}`;
      } else {
        locations = `${locations}, ${si_data} ${gu_data}`;
      }
    }

    return locations;
  };

  const sports_return = (value) => {
    if (!value.length) {
      return "카테고리 미등록";
    }

    var sports = "";

    for (var i = 0; i < value.length; i++) {
      var sport_data = value[i].sport;

      if (i == 0) {
        sports = sport_data;
      } else {
        sports = `${sports}, ${sport_data}`;
      }
    }

    return sports;
  };

  const searchTextInput = (value) => {
    var copy = value;
    var regExp = /[`~!@#$%^&*()_|+\-=?;:'"<>\{\}\\\\/]/gim;

    if (regExp.test(copy)) {
      alert("특수문자는 입력하실수 없습니다.");
      copy = copy.replace(regExp, "");
    }
    setSearch(copy);
  };

  return (
    <>
      <DesignedMainLayout>
        <DesignedMainHeader title="파트너 관리" subtitle={`전체 파트너 : ${count}명 (현재 리스트)`} />
        <Table>
          <TableFilterContainer id="TableFilter" margin={`0 0 19px 0`} style={{ justifyContent: "flex-start" }}>
            <TableFilterContent margin={`0 24px 0 0`}>
              <Input
                onKeyUp={(e) => EnterSearch(e.key)}
                value={search}
                onChange={(e) => searchTextInput(e.target.value)}
                width={`350px`}
                type={`text`}
                maxLength={40}
                placeholder="검색어를 입력해주세요"
              />
              <Icon width={`calc(1rem * (17 / 14))`} cursor={`pointer`} src={SearchIcon} onClick={() => PageInput(1)} />
            </TableFilterContent>

            <DropDown
              data={sport !== "all" ? sport : "스포츠 전체"}
              setData={setFilter}
              option={[
                {
                  text: "스포츠 전체",
                  value: "all",
                },
                {
                  text: "스쿠버다이빙",
                  value: "스쿠버다이빙",
                },
                {
                  text: "프리다이빙",
                  value: "프리다이빙",
                },
                {
                  text: "패들보드",
                  value: "패들보드",
                },
                {
                  text: "서핑",
                  value: "서핑",
                },
                {
                  text: "수영/스노클링",
                  value: "수영,스노클링",
                },
                {
                  text: "스키",
                  value: "스키",
                },
                {
                  text: "스노보드",
                  value: "스노보드",
                },
              ]}
              type={`object`}
              defaultText={filter.sport !== "all" ? filter.sport : "스포츠 전체"}
              keyName={`sport`}
            />

            <DropDown
              data={statusMaker(status)}
              setData={setFilter}
              option={[
                {
                  text: "상태 전체",
                  value: "all",
                },
                {
                  text: "활성",
                  value: "open",
                },
                {
                  text: "비활성",
                  value: "close",
                },
                {
                  text: "승인요청",
                  value: "confirm",
                },
              ]}
              type={`object`}
              keyName={`status`}
            />

            <TableFilterContent onClick={() => setLocationPopup(!locationPopup)} ref={locationRef} cursor={`pointer`} margin={`0 24px 0 0`}>
              <Text weight={`500`} width={`100%`} margin={`0 2px 0 0`} color={`#3C3E41`}>
                {filter.location != "all" && filter.location ? filter.location : `지역 전체`}
              </Text>
              <Icon width={`calc(1rem * (17 / 14))`} src={FilterIcon} />

              <AbsoluteWrapper
                display={locationPopup ? `inline-flex` : `none`}
                z={`100`}
                width={`150px`}
                bgc={`#ffffff`}
                bottom={`-8px`}
                left={`0`}
                wrap={`wrap`}
                transform={`translate(0, 100%)`}
              >
                <Wrapper wrap={`wrap`} width={`100%`} border={`1px solid #DCDCDC`} mh={`300px`} of={`hidden auto`}>
                  <Wrapper display={`inline-block`} padding={`8px`} width={`100%`} ws={`normal`}>
                    {LocationList.map((item, idx) => (
                      <Wrapper key={`${idx}_location_filter`} onClick={() => FilterSearch(item)} cursor={`pointer`} padding={`8px`} width={`100%`}>
                        <Text color={filter.location == item ? `#3383FF` : `#818792`} weight={`500`}>
                          {item != "all" ? item : `전체`}
                        </Text>
                      </Wrapper>
                    ))}
                  </Wrapper>
                </Wrapper>
              </AbsoluteWrapper>
            </TableFilterContent>
          </TableFilterContainer>

          <TableContent height={Height.Layout - (Height.LayoutHeader + Height.TableFilter + Height.TableFooter)} contentHeight={Height.TableHeader}>
            <section id="TableHeader">
              <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                파트너 ID
              </TableColumn>

              <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                파트너 명
              </TableColumn>

              <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                전화번호
              </TableColumn>

              <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                액티비티 카테고리
              </TableColumn>

              <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                지역
              </TableColumn>

              <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                상태
              </TableColumn>
            </section>

            <section>
              {partner
                ? partner.map((item, idx) => (
                    <TableWrapper
                      onClick={() => showDetail(item.id, idx)}
                      //   onClick={() => setOpen(true)}
                      cursor={`pointer`}
                      key={`${idx}_reservation`}
                      jc={`flex-start`}
                      bl={`1px solid ${colors.Gr02}`}
                      br={`1px solid ${colors.Gr02}`}
                      bb={`1px solid ${colors.Gr02}`}
                      width={`100%`}
                      bgc={`#ffffff`}
                    >
                      <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        {`${item.id}`}
                      </TableColumn>

                      <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        {item.name ? `${item.name}` : "닉네임 없음"}
                      </TableColumn>

                      <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        {`${item.phone}`}
                      </TableColumn>

                      <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        {`${sports_return(item.sport)}`}
                      </TableColumn>

                      <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        {`${item.location ? locations_return(item.location) : "지역 정보 없음"}`}
                      </TableColumn>

                      <TableColumn
                        width={`10`}
                        weight={`500`}
                        padding={`9px 20px`}
                        br={`1px solid ${colors.Gr02}`}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {/* <ToggleButton
                          toggleHandler={() =>
                            statusToggleHandler(item.id, idx)
                          }
                          status={item.status}
                          type="partner"
                        /> */}
                        {statusConvert(item.status)}
                      </TableColumn>
                    </TableWrapper>
                  ))
                : ""}

              <TableFooter>
                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  src={DoublePaging}
                  onClick={() => {
                    if (Number(n) < 11) {
                      PageInput(1);
                    } else {
                      PageInput(Number(n) - 10);
                    }
                  }}
                />
                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  src={OnePaging}
                  onClick={() => {
                    if (n > 1) {
                      PageInput(n - 1);
                    } else {
                      window.alert("첫번째 페이지 입니다.");
                    }
                  }}
                />

                {PageList.length
                  ? PageList.map((item, idx) => (
                      <TableFooterPaging
                        key={`paging_number_${idx}`}
                        margin={`0 16px 0 0`}
                        color={`${n == item ? "#3383FF" : "#ACAFB5"}`}
                        onClick={() => PageInput(item)}
                      >
                        {item}
                      </TableFooterPaging>
                    ))
                  : ""}

                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  transform={`rotate(180deg)`}
                  src={OnePaging}
                  onClick={() => {
                    if (n < numPages) {
                      PageInput(Number(n) + 1);
                    } else {
                      window.alert("마지막 페이지 입니다.");
                    }
                  }}
                />
                <Icon
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  transform={`rotate(180deg)`}
                  src={DoublePaging}
                  onClick={() => {
                    if (Number(n) + 10 > numPages) {
                      PageInput(numPages);
                    } else {
                      PageInput(Number(n) + 10);
                    }
                  }}
                />
              </TableFooter>
            </section>
          </TableContent>

          <TableFooter id="TableFooter" style={{ display: "none" }}>
            <Icon
              margin={`0 16px 0 0`}
              width={`calc(1rem * (20 / 14))`}
              cursor={`pointer`}
              src={DoublePaging}
              onClick={() => {
                if (Number(n) < 11) {
                  PageInput(1);
                } else {
                  PageInput(Number(n) - 10);
                }
              }}
            />
            <Icon
              margin={`0 16px 0 0`}
              width={`calc(1rem * (20 / 14))`}
              cursor={`pointer`}
              src={OnePaging}
              onClick={() => {
                if (n > 1) {
                  PageInput(n - 1);
                } else {
                  window.alert("첫번째 페이지 입니다.");
                }
              }}
            />

            {PageList.length
              ? PageList.map((item, idx) => (
                  <TableFooterPaging
                    key={`paging_number_${idx}`}
                    margin={`0 16px 0 0`}
                    color={`${n == item ? "#3383FF" : "#ACAFB5"}`}
                    onClick={() => PageInput(item)}
                  >
                    {item}
                  </TableFooterPaging>
                ))
              : ""}

            <Icon
              margin={`0 16px 0 0`}
              width={`calc(1rem * (20 / 14))`}
              cursor={`pointer`}
              transform={`rotate(180deg)`}
              src={OnePaging}
              onClick={() => {
                if (n < numPages) {
                  PageInput(Number(n) + 1);
                } else {
                  window.alert("마지막 페이지 입니다.");
                }
              }}
            />
            <Icon
              width={`calc(1rem * (20 / 14))`}
              cursor={`pointer`}
              transform={`rotate(180deg)`}
              src={DoublePaging}
              onClick={() => {
                if (Number(n) + 10 > numPages) {
                  PageInput(numPages);
                } else {
                  PageInput(Number(n) + 10);
                }
              }}
            />
          </TableFooter>
        </Table>
      </DesignedMainLayout>

      <DrawerModal open={open} setOpen={setOpen}>
        <PartnerDetail id={partnerId} index={partnerIndex} partner={partner} setPartner={setPartner} modal={open} />
      </DrawerModal>
    </>
  );
};

export default Partner;

const Input = styled.input`
  width: ${({ width }) => `${width}`};
  display: inline-block;
`;
