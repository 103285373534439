import { useState } from "react";
import styled from "styled-components";
import chevronIcon from "../../assets/icon/chevron-b.svg";
import base from "../../styles/constants/base";
import colors from "../../styles/constants/colors";
import flexbox from "../../styles/func/flexbox";
import transition from "../../styles/func/transition";
import deleteIcon from "../../assets/icon/close.svg";
import typography from "../../styles/constants/typography";

const Wrapper = styled.div`
  width: 100%;
  ${flexbox("space-between", "flex-start")};
  margin: 10px 0;
`;

const ButtonBox = styled.div`
  width: 150px;
  height: 100%;

  ${flexbox("flex-end", "flex-start")};

  button {
    padding: 0 0 10px 10px;
  }
`;

const Card = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 0px;
  }
`;

const QuestionBox = styled.div`
  width: 100%;
  height: 50px;
  ${flexbox("space-between")}
  margin-bottom: 20px;

  div {
    padding: 20px 0 10px 20px;
    cursor: pointer;
  }
`;

const ChevronImg = styled.img`
  transform: rotate(90deg);
  transition: ${transition("transform")};
  transform: ${({ open }) => (open ? `rotate(-90deg)` : null)};
  margin-left: 20px;
`;

const AnswerBox = styled.div`
  width: 100%;
  height: 100px;
  position: relative;
  max-height: ${({ open }) => (open ? "90px" : 0)};
  overflow: hidden;
  transition: ${transition("max-height")};
  ${flexbox("flex-start", "flex-start")}

  span {
    display: block;
    height: 100%;
    margin-right: 20px;
  }

  .FaqText {
    display: block;
    padding: 10px;
    width: 100%;
    height: 100%;
    border-radius: ${base.borderRadius.default}px;
    background-color: ${colors.lightBlue};
    font-weight: ${typography.weight.thin};
  }

  p {

  }
`;

const InputBox = styled.div`
  position: relative;
  width: 100%;

  p {
    display: block;
    ${flexbox("flex-start")}
  }

  button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-45%);

    img {
      width: 15px;
    }
  }
`;

const FAQItem = ({ data = "", deleteHandler = "" }) => {
  const [open, setOpen] = useState(false);

  const openToggleHandler = () => {
    setOpen(!open);
  };

  return (
    <Wrapper>
      <Card>
        <QuestionBox open={open}>
          <span>Q. </span>

          <InputBox>
            <p>{data.name}</p>

            <button onClick={() => deleteHandler(data.id)}>
              <img src={deleteIcon} />
            </button>
          </InputBox>

          <div onClick={openToggleHandler}>
            <ChevronImg src={chevronIcon} open={open} />
          </div>
        </QuestionBox>

        <AnswerBox open={open}>
          <span>A.</span>
          <div className="FaqText">
            {data.content ? data.content.split('\n').map((item, idx) => 
                (<div key={idx}>{item}</div>)
            
            ) 
            : ""}
          </div>
        </AnswerBox>
      </Card>

      <ButtonBox></ButtonBox>
    </Wrapper>
  );
};

export default FAQItem;
