import { useRef } from "react";
import styled from "styled-components";
import { copyText } from "../../function";
import colors from "../../styles/constants/colors";
import flexbox from "../../styles/func/flexbox";
import ModalLayout from "./layout";

const Wrapper = styled.div`
  width: 100%;

  input {
    all: unset;
    width: 100%;
    color: ${colors.gray};
    margin: 0px 0 50px 0;
  }

  button {
    width: 100%;
    ${flexbox("flex-end")}
    margin-bottom: 20px;
    color: ${colors.blue};
  }
`;

const AddressModal = ({
  closeHandler,
  address = "인천시 연수구 먼우금로 928-11 8동 202호 모스다이빙",
}) => {
  const addressInput = useRef();

  const confirmHandler = () => {
    console.log("확인");
  };

  const copyHandler = () => {
    copyText(addressInput);
  };

  return (
    <ModalLayout
      title="주소를 확인해 주세요."
      buttonText="확인"
      clickHandler={confirmHandler}
      closeHandler={closeHandler}
    >
      <Wrapper>
        <input
          type="text"
          value={`${address.main} ${address.detail}`}
          ref={addressInput}
          readOnly
        />

        <button type="button" onClick={copyHandler}>
          주소 복사
        </button>
      </Wrapper>
    </ModalLayout>
  );
};

export default AddressModal;
