import { useEffect, useState } from "react";
import dashboardData from "../../data/dashboard";
import ConnectTab from "./connect";
import axios from "axios";

const DashboardPage = () => {
  // Data fetching
  const data = dashboardData;

  return (
      <ConnectTab />
  );
};

export default DashboardPage;
