import styled from "styled-components";

const Container = styled.div`
  display : inline-block;
  width: 100%;
  border-radius: 8px;
  padding: 10px 12px;
  border: 1px solid rgba(132,140,148,1);
  font-size: 17px;
  font-weight: 500;
  background-color: #F9F9F9;
  height: 400px;
  line-height : 1.4;
  overflow : hidden auto;
`

const Content = styled.div`
  display : inline-flex;
  flex-wrap : wrap;
  justify-content : start;
  align-items : center;
  width : 100%;
  font-size: 15px;
  font-weight : 400;

  span {
    display : inline-block;
  }

  input, textarea {
    display : inline-flex;
    font-weight : 500;
    border : unset !important;;
    // text-decoration : underline;
    width : ${({ size }) => (size ? size : '600px')};
    text-align : ${({ align }) => (align ? align : 'unset')};
    padding : 0 5px;
    height : unset !important;
    background-color : transparent !important;
    border-radius : unset !important;
    outline : none !important;
    border-bottom : #eeeeee solid 1px !important;
  }

`

const Template = ({type, data, change}) => { 

  switch(type) {

    case "CCONFIRM":
      return (
        <Container>

          <Content size="150px" align="center">
            <input onChange={(e) => change(e.target.name, e.target.value)} type="text" value={data.user} name="user" />
            <span>크루님께서 결제한 액티비티가 아래와 같이 예약 확정되어 안내드려요.</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content size="600px">
            <span>- 파트너:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.partner} name="partner" />
          </Content>

          <Content size="600px">
            <span>- 액티비티:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.lesson} name="lesson" />
          </Content>

          <Content size="600px">
            <span>- 인원:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.people} name="people" />
          </Content>

          <Content size="600px">
            <span>- 일정:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.schedule} name="schedule" />
          </Content>

          <Content size="600px">
            <span>- 장소:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.location} name="location" />
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>액티비티 전에 파트너님과 [마이 세모스 > 우측 상단 세모스 챗]을 통해 소통하실 수 있어요. </span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>준비물 및 기타 유의사항은 [마이 세모스]를 확인해 주세요.</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>레저스포츠 1등 예약 앱, 세모스!</span>
          </Content>

          <Content >
            <span>☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)</span>
          </Content>

        </Container>
      );
    break;

    case "CCREFUND":
      return (
        <Container>

          <Content size="150px" align="center">
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.user} name="user" />
            <span>크루님의 요청으로 액티비티가 취소되어 안내드려요.</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>- 파트너:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.partner} name="partner" />
          </Content>

          <Content >
            <span>- 액티비티:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.lesson} name="lesson" />
          </Content>

          <Content >
            <span>- 인원:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.people} name="people" />
          </Content>

          <Content >
            <span>- 일정:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.schedule} name="schedule" />
          </Content>

          <Content >
            <span>- 장소:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.location} name="location" />
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>- 환불 예정 금액 : </span>
          </Content>

          <Content >
          <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.refund} name="refund" />
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>***다음 기회에 꼭 세모스를 찾아주세요! 결제 금액은 영업일 기준 1~3일 안에 환불됩니다.</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>레저스포츠 1등 예약 앱, 세모스!</span>
          </Content>

          <Content >
            <span>☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)</span>
          </Content>

        </Container>
      );
    break;

    case "CPREFUND":
      return (
        <Container>

          <Content  size="150px" align="center">
            <span>크루님! 파트너님의 사정으로 예약하신 일정 진행이 불가하여 결제하신 액티비티가 환불될 예정이에요.</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>- 파트너:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.partner} name="partner" />
          </Content>

          <Content >
            <span>- 액티비티:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.lesson} name="lesson" />
          </Content>

          <Content >
            <span>- 인원:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.people} name="people" />
          </Content>

          <Content >
            <span>- 일정:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.schedule} name="schedule" />
          </Content>

          <Content >
            <span>- 장소:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.location} name="location" />
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>- 환불 사유 :</span>
          </Content>

          <Content >
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.refundTag} name="refundTag" />
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>- 환불 예정 금액 : </span>
          </Content>

          <Content >
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.refund} name="refund" />
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>사용하신 포인트와 함께 100% 전액 환불될 예정이에요.</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>***세모스에서 다른 액티비티를 선택해 주세요.</span>
          </Content>

          <Content >
            <span>결제 금액은 영업일 기준 1~3일 안에 환불됩니다.</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>레저스포츠 1등 예약 앱, 세모스!</span>
          </Content>

          <Content >
            <span>☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)</span>
          </Content>

        </Container>
      );
    break;

    case "CAREFUND":
      return (
        <Container>

          <Content  size="150px" align="center">
            <span>크루님! 24시간 이내에 예약 확정이 되지 않아 결제하신 액티비티가 환불될 예정이에요.</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>- 파트너:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.partner} name="partner" />
          </Content>

          <Content >
            <span>- 액티비티:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.lesson} name="lesson" />
          </Content>

          <Content >
            <span>- 인원:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.people} name="people" />
          </Content>

          <Content >
            <span>- 일정:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.schedule} name="schedule" />
          </Content>

          <Content >
            <span>- 장소:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.location} name="location" />
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>- 환불 사유 :</span>
          </Content>

          <Content >
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.refundTag} name="refundTag" />
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>- 환불 예정 금액 : </span>
          </Content>

          <Content >
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.refund} name="refund" />
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>사용하신 포인트와 함께 100% 전액 환불될 예정이에요.</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>***세모스에서 다른 액티비티를 선택해 주세요.</span>
          </Content>

          <Content >
            <span>결제 금액은 영업일 기준 1~3일 안에 환불됩니다.</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>레저스포츠 1등 예약 앱, 세모스!</span>
          </Content>

          <Content >
            <span>☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)</span>
          </Content>

        </Container>
      );
    break;

    case "CREMINDER":
      return (
        <Container>

          <Content size="150px" align="center">
            <span>크루님! 다가올 액티비티 잊지 않으셨죠?</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>- 파트너:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.partner} name="partner" />
          </Content>

          <Content >
            <span>- 파트너 연락처:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.partnerNumber} name="partnerNumber" />
          </Content>

          <Content >
            <span>- 액티비티:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.lesson} name="lesson" />
          </Content>

          <Content >
            <span>- 인원:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.people} name="people" />
          </Content>

          <Content >
            <span>- 일정:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.schedule} name="schedule" />
          </Content>

          <Content >
            <span>- 장소:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.location} name="location" />
          </Content>

          <Content >
            <span></span>
          </Content>
          
          <Content >
            <span>준비물 및 기타 유의사항은 [마이 세모스]를 확인해 주세요.</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>레저스포츠 1등 예약 앱, 세모스! </span>
          </Content>

          <Content >
            <span>☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)</span>
          </Content>

        </Container>
      );
    break;

    case "CREVIEW":
      return (
        <Container>

          <Content size="200px" >
            <input onChange={(e) => change(e.target.name, e.target.value)}  style={{ width : '150px' }} type="text" value={data.partner} name="partner" />
            <span> 파트너님과 함께한 액티비티는 어떠셨나요? 세모스 앱에 리뷰를 남겨보세요!</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>회원님의 소중한 피드백은 액티비티를 고민하는 분들에게 큰 도움이 될 거에요.</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>레저스포츠 1등 예약 앱, 세모스!</span>
          </Content>

          <Content >
            <span>☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)</span>
          </Content>

        </Container>
      );
    break;

    // partner

    case "PREFUND":
      return (
        <Container>

          <Content size="150px" align="center">
            <span>파트너님의 액티비티가 환불되어 안내드려요.</span>
          </Content>

          <Content >
            <span>[예약 관리]의 [취소 내역]에서 상세 내용을 확인하실 수 있어요.</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>- 크루 닉네임:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.user} name="user" />
          </Content>

          <Content >
            <span>- 액티비티:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.lesson} name="lesson" />
          </Content>

          <Content >
            <span>- 액티비티 금액:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.partnerPayment} name="partnerPayment" />
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>- 일정:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.schedule} name="schedule" />
          </Content>

          <Content >
            <span>- 장소:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.location} name="location" />
          </Content>

          <Content >
            <span>- 인원:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.people} name="people" />
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>레저스포츠 1등 예약 앱, 세모스!</span>
          </Content>

          <Content >
            <span>[구글 Play 스토어 / 애플 App 스토어에서 세모스 파트너 다운로드]</span>
          </Content>

          <Content >
            <span>☎️ 문의: 카카오톡 채널 [세모스 파트너] (오전 10시 ~ 오후 7시)</span>
          </Content>

        </Container>
      );
    break;

    case "PCONFIRM2":
      return (
        <Container>

          <Content size="150px" align="center">
            <span>파트너님의 액티비티가 아래와 같이 예약 확정되어 안내드려요. </span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>- 크루 닉네임:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.user} name="user" />
          </Content>

          <Content >
            <span>- 액티비티:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.lesson} name="lesson" />
          </Content>

          <Content >
            <span>- 액티비티 금액:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.partnerPayment} name="partnerPayment" />
          </Content>

          <Content >
            <span>- 포함사항:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.include} name="include" />
          </Content>

          <Content >
            <span>- 불포함사항:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.exclude} name="exclude" />
          </Content>

          <Content >
            <span>- 일정:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.schedule} name="schedule" />
          </Content>

          <Content >
            <span>- 장소:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.location} name="location" />
          </Content>

          <Content >
            <span>- 인원:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.people} name="people" />
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>***액티비티를 결제하신 크루님과 [크루 관리]를 통해 소통하실 수 있어요.</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>레저스포츠 1등 예약 앱, 세모스!</span>
          </Content>

          <Content >
            <span>[구글 Play 스토어 / 애플 App 스토어에서 세모스 파트너 다운로드</span>
          </Content>

          <Content >
            <span>☎️ 문의: 카카오톡 채널 [세모스 파트너] (오전 10시 ~ 오후 7시)</span>
          </Content>

        </Container>
      );
    break;

    case "PSOCONFIRM":
      return (
        <Container>

          <Content size="150px" align="center">
            <span>[세모스ONLY] 액티비티 그룹 진행이 확정되었어요. </span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>- 액티비티: [세모스ONLY]</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.lesson} name="lesson" />
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>- 예약 확정 인원 수:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.user} name="user" />
          </Content>

          <Content >
            <span>- 액티비티 금액:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.partnerPayment} name="partnerPayment" />
          </Content>

          <Content >
            <span>- 포함사항:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.include} name="include" />
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>- 일정:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.schedule} name="schedule" />
          </Content>

          <Content >
            <span>- 장소:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.location} name="location" />
          </Content>

          <Content >
            <span>- 인원:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.people} name="people" />
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>[세모스ONLY란?]</span>
          </Content>

          <Content >
            <span>- 세모스에서 자체적으로 운영하는 액티비티로, (커뮤니케이션), (파트너 등급), (리뷰) 등을 종합적으로 고려하여 최우수 파트너님께 우선 배정해드리는 그룹 액티비티입니다. 액티비티 진행 </span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.soLimit} name="soLimit" />
            <span>전 예약이 마감되어 확정 여부가 결정됩니다.</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>레저스포츠 1등 예약 앱, 세모스!</span>
          </Content>

          <Content >
            <span>[구글 Play 스토어 / 애플 App 스토어에서 세모스 파트너 다운로드</span>
          </Content>

          <Content >
            <span>☎️ 문의: 카카오톡 채널 [세모스 파트너] (오전 10시 ~ 오후 7시)</span>
          </Content>

        </Container>
      );
    break;
    case "PREMINDER3":
      return (
        <Container>

          <Content size="150px" align="center">
            <span>파트너님! 예정된 액티비티 잊지 않으셨죠?</span>
          </Content>
          
          <Content >
            <span></span>
          </Content>

          <Content >
            <span>진행해주실 액티비티의 크루님들께 [사진 리뷰 작성] 독려를 부탁드려요.</span>
          </Content>
          
          <Content >
            <span></span>
          </Content>

          <Content >
            <span>- 크루 닉네임:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.user} name="user" />
          </Content>

          <Content >
            <span>- 크루 연락처:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.userPhone} name="userPhone" />
          </Content>

          <Content >
            <span>- 액티비티:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.lesson} name="lesson" />
          </Content>

          <Content >
            <span>- 액티비티 금액:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.partnerPayment} name="partnerPayment" />
          </Content>

          <Content >
            <span>- 포함사항:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.include} name="include" />
          </Content>

          <Content >
            <span>- 불포함사항:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.exclude} name="exclude" />
          </Content>

          <Content >
            <span>- 일정:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.schedule} name="schedule" />
          </Content>

          <Content >
            <span>- 장소:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.location} name="location" />
          </Content>

          <Content >
            <span>- 인원:</span>
            <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.people} name="people" />
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>***파트너님의 열정적인 액티비티를 경험한 분들의 사진 리뷰가 많을수록 결제를 고민하는 크루님께 매력적인 액티비티가 돼요!</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>레저스포츠 1등 예약 앱, 세모스!</span>
          </Content>

          <Content >
            <span>[구글 Play 스토어 / 애플 App 스토어에서 세모스 파트너 다운로드]</span>
          </Content>

          <Content >
            <span>☎️ 문의: 카카오톡 채널 [세모스 파트너] (오전 10시 ~ 오후 7시)</span>
          </Content>

        </Container>
      );
    break;

    case "PPAYMENT2":
        return (
          <Container>

            <Content >
              <span>액티비티 결제가 발생했어요.</span>
            </Content>
  
            <Content >
              <span>24시간 이내에 세모스 파트너 앱/웹에서 예약을 확정해주세요.</span>
            </Content>
  
            <Content >
              <span></span>
            </Content>
    
            <Content >
              <span>파트너 앱 : [예약 관리 → 결제 완료 → 예약확정]</span>
            </Content>
    
            <Content >
              <span></span>
            </Content>
  
            <Content >
              <span>- 크루 닉네임:</span>
              <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.user} name="user" />
            </Content>
  
            <Content >
              <span>- 액티비티:</span>
              <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.lesson} name="lesson" />
            </Content>
  
            <Content >
              <span>- 액티비티 금액:</span>
              <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.partnerPayment} name="partnerPayment" />
            </Content>
  
            <Content >
              <span>- 포함사항:</span>
              <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.include} name="include" />
            </Content>
  
            <Content >
              <span>- 불포함사항:</span>
              <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.exclude} name="exclude" />
            </Content>
  
            <Content >
              <span>- 일정:</span>
              <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.schedule} name="schedule" />
            </Content>
  
            <Content >
              <span>- 장소:</span>
              <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.location} name="location" />
            </Content>
  
            <Content >
              <span>- 인원:</span>
              <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.people} name="people" />
            </Content>
  
            <Content >
              <span></span>
            </Content>

            <Content size="150px" align="center">
              <span>***결제 24시간 이후에는 액티비티가 자동 취소되오니, 위 일정이 가능하시다면 예약을 꼭 확정해주세요.</span>
            </Content>

            <Content >
              <span></span>
            </Content>

            <Content >
              <span>레저스포츠 1등 예약 앱, 세모스!</span>
            </Content>
  
            <Content >
            <span>[구글 Play 스토어 / 애플 App 스토어에서 세모스 파트너 다운로드]</span>
            </Content>
  
            <Content >
              <span>☎️문의: 카카오톡 채널 [세모스 파트너] (오전 10시 ~ 오후 7시)</span>
            </Content>
  
          </Container>
        );
      break;

      case "PSOPAYMENT":
        return (
            <Container>

            <Content >
              <span>[세모스ONLY] 액티비티 결제가 발생했어요. </span>
            </Content>
  
            <Content >
              <span></span>
            </Content>
    
            <Content >
              <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.soLimit} name="soLimit" />
              <span>이내에 진행 가능 여부를 카카오톡으로 회신해주세요.</span>
            </Content>
    
            <Content >
              <span></span>
            </Content>

            <Content >
              <span>- 액티비티: [세모스ONLY] </span>
              <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.lesson} name="lesson" />
            </Content>
    
            <Content >
              <span></span>
            </Content>
  
            <Content >
              <span>- 크루 닉네임:</span>
              <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.user} name="user" />
            </Content>
  
            <Content >
              <span>- 액티비티 금액:</span>
              <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.partnerPayment} name="partnerPayment" />
            </Content>
  
            <Content >
              <span>- 포함사항:</span>
              <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.include} name="include" />
            </Content>
  
            <Content >
              <span></span>
            </Content>
  
            <Content >
              <span>- 일정:</span>
              <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.schedule} name="schedule" />
            </Content>
  
            <Content >
              <span>- 장소:</span>
              <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.location} name="location" />
            </Content>
  
            <Content >
              <span>- 인원:</span>
              <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.people} name="people" />
            </Content>
  
            <Content >
              <span></span>
            </Content>

            <Content size="150px" align="center">
              <span>***본 제안은</span>
              <input onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.now} name="now" />
              <span>까지만 유효해요</span>
            </Content>

            <Content >
              <span></span>
            </Content>

            <Content >
              <span>[세모스ONLY란?]</span>
            </Content>
  
            <Content >
            <span>- 세모스에서 자체적으로 운영하는 PB 액티비티로, (커뮤니케이션), (파트너 등급), (리뷰) 등을 종합적으로 고려하여 최우수 파트너님께 우선 배정해드리는 액티비티입니다.</span>
            </Content>
    
            <Content >
              <span></span>
            </Content>
  
            <Content >
            <span>파트너님이 세모스 내에서 판매하시는 액티비티와 별개로 진행되며, 수수료율은 진행하시는 파트너님의 등급에 따라 결정됩니다.</span>
            </Content>
    
            <Content >
              <span></span>
            </Content>

            <Content >
              <span>레저스포츠 1등 예약 앱, 세모스!</span>
            </Content>
  
            <Content >
            <span>[구글 Play 스토어 / 애플 App 스토어에서 세모스 파트너 다운로드]</span>
            </Content>
  
            <Content >
              <span>☎️문의: 카카오톡 채널 [세모스 파트너] (오전 10시 ~ 오후 7시)</span>
            </Content>
  
          </Container>
        );
      break;
  }

}

export default Template;