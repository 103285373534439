import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

//img
import Arrow from "../../assets/icon/selectArrow.png";
import colors from "../../styles/constants/colors";

const DropDown = ({ data, type, idx, setData, option, defaultText, no, keyName, width, doubleDropDown, reset, height, z, day }) => {
  const [open, setOpen] = useState(false);

  const ref = useRef();

  const handleClose = (e) => {
    if (open && (!ref.current || !ref.current.contains(e.target))) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, [open]);

  const changeValue = (value) => {
    if (doubleDropDown && data !== value) {
      reset();
    }
    if (type === "curriculum") {
      console.log(keyName, value, idx);
      setData("time", value, idx, day);
    } else {
      setData((prev) => ({ ...prev, [keyName]: value }));
    }

    setOpen(false);
  };

  return (
    <div
      style={{
        position: "relative",
        cursor: "pointer",
        width: width ? `${width}` : "100%",
        zIndex: z ? `${z}` : "40",
      }}
      onBlur={() => setOpen(false)}
      ref={ref}
    >
      <SelectBox className="f13" no={no} onClick={() => setOpen((prev) => !prev)}>
        {data ? data : defaultText}
        <SelectIcon src={Arrow} open={open} />
      </SelectBox>
      {open && (
        <OptionContainer height={height}>
          {option.map((el, i) => (
            <Option className="f12" key={el.text} last={option.length - 1 == i} onClick={() => changeValue(el.value)} color={el.color}>
              {el.text}
            </Option>
          ))}
        </OptionContainer>
      )}
    </div>
  );
};

export default DropDown;

const SelectBox = styled.div`
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.Gr02};
  line-height: 100%;
  background-color: ${colors.White};
  /* min-width: 7rem !important; */
  width: 100%;
  white-space: nowrap;
`;

const SelectIcon = styled.img`
  width: calc(1rem * 17 / 14);
  height: calc(1rem * 17 / 14);
  margin-left: 8px;

  transform: ${({ open }) => (open ? "rotate(-180deg)" : "")};
  transition: all 0.7s;
`;

const OptionContainer = styled.div`
  width: 100%;
  /* min-width: 7rem; */
  position: absolute;
  top: calc(16px + 1rem * 17 / 14);
  left: 0;
  z-index: 2;
  border: 1px solid ${colors.Gr02};
  background-color: ${colors.White};
  height: ${({ height }) => `${height}`};
  overflow: auto;
`;

const Option = styled.div`
  padding: 8px 16px;
  font-weight: 500;
  color: ${({ color }) => (color ? color : colors.Gr05)};
  border-bottom: ${({ last }) => (last ? "none" : `1px solid ${colors.Gr02}`)};
  display: flex;
  justify-content: center;
  align-items: center;
`;
