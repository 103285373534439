import colors from "./colors";

const base = {
  height: {
    header: 48,
    container: 830,
    input: 50,
    button: {
      default: 56,
      small: 34,
      large: 66,
    },
  },

  width: {
    navbar: 200,
  },

  borderRadius: {
    default: 0,
  },

  boxShadow: {
    default: `rgba(0, 0, 0, 0.15) 1.95px 1.95px 5px,
      rgba(0, 0, 0, 0.15) -0.5px -0.5px 5px
      `,
  },

  border: `2px solid ${colors.lightGray}`,

  spacing: {
    main: 20,
  },
};

export default base;
