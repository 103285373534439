import styled from "styled-components";
import Input from "../../modules/input";
import Select from "../../modules/select";
import flexbox from "../../styles/func/flexbox";

import colors from "../../styles/constants/colors";

const Card = styled.li`
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
`;

const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  div {
    &:first-child {
      width: 10%;
      ${flexbox()}
    }

    &:nth-child(2) {
      padding: 0 10px;
      width: 35%;
    }

    &:nth-child(3) {
      padding: 0 10px;
      width: 35%;

      input {
        text-decoration: underline;
      }
    }

    &:nth-child(4) {
      padding: 0 10px;
      width: 10%;

      select {
        opacity: ${({ disabled }) => (disabled ? `0.7` : null)};
        pointer-events: ${({ disabled }) => (disabled ? `none` : null)};
      }
    }

    &:nth-child(5) {
      ${flexbox()}
      width: 10%;
    }
  }
`;

const DeleteButton = styled.button`
  color: ${colors.white};
  background-color: ${colors.mediumGray};
  padding: 5px 20px;
  border-radius: 40px;
`;

const BannerEditRowCard = ({
  data,
  deleteHandler,
  editHandler,
  filteredData,
  idx,
}) => {
  let optionList = [];
  const getBannerOptionList = (status) => {
    if (status === false) {
      optionList = Array.from({ length: 1 }, (_, i) => {
        return { text: i, value: i };
      });
    } else {
      optionList = Array.from({ length: filteredData.length }, (_, i) => {
        return { text: i + 1, value: i + 1 };
      });
    }

    return optionList;
  };

  return (
    <Card>
      <Box>
        <div>{data.number}</div>

        <Input
          type="text"
          name="name"
          value={data.subject}
          changeHandler={(e) => editHandler(e.target.value, idx, "subject")}
        />

        <Input
          type="text"
          name="link"
          value={data.link}
          changeHandler={(e) => editHandler(e.target.value, idx, "link")}
        />

        <Select
          optionList={getBannerOptionList(data.status)}
          name="order"
          value={data.order}
          changeHandler={(e) => editHandler(e.target.value, idx, "order")}
          disabled={data.status === "off" ? true : false}
        />

        <div>
          <DeleteButton type="button" onClick={() => deleteHandler(idx)}>
            삭제
          </DeleteButton>
        </div>
      </Box>
    </Card>
  );
};

export default BannerEditRowCard;
