import React, { useState, useEffect } from "react";
import axios from "axios";

// components
import { Wrapper, AbsoluteWrapper, Text } from "../../layouts/layoutStorage";

const LocationPicker = ({
  info,
  location,
  setLocation,
  status,
  text,
  setText,
  id,
}) => {
  const LocationHandle = (data) => {
    if (data) {
      const token = localStorage.getItem("token");
      const Address = data.address;
      const EtcAddress = data.address_extra;

      if (
        window.confirm(
          `진행 장소를 ${Address} ${EtcAddress}로 변경하시겠습니까?`
        )
      ) {
        const frm = new FormData();

        frm.append("mode", "location_change");
        frm.append("token", token);
        frm.append("address", Address);
        frm.append("extra", EtcAddress);
        frm.append("payment_id", id);

        axios
          .post("https://ai.semos.kr/semos_admin/payment/data", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            const Value = res.data;

            if (Value.status === "success") {
              window.alert("변경이 완료되었습니다.");
              window.location.reload();
            }
          });
        setText("");
      }
    }
  };

  return (
    <AbsoluteWrapper
      ai={`flex-start`}
      height={`200px`}
      of={`hidden auto`}
      display={status ? `inline-flex` : `none`}
      z={`150`}
      left={`0`}
      bottom={`-8px`}
      border={`1px solid #DCDCDC`}
      transform={`translate(0, 100%)`}
      width={`100%`}
      padding={`13px 2px`}
      bgc={`#ffffff`}
    >
      <Wrapper wrap={`wrap`} width={`100%`} position={`relative`}>
        {location.length ? (
          location.map((item, idx) => (
            <Wrapper
              onClick={() => LocationHandle(item)}
              key={`${idx}_location_picker`}
              border={
                info.service_location ==
                  `${item.address} ${item.address_extra}` && `1px solid #0E6DFF`
              }
              padding={`5px 7px`}
              cursor={`pointer`}
              width={`100%`}
              jc={`flex-end`}
            >
              <Text
                color={
                  info.service_location ==
                    `${item.address} ${item.address_extra}` && `#0E6DFF`
                }
                className={`f13`}
              >{`${item.address} ${item.address_extra}`}</Text>
            </Wrapper>
          ))
        ) : (
          <Wrapper
            padding={`5px 0`}
            cursor={`pointer`}
            width={`100%`}
            jc={`center`}
          >
            <Text className={`f13`}>{`검색된 장소가 없습니다.`}</Text>
          </Wrapper>
        )}
      </Wrapper>
    </AbsoluteWrapper>
  );
};

export default LocationPicker;
