import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import axios from "axios";

//components
import DropDown from "./dropDownforActivity";
import {
  TableFilterContainer,
  TableFilterContent,
  TableWrapper,
  Wrapper,
  WrapperRow,
  AbsoluteWrapper,
  Icon,
  Text,
  TableContent,
  TableFooter,
  TableColumn,
  TableFooterPaging,
  WrapperColmun,
} from "../../layouts/layoutStorage";
import { curriculumData } from "./activityData";

import colors from "../../styles/constants/colors";

const Curriculum = ({ titleValue, titleOnChange, dropData, dropSetData, idx, type, contentValue, contentOnChange, curriculumFunc, iconImg, day, length }) => {
  const ref = useRef();

  const curriculumContentSet = (e) => {
    ref.current.style.height = "auto";
    ref.current.style.height = ref.current.scrollHeight + "px";
    contentOnChange(e);
  };

  useEffect(() => {
    ref.current.style.height = "auto";
    ref.current.style.height = ref.current.scrollHeight + 1 + "px";
  }, [contentValue]);

  return (
    <WrapperColmun>
      <Wrapper padding="calc(1rem * 16 / 14) 0 0 0" width="100%">
        <Wrapper width="100%" padding="8px 16px" border={`1px solid ${colors.Gr02}`}>
          <input
            style={{ width: "100%", color: colors.Gr05 }}
            placeholder="커리큘럼 제목을 작성해 주세요"
            value={titleValue}
            name="title"
            onChange={titleOnChange}
          />
        </Wrapper>
        <Wrapper width="20rem" margin="0 0 0 8px">
          <DropDown
            z={length ? 599 - idx - length : 599 - idx}
            height="20rem"
            data={dropData}
            setData={dropSetData}
            option={curriculumData}
            no={false}
            keyName="time"
            defaultText="소요 시간"
            type={type}
            idx={idx}
            day={day}
          />
        </Wrapper>
      </Wrapper>
      <Wrapper margin="8px 0 0 0">
        <Wrapper width="100%" margin="0 8px 0 0" padding="8px 16px" border={`1px solid ${colors.Gr02}`}>
          <Textarea
            rows={1}
            ref={ref}
            placeholder="커리큘럼을 간단하게 소개해 주세요"
            value={contentValue}
            name="content"
            onChange={(e) => {
              curriculumContentSet(e);
            }}
          />
        </Wrapper>
        <Icon width={`ccalc(1rem * (17 / 14) + 16px)`} height={`calc(1rem * (17 / 14) + 16px)`} cursor={`pointer`} src={iconImg} onClick={curriculumFunc} />
      </Wrapper>
    </WrapperColmun>
  );
};

export default Curriculum;

const Textarea = styled.textarea`
  outline: none;
  border: none;
  resize: none;
  width: 100%;
  color: ${colors.Gr05};
`;
