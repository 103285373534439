import React, { useState, useEffect, useRef } from "react";
import {
  Wrapper,
  WrapperColmun,
  Text,
  Icon,
  HoverButton,
  Label,
  AbsoluteWrapper,
} from "../../layouts/layoutStorage";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";

import colors from "../../styles/constants/colors";
import ImageCrop from "../designedComponents/imageCrop";

import CameraAdd from "../../assets/icon/camerAdd.png";
import { ReactComponent as Magnify } from "../../assets/designedIcons/magnify.svg";
import { ReactComponent as Dropdown } from "../../assets/designedIcons/dropdown.svg";

const PAGE_LIST = ["액티비티 상세페이지", "파트너 프로필", "기획전"];

const MainBannerDetail = () => {
  const { n } = useParams();
  const inputRef = useRef();
  const eventRef = useRef();

  const { RangePicker } = DatePicker;

  const [cropImage, setCropImage] = useState({
    width: 8,
    height: 5,
    type: "",
    number: "",
    image: "",
    status: false,
    scale: true,
  });

  const [info, setInfo] = useState({
    id: "",
    image: "",
    name: "",
    start: "",
    end: "",
    type: "",
    selected_id: "",
    selected_name: "",
  });

  const [page, setPage] = useState("액티비티 상세페이지");
  const [searchInput, setSearchInput] = useState("");
  const [list, setList] = useState([]);
  const [openEventList, setOpenEventList] = useState(false);
  const [focusedInput, setFocusedInput] = useState("");

  const handleCloseEvent = (e) => {
    if (
      openEventList &&
      (!eventRef.current || !eventRef.current.contains(e.target))
    ) {
      setOpenEventList(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleCloseEvent);

    return () => {
      window.removeEventListener("click", handleCloseEvent);
    };
  }, [openEventList]);

  const landingPages = (type) => {
    setPage(type);
    setSearchInput("");
  };

  const getData = () => {
    const token = localStorage.getItem("token");
    const frm = new FormData();

    frm.append("mode", "banner_detail");
    frm.append("id", n);
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_admin/banner/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        setInfo(data.data.data);
        setPage(
          data.data.data.type_ko
            ? data.data.data.type_ko
            : "액티비티 상세페이지"
        );
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const listChanger = () => {
    const frm = new FormData();

    frm.append("text", searchInput);
    if (page === "액티비티 상세페이지") {
      frm.append("mode", "activity_search");
    } else if (page === "파트너 프로필") {
      frm.append("mode", "partner_search");
    } else if (page === "기획전") {
      frm.append("mode", "exhibition_search");
    }

    axios
      .post("https://ai.semos.kr/semos_admin/banner/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        setList(data.data.list);
      });
  };

  useEffect(() => {
    if (searchInput) {
      listChanger();
    } else if (openEventList) {
      listChanger();
    }
  }, [searchInput, openEventList]);

  const contentSelect = (content) => {
    if (page === "액티비티 상세페이지") {
      setInfo((prev) => ({
        ...prev,
        type: "activity",
        selected_id: content.id,
        selected_name: content.title,
      }));
      setSearchInput("");
    } else if (page === "파트너 프로필") {
      setInfo((prev) => ({
        ...prev,
        type: "partner",
        selected_id: content.id,
        selected_name: content.name,
      }));
      setSearchInput("");
      //   setSearchInput(`${content.id} ${content.name}`);
    } else if (page === "기획전") {
      setInfo((prev) => ({
        ...prev,
        type: "event",
        selected_id: content.id,
        selected_name: content.name,
      }));
      setSearchInput("");
      setTimeout(() => {
        setOpenEventList(false);
      }, 50);
      //   setSearchInput(`${content.name}`);
    }
  };

  const setImageFromFile = ({ file, setImageUrl }) => {
    let reader = new FileReader();
    reader.onload = function () {
      setImageUrl({ result: reader.result });
    };

    reader.readAsDataURL(file);
  };

  const addImg = ({ target: { files } }) => {
    setImageFromFile({
      file: files[0],
      setImageUrl: ({ result }) => {
        setInfo((prev) => ({ ...prev, image: result }));
      },
    });
  };

  const ImgChange = (value, type, number) => {
    setCropImage({
      width: 375,
      height: 285,
      type: type,
      number: number,
      image: value,
      status: true,
      scale: true,
    });
  };

  const saveData = () => {
    const token = localStorage.getItem("token");
    let type = "";

    if (info.type == "activity") {
      type = "ActivityDetail";
    } else if (info.type === "partner") {
      type = "PartnerDetail";
    } else {
      type = "Event";
    }

    const frm = new FormData();
    frm.append("mode", "banner_edit");
    frm.append("token", token);
    frm.append("id", n);
    frm.append("title", info.name);
    frm.append("image", info.image);
    frm.append("start", info.start);
    frm.append("end", info.end);
    frm.append("type", type);
    frm.append("target", info.selected_id);

    axios
      .post("https://ai.semos.kr/semos_admin/banner/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        // setList(data.data.list);
        console.log(data.data);
        if (data.data.status === "success") {
          window.alert("변경사항이 저장되었습니다.");
          window.location.reload();
        }
      });
  };

  return (
    <Wrapper
      width="100%"
      height="100%"
      bgc={colors.Gr01}
      ai="flex-start"
      padding="36px"
    >
      <Wrapper width="718px" height="100%">
        <WrapperColmun width="100%" height="100%" jc="space-between">
          <WrapperColmun width="100%" height="100%">
            <Wrapper width="100%" margin="0 0 24px 0">
              <Text className="f24" weight="700" color={colors.Gr06} ta="start">
                {Number(n) ? "배너 수정" : "배너 등록"}
              </Text>
            </Wrapper>

            <Wrapper
              width="375px"
              height="285px"
              border={`1px solid ${colors.Gr02}`}
              bgc={colors.White}
              jc="center"
              ai="center"
              radius="8px"
              margin="0 0 0 172px"
              cursor="pointer"
            >
              <Label
                htmlFor="banner"
                width="100%"
                height="100%"
                cursor="pointer"
              >
                {info.image ? (
                  <Icon
                    src={info.image}
                    width="100%"
                    height="100%"
                    radius="8px"
                  />
                ) : (
                  <WrapperColmun
                    width="100%"
                    height="100%"
                    jc="center"
                    ai="center"
                  >
                    <Icon src={CameraAdd} width="40px" height="40px" />
                    <Wrapper height="8px" />
                    <Text className="f12" weight="500" color={colors.Gr03}>
                      375*285
                    </Text>
                    <Text className="f12" weight="500" color={colors.Gr03}>
                      사진 등록하기
                    </Text>
                  </WrapperColmun>
                )}
                <input
                  type="file"
                  id="banner"
                  accept="image/*"
                  onChange={addImg}
                />
              </Label>
            </Wrapper>

            <Wrapper height="28px" />

            <WrapperColmun>
              <Text className="f16" weight="600" color={colors.Gr05}>
                배너 명
              </Text>
              <Wrapper height="8px" />
              <Wrapper
                bgc={colors.White}
                border={`1px solid ${colors.Gr02}`}
                radius="8px"
              >
                <Input
                  maxLength="20"
                  placeholder="20자 제한입니다."
                  onChange={(e) =>
                    setInfo((prev) => ({ ...prev, name: e.target.value }))
                  }
                  value={info.name}
                />
              </Wrapper>

              <Wrapper height="8px" />
            </WrapperColmun>

            <Wrapper height="28px" />

            <WrapperColmun>
              <Text className="f16" weight="600" color={colors.Gr05}>
                노출 기간
              </Text>
              <Wrapper height="8px" />

              <RangePicker
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                onChange={(_, date) =>
                  setInfo((prev) => ({ ...prev, start: date[0], end: date[1] }))
                }
                size="large"
                value={[
                  info.start ? dayjs(info.start) : dayjs(),
                  info.end ? dayjs(info.end) : dayjs(),
                ]}
              />

              <Wrapper height="8px" />
            </WrapperColmun>

            <Wrapper height="28px" />

            <WrapperColmun>
              <Wrapper width="100%" jc="space-between">
                <Text className="f16" weight="600" color={colors.Gr05}>
                  랜딩페이지 연결
                </Text>

                <Wrapper mw="calc(1rem * 480/14)">
                  <Text
                    className="f14"
                    color={colors.Bl07}
                    weight="500"
                    ellipsis={true}
                  >
                    {`${info.selected_id} ${info.selected_name}`}
                  </Text>
                </Wrapper>
              </Wrapper>
              <Wrapper height="8px" />

              <Wrapper>
                {PAGE_LIST.map((item) => (
                  <HoverButton
                    key={item}
                    active={page === item}
                    padding="6px 12px"
                    margin="0 8px 0 0"
                    bgc={colors.White}
                    bgch={colors.Bl02}
                    color={colors.Gr05}
                    colorh={colors.Gr06}
                    radius="8px"
                    onClick={() => landingPages(item)}
                  >
                    <Text className="f12">{item}</Text>
                  </HoverButton>
                ))}
              </Wrapper>

              <Wrapper height="12px" />

              {page === "기획전" ? (
                <Wrapper
                  width="100%"
                  bgc={colors.White}
                  border={`1px solid ${
                    openEventList ? colors.Bl05 : colors.Gr02
                  }`}
                  radius="8px"
                  ref={eventRef}
                  position="relative"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenEventList((prev) => !prev);
                    setFocusedInput(page);
                  }}
                  cursor="pointer"
                >
                  <Text
                    width="100%"
                    className="f12"
                    weight="500"
                    style={{ padding: "11px 16px" }}
                    color={searchInput ? colors.Gr06 : colors.Gr04}
                  >
                    {searchInput ? searchInput : "기획전을 선택해주세요"}
                  </Text>
                  <Dropdown
                    fill={colors.Gr04}
                    style={{
                      marginRight: 12,
                      transform: openEventList ? "rotate(-180deg)" : "",
                    }}
                  />
                  {/* 기획전 */}
                  {page === "기획전" && openEventList && list?.length > 0 && (
                    <AbsoluteWrapper
                      bgc={colors.White}
                      z={20}
                      fd="column"
                      top="50px"
                      width="100%"
                      radius="8px"
                      border={`1px solid ${colors.Gr02}`}
                      // height="200px"
                      style={{ overflow: "auto" }}
                    >
                      {list.map((item) => {
                        const { id, name } = item;

                        return (
                          <HoverButton
                            key={id}
                            style={{ width: "100%" }}
                            bgch={colors.Bl01}
                            onClick={() => contentSelect(item)}
                          >
                            <Wrapper padding="8px" width="100%">
                              <Text
                                className="f12"
                                weight="600"
                                color={colors.Gr04}
                              >
                                {name}
                              </Text>
                            </Wrapper>
                          </HoverButton>
                        );
                      })}
                    </AbsoluteWrapper>
                  )}
                </Wrapper>
              ) : (
                <Wrapper
                  width="100%"
                  bgc={colors.White}
                  padding="0 0 0 8px"
                  border={`1px solid ${colors.Gr02}`}
                  radius="8px"
                >
                  <Magnify fill={colors.Gr04} width="20px" height="20px" />
                  <Input
                    ref={inputRef}
                    placeholder={
                      page === "파트너 프로필"
                        ? "ID 또는 파트너 이름으로 검색해주세요."
                        : "ID 또는 액티비티 이름으로 검색해주세요."
                    }
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    onFocus={() => setFocusedInput(page)}
                    focused={focusedInput === page}
                  />
                </Wrapper>
              )}
              <Wrapper height="8px" />

              {/* 액티비티 상세페이지 || 파트너 프로필 */}
              {page !== "기획전" && searchInput && list?.length > 0 && (
                <WrapperColmun
                  bgc={colors.White}
                  border={`1px solid ${colors.Gr02}`}
                  height="200px"
                  style={{ overflow: "scroll" }}
                >
                  {list.map((item) => {
                    if (page === "액티비티 상세페이지") {
                      let locationText = "";

                      for (let i = 0; i < item.location?.length; i++) {
                        if (i) {
                          locationText = locationText + ", " + item.location[i];
                        } else {
                          locationText = item.location[i];
                        }
                      }
                      return (
                        <HoverButton
                          style={{ width: "100%" }}
                          bgch={colors.Bl01}
                          onClick={() => contentSelect(item)}
                        >
                          <Wrapper padding="8px" width="100%">
                            <Icon
                              src={item.image}
                              width="calc(1rem * 48 / 14 + 8px)"
                              height="calc(1rem * 48 / 14 + 8px)"
                              margin="0 12px 0 0"
                            />
                            <WrapperColmun>
                              <Text className="f14">{item.title}</Text>
                              <Wrapper height="4px" />
                              <Wrapper>
                                <Wrapper>
                                  <Text
                                    className="f12"
                                    weight="600"
                                    color={colors.Gr04}
                                  >
                                    ID
                                  </Text>
                                  <Wrapper width="4px" />
                                  <Text
                                    className="f12"
                                    weight="500"
                                    color={colors.Gr05}
                                  >
                                    {item.id}
                                  </Text>
                                </Wrapper>
                              </Wrapper>
                              <Wrapper height="4px" />
                              <Wrapper>
                                <Text
                                  className="f12"
                                  weight="600"
                                  color={colors.Gr04}
                                >
                                  장소
                                </Text>
                                <Wrapper width="4px" />
                                <Text
                                  className="f12"
                                  weight="500"
                                  color={colors.Gr05}
                                >
                                  {locationText}
                                </Text>
                              </Wrapper>
                            </WrapperColmun>
                          </Wrapper>
                        </HoverButton>
                      );
                    } else {
                      return (
                        <HoverButton
                          style={{ width: "100%" }}
                          bgch={colors.Bl01}
                          onClick={() => contentSelect(item)}
                        >
                          <Wrapper padding="12px 8px">{item.name}</Wrapper>
                        </HoverButton>
                      );
                    }
                  })}
                </WrapperColmun>
              )}
            </WrapperColmun>
          </WrapperColmun>

          <Wrapper width="100%" jc="flex-end">
            <Button
              active={
                info.image &&
                info.name &&
                info.start &&
                info.end &&
                info.selected_id &&
                info.type
              }
              onClick={() =>
                info.image &&
                info.name &&
                info.start &&
                info.end &&
                info.selected_id &&
                info.type &&
                saveData()
              }
            >
              <Text
                className="f16"
                weight="600"
                color={
                  info.image &&
                  info.name &&
                  info.start &&
                  info.end &&
                  info.selected_id &&
                  info.type
                    ? colors.White
                    : colors.Gr03
                }
              >
                등록하기
              </Text>
            </Button>
          </Wrapper>
        </WrapperColmun>
      </Wrapper>

      <ImageCrop
        info={cropImage}
        setInfo={setCropImage}
        setImg={setInfo}
        img={info}
      />
    </Wrapper>
  );
};

export default MainBannerDetail;

const Input = styled.input`
  width: 100%;
  padding: 11px 16px;
`;

const Button = styled.div`
  width: 160px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ active }) => (active ? colors.Bl07 : colors.Gr01)};
  cursor: pointer;
  border-radius: 8px;
`;
