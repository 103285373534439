import styled from "styled-components";
import base from "../../../styles/constants/base";
import colors from "../../../styles/constants/colors";
import calendarIcon from "../../../assets/icon/calendar-b.svg";
import { Link } from "react-router-dom";
import ToggleButton from "../../../modules/toggleButton";
import flexbox from "../../../styles/func/flexbox";
import transition from "../../../styles/func/transition";

const Card = styled.li`
  position: relative;
  width: 100%;
  height: 190px;
  box-shadow: ${base.boxShadow.default};
  border-radius: ${base.borderRadius.default}px;
  transition: ${transition("opacity")};

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  ${({ active }) => !active && `opacity: 0.6`}
`;

const SLink = styled(Link)`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 20px;
`;

const Thumbnail = styled.img`
  width: 190px;
  height: 100%;
  background-color: ${colors.gray};
  border-radius: ${base.borderRadius.default}px;
  margin-right: 20px;
  overflow: hidden;
  flex-shrink: 0;
`;

const MetaBox = styled.div`
  width: 100%;
  ${flexbox("space-between", "flex-start", "column")}
`;

const Top = styled.div`
  width: 100%;

  div {
    ${flexbox("space-between")}
  }
`;

const Schedule = styled.p`
  ${flexbox("flex-start")}

  img {
    margin-right: 10px;
  }
`;

const FixedWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const Cnt = styled.span`
  ${({ exist }) => exist === "true" && `color: ${colors.red};`}
`;

const LessonCard = ({ data, activeToggleHandler }) => {
  return (
    <Card active={data.status}>
      <SLink to={`/partner/111/lesson/123`}>
        <Thumbnail src={data.img} />

        <MetaBox>
          <Top>
            <div>
              <p>{data.title}</p>
            </div>

            <Schedule>
              <img src={calendarIcon} />

              <span>
                일정{" "}
                <Cnt exist={data.schedule.length > 0 ? "true" : "false"}>
                  ({data.schedule.length})
                </Cnt>
              </span>
            </Schedule>
          </Top>

          <p>{data.price.toLocaleString()}원</p>
        </MetaBox>
      </SLink>

      <FixedWrapper>
        <ToggleButton
          toggleHandler={() =>
            activeToggleHandler(data.id, {
              ...data,
              status: !data.status,
            })
          }
          status={data.status}
        />
      </FixedWrapper>
    </Card>
  );
};

export default LessonCard;
