import styled from "styled-components";
import Border from "../../../modules/border";
import base from "../../../styles/constants/base";
import colors from "../../../styles/constants/colors";
import typography from "../../../styles/constants/typography";
import flexbox from "../../../styles/func/flexbox";
import regionIcon from "../../../assets/icon/region-b.svg";
import OverlayPortal from "../../../overlayPortal";
import Overlay from "../../../layouts/overlay";
import { useState } from "react";
import LocationModal from "../../../layouts/modal/location";
import { Link } from "react-router-dom";

const Card = styled.li`
  width: 100%;
  box-shadow: ${base.boxShadow.default};
  border-radius: ${base.borderRadius.default}px;
  padding: 20px;
  margin-bottom: 20px;

  header {
    width: 100%;
    height: 40px;
    ${flexbox("space-between")}

    p {
      font-size: ${typography.size.medium}px;
      font-weight: ${typography.weight.bold};
    }

    button {
      color: ${colors.white};
      background-color: ${colors.blue};
      padding: 10px 20px;
      border-radius: ${base.borderRadius.default}px;
    }
  }
`;

const Location = styled.div`
  ${flexbox("flex-start")}

  img {
    margin-right: 10px;
  }
`;

const SLink = styled(Link)`
  width: 100%;
  padding: 10px 0;
  display : inline-flex;
  align-items : center;
`;

const LocationCard = ({ addHandler, name, data, pid }) => {
  const [modal, setModal] = useState(false);


  const [locationData, setLocationData] = useState({
    location: "",
    location_additional: "",
    location_dong: "",
    location_gu: "",
    location_si: "",
    lesson_name: name,
    partner_number : pid
  });

  const openModalHandler = () => {
    setModal(!modal);
  };

  return (
    <>
      <Card>
        <header>
          <p>{name}</p>

          <button type="button" onClick={openModalHandler}>
            장소 추가
          </button>
        </header>

        {data ? data.map((item, idx) => (
          <div key={idx}>
            <Border margin="10px" />

            <Location>
              <SLink to={`/partner/` + pid + `/lesson/${item.id}`}>
                <img src={regionIcon} />
                {item.location}
              </SLink>
            </Location>
          </div>
        )) : ""}
      </Card>

      <OverlayPortal>
        {modal && (
          <>
            <Overlay toggleHandler={openModalHandler} />
            <LocationModal loAdd={() => addHandler(locationData)} name={name} loList={locationData} setLoList={setLocationData} closeHandler={openModalHandler} />
          </>
        )}
      </OverlayPortal>
    </>
  );
};

export default LocationCard;
