import styled from "styled-components";
import base from "../../../../styles/constants/base";
import colors from "../../../../styles/constants/colors";
import typography from "../../../../styles/constants/typography";
import flexbox from "../../../../styles/func/flexbox";

export const Main = styled.main`
  padding: 0 20px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 70px;
`;

export const Row = styled.div`
  width: 100%;
  ${flexbox("flex-start")}
  align-items: ${({ start }) => (start === 1 ? "flex-start;" : null)};
  justify-content: ${({ between }) =>
    between === 1 ? "space-between;" : null};
  flex-direction: ${({ direction }) =>
    direction === "column" ? `column` : null};
  margin: 20px 0;

  ul {
    margin: 20px 0;
    padding-left: 150px;
    width: 100%;
    ${flexbox("flex-start")};
    flex-wrap: wrap;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  ${flexbox("space-between")}
`;

export const LikeDiv = styled.div`
  ${flexbox()}
  margin-left: 50px;

  p {
    flex-shrink: 0;
    font-size: ${typography.size.medium}px;
    font-weight: ${typography.weight.bold};
    margin-right: 100px;
  }
`;

export const DiscountBox = styled.div`
  width: 100%;
  ${flexbox("flex-start")}

  select {
    width: 250px;
  }
`;

export const OptionBox = styled.div`
  width: 100%;
  ${flexbox("flex-start")}
`;

export const OptionWrapper = styled.div`
  width: 250px;
`;

export const DiscountContainer = styled.div`
  width: 100%;
`;

export const DiscountWrapper = styled.div`
  width: 100%;
  ${flexbox()}
  margin-left: 20px;
`;

export const RowTop = styled.div`
  width: 100%;
  ${flexbox("flex-start")}
`;

export const CategoryDiv = styled.div`
  width: 100%;
  ${flexbox("flex-start")}
`;

export const FAQRow = styled.div`
  width: 100%;
  margin: 20px 0;

  p {
    font-size: ${typography.size.medium}px;
    font-weight: ${typography.weight.bold};
    margin-bottom: 10px;
  }
`;

export const FAQTop = styled.div`
  width: 100%;
  margin: 20px 0;
  ${flexbox("space-between")};
`;

export const UseInfoRow = styled.div`
  margin: 20px 0;

  p {
    font-size: ${typography.size.medium}px;
    font-weight: ${typography.weight.bold};
    margin-bottom: 10px;
  }

  textarea {
    box-shadow: none;
    min-height: 90px;

    &:focus {
      box-shadow: none;
    }
  }
`;

export const Title = styled.p`
  width: 150px;
  height: 100%;
  flex-shrink: 0;
  font-size: ${typography.size.medium}px;
  font-weight: ${typography.weight.bold};
  ${flexbox("flex-start")}

  img {
    margin-left: 10px;
  }
`;

export const ButtonBox = styled.div`
  width: 150px;
  ${flexbox("flex-end")};
  white-space: nowrap;

  button {
    height: ${base.height.input}px;
    padding: 10px 20px;
    background-color: ${colors.blue};
    color: ${colors.white};
    border-radius: ${base.borderRadius.default}px;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  padding: 20px;
  ${flexbox("flex-end")}
  position: absolute;
  bottom: 0px;
  background-color: ${colors.white};

  button:first-child {
    margin-right: 10px;
  }
`;

export const ModalText = styled.p`
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 20px 0;
`;
