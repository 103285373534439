import styled from "styled-components";
import MainHeader from "../../components/header";
import { partnerGlobalData, partnerListData } from "../../data/partner";
import usePagination from "../../hooks/usePagination";
import MainLayout from "../../layouts/mainLayout";
import PartnerLayout from "../../layouts/partner";
import base from "../../styles/constants/base";
import searchIcon from "../../assets/icon/search.svg";
import useInput from "../../hooks/useInput";
import Input from "../../modules/input";
import Select from "../../modules/select";
import Index from "../../layouts";
import OverlayPortal from "../../overlayPortal";
import Overlay from "../../layouts/overlay";
import { useState, useEffect } from "react";
import FilterModal from "../../layouts/modal/filter";
import colors from "../../styles/constants/colors";
import FacilityRowCard from "../../components/facility/rowCard";
import useList from "../../hooks/useList";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

// import Pagination from "../../components/pagination";
import flexbox from "../../styles/func/flexbox";
import typography from "../../styles/constants/typography";


const FilterBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  div {
    display: flex;
  }
`;

const SerachIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
  pointer-events: none;
`;

const Main = styled.main`
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;

  ul {
    width: 100%;
  }
`;

const Footer = styled.footer`
  width: 100%;
  padding: 0 ${base.spacing.main}px;
  position: absolute;
  bottom: 20px;
`;


// pagination
const Div = styled.div`
  width: 100%;
  height: 100%;
  ${flexbox("space-between")};
  font-weight: ${typography.weight.regular};
`;

const SectionButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: ${colors.white};
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};

  &:focus {
    box-shadow: ${base.boxShadow.default};
  }
`;

const List = styled.ul`
  ${flexbox()}
`;

const PageButton = styled.li`
  width: 40px;
  height: 40px;
  border: ${({ active }) => (active ? `2px solid ${colors.blue}` : null)};
  color: ${({ active }) => (active ? colors.blue : null)};
  ${flexbox()};
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};
  margin: 0 5px;
  cursor: pointer;
`;

const FacilityPage = () => {
  
  const navigate = useNavigate();
  const { num, y, m, t, f, c } = useParams();
  const numCheck = num > 1 || (y || m || t || f !== 'all' || c !== 'all');

  const [modal, setModal] = useState(false);

  const { value, setValue, inputChangeHandler } = useInput({
    search: t !== 'all' ? t : "" ,
    filter: f !== 'all' ? f : "all" ,
    category: c !== 'all' ? c : "all" ,
    location_category : m !== 'all' ? m : "" ,
    location_type : y !== 'all' ? y : "" ,
  });

  const [facility, setFacility] = useState("");

  const statusToggleHandler = (facility_id, number) => {
    var token = window.localStorage.getItem('token')

    var facilityStatus = facility[number].status == 'yes';
  
    const frm = new FormData();
    frm.append("mode", "facility_status");
    frm.append("number", facility_id);
    frm.append("token", token);
    frm.append("status", facilityStatus ? 'close' : 'yes');

      axios.post("https://ai.semos.kr/semos_admin/facility/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then((data) => {
        var Value = data.data;
        var status = Value.status
        var statusMessage = Value.status_message

        if(status == 'success') {

          if(facilityStatus) {

            var facilityData = [...facility]

            facilityData[number].status = 'close'

            setFacility(facilityData)

          } else {

            var facilityData = [...facility]

            facilityData[number].status = 'yes'

            setFacility(facilityData)

          }

          alert(statusMessage)

        } else if(status == 'waring') {
          alert(statusMessage)
        }

      });

  };

  const modalToggleHandler = () => {
    setModal(!modal);
  };

  const applyHandler = (cv, fv) => {
    setValue((prev) => ({
      ...prev,
      category: cv,
      filter: fv,
    }));
    setModal(!modal);
  };

  const resetHandler = () => {
    setValue((prev) => ({
      ...prev,
      category: "",
      filter: "all",
    }));

  };

  const goToFacilityRegisterHandler = () => {
    navigate(`/facility/register`);
  };


  const [facilityCount, setFacilityCount] = useState(0);
  const [facilityCheck, setFacilityCheck] = useState(0);
  const [page, setPage] = useState(num);
  const [updateStatus, setUpdateStatus] = useState(false);

  const [loName, setLoName] = useState();
  const [loData, setLoData] = useState();


      // 시설 리스트 로드
      useEffect(() => {
    
        var token = window.localStorage.getItem('token')
  
        const frm = new FormData();
        frm.append("mode", (updateStatus || numCheck ? "facility_update" : "list"));
        frm.append("token", token);
        frm.append("page", updateStatus ? 1 : num);
        frm.append("location_type", (updateStatus || numCheck ? value.location_type : "all"));
        frm.append("location_category", (updateStatus || numCheck ? value.location_category : "all"));
        frm.append("text", (updateStatus || numCheck ? value.search : 'all'));
        frm.append("type", (updateStatus || numCheck ? value.filter : 'all'));
        frm.append("category", (updateStatus || numCheck ? value.category : 'all'));

          axios.post("https://ai.semos.kr/semos_admin/facility/data", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          }).then((data) => {
            var Value = data.data;
            var status = Value.status
            var statusMessage = Value.status_message
  
            if(status == 'success') {
  
              setFacility(Value.facility_list)
              setFacilityCount(Value.facility_count)
              setUpdateStatus(true)
              setLoData(Value.location_list);
              setLoName(Value.location_name_list);

              if(updateStatus) {
                setPage(1)
                navigate(`/facility_list/${1}/${value.location_type ? value.location_type : "all" }/${value.location_category ? value.location_category : "all" }/${value.search ? value.search : "all" }/${value.filter}/${value.category}`,  { replace: true})
              } else {
                setPage(num)
                setFacilityCheck(Value.partner_check)
              }
  
            } else if(status == 'waring') {
              alert(statusMessage)
            }
  
          });
  
      }, [value.location_type, value.location_category, value.filter, value.category]);


  const prevHandler = () => {
    if (page > 1) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "facility_update");
      frm.append("token", token);
      frm.append("page", page - 1);
      frm.append("location_type", value.location_type);
      frm.append("location_category", value.location_category);
      frm.append("text", value.search);
      frm.append("type", value.filter);
      frm.append("category", value.category);

        axios.post("https://ai.semos.kr/semos_admin/facility/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setFacility(Value.facility_list)
            setFacilityCount(Value.facility_count)
            setLoData(Value.location_list);
            setLoName(Value.location_name_list);
            setPage(page - 1)
            navigate(`/facility_list/${page - 1}/${value.location_type ? value.location_type : "all" }/${value.location_category ? value.location_category : "all" }/${value.search ? value.search : "all" }/${value.filter}/${value.category}`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    } else {
      alert('첫 페이지입니다.');
    }
  };

  const nextHandler = () => {
    if (page < numPages) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "facility_update");
      frm.append("token", token);
      frm.append("page", page + 1);
      frm.append("location_type", value.location_type);
      frm.append("location_category", value.location_category);
      frm.append("text", value.search);
      frm.append("type", value.filter);
      frm.append("category", value.category);

        axios.post("https://ai.semos.kr/semos_admin/facility/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setFacility(Value.facility_list)
            setFacilityCount(Value.facility_count)
            setLoData(Value.location_list);
            setLoName(Value.location_name_list);
            setPage(page + 1)
            navigate(`/facility_list/${page + 1}/${value.location_type ? value.location_type : "all" }/${value.location_category ? value.location_category : "all" }/${value.search ? value.search : "all" }/${value.filter}/${value.category}`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    } else {
      alert('마지막 페이지입니다.');
    }
  };

  const PageChange = (number) => {
    if ( number < (numPages + 1) && number > 0 ) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "facility_update");
      frm.append("token", token);
      frm.append("page", number);
      frm.append("location_type", value.location_type);
      frm.append("location_category", value.location_category);
      frm.append("text", value.search);
      frm.append("type", value.filter);
      frm.append("category", value.category);

        axios.post("https://ai.semos.kr/semos_admin/facility/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setFacility(Value.facility_list)
            setFacilityCount(Value.facility_count)
            setLoData(Value.location_list);
            setLoName(Value.location_name_list);
            setPage(number)
            navigate(`/facility_list/${number}/${value.location_type ? value.location_type : "all" }/${value.location_category ? value.location_category : "all" }/${value.search ? value.search : "all" }/${value.filter}/${value.category}`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    }
  };

  const TextSearch = (keyValue) => {

    if(keyValue === 'Enter') {
        var token = window.localStorage.getItem('token')

        const frm = new FormData();
        frm.append("mode", "facility_update");
        frm.append("token", token);
        frm.append("page", 1);
        frm.append("location_type", value.location_type);
        frm.append("location_category", value.location_category);
        frm.append("text", value.search);
        frm.append("type", value.filter);
        frm.append("category", value.category);

        axios.post("https://ai.semos.kr/semos_admin/facility/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setFacility(Value.facility_list)
            setFacilityCount(Value.facility_count)
            setLoData(Value.location_list);
            setLoName(Value.location_name_list);
            setPage(1)
            navigate(`/facility_list/${1}/${value.location_type ? value.location_type : "all" }/${value.location_category ? value.location_category : "all" }/${value.search ? value.search : "all" }/${value.filter}/${value.category}`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    }
  
  };


  const numPages = Math.ceil(facilityCount / 10);
  let PageList = [];
  
  if(page < 6) {
    //PageList = [1,2,3,4,5,6,7,8,9,10];
    for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
      PageList.push(i+1);
    }

  } else {
    if( (numPages - 5) < page ) {
      var startPage = numPages - 9;
      if(startPage < 1) {
        startPage = 1;
      }

      for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
        PageList.push(startPage + i);
      }

    } else {
      var startPage = page - 4;
      if(startPage < 1) {
        startPage = 1;
      }
      
      for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
        PageList.push(startPage + i);
      }
    }
  }

    var loNameList = [{
        text : "전체",
        value: ""
    }];

    for(var i=0; i < (loName ? loName.length : 0); i++) {
        loNameList.push({
            text : loName[i],
            value: loName[i]
        })
    }

    var loDataList = [];

    if(value.location_type) {
        var LDS = loData ? loData[value.location_type] : "";

        loDataList.push({
            text : "전체",
            value: ""
        })

        for(var i=0; i < LDS.length; i++) {
            loDataList.push({
                text : LDS[i].location_category,
                value: LDS[i].location_value
            })
        }
    } else {
        loDataList.push({
            text : "대분류 미선택",
            value: ""
        })
    }

  return (
    <>
      <PartnerLayout>

        <MainLayout
          height="800px"
          heightType={true}
          button={{ type: "plus", clickHandler: goToFacilityRegisterHandler }}
        >
          <MainHeader title="시설 리스트">
            <FilterBox>
              <div>
                <Input
                  width="350px"
                  type="text"
                  name="search"
                  value={value.search}
                  changeHandler={inputChangeHandler}
                  placeholder="시설명으로 검색 (ENTER)"
                  keyPressHandler={TextSearch}
                  padding={true}
                  margin={true}
                >
                  <SerachIcon src={searchIcon} />
                </Input>

                {/*<FilterButton type="button" onClick={modalToggleHandler}>
                  필터
                </FilterButton>*/}
              </div>

              <Select
              type="location"
              name="location_type"
              border={false}
              width="100px"
              isTransparent={true}
              value={value.location_type}
              changeHandler={inputChangeHandler}
              optionList={loNameList ? loNameList : []}
            />

            <Select
              type="location"
              name="location_category"
              border={false}
              width="200px"
              isTransparent={true}
              value={value.location_category}
              changeHandler={inputChangeHandler}
              optionList={loDataList ? loDataList : []}
            />
            </FilterBox>
          </MainHeader>

          <Main>
            <Index
              type="facility"
              indexList={[
                "NO.",
                "시설 명",
                "종목",
                "유형",
                "장소",
                "상태",
              ]}
            />
            <ul>
              {facility ? facility.map((item, idx) => (
                <FacilityRowCard
                  key={idx}
                  data={item}
                  idx={idx}
                  statusToggleHandler={() => statusToggleHandler(item.facility_id, idx)}
                  status={item.status}
                />
              )) : ""}
            </ul>
          </Main>

          <Footer>
            {/*<Pagination
              total={listData.length}
              limit={limit}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />*/}

          <Div>
            <SectionButton onClick={prevHandler}>&lt;</SectionButton>

            <List>
              {PageList.length ? PageList.map((item, idx) => 
                ( <PageButton
                    key={idx}
                    onClick={() => PageChange(item)}
                    aria-current={page == item ? "page" : null}
                    active={page == item ? true : false}
                  >
                    {item}
                  </PageButton>
                )) : ""}
            </List>

            <SectionButton onClick={nextHandler}>&gt;</SectionButton>
          </Div>

          </Footer>
        </MainLayout>
      </PartnerLayout>

      <OverlayPortal>
        {modal && (
          <>
            <Overlay toggleHandler={modalToggleHandler} />

            <FilterModal
              value={value}
              closeHandler={modalToggleHandler}
              applyHandler={applyHandler}
              resetHandler={resetHandler}
            />
          </>
        )}
      </OverlayPortal>
    </>
  );
};

export default FacilityPage;
