import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

//components

//img
import NoImage from "../../assets/icon/noImage.png";
import BlueArrow from "../../assets/icon/blueArrow.png";
import Kakao from "../../assets/logo/kakao_logo.png";
import Apple from "../../assets/icon/apple_logo.png";
import Google from "../../assets/logo/googlelogin.png";
import Naver from "../../assets/logo/naver_logo.png";
import Arrow from "../../assets/icon/payment_arrow.png";
import { Wrapper } from "../../layouts/layoutStorage";

const W = window.innerWidth;

const UserDetail = () => {
  //   const {
  //     age,
  //     date,
  //     e_mail,
  //     gender,
  //     name,
  //     phone,
  //     thumbnail,
  //     tier,
  //     type,
  //     user_number,
  //     login_type,
  //   } = info;

  const { n } = useParams();
  const [info, setInfo] = useState([]);

  const [cropImage, setCropImage] = useState({
    width: 8,
    height: 5,
    type: "",
    number: "",
    image: "",
    status: false,
    scale: true,
  });

  const loginTypeMaker = (type) => {
    switch (type) {
      case "카카오":
        return (
          <LoginBoxType className="f14" bgc="#fbe300" c="#3b1e1e">
            <LoginIcon src={Kakao} />
            카카오
          </LoginBoxType>
        );
        break;

      case "애플":
        return (
          <LoginBoxType className="f14" bgc="#F0F0F0" c="#818792">
            <LoginIcon src={Apple} />
            Apple
          </LoginBoxType>
        );
        break;

      case "구글":
        return (
          <LoginBoxType className="f14" bgc="#FFFFFF" c="#3b1e1e">
            <LoginIcon src={Google} />
            Google
          </LoginBoxType>
        );
        break;

      case "네이버":
        return (
          <LoginBoxType className="f14" bgc="#03C75A" c="#ffffff">
            <LoginIcon src={Naver} />
            네이버
          </LoginBoxType>
        );
        break;
    }
  };

  useEffect(() => {
    var token = localStorage.getItem("token");

    const frm = new FormData();

    frm.append("mode", "detail");
    frm.append("token", token);
    frm.append("id", n);

    axios
      .post("https://ai.semos.kr/semos_admin/user/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;

        console.log(Value.data);

        if (Value.status == "success") {
          setInfo(Value.data);
          //   setStatusState(Value.data.status);
        }
      });
  }, [n]);

  const navigate = useNavigate();

  const addingAction = () => {
    console.log("통신");
  };

  const resignHandler = () => {
    if (window.confirm("해당 유저의 탈퇴를 진행하시겠습니까?")) {
      var token = window.localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "retire");
      frm.append("token", token);
      frm.append("id", info.id);

      axios
        .post("https://ai.semos.kr/semos_admin/user/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            alert(statusMessage);
            window.location.reload();
          } else if (status == "waring") {
            alert(statusMessage);
            // window.location.replace("/");
          } else if (status == "fail") {
            alert(statusMessage);
            // window.location.reload();
          }
        });
    }
  };

  const goToSales = (name) => {
    if (name && name != "가입미완") {
      navigate(`/sales_list/1/${name}/all/all/결제 기간/all/결제 방법 전체/결제 플랫폼 전체`);
      setTimeout(() => {
        window.location.reload();
      }, 100);
    } else {
      alert("닉네임이 없는 이용자입니다.");
    }
  };

  const setImageFromFile = ({ file, setImageUrl }) => {
    let reader = new FileReader();
    reader.onload = function () {
      setImageUrl({ result: reader.result });
    };
    reader.readAsDataURL(file);
  };

  const ImgChange = (value, type, number) => {
    setCropImage({
      width: 8,
      height: 5,
      type: type,
      number: number,
      image: value,
      status: true,
      scale: true,
    });
  };

  return (
    <Wrapper
      bgc="#ffffff"
      of={`hidden auto`}
      display={`inline-block`}
      width={`100%`}
      margin="10px 0 0 0"
      mw={`1000px`}
      mh={`calc(100vh - 72px)`}
      padding={`10px 0`}
    >
      <Container>
        <FirstSection>
          <TopSmallTextArea>
            <TopSmallText color="#ACAFB5" className="f14">
              회원번호 : <TopSmallText color="#3383ff">{info.id}</TopSmallText>
            </TopSmallText>
            <TopSmallText color="#ACAFB5" className="f14">
              가입일 : <TopSmallText color="#818792">{info.date}</TopSmallText>
            </TopSmallText>
          </TopSmallTextArea>
          <MainInfoArea>
            <ProfileImage src={info.profile && info.profile != "가입미완" ? info.profile : NoImage} />
            <div>
              <MainInfoText fw={700} className="f24">
                <ColorText color="#3C3E41">{info.nickname ? info.nickname : "닉네임 없음"}</ColorText>
                {loginTypeMaker(info.login)}
              </MainInfoText>
              <div style={{ height: "8px" }} />
              <MainInfoText className="f14">
                <ColorText color="#ACAFB5">유저 아이디 :&nbsp;</ColorText>
                <ColorText color="#3c3e41">{info.e_mail}</ColorText>
              </MainInfoText>
              <div style={{ height: "6px" }} />
              <div style={{ display: "flex", alignItems: "center" }}>
                <MainInfoText className="f14">
                  <ColorText color="#ACAFB5">전화번호 :&nbsp;</ColorText>
                  <ColorText color="#3c3e41">{info.phone}</ColorText>
                </MainInfoText>
                <div style={{ width: "calc(1rem * 36/14)" }} />
                <MainInfoText className="f14">
                  <ColorText color="#ACAFB5">연령 :&nbsp;</ColorText>
                  <ColorText color="#3c3e41">{info.age}세</ColorText>
                </MainInfoText>
              </div>
            </div>
          </MainInfoArea>
        </FirstSection>

        <DividerLine />

        {/* <SecondSection>
        <SectionTitle className="f16">라이선스</SectionTitle>
        <div style={{ height: "16px" }} />

        <LicenseItem>
          <LicenseImageContainer>
            <LicenseImage />
            <input
              type="file"
              id="license"
              style={{ display: "none" }}
              accept="image/*"
              onChange={({ target: { files } }) => {
                if (files.length) {
                  setImageFromFile({
                    file: files[0],
                    setImageUrl: ({ result }) => {
                      ImgChange(result, "image_p", 0);
                    },
                  });
                }
              }}
            />
            <ColorText
              for="license"
              className="f13"
              style={{ fontWeight: "500", cursor: "pointer" }}
              color="#3383ff"
            >
              이미지 등록하기
            </ColorText>
          </LicenseImageContainer>
          <LicenseInfoContainer>
            <LicenseLine>
              <LicenseCategory className="f14">라이선스 이름</LicenseCategory>
              <LicenseInfoInputContainer>
                <LicenseInput
                  placeholder="라이선스 이름 입력"
                  className="f14"
                />
              </LicenseInfoInputContainer>
            </LicenseLine>
            <LicenseLine>
              <LicenseCategory className="f14">라이선스 종목</LicenseCategory>
              <LicenseInfoInputContainer>
                <LicenseInput
                  placeholder="라이선스 종목 입력"
                  className="f14"
                />
              </LicenseInfoInputContainer>
            </LicenseLine>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <LicenseAddButton onClick={() => addingAction()}>
                <LicenseText>
                  <ColorText color="#66A2FF" className="f14">
                    라이선스 추가하기
                  </ColorText>
                </LicenseText>
                <LicenseAddIcon src={BlueAdd} />
              </LicenseAddButton>
            </div>
          </LicenseInfoContainer>
        </LicenseItem>
      </SecondSection> */}

        <DividerLine />

        <ThirdSection>
          <ThirdSectionTop>
            <SectionTitle className="f16">결제 내역</SectionTitle>
            {info.pay_list?.length > 0 && (
              <LinkButton>
                <ColorText color="#3383FF" onClick={() => goToSales(info.nickname)}>
                  판매 내역 이동
                </ColorText>
                <LinkArrow src={BlueArrow} />
              </LinkButton>
            )}
          </ThirdSectionTop>

          <Table>
            <TableHeaderLine>
              <TableColumn bgc="#F9F9F9" className="f14" w={50}>
                액티비티 명
              </TableColumn>
              <TableColumn bgc="#F9F9F9" className="f14" w={25}>
                액티비티 카테고리
              </TableColumn>
              <TableColumn bgc="#F9F9F9" className="f14" w={25}>
                결제일
              </TableColumn>
            </TableHeaderLine>
            {info.pay_list?.map((el, i) => (
              <TableContentLine key={"pay_" + i}>
                <TableColumn bgc="#ffffff" w={50} className="f14">
                  {el.title}
                </TableColumn>
                <TableColumn bgc="#ffffff" w={25} className="f14">
                  {el.sport ? el.sport : "카테고리 없음"}
                </TableColumn>
                <TableColumn bgc="#ffffff" w={25} className="f14">
                  {el.pay_date}
                </TableColumn>
              </TableContentLine>
            ))}
          </Table>
          <div style={{ height: "113px" }} />
          <ColorText style={{ fontWeight: "700", cursor: "pointer" }} color="#ff786b" onClick={() => resignHandler()}>
            탈퇴하기
          </ColorText>
        </ThirdSection>
      </Container>
    </Wrapper>
  );
};

export default UserDetail;

const Container = styled.div`
  /* padding: 40px 35px 52px 35px; */
`;

const FirstSection = styled.div`
  padding: 40px 36px;
`;

const TopSmallTextArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 41px;
`;

const TopSmallText = styled.span`
  font-weight: 500;
  color: ${({ color }) => color};
  letter-spacing: -0.02em;
`;

const MainInfoArea = styled.div`
  display: flex;
  /* align-items: center; */
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: calc(7rem);
  height: calc(7rem);
  margin-right: calc(1rem * 20 / 14);
`;

const MainInfoText = styled.div`
  display: flex;
  letter-spacing: -0.02em;
  font-weight: ${({ fw }) => (fw ? fw : 500)};
`;

const ColorText = styled.span`
  color: ${({ color }) => color};
`;

const DividerLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dcdcdc;
`;

const SecondSection = styled.div`
  padding: 36px;
`;

const SectionTitle = styled.div`
  color: #3c3e41;
  font-weight: 700;
  letter-spacing: -0.02em;
`;

const LicenseAddButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #66a2ff;
  border-radius: 6px;
  padding: 7.5px 0;
  width: calc(1rem * 184 / 14);
  cursor: pointer;
`;

const LicenseText = styled.span`
  font-weight: 500;
`;

const LicenseAddIcon = styled.img`
  width: calc(1rem * 20 / 14);
  height: calc(1rem * 20 / 14);
  margin-left: 4px;
`;

const Cancel = styled.div`
  font-weight: 500;
  cursor: pointer;
`;

const LicenseItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LicenseImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: calc(1rem);
`;

const LicenseImage = styled.img`
  width: calc(1rem * 199 / 14);
  height: calc(1rem * 124 / 14);
  margin-bottom: 4px;
`;

const LicenseInfoContainer = styled.div``;

const LicenseLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const LicenseInfoInputContainer = styled.div`
  border: 1px solid #dcdcdc;
  width: calc(1rem * 300 / 14);
`;

const LicenseCategory = styled.div`
  color: #3c3e41;
  font-weight: 500;
  letter-spacing: -0.02em;
  margin-right: 8px;
`;

const LicenseInput = styled.input`
  padding: 8px 16px;
  width: 100%;
`;

const ThirdSection = styled.div`
  padding: 36px;
  padding-bottom: 52px;
`;

const ThirdSectionTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const LinkButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
`;

const LinkArrow = styled.img`
  width: calc(1rem * 20 / 14);
  height: calc(1rem * 20 / 14);
  margin-left: 2px;
`;

const Table = styled.div``;

const TableHeaderLine = styled.div`
  display: flex;
  align-items: center;
`;

const TableContentLine = styled.div`
  display: flex;
  align-items: center;
`;

const TableColumn = styled.div`
  width: ${({ w }) => `calc(1% * ${w})`};
  background-color: ${({ bgc }) => (bgc ? bgc : "#ffffff")};
  color: ${({ color }) => (color ? color : "#3c3e41")};
  text-align: center;
  font-weight: 500;
  padding: ${({ padding }) => (padding ? padding : "13px 0")};
  border-bottom: 1px solid #dcdcdc;
`;

const LoginBoxType = styled.div`
  padding: 3px 16px 3px 10px;
  background-color: ${({ bgc }) => bgc};
  color: ${({ c }) => c};
  font-weight: 500;
  display: flex;
  align-items: center;
  border-radius: 16px;
  margin-left: calc(1rem * 10 / 14);
`;

const LoginIcon = styled.img`
  width: calc(1rem * 20 / 14);
  height: calc(1rem * 20 / 14);
  margin-right: calc(1rem * 4 / 14);
`;

const Icon = styled.img`
  width: calc(1rem * 28 / 14);
  height: calc(1rem * 28 / 14);
  transform: rotate(-90deg);
  cursor: pointer;
  margin-bottom: 15px;
`;
