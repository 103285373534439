import styled from "styled-components";
import { faqCategoryList } from "../../data/faq";
import colors from "../../styles/constants/colors";
import typography from "../../styles/constants/typography";
import flexbox from "../../styles/func/flexbox";

const Nav = styled.nav`
  width: 100%;
  height: 44px;
  ${flexbox("flex-start")}

  ul {
    width: 100%;
    height: 44px;
    ${flexbox("flex-start")}
    border-bottom: 2px solid ${colors.lightGray};
    font-weight: ${typography.weight.bold};

    li {
      padding-right: 100px;
      cursor: pointer;
    }
  }
`;

const Active = styled.p`
  color: ${colors.blue};
`;

const CategoryTabBar = ({ value, changeHandler }) => {
  const faqTabList = [
    {
      text: "전체",
      value: "all",
    },
    {
      text: "이용 안내",
      value: "info",
    },
    {
      text: "회원 정보",
      value: "user",
    },
    {
      text: "결제/환불",
      value: "payment",
    },
  ];

  return (
    <Nav>
      <ul>
        {faqTabList.map((item, idx) => (
          <li key={idx} onClick={() => changeHandler(item.value)}>
            {value === item.value ? (
              <Active>{item.text}</Active>
            ) : (
              <p>{item.text}</p>
            )}
          </li>
        ))}
      </ul>
    </Nav>
  );
};

export default CategoryTabBar;
