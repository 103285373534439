import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

import DropDown from "../designedComponents/dropDown";
import { Wrapper } from "../../layouts/layoutStorage";
import NewAnnounce from "./newAnnounce";

import colors from "../../styles/constants/colors";

const AnnounceDetail = () => {
  const { n, type } = useParams();
  const navigate = useNavigate();
  const [item, setItem] = useState({});

  useEffect(() => {
    var token = localStorage.getItem("token");

    const frm = new FormData();

    frm.append("mode", "detail");
    frm.append("token", token);
    frm.append("id", n);

    axios
      .post("https://ai.semos.kr/semos_admin/announcement/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;

        setItem(Value.data);
      });
  }, [n]);

  const saveHandler = () => {
    var token = window.localStorage.getItem("token");
    var stat = item.status == "활성화" ? 1 : 0;
    const frm = new FormData();
    if (n == "new") {
      frm.append("mode", "upload");
      frm.append("token", token);
      frm.append("content", item.content);
      frm.append("image", item.image);
      frm.append("title", item.title);
      frm.append("type", type.includes("partner") ? "partner" : "customer");

      axios
        .post("https://ai.semos.kr/semos_admin/announcement/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            alert(statusMessage);
            // window.location.reload();
            navigate(`/announcement/1/all/${type}/all`);

            return true;
          } else {
            alert(statusMessage);
            return false;
          }
        });
    } else {
      frm.append("mode", "edit");
      frm.append("token", token);
      frm.append("image", item.image);
      frm.append("content", item.content);
      frm.append("title", item.title);
      frm.append("status", stat);
      frm.append("id", item.id);

      axios
        .post("https://ai.semos.kr/semos_admin/announcement/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            alert(statusMessage);
            window.location.reload();
            return true;
          } else {
            alert(statusMessage);
            return false;
          }
        });
    }
  };

  const setImageFromFile = ({ file, setImageUrl }) => {
    var uploadFileSize = file.size;
    var maxFileSize = 1024 * 1024 * 5;
    let reader = new FileReader();
    reader.onload = function () {
      setImageUrl({ result: reader.result });
    };
    reader.readAsDataURL(file);
  };

  return (
    <Wrapper
      bgc={colors.White}
      of={`hidden auto`}
      margin="10px 0 0 0"
      display={`inline-block`}
      width={`100%`}
      mw={`1000px`}
      mh={`calc(100vh - 72px)`}
      padding={`10px 0`}
    >
      {n.includes("partner") || n.includes("customer") ? (
        <NewAnnounce />
      ) : (
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title className="f24">세모스 공지</Title>

            <DropDown
              option={[
                {
                  text: "활성화",
                  value: "활성화",
                },
                {
                  text: "비활성화",
                  value: "비활성화",
                },
              ]}
              data={item.status}
              setData={setItem}
              type="object"
              keyName="status"
            />
          </div>
          <SmallTitle className="f16">공지 사진</SmallTitle>
          <ImageContainer>
            <BannerImage src={item.image} />
          </ImageContainer>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="announce"
            onChange={({ target: { files } }) => {
              if (files.length) {
                setImageFromFile({
                  file: files[0],
                  setImageUrl: ({ result }) => {
                    setItem((prev) => ({ ...prev, image: result }));
                  },
                });
              }
            }}
          />
          <ImageAddLabel htmlFor="announce">이미지 등록하기</ImageAddLabel>

          <TitleSection>
            제목
            <InputContainer style={{ width: "95%" }}>
              <Input
                placeholder="제목을 작성해 주세요."
                value={item.title}
                onChange={(e) =>
                  setItem((prev) => ({ ...prev, title: e.target.value }))
                }
              />
            </InputContainer>
          </TitleSection>

          <div style={{ height: "8px" }} />

          <InputContainer>
            <TextArea
              placeholder="내용을 작성해 주세요"
              value={item.content}
              rows={10}
              onChange={(e) =>
                setItem((prev) => ({ ...prev, content: e.target.value }))
              }
            />
          </InputContainer>

          <div style={{ height: "30px" }} />

          <SaveButton onClick={() => saveHandler()}>저장</SaveButton>
        </Container>
      )}
    </Wrapper>
  );
};

export default AnnounceDetail;

const Container = styled.div`
  padding: 36px;
  padding-bottom: 52px;
`;

const Title = styled.div`
  color: ${colors.Gr05};
  font-weight: 700;
  margin-bottom: 36px;
`;

const SmallTitle = styled.div`
  margin-bottom: 16px;
  color: ${colors.Gr05};
  font-weight: 700;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 500px;
  overflow: hidden;
`;

const BannerImage = styled.img`
  width: 100%;
  /* height: 500px; */
`;

const ImageAddLabel = styled.label`
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
  color: ${colors.Bl06};
  margin-top: 10px;
  margin-bottom: 56px;
  cursor: pointer;
`;

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
`;

const InputContainer = styled.div`
  padding: 8px 16px;
  border: 1px solid ${colors.Gr02};
  width: 100%;
`;

const Input = styled.input`
  color: ${colors.Gr05};
  width: 100%;
  font-weight: 500;
`;

const TextArea = styled.textarea`
  resize: none;
  outline: none;
  border: none;
  width: 100%;
`;

const SaveButton = styled.div`
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.Bl07};
  color: ${colors.White};
  font-weight: 700;
  border-radius: 8px;
  cursor: pointer;
`;
