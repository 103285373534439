import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// components
import {
  TableFilterContent,
  Wrapper,
  DetailHeader,
  Icon,
  Text,
  TextArea,
  Devive,
  AbsoluteWrapper,
  Label,
} from "../../layouts/layoutStorage";
import DropDown from "../../components/designedComponents/dropDown";
import DropDownCenter from "../../components/designedComponents/dropDownCenter";
import ImageCrop from "../../components/designedComponents/imageCrop";

// icon
import SearchIcon from "../../assets/icon/search.png";
import EmptyImage from "../../assets/icon/emptyImage.png";
import XArrowIcon from "../../assets/icon/xArrowIcon.png";
import XArrowIconGrey from "../../assets/icon/xArrowIconGrey.png";
import SemosLogoGrey from "../../assets/icon/semosLogoGrey.png";
import LocationGrey from "../../assets/icon/locationGrey.png";
import ArrowIconBlue from "../../assets/icon/arrowIconBlue.png";
import CloseIcon from "../../assets/icon/closeIcon.png";
import PlusIconGrey from "../../assets/icon/plusIconGrey.png";

import KbBank from "../../assets/icon/kbBank.png";
import KakaoBank from "../../assets/icon/kakaoBank.png";
import WooriBank from "../../assets/icon/wooriBank.png";
import HanaBank from "../../assets/icon/hanaBank.png";
import ShBank from "../../assets/icon/shBank.png";
import NhBank from "../../assets/icon/nhBank.png";
import IbkBank from "../../assets/icon/ibkBank.png";
import MgBank from "../../assets/icon/mgBank.png";
import colors from "../../styles/constants/colors";

const CenterDetail = ({ info, setOpen, open }) => {
  const BankList = [
    {
      text: "국민은행",
      icon: KbBank,
    },
    {
      text: "카카오뱅크",
      icon: KakaoBank,
    },
    {
      text: "우리은행",
      icon: WooriBank,
    },
    {
      text: "신한은행",
      icon: ShBank,
    },
    {
      text: "하나은행",
      icon: HanaBank,
    },
    {
      text: "농협은행",
      icon: NhBank,
    },
    {
      text: "기업은행",
      icon: IbkBank,
    },
    {
      text: "새마을금고",
      icon: MgBank,
    },
    {
      text: "케이뱅크",
      icon: "",
    },
    {
      text: "토스뱅크",
      icon: "",
    },
    {
      text: "지역농협",
      icon: "",
    },
    {
      text: "SC제일은행",
      icon: "",
    },
    {
      text: "우체극",
      icon: "",
    },
    {
      text: "수협은행",
      icon: "",
    },
    {
      text: "지역수협",
      icon: "",
    },
    {
      text: "신협",
      icon: "",
    },
    {
      text: "씨티은행",
      icon: "",
    },
    {
      text: "KDB산업은행",
      icon: "",
    },
    {
      text: "대구은행",
      icon: "",
    },
    {
      text: "부산은행",
      icon: "",
    },
    {
      text: "경남은행",
      icon: "",
    },
    {
      text: "광주은행",
      icon: "",
    },
    {
      text: "전북은행",
      icon: "",
    },
    {
      text: "제주은행",
      icon: "",
    },
  ];

  const navigate = useNavigate();

  const [category, setCategory] = useState(0);
  const [sport, setSport] = useState();
  const [bankPicker, setBankPicker] = useState(false);
  const [search, setSearch] = useState("");
  const [partnerList, setPartnerList] = useState([]);
  const [searchInfo, setSearchInfo] = useState(false);
  const [careerInfo, setCareerInfo] = useState("");
  const [centerInfo, setCenterInfo] = useState(info);
  const [deleteInfo, setDeleteInfo] = useState({
    sport: [],
    partner: [],
    career: [],
  });
  const [cropImage, setCropImage] = useState({
    width: 8,
    height: 5,
    type: "",
    number: "",
    image: "",
    status: false,
    scale: true,
  });

  useEffect(() => {
    setCenterInfo(info);
  }, [info]);

  const StatusText = {
    0: "승인대기",
    1: "활성화",
    2: "비활성화",
  };

  const SportList = {
    1: "워터스포츠",
    2: "스노우스포츠",
    3: "실내스포츠",
    4: "야외스포츠",
  };

  const SportOptionList = [
    [{ text: "대분류를 선택해주세요", value: "" }],
    [
      { text: "프리다이빙", value: "프리다이빙" },
      { text: "스쿠버다이빙", value: "스쿠버다이빙" },
      { text: "패들보드", value: "패들보드" },
      { text: "서핑", value: "서핑" },
      { text: "웨이크보드", value: "웨이크보드" },
      { text: "수상스키", value: "수상스키" },
    ],
    [
      { text: "스노보드", value: "스노보드" },
      { text: "스키", value: "스키" },
    ],
    [
      { text: "필라테스", value: "필라테스" },
      { text: "피트니스", value: "피트니스" },
    ],
    [
      { text: "축구", value: "축구" },
      { text: "야구", value: "야구" },
      { text: "농구", value: "농구" },
    ],
  ];

  const bankPickerRef = useRef();
  const modalRef = useRef();

  const bankPickerClose = (e) => {
    if (bankPicker && modalRef.current.contains(e.target)) {
      setBankPicker(true);
    } else if (
      bankPicker &&
      (!bankPickerRef.current || !bankPickerRef.current.contains(e.target))
    ) {
      setBankPicker(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", bankPickerClose);

    return () => {
      window.removeEventListener("click", bankPickerClose);
    };
  }, [bankPicker]);

  const StatusComponent = (status) => {
    switch (status) {
      case "0":
        return (
          <Wrapper
            margin={`0 0 0 9px`}
            bgc={colors.White}
            border={`1px solid ${colors.Bl06}`}
            padding={`3px 14px`}
            radius={`16px`}
          >
            <Text color={colors.Bl06} weight={`500`}>
              {`- 승인대기`}
            </Text>
          </Wrapper>
        );
        break;

      case "1":
        return (
          <Wrapper
            margin={`0 0 0 9px`}
            bgc={colors.Bl02}
            border={`1px solid ${colors.Bl06}`}
            padding={`3px 14px`}
            radius={`16px`}
          >
            <Text color={colors.Bl06} weight={`500`}>
              {`• 활성화`}
            </Text>
          </Wrapper>
        );
        break;

      case "2":
        return (
          <Wrapper
            margin={`0 0 0 9px`}
            bgc={colors.Gr01}
            border={`1px solid ${colors.Gr03}`}
            padding={`3px 14px`}
            radius={`16px`}
          >
            <Text color={colors.Gr04} weight={`500`}>
              {`• 비활성화`}
            </Text>
          </Wrapper>
        );
        break;
    }
  };

  const setImageFromFile = ({ file, setImageUrl }) => {
    let reader = new FileReader();
    reader.onload = function () {
      setImageUrl({ result: reader.result });
    };

    reader.readAsDataURL(file);
  };

  const PartnerSearch = (text) => {
    setSearch(text);

    if (text) {
      var token = window.localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "partner_search");
      frm.append("token", token);
      frm.append("text", text);

      axios
        .post("https://ai.semos.kr/semos_admin/center/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            setPartnerList(Value.partner_list);

            if (!searchInfo) {
              setSearchInfo(true);
            }
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    } else {
      setPartnerList([]);
      setSearchInfo(false);
    }
  };

  const SportHandler = (type, value) => {
    if (type == "category") {
      setCategory(value);
      setSport("");
    } else {
      setSport(value);
    }
  };

  const AddSport = () => {
    if (category != 0 && category && sport) {
      let CenterInfo = centerInfo;
      let sportList = CenterInfo.sport;

      for (var i = 0; i < sportList.length; i++) {
        var category_og = sportList[i].category;
        var sport_og = sportList[i].sport;

        if (category_og == category && sport_og == sport) {
          window.alert(`중복된 액티비티 카테고리가 있습니다. 확인해주세요!`);
          break;
        }
      }

      sportList[sportList.length] = {
        id: "new",
        category: category,
        sport: sport,
        change: "yes",
      };
      CenterInfo["sport"] = sportList;

      setCenterInfo(CenterInfo);
      setCategory(0);
      setSport("");
    } else {
      window.alert(`액티비티 카테고리 대분류, 소분류 모두 선택해주세요!`);
    }
  };

  const DeleteInfo = (type, item, number) => {
    let DInfo = deleteInfo;
    let DInfoType = DInfo[type];

    DInfoType[DInfoType.length] = item;

    setDeleteInfo((prev) => ({ ...prev, [type]: DInfoType }));

    let CInfo = centerInfo;
    let CInfoType = CInfo[type];
    CInfoType.splice(number, 1);

    setCenterInfo((prev) => ({ ...prev, [type]: CInfoType }));
  };

  const AccountInfo = (type, value) => {
    let CInfo = centerInfo;
    let CInfoType = CInfo.account;

    if (type == "business_number") {
      CInfoType[type] = value.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");
    } else {
      CInfoType[type] = value;
    }

    setCenterInfo((prev) => ({ ...prev, account: CInfoType }));
  };

  const InfoHandler = (type, value) => {
    if (type == "career") {
      let CInfo = centerInfo;
      let CInfoType = CInfo[type];

      if (careerInfo.length > 1) {
        CInfoType[CInfoType.length] = {
          id: "new",
          career: careerInfo,
          change: "yes",
        };

        setCareerInfo("");
        setCenterInfo((prev) => ({ ...prev, [type]: CInfoType }));
      } else {
        window.alert("센터 경력이 올바르지 않습니다.");
      }
    } else if (type == "partner") {
      let CInfo = centerInfo;
      let CInfoType = CInfo[type];

      var check = false;

      for (var i = 0; i < CInfoType.length; i++) {
        if (CInfoType[i].id == value.id) {
          window.alert("중복된 파트너가 있습니다. 파트너를 확인해주세요!");
          check = true;
          break;
        }
      }

      if (!check) {
        CInfoType[CInfoType.length] = value;
        setCareerInfo("");
        setCenterInfo((prev) => ({ ...prev, [type]: CInfoType }));
      }
    } else {
      setCenterInfo((prev) => ({ ...prev, [type]: value }));
    }
  };

  const ImgChange = (value, type, number) => {
    if (
      type == "center_image_1" ||
      type == "center_image_2" ||
      type == "center_image_3"
    ) {
      setCropImage({
        width: 8,
        height: 5,
        type: type,
        number: number,
        image: value,
        status: true,
        scale: true,
      });
    } else {
      setCropImage({
        width: 1,
        height: 1,
        type: type,
        number: number,
        image: value,
        status: true,
        scale: false,
      });
    }
  };

  const ActivityReplace = (param) => {
    setOpen(false);
    document.body.style.removeProperty("overflow");
    document.getElementById("root").style.pointerEvents = "auto";

    setTimeout(() => {
      navigate(`/activity/1/${param}/all/all/all`);
    }, 300);
  };

  const CenterSave = () => {
    if (window.confirm("정보를 저장하시겠습니까?")) {
      var token = window.localStorage.getItem("token");

      axios
        .post(
          "https://ai.semos.kr/semos_admin/center/data",
          {
            mode: "center_save",
            token: token,
            info: centerInfo,
            delete: deleteInfo,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            alert(statusMessage);
            window.location.reload();
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    }
  };

  useEffect(() => {
    if (centerInfo) {
      if (centerInfo.type == 0) {
        setCenterInfo((prev) => ({ ...prev, scale: 1 }));
      }
    }
  }, [centerInfo && centerInfo.type]);

  return (
    <>
      <Wrapper
        of={`hidden auto`}
        display={`inline-block`}
        width={`100%`}
        padding={`40px 0`}
      >
        <DetailHeader padding={`0 36px`} margin={`0 0 41px 0`}>
          <Wrapper jc={`flex-start`}>
            <Text weight={`500`} className={`f24`} color={colors.Black}>
              센터 관리
            </Text>

            {StatusComponent(`${centerInfo && centerInfo.status}`)}
          </Wrapper>

          <Wrapper jc={`flex-start`}>
            <DropDown
              data={centerInfo && StatusText[centerInfo.status]}
              setData={setCenterInfo}
              option={[
                { text: `활성화`, value: `1` },
                { text: `비활성화`, value: `2` },
                { text: `승인대기`, value: `0` },
              ]}
              type={`object`}
              defaultText={`승인대기`}
              no={false}
              keyName={`status`}
            />

            <Wrapper
              onClick={() => CenterSave()}
              cursor={`pointer`}
              radius={`8px`}
              bgc={colors.Bl07}
              padding={`6.5px 12px`}
              jc={`center`}
            >
              <Text
                lh={`none`}
                color={colors.White}
                weight={`500`}
                className={`f12`}
              >
                {centerInfo && (centerInfo.id == "new" ? `추가` : `저장`)}
              </Text>
            </Wrapper>
          </Wrapper>
        </DetailHeader>

        <Wrapper wrap={`wrap`} padding={`0 36px`} width={`100%`}>
          <Wrapper
            transition={`all 0.3s ease`}
            mh={`unset`}
            width={`100%`}
            wrap={`wrap`}
          >
            <Wrapper margin={`0 0 12px 0`} width={`100%`} jc={`flex-start`}>
              <Text
                width={`160px`}
                color={colors.Gr04}
                weight={`500`}
                className={`f14`}
              >
                센터 이름
              </Text>
              <TableFilterContent width={`calc(100% - 160px)`} margin={`0`}>
                <Input
                  value={centerInfo && `${centerInfo.title}`}
                  onChange={(e) => InfoHandler("title", e.target.value)}
                  width={`100%`}
                  type={`text`}
                  maxLength={50}
                  placeholder="센터명을 입력해주세요!"
                />
              </TableFilterContent>
            </Wrapper>

            <Wrapper margin={`0 0 52px 0`} width={`100%`} jc={`flex-start`}>
              <Text
                width={`160px`}
                color={colors.Gr04}
                weight={`500`}
                className={`f14`}
              >
                센터 정원
              </Text>

              <DropDown
                data={centerInfo && `${centerInfo.type == 0 ? `강사` : `센터`}`}
                setData={setCenterInfo}
                option={[
                  { text: `강사`, value: `0` },
                  { text: `센터`, value: `1` },
                ]}
                type={`object`}
                defaultText={`강사`}
                no={false}
                keyName={`type`}
              />

              <DropDown
                data={centerInfo && `${centerInfo.scale}명`}
                setData={setCenterInfo}
                option={
                  centerInfo && centerInfo.type == 0
                    ? [{ text: `1명`, value: `1` }]
                    : [
                        { text: `1명`, value: `1` },
                        { text: `3명`, value: `3` },
                        { text: `5명`, value: `5` },
                        { text: `10명`, value: `10` },
                        { text: `15명`, value: `15` },
                        { text: `20명`, value: `20` },
                      ]
                }
                type={`object`}
                defaultText={`승인대기`}
                no={false}
                keyName={`scale`}
              />
            </Wrapper>
          </Wrapper>
        </Wrapper>

        <Wrapper
          margin={`0 0 42px 0`}
          wrap={`wrap`}
          padding={`0 36px`}
          width={`100%`}
          jc={`space-between`}
        >
          <Text
            margin={`0 0 16px 0`}
            color={colors.Gr05}
            width={`100%`}
            weight={`700`}
            className={`f16`}
          >
            액티비티 카테고리
          </Text>

          <Wrapper width={`100%`} jc={`space-between`}>
            <Wrapper width={`calc((100% - 52px) / 2)`} jc={`flex-start`}>
              <DropDownCenter
                data={category && category != 0 && SportList[category]}
                setData={SportHandler}
                option={[
                  { text: `워터스포츠`, value: 1 },
                  { text: `스노우스포츠`, value: 2 },
                  { text: `실내스포츠`, value: 3 },
                  { text: `야외스포츠`, value: 4 },
                ]}
                type={`category`}
                defaultText={`대분류 선택`}
                no={false}
                keyName={`status`}
                width={`100%`}
              />
            </Wrapper>

            <Wrapper width={`calc((100% - 52px) / 2)`} jc={`flex-start`}>
              <DropDownCenter
                data={sport && sport}
                setData={SportHandler}
                option={SportOptionList[category]}
                type={`sport`}
                defaultText={`소분류 선택`}
                no={false}
                keyName={`status`}
                width={`100%`}
              />
            </Wrapper>

            <Wrapper
              onClick={() => AddSport()}
              cursor={`pointer`}
              border={`1px solid ${colors.Bl05}`}
              radius={`6px`}
              width={`36px`}
              height={`36px`}
              jc={`center`}
            >
              <Text
                lh={`unset`}
                color={colors.Bl05}
                weight={`500`}
                className={`f18`}
              >
                +
              </Text>
            </Wrapper>
          </Wrapper>

          <Wrapper margin={`18px 0 0 0`} width={`100%`} jc={`flex-end`}>
            {centerInfo &&
              centerInfo.sport.map((item, idx) => (
                <Wrapper
                  key={`center_sport_${idx}`}
                  margin={`0 0 10px 8px`}
                  bgc={colors.Gr01}
                  padding={`6px 12px`}
                  border={`1.2px solid ${colors.Gr02}`}
                  radius={`24px`}
                  jc={`center`}
                >
                  <Text
                    lh={`unset`}
                    color={colors.Gr05}
                    weight={`500`}
                    className={`f13`}
                  >{`${SportList[item.category]}`}</Text>
                  <Text
                    lh={`unset`}
                    margin={`0 7px`}
                    color={colors.Gr02}
                    weight={`500`}
                    className={`f13`}
                  >
                    |
                  </Text>
                  <Text
                    lh={`unset`}
                    color={colors.Gr05}
                    weight={`500`}
                    className={`f13`}
                  >{`${item.sport}`}</Text>
                  <Icon
                    width={`calc(1rem * (12 / 14))`}
                    cursor={`pointer`}
                    margin={`0 0 0 6px`}
                    src={XArrowIcon}
                    onClick={() => DeleteInfo("sport", item, idx)}
                  />
                </Wrapper>
              ))}
          </Wrapper>
        </Wrapper>

        <Wrapper
          margin={`0 0 42px 0`}
          wrap={`wrap`}
          padding={`0 36px`}
          width={`100%`}
          jc={`space-between`}
        >
          <Text
            margin={`0 0 16px 0`}
            color={colors.Gr05}
            width={`100%`}
            weight={`700`}
            className={`f16`}
          >
            배경 이미지
          </Text>

          <Wrapper width={`100%`} jc={`space-between`}>
            <Wrapper
              ai={`flex-start`}
              jc={`center`}
              width={`calc(100% / 3)`}
              wrap={`wrap`}
            >
              <Label
                of={`hidden`}
                key={`center_image_1`}
                bgc={colors.Gr01}
                radius={`8px`}
                margin={`10px 0`}
                cursor={`pointer`}
                width={`calc(100% - 10px)`}
                height={`calc( ( ( ( (1rem * 747) / 14) - 142px ) / 3 ) * (5 / 8) )`}
                htmlFor={`center_image_1`}
              >
                <input
                  onChange={({ target: { files } }) => {
                    if (files.length) {
                      setImageFromFile({
                        file: files[0],
                        setImageUrl: ({ result }) => {
                          ImgChange(result, "center_image_1", 0);
                        },
                      });
                    }
                  }}
                  id={`center_image_1`}
                  accept="image/*"
                  type="file"
                />
                <img
                  src={
                    centerInfo
                      ? centerInfo.image[0]
                        ? centerInfo.image[0].image
                        : EmptyImage
                      : EmptyImage
                  }
                />
              </Label>
            </Wrapper>

            <Wrapper
              ai={`flex-start`}
              jc={`center`}
              width={`calc(100% / 3)`}
              wrap={`wrap`}
            >
              <Label
                of={`hidden`}
                key={`center_image_2`}
                bgc={colors.Gr01}
                radius={`8px`}
                margin={`10px 0`}
                cursor={`pointer`}
                width={`calc(100% - 10px)`}
                height={`calc( ( ( ( (1rem * 747) / 14) - 142px ) / 3 ) * (5 / 8) )`}
                htmlFor={`center_image_2`}
              >
                <input
                  onChange={({ target: { files } }) => {
                    if (files.length) {
                      setImageFromFile({
                        file: files[0],
                        setImageUrl: ({ result }) => {
                          ImgChange(result, "center_image_2", 1);
                        },
                      });
                    }
                  }}
                  id={`center_image_2`}
                  accept="image/*"
                  type="file"
                />
                <img
                  src={
                    centerInfo
                      ? centerInfo.image[1]
                        ? centerInfo.image[1].image
                        : EmptyImage
                      : EmptyImage
                  }
                />
              </Label>
            </Wrapper>

            <Wrapper
              ai={`flex-start`}
              jc={`center`}
              width={`calc(100% / 3)`}
              wrap={`wrap`}
            >
              <Label
                of={`hidden`}
                key={`center_image_3`}
                bgc={colors.Gr01}
                radius={`8px`}
                margin={`10px 0`}
                cursor={`pointer`}
                width={`calc(100% - 10px)`}
                height={`calc( ( ( ( (1rem * 747) / 14) - 142px ) / 3 ) * (5 / 8) )`}
                htmlFor={`center_image_3`}
              >
                <input
                  onChange={({ target: { files } }) => {
                    if (files.length) {
                      setImageFromFile({
                        file: files[0],
                        setImageUrl: ({ result }) => {
                          ImgChange(result, "center_image_3", 2);
                        },
                      });
                    }
                  }}
                  id={`center_image_3`}
                  accept="image/*"
                  type="file"
                />
                <img
                  src={
                    centerInfo
                      ? centerInfo.image[2]
                        ? centerInfo.image[2].image
                        : EmptyImage
                      : EmptyImage
                  }
                />
              </Label>
            </Wrapper>
          </Wrapper>
        </Wrapper>

        <Wrapper
          margin={`0 0 52px 0`}
          wrap={`wrap`}
          padding={`0 36px`}
          width={`100%`}
          jc={`space-between`}
        >
          <Text
            margin={`0 0 16px 0`}
            color={colors.Gr05}
            width={`100%`}
            weight={`700`}
            className={`f16`}
          >
            정산 정보
          </Text>

          <Wrapper wrap={`wrap`} width={`100%`} jc={`space-between`}>
            <Wrapper margin={`0 0 8px 0`} width={`100%`} jc={`flex-start`}>
              <Text
                width={`160px`}
                color={colors.Gr04}
                weight={`500`}
                className={`f14`}
              >
                계좌 정보
              </Text>

              <TableFilterContent
                cursor={`pointer`}
                width={`140px`}
                margin={`0 10px 0 0`}
              >
                <Wrapper
                  onClick={() => setBankPicker(!bankPicker)}
                  ref={bankPickerRef}
                  width={`100%`}
                  jc={`center`}
                >
                  <Text
                    lh={`138%`}
                    color={colors.Gr05}
                    weight={`500`}
                    className={`f13`}
                  >
                    {centerInfo &&
                      `${
                        centerInfo.account.bank
                          ? centerInfo.account.bank
                          : "은행선택"
                      }`}
                  </Text>
                </Wrapper>
                <AbsoluteWrapper
                  ref={modalRef}
                  onClick={() => setBankPicker(false)}
                  display={bankPicker ? `inline-flex` : `none`}
                  border={`1px solid ${colors.B20}`}
                  z={`100`}
                  wrap={`wrap`}
                  bgc={colors.White}
                  radius={`16px`}
                  padding={`24px`}
                  width={`calc(1rem * (380 / 14))`}
                  bottom={`-8px`}
                  left={`0`}
                  transform={`translate(0, 100%)`}
                >
                  <Wrapper wrap={`wrap`} width={`100%`} jc={`space-between`}>
                    <Text color={colors.Gr06} weight={`500`} className={`f18`}>
                      은행 선택
                    </Text>

                    <Icon
                      width={`calc(1rem * (28 / 14))`}
                      cursor={`pointer`}
                      src={CloseIcon}
                      onClick={() => setBankPicker(false)}
                    />
                  </Wrapper>

                  <Wrapper
                    wrap={`wrap`}
                    margin={`26px 0 0 0`}
                    width={`100%`}
                    jc={`space-between`}
                  >
                    {BankList.map((item, idx) => (
                      <Wrapper
                        onClick={() => {
                          AccountInfo("bank", item.text);
                          setBankPicker(false);
                        }}
                        padding={`18px`}
                        key={`bank_${idx}`}
                        cursor={`pointer`}
                        radius={`8px`}
                        border={
                          centerInfo
                            ? item.text == centerInfo.account.bank
                              ? `1px solid ${colors.Bl06}`
                              : `1px solid ${colors.Gr02}`
                            : `1px solid ${colors.Gr02}`
                        }
                        wrap={`wrap`}
                        margin={`0 0 12px 0`}
                        width={`calc( (100% - 30px) / 4)`}
                        jc={`center`}
                      >
                        <Icon
                          width={`calc(1rem * (27 / 14))`}
                          src={item.icon}
                          margin={`0 0 10px 0`}
                        />
                        <Text
                          jc={`center`}
                          color={
                            centerInfo
                              ? item.text == centerInfo.account.bank
                                ? `${colors.Bl06}`
                                : `${colors.Gr03}`
                              : `${colors.Gr03}`
                          }
                          width={`100%`}
                          weight={`500`}
                          className={`f12`}
                        >
                          {item.text}
                        </Text>
                      </Wrapper>
                    ))}
                  </Wrapper>
                </AbsoluteWrapper>
              </TableFilterContent>

              <TableFilterContent width={`calc(100% - 310px)`} margin={`0`}>
                <Input
                  value={centerInfo && `${centerInfo.account.account}`}
                  onChange={(e) => AccountInfo("account", e.target.value)}
                  width={`100%`}
                  type={`text`}
                  maxLength={20}
                  placeholder="계좌번호를 입력해 주세요."
                />
              </TableFilterContent>
            </Wrapper>

            <Wrapper margin={`0 0 8px 0`} width={`100%`} jc={`flex-start`}>
              <Text
                width={`160px`}
                color={colors.Gr04}
                weight={`500`}
                className={`f14`}
              >
                주민등록번호
              </Text>

              <TableFilterContent cursor={`pointer`} width={`calc(50% - 98px)`}>
                <Input
                  value={centerInfo && `${centerInfo.account.first_id}`}
                  onChange={(e) => AccountInfo("first_id", e.target.value)}
                  width={`100%`}
                  type={`text`}
                  maxLength={6}
                  placeholder="970822"
                />
              </TableFilterContent>

              <Text
                jc={`center`}
                width={`36px`}
                color={colors.Gr04}
                weight={`700`}
                className={`f18`}
              >
                -
              </Text>

              <TableFilterContent width={`calc(50% - 98px)`} margin={`0`}>
                <InputSub
                  value={centerInfo && `${centerInfo.account.last_id}`}
                  onChange={(e) => AccountInfo("last_id", e.target.value)}
                  width={`100%`}
                  type="text"
                  maxLength={7}
                  placeholder="*******"
                />
              </TableFilterContent>
            </Wrapper>

            <Wrapper margin={`0 0 24px 0`} width={`100%`} jc={`flex-start`}>
              <Text
                width={`160px`}
                color={colors.Gr04}
                weight={`500`}
                className={`f14`}
              >
                사업자등록번호
              </Text>

              <TableFilterContent
                cursor={`pointer`}
                width={`calc(100% - 160px)`}
              >
                <Input
                  value={centerInfo && `${centerInfo.account.business_number}`}
                  onChange={(e) =>
                    AccountInfo("business_number", e.target.value)
                  }
                  width={`100%`}
                  type={`text`}
                  maxLength={11}
                  placeholder="000-00-00000"
                />
              </TableFilterContent>
            </Wrapper>
          </Wrapper>

          <Wrapper width={`100%`} jc={`space-between`}>
            <Wrapper
              ai={`flex-start`}
              jc={`center`}
              width={`calc(100% / 3)`}
              wrap={`wrap`}
            >
              <Text
                width={`100%`}
                color={colors.Gr05}
                weight={`500`}
                className={`f14`}
              >
                통장사본
              </Text>

              <Wrapper
                bgc={colors.Gr01}
                radius={`8px`}
                margin={`14px 0 10px 0`}
                width={`calc(100% - 10px)`}
                height={`calc( ( ( ( (1rem * 747) / 14) - 142px ) / 3 ) * (5 / 8) )`}
              >
                <img
                  style={{
                    display: "inline-block",
                    width: "100%",
                    height: "100%",
                    borderRadius: "8px",
                  }}
                  src={
                    centerInfo
                      ? centerInfo.bank_image.image
                        ? centerInfo.bank_image.image
                        : EmptyImage
                      : EmptyImage
                  }
                />
              </Wrapper>

              <Label
                key={`bank_image`}
                htmlFor={`bank_image`}
                cursor={`pointer`}
                width={`calc(100% - 10px)`}
                jc="flex-end"
              >
                <input
                  onChange={({ target: { files } }) => {
                    if (files.length) {
                      setImageFromFile({
                        file: files[0],
                        setImageUrl: ({ result }) => {
                          ImgChange(result, "bank_image", 0);
                        },
                      });
                    }
                  }}
                  id={`bank_image`}
                  accept="image/*"
                  type="file"
                />
                <Text
                  lh={`unset`}
                  color={colors.Bl06}
                  weight={`500`}
                  className={`f14`}
                >
                  이미지 등록하기
                </Text>
              </Label>
            </Wrapper>

            <Wrapper
              ai={`flex-start`}
              jc={`center`}
              width={`calc(100% / 3)`}
              wrap={`wrap`}
            >
              <Text
                width={`100%`}
                color={colors.Gr05}
                weight={`500`}
                className={`f14`}
              >
                신분증
              </Text>

              <Wrapper
                bgc={colors.Gr01}
                radius={`8px`}
                margin={`14px 0 10px 0`}
                width={`calc(100% - 10px)`}
                height={`calc( ( ( ( (1rem * 747) / 14) - 142px ) / 3 ) * (5 / 8) )`}
              >
                <img
                  style={{
                    display: "inline-block",
                    width: "100%",
                    height: "100%",
                    borderRadius: "8px",
                  }}
                  src={
                    centerInfo
                      ? centerInfo.id_card_image.image
                        ? centerInfo.id_card_image.image
                        : EmptyImage
                      : EmptyImage
                  }
                />
              </Wrapper>

              <Label
                cursor={`pointer`}
                key={`id_card_image`}
                htmlFor={`id_card_image`}
                width={`calc(100% - 10px)`}
                jc="flex-end"
              >
                <input
                  onChange={({ target: { files } }) => {
                    if (files.length) {
                      setImageFromFile({
                        file: files[0],
                        setImageUrl: ({ result }) => {
                          ImgChange(result, "id_card_image", 0);
                        },
                      });
                    }
                  }}
                  id={`id_card_image`}
                  accept="image/*"
                  type="file"
                />
                <Text
                  lh={`unset`}
                  color={colors.Bl06}
                  weight={`500`}
                  className={`f14`}
                >
                  이미지 등록하기
                </Text>
              </Label>
            </Wrapper>

            <Wrapper
              ai={`flex-start`}
              jc={`center`}
              width={`calc(100% / 3)`}
              wrap={`wrap`}
            >
              <Text
                width={`100%`}
                color={colors.Gr05}
                weight={`500`}
                className={`f14`}
              >
                사업자등록증
              </Text>

              <Wrapper
                bgc={colors.Gr01}
                radius={`8px`}
                margin={`14px 0 10px 0`}
                width={`calc(100% - 10px)`}
                height={`calc( ( ( ( (1rem * 747) / 14) - 142px ) / 3 ) * (5 / 8) )`}
              >
                <img
                  style={{
                    display: "inline-block",
                    width: "100%",
                    height: "100%",
                    borderRadius: "8px",
                  }}
                  src={
                    centerInfo
                      ? centerInfo.business_image.image
                        ? centerInfo.business_image.image
                        : EmptyImage
                      : EmptyImage
                  }
                />
              </Wrapper>
              <Label
                key={`business_image`}
                cursor={`pointer`}
                htmlFor={`business_image`}
                width={`calc(100% - 10px)`}
                jc={`flex-end`}
              >
                <input
                  onChange={({ target: { files } }) => {
                    if (files.length) {
                      setImageFromFile({
                        file: files[0],
                        setImageUrl: ({ result }) => {
                          ImgChange(result, "business_image", 0);
                        },
                      });
                    }
                  }}
                  id={`business_image`}
                  accept="image/*"
                  type="file"
                />
                <Text
                  lh={`unset`}
                  color={colors.Bl06}
                  weight={`500`}
                  className={`f14`}
                >
                  이미지 등록하기
                </Text>
              </Label>
            </Wrapper>
          </Wrapper>
        </Wrapper>

        <Wrapper
          margin={`0 0 42px 0`}
          wrap={`wrap`}
          padding={`0 36px`}
          width={`100%`}
          jc={`space-between`}
        >
          <Text
            margin={`0 0 16px 0`}
            color={colors.Gr05}
            width={`100%`}
            weight={`700`}
            className={`f16`}
          >
            센터 소개
          </Text>

          <Wrapper
            border={`1px solid ${colors.Gr02}`}
            width={`100%`}
            jc={`center`}
          >
            <TextArea
              onChange={(e) => InfoHandler("intro", e.target.value)}
              width={`100%`}
              height={`300px`}
              padding={`16px`}
              type={`text`}
              value={centerInfo && `${centerInfo.intro}`}
            />
          </Wrapper>
        </Wrapper>

        <Wrapper
          wrap={`wrap`}
          padding={`0 36px`}
          width={`100%`}
          jc={`space-between`}
        >
          <Text
            margin={`0 0 16px 0`}
            color={colors.Gr05}
            width={`100%`}
            weight={`700`}
            className={`f16`}
          >
            센터 경력
          </Text>

          <Wrapper margin={`0 0 8px 0`} width={`100%`} jc={`space-between`}>
            <TableFilterContent width={`calc(100% - 44px)`} margin={`0`}>
              <Input
                value={careerInfo}
                onChange={(e) => setCareerInfo(e.target.value)}
                width={`100%`}
                type={`text`}
                maxLength={50}
                placeholder="센터 경력을 입력해 주세요"
              />
            </TableFilterContent>

            <Wrapper
              onClick={() => InfoHandler("career")}
              cursor={`pointer`}
              border={`1px solid ${colors.Bl05}`}
              radius={`6px`}
              width={`36px`}
              height={`36px`}
              jc={`center`}
            >
              <Text
                lh={`unset`}
                color={colors.Bl05}
                weight={`500`}
                className={`f18`}
              >
                +
              </Text>
            </Wrapper>
          </Wrapper>

          {centerInfo &&
            centerInfo.career.map((item, idx) => (
              <Wrapper
                key={`center_career_${idx}`}
                margin={`0 0 8px 0`}
                width={`100%`}
                jc={`space-between`}
              >
                <TableFilterContent width={`calc(100% - 44px)`} margin={`0`}>
                  <Input
                    value={item.career}
                    //onChange={(e) => PartnerSearch(e.target.value)}
                    width={`100%`}
                    readOnly={true}
                    type={`text`}
                    maxLength={50}
                    placeholder="센터 경력을 입력해 주세요"
                  />
                </TableFilterContent>

                <Wrapper
                  onClick={() => DeleteInfo("career", item, idx)}
                  cursor={`pointer`}
                  border={`1px solid ${colors.Red02}`}
                  radius={`6px`}
                  width={`36px`}
                  height={`36px`}
                  jc={`center`}
                >
                  <Text
                    lh={`unset`}
                    color={colors.Red02}
                    weight={`500`}
                    className={`f18`}
                  >
                    -
                  </Text>
                </Wrapper>
              </Wrapper>
            ))}
        </Wrapper>

        <Devive
          width={`100%`}
          height={`1px`}
          bgc={colors.Gr02}
          margin={`52px 0 36px 0`}
        />

        <Wrapper
          wrap={`wrap`}
          padding={`0 36px`}
          width={`100%`}
          jc={`space-between`}
        >
          <Text
            margin={`0 0 16px 0`}
            color={colors.Gr05}
            width={`100%`}
            weight={`700`}
            className={`f16`}
          >
            파트너 목록
          </Text>

          <Wrapper
            wrap={`wrap`}
            margin={`0 0 24px 0`}
            width={`100%`}
            jc={`space-between`}
          >
            <TableFilterContent width={`100%`} margin={`0`}>
              <Input
                value={search}
                onChange={(e) => PartnerSearch(e.target.value)}
                width={`100%`}
                type={`text`}
                maxLength={50}
                placeholder="검색어를 입력해주세요"
              />
              <Icon
                width={`calc(1rem * (17 / 14))`}
                cursor={`pointer`}
                src={SearchIcon}
              />
            </TableFilterContent>

            {partnerList && (
              <Wrapper
                display={search ? `inline-flex` : `none`}
                border={`1px solid ${colors.Gr02}`}
                bb={`unset`}
                wrap={`wrap`}
                margin={`8px 0 0 0`}
                width={`100%`}
              >
                {partnerList.map((item, idx) => (
                  <Wrapper
                    onClick={() => InfoHandler("partner", item)}
                    key={`${idx}_partner`}
                    cursor={`pointer`}
                    bb={`1px solid ${colors.Gr02}`}
                    padding={`13px 18px`}
                    margin={`8px 0 0 0`}
                    width={`100%`}
                  >
                    <Text
                      lh={`unset`}
                      color={colors.Gr05}
                      width={`calc(100% - 24px)`}
                      weight={`500`}
                      className={`f12`}
                    >
                      {item.name}
                    </Text>
                    <Icon src={PlusIconGrey} width={`24px`} />
                  </Wrapper>
                ))}
              </Wrapper>
            )}
          </Wrapper>

          <Wrapper width={`100%`} wrap={`wrap`} jc={`flex-start`}>
            {centerInfo &&
              centerInfo.partner.map((item, idx) => (
                <Wrapper
                  key={`center_partner_${idx}`}
                  radius={`8px`}
                  border={`1px solid ${colors.Gr02}`}
                  width={`calc((100% - 29px) / 3)`}
                  margin={`0 14px 0 0`}
                  padding={`14px 16px`}
                  jc={`flex-start`}
                >
                  <Text
                    ellipsis={true}
                    width={`calc(100% - (1rem * (17 / 14)))`}
                    lh={`unset`}
                    color={colors.Bl06}
                    weight={`500`}
                    wh={`nowrap`}
                    className={`f14`}
                  >
                    {item.name}
                  </Text>

                  <Icon
                    width={`calc(1rem * (17 / 14))`}
                    cursor={`pointer`}
                    src={XArrowIconGrey}
                    onClick={() => DeleteInfo("partner", item, idx)}
                  />
                </Wrapper>
              ))}
          </Wrapper>
        </Wrapper>

        <Devive
          width={`100%`}
          height={`1px`}
          bgc={colors.Gr02}
          margin={`52px 0 36px 0`}
        />

        <Wrapper
          wrap={`wrap`}
          margin={`0 0 52px 0`}
          padding={`0 36px`}
          width={`100%`}
          jc={`space-between`}
        >
          <Text
            margin={`0 0 16px 0`}
            color={colors.Gr05}
            width={`100%`}
            weight={`700`}
            className={`f16`}
          >
            액티비티 목록
          </Text>

          <Wrapper wrap={`wrap`} width={`100%`} jc={`space-between`}>
            {centerInfo &&
              centerInfo.activity.map((item, idx) => (
                <Wrapper
                  cursor={`pointer`}
                  key={`center_partner_${idx}`}
                  margin={`0 0 24px 0`}
                  width={`100%`}
                  wrap={`wrap`}
                  onClick={() => ActivityReplace(item.title)}
                >
                  <Wrapper
                    margin={`0 0 12px 0`}
                    width={`100%`}
                    jc={`space-between`}
                  >
                    <Wrapper>
                      <Icon
                        margin={`0 4px 0 0`}
                        width={`calc(1rem * (16 / 14))`}
                        src={SemosLogoGrey}
                      />
                      <Text
                        ws={`nowrap`}
                        color={colors.Bl07}
                        width={`100%`}
                        weight={`500`}
                        className={`f12`}
                      >
                        {item.sport ? item.sport[0].sport : "미등록"}
                      </Text>
                      <Text
                        ws={`nowrap`}
                        margin={`0 6px`}
                        color={colors.Gr04}
                        width={`100%`}
                        weight={`500`}
                        className={`f12`}
                      >
                        |
                      </Text>
                      <Text
                        ws={`nowrap`}
                        color={colors.Gr04}
                        width={`100%`}
                        weight={`500`}
                        className={`f12`}
                      >
                        {item.duration ? item.duration : "미등록"}
                      </Text>
                      <Text
                        ws={`nowrap`}
                        margin={`0 6px`}
                        color={colors.Gr04}
                        width={`100%`}
                        weight={`500`}
                        className={`f12`}
                      >
                        |
                      </Text>
                      <Text
                        ws={`nowrap`}
                        color={colors.Gr04}
                        width={`100%`}
                        weight={`500`}
                        className={`f12`}
                      >
                        {item.type ? item.type : "미등록"}
                      </Text>
                    </Wrapper>

                    <Wrapper>
                      <Icon
                        margin={`0 4px 0 0`}
                        width={`calc(1rem * (16 / 14))`}
                        src={LocationGrey}
                      />
                      <Text
                        color={colors.Gr04}
                        width={`100%`}
                        weight={`500`}
                        className={`f12`}
                      >
                        {item.schecule ? item.schecule : "스케줄 미등록"}
                      </Text>
                    </Wrapper>
                  </Wrapper>

                  <Wrapper width={`100%`} jc={`space-between`}>
                    <Wrapper
                      of={`hidden`}
                      height={`calc(1rem * (65 / 14))`}
                      width={`calc(1rem * (65 / 14))`}
                      jc={`center`}
                    >
                      <Icon
                        height={`calc(1rem * (65 / 14))`}
                        width={`auto`}
                        src={item.profile ? item.profile : EmptyImage}
                      />
                    </Wrapper>

                    <Wrapper
                      ai={`normal`}
                      wrap={`wrap`}
                      padding={`0 0 0 12px`}
                      height={`calc(1rem * (65 / 14))`}
                      width={`calc(100% - (1rem * (65 / 14)) )`}
                      jc={`center`}
                    >
                      <Text
                        ai={`flex-start`}
                        color={colors.Gr05}
                        width={`100%`}
                        weight={`500`}
                        className={`f14`}
                      >
                        {item.title ? item.title : "액티비티명 미등록"}
                      </Text>

                      <Text
                        ai={`flex-end`}
                        color={colors.Bl06}
                        width={`100%`}
                        weight={`500`}
                        className={`f15`}
                      >
                        {item.price_text
                          ? `${item.price_text} 원`
                          : "판매 가격 미등록"}
                      </Text>
                    </Wrapper>
                  </Wrapper>
                </Wrapper>
              ))}
          </Wrapper>

          <Wrapper wrap={`wrap`} width={`100%`} jc={`flex-end`}>
            <Wrapper
              onClick={() => ActivityReplace(centerInfo.title)}
              wrap={`wrap`}
              cursor={`pointer`}
              jc={`flex-end`}
            >
              <Text
                lh={`unset`}
                color={colors.Bl06}
                weight={`500`}
                className={`f16`}
              >
                액티비티 관리로 이동
              </Text>
              <Icon
                width={`calc(1rem * (20 / 14))`}
                cursor={`pointer`}
                src={ArrowIconBlue}
                //onClick={(e) => PartnerSearch(partnerText)}
              />
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper>

      <ImageCrop
        info={cropImage}
        setInfo={setCropImage}
        setImg={setCenterInfo}
        img={centerInfo}
      />
    </>
  );
};

export default CenterDetail;

const Input = styled.input`
  width: ${({ width }) => `${width}`};
  display: inline-block;
`;
const InputSub = styled.input`
  width: ${({ width }) => `${width}`};
  display: inline-block;
  -webkit-text-security: disc;
`;
