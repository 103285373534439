import styled from "styled-components";

const Container = styled.div`
  display : inline-block;
  width: 100%;
  border-radius: 8px;
  padding: 10px 12px;
  border: 1px solid rgba(132,140,148,1);
  font-size: 17px;
  font-weight: 500;
  background-color: #F9F9F9;
  height: 250px;
  line-height : 1.4;
  overflow : hidden auto;
`

const Content = styled.div`
  display : inline-flex;
  flex-wrap : wrap;
  justify-content : start;
  align-items : center;
  width : 100%;
  font-size: 15px;
  font-weight : 400;

  span {
    display : inline-block;
  }

  input, textarea {
    display : inline-flex;
    font-weight : 500;
    border : unset !important;;
    // text-decoration : underline;
    width : ${({ size }) => (size ? size : '600px')};
    text-align : ${({ align }) => (align ? align : 'unset')};
    padding : 0 5px;
    height : unset !important;
    background-color : transparent !important;
    border-radius : unset !important;
    outline : none !important;
    border-bottom : #eeeeee solid 1px !important;
  }

`

const Template = ({type, data, change}) => { 

  switch(type) {

    case "PREGISTER":
      return (
        <Container>

          <Content size="150px" align="center">
            <input onChange={(e) => change(e.target.name, e.target.value)} readOnly type="text" value={data.partner} name="partner" />
            <span>파트너님, 파트너 등록을 신청해주셔서 감사합니다 :)</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content size="600px">
            <span>세모스는 현재 앱스토어 10위를 기념하며 모든 레슨을 무료로 등록해드리고 있습니다! 레슨 등록 가능한 링크를 발송해드릴 예정이오니, 아래 사항을 본 채널로 회신해주시기 바랍니다.</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content size="600px">
            <span>세모스에 등록하실 레슨명(1종목 당 5개 가능)</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>-</span>
          </Content>

          <Content >
            <span>세모스와 함께해주셔서 늘 감사합니다 :)</span>
          </Content>

          <Content >
            <span>워터스포츠 1등 예약 앱, 세모스!</span>
          </Content>

          <Content >
            <span>☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)</span>
          </Content>

        </Container>
      );
    break;

    case "PSCHEDULE3":
      return (
        <Container>

          <Content size="150px" align="center">
            <input readOnly onChange={(e) => change(e.target.name, e.target.value)}  type="text" value={data.partner} name="partner" />
            <span>파트너님! 세모스 어플 내</span>
            <input onChange={(e) => change(e.target.name, e.target.value)} placeholder='**월' maxLength="10" type="text" value={data.month} name="month" />
            <span>일정을 등록하고 예약시스템을 오픈하기 위해 아래 정보가 필요해요.</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content size="150px" align="center">
            <span>-</span>
            <input onChange={(e) => change(e.target.name, e.target.value)} placeholder='**월' maxLength="10" type="text" value={data.month} name="month" />
            <span>진행 불가능 일정</span>
          </Content>

          <Content >
            <span>- 액티비티 정보(금액, 활동 지역 등) 변동사항</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content  size="150px" align="center">
            <span>파트너님의 레슨을 고객님들이 정상적으로 결제할 수 있도록</span>
            <input onChange={(e) => change(e.target.name, e.target.value)} placeholder='**월 **일' maxLength="10" type="text" value={data.mnd} name="mnd" />
            <span>까지 회신주시기 바랍니다.</span>
          </Content>

          <Content >
            <span></span>
          </Content>

          <Content >
            <span>레저스포츠 1등 예약 앱, 세모스!</span>
          </Content>

          <Content >
            <span>☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)</span>
          </Content>


        </Container>
      );
    break;

 

  }

}

export default Template;