export const partnerGlobalData = {
  user: [
    {
      title: "신규 등록 파트너",
      value: 3,
    },
    {
      title: "워터스포츠 파트너",
      value: 87,
    },
    {
      title: "야외스포츠 파트너",
      value: 6,
    },
    {
      title: "실내스포츠 파트너",
      value: 2,
    },
  ],
};

export const partnerListData = [
  {
    id: 119,
    name: "고크리스틴",
    category: "프리다이빙",
    nickname: "고크리스틴",
    registerDate: "2022-03-25",
    status: true,
  },
  {
    id: 120,
    name: "고크리스틴",
    category: "프리다이빙",
    nickname: "고크리스틴",
    registerDate: "2022-03-25",
    status: true,
  },
  {
    id: 113,
    name: "고크리스틴",
    category: "프리다이빙",
    nickname: "고크리스틴",
    registerDate: "2022-03-25",
    status: true,
  },
  {
    id: 111,
    name: "고크리스틴",
    category: "프리다이빙",
    nickname: "고크리스틴",
    registerDate: "2022-03-25",
    status: false,
  },
  {
    id: 122,
    name: "고크리스틴",
    category: "프리다이빙",
    nickname: "고크리스틴",
    registerDate: "2022-03-25",
    status: false,
  },
  {
    id: 222,
    name: "고크리스틴",
    category: "프리다이빙",
    nickname: "고크리스틴",
    registerDate: "2022-03-25",
    status: false,
  },
  {
    id: 400,
    name: "고크리스틴",
    category: "프리다이빙",
    nickname: "고크리스틴",
    registerDate: "2022-03-25",
    status: false,
  },
  {
    id: 321,
    name: "고크리스틴",
    category: "프리다이빙",
    nickname: "고크리스틴",
    registerDate: "2022-03-25",
    status: true,
  },
  {
    id: 777,
    name: "고크리스틴",
    category: "프리다이빙",
    nickname: "고크리스틴",
    registerDate: "2022-03-25",
    status: true,
  },
  {
    id: 577,
    name: "고크리스틴",
    category: "프리다이빙",
    nickname: "고크리스틴",
    registerDate: "2022-03-25",
    status: true,
  },
  {
    id: 252,
    name: "고크리스틴",
    category: "프리다이빙",
    nickname: "고크리스틴",
    registerDate: "2022-03-25",
    status: true,
  },
  {
    id: 145,
    name: "고크리스틴",
    category: "프리다이빙",
    nickname: "고크리스틴",
    registerDate: "2022-03-25",
    status: true,
  },
  {
    id: 198,
    name: "고크리스틴",
    category: "프리다이빙",
    nickname: "고크리스틴",
    registerDate: "2022-03-25",
    status: true,
  },
];
