import styled from "styled-components";
import colors from "../../../styles/constants/colors";

export const ButtonBox = styled.div`
  width: 100%;
  text-align: end;
  justify-self: flex-end;

  button {
    background-color: ${colors.blue};
    color: ${colors.white};
    padding: 5px 15px;
    border-radius: 40px;
  }
`;

export const Main = styled.main`
  padding: 0 20px;
  margin: 30px 0;
  min-height: calc(10 * 65px + 50px);
`;

export const Footer = styled.footer`
  padding: 0 20px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 90px;
  background-color: ${colors.white};
`;
