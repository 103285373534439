import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";

import DesignedMainHeader from "../../components/designedHeader";
import DesignedMainLayout from "../../layouts/designedMainLayout";
import Table from "../../layouts/table";
import {
  TableFilterContainer,
  TableFilterContent,
  TableWrapper,
  Wrapper,
  Icon,
  Text,
  TableContent,
  TableFooter,
  TableColumn,
  TableFooterPaging,
} from "../../layouts/layoutStorage";

// icon
import SearchIcon from "../../assets/icon/search.png";
import DoublePaging from "../../assets/icon/double_paging.png";
import OnePaging from "../../assets/icon/one_paging.png";
import colors from "../../styles/constants/colors";

const LIST_HEADER = ["액티비티 ID", "액티비티 명", "센터", "파트너 명", "유형", "액티비티 카테고리", "1인 가격", "장소", "상태"];

const PBActivity = () => {
  const { n, t, cate } = useParams();
  const navigate = useNavigate();

  const [activity, setActivity] = useState([]);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState(t !== "all" ? t : "");
  const [Height, setHeight] = useState({
    TableFilter: 0,
    TableHeader: 0,
    TableFooter: 0,
    Layout: 0,
    LayoutHeader: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const Layout = document.getElementById("Layout")?.offsetHeight - 36;
      const LayoutHeader = document.getElementById("LayoutHeader")?.offsetHeight + 65;
      const TableFilter = document.getElementById("TableFilter")?.offsetHeight + 19;
      const TableHeader = document.getElementById("TableHeader")?.offsetHeight;
      const TableFooter = document.getElementById("TableFooter")?.offsetHeight;

      setHeight({
        TableFilter: TableFilter,
        TableHeader: TableHeader,
        TableFooter: TableFooter,
        Layout: Layout,
        LayoutHeader: LayoutHeader,
      });
    }, 500);
  }, []);

  useEffect(() => {
    getData();
  }, [n, t, cate]);

  const numPages = Math.ceil(count / 12);

  let PageList = [];

  if (n < 11) {
    for (let i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
      PageList.push(i + 1);
    }
  } else {
    if (numPages - 10 < n) {
      const startPage = numPages - 19;
      if (startPage < 1) {
        startPage = 1;
      }

      for (let i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    } else {
      const startPage = n - 9;
      if (startPage < 1) {
        startPage = 1;
      }

      for (let i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    }
  }

  const getData = () => {
    const token = window.localStorage.getItem("token");

    const frm = new FormData();

    frm.append("mode", "pb_activity_list");
    frm.append("token", token);
    frm.append("page", n);
    frm.append("text", t);
    frm.append("category", cate);

    axios
      .post("https://ai.semos.kr/semos_admin/activity/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        const Value = data.data;
        const status = Value.status;
        const statusMessage = Value.status_message;

        if (status === "success") {
          setActivity(Value.activity_list);
          setCount(Value.activity_count);
        } else if (status === "waring") {
          alert(statusMessage);
        }
      });
  };

  const handlePage = (num) => {
    const token = localStorage.getItem("token");

    if (token) {
      navigate(`/pb_activity/${num}/${search ? search : "all"}/all`);
    } else {
      alert("권한이 없습니다.");
    }
  };

  const handleSearch = (value) => {
    const copy = value;
    setSearch(copy);
  };

  const enterSearch = (key) => {
    if (key == "Enter") {
      handlePage(1);
    }
  };

  const handleStatusColor = (item) => {
    switch (item) {
      case "활성화":
        return `${colors.Bl06}`;
      case "비노출":
        return `${colors.Gr03}`;
      case "승인 요청":
        return `${colors.Red03}`;
    }
  };

  const showDetail = (id) => {
    window.open(`/pb_activity_detail/${id}`, "_blank");
  };

  return (
    <>
      <DesignedMainLayout>
        <DesignedMainHeader title="PB 액티비티" />

        <Table>
          <TableFilterContainer id="TableFilter" margin={`0 0 19px 0`}>
            <Wrapper jc="flex-start">
              <TableFilterContent margin={`0 24px 0 0`}>
                <Input
                  onKeyUp={(e) => enterSearch(e.key)}
                  value={search}
                  onChange={(e) => handleSearch(e.target.value)}
                  width={`350px`}
                  type="text"
                  maxLength={40}
                  placeholder="검색어를 입력해주세요"
                />
                <Icon width={`calc(1rem * (17 / 14))`} cursor={`pointer`} src={SearchIcon} onClick={() => handlePage(1)} />
              </TableFilterContent>
            </Wrapper>

            <SaveButton
              className="f12"
              onClick={() => {
                showDetail("new");
              }}
            >
              액티비티 추가하기
            </SaveButton>
          </TableFilterContainer>
          <TableContent height={Height.Layout - (Height.LayoutHeader + Height.TableFilter + Height.TableFooter)} contentHeight={Height.TableHeader}>
            <section id="TableHeader">
              {LIST_HEADER.map((val) => (
                <TableColumn key={val} width={val === "액티비티 명" ? "20" : "10"} weight={`500`}>
                  {val}
                </TableColumn>
              ))}
            </section>

            <section>
              {activity
                ? activity.map((item, idx) => {
                    const sportArr = item.sport;
                    let sportext = "";

                    if (sportArr.length) {
                      if (sportArr.length == 1) {
                        sportext = sportArr[0].sport;
                      } else {
                        for (let i = 0; i < sportArr.length; i++) {
                          if (i) {
                            sportext = sportext + " | " + sportArr[i].sport;
                          } else {
                            sportext = sportArr[0].sport;
                          }
                        }
                      }
                    }

                    return (
                      <Link to={`/pb_activity_detail/${item.activity_id}`}>
                        <TableWrapper
                          cursor={`pointer`}
                          key={`${idx}_reservation`}
                          bgc={colors.White}
                          bt={`1px solid transparent`}
                          bl={`1px solid transparent`}
                          br={`1px solid transparent`}
                          bb={`1px solid transparent`}
                          width={`100%`}
                        >
                          <TableColumn width={`10`} weight={`500`}>
                            {`${item.activity_id}`}
                          </TableColumn>

                          <TableColumn width={`20`} weight={`500`}>
                            {`${item.name}`}
                          </TableColumn>

                          <TableColumn width={`10`} weight={`500`}>
                            {`${item.center}`}
                          </TableColumn>

                          <TableColumn width={`10`} weight={`500`}>
                            {`${item.partner}`}
                          </TableColumn>

                          <TableColumn width={`10`} weight={`500`}>
                            {`${item.duration} ${item.type}`}
                          </TableColumn>

                          <TableColumn width={`10`} weight={`500`}>
                            {`${sportext}`}
                          </TableColumn>

                          <TableColumn width={`10`} weight={`500`}>
                            {item.price ? `${item.price}원` : ""}
                          </TableColumn>

                          <TableColumn width={`10`} weight={`500`}>
                            {`${item.location}`}
                          </TableColumn>

                          <TableColumn width={`10`} weight={`500`}>
                            <Text color={handleStatusColor(item.status)}>{item.status}</Text>
                          </TableColumn>
                        </TableWrapper>
                      </Link>
                    );
                  })
                : ""}

              <TableFooter id="TableFooter">
                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  src={DoublePaging}
                  onClick={() => {
                    if (Number(n) < 11) {
                      handlePage(1);
                    } else {
                      handlePage(Number(n) - 10);
                    }
                  }}
                />
                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  src={OnePaging}
                  onClick={() => {
                    if (Number(n) > 1) {
                      handlePage(n - 1);
                    } else {
                      window.alert("첫번째 페이지 입니다.");
                    }
                  }}
                />

                {PageList.length
                  ? PageList.map((item, idx) => (
                      <TableFooterPaging
                        key={`paging_number_${idx}`}
                        margin={`0 16px 0 0`}
                        color={`${n == item.toString() ? colors.Bl06 : colors.Gr03}`}
                        onClick={() => handlePage(item)}
                      >
                        {item}
                      </TableFooterPaging>
                    ))
                  : ""}

                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  transform={`rotate(180deg)`}
                  src={OnePaging}
                  onClick={() => {
                    if (n < numPages) {
                      handlePage(Number(n) + 1);
                    } else {
                      window.alert("마지막 페이지 입니다.");
                    }
                  }}
                />
                <Icon
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  transform={`rotate(180deg)`}
                  src={DoublePaging}
                  onClick={() => {
                    if (Number(n) + 10 > numPages) {
                      handlePage(numPages);
                    } else {
                      handlePage(Number(n) + 10);
                    }
                  }}
                />
              </TableFooter>
            </section>
          </TableContent>
        </Table>
      </DesignedMainLayout>
    </>
  );
};

export default PBActivity;

const Input = styled.input`
  display: inline-block;
  width: ${({ width }) => `${width}`};
`;

const SaveButton = styled.div`
  padding: 12px 20px;
  background-color: ${colors.Bl07};
  border-radius: 8px;
  color: ${colors.White};
  line-height: 142%;
  font-weight: 700;
  cursor: pointer;
`;
