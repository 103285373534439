import { Link } from "react-router-dom";
import styled from "styled-components";
import ToggleButton from "../../modules/toggleButton";
import flexbox from "../../styles/func/flexbox";

const Card = styled.li`
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
`;

const SLink = styled(Link)`
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;

  div {
    white-space : nowrap;
    overflow : hidden;
    text-overflow : ellipsis;

    &:first-child {
      width: calc(10% + ((10 / 90) * 10%) );
      padding-right : 2px;
    }
  
    &:nth-child(2) {
      width: calc(15% + ((15 / 90) * 10%) );
      padding-right : 2px;
    }
  
    &:nth-child(3) {
      width: calc(17% + ((17 / 90) * 10%) );
      padding-right : 2px;
    }
  
    &:nth-child(4) {
      width: calc(17% + ((17 / 90) * 10%) );
      padding-right : 2px;
    }
  
    &:nth-child(5) {
      width: calc(31% + ((31 / 90) * 10%) );
      padding-right : 2px;
    }
  }
`;

const StatusDiv = styled.div`
  width: 10%;
  ${flexbox()}
`;
const ConfirmDiv = styled.div`
  display : inline-block;
  pointer-events : none;
  color : #E53553;
`


const FacilityRowCard = ({ data, statusToggleHandler }) => {
  return (
    <Card>
      <SLink to={`/facility/${data.facility_id}`}>
        <div>{data.number}</div>

        <div>{data.name}</div>

        <div>{data.sport}</div>

        <div>{data.category}</div>

        <div>{`${data.address}`}</div>

      </SLink>

      <StatusDiv>
        {data.status == 'yes' || data.status == 'close' ? <ToggleButton
          toggleHandler={() =>
            statusToggleHandler()
          }
          status={data.status}
          type="activity"
        /> : <ConfirmDiv>미승인</ConfirmDiv>}
      </StatusDiv>
    </Card>
  );
};

export default FacilityRowCard;
