import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
// img
import ArrowIcon from "../assets/icon/side_nav_arrow.png";

const SideNavigate = ({ el, pathname }) => {
  const [open, setOpen] = useState(false);

  const activeVending = (path) => {
    if (pathname.includes("message")) {
      if (pathname.includes(`message_detail`)) {
        return path.includes(`message_list`);
      } else {
        return pathname.includes(`${path}`);
      }
    } else if (pathname.includes("activity")) {
      // PB 액티비티 | 파트너 액티비티 구분
      if (pathname.includes("detail")) {
        return pathname.split("/")[1].split("_detail")[0] === `${path.split("/")[1]}`;
      } else {
        return pathname.split("/")[1] === `${path.split("/")[1]}`;
      }
    } else {
      return pathname.includes(`${path.split("/")[1].split("_")[0]}`);
    }
  };

  if (el.path) {
    return (
      <Linker active={activeVending(el.path)} to={el.path}>
        <div>
          <img src={activeVending(el.path) ? el.active : el.default} />
          {el.text}
        </div>
      </Linker>
    );
  } else {
    return (
      <>
        <Linker2 onClick={() => setOpen((prev) => !prev)}>
          <Container>
            <img src={el.default} />
            {el.text}
          </Container>
          <Arrow src={ArrowIcon} id="arrow" open={open} />
        </Linker2>
        {open &&
          el.children.map((child, i) => {
            return (
              <Linker active={activeVending(child.path)} to={child.path}>
                <div>
                  <span
                    style={{
                      width: "32px",
                      height: "22px",
                      border: "none",
                    }}
                  />
                  {child.text}
                </div>
              </Linker>
            );
          })}
      </>
    );
  }
};
export default SideNavigate;

const Linker = styled(Link)`
  div {
    padding: 16px 0;
    padding-left: 30px;
    display: flex;
    align-items: center;
    background-color: ${({ active }) => (active ? "#F2F7FF" : "#FFFFFF")};
    font-size: 14px;
    font-weight: 600;
    color: ${({ active }) => (active ? "#3383FF" : "#ACAFB5")};
    img {
      width: 22px;
      height: 22px;
      margin-right: 10px;
    }
    ${({ active }) => active && "border-right: 1.5px solid #0E6DFF"}
  }
  cursor: pointer;
`;
const Linker2 = styled.div`
  padding: 16px 0 16px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #acafb5;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
`;
const Arrow = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 24px;
  transition: all 0.7s ease;
  transform: ${({ open }) => (open ? "rotate(-180deg)" : "")};
`;
