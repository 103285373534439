import styled from "styled-components";
import base from "../../styles/constants/base";
import colors from "../../styles/constants/colors";
import typography from "../../styles/constants/typography";
import flexbox from "../../styles/func/flexbox";

const CARD_COUNT = 4;

const CARD_TITLE = {
  "indoor" : "실내스포츠 파트너",
  "new" : "신규 등록 파트너",
  "outdoor" : "야외스포츠 파트너",
  "water" : "워터스포츠 파트너",
}

const Card = styled.li`
  width: calc((100% / ${CARD_COUNT}));
  height: 140px;
  background-color: ${colors.white};
  border-radius: ${base.borderRadius.default}px;
  box-shadow: ${base.boxShadow.default};
  margin: 0 10px;
  padding: 20px;
  ${flexbox("center", "center", "column")}

  p {
    font-weight: ${typography.weight.bold};
    font-size: ${typography.size.medium}px;
    margin-bottom: 20px;
  }

  div {
    strong {
      font-size: 40px;
      margin-right: 10px;
    }
  }

  &:first-child {
    strong {
      color: ${colors.red};
    }
  }
`;

const PartnerCard = ({ title, value }) => {
  return (
    <Card>
      <p>{CARD_TITLE[title]}</p>

      <div>
        <strong>{value}</strong>
        <span>인</span>
      </div>
    </Card>
  );
};

export default PartnerCard;
