import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import Calendar from "../../../components/calendar";
import ClockField from "../../../components/clockField";
import MainHeader from "../../../components/header";
import useInput from "../../../hooks/useInput";
import useList from "../../../hooks/useList";
import MainLayout from "../../../layouts/mainLayout";
import Button from "../../../modules/button";
import colors from "../../../styles/constants/colors";
import typography from "../../../styles/constants/typography";
import flexbox from "../../../styles/func/flexbox";

const getFilteredData = (scheduleData, targetDatetime) => {
  const { year, month, date } = targetDatetime;

  const index = `${year}-${
    parseInt(month) + 1 < 10 ? `0${month + 1}` : month + 1
  }-${parseInt(date) < 10 ? `0${date}` : date}`;

  return scheduleData[index];
};

const Main = styled.main`
  width: 100%;
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 120px;
  height: 100%;
  overflow-y: scroll;

  section {
    width: 100%;

    header {
      width: 100%;
      ${flexbox("space-between")};
      margin-bottom: 20px;

      p {
        font-size: ${typography.size.medium}px;
        font-weight: ${typography.weight.bold};
      }

      button {
        color: ${colors.gray};
      }
    }

    ul {
    }
  }
`;

const CalendarBox = styled.div`
  width: 100%;
  ${flexbox("center", "flex-start")}
`;

const ClockBox = styled.div`
  display: flex;

  p {
    margin-right: 50px;
    font-size: ${typography.size.medium}px;
  }
`;

const Title = styled.p`
  font-size: ${typography.size.medium}px;
  margin-bottom: 10px;
`;

const Comment = styled.p``;

const Footer = styled.footer`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 90px;
  padding-right: 20px;
  ${flexbox("flex-end")}
  background-color: ${colors.white}
`;

const PartnerScheduleRegisterPage = () => {
  const saveHandler = () => {
    console.log("일정 저장하기");
  };

  const scheduleData = {
    "2022-05-03": {
      time: [
        {
          id: 0,
          "start_time": "08",
          "start_min": "00",
          "finish_time": "11",
          "finish_min": "00",
        },
        {
          id: 0,
          "start_time": "08",
          "start_min": "00",
          "finish_time": "11",
          "finish_min": "00",
        },
      ],
    },
    "2022-05-15": {
      time: [
        {
          id: 0,
          "start_time": "08",
          "start_min": "00",
          "finish_time": "11",
          "finish_min": "00",
        },
        {
          id: 0,
          "start_time": "08",
          "start_min": "00",
          "finish_time": "11",
          "finish_min": "00",
        },
      ],
    },
    "2022-05-30": {
      time: [
        {
          id: 0,
          "start_time": "08",
          "start_min": "00",
          "finish_time": "11",
          "finish_min": "00",
        },
        {
          id: 0,
          "start_time": "08",
          "start_min": "00",
          "finish_time": "11",
          "finish_min": "00",
        },
      ],
    },
  };

  const currentDate = new Date();

  const [targetDatetime, setTargetDatetime] = useState({
    year: currentDate.getFullYear(),
    month: currentDate.getMonth(),
    date: currentDate.getDate(),
  });

  const [targetDay, setTargetDay] = useState(currentDate.getDate());

  const [targetDayList, setTargetDayList] = useState([]);

  const [targetDayData, setTargetDayData] = useState(
    getFilteredData(scheduleData, {
      year: targetDatetime.year,
      month: targetDatetime.month,
      date: targetDay,
    })
  );

  const targetDayDataChangeHandler = (day) => {
    setTargetDay(day);

    setTargetDayData(
      getFilteredData(scheduleData, {
        year: targetDatetime.year,
        month: targetDatetime.month,
        date: day,
      })
    );
  };

  const addTargetDayListHandler = (datetime) => {
    const index = `${datetime.year}-${
      parseInt(datetime.month) < 10 ? `0${datetime.month}` : datetime.month
    }-${parseInt(datetime.date) < 10 ? `0${datetime.date}` : datetime.date}`;

    if (targetDayList[index]) {
      setTargetDayList((prev) => {
        const updated = { ...prev };
        delete updated[index];
        return updated;
      });
    } else {
      setTargetDayList((prev) => ({
        ...prev,
        [index]: datetime,
      }));
    }
  };

  const {
    value: clockValue,
    setValue: setClockValue,
    inputChangeHandler: clockInputChangeHandler,
  } = useInput({
    "start_time": "",
    "start_min": "",
    "finish_time": "",
    "finish_min": "",
  });

  const {
    list: clockList,
    setList: setClockList,
    addHandler: addClockListHandler,
    deleteHandler: deleteClockHandler,
  } = useList([]);

  const resetHandler = () => {
    setClockList([]);
    setTargetDayList([]);
  };

  console.log();

  const addClockHandler = (e) => {
    const { name, value } = e.target;

    console.log();

    addClockListHandler({
      id: new Date(),
      ...clockValue,
    });

    setClockValue({
      "start_time": "",
      "start_min": "",
      "finish_time": "",
      "finish_min": "",
    });
  };

          // 파트너 상세 로드
          useEffect(() => {
    
            var token = window.localStorage.getItem('token')
      
            const frm = new FormData();
            frm.append("mode", 'schedule_intro');
            frm.append("token", token);
            frm.append("number", "");
    
              axios.post("https://ai.semos.kr/semos_admin/partner/data", frm, {
                headers: { "Content-Type": "multipart/form-data" },
              }).then((data) => {
                var Value = data.data;
                var status = Value.status
                var statusMessage = Value.status_message
      
                if(status == 'success') {
         
    
                } else if(status == 'waring') {
                  alert(statusMessage)
                }
      
              });
      
          }, []);

  return (
    <MainLayout>
      <MainHeader title="경기 가평군 청평면 고재길 262-57 가평 k26" />

      <Main>
        <Title>등록할 날짜를 클릭해 주세요.</Title>
        <Comment>
          날짜로 등록한 일정은 저장 시 바로 이용자 서비스에 적용됩니다.
        </Comment>

        <CalendarBox>
          <Calendar
            allData={scheduleData}
            targetDay={null}
            targetDayList={targetDayList}
            currentDate={currentDate}
            targetDatetime={targetDatetime}
            setTargetDatetime={setTargetDatetime}
            setTargetDay={targetDayDataChangeHandler}
            addTargetDayListHandler={addTargetDayListHandler}
          />
        </CalendarBox>

        <section>
          <header>
            <p>레슨 시작 시간과 끝 시간을 입력해 주세요.</p>
            <button onClick={resetHandler}>일정 초기화</button>
          </header>

          <ClockBox>
            <p>레슨 시간</p>
            <ClockField
              clock={{
                clockChangeHandler: clockInputChangeHandler,
                addClockListHandler: addClockHandler,
                deleteClockHandler: deleteClockHandler,
                clockList,
              }}
            />
          </ClockBox>
        </section>
      </Main>

      <Footer>
        <Button text="일정 저장하기" width="230px" clickHandler={saveHandler} />
      </Footer>
    </MainLayout>
  );
};

export default PartnerScheduleRegisterPage;
