import { Link } from "react-router-dom";
import styled from "styled-components";
import colors from "../../styles/constants/colors";

const Card = styled.li`
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
`;

const SLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content : space-between;

  div {
    overflow : hidden;
    text-overflow: ellipsis;
    white-space : nowrap;


    &:first-child {
      width: calc(10% + ((10 / 90) * 10%) );
    }

    &:nth-child(2) {
      width: calc(15% + ((15 / 90) * 10%) );
    }

    &:nth-child(3) {
      width: calc(25% + ((25 / 90) * 10%) );
    }

    &:nth-child(4) {
      width: calc(20% + ((20 / 90) * 10%) );
    }

    &:nth-child(5) {
      width: calc(20% + ((20 / 90) * 10%) );
    }
  }
`;

const Partner = styled.p`
  color: ${colors.red};
`;

const LevelDiv = styled.div`
  width: 10%;
  text-align: center;
`;

const LevelButton = styled.button`
  color: ${colors.white};
  background-color: ${colors.blue};
  padding: 5px 20px;
  border-radius: 30px;
`;

const CustomerListCard = ({ data, idx, level }) => {


  return (
    <Card>
      <SLink to={`/customer/${data.id}`}>
        <div>{data.number}</div>

        <div>{data.name}</div>

        <div>{data.email}</div>

        <div>{data.phone}</div>

        <div color={data.type === "파트너" ? "true" : "false"}>
          <p>{data.type === "일반회원" && "일반회원"}</p>
          <p>{data.type === "미승인 파트너" && "미승인 파트너"}</p>
          <p>{data.type === "delete" && "탈퇴된 회원"}</p>
          <Partner>{data.type === "파트너" && "파트너"}</Partner>
          <p>{data.type === "리포츠" && "리포츠"}</p>
        </div>
      </SLink>

      <LevelDiv>
        {data.tier_up === 'yes' && (
          <LevelButton type="button" onClick={() => level(data.id, idx)}>
            등업
          </LevelButton>
        )}
      </LevelDiv>
    </Card>
  );
};

export default CustomerListCard;
