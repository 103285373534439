import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

// components
import DropDown from "../../components/designedComponents/dropDown";


const DashboardNew = () => {
    const [card, setCard] = useState(0);

    const [cardData, setCardData] = useState({});

    const [filter, setFilter] = useState({
        year : '2024',
        month : '04',
        start : false
    });

    const [loading, setLoading] = useState(false);

    const today = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        date: new Date().getDate(),
    };

    useEffect(() => {
        setLoading(true);
        setFilter((prev) => ({ ...prev, year: today.year, month: (today.month > 9) ? today.month : `0${today.month}` }));

        var token = localStorage.getItem("token");
        const frm = new FormData();

        frm.append("mode", "main_new");
        frm.append("token", token);
        frm.append("year", today.year);
        frm.append("month", (today.month > 9) ? today.month : `0${today.month}`);

        axios
            .post("https://ai.semos.kr/semos_admin/v2/board/Dashboard", frm, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
                const Value = res.data;
                const Status = Value.status;

                if(Status == 'success') {
                    setCardData(Value.data_list);
                    setFilter((prev) => ({ ...prev, start: true }));

                    setLoading(false);
                }

            });
    }, []);

    useEffect(() => {
        if(filter.start === true) {
            setLoading(true);
            var token = localStorage.getItem("token");
            const frm = new FormData();
    
            frm.append("mode", "main_new");
            frm.append("token", token);
            frm.append("year", filter.year);
            frm.append("month", filter.month);
    
            axios
                .post("https://ai.semos.kr/semos_admin/v2/board/Dashboard", frm, {
                    headers: { "Content-Type": "multipart/form-data" },
                })
                .then((res) => {
                    const Value = res.data;
                    const Status = Value.status;
    
                    if(Status == 'success') {
                        setCardData(Value.data_list);
                        setFilter((prev) => ({ ...prev, start: true }));

                        setLoading(false);
                    }
    
                });
        }
    }, [filter])

    return (
        <Container id="con">
            <CardContainer>

                <div style={{width : '100%', display : 'inline-flex', height : '50px', justifyContent : "flex-end", alignItems : "flex-start", }}>
                    <DropDown
                        data={filter?.year}
                        setData={setFilter}
                        option={[
                                { text: "2022년", value: "2022" },
                                { text: "2023년", value: "2023" },
                                { text: "2024년", value: "2024" },
                                { text: "2025년", value: "2025" },
                                { text: "2026년", value: "2026" },
                                { text: "2027년", value: "2027" },
                                { text: "2028년", value: "2028" },
                                { text: "2029년", value: "2029" },
                                { text: "2030년", value: "2030" },
                                { text: "2031년", value: "2031" },
                                { text: "2032년", value: "2032" }
                            ]}
                            type={`object`}
                            defaultText={`2024년`}
                            no={false}
                            keyName={`year`}
                        />

                        <DropDown
                            data={filter?.month}
                            setData={setFilter}
                            option={[
                                { text: "01월", value: "01" },
                                { text: "02월", value: "02" },
                                { text: "03월", value: "03" },
                                { text: "04월", value: "04" },
                                { text: "05월", value: "05" },
                                { text: "06월", value: "06" },
                                { text: "07월", value: "07" },
                                { text: "08월", value: "08" },
                                { text: "09월", value: "09" },
                                { text: "10월", value: "10" },
                                { text: "11월", value: "11" },
                                { text: "12월", value: "12" }
                            ]}
                            type={`object`}
                            defaultText={`01월`}
                            no={false}
                            keyName={`month`}
                        />
                </div>

                <div style={{width : '100%', display : 'inline-flex', flex : 3, gap : '20px', flexWrap : 'wrap'}}>
                    <Card>
                        <div>
                            <CardTitle color="#3383ff" className="f20">
                                전체 파트너 수
                            </CardTitle>
                            <SubTitle className="f16"></SubTitle>
                        </div>
                            <ContentText
                                color="#3C3E41"
                                className="f26"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}
                            >
                                {cardData?.PARTNER_COUNT}인
                            </ContentText>
                    </Card>

                    <Card>
                        <div>
                            <CardTitle color="#3383ff" className="f20">
                                구독 파트너 수/비율
                            </CardTitle>
                            <SubTitle className="f16"></SubTitle>
                        </div>
                            <ContentText
                                color="#3C3E41"
                                className="f26"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}
                            >
                                {cardData?.PARTNER_SUBSCRIBE}인 / {cardData?.PARTNER_SUBSCRIBE_PERCENT}%
                            </ContentText>
                    </Card>

                    <Card>
                        <div>
                            <CardTitle color="#3383ff" className="f20">
                                월간 신규 구독 파트너 수
                            </CardTitle>
                            <SubTitle className="f16"></SubTitle>
                        </div>
                            <ContentText
                                color="#3C3E41"
                                className="f26"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}
                            >
                                {cardData?.PARTNER_SUBSCRIBE_NEW}인
                            </ContentText>
                    </Card>

                    <Card>
                        <div>
                            <CardTitle color="#3383ff" className="f20">
                                누적 시설 파트너 수
                            </CardTitle>
                            <SubTitle className="f16"></SubTitle>
                        </div>
                            <ContentText
                                color="#3C3E41"
                                className="f26"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}
                            >
                                {cardData?.PARTNER_SUBSCRIBE_FACILITY}인
                            </ContentText>
                    </Card>

                    <Card>
                        <div>
                            <CardTitle color="#3383ff" className="f20">
                                파트너 월간 반복 매출
                            </CardTitle>
                            <SubTitle className="f16">(MRR)</SubTitle>
                        </div>
                            <ContentText
                                color="#3C3E41"
                                className="f26"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}
                            >
                                {cardData?.PARTNER_SUBSCRIBE_PRICE}원
                            </ContentText>
                    </Card>
                </div>

                <div style={{width : '100%', display : 'inline-flex', flex : 3, gap : '20px', flexWrap : 'wrap'}}>
                    <Card>
                        <div>
                            <CardTitle color="#3383ff" className="f20">
                                B2C 총 거래액
                            </CardTitle>
                            <SubTitle className="f16">(인앱결제+현장결제)</SubTitle>
                        </div>
                            <ContentText
                                color="#3C3E41"
                                className="f26"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}
                            >
                                {cardData?.B2C_ALL_PAYMENT}원
                            </ContentText>
                    </Card>

                    <Card>
                        <div>
                            <CardTitle color="#3383ff" className="f20">
                                B2C 인앱 결제액
                            </CardTitle>
                            <SubTitle className="f16">(인앱결제-포인트 제외)</SubTitle>
                        </div>
                            <ContentText
                                color="#3C3E41"
                                className="f26"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}
                            >
                                {cardData?.B2C_INAPP_PAYMENT}원
                            </ContentText>
                    </Card>

                    <Card>
                        <div>
                            <CardTitle color="#3383ff" className="f20">
                                B2C 결제 건 수/취소 건 수
                            </CardTitle>
                            <SubTitle className="f16"></SubTitle>
                        </div>
                            <ContentText
                                color="#3C3E41"
                                className="f26"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}
                            >
                                {cardData?.B2C_ALL_PAYMENT_COUNT}개 / {cardData?.B2C_CANCEL_PAYMENT_COUNT}개
                            </ContentText>
                    </Card>

                    <Card>
                        <div>
                            <CardTitle color="#3383ff" className="f20">
                                B2C 유저 수
                            </CardTitle>
                            <SubTitle className="f16"></SubTitle>
                        </div>
                            <ContentText
                                color="#3C3E41"
                                className="f26"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}
                            >
                                {cardData?.B2C_USER_COUNT}인
                            </ContentText>
                    </Card>

                    <Card>
                        <div>
                            <CardTitle color="#3383ff" className="f20">
                                상세 페이지 뷰 수 (파트너/프로그램)
                            </CardTitle>
                            <SubTitle className="f16"></SubTitle>
                        </div>
                            <ContentText
                                color="#3C3E41"
                                className="f26"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}
                            >
                                {`${cardData?.B2C_ALL_VIEW_COUNT}회 (${cardData?.B2C_PARTNER_VIEW_COUNT}회 / ${cardData?.B2C_ACTIVITY_VIEW_COUNT}회)`}
                            </ContentText>
                    </Card>
                </div>


            </CardContainer>

        </Container>
    );
};

export default DashboardNew;

const Container = styled.section`
    /* width: calc(100%); */
    min-height: calc(100vh - 50px);
    height: calc(100vh - 50px);
    background-color: #f9f9f9;
    padding: 36px;
    padding-bottom: 30px;
    /* overflow: hidden; */
`;

const CardContainer = styled.div`
    width: 100%;
    height : 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const Card = styled.div`
    width: calc((100% - 40px) * 0.33);
    padding: 18px 20px 16px 22px;
    background-color: ${({ active }) => (active ? "#F2F7FF" : "#FFFFFF")};
    border: 1px solid ${({ active }) => (active ? "#3383FF" : "#F5F5F5")};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 12px;
    height: auto;
    max-height: calc((100% - 40px) * 0.33);
`;

const CardTitle = styled.div`
    color: ${({ color }) => color};

    font-weight: 600;
    margin-bottom: 2px;
    letter-spacing: -0.02em;
`;

const SubTitle = styled.div`
    color: #acafb5;
    font-weight: 500;

    letter-spacing: -0.02em;
`;

const ContentText = styled.div`
    color: ${({ color }) => color};
    font-weight: 700;
    text-align: end;
    letter-spacing: -0.02em;

    span {
        font-weight: 500;
        margin-left: 4px;
    }
`;
