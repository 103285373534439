import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";

import DesignedMainHeader from "../../components/designedHeader";
import DesignedMainLayout from "../../layouts/designedMainLayout";
import DrawerModal from "../../components/drawerModal";
import Table from "../../layouts/table";
import {
  TableFilterContainer,
  TableFilterContent,
  TableWrapper,
  Wrapper,
  AbsoluteWrapper,
  Icon,
  Text,
  TableContent,
  TableFooter,
  TableColumn,
  TableFooterPaging,
} from "../../layouts/layoutStorage";
// import ActivityDetail from "../../components/designedActivity/activityDetail";

// icon
import SearchIcon from "../../assets/icon/search.png";
import DoublePaging from "../../assets/icon/double_paging.png";
import OnePaging from "../../assets/icon/one_paging.png";
import FilterIcon from "../../assets/icon/filter.png";
import colors from "../../styles/constants/colors";

const Activity = () => {
  const { n, t, cate, loc, status } = useParams();
  const navigate = useNavigate();
  const activityRef = useRef();
  const categoryRef = useRef();
  const statusRef = useRef();
  const locationRef = useRef();

  const [locationPopup, setLocationPopup] = useState(false);
  const [statusPopup, setStatusPopup] = useState(false);
  const [wherePopup, setWherePopup] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState([]);

  const [activity, setActivity] = useState([]);
  const [count, setCount] = useState(0);
  const [Height, setHeight] = useState({
    TableFilter: 0,
    TableHeader: 0,
    TableFooter: 0,
    Layout: 0,
    LayoutHeader: 0,
  });

  const [selected, setSelected] = useState("");

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open) {
      setSelected("");
    }
  }, [open]);

  const [search, setSearch] = useState(t !== "all" ? t : "");

  const [filter, setFilter] = useState({
    category: cate != "all" ? cate : "",
    location: loc != "all" ? loc : "",
    status: status != "all" ? status : "",
  });

  const CategoryList = ["all", "스쿠버다이빙", "프리다이빙", "수영/스노클링", "서핑", "패들보드", "스키", "스노보드"];

  const StatusList = ["all", "활성화", "비노출", "승인 요청"];

  useEffect(() => {
    setTimeout(() => {
      const Layout = document.getElementById("Layout")?.offsetHeight - 36;
      const LayoutHeader = document.getElementById("LayoutHeader")?.offsetHeight + 65;
      const TableFilter = document.getElementById("TableFilter")?.offsetHeight + 19;
      const TableHeader = document.getElementById("TableHeader")?.offsetHeight;
      const TableFooter = document.getElementById("TableFooter")?.offsetHeight;

      setHeight({
        TableFilter: TableFilter,
        TableHeader: TableHeader,
        TableFooter: TableFooter,
        Layout: Layout,
        LayoutHeader: LayoutHeader,
      });
    }, 500);

    const frm = new FormData();

    frm.append("mode", "location");

    axios
      .post("https://ai.semos.kr/semos_admin/v2/activity/ActivityList", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        const Value = data.data;

        setAddressList(Value.list);
      });

    if (loc != "all") {
      let split = loc.split(",");

      setSelectedAddress(split);
    }
  }, []);

  useEffect(() => {
    var token = window.localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", "main");
    frm.append("token", token);
    frm.append("page", n);
    frm.append("location", loc);
    frm.append("text", t);
    frm.append("category", cate);
    frm.append("status", status);

    axios
      .post("https://ai.semos.kr/semos_admin/v2/activity/ActivityList", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var status = Value.status;
        var statusMessage = Value.status_message;

        if (status == "success") {
          setActivity(Value.activity_list);
          setCount(Value.activity_count);
        } else if (status == "waring") {
          alert(statusMessage);
        }
      });
  }, [n, t, cate, loc, status]);

  const PageInput = (num) => {
    var token = localStorage.getItem("token");

    if (token) {
      navigate(
        `/activity/${num}/${search ? search : "all"}/${filter.category ? filter.category : "all"}/${filter.location ? filter.location : "all"}/${
          filter.status ? filter.status : "all"
        }`
      );
    } else {
      alert("권한이 없습니다.");
    }
  };

  const numPages = Math.ceil(count / 12);

  let PageList = [];

  if (n < 11) {
    for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
      PageList.push(i + 1);
    }
  } else {
    if (numPages - 10 < n) {
      var startPage = numPages - 19;
      if (startPage < 1) {
        startPage = 1;
      }

      for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    } else {
      var startPage = n - 9;
      if (startPage < 1) {
        startPage = 1;
      }

      for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    }
  }

  const EnterSearch = (key) => {
    if (key == "Enter") {
      PageInput(1);
    }
  };

  useEffect(() => {
    if (count) {
      PageInput(1);
    }
  }, [filter]);

  const showDetail = (id) => {
    /*setSelected(id);
    setOpen(true);*/
    //navigate( `/activity_detail/${id}` );
    window.open(`/activity_detail/${id}`, "_blank");
  };

  const statusColor = (item) => {
    switch (item) {
      case "활성화":
        return "#3383FF";

      case "비노출":
        return "#ACAFB5";

      case "승인 요청":
        return "#FF6052";
    }
  };

  const FilterSearch = (value, type) => {
    if (type == "sport") {
      if (value == "수영/스노클링") {
        var copy = value;
        copy = "수영_스노클링";

        setFilter((prev) => ({ ...prev, category: copy }));
      } else {
        setFilter((prev) => ({ ...prev, category: value }));
      }

      setLocationPopup(false);
    } else if (type == "status") {
      setFilter((prev) => ({ ...prev, status: value }));

      setStatusPopup(false);
    } else if (type === "location") {
      let copyFilter = value.join();

      setFilter((prev) => ({ ...prev, location: copyFilter }));
    }
  };

  const searchTextInput = (value) => {
    var copy = value;
    // var regExp = /[`~!@#$%^&*()_|+\-=?;:'"<>\{\}\\\\/]/gim;

    // if (regExp.test(copy)) {
    //   alert("특수문자는 입력하실수 없습니다.");
    //   copy = copy.replace(value, "");
    // }
    setSearch(copy);
  };

  const cateClose = (e) => {
    if (locationPopup && (!categoryRef.current || !categoryRef.current.contains(e.target))) {
      setLocationPopup(false);
    } else if (statusPopup && (!statusRef.current || !statusRef.current.contains(e.target))) {
      setStatusPopup(false);
    } else if (wherePopup && (!locationRef.current || !locationRef.current.contains(e.target))) {
      setWherePopup(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", cateClose);

    return () => {
      window.removeEventListener("click", cateClose);
    };
  }, [locationPopup, statusPopup, wherePopup]);

  return (
    <>
      <DesignedMainLayout>
        <DesignedMainHeader title="액티비티 관리" subtitle={`전체 액티비티 : ${count}개 (현재 리스트)`} />

        <Table>
          <TableFilterContainer id="TableFilter" margin={`0 0 19px 0`}>
            <Wrapper jc="flex-start">
              <TableFilterContent margin={`0 24px 0 0`}>
                <Input
                  onKeyUp={(e) => EnterSearch(e.key)}
                  value={search}
                  onChange={(e) => searchTextInput(e.target.value)}
                  width={`350px`}
                  type={`text`}
                  maxLength={40}
                  placeholder="액티비티 명, 센터, 파트너 검색 가능"
                />
                <Icon width={`calc(1rem * (17 / 14))`} cursor={`pointer`} src={SearchIcon} onClick={() => PageInput(1)} />
              </TableFilterContent>

              <TableFilterContent
                ref={activityRef}
                cursor={`pointer`}
                margin={`0 24px 0 0`}
                onClick={(e) => {
                  e.stopPropagation();
                  setLocationPopup(!locationPopup);
                }}
              >
                <Text weight={`500`} width={`100%`} margin={`0 2px 0 0`} color={`#3C3E41`}>
                  {filter.category != "all" && filter.category ? (filter.category == "수영_스노클링" ? "수영/스노클링" : filter.category) : `카테고리 전체`}
                </Text>
                <Icon width={`calc(1rem * (17 / 14))`} src={FilterIcon} />

                <AbsoluteWrapper
                  display={locationPopup ? `inline-flex` : `none`}
                  z={`100`}
                  width={`150px`}
                  bgc={`#ffffff`}
                  bottom={`-8px`}
                  left={`0`}
                  wrap={`wrap`}
                  transform={`translate(0, 100%)`}
                >
                  <Wrapper wrap={`wrap`} width={`100%`} border={`1px solid #DCDCDC`} mh={`300px`} of={`hidden auto`}>
                    <Wrapper display={`inline-block`} padding={`8px`} width={`100%`} ws={`normal`}>
                      {CategoryList.map((item, idx) => (
                        <Wrapper
                          key={`${idx}_location_filter`}
                          onClick={() => FilterSearch(item, "sport")}
                          cursor={`pointer`}
                          padding={`8px`}
                          width={`100%`}
                          ref={categoryRef}
                        >
                          <Text color={filter.category == item ? `#3383FF` : `#818792`} weight={`500`}>
                            {item != "all" ? item : `전체`}
                          </Text>
                        </Wrapper>
                      ))}
                    </Wrapper>
                  </Wrapper>
                </AbsoluteWrapper>
              </TableFilterContent>

              <TableFilterContent
                ref={statusRef}
                cursor={`pointer`}
                margin={`0 24px 0 0`}
                onClick={(e) => {
                  e.stopPropagation();
                  setStatusPopup(!statusPopup);
                }}
              >
                <Text weight={`500`} width={`100%`} margin={`0 2px 0 0`} color={`#3C3E41`}>
                  {filter.status != "all" && filter.status ? filter.status : `상태 전체`}
                </Text>
                <Icon width={`calc(1rem * (17 / 14))`} src={FilterIcon} />

                <AbsoluteWrapper
                  display={statusPopup ? `inline-flex` : `none`}
                  z={`100`}
                  width={`150px`}
                  bgc={`#ffffff`}
                  bottom={`-8px`}
                  left={`0`}
                  wrap={`wrap`}
                  transform={`translate(0, 100%)`}
                >
                  <Wrapper wrap={`wrap`} width={`100%`} border={`1px solid #DCDCDC`} mh={`300px`} of={`hidden auto`}>
                    <Wrapper display={`inline-block`} padding={`8px`} width={`100%`} ws={`normal`}>
                      {StatusList.map((item, idx) => (
                        <Wrapper
                          key={`${idx}_status_filter`}
                          onClick={() => FilterSearch(item, "status")}
                          cursor={`pointer`}
                          padding={`8px`}
                          width={`100%`}
                          ref={statusRef}
                        >
                          <Text color={filter.status == item ? `#3383FF` : `#818792`} weight={`500`}>
                            {item != "all" ? item : `전체`}
                          </Text>
                        </Wrapper>
                      ))}
                    </Wrapper>
                  </Wrapper>
                </AbsoluteWrapper>
              </TableFilterContent>

              <TableFilterContent
                ref={locationRef}
                cursor={`pointer`}
                margin={`0 24px 0 0`}
                onClick={(e) => {
                  e.stopPropagation();
                  setWherePopup((prev) => !prev);
                }}
              >
                <Text weight={`500`} width={`100%`} margin={`0 2px 0 0`} color={`#3C3E41`}>
                  {filter.location != "all" && filter.location.length != 0 ? filter.location.replaceAll("'", "") : `지역 전체`}
                </Text>
                <Icon width={`calc(1rem * (17 / 14))`} src={FilterIcon} />

                <AbsoluteWrapper
                  display={wherePopup ? `inline-flex` : `none`}
                  z={`100`}
                  width={`400px`}
                  bgc={`#ffffff`}
                  bottom={`-8px`}
                  left={`0`}
                  wrap={`wrap`}
                  transform={`translate(0, 100%)`}
                >
                  <Wrapper
                    wrap={`wrap`}
                    width={`100%`}
                    border={`1px solid #DCDCDC`}
                    // mh={`300px`}
                    // of={`hidden auto`}
                  >
                    <Wrapper display={`inline-block`} padding={`8px`} width={`100%`} ws={`normal`} height="210px">
                      {addressList.map((item, idx) => (
                        <Wrapper
                          key={idx}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!selectedAddress.includes(`'${item}'`)) {
                              setSelectedAddress((prev) => [...prev, `'${item}'`]);
                            } else {
                              let copyAddress = [...selectedAddress];
                              copyAddress = copyAddress.filter((x) => x !== `'${item}'`);
                              setSelectedAddress(copyAddress);
                            }
                          }}
                          cursor={`pointer`}
                          padding={`8px`}
                        >
                          <Text color={selectedAddress.includes(`'${item}'`) ? `#3383FF` : `#818792`} weight={`500`}>
                            {`${item == "제주특별자치도" ? "제주" : item}`}
                          </Text>
                        </Wrapper>
                      ))}

                      <AbsoluteWrapper display={`inline-flex`} z={`101`} bgc={`#ffffff`} bottom={`8px`} right={`8px`}>
                        <Wrapper
                          cursor={`pointer`}
                          padding={`9px 20px`}
                          bgc={`#F5F5F5`}
                          radius={`8px`}
                          margin={`0 16px 0 0`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setWherePopup(false);
                            setSelectedAddress([]);
                            setFilter((prev) => ({ ...prev, location: "" }));
                          }}
                        >
                          <Text className="f14" color={`#818792`} weight={`500`}>
                            {`초기화`}
                          </Text>
                        </Wrapper>

                        <Wrapper
                          cursor={`pointer`}
                          padding={`9px 20px`}
                          bgc={`#0E6DFF`}
                          radius={`8px`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setWherePopup(false);
                            FilterSearch(selectedAddress, `location`);
                          }}
                        >
                          <Text className="f14" color={`#ffffff`} weight={`500`}>
                            {`적용`}
                          </Text>
                        </Wrapper>
                      </AbsoluteWrapper>
                    </Wrapper>
                  </Wrapper>
                </AbsoluteWrapper>
              </TableFilterContent>
            </Wrapper>

            <SaveButton
              className="f12"
              onClick={() => {
                showDetail("new");
              }}
            >
              액티비티 추가하기
            </SaveButton>
          </TableFilterContainer>
          <TableContent height={Height.Layout - (Height.LayoutHeader + Height.TableFilter + Height.TableFooter)} contentHeight={Height.TableHeader}>
            <section id="TableHeader">
              <TableColumn width={`10`} weight={`500`} bl={`1px solid transparent`} br={`1px solid ${colors.Gr02}`}>
                액티비티 ID
              </TableColumn>

              <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                액티비티 명
              </TableColumn>

              <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                센터
              </TableColumn>

              <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                파트너 명
              </TableColumn>

              <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                유형
              </TableColumn>

              <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                액티비티 카테고리
              </TableColumn>

              <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                가격
              </TableColumn>

              <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                장소
              </TableColumn>

              <TableColumn width={`10`} weight={`500`} br={`1px solid transparent`}>
                상태
              </TableColumn>
            </section>

            <section>
              {activity
                ? activity.map((item, idx) => {
                    var sportArr = item.sport;
                    var sportext = "";

                    if (sportArr.length) {
                      if (sportArr.length == 1) {
                        sportext = sportArr[0].sport;
                      } else {
                        for (let i = 0; i < sportArr.length; i++) {
                          if (i) {
                            sportext = sportext + " | " + sportArr[i].sport;
                          } else {
                            sportext = sportArr[0].sport;
                          }
                        }
                      }
                    }
                    return (
                      <Link to={`/activity_detail/${item.activity_id}`}>
                        <TableWrapper
                          cursor={`pointer`}
                          key={`${idx}_reservation`}
                          bgc={selected == item.activity_id ? "#F2F7FF" : "white"}
                          bl={`1px solid transparent`}
                          br={`1px solid transparent`}
                          bb={`1px solid ${colors.Gr02}`}
                          bt={`1px solid transparent`}
                          width={`100%`}
                        >
                          <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                            {`${item.activity_id}`}
                          </TableColumn>

                          <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                            {`${item.name}`}
                          </TableColumn>

                          <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                            {`${item.center}`}
                          </TableColumn>

                          <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                            {`${item.partner}`}
                          </TableColumn>

                          <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                            {`${item.type}`}
                          </TableColumn>

                          <TableColumn
                            width={`10`}
                            weight={`500`}
                            // padding={"8px 16px 8px 10px"}
                            br={`1px solid ${colors.Gr02}`}
                          >
                            {`${sportext}`}
                            {/* {`${item.sport.length ? item.sport[0].sport : ""}`} */}
                          </TableColumn>

                          <TableColumn
                            width={`10`}
                            weight={`500`}
                            // padding={"8px 16px 8px 10px"}
                            br={`1px solid ${colors.Gr02}`}
                          >
                            {item.price ? `${item.price}원` : ""}
                          </TableColumn>

                          <TableColumn
                            width={`10`}
                            weight={`500`}
                            // padding={"8px 16px 8px 10px"}
                            br={`1px solid ${colors.Gr02}`}
                          >
                            {`${item.location}`}
                          </TableColumn>

                          <TableColumn
                            width={`10`}
                            weight={`500`}
                            // padding={"8px 16px 8px 10px"}
                            br={`1px solid transparent`}
                          >
                            {/* {statusConvert(item.status)} */}
                            <Text color={statusColor(item.status)}>{item.status}</Text>
                          </TableColumn>
                        </TableWrapper>
                      </Link>
                    );
                  })
                : ""}

              <TableFooter id="TableFooter">
                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  src={DoublePaging}
                  onClick={() => {
                    if (Number(n) < 11) {
                      PageInput(1);
                    } else {
                      PageInput(Number(n) - 10);
                    }
                  }}
                />
                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  src={OnePaging}
                  onClick={() => {
                    if (Number(n) > 1) {
                      PageInput(n - 1);
                    } else {
                      window.alert("첫번째 페이지 입니다.");
                    }
                  }}
                />

                {PageList.length
                  ? PageList.map((item, idx) => (
                      <TableFooterPaging
                        key={`paging_number_${idx}`}
                        margin={`0 16px 0 0`}
                        color={`${n == item ? "#3383FF" : "#ACAFB5"}`}
                        onClick={() => PageInput(item)}
                      >
                        {item}
                      </TableFooterPaging>
                    ))
                  : ""}

                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  transform={`rotate(180deg)`}
                  src={OnePaging}
                  onClick={() => {
                    if (n < numPages) {
                      PageInput(Number(n) + 1);
                    } else {
                      window.alert("마지막 페이지 입니다.");
                    }
                  }}
                />
                <Icon
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  transform={`rotate(180deg)`}
                  src={DoublePaging}
                  onClick={() => {
                    if (Number(n) + 10 > numPages) {
                      PageInput(numPages);
                    } else {
                      PageInput(Number(n) + 10);
                    }
                  }}
                />
              </TableFooter>
            </section>
          </TableContent>
        </Table>
      </DesignedMainLayout>

      {/* <DrawerModal open={open} setOpen={setOpen}>
        <ActivityDetail open={open} selected={selected} />
      </DrawerModal> */}
    </>
  );
};

export default Activity;

const Input = styled.input`
  width: ${({ width }) => `${width}`};
  display: inline-block;
`;

const SaveButton = styled.div`
  background-color: #0e6dff;
  padding: 12px 20px;
  font-weight: 700;
  color: #ffffff;
  border-radius: 8px;
  line-height: 142%;
  cursor: pointer;
`;
