import { useEffect, useState } from "react";

const usePagination = (targetList, defaultLimit) => {
  const [list, setList] = useState(targetList);
  const [limit, setLimit] = useState(defaultLimit);
  const [currentPage, setCurrentPage] = useState(1);
  const offset = (currentPage - 1) * limit;

  useEffect(() => {
    // TODO: dataFetching
  }, []);

  return {
    offset,
    list,
    limit,
    setLimit,
    currentPage,
    setCurrentPage,
  };
};

export default usePagination;
