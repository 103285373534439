import styled from "styled-components";
import colors from "../styles/constants/colors";

export const TableFilterContainer = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin: ${({ margin }) => margin};
`;

export const TableFilterContent = styled.div`
  padding: 8px 16px;
  display: inline-flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dcdcdc;
  background-color: #ffffff;
  margin: ${({ margin }) => margin};
  cursor: ${({ cursor }) => cursor};
  position: relative;
  width: ${({ width }) => `${width}`};
`;

export const Wrapper = styled.div`
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  flex-wrap: ${({ wrap }) => `${wrap}`};
  display: ${({ display }) => `${display ? display : `inline-flex`}`};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  justify-content: ${({ jc }) => jc};
  background-color: ${({ bgc }) => `${bgc}`};
  border: ${({ border }) => `${border}`};
  border-top: ${({ bt }) => `${bt}`};
  border-bottom: ${({ bb }) => `${bb}`};
  border-right: ${({ br }) => `${br}`};
  border-left: ${({ bl }) => `${bl}`};
  cursor: ${({ cursor }) => `${cursor}`};
  padding: ${({ padding }) => `${padding}`};
  margin: ${({ margin }) => margin};
  border-radius: ${({ radius }) => `${radius}`};
  position: ${({ position }) => `${position}`};
  gap: ${({ gap }) => gap};
  ${({ ws }) => ws && `white-space :${ws};`}
  ${({ of }) => of && `overflow :${of};`}
    ${({ mh }) => mh && `max-height :${mh};`}
    ${({ mw }) => mw && `max-width :${mw};`}
    ${({ transition }) => transition && `transition :${transition};`};
`;

export const Label = styled.label`
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  flex-wrap: ${({ wrap }) => `${wrap}`};
  display: ${({ display }) => `${display ? display : `inline-flex`}`};
  align-items: center;
  justify-content: ${({ jc }) => jc};
  background-color: ${({ bgc }) => `${bgc}`};
  border-top: ${({ bt }) => `${bt}`};
  border-bottom: ${({ bb }) => `${bb}`};
  border-right: ${({ br }) => `${br}`};
  border-left: ${({ bl }) => `${bl}`};
  border: ${({ border }) => `${border}`};
  cursor: ${({ cursor }) => `${cursor}`};
  padding: ${({ padding }) => `${padding}`};
  margin: ${({ margin }) => margin};
  border-radius: ${({ radius }) => `${radius}`};
  position: ${({ position }) => `${position}`};
  ${({ of }) => of && `overflow :${of};`}
  ${({ mh }) => mh && `max-height :${mh};`}
  ${({ transition }) => transition && `transition :${transition};`}

  input {
    width: 0;
    height: 0;
    display: none;
  }

  img {
    display: inline-block;
  }
`;

export const WrapperRow = styled.div`
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  flex-wrap: ${({ wrap }) => `${wrap}`};
  display: ${({ display }) => `${display ? display : `inline-flex`}`};
  align-items: ${({ ai }) => ai};
  justify-content: ${({ jc }) => jc};
  background-color: ${({ bgc }) => `${bgc}`};
  border-top: ${({ bt }) => `${bt}`};
  border-bottom: ${({ bb }) => `${bb}`};
  border-right: ${({ br }) => `${br}`};
  border-left: ${({ bl }) => `${bl}`};
  border: ${({ border }) => `${border}`};
  cursor: ${({ cursor }) => `${cursor}`};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => `${padding}`};
  border-radius: ${({ radius }) => `${radius}`};
  position: ${({ position }) => `${position}`};
  overflow: ${({ of }) => `${of}`};
  flex-direction: row;
  gap: ${({ gap }) => `${gap}`};
`;

export const WrapperColmun = styled.div`
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  flex-wrap: ${({ wrap }) => `${wrap}`};
  display: ${({ display }) => `${display ? display : `inline-flex`}`};
  align-items: ${({ ai }) => ai};
  justify-content: ${({ jc }) => jc};
  background-color: ${({ bgc }) => `${bgc}`};
  border-top: ${({ bt }) => `${bt}`};
  border-bottom: ${({ bb }) => `${bb}`};
  border-right: ${({ br }) => `${br}`};
  border-left: ${({ bl }) => `${bl}`};
  border: ${({ border }) => `${border}`};
  cursor: ${({ cursor }) => `${cursor}`};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => `${padding}`};
  border-radius: ${({ radius }) => `${radius}`};
  position: ${({ position }) => `${position}`};
  overflow: ${({ of }) => `${of}`};
  flex-direction: column;
  gap: ${({ gap }) => `${gap}`};
  :hover {
    background-color: ${({ hvBgc }) => `${hvBgc}`};
    border: ${({ hvBorder }) => `${hvBorder}`};
  }
`;
export const WrapperColumn = styled.div`
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  flex-wrap: ${({ wrap }) => `${wrap}`};
  display: ${({ display }) => `${display ? display : `inline-flex`}`};
  align-items: ${({ ai }) => ai};
  justify-content: ${({ jc }) => jc};
  background-color: ${({ bgc }) => `${bgc}`};
  border-top: ${({ bt }) => `${bt}`};
  border-bottom: ${({ bb }) => `${bb}`};
  border-right: ${({ br }) => `${br}`};
  border-left: ${({ bl }) => `${bl}`};
  border: ${({ border }) => `${border}`};
  cursor: ${({ cursor }) => `${cursor}`};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => `${padding}`};
  border-radius: ${({ radius }) => `${radius}`};
  position: ${({ position }) => `${position}`};
  overflow: ${({ of }) => `${of}`};
  flex-direction: column;
  gap: ${({ gap }) => `${gap}`};
  :hover {
    background-color: ${({ hvBgc }) => `${hvBgc}`};
    border: ${({ hvBorder }) => `${hvBorder}`};
  }
`;

export const AbsoluteWrapper = styled.div`
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  flex-wrap: ${({ wrap }) => `${wrap}`};
  flex-direction: ${({ fd }) => `${fd}`};
  display: ${({ display }) => `${display ? display : `inline-flex`}`};
  align-items: ${({ ai }) => (ai ? ai : `center`)};
  justify-content: ${({ jc }) => jc};
  background-color: ${({ bgc }) => `${bgc}`};
  border-bottom: ${({ bb }) => `${bb}`};
  border-right: ${({ br }) => `${br}`};
  border-left: ${({ bl }) => `${bl}`};
  cursor: ${({ cursor }) => `${cursor}`};
  padding: ${({ padding }) => `${padding}`};
  border: ${({ border }) => `${border}`};
  border-radius: ${({ radius }) => `${radius}`};
  position: absolute;
  top: ${({ top }) => `${top}`};
  bottom: ${({ bottom }) => `${bottom}`};
  right: ${({ right }) => `${right}`};
  left: ${({ left }) => `${left}`};
  transform: ${({ transform }) => `${transform}`};
  z-index: ${({ z }) => `${z}`};
  ${({ of }) => of && `overflow :${of};`}
`;

export const FixedWrapper = styled.div`
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  display: ${({ display }) => `${display ? display : `inline-flex`}`};
  align-items: center;
  justify-content: ${({ jc }) => jc};
  background-color: ${({ bgc }) => `${bgc}`};
  border-bottom: ${({ bb }) => `${bb}`};
  border-right: ${({ br }) => `${br}`};
  border-left: ${({ bl }) => `${bl}`};
  cursor: ${({ cursor }) => `${cursor}`};
  padding: ${({ padding }) => `${padding}`};
  border: ${({ border }) => `${border}`};
  border-radius: ${({ radius }) => `${radius}`};
  position: fixed;
  top: ${({ top }) => `${top}`};
  bottom: ${({ bottom }) => `${bottom}`};
  right: ${({ right }) => `${right}`};
  left: ${({ left }) => `${left}`};
  transform: ${({ transform }) => `${transform}`};
  z-index: ${({ z }) => `${z}`};
`;

export const Icon = styled.img`
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  display: ${({ display }) => `${display ? display : `inline-block`}`};
  cursor: ${({ cursor }) => cursor};
  transform: ${({ transform }) => `${transform}`};
  transition: ${({ transition }) => `${transition}`};
  margin: ${({ margin }) => margin};
  border-radius: ${({ radius }) => radius};
`;

export const Text = styled.span`
  width: ${({ width }) => `${width}`};
  display: inline-flex;
  align-items: ${({ ai }) => (ai ? ai : `center`)};
  line-height: ${({ lh }) => (lh ? lh : `142%`)};
  text-align: ${({ ta }) => `${ta}`};
  justify-content: ${({ jc }) => jc};
  margin: ${({ margin }) => margin};
  color: ${({ color }) => `${color}`};
  font-weight: ${({ weight }) => `${weight}`};
  font-size: ${({ size }) => `${size}`};
  flex-wrap: ${({ wrap }) => `${wrap}`};
  white-space: ${({ ws }) => `${ws}`};
  ${({ ellipsis }) =>
    `${
      ellipsis &&
      `
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;`
    }`};
`;

export const TextArea = styled.textarea`
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  overflow: hidden auto;
  display: inline-flex;
  resize: none;
  line-height: ${({ lh }) => (lh ? lh : `142%`)};
  justify-content: ${({ jc }) => jc};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  color: ${({ color }) => `${color}`};
  font-weight: ${({ weight }) => `${weight}`};
  flex-wrap: ${({ wrap }) => `${wrap}`};
  white-space: ${({ ws }) => `${ws}`};
`;

export const PaddingText = styled.span`
  width: ${({ width }) => `${width}`};
  display: inline-flex;
  align-items: ${({ ai }) => (ai ? ai : `center`)};
  line-height: ${({ lh }) => (lh ? lh : `142%`)};
  text-align: ${({ ta }) => `${ta}`};
  justify-content: ${({ jc }) => jc};
  margin: ${({ margin }) => margin};
  color: ${({ color }) => `${color}`};
  font-weight: ${({ weight }) => `${weight}`};
  flex-wrap: ${({ wrap }) => `${wrap}`};
  padding: ${({ padding }) => `${padding}`};
  border-radius: ${({ radius }) => `${radius}`};
  background-color: ${({ bgc }) => `${bgc}`};
`;

export const TableContent = styled.div`
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  height: ${({ height }) => `${height}px`};

  section:first-child {
    width: 100%;
    display: inline-flex;
    ${({ border }) => `${border ? border : `1px solid #e6e6e6`}`};
    ${({ bgc }) => `background-color : ${bgc};`}
    flex-wrap : ${({ wrap }) => `${wrap}`};
  }

  section:nth-child(2) {
    width: 100%;
    display: inline-block;
    overflow: hidden auto;
    height: ${({ height, contentHeight }) => `${height - contentHeight}px`};
  }
`;

export const TableColumn = styled.div`
  width: ${({ width }) => `calc( 1% * ${width} )`};
  display: ${({ display }) => `${display ? display : `inline-block`}`};
  text-align: ${({ ta }) => `${ta ? ta : `center`}`};
  padding: ${({ padding }) => `${padding ? padding : `13px 16px`}`};
  white-space: nowrap;
  font-weight: ${({ weight }) => `${weight}`};
  color: ${({ color }) => (color ? color : "#3C3E41")};
  justify-content: ${({ jc }) => `${jc}`};
  text-overflow: ellipsis;
  overflow: hidden;
  border-right: ${({ br }) => `${br}`};
  border-left: ${({ bl }) => `${bl}`};

  /* &:hover {
    border-right: 1px solid #0e6dff;
  } */
`;

export const TableFooter = styled.div`
  padding: 20px 0;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const TableFooterPaging = styled.span`
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0 6.5px;
  width: 2.2rem;
  cursor: pointer;
  margin: ${({ margin }) => margin};
  color: ${({ color }) => `${color}`};
  border-bottom: ${({ color }) => color == "#3383FF" && `1px solid #3383FF`};
`;

export const TableWrapper = styled.div`
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  flex-wrap: ${({ wrap }) => `${wrap}`};
  display: ${({ display }) => `${display ? display : `inline-flex`}`};
  align-items: center;
  justify-content: ${({ jc }) => jc};
  background-color: ${({ bgc }) => `${bgc}`};
  border-top: ${({ bt }) => `${bt}`};
  border-bottom: ${({ bb }) => `${bb}`};
  border-right: ${({ br }) => `${br}`};
  border-left: ${({ bl }) => `${bl}`};
  border: ${({ border }) => `${border}`};
  cursor: ${({ cursor }) => `${cursor}`};
  padding: ${({ padding }) => `${padding}`};
  border-radius: ${({ radius }) => `${radius}`};
  position: ${({ position }) => `${position}`};

  &:hover {
    border: 1px solid #0e6dff;
  }
`;

export const TableWrapper2 = styled.div`
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  flex-wrap: ${({ wrap }) => `${wrap}`};
  display: ${({ display }) => `${display ? display : `inline-flex`}`};
  align-items: center;
  justify-content: ${({ jc }) => jc};
  background-color: ${({ bgc }) => `${bgc}`};
  border-top: ${({ bt }) => `${bt}`};
  border-bottom: ${({ bb }) => `${bb}`};
  border-right: ${({ br }) => `${br}`};
  border-left: ${({ bl }) => `${bl}`};
  border: ${({ border }) => `${border}`};
  cursor: ${({ cursor }) => `${cursor}`};
  padding: ${({ padding }) => `${padding}`};
  border-radius: ${({ radius }) => `${radius}`};
  position: ${({ position }) => `${position}`};

  .Func {
    &:hover {
      border: 1px solid #0e6dff;
    }
  }

  .Copy {
    &:hover {
      border: 1px solid red;
    }
  }

  .Open {
    &:hover {
      border: 1px solid green;
    }
  }

`;

export const DetailHeader = styled.div`
  width: 100%;
  justify-content: space-between;
  display: inline-flex;
  align-items: center;
  padding: ${({ padding }) => `${padding}`};
  margin: ${({ margin }) => `${margin}`};
  font-weight: ${({ ft }) => (ft ? ft : "400")};
`;

export const Devive = styled.div`
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  display: inline-block;
  background-color: ${({ bgc }) => `${bgc}`};
  margin: ${({ margin }) => `${margin}`};
`;

export const Radio = styled.div`
  width: ${({ width }) => `${width}px`};
  height: ${({ width }) => `${width}px`};
  display: inline-flex;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  margin: ${({ margin }) => `${margin}`};
  border: ${({ border }) => `${border}`};
  border-radius: 50%;
  position: relative;

  div {
    width: ${({ width, padding }) => `${width - padding * 2}px`};
    height: ${({ width, padding }) => `${width - padding * 2}px`};
    background-color: ${({ bgc }) => `${bgc}`};
    border-radius: 50%;
    /*position : absolute;
    top : ${({ padding }) => `${padding}px`};
    left : ${({ padding }) => `${padding}px`};
    transform : ${({ padding }) => `translate(-${padding / 2}px, -${padding / 2}px)`};*/
  }
`;

export const HoverButton = styled.div`
  border: 1px solid ${({ active }) => (active ? colors.Bl05 : colors.Gr02)};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  background-color: ${({ bgc, active, bgch }) => (active ? bgch : bgc)};
  color: ${({ color, active, colorh }) => (active ? colorh : color)};
  border-radius: ${({ radius }) => radius};
  cursor: pointer;

  &:hover {
    transition: all 0.3s ease;
    border: 1px solid ${colors.Bl05};
    background-color: ${({ bgch }) => bgch};
    color: ${({ colorh }) => colorh};
  }
`;

export const Input = styled.input`
  border: ${({ border }) => (border ? `${border}` : "none")};
  font-size: ${({ size }) => (size ? `calc(1rem * (${size / 14}))` : "1rem")};
  padding: ${({ padding }) => `${padding}`};
  color: ${({ color }) => `${color}`};
  font-weight: ${({ weight }) => `${weight}`};
  white-space: ${({ ws }) => `${ws}`};
  letter-spacing: ${({ ls }) => `${ls}`};
  width: ${({ width }) => `${width}`};
  text-align: ${({ ta }) => (ta ? ta : "start")};
  ::placeholder {
    color: ${({ phColor }) => `${phColor}`};
  }
`;
