import { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/logo/logo-w.svg";
import { useAuth } from "../context/auth";
import base from "../styles/constants/base";
import colors from "../styles/constants/colors";
import zIndex from "../styles/constants/z-index";
import flexbox from "../styles/func/flexbox";

const SHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 48px;
  padding: 10px 36px;
  border-bottom: 1px solid #E6E6E6;
  background-color: ${({ color }) => color};
  z-index: ${zIndex.header};
  ${flexbox("space-between")}

  p {
    cursor: pointer;
  }
`;

const LogoutButton = styled.div`
  background-color: #ffffff;
  padding: 0 15px;
  cursor : pointer;
  height: 28px;
  color : #FF786B;
  display : inline-flex;
  justify-contents : center;
  align-items : center;
  border: 1px solid #FF786B;
  margin-left : 22px;
  font-size : 12px;
  font-weight : 500;
`;

const UserInfo = styled.div`
  display : inline-flex;
  flex-wrap : nowrap;
  align-items : center;
`
const UserInfoEmail = styled.span`
  font-size : 14px;
  font-weight : 500;
  display : inline-flex;
  color: #818792;
`

const Header = () => {
  const { pathname } = useLocation();

  // 로그인시에는 이메일이 있음
  const USER_EMAIL = window.localStorage.getItem('email') ? window.localStorage.getItem('email') : "";

  const logoutHandler = () => { 
    window.localStorage.clear();
    window.location.replace('/')
  };

  return (
    <SHeader
      color={pathname === "/" ? `${colors.lightBlack}` : `${colors.white}`}
    >
      <img src={logo} alt="세모스 로고" />

      {pathname !== "/" && (
        <UserInfo>
          <UserInfoEmail>{USER_EMAIL && USER_EMAIL}</UserInfoEmail>

          <LogoutButton type="button" onClick={logoutHandler}>
            로그아웃
          </LogoutButton>
        </UserInfo>
      )}




    </SHeader>
  );
};

export default Header;
