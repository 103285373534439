import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";

// components
import {
  TableFilterContent,
  Wrapper,
  DetailHeader,
  Icon,
  Text,
  WrapperRow,
  Devive,
  Radio,
  TableContent,
  TableColumn,
  TableWrapper,
  Label,
} from "../../layouts/layoutStorage";
import { CXMessageTypeValue } from "../../layouts/textStorage";
import DropDown from "../../components/designedComponents/dropDown";
import DatePicker from "../../components/designedComponents/datePicker";
import TimePicker from "../../components/designedComponents/timePicker";
import PartnerPicker from "../../components/designedComponents/partnerPicker";
import ImageCrop from "../../components/designedComponents/imageCrop";

// icon
import ReservationCheck from "../../assets/icon/reservation_check.png";
import ReservationConfirm from "../../assets/icon/reservation_confirm.png";
import ReservationCancel from "../../assets/icon/reservation_cancel.png";
import ReservationClear from "../../assets/icon/reservation_clear.png";
import ReservationDone from "../../assets/icon/reservation_done.png";
import SearchIcon from "../../assets/icon/search.png";
import PaymentSport from "../../assets/icon/payment_sport.png";
import PaymentLocation from "../../assets/icon/payment_location.png";
import PaymentPeople from "../../assets/icon/payment_people.png";
import PaymentCalendar from "../../assets/icon/payment_calendar.png";
import PaymentBell from "../../assets/icon/payment_bell.png";
import PaymentArrow from "../../assets/icon/payment_arrow.png";
import EmptyImage from "../../assets/icon/emptyImage.png";
import colors from "../../styles/constants/colors";

const ManagementDetail = () => {
  const { n } = useParams();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(true);
  const [noticeInfo, setNoticeInfo] = useState(true);
  const [ticketInfo, setTicketInfo] = useState(true);
  const [amountInfo, setAmountInfo] = useState(true);
  const [datePicker, setDatePicker] = useState(false);
  const [timePicker, setTimePicker] = useState(false);
  const [partnerPicker, setPartnerPicker] = useState(false);
  const [partnerText, setPartnerText] = useState("");
  const [partnerList, setPartnerList] = useState([]);
  const [reservationInfo, setReservationInfo] = useState({});
  const [userSpec, setUserSpec] = useState("");
  const [ticketSpec, setTicketSpec] = useState("");
  const [cropImage, setCropImage] = useState({
    width: 1,
    hegiht: 1,
    type: "",
    number: "",
    image: "",
    status: false,
    scale: false,
  });

  useEffect(() => {
    managementDetailOpen();
  }, [n]);

  const StatusList = {
    used_c: "예약확정",
    check: "결제완료",
    done: "액티비티 완료",
    cancel: "환불/취소",
    clear: "정산 완료",
  };

  const managementDetailOpen = () => {
    var token = window.localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", "specific_new");
    frm.append("token", token);
    frm.append("number", n);

    axios
      .post("https://ai.semos.kr/semos_admin/payment/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var status = Value.status;
        var statusMessage = Value.status_message;
        var paymentData = Value.payment_data;

        if (status == "success") {
          setReservationInfo(paymentData);
          setUserSpec(paymentData.user_spec);
          setTicketSpec(paymentData.ticket);
        } else if (status == "waring") {
          window.alert(statusMessage);
        }
      });
  };

  const DateSelect = (d, month, next) => {
    if (month) {
      let Year = reservationInfo.date_y;
      if (next) {
        var Mon = Number(reservationInfo.date_m);
      } else {
        var Mon = Number(reservationInfo.date_m) - 2;
      }

      let Day = Number(1);

      let NewDate = new Date(Year, Mon, Day);

      let NewYear = NewDate.getFullYear();
      let NewMonth = NewDate.getMonth() + 1;
      let NewDay = NewDate.getDate();

      if (NewMonth < 10) {
        NewMonth = `0${NewMonth}`;
      }

      if (NewDay < 10) {
        NewDay = `0${NewDay}`;
      }

      setTimeout(() => {
        setDatePicker(true);
      }, 10);

      setReservationInfo({
        ...reservationInfo,
        date_y: NewYear,
        date_m: NewMonth,
        date_d: NewDay,
      });
    } else {
      if (d < 10) {
        d = `0${d}`;
      }

      setReservationInfo({ ...reservationInfo, date_d: d });
    }
  };

  const TimeSelect = (time) => {
    setReservationInfo({ ...reservationInfo, date_t: time });
    setTimePicker(false);
  };

  const setImageFromFile = ({ file, setImageUrl }) => {
    let reader = new FileReader();
    reader.onload = function () {
      setImageUrl({ result: reader.result });
    };

    reader.readAsDataURL(file);
  };

  const ImgListChange = (value, type, number) => {
    setCropImage({
      width: 1,
      hegiht: 1,
      type: type,
      number: number,
      image: value,
      status: true,
      scale: false,
    });
  };

  const TimeCheck = (time) => {
    if (time) {
      const time_split = time.split(":");

      var time_h = time_split[0];
      const time_m = time_split[1];
      var time_t = "오전";

      if (time_h > 11) {
        time_t = "오후";
        if (Number(time_h) != 12) {
          time_h = Number(time_h) - 12;

          if (time_h < 10) {
            time_h = `0${time_h}`;
          }
        }
      }

      return `${time_t} ${time_h}:${time_m}`;
    }
  };

  const PartnerSearch = (text) => {
    setPartnerText(text);

    if (text) {
      var token = window.localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "partner_search2");
      frm.append("token", token);
      frm.append("text", text);

      axios
        .post("https://ai.semos.kr/semos_admin/partner/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            setPartnerList(Value.user_id_list);

            if (!partnerPicker) {
              setPartnerPicker(true);
            }
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    } else {
      setPartnerList([]);
      setPartnerPicker(false);
    }
  };

  const EnterSearch = (key) => {
    if (key == "Enter") {
      PartnerSearch(partnerText);
    }
  };

  const ChangeUserSpec = (number, type, value) => {
    let UserSpecOG = [...userSpec];
    let UserSpecData = UserSpecOG[number];
    UserSpecData[type] = value;
    UserSpecData["change"] = "yes";

    UserSpecOG[number] = UserSpecData;

    setUserSpec(UserSpecOG);
  };

  const PaymentSave = () => {
    var token = window.localStorage.getItem("token");

    axios
      .post(
        "https://ai.semos.kr/semos_admin/payment/data",
        {
          mode: "payment_save",
          token: token,
          user_spec: userSpec,
          ticket_spec: ticketSpec,
          info: reservationInfo,
        },
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var statusMessage = Value.status_message;

        if (Status == "success") {
          alert(statusMessage);
          window.location.reload();
        } else if (Status == "waring") {
          alert(statusMessage);
        }
      });
  };

  return (
    <>
      <Wrapper
        bgc={colors.White}
        of={`hidden auto`}
        margin="10px 0 0 0"
        display={`inline-block`}
        width={`100%`}
        mw={`1000px`}
        mh={`calc(100vh - 72px)`}
        padding={`10px 0`}
      >
        <Wrapper
          of={`hidden auto`}
          display={`inline-block`}
          width={`100%`}
          padding={`40px 0`}
        >
          <DetailHeader padding={`0 36px`} margin={`0 0 28px 0`}>
            <Wrapper jc={`flex-start`}>
              {/* {StatusComponent(
              StatusList[`${reservationInfo && reservationInfo.status}`]
            )} */}
              {/* {tag} */}
            </Wrapper>
          </DetailHeader>

          <DetailHeader padding={`0 36px`} margin={`0 0 30px 0`}>
            <Wrapper>
              <Text wrap={`wrap`} color={colors.Gr03} weight={`500`}>
                파트너 :&nbsp;
              </Text>
              <Text wrap={`wrap`} color={colors.Gr05} weight={`500`}>{`${
                reservationInfo && reservationInfo.partner
              }`}</Text>
            </Wrapper>
          </DetailHeader>

          <Wrapper padding={`0 36px`} width={`100%`} jc={`space-between`}>
            <Wrapper
              radius={`8px`}
              bgc={colors.Gr01}
              width={`200px`}
              height={`125px`}
            >
              <Icon
                src={`${reservationInfo && reservationInfo.service_thumbnail}`}
                width={`200px`}
                radius="8px"
              />
            </Wrapper>

            <WrapperRow
              ai={`stretch`}
              jc={`space-between`}
              width={`calc(100% - 220px)`}
              height={`125px`}
              wrap={`wrap`}
            >
              <Text
                ai={`flex-start`}
                color={colors.Gr05}
                weight={`500`}
                className={`f16`}
              >{`${reservationInfo && reservationInfo.service}`}</Text>

              <Wrapper width={`100%`} wrap={`wrap`}>
                <Wrapper width={`100%`}>
                  <Wrapper width={`120px`}>
                    <Icon
                      margin={`0 4px 0 0`}
                      src={PaymentLocation}
                      width={`16px`}
                    />
                    <Text color={colors.Gr04} weight={`500`} className={`f12`}>
                      예약 장소{" "}
                    </Text>
                  </Wrapper>

                  <Wrapper width={`calc(100% - 120px)`}>
                    <Text
                      color={colors.Gr05}
                      weight={`500`}
                      className={`f12`}
                    >{`${
                      reservationInfo && reservationInfo.service_location
                    }`}</Text>
                  </Wrapper>
                </Wrapper>

                <Wrapper width={`100%`}>
                  <Wrapper width={`120px`}>
                    <Icon
                      margin={`0 4px 0 0`}
                      src={PaymentSport}
                      width={`16px`}
                    />
                    <Text color={colors.Bl07} weight={`500`} className={`f12`}>
                      스포츠{" "}
                    </Text>
                    <Text color={colors.Gr04} weight={`500`} className={`f12`}>
                      &nbsp;| 유형{" "}
                    </Text>
                  </Wrapper>

                  <Wrapper width={`calc(100% - 120px)`}>
                    <Text
                      color={colors.Bl07}
                      weight={`500`}
                      className={`f12`}
                    >{`${reservationInfo && reservationInfo.sport}`}</Text>
                    <Text color={colors.Gr05} weight={`500`} className={`f12`}>
                      &nbsp;|{" "}
                      {`${reservationInfo && reservationInfo.service_duration}`}{" "}
                    </Text>
                    <Text color={colors.Gr05} weight={`500`} className={`f12`}>
                      &nbsp;|{" "}
                      {`${reservationInfo && reservationInfo.service_type}`}{" "}
                    </Text>
                  </Wrapper>
                </Wrapper>

                <Wrapper width={`100%`}>
                  <Wrapper width={`120px`}>
                    <Icon
                      margin={`0 4px 0 0`}
                      src={PaymentPeople}
                      width={`16px`}
                    />
                    <Text color={colors.Gr04} weight={`500`} className={`f12`}>
                      예약 인원{" "}
                    </Text>
                  </Wrapper>

                  <Wrapper width={`calc(100% - 120px)`}>
                    <Text
                      color={colors.Gr05}
                      weight={`500`}
                      className={`f12`}
                    >{`${reservationInfo && reservationInfo.people}인`}</Text>
                  </Wrapper>
                </Wrapper>
              </Wrapper>
            </WrapperRow>
          </Wrapper>

          <Devive
            width={`100%`}
            height={`1px`}
            bgc={colors.Gr02}
            margin={`30px 0 36px 0`}
          />

          <Wrapper
            wrap={`wrap`}
            padding={`0 36px`}
            width={`100%`}
            jc={`space-between`}
          >
            <Text
              margin={`0 0 16px 0`}
              color={colors.Gr05}
              width={`100%`}
              weight={`700`}
              className={`f16`}
            >
              예약 일정
            </Text>

            <Wrapper width={`100%`} jc={`flex-start`}>
              <TableFilterContent margin={`0`}>
                <Text
                  color={colors.Gr05}
                  width={`calc(1rem * (120 / 14))`}
                  weight={`500`}
                  className={`f13`}
                >
                  {`${reservationInfo && reservationInfo.date_y}년 ${
                    reservationInfo && reservationInfo.date_m
                  }월 ${reservationInfo && reservationInfo.date_d}일`}
                </Text>
                <Icon
                  margin={`0 0 0 6px`}
                  width={`calc(1rem * (20 / 14))`}
                  src={PaymentCalendar}
                />
              </TableFilterContent>

              <TableFilterContent margin={`0 0 0 10px`}>
                <Text
                  margin={`0 6px 0 0`}
                  color={colors.Gr05}
                  width={`100%`}
                  weight={`500`}
                  className={`f13`}
                >
                  {TimeCheck(reservationInfo && reservationInfo.date_t)}
                </Text>

                <Icon width={`calc(1rem * (20 / 14))`} src={PaymentBell} />
              </TableFilterContent>
            </Wrapper>
          </Wrapper>

          <Devive
            width={`100%`}
            height={`1px`}
            bgc={colors.Gr02}
            margin={`36px 0 36px 0`}
          />

          <Wrapper wrap={`wrap`} padding={`0 36px`} width={`100%`}>
            <Wrapper margin={`0 0 16px 0`} width={`100%`} jc={`space-between`}>
              <Text color={colors.Gr05} weight={`700`} className={`f16`}>
                액티비티 이용자 정보
              </Text>

              <Icon
                width={`calc(1rem * (18 / 14))`}
                src={PaymentArrow}
                cursor={`pointer`}
                transform={userInfo ? `rotate(0)` : `rotate(180deg)`}
                transition={`all 0.3s ease`}
                onClick={() => setUserInfo(!userInfo)}
              />
            </Wrapper>

            <Wrapper
              transition={`all 0.3s ease`}
              of={`hidden`}
              mh={userInfo ? `unset` : `0`}
              width={`100%`}
              wrap={`wrap`}
            >
              {userSpec &&
                userSpec.map((item, idx) => (
                  <Wrapper
                    key={`${idx}_user_spec`}
                    margin={`0 0 24px 0`}
                    width={`100%`}
                    wrap={`wrap`}
                  >
                    <Wrapper
                      margin={`0 0 20px 0`}
                      width={`100%`}
                      jc={`space-between`}
                    >
                      <Text color={colors.Bl06} weight={`600`}>
                        이용자 {`${idx + 1}`}
                      </Text>

                      <Wrapper>
                        <Text color={colors.Bl06} weight={`600`}>{`${
                          idx + 1
                        }`}</Text>
                        <Text color={colors.Gr03} weight={`600`}>
                          &nbsp;{`/ ${userSpec.length}`}
                        </Text>
                      </Wrapper>
                    </Wrapper>
                    {idx == 0 ? (
                      <Wrapper
                        margin={`0 0 8px 0`}
                        jc={`space-between`}
                        width={`100%`}
                        wrap={`wrap`}
                      >
                        <Text width={`8rem`} color={colors.Gr05} weight={`500`}>
                          이용자 이름
                        </Text>

                        <Wrapper
                          jc={`space-between`}
                          width={`calc(100% - 9rem)`}
                        >
                          <TableFilterContent width={`40%`} margin={`0`}>
                            <Text
                              className="f14"
                              color={colors.Gr05}
                              weight={`500`}
                            >
                              {item.name}
                            </Text>
                          </TableFilterContent>

                          <Wrapper
                            padding={`0 0 0 18px`}
                            jc={`space-between`}
                            width={`60%`}
                          >
                            <Text
                              width={`70px`}
                              color={colors.Gr05}
                              weight={`500`}
                            >
                              전화번호
                            </Text>

                            <TableFilterContent
                              width={`calc(100% - 90px)`}
                              margin={`0`}
                            >
                              <Text
                                className="f14"
                                color={colors.Gr05}
                                weight={`500`}
                              >
                                {reservationInfo.user_phone}
                              </Text>
                            </TableFilterContent>
                          </Wrapper>
                        </Wrapper>
                      </Wrapper>
                    ) : (
                      <Wrapper
                        margin={`0 0 8px 0`}
                        jc={`space-between`}
                        width={`100%`}
                        wrap={`wrap`}
                      >
                        <Text width={`8rem`} color={colors.Gr05} weight={`500`}>
                          이용자 이름
                        </Text>

                        <Wrapper
                          jc={`space-between`}
                          width={`calc(100% - 9rem)`}
                        >
                          <TableFilterContent width={`100%`} margin={`0`}>
                            <Text
                              className="f14"
                              color={colors.Gr05}
                              weight={`500`}
                            >
                              {item.name}
                            </Text>
                          </TableFilterContent>
                        </Wrapper>
                      </Wrapper>
                    )}

                    <Wrapper
                      margin={`0 0 8px 0`}
                      jc={`space-between`}
                      width={`100%`}
                      wrap={`wrap`}
                    >
                      <Text width={`8rem`} color={colors.Gr05} weight={`500`}>
                        생년월일
                      </Text>

                      <Wrapper jc={`flex-start`} width={`calc(100% - 9rem)`}>
                        <TableFilterContent width={`40%`} margin={`0 18px 0 0`}>
                          <Text
                            className="f14"
                            color={colors.Gr05}
                            weight={`500`}
                          >
                            {item.birth}
                          </Text>
                        </TableFilterContent>

                        <Wrapper jc={`flex-start`} width={`calc(60% - 18px)`}>
                          <Wrapper>
                            <Text
                              margin={`0 10px 0 0`}
                              color={colors.Gr04}
                              weight={`500`}
                            >
                              남
                            </Text>
                            <Radio
                              // onClick={() =>
                              //   ChangeUserSpec(idx, "gender", "남성")
                              // }
                              width={`16`}
                              padding={`3`}
                              border={`1px solid ${
                                item.gender == "남성"
                                  ? colors.Bl07
                                  : colors.Gr03
                              }`}
                              bgc={`${
                                item.gender == "남성"
                                  ? colors.Bl07
                                  : colors.White
                              }`}
                            >
                              <div />
                            </Radio>
                          </Wrapper>

                          <Wrapper padding={`0 0 0 14px`}>
                            <Text
                              margin={`0 10px 0 0`}
                              color={colors.Gr04}
                              weight={`500`}
                            >
                              여
                            </Text>
                            <Radio
                              // onClick={() =>
                              //   ChangeUserSpec(idx, "gender", "여성")
                              // }
                              width={`16`}
                              padding={`3`}
                              border={`1px solid ${
                                item.gender == "여성"
                                  ? colors.Bl07
                                  : colors.Gr03
                              }`}
                              bgc={`${
                                item.gender == "여성"
                                  ? colors.Bl07
                                  : colors.White
                              }`}
                            >
                              <div />
                            </Radio>
                          </Wrapper>
                        </Wrapper>
                      </Wrapper>
                    </Wrapper>

                    <Wrapper
                      margin={`0 0 8px 0`}
                      jc={`space-between`}
                      width={`100%`}
                      wrap={`wrap`}
                    >
                      <Text width={`8rem`} color={colors.Gr05} weight={`500`}>
                        키 (cm)
                      </Text>

                      <TableFilterContent width={`calc(100% - 9rem)`}>
                        <Text
                          className="f14"
                          color={colors.Gr05}
                          weight={`500`}
                        >
                          {item.height}
                        </Text>
                      </TableFilterContent>
                    </Wrapper>

                    <Wrapper
                      margin={`0 0 8px 0`}
                      jc={`space-between`}
                      width={`100%`}
                      wrap={`wrap`}
                    >
                      <Text width={`8rem`} color={colors.Gr05} weight={`500`}>
                        체중 (kg)
                      </Text>

                      <TableFilterContent width={`calc(100% - 9rem)`}>
                        <Text
                          className="f14"
                          color={colors.Gr05}
                          weight={`500`}
                        >
                          {item.weight}
                        </Text>
                      </TableFilterContent>
                    </Wrapper>

                    <Wrapper jc={`space-between`} width={`100%`} wrap={`wrap`}>
                      <Text width={`8rem`} color={colors.Gr05} weight={`500`}>
                        발 사이즈 (mm)
                      </Text>

                      <TableFilterContent width={`calc(100% - 9rem)`}>
                        <Text
                          className="f14"
                          color={colors.Gr05}
                          weight={`500`}
                        >
                          {item.foot}
                        </Text>
                      </TableFilterContent>
                    </Wrapper>
                  </Wrapper>
                ))}

              {reservationInfo &&
                reservationInfo.service_type == "시설 이용권" && (
                  <Wrapper
                    key={`1_user_spec`}
                    margin={`0 0 0 0`}
                    width={`100%`}
                    wrap={`wrap`}
                  >
                    <Wrapper
                      margin={`0 0 8px 0`}
                      jc={`space-between`}
                      width={`100%`}
                      wrap={`wrap`}
                    >
                      <Text width={`8rem`} color={colors.Gr05} weight={`500`}>
                        이용자 이름
                      </Text>

                      <Wrapper jc={`space-between`} width={`calc(100% - 9rem)`}>
                        <TableFilterContent width={`40%`} margin={`0`}>
                          <Input
                            width={`100%`}
                            type={`text`}
                            maxLength={10}
                            placeholder="이용자 명"
                            readOnly
                            value={`${reservationInfo.user}`}
                          />
                        </TableFilterContent>

                        <Wrapper
                          padding={`0 0 0 18px`}
                          jc={`space-between`}
                          width={`60%`}
                        >
                          <Text
                            width={`70px`}
                            color={colors.Gr05}
                            weight={`500`}
                          >
                            전화번호
                          </Text>

                          <TableFilterContent
                            width={`calc(100% - 90px)`}
                            margin={`0`}
                          >
                            <Input
                              width={`100%`}
                              type={`text`}
                              maxLength={14}
                              readOnly
                              placeholder="01*-****-****"
                              value={`${reservationInfo.user_phone}`}
                            />
                          </TableFilterContent>
                        </Wrapper>
                      </Wrapper>
                    </Wrapper>
                  </Wrapper>
                )}
            </Wrapper>
          </Wrapper>

          <Devive
            width={`100%`}
            height={`1px`}
            bgc={colors.Gr02}
            margin={`36px 0 36px 0`}
          />

          <Wrapper wrap={`wrap`} padding={`0 36px`} width={`100%`}>
            <Wrapper margin={`0 0 16px 0`} width={`100%`} jc={`space-between`}>
              <Text color={colors.Gr05} weight={`700`} className={`f16`}>
                결제 정보
              </Text>

              <Icon
                width={`calc(1rem * (18 / 14))`}
                src={PaymentArrow}
                cursor={`pointer`}
                transform={amountInfo ? `rotate(0)` : `rotate(180deg)`}
                transition={`all 0.3s ease`}
                onClick={() => setAmountInfo(!amountInfo)}
              />
            </Wrapper>

            <Wrapper
              transition={`all 0.3s ease`}
              of={`hidden`}
              mh={amountInfo ? `unset` : `0`}
              width={`100%`}
              wrap={`wrap`}
            >
              <Wrapper
                margin={`0 0 30px 0`}
                width={`100%`}
                jc={`space-between`}
              >
                <Text color={colors.Gr04} weight={`500`} className={`f14`}>
                  결제 수단
                </Text>
                <Text color={colors.Bl06} weight={`500`} className={`f14`}>{`${
                  reservationInfo &&
                  (reservationInfo.method == "kakao" ? "카카오페이" : "토스")
                }`}</Text>
              </Wrapper>

              <Wrapper
                margin={`0 0 30px 0`}
                width={`100%`}
                jc={`space-between`}
              >
                <Text color={colors.Gr04} weight={`500`} className={`f14`}>
                  액티비티 금액
                </Text>
                <Text color={colors.Gr05} weight={`500`} className={`f14`}>
                  {`${reservationInfo && reservationInfo.service_amount_text}`}{" "}
                  원
                </Text>
              </Wrapper>

              <Wrapper
                margin={`0 0 30px 0`}
                width={`100%`}
                jc={`space-between`}
              >
                <Text color={colors.Gr04} weight={`500`} className={`f14`}>
                  포인트 / 쿠폰 할인
                </Text>
                <Text color={colors.Gr05} weight={`500`} className={`f14`}>
                  - {`${reservationInfo && reservationInfo.point_text}`} 원
                </Text>
              </Wrapper>

              <Wrapper
                margin={`0 0 30px 0`}
                width={`100%`}
                jc={`space-between`}
              >
                <Text color={colors.Gr04} weight={`500`} className={`f14`}>
                  최종 결제금액
                </Text>
                <Text color={colors.Bl06} weight={`500`} className={`f14`}>
                  {`${reservationInfo && reservationInfo.amount_text}`} 원
                </Text>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper>
      <ImageCrop
        info={cropImage}
        setInfo={setCropImage}
        setImg={setTicketSpec}
        img={ticketSpec}
      />
    </>
  );
};

export default ManagementDetail;

const Input = styled.input`
  width: ${({ width }) => `${width}`};
  display: inline-block;
`;
