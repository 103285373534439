import { BrowserRouter, Route, Routes } from "react-router-dom";

import styled from "styled-components";

import AuthProvider, { ProtectRouter } from "./context/auth";
import { useKakao } from "./context/kakao";

import GlobalLayout from "./layouts/globalLayout";
import LoginPage from "./pages/login";

import Rating from "./pages/newDashboard/rating";
import DashboardPage from "./pages/dashboard";
import PageTab from "./pages/dashboard/page";
import LessonAndPartnerTab from "./pages/dashboard/lessonAndPartner.js";

import BannerPage from "./pages/banner/main/index";
import BannerEditPage from "./pages/banner/edit/index";
import BannerRegisterPage from "./pages/banner/register/index";

import CustomerPage from "./pages/customer";
import CustomerDatailPage from "./pages/customer/detail";
import CustomerEditPage from "./pages/customer/edit";

import InquiryPage from "./pages/inquiry/main/index";
import InquiryChatPage from "./pages/inquiry/chat/index";

import PartnerPage from "./pages/partner";
import PartnerLocationPage from "./pages/partner/location";
import PartnerLessonPage from "./pages/partner/lesson";
import PartnerLessonEditPage from "./pages/partner/lesson/edit/index";
import PartnerLessonRegisterPage from "./pages/partner/lesson/register/index";
import PartnerProfilePage from "./pages/partner/profile";
import PartnerScheduleEditPage from "./pages/partner/schedule/edit";
import PartnerScheduleRegisterPage from "./pages/partner/schedule/register";
import PartnerScheduleEditDetailPage from "./pages/partner/schedule/detail";
import PartnerLessonUsageInfoEditorPage from "./pages/partner/lesson/info";

import Activity from "./pages/activity/index";
import Facility from "./pages/facility/index";
import FacilityEdit from "./pages/facility/profile";
import FacilityProduct from "./pages/facility/product";

import Schedule from "./pages/schedule";

import SalesDetailPage from "./pages/sales/detail";

import FAQPage from "./pages/service/faq/main/index";
import FAQRegisterPage from "./pages/service/faq/register/index";

import NoticePage from "./pages/service/notice";
import NoticeDetailPage from "./pages/service/notice/detail";
import NoticeRegisterPage from "./pages/service/notice/register";

import MessagePage from "./pages/message";
import MessageList from "./pages/message/list";
import MessageDetailPage from "./pages/message/detail";
import MessagePush from "./pages/message/push";

//import PartnerRegisterPage from "./pages/partner/register";

// Change ing pages here

// import SalesPage from "./pages/sales";

// new pages here

import "./styles/font.css";
import Dashboard from "./pages/designedDashboard/dashboardNew";
import Sales from "./pages/desingedSales/sales";
// import Calculate from "./pages/designedCalculate/calculate";
// import Calculate from "./pages/designedCalculate/Calculate";
import Calculate from "./pages/designedCalculate/CalculateNew";
import Banner from "./pages/designedBanner/banner";
import User from "./pages/designedMember/user";
import Partner from "./pages/designedMember/partner";
import Center from "./pages/designedCenter/center";
import CenterDetail from "./pages/designedCenter/centerDetail";
import Activitya from "./pages/designedActivity/activity";
import ActivityDetail from "./pages/designedActivity/activityDetail";
import PBActivity from "./pages/designedPBActivity/PBActivity";
import PBActivityDetail from "./pages/designedPBActivity/PBActivityDetail";
import FAQ from "./pages/designedAnnouncementFAQ/faq";
import Announcement from "./pages/designedAnnouncementFAQ/announcement";
import Event from "./pages/designedBanner/event";
import SendMessage from "./pages/designedPush/sendMessage";
import AlarmTalk from "./pages/designedAlarmTalk/AlarmTalk";
import Review from "./pages/designedReview/review";
import IndividualMessage from "./pages/designedMessage/individualMessage";
import SalesDetail from "./components/designedSales/salesDetail";
import UserDetail from "./components/designedMember/userDetail";
import ReviewDetail from "./components/designedReview/reviewDetail";
import CalculateDetail from "./components/designedMember/calculateDetail";
import ManagementDetail from "./components/designedMember/managementDetail";
import BannerInput from "./components/designedBanner/bannerInput";
import EventDetail from "./components/designedBanner/eventDetail";
import AnnounceDetail from "./components/designedAnnouncementFAQ/announceDetail";
import FaqDetail from "./components/designedAnnouncementFAQ/faqDetail";
import AlarmTalkDetail from "./components/designedAlarmTalk/alarmTalkDetail";
import MainBannerDetail from "./components/designedBanner/MainBannerDetail";
import Exhibition from "./pages/designedExhibition/exhibition";
import Bulletin from "./pages/designedBulletin/bulletin";
import BulletinDetail from "./pages/designedBulletin/bulletinDetail";
import CalculateGuide from "./pages/designedCalculate/CalculateGuide";

const firstPage = window.location.pathname == "/";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  padding-top: 48px;
  padding-left: ${firstPage ? 0 : 210}px;
  position: relative;
`;

const App = () => {
  const { kakaoService } = useKakao();

  kakaoService.init();
  return (
    <Container className="App">
      <BrowserRouter>
        <AuthProvider>
          <GlobalLayout>
            <ProtectRouter>
              <Routes>
                <Route index element={<LoginPage />} />
                <Route path="dashboard/data" element={<DashboardPage />} />
                <Route path="dashboard/page" element={<PageTab />} />
                <Route path="dashboard/lesson-partner" element={<LessonAndPartnerTab />} />
                {/* <Route path="dashboard" element={<DashBoardTest />} /> */}
                <Route path="dashboard/rating" element={<Rating />} />
                <Route path="customer_list/:num/:y/:m/:t/:f" element={<CustomerPage />} />
                <Route path="customer/:cid" element={<CustomerDatailPage />} />
                <Route path="customer/:cid/edit" element={<CustomerEditPage />} />

                <Route path="sales/:sid" element={<SalesDetailPage />} />
                <Route path="activity_list/:num/:y/:m/:t/:f/:c" element={<Activity />} />
                <Route path="facility_list/:num/:y/:m/:t/:f/:c" element={<Facility />} />
                <Route path="facility/:fid" element={<FacilityEdit />} />
                <Route path="facility_product/:fid" element={<FacilityProduct />} />
                <Route path="schedule_list/:num/:y/:m/:t/:f/:c" element={<Schedule />} />
                <Route path="inquiry_list/:num/:y/:m/:t/:f" element={<InquiryPage />} />
                <Route path="inquiry/:qid" element={<InquiryChatPage />} />
                <Route path="partner_list/:num/:y/:m/:t/:f/:c" element={<PartnerPage />} />
                {/*<Route
                  path="partner/register"
                  element={<PartnerProfilePage />}
                />*/}
                <Route path="push_notify" element={<MessagePush />} />
                {/* <Route path="message_send" element={<MessagePage />} /> */}
                {/* <Route
                  path="message_list/:num/:y/:m/:t/:f"
                  element={<MessageList />}
                /> */}
                <Route path="message/:mid" element={<MessageDetailPage />} />
                <Route path="partner/:pid" element={<PartnerProfilePage />} />
                <Route path="partner/:pid/location" element={<PartnerLocationPage />} />
                <Route path="partner/:pid/lesson_list/:num" element={<PartnerLessonPage />} />
                <Route path="partner/:pid/lesson/:lid" element={<PartnerLessonEditPage />} />
                <Route path="partner/:pid/lesson/:lid/editor" element={<PartnerLessonUsageInfoEditorPage />} />
                <Route path="partner/:pid/lesson/register/editor" element={<PartnerLessonUsageInfoEditorPage />} />
                <Route path="partner/:pid/lesson/register" element={<PartnerLessonRegisterPage />} />
                <Route path="partner/:pid/schedule/:lt/:lat" element={<PartnerScheduleEditPage />} />
                <Route path="partner/:pid/schedule/detail/:lid" element={<PartnerScheduleEditDetailPage />} />
                <Route path="partner/:pid/schedule/register" element={<PartnerScheduleRegisterPage />} />
                {/* service */}
                <Route path="notice_list/:num" element={<NoticePage />} />
                <Route path="notice/:nid" element={<NoticeDetailPage />} />
                <Route path="notice/register" element={<NoticeRegisterPage />} />
                <Route path="faq_list/:num/:u/:t/:f" element={<FAQPage />} />
                <Route path="faq/register" element={<FAQRegisterPage />} />
                <Route path="banner_list/:num" element={<BannerPage />} />
                <Route path="banner/edit" element={<BannerEditPage />} />
                <Route path="banner/register" element={<BannerRegisterPage />} />

                {/* new */}
                <Route path="sales_list/:n/:t/:s/:e/:d/:status/:pay/:platform" element={<Sales />} />
                <Route path="sales_detail/:id" element={<SalesDetail />} />
                <Route path="review_list/:num/:t/:o/:s/:e" element={<Review />} />
                <Route path="review_detail/:n" element={<ReviewDetail />} />
                <Route path="push_message_send" element={<SendMessage />} />
                <Route path="individual_message_send" element={<IndividualMessage />} />
                <Route path="message_list/:num/:y/:m/:t/:f" element={<AlarmTalk />} />
                <Route path="message_detail/:id/:n" element={<AlarmTalkDetail />} />
                <Route path="dashboard" element={<Dashboard />} />
                {/* <Route path="calculate" element={<Calculate />} /> */}
                {/* <Route path="calculate" element={<Calculate />} /> */}
                <Route path="calculate/:n/:p/:y/:m" element={<Calculate />} />
                <Route path="calculate_detail/:n/:y/:m/:d" element={<CalculateDetail />} />
                <Route path="calculate_guide" element={<CalculateGuide />} />
                <Route path="calculate_manage/:n" element={<ManagementDetail />} />
                <Route path="banner/:n/:t/:o/:s/:m" element={<Banner />} />
                <Route path="banner_detail/:n/:t" element={<BannerInput />} />
                <Route path="main_banner/:n" element={<MainBannerDetail />} />

                <Route path="user/:n/:t" element={<User />} />
                <Route path="user_detail/:n" element={<UserDetail />} />
                <Route path="partner/:n/:t/:sport/:status/:loc" element={<Partner />} />
                <Route path="center/:n/:t/:sport/:status/:loc/:type" element={<Center />} />
                <Route path="center_detail/:n" element={<CenterDetail />} />
                {/* 액티비티 관리 */}
                <Route path="activity/:n/:t/:cate/:loc/:status" element={<Activitya />} />
                <Route path="activity_detail/:n" element={<ActivityDetail />} />
                <Route path="pb_activity/:n/:t/:cate" element={<PBActivity />} />
                <Route path="pb_activity_detail/:n" element={<PBActivityDetail />} />

                <Route path="faq/:n/:t/:type/:cate" element={<FAQ />} />
                <Route path="faq_detail/:n/:t" element={<FaqDetail />} />
                <Route path="announcement/:n/:t/:type/:status" element={<Announcement />} />
                <Route path="announcement_detail/:type/:n" element={<AnnounceDetail />} />
                <Route path="/event/:n" element={<Event />} />
                <Route path="/event_detail/:n" element={<EventDetail />} />
                <Route path="/exhibition" element={<Exhibition />} />
                <Route path="/bulletin" element={<Bulletin />} />
                <Route path="/bulletin_detail/:n" element={<BulletinDetail />} />
              </Routes>
            </ProtectRouter>
          </GlobalLayout>
        </AuthProvider>
      </BrowserRouter>
    </Container>
  );
};

export default App;
