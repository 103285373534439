import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { Page, View, Document, StyleSheet } from "@react-pdf/renderer";
import axios from "axios";

// components
import {
  Wrapper,
  DetailHeader,
  Text,
  TableContent,
  TableColumn,
  TableWrapper,
  TableFilterContainer,
} from "../../layouts/layoutStorage";
import Table from "../../layouts/table";
import colors from "../../styles/constants/colors";

const CalculateDetail = () => {
  const navigate = useNavigate();
  const { n, y, m, d } = useParams();

  // desinged height
  const [Height, setHeight] = useState({
    TableFilter: 0,
    TableHeader: 0,
    TableFooter: 0,
    Layout: 0,
    LayoutHeader: 0,
  });

  const [calculateDetail, setCalculateDetail] = useState();
  const [centerInfo, setCenterInfo] = useState();

  const OpenModal = () => {
    var token = window.localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", "calculate_info");
    frm.append("token", token);
    frm.append("id", n);
    frm.append("year", y);
    frm.append("month", m);
    frm.append("number", d);

    axios
      .post("https://ai.semos.kr/semos_admin/center/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var status = Value.status;
        var statusMessage = Value.status_message;
        var calculateData = Value.amount_list;
        var centerData = Value.center_info;

        if (status == "success") {
          setCalculateDetail(calculateData);
          setCenterInfo(centerData);
        } else if (status == "waring") {
          window.alert(statusMessage);
        }
      });
  };
  useEffect(() => {
    OpenModal();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const Layout = document.getElementById("Layout").offsetHeight - 36;
      const LayoutHeader =
        document.getElementById("LayoutHeader").offsetHeight + 65;
      const TableHeader = document.getElementById("TableHeader").offsetHeight;
      const TableFilter =
        document.getElementById("TableFilter").offsetHeight + 13;

      setHeight({
        TableFilter: TableFilter,
        TableHeader: TableHeader,
        TableFooter: 0,
        Layout: Layout,
        LayoutHeader: LayoutHeader,
      });
    }, 500);
  }, []);

  return (
    <Wrapper
      bgc={colors.White}
      of={`hidden auto`}
      margin="10px 0 0 0"
      display={`inline-block`}
      width={`100%`}
      mw={`1000px`}
      mh={`calc(100vh - 72px)`}
      padding={`10px 0`}
    >
      <Wrapper
        display={`inline-block`}
        width={`100%`}
        padding={`40px 0`}
        id={`Layout`}
      >
        <DetailHeader
          id={`LayoutHeader`}
          padding={`0 36px`}
          margin={`0 0 65px 0`}
        >
          <Wrapper jc={`flex-start`}>
            <Text weight={`500`} className={`f24`} color={colors.Black}>
              정산
            </Text>
          </Wrapper>

          <Wrapper
            onClick={() => window.alert("준비중입니다.")}
            cursor={`pointer`}
            bgc={colors.Bl07}
            radius={`8px`}
            padding={`12px 24px`}
            jc={`center`}
          >
            <Text weight={`700`} className={`f14`} color={colors.White}>
              PDF로 저장
            </Text>
          </Wrapper>
        </DetailHeader>

        <Wrapper
          id={`TableFilter`}
          width={`100%`}
          wrap={`wrap`}
          bb={`${colors.Gr02} 1px solid`}
          padding={`0 36px 20px 36px`}
          margin={`0 0 25px 0`}
        >
          <Wrapper width={`100%`} jc={`space-between`} margin={`0 0 20px 0`}>
            <Text weight={`700`} className={`f18`} color={colors.Black}>
              {`${centerInfo && centerInfo.name} 파트너 님의 ${
                centerInfo && centerInfo.year
              }년, ${centerInfo && centerInfo.month}월 ${
                centerInfo && centerInfo.number
              }차 정산 내역서`}
            </Text>

            <Wrapper>
              {/*<Text weight={`700`} className={`f18`} color={`#3C3E41;`}>
              {`/${'1'}`}
            </Text>
            <Text weight={`700`} className={`f18`} color={`#ACAFB5`}>
              {`/${'22'}`}
            </Text>*/}
            </Wrapper>
          </Wrapper>

          <Wrapper width={`100%`} jc={`flex-end`} margin={`0 0 4px 0`}>
            <Wrapper width={`calc(1rem * (200 / 14))`} jc={`space-between`}>
              <Text
                ws={`nowrap`}
                weight={`500`}
                className={`f14`}
                color={colors.Gr03}
              >
                {`정산 금액`}
              </Text>

              <Text
                ws={`nowrap`}
                weight={`500`}
                className={`f14`}
                color={colors.Gr05}
              >
                {centerInfo && `${centerInfo.all_amount_text}`}원
              </Text>
            </Wrapper>
          </Wrapper>

          <Wrapper width={`100%`} jc={`flex-end`} margin={`0 0 4px 0`}>
            <Wrapper width={`calc(1rem * (200 / 14))`} jc={`space-between`}>
              <Text
                ws={`nowrap`}
                weight={`500`}
                className={`f14`}
                color={colors.Gr03}
              >
                {`판매 수수료`}
              </Text>

              <Text
                ws={`nowrap`}
                weight={`500`}
                className={`f14`}
                color={colors.Gr03}
              >
                {centerInfo && `${centerInfo.charge}`}%
              </Text>
            </Wrapper>
          </Wrapper>

          <Wrapper width={`100%`} jc={`flex-end`}>
            <Wrapper width={`calc(1rem * (200 / 14))`} jc={`space-between`}>
              <Text
                ws={`nowrap`}
                weight={`500`}
                className={`f14`}
                color={colors.Gr03}
              >
                {`정산 금액`}
              </Text>

              <Text
                ws={`nowrap`}
                weight={`500`}
                className={`f14`}
                color={colors.Gr05}
              >
                {centerInfo && `${centerInfo.all_clear_amount_text}`}원
              </Text>
            </Wrapper>
          </Wrapper>
        </Wrapper>

        <Table>
          <TableFilterContainer
            style={{ padding: "0 36px" }}
            id="TableFilter"
            margin={`0 0 13px 0`}
          >
            <Wrapper width={`100%`} jc={`flex-start`}>
              <Text
                ws={`nowrap`}
                weight={`700`}
                className={`f14`}
                color={colors.Gr05}
              >
                정산 상세 내역
              </Text>
            </Wrapper>
          </TableFilterContainer>

          <TableContent
            height={Height.Layout - (Height.LayoutHeader + Height.TableFilter)}
            contentHeight={Height.TableHeader}
            style={{ padding: "0 36px" }}
          >
            <section
              style={{ backgroundColor: `${colors.Gr01}` }}
              id={`TableHeader`}
            >
              <TableColumn
                className={`f10`}
                width={`12`}
                weight={`500`}
                br={`1px solid ${colors.Gr02}`}
              >
                주문번호
              </TableColumn>

              <TableColumn
                className={`f10`}
                width={`10`}
                weight={`500`}
                br={`1px solid ${colors.Gr02}`}
              >
                결제일시
              </TableColumn>

              <TableColumn
                ta={`right`}
                className={`f10`}
                width={`10`}
                weight={`500`}
                br={`1px solid ${colors.Gr02}`}
              >
                액티비티 카테고리
              </TableColumn>

              <TableColumn
                className={`f10`}
                width={`23`}
                weight={`500`}
                br={`1px solid ${colors.Gr02}`}
              >
                액티비티 명
              </TableColumn>

              <TableColumn
                className={`f10`}
                width={`5`}
                weight={`500`}
                br={`1px solid ${colors.Gr02}`}
              >
                인원
              </TableColumn>

              <TableColumn
                className={`f10`}
                width={`16`}
                weight={`500`}
                br={`1px solid ${colors.Gr02}`}
              >
                판매 금액
              </TableColumn>

              <TableColumn
                className={`f10`}
                width={`8`}
                weight={`500`}
                br={`1px solid ${colors.Gr02}`}
              >
                수수료율
              </TableColumn>

              <TableColumn
                className={`f10`}
                width={`16`}
                weight={`500`}
                br={`1px solid ${colors.Gr02}`}
              >
                총 정산금액
              </TableColumn>
            </section>

            <section>
              {calculateDetail &&
                calculateDetail.map((item, idx) => (
                  <TableWrapper
                    cursor={`pointer`}
                    key={`${idx}_reservation`}
                    jc={`flex-start`}
                    bb={`1px solid ${colors.Gr02}`}
                    width={`100%`}
                    bgc={colors.White}
                  >
                    <TableColumn
                      className={`f10`}
                      width={`12`}
                      weight={`500`}
                      br={`1px solid ${colors.Gr02}`}
                    >
                      {item.oid}
                    </TableColumn>

                    <TableColumn
                      className={`f10`}
                      width={`10`}
                      weight={`500`}
                      br={`1px solid ${colors.Gr02}`}
                    >
                      {item.date}
                    </TableColumn>

                    <TableColumn
                      ta={`right`}
                      className={`f10`}
                      width={`10`}
                      weight={`500`}
                      br={`1px solid ${colors.Gr02}`}
                    >
                      {item.sport}
                    </TableColumn>

                    <TableColumn
                      className={`f10`}
                      width={`23`}
                      weight={`500`}
                      br={`1px solid ${colors.Gr02}`}
                    >
                      {item.title}
                    </TableColumn>

                    <TableColumn
                      className={`f10`}
                      width={`5`}
                      weight={`500`}
                      br={`1px solid ${colors.Gr02}`}
                    >
                      {item.people}
                    </TableColumn>

                    <TableColumn
                      className={`f10`}
                      width={`16`}
                      weight={`500`}
                      br={`1px solid ${colors.Gr02}`}
                    >
                      {item.amount_text}
                    </TableColumn>

                    <TableColumn
                      className={`f10`}
                      width={`8`}
                      weight={`500`}
                      br={`1px solid ${colors.Gr02}`}
                    >
                      {item.charge}%
                    </TableColumn>

                    <TableColumn className={`f10`} width={`16`} weight={`500`}>
                      {item.clear_amount_text}
                    </TableColumn>
                  </TableWrapper>
                ))}
            </section>
          </TableContent>
        </Table>
      </Wrapper>
    </Wrapper>
  );
};

export default CalculateDetail;

const Input = styled.input`
  width: ${({ width }) => `${width}`};
  display: inline-block;
`;
const InputSub = styled.input`
  width: ${({ width }) => `${width}`};
  display: inline-block;
  -webkit-text-security: disc;
`;
