import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import MainHeader from "../../components/header";
import MainLayout from "../../layouts/mainLayout";
import Button from "../../modules/button";
import TemplateP from "./template_p";

// icon
import Close from "../../assets/icon/close.svg"

// style
import typography from "../../styles/constants/typography";
import colors from "../../styles/constants/colors";

// const CUSTOMER_PER_PAGE = 10;
// const CUSTOMER_CARD_HEIGHT = 50;

const Main = styled.main`
  width: 100%;
  height: 100%;
  padding: 0 20px;
  margin-top: 20px;
  overflow-y: scroll;

  ul {
    width: 100%;
  }
`;

const FilterBox = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;

  div {
    display: inline-flex;
    align-items : center;
    justify-content : center;
    box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 5px, rgb(0 0 0 / 15%) -0.5px -0.5px 5px;
    border-radius: 8px;
    cursor : pointer;
    margin-right : 10px;
    padding : 10px 15px;
  }

  div:last-child { 
    margin-right : unset;
  }
`;


const BottomButtonBox = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;

  button:first-child {
    margin-right: 10px;
  }
`;

const PaymentCXBox = styled.div`
  margin-top: 30px;

  .cxContentBox {
    width : 100%;
    display : inline-flex;
    align-items : top;
    justify-content : space-between;
    font-size: ${typography.size.small}px;
    margin-bottom: 20px;

    p:first-child {
      color: ${colors.mediumGray};
    }

    .cxContent {
        width : calc(100% - 100px);
        display : inline-block;
        position : relative;

        .cxType {
            border-radius : 8px;
            padding : 10px 12px;
            display : inline-block;
            border : 3px solid rgba(132,140,148,1);
            cursor : pointer;
            font-size: 17px;
            font-weight : 500;
        }
        .cxType:first-child  {
            margin-right : 15px;
        }
        .typeActive {
            border : 3px solid rgba(0,149,255,1);
        }
        .cxManeger {
            width : 100%;
            display : inline-block;
            border-radius : 8px;
            padding : 10px 12px;
            border : 1px solid rgba(132,140,148,1);
            font-size: 17px;
            font-weight : 500;
            background-color : #F9F9F9;
        }
        .cxMessage {
            width : 100%;
            display : inline-block;
            border-radius : 8px;
            padding : 10px 12px;
            border : 1px solid rgba(132,140,148,1);
            font-size: 17px;
            font-weight : 500;
            background-color : #F9F9F9;
            height : 150px;
            resize : none;
        }

        .cxUserSearch {
          width : 100%;
          position : absolute;
          bottom : -5px;
          right : 0;
          transform : translate(0, 100%);
          padding : 15px 25px;
          border : 1px solid rgba(132,140,148,1);
          background-color : #F9F9F9;
          z-index : 100;
          border-radius : 8px;
          height : 180px;
          overflow : hidden auto;
          cursor : pointer;

          .userList {
            width : 100%;
            padding : 5px 0;
            border-bottom : 1px solid rgba(132,140,148,1);

            span {
              width : calc(100% / 3);
              display : inline-flex;
              white-space : nowrap;
              text-overflow: ellipsis;
            }
          } 
        }

        .cxUserSearchStatus {
          position : absolute;
          top : 5px;
          right : 5px;
          cursor : pointer;
          
          img {
            width : 15px;
            height : 15px;
            display : inline-block;
          }
        }
    }
  }
`;


const PushNotify = () => {

/*const ThisDate = new Date();
const ThisMonth = ThisDate.getMonth();
const ThisDay = ThisDate.getDate();
const [template, setTemplate] = useState("PREGISTER4");
const [phone, setPhone] = useState("");
const messageContent = {};
const [month, setMonth] = useState(ThisMonth > 7 ? String(ThisMonth + 2) + "월" : "0" + String(ThisMonth + 2) + "월" );
const [monthAday, setMonthAday] = useState(`${ThisMonth > 8 ? ThisMonth + 1 : "0" + String(ThisMonth + 1)}월 ${ThisDay > 9 ? ThisDay : "0" + String(ThisDay)}일` );
const [partner, setPartner] = useState("***");
const [link, setLink] = useState("");
const [name, setName] = useState("");*/

const [CXManager, setCXManager] = useState("Victor");

const [searchUser, setSearchUser] = useState("");

const [user, setUser] = useState([]);

const [target, setTarget] = useState();

const [type, setType] = useState("one");

const [title, setTitle] = useState("");

const [message, setMessage] = useState("");

const [search, setSearch] = useState(false);


const SendPush = () => {

  if(target && type && title && message) {

    if(window.confirm("푸시알림을 보내시겠습니까?")) {
      var token = window.localStorage.getItem('token')
      const frm = new FormData();
      frm.append("mode", "push_notify");
      frm.append("token", token);
      frm.append("target_token", target.device_token);
      frm.append("target_user", target.number);
      frm.append("target_nickname", target.nickname);
      frm.append("title", title);
      frm.append("message", message);

      axios.post("https://ai.semos.kr/semos_admin/user/send", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            alert(statusMessage)
          } else if(status == 'waring') {
            alert(statusMessage)
          } else if(status == 'fail') {
            alert(statusMessage)
          }
      });
    }

  } else {
    alert("모든 항목을 입력해주세요!");
  }

}

const DataChange = (type, value) => {

  switch(type) {
    
    // type
    case "user":
      setTarget(value)
    break;

    case "searchUser":

      setSearchUser(value)

      var token = window.localStorage.getItem('token')
      const frm = new FormData();
      frm.append("mode", "searchUser");
      frm.append("token", token);
      frm.append("user", searchUser);

      axios.post("https://ai.semos.kr/semos_admin/user/send", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setUser(Value.user_list);

            if(value.length) {
              setSearch(true);
            } else {
              setSearch(false);
            }
          } else if(status == 'waring') {
            alert(statusMessage)
          }
      });

    break;

    case "CXManager":
      setCXManager(value)
    break;

    case "type":
      setType(value)
    break;

    case "title":
      setTitle(value)
    break;

    case "message":
      setMessage(value)
    break;
    
  }

}

  return (
      <MainLayout heightType={true}>
        <MainHeader title={type == 'one' ? "개별 전송" : "전체 전송"} /*title="친구톡 전송"*/>
          <FilterBox>
            <div onClick={() => DataChange("type", 'all')} className={ type == 'all' ? "bg_blue_100 color_white font_15" : "bg_white font_15" }>
              전체
            </div>

            <div onClick={() => DataChange("type", 'one')} className={ type == 'one' ? "bg_blue_100 color_white font_15" : "bg_white font_15" }>
              개별
            </div>
          </FilterBox>
        </MainHeader>

        <Main>

          {type == 'one' ? 
              <section>
                 <p style={{ fontSize : "20px", fontWeight : "500" }} >푸시 알림 정보</p>
   
                 <PaymentCXBox>
                     <div style={{marginBottom : search ? "200px" : "20px"}} className="cxContentBox">
                         <p>유저 검색</p>
                         <span className="cxContent">
                             <input type="text" value={searchUser} maxLength="20" onChange={(e) => DataChange("searchUser", e.target.value)} className="cxManeger" placeholder="닉네임, 전화번호, 실명으로 검색" />

                            {search && 
                              <div className="cxUserSearch">

                                <div onClick={() => setSearch(false)} className="cxUserSearchStatus">
                                  <img src={Close} />
                                </div>

                                {user.map((item, idx) => (
                                  <div style={{color : target && (target.number === item.number ? "#2C84EE" : "#000000" )}} onClick={() => DataChange('user', item)} key={`${idx}_userList`} className="userList" >
                                    <span>{item.nickname}</span>
                                    <span>{item.name}</span>
                                    <span>{item.phone_text}</span>
                                  </div>
                                )) }
                
                              </div>                            
                            }

                         </span>
                     </div>

                     <div className="cxContentBox">
                         <p>타겟 유저</p>
                         <span className="cxContent">
                             <input type="text" value={target && `${target.name} | ${target.nickname} | ${target.phone_text}`} maxLength="50" readOnly className="cxManeger" placeholder="유저를 검색해주세요!" />
                         </span>
                     </div>
      
                     <div className="cxContentBox">
                         <p>담당자 명</p>
                         <span className="cxContent">
                             <input type="text" value={CXManager} maxLength="20" onChange={(e) => DataChange("CXManager", e.target.value)} className="cxManeger" placeholder="담당자명을 입력해 주세요!" />
                         </span>
                     </div>

                     <div className="cxContentBox">
                         <p>푸시 알림 제목</p>
                         <span className="cxContent">
                             <input value={title} type="text" maxLength="50" onChange={(e) => DataChange("title", e.target.value)} className="cxManeger" placeholder="푸시 알림 제목을 적어주세요! *최대 50글자" />
                         </span>
                     </div>
   
                     <div className="cxContentBox">
                         <p>푸시 알림 내용</p>
                         <span className="cxContent">
                             <textarea value={message} onChange={(e) => DataChange("message", e.target.value)} type="text" className="cxMessage" placeholder="푸시 알림 내용을 적어주세요! *최대 200글자" maxLength="200" />
                         </span>
                     </div>
                 </PaymentCXBox>
   
             </section> 

             :               
             
              <section>
                {/*<p style={{ fontSize : "20px", fontWeight : "500" }} >푸시 알림 정보</p>

                <PaymentCXBox>

                    <div className="cxContentBox">
                         <p>유저 명</p>
                         <span className="cxContent">
                             <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="cxManeger" placeholder="유저명을 입력해 주세요!" />
                         </span>
                     </div>
   
                     <div className="cxContentBox"> 
                         <p>유저 전화번호</p>
                         <span className="cxContent">
                             <input type="text" maxLength={"13"} value={phone} onChange={(e) => PhoneHyphen(e.target.value)} className="cxManeger" placeholder="- 을 제외하고 입력해주세요!" />
                         </span>
                     </div>
   
                     <div className="cxContentBox">
                         <p>담당자 명</p>
                         <span className="cxContent">
                             <input type="text" value={CXManager} onChange={(e) => setCXManager(e.target.value)} className="cxManeger" placeholder="담당자명을 입력해 주세요!" />
                         </span>
                     </div>
   
   
                     <div className="cxContentBox">
                         <p>문자 내용</p>
                         <span className="cxContent">
                             <textarea onChange={(e) => setMessage(e.target.value)} value={message} type="text" className="cxMessage" maxLength="1000" />
                         </span>
                     </div>
                 </PaymentCXBox> */}
              </section>
          }
   
          </Main>

          <BottomButtonBox>
              <Button text="초기화" clickHandler={""} width={`180px`} color={colors.white} fontcolor={colors.gray}/>

              <Button text={type == 'one' ? "개별 전송" : "전체 전송"} clickHandler={SendPush} width={`180px`} />

          </BottomButtonBox>

      </MainLayout>
  );
};

export default PushNotify;
