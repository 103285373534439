import { useRef, useState } from "react";
import styled from "styled-components";


// icon
import NotingSemoring from "../assets/icon/notingSemoring.jpg"

const LoadingSpinner = ({show, color}) => {



  return (
    <Container show={show}>
      <div className="backgroundContainer"/>
    
      <div className="contentContainer">

        <Loading col={color}>

          <div className="loadingRound">
            <div className="loadingBorder" />
            <img className="loadingImg" src={NotingSemoring}/>
          </div>

          <span className="loadingText">
            로딩은 세모링이 처리했으니 잠시만 기다려달라구!
          </span>
        </Loading>

      </div>
    </Container>
  )


}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 997;
  display : ${({ show }) => show ? "inline-block" : "none"};


  .backgroundContainer {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;

    background-color: rgba(0,0,0,0.2);
    backdrop-filter: blur(2px);
    display : inline-block;
  }

  .contentContainer {
    align-items : center;
    justify-content : center;
    position: fixed;
    display : inline-flex;
    flex-wrap : wrap;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius : 8px;
    z-index: 999;
    overflow: hidden;
    width : 50vw;
  }
`;

const Loading = styled.div`

  display : inline-flex;
  justify-content : center;
  flex-wrap : wrap;
  z-index: 1000;

  .loadingRound {
    border-radius : 50%;
    background-color : #ffffff;
    display : inline-flex;
    position : relative;
    box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 5px, rgb(0 0 0 / 15%) -0.5px -0.5px 5px;
    width : 200px;
    height : 200px; 

    .loadingBorder {
      width : 200px;
      height : 200px; 
      border-radius : 50%;
      border : 10px solid ${({ col }) => col};
      border-right : 10px solid transparent;
      position : absolute;
      top : 0;
      right : 0;
      animation: rotate 1.5s linear infinite;
      transform: rotate(360deg);
    }

    @keyframes rotate
    {
        from { transform: rotate(360deg); }
        to { transform: rotate(-360deg); }
    }

    .loadingImg { 
      width : 130px;
      height : 130px; 
      z-index: 1001;
      position : absolute;
      top : 50%;
      right : 50%;
      border-radius : 30%;
      transform : translate(50%, -50%);
    }
  }

  .loadingText {
    white-space : nowrap;
    display : inline-block;
    color : #000000;
    line-height : 1.4;
    font-weight : 500;
    font-size : 24px;
    width : 50vw;
    text-align : center;
    margin-top : 20px;
  }
`


export default LoadingSpinner;