import styled from "styled-components";
import Select from "../modules/select";
import Tag from "../modules/tag";
import base from "../styles/constants/base";
import colors from "../styles/constants/colors";
import flexbox from "../styles/func/flexbox";
import ClockField from "./clockField";
import WeekField from "./weekField";

const Box = styled.div`
  width: 100%;
  margin: 20px 0;
  padding: 20px;
  box-shadow: ${base.boxShadow.default};
  border-radius: ${base.borderRadius.default}px;
`;

const LocationList = styled.ul`
  width: 100%;
  margin-top: 30px;
  padding-left: 0px !important;
  ${flexbox("flex-start")}
  flex-wrap: wrap;
`;

const ScheduleCard = ({
  type = "write",
  location = "",
  week = "",
  clock = "",
}) => {
  const {
    locationChangeHandler,
    locationList,
    locationValue,
    deleteLocationHandler,
  } = location;

  if (type === "write") {
    return (
      <Box>
        <section>
          <Select
            placeholder="위치 추가하기"
            width="320px"
            optionList={[
              {
                text: "송도",
                value: "송도",
              },
            ]}
            name="location"
            value={locationValue.location}
            changeHandler={locationChangeHandler}
          />
          <LocationList>
            {locationList.map((item, idx) => (
              <Tag
                text={item.value}
                key={idx}
                deleteHandler={() => deleteLocationHandler(item.id)}
                ispointer={true}
                underline={true}
                color={colors.blue}
                borderColor={colors.blue}
                bgColor={colors.lightBlue}
              />
            ))}
          </LocationList>
        </section>

        <section>
          <WeekField week={week} />
        </section>

        <section>
          <ClockField clock={clock} />
        </section>
      </Box>
    );
  }

  if (type === "read") {
    return (
      <Box>
        <section>
          {/* <Select
            placeholder="위치 추가하기"
            width="320px"
            optionList={[
              {
                text: "송도",
                value: "송도",
              },
            ]}
            name="location"
            value={location}
          /> */}
          <LocationList>
            {location &&
              location.map((item, idx) => (
                <Tag
                  text={item.value}
                  key={idx}
                  deleteHandler={() => deleteLocationHandler(item.id)}
                  ispointer={true}
                  underline={true}
                  color={colors.blue}
                  borderColor={colors.blue}
                  bgColor={colors.lightBlue}
                />
              ))}
          </LocationList>
        </section>

        <section>
          <WeekField type="read" week={week} />
        </section>

        <section>
          <ClockField type="read" clock={clock} />
        </section>
      </Box>
    );
  }
};

export default ScheduleCard;
