const typography = {
  weight: {
    thin: 400,
    regular: 500,
    bold: 700,
  },
  size: {
    micro: 11,
    tiny: 13,
    small: 15,
    base: 17,
    medium: 19,
    me_new: 23,
    large: 25,
  },
  lineSpacing: {
    micro: 17,
    tiny: 20,
    small: 22,
    base: 24,
    medium: 26,
    large: 28,
    xLarge: 34,
  },
};

export default typography;
