import styled from "styled-components";
import ImageInput from "../../components/imageInput";
import useImage from "../../hooks/useImage";
import useInput from "../../hooks/useInput";
import Input from "../../modules/input";
import Select from "../../modules/select";
import Textarea from "../../modules/textarea";
import { useEffect, useState } from "react";
import axios from "axios";

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const ImageSection = styled.section`
  margin-right: 20px;
`;

const MetadataSection = styled.section`
  width: 100%;
`;

const Box = styled.div`
  margin: 30px 0;

  p {
    margin-bottom: 10px;
  }
`;

const NoticeDetailLayout = ({
  type = "register",
  data = null,
  imageData = null,
  setNotice
}) => {
  const valueDefualt = data
    ? {
        category: data.category,
        title: data.title,
        contents: data.contents,
      }
    : {
        category: "",
        title: "",
        contents: "",
      };

  const imageDefualt = imageData
    ? {
        thumbnail: {
          base: "",
          files: "",
        },
        contents: {
          base: "",
          files: "",
        },
      }
    : {
        thumbnail: {
          base: "",
          files: "",
        },
        contents: {
          base: "",
          files: "",
        },
      };

  const { value, inputChangeHandler } = useInput(valueDefualt);

  const { image, imageChangeHandler } = useImage(imageDefualt);

  const [thumbnail, setThumbnail] = useState("");

  const [imgContent, setImgContent] = useState("");


  const setImageFromFile = ({ file, setImageUrl  }) => {

    var uploadFileSize = file.size

    var maxFileSize = 1024 * 1024 * 5

    if(uploadFileSize > maxFileSize) {
      alert('업로드 가능한 이미지 최대 용량은 5MB입니다.\n이미지 용량을 확인해주세요')
  
    } else {
        let reader = new FileReader();
        reader.onload = function () {
        setImageUrl({ result: reader.result });
        };

        reader.readAsDataURL(file);
    }

  };  

  return (
    <Container type={type}>
      <ImageSection>
        <ImageInput
          edit={type === "edit" ? true : false}
          name="thumbnail"
          preview={data ? data.thumbnail : ""}
          changeHandler={setThumbnail}
          imagePro={setImageFromFile}
          height={"268.75px"}
        >
          <p>썸네일 이미지</p>
          <p>(8:5 비율/5MB 이하/JPG)</p>
        </ImageInput>

        <ImageInput
          edit={type === "edit" ? true : false}
          name="contents"
          preview={data ? data.content : ""}
          changeHandler={setImgContent}
          height={"350px"}
          imagePro={setImageFromFile}
          type={'long2'}
        >
          <p>컨텐츠 이미지</p>
          <p>(5MB 이하/JPG)</p>
        </ImageInput>
      </ImageSection>

      <MetadataSection>
        {/*<Select
          name="category"
          value={value.category}
          changeHandler={inputChangeHandler}
          optionList={[]}
          placeholder="카테고리"
          width="160px"
        />*/}

        <Box style={{marginTop : '0'}}>
          <p>공지사항 제목</p>
          <Input
            type="text"
            placeholder="제목을 입력해 주세요.(최대 20자)"
            name="title"
            value={data ? data.subject : ""}
            changeHandler={inputChangeHandler}
          />
        </Box>

        <Box>
          <p>공지사항 내용</p>
          <Textarea
            type="text"
            placeholder="내용을 입력해 주세요."
            name="contents"
            value={data ? data.info : ""}
            changeHandler={inputChangeHandler}
          />
        </Box>
      </MetadataSection>
    </Container>
  );
};

export default NoticeDetailLayout;
