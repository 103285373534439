import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import {
  Icon,
  Wrapper,
  Text,
  WrapperColumn,
  AbsoluteWrapper,
  WrapperRow,
} from "../../layouts/layoutStorage";
import colors from "../../styles/constants/colors";

import EmptyHeart from "../../assets/designedIcons/emptyHeart.svg";
import Star from "../../assets/designedIcons/star.svg";
import Map from "../../assets/designedIcons/mapCircle.svg";

const Item = ({ item }) => {
  return (
    <>
      <WrapperColumn
        width="100%"
        margin={"0 0 24px 0"}
        style={{ position: "relative" }}
      >
        <Wrapper width="100%">
          <WrapperColumn width="100%">
            <Wrapper width="100%">
              <ReviewCard img={item?.activity_img}>
                {item?.semos_only && (
                  <RainbowBox>
                    <Text
                      weight="600"
                      size="10px"
                      ls="-0.02em"
                      color={colors.White}
                    >
                      세모스 ONLY
                    </Text>
                  </RainbowBox>
                )}
                <AbsoluteWrapper
                  bottom={0}
                  padding="8px"
                  bgc={colors.B20}
                  width="100%"
                  style={{
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                  }}
                >
                  {item.location_list?.length && (
                    <>
                      <Icon
                        width="14px"
                        height="14px"
                        src={Map}
                        margin="0 4px 0 0"
                      />
                      {item.location_list.map((el, idx) => (
                        <Text
                          ws="pre"
                          weight="500"
                          size="12px"
                          ls="-0.02em"
                          color={colors.White}
                          key={idx}
                        >
                          {`${el.city.includes("제주") ? "제주" : el.city} ${
                            el.district
                          }`}
                          {item.location_list.length > 1 && idx === 0
                            ? `, `
                            : ""}
                        </Text>
                      ))}
                      {/* {item.location_list.slice(0, 2).map((el, idx) => (
                        <Text ws="pre" weight="500" size="12px" ls="-0.02em" color={colors.White} key={idx}>
                          {`${el.city.includes("제주") ? "제주" : el.city} ${el.district}`}
                          {item.location_list.length > 1 && idx === 0 ? `, ` : ""}
                        </Text>
                      ))} <Text ws="pre" weight="500" size="12px" ls="-0.02em" color={colors.Gr04}>
                        {item.location_list.length > 2 && ` +${item.location_list.length - 2}`}
                      </Text> */}
                    </>
                  )}
                </AbsoluteWrapper>
              </ReviewCard>
            </Wrapper>

            <WrapperColumn
              padding="10px 0 0 0"
              gap="8px"
              width="100%"
              bgc={colors.White}
            >
              <WrapperColumn gap="12px">
                <WrapperRow jc="space-between" ai="center">
                  <WrapperRow ai="center">
                    <Text
                      weight="600"
                      size="10px"
                      ls="-0.02em"
                      color={colors.Bl07}
                    >
                      {item?.category}
                    </Text>

                    <Text
                      margin="0 3px"
                      weight="600"
                      size="12px"
                      ls="-0.02em"
                      color={colors.Gr03}
                    >
                      |
                    </Text>
                    <Text
                      weight="600"
                      size="10px"
                      ls="-0.02em"
                      color={colors.Gr03}
                    >
                      {item?.duration}
                    </Text>

                    <Text
                      margin="0 3px"
                      weight="600"
                      size="12px"
                      ls="-0.02em"
                      color={colors.Gr03}
                    >
                      |
                    </Text>
                    <Text
                      weight="600"
                      size="10px"
                      ls="-0.02em"
                      color={colors.Gr03}
                    >
                      {item?.type}
                    </Text>
                  </WrapperRow>
                  <Wrapper ai="center">
                    {item.keyword?.length
                      ? item.keyword?.map((el, idx) => (
                          <Wrapper key={idx} padding="0 3px">
                            <Text
                              weight="600"
                              size="10px"
                              ls="-0.02em"
                              color={colors.Gr03}
                            >
                              {`#${el}`}
                            </Text>
                          </Wrapper>
                        ))
                      : ""}
                  </Wrapper>
                </WrapperRow>

                <Text
                  ellipsis={true}
                  weight="600"
                  size="13px"
                  fontFam
                  color={colors.Gr05}
                  ai="flex-start"
                >
                  {item?.name}
                </Text>
              </WrapperColumn>

              <WrapperColumn margin="0 0 3px 0" position="relative" gap="6px">
                {/* 키워드 & 별점 */}
                <WrapperRow width="100%" jc="space-between">
                  <Wrapper ai="center" gap="4px">
                    {item?.event_price !== "0" ? (
                      <Text
                        weight="500"
                        size="12px"
                        ls="-0.02em"
                        color={colors.Gr03}
                        style={{
                          textDecorationLine: "line-through",
                        }}
                      >
                        {item?.price ? `${item.price}원` : "120,000원"}
                      </Text>
                    ) : (
                      ""
                    )}
                    {item?.discount !== "0" ? (
                      <Text
                        weight="600"
                        size="16px"
                        ls="-0.02em"
                        color={colors.Red03}
                      >
                        {item.discount}%
                      </Text>
                    ) : (
                      ""
                    )}

                    <Text
                      weight="600"
                      size="16px"
                      ls="-0.02em"
                      color={colors.Gr06}
                    >
                      {item.event_price}원
                    </Text>
                  </Wrapper>
                  <Wrapper ai="center" gap="1.8px">
                    <Icon width="11px" height="11px" src={Star} />
                    <Text
                      weight="600"
                      size="10px"
                      ls="-0.02em"
                      color={colors.Gr05}
                    >
                      5
                    </Text>
                  </Wrapper>
                </WrapperRow>

                {/* 가격 */}
              </WrapperColumn>
            </WrapperColumn>
          </WrapperColumn>
        </Wrapper>

        {/* 좋아요 */}
        <AbsoluteWrapper top="8px" right="8px">
          <Icon width="20px" height="20px" src={EmptyHeart} />
        </AbsoluteWrapper>
      </WrapperColumn>
    </>
  );
};

export default Item;

const ReviewCard = styled.div`
  background-image: ${({ img }) => `url(${img})`};
  background-size: 100%;
  background-position: center;
  position: relative;
  width: 100%;
  border-radius: 8px;
  aspect-ratio: 327 / 204;
`;

const RainbowBox = styled.div`
  position: absolute;
  left: 8px;
  top: 8px;
  border-radius: 8px;
  padding: 3px 7px;
  background: ${colors.Gradient};
  /* background: radial-gradient(100% 843.76% at 0% 52.38%, #ff6759 0%, #8d4ef2 44.79%, #0e6dff 100%); */
`;
