import styled from "styled-components";
import flexbox from "../styles/func/flexbox";
import deleteIcon from "../assets/icon/close.svg";
import colors from "../styles/constants/colors";

const Div = styled.div`
  ${flexbox("space-between")};
  display: inline-flex;
  border: 1px solid ${({ borderColor }) => borderColor};
  padding: 5px 10px;
  border-radius: 40px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  margin-right: 10px;
  margin-bottom: 10px;

  span {
    text-decoration: ${({ underline }) =>
      underline === true ? "underline" : null};
    cursor: ${({ ispointer }) => (ispointer === "true" ? "pointer" : null)};
  }

  button {
    margin-left: 20px;

    img {
      width: 10px;
    }
  }
`;

const Tag = ({
  text,
  deleteHandler,
  clickHandler = "",
  bgColor = `${colors.white}`,
  borderColor = `${colors.black}`,
  color = `${colors.black}`,
  underline = false,
  ispointer = false,
}) => {
  return (
    <Div
      bgColor={bgColor}
      borderColor={borderColor}
      color={color}
      underline={underline}
      ispointer={ispointer}
    >
      <span onClick={clickHandler}>{text}</span>

      <button type="button" onClick={deleteHandler}>
        <img src={deleteIcon} />
      </button>
    </Div>
  );
};

export default Tag;
