import styled from "styled-components";
import base from "../../styles/constants/base";
import colors from "../../styles/constants/colors";
import typography from "../../styles/constants/typography";

const Container = styled.div``;

const Box = styled.div`
  margin: ${({ margin }) => (margin === true ? `30px 0` : "0px")};
  display: flex;
  flex-direction: ${({ isMe }) => isMe && "row-reverse"};

  padding-right: ${({ isMe }) => isMe && "20px;"};
  padding-left: ${({ isMe }) => !isMe && "20px;"};
`;

const TimeBox = styled.div`
  height: 100%;
  align-self: end;
  margin-left: 10px;

  time {
    color: ${colors.gray};
    font-size: ${typography.size.small}px;
  }
`;

const BubbleBox = styled.div`
  position: relative;
  max-width: 400px;
  display: flex;
  filter: drop-shadow(1.95px 1.95px 2.5px rgba(0, 0, 0, 0.2));
`;

const Bubble = styled.div`
  width: ${({ width }) => width};
  display : inline-block;
  padding: 10px;
  height: 100%;
  border-radius: ${base.borderRadius.default}px;
  background-color: ${({ isMe }) => (isMe ? colors.lightBlue : colors.white)};
  margin-left: ${({ isMe }) => isMe && "10px"};
  text-align: left;
  background-color: ${({ color }) =>
    color === true ? colors.lightBlue : null};

  p {
    padding: 0 5px;
    width: 100%;
    display : inline-block;
  }
`;

const Tooltip = styled.div`
  width: 70px;
  height: 70px;
  top: 0;
  position: absolute;
  right: -40px;
  top: -17px;
  ${({ isMe }) => !isMe && `transform: scaleX(-1)`};
  ${({ isMe }) => !isMe && `left: -40px`};

  clip-path: polygon(
    25% 25%,
    73.44% 25%,
    56.22% 32.32%,
    48.76% 36.92%,
    41.6% 42%,
    36.29% 48.98%,
    30.65% 57.96%,
    25% 66.94%
  );
  background-color: ${({ isMe }) => (isMe ? colors.lightBlue : colors.white)};

  background-color: ${({ color }) =>
    color === true ? colors.lightBlue : null};
`;

const MessageBubble = ({
  type,
  width = "auto",
  text,
  time = "",
  isMe = true,
  margin = false,
  color = false,
}) => {
  if (type === "list") {
    return (
      <Container isMe={isMe}>
        <Box isMe={isMe} margin={margin}>
          <BubbleBox>
            <Bubble isMe={isMe} color={color}>
              <p>{text.length > 20 ? `${text.slice(0, 20)}...` : text}</p>
            </Bubble>

            <Tooltip isMe={isMe} color={color} />
          </BubbleBox>

          <TimeBox>
            <time>{time}</time>
          </TimeBox>
        </Box>
      </Container>
    );
  }

  return (
    <Container isMe={isMe}>
      <Box isMe={isMe} margin={margin}>
        <BubbleBox>
          <Bubble isMe={isMe} color={color}>
            { text.split('\n').map((item, idx) => (
                <p>{item}</p>
            )) }
            
          </Bubble>

          <Tooltip isMe={isMe} color={color} />
        </BubbleBox>

        <TimeBox>
          <time>{time}</time>
        </TimeBox>
      </Box>
    </Container>
  );
};

export default MessageBubble;
