import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import DropDown from "../designedComponents/dropDown";
import colors from "../../styles/constants/colors";

const NewFaq = () => {
  const { n, t } = useParams();
  const navigate = useNavigate();
  const [info, setInfo] = useState({
    category: "",
    q: "",
    a: "",
  });

  const registerFaq = () => {
    if (info.category && info.q && info.a) {
      if (window.confirm("공지사항을 등록하시겠습니까?")) {
        var token = window.localStorage.getItem("token");

        const frm = new FormData();

        frm.append("mode", "upload");
        frm.append("token", token);
        frm.append("category", info.category);
        frm.append("q", info.q);
        frm.append("a", info.a);
        frm.append("type", t);

        axios
          .post("https://ai.semos.kr/semos_admin/faq/data", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((data) => {
            var Value = data.data;
            var status = Value.status;
            var statusMessage = Value.status_message;

            if (status == "success") {
              alert("faq 등록이 완료되었습니다.");
              navigate(`/faq/1/all/customer/all`);
            } else if (status == "waring") {
              alert(statusMessage);
            }
          });
      }
    }
  };

  return (
    <Container>
      <div>
        <Title className="f24">자주 묻는 질문 등록</Title>
        <QuestionLine>
          <QAArea>Q</QAArea>
          <QuestionArea style={{ marginRight: "12px", width: "77%" }}>
            <Input
              placeholder="질문을 작성해 주세요"
              value={info.q}
              onChange={(e) =>
                setInfo((prev) => ({ ...prev, q: e.target.value }))
              }
            />
          </QuestionArea>
          <DropDown
            no={true}
            data={info.category}
            option={[
              {
                text: t == "customer" ? "회원 정보" : "입점 안내",
                value: t == "customer" ? "회원 정보" : "입점 안내",
              },
              { text: "결제 및 환불", value: "결제 및 환불" },
              { text: "이용 안내", value: "이용 안내" },
            ]}
            setData={setInfo}
            type="object"
            keyName="category"
            defaultText="카테고리 선택"
          />
        </QuestionLine>
        <div style={{ height: "8px" }} />
        <QuestionLine>
          <QAArea>A</QAArea>
          <QuestionArea>
            <TextArea
              placeholder="답변을 작성해 주세요"
              value={info.a}
              rows={20}
              onChange={(e) =>
                setInfo((prev) => ({ ...prev, a: e.target.value }))
              }
            />
          </QuestionArea>
        </QuestionLine>
      </div>

      <SaveButton onClick={() => registerFaq()}>등록하기</SaveButton>
    </Container>
  );
};

export default NewFaq;

const Container = styled.div`
  padding: 36px;
  padding-bottom: 52px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 700;
  margin-bottom: 36px;
`;

const QuestionLine = styled.div`
  display: flex;
  /* align-items: center; */
`;

const QAArea = styled.div`
  width: 2rem;
  padding: 8px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
`;

const QuestionArea = styled.div`
  padding: 8px 16px;
  border: 1px solid ${colors.Gr02};
  width: 100%;
`;

const Input = styled.input`
  color: ${colors.Gr05};
  width: 100%;
  font-weight: 500;
`;

const TextArea = styled.textarea`
  resize: none;
  outline: none;
  border: none;
  width: 100%;
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  letter-spacing: -0.02em;
`;

const DeleteButton = styled.div`
  padding: 12px 0;
  color: ${colors.Red03};
  cursor: pointer;
`;

const SaveButton = styled.div`
  margin: 30px 0 0 0;
  background-color: ${colors.Bl07};
  color: ${colors.White};
  padding: 12px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
`;
