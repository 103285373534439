import { useState } from "react";

const useList = (defaultState) => {
  const [list, setList] = useState(defaultState);

  const addHandler = (data) => {
    setList((prev) => [...prev, data]);
  };

  const editHandler = (id, data) => {
    //  TODO: 순서 유지하면서 수정될 수 있도록 하기

    const updatedList = list.map((item) => {
      return item.id === id ? data : { ...item };
    });

    setList(updatedList);
  };

  const deleteHandler = (id) => {
    const filteredData = list.filter((item) => item.id !== id);

    setList(filteredData);
  };

  return { list, setList, addHandler, editHandler, deleteHandler };
};

export default useList;
