import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";

// components
import DesignedMainHeader from "../../components/designedHeader";
import DesignedMainLayout from "../../layouts/designedMainLayout";
import DrawerModal from "../../components/drawerModal";
import Table from "../../layouts/table";
import {
  TableFilterContainer,
  TableFilterContent,
  TableWrapper,
  Icon,
  TableContent,
  TableFooter,
  TableColumn,
  TableFooterPaging,
  AbsoluteWrapper,
  Wrapper,
  Text,
} from "../../layouts/layoutStorage";

import SearchIcon from "../../assets/icon/search.png";
import DoublePaging from "../../assets/icon/double_paging.png";
import OnePaging from "../../assets/icon/one_paging.png";
import CenterDetail from "../../components/designedMember/centerDetail";

// icon
import FilterIcon from "../../assets/icon/filter.png";
import { useRef } from "react";
import colors from "../../styles/constants/colors";

const Center = () => {
  const { n, t, sport, status, loc, type } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [locationPopup, setLocationPopup] = useState(false);
  const [typePopup, setTypePopup] = useState(false);
  const [sportPopup, setSportPopup] = useState(false);
  const [statusPopup, setStatusPopup] = useState(false);
  const [loading, setLoading] = useState(true);

  const [center, setCenter] = useState([]);
  const [centerInfo, setCenterInfo] = useState();
  const [count, setCount] = useState(0);
  const [Height, setHeight] = useState({
    TableFilter: 0,
    TableHeader: 0,
    TableFooter: 0,
    Layout: 0,
    LayoutHeader: 0,
  });

  // filter
  const [filter, setFilter] = useState({
    sport: sport !== "all" ? sport : "카테고리 전체",
    status: status !== "all" ? status : "",
    location: loc !== "all" ? loc : "",
    text: t !== "all" ? t : "",
    type: type !== "all" ? type : "",
  });

  const [page, setPage] = useState(n);

  const [filterCopy, setFilterCopy] = useState({
    text: t !== "all" ? t : "",
  });

  const loadData = (number) => {
    var token = localStorage.getItem("token");

    const frm = new FormData();

    frm.append("mode", "center_update");
    frm.append("token", token);
    frm.append("page", number);
    frm.append("location_type", loc !== "all" ? loc : "");
    frm.append("text", t !== "all" ? t : "");
    frm.append("category", sport !== "all" ? sport : "카테고리 전체");
    frm.append("type", type !== "all" ? type : "");
    frm.append("status", status !== "all" ? status : "");

    axios
      .post("https://ai.semos.kr/semos_admin/center/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var CenterList = Value.center_list;
        var CenterCount = Value.center_count;
        var Status = Value.status == "success";

        if (Status) {
          setCenter(CenterList);
          setCount(CenterCount);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    loadData(n);

    setTimeout(() => {
      const Layout = document.getElementById("Layout")?.offsetHeight - 36;
      const LayoutHeader = document.getElementById("LayoutHeader")?.offsetHeight + 65;
      const TableFilter = document.getElementById("TableFilter")?.offsetHeight + 19;
      const TableHeader = document.getElementById("TableHeader")?.offsetHeight;
      const TableFooter = document.getElementById("TableFooter")?.offsetHeight;

      setHeight({
        TableFilter: TableFilter,
        TableHeader: TableHeader,
        TableFooter: TableFooter,
        Layout: Layout,
        LayoutHeader: LayoutHeader,
      });
    }, 500);
  }, []);

  useEffect(() => {
    var token = localStorage.getItem("token");

    const frm = new FormData();

    frm.append("mode", "center_update");
    frm.append("token", token);
    frm.append("page", n);
    frm.append("location_type", loc);
    frm.append("text", t);
    frm.append("category", sport);
    frm.append("type", type);
    frm.append("status", status);

    axios
      .post("https://ai.semos.kr/semos_admin/center/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var CenterList = Value.center_list;
        var CenterCount = Value.center_count;

        setCenter(CenterList);
        setCount(CenterCount);
      });

    if (open) {
      setOpen(false);
      document.body.style.removeProperty("overflow");
      document.getElementById("root").style.pointerEvents = "auto";
    }
  }, [n, t, sport, status, loc, type]);

  const CenterSpecific = (number) => {
    /*var token = localStorage.getItem("token");

    const frm = new FormData();

    frm.append("mode", "center_specific");
    frm.append("token", token);
    frm.append("center_id", number);

    axios
      .post("https://ai.semos.kr/semos_admin/center/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status == "success";
        var CenterInfo = Value.center_info;

        if (Status) {
          setCenterInfo(CenterInfo);
          setOpen(true);
        }
      });*/
    //navigate(`/center_detail/${number}`);
    window.open(`/center_detail/${number}`, "_blank");
  };

  const CenterAdd = () => {
    setCenterInfo({
      account: {
        bank: "",
        account: "",
        first_id: "",
        last_id: "",
        business_number: "",
      },
      activity: [],
      bank_image: { image: "", change: "no" },
      business_image: { image: "", change: "no" },
      career: [],
      id: "new",
      id_card_image: { image: "", change: "no" },
      image: [],
      intro: "",
      owner: "",
      partner: [],
      profile: "",
      scale: "",
      sport: [],
      status: "",
      title: "",
    });
    setOpen(true);
  };

  const EnterSearch = (key) => {
    if (key == "Enter") {
      FilterSearch("text", filterCopy.text);
    }
  };

  const PageInput = (num) => {
    setPage(num);
    navigate(`/center/${num}/${t ? t : "all"}/${sport ? sport : "all"}/${status ? status : "all"}/${loc ? loc : "all"}/${type ? type : "all"}`);
  };

  useEffect(() => {
    if(loading === false) {
      setPage(1);
      navigate(
        `/center/${1}/${filter.text ? filter.text : "all"}/${filter.sport ? filter.sport : "all"}/${filter.status ? filter.status : "all"}/${
          filter.location ? filter.location : "all"
        }/${filter.type ? filter.type : "all"}`
      );
    }
  }, [filter]);

  const numPages = Math.ceil(count / 12);

  let PageList = [];

  if (n < 11) {
    for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
      PageList.push(i + 1);
    }
  } else {
    if (numPages - 10 < n) {
      var startPage = numPages - 19;
      if (startPage < 1) {
        startPage = 1;
      }

      for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    } else {
      var startPage = n - 9;
      if (startPage < 1) {
        startPage = 1;
      }

      for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    }
  }

  const LocationList = ["all", "서울", "경기", "인천", "수원", "용인", "고양", "부산", "대구", "강원", "울산", "경북", "경남", "제주"];
  const SportList = ["all", "스쿠버다이빙", "프리다이빙", "스노보드", "스키", "패들보드", "서핑", "웨이크보드", "수상스키", "수영,스노클링"];
  const TypeList = ["all", "강사", "센터"];

  const StatusList = ["all", "활성화", "비활성화", "승인 대기"];

  const statusColor = (item) => {
    switch (item) {
      case "1":
        return "#3383FF";

      case "0":
        return "#FF6052";

      case "2":
        return "#ACAFB5";
    }
  };

  const categoryRef = useRef();

  const categoryClose = (e) => {
    if (sportPopup && (!categoryRef.current || !categoryRef.current.contains(e.target))) {
      setSportPopup(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", categoryClose);

    return () => {
      window.removeEventListener("click", categoryClose);
    };
  }, [sportPopup]);

  const locationRef = useRef();

  const locationClose = (e) => {
    if (locationPopup && (!locationRef.current || !locationRef.current.contains(e.target))) {
      setLocationPopup(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", locationClose);

    return () => {
      window.removeEventListener("click", locationClose);
    };
  }, [locationPopup]);

  const typeRef = useRef();

  const typeClose = (e) => {
    if (typePopup && (!typeRef.current || !typeRef.current.contains(e.target))) {
      setTypePopup(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", typeClose);

    return () => {
      window.removeEventListener("click", typeClose);
    };
  }, [typePopup]);

  const statusRef = useRef();

  const statusClose = (e) => {
    if (statusPopup && (!statusRef.current || !statusRef.current.contains(e.target))) {
      setStatusPopup(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", statusClose);

    return () => {
      window.removeEventListener("click", statusClose);
    };
  }, [statusPopup]);

  const FilterHandler = (type, value) => {
    var copy = value;
    var regExp = /[`~!@#$%^&*()_|+\-=?;:'"<>\{\}\\\\/]/gim;

    if (regExp.test(copy)) {
      alert("특수문자는 입력하실수 없습니다.");
      copy = copy.replace(regExp, "");
    }
    if (copy) {
      setFilterCopy({ ...filterCopy, [type]: copy });
    } else {
      setFilterCopy({ ...filterCopy, [type]: "all" });
    }
  };

  const FilterSearch = (type, value) => {
    // if (copy) {
    if (type == "text") {
      setPage(1);
      setFilter({ ...filter, [type]: filterCopy.text });
    } else if (type == "location") {
      setPage(1);
      setFilter({ ...filter, [type]: value });
    } else if (type == "sport") {
      setPage(1);
      setFilter({ ...filter, [type]: value });
    } else if (type == "type") {
      setPage(1);
      setFilter({ ...filter, [type]: value });
    } else if (type == "status") {
      setPage(1);
      setFilter({ ...filter, [type]: value });
    }
  };

  const locations_return = (value) => {
    if (!value.length) {
      return "지역 정보 미등록";
    }

    var locations = "";

    for (var i = 0; i < value.length; i++) {
      var si_data = value[i].si;
      var gu_data = value[i].gu;

      if (i == 0) {
        locations = `${si_data} ${gu_data}`;
      } else {
        locations = `${locations}, ${si_data} ${gu_data}`;
      }
    }

    return locations;
  };

  return (
    <>
      <DesignedMainLayout>
        <DesignedMainHeader title="파트너 관리" subtitle={`전체 파트너 : ${count}명 (현재 리스트)`} />
        <Table>
          <TableFilterContainer id="TableFilter" margin={`0 0 19px 0`} style={{ justifyContent: "flex-start" }}>
            <Wrapper width={`50%`} jc={`flex-start`}>
              <TableFilterContent margin={`0 24px 0 0`}>
                <Input
                  onKeyUp={(e) => EnterSearch(e.key)}
                  value={filterCopy.text != "all" ? filterCopy.text : ""}
                  onChange={(e) => FilterHandler("text", e.target.value)}
                  width={`350px`}
                  type={`text`}
                  maxLength={40}
                  placeholder="파트너 명, 전화번호 (4자리) 검색 가능"
                />
                <Icon width={`calc(1rem * (17 / 14))`} cursor={`pointer`} src={SearchIcon} onClick={() => FilterSearch("text", filterCopy.text)} />
              </TableFilterContent>

              <TableFilterContent onClick={() => setTypePopup(!typePopup)} ref={typeRef} cursor={`pointer`} margin={`0 24px 0 0`}>
                <Text weight={`500`} width={`100%`} margin={`0 2px 0 0`} color={`#3C3E41`}>
                  {filter.type != "all" && filter.type ? filter.type : `파트너 분류 전체`}
                </Text>
                <Icon width={`calc(1rem * (17 / 14))`} src={FilterIcon} />

                <AbsoluteWrapper
                  display={typePopup ? `inline-flex` : `none`}
                  z={`100`}
                  width={`150px`}
                  bgc={`#ffffff`}
                  bottom={`-8px`}
                  left={`0`}
                  wrap={`wrap`}
                  transform={`translate(0, 100%)`}
                >
                  <Wrapper wrap={`wrap`} width={`100%`} border={`1px solid #DCDCDC`} mh={`300px`} of={`hidden auto`}>
                    <Wrapper display={`inline-block`} padding={`8px`} width={`100%`} ws={`normal`}>
                      {TypeList.map((item, idx) => (
                        <Wrapper key={`${idx}_type_filter`} onClick={() => FilterSearch(`type`, item)} cursor={`pointer`} padding={`8px`} width={`100%`}>
                          <Text color={filter.type == item ? `#3383FF` : `#818792`} weight={`500`}>
                            {item != "all" ? item : `전체`}
                          </Text>
                        </Wrapper>
                      ))}
                    </Wrapper>
                  </Wrapper>
                </AbsoluteWrapper>
              </TableFilterContent>

              <TableFilterContent cursor={`pointer`} margin={`0 24px 0 0`} onClick={() => setSportPopup(!sportPopup)} ref={categoryRef}>
                <Text weight={`500`} margin={`0 2px 0 0`} color={`#3C3E41`}>
                  {filter.sport !== "all" && filter.sport ? filter.sport : `카테고리 전체`}
                </Text>
                <Icon width={`calc(1rem * (17 / 14))`} src={FilterIcon} />

                <AbsoluteWrapper
                  display={sportPopup ? `inline-flex` : `none`}
                  z={`100`}
                  width={`150px`}
                  bgc={`#ffffff`}
                  bottom={`-8px`}
                  left={`0`}
                  wrap={`wrap`}
                  transform={`translate(0, 100%)`}
                >
                  <Wrapper wrap={`wrap`} width={`100%`} border={`1px solid #DCDCDC`} height={`300px`} of={`hidden auto`}>
                    <Wrapper display={`inline-block`} padding={`8px`} width={`100%`} ws={`normal`}>
                      {SportList.map((item, idx) => (
                        <Wrapper key={`${idx}_sport_filter`} onClick={() => FilterSearch(`sport`, item)} cursor={`pointer`} padding={`8px`} width={`100%`}>
                          <Text color={filter.sport == item ? `#3383FF` : `#818792`} weight={`500`}>
                            {item != "all" ? item : `전체`}
                          </Text>
                        </Wrapper>
                      ))}
                    </Wrapper>
                  </Wrapper>
                </AbsoluteWrapper>
              </TableFilterContent>

              <TableFilterContent onClick={() => setStatusPopup(!statusPopup)} ref={statusRef} cursor={`pointer`} margin={`0 24px 0 0`}>
                <Text weight={`500`} width={`100%`} margin={`0 2px 0 0`} color={`#3C3E41`}>
                  {filter.status != "all" && filter.status ? filter.status : `상태 전체`}
                </Text>
                <Icon width={`calc(1rem * (17 / 14))`} src={FilterIcon} />

                <AbsoluteWrapper
                  display={statusPopup ? `inline-flex` : `none`}
                  z={`100`}
                  width={`150px`}
                  bgc={`#ffffff`}
                  bottom={`-8px`}
                  left={`0`}
                  wrap={`wrap`}
                  transform={`translate(0, 100%)`}
                >
                  <Wrapper wrap={`wrap`} width={`100%`} border={`1px solid #DCDCDC`} mh={`300px`} of={`hidden auto`}>
                    <Wrapper display={`inline-block`} padding={`8px`} width={`100%`} ws={`normal`}>
                      {StatusList.map((item, idx) => (
                        <Wrapper key={`${idx}_status_filter`} onClick={() => FilterSearch(`status`, item)} cursor={`pointer`} padding={`8px`} width={`100%`}>
                          <Text color={filter.status == item ? `#3383FF` : `#818792`} weight={`500`}>
                            {item != "all" ? item : `전체`}
                          </Text>
                        </Wrapper>
                      ))}
                    </Wrapper>
                  </Wrapper>
                </AbsoluteWrapper>
              </TableFilterContent>

              <TableFilterContent onClick={() => setLocationPopup(!locationPopup)} ref={locationRef} cursor={`pointer`} margin={`0 24px 0 0`}>
                <Text weight={`500`} width={`100%`} margin={`0 2px 0 0`} color={`#3C3E41`}>
                  {filter.location != "all" && filter.location ? filter.location : `지역 전체`}
                </Text>
                <Icon width={`calc(1rem * (17 / 14))`} src={FilterIcon} />

                <AbsoluteWrapper
                  display={locationPopup ? `inline-flex` : `none`}
                  z={`100`}
                  width={`150px`}
                  bgc={`#ffffff`}
                  bottom={`-8px`}
                  left={`0`}
                  wrap={`wrap`}
                  transform={`translate(0, 100%)`}
                >
                  <Wrapper wrap={`wrap`} width={`100%`} border={`1px solid #DCDCDC`} mh={`300px`} of={`hidden auto`}>
                    <Wrapper display={`inline-block`} padding={`8px`} width={`100%`} ws={`normal`}>
                      {LocationList.map((item, idx) => (
                        <Wrapper
                          key={`${idx}_location_filter`}
                          onClick={() => FilterSearch(`location`, item)}
                          cursor={`pointer`}
                          padding={`8px`}
                          width={`100%`}
                        >
                          <Text color={filter.location == item ? `#3383FF` : `#818792`} weight={`500`}>
                            {item != "all" ? item : `전체`}
                          </Text>
                        </Wrapper>
                      ))}
                    </Wrapper>
                  </Wrapper>
                </AbsoluteWrapper>
              </TableFilterContent>
            </Wrapper>

            <Wrapper width={`50%`} jc={`flex-end`}>
              <Wrapper onClick={() => CenterSpecific("new")} radius={`8px`} cursor={`pointer`} bgc={`#0E6DFF`} padding={`12px 20px`} jc={`center`}>
                <Text color={`#ffffff`}>파트너 추가하기</Text>
              </Wrapper>
            </Wrapper>
          </TableFilterContainer>

          <TableContent height={Height.Layout - (Height.LayoutHeader + Height.TableFilter + Height.TableFooter)} contentHeight={Height.TableHeader}>
            <section id="TableHeader">
              <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`} bl={`1px solid transparent`}>
                파트너 ID
              </TableColumn>

              <TableColumn ta={`left`} width={`14`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                파트너 명
              </TableColumn>

              <TableColumn width={`8`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                파트너 구분
              </TableColumn>

              <TableColumn ta={`left`} width={`12`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                파트너 관리자
              </TableColumn>

              <TableColumn width={`13`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                파트너 연락처
              </TableColumn>

              <TableColumn ta={`left`} width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                액티비티 카테고리
              </TableColumn>

              <TableColumn ta={`left`} width={`15`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                지역
              </TableColumn>

              <TableColumn width={`8`} weight={`500`} br={`1px solid transparent`}>
                상태
              </TableColumn>
            </section>

            <section>
              {center
                ? center.map((item, idx) => (
                    <Link to={`/center_detail/${item.center_id}`}>
                      <TableWrapper
                        cursor={`pointer`}
                        key={`${idx}_center`}
                        jc={`flex-start`}
                        bl={`1px solid transparent`}
                        br={`1px solid transparent`}
                        bb={`1px solid ${colors.Gr02}`}
                        bt={`1px solid transparent`}
                        width={`100%`}
                        bgc={`#ffffff`}
                      >
                        <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                          {`${item.center_id}`}
                        </TableColumn>

                        <TableColumn width={`14`} weight={`500`} br={`1px solid ${colors.Gr02}`} ta={`left`}>
                          {item.name ? `${item.name}` : "파트너명 없음"}
                        </TableColumn>

                        <TableColumn width={`8`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                          {`${item.type}`}
                        </TableColumn>

                        <TableColumn width={`12`} weight={`500`} br={`1px solid ${colors.Gr02}`} ta={`left`}>
                          {`${item.manager}`}
                        </TableColumn>

                        <TableColumn width={`13`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                          {`${item.phone ? item.phone : "전화번호 미등록"}`}
                        </TableColumn>

                        <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`} ta={`left`}>
                          {item.center_sport.map((sport, id) => (
                            <span key={id}>
                              {id !== 0 ? ", " : ""}
                              {`${sport.sport ? sport.sport : "카테고리 정보 없음"}`}
                            </span>
                          ))}
                        </TableColumn>

                        <TableColumn width={`15`} weight={`500`} br={`1px solid ${colors.Gr02}`} ta={`left`}>
                          {`${item.center_location ? locations_return(item.center_location) : "지역 정보 없음"}`}
                        </TableColumn>

                        <TableColumn
                          width={`8`}
                          weight={`500`}
                          padding={`9px 20px`}
                          br={`1px solid transparent`}
                          color={statusColor(item.center_status)}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {item.center_status == "1" ? `활성화` : item.center_status == "2" ? `비활성화` : `승인대기`}
                        </TableColumn>
                      </TableWrapper>
                    </Link>
                  ))
                : ""}

              <TableFooter>
                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  src={DoublePaging}
                  onClick={() => {
                    if (Number(n) < 11) {
                      PageInput(1);
                    } else {
                      PageInput(Number(n) - 10);
                    }
                  }}
                />
                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  src={OnePaging}
                  onClick={() => {
                    if (page > 1) {
                      PageInput(n - 1);
                    } else {
                      window.alert("첫번째 페이지 입니다.");
                    }
                  }}
                />

                {PageList.length
                  ? PageList.map((item, idx) => (
                      <TableFooterPaging
                        key={`paging_number_${idx}`}
                        margin={`0 16px 0 0`}
                        color={`${n == item ? "#3383FF" : "#ACAFB5"}`}
                        onClick={() => PageInput(item)}
                      >
                        {item}
                      </TableFooterPaging>
                    ))
                  : ""}

                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  transform={`rotate(180deg)`}
                  src={OnePaging}
                  onClick={() => {
                    if (n < numPages) {
                      PageInput(Number(n) + 1);
                    } else {
                      window.alert("마지막 페이지 입니다.");
                    }
                  }}
                />
                <Icon
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  transform={`rotate(180deg)`}
                  src={DoublePaging}
                  onClick={() => {
                    if (Number(n) + 10 > numPages) {
                      PageInput(numPages);
                    } else {
                      PageInput(Number(n) + 10);
                    }
                  }}
                />
              </TableFooter>
            </section>
          </TableContent>

          <TableFooter id="TableFooter" style={{ display: "none" }}>
            <Icon
              margin={`0 16px 0 0`}
              width={`calc(1rem * (20 / 14))`}
              cursor={`pointer`}
              src={DoublePaging}
              onClick={() => {
                if (Number(n) < 11) {
                  PageInput(1);
                } else {
                  PageInput(Number(n) - 10);
                }
              }}
            />
            <Icon
              margin={`0 16px 0 0`}
              width={`calc(1rem * (20 / 14))`}
              cursor={`pointer`}
              src={OnePaging}
              onClick={() => {
                if (page > 1) {
                  PageInput(n - 1);
                } else {
                  window.alert("첫번째 페이지 입니다.");
                }
              }}
            />

            {PageList.length
              ? PageList.map((item, idx) => (
                  <TableFooterPaging
                    key={`paging_number_${idx}`}
                    margin={`0 16px 0 0`}
                    color={`${n == item ? "#3383FF" : "#ACAFB5"}`}
                    onClick={() => PageInput(item)}
                  >
                    {item}
                  </TableFooterPaging>
                ))
              : ""}

            <Icon
              margin={`0 16px 0 0`}
              width={`calc(1rem * (20 / 14))`}
              cursor={`pointer`}
              transform={`rotate(180deg)`}
              src={OnePaging}
              onClick={() => {
                if (n < numPages) {
                  PageInput(Number(n) + 1);
                } else {
                  window.alert("마지막 페이지 입니다.");
                }
              }}
            />
            <Icon
              width={`calc(1rem * (20 / 14))`}
              cursor={`pointer`}
              transform={`rotate(180deg)`}
              src={DoublePaging}
              onClick={() => {
                if (Number(n) + 10 > numPages) {
                  PageInput(numPages);
                } else {
                  PageInput(Number(n) + 10);
                }
              }}
            />
          </TableFooter>
        </Table>
      </DesignedMainLayout>

      <DrawerModal open={open} setOpen={setOpen}>
        <CenterDetail open={open} setOpen={setOpen} info={centerInfo} />
      </DrawerModal>
    </>
  );
};

export default Center;

const Input = styled.input`
  width: ${({ width }) => `${width}`};
  display: inline-block;
`;
