import { Link } from "react-router-dom";
import styled from "styled-components";
import colors from "../../styles/constants/colors";

const Card = styled.li`
  width: 100%;
  height: 65px;
`;

const SLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  white-space : nowrap;
  overflow : hidden;
  text-overflow : ellipsis;

  div {
    overflow : hidden;
    text-overflow : ellipsis;
    
    &:first-child {
      width: 5%;
    }

    &:nth-child(2) {
      width: 7%;
    }

    &:nth-child(3) {
      width: 7%;
    }

    &:nth-child(4) {
      width: 10%;
    }

    &:nth-child(5) {
      width: 13%;
    }

    &:nth-child(6) {
      width: 10%;
    }

    &:nth-child(7) {
      width: 28%;
    }

    &:nth-child(8) {
      width: 5%;
    }

    &:nth-child(9) {
      width: 13%;
    }

  }
`;

const Check = styled.p`
  color: ${colors.red};
`;

const Success = styled.p`
  color: ${colors.blue};
`;

const MessageListCard = ({ data }) => {

  const PhoneHyphen = (value) => {
    return String(value).replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-")
  }

  const CXMessageTypeValue = {
    "cancelp" : "유저 - 취소 (강사 미확정)",
    "cancelp2" : "강사 - 환불 (고객 요청)",
    "cancelu" : "유저 - 취소 (고객 요청)",
    "refundu" : "유저 - 환불 (고객 요청)",
    "refundp" : "강사 - 환불 (고객 요청)",
    "reminder" : "유저 - 리마인더",
    "reminder2" : "유저 - 리마인더",
    "payment" : "유저 - 결제 안내",
    "confirm" : "유저 - 일정 확정",
    "confirm2" : "유저 - 일정 확정",
    
    "UCANCELPS" : "유저 - 취소 (강사 미확정)",
    "UCANCELS" : "유저 - 취소 (고객 요청)",
    "PCANCELS" : "강사 - 환불 (고객 요청)",
    "PCANCELS2" : "강사 - 환불 (고객 요청)",
    "UREMINDERS" : "유저 - 리마인더",
    "PREMINDERS" : "강사 - 리마인더",
    "PAYMENTS" : "유저 - 결제 안내",
    "PPAYMENTS" : "강사 - 결제 안내",
    "UCONFIRMS" : "유저 - 일정 확정",
    "PCONFIRMS" : "강사 - 일정 확정",
    "UREVIEW" : "유저 - 리뷰 안내"
  }

  return (
    <Card>
      <SLink to={`/message/${data.id}`}>
        <div>{data.number}</div>

        <div>{data.manager}</div>

        <div>{data.OID ? data.OID : "test"}</div>

        <div>{data.user}</div>

        <div>{PhoneHyphen(data.phone)}</div>

        <div>{CXMessageTypeValue[data.category]}</div>

        <div>{data.content ? data.content : "test"}</div>

        <div>
          <Success>{data.status === "success" && "성공"}</Success>
          <Check>{data.status === "fail" && "실패"}</Check>
          <p>{data.status === "reservation" && "예약"}</p>
        </div>

        <div>{data.date}</div>

      </SLink>
    </Card>
  );
};

export default MessageListCard;
