import styled from "styled-components";
import MainHeader from "../../components/header";
import { partnerGlobalData, partnerListData } from "../../data/partner";
import usePagination from "../../hooks/usePagination";
import MainLayout from "../../layouts/mainLayout";
import PartnerLayout from "../../layouts/partner";
import base from "../../styles/constants/base";
import searchIcon from "../../assets/icon/search.svg";
import useInput from "../../hooks/useInput";
import Input from "../../modules/input";
import Select from "../../modules/select";
import Index from "../../layouts";
import OverlayPortal from "../../overlayPortal";
import Overlay from "../../layouts/overlay";
import { useState, useEffect } from "react";
import FilterModal from "../../layouts/modal/filter";
import colors from "../../styles/constants/colors";
import ScheduleRowCard from "../../components/schedule/rowCard";
import SchedulePopup from "../../components/activity/shedulePopup";
import useList from "../../hooks/useList";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

// import Pagination from "../../components/pagination";
import flexbox from "../../styles/func/flexbox";
import typography from "../../styles/constants/typography";


const GlobalDataSection = styled.section`
  width: 100%;
  margin-bottom: 20px;

  ul {
    width: 100%;
    display: flex;
  }
`;

const FilterBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  div {
    display: flex;
  }
`;

const SerachIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
  pointer-events: none;
`;

const FilterButton = styled.button`
  color: ${colors.white};
  background-color: ${colors.blue};
  padding: 0 10px;
  border-radius: ${base.borderRadius.default}px;
`;

const Main = styled.main`
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;

  ul {
    width: 100%;
  }
`;

const Footer = styled.footer`
  width: 100%;
  padding: 0 ${base.spacing.main}px;
  position: absolute;
  bottom: 20px;
`;


// pagination
const Div = styled.div`
  width: 100%;
  height: 100%;
  ${flexbox("space-between")};
  font-weight: ${typography.weight.regular};
`;

const SectionButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: ${colors.white};
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};

  &:focus {
    box-shadow: ${base.boxShadow.default};
  }
`;

const List = styled.ul`
  ${flexbox()}
`;

const PageButton = styled.li`
  width: 40px;
  height: 40px;
  border: ${({ active }) => (active ? `2px solid ${colors.blue}` : null)};
  color: ${({ active }) => (active ? colors.blue : null)};
  ${flexbox()};
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};
  margin: 0 5px;
  cursor: pointer;
`;

const SchedulePage = () => {
  const navigate = useNavigate();
  const { num, y, m, t, f, c } = useParams();
  const numCheck = num > 1 || (y || m || t || f !== 'all' || c !== 'all');

  const globalData = partnerGlobalData;

  const listData = partnerListData;

  const { list, editHandler } = useList(partnerListData);

  const [modal, setModal] = useState(false);

  const [onOff, setOnOff] = useState(false);

  const { value, setValue, inputChangeHandler } = useInput({
    search: t !== 'all' ? t : "" ,
    filter: f !== 'all' ? f : "all" ,
    category: c !== 'all' ? c : "all" ,
    month: m !== 'all' ? m : "" ,
    year : y !== 'all' ? y : "" ,
  });

  const { value: datetimeValue, inputChangeHandler: datetimeChangeHandler } =
    useInput({
      "start-date": "",
      "finish-date": "",
    });

  const statusToggleHandler = (partner_id, service_id, number) => {
    var token = window.localStorage.getItem('token')

    var activityStatus = activity[number].status == 'yes';
  
    const frm = new FormData();
    frm.append("mode", "activity_status");
    frm.append("token", token);
    frm.append("partner_number", partner_id);
    frm.append("service_number", service_id);
    frm.append("status", activityStatus ? 'close' : 'yes');

      axios.post("https://ai.semos.kr/semos_admin/partner/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then((data) => {
        var Value = data.data;
        var status = Value.status
        var statusMessage = Value.status_message

        if(status == 'success') {


          if(activityStatus) {

            var partnerData = [...activity]

            partnerData[number].status = 'close'

            setActivity(partnerData)

          } else {

            var partnerData = [...activity]

            partnerData[number].status = 'yes'

            setActivity(partnerData)

          }

          alert(statusMessage)

        } else if(status == 'waring') {
          alert(statusMessage)
        }

      });

    
  };

  const modalToggleHandler = () => {
    setModal(!modal);
  };

  const applyHandler = (cv, fv) => {
    setValue((prev) => ({
      ...prev,
      category: cv,
      filter: fv,
    }));
    setModal(!modal);
  };

  const resetHandler = () => {
    setValue((prev) => ({
      ...prev,
      category: "",
      filter: "all",
    }));

  };

  const SchedulePopupOpen = () => {
    setOnOff(!onOff)
  };

  const { offset, limit, currentPage, setCurrentPage } = usePagination(
    listData,
    9
  );


  const [activity, setActivity] = useState("");
  const [activityCount, setActivityCount] = useState(0);
  const [activityCheck, setActivityCheck] = useState(0);
  const [page, setPage] = useState(num);
  const [updateStatus, setUpdateStatus] = useState(false);

      // 파트너 리스트 로드
      useEffect(() => {
    
        var token = window.localStorage.getItem('token')
  
        const frm = new FormData();
        frm.append("mode", (updateStatus || numCheck ? "activity_update" : "list"));
        frm.append("token", token);
        frm.append("page", updateStatus ? 1 : num);
        frm.append("year", (updateStatus || numCheck ? value.year : "all"));
        frm.append("month", (updateStatus || numCheck ? value.month : "all"));
        frm.append("text", (updateStatus || numCheck ? value.search : 'all'));
        frm.append("type", (updateStatus || numCheck ? value.filter : 'all'));
        frm.append("category", (updateStatus || numCheck ? value.category : 'all'));

          axios.post("https://ai.semos.kr/semos_admin/activity/data", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          }).then((data) => {
            var Value = data.data;
            var status = Value.status
            var statusMessage = Value.status_message
  
            if(status == 'success') {
  
              setActivity(Value.activity_list)
              setActivityCount(Value.activity_count)
              setUpdateStatus(true)

              if(updateStatus) {
                setPage(1)
                navigate(`/schedule_list/${1}/${value.year ? value.year : "all" }/${value.month ? value.month : "all" }/${value.search ? value.search : "all" }/${value.filter}/${value.category}`,  { replace: true})
              } else {
                setPage(num)
                setActivityCheck(Value.partner_check)
              }
  
            } else if(status == 'waring') {
              alert(statusMessage)
            }
  
          });
  
      }, [value.year, value.month, value.filter, value.category]);


  const prevHandler = () => {
    if (page > 1) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "activity_update");
      frm.append("token", token);
      frm.append("page", page - 1);
      frm.append("year", value.year);
      frm.append("month", value.month);
      frm.append("text", value.search);
      frm.append("type", value.filter);
      frm.append("category", value.category);

        axios.post("https://ai.semos.kr/semos_admin/activity/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setActivity(Value.activity_list)
            setActivityCount(Value.activity_count)
            setPage(page - 1)
            navigate(`/schedule_list/${page - 1}/${value.year ? value.year : "all" }/${value.month ? value.month : "all" }/${value.search ? value.search : "all" }/${value.filter}/${value.category}`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    } else {
      alert('첫 페이지입니다.');
    }
  };

  const nextHandler = () => {
    if (page < numPages) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "activity_update");
      frm.append("token", token);
      frm.append("page", page + 1);
      frm.append("year", value.year);
      frm.append("month", value.month);
      frm.append("text", value.search);
      frm.append("type", value.filter);
      frm.append("category", value.category);

        axios.post("https://ai.semos.kr/semos_admin/activity/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setActivity(Value.activity_list)
            setActivityCount(Value.activity_count)
            setPage(page + 1)
            navigate(`/schedule_list/${page + 1}/${value.year ? value.year : "all" }/${value.month ? value.month : "all" }/${value.search ? value.search : "all" }/${value.filter}/${value.category}`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    } else {
      alert('마지막 페이지입니다.');
    }
  };

  const PageChange = (number) => {
    if ( number < (numPages + 1) && number > 0 ) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "activity_update");
      frm.append("token", token);
      frm.append("page", number);
      frm.append("year", value.year);
      frm.append("month", value.month);
      frm.append("text", value.search);
      frm.append("type", value.filter);
      frm.append("category", value.category);

        axios.post("https://ai.semos.kr/semos_admin/activity/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setActivity(Value.activity_list)
            setActivityCount(Value.activity_count)
            setPage(number)
            navigate(`/schedule_list/${number}/${value.year ? value.year : "all" }/${value.month ? value.month : "all" }/${value.search ? value.search : "all" }/${value.filter}/${value.category}`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    }
  };

  const TextSearch = (keyValue) => {

    if(keyValue === 'Enter') {
        var token = window.localStorage.getItem('token')

        const frm = new FormData();
        frm.append("mode", "activity_update");
        frm.append("token", token);
        frm.append("page", 1);
        frm.append("year", value.year);
        frm.append("month", value.month);
        frm.append("text", value.search);
        frm.append("type", value.filter);
        frm.append("category", value.category);

        axios.post("https://ai.semos.kr/semos_admin/activity/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setActivity(Value.activity_list)
            setActivityCount(Value.activity_count)
            setPage(1)
            navigate(`/schedule_list/${1}/${value.year ? value.year : "all" }/${value.month ? value.month : "all" }/${value.search ? value.search : "all" }/${value.filter}/${value.category}`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    }
  
  };


  const numPages = Math.ceil(activityCount / 10);
  let PageList = [];
  
  if(page < 6) {
    //PageList = [1,2,3,4,5,6,7,8,9,10];
    for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
      PageList.push(i+1);
    }

  } else {
    if( (numPages - 5) < page ) {
      var startPage = numPages - 9;
      if(startPage < 1) {
        startPage = 1;
      }

      for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
        PageList.push(startPage + i);
      }

    } else {
      var startPage = page - 4;
      if(startPage < 1) {
        startPage = 1;
      }
      
      for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
        PageList.push(startPage + i);
      }
    }
  }

  return (
    <>
      <PartnerLayout>
        {/*<GlobalDataSection>
          <ul>
            {partnerCheck ? Object.entries(partnerCheck).map((entrie, idx) => (
              <PartnerCard key={idx} title={entrie[0]} value={entrie[1]} />
            )) : ""}
          </ul>
        </GlobalDataSection>*/}

        <MainLayout
          height="800px"
          heightType={true}
          button={{ type: "schedule", clickHandler: SchedulePopupOpen }}
        >
          <MainHeader title="일정 관리">
            <FilterBox>
              <div>
                <Input
                  width="350px"
                  type="text"
                  name="search"
                  value={value.search}
                  changeHandler={inputChangeHandler}
                  placeholder="파트너명으로 검색 (ENTER)"
                  keyPressHandler={TextSearch}
                  padding={true}
                  margin={true}
                >
                  <SerachIcon src={searchIcon} />
                </Input>

                {/*<FilterButton type="button" onClick={modalToggleHandler}>
                  필터
                </FilterButton>*/}
              </div>

              <Select
              type="calendar"
              name="year"
              border={false}
              width="150px"
              isTransparent={true}
              value={value.year}
              changeHandler={inputChangeHandler}
              optionList={[
                {
                    text: "전체(년)",
                    value: "",
                },
                {
                    text: "2021년",
                    value: "2021",
                },
                {
                    text: "2022년",
                    value: "2022",
                },
                {
                    text: "2023년",
                    value: "2023",
                },
                {
                  text: "2024년",
                  value: "2024",
                },
                {
                  text: "2025년",
                  value: "2025",
                },
              ]}
            />

            <Select
              type="calendar"
              name="month"
              border={false}
              width="150px"
              isTransparent={true}
              value={value.month}
              changeHandler={inputChangeHandler}
              optionList={[
                {
                    text: "전체(월)",
                    value: "",
                },
                {
                    text: "01월",
                    value: "01",
                },
                {
                    text: "02월",
                    value: "02",
                },
                {
                    text: "03월",
                    value: "03",
                },
                {
                    text: "04월",
                    value: "04",
                },
                {
                    text: "05월",
                    value: "05",
                },
                {
                    text: "06월",
                    value: "06",
                },
                {
                    text: "07월",
                    value: "07",
                },
                {
                    text: "08월",
                    value: "08",
                },
                {
                    text: "09월",
                    value: "09",
                },
                {
                    text: "10월",
                    value: "10",
                },
                {
                    text: "11월",
                    value: "11",
                },
                {
                    text: "12월",
                    value: "12",
                },
              ]}
            />
            </FilterBox>
          </MainHeader>

          <Main>
            <Index
              type="activity"
              indexList={[
                "NO.",
                "액티비티 명",
                "종목",
                "유형",
                "가격",
                "장소",
                "파트너",
                "상태",
              ]}
            />
            <ul>
              {activity ? activity.map((item, idx) => (
                <ScheduleRowCard
                  key={idx}
                  data={item}
                  idx={idx}
                  statusToggleHandler={() => statusToggleHandler(item.partner_id, item.service_id, idx)}
                  status={item.status}
                />
              )) : ""}
            </ul>
          </Main>

          <Footer>
            {/*<Pagination
              total={listData.length}
              limit={limit}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />*/}

          <Div>
            <SectionButton onClick={prevHandler}>&lt;</SectionButton>

            <List>
              {PageList.length ? PageList.map((item, idx) => 
                ( <PageButton
                    key={idx}
                    onClick={() => PageChange(item)}
                    aria-current={page == item ? "page" : null}
                    active={page == item ? true : false}
                  >
                    {item}
                  </PageButton>
                )) : ""}
            </List>

            <SectionButton onClick={nextHandler}>&gt;</SectionButton>
          </Div>

          </Footer>
        </MainLayout>
      </PartnerLayout>

      <SchedulePopup status={onOff} OnOff={setOnOff}/>

      <OverlayPortal>
        {modal && (
          <>
            <Overlay toggleHandler={modalToggleHandler} />

            <FilterModal
              value={value}
              closeHandler={modalToggleHandler}
              applyHandler={applyHandler}
              resetHandler={resetHandler}
            />
          </>
        )}
      </OverlayPortal>
    </>
  );
};

export default SchedulePage;
