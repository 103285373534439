import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";

// components
import {
  Wrapper,
  Icon,
  Text,
  Devive,
  FixedWrapper,
} from "../../layouts/layoutStorage";
import colors from "../../styles/constants/colors";

const ImageCrop = ({ info, setInfo, setImg, img }) => {
  const Status = info.status;
  const cropImage = info.image;
  const Width = info.width;
  const Height = info.height;
  const Type = info.type;
  const Number = info.number;
  const Scale = info.scale;

  let cropper = "";

  useEffect(() => {
    if (cropper) {
      cropper.reset();
      cropper.destroy();
    }

    if (cropImage) {
      const image = document.getElementById("cropImg");

      if (Scale) {
        cropper = new Cropper(image, {
          aspectRatio: Width / Height,
        });
      } else {
        cropper = new Cropper(image);
      }
    }
  }, [info]);

  const CloseCrop = () => {
    if (cropper) {
      cropper.reset();
      cropper.destroy();
    }

    setInfo({
      width: 1,
      hegiht: 1,
      type: "",
      number: "",
      image: "",
      status: false,
      scale: false,
    });

    let InputFile = document.getElementById(Type);
    InputFile.value = "";
  };

  const CropImage = () => {
    if (window.confirm("이미지를 자르시겠습니까?")) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          var base64data = reader.result;

          if (
            Type == "lift_ticket" ||
            Type == "clothes_ticket" ||
            Type == "equipment_ticket"
          ) {
            var TypeList = `${Type}_list`;
            var Data = img;
            var DataList = Data[TypeList];

            if (Number == "new") {
              var DataValue = {
                id: Number,
                ticket: base64data,
                change: "yes",
              };

              DataList[DataList.length] = DataValue;
            } else {
              var DataValue = DataList[Number];
              DataValue["ticket"] = base64data;
              DataValue["change"] = "yes";
              DataList[Number] = DataValue;
            }

            setImg({ ...img, [TypeList]: DataList });
          } else if (
            Type == "center_image_1" ||
            Type == "center_image_2" ||
            Type == "center_image_3"
          ) {
            var Data = img;
            var DataList = Data.image;

            if (Type == "center_image_1") {
              var DataListValue = DataList[0];

              if (DataListValue) {
                DataListValue["change"] = "yes";
                DataListValue["image"] = base64data;
                DataList[0] = DataListValue;
              } else {
                DataList[0] = {
                  id: "new",
                  image: base64data,
                  change: "yes",
                };
              }
            } else if (Type == "center_image_2") {
              var DataListValue = DataList[1];

              if (DataListValue) {
                DataListValue["change"] = "yes";
                DataListValue["image"] = base64data;
                DataList[1] = DataListValue;
              } else {
                DataList[1] = {
                  id: "new",
                  image: base64data,
                  change: "yes",
                };
              }
            } else if (Type == "center_image_3") {
              var DataListValue = DataList[2];

              if (DataListValue) {
                DataListValue["change"] = "yes";
                DataListValue["image"] = base64data;
                DataList[2] = DataListValue;
              } else {
                DataList[2] = {
                  id: "new",
                  image: base64data,
                  change: "yes",
                };
              }
            }

            setImg({ ...img, image: DataList });
          } else if (
            Type == "bank_image" ||
            Type == "id_card_image" ||
            Type == "business_image"
          ) {
            var Data = img;
            var DataList = Data[Type];

            DataList["change"] = "yes";
            DataList["image"] = base64data;

            console.log(DataList);

            setImg({ ...img, [Type]: DataList });
          } else if (Type == "activity") {
            let Data = img;
            // console.log(img);
            let DataList = Data["background"];
            DataList.push(base64data);

            console.log(DataList);
            setImg({ ...img, ["background"]: DataList });
            console.log(img);

            // DataList["image"] = base64data;
            // console.log(DataList);
          } else {
            setImg({ ...img, [Type]: base64data });
          }

          cropper.reset();
          cropper.destroy();
          setInfo({
            width: 1,
            hegiht: 1,
            type: "",
            number: "",
            image: "",
            status: false,
            scale: false,
          });
          let InputFile = document.getElementById(Type);
          InputFile.value = "";
        };
      });
    }
  };

  return (
    <FixedWrapper
      bgc={colors.W50}
      width={`100vw`}
      height={`100vh`}
      z={`105`}
      display={Status ? `inline-flex` : `none`}
      top={`0`}
      right={`0`}
      jc={`center`}
    >
      <Wrapper
        padding={`20px`}
        width={`50vw`}
        wrap={`wrap`}
        radius={`16px`}
        bgc={colors.White}
        z={`100`}
      >
        <Wrapper display={`inline-block`} width={`100%`} height={`50vh`}>
          <Icon
            display={`block`}
            id={`cropImg`}
            width={`100%`}
            src={cropImage ? cropImage : ""}
          />
        </Wrapper>

        <Devive
          width={`100%`}
          height={`1px`}
          bgc={colors.White}
          margin={`10px 0 10px 0`}
        />

        <Wrapper width={`100%`} jc={`flex-end`}>
          <Wrapper
            onClick={() => CloseCrop()}
            margin={`0 10px 0 0`}
            cursor={`pointer`}
            padding={`12px 20px`}
            radius={`8px`}
            bgc={colors.Gr01}
          >
            <Text weight={`500`} color={colors.Gr04} className="f16">
              닫기
            </Text>
          </Wrapper>

          <Wrapper
            onClick={() => CropImage()}
            cursor={`pointer`}
            padding={`12px 20px`}
            radius={`8px`}
            bgc={colors.Bl07}
          >
            <Text weight={`500`} color={colors.White} className="f16">
              자르기
            </Text>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </FixedWrapper>
  );
};

export default ImageCrop;
