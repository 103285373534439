import { useState } from "react";

const useInput = (state) => {
  const [value, setValue] = useState(state);
  const [change, setChange] = useState(false);

  const changeHandler = (e) => {
    const { name, value } = e.target;

    if(name == 'text') {
      setValue((prev) => ({
        ...prev,
        [name]: value,
      }));
  
      setChange(true);
    } else if(name == 'location_type') { 
        setValue((prev) => ({
            ...prev,
            [name]: value,
            location_category : ""
          }));
      
          setChange(true);

    } else {
      setValue((prev) => ({
        ...prev,
        [name]: value,
      }));
  
      setChange(true);
    }

  };

  return { value, setValue, change, inputChangeHandler: changeHandler };
};

export default useInput;
