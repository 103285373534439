import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

// components
import {
  TableFilterContent,
  Wrapper,
  DetailHeader,
  Icon,
  Text,
  WrapperRow,
  Devive,
  Radio,
  TableContent,
  TableColumn,
  TableWrapper,
  Label,
} from "../../layouts/layoutStorage";
import { CXMessageTypeValue } from "../../layouts/textStorage";
import DropDown from "../../components/designedComponents/dropDown";
import DatePicker from "../../components/designedComponents/datePicker";
import TimePicker from "../../components/designedComponents/timePicker";
import PartnerPicker from "../../components/designedComponents/partnerPicker";
import LocationPicker from "../../components/designedComponents/locationPicker";
import ImageCrop from "../../components/designedComponents/imageCrop";

// icon
import ReservationCheck from "../../assets/icon/reservation_check.png";
import ReservationConfirm from "../../assets/icon/reservation_confirm.png";
import ReservationCancel from "../../assets/icon/reservation_cancel.png";
import ReservationClear from "../../assets/icon/reservation_clear.png";
import ReservationDone from "../../assets/icon/reservation_done.png";
import SearchIcon from "../../assets/icon/search.png";
import PaymentSport from "../../assets/icon/payment_sport.png";
import PaymentLocation from "../../assets/icon/payment_location.png";
import PaymentPeople from "../../assets/icon/payment_people.png";
import PaymentCalendar from "../../assets/icon/payment_calendar.png";
import PaymentBell from "../../assets/icon/payment_bell.png";
import PaymentArrow from "../../assets/icon/payment_arrow.png";
import EmptyImage from "../../assets/icon/emptyImage.png";

const SalesDetail = () => {
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState(true);
  const [noticeInfo, setNoticeInfo] = useState(true);
  const [ticketInfo, setTicketInfo] = useState(true);
  const [amountInfo, setAmountInfo] = useState(true);
  const [datePicker, setDatePicker] = useState(false);
  const [timePicker, setTimePicker] = useState(false);

  const [partnerPicker, setPartnerPicker] = useState(false);
  const [partnerText, setPartnerText] = useState("");
  const [partnerList, setPartnerList] = useState([]);

  const [locationPicker, setLocationPicker] = useState(false);
  const [locationText, setLocationText] = useState("");
  const [locationList, setLocationList] = useState([]);

  const [reservationInfo, setReservationInfo] = useState({});
  const [userSpec, setUserSpec] = useState("");
  const [ticketSpec, setTicketSpec] = useState("");
  const [cropImage, setCropImage] = useState({
    width: 1,
    hegiht: 1,
    type: "",
    number: "",
    image: "",
    status: false,
    scale: false,
  });

  const { id } = useParams();

  const getData = () => {
    var token = window.localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", "specific_new");
    frm.append("token", token);
    frm.append("number", id);

    axios
      .post("https://ai.semos.kr/semos_admin/payment/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var status = Value.status;
        var statusMessage = Value.status_message;
        var paymentData = Value.payment_data;

        if (status == "success") {
          setReservationInfo(paymentData);
          setUserSpec(paymentData.user_spec);
          setTicketSpec(paymentData.ticket);
        } else if (status == "waring") {
          window.alert(statusMessage);
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const StatusList = {
    used_c: "예약확정",
    check: "결제완료",
    done: "액티비티 완료",
    cancel: "환불/취소",
    clear: "정산 완료",
  };

  const StatusText = (status) => {
    switch (status) {
      case "예약확정":
        return `이제 액티비티를 준비해 볼까요?`;

      case "결제완료":
        return `액티비티를 확정해 주세요`;

      case "정산 완료":
        return `파트너님께 정산 완료!`;

      case "액티비티 완료":
        return `이제 액티비티를 준비해 볼까요?`;

      case "환불/취소":
        return `액티비티가 취소되었어요`;

      default :
        return ""        
    }
  };

  const CountType = (type, count) => {
    switch (type) {
      case "이용권(횟수)":
        return `${count} 회`;

      case "이용권(기간)":
        return `${count} 개월`;

      case "정기 클래스":
        return `${count} 개월`;

      default :
        return `${count} 인`        
    }
  };

  const TypeCheck = (type) => {
    switch (type) {
      case "이용권(횟수)":
        return false;

      case "이용권(기간)":
        return false;

      case "정기 클래스":
        return false;

      default :
        return true;        
    }
  };

  const StatusComponent = (status) => {
    switch (status) {
      case "예약확정":
        return (
          <Wrapper bgc={`#66A2FF`} padding={`4px 10px`} radius={`16px`}>
            <Icon
              margin={`0 2px 0 0`}
              width={`calc(1rem * (20 / 14))`}
              src={ReservationCheck}
            />
            <Text color={`#ffffff`} weight={`500`}>
              {`${status}`}
            </Text>
          </Wrapper>
        );
        break;

      case "결제완료":
        return (
          <Wrapper bgc={`#A9BA82`} padding={`4px 10px`} radius={`16px`}>
            <Icon
              margin={`0 2px 0 0`}
              width={`calc(1rem * (20 / 14))`}
              src={ReservationConfirm}
            />
            <Text color={`#ffffff`} weight={`500`}>
              {`${status}`}
            </Text>
          </Wrapper>
        );
        break;

      case "정산 완료":
        return (
          <Wrapper bgc={`#E5F0FF`} padding={`4px 10px`} radius={`16px`}>
            <Icon
              margin={`0 2px 0 0`}
              width={`calc(1rem * (20 / 14))`}
              src={ReservationClear}
            />
            <Text color={`#80B2FF`} weight={`500`}>
              {`${status}`}
            </Text>
          </Wrapper>
        );
        break;

      case "액티비티 완료":
        return (
          <Wrapper bgc={`#E5F0FF`} padding={`4px 10px`} radius={`16px`}>
            <Icon
              margin={`0 2px 0 0`}
              width={`calc(1rem * (20 / 14))`}
              src={ReservationDone}
            />
            <Text color={`#80B2FF`} weight={`500`}>
              {`${status}`}
            </Text>
          </Wrapper>
        );
        break;

      case "환불/취소":
        return (
          <Wrapper bgc={`#FFE4E1`} padding={`4px 10px`} radius={`16px`}>
            <Icon
              margin={`0 2px 0 0`}
              width={`calc(1rem * (20 / 14))`}
              src={ReservationCancel}
            />
            <Text color={`#FF9187`} weight={`500`}>
              {`${status}`}
            </Text>
          </Wrapper>
        );
        break;
    }
  };

  const DateSelect = (d, month, next) => {
    if (month) {
      let Year = reservationInfo.date_y;
      if (next) {
        var Mon = Number(reservationInfo.date_m);
      } else {
        var Mon = Number(reservationInfo.date_m) - 2;
      }

      let Day = Number(1);

      let NewDate = new Date(Year, Mon, Day);

      let NewYear = NewDate.getFullYear();
      let NewMonth = NewDate.getMonth() + 1;
      let NewDay = NewDate.getDate();

      if (NewMonth < 10) {
        NewMonth = `0${NewMonth}`;
      }

      if (NewDay < 10) {
        NewDay = `0${NewDay}`;
      }

      setTimeout(() => {
        setDatePicker(true);
      }, 10);

      setReservationInfo({
        ...reservationInfo,
        date_y: NewYear,
        date_m: NewMonth,
        date_d: NewDay,
      });
    } else {
      if (d < 10) {
        d = `0${d}`;
      }

      setReservationInfo({ ...reservationInfo, date_d: d });
    }
  };

  const TimeSelect = (time) => {
    setReservationInfo({ ...reservationInfo, date_t: time });
    setTimePicker(false);
  };

  const setImageFromFile = ({ file, setImageUrl }) => {
    let reader = new FileReader();
    reader.onload = function () {
      setImageUrl({ result: reader.result });
    };

    reader.readAsDataURL(file);
  };

  const ImgListChange = (value, type, number) => {
    setCropImage({
      width: 1,
      hegiht: 1,
      type: type,
      number: number,
      image: value,
      status: true,
      scale: false,
    });
  };

  const TimeCheck = (time) => {
    if (time) {
      const time_split = time.split(":");

      var time_h = time_split[0];
      const time_m = time_split[1];
      var time_t = "오전";

      if (time_h > 11) {
        time_t = "오후";
        if (Number(time_h) != 12) {
          time_h = Number(time_h) - 12;

          if (time_h < 10) {
            time_h = `0${time_h}`;
          }
        }
      }

      return `${time_t} ${time_h}:${time_m}`;
    }
  };

  const PartnerSearch = (text) => {
    setPartnerText(text);

    if (text) {
      var token = window.localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "partner_search2");
      frm.append("token", token);
      frm.append("text", text);

      axios
        .post("https://ai.semos.kr/semos_admin/partner/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            setPartnerList(Value.user_id_list);

            if (!partnerPicker) {
              setPartnerPicker(true);
            }
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    } else {
      setPartnerList([]);
      setPartnerPicker(false);
    }
  };

  const EnterSearch = (key) => {
    if (key == "Enter") {
      PartnerSearch(partnerText);
    }
  };

  const LocationSearch = (text) => {
    setLocationText(text);

    if (text) {
      var token = window.localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "location_search");
      frm.append("token", token);
      frm.append("text", text);

      axios
        .post("https://ai.semos.kr/semos_admin/partner/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            setLocationList(Value.location_list);

            if (!locationPicker) {
              setLocationPicker(true);
            }
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    } else {
      setLocationList([]);
      setLocationPicker(false);
    }
  };

  const EnterLocation = (key) => {
    if (key == "Enter") {
      LocationSearch(partnerText);
    }
  };

  const ChangeUserSpec = (number, type, value) => {
    let UserSpecOG = [...userSpec];
    let UserSpecData = UserSpecOG[number];
    UserSpecData[type] = value;
    UserSpecData["change"] = "yes";

    UserSpecOG[number] = UserSpecData;

    setUserSpec(UserSpecOG);
  };

  const PaymentSave = () => {
    var token = window.localStorage.getItem("token");

    axios
      .post(
        "https://ai.semos.kr/semos_admin/payment/data",
        {
          mode: "payment_save",
          token: token,
          user_spec: userSpec,
          ticket_spec: ticketSpec,
          info: reservationInfo,
        },
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var statusMessage = Value.status_message;

        if (Status == "success") {
          alert(statusMessage);
          window.location.reload();
        } else if (Status == "waring") {
          alert(statusMessage);
        }
      });
  };

  const SendGo = (link) => {
    document.body.style.removeProperty("overflow");
    document.getElementById("root").style.pointerEvents = "auto";

    setTimeout(() => {
      navigate(link);
    }, 500);
  };

  const payMethod = {
    "after_pay" : "현장 결제",
    "empty" : "쿠폰/포인트",
    "kakao" : "카카오페이",
    "toss" : "토스"
  }

  return (
    <>
      <Wrapper
        bgc="#ffffff"
        of={`hidden auto`}
        margin="10px auto 0 auto"
        display={`inline-block`}
        width={`100%`}
        mw={`1000px`}
        mh={`calc(100vh - 72px)`}
        padding={`10px 0`}
      >
        <DetailHeader padding={`0 36px`} margin={`0 0 28px 0`}>
          <Wrapper jc={`flex-start`}>
            {StatusComponent(
              StatusList[`${reservationInfo && reservationInfo.status}`]
            )}

            <Text
              weight={`500`}
              className={`f20`}
              color={`#0E6DFF`}
              margin={`0 0 0 10px`}
            >
              {StatusText(
                StatusList[`${reservationInfo && reservationInfo.status}`]
              )}
            </Text>
          </Wrapper>

          <Wrapper jc={`flex-start`}>
            <DropDown
              data={reservationInfo && StatusList[reservationInfo.status]}
              setData={setReservationInfo}
              option={[
                { text: `결제완료`, value: `check` },
                { text: `예약확정`, value: `used_c` },
                { text: `액티비티 완료`, value: `done` },
                { text: `정산완료`, value: `clear` },
                { text: `환불/취소`, value: `cancel` },
              ]}
              type={`object`}
              defaultText={`결제완료`}
              no={false}
              keyName={`status`}
            />

            <Wrapper
              onClick={() => PaymentSave()}
              cursor={`pointer`}
              radius={`8px`}
              bgc={`#0E6DFF`}
              padding={`6.5px 12px`}
              jc={`center`}
            >
              <Text
                lh={`none`}
                color={`#ffffff`}
                weight={`500`}
                className={`f12`}
              >
                저장
              </Text>
            </Wrapper>
          </Wrapper>
        </DetailHeader>

        <DetailHeader padding={`0 36px`} margin={`0 0 30px 0`}>
          <Wrapper>
            <Text wrap={`wrap`} color={`#ACAFB5`} weight={`500`}>
              파트너 :&nbsp;
            </Text>
            <Text wrap={`wrap`} color={`#3C3E41`} weight={`500`}>{`${
              reservationInfo && reservationInfo.partner
            }`}</Text>
          </Wrapper>

          {(reservationInfo && reservationInfo.service_type != "시설 이용권") && (
            <TableFilterContent margin={`0`}>
              <Input
                onKeyUp={(e) => EnterSearch(e.key)}
                value={partnerText}
                onChange={(e) => PartnerSearch(e.target.value)}
                width={`400px`}
                type={`text`}
                maxLength={40}
                placeholder="변경할 파트너 이름 혹은 번호를 입력해 주세요"
              />
              <Icon
                width={`calc(1rem * (17 / 14))`}
                cursor={`pointer`}
                src={SearchIcon}
                onClick={(e) => PartnerSearch(partnerText)}
              />

              {partnerText && (
                <PartnerPicker
                  info={reservationInfo}
                  partner={partnerList}
                  setPartner={setReservationInfo}
                  status={partnerPicker}
                  text={partnerText}
                  setText={setPartnerText}
                  id={id}
                />
              )}
            </TableFilterContent>
          )}
        </DetailHeader>

        <Wrapper padding={`0 36px`} width={`100%`} jc={`space-between`}>
          <Wrapper
            radius={`8px`}
            bgc={`#F5F5F5`}
            width={`200px`}
            height={`125px`}
          >
            <Icon
              src={`${reservationInfo && reservationInfo.service_thumbnail}`}
              width={`200px`}
            />
          </Wrapper>

          <WrapperRow
            ai={`stretch`}
            jc={`space-between`}
            width={`calc(100% - 220px)`}
            height={`125px`}
            wrap={`wrap`}
          >
            <Text
              ai={`flex-start`}
              color={`#3C3E41`}
              weight={`500`}
              className={`f16`}
            >{`${reservationInfo && reservationInfo.service}`}</Text>

            <Wrapper width={`100%`} wrap={`wrap`}>
              <Wrapper width={`100%`}>
                <Wrapper width={`120px`}>
                  <Icon
                    margin={`0 4px 0 0`}
                    src={PaymentLocation}
                    width={`16px`}
                  />
                  <Text color={`#818792`} weight={`500`} className={`f12`}>
                    예약 장소{" "}
                  </Text>
                </Wrapper>

                <Wrapper width={`calc(100% - 120px)`}>
                  <Text color={`#3C3E41`} weight={`500`} className={`f12`}>{`${
                    reservationInfo && reservationInfo.service_location
                  }`}</Text>
                </Wrapper>
              </Wrapper>

              <Wrapper width={`100%`}>
                <Wrapper width={`120px`}>
                  <Icon
                    margin={`0 4px 0 0`}
                    src={PaymentSport}
                    width={`16px`}
                  />
                  <Text color={`#0E6DFF`} weight={`500`} className={`f12`}>
                    스포츠{" "}
                  </Text>
                  <Text color={`#818792`} weight={`500`} className={`f12`}>
                    &nbsp;| 유형{" "}
                  </Text>
                </Wrapper>

                <Wrapper width={`calc(100% - 120px)`}>
                  <Text color={`#0E6DFF`} weight={`500`} className={`f12`}>{`${
                    reservationInfo && reservationInfo.sport
                  }`}</Text>
                  { (TypeCheck(reservationInfo?.service_type) == true) ?
                    <Text color={`#3C3E41`} weight={`500`} className={`f12`}>
                      &nbsp;|{" "}
                      {`${reservationInfo && reservationInfo.service_duration}`}{" "}
                    </Text>
                    : ""
                  }
                  <Text color={`#3C3E41`} weight={`500`} className={`f12`}>
                    &nbsp;|{" "}
                    {`${reservationInfo && reservationInfo.service_type}`}{" "}
                  </Text>
                </Wrapper>
              </Wrapper>

              <Wrapper width={`100%`}>
                <Wrapper width={`120px`}>
                  <Icon
                    margin={`0 4px 0 0`}
                    src={PaymentPeople}
                    width={`16px`}
                  />
                  <Text color={`#818792`} weight={`500`} className={`f12`}>
                    예약 인원{" "}
                  </Text>
                </Wrapper>

                <Wrapper width={`calc(100% - 120px)`}>
                  <Text color={`#3C3E41`} weight={`500`} className={`f12`}>
                    {CountType(reservationInfo?.service_type, reservationInfo?.people)}
                  </Text>
                </Wrapper>
              </Wrapper>
            </Wrapper>
          </WrapperRow>
        </Wrapper>

        <Devive
          width={`100%`}
          height={`1px`}
          bgc={`#E6E6E6`}
          margin={`30px 0 36px 0`}
        />

        <Wrapper
          wrap={`wrap`}
          padding={`0 36px`}
          width={`100%`}
          jc={`space-between`}
        >
          <Text
            margin={`0 0 16px 0`}
            color={`#3C3E4`}
            width={`100%`}
            weight={`700`}
            className={`f16`}
          >
            예약 정보
          </Text>

          <Wrapper width={`100%`} margin={`0 0 25px 0`} jc={`flex-start`}>
            <Text width={`130px`} color={`#3C3E41`} weight={`500`}>
              예약 일정
            </Text>

            <TableFilterContent
              onClick={() => {
                setTimeout(() => setDatePicker(!datePicker), 1);
              }}
              cursor={`pointer`}
              margin={`0`}
            >
              <Text
                color={`#3C3E4`}
                width={`calc(1rem * (120 / 14))`}
                weight={`500`}
                className={`f13`}
              >
                {`${reservationInfo && reservationInfo.date_y}년 ${
                  reservationInfo && reservationInfo.date_m
                }월 ${reservationInfo && reservationInfo.date_d}일`}
              </Text>
              <Icon
                margin={`0 0 0 6px`}
                width={`calc(1rem * (20 / 14))`}
                cursor={`pointer`}
                src={PaymentCalendar}
                onClick={() => setDatePicker(!datePicker)}
              />
              <div>
                <DatePicker
                  Y={reservationInfo && reservationInfo.date_y}
                  M={reservationInfo && reservationInfo.date_m}
                  D={reservationInfo && reservationInfo.date_d}
                  setDate={DateSelect}
                  status={datePicker}
                  setStatus={setDatePicker}
                />
              </div>
            </TableFilterContent>

            <TableFilterContent cursor={`pointer`} margin={`0 0 0 10px`}>
              <Text
                onClick={() => {
                  setTimeout(() => setTimePicker(!timePicker), 1);
                }}
                margin={`0 6px 0 0`}
                color={`#3C3E4`}
                width={`100%`}
                weight={`500`}
                className={`f13`}
              >
                {TimeCheck(reservationInfo && reservationInfo.date_t)}
              </Text>

              <Icon
                width={`calc(1rem * (20 / 14))`}
                src={PaymentBell}
                onClick={() => {
                  setTimeout(() => setTimePicker(!timePicker), 1);
                }}
              />
              <TimePicker
                time={reservationInfo && reservationInfo.date_t}
                setTime={TimeSelect}
                status={timePicker}
                setStatus={setTimePicker}
              />
            </TableFilterContent>
          </Wrapper>

          <Wrapper width={`100%`} jc={`flex-start`}>
            <Text width={`130px`} color={`#3C3E41`} weight={`500`}>
              액티비티 장소
            </Text>

            <TableFilterContent margin={`0`}>
              <Input
                onKeyUp={(e) => EnterSearch(e.key)}
                value={locationText}
                onChange={(e) => LocationSearch(e.target.value)}
                width={`400px`}
                type={`text`}
                maxLength={40}
                placeholder="변경할 지역을 입력해주세요"
              />
              <Icon
                width={`calc(1rem * (17 / 14))`}
                cursor={`pointer`}
                src={SearchIcon}
                onClick={(e) => EnterLocation(locationText)}
              />

              {locationText && (
                <LocationPicker
                  info={reservationInfo}
                  location={locationList}
                  setLocation={setReservationInfo}
                  status={locationPicker}
                  text={locationText}
                  setText={setLocationText}
                  id={id}
                />
              )}
            </TableFilterContent>
          </Wrapper>
        </Wrapper>

        <Devive
          width={`100%`}
          height={`1px`}
          bgc={`#E6E6E6`}
          margin={`36px 0 36px 0`}
        />

        <Wrapper wrap={`wrap`} padding={`0 36px`} width={`100%`}>
          <Wrapper margin={`0 0 16px 0`} width={`100%`} jc={`space-between`}>
            <Text color={`#3C3E4`} weight={`700`} className={`f16`}>
              액티비티 이용자 정보
            </Text>

            <Icon
              width={`calc(1rem * (18 / 14))`}
              src={PaymentArrow}
              cursor={`pointer`}
              transform={userInfo ? `rotate(0)` : `rotate(180deg)`}
              transition={`all 0.3s ease`}
              onClick={() => setUserInfo(!userInfo)}
            />
          </Wrapper>

          <Wrapper
            transition={`all 0.3s ease`}
            of={`hidden`}
            mh={userInfo ? `unset` : `0`}
            width={`100%`}
            wrap={`wrap`}
          >
            {userSpec &&
              userSpec?.map((item, idx) => (
                <Wrapper
                  key={`${idx}_user_spec`}
                  margin={`0 0 24px 0`}
                  width={`100%`}
                  wrap={`wrap`}
                >
                  <Wrapper
                    margin={`0 0 20px 0`}
                    width={`100%`}
                    jc={`space-between`}
                  >
                    <Text color={`#3383FF`} weight={`600`}>
                      이용자 {`${idx + 1}`}
                    </Text>

                    <Wrapper>
                      <Text color={`#3383FF`} weight={`600`}>{`${
                        idx + 1
                      }`}</Text>
                      <Text color={`#ACAFB5`} weight={`600`}>
                        &nbsp;{`/ ${reservationInfo.people}`}
                      </Text>
                    </Wrapper>
                  </Wrapper>
                  {idx == 0 ? (
                    <Wrapper
                      margin={`0 0 8px 0`}
                      jc={`space-between`}
                      width={`100%`}
                      wrap={`wrap`}
                    >
                      <Text width={`130px`} color={`#3C3E41`} weight={`500`}>
                        이용자 이름
                      </Text>

                      <Wrapper
                        jc={`space-between`}
                        width={`calc(100% - 140px)`}
                      >
                        <TableFilterContent width={`40%`} margin={`0`}>
                          <Input
                            width={`100%`}
                            type={`text`}
                            maxLength={10}
                            placeholder="이용자 명"
                            value={`${item.name}`}
                            onChange={(e) =>
                              ChangeUserSpec(idx, "name", e.target.value)
                            }
                          />
                        </TableFilterContent>

                        <Wrapper
                          padding={`0 0 0 18px`}
                          jc={`space-between`}
                          width={`60%`}
                        >
                          <Text width={`70px`} color={`#3C3E41`} weight={`500`}>
                            전화번호
                          </Text>

                          <TableFilterContent
                            width={`calc(100% - 90px)`}
                            margin={`0`}
                          >
                            <Input
                              width={`100%`}
                              type={`text`}
                              maxLength={14}
                              placeholder="01*-****-****"
                              readOnly
                              value={`${reservationInfo.user_phone}`}
                            />
                          </TableFilterContent>
                        </Wrapper>
                      </Wrapper>
                    </Wrapper>
                  ) : (
                    <Wrapper
                      margin={`0 0 8px 0`}
                      jc={`space-between`}
                      width={`100%`}
                      wrap={`wrap`}
                    >
                      <Text width={`130px`} color={`#3C3E41`} weight={`500`}>
                        이용자 이름
                      </Text>

                      <Wrapper
                        jc={`space-between`}
                        width={`calc(100% - 140px)`}
                      >
                        <TableFilterContent width={`100%`} margin={`0`}>
                          <Input
                            width={`100%`}
                            type={`text`}
                            maxLength={10}
                            placeholder="이용자 명"
                            value={`${item.name}`}
                            onChange={(e) =>
                              ChangeUserSpec(idx, "name", e.target.value)
                            }
                          />
                        </TableFilterContent>
                      </Wrapper>
                    </Wrapper>
                  )}

                  <Wrapper
                    margin={`0 0 8px 0`}
                    jc={`space-between`}
                    width={`100%`}
                    wrap={`wrap`}
                  >
                    <Text width={`130px`} color={`#3C3E41`} weight={`500`}>
                      생년월일
                    </Text>

                    <Wrapper jc={`flex-start`} width={`calc(100% - 140px)`}>
                      <TableFilterContent width={`40%`} margin={`0 18px 0 0`}>
                        <Input
                          width={`100%`}
                          type={`text`}
                          maxLength={6}
                          placeholder="생년월일"
                          value={`${item.birth}`}
                          onChange={(e) =>
                            ChangeUserSpec(idx, "birth", e.target.value)
                          }
                        />
                      </TableFilterContent>

                      <Wrapper jc={`flex-start`} width={`calc(60% - 18px)`}>
                        <Wrapper cursor={`pointer`}>
                          <Text
                            margin={`0 10px 0 0`}
                            color={`#818792`}
                            weight={`500`}
                          >
                            남
                          </Text>
                          <Radio
                            onClick={() => ChangeUserSpec(idx, "gender", "1")}
                            width={`16`}
                            padding={`3`}
                            border={`1px solid ${
                              item.gender == "1" ? `#0E6DFF` : `#ACAFB5`
                            }`}
                            bgc={`${
                              item.gender == "1" ? `#0E6DFF` : `#ffffff`
                            }`}
                          >
                            <div />
                          </Radio>
                        </Wrapper>

                        <Wrapper padding={`0 0 0 14px`} cursor={`pointer`}>
                          <Text
                            margin={`0 10px 0 0`}
                            color={`#818792`}
                            weight={`500`}
                          >
                            여
                          </Text>
                          <Radio
                            onClick={() => ChangeUserSpec(idx, "gender", "2")}
                            width={`16`}
                            padding={`3`}
                            border={`1px solid ${
                              item.gender == "2" ? `#0E6DFF` : `#ACAFB5`
                            }`}
                            bgc={`${
                              item.gender == "2" ? `#0E6DFF` : `#ffffff`
                            }`}
                          >
                            <div />
                          </Radio>
                        </Wrapper>
                      </Wrapper>
                    </Wrapper>
                  </Wrapper>

                  <Wrapper
                    margin={`0 0 8px 0`}
                    jc={`space-between`}
                    width={`100%`}
                    wrap={`wrap`}
                  >
                    <Text width={`130px`} color={`#3C3E41`} weight={`500`}>
                      키 (cm)
                    </Text>

                    <TableFilterContent width={`calc(100% - 140px)`}>
                      <Input
                        width={`100%`}
                        type={`text`}
                        maxLength={3}
                        placeholder="키"
                        value={`${item.height}`}
                        onChange={(e) =>
                          ChangeUserSpec(idx, "height", e.target.value)
                        }
                      />
                    </TableFilterContent>
                  </Wrapper>

                  <Wrapper
                    margin={`0 0 8px 0`}
                    jc={`space-between`}
                    width={`100%`}
                    wrap={`wrap`}
                  >
                    <Text width={`130px`} color={`#3C3E41`} weight={`500`}>
                      체중 (kg)
                    </Text>

                    <TableFilterContent width={`calc(100% - 140px)`}>
                      <Input
                        width={`100%`}
                        type={`text`}
                        maxLength={3}
                        placeholder="체중"
                        value={`${item.weight}`}
                        onChange={(e) =>
                          ChangeUserSpec(idx, "weight", e.target.value)
                        }
                      />
                    </TableFilterContent>
                  </Wrapper>

                  <Wrapper jc={`space-between`} width={`100%`} wrap={`wrap`}>
                    <Text width={`130px`} color={`#3C3E41`} weight={`500`}>
                      발 사이즈 (mm)
                    </Text>

                    <TableFilterContent width={`calc(100% - 140px)`}>
                      <Input
                        width={`100%`}
                        type={`text`}
                        maxLength={3}
                        placeholder="발 사이즈"
                        value={`${item.foot}`}
                        onChange={(e) =>
                          ChangeUserSpec(idx, "foot", e.target.value)
                        }
                      />
                    </TableFilterContent>
                  </Wrapper>
                </Wrapper>
              ))}

            {reservationInfo &&
              reservationInfo.service_type == "시설 이용권" && (
                <Wrapper
                  key={`1_user_spec`}
                  margin={`0 0 0 0`}
                  width={`100%`}
                  wrap={`wrap`}
                >
                  <Wrapper
                    margin={`0 0 8px 0`}
                    jc={`space-between`}
                    width={`100%`}
                    wrap={`wrap`}
                  >
                    <Text width={`130px`} color={`#3C3E41`} weight={`500`}>
                      이용자 이름
                    </Text>

                    <Wrapper jc={`space-between`} width={`calc(100% - 140px)`}>
                      <TableFilterContent width={`40%`} margin={`0`}>
                        <Input
                          width={`100%`}
                          type={`text`}
                          maxLength={10}
                          placeholder="이용자 명"
                          readOnly
                          value={`${reservationInfo.user}`}
                        />
                      </TableFilterContent>

                      <Wrapper
                        padding={`0 0 0 18px`}
                        jc={`space-between`}
                        width={`60%`}
                      >
                        <Text width={`70px`} color={`#3C3E41`} weight={`500`}>
                          전화번호
                        </Text>

                        <TableFilterContent
                          width={`calc(100% - 90px)`}
                          margin={`0`}
                        >
                          <Input
                            width={`100%`}
                            type={`text`}
                            maxLength={14}
                            readOnly
                            placeholder="01*-****-****"
                            value={`${reservationInfo.user_phone}`}
                          />
                        </TableFilterContent>
                      </Wrapper>
                    </Wrapper>
                  </Wrapper>
                </Wrapper>
              )}
          </Wrapper>
        </Wrapper>

        <Devive
          width={`100%`}
          height={`1px`}
          bgc={`#E6E6E6`}
          margin={`36px 0 36px 0`}
        />

        {reservationInfo && reservationInfo.service_type == "시설 이용권" && (
          <>
            <Wrapper wrap={`wrap`} padding={`0 36px`} width={`100%`}>
              <Wrapper
                margin={`0 0 16px 0`}
                width={`100%`}
                jc={`space-between`}
              >
                <Text color={`#3C3E4`} weight={`700`} className={`f16`}>
                  이용권 정보
                </Text>

                <Icon
                  width={`calc(1rem * (18 / 14))`}
                  src={PaymentArrow}
                  cursor={`pointer`}
                  transform={ticketInfo ? `rotate(0)` : `rotate(180deg)`}
                  transition={`all 0.3s ease`}
                  onClick={() => setTicketInfo(!ticketInfo)}
                />
              </Wrapper>

              <Wrapper
                ai={`flex-start`}
                transition={`height 0.7s ease`}
                of={`hidden`}
                mh={ticketInfo ? `unset` : `0`}
                width={`100%`}
                wrap={`wrap`}
              >
                <Wrapper
                  ai={`flex-start`}
                  jc={`center`}
                  width={`calc(100% / 3)`}
                  wrap={`wrap`}
                >
                  <Text
                    width={`100%`}
                    color={`#3C3E4`}
                    weight={`500`}
                    className={`f14`}
                  >
                    리프트권
                  </Text>

                  {ticketSpec &&
                    ticketSpec.lift_ticket_list?.map((item, idx) => (
                      <Label
                        key={`${idx}_lift_ticket`}
                        bgc={`#F5F5F5`}
                        radius={`8px`}
                        margin={`10px 0`}
                        cursor={`pointer`}
                        width={`calc(100% - 10px)`}
                        height={`calc( ( ( ( (1rem * 747) / 14) - 142px ) / 3 ) )`}
                        htmlFor={`${idx}_lift_ticket`}
                      >
                        <input
                          onChange={({ target: { files } }) => {
                            if (files.length) {
                              setImageFromFile({
                                file: files[0],
                                setImageUrl: ({ result }) => {
                                  ImgListChange(result, "lift_ticket", idx);
                                },
                              });
                            }
                          }}
                          id={`${idx}_lift_ticket`}
                          accept="image/*"
                          type="file"
                        />
                        <img src={item.ticket ? item.ticket : EmptyImage} />
                      </Label>
                    ))}

                  <Label
                    key={`new_lift_ticket`}
                    bgc={`#F5F5F5`}
                    radius={`8px`}
                    margin={`10px 0`}
                    cursor={`pointer`}
                    width={`calc(100% - 10px)`}
                    height={`calc( ( ( ( (1rem * 747) / 14) - 142px ) / 3 ) )`}
                    htmlFor={`new_lift_ticket`}
                  >
                    <input
                      onChange={({ target: { files } }) => {
                        if (files.length) {
                          setImageFromFile({
                            file: files[0],
                            setImageUrl: ({ result }) => {
                              ImgListChange(result, "lift_ticket", "new");
                            },
                          });
                        }
                      }}
                      id={`new_lift_ticket`}
                      accept="image/*"
                      type="file"
                    />
                    <img src={EmptyImage} />
                  </Label>

                  <Text
                    jc={`center`}
                    width={`100%`}
                    color={`#3C3E4`}
                    weight={`500`}
                    className={`f14`}
                  >
                    {ticketSpec &&
                      `성인 ${ticketSpec.lift_adult} / 소인 ${ticketSpec.lift_child}`}
                  </Text>
                </Wrapper>

                <Wrapper
                  ai={`flex-start`}
                  jc={`center`}
                  width={`calc(100% / 3)`}
                  wrap={`wrap`}
                >
                  <Text
                    width={`100%`}
                    color={`#3C3E4`}
                    weight={`500`}
                    className={`f14`}
                  >
                    장비 대여권
                  </Text>

                  {ticketSpec &&
                    ticketSpec.equipment_ticket_list?.map((item, idx) => (
                      <Label
                        key={`${idx}_equipment_ticket`}
                        bgc={`#F5F5F5`}
                        radius={`8px`}
                        margin={`10px 0`}
                        cursor={`pointer`}
                        width={`calc(100% - 10px)`}
                        height={`calc( ( ( ( (1rem * 747) / 14) - 142px ) / 3 ) )`}
                        htmlFor={`${idx}_equipment_ticket`}
                      >
                        <input
                          onChange={({ target: { files } }) => {
                            if (files.length) {
                              setImageFromFile({
                                file: files[0],
                                setImageUrl: ({ result }) => {
                                  ImgListChange(
                                    result,
                                    "equipment_ticket",
                                    idx
                                  );
                                },
                              });
                            }
                          }}
                          id={`${idx}_equipment_ticket`}
                          accept="image/*"
                          type="file"
                        />
                        <img src={item.ticket ? item.ticket : EmptyImage} />
                      </Label>
                    ))}

                  <Label
                    bgc={`#F5F5F5`}
                    radius={`8px`}
                    margin={`10px 0`}
                    cursor={`pointer`}
                    width={`calc(100% - 10px)`}
                    height={`calc( ( ( ( (1rem * 747) / 14) - 142px ) / 3 ) )`}
                    htmlFor={`new_equipment_ticket`}
                  >
                    <input
                      onChange={({ target: { files } }) => {
                        if (files.length) {
                          setImageFromFile({
                            file: files[0],
                            setImageUrl: ({ result }) => {
                              ImgListChange(result, "equipment_ticket", "new");
                            },
                          });
                        }
                      }}
                      id={`new_equipment_ticket`}
                      accept="image/*"
                      type="file"
                    />
                    <img src={EmptyImage} />
                  </Label>

                  <Text
                    jc={`center`}
                    width={`100%`}
                    color={`#3C3E4`}
                    weight={`500`}
                    className={`f14`}
                  >
                    {ticketSpec &&
                      `성인 ${ticketSpec.equipment_adult} / 소인 ${ticketSpec.equipment_child}`}
                  </Text>
                </Wrapper>

                <Wrapper
                  ai={`flex-start`}
                  jc={`center`}
                  width={`calc(100% / 3)`}
                  wrap={`wrap`}
                >
                  <Text
                    width={`100%`}
                    color={`#3C3E4`}
                    weight={`500`}
                    className={`f14`}
                  >
                    의류 대여권
                  </Text>

                  {ticketSpec &&
                    ticketSpec.clothes_ticket_list?.map((item, idx) => (
                      <Label
                        key={`${idx}_clothes_ticket`}
                        bgc={`#F5F5F5`}
                        radius={`8px`}
                        margin={`10px 0`}
                        cursor={`pointer`}
                        width={`calc(100% - 10px)`}
                        height={`calc( ( ( ( (1rem * 747) / 14) - 142px ) / 3 ) )`}
                        htmlFor={`${idx}_clothes_ticket`}
                      >
                        <input
                          onChange={({ target: { files } }) => {
                            if (files.length) {
                              setImageFromFile({
                                file: files[0],
                                setImageUrl: ({ result }) => {
                                  ImgListChange(result, "clothes_ticket", idx);
                                },
                              });
                            }
                          }}
                          id={`${idx}_clothes_ticket`}
                          accept="image/*"
                          type="file"
                        />
                        <img src={item.ticket ? item.ticket : EmptyImage} />
                      </Label>
                    ))}

                  <Label
                    bgc={`#F5F5F5`}
                    radius={`8px`}
                    margin={`10px 0`}
                    cursor={`pointer`}
                    width={`calc(100% - 10px)`}
                    height={`calc( ( ( ( (1rem * 747) / 14) - 142px ) / 3 ) )`}
                    htmlFor={`new_clothes_ticket`}
                  >
                    <input
                      onChange={({ target: { files } }) => {
                        if (files.length) {
                          setImageFromFile({
                            file: files[0],
                            setImageUrl: ({ result }) => {
                              ImgListChange(result, "clothes_ticket", "new");
                            },
                          });
                        }
                      }}
                      id={`new_clothes_ticket`}
                      accept="image/*"
                      type="file"
                    />
                    <img src={EmptyImage} />
                  </Label>

                  <Text
                    jc={`center`}
                    width={`100%`}
                    color={`#3C3E4`}
                    weight={`500`}
                    className={`f14`}
                  >
                    {ticketSpec &&
                      `성인 ${ticketSpec.clothes_adult} / 소인 ${ticketSpec.clothes_child}`}
                  </Text>
                </Wrapper>
              </Wrapper>
            </Wrapper>

            <Devive
              width={`100%`}
              height={`1px`}
              bgc={`#E6E6E6`}
              margin={`36px 0 36px 0`}
            />
          </>
        )}

        <Wrapper wrap={`wrap`} padding={`0 36px`} width={`100%`}>
          <Wrapper margin={`0 0 16px 0`} width={`100%`} jc={`space-between`}>
            <Text color={`#3C3E4`} weight={`700`} className={`f16`}>
              알림 내역
            </Text>

            <Icon
              width={`calc(1rem * (18 / 14))`}
              src={PaymentArrow}
              cursor={`pointer`}
              transform={noticeInfo ? `rotate(0)` : `rotate(180deg)`}
              transition={`all 0.3s ease`}
              onClick={() => setNoticeInfo(!noticeInfo)}
            />
          </Wrapper>

          <Wrapper
            transition={`height 0.7s ease`}
            of={`hidden`}
            mh={noticeInfo ? `unset` : `0`}
            width={`100%`}
            wrap={`wrap`}
          >
            <TableContent
              border={`border-bottom : 1px solid #DCDCDC; border-top : 1px solid #DCDCDC;`}
              bgc={`#F9F9F9`}
              wrap={`wrap`}
            >
              <section>
                <TableColumn
                  width={`15`}
                  ta={`center`}
                  padding={`8px 14px`}
                  weight={`500`}
                >
                  <Text className="f12" color={`#3C3E41`} weight={`500`}>
                    알림 종류
                  </Text>
                </TableColumn>

                <TableColumn
                  width={`20`}
                  ta={`left`}
                  padding={`8px 14px`}
                  weight={`500`}
                >
                  <Text className="f12" color={`#3C3E41`} weight={`500`}>
                    알림 대상
                  </Text>
                </TableColumn>

                <TableColumn
                  width={`40`}
                  ta={`left`}
                  padding={`8px 14px`}
                  weight={`500`}
                >
                  <Text className="f12" color={`#3C3E41`} weight={`500`}>
                    발송 내용
                  </Text>
                </TableColumn>

                <TableColumn
                  width={`25`}
                  ta={`center`}
                  padding={`8px 14px`}
                  weight={`500`}
                >
                  <Text className="f12" color={`#3C3E41`} weight={`500`}>
                    발송 일시
                  </Text>
                </TableColumn>
              </section>

              <section>
                {reservationInfo &&
                  reservationInfo.notice_list?.map((item, idx) => (
                    <TableWrapper
                      key={`notification_${idx}`}
                      cursor={`pointer`}
                      bgc={`#ffffff`}
                      width={`100%`}
                      bb={`1px solid #DCDCDC`}
                    >
                      <TableColumn
                        className="f12"
                        color={`#3C3E41`}
                        width={`15`}
                        ta={`center`}
                        padding={`8px 14px`}
                        weight={`500`}
                      >
                        알림톡
                      </TableColumn>

                      <TableColumn
                        className="f12"
                        color={`#3C3E41`}
                        width={`20`}
                        ta={`left`}
                        padding={`8px 14px`}
                        weight={`500`}
                      >
                        {item.user_name}
                      </TableColumn>

                      <TableColumn
                        className="f12"
                        color={`#3C3E41`}
                        width={`40`}
                        ta={`left`}
                        padding={`8px 14px`}
                        weight={`500`}
                      >
                        {CXMessageTypeValue[item.message_type]}
                      </TableColumn>

                      <TableColumn
                        className="f12"
                        color={`#ACAFB5`}
                        width={`25`}
                        ta={`center`}
                        padding={`8px 14px`}
                        weight={`500`}
                      >
                        {item.message_time.split(" ")[0]}
                      </TableColumn>
                    </TableWrapper>
                  ))}
              </section>
            </TableContent>

            <Wrapper margin={`24px 0 0 0`} jc={`flex-end`} width={`100%`}>
              <Wrapper
                onClick={() =>
                  SendGo(`/sales/${reservationInfo && reservationInfo.id}`)
                }
                bgc={`#0E6DFF`}
                cursor={`pointer`}
                jc={`center`}
                padding={`12px 20px`}
                radius={`8px`}
              >
                <Text className="f14" color={`#FFFFFF`} weight={`700`}>
                  알림톡 전송
                </Text>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>

        <Devive
          width={`100%`}
          height={`1px`}
          bgc={`#E6E6E6`}
          margin={`36px 0 36px 0`}
        />

        <Wrapper wrap={`wrap`} padding={`0 36px`} width={`100%`}>
          <Wrapper margin={`0 0 16px 0`} width={`100%`} jc={`space-between`}>
            <Text color={`#3C3E4`} weight={`700`} className={`f16`}>
              결제 정보
            </Text>

            <Icon
              width={`calc(1rem * (18 / 14))`}
              src={PaymentArrow}
              cursor={`pointer`}
              transform={amountInfo ? `rotate(0)` : `rotate(180deg)`}
              transition={`all 0.3s ease`}
              onClick={() => setAmountInfo(!amountInfo)}
            />
          </Wrapper>
        {
            reservationInfo.status == 'cancel' ?
                <Wrapper
                    transition={`all 0.3s ease`}
                    of={`hidden`}
                    mh={amountInfo ? `unset` : `0`}
                    width={`100%`}
                    wrap={`wrap`}
                >
                    <Wrapper margin={`0 0 30px 0`} width={`100%`} jc={`space-between`}>
                    <Text color={`#818792`} weight={`500`} className={`f14`}>
                        결제 수단
                    </Text>
                    <Text color={`#3383FF`} weight={`500`} className={`f14`}>{`${
                        reservationInfo &&
                        (reservationInfo.method == "kakao" ? "카카오페이" : "토스")
                    }`}</Text>
                    </Wrapper>

                    <Wrapper margin={`0 0 30px 0`} width={`100%`} jc={`space-between`}>
                    <Text color={`#818792`} weight={`500`} className={`f14`}>
                        액티비티 금액
                    </Text>
                    <Text color={`#3C3E41`} weight={`500`} className={`f14`}>
                        {`${reservationInfo && reservationInfo.partner_payment}`} 원
                    </Text>
                    </Wrapper>

                    <Wrapper margin={`0 0 30px 0`} width={`100%`} jc={`space-between`}>
                    <Text color={`#818792`} weight={`500`} className={`f14`}>
                        포인트 / 쿠폰 할인
                    </Text>
                    <Text color={`#3C3E41`} weight={`500`} className={`f14`}>
                        - {`${reservationInfo && reservationInfo.point_text}`} 원
                    </Text>
                    </Wrapper>

                    <Wrapper margin={`0 0 30px 0`} width={`100%`} jc={`space-between`}>
                    <Text color={`#818792`} weight={`500`} className={`f14`}>
                        최종 결제금액
                    </Text>
                    <Text color={`#3383FF`} weight={`500`} className={`f14`}>
                        {`${reservationInfo && reservationInfo.amount_text}`} 원
                    </Text>
                    </Wrapper>

                    <Wrapper margin={`0 0 30px 0`} width={`100%`} jc={`space-between`}>
                        <Text color={`#818792`} weight={`500`} className={`f14`}>
                            환불금액
                        </Text>
                        <Text color={`#FF9187`} weight={`500`} className={`f14`}>
                            {`${reservationInfo && reservationInfo.refund_text}`} 원
                        </Text>
                    </Wrapper>

                    <Wrapper margin={`0 0 30px 0`} width={`100%`} jc={`space-between`}>
                        <Text color={`#818792`} weight={`500`} className={`f14`}>
                            환불사유
                        </Text>
                        <Text color={`#FF9187`} weight={`500`} className={`f14`}>
                            {reservationInfo && `[${reservationInfo.refund_type == 1 ? '크루' : '파트너'}] ${reservationInfo.refund_tag}`}
                        </Text>
                    </Wrapper>
                </Wrapper>
            :
                <Wrapper
                    transition={`all 0.3s ease`}
                    of={`hidden`}
                    mh={amountInfo ? `unset` : `0`}
                    width={`100%`}
                    wrap={`wrap`}
                >
                    <Wrapper margin={`0 0 30px 0`} width={`100%`} jc={`space-between`}>
                    <Text color={`#818792`} weight={`500`} className={`f14`}>
                        결제 수단
                    </Text>
                    <Text color={`#3383FF`} weight={`600`} className={`f14`}>{`${
                        reservationInfo &&
                        (payMethod[reservationInfo?.method])
                    }`}</Text>
                    </Wrapper>

                    <Wrapper margin={`0 0 30px 0`} width={`100%`} jc={`space-between`}>
                    <Text color={`#818792`} weight={`500`} className={`f14`}>
                        액티비티 금액
                    </Text>
                    <Text color={`#3C3E41`} weight={`500`} className={`f14`}>
                        {`${reservationInfo && reservationInfo.partner_payment}`} 원
                    </Text>
                    </Wrapper>

                    <Wrapper margin={`0 0 30px 0`} width={`100%`} jc={`space-between`}>
                    <Text color={`#818792`} weight={`500`} className={`f14`}>
                        포인트 / 쿠폰 할인
                    </Text>
                    <Text color={`#3C3E41`} weight={`500`} className={`f14`}>
                        - {`${reservationInfo && reservationInfo.point_text}`} 원
                    </Text>
                    </Wrapper>

                    <Wrapper margin={`0 0 30px 0`} width={`100%`} jc={`space-between`}>
                        <Text color={`#818792`} weight={`500`} className={`f14`}>
                            최종 결제금액
                        </Text>
                        <Text color={`#3383FF`} weight={`500`} className={`f14`}>
                            {`${reservationInfo && reservationInfo.amount_text}`} 원
                        </Text>
                    </Wrapper>
                </Wrapper>
        }

        </Wrapper>
      </Wrapper>
      <ImageCrop
        info={cropImage}
        setInfo={setCropImage}
        setImg={setTicketSpec}
        img={ticketSpec}
      />
    </>
  );
};

export default SalesDetail;

const Input = styled.input`
  width: ${({ width }) => `${width}`};
  display: inline-block;
`;
