const noticeList = [
  {
    year: 2021,
    month: "08",
    day: 30,
    thumbnail: "",
    title: "양양바다서핑은 세모스에서!",
  },
  {
    year: 2021,
    month: "08",
    day: 30,
    thumbnail: "",
    title: "[크루EVENT] 서핑 1Day 클래스",
  },
  {
    year: 2021,
    month: "08",
    day: 30,
    thumbnail: "",
    title: "9월달 크루 모집 안내",
  },
  {
    year: 2021,
    month: "08",
    day: 30,
    thumbnail: "",
    title: "8월달 크루 모집 안내",
  },
  {
    year: 2021,
    month: "08",
    day: 30,
    thumbnail: "",
    title: "3배 이상 할인받는 크루 모집",
  },
  {
    year: 2021,
    month: "08",
    day: 30,
    thumbnail: "",
    title: "워터 스포츠 강사님들 주목!",
  },
  {
    year: 2021,
    month: "08",
    day: 30,
    thumbnail: "",
    title: "워터 스포츠 강사님들 주목!",
  },
  {
    year: 2021,
    month: "08",
    day: 30,
    thumbnail: "",
    title: "워터 스포츠 강사님들 주목!",
  },
];

export default noticeList;
