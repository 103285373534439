import { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import useInput from "../../hooks/useInput";
import Border from "../../modules/border";
import Textarea from "../../modules/textarea";
import base from "../../styles/constants/base";
import colors from "../../styles/constants/colors";
import flexbox from "../../styles/func/flexbox";
import ModalLayout from "./layout";
import Template from "./template";

const USER_CLASSIFICATION = [
  {
    value: "general",
    text: "일반 유저",
  },
  {
    value: "partner",
    text: "파트너 유저",
  },
  {
    value: "all",
    text: "전체 유저",
  },
];

const ClassificationBox = styled.div`
  ${flexbox("space-between")};

  ul {
    ${flexbox()};

    li {
      border: 2px solid ${colors.lightGray};
      border-radius: 8px;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 15px;
      }

      p {
        width: 100%;
        height: 100%;
        ${flexbox()}
        border-radius: 8px;
        padding: 10px 20px;
      }
    }
  }
`;


const TemplateBox = styled.div`
  ${flexbox("space-between")};
  margin-top : 20px;

  ul {
    ${flexbox()};

    li {
      border: 2px solid ${colors.lightGray};
      border-radius: 8px;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 15px;
      }

      p {
        width: 100%;
        height: 100%;
        ${flexbox()}
        border-radius: 8px;
        padding: 10px 20px;
      }
    }
  }
`;

const ImageUpload = styled.input`
  width : 100%;
  display : inline-block;
`

const Active = styled.p`
  color: ${colors.blue};
  border: 2px solid ${colors.blue};
`;

const Normal = styled.p`
  color: ${colors.gray};
  border: 2px solid transparent;
`;

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const MessageTypeContainer = styled.div`
width : 100%;
display : inline-flex;
justify-content : space-between;
margin-bottom : 20px;

  ul {
    ${flexbox()};

    li {
      border: 2px solid ${colors.lightGray};
      border-radius: 8px;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 15px;
      }

      p {
        width: 100%;
        height: 100%;
        ${flexbox()}
        border-radius: 8px;
        padding: 10px 20px;
      }
    }
  }
`

const SearchBox = styled.div`
  width : 100%;
  display : inline-flex;
  flex-wrap : wrap;

  .inputBox {
      width : 100%;
      display : inline-flex;
      justify-content : space-between;
      margin-bottom : 0;
      border-radius : 8px 8px 0 0;        
      border: 2px solid #DEDEDE;

      .searchButton {
          width : calc(20% - 5px);
          display : inline-flex;
          height : 100%;
      }

      input {
        display : inline-block;
        width : 80%;
        padding : 10px;
        border : none !important;
        background-color: #fff;
    }
  }

  .valueBox {
      width : 100%;
      max-height : 250px;
      display : inline-flex;
      flex-wrap : wrap;
      overflow : hidden auto;
      border: 2px solid #DEDEDE;
      background-color: #fff;
      border-top : none !important;
      border-radius : 0 0 8px 8px;  

      span {
          width : 100%;
          border-bottom: 1px solid #DEDEDE;
          display : inline-block;
          padding : 10px;
          cursor : pointer;
      }
  }

`

const CheckUser = styled.div`
  width : 100%;
  display : inline-flex;
  flex-wrap: wrap;
  margin-bottom : 20px;
  overflow : hidden auto;
  max-height : 100px;

  .selected_user {
      display : inline-flex;
      padding : 5px;
      flex-wrap : nowrap;
      white-space : nowrap;
  }
`    

const NoticeModal = ({ closeHandler }) => {
  const { value, setValue, inputChangeHandler } = useInput({
    contents: "",
  });

  const [target, setTarget] = useState("general");

  const [messageType, setMessageType] = useState("all");

  const [searchUser, setSearchUser] = useState("");

  const [userList, setUserList] = useState([]);

  const [selectUserList, setSelectUserList] = useState([]);

  const [template, setTemplate] = useState("PSCHEDULE3");
  
  const [partner, setPartner] = useState("자동 입력");

  const ThisDate = new Date();
  const ThisMonth = ThisDate.getMonth();
  const ThisDay = ThisDate.getDate();
  
  const [month, setMonth] = useState(ThisMonth > 7 ? String(ThisMonth + 2) + "월" : "0" + String(ThisMonth + 2) + "월" );
  const [monthAday, setMonthAday] = useState(`${ThisMonth > 8 ? ThisMonth + 1 : "0" + String(ThisMonth + 1)}월 ${ThisDay > 9 ? ThisDay : "0" + String(ThisDay)}일` );
  
  const messageContent = {
  
PREGISTER : ` 파트너님, 파트너 등록을 신청해주셔서 감사합니다 :)
    
세모스는 현재 앱스토어 10위를 기념하며 모든 레슨을 무료로 등록해드리고 있습니다! 레슨 등록 가능한 링크를 발송해드릴 예정이오니, 아래 사항을 본 채널로 회신해주시기 바랍니다.
  
세모스에 등록하실 레슨명(1종목 당 5개 가능)
  
-
세모스와 함께해주셔서 늘 감사합니다 :)
워터스포츠 1등 예약 앱, 세모스!
☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)`,
  
PSCHEDULE3 : ` 파트너님! 세모스 어플 내 ${month} 일정을 등록하고 예약시스템을 오픈하기 위해 아래 정보가 필요해요.

- ${month} 진행 불가능 일정
- 액티비티 정보(금액, 활동 지역 등) 변동사항

***파트너님의 액티비티를 고객님들이 정상적으로 결제할 수 있도록 ${monthAday}까지 회신주시기 바랍니다.

레저스포츠 1등 예약 앱, 세모스!
☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)`,
  
  };

  const DataChange = (type, value) => {

    switch(type) {
      
      // user
      case "partner":
        setPartner(value)
      break;
  
      case "month":
        setMonth(value)
      break;
  
      case "mnd":
        setMonthAday(value)
      break;
      
    }
  
  }

  const sendHandler = () => {

    if(window.confirm('메세지를 발송하시겠습니까?')) {
      var message = value.contents;
      var type = target;
  
      var token = window.localStorage.getItem('token')
  
      const frm = new FormData();
      frm.append("mode", "kakaoTalk");
      frm.append("token", token);
      frm.append("type", type);
      frm.append("messageType", messageType);
      frm.append("template", template);
      frm.append("message",  messageType == 'all' && target === 'partner' && template ? messageContent[template] : message);
  
      axios.post("https://ai.semos.kr/semos_admin/user/send", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then((data) => {
  
        var Value = data.data;
        var status = Value.status
        var statusMessage = Value.status_message
  
        if(status == 'success') {
          alert(statusMessage)
          //window.location.reload();
        } else if(status == 'fail') {
          alert(statusMessage)
          //window.location.reload();
        } else if(status == 'waring') {
          alert(statusMessage)
          //window.location.replace('/');
        }
  
      })
    }

  };

  const SearchUser = (user) => { 

    var token = window.localStorage.getItem('token')

    const frm = new FormData();
    frm.append("mode", "searchUser");
    frm.append("token", token);
    frm.append("user", user);

    axios.post("https://ai.semos.kr/semos_admin/user/send", frm, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then((data) => {

      var Value = data.data;
      var status = Value.status
      var statusMessage = Value.status_message

      if(status == 'success') {
        setSearchUser(user)
        setUserList(Value.user_list)
      } else if(status == 'fail') {
        alert(statusMessage)
      } else if(status == 'waring') {
        alert(statusMessage)
      }

    })

  }

  const resetHandler = () => {
    setValue({
      contents: "",
    });

    setTarget("general");
  };

  const activeHandler = (value) => {
    setTarget(value);
  };

  const SelectUser = (number) => {

    var OgData = [...userList];

    var OgData = OgData[number];

    var userData = [...selectUserList];

    var userCheck = false;

    for(var i=0; userData.length; i++) {

        var OgName = OgData.nickname == userData[i].nickname;
        var OgPhone = OgData.phone_text == userData[i].phone_text;

        if(OgName && OgPhone) {
            alert('중복된 유저입니다!')
            userCheck = true;
            return;
        } 
    
    }

    if(!userCheck) {
        userData[userData.length] = OgData;

        setSelectUserList(userData);
    }

  }

  return (
    <ModalLayout
      title="내용을 입력해 주세요."
      buttonText="알림톡 발송하기"
      clickHandler={sendHandler}
      closeHandler={closeHandler}
    >
    
      <MessageTypeContainer>
        <ul>
            <li onClick={() => setMessageType("one")} active={messageType == 'one' ? "true" : "false"}>
                {messageType == 'one' ? <Active>개별 발송</Active> : <Normal>개별 발송</Normal>}
            </li>

            <li onClick={() => setMessageType("all")} active={messageType == 'all' ? "true" : "false"}>
                {messageType == 'all' ? <Active>전체 발송</Active> : <Normal>전체 발송</Normal>}
            </li>
        </ul>
      </MessageTypeContainer>

      {messageType == 'one' ? 
        <SearchBox>
            <div className="inputBox">
                <input placeholder="닉네임 / 본명 / 전화번호로 검색 * - 포함해서 검색" value={searchUser} type='search' onChange={(e) => SearchUser(e.target.value)} />
                <span className="searchButton"></span>
            </div>
            <div className="valueBox">
                {userList.map((item, idx) => (
                   <span onClick={ () => SelectUser(idx) }>{`[${item.tier}] ${item.nickname} (${item.name ? item.name : "미등록"}) | ${item.phone_text}`}</span> 
                ))}                   
            </div>


        </SearchBox>
      : 
      <ClassificationBox>
        <ul>
          {/* TODO: 활성화가 안됨 */}
          {USER_CLASSIFICATION.map((item, idx) => (
            <li
              key={idx}
              onClick={() => activeHandler(item.value)}
              active={target === item.value ? "true" : "false"}
            >
              {target === item.value ? (
                <Active>{item.text}</Active>
              ) : (
                <Normal>{item.text}</Normal>
              )}
            </li>
          ))}
        </ul>

        <button type="button" onClick={resetHandler}>
          초기화
        </button>
      </ClassificationBox>}

      { messageType == 'all' && target === 'partner' ? 
        <TemplateBox>
          <ul>
            <li onClick={() => setTemplate('')} >{!template ? <Active>선택안함</Active> : <Normal>선택안함</Normal>}</li>
            <li onClick={() => setTemplate('PSCHEDULE3')}>{template === 'PSCHEDULE3' ? <Active>파트너 일정 등록</Active> : <Normal>파트너 일정 등록</Normal>}</li>
          </ul>
        </TemplateBox>
        : ""
      }

      <Border />


      <Wrapper>

       {selectUserList.length ? 
       <CheckUser>

            

       </CheckUser> : "" }

       { messageType == 'all' && target === 'partner' && template ? 
          <Template type={template} change={DataChange}
            data={{
              "partner" : partner,
              "month" : month,
              "mnd" : monthAday,
          }} /> : 
          <Textarea
            name="contents"
            value={value.contents}
            changeHandler={inputChangeHandler}
            placeholder="내용을 입력해 주세요."
          /> }
      </Wrapper>
    </ModalLayout>
  );
};

export default NoticeModal;
