import styled from "styled-components";
import base from "../styles/constants/base";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 48px);
`;

const OuterWrapper = styled.div`
  position: relative;
  max-width: 100%;
  height: calc(100vh - 48px);
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #F9F9F9;
  border-radius: ${base.borderRadius.default}px;
  box-shadow: ${base.boxShadow.default};
  padding: 36px 36px 0 36px;
  overflow: hidden;
`;



const DesignedMainLayout = ({ heightType, children, button = null, bottomButton = null }) => {
  return (
    <Container id='Layout'>
      <OuterWrapper hc={heightType}>
        <Wrapper>
          {children}

        </Wrapper>

      </OuterWrapper>
    </Container>
  );
};

export default DesignedMainLayout;
