import dashboardIcon from "../../assets/icon/dashboard.svg";
import customerIcon from "../../assets/icon/customer-list.svg";
import salesIcon from "../../assets/icon/sales.svg";
import inquiryIcon from "../../assets/icon/inquiry.svg";
import partnerIcon from "../../assets/icon/partner.svg";
import activityIcon from "../../assets/icon/activity.svg";
import globeIcon from "../../assets/icon/globe.svg";
import scheduleIcon from "../../assets/icon/location_schedule.svg";

// new
import ActiveDashBoard from "../../assets/icon/dashboard_active.png";
import DefaultDashBoard from "../../assets/icon/dashboard_default.png";
import DefaultUser from "../../assets/icon/user_default.png";
import DefaultActivity from "../../assets/icon/activity_default.png";
import ActiveActivity from "../../assets/icon/activity_active.png";
import DefaultSell from "../../assets/icon/sell_default.png";
import ActiveSell from "../../assets/icon/sell_active.png";
import DefaultReview from "../../assets/icon/review_default.png";
import ActiveReview from "../../assets/icon/review_active.png";
import DefaultCalculate from "../../assets/icon/calculate_default.png";
import ActiveCalculate from "../../assets/icon/calculate_active.png";
import DefaultEvent from "../../assets/icon/event_default.png";
import DefaultAnnounce from "../../assets/icon/announce_default.png";
import DefaultMessage from "../../assets/icon/message_default.png";

const NowData = new Date();

const NowYear = NowData.getFullYear();
const NowMonth = ((NowData.getMonth() + 1) > 9) ? (NowData.getMonth() + 1)  : `0${(NowData.getMonth() + 1) }`;

const navbarList = [
  {
    text: "대시보드",
    img: dashboardIcon,
    path: "/dashboard/data",
  },
  {
    text: "고객 리스트",
    img: customerIcon,
    path: "/customer_list/1/all/all/all/all",
  },
  {
    text: "판매 내역",
    img: salesIcon,
    path: "/sales_list/1/all/all/all/결제 기간/all/결제 방법 전체/결제 플랫폼 전체",
  },
  {
    text: "문의 내역",
    img: inquiryIcon,
    path: "/inquiry_list/1/all/all/all/all",
  },
  {
    text: "파트너 관리",
    img: partnerIcon,
    path: "/partner_list/1/all/all/all/all/all",
  },
  {
    text: "액티비티 관리",
    img: activityIcon,
    path: "/activity_list/1/all/all/all/all/all",
  },
  {
    text: "시설 관리",
    img: globeIcon,
    path: "/facility_list/1/all/all/all/all/all",
  },
  /*{
    text: "장소-일정 관리",
    img: scheduleIcon,
    path: "/schedule_list/1/all/all/all/all/all",
  },*/
];

export const serviceList = [
  {
    text: "공지사항",
    path: "/notice_list/1",
  },
  {
    text: "FAQ",
    path: "/faq_list/1/customer/all/all",
  },
  {
    text: "배너/기획전",
    path: "/banner_list/1",
  },
  {
    text: "개별 메시지 전송",
    path: "/message_send",
  },
  {
    text: "알림/친구톡 내역",
    path: "/message_list/1/all/all/all/all",
  },
  {
    text: "푸시 메시지 전송",
    path: "/push_notify",
  },
];

export const newNavList = [
  {
    text: "대시보드",
    active: ActiveDashBoard,
    default: DefaultDashBoard,
    path: "/dashboard",
  },
  {
    text: "회원 관리",
    default: DefaultUser,
    path: false,
    children: [
      {
        text: "유저 관리",
        path: "/user/1/all",
        detail: "user_detail",
      },
      {
        text: "파트너 관리",
        path: "/center/1/all/all/all/all/all",
        detail: "center_detail",
      },
    ],
  },
  {
    text: "액티비티 관리",
    default: DefaultActivity,
    path: false,
    children: [
      {
        text: "파트너 액티비티",
        path: "/activity/1/all/all/all/all",
        detail: "activity_detail",
      },
      /*{
        text: "PB 액티비티",
        path: "/pb_activity/1/all/all",
        detail: "pb_activity_detail",
      },*/
    ],
  },
  // {
  //   text: "액티비티 관리",
  //   active: ActiveActivity,
  //   default: DefaultActivity,
  //   path: "/activity/1/all/all/all/all",
  // },
  {
    text: "판매 내역",
    active: ActiveSell,
    default: DefaultSell,
    path: "/sales_list/1/all/all/all/결제 기간/all/결제 방법 전체/결제 플랫폼 전체",
  },
  {
    text: "정산 내역",
    active: ActiveCalculate,
    default: DefaultCalculate,
    path: `/calculate/1/파트너 전체/${NowYear}/${NowMonth}`,
  },
  {
    text: "공지사항 / FAQ",
    default: DefaultAnnounce,
    path: false,
    children: [
      {
        text: "공지사항",
        path: "/announcement/1/all/customer/all",
        detail: "announcement_detail",
      },
      {
        text: "FAQ",
        path: "/faq/1/all/customer/all",
        detail: "faq_detail",
      },
    ],
  },
  {
    text: "리뷰 관리",
    active: ActiveReview,
    default: DefaultReview,
    path: "/review_list/1/all/all/all/all",
  },
  {
    text: "이벤트 관리",
    default: DefaultEvent,
    path: false,
    children: [
      {
        text: "배너 관리",
        path: "/banner/1/1/order/all/all",
        detail: "main_banner",
      },
      {
        text: "이벤트",
        path: "/event/1",
        detail: "event_detail",
      },
      {
        text: "기획전",
        path: "/exhibition",
      },
    ],
  },
  {
    text: "메세지 발송 관리",
    default: DefaultMessage,
    path: false,
    children: [
      {
        text: "알림 / 친구톡",
        path: "/message_list/1/all/all/all/all",
        detail: "message_detail",
      },
      {
        text: "푸시 메세지 전송",
        path: "/push_message_send",
      },
      {
        text: "개별 메세지 전송",
        path: "/individual_message_send",
      },
    ],
  },
  {
    text: "게시글",
    active: "",
    default: "",
    path: "/bulletin",
  },
];

export default navbarList;
