import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";

import DesignedMainHeader from "../../components/designedHeader";
import DesignedMainLayout from "../../layouts/designedMainLayout";
import DrawerModal from "../../components/drawerModal";
import Table from "../../layouts/table";
import {
  TableFilterContainer,
  TableFilterContent,
  TableWrapper,
  Wrapper,
  AbsoluteWrapper,
  Icon,
  Text,
  TableContent,
  TableFooter,
  TableColumn,
  TableFooterPaging,
} from "../../layouts/layoutStorage";
import NewEvent from "../../components/designedBanner/newEvent";

import SearchIcon from "../../assets/icon/search.png";
import DoublePaging from "../../assets/icon/double_paging.png";
import OnePaging from "../../assets/icon/one_paging.png";
import EventDetail from "../../components/designedBanner/eventDetail";

const W = window.innerWidth;

const Event = () => {
  const { n } = useParams();
  const navigate = useNavigate();

  const [event, setEvent] = useState([]);
  const [count, setCount] = useState(0);

  const [selected, setSelected] = useState("");

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  useEffect(() => {
    var token = window.localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", "list");
    frm.append("token", token);
    frm.append("page", n);

    axios
      .post("https://ai.semos.kr/semos_admin/event/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var status = Value.status;

        if (status == "success") {
          setEvent(Value.event_list);
          setCount(Value.event_count);
        }
      });
  }, [n]);

  const numPages = Math.ceil(count / 10);

  let PageList = [];

  if (n < 11) {
    for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
      PageList.push(i + 1);
    }
  } else {
    if (numPages - 10 < n) {
      var startPage = numPages - 19;
      if (startPage < 1) {
        startPage = 1;
      }

      for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    } else {
      var startPage = n - 9;
      if (startPage < 1) {
        startPage = 1;
      }

      for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    }
  }

  const PageInput = (num) => {
    var token = localStorage.getItem("token");

    if (token) {
      navigate(`/event/${num}`);
    } else {
      alert("권한이 없습니다.");
    }
  };

  const showDetail = (id) => {
    // setSelected(id);
    // setOpen(true);
    window.open(`/event_detail/${id}`, "_blank");
  };

  return (
    <>
      <DesignedMainLayout>
        <DesignedMainHeader title="이벤트" />

        <AddNew
          onClick={() => {
            // setOpen2(true);
            window.open(`/event_detail/new`, "_blank");
          }}
        >
          이벤트 추가
        </AddNew>

        <Container>
          {event?.map((el, i) => (
            <EventCard item={el} showDetail={showDetail} i={i} />
          ))}

          <TableFooter>
            <Icon
              margin={`0 16px 0 0`}
              width={`calc(1rem * (20 / 14))`}
              cursor={`pointer`}
              src={DoublePaging}
              onClick={() => {
                if (Number(n) < 11) {
                  PageInput(1);
                } else {
                  PageInput(Number(n) - 10);
                }
              }}
            />
            <Icon
              margin={`0 16px 0 0`}
              width={`calc(1rem * (20 / 14))`}
              cursor={`pointer`}
              src={OnePaging}
              onClick={() => {
                if (n > 1) {
                  PageInput(n - 1);
                } else {
                  window.alert("첫번째 페이지 입니다.");
                }
              }}
            />

            {PageList.length
              ? PageList.map((item, idx) => (
                  <TableFooterPaging
                    key={`paging_number_${idx}`}
                    margin={`0 16px 0 0`}
                    color={`${n == item ? "#3383FF" : "#ACAFB5"}`}
                    onClick={() => PageInput(item)}
                  >
                    {item}
                  </TableFooterPaging>
                ))
              : ""}

            <Icon
              margin={`0 16px 0 0`}
              width={`calc(1rem * (20 / 14))`}
              cursor={`pointer`}
              transform={`rotate(180deg)`}
              src={OnePaging}
              onClick={() => {
                if (n < numPages) {
                  PageInput(Number(n) + 1);
                } else {
                  window.alert("마지막 페이지 입니다.");
                }
              }}
            />
            <Icon
              width={`calc(1rem * (20 / 14))`}
              cursor={`pointer`}
              transform={`rotate(180deg)`}
              src={DoublePaging}
              onClick={() => {
                if (Number(n) + 10 > numPages) {
                  PageInput(numPages);
                } else {
                  PageInput(Number(n) + 10);
                }
              }}
            />
          </TableFooter>
        </Container>
      </DesignedMainLayout>

      {/* <DrawerModal open={open} setOpen={setOpen}>
        <EventDetail id={selected} />
      </DrawerModal>

      <DrawerModal open={open2} setOpen={setOpen2}>
        <NewEvent />
      </DrawerModal> */}
    </>
  );
};

export default Event;

const EventCard = ({ item, showDetail, i }) => {
  return (
    <Link to={`/event_detail/${item.id}`}>
      <CardWrap left={i % 5}>
        <Thumbnail src={item.thumbnail} />
        <div style={{ height: "12px" }} />
        <Title className="f16">{item.title}</Title>
        <div style={{ height: "8px" }} />
        <RegisterDate>{item.date}</RegisterDate>
      </CardWrap>
    </Link>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
`;

const CardWrap = styled.div`
  width: ${(W - 378) / 5}px;
  margin-bottom: 50px;
  margin-left: ${({ left }) => (left ? 24 : 0)}px;
  cursor: pointer;
`;

const Thumbnail = styled.img`
  width: ${(W - 306) / 5}px;
  height: ${(W - 306) / 8}px;
  border-radius: 8px;
`;

const Title = styled.div`
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #3c3e41;
`;

const RegisterDate = styled.div`
  font-weight: 500;
  letter-spacing: -0.02em;
  color: #818792;
`;

const Seperator = styled.div`
  position: absolute;
  bottom: 0;
`;

const AddNew = styled.div`
  position: absolute;
  top: 36px;
  right: 36px;
  padding: 8px 25px;
  color: #ffffff;
  font-weight: 700;
  background-color: #0e6dff;
  border-radius: 8px;
  cursor: pointer;
`;
