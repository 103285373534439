const inquiryList = [
  {
    id: 1,
    user: "고크리스틴",
    partner: "비바프리다이빙(김유진)",
    lesson: "체험 다이빙",
    chat: [
      {
        text: "가격이 동반 할인가가 적용된건지 궁금하네요. 제가 알기로는...",
        datetime: "22년 3월 18일",
      },
    ],
    status: false,
  },
  {
    id: 2,
    user: "고크리스틴",
    partner: "비바프리다이빙(김유진)",
    lesson: "체험 다이빙",
    chat: [
      {
        text: "가격이 동반 할인가가 적용된건지 궁금하네요. 제가 알기로는...",
        datetime: "22년 3월 18일",
      },
    ],
    status: false,
  },
  {
    id: 3,
    user: "고크리스틴",
    partner: "비바프리다이빙(김유진)",
    lesson: "체험 다이빙",
    chat: [
      {
        text: "넵 알겠습니다",
        datetime: "22년 3월 18일",
      },
    ],
    status: false,
  },
  {
    id: 4,
    user: "고크리스틴",
    partner: "비바프리다이빙(김유진)",
    lesson: "체험 다이빙",
    chat: [
      {
        text: "그렇게 해주세요",
        datetime: "22년 3월 18일",
      },
    ],
    status: true,
  },
  {
    id: 5,
    user: "고크리스틴",
    partner: "비바프리다이빙(김유진)",
    lesson: "체험 다이빙",
    chat: [
      {
        text: "가격이 동반 할인가가 적용된건지 궁금하네요. 제가 알기로는...",
        datetime: "22년 3월 18일",
      },
    ],
    status: true,
  },
  {
    id: 6,
    user: "고크리스틴",
    partner: "비바프리다이빙(김유진)",
    lesson: "체험 다이빙",
    chat: [
      {
        text: "가격이 동반 할인가가 적용된건지 궁금하네요. 제가 알기로는...",
        datetime: "22년 3월 18일",
      },
    ],
    status: true,
  },
  {
    id: 7,
    user: "고크리스틴",
    partner: "비바프리다이빙(김유진)",
    lesson: "체험 다이빙",
    chat: [
      {
        text: "가격이 동반 할인가가 적용된건지 궁금하네요. 제가 알기로는...",
        datetime: "22년 3월 18일",
      },
    ],
    status: false,
  },
  {
    id: 8,
    user: "고크리스틴",
    partner: "비바프리다이빙(김유진)",
    lesson: "체험 다이빙",
    chat: [
      {
        text: "가격이 동반 할인가가 적용된건지 궁금하네요. 제가 알기로는...",
        datetime: "22년 3월 18일",
      },
    ],
    status: false,
  },
];

export default inquiryList;
