import React, { useState, useEffect } from "react";
import axios from "axios";

// components
import { Wrapper, AbsoluteWrapper, Text } from "../../layouts/layoutStorage";
import colors from "../../styles/constants/colors";

const PartnerPicker = ({
  info,
  partner,
  setPartner,
  status,
  text,
  setText,
  id,
}) => {
  const PartnerHandle = (data) => {
    if (data) {
      const token = localStorage.getItem("token");
      const PartnerNumber = data.token;
      const PartnerPhone = data.phone;
      const PartnerName = data.name;
      const PartnerSport = data.sport;

      if (
        window.confirm(`예약 내역을 ${PartnerName}님으로 변경하시겠습니까?`)
      ) {
        const frm = new FormData();

        frm.append("mode", "partner_change");
        frm.append("token", token);
        frm.append("partner_id", PartnerNumber);
        frm.append("payment_id", id);

        axios
          .post("https://ai.semos.kr/semos_admin/payment/data", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            const Value = res.data;

            if (Value.status === "success") {
              window.alert("변경이 완료되었습니다.");
              window.location.reload();
            }
          });
        setText("");
      }
    }
  };

  return (
    <AbsoluteWrapper
      ai={`flex-start`}
      height={`200px`}
      of={`hidden auto`}
      display={status ? `inline-flex` : `none`}
      z={`150`}
      left={`0`}
      bottom={`-8px`}
      border={`1px solid ${colors.Gr02}`}
      transform={`translate(0, 100%)`}
      width={`100%`}
      padding={`13px 2px`}
      bgc={colors.White}
    >
      <Wrapper wrap={`wrap`} width={`100%`} position={`relative`}>
        {partner.length ? (
          partner.map((item, idx) => (
            <Wrapper
              onClick={() => PartnerHandle(item)}
              key={`${idx}_partner_picker`}
              border={
                info.change_partner == item.token && `1px solid ${colors.Bl07}`
              }
              padding={`5px 7px`}
              cursor={`pointer`}
              width={`100%`}
              jc={`flex-end`}
            >
              <Text
                color={info.change_partner == item.token && `${colors.Bl07}`}
                className={`f13`}
              >{`${item.sport} | ${item.name}`}</Text>
            </Wrapper>
          ))
        ) : (
          <Wrapper
            padding={`5px 0`}
            cursor={`pointer`}
            width={`100%`}
            jc={`center`}
          >
            <Text className={`f13`}>{`검색된 파트너가 없습니다.`}</Text>
          </Wrapper>
        )}
      </Wrapper>
    </AbsoluteWrapper>
  );
};

export default PartnerPicker;
