import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";

// components
import DesignedMainHeader from "../../components/designedHeader";
import DesignedMainLayout from "../../layouts/designedMainLayout";
import Table from "../../layouts/table";
import {
  TableFilterContainer,
  TableFilterContent,
  TableWrapper,
  Wrapper,
  AbsoluteWrapper,
  Icon,
  Text,
  TableContent,
  TableFooter,
  TableColumn,
  TableFooterPaging,
} from "../../layouts/layoutStorage";

// icon
import SearchIcon from "../../assets/icon/search.png";
import DoublePaging from "../../assets/icon/double_paging.png";
import OnePaging from "../../assets/icon/one_paging.png";
import Kakao from "../../assets/logo/kakao_logo.png";
import Apple from "../../assets/icon/apple_logo.png";
import Google from "../../assets/logo/googlelogin.png";
import Naver from "../../assets/logo/naver_logo.png";
import colors from "../../styles/constants/colors";

const User = () => {
  const { n, t } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [filter, setFilter] = useState({
    text: t.includes("all}") ? "" : t.split("}")[0],
  });

  const [page, setPage] = useState(n);

  const [user, setUser] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const [selected, setSelected] = useState("");
  const [search, setSearch] = useState(t !== "all" ? t : "");

  const [info, setInfo] = useState({});
  const [payList, setPayList] = useState([]);
  const [open, setOpen] = useState(false);

  const [Height, setHeight] = useState({
    TableFilter: 0,
    TableHeader: 0,
    TableFooter: 0,
    Layout: 0,
    LayoutHeader: 0,
  });

  useEffect(() => {
    if (t == "all") {
      setSearch("");
    }
  }, [t]);

  useEffect(() => {
    setTimeout(() => {
      const Layout = document.getElementById("Layout")?.offsetHeight - 36;
      const LayoutHeader = document.getElementById("LayoutHeader")?.offsetHeight + 65;
      const TableFilter = document.getElementById("TableFilter")?.offsetHeight + 19;
      const TableHeader = document.getElementById("TableHeader")?.offsetHeight;
      const TableFooter = document.getElementById("TableFooter")?.offsetHeight;

      setHeight({
        TableFilter: TableFilter,
        TableHeader: TableHeader,
        TableFooter: TableFooter,
        Layout: Layout,
        LayoutHeader: LayoutHeader,
      });
    }, 500);
  }, []);

  useEffect(() => {
    loadData();
  }, [n, t]);

  const loadData = () => {
    var token = window.localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", "new_list");
    frm.append("token", token);
    frm.append("page", n);
    frm.append("text", t != "all" ? t : "");

    axios
      .post("https://ai.semos.kr/semos_admin/user/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;

        if (Value.status == "success") {
          setUser(Value.user_list);
          setCount(Value.user_count);
          setLoading(false);
        }
      });
  };

  const EnterSearch = (key) => {
    if (key == "Enter") {
      PageInput(1);
    }
  };

  // filter setting
  const TextInput = (type, text) => {
    setFilter({ ...filter, [type]: text });
  };

  useEffect(() => {
    if(loading === false) {
      PageInput(1);
    }
  }, [filter]);

  // text 클릭 검색
  const TextSearch = () => {
    // if (filter.text != "all") {
    //   var token = window.localStorage.getItem("token");
    //   var text = t !== "all" ? t : "";
    //   //   console.log(text);
    //   const frm = new FormData();
    //   frm.append("mode", "new_list");
    //   frm.append("token", token);
    //   frm.append("page", 1);
    //   frm.append("text", text);
    //   axios
    //     .post("https://ai.semos.kr/semos_admin/user/data", frm, {
    //       headers: { "Content-Type": "multipart/form-data" },
    //     })
    //     .then((data) => {
    //       var Value = data.data;
    //       var status = Value.status;
    //       var statusMessage = Value.status_message;
    //       if (status == "success") {
    //         setUser(Value.user_list);
    //         setCount(Value.user_count);
    //         setPage(1);
    //         navigate(`/user/${1}/${text}}`, { replace: true });
    //       } else if (status == "waring") {
    //         alert(statusMessage);
    //       }
    //     });
    // }
    // navigate(`/user/${1}/${t}}`, { replace: true });
  };

  const numPages = Math.ceil(count / 10);

  let PageList = [];

  if (n < 11) {
    for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
      PageList.push(i + 1);
    }
  } else {
    if (numPages - 10 < n) {
      var startPage = numPages - 19;
      if (startPage < 1) {
        startPage = 1;
      }

      for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    } else {
      var startPage = n - 9;
      if (startPage < 1) {
        startPage = 1;
      }

      for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    }
  }

  const PageInput = (num) => {
    // if (num == 1) {
    // var token = window.localStorage.getItem("token");

    // const frm = new FormData();
    // frm.append("mode", "list");
    // frm.append("token", token);
    // frm.append("page", num);
    // frm.append("text", filter.text != "all" ? filter.text : "");

    // axios
    //   .post("https://ai.semos.kr/semos_admin/user/data", frm, {
    //     headers: { "Content-Type": "multipart/form-data" },
    //   })
    //   .then((data) => {
    //     var Value = data.data;
    //     var status = Value.status;
    //     var statusMessage = Value.status_message;

    //     if (status == "success") {
    //   setUser(Value.customer_list);
    //   setCount(Value.customer_count);
    //   setPage(num);
    navigate(`/user/${num}/${search ? search : "all"}`);
    //     } else if (status == "waring") {
    //       alert(statusMessage);
    //     }
    //   });
    // } else {
    //   setPage(num);
    // }
  };

  const showDetail = (id) => {
    // var token = localStorage.getItem("token");
    // const frm = new FormData();
    // frm.append("mode", "specific");
    // frm.append("token", token);
    // frm.append("number", id);
    // axios
    //   .post("https://ai.semos.kr/semos_admin/user/data", frm, {
    //     headers: { "Content-Type": "multipart/form-data" },
    //   })
    //   .then((data) => {
    //     var Value = data.data;
    //     if (Value.status == "success") {
    //       setInfo(Value.customer_data);
    //       setPayList(Value.pay_list);
    //     }
    //   });
    // setSelected(id);
    // setOpen(true);
    // window.open(`/user_detail/${id}`, "_blank");
  };

  const loginTypeMaker = (type) => {
    switch (type) {
      case "카카오":
        return (
          <LoginBoxType className="f14" bgc="#fbe300" c="#3b1e1e">
            <LoginIcon src={Kakao} />
            카카오
          </LoginBoxType>
        );

      case "애플":
        return (
          <LoginBoxType className="f14" bgc="#F0F0F0" c="#818792">
            <LoginIcon src={Apple} />
            Apple
          </LoginBoxType>
        );

      case "구글":
        return (
          <LoginBoxType className="f14" bgc="#FFFFFF" c="#3b1e1e">
            <LoginIcon src={Google} />
            Google
          </LoginBoxType>
        );

      case "네이버":
        return (
          <LoginBoxType className="f14" bgc="#03C75A" c="#ffffff">
            <LoginIcon src={Naver} />
            네이버
          </LoginBoxType>
        );

      case "현장 가입":
        return (
          <LoginBoxType className="f14" bgc="#ffffff" c="#000000">
            현장 가입
          </LoginBoxType>
        );

      default :
        return (<></>)
    }
  };

  const searchTextInput = (value) => {
    var copy = value;
    var regExp = /[`~!#$%^&*()_|+\=?;:'"<>\{\}\\\\/]/gim;

    if (regExp.test(copy)) {
      alert("특수문자는 입력하실수 없습니다.");
      copy = copy.replace(regExp, "");
    }
    setSearch(copy);
  };

  return (
    <>
      <DesignedMainLayout>
        <DesignedMainHeader title="유저 관리" subtitle={`전체 유저 : ${count}명 (현재 리스트)`} />
        <Table>
          <TableFilterContainer id="TableFilter" margin={`0 0 19px 0`}>
            <TableFilterContent margin={`0 24px 0 0`}>
              <Input
                onKeyUp={(e) => EnterSearch(e.key)}
                value={search}
                onChange={(e) => searchTextInput(e.target.value)}
                width={`350px`}
                type={`text`}
                maxLength={40}
                placeholder="닉네임, 이메일, 전화번호(4자리) 검색 가능"
              />
              <Icon width={`calc(1rem * (17 / 14))`} cursor={`pointer`} src={SearchIcon} onClick={() => TextSearch()} />
            </TableFilterContent>
          </TableFilterContainer>

          <TableContent height={Height.Layout - (Height.LayoutHeader + Height.TableFilter + Height.TableFooter)} contentHeight={Height.TableHeader}>
            <section id="TableHeader">
              <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                유저 ID
              </TableColumn>

              <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                닉네임
              </TableColumn>

              <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                이메일
              </TableColumn>

              <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                전화번호
              </TableColumn>

              <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                가입일
              </TableColumn>

              <TableColumn width={`10`} weight={`500`} br={`1px solid transparent`}>
                가입유형
              </TableColumn>
            </section>

            <section>
              {user
                ? user.map((item, idx) => (
                    <Link to={`/user_detail/${item.id}`}>
                      <TableWrapper
                        cursor={`pointer`}
                        key={`${idx}_reservation`}
                        jc={`flex-start`}
                        bl={`1px solid transparent`}
                        br={`1px solid transparent`}
                        bb={`1px solid ${colors.Gr02}`}
                        bt={`1px solid transparent`}
                        width={`100%`}
                        bgc={`#ffffff`}
                      >
                        <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                          {`${item.id}`}
                        </TableColumn>

                        <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                          {item.nickname ? `${item.nickname}` : "닉네임 없음"}
                        </TableColumn>

                        <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                          {`${item.email}`}
                        </TableColumn>

                        <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                          {`${item.phone}`}
                        </TableColumn>

                        <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                          {`${item.date}`}
                        </TableColumn>

                        <TableColumn width={`10`} weight={`500`} padding={"8px 16px 8px 10px"} br={`1px solid transparent`}>
                          {loginTypeMaker(item.login)}
                        </TableColumn>
                      </TableWrapper>
                    </Link>
                  ))
                : ""}

              <TableFooter>
                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  src={DoublePaging}
                  onClick={() => {
                    if (Number(n) < 11) {
                      PageInput(1);
                    } else {
                      PageInput(Number(n) - 10);
                    }
                  }}
                />
                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  src={OnePaging}
                  onClick={() => {
                    if (page > 1) {
                      PageInput(n - 1);
                    } else {
                      window.alert("첫번째 페이지 입니다.");
                    }
                  }}
                />

                {PageList.length
                  ? PageList.map((item, idx) => (
                      <TableFooterPaging
                        key={`paging_number_${idx}`}
                        margin={`0 16px 0 0`}
                        color={`${n == item ? "#3383FF" : "#ACAFB5"}`}
                        onClick={() => PageInput(item)}
                      >
                        {item}
                      </TableFooterPaging>
                    ))
                  : ""}

                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  transform={`rotate(180deg)`}
                  src={OnePaging}
                  onClick={() => {
                    if (n < numPages) {
                      PageInput(Number(n) + 1);
                    } else {
                      window.alert("마지막 페이지 입니다.");
                    }
                  }}
                />
                <Icon
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  transform={`rotate(180deg)`}
                  src={DoublePaging}
                  onClick={() => {
                    if (Number(n) + 10 > numPages) {
                      PageInput(numPages);
                    } else {
                      PageInput(Number(n) + 10);
                    }
                  }}
                />
              </TableFooter>
            </section>
          </TableContent>

          <TableFooter id="TableFooter" style={{ display: "none" }}>
            <Icon
              margin={`0 16px 0 0`}
              width={`calc(1rem * (20 / 14))`}
              cursor={`pointer`}
              src={DoublePaging}
              onClick={() => {
                if (Number(n) < 11) {
                  PageInput(1);
                } else {
                  PageInput(Number(n) - 10);
                }
              }}
            />
            <Icon
              margin={`0 16px 0 0`}
              width={`calc(1rem * (20 / 14))`}
              cursor={`pointer`}
              src={OnePaging}
              onClick={() => {
                if (page > 1) {
                  PageInput(n - 1);
                } else {
                  window.alert("첫번째 페이지 입니다.");
                }
              }}
            />

            {PageList.length
              ? PageList.map((item, idx) => (
                  <TableFooterPaging
                    key={`paging_number_${idx}`}
                    margin={`0 16px 0 0`}
                    color={`${n == item ? "#3383FF" : "#ACAFB5"}`}
                    onClick={() => PageInput(item)}
                  >
                    {item}
                  </TableFooterPaging>
                ))
              : ""}

            <Icon
              margin={`0 16px 0 0`}
              width={`calc(1rem * (20 / 14))`}
              cursor={`pointer`}
              transform={`rotate(180deg)`}
              src={OnePaging}
              onClick={() => {
                if (n < numPages) {
                  PageInput(Number(n) + 1);
                } else {
                  window.alert("마지막 페이지 입니다.");
                }
              }}
            />
            <Icon
              width={`calc(1rem * (20 / 14))`}
              cursor={`pointer`}
              transform={`rotate(180deg)`}
              src={DoublePaging}
              onClick={() => {
                if (Number(n) + 10 > numPages) {
                  PageInput(numPages);
                } else {
                  PageInput(Number(n) + 10);
                }
              }}
            />
          </TableFooter>
        </Table>
      </DesignedMainLayout>

      {/* <DrawerModal open={open} setOpen={setOpen}>
        <UserDetail
          //   info={info}
          //   payList={payList}
          id={selected}
          loginTypeMaker={loginTypeMaker}
        />
      </DrawerModal> */}
    </>
  );
};

export default User;

const Input = styled.input`
  width: ${({ width }) => `${width}`};
  display: inline-block;
`;

const LoginBoxType = styled.div`
  padding: 3px 16px 3px 10px;
  background-color: ${({ bgc }) => bgc};
  color: ${({ c }) => c};
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  margin-left: calc(1rem * 10 / 14);
`;

const LoginIcon = styled.img`
  width: calc(1rem * 20 / 14);
  height: calc(1rem * 20 / 14);
  margin-right: calc(1rem * 4 / 14);
`;
