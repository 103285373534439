import BannerEditRowCard from "../../../components/banner/bannerEditRowCard";
import MainHeader from "../../../components/header";
import Pagination from "../../../components/pagination";
import bannerList from "../../../data/banner";
import useList from "../../../hooks/useList";
import usePagination from "../../../hooks/usePagination";
import Index from "../../../layouts";
import MainLayout from "../../../layouts/mainLayout";
import { ButtonBox, Main, Footer } from "./style";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import base from "../../../styles/constants/base";
import flexbox from "../../../styles/func/flexbox";
import typography from "../../../styles/constants/typography";
import colors from "../../../styles/constants/colors";
import styled from "styled-components";
import axios from "axios";


// pagination
const Div = styled.div`
  width: 100%;
  height: 100%;
  ${flexbox("space-between")};
  font-weight: ${typography.weight.regular};
`;

const SectionButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: ${colors.white};
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};

  &:focus {
    box-shadow: ${base.boxShadow.default};
  }
`;

const List = styled.ul`
  ${flexbox()}
`;

const PageButton = styled.li`
  width: 40px;
  height: 40px;
  border: ${({ active }) => (active ? `2px solid ${colors.blue}` : null)};
  color: ${({ active }) => (active ? colors.blue : null)};
  ${flexbox()};
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};
  margin: 0 5px;
  cursor: pointer;
`;


const BannerEditPage = () => {
  const bannerData = bannerList;

  const { list, setList } = useList(bannerData);

  const { offset, limit, currentPage, setCurrentPage } = usePagination(
    list,
    10
  );


  const [banner, setBanner] = useState("");
  const [bannerImg, setBannerImg] = useState("");
  const [bannerCount, setBannerCount] = useState(0);
  const [page, setPage] = useState(1);
  const [bannerDelete, setBannerDelete] = useState([]);
  

  const filteredOrder = banner ? banner.filter((item) => item.order) : 0 ;

  const editHandler = (value, number, type) => {
    var bannerData = [...banner]

    bannerData[number][type] = value
    bannerData[number].change = 'yes'

    setBanner(bannerData)

  };

  const deleteHandler = (number) => {
    var bannerData = [...banner]
    var bannerDeleteData = [...bannerDelete]

    var deleteData = bannerData[number]

    bannerDeleteData[bannerDeleteData.length] = deleteData

    bannerData.splice(number, 1)

    setBanner(bannerData)
    setBannerDelete(bannerDeleteData)

  };

  const bannerSave = () => {

    if(window.confirm('저장하시겠습니까?')) {
        var token = window.localStorage.getItem("token");

        axios.post(
            "https://ai.semos.kr/semos_admin/notice/data",
            {
              mode: "banner_edit_save",
              token: token,
              banner : banner,
              bannerDelete : bannerDelete
            },
            {
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
            }
          )
          .then((data) => {
            var Value = data.data;
            var status = Value.status;
            var statusMessage = Value.status_message;
    
            if (status == "success") {
              alert(statusMessage);
            } else if (status == "waring") {
              alert(statusMessage);
            }
          });
    }


  }


  // FAQ 리스트 로드
  useEffect(() => {

      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "banner_edit");
      frm.append("token", token);
      frm.append("page", "1");

        axios.post("https://ai.semos.kr/semos_admin/notice/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {

            setBanner(Value.banner_list)
            setBannerCount(Value.banner_count)
            setBannerImg(Value.banner_img_list)

          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });

  }, []);



  const prevHandler = () => {
    if (page > 1) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "banner_edit_update");
      frm.append("token", token);
      frm.append("page", page - 1);

        axios.post("https://ai.semos.kr/semos_admin/notice/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setBanner(Value.banner_list)
            setBannerCount(Value.banner_count)
            setPage(page - 1)
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    } else {
      alert('첫 페이지입니다.');
    }
  };

  const nextHandler = () => {
    if (page < numPages) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "banner_edit_update");
      frm.append("token", token);
      frm.append("page", page + 1);

        axios.post("https://ai.semos.kr/semos_admin/notice/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setBanner(Value.banner_list)
            setBannerCount(Value.banner_count)
            setPage(page + 1)
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    } else {
      alert('마지막 페이지입니다.');
    }
  };

  const PageChange = (number) => {
    if ( number < (numPages + 1) && number > 0 ) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "banner_edit_update");
      frm.append("token", token);
      frm.append("page", number);

        axios.post("https://ai.semos.kr/semos_admin/notice/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setBanner(Value.banner_list)
            setBannerCount(Value.banner_count)
            setPage(number)
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    }
  };

    // 페이지 갯수 제한
    const numPages = Math.ceil(bannerCount / 10);
    let PageList = [];
    
    if(page < 6) {
      //PageList = [1,2,3,4,5,6,7,8,9,10];
      for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
        PageList.push(i+1);
      }
  
    } else {
      if( (numPages - 5) < page ) {
        var startPage = numPages - 9;
        if(startPage < 1) {
          startPage = 1;
        }
  
        for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
          PageList.push(startPage + i);
        }
  
      } else {
        var startPage = page - 4;
        if(startPage < 1) {
          startPage = 1;
        }
        
        for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
          PageList.push(startPage + i);
        }
      }
    }

    
  return (
    <MainLayout>
      <MainHeader title="배너">
        <ButtonBox>
          <button type='button' onClick={bannerSave} >저장하기</button>
        </ButtonBox>
      </MainHeader>

      <Main>
        <Index
          type="banner-edit"
          indexList={["NO.", "이름", "링크", "순서", "상태"]}
        />

        <ul>
          {banner ? banner.map((item, idx) => (
            <BannerEditRowCard
              key={idx}
              data={item}
              idx={idx}
              deleteHandler={deleteHandler}
              editHandler={editHandler}
              filteredData={filteredOrder}
            />
          )) : ""}
        </ul>
      </Main>

      <Footer>
       {/*<Pagination
          total={list.length}
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />*/}

          <Div>
            <SectionButton onClick={prevHandler}>&lt;</SectionButton>

            <List>
              {PageList.length ? PageList.map((item, idx) => 
                ( <PageButton
                    key={idx}
                    onClick={() => PageChange(item)}
                    aria-current={page === item ? "page" : null}
                    active={page === item ? true : false}
                  >
                    {item}
                  </PageButton>
                )) : ""}
            </List>

            <SectionButton onClick={nextHandler}>&gt;</SectionButton>
          </Div>
      </Footer>
    </MainLayout>
  );
};

export default BannerEditPage;
