import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Profile from "../../components/customer/profile";
import MainHeader from "../../components/header";
import customerDetailData from "../../data/customer/detail";
import MainLayout from "../../layouts/mainLayout";
import Button from "../../modules/button";
import base from "../../styles/constants/base";
import colors from "../../styles/constants/colors";
import typography from "../../styles/constants/typography";
import flexbox from "../../styles/func/flexbox";

const Main = styled.main`
  height: 100%;
  padding: 20px;
  margin-top: 20px;
  padding-bottom: 150px;
  overflow-y: scroll;

  section:not(:last-child) {
    margin-bottom: 50px;
  }

  section:nth-child(2) {
    font-weight: ${typography.weight.bold};
    font-size: ${typography.size.medium}px;
  }

  section:last-child {
    p:first-child {
      margin-bottom: 20px;
    }
  }
`;

const PartnerProfileSection = styled.section`
  p {
    font-weight: ${typography.weight.bold};
    font-size: ${typography.size.medium}px;
    margin-bottom: 20px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      width: calc(50% - 10px);
      border: 2px solid ${colors.lightGray};
      border-radius: ${base.borderRadius.default}px;
      height: 44px;
      margin-right: 20px;
      margin-bottom: 20px;
      padding: 0 20px;
      ${flexbox("flex-start")}
    }

    li:nth-child(even) {
      margin-right: 0px;
    }
  }
`;

const Comment = styled.div`
  width: 100%;
  min-height: 270px;
  background-color: ${colors.lightBlue};
  border-radius: ${base.borderRadius.default}px;
  padding: 20px;
`;

const DefaultComment = styled.div`
  width: 100%;
  min-height: 270px;
  background-color: ${colors.lightGray};
  border-radius: ${base.borderRadius.default}px;
  padding: 20px;
`;

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90px;
  padding-right: 20px;
  ${flexbox("flex-end")}
  background-color: ${colors.white};

  button:first-child {
    margin-right: 10px;
  }
`;



const CustomerDatailPage = () => {
  const CUSTOMER_NUM = 1234;
  const { name, type, comment, profile } = customerDetailData;
  const navigate = useNavigate();

  const { cid } = useParams();
  // 상세 보기 및 수정 다 가능한 페이지(state)
  // 고객은 일반 회원과 파트너로 나뉜다(state)

  const goToEditHandler = () => {
    navigate(`/customer/${cid}/edit`);
  };

  const resignHandler = () => {
    if(window.confirm("해당 유저의 탈퇴를 진행하시겠습니까?"))  {


      var token = window.localStorage.getItem('token')
  
      const frm = new FormData();
      frm.append("mode", "retire");
      frm.append("token", token);
      frm.append("user_number", cid);

        axios.post("https://ai.semos.kr/semos_admin/user/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            alert(statusMessage)
            navigate(-1);
          } else if(status == 'waring') {
            alert(statusMessage)
            window.location.replace('/')
          } else if(status == 'fail') {
            alert(statusMessage)
            window.location.reload();
          }

        });

    }
  };

  const [customer, setCustomer] = useState("");
  const [profileData, setProfile] = useState("");

      // 고객 리스트 로드
      useEffect(() => {
    
        var token = window.localStorage.getItem('token')
  
        const frm = new FormData();
        frm.append("mode", "specific");
        frm.append("token", token);
        frm.append("number", cid);
  
          axios.post("https://ai.semos.kr/semos_admin/user/data", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          }).then((data) => {
            var Value = data.data;
            var status = Value.status
            var statusMessage = Value.status_message
  
            if(status == 'success') {
              setProfile(Value.profile_list)
              setCustomer(Value.customer_data)
            } else if(status == 'waring') {
              alert(statusMessage)
            }
  
          });
  
      },[]);

  return (
    <MainLayout>
      <MainHeader title={customer ? customer.name : "" } />

      <Main>
        <Profile data={customer} />

        <section>
          <p>타입 : {customer ? customer.type : "" }</p>
        </section>

        {customer.type === "파트너" || customer.type === "리포츠" ? (
          <PartnerProfileSection>
            <p>프로필</p>

            <ul>
              {profileData &&
                profileData.map((item, idx) => <li key={idx}>{item}</li>)}
            </ul>
          </PartnerProfileSection>
        ) : ""}

        <section>
          <p>메모</p>

          {customer.memo ? (
            <Comment>{customer.memo && customer.memo}</Comment>
          ) : <DefaultComment>{"등록된 메모가 없습니다."}</DefaultComment>
          }
        </section>
      </Main>

      <Footer>
        <Button
          text="탈퇴하기"
          clickHandler={resignHandler}
          width={`230px`}
          color={colors.white}
          fontcolor={colors.gray}
        />

        <Button
          text="수정하기"
          clickHandler={goToEditHandler}
          width={`230px`}
        />
      </Footer>
    </MainLayout>
  );
};

export default CustomerDatailPage;
