import MainHeader from "../../../../components/header";
import useInput from "../../../../hooks/useInput";
import MainLayout from "../../../../layouts/mainLayout";
import Input from "../../../../modules/input";
import { Main, SearchIcon, FilterBox, Footer } from "./style";
import searchIcon from "../../../../assets/icon/search.svg";
import UserFilterBox from "../../../../components/faq/userFilterBox";
import usePagination from "../../../../hooks/usePagination";
import faqList from "../../../../data/faq";
import Pagination from "../../../../components/pagination";
import CategoryTabBar from "../../../../components/faq/categoryTabBar";
import useList from "../../../../hooks/useList";
import FAQCard from "../../../../components/faq/card";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import base from "../../../../styles/constants/base";
import flexbox from "../../../../styles/func/flexbox";
import typography from "../../../../styles/constants/typography";
import colors from "../../../../styles/constants/colors";
import styled from "styled-components";
import axios from "axios";


// pagination
const Div = styled.div`
  width: 100%;
  height: 100%;
  ${flexbox("space-between")};
  font-weight: ${typography.weight.regular};
`;

const SectionButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: ${colors.white};
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};

  &:focus {
    box-shadow: ${base.boxShadow.default};
  }
`;

const List = styled.ul`
  ${flexbox()}
`;

const PageButton = styled.li`
  width: 40px;
  height: 40px;
  border: ${({ active }) => (active ? `2px solid ${colors.blue}` : null)};
  color: ${({ active }) => (active ? colors.blue : null)};
  ${flexbox()};
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};
  margin: 0 5px;
  cursor: pointer;
`;


const FAQPage = () => {
  const { num, u, t, f } = useParams();
  const navigate = useNavigate();
  const numCheck = num > 1 || (u || t || f );

  const {
    value: filterValue,
    setValue: setFilterValue,
    inputChangeHandler: filterInputChangeHandler,
  } = useInput({
    search: t !== 'all' ? t : "" ,
    user: u ? u : "customer" ,
  });

  const [faq, setFaq] = useState();
  const [faqCount, setFaqCount] = useState(0);
  const [page, setPage] = useState(1);
  const [updateStatus, setUpdateStatus] = useState(false);

  const { value: tabValue, setValue: setTabValue } = useInput(f);

    // FAQ 리스트 로드
    useEffect(() => {
    
        var token = window.localStorage.getItem('token')
  
        const frm = new FormData();
        frm.append("mode", updateStatus || numCheck ? "faq_update" : "faq");
        frm.append("token", token);
        frm.append("page", updateStatus ? 1 : num);
        frm.append("text", (updateStatus || numCheck ? filterValue.search : 'all'));
        frm.append("type", (updateStatus || numCheck ? filterValue.user : 'customer'));
        frm.append("category", (updateStatus || numCheck ? tabValue : 'all'));

          axios.post("https://ai.semos.kr/semos_admin/notice/data", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          }).then((data) => {
            var Value = data.data;
            var status = Value.status
            var statusMessage = Value.status_message
  
            if(status == 'success') {
  
              setFaq(Value.faq_list)
              setFaqCount(Value.faq_count)
              setUpdateStatus(true)

              if(updateStatus) {
                setPage(1)
                navigate(`/faq_list/${1}/${filterValue.user ? filterValue.user : "customer" }/${filterValue.search ? filterValue.search : "all" }/${tabValue ? tabValue : "all" }`,  { replace: true})
              } else {
                setPage(num)
              }

            } else if(status == 'waring') {
              alert(statusMessage)
            }
  
          });
  
    }, [filterValue.search, filterValue.user, tabValue]);

  const goToNoticeRegisterHandler = () => {
    navigate("/notice/register");
  };

  const {
    list: faqListData,
    setList: setFaqList,
  } = useList(faqList);

  

  const { offset, limit, currentPage, setCurrentPage } = usePagination(
    faqListData,
    7
  );

  const goToRegisterHandler = () => {
    navigate("/faq/register");
  };

  const userChangeHandler = (e) => {
    const { name } = e.target;

    setFilterValue((prev) => ({
      ...prev,
      user: name,
    }));
  };

  const tabChangeHandler = (name) => {
    setTabValue(name);

    const filteredList = faqList.filter((item) => {
      let result;

      if (name === "all") {
        result = item;
      } else {
        result = item.category === name;
      }

      return result;
    });

    setCurrentPage(1);

    setFaqList(filteredList);
  };

  const faqEditHandler = (number, type, value) => {

    var faqData = [...faq];

    faqData[number][type] = value;

    setFaq(faqData)

  }

  const faqSaveHandler = (number) => {
        var token = window.localStorage.getItem("token");

        axios.post(
            "https://ai.semos.kr/semos_admin/notice/data",
            {
              mode: "faq_edit_save",
              token: token,
              data : faq[number],
            },
            {
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
            }
          )
          .then((data) => {
            var Value = data.data;
            var status = Value.status;
            var statusMessage = Value.status_message;
    
            if (status == "success") {
              alert(statusMessage);
              return true
            } else if (status == "waring") {
              alert(statusMessage);
              return false
            }
          });

  }

  const faqDeleteHandler = (number) => {

    if(window.confirm('삭제하시겠습니까?')) { 
        var token = window.localStorage.getItem("token");

        axios.post(
            "https://ai.semos.kr/semos_admin/notice/data",
            {
              mode: "faq_delete",
              token: token,
              number : faq[number].id,
            },
            {
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
            }
          )
          .then((data) => {
            var Value = data.data;
            var status = Value.status;
            var statusMessage = Value.status_message;
    
            if (status == "success") {
    
              alert(statusMessage);
              var faqData = [...faq]
              faqData.splice(number, 1)
              setFaq(faqData)
    
            } else if (status == "waring") {
              alert(statusMessage);
            }
          });
    }
  }


  const prevHandler = () => {
    if (page > 1) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "faq_update");
      frm.append("token", token);
      frm.append("page", page - 1);
      frm.append("text", filterValue.search);
      frm.append("type", filterValue.user);
      frm.append("category", tabValue);

        axios.post("https://ai.semos.kr/semos_admin/notice/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setFaq(Value.faq_list)
            setFaqCount(Value.faq_count)
            setPage(page - 1)
            navigate(`/faq_list/${page - 1}/${filterValue.user ? filterValue.user : "customer" }/${filterValue.search ? filterValue.search : "all" }/${tabValue ? tabValue : "all" }`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    } else {
      alert('첫 페이지입니다.');
    }
  };

  const nextHandler = () => {
    if (page < numPages) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "faq_update");
      frm.append("token", token);
      frm.append("page", page + 1);
      frm.append("text", filterValue.search);
      frm.append("type", filterValue.user);
      frm.append("category", tabValue);

        axios.post("https://ai.semos.kr/semos_admin/notice/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setFaq(Value.faq_list)
            setFaqCount(Value.faq_count)
            setPage(page + 1)
            navigate(`/faq_list/${page + 1}/${filterValue.user ? filterValue.user : "customer" }/${filterValue.search ? filterValue.search : "all" }/${tabValue ? tabValue : "all" }`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    } else {
      alert('마지막 페이지입니다.');
    }
  };

  const PageChange = (number) => {
    if ( number < (numPages + 1) && number > 0 ) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "faq_update");
      frm.append("token", token);
      frm.append("page", number);     
      frm.append("text", filterValue.search);
      frm.append("type", filterValue.user);
      frm.append("category", tabValue);

        axios.post("https://ai.semos.kr/semos_admin/notice/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setFaq(Value.faq_list)
            setFaqCount(Value.faq_count)
            setPage(number)
            navigate(`/faq_list/${number}/${filterValue.user ? filterValue.user : "customer" }/${filterValue.search ? filterValue.search : "all" }/${tabValue ? tabValue : "all" }`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    }
  };

  const numPages = Math.ceil(faqCount / 7);
  let PageList = [];
  
  if(page < 6) {
    //PageList = [1,2,3,4,5,6,7,8,9,10];
    for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
      PageList.push(i+1);
    }

  } else {
    if( (numPages - 5) < page ) {
      var startPage = numPages - 9;
      if(startPage < 1) {
        startPage = 1;
      }

      for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
        PageList.push(startPage + i);
      }

    } else {
      var startPage = page - 4;
      if(startPage < 1) {
        startPage = 1;
      }
      
      for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
        PageList.push(startPage + i);
      }
    }
  }

  return (
    <MainLayout heightType={true} button={{ type: "plus", clickHandler: goToRegisterHandler }}>
      <MainHeader title="자주 묻는 질문(FAQ)">
        <FilterBox>
          <div>
            <Input
              width="400px"
              type="text"
              name="search"
              value={filterValue.search}
              changeHandler={filterInputChangeHandler}
              placeholder="찾고 싶은 키워드로 검색"
              padding={true}
              margin={true}
            >
              <SearchIcon src={searchIcon} />
            </Input>
          </div>

          <UserFilterBox
            value={filterValue.user}
            changeHandler={userChangeHandler}
          />
        </FilterBox>
      </MainHeader>

      <Main>
        <CategoryTabBar value={tabValue} changeHandler={tabChangeHandler} />

        <ul>
          {faq ? faq.map((item, idx) => (
            <FAQCard
              key={idx}
              data={item}
              idx={idx + offset + 1}
              numberData={idx}
              currentPage={currentPage}
              limit={limit}
              deleteHandler={faqDeleteHandler}
              editHandler={faqEditHandler}
              tab={tabValue}
              faqSaveHandler={faqSaveHandler}
            />
          )) : faq}
        </ul>
      </Main>

      <Footer>
        {/*<Pagination
          total={faqListData.length}
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          />*/}

          <Div>
            <SectionButton onClick={prevHandler}>&lt;</SectionButton>

            <List>
              {PageList.length ? PageList.map((item, idx) => 
                ( <PageButton
                    key={idx}
                    onClick={() => PageChange(item)}
                    aria-current={page == item ? "page" : null}
                    active={page == item ? true : false}
                  >
                    {item}
                  </PageButton>
                )) : ""}
            </List>

            <SectionButton onClick={nextHandler}>&gt;</SectionButton>
          </Div>

      </Footer>
    </MainLayout>
  );
};

export default FAQPage;
