import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useNavigate, useParams, Link } from "react-router-dom";

// components
import DesignedMainHeader from "../../components/designedHeader";
import DesignedMainLayout from "../../layouts/designedMainLayout";
import Table from "../../layouts/table";
import DrawerModal from "../../components/drawerModal";
import DropDown from "../../components/designedComponents/dropDown";
import SalesDetail from "../../components/designedSales/salesDetail";
import {
  TableFilterContainer,
  TableFilterContent,
  TableWrapper,
  Wrapper,
  AbsoluteWrapper,
  Icon,
  Text,
  TableContent,
  TableFooter,
  TableColumn,
  TableFooterPaging,
} from "../../layouts/layoutStorage";

// icon
import SearchIcon from "../../assets/icon/search.png";
import FilterIcon from "../../assets/icon/filter.png";
import DoublePaging from "../../assets/icon/double_paging.png";
import OnePaging from "../../assets/icon/one_paging.png";
import ReservationCheck from "../../assets/icon/reservation_check.png";
import ReservationConfirm from "../../assets/icon/reservation_confirm.png";
import ReservationCancel from "../../assets/icon/reservation_cancel.png";
import ReservationClear from "../../assets/icon/reservation_clear.png";
import ReservationDone from "../../assets/icon/reservation_done.png";
import colors from "../../styles/constants/colors";

// import ArrowIcon from "../../assets/icon/arrow_black.png";

const Sales = () => {
  const { n, t, s, e, d, status, pay, platform } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  // desinged height
  const [Height, setHeight] = useState({
    TableFilter: 0,
    TableHeader: 0,
    TableFooter: 0,
    Layout: 0,
    LayoutHeader: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const Layout = document.getElementById("Layout").offsetHeight - 36;
      const LayoutHeader = document.getElementById("LayoutHeader").offsetHeight + 65;
      const TableFilter = document.getElementById("TableFilter").offsetHeight + 19;
      const TableHeader = document.getElementById("TableHeader").offsetHeight;
      const TableFooter = document.getElementById("TableFooter").offsetHeight;

      setHeight({
        TableFilter: TableFilter,
        TableHeader: TableHeader,
        TableFooter: TableFooter,
        Layout: Layout,
        LayoutHeader: LayoutHeader,
      });
    }, 500);
  }, []);

  // filter
  const [filter, setFilter] = useState({
    text: t !== "all" ? t : "",
    start: s !== "all" ? s : "",
    end: e !== "all" ? e : "",
    date_type: d,
    status: status.split(",").includes("all") ? [] : status.split(","),
    pay_type: pay,
    pay_platform: platform,
  });

  const [filterCopy, setFilterCopy] = useState({
    text: t !== "all" ? t : "",
    start: s !== "all" ? s : "",
    end: e !== "all" ? e : "",
    date_type: d,
    status: status.split(",").includes("all") ? [] : status.split(","),
    pay_type: pay,
    pay_platform: platform,
  });
  const [page, setPage] = useState(n);
  const [popup, setPopup] = useState(false);

  // data
  const [payment, setPayment] = useState("");
  const [paymentCount, setPaymentCount] = useState(0);
  const [paymentDetail, setPaymentDetail] = useState();
  const [token, setToken] = useState();
  const [amountList, setAmountList] = useState({
    'real' : 0,
    'all' : 0,
    'point' : 0
  });

  const loadData = () => {
    var token = window.localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", "sales_update");
    frm.append("token", token);
    frm.append("page", n);
    frm.append("text", t !== "all" ? t : "");
    frm.append("date_type", d);
    frm.append("pay_platform", platform);
    frm.append("pay_type", pay);
    frm.append("start", s !== "all" ? s : "");
    frm.append("end", e !== "all" ? e : "");
    frm.append("status", TextSplitEng2(status));

    axios
      .post("https://ai.semos.kr/semos_admin/payment/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;

        if (Status == "success") {
          setPayment(Value.payment_list);
          setPaymentCount(Value.payment_count);
          setToken(token);

          setAmountList({
            'real' : Value.real_pay,
            'all' : Value.all_amount,
            'point' : Value.real_point
          })
        } else if (Status == "waring") {
          alert(StatusMessage);
        }
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const PageInput = (num) => {
    if (num) {
      var token = window.localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "sales_update");
      frm.append("token", token);
      frm.append("page", num);
      frm.append("text", filter.text != "all" ? filter.text : "");
      frm.append("date_type", filter.date_type ? filter.date_type : "");
      frm.append("pay_type", filter.pay_type ? filter.pay_type : "");
      frm.append("pay_platform", filter.pay_platform ? filter.pay_platform : "");
      frm.append("start", filter.start && (filter.start.length > 9 ? filter.start : ""));
      frm.append("end", filter.end && (filter.end.length > 9 ? filter.end : ""));
      frm.append("status", TextSplitEng(filter.status));

      axios
        .post("https://ai.semos.kr/semos_admin/payment/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            setPayment(Value.payment_list);
            setPaymentCount(Value.payment_count);
            setPage(num);
            navigate(
              `/sales_list/${num}/${filter.text ? filter.text : "all"}/${filter.start ? filter.start : "all"}/${filter.end ? filter.end : "all"}/${filter.date_type}/${TextSplit(filter.status)}/${filter.pay_type}/${filter.pay_platform}`
            );

            setAmountList({
              'real' : Value.real_pay,
              'all' : Value.all_amount,
              'point' : Value.real_point
            })
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    }
  };

  // filter setting
  const TextInput = (type, text) => {
    var copy = text;

    if (type == "text") {
      var regExp = /[`~!@#$%^&*()_|+\-=?;:'"<>\{\}\\\\/]/gim;

      if (regExp.test(copy)) {
        alert("특수문자는 입력하실수 없습니다.");
        copy = copy.replace(regExp, "");
      }
    }
    if (type == `status`) {
      if (copy == "clear") {
        setFilterCopy({ ...filterCopy, [type]: [] });
      } else {
        let value = filterCopy;
        let valueContent = value.status;

        if (valueContent.includes(copy)) {
          for (var i = 0; i < valueContent.length; i++) {
            if (copy == valueContent[i]) {
              valueContent.splice(i, 1);
              break;
            }
          }
        } else {
          if (valueContent.length == 4) {
            valueContent = [];
          } else {
            valueContent[valueContent.length] = copy;
          }
        }
        setFilterCopy({ ...filterCopy, [type]: valueContent });
      }
    } else {
      setFilter({ ...filter, [type]: copy });
    }
  };

  // text 클릭 검색
  const TextSearch = () => {
    if (filter.text != "all") {
      var token = window.localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "sales_update");
      frm.append("token", token);
      frm.append("page", "1");
      frm.append("text", filter.text != "all" ? filter.text : "");
      frm.append("date_type", filter.date_type ? filter.date_type : "");
      frm.append("pay_type", filter.pay_type ? filter.pay_type : "");
      frm.append("pay_platform", filter.pay_platform ? filter.pay_platform : "");
      frm.append("start", filter.start && (filter.start.length > 9 ? filter.start : ""));
      frm.append("end", filter.end && (filter.end.length > 9 ? filter.end : ""));
      frm.append("status", TextSplitEng(filter.status));

      axios
        .post("https://ai.semos.kr/semos_admin/payment/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            setPayment(Value.payment_list);
            setPaymentCount(Value.payment_count);
            setPage(1);

            navigate(
              `/sales_list/${page}/${filter.text ? filter.text : "all"}/${filter.start ? filter.start : "all"}/${filter.end ? filter.end : "all"}/${filter.date_type}/${TextSplit(filter.status)}/${filter.pay_type}/${filter.pay_platform}`
            );

            setAmountList({
              'real' : Value.real_pay,
              'all' : Value.all_amount,
              'point' : Value.real_point
            })
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    }
  };

  // date 클릭 검색
  const DateSearch = () => {
    if (filter.start.length > 9 && filter.end.length > 9) {
      var token = window.localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "sales_update");
      frm.append("token", token);
      frm.append("page", "1");
      frm.append("text", filter.text != "all" ? filter.text : "");
      frm.append("date_type", filter.date_type ? filter.date_type : "");
      frm.append("pay_type", filter.pay_type ? filter.pay_type : "");
      frm.append("pay_platform", filter.pay_platform ? filter.pay_platform : "");
      frm.append("start", filter.start && (filter.start.length > 9 ? filter.start : ""));
      frm.append("end", filter.end && (filter.end.length > 9 ? filter.end : ""));
      frm.append("status", TextSplitEng(filter.status));

      axios
        .post("https://ai.semos.kr/semos_admin/payment/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            setPayment(Value.payment_list);
            setPaymentCount(Value.payment_count);
            setPage("1");

            navigate(
              `/sales_list/${page}/${filter.text ? filter.text : "all"}/${filter.start ? filter.start : "all"}/${filter.end ? filter.end : "all"}/${filter.date_type}/${TextSplit(filter.status)}/${filter.pay_type}/${filter.pay_platform}`
            );

            setAmountList({
              'real' : Value.real_pay,
              'all' : Value.all_amount,
              'point' : Value.real_point
            })
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    }
  };

  // text enter 검색
  const EnterSearch = (key) => {
    if (key == "Enter") {
      TextSearch();
    }
  };

  // filter clear
  const FilterHandle = (type) => {
    if (type == "clear") {
      setFilterCopy({ ...filterCopy, status: [] });
    } else if (type == "confirm") {
      var token = window.localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "sales_update");
      frm.append("token", token);
      frm.append("page", "1");
      frm.append("text", filter.text != "all" ? filter.text : "");
      frm.append("date_type", filter.date_type ? filter.date_type : "");
      frm.append("pay_type", filter.pay_type ? filter.pay_type : "");
      frm.append("pay_platform", filter.pay_platform ? filter.pay_platform : "");
      frm.append("start", filter.start && (filter.start.length > 9 ? filter.start : ""));
      frm.append("end", filter.end && (filter.end.length > 9 ? filter.end : ""));
      frm.append("status", TextSplitEng(filterCopy.status));

      axios
        .post("https://ai.semos.kr/semos_admin/payment/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var status = Value.status;
          var statusMessage = Value.status_message;

          if (status == "success") {
            setPayment(Value.payment_list);
            setPaymentCount(Value.payment_count);
            setPage("1");
            setFilter({ ...filter, status: filterCopy.status });
            setPopup(false);

            navigate(
              `/sales_list/${page}/${filter.text ? filter.text : "all"}/${filter.start ? filter.start : "all"}/${filter.end ? filter.end : "all"}/${filter.date_type}/${TextSplit(filter.status)}/${filter.pay_type}/${filter.pay_platform}`
            );

            setAmountList({
              'real' : Value.real_pay,
              'all' : Value.all_amount,
              'point' : Value.real_point
            })
          } else if (status == "waring") {
            alert(statusMessage);
          }
        });
    }
  };

  const numPages = Math.ceil(paymentCount / 12);
  let PageList = [];

  if (page < 11) {
    for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
      PageList.push(i + 1);
    }
  } else {
    if (numPages - 10 < page) {
      var startPage = numPages - 19;
      if (startPage < 1) {
        startPage = 1;
      }

      for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    } else {
      var startPage = page - 9;
      if (startPage < 1) {
        startPage = 1;
      }

      for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    }
  }

  const StatusComponent = (status) => {
    switch (status) {
      case "예약확정":
        return (
          <Wrapper bgc={`#66A2FF`} padding={`4px 10px`} radius={`16px`}>
            <Icon margin={`0 2px 0 0`} width={`calc(1rem * (20 / 14))`} src={ReservationCheck} />
            <Text color={`#ffffff`} weight={`500`}>
              {`${status}`}
            </Text>
          </Wrapper>
        );
        break;

      case "결제완료":
        return (
          <Wrapper bgc={`#A9BA82`} padding={`4px 10px`} radius={`16px`}>
            <Icon margin={`0 2px 0 0`} width={`calc(1rem * (20 / 14))`} src={ReservationConfirm} />
            <Text color={`#ffffff`} weight={`500`}>
              {`${status}`}
            </Text>
          </Wrapper>
        );
        break;

      case "정산 완료":
        return (
          <Wrapper bgc={`#E5F0FF`} padding={`4px 10px`} radius={`16px`}>
            <Icon margin={`0 2px 0 0`} width={`calc(1rem * (20 / 14))`} src={ReservationClear} />
            <Text color={`#80B2FF`} weight={`500`}>
              {`${status}`}
            </Text>
          </Wrapper>
        );
        break;

      case "액티비티 완료":
        return (
          <Wrapper bgc={`#E5F0FF`} padding={`4px 10px`} radius={`16px`}>
            <Icon margin={`0 2px 0 0`} width={`calc(1rem * (20 / 14))`} src={ReservationDone} />
            <Text color={`#80B2FF`} weight={`500`}>
              {`${status}`}
            </Text>
          </Wrapper>
        );
        break;

      case "환불 및 취소":
        return (
          <Wrapper bgc={`#FFE4E1`} padding={`4px 10px`} radius={`16px`}>
            <Icon margin={`0 2px 0 0`} width={`calc(1rem * (20 / 14))`} src={ReservationCancel} />
            <Text color={`#FF9187`} weight={`500`}>
              {`${status}`}
            </Text>
          </Wrapper>
        );
        break;

      default :
          return (
            <></>
          )
        break;
    }
  };

  const TextSplit = (text) => {
    if (!text?.length || text == "all") {
      return "all";
    } else {
      var value = "";

      for (var i = 0; i < text.length; i++) {
        if (i == 0) {
          value = `${text[i]}`;
        } else {
          value = `${value},${text[i]}`;
        }
      }
      return value;
    }
  };

  const TextSplitEng = (text) => {
    var reserve_status = {
      예약확정: "used_c",
      "액티비티 완료": "done",
      "환불 및 취소": "cancel",
      결제완료: "check",
      "정산 완료": "clear",
    };

    if (!text.length || text == "all") {
      return "all";
    } else {
      var value = "";

      for (var i = 0; i < text.length; i++) {
        if (i == 0) {
          value = `${reserve_status[text[i]]}`;
        } else {
          value = `${value},${reserve_status[text[i]]}`;
        }
      }
      return value;
    }
  };

  const TextSplitEng2 = (text) => {
    var textlist = text.split(",");

    var reserve_status = {
      "예약확정": "used_c",
      "액티비티 완료": "done",
      "환불 및 취소": "cancel",
      "결제완료": "check",
      "정산 완료": "clear",
    };

    if (!textlist.length || textlist == "all") {
      return "all";
    } else {
      var value = "";

      for (var i = 0; i < textlist.length; i++) {
        if (i == 0) {
          value = `${reserve_status[textlist[i]]}`;
        } else {
          value = `${value},${reserve_status[textlist[i]]}`;
        }
      }
      return value;
    }
  };

  const PaymentType = () => {

    var token = window.localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", "sales_update");
    frm.append("token", token);
    frm.append("page", "1");
    frm.append("text", filter.text != "all" ? filter.text : "");
    frm.append("date_type", filter.date_type ? filter.date_type : "");
    frm.append("pay_type", filter.pay_type ? filter.pay_type : "");
    frm.append("pay_platform", filter.pay_platform ? filter.pay_platform : "");
    frm.append("start", filter.start && (filter.start.length > 9 ? filter.start : ""));
    frm.append("end", filter.end && (filter.end.length > 9 ? filter.end : ""));
    frm.append("status", TextSplitEng(filter.status));

    axios
      .post("https://ai.semos.kr/semos_admin/payment/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var status = Value.status;
        var statusMessage = Value.status_message;

        if (status == "success") {
          setPayment(Value.payment_list);
          setPaymentCount(Value.payment_count);
          setPage("1");
          setPopup(false);

          navigate(
            `/sales_list/${page}/${filter.text ? filter.text : "all"}/${filter.start ? filter.start : "all"}/${filter.end ? filter.end : "all"}/${filter.date_type}/${TextSplit(filter.status)}/${filter.pay_type}/${filter.pay_platform}`
          );

          setAmountList({
            'real' : Value.real_pay,
            'all' : Value.all_amount,
            'point' : Value.real_point
          })
        } else if (status == "waring") {
          alert(statusMessage);
        }
    });

  }

  useEffect(() => {
    if(token) {
      PaymentType();
    }
  }, [filter?.pay_type, filter?.pay_platform]);

  return (
    <DesignedMainLayout>
      <DesignedMainHeader title={`판매 내역`} subtitle={`전체 금액 : ${amountList?.all}원 = 결제 금액 : ${amountList?.real}원 + 포인트 : ${amountList?.point}원`} />

      <Table>
        <TableFilterContainer id="TableFilter" margin={`0 0 19px 0`}>
          <Wrapper width={`50%`} jc={`flex-start`}>
            <TableFilterContent margin={`0 24px 0 0`}>
              <Input
                onKeyUp={(e) => EnterSearch(e.key)}
                value={filter.text != "all" ? filter.text : ""}
                onChange={(e) => TextInput("text", e.target.value)}
                width={`250px`}
                type={`text`}
                maxLength={40}
                placeholder="닉네임(본명), 파트너, 액티비티"
              />
              <Icon width={`calc(1rem * (17 / 14))`} cursor={`pointer`} src={SearchIcon} onClick={() => TextSearch()} />
            </TableFilterContent>

            <TableFilterContent cursor={`pointer`} margin={`0 24px 0 0`}>
              <Text weight={`500`} margin={`0 2px 0 0`} color={`#3C3E41`} onClick={() => setPopup(!popup)}>
                {(TextSplit(filter.status) && (TextSplit(filter.status) != "all")) ? TextSplit(filter.status) : `예약 상태`}
              </Text>
              <Icon width={`calc(1rem * (17 / 14))`} src={FilterIcon} />

              <AbsoluteWrapper
                display={popup ? `inline-flex` : `none`}
                z={`100`}
                width={`400px`}
                bgc={`#ffffff`}
                bottom={`-8px`}
                left={`0`}
                transform={`translate(0, 100%)`}
              >
                <Wrapper wrap={`wrap`} width={`400px`} border={`1px solid #DCDCDC`}>
                  <Wrapper display={`inline-block`} padding={`8px`} width={`400px`} height={`210px`} ws={`normal`}>
                    <Wrapper onClick={() => TextInput(`status`, `clear`)} cursor={`pointer`} padding={`8px`}>
                      <Text color={!filterCopy.status.length || filterCopy.status.length == 5 ? `#3383FF` : `#818792`} weight={`500`}>
                        {`전체`}
                      </Text>
                    </Wrapper>

                    <Wrapper onClick={() => TextInput(`status`, `결제완료`)} cursor={`pointer`} padding={`8px`}>
                      <Text
                        color={filterCopy.status.includes(`결제완료`) && filterCopy.status.length != 5 ? `#3383FF` : `#818792`}
                        weight={`500`}
                      >{`결제완료`}</Text>
                    </Wrapper>

                    <Wrapper onClick={() => TextInput(`status`, `예약확정`)} cursor={`pointer`} padding={`8px`}>
                      <Text
                        color={filterCopy.status.includes(`예약확정`) && filterCopy.status.length != 5 ? `#3383FF` : `#818792`}
                        weight={`500`}
                      >{`예약확정`}</Text>
                    </Wrapper>

                    <Wrapper onClick={() => TextInput(`status`, `액티비티 완료`)} cursor={`pointer`} padding={`8px`}>
                      <Text
                        color={filterCopy.status.includes(`액티비티 완료`) && filterCopy.status.length != 5 ? `#3383FF` : `#818792`}
                        weight={`500`}
                      >{`액티비티 완료`}</Text>
                    </Wrapper>

                    {/*<Wrapper onClick={() => TextInput(`status`, `정산 완료`)} cursor={`pointer`} padding={`8px`}>
                      <Text
                        color={filterCopy.status.includes(`정산 완료`) && filterCopy.status.length != 5 ? `#3383FF` : `#818792`}
                        weight={`500`}
                      >{`정산 완료`}</Text>
                    </Wrapper>*/}

                    <Wrapper onClick={() => TextInput(`status`, `환불 및 취소`)} cursor={`pointer`} padding={`8px`}>
                      <Text
                        color={filterCopy.status.includes(`환불 및 취소`) && filterCopy.status.length != 5 ? `#3383FF` : `#818792`}
                        weight={`500`}
                      >{`환불 및 취소`}</Text>
                    </Wrapper>
                  </Wrapper>

                  <Wrapper position={`relative`} display={`inline-block`} padding={`8px`} width={`100%`} height={`100px`} bt={`1px solid #DCDCDC`}>
                    {!filterCopy.status.length || filterCopy.status.length == 5 ? (
                      <Wrapper key={`status_${"all"}`} cursor={`pointer`} padding={`8px`}>
                        <Text className="f13" color={`#3383FF`} weight={`500`}>{`전체`}</Text>
                      </Wrapper>
                    ) : (
                      filterCopy.status.map((item, idx) => (
                        <Wrapper key={`status_${idx}`} onClick={() => TextInput(`status`, item)} cursor={`pointer`} padding={`8px`}>
                          <Text className="f13" color={`#3383FF`} weight={`500`}>
                            {item}
                          </Text>
                        </Wrapper>
                      ))
                    )}

                    <AbsoluteWrapper display={`inline-flex`} z={`101`} bgc={`#ffffff`} bottom={`8px`} right={`8px`}>
                      <Wrapper
                        cursor={`pointer`}
                        padding={`9px 20px`}
                        bgc={`#F5F5F5`}
                        radius={`8px`}
                        margin={`0 16px 0 0`}
                        onClick={() => FilterHandle(`clear`)}
                      >
                        <Text className="f14" color={`#818792`} weight={`500`}>
                          {`초기화`}
                        </Text>
                      </Wrapper>

                      <Wrapper cursor={`pointer`} padding={`9px 20px`} bgc={`#0E6DFF`} radius={`8px`} onClick={() => FilterHandle(`confirm`)}>
                        <Text className="f14" color={`#ffffff`} weight={`500`}>
                          {`적용`}
                        </Text>
                      </Wrapper>
                    </AbsoluteWrapper>
                  </Wrapper>
                </Wrapper>
              </AbsoluteWrapper>
            </TableFilterContent>

            <DropDown
              data={filter?.pay_platform}
              setData={setFilter}
              option={[
                { text: "결제 플랫폼 전체", value: "결제 플랫폼 전체" },
                { text: "B2C", value: "B2C" },
                { text: "B2B", value: "B2B" }
              ]}
              type={`object`}
              defaultText={`결제 플랫폼 전체`}
              no={false}
              keyName={`pay_platform`}
            />

            <DropDown
              data={filter?.pay_type}
              setData={setFilter}
              option={[
                { text: "결제 방법 전체", value: "결제 방법 전체" },
                { text: "인앱+현장", value: "인앱+현장" },
                { text: "인앱 결제", value: "인앱 결제" },
                { text: "현장 결제", value: "현장 결제" },
                { text: "선물", value: "선물" }
              ]}
              type={`object`}
              defaultText={`결제 방법 전체`}
              no={false}
              keyName={`pay_type`}
            />
          </Wrapper>

          <Wrapper width={`50%`} jc={`flex-end`}>
            <DropDown
              data={filter.date_type}
              setData={setFilter}
              option={[
                { text: "결제 기간", value: "결제 기간" },
                { text: "레슨일", value: "레슨일" }
              ]}
              type={`object`}
              defaultText={`결제 기간`}
              no={false}
              keyName={`date_type`}
            />

            <TableFilterContent margin={`0 10px 0 0`}>
              <Input
                value={filter.start != "all" ? filter.start : ""}
                onChange={(e) => TextInput("start", e.target.value)}
                type={`text`}
                maxLength={10}
                width={`8rem`}
                placeholder="연도.월.일 (시작)"
              />
            </TableFilterContent>

            <TableFilterContent margin={`0 10px 0 0`}>
              <Input
                value={filter.end != "all" ? filter.end : ""}
                onChange={(e) => TextInput("end", e.target.value)}
                type={`text`}
                maxLength={10}
                width={`8rem`}
                placeholder="연도.월.일 (종료)"
              />
            </TableFilterContent>

            <Wrapper width={`auto`} jc={`center`} cursor={`pointer`}>
              <Text onClick={() => DateSearch()} className="f13" color={`#3383FF`} weight={`500`}>
                보기
              </Text>
            </Wrapper>
          </Wrapper>
        </TableFilterContainer>

        <TableContent height={Height.Layout - (Height.LayoutHeader + Height.TableFilter + Height.TableFooter)} contentHeight={Height.TableHeader}>
          <section id="TableHeader">
            <TableColumn width={`6`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
              주문번호
            </TableColumn>

            <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
              닉네임 (본명)
            </TableColumn>

            <TableColumn width={`9`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
              파트너
            </TableColumn>

            <TableColumn width={`7`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
              카테고리
            </TableColumn>

            <TableColumn width={`18`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
              액티비티
            </TableColumn>

            <TableColumn width={`8`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
              결제방법
            </TableColumn>

            <TableColumn width={`8`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
              결제금액
            </TableColumn>

            <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
              결제일
            </TableColumn>

            <TableColumn width={`11`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
              예약 상태
            </TableColumn>

            <TableColumn width={`13`} weight={`500`}>
              시작일
            </TableColumn>
          </section>

          <section>
            {payment
              ? payment.map((item, idx) => (
                  <Link to={`/sales_detail/${item.id}`}>
                    <TableWrapper
                      cursor={`pointer`}
                      key={`${idx}_reservation`}
                      jc={`flex-start`}
                      bl={`1px solid transparent`}
                      br={`1px solid transparent`}
                      bb={`1px solid ${colors.Gr02}`}
                      bt={`1px solid transparent`}
                      width={`100%`}
                      bgc={`#ffffff`}
                    >
                      <TableColumn width={`6`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        {`${item.payment_number}`}
                      </TableColumn>

                      <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        {`${item.user} (${item.user_name ? item.user_name : "미작성"})`}
                      </TableColumn>

                      <TableColumn width={`9`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        {`${item.partner}`}
                      </TableColumn>

                      <TableColumn width={`7`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        {`${item.sport}`}
                      </TableColumn>

                      <TableColumn ta={`left`} width={`18`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        {`${item.service}`}
                      </TableColumn>

                      <TableColumn width={`8`} weight={`500`} br={`1px solid ${colors.Gr02}`} color={colors.Bl07}>
                        {`${item.payment_method}`}
                      </TableColumn>

                      <TableColumn width={`8`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                        {`${item.amount_text} 원`}
                      </TableColumn>

                      <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`} color={`#ACAFB5`}>
                        {`${item.date}`}
                      </TableColumn>

                      <TableColumn width={`11`} weight={`500`} br={`1px solid ${colors.Gr02}`} padding={`7px 16px`}>
                        {StatusComponent(item.status)}
                      </TableColumn>

                      <TableColumn width={`13`} weight={`500`}>
                        {`${item.lesson_date}`}
                      </TableColumn>
                    </TableWrapper>
                  </Link>
                ))
              : ""}

            <TableFooter id="TableFooter">
              <Icon
                margin={`0 16px 0 0`}
                width={`calc(1rem * (20 / 14))`}
                cursor={`pointer`}
                src={DoublePaging}
                onClick={() => {
                  if (Number(page) < 11) {
                    PageInput(1);
                  } else {
                    PageInput(Number(page) - 10);
                  }
                }}
              />
              <Icon
                margin={`0 16px 0 0`}
                width={`calc(1rem * (20 / 14))`}
                cursor={`pointer`}
                src={OnePaging}
                onClick={() => {
                  if (page > 1) {
                    PageInput(n - 1);
                  } else {
                    window.alert("첫번째 페이지 입니다.");
                  }
                }}
              />

              {PageList.length
                ? PageList.map((item, idx) => (
                    <TableFooterPaging
                      key={`paging_number_${idx}`}
                      margin={`0 16px 0 0`}
                      color={`${n == item ? "#3383FF" : "#ACAFB5"}`}
                      onClick={() => PageInput(item)}
                    >
                      {item}
                    </TableFooterPaging>
                  ))
                : ""}

              <Icon
                margin={`0 16px 0 0`}
                width={`calc(1rem * (20 / 14))`}
                cursor={`pointer`}
                transform={`rotate(180deg)`}
                src={OnePaging}
                onClick={() => {
                  if (n < numPages) {
                    PageInput(Number(n) + 1);
                  } else {
                    window.alert("마지막 페이지 입니다.");
                  }
                }}
              />
              <Icon
                width={`calc(1rem * (20 / 14))`}
                cursor={`pointer`}
                transform={`rotate(180deg)`}
                src={DoublePaging}
                onClick={() => {
                  if (Number(page) + 10 > numPages) {
                    PageInput(numPages);
                  } else {
                    PageInput(Number(page) + 10);
                  }
                }}
              />
            </TableFooter>
          </section>

          <TableFooter id="TableFooter" style={{ display: "none" }}>
            <Icon
              margin={`0 16px 0 0`}
              width={`calc(1rem * (20 / 14))`}
              cursor={`pointer`}
              src={DoublePaging}
              onClick={() => {
                if (Number(page) < 11) {
                  PageInput(1);
                } else {
                  PageInput(Number(page) - 10);
                }
              }}
            />
            <Icon
              margin={`0 16px 0 0`}
              width={`calc(1rem * (20 / 14))`}
              cursor={`pointer`}
              src={OnePaging}
              onClick={() => {
                if (page > 1) {
                  PageInput(n - 1);
                } else {
                  window.alert("첫번째 페이지 입니다.");
                }
              }}
            />

            {PageList.length
              ? PageList.map((item, idx) => (
                  <TableFooterPaging
                    key={`paging_number_${idx}`}
                    margin={`0 16px 0 0`}
                    color={`${n == item ? "#3383FF" : "#ACAFB5"}`}
                    onClick={() => PageInput(item)}
                  >
                    {item}
                  </TableFooterPaging>
                ))
              : ""}

            <Icon
              margin={`0 16px 0 0`}
              width={`calc(1rem * (20 / 14))`}
              cursor={`pointer`}
              transform={`rotate(180deg)`}
              src={OnePaging}
              onClick={() => {
                if (n < numPages) {
                  PageInput(Number(n) + 1);
                } else {
                  window.alert("마지막 페이지 입니다.");
                }
              }}
            />
            <Icon
              width={`calc(1rem * (20 / 14))`}
              cursor={`pointer`}
              transform={`rotate(180deg)`}
              src={DoublePaging}
              onClick={() => {
                if (Number(page) + 10 > numPages) {
                  PageInput(numPages);
                } else {
                  PageInput(Number(page) + 10);
                }
              }}
            />
          </TableFooter>
        </TableContent>
      </Table>
    </DesignedMainLayout>
  );
};

export default Sales;

const Input = styled.input`
  width: ${({ width }) => `${width}`};
  display: inline-block;
`;
