import { useRef, useState } from "react";
import styled from "styled-components";
import base from "../styles/constants/base";
import cameraIcon from "../assets/icon/camera.svg";
import cameraWhiteIcon from "../assets/icon/camera-w.svg";
import cameraCircleIcon from "../assets/icon/camera-circle.svg";
import colors from "../styles/constants/colors";
import flexbox from "../styles/func/flexbox";

import cameraBlackIcon from "../assets/icon/camera-black.svg";

const Box = styled.div`
  position: relative;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin-bottom: 0;
  border-radius: ${base.borderRadius.default}px;
  border: ${({ border }) =>
    border === true
      ? `2px solid ${colors.blue}`
      : `2px solid ${colors.lightGray}`};

  border: 1px solid
    ${({ type }) => (type === "editor" ? `transparent !important;` : "null")};

  cursor: pointer;

  margin-bottom: ${({ type }) => (type === "editor" ? "0" : "20px")};

  button {
    width: 100%;
    padding: 20px;
    position: absolute;
    bottom: 0;
    border-bottom-left-radius: ${base.borderRadius.default}px;
    border-bottom-right-radius: ${base.borderRadius.default}px;
    border: 2px solid ${colors.blue};
    color: ${colors.blue};
  }
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  display: none;
`;

const Preview = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ edit }) => (edit ? colors.gray : colors.white)};
  background-image: url(${({ url }) => url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: ${base.borderRadius.default}px;
  ${flexbox("center", "center", "column")}
  background-color: ${({ type }) => (type === "editor" ? `transparent` : null)};

  p {
    color: ${({ edit }) => (edit ? colors.white : colors.gray)};
  }
`;

const Preview2 = styled.div`
  width: 100%;
  height: 100%;
  border-radius: ${base.borderRadius.default}px;
  background-color: ${({ type }) => (type === "editor" ? `transparent` : null)};
  overflow : hidden;
  overflow-y : auto;
  overflow-x : hidden;

  p {
    color: ${({ edit }) => (edit ? colors.white : colors.gray)};
  }
`;

const PreviewImg = styled.img`
  width: 100%;
  display : inline-block;
`;

const Camera = styled.img`
  pointer-events: none;
  margin-bottom: ${({ type }) => (type === "editor" ? "0" : "10px")};
  width: ${({ type }) => (type === "editor" ? "30px" : "auto")};
`;

const FixedCamera = styled.img`
  position: absolute;
  bottom: -5px;
  right: -5px;
  pointer-events: none;
`;

const ImageInput = ({
  type = "base",
  edit = false,
  children,
  name,
  changeHandler,
  preview,
  width = "430px",
  height = "200px",
  border = false,
  imagePro,
}) => {
  const [minHeight, setMinHeight] = useState(height);

  const heightToggleHandler = () => {
    if (minHeight === height) {
      setMinHeight("1000px");
    } else {
      setMinHeight(height);
    }
  };

  const inputRef = useRef();

  const fileHandler = () => {
    inputRef.current.click();
  };

  if (type === "base") {
    return (
      <Box
        width={width}
        height={height}
        edit={edit}
        border={border}
        type={type}
      >
        <Input
          type="file"
          accept="image/*"
          onChange={({ target: { files } }) => {
            if (files.length) {
              imagePro({
                file: files[0],
                setImageUrl: ({ result }) => {
                  changeHandler(result);
                },
              });
            }
          }}
          name={`${name}`}
          ref={inputRef}
          color={type}
        />

        <Preview url={preview} onClick={fileHandler} edit={edit}>
          {!preview && (
            <>
              <Camera src={edit ? cameraWhiteIcon : cameraIcon} type={type} />
              {children}
            </>
          )}
        </Preview>
      </Box>
    );
  }

  if (type === "long2") {
    return (
      <Box
        width={width}
        height={height}
        edit={edit}
        border={border}
        type={type}
      >
        <Input
          type="file"
          accept="image/*"
          onChange={({ target: { files } }) => {
            if (files.length) {
              imagePro({
                file: files[0],
                setImageUrl: ({ result }) => {
                  changeHandler(result);
                },
              });
            }
          }}
          name={`${name}`}
          ref={inputRef}
          color={type}
        />

        <Preview2 onClick={fileHandler} edit={edit}>
          {!preview && (
            <>
              <Camera src={edit ? cameraWhiteIcon : cameraIcon} type={type} />
              {children}
            </>
          )}


          {preview && (
              <PreviewImg src={preview} />
          )}
        </Preview2>
      </Box>
    );
  }

  if (type === "camera") {
    return (
      <Box
        width={width}
        height={height}
        edit={edit}
        border={border}
        type={type}
      >
        <Input
          type="file"
          accept="image/*"
          onChange={changeHandler}
          name={`${name}`}
          ref={inputRef}
          color={type}
        />

        <Preview url={preview} onClick={fileHandler} edit={edit} type={type}>
          <FixedCamera src={cameraCircleIcon} />
        </Preview>
      </Box>
    );
  }

  if (type === "long") {
    return (
      <Box
        width={width}
        height={minHeight}
        edit={edit}
        border={border}
        type={type}
      >
        <Input
          type="file"
          accept="image/*"
          onChange={changeHandler}
          name={`${name}`}
          ref={inputRef}
          color={type}
        />

        <Preview url={preview} onClick={fileHandler} edit={edit}>
          {!preview && (
            <>
              <Camera src={edit ? cameraWhiteIcon : cameraIcon} type={type} />
              {children}
            </>
          )}
        </Preview>

        <button type="button" onClick={heightToggleHandler}>
          {minHeight === height ? "자세히 보기" : "접기"}
        </button>
      </Box>
    );
  }

  if (type === "editor") {
    return (
      <Box
        width={width}
        height={minHeight}
        edit={edit}
        border={border}
        type={type}
      >
        <Input
          type="file"
          accept="image/*"
          onChange={changeHandler}
          name={`${name}`}
          ref={inputRef}
          color={type}
        />

        <Preview url={preview} onClick={fileHandler} edit={edit} type={type}>
          {!preview && (
            <>
              <Camera src={cameraBlackIcon} type={type} />
              {children}
            </>
          )}
        </Preview>
      </Box>
    );
  }
};

export default ImageInput;
