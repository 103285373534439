import MainHeader from "../../../components/header";
import LocationCard from "../../../components/partner/schedule/locationCard";
import locationList from "../../../data/partner/location";
import usePagination from "../../../hooks/usePagination";
import MainLayout from "../../../layouts/mainLayout";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { Footer, Main } from "./style";
import styled from "styled-components";

// import Pagination from "../../../components/pagination";
import flexbox from "../../../styles/func/flexbox";
import typography from "../../../styles/constants/typography";
import colors from "../../../styles/constants/colors";
import base from "../../../styles/constants/base";

// pagination
const Div = styled.div`
  width: 100%;
  height: 100%;
  ${flexbox("space-between")};
  font-weight: ${typography.weight.regular};
`;

const SectionButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: ${colors.white};
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};

  &:focus {
    box-shadow: ${base.boxShadow.default};
  }
`;

const List = styled.ul`
  ${flexbox()}
`;

const PageButton = styled.li`
  width: 40px;
  height: 40px;
  border: ${({ active }) => (active ? `2px solid ${colors.blue}` : null)};
  color: ${({ active }) => (active ? colors.blue : null)};
  ${flexbox()};
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};
  margin: 0 5px;
  cursor: pointer;
`;

const PartnerLocationPage = () => {
  const { pid } = useParams();

  const locationData = locationList;

  const { offset, limit, currentPage, setCurrentPage } = usePagination(
    locationData,
    5
  );

          const [location, setLocation] = useState([]);
          const [locationCount, setLocationCount] = useState(0);
          const [page, setPage] = useState(1);

           // 문의 상세 로드
           useEffect(() => {
    
            var token = window.localStorage.getItem('token')
      
            const frm = new FormData();
            frm.append("mode", 'location');
            frm.append("token", token);
            frm.append("number", pid);
            frm.append("page", '1');
    
              axios.post("https://ai.semos.kr/semos_admin/partner/data", frm, {
                headers: { "Content-Type": "multipart/form-data" },
              }).then((data) => {
                var Value = data.data;
                var status = Value.status
                var statusMessage = Value.status_message
      
                if(status == 'success') {
                  setLocation(Value.location_list)
                  setLocationCount(Value.location_count)
                } else if(status == 'waring') {
                  alert(statusMessage)
                }
      
              });
      
          }, []);


  const prevHandler = () => {
    if (page > 1) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", 'location_update');
      frm.append("token", token);
      frm.append("number", pid);
      frm.append("page", page - 1);

        axios.post("https://ai.semos.kr/semos_admin/partner/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setLocation(Value.location_list)
            setLocationCount(Value.location_count)
            setPage(page - 1)
          } else if(status == 'waring') {
            alert(statusMessage)
          }
        });
    } else {
      alert('첫 페이지입니다.');
    }
  };

  const nextHandler = () => {
    if (page < numPages) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", 'location_update');
      frm.append("token", token);
      frm.append("number", pid);
      frm.append("page", page + 1);

        axios.post("https://ai.semos.kr/semos_admin/partner/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setLocation(Value.location_list)
            setLocationCount(Value.location_count)
            setPage(page + 1)
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    } else {
      alert('마지막 페이지입니다.');
    }
  };

  const PageChange = (number) => {
    if ( number < (numPages + 1) && number > 0 ) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", 'location_update');
      frm.append("token", token);
      frm.append("number", pid);
      frm.append("page", number);

        axios.post("https://ai.semos.kr/semos_admin/partner/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setLocation(Value.location_list)
            setLocationCount(Value.location_count)
            setPage(number)
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    }
  };

          const numPages = Math.ceil(locationCount / 5);
          let PageList = [];
          
          if(page < 6) {
            //PageList = [1,2,3,4,5,6,7,8,9,10];
            for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
              PageList.push(i+1);
            }
        
          } else {
            if( (numPages - 5) < page ) {
              var startPage = numPages - 9;
              if(startPage < 1) {
                startPage = 1;
              }
        
              for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
                PageList.push(startPage + i);
              }
        
            } else {
              var startPage = page - 4;
              if(startPage < 1) {
                startPage = 1;
              }
              
              for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
                PageList.push(startPage + i);
              }
            }
          }

  return (
    <MainLayout>
      <MainHeader title="일정을 등록할 장소를 선택해 주세요." />

      <Main>
        <ul>
          {location ? location.map((item, idx) => (
            <LocationCard key={idx} data={item} pid={pid} idx={idx} />
          )) : ""}
        </ul>
      </Main>

      <Footer>
        {/*<Pagination
          total={inquiryHistoryList.length}
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />*/}
          <Div>
            <SectionButton onClick={prevHandler}>&lt;</SectionButton>

            <List>
              {PageList.length ? PageList.map((item, idx) => 
                ( <PageButton
                    key={idx}
                    onClick={() => PageChange(item)}
                    aria-current={page === item ? "page" : null}
                    active={page === item ? true : false}
                  >
                    {item}
                  </PageButton>
                )) : ""}
            </List>

            <SectionButton onClick={nextHandler}>&gt;</SectionButton>
          </Div>
      </Footer>
    </MainLayout>
  );
};
export default PartnerLocationPage;
