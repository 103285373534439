import styled from "styled-components";
import base from "../styles/constants/base";
import typography from "../styles/constants/typography";

const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 65px;

  h1 {
    /* font-size: ${typography.size.large}px; */
  }

  p {
    font-size: ${typography.size.base}px;
  }
`;

const Div = styled.div`
  flex-shrink: 0;
`;

const DesignedMainHeader = ({ title, subtitle, children }) => {
  return (
    <Header id="LayoutHeader">
      <Div>
        <h1 className="f24">{title}</h1>
        <p>{subtitle}</p>
      </Div>

      {children}
    </Header>
  );
};

export default DesignedMainHeader;
