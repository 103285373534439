import axios from "axios";
import { useEffect, useState } from "react";

import useData from "../../../hooks/data/useData";
import useDate from "../../../hooks/useDate";
import useInput from "../../../hooks/useInput";

import BarChart from "../../../chart/data/bar";
import DougnutChart from "../../../chart/doughnut";

import TabBar from "../../../components/dashboard/tabBar";
import TitleBox from "../../../components/dashboard/titleBox";
import lessonAndPartnerData from "../../../data/dashboard/lesson";
import LineCharts from "../../../chart/data/lines";

import DashboardLayout from "../../../layouts/dashboard";
import DateInput from "../../../modules/date/input";
import Select from "../../../modules/select";

import colors from "../../../styles/constants/colors";

import QuestionMark from "../../../assets/icon/questionMark.svg";

import {
  BottomRightWrapper,
  BottomSection,
  Box,
  Container,
  FlexWrapper,
  LikeAndRatingBox,
  LocationBox,
  MarginSection,
  MainWrapper,
  RankingBox,
  RankingBox2,
  RankingListWrapper,
  DougnutChartWrapper,
  Title,
} from "./style";

const getTextFilter = (filter) => {
  switch (filter.filter) {
    case "m":
      return "month";

    case "w":
      return "week";

    case "d":
      return "day";
    default:
  }
};

const transformDate = (fullDate) => {
  const [year, month, day] = fullDate.split("-");

  return `${year.slice(2, 4)}-${month}-${day}`;
};

const LessonAndPartnerTab = () => {
  const [status, setStatus] = useState({
    payment: false,
    searchAndTag: false,
  });

  const [lessonData, setLessonData] = useState([]);

  const [valueList, setValueList] = useState({});

  const [labelsList, setLabelsList] = useState({});

  const { today, getFilter, getDataByCategory, transformDatetime } = useData(
    setValueList,
    setLabelsList
  );

  const { value: ratingValue, inputChangeHandler: ratingChangeHandler } =
    useInput({
      rating: "like",
    });

  const { value: marginFilter, changeHandler: marginChangeHandler } = useDate({
    filter: "m",
    date: transformDatetime("m"),
  });

  const { value: searchFilter, changeHandler: searchChangeHandler } = useDate({
    filter: "m",
    date: transformDatetime("m"),
  });

  const { value: starLessonFilter, changeHandler: starLessonChangeHandler } = useDate({
    filter: "m",
    date: transformDatetime("m"),
    type: "inquire"
  });

  const { value: paymentFilter, changeHandler: paymentChangeHandler } = useDate({
    filter: "m",
    date: transformDatetime("m"),
  });

  const { value: ratingFilter, changeHandler: ratingFilterChangeHandler } =
    useDate({
      filter: "m",
      date: transformDatetime("m"),
      category: "jjim",
    });

  useEffect(() => {
    const frm = new FormData();

    frm.append("mode", "Load");
    frm.append("page", "lesson");

    axios
      .post("https://ai.semos.kr/semos_admin/board/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        const {
          PAYMENT_DATA,
          PAYMENT_DATA2,
          PAYMENT_PERCENT_DATA,
          JJIM_DATA,
          TAG_DATA,
          SEARCH_DATA,
          LOCATION_DATA,
          PAYMENT_RANK_DATA,
          STAR_LESSON_DATA
        } = res.data;

        setLessonData(res.data);

        setValueList({
          payment: PAYMENT_DATA.map((item) => parseInt(item.value)),
          payment2: PAYMENT_DATA2.map((item) => parseInt(item.value)),
          search: SEARCH_DATA.map((item) => ({
            type: item.type,
            value: item.value,
          })),
          star_lesson: STAR_LESSON_DATA.map((item) => ({
            type: item.type,
            value: item.value,
          })),
          tag: TAG_DATA.map((item) => ({
            type: item.type,
            value: item.value,
          })),
          paymentRank: PAYMENT_RANK_DATA.map((item) => ({
            type: item.type,
            value: item.value,
          })),
          location: LOCATION_DATA.map((item) => item.value),
          /*jjim: JJIM_DATA.map((item) => ({
            value: item.value,
            type: item.type,
          })).splice(0, 5),*/
        });

        setLabelsList({
          payment: PAYMENT_DATA.map((item) => item.date),
          payment2: PAYMENT_DATA2.map((item) => item.date),
          location: LOCATION_DATA.map((item) => item.type),
        });
      });
  }, []);

  // 매출 추이
  useEffect(() => {
    if (status.payment) {
      const frm = new FormData();

      frm.append("mode", "Update");
      frm.append("page", "lesson");
      frm.append("date", marginFilter.date);
      frm.append("date_type", getTextFilter(marginFilter));
      frm.append("type", "payment");

      axios
        .post("https://ai.semos.kr/semos_admin/board/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          const data = res.data[0];
          setValueList((prev) => ({
            ...prev,
            payment: data.map((item) => parseInt(item.value)),
          }));

          setLabelsList((prev) => {
            if (marginFilter.filter === "w") {
              return {
                ...prev,
                payment: data.map(
                  (item) =>
                    `${transformDate(item.start_date)} ~ ${transformDate(
                      item.finish_date
                    )}`
                ),
              };
            } else {
              return {
                ...prev,
                payment: data.map((item) => item.date),
              };
            }
          });
        });
    }
  }, [marginFilter]);


  // 인기 레슨
  useEffect(() => {
    if (status.starLesson) {
      const frm = new FormData();

      frm.append("mode", "Update");
      frm.append("page", "lesson");
      frm.append("date", starLessonFilter.date);
      frm.append("date_type", getTextFilter(starLessonFilter));
      frm.append("type", "star_lesson");
      frm.append("category", starLessonFilter.type);

      axios
        .post("https://ai.semos.kr/semos_admin/board/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          const { data } = res;

          setValueList((prev) => ({
            ...prev,
            star_lesson: data[0] !== null ? data.map((item) => ({
              type: item.type,
              value: item.value,
            })) : "",
          }));
        });
    }
  }, [starLessonFilter]);

  // 검색어/태그 순위: 검색어
  useEffect(() => {
    if (status.searchAndTag) {
      const frm = new FormData();

      frm.append("mode", "Update");
      frm.append("page", "lesson");
      frm.append("date", searchFilter.date);
      frm.append("date_type", getTextFilter(searchFilter));
      frm.append("type", "search");

      axios
        .post("https://ai.semos.kr/semos_admin/board/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          const { data } = res;

          setValueList((prev) => ({
            ...prev,
            search: data[0] !== null ?data.map((item) => ({
              type: item.type,
              value: item.value,
            })) : "",
          }));
        });
    }
  }, [searchFilter]);

  // 검색어/태그 순위: 태그
  useEffect(() => {
    if (status.searchAndTag) {
      const frm = new FormData();

      frm.append("mode", "Update");
      frm.append("page", "lesson");
      frm.append("date", searchFilter.date);
      frm.append("date_type", getTextFilter(searchFilter));
      frm.append("type", "tag");

      axios
        .post("https://ai.semos.kr/semos_admin/board/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          const { data } = res;

          setValueList((prev) => ({
            ...prev,
            tag: data[0] !== null ? data.map((item) => ({
              type: item.type,
              value: item.value,
            })) : "",
          }));
        });
    }
  }, [searchFilter]);

    // 구매 순위
    useEffect(() => {
        if (status.paymentRank) {
          const frm = new FormData();
    
          frm.append("mode", "Update");
          frm.append("page", "lesson");
          frm.append("date", paymentFilter.date);
          frm.append("date_type", getTextFilter(paymentFilter));
          frm.append("type", "payment_rank");
    
          axios
            .post("https://ai.semos.kr/semos_admin/board/data", frm, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
              const { data } = res;
    
              setValueList((prev) => ({
                ...prev,
                paymentRank: data[0] !== null ? data.map((item) => ({
                  type: item.type,
                  value: item.value,
                })) : "",
              }));
            });
        }
      }, [paymentFilter]);

  //   useEffect(() => {
  //     const frm = new FormData();

  //     frm.append("mode", "Update");
  //     frm.append("page", "lesson");
  //     frm.append("date", ratingFilter.date);
  //     frm.append("date_type", getTextFilter(ratingFilter));
  //     frm.append("type", "jjim");

  //     axios
  //       .post("https://ai.semos.kr/semos_admin/board/data", frm, {
  //         headers: { "Content-Type": "multipart/form-data" },
  //       })
  //       .then((res) => {
  //         const { data } = res;

  //         setValueList((prev) => ({
  //           ...prev,
  //           jjim: data.map((item) => ({
  //             type: item.type,
  //             value: item.value,
  //           })),
  //         }));
  //       });
  //   }, []);

  return (
    <DashboardLayout>

      <Container>
        <MarginSection>
          <TitleBox title="매출 추이" unit={{ text: "단위", value: "" }}>

            <FlexWrapper>
              {(marginFilter.filter === "d" || marginFilter.filter === "w") && (
                <DateInput
                  width="180px"
                  type="date"
                  name="date"
                  min="2021-05-01"
                  max={`${today.getFullYear()}-${
                    today.getMonth() + 1 < 10
                      ? `0${today.getMonth() + 1}`
                      : today.getMonth() + 1
                  }-${
                    today.getDate() < 10
                      ? `0${today.getDate()}`
                      : today.getDate()
                  }`}
                  value={marginFilter.date}
                  changeHandler={(e) => {
                    setStatus((prev) => ({
                      ...prev,
                      payment: true,
                    }));
                    marginChangeHandler(e);
                  }}
                />
              )}
              {marginFilter.filter === "m" && (
                <DateInput
                  width="180px"
                  type="month"
                  name="date"
                  min="2021-05"
                  max={`${today.getFullYear()}-${
                    today.getMonth() + 1 < 10
                      ? `0${today.getMonth() + 1}`
                      : today.getMonth() + 1
                  }`}
                  value={marginFilter.date}
                  changeHandler={(e) => {
                    setStatus((prev) => ({
                      ...prev,
                      payment: true,
                    }));
                    marginChangeHandler(e);
                  }}
                />
              )}

              <Select
                border={false}
                isTransparent={true}
                width="110px"
                name="filter"
                value={marginFilter.filter}
                changeHandler={(e) => {
                  setStatus((prev) => ({
                    ...prev,
                    payment: true,
                  }));
                  marginChangeHandler(e);
                }}
                optionList={[
                  {
                    text: "일간",
                    value: "d",
                  },
                  {
                    text: "주간",
                    value: "w",
                  },
                  {
                    text: "월간",
                    value: "m",
                  },
                ]}
              />
            </FlexWrapper>
          </TitleBox>

          <MainWrapper>

            <Box>
              <LineCharts
                type='payment'
                names={['총 매출', '객단가']}
                list={valueList.payment}
                list2={valueList.payment2}
                labels={labelsList.payment}
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
          </MainWrapper>
        </MarginSection>

        <BottomSection>
          <RankingBox>
            <TitleBox
              title="검색 순위"
              unit={{ text: "단위", value: "회" }}
            >
              <FlexWrapper>
                {(searchFilter.filter === "d" ||
                  searchFilter.filter === "w") && (
                  <DateInput
                    width="180px"
                    type="date"
                    name="date"
                    min="2021-05-01"
                    max={`${today.getFullYear()}-${
                      today.getMonth() + 1 < 10
                        ? `0${today.getMonth() + 1}`
                        : today.getMonth() + 1
                    }-${
                      today.getDate() < 10
                        ? `0${today.getDate()}`
                        : today.getDate()
                    }`}
                    value={searchFilter.date}
                    changeHandler={(e) => {
                      setStatus((prev) => ({
                        ...prev,
                        searchAndTag: true,
                      }));
                      searchChangeHandler(e);
                    }}
                    // changeHandler={searchChangeHandler}
                  />
                )}

                {searchFilter.filter === "m" && (
                  <DateInput
                    width="180px"
                    type="month"
                    name="date"
                    min="2021-05"
                    max={`${today.getFullYear()}-${
                      today.getMonth() + 1 < 10
                        ? `0${today.getMonth() + 1}`
                        : today.getMonth() + 1
                    }`}
                    value={searchFilter.date}
                    changeHandler={(e) => {
                      setStatus((prev) => ({
                        ...prev,
                        searchAndTag: true,
                      }));
                      searchChangeHandler(e);
                    }}
                    // changeHandler={searchChangeHandler}
                  />
                )}

                <Select
                  border={false}
                  isTransparent={true}
                  width="110px"
                  name="filter"
                  value={searchFilter.filter}
                  changeHandler={(e) => {
                    setStatus((prev) => ({
                      ...prev,
                      searchAndTag: true,
                    }));
                    searchChangeHandler(e);
                  }}
                  //   changeHandler={searchChangeHandler}
                  optionList={[
                    {
                      text: "일간",
                      value: "d",
                    },
                    {
                      text: "주간",
                      value: "w",
                    },
                    {
                      text: "월간",
                      value: "m",
                    },
                  ]}
                />
              </FlexWrapper>
            </TitleBox>

            <RankingListWrapper>
              <ol>
                {valueList.search ? valueList.search.slice(0,5)?.map((item, idx) => (
                  <li key={idx + 1}>
                    <p>
                      {idx + 1}. {item.type}
                    </p>
                    <p>{item.value}</p>
                  </li>
                )) : ""}
              </ol>

              <ol>
                {valueList.search ? valueList.search.slice(5)?.map((item, idx) => (
                  <li key={idx + 6}>
                    <p>
                      {idx + 6}. {item.type}
                    </p>
                    <p>{item.value}</p>
                  </li>
                )) : ""}
              </ol>
            </RankingListWrapper>
          </RankingBox>

          <RankingBox2>
            <TitleBox
              title="구매 순위"
              unit={{ text: "단위", value: "건" }}
            >
              <FlexWrapper>
                {(paymentFilter.filter === "d" ||
                  paymentFilter.filter === "w") && (
                  <DateInput
                    width="180px"
                    type="date"
                    name="date"
                    min="2021-05-01"
                    max={`${today.getFullYear()}-${
                      today.getMonth() + 1 < 10
                        ? `0${today.getMonth() + 1}`
                        : today.getMonth() + 1
                    }-${
                      today.getDate() < 10
                        ? `0${today.getDate()}`
                        : today.getDate()
                    }`}
                    value={paymentFilter.date}
                    changeHandler={(e) => {
                      setStatus((prev) => ({
                        ...prev,
                        paymentRank: true,
                      }));
                      paymentChangeHandler(e);
                    }}
                    // changeHandler={searchChangeHandler}
                  />
                )}

                {paymentFilter.filter === "m" && (
                  <DateInput
                    width="180px"
                    type="month"
                    name="date"
                    min="2021-05"
                    max={`${today.getFullYear()}-${
                      today.getMonth() + 1 < 10
                        ? `0${today.getMonth() + 1}`
                        : today.getMonth() + 1
                    }`}
                    value={paymentFilter.date}
                    changeHandler={(e) => {
                      setStatus((prev) => ({
                        ...prev,
                        paymentRank: true,
                      }));
                      paymentChangeHandler(e);
                    }}
                    // changeHandler={searchChangeHandler}
                  />
                )}

                <Select
                  border={false}
                  isTransparent={true}
                  width="110px"
                  name="filter"
                  value={paymentFilter.filter}
                  changeHandler={(e) => {
                    setStatus((prev) => ({
                      ...prev,
                      paymentRank: true,
                    }));
                    paymentChangeHandler(e);
                  }}
                  //   changeHandler={searchChangeHandler}
                  optionList={[
                    {
                      text: "일간",
                      value: "d",
                    },
                    {
                      text: "주간",
                      value: "w",
                    },
                    {
                      text: "월간",
                      value: "m",
                    },
                  ]}
                />
              </FlexWrapper>
            </TitleBox>

            <RankingListWrapper>
              <ol>
                {valueList.paymentRank ? valueList.paymentRank.slice(0,5)?.map((item, idx) => (
                  <li key={idx + 1}>
                    <p>
                      {idx + 1}. {item.type}
                    </p>
                    <p>{item.value}</p>
                  </li>
                )) : ""}
              </ol>

              <ol>
                {valueList.paymentRank ? valueList.paymentRank.slice(5)?.map((item, idx) => (
                  <li key={idx + 6}>
                    <p>
                      {idx + 6}. {item.type}
                    </p>
                    <p>{item.value}</p>
                  </li>
                )) : ""}
              </ol>
            </RankingListWrapper>
          </RankingBox2>
        </BottomSection>

          <BottomSection>
            <RankingBox>
              <TitleBox
                title="레슨 지역"
                unit={{
                  text: "기준시",
                  value: `${
                    lessonData.LOCATION_DATA
                      ? lessonData.LOCATION_DATA[0].date
                      : "알 수 없음"
                  }`,
                }}
              >

              <FlexWrapper><span style={{ display : 'inline-block', height : '50px' }}></span></FlexWrapper>
              </TitleBox>

              <DougnutChartWrapper>
                <DougnutChart
                  labels={labelsList.location}
                  list={valueList.location}
                  originData={labelsList.location}
                  style={{ width: "40%", height: "100%" }}
                />
              </DougnutChartWrapper>
            </RankingBox>

            {/*<LikeAndRatingBox>
              <TitleBox
                title="찜"
                unit={{
                  text: "기준시",
                  value: "누적",
                }}
              ></TitleBox>

              <ol>
                {valueList.jjim &&
                  valueList.jjim.map((item, idx) => (
                    <li key={idx}>
                      <p>
                        {idx + 1}. {item.type}
                      </p>
                      <p>{item.value}</p>
                    </li>
                  ))}
              </ol>
            </LikeAndRatingBox>*/}

        <RankingBox2>
            <TitleBox
              title="인기 레슨"
              unit={{ text: "단위", value: "건" }}
            >
              <FlexWrapper>
                {(starLessonFilter.filter === "d" ||
                  starLessonFilter.filter === "w") && (
                  <DateInput
                    width="180px"
                    type="date"
                    name="date"
                    min="2021-05-01"
                    max={`${today.getFullYear()}-${
                      today.getMonth() + 1 < 10
                        ? `0${today.getMonth() + 1}`
                        : today.getMonth() + 1
                    }-${
                      today.getDate() < 10
                        ? `0${today.getDate()}`
                        : today.getDate()
                    }`}
                    value={starLessonFilter.date}
                    changeHandler={(e) => {
                      setStatus((prev) => ({
                        ...prev,
                        starLesson: true,
                      }));
                      starLessonChangeHandler(e);
                    }}
                    // changeHandler={searchChangeHandler}
                  />
                )}

                {starLessonFilter.filter === "m" && (
                  <DateInput
                    width="180px"
                    type="month"
                    name="date"
                    min="2021-05"
                    max={`${today.getFullYear()}-${
                      today.getMonth() + 1 < 10
                        ? `0${today.getMonth() + 1}`
                        : today.getMonth() + 1
                    }`}
                    value={starLessonFilter.date}
                    changeHandler={(e) => {
                      setStatus((prev) => ({
                        ...prev,
                        starLesson: true,
                      }));
                      starLessonChangeHandler(e);
                    }}
                    // changeHandler={searchChangeHandler}
                  />
                )}

                <Select
                  clna='select_mini'
                  border={false}
                  isTransparent={true}
                  width="80px"
                  name="filter"
                  value={starLessonFilter.filter}
                  changeHandler={(e) => {
                    setStatus((prev) => ({
                      ...prev,
                      starLesson: true,
                    }));
                    starLessonChangeHandler(e);
                  }}
                  //   changeHandler={searchChangeHandler}
                  optionList={[
                    {
                      text: "일간",
                      value: "d",
                    },
                    {
                      text: "주간",
                      value: "w",
                    },
                    {
                      text: "월간",
                      value: "m",
                    },
                  ]}
                />

                <Select
                  clna='select_mini'
                  border={false}
                  isTransparent={true}
                  width="80px"
                  name="type"
                  value={starLessonFilter.type}
                  changeHandler={(e) => {
                    setStatus((prev) => ({
                      ...prev,
                      starLesson: true,
                    }));
                    starLessonChangeHandler(e);
                  }}
                  //   changeHandler={searchChangeHandler}
                  optionList={[
                    {
                      text: "찜",
                      value: "jjim",
                    },
                    {
                      text: "문의",
                      value: "inquire",
                    },
                    {
                      text: "리뷰",
                      value: "review",
                    },
                    {
                      text: "공유",
                      value: "share",
                    },
                  ]}
                />
              </FlexWrapper>
            </TitleBox>

            <RankingListWrapper>
              <ol>
                {valueList.star_lesson ? valueList.star_lesson.slice(0,5)?.map((item, idx) => (
                  <li key={idx + 1}>
                    <p>
                      {idx + 1}. {item.type}
                    </p>
                    <p>{item.value}</p>
                  </li>
                )) : ""}
              </ol>

              <ol>
                {valueList.star_lesson ? valueList.star_lesson.slice(5)?.map((item, idx) => (
                  <li key={idx + 6}>
                    <p>
                      {idx + 6}. {item.type}
                    </p>
                    <p>{item.value}</p>
                  </li>
                )) : ""}
              </ol>
            </RankingListWrapper>
          </RankingBox2>
          </BottomSection>
  
      </Container>
    </DashboardLayout>
  );
};

export default LessonAndPartnerTab;
