import styled from "styled-components";
import base from "../../styles/constants/base";
import flexbox from "../../styles/func/flexbox";

const Container = styled.div`
  width: 100%;
  padding-top: 130px;
  padding-top: 0;
`;

const Wrapper = styled.div`
  position: relative;
  max-width: calc(1050px + 20px);
  height: 860px;
  margin: 0 auto;
  padding: ${base.spacing.main}px 0;
`;

const PartnerLayout = ({ children }) => {
  return (
    <Container>
      <Wrapper>{children}</Wrapper>
    </Container>
  );
};

export default PartnerLayout;
