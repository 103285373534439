import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import MainHeader from "../../components/header";
import salesDetailData from "../../data/sales/detail";
import MainLayout from "../../layouts/mainLayout";
import Border from "../../modules/border";
import Button from "../../modules/button";
import base from "../../styles/constants/base";
import colors from "../../styles/constants/colors";
import typography from "../../styles/constants/typography";
import flexbox from "../../styles/func/flexbox";
import { useNavigate, useParams } from "react-router-dom";

const Main = styled.main`
  padding: 0 20px;
  margin-top: 20px;
`;

const MetaSection = styled.section`
  display: flex;

  img {
    width: 180px;
    border-radius: ${base.borderRadius.default}px;
    background-color: ${colors.gray};
    margin-right: 20px;
    cursor : pointer;
  }

  div {
    ${flexbox("space-between", "flex-start", "column")}
  }

  p {
    overflow : hidden;
    text-overflow: ellipsis;
    white-space : nowrap;
  }
`;

const ParticipantSection = styled.section`
  div {
    display: flex;
    padding: 10px 0;

    p:first-child {
      font-weight: ${typography.weight.bold};
      margin-right: 70px;
      flex-shrink: 0;
    }

    p:nth-child(2) {
      flex-shrink: 0;
    }

    p:last-child {
      width: 100%;
      text-align: end;
    }
  }
`;

const BottomButtonBox = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;

  button:first-child {
    margin-right: 10px;
  }
`;

const PaymentBox = styled.div`
  margin-top: 30px;

  div {
    ${flexbox("space-between")}
    font-size: ${typography.size.small}px;
    margin-bottom: 20px;

    p:first-child {
      color: ${colors.mediumGray};
    }
  }

  div:first-child {
    margin-bottom: 50px;
  }

  div:last-child {
    margin-top: 40px;
    font-size: ${typography.size.medium}px;
    font-weight: ${typography.weight.bold};

    p:first-child {
      color: ${colors.black};
    }

    p:last-child {
      color: ${colors.red};
    }
  }
`;

const PaymentCXBox = styled.div`
  margin-top: 30px;

  div {
    width : 100%;
    display : inline-flex;
    align-items : top;
    justify-content : space-between;
    font-size: ${typography.size.small}px;
    margin-bottom: 20px;

    p:first-child {
      color: ${colors.mediumGray};
    }

    .cxContent {
        width : calc(100% - 100px);
        display : inline-block;

        .cxType {
            border-radius : 8px;
            padding : 10px 12px;
            display : inline-block;
            border : 3px solid rgba(132,140,148,1);
            cursor : pointer;
            font-size: 17px;
            font-weight : 500;
        }
        .cxType:first-child  {
            margin-right : 15px;
        }
        .typeActive {
            border : 3px solid rgba(0,149,255,1);
        }
        .cxManeger {
            width : 100%;
            display : inline-block;
            border-radius : 8px;
            padding : 10px 12px;
            border : 1px solid rgba(132,140,148,1);
            font-size: 17px;
            font-weight : 500;
            background-color : #F9F9F9;
        }
        .cxMessage {
            width : 100%;
            display : inline-block;
            border-radius : 8px;
            padding : 10px 12px;
            border : 1px solid rgba(132,140,148,1);
            font-size: 17px;
            font-weight : 500;
            background-color : #F9F9F9;
            height : 220px;
            resize : none;
        }
    }
  }
`;


const MessageDetailPage = () => {
  const { title, status, number, date, img, info, buyer, seller, payment } =
    salesDetailData;

  const { mid } = useParams();
  const navigate = useNavigate();


  const [paymentSpecific, setPaymentSpecific] = useState();

      // 판매 리스트 로드
      useEffect(() => {
    
        var token = window.localStorage.getItem('token')
  
        const frm = new FormData();
        frm.append("mode", "specific");
        frm.append("token", token);
        frm.append("number", mid);
  
          axios.post("https://ai.semos.kr/semos_admin/user/send", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          }).then((data) => {
            var Value = data.data;
            var status = Value.status
            var statusMessage = Value.status_message
  
            if(status == 'success') {
              setPaymentSpecific(Value.payment_data)
  
            } else if(status == 'waring') {
              alert(statusMessage)
            }
  
          });
  
      }, []);


const CXMessageType = {
  "cancelp" : [
      <span className="cxContent">
          <span className={"cxType typeActive"}>취소 (강사 미확정)</span>
      </span>
  ],
  "cancelu" : [
      <span className="cxContent">
          <span className={"cxType typeActive"}>취소 (고객 요청)</span>
      </span>
  ],
  "refundu" : [
      <span className="cxContent">
          <span className={"cxType typeActive"}>환불 (고객 요청)</span>
      </span>
  ],
  "refundp" : [
      <span className="cxContent">
          <span className={"cxType typeActive"}>환불 (강사 요청)</span>
      </span>
  ],
  "reminder" : [
      <span className="cxContent">
          <span className={"cxType typeActive"}>리마인더</span>
      </span>
  ],
  "confirm" : [
      <span className="cxContent">
          <span className={"cxType typeActive"}>일정 확정</span>
      </span>
  ],

  "test" : [
    <span className="cxContent">
        <span className={"cxType typeActive"}>테스트</span>
    </span>
  ],
  "payment" : [
    <span className="cxContent">
        <span className={"cxType typeActive"}>결제 안내</span>
    </span>
  ]
}



  return (

    paymentSpecific ?

      <MainLayout heightType={true}>
          <MainHeader title={ 
            paymentSpecific.message_send_type == 'alimTalk' 
            ? "[" + paymentSpecific.sport + "] " + paymentSpecific.service 
            : "친구톡 내역 상세"} />

          {paymentSpecific.message_send_type == 'alimTalk' ?
                    <Main>
                      <MetaSection>
                          <img onClick={() => window.open(paymentSpecific ? 'https://semos.kr/coaching/' + paymentSpecific.pid + '/' + paymentSpecific.lid + '/normal' : "" )} src={paymentSpecific ? paymentSpecific.service_thumbnail : ""} />
            
                          <div>
                          <p><b>결제번호</b> : {paymentSpecific ? paymentSpecific.payment_number : ""}</p>
                          <p><b>결제일</b> : {paymentSpecific ? paymentSpecific.date : ""}</p>
                          <p><b>이용 정보</b> : {paymentSpecific ? paymentSpecific.use_date.split(' ~ ')[0] : ""}</p>
                          <p><b>레슨 장소</b> : {paymentSpecific ? paymentSpecific.service_location : ""}</p>
                          </div>
                      </MetaSection>
                      <Border />
                      <ParticipantSection>
                          <div>
                            <p>유저</p>
                            <p>{paymentSpecific ? paymentSpecific.user : ""}</p>
              
                            <p>{paymentSpecific ? paymentSpecific.user_phone : ""}</p>
                          </div>
            
                          <div>
                            <p>강사</p>
                            <p>{paymentSpecific ? paymentSpecific.partner : ""}</p>
                            <p>{paymentSpecific ? paymentSpecific.partner_phone : ""}</p>
                          </div>

                          <div>
                            <p>발송 시간</p>
                            <p>{""}</p>

                            <p>{paymentSpecific ? paymentSpecific.message_date : ""}</p>
                          </div>
                      </ParticipantSection>
                      <Border />
            
                      <section>
                          <p style={{ fontSize : "20px", fontWeight : "500" }} >알림톡 정보</p>
            
                          <PaymentCXBox>
                              <div>
                                  <p>알림 사유</p>
                                  {paymentSpecific ? paymentSpecific.message_type ?  CXMessageType[paymentSpecific.message_type] : CXMessageType['test'] : ""}
                              </div>
            
                              <div>
                                  <p>담당자 명</p>
                                  <span className="cxContent">
                                      <input type="text" value={paymentSpecific ? paymentSpecific.message_manager : ""} className="cxManeger" readOnly placeholder="담당자 명을 입력해 주세요" />
                                  </span>
                              </div>
            
                              <div>
                                  <p>알림 내용</p>
                                  <span className="cxContent">
                                      <textarea value={paymentSpecific ? paymentSpecific.message_content : ""} type="text" className="cxMessage" readOnly maxLength="300" />
                                  </span>
                              </div>
                          </PaymentCXBox>
            
                    
                      </section>
                    </Main>
          
          : 
                    <Main>
       
            
                      <ParticipantSection>
                          <div>
                            <p>유저</p>
                            <p>{paymentSpecific ? paymentSpecific.message_user_name : ""}</p>

                            <p>{paymentSpecific ? paymentSpecific.message_phone : ""}</p>
                          </div>

                          <div>
                            <p>발송 시간</p>
                            <p>{""}</p>

                            <p>{paymentSpecific ? paymentSpecific.message_date : ""}</p>
                          </div>
                      </ParticipantSection>
                      <Border />

                      <section>
                          <p style={{ fontSize : "20px", fontWeight : "500" }} >친구톡 정보</p>

                          <PaymentCXBox>
                              <div>
                                  <p>담당자 명</p>
                                  <span className="cxContent">
                                      <input type="text" value={paymentSpecific ? paymentSpecific.message_manager : ""} className="cxManeger" readOnly placeholder="담당자 명을 입력해 주세요" />
                                  </span>
                              </div>

                              <div>
                                  <p>알림 내용</p>
                                  <span className="cxContent">
                                      <textarea value={paymentSpecific ? paymentSpecific.message_content : ""} type="text" className="cxMessage" readOnly maxLength="300" />
                                  </span>
                              </div>
                          </PaymentCXBox>

                    
                      </section>
                    </Main>

          }


          <BottomButtonBox>

          </BottomButtonBox>
      </MainLayout>

    :
      ""
  );
};

export default MessageDetailPage;
