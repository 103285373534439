import styled from "styled-components";
import colors from "../styles/constants/colors";
import typography from "../styles/constants/typography";

const ROW_HEIGHT = 50;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${ROW_HEIGHT}px;
  border-bottom: 2px solid ${colors.lightGray};
  color: ${colors.gray};
  font-weight: ${typography.weight.regular};
`;

const Col = styled.div`
  width: 100%;
  flex-shrink: 1;

  &:first-child {
    width: 10%;
  }

  &:nth-child(2) {
    width: 15%;
  }

  &:nth-child(3) {
    width: 25%;
  }

  &:nth-child(4) {
    width: 20%;
  }

  &:nth-child(5) {
    width: 20%;
  }

  &:nth-child(6) {
    width: 10%;
    text-align: center;
  }
`;

const SCol = styled.div`
  width: 100%;
  flex-shrink: 1;
  display : inline-block;

  &:first-child {
    width: 5%;
    overflow : hidden;
    text-overflow: ellipsis;
  }

  &:nth-child(2) {
    width: 10%;
    overflow : hidden;
    text-overflow: ellipsis;
  }

  &:nth-child(3) {
    width: 10%;
    overflow : hidden;
    text-overflow: ellipsis;
  }

  &:nth-child(4) {
    width: 10%;
    overflow : hidden;
    text-overflow: ellipsis;
  }

  &:nth-child(5) {
    width: 30%;
    overflow : hidden;
    text-overflow: ellipsis;
  }

  &:nth-child(6) {
    width: 10%;
  }

  &:nth-child(7) {
    width: 10%;
  }

  &:nth-child(8) {
    width: 7%;
  }

  &:nth-child(9) {
    width: 8%;
  }
`;

const PCol = styled.div`
  width: 100%;
  flex-shrink: 1;

  &:first-child {
    width: 10%;
  }

  &:nth-child(2) {
    width: 20%;
  }

  &:nth-child(3) {
    width: 10%;
  }

  &:nth-child(4) {
    width: 20%;
  }

  &:nth-child(5) {
    width: 30%;
  }

  &:nth-child(6) {
    width: 10%;
    text-align: center;
  }

`;

const BCol = styled.div`
  width: 100%;
  flex-shrink: 1;
  text-align: center;

  &:first-child {
    width: 5%;
  }

  &:nth-child(2) {
    width: 25%;
  }

  &:nth-child(3) {
    width: 25%;
  }

  &:nth-child(4) {
    width: 25%;
  }

  &:nth-child(5) {
    width: 10%;
  }

  &:nth-child(6) {
    width: 10%;
  }
`;

const BCCol = styled.div`
  width: 100%;
  flex-shrink: 1;
  text-align: center;

  &:first-child {
    width: 10%;
  }

  &:nth-child(2) {
    width: 35%;
  }

  &:nth-child(3) {
    width: 35%;
  }

  &:nth-child(4) {
    width: 10%;
  }

  &:nth-child(5) {
    width: 10%;
  }
`;


const MCol = styled.div`
  width: 100%;
  flex-shrink: 1;
  display : inline-block;

  &:first-child {
    width: 5%;
  }

  &:nth-child(2) {
    width: 7%;
  }

  &:nth-child(3) {
    width: 7%;
  }

  &:nth-child(4) {
    width: 10%;
  }

  &:nth-child(5) {
    width: 13%;
  }

  &:nth-child(6) {
    width: 10%;
  }

  &:nth-child(7) {
    width: 28%;
  }

  &:nth-child(8) {
    width: 5%;
  }

  &:nth-child(9) {
    width: 13%;
  }

`;

const ACol = styled.div`
  width: 100%;
  flex-shrink: 1;

  &:first-child {
    width: 10%;
  }

  &:nth-child(2) {
    width: 25%;
  }

  &:nth-child(3) {
    width: 8%;
  }

  &:nth-child(4) {
    width: 11%;
  }

  &:nth-child(5) {
    width: 8%;
  }

  &:nth-child(6) {
    width: 18%;
  }

  &:nth-child(7) {
    width: 10%;
  }

  &:nth-child(8) {
    width: 10%;
    text-align: center;
  }
`;

const FCol = styled.div`
  width: 100%;
  flex-shrink: 1;

  &:first-child {
    width: 10%;
  }

  &:nth-child(2) {
    width: 15%;
  }

  &:nth-child(3) {
    width: 17%;
  }

  &:nth-child(4) {
    width: 17%;
  }

  &:nth-child(5) {
    width: 31%;
  }

  &:nth-child(6) {
    width: 10%;
    text-align: center;
  }
`;

const Index = ({ type = "customer", indexList = [] }) => {


  if (type === "customer") {
    return (
      <Row>
        {indexList.map((item, idx) => (
          <Col key={idx}>{item}</Col>
        ))}
      </Row>
    );
  }

  if (type === "sales") {

    return (
      <Row>
        {indexList.map((item, idx) => (
          <SCol key={idx}>{item}</SCol>
        ))}
      </Row>
    );
  }

  if (type === "partner") {
    return (
      <Row>
        {indexList.map((item, idx) => (
          <PCol key={idx}>{item}</PCol>
        ))}
      </Row>
    );
  }

  if (type === "activity") {
    return (
      <Row>
        {indexList.map((item, idx) => (
          <ACol key={idx}>{item}</ACol>
        ))}
      </Row>
    );
  }

  if (type === "facility") {
    return (
      <Row>
        {indexList.map((item, idx) => (
          <FCol key={idx}>{item}</FCol>
        ))}
      </Row>
    );
  }

  if (type === "banner") {
    return (
      <Row>
        {indexList.map((item, idx) => (
          <BCol key={idx}>{item}</BCol>
        ))}
      </Row>
    );
  }

  if (type === "banner-edit") {
    return (
      <Row>
        {indexList.map((item, idx) => (
          <BCCol key={idx}>{item}</BCCol>
        ))}
      </Row>
    );
  }

  if (type === "message") {
    return (
      <Row>
        {indexList.map((item, idx) => (
          <MCol key={idx}>{item}</MCol>
        ))}
      </Row>
    );
  }
};

export default Index;
