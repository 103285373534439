import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import MainHeader from "../../components/header";
import MainLayout from "../../layouts/mainLayout";
import Border from "../../modules/border";
import Button from "../../modules/button";
import base from "../../styles/constants/base";
import colors from "../../styles/constants/colors";
import typography from "../../styles/constants/typography";
import flexbox from "../../styles/func/flexbox";
import Template from "./template";
import { useNavigate, useParams } from "react-router-dom";

// icon
import DropCaret from "../../assets/icon/caret-down-fill.svg";

const Main = styled.main`
  padding: 0 20px;
  margin-top: 20px;
  max-height : calc(100vh - 185px);
  overflow : hidden auto;
`;

const MetaSection = styled.section`
  display: flex;

  img {
    width: 180px;
    border-radius: ${base.borderRadius.default}px;
    background-color: ${colors.gray};
    margin-right: 20px;
    cursor : pointer;
  }

  div {
    ${flexbox("space-between", "flex-start", "column")}
  }

  p {
    overflow : hidden;
    text-overflow: ellipsis;
    white-space : nowrap;
  }

  .searchWrapper {
    width : 100%;
    display : inline-flex;

    .inputWrapper {
      padding-right : 40px;
      display : inline-flex;
      position : relative;
    }

    .right_button {
      position : absolute;
      top : 50%;
      right : 0;
      transform : translate(0, -50%);
      border-radius : 2px;
      height : 100%;
      width : 35px;
      display : inline-flex;
      align-items : center;
      justify-content : center;
      color : #ffffff;
      font-size : 13px;
      cursor : pointer;
      box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
    }

    .LSNAC {
      display : none !important;
      pointer-events : none;
    }
    
    .location_search {
      padding : 4px;
      background-color : #ffffff;
      display : inline-flex;
      flex-wrap : wrap;
      width : 350px;
      position : absolute;
      bottom : 0;
      right : 40px;
      border-radius : 0 0 4px 4px;
      transform : translate(0, 100%);
      border : 1px solid rgba(132, 140, 148, .5);
      max-height : 200px;
      overflow : hidden auto;
      z-index : 100;

      span {
        width : 100%;
        display : inline-flex;
        cursor : pointer;
        border-bottom : 1px solid rgba(132, 140, 148, .3);
        padding : 4px 0;
      }

      .addressSearch {
        width : 100%;
        display : inline-flex !important;
        cursor : pointer !important;
        border-bottom : 1px solid rgba(132, 140, 148, .3) !important;
        padding : 4px 0 !important;
        text-decoration : none;
      }
    }

    .inputTable {
      display : inline-block;
      width : 350px;

      .dotSpan {
        display : inline-block;
        padding : 0 1px;
      }

      input {
        max-width : 50px !important;
        min-width : 10px !important;
        width : auto !important;
        padding : 0 2px !important;
        display : inline-flex;
        height : unset !important;
        border: unset !important;
        border-radius: unset !important;
        text-decoration : unset !important;
        text-align : center;
        border-bottom : 1px solid #000000 !important;
      }
    }

    input {
      height : unset !important;
      border: unset !important;
      border-radius: unset !important;
      padding: unset !important;
      text-decoration : underline;
      display : inline-flex;
      width : 350px;
    }
  }
`;

const ParticipantSection = styled.section`
  div {
    display: flex;
    padding: 10px 0;

    p:first-child {
      font-weight: ${typography.weight.bold};
      margin-right: 70px;
      flex-shrink: 0;
    }

    p:nth-child(2) {
      flex-shrink: 0;
    }

    p:last-child {
      width: 100%;
      text-align: end;
    }
  }
`;

const BottomButtonBox = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  
  button {
    margin-right: 10px;
  }

  button:last-child {
    margin-right: 0 !important;
  }


`;


const InfoBox = styled.div`
  margin-top: 30px;

  div {
    width : 100%;
    display : inline-flex;
    align-items : start;
    justify-content : space-between;
    font-size: ${typography.size.small}px;
    margin-bottom: 20px;

    p:first-child {
      color: ${colors.mediumGray};
    }

    .physicalBox {
      display : inline-flex;
      width : calc(100% - 70px);
      max-width : 600px;
      border : #DEDEDE solid 1px;
      flex-wrap : wrap;

      .physicalContent {
        width : 100%;
        display : inline-flex;
        border-bottom : #DEDEDE solid 1px;

        .physicalValue {
          width : calc(100% / 4);
          display : inline-block;
          border-right : #DEDEDE solid 1px;
          text-align : center;
          padding : 7px 0;
        }

        .physicalValue:last-child {
          border-right : unset !important;
        }
      }

      .physicalContent:last-child {
        border-bottom : unset !important;
      }
    }

    .noticeBox {
      display : inline-flex;
      flex-wrap : wrap;
      max-width : 600px;
      width : calc(100% - 70px);

      .noticeContent {
        width : 100%;
        display : inline-flex;
        justify-content : space-between;
        border-bottom : #DEDEDE solid 1px;
        margin-bottom : 5px;
        align-items : center;

        .noticeName {
          width : calc(100% - 110px);
          display : inline-flex;
          align-items : center;
        }

        .noticeButton {
          width : 100px;
          padding : 10px 0;
          border-radius : 8px;
          color : white;
          display : inline-flex;
          justify-content : center;
          cursor : pointer;
          box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 5px, rgb(0 0 0 / 15%) -0.5px -0.5px 5px;
        }
      }

      .noticeContent:last-child { 
        margin-bottom : 0 !important;
      }
    }
  }


`;

const PaymentBox = styled.div`
  margin-top: 30px;

  div {
    ${flexbox("space-between")}
    font-size: ${typography.size.small}px;
    margin-bottom: 20px;

    p:first-child {
      color: ${colors.mediumGray};
    }
  }

  div:first-child {
    margin-bottom: 50px;
  }

  div:last-child {
    margin-top: 40px;
    font-size: ${typography.size.medium}px;
    font-weight: ${typography.weight.bold};

    p:first-child {
      color: ${colors.black};
    }

    p:last-child {
      color: ${colors.red};
    }
  }
`;

const PaymentCXBox = styled.div`
  margin-top: 30px;

  .BOX {
    width : 100%;
    display : inline-flex;
    align-items : top;
    justify-content : space-between;
    font-size: ${typography.size.small}px;
    margin-bottom: 20px;

    p:first-child {
      color: ${colors.mediumGray};
    }

    .cxContent {
        width : calc(100% - 100px);
        display : inline-block;

        .cxType {
            border-radius : 8px;
            padding : 10px 12px;
            display : inline-block;
            border : 3px solid rgba(132,140,148,1);
            cursor : pointer;
            font-size: 17px;
            font-weight : 500;
            margin-right : 15px;
            margin-bottom : 5px;
        }
        .cxType:last-child  {
            margin-right : 0px !important;
        }
        .typeActive {
            border : 3px solid rgba(0,149,255,1);
        }
        .cxManeger {
            width : 100%;
            display : inline-block;
            border-radius : 8px;
            padding : 10px 12px;
            border : 1px solid rgba(132,140,148,1);
            font-size: 17px;
            font-weight : 500;
            background-color : #F9F9F9;
        }
        .cxMessage {
            width : 100%;
            display : inline-block;
            border-radius : 8px;
            padding : 10px 12px;
            border : 1px solid rgba(132,140,148,1);
            font-size: 17px;
            font-weight : 500;
            background-color : #F9F9F9;
            height : 250px;
            resize : none;
        }
    }
  }
`;

const PaymentStatusContent = styled.span`
  padding: 5px 10px;
  display : inline-flex;
  border-radius : 8px;
  background-color : #2C84EE;
  color : white;
  font-size: 15px;
  position : absolute;
  top : 0;
  right : 20px;
  font-weight : 400;
` 

const PartnerAdd = styled.section`
  display : inline-flex;
  padding-right : 22.5px;
  position : relative;

  .open_caret {
    position : absolute;
    top : 50%;
    right : 0;
    width : 15px;
    display : inline-block;
    transform : translate(0, -50%);
    cursor : pointer;
    transform : translate(0, -50%) rotate(${({ PIS }) => (PIS ? '180deg' : "0")} );
    transition: all .3s ease 0s;
  }

  .partner_name {
    display : inline-block;
    width : 300px;
  }

  .search_container {
    display : ${({ PIS }) => (PIS ? 'inline-block' : "none")};;
    padding : 5px;
    position : absolute;
    bottom : 0;
    right : 0;
    width : 100%;
    border-radius : 0 0 8px 8px;
    background-color : #ffffff;
    border : 1px solid rgba(132,140,148,1);
    transform : translate(0, 100%);

    input {
      width : 100%;
      height: unset;
      border: unset;
      border-radius: unset !important;
      display : inline-block;
      border-bottom : 1px solid rgba(132,140,148,1);
      padding : 3px 0;
      background-color: #fff;
      font-size: 13px;
    }

    .search_content_container {
      display : inline-block;
      overflow : hidden auto;
      max-height : 150px;
      width : 100%;


      .search_content {
        display : inline-block;
        border-bottom : 1px solid rgba(132,140,148,1);
        padding : 3px 0;
        background-color: #fff;
        font-size: 13px;
        width : 100%;
        cursor : pointer;
      }
    }
  }
`;

const SalesDetailPage = () => {
  /*const { title, status, number, date, img, info, buyer, seller, payment } =
    salesDetailData;*/

  const { sid } = useParams();
  const navigate = useNavigate();

  /*const cancelHandler = () => {
    
    var token = window.localStorage.getItem('token')
  
    const frm = new FormData();
    frm.append("mode", "status_update");
    frm.append("token", token);
    frm.append("payment_id", sid);
    frm.append("payment_status", "cancel");

      axios.post("https://ai.semos.kr/semos_admin/payment/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then((data) => {
        var Value = data.data;
        var status = Value.status
        var statusMessage = Value.status_message

        if(status == 'success') {
          alert("판매 상태가 변경되었습니다.")

          var payment_data = paymentSpecific
          var payment_status = "cancel"
  
          setPaymentSpecific({...paymentSpecific, status:payment_status})

        } else if(status == 'waring') {
          alert(statusMessage)
        }

      });

  };*/

  const fixHandler = (type) => {
        
    var token = window.localStorage.getItem('token')
  
    const frm = new FormData();
    frm.append("mode", "status_update");
    frm.append("token", token);
    frm.append("payment_id", sid);
    frm.append("payment_status", type);

      axios.post("https://ai.semos.kr/semos_admin/payment/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then((data) => {
        var Value = data.data;
        var status = Value.status
        var statusMessage = Value.status_message

        if(status == 'success') {
          alert("판매 상태가 변경되었습니다.")

          var payment_data = paymentSpecific
          var payment_status = type
  
          setPaymentSpecific({...paymentSpecific, status:payment_status})

        } else if(status == 'waring') {
          alert(statusMessage)
        }

      });
  };

  const setCXTypes = (type) => {
    setMessageText(CXMessageContent[type])
    setCXType(type)
  }

  const [paymentSpecific, setPaymentSpecific] = useState("");
  const [CXPopup, setCXPopup] = useState(true);
  const [CXType, setCXType] = useState('CCONFIRM');
  const [CXManager, setCXManager] = useState("Victor");
  const [sendPhoneNumber, setSendPhoneNumber] = useState("010");
  const [LS, setLS] = useState(false);
  const [LSList, setLSList] = useState();
  const [PS, setPS] = useState({
    year : "",
    month : "",
    day : "",
    time : ""
  });
  const [partnerId, setPartnerId] = useState({
    status : false,
    phone : "",
    partner : "",
    sport : "",
  });
  const [nameSearchText, setNameSearchText] = useState("");
  const [idSearch, setIdSearch] = useState([]);

    var dateNow = new Date();
    var tomorrow = new Date(dateNow.setDate(dateNow.getDate() + 1));
    const tomorrowM = tomorrow.getMonth() + 1 > 9 ? tomorrow.getMonth() + 1 : "0" + String(tomorrow.getMonth() + 1); 
    const tomorrowD = tomorrow.getDate() > 9 ? tomorrow.getDate() : "0" + String(tomorrow.getDate()); 
    const tomorrowH = tomorrow.getHours() > 9 ? tomorrow.getHours() : "0" + String(tomorrow.getHours()); 

  // message_data
  const [user, setUser] = useState("");
  const [remind, setRemind] = useState("2일");
  const [partner, setPartner] = useState("");
  const [paymentNumber, setPaymentNumber] = useState("");
  const [lesson, setLesson] = useState("");
  const [people, setPeople] = useState("");
  const [schedule, setSchedule] = useState("");
  const [location, setLocation] = useState("");
  const [partnerPhone, setPartnerPhone] = useState("010");
  const [userPhone, setUserPhone] = useState("010");
  const [userSpec, setUserSpec] = useState("");
  const [exclude, setExclude] = useState("");
  const [include, setInclude] = useState("");
  const [soLimit, setSoLimit] = useState("3시간");
  const [soLimit2, setSoLimit2] = useState("3시간");
  const [bring, setBring] = useState("");
  const [partnerPayment, setPartnerPayment] = useState("");
  const [messageText, setMessageText] = useState("");
  const [now, setNow] = useState(`${tomorrowM}월 ${tomorrowD}일 ${tomorrowH}시`);
  const [refund, setRefund] = useState("");
  const [refundTag, setRefundTag] = useState("");

    useEffect(() => {
        if(CXType == 'SOPAYMENT') {
            let dateNow = new Date();
            let tomorrow = new Date(dateNow.setHours(dateNow.getHours() + 3));
            const tM = tomorrow.getMonth() + 1 > 9 ? tomorrow.getMonth() + 1 : "0" + String(tomorrow.getMonth() + 1); 
            const tD = tomorrow.getDate() > 9 ? tomorrow.getDate() : "0" + String(tomorrow.getDate()); 
            const tH = tomorrow.getHours() > 9 ? tomorrow.getHours() : "0" + String(tomorrow.getHours()); 

            setNow(`${tM}월 ${tD}일 ${tH}시`);
        } else {
            let dateNow = new Date();
            let tomorrow = new Date(dateNow.setDate(dateNow.getDate() + 1));
            const tM = tomorrow.getMonth() + 1 > 9 ? tomorrow.getMonth() + 1 : "0" + String(tomorrow.getMonth() + 1); 
            const tD = tomorrow.getDate() > 9 ? tomorrow.getDate() : "0" + String(tomorrow.getDate()); 
            const tH = tomorrow.getHours() > 9 ? tomorrow.getHours() : "0" + String(tomorrow.getHours()); 

            setNow(`${tM}월 ${tD}일 ${tH}시`);
        }

        if(CXType == 'CPREFUND' || CXType == 'PREFUND' || CXType == 'CAREFUND') {
            setRefund(paymentSpecific.refund_amount_100)
        } else {
            setRefund(paymentSpecific.refund_amount_text)
        }

    }, [CXType])

      // 판매 리스트 로드
      useEffect(() => {
    
        var token = window.localStorage.getItem('token')
  
        const frm = new FormData();
        frm.append("mode", "payment_detail");
        frm.append("token", token);
        frm.append("number", sid);
  
          axios.post("https://ai.semos.kr/semos_admin/payment/data", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          }).then((data) => {
            var Value = data.data;
            var status = Value.status
            var statusMessage = Value.status_message
  
            if(status == 'success') {

              var usl = "";
              var usd = Value.payment_data.user_spec;
              for(var i=0; usd.length > i; i++) {
                if(i === usd.length - 1) {
                  usl = usl + String(usd[i].name + ' / ' + (usd[i].gender ? usd[i].gender + ' / ' : "미작성 / ") + usd[i].height + 'cm / ' + usd[i].weight + 'kg / ' + usd[i].foot + 'mm');
                } else {
                  usl = usl + String(usd[i].name + ' / ' + (usd[i].gender ? usd[i].gender + ' / ' : "미작성 / ") + usd[i].height + 'cm / ' + usd[i].weight + 'kg / ' + usd[i].foot + "mm\r\n");
                }
              }

              var icl = "";
              var ecl = "";
              var bcl = "";
              var icd = Value.payment_data.extra_info_include;
              var ecd = Value.payment_data.extra_info_exclude;
              var bcd = Value.payment_data.extra_info_bring;

              for(var i=0; ecd.length > i; i++) {
                if(i === ecd.length - 1) { 
                  ecl = ecl + String(ecd[i])
                } else {
                  ecl = ecl + String(ecd[i] + ', ')
                }
              }

              for(var i=0; icd.length > i; i++) {
                if(i === icd.length - 1) { 
                  icl = icl + String(icd[i])
                } else {
                  icl = icl + String(icd[i] + ', ')
                }
              }

              for(var i=0; bcd.length > i; i++) {
                if(i === bcd.length - 1) { 
                  bcl = bcl + String(bcd[i])
                } else {
                  bcl = bcl + String(bcd[i] + ', ')
                }
              }

              var FDDATA = Value.payment_data.use_date.split(' ~ ')[0];

              var FDDATASPLIT1 = FDDATA.split(' ');

              var FDDATASPLIT2 = FDDATASPLIT1[0].split('.')

              setPaymentSpecific(Value.payment_data)
              setPartnerPhone(Value.payment_data.partner_phone)
              setUserPhone(Value.payment_data.user_phone)
              setPartner(Value.payment_data.partner)
              setSchedule(Value.payment_data.use_date.split(' ~ ')[0])
              setLocation(Value.payment_data.service_location)
              setUser(Value.payment_data.user)
              setLesson(`[${Value.payment_data.sport}] ${Value.payment_data.service}`)
              setPeople(Value.payment_data.people)
              setPaymentNumber(Value.payment_data.payment_number)
              setPartnerPayment(`${Value.payment_data.partner_payment}원`)
              setLSList(Value.payment_data.location_search2)
              setPS({
                year : FDDATASPLIT2[0],
                month : FDDATASPLIT2[1],
                day : FDDATASPLIT2[2],
                time : FDDATASPLIT1[1],
              })
              setPartnerId({
                status : false,
                phone : Value.payment_data.partner_phone,
                partner : Value.payment_data.partner,
                token : Value.payment_data.partner_token,
                sport : Value.payment_data.partner_category,
              })
              setUserSpec(usl)
              setExclude(ecl)
              setInclude(icl)
              setBring(bcl)
              setRefund(`${Value.payment_data.refund_amount_text}원`)
              setRefundTag("")

              setSendPhoneNumber(Value.payment_data.user_phone)

            } else if(status == 'waring') {
              alert(statusMessage)
            }
  
          });
  
          // retention
          var YEAR = '2023';

          const frm2 = new FormData();
          frm2.append("mode", "retention_result");
          frm2.append("token", token);
          frm2.append("year", YEAR);

          axios.post("https://ai.semos.kr/semos_admin/board/dashboard", frm2, {
            headers: { "Content-Type": "multipart/form-data" },
          }).then((data) => {
            var VALUE = data.data;
            console.log(VALUE)
          });
          
      }, []);


      const messageSend = () => {

        if(window.confirm("작성한 내용을 전송하시겠습니까?")) { 
      
          const mCheck = CXManager.length > 2;
          const pCheck = sid > 0;
          const tCheck = CXType.length > 1;
          const cCheck = CXMessageContent[CXType].length > 9;
      
          if(mCheck && pCheck && tCheck && cCheck) {
      
              var token = window.localStorage.getItem('token');
        
              const frm = new FormData();
              frm.append("mode", "CXMessage_new");
              frm.append("token", token);
              frm.append("payId", sid);
              frm.append("manager", CXManager);
              frm.append("sendNumber", sendPhoneNumber);
              frm.append("content", CXMessageContent[CXType]);
              frm.append("remind", remind);
              //frm.append("partnerContent", CXPartnerMessageContent[CXType]);
              frm.append("cxType", CXType);
              frm.append("activity", lesson);
              frm.append("refund", refund);
              frm.append("partnerName", partner);
              if(CXType == 'UREVIEW3' || CXType == 'UREMINDER5' || CXType == 'UCONFIRMS5') {
                frm.append("pushTitle", CXPushContent[CXType].title);
                frm.append("pushContent", CXPushContent[CXType].content);
              }
              
              axios.post("https://ai.semos.kr/semos_admin/user/send", frm, {
                headers: { "Content-Type": "multipart/form-data" },
              }).then((data) => {
                var Value = data.data;
                var status = Value.status
                var statusMessage = Value.status_message
        
                if(status == 'success') {
                  alert(statusMessage)
                  window.location.reload();
                } else if(status == 'waring') {
                  alert(statusMessage)
                  window.location.reload();
                } else if(status == 'fail') {
                  alert(statusMessage)
                  window.location.reload();
                }
        
              });
      
          } else {
            if(!mCheck) {
              alert('담당자명을 확인해주세요!')
            } else if(!pCheck) {
              alert('결제 ID를 확인해주세요!')
            } else if(!tCheck) {
              alert('알림 사유를 확인해주세요!')
            } else if(!cCheck) {
              alert('알림 내용을 확인해주세요!')
            }
          }
        }
      
      }

      const NameSearch = (text) => {
        setNameSearchText(text);

        var token = window.localStorage.getItem('token')
  
        const frm = new FormData();
        frm.append("mode", 'partner_search2');
        frm.append("token", token);
        frm.append("text", text);

        axios.post("https://ai.semos.kr/semos_admin/partner/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setIdSearch(Value.user_id_list)
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
      }

      const CXMessageTypeValue = {
        "cancelp" : "액티비티 취소/환불 안내 - 강사 요청",
        "cancelp2" : "액티비티 환불/취소",
        "cancelu" : "액티비티 취소/환불 안내 - 고객 요청",
        "refundu" : "유저 - 환불 (고객 요청)",
        "refundp" : "액티비티 환불/취소",
        "reminder" : "액티비티 이틀 전 리마인더",
        "reminder2" : "액티비티 이틀 전 리마인더",
        "payment" : "액티비티 결제 안내",
        "confirm" : "액티비티 확정 안내",
        "confirm2" : "액티비티 확정 안내",

        "UCANCELPS" : "액티비티 취소/환불 안내 - 강사 요청",
        "UCANCELS" : "액티비티 취소/환불 안내 - 고객 요청",
        "PCANCELS" : "액티비티 환불/취소",
        "PCANCELS2" : "액티비티 환불/취소",
        "UREMINDERS" : "액티비티 이틀 전 리마인더",
        "PREMINDERS" : "액티비티 이틀 전 리마인더",
        "PAYMENTS" : "액티비티 결제 안내",
        "PPAYMENTS" : "구매 발생 (예약 확정 필요)",
        "UCONFIRMS" : "액티비티 확정 안내",
        "PCONFIRMS" : "예약 확정",
        
        "UCANCELPS2" : "액티비티 취소/환불 안내 - 강사 요청",
        "UCANCELS2" : "액티비티 취소/환불 안내 - 고객 요청",
        "PCANCELS" : "액티비티 환불/취소",
        "PCANCELS3" : "액티비티 환불/취소",
        "UREMINDER2" : "액티비티 이틀 전 리마인더",
        "PREMINDER2" : "액티비티 이틀 전 리마인더",
        "PAYMENTS2" : "액티비티 결제 안내",
        "PPAYMENTS2" : "구매 발생 (예약 확정 필요)",
        "UCONFIRMS2" : "액티비티 확정 안내",
        "PCONFIRMS2" : "예약 확정",
        "UREVIEW" : "리뷰 안내",

        "PREGISTER2" : "세모스 파트너 등록",
        "PREGISTER" : "세모스 파트너 등록",
        "PSCHEDULE" : "월별 일정 등록",
        "REVIEWJOME" : "리뷰 독려 요청",
        "UREMINDER3" : "액티비티 이틀 전 리마인더",
        "PREMiNDER3" : "액티비티 이틀 전 리마인더",

        "PSCHEDULE2" : "월별 일정 등록",
        "PREGISTER3" : "세모스 파트너 등록",
        "REVIEWJOM2" : "리뷰 독려 요청",
        "UCANCELPS3" : "액티비티 취소/환불 안내 - 강사 요청",
        "UCANCELS3" : "액티비티 취소/환불 안내 - 고객 요청",
        "PCANCELS4" : "액티비티 환불/취소",
        "UREMINDER4" : "액티비티 이틀 전 리마인더",
        "PREMINDER4" : "액티비티 이틀 전 리마인더",
        "PAYMENTS3" : "액티비티 결제 안내",
        "PPAYMENTS3" : "구매 발생 (예약 확정 필요)",
        "UCONFIRMS3" : "액티비티 확정 안내",
        "PCONFIRMS3" : "예약 확정",
        "UREVIEW2" : "리뷰 안내",

        "PREGISTER4" : "세모스 파트너 등록",
        "REVIEWJOM3" : "리뷰 독려 요청",
        "UCANCELPS4" : "액티비티 취소/환불 안내 - 강사 요청",
        "UCANCELS4" : "액티비티 취소/환불 안내 - 고객 요청",
        "PCANCELS5" : "액티비티 환불/취소",
        "UREMINDER5" : "액티비티 이틀 전 리마인더",
        "PREMINDER5" : "액티비티 이틀 전 리마인더",
        "PAYMENTS4" : "액티비티 결제 안내",
        "PAYMENT5" : "액티비티 결제 안내",
        "PPAYMENTS4" : "구매 발생 (예약 확정 필요)",
        "UCONFIRMS4" : "액티비티 확정 안내",
        "PCONFIRMS4" : "예약 확정",
        "UREVIEW3" : "리뷰 안내",

        "PAYMENTS3" : "액티비티 결제 안내",
        "PAYMENT6" : "액티비티 결제 안내",
        "PAYMENT4" : "액티비티 결제 안내",
        "UCONFIRMS5" : "액티비티 확정 안내",
        "UCANCELS5" : "액티비티 취소/환불 안내 - 고객 요청",
        "UCANCELPS5" : "액티비티 취소/환불 안내 - 강사 요청",
        "UREMINDER6" : "액티비티 이틀 전 리마인더",
        "PCANCEL" : "액티비티 환불/취소",
        "PCONFIRM" : "예약 확정",
        "PREMINDER" : "액티비티 이틀 전 리마인더",
        "PPAYMENT" : "구매 발생 (예약 확정 필요)",
        "SOPAYMENT" : "세모스ONLY결제발생",
        "SOPAYMENT2" : "세모스ONLY결제발생",
        "ActivityDenied" : "액티비티반려",
        "ActivityAccepted" : "액티비티승인",

        "CPAYMENT" : "크루_액티비티_결제",
        "CSOPAYMENT" : "크루_세모스온리_액티비티_결제",
        "CCONFIRM" : "크루_예약일정_확정",
        "CREMINDER" : "크루_예약일정_리마인더",
        "CREVIEW" : "크루_리뷰_요청",
        "CCREFUND" : "크루_예약일정_환불_크루",
        "CPREFUND" : "크루_예약일정_환불_파트너",
        "CAREFUND" : "크루_예약일정_환불_자동",

        "PSOPAYMENT" : "파트너_액티비티_결제",
        "PSOCONFIRM" : "파트너_세모스온리_결제_확정",
        "PAPASS" : "파트너_액티비티_심사승인",
        "PPAYMENT2" : "파트너_액티비티_결제",
        "PCONFIRM2" : "파트너_예약일정_확정",
        "PREMINDER3" : "파트너_예약일정_리마인더",
        "PREFUND" : "파트너_예약일정_환불"

      }


const CXPushContent = { 
  "UCONFIRMS5" : {
    title : "액티비티 예약이 확정되었어요!",
    content : `${lesson}
이용일자 : ${schedule}
일정 확인하러 가기 ->`
  },
  "UREMINDER5" : {
    title : `${user}님 ${remind} 뒤, 액티비티 잊지 않으셨죠?`,
    content : `${lesson}
이용일자 : ${schedule}
준비물 : ${bring}
일정 확인하러 가기 ->`
  },
  "UREVIEW3" : {
    title : `소중한 액티비티 경험을 들려주세요 🙏`,
    content : `이번에 진행한 ${lesson}는 재미있으셨나요? 소중한 경험을 공유해 주세요 😊`
  }
}

const CXMessageContent = { 

"CAREFUND" : `크루님! 24시간 이내에 예약 확정이 되지 않아 결제하신 액티비티가 환불될 예정이에요.

- 파트너: ${partner} 
- 액티비티: ${lesson} 
- 인원: ${people}
- 일정: ${schedule}
- 장소: ${location}

- 환불 사유 : 
${refundTag}

- 환불 예정 금액 : 
${refund}

사용하신 포인트와 함께 100% 전액 환불될 예정이에요.

***세모스에서 다른 액티비티를 선택해 주세요.
결제 금액은 영업일 기준 1~3일 안에 환불됩니다.

레저스포츠 1등 예약 앱, 세모스!
☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)`,

"CCONFIRM" : `크루님께서 결제한 액티비티가 아래와 같이 예약 확정되어 안내드려요.

- 파트너: ${partner} 
- 액티비티: ${lesson} 
- 인원: ${people}
- 일정: ${schedule}
- 장소: ${location}

액티비티 전에 파트너님과 [마이 세모스 > 우측 상단 세모스 챗]을 통해 소통하실 수 있어요. 

준비물 및 기타 유의사항은 [마이 세모스]를 확인해 주세요.

레저스포츠 1등 예약 앱, 세모스!
☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)`,

"CCREFUND" : `${user} 크루님의 요청으로 액티비티가 취소되어 안내드려요.

- 파트너: ${partner}
- 액티비티: ${lesson}
- 인원: ${people}
- 일정: ${schedule}
- 장소: ${location}

- 환불 예정 금액 :
${refund}

***다음 기회에 꼭 세모스를 찾아주세요! 결제 금액은 영업일 기준 1~3일 안에 환불됩니다.

레저스포츠 1등 예약 앱, 세모스!
☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)`,

"CPREFUND" : `크루님! 파트너님의 사정으로 예약하신 일정 진행이 불가하여 결제하신 액티비티가 환불될 예정이에요.

- 파트너: ${partner} 
- 액티비티: ${lesson} 
- 인원: ${people}
- 일정: ${schedule}
- 장소: ${location}

- 환불 사유 : 
${refundTag}

- 환불 예정 금액 : 
${refund}

사용하신 포인트와 함께 100% 전액 환불될 예정이에요.

***세모스에서 다른 액티비티를 선택해 주세요.
결제 금액은 영업일 기준 1~3일 안에 환불됩니다.

레저스포츠 1등 예약 앱, 세모스!
☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)`,

"CREMINDER" : `크루님! 다가올 액티비티 잊지 않으셨죠?

- 파트너: ${partner} 
- 파트너 연락처: ${partnerPhone} 
- 액티비티: ${lesson} 
- 인원: ${people}
- 일정: ${schedule}
- 장소: ${location}

준비물 및 기타 유의사항은 [마이 세모스]를 확인해 주세요.

레저스포츠 1등 예약 앱, 세모스!
☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)`,

"CREVIEW" : `${partner} 파트너님과 함께한 액티비티는 어떠셨나요? 세모스 앱에 리뷰를 남겨보세요!

회원님의 소중한 피드백은 액티비티를 고민하는 분들에게 큰 도움이 될 거에요.


레저스포츠 1등 예약 앱, 세모스!
☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)`,

// PARTNER

"PCONFIRM2" : `파트너님의 액티비티가 아래와 같이 예약 확정되어 안내드려요. 

- 크루 닉네임: ${user}
- 액티비티: ${lesson}
- 액티비티 금액: ${partnerPayment}
- 포함사항: ${include}
- 불포함사항: ${exclude}
- 일정: ${schedule}
- 장소: ${location}
- 인원: ${people}

***액티비티를 결제하신 크루님과 [크루 관리]를 통해 소통하실 수 있어요.

레저스포츠 1등 예약 앱, 세모스!
[구글 Play 스토어 / 애플 App 스토어에서 세모스 파트너 다운로드]
☎ 문의: 카카오톡 채널 [세모스 파트너] (오전 10시 ~ 오후 7시)`,

"PSOCONFIRM" : `[세모스ONLY] 액티비티 그룹 진행이 확정되었어요.

- 액티비티: [세모스ONLY] ${lesson}

- 예약 확정 인원 수: ${user}
- 액티비티 금액: ${partnerPayment}
- 포함사항: ${include}

- 일정: ${schedule}
- 장소: ${location}
- 인원: ${people}

[세모스ONLY란?]
- 세모스에서 자체적으로 운영하는 액티비티로, (커뮤니케이션), (파트너 등급), (리뷰) 등을 종합적으로 고려하여 최우수 파트너님께 우선 배정해드리는 그룹 액티비티입니다. 액티비티 진행 ${soLimit} 전 예약이 마감되어 확정 여부가 결정됩니다.

레저스포츠 1등 예약 앱, 세모스!
[구글 Play 스토어 / 애플 App 스토어에서 세모스 파트너 다운로드]
☎ 문의: 카카오톡 채널 [세모스 파트너] (오전 10시 ~ 오후 7시)`,

"PREMINDER3" : `파트너님! 예정된 액티비티 잊지 않으셨죠? 

진행해주실 액티비티의 크루님들께 [사진 리뷰 작성] 독려를 부탁드려요.

- 크루 닉네임: ${user}
- 크루 연락처: ${userPhone}
- 액티비티: ${lesson}
- 액티비티 금액: ${partnerPayment}
- 포함사항: ${include}
- 불포함사항: ${exclude}
- 일정: ${schedule}
- 장소: ${location}
- 인원: ${people}

***파트너님의 열정적인 액티비티를 경험한 분들의 사진 리뷰가 많을수록 결제를 고민하는 크루님께 매력적인 액티비티가 돼요!

레저스포츠 1등 예약 앱, 세모스!
[구글 Play 스토어 / 애플 App 스토어에서 세모스 파트너 다운로드]
☎ 문의: 카카오톡 채널 [세모스 파트너] (오전 10시 ~ 오후 7시)`,


"PPAYMENT2" : `액티비티 결제가 발생했어요. 
24시간 이내에 세모스 파트너 앱/웹에서 예약을 확정해주세요.

파트너 앱 : [예약 관리 → 결제 완료 → 예약확정]

- 크루 닉네임: ${user}
- 액티비티: ${lesson}
- 액티비티 금액: ${partnerPayment}
- 포함사항: ${include}
- 불포함사항: ${exclude}
- 일정: ${schedule}
- 장소: ${location}
- 인원: ${people}

***결제 24시간 이후에는 액티비티가 자동 취소되오니, 위 일정이 가능하시다면 예약을 꼭 확정해주세요.

레저스포츠 1등 예약 앱, 세모스!
[구글 Play 스토어 / 애플 App 스토어에서 세모스 파트너 다운로드]
☎ 문의: 카카오톡 채널 [세모스 파트너] (오전 10시 ~ 오후 7시)`,

"PSOPAYMENT" : `[세모스ONLY] 액티비티 결제가 발생했어요. 

${soLimit} 이내에 진행 가능 여부를 카카오톡으로 회신해주세요.

- 액티비티: [세모스ONLY] ${lesson}

- 크루 닉네임: ${user}
- 액티비티 금액: ${partnerPayment}
- 포함사항: ${include}
- 불포함사항: ${exclude}
- 일정: ${schedule}
- 장소: ${location}
- 인원: ${people}

***본 제안은 ${now}까지만 유효해요.
이후에는 액티비티 결제가 자동 취소되오니, 위 일정이 진행 가능하시다면 예약을 꼭 확정해주세요.

[세모스ONLY란?]
- 세모스에서 자체적으로 운영하는 PB 액티비티로, (커뮤니케이션), (파트너 등급), (리뷰) 등을 종합적으로 고려하여 최우수 파트너님께 우선 배정해드리는 액티비티입니다. 

파트너님이 세모스 내에서 판매하시는 액티비티와 별개로 진행되며, 수수료율은 진행하시는 파트너님의 등급에 따라 결정됩니다.

레저스포츠 1등 예약 앱, 세모스!
[구글 Play 스토어 / 애플 App 스토어에서 세모스 파트너 다운로드]
☎ 문의: 카카오톡 채널 [세모스 파트너] (오전 10시 ~ 오후 7시)`,

"PREFUND" : `파트너님의 액티비티가 환불되어 안내드려요.
[예약 관리]의 [취소 내역]에서 상세 내용을 확인하실 수 있어요.

- 크루 닉네임: ${user}
- 액티비티: ${lesson}
- 액티비티 금액: ${partnerPayment}

- 일정: ${schedule}
- 장소: ${location}
- 인원: ${people}

레저스포츠 1등 예약 앱, 세모스!
[구글 Play 스토어 / 애플 App 스토어에서 세모스 파트너 다운로드]
☎ 문의: 카카오톡 채널 [세모스 파트너] (오전 10시 ~ 오후 7시)`,

}

const PhoneHyphen = (value) => {
  let valueCheck = String(value).replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-");

  setSendPhoneNumber(valueCheck)
}

const DataChange = (type, value) => {

  switch(type) {
    
    // user
    case "user":
      setUser(value)
    break;

    case "userPhone":
      var valueCheck = String(value).replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-");
      setUserPhone(valueCheck)
    break;

    case "paymentNumber":
      setPaymentNumber(value)
    break;

    case "lesson":
      setLesson(value)
    break;

    case "people":
      setPeople(value)
    break;

    case "schedule":
      setSchedule(value)
    break;

    case "location":
      setLocation(value)
    break;

    // partner
    case "partnerPayment":
      var str = String(value).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
      setPartnerPayment(str)
    break;

    case "partner":
      setPartner(value)
    break;

    case "partnerNumber":
      var valueCheck = String(value).replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-");
      setPartnerPhone(valueCheck)
    break;
  
    case "include":
      setInclude(value)
    break;
  
    case "exclude":
      setExclude(value)
    break;

    case "userSpec":
      setUserSpec(value)
    break;

    case "now":
        setNow(value)
    break;

    case "remind":
      setRemind(value)
    break;

    case "refund":
        setRefund(value)
    break;

    case "refundTag":
        setRefundTag(value)
    break;

    case "soLimit":
        setSoLimit(value)
    break;
    
  }

}

const CXTypesChange = (value, type) => {

    if(type === 'user') {
        
        setCXTypes(value)
        setSendPhoneNumber(paymentSpecific.user_phone)
    } else if(type === 'partner') {

        setCXTypes(value)
        setSendPhoneNumber(paymentSpecific.partner_phone)
    }

}

const LocationSearch = (text) => {

  var token = window.localStorage.getItem('token');
        
  const frm = new FormData();
  frm.append("mode", "location_search_new");
  frm.append("token", token);
  frm.append("number", sid);
  frm.append("text", text);
 

  axios.post("https://ai.semos.kr/semos_admin/payment/data", frm, {
    headers: { "Content-Type": "multipart/form-data" },
  }).then((data) => {
    var Value = data.data;
    var status = Value.status
    var statusMessage = Value.status_message

    if(status == 'success') {
      setLSList(Value.location_search2)
    } else if(status == 'waring') {
      alert(statusMessage)
      window.location.reload();
    } else if(status == 'fail') {
      alert(statusMessage)
      window.location.reload();
    }

  });

}

const ChangeLocation = (LID, LEID) => {

  if(window.confirm('장소를 변경하시겠습니까?')) {
    var token = window.localStorage.getItem('token');
        
    const frm = new FormData();
    frm.append("mode", "CXLocation_new");
    frm.append("token", token);
    frm.append("payId", sid);
    frm.append("locationAddress", LID);
    frm.append("locationExtraAddress", LEID);

    axios.post("https://ai.semos.kr/semos_admin/payment/data", frm, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then((data) => {
      var Value = data.data;
      var status = Value.status
      var statusMessage = Value.status_message

      if(status == 'success') {
        alert(statusMessage)
        window.location.reload();
      } else if(status == 'waring') {
        alert(statusMessage)
        window.location.reload();
      } else if(status == 'fail') {
        alert(statusMessage)
        window.location.reload();
      }

    });
  }
}

const ChangeSchedule = () => {

  if(window.confirm('일정를 변경하시겠습니까?')) {

    var token = window.localStorage.getItem('token');
        
    const frm = new FormData();
    frm.append("mode", "CXSchedule");
    frm.append("token", token);
    frm.append("payId", sid);
    frm.append("schedule", `${PS.year}.${PS.month}.${PS.day} ${PS.time}`);

    axios.post("https://ai.semos.kr/semos_admin/payment/data", frm, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then((data) => {
      var Value = data.data;
      var status = Value.status
      var statusMessage = Value.status_message

      if(status == 'success') {
        alert(statusMessage)
        window.location.reload();
      } else if(status == 'waring') {
        alert(statusMessage)
        window.location.reload();
      } else if(status == 'fail') {
        alert(statusMessage)
        window.location.reload();
      }

    });
    
  }
}

const SetId = (name, phone, token) => {

  if(window.confirm('새로운 강사를 배정하시겠습니까?')) { 

    var Utoken = window.localStorage.getItem('token');

    const frm = new FormData();
    frm.append("mode", "CXPartnerAdd");
    frm.append("token", Utoken);
    frm.append("pId", token);
    frm.append("payId", sid);

    axios.post("https://ai.semos.kr/semos_admin/payment/data", frm, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then((data) => {
      var Value = data.data;
      var status = Value.status
      var statusMessage = Value.status_message

      if(status == 'success') {
        alert(statusMessage)
        window.location.reload();
      } else if(status == 'waring') {
        alert(statusMessage)
        window.location.reload();
      } else if(status == 'fail') {
        alert(statusMessage)
        window.location.reload();
      }

    });
    
  }

}

const payStatusData = {
  0 : "환불/취소",
  4 : "정산 완료",
  3 : "액티비티 완료",
  2 : "예약확정",
  1 : "결제완료"
}

const payMethod = {
  "after_pay" : "나중 결제",
  "kakao" : "카카오페이",
  "toss" : "토스"
}

  return (
    CXPopup ? 
    <MainLayout >
        <MainHeader title={ paymentSpecific ? "[" + paymentSpecific.sport + "] " + paymentSpecific.service : ""} >
          <PaymentStatusContent>
            {paymentSpecific ? payStatusData[paymentSpecific.status] : ""}
          </PaymentStatusContent>
        </MainHeader>
        <Main>
        <MetaSection>
            <img onClick={() => window.open(paymentSpecific ? 'https://semos.kr/coaching/' + paymentSpecific.pid + '/' + paymentSpecific.lid + '/normal' : "" )} src={paymentSpecific ? paymentSpecific.service_thumbnail : ""} />

            <div>
              <p><b>결제번호</b> : {paymentSpecific ? paymentSpecific.payment_number : ""}</p>
              <p><b>결제일</b> : {paymentSpecific ? paymentSpecific.date : ""}</p>
              <span className="searchWrapper">
                <p><b>이용 정보</b> : </p>&nbsp;
                <span className="inputWrapper">
                  <div className="inputTable">
                    <input type='text' maxLength='4' onChange={(e) => setPS({...PS, year : e.target.value})} value={PS.year}/>
                    <span className="dotSpan">.</span>
                    <input type='text' maxLength='2' onChange={(e) => setPS({...PS, month : e.target.value})} value={PS.month}/>
                    <span className="dotSpan">.</span>
                    <input type='text' maxLength='2' onChange={(e) => setPS({...PS, day : e.target.value})} value={PS.day}/>
                    &nbsp;
                    <input type='text' maxLength='5' onChange={(e) => setPS({...PS, time : e.target.value})} value={PS.time}/>
                  </div>
                  {/*<input onChange={(e) => setPS(e.target.value)} value={PS ? PS : ""} type="text"/>*/}
                  <span onClick={() => ChangeSchedule()} className="right_button bg_blue_100">변경</span>
                </span>
              </span>
              <span className="searchWrapper">
                <p><b>액티비티 장소</b> : </p>&nbsp;
                <span className="inputWrapper">
                  <input value={paymentSpecific ? paymentSpecific.service_location : ""} type="text"/>
                  <span onClick={() => setLS(!LS)} className="right_button bg_blue_100">{LS ? "닫기" : "열기"}</span>
                  <span className={`location_search ${LS ? "" : "LSNAC"}`}>
                    <input onChange={(e) => LocationSearch(e.target.value)} className="addressSearch" type='text' maxLength="30" placeholder="주소, 장소명으로 검색" />
                    {LSList ? LSList.map((item, idx) => (
                      <span className={paymentSpecific ? `${item.address} ${item.address_extra}` === paymentSpecific.service_location ? "color_blue" : "" : ""} 
                      onClick={() => ChangeLocation(item.address, item.address_extra)} key={`location_search_${idx}`}>{`${item.address} ${item.address_extra}`}</span>
                    )) : ""}
                  </span>
                </span>
              </span>
            </div>
        </MetaSection>
        <Border />
        <ParticipantSection>
            <div>
            <p>유저</p>
            <p>{paymentSpecific ? paymentSpecific.user : ""}</p>

            <p>{paymentSpecific ? paymentSpecific.user_phone : ""}</p>
            </div>

            <div>
            <p>강사</p>
            {/*<p>{paymentSpecific ? paymentSpecific.partner : ""}</p>*/}
              <PartnerAdd>
                <span className="partner_name">
                  {`[${partnerId.sport}] ${partnerId.partner}`}
                </span>
                <img onClick={() => setPartnerId({...partnerId, status : !partnerId.status} )} className="open_caret" src={DropCaret}/>
                <section className="search_container">
                  <input onChange={(e) => NameSearch(e.target.value)} value={nameSearchText} type='text' maxLength='40' placeholder="강사명, 전화번호로 검색해주세요!"/>

                  <section className="search_content_container">
                    {idSearch.map((item, idx) => (
                      <span className={partnerId.token == item.token ? "color_blue search_content" : "search_content"} onClick={() => SetId(item.name, item.phone, item.token)} key={`partner_list_${idx}`}>
                        {`[${item.sport}] ${item.name} | ${item.phone}`}
                      </span>
                    )) }
                  </section>

                </section>
              </PartnerAdd>
            <p>{paymentSpecific ? paymentSpecific.partner_phone : ""}</p>
            </div>

        </ParticipantSection>
        
        <Border />

        <section>
            <p style={{ fontSize : "20px", fontWeight : "500" }} >알림톡 정보</p>

            <PaymentCXBox>
                <div className="BOX">
                    <p>크루 알림</p>
                    {/*CXMessageType[CXType]*/}
                    <span className="cxContent">
                        <span onClick={() => CXTypesChange('CCONFIRM', "user")} className={"cxType " + (CXType == 'CCONFIRM' ? "typeActive" : "")}>크루 예약 확정</span>
                        <span onClick={() => CXTypesChange('CREMINDER', "user")} className={"cxType " + (CXType == 'CREMINDER' ? "typeActive" : "")}>크루 리마인더</span>
                        <span onClick={() => CXTypesChange('CREVIEW', "user")} className={"cxType " + (CXType == 'CREVIEW' ? "typeActive" : "")}>크루 리뷰 요청</span>
                        <span onClick={() => CXTypesChange('CCREFUND', "user")} className={"cxType " + (CXType == 'CCREFUND' ? "typeActive" : "")}>크루 환불(크루요청)</span>
                        <span onClick={() => CXTypesChange('CPREFUND', "user")} className={"cxType " + (CXType == 'CPREFUND' ? "typeActive" : "")}>크루 환불(파트너요청)</span>
                        <span onClick={() => CXTypesChange('CAREFUND', "user")} className={"cxType " + (CXType == 'CAREFUND' ? "typeActive" : "")}>크루 환불(24초과)</span>
                    </span>
                </div>

                <div className="BOX">
                    <p>파트너 알림</p>
                    {/*CXMessageType[CXType]*/}
                    <span className="cxContent">
                        <span onClick={() => CXTypesChange('PPAYMENT2', "partner")} className={"cxType " + (CXType == 'PPAYMENT2' ? "typeActive" : "")}>파트너 결제 발생</span>
                        <span onClick={() => CXTypesChange('PSOPAYMENT', "partner")} className={"cxType " + (CXType == 'PSOPAYMENT' ? "typeActive" : "")}>파트너 세모스온리 결제 발생</span>
                        <span onClick={() => CXTypesChange('PCONFIRM2', "partner")} className={"cxType " + (CXType == 'PCONFIRM2' ? "typeActive" : "")}>파트너 예약 확정</span>
                        <span onClick={() => CXTypesChange('PSOCONFIRM', "partner")} className={"cxType " + (CXType == 'PSOCONFIRM' ? "typeActive" : "")}>파트너 세모스온리 확정</span>
                        <span onClick={() => CXTypesChange('PREMINDER3', "partner")} className={"cxType " + (CXType == 'PREMINDER3' ? "typeActive" : "")}>파트너 리마인더</span>
                        <span onClick={() => CXTypesChange('PREFUND', "partner")} className={"cxType " + (CXType == 'PREFUND' ? "typeActive" : "")}>파트너 환불</span>
                    </span>
                </div>

                <div className="BOX">
                    <p>담당자 명</p>
                    <span className="cxContent">
                        <input type="text" value={CXManager} onChange={(e) => setCXManager(e.target.value)} className="cxManeger" placeholder="담당자 명을 입력해 주세요" />
                    </span>
                </div>

                {/*<div>
                    <p>강사명</p>
                    <span className="cxContent">
                        <input maxLength='20' type="text" value={partner} onChange={(e) => setPartner(e.target.value)} className="cxManeger" placeholder="강사명 수정" />
                    </span>
                </div>*/}

                <div className="BOX">
                    <p>전화번호</p>
                    <span className="cxContent">
                        <input maxLength='13' type="text" value={sendPhoneNumber} onChange={(e) => PhoneHyphen(e.target.value)} className="cxManeger" placeholder="- 없이 작성" />
                    </span>
                </div>


                {/*<div>
                    <p>일정</p>
                    <span className="cxContent">
                        <input maxLength='100' type="text" value={paySchedule} onChange={(e) => setPaySchedule(e.target.value)} className="cxManeger" placeholder="일정 수정" />
                    </span>
                </div>

                <div>
                    <p>장소</p>
                    <span className="cxContent">
                        <input maxLength='100' type="text" value={payLocation} onChange={(e) => setPayLocation(e.target.value)} className="cxManeger" placeholder="장소 수정" />
                    </span>
                </div>*/}

                <div className="BOX">
                    <p>알림 내용</p>
                    <span className="cxContent">
                        {/*<textarea onChange={(e) => setMessageText(e.target.value)} value={messageText} type="text" className="cxMessage" maxLength="1000" />*/}
                        <Template type={CXType} change={DataChange}
                        data={{
                           "user" : user,
                           "paymentNumber" : paymentNumber,
                           "partnerNumber" : partnerPhone,
                           "partner" : partner,
                           "lesson" : lesson,
                           "people" : people,
                           "schedule" : schedule,
                           "location" : location,
                           "refund" : refund,
                           "refundTag" : refundTag,
                           "partnerPayment" : partnerPayment,
                           "include" : include,
                           "exclude" : exclude,
                           "userSpec" : userSpec,
                           "userPhone" : userPhone,
                           "now" : now,
                           "remind" : remind,
                           "soLimit" : soLimit
                        }} />
                    </span>
                </div>
            </PaymentCXBox>

      
        </section>
        </Main>

        <BottomButtonBox>
            <Button text="뒤로가기" clickHandler={() => setCXPopup(false)} width={`230px`} color={colors.white} fontcolor={colors.gray}/>

            <Button text="알림톡 전송" clickHandler={messageSend} width={`230px`} />

        </BottomButtonBox>
    </MainLayout>
    :
    <MainLayout >
      <MainHeader title={ paymentSpecific ? "[" + paymentSpecific.sport + "] " + paymentSpecific.service : ""} >
        <PaymentStatusContent>
          {paymentSpecific ? payStatusData[paymentSpecific.status] : ""}
        </PaymentStatusContent>
      </MainHeader>
      <Main>
        <MetaSection>
          <img onClick={() => window.open(paymentSpecific ? 'https://semos.kr/coaching/' + paymentSpecific.pid + '/' + paymentSpecific.lid + '/normal' : "" )} src={paymentSpecific ? paymentSpecific.service_thumbnail : ""} />

          <div>
            <p><b>결제번호</b> : {paymentSpecific ? paymentSpecific.payment_number : ""}</p>
            <p><b>결제일</b> : {paymentSpecific ? paymentSpecific.date : ""}</p>
            <span className="searchWrapper">
              <p><b>이용 정보</b> : </p>&nbsp;
              <span className="inputWrapper">
                  <div className="inputTable">
                    <input type='text' maxLength='4' onChange={(e) => setPS({...PS, year : e.target.value})} value={PS.year}/>
                    <span className="dotSpan">.</span>
                    <input type='text' maxLength='2' onChange={(e) => setPS({...PS, month : e.target.value})} value={PS.month}/>
                    <span className="dotSpan">.</span>
                    <input type='text' maxLength='2' onChange={(e) => setPS({...PS, day : e.target.value})} value={PS.day}/>
                    &nbsp;
                    <input type='text' maxLength='5' onChange={(e) => setPS({...PS, time : e.target.value})} value={PS.time}/>
                  </div>
                  {/*<input onChange={(e) => setPS(e.target.value)} value={PS ? PS : ""} type="text"/>*/}
                <span onClick={() => ChangeSchedule()} className="right_button bg_blue_100">변경</span>
              </span>
            </span>
            <span className="searchWrapper">
              <p><b>액티비티 장소</b> : </p>&nbsp;
              <span className="inputWrapper">
                <input value={paymentSpecific ? paymentSpecific.service_location : ""} type="text"/>
                <span onClick={() => setLS(!LS)} className="right_button bg_blue_100">{LS ? "닫기" : "열기"}</span>
                <span className={`location_search ${LS ? "" : "LSNAC"}`}>
                  <input onChange={(e) => LocationSearch(e.target.value)} className="addressSearch" type='text' maxLength="30" placeholder="주소, 장소명으로 검색" />
                  {LSList ? LSList.map((item, idx) => (
                    <span className={paymentSpecific ? `${item.address} ${item.address_extra}` === paymentSpecific.service_location ? "color_blue" : "" : ""} 
                    onClick={() => ChangeLocation(item.address, item.address_extra)} key={`location_search_${idx}`}>{`${item.address} ${item.address_extra}`}</span>
                  )) : ""}
                </span>
              </span>
            </span>
          </div>
        </MetaSection>
        <Border />
        <ParticipantSection>
          <div>
            <p>유저</p>
            <p>{paymentSpecific ? paymentSpecific.user : ""}</p>

            <p>{paymentSpecific ? paymentSpecific.user_phone : ""}</p>
          </div>

          <div>
            <p>강사</p>
            {/*<p>{paymentSpecific ? paymentSpecific.partner : ""}</p>*/}
            <PartnerAdd PIS={partnerId.status}>
              <span className="partner_name">
                {`[${partnerId.sport}] ${partnerId.partner}`}
              </span>
              <img onClick={() => setPartnerId({...partnerId, status : !partnerId.status} )} className="open_caret" src={DropCaret}/>
              <section className="search_container">
                <input onChange={(e) => NameSearch(e.target.value)} value={nameSearchText} type='text' maxLength='40' placeholder="강사명, 전화번호로 검색해주세요!"/>

                <section className="search_content_container">
                  {idSearch.map((item, idx) => (
                    <span className={partnerId.token == item.token ? "color_blue search_content" : "search_content"} onClick={() => SetId(item.name, item.phone, item.token)} key={`partner_list_${idx}`}>
                      {`[${item.sport}] ${item.name} | ${item.phone}`}
                    </span>
                  )) }
                </section>

              </section>
            </PartnerAdd>
            <p>{partnerId.phone}</p>
          </div>

        </ParticipantSection>

        {paymentSpecific ? 
        paymentSpecific.user_spec.length || paymentSpecific.notice_list.length ?
        <>
        <Border />

        <section>
          <p style={{ fontSize : "20px", fontWeight : "500" }} >추가 정보</p>
          
          { paymentSpecific.user_spec.length ?
              <InfoBox>
                <div>
                  <p>신체 정보</p>
    
                  <span className='physicalBox' >
    
                      <span className="physicalContent">
                        <span className="physicalValue weight_700">실명</span>
                        <span className="physicalValue weight_700">성별</span>
                        <span className="physicalValue weight_700">키</span>
                        <span className="physicalValue weight_700">몸무게</span>
                        <span className="physicalValue weight_700">발사이즈</span>
                      </span>
                      
                      { paymentSpecific.user_spec.map((item, idx) => 
                          (<span className="physicalContent">
                            <span className="physicalValue">{item.name}</span>
                            <span className="physicalValue">{item.gender ? item.gender : "미작성"}</span>
                            <span className="physicalValue">{item.height}cm</span>
                            <span className="physicalValue">{item.weight}kg</span>
                            <span className="physicalValue">{item.foot}mm</span>
                          </span>))
                      }
                
                  </span>
                </div>
              </InfoBox>
           : 
              ""
          }
        { paymentSpecific.notice_list.length ?
          <InfoBox>
            <div>
              <p>알림 내역</p>

              <span className='noticeBox' >

              { paymentSpecific.notice_list.map((item, idx) => 
                <span className="noticeContent">

                  <span className="noticeName">
                    {`${item.user_name} • ${CXMessageTypeValue[item.message_type]} • ${item.message_time}`}
                  </span>
                  <span onClick={() => navigate(`/message/${item.id}`)} className="noticeButton bg_blue_100">
                    알림 확인
                  </span>

                </span>)}

              </span>
            </div>
          </InfoBox>
          : ""}
        </section> </> : "" : "" } 

        <Border />

        <section>
          <p style={{ fontSize : "20px", fontWeight : "500" }} >결제 정보</p>

          <PaymentBox>
            <div>
              <p>결제 수단</p>
              <p>{paymentSpecific ? payMethod[paymentSpecific.method] : ""}</p>
            </div>

            <div>
              <p>상품 금액</p>
              <p>{paymentSpecific ? paymentSpecific.service_amount_text : ""}원</p>
            </div>

            <div>
              <p>인원: {paymentSpecific ? paymentSpecific.people : ""}인권</p>
              <p>+ {paymentSpecific ? paymentSpecific.discount_text : ""}원</p>
            </div>

            <div>
              <p>포인트 사용</p>
              <p>- {paymentSpecific ? paymentSpecific.point_text : ""}원</p>
            </div>



            <div>
              <p>총 결제 금액</p>
              <p>
                {paymentSpecific ? paymentSpecific.amount_text : ""}
                원
              </p>
            </div>
          </PaymentBox>
        </section>
      </Main>

      <BottomButtonBox>
        {/*paymentSpecific.status === "check" || paymentSpecific.status === "used_c" ?  <Button
          text={paymentSpecific.status === "check" ? "일정 취소하기" : "일정 환불하기"}
          clickHandler={() => { setCXPopup(true);
          paymentSpecific.status == 'check' ? setCXType("cancelp2") : setCXType("refundp") } }
          width={`230px`}
          color={colors.white}
          fontcolor={colors.gray}
          /> : "" } */}

        {/*paymentSpecific.status === "check" && (
          <Button
            text="일정 확정하기"
            clickHandler={() => { 
            setCXPopup(true);
            setCXType("confirm2") }}
            width={`230px`}
          />
        )*/}

        {/*paymentSpecific.status === "used_c" && (
          <Button
            text="1일전 안내하기"
            clickHandler={() => { 
            setCXPopup(true);
            setCXType("reminder2") }}
            width={`230px`}
          />
        )*/}

        {/*paymentSpecific.status === "cancel" ?
          <Button
            pointerEvents={'none'}
            text="환불/취소된 일정"
            color={colors.white}
            fontcolor={colors.gray}
            //clickHandler={fixHandler}
            width={`230px`}
          /> : ""
        */}

          {/*paymentSpecific.status !== "check" ?
            <Button
              text="일정 미확정하기"
              color={colors.white}
              fontcolor={colors.gray}
              clickHandler={() => fixHandler("check")}
              width={`230px`}
          /> : ""}

          {paymentSpecific.status !== "used_c" ?
            <Button
              text="일정 확정하기"
              color={colors.white}
              fontcolor={colors.gray}
              clickHandler={() => fixHandler("used_c")}
              width={`230px`}
          /> : ""}

          {paymentSpecific.status !== "cancel" ?
            <Button
              text="환불/취소하기"
              color={colors.white}
              fontcolor={colors.gray}
              clickHandler={() => fixHandler("cancel")}
              width={`230px`}
          /> : ""*/}

          <Button
            text="알림톡 전송"
            clickHandler={() => { 
            setCXPopup(true);
            setCXTypes("CCONFIRM") }}
            width={`230px`}
          />

      </BottomButtonBox>
    </MainLayout>
  );
};

export default SalesDetailPage;




// 강사 CX 메시지
/*const CXPartnerMessageContent = {
"cancelp2" : `[세모스] 액티비티 환불 발생 안내

안녕하세요 ${partnerName} 파트너님!
파트너님의 액티비티이 환불되어 안내 드립니다.

◼︎ 고객명: ${user}
◼︎ 액티비티: ${"[" + paymentSpecific.sport + "] " + paymentSpecific.service}
◼︎ 일정: ${paySchedule}
◼︎ 장소: ${payLocation}
◼︎ 인원: ${people}인
  
세모스와 함께해주셔서 늘 감사합니다, 강사님!`,
      
"cancelu" : `[세모스] 액티비티 환불 발생 안내

안녕하세요 ${partnerName} 파트너님!
파트너님의 액티비티이 환불되어 안내 드립니다.

◼︎ 고객명: ${user}
◼︎ 액티비티: ${"[" + paymentSpecific.sport + "] " + paymentSpecific.service}
◼︎ 일정: ${paySchedule}
◼︎ 장소: ${payLocation}
◼︎ 인원: ${people}인
  
세모스와 함께해주셔서 늘 감사합니다, 강사님!`,
      
"refundp" : `[세모스] 액티비티 환불 발생 안내

안녕하세요 ${partnerName} 파트너님!
파트너님의 액티비티이 환불되어 안내 드립니다.

◼︎ 고객명: ${user}
◼︎ 액티비티: ${"[" + paymentSpecific.sport + "] " + paymentSpecific.service}
◼︎ 일정: ${paySchedule}
◼︎ 장소: ${payLocation}
◼︎ 인원: ${people}인
  
세모스와 함께해주셔서 늘 감사합니다, 강사님!`,
      
"refundu" : `[세모스] 액티비티 환불 발생 안내

안녕하세요 ${partnerName} 파트너님!
파트너님의 액티비티이 환불되어 안내 드립니다.

◼︎ 고객명: ${user}
◼︎ 액티비티: ${"[" + paymentSpecific.sport + "] " + paymentSpecific.service}
◼︎ 일정: ${paySchedule}
◼︎ 장소: ${payLocation}
◼︎ 인원: ${people}인
  
세모스와 함께해주셔서 늘 감사합니다, 강사님!`,
      
"reminder2" : `[세모스] 액티비티 1일전 안내

안녕하세요 ${partnerName} 파트너님!
1일 뒤, 액티비티 잊지 않으셨죠?

◼︎ 고객명: ${user}
◼︎ 고객 연락처: ${userPhone}
◼︎ 액티비티: ${"[" + paymentSpecific.sport + "] " + paymentSpecific.service}
◼︎ 일정: ${paySchedule}
◼︎ 장소: ${payLocation}
◼︎ 인원: ${people}인

세모스와 함께해주셔서 늘 감사합니다, 강사님!`,
      
"confirm2" : `[세모스] 예약 확정 안내

안녕하세요 ${partnerName} 파트너님!
파트너님께서 일정 확정해주신 액티비티이 아래와 같이 예약 확정되어 안내 드립니다.

◼︎ 고객명: ${user}
◼︎ 고객 연락처: ${userPhone}
◼︎ 액티비티: ${"[" + paymentSpecific.sport + "] " + paymentSpecific.service}
◼︎ 포함사항: ${include}
◼︎ 불포함사항: ${exclude}
◼︎ 일정: ${paySchedule}
◼︎ 장소: ${payLocation}
◼︎ 인원: ${people}인
◼︎ 장비 대여 사이즈 정보
${userSpec}

세모스와 함께해주셔서 늘 감사합니다, 강사님!`,
};


// 유저 CX 메시지
const CXMessageContent = {
"cancelp2" : `[세모스] 액티비티 취소 안내
        
안녕하세요 ${user} 회원님! 
강사님의 예약 미확정으로 인해 회원님의 액티비티이 취소되어 안내 드립니다.
    
◼︎ 결제번호: ${paymentNumber}
◼︎ 강사: ${partner}
◼︎ 액티비티: ${"[" + paymentSpecific.sport + "] " + paymentSpecific.service}
◼︎ 인원: ${people}인
◼︎ 일정: ${paySchedule}
◼︎ 장소: ${payLocation}
    
세모스 앱에서 다른 액티비티을 선택해 주세요.
결제 금액은 영업일 기준 1~3일 안에 환불 처리될 예정입니다.
☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)`,
    
"cancelu" : `[세모스] 액티비티 취소/환불 안내
    
안녕하세요 ${user} 회원님!
회원님의 요청으로 액티비티이 취소되어 안내 드립니다.
    
◼︎ 결제번호: ${paymentNumber}
◼︎ 강사: ${partner}
◼︎ 액티비티: ${"[" + paymentSpecific.sport + "] " + paymentSpecific.service}
◼︎ 인원: ${people}인
◼︎ 일정: ${paySchedule}
◼︎ 장소: ${payLocation}
    
다음 기회에 꼭 세모스를 찾아주세요!
결제 금액은 영업일 기준 1~3일 안에 환불 처리될 예정입니다.
☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)`,
    
"refundp" : `[세모스] 액티비티 환불 안내

안녕하세요 ${user} 회원님! 
강사님의 일정 진행 불가로 인해 회원님의 액티비티이 환불되어 안내 드립니다.
    
◼︎ 결제번호: ${paymentNumber}
◼︎ 강사: ${partner}
◼︎ 액티비티: ${"[" + paymentSpecific.sport + "] " + paymentSpecific.service}
◼︎ 인원: ${people}인
◼︎ 일정: ${paySchedule}
◼︎ 장소: ${payLocation}
    
회원님께 불편을 끼쳐드려 죄송합니다. 세모스 앱에서 강사님과 대화를 나누거나, 다른 액티비티을 선택해 주세요.
결제 금액은 영업일 기준 1~3일 안에 환불 처리될 예정입니다.
☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)`,
    
"refundu" : `[세모스] 액티비티 취소/환불 안내
    
안녕하세요 ${user} 회원님! 
회원님의 요청으로 액티비티이 취소되어 안내 드립니다.
    
◼︎ 결제번호: ${paymentNumber}
◼︎ 강사: ${partner}
◼︎ 액티비티: ${"[" + paymentSpecific.sport + "] " + paymentSpecific.service}
◼︎ 인원: ${people}인
◼︎ 일정: ${paySchedule}
◼︎ 장소: ${payLocation}
    
다음 기회에 꼭 세모스를 찾아주세요!
결제 금액은 영업일 기준 1~3일 안에 환불 처리될 예정입니다.
☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)`,
    
"reminder2" : `[세모스] 액티비티 1일전 안내

안녕하세요 ${user} 회원님! 
1일 뒤, 액티비티 잊지 않으셨죠?
    
◼︎ 결제번호: ${paymentNumber}
◼︎ 강사: ${partner}
◼︎ 액티비티: ${"[" + paymentSpecific.sport + "] " + paymentSpecific.service}
◼︎ 인원: ${people}인
◼︎ 일정: ${paySchedule}
◼︎ 장소: ${payLocation}
    
워터스포츠의 매력에 풍덩 빠지실 거에요!
준비물 및 기타 유의사항은 액티비티 상세페이지를 확인해 주세요.
☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시)`,
    
"confirm2" : `[세모스] 액티비티 확정 안내
    
안녕하세요 ${user} 회원님! 
회원님께서 결제한 액티비티이 아래와 같이 예약 확정되어 안내 드립니다.
    
◼︎ 결제번호: ${paymentNumber}
◼︎ 강사: ${partner}
◼︎ 액티비티: ${"[" + paymentSpecific.sport + "] " + paymentSpecific.service}
◼︎ 인원: ${people}인
◼︎ 일정: ${paySchedule}
◼︎ 장소: ${payLocation}
    
세모스를 이용해 주셔서 고맙습니다!
취소/환불 규정은 액티비티 상세페이지를 확인해 주세요.
☎ 문의: 카카오톡 채널 [세모스 , 세상의 모든 스포츠] (오전 10시 ~ 오후 7시) `,
}*/
