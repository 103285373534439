import styled from "styled-components";
import base from "../styles/constants/base";

const Container = styled.div`
  padding: 0 ${base.spacing.main}px;
`;

const HorizonLayout = ({ children }) => {
  return <Container>{children}</Container>;
};

export default HorizonLayout;
