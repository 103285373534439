import React, { useState, useEffect } from "react";
import styled from "styled-components";
import colors from "../../styles/constants/colors";

import Arrow from "../../assets/icon/selectArrow.png";
import { Wrapper, Text } from "../../layouts/layoutStorage";

const W = window.innerWidth;

const PBActivityPriceInfo = ({ open: detailOpen, data, setData }) => {
  const [open, setOpen] = useState({
    initial: false,
    max: false,
  });

  const [range, setRange] = useState({
    initial: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
    max: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
  });

  const localeStringMaker = (value) => {
    var copy = value?.replace(/\D/g, "");
    copy = copy?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return copy;
  };

  //가격 수정
  const priceChange = (value) => {
    var copy = value?.replace(/\D/g, "");
    copy = copy?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setData((prev) => ({ ...prev, price: copy }));
  };

  useEffect(() => {
    var initial = 1;
    var arr = [];
    for (let i = initial; i < 21; i++) {
      arr.push(i);
    }
    setRange((prev) => ({ ...prev, max: arr }));

    if (initial > data.max_capacity) {
      setData((prev) => ({ ...prev, max_capacity: initial }));
    }
  }, [data.max_capacity]);

  const capaPick = (el, type) => {
    setData((prev) => ({ ...prev, [type]: el }));
  };

  return (
    <div id="price">
      <TitleBox>
        <Styler pt={36} pl={36} pb={16} pr={36} flex={true} justify="space-between" align="center">
          <Title className="f16">액티비티 가격 정보</Title>
        </Styler>
      </TitleBox>
      <Styler pl={36} pr={36}>
        <Styler flex={true} margin="8px 0 0 0">
          <Wrapper width="100%">
            <CapacityText>초기값</CapacityText>

            <Wrapper jc="center" ai="center" padding="calc(1rem * (9 / 14)) 0" width="calc(1rem * 247 / 14)" border={`1px solid ${colors.Gr02}`}>
              <InitialValueInput
                type="tel"
                maxLength={2}
                value={data.initial_capacity}
                placeholder="초기값을 입력해 주세요."
                onChange={(e) => setData((prev) => ({ ...prev, initial_capacity: e.target.value }))}
              />
            </Wrapper>
          </Wrapper>
        </Styler>

        <Styler flex={true} justify="space-between" margin="8px 0 0 0">
          <CapacityText>확정</CapacityText>

          <Styler flex={true} justify="space-between" width="calc(100% - (1rem * 84 / 14))">
            <CapacityControlBox>
              <DropDownContainer width="calc(100% - 10px)">
                <DropDown onClick={() => setOpen((prev) => ({ ...prev, initial: !open.initial }))}>
                  <CapacityNumber>{data.confirm_capacity ? `${data.confirm_capacity}인` : "확정 인원 선택"}</CapacityNumber>
                  <SelectIcon open={open.initial} src={Arrow} />
                </DropDown>
                {open.initial && (
                  <DropMenu open={open}>
                    {range.initial.map((el, i) => (
                      <MenuItem
                        key={`${el}_${i}_range`}
                        el={el}
                        onClick={() => {
                          capaPick(el, "confirm_capacity");
                          setOpen((prev) => ({ ...prev, initial: !open.initial }));
                        }}
                      >
                        {el}인
                      </MenuItem>
                    ))}
                  </DropMenu>
                )}
              </DropDownContainer>
            </CapacityControlBox>
            <CapacityControlBox>
              <CapacityText width="calc(1rem * 60 / 14)">최대</CapacityText>
              <DropDownContainer>
                <DropDown onClick={() => setOpen((prev) => ({ ...prev, max: !open.max }))}>
                  <CapacityNumber>{data.max_capacity ? `${data.max_capacity}인` : "최대 인원 선택"}</CapacityNumber>
                  <SelectIcon open={open.max} src={Arrow} />
                </DropDown>
                {open.max && (
                  <DropMenu open={open}>
                    {range.max.map((el, i) => (
                      <MenuItem
                        key={`${el}_${i}_maxRange`}
                        el={el}
                        onClick={() => {
                          capaPick(el, "max_capacity");
                          setOpen((prev) => ({ ...prev, max: !open.max }));
                        }}
                      >
                        {el}인
                      </MenuItem>
                    ))}
                  </DropMenu>
                )}
              </DropDownContainer>
              <Wrapper
                margin="0 0 0 15px"
                width="calc(1rem * 151 / 14)"
                padding="calc(1rem * (9 / 14)) 0"
                jc="center"
                ai="center"
                border={`1px solid ${colors.Gr02}`}
                bgc={colors.Gr01}
              >
                <Text className="f13" weight="500" color={colors.Gr03}>
                  {`${Number(data.initial_capacity) + Number(data.max_capacity)}`}인
                </Text>
              </Wrapper>
            </CapacityControlBox>
          </Styler>
        </Styler>

        <Styler flex={true} align="center" margin="8px 0 0 0">
          <CapacityText>최소 연령</CapacityText>
          <Wrapper ai="flex-end">
            <Wrapper width="calc(1rem * 124 / 14)" border={`1px solid ${colors.Gr02}`}>
              <TextInput type="tel" value={data.min_age} placeholder="최소 연령" onChange={(e) => setData((prev) => ({ ...prev, min_age: e.target.value }))} />
            </Wrapper>
            <Text margin="0 0 0 4px" className="f14" weight="500" color={colors.Gr03}>
              세
            </Text>
          </Wrapper>
        </Styler>
        <Styler flex={true} align={"flex-start"} justify={"space-between"} pb={14} pt={24}>
          <SmallTitle>판매금액</SmallTitle>
        </Styler>

        <Styler align="center" pb={26}>
          <Styler flex={true} justify="space-between" align="center" pb={10}>
            <TimeText>{`1인`}</TimeText>

            <Styler width="100%" style={{ marginRight: 8 }} flex={true} align="center">
              <TextInputDiv>
                <TextInput
                  type="tel"
                  value={localeStringMaker(data.price)}
                  // value={data.price}
                  placeholder="인당 판매금액 작성하기"
                  onChange={(e) => priceChange(e.target.value)}
                />
              </TextInputDiv>
            </Styler>
          </Styler>
        </Styler>
      </Styler>
    </div>
  );
};

export default PBActivityPriceInfo;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  padding-top: ${({ pt }) => `calc(1rem * ${pt} / 14)`};
  padding-bottom: ${({ pb }) => `calc(1rem * ${pb} / 14)`};
  padding-left: ${({ pl }) => `calc(1rem * ${pl} / 14)`};
  padding-right: ${({ pr }) => `calc(1rem * ${pr} / 14)`};
  margin: ${({ margin }) => margin};
  height: ${({ height }) => height}px;
  width: ${({ width }) => width};
`;

const TitleBox = styled.div`
  border-top: 1px solid ${colors.Gr02};
`;

const Title = styled.div`
  font-weight: 600;

  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${({ red }) => (red ? colors.Red03 : colors.Gr05)};
`;

const Indicator = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr04};
`;

const Index = styled.span`
  color: ${({ red }) => (red ? colors.Red03 : colors.Gr05)};
`;

const SmallTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ status }) => (status == "empty" ? colors.Red03 : colors.Gr05)};
  line-height: 142%;
  font-weight: 500;
`;

const CapacityControlBox = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
`;

const RigntControlContainer = styled.div`
  max-width: 50%;
  display: inline-flex;
  align-items: center;
`;

const RigntControlTitle = styled.span`
  display: inline-block;
  color: ${colors.Gr04};
  font-size: 14px;
  margin-right: 12px;
`;

const RigntControlInput = styled.input`
  display: inline-block;
  color: ${colors.Gr04};
  padding: 8px 12px;
  border: 1px solid ${colors.Gr02};
  text-align: center;
  max-width: 60px;
`;
const InitialValueInput = styled.input`
  font-weight: 500;
  font-size: calc(1rem * 13 / 14);
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  width: 100%;
  text-align: center;
`;

const RigntControlUnit = styled.span`
  padding: 0 12px 0 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  color: ${colors.Gr04};
  display: inline-block;
`;

const RigntControlButton = styled.span`
  padding: 6px 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: inline-block;
  cursor: pointer;
  border-radius: 4px;
  background-color: ${({ status }) => (status ? colors.Bl02 : colors.Gr01)};
  color: ${({ status }) => (status ? colors.Bl07 : colors.Gr03)};
`;

const CapacityText = styled.div`
  color: ${({ status }) => (status == "empty" ? colors.Red03 : colors.Gr04)};
  line-height: 142%;
  letter-spacing: -0.02em;
  font-weight: 500;
  white-space: nowrap;
  height: 40.83px;
  display: flex;
  align-items: center;
  width: ${({ width }) => (width ? width : "calc(1rem * 84 / 14)")};
`;

const DropDownContainer = styled.div`
  position: relative;
  cursor: pointer;
  width: ${({ width }) => (width ? width : "100%")};
`;

const DropDown = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.2px solid ${colors.Gr02};
  padding: calc(1rem * (9 / 14)) 0;
  /* width: ${W / 2 - (34 + 42)}px; */
`;

const CapacityNumber = styled.div`
  font-weight: 500;
  font-size: calc(1rem * 13 / 14);
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
`;

const SelectIcon = styled.img`
  width: calc(1rem * 17 / 14);
  height: calc(1rem * 17 / 14);
  margin-left: 4px;
  transform: ${({ open }) => (open ? "rotate(-180deg)" : "")};
  transition: all 0.75s;
`;

const DropMenu = styled.div`
  border: 1.2px solid ${colors.Gr02};
  width: 100%;
  /* width: ${W / 2 - (34 + 42)}px; */
  z-index: 3;
  position: absolute;
  background-color: ${colors.White};
  max-height: 200px;
  overflow: auto;
  margin-top: 8px;

  @keyframes dropdown {
    0% {
      transform: translateY(-16px);
    }
    100% {
      transform: translateY(0);
    }
  }

  animation: dropdown 0.5s ease;
`;

const MenuItem = styled.div`
  padding: 10px 0;
  border-bottom: 1.2px solid ${colors.Gr02};
  text-align: center;
  font-weight: 500;
  font-size: calc(1rem * 13 / 14);
  line-height: 142%;
  letter-spacing: -0.02em;

  ${({ el }) => el == 20 && `border-bottom: 0px`};
`;

const TextInputDiv = styled.div`
  border: 1px solid ${({ red }) => (red ? colors.Red03 : colors.Gr02)};
  /* width: fit-content; */
  width: 100%;
`;

const TextInput = styled.input`
  padding: calc(1rem * (9 / 14)) 14px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  width: 100%;

  &::placeholder {
    color: ${colors.Gr03};
  }
`;

const TextInputChange = styled.input`
  padding: 9px 14px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 142%;
  letter-spacing: -0.02em;
  width: 100%;
  background-color: ${({ status }) => (status ? colors.White : colors.Gr01)};
  color: ${({ status }) => (status ? colors.Gr06 : colors.Gr06)};

  &::placeholder {
    color: ${colors.Gr03};
  }
`;

const PriceUnit = styled.div`
  font-weight: 500;
  font-size: 1rem;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  margin-left: 12px;
`;

const TimeText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  padding: calc(1rem * 9 / 14) 0;
  margin-right: 8px;
  background-color: ${colors.Gr01};
  white-space: nowrap;
  font-weight: 500;
  font-size: calc(1rem * 13 / 14);
  line-height: 142%;
  color: ${colors.Gr05};
  border: 1.2px solid ${colors.Gr02};
`;
