import styled from "styled-components";

import flexbox from "../../../../styles/func/flexbox";
import colors from "../../../../styles/constants/colors";
import transition from "../../../../styles/func/transition";

export const Main = styled.main`
  width: 100%;
  height: 100%;
  padding: 0 20px;
  margin-top: 20px;
  overflow-y: scroll;
`;

export const Toolbar = styled.div`
  width: 100%;
  background-color: #f7f9fc;
  border-radius: 16px;
  padding: 10px 20px;
  ${flexbox("flex-start")}
`;

export const SelectBox = styled.div`
  position: relative;
  width: 165px;

  select {
    width: 100%;
    margin-right: 20px;
    background-color: transparent;
    border: none;
  }

  img {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  select:focus {
    border: none;
  }
`;

export const BoldButton = styled.button`
  font-weight: 800;
  padding: 10px 20px;
  margin-right: 10px;
  border: 1px solid ${({ active }) => (active ? colors.gray : "transparent")};
  border-radius: 16px;
  transition: ${transition()};
`;

export const ItalicButton = styled.button`
  font-style: italic;
  padding: 10px 20px;
  margin-right: 10px;
  border: 1px solid ${({ active }) => (active ? colors.gray : "transparent")};
  border-radius: 16px;
  transition: ${transition()};
`;

export const UnderlineButton = styled.button`
  text-decoration: underline;
  padding: 10px 20px;
  border: 1px solid ${({ active }) => (active ? colors.gray : "transparent")};
  border-radius: 16px;
  transition: ${transition()};
`;

export const ColorInputBox = styled.div`
  position: relative;
  width: 50px;
  ${flexbox()}
  margin-right: 10px;

  input {
    position: absolute;
    top: -5px;
    right: 5px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    height: 15px;
    padding: 0;
    width: 11px;

    &:focus {
      border: none;
    }
  }

  div {
    cursor: pointer;

    span {
    }
  }
`;

export const BackgroundColorInputBox = styled.div`
  position: relative;
  width: 50px;
  ${flexbox()}
  margin-right: 20px;

  input {
    position: absolute;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    height: 30px;
    padding: 0;
    width: 27px;
    opacity: 0.2;

    &:focus {
      border: none;
    }
  }

  div {
    cursor: pointer;
  }
`;

export const WhitespaceButton = styled.button`
  padding: 10px 20px;
  margin-right: 10px;
  border: 1px solid ${({ active }) => (active ? colors.gray : "transparent")};
  border-radius: 16px;
  transition: ${transition()};
`;

export const Preview = styled.div`
  width: 100%;
  height: 400px;
  border: 1px solid ${colors.gray};
  border-radius: 16px;
  padding: 20px 100px;
  overflow-y: scroll;
  margin-top: 20px;
`;

export const InputBox = styled.div`
  width: 100%;
  margin-top: 20px;
  ${flexbox("flex-start")}
`;

export const TextBox = styled.div`
  width: 100%;
  height: 150px;
  display: flex;

  button {
    white-space: nowrap;
    color: ${colors.white};
    background-color: ${colors.blue};
    height: 100%;
    padding: 0 20px;
    border-radius: 16px;
    margin-left: 20px;
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  border: 1px solid ${colors.gray};
  border-radius: 16px;
  resize: none;
  padding: 20px;
`;

export const Footer = styled.footer`
  width: 100%;
  padding: 20px;
  ${flexbox("flex-end")}
  position: absolute;
  bottom: 0px;
  background-color: ${colors.white};

  button:first-child,
  button:nth-child(2) {
    /* margin-right: 10px; */
  }

  /* button:first-child {
    background-color: ${colors.white};
    color: ${colors.gray};
  } */
`;
