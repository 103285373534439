import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import KakaoProvider from "./context/kakao";
import GlobalStyles from "./styles/globalStyles";
import { ConfigProvider } from "antd";
import koKR from "antd/lib/locale/ko_KR";
import dayjs from "dayjs";
import "dayjs/locale/ko";
dayjs.locale("ko");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <KakaoProvider>
    <GlobalStyles />
    <ConfigProvider locale={koKR}>
      <App />
    </ConfigProvider>
  </KakaoProvider>
);
