import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";

import useInput from "../../hooks/useInput";

import DropDown from "../../components/designedComponents/dropDown";
import ToggleButton from "../../modules/toggleButton";
import DesignedMainHeader from "../../components/designedHeader";
import DesignedMainLayout from "../../layouts/designedMainLayout";
import DrawerModal from "../../components/drawerModal";
import Table from "../../layouts/table";
import {
  TableFilterContainer,
  TableFilterContent,
  TableWrapper,
  Wrapper,
  AbsoluteWrapper,
  Icon,
  Text,
  TableContent,
  TableFooter,
  TableColumn,
  TableFooterPaging,
} from "../../layouts/layoutStorage";

// icon
import SearchIcon from "../../assets/icon/search.png";
import DoublePaging from "../../assets/icon/double_paging.png";
import OnePaging from "../../assets/icon/one_paging.png";
import ReviewDetail from "../../components/designedReview/reviewDetail";
import colors from "../../styles/constants/colors";

const Review = () => {
  const [Height, setHeight] = useState({
    TableFilter: 0,
    TableHeader: 0,
    TableFooter: 0,
    Layout: 0,
    LayoutHeader: 0,
  });

  const [selected, setSelected] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const Layout = document.getElementById("Layout")?.offsetHeight - 36;
      const LayoutHeader = document.getElementById("LayoutHeader")?.offsetHeight + 65;
      const TableFilter = document.getElementById("TableFilter")?.offsetHeight + 19;
      const TableHeader = document.getElementById("TableHeader")?.offsetHeight;
      const TableFooter = document.getElementById("TableFooter")?.offsetHeight;

      setHeight({
        TableFilter: TableFilter,
        TableHeader: TableHeader,
        TableFooter: TableFooter,
        Layout: Layout,
        LayoutHeader: LayoutHeader,
      });
    }, 500);
  }, []);

  useEffect(() => {
    if (!open) {
      setSelected(0);
    }
  }, [open]);

  const { num, t, o, s, e } = useParams();

  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState({
    start: s !== "all" ? s : "",
    end: e !== "all" ? e : "",
  });

  const [search, setSearch] = useState(t !== "all" ? t : "");
  const [status, setStatus] = useState(o !== "all" ? o : "all");

  const onChangeHandler = (type, value) => {
    setSearchValue({
      ...searchValue,
      [type]: value,
    });
  };

  const [filter, setFilter] = useState({
    searchType: "작성자",
    dateType: "",
  });

  const [page, setPage] = useState(num);
  const [reviewData, setReviewData] = useState([]);
  const [reviewCount, setReviewCount] = useState(0);

  let dateValid = /[0-9]{4}.(0?[1-9]|1[012]).(0?[1-9]|[12][0-9]|3[01])/;

  useEffect(() => {
    loadData();
  }, [num, t, o, s, e, filter]);
  //

  useEffect(() => {
    pageNavi(1);
  }, [status]);

  // 페이지 전환 함수
  const prevHandler = () => {
    if (page > 1) {
      pageNavi(Number(page) - 1);
    } else {
      alert("첫 페이지입니다.");
    }
  };

  const nextHandler = () => {
    if (page < numPages) {
      pageNavi(Number(page) + 1);
    } else {
      alert("마지막 페이지입니다.");
    }
  };

  const PageChange = (number) => {
    if (number < numPages + 1 && number > 0) {
      pageNavi(number);
    }
  };

  const TextSearch = (e) => {
    if (e.key === "Enter") {
      pageNavi(1);
    }
  };

  const pageNavi = (number) => {
    navigate(
      `/review_list/${number}/${search ? search : "all"}/${status ? status : "all"}/${searchValue.start ? searchValue.start : "all"}/${
        searchValue.end ? searchValue.end : "all"
      }`
    );
  };

  const loadData = () => {
    var token = window.localStorage.getItem("token");
    const frm = new FormData();
    frm.append("mode", "list");
    frm.append("token", token);
    frm.append("page", num);
    frm.append("text", search);
    frm.append("search_type", filter.searchType);
    frm.append("status", status);
    if (dateValid.test(searchValue.start) && dateValid.test(searchValue.end)) {
      frm.append("start_date", searchValue.start);
      frm.append("end_date", searchValue.end);
      frm.append("date_type", filter.dateType ? filter.dateType : "리뷰 등록일");
    }

    axios
      .post("https://ai.semos.kr/semos_admin/review/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        let Value = data.data;
        let axiosStatus = Value.status;
        let statusMessage = Value.status_message;

        if (axiosStatus == "success") {
          setReviewData(Value.review_list);
          setReviewCount(Value.customer_count);
        } else if (axiosStatus == "waring") {
          alert(statusMessage);
        }
      });
  };

  // 하단 페이지 버튼
  const numPages = Math.ceil(reviewCount / 12);

  let PageList = [];

  if (Number(page) < 11) {
    for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
      PageList.push(i + 1);
    }
  } else {
    if (numPages - 10 < Number(page)) {
      var startPage = numPages - 19;
      if (startPage < 1) {
        startPage = 1;
      }

      for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    } else {
      var startPage = Number(page) - 9;
      if (startPage < 1) {
        startPage = 1;
      }

      for (var i = 0; i < (numPages > 19 ? 20 : numPages); i++) {
        PageList.push(startPage + i);
      }
    }
  }

  // 디테일 페이지
  const showDetail = (id) => {
    // setSelected(id);
    // setOpen(true);
    window.open(`/review_detail/${id}`, "_blank");
  };

  const searchFilterCase = (filter) => {
    switch (filter) {
      case "작성자":
        return "작성자 ID";

      case "파트너":
        return "파트너 ID";

      case "액티비티 명":
        return "액티비티 명";
    }
  };

  const statusCase = (status) => {
    switch (status) {
      case "all":
        return "전체";

      case "노출":
        return "노출";

      case "비노출":
        return "비노출";
    }
  };

  // 상태 토글버튼
  const statusToggleHandler = (id, number) => {
    var token = window.localStorage.getItem("token");
    var status = reviewData[number].status;

    const frm = new FormData();
    frm.append("mode", "status_change");
    frm.append("token", token);
    frm.append("id", id);
    frm.append("status", status);
    axios
      .post("https://ai.semos.kr/semos_admin/review/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var status = Value.status;
        var statusMessage = Value.status_message;

        if (status == "success") {
          var copy = [...reviewData];
          if (Number(status)) {
            copy[number].status = "0";
          } else {
            copy[number].status = "1";
          }
          alert(statusMessage);
          setReviewData(copy);
        } else if (status == "waring") {
          alert(statusMessage);
        }
      });
  };

  const statusConvert = (status) => {
    switch (status) {
      case "1":
        return <div style={{ color: "#0e6dff" }}>노출</div>;
      break;

      case "0":
        return <div style={{ color: "#acafb5" }}>비노출</div>;
      break;
    }
  };

  const searchTextInput = (value) => {
    var copy = value;
    var regExp = /[`~!@#$%^&*()_|+\-=?;:'"<>\{\}\\\\/]/gim;

    if (regExp.test(copy)) {
      alert("특수문자는 입력하실수 없습니다.");
      copy = copy.replace(regExp, "");
    }

    setSearch(copy);
  };

  return (
    <>
      <DesignedMainLayout>
        
        <DesignedMainHeader title="리뷰 관리" subtitle={`전체 리뷰 : ${reviewCount}개 (현재 리스트)`} />

        <Table>

          <TableFilterContainer id="TableFilter" margin={`0 0 19px 0`}>

            <Wrapper>

              <Text className="f13" margin="0 10px 0 0" ws="pre">
                정렬
              </Text>

              <DropDown
                className="f14"
                data={searchFilterCase(filter.searchType)}
                setData={setFilter}
                option={[
                  { text: "작성자 ID", value: "작성자" },
                  { text: "파트너 ID", value: "파트너" },
                  { text: "액티비티 명", value: "액티비티 명" },
                ]}
                type={`object`}
                defaultText="작성자 ID"
                no={false}
                keyName={`searchType`}
              />

              <TableFilterContent margin={`0 24px 0 0`}>
                <Input
                  className="f14"
                  width={`calc(1rem* 25)`}
                  type={`text`}
                  maxLength={40}
                  name="search"
                  value={search}
                  onKeyUp={TextSearch}
                  onChange={(e) => searchTextInput(e.target.value)}
                  placeholder="검색어를 입력해 주세요"
                />
                <Icon width={`calc(1rem * (17 / 14))`} cursor={`pointer`} src={SearchIcon} onClick={() => pageNavi(1)} />
              </TableFilterContent>

            </Wrapper>

            <Wrapper width={`50%`} jc={`flex-end`}>

              <DropDown
                data={statusCase(status)}
                setData={setStatus}
                option={[
                  { text: "전체", value: "all" },
                  { text: "노출", value: "노출" },
                  { text: "비노출", value: "비노출" },
                ]}
                // type={`object`}
                defaultText="전체"
                no={false}
                // keyName={`status`}
              />

              <DropDown
                data={filter.dateType}
                setData={setFilter}
                option={[
                  { text: "리뷰 등록일", value: "리뷰 등록일" },
                  { text: "리뷰 수정일", value: "리뷰 수정일" },
                ]}
                type={`object`}
                defaultText="리뷰 등록일"
                no={false}
                keyName={`dateType`}
              />

              <TableFilterContent margin={`0 12px 0 0`}>
                <Input
                  className="f14"
                  width={`calc(1rem* 9)`}
                  type={`text`}
                  maxLength={10}
                  name="start"
                  value={searchValue.start}
                  onKeyUp={(e) => {
                    if (e.key === "Enter" && searchValue.start.value) {
                      pageNavi(1);
                    }
                  }}
                  onChange={(e) => onChangeHandler("start", e.target.value)}
                  placeholder="연도.월.일 입력하기"
                />
              </TableFilterContent>

              <TableFilterContent margin={`0 12px 0 0`}>
                <Input
                  className="f14"
                  width={`calc(1rem* 9)`}
                  type={`text`}
                  maxLength={10}
                  name="end"
                  value={searchValue.end}
                  //   onKeyUp={TextSearch}
                  onChange={(e) => onChangeHandler("end", e.target.value)}
                  placeholder="연도.월.일 입력하기"
                />
              </TableFilterContent>

              <Wrapper cursor="pointer">
                <Text color="#3383FF" onClick={() => pageNavi(1)}>
                  보기
                </Text>
              </Wrapper>

            </Wrapper>

          </TableFilterContainer>

          <TableContent height={Height.Layout - (Height.LayoutHeader + Height.TableFilter + Height.TableFooter)} contentHeight={Height.TableHeader}>

            <section id="TableHeader">

              <TableColumn width={`6`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                주문번호
              </TableColumn>

              <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                액티비티 명
              </TableColumn>

              <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                파트너 ID
              </TableColumn>

              <TableColumn width={`5`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                평점
              </TableColumn>

              <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                작성자 ID
              </TableColumn>

              <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                리뷰 내용
              </TableColumn>

              <TableColumn width={`13`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                리뷰 등록일
              </TableColumn>

              <TableColumn width={`7`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                상태
              </TableColumn>

              <TableColumn width={`13`} weight={`500`} br={`1px solid transparent`}>
                리뷰 수정일
              </TableColumn>

            </section>

            <section>
              {reviewData
                ? reviewData.map((item, idx) => (
                    <Link to={`/review_detail/${item.id}`}>
                      <TableWrapper
                        cursor={`pointer`}
                        key={`${idx}_reservation`}
                        jc={`flex-start`}
                        bgc={selected == item.id ? "#F2F7FF" : "white"}
                        bl={`1px solid transparent`}
                        br={`1px solid transparent`}
                        bb={`1px solid ${colors.Gr02}`}
                        bt={`1px solid transparent`}
                        width={`100%`}
                      >
                        <TableColumn width={`6`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                          {item.pay_id}
                        </TableColumn>

                        <TableColumn width={`20`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                          {item.activity}
                        </TableColumn>

                        <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                          {/* {item.OID ? item.OID : "test"} */}
                          {item.partner}
                        </TableColumn>

                        <TableColumn width={`5`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                          {item.rating}
                        </TableColumn>

                        <TableColumn width={`10`} weight={`500`} br={`1px solid ${colors.Gr02}`}>
                          {/* {PhoneHyphen(item.phone)} */}
                          {item.user}
                        </TableColumn>

                        <TableColumn
                          width={`20`}
                          weight={`500`}
                          // padding={"8px 16px 8px 10px"}
                          br={`1px solid ${colors.Gr02}`}
                        >
                          {item.content}
                        </TableColumn>

                        <TableColumn
                          width={`13`}
                          weight={`500`}
                          // padding={"8px 16px 8px 10px"}
                          br={`1px solid ${colors.Gr02}`}
                        >
                          {item.created}
                        </TableColumn>

                        <TableColumn
                          width={`7`}
                          weight={`500`}
                          padding={"7px 16px"}
                          br={`1px solid ${colors.Gr02}`}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {statusConvert(item.status)}
                          {/* <ToggleButton
                          toggleHandler={() =>
                            statusToggleHandler(item.id, idx)
                          }
                          status={item.status}
                          type="review"
                        /> */}
                          {/* {item.status ? StatusComponent(item.status) : "test"} */}
                        </TableColumn>

                        <TableColumn
                          width={`13`}
                          weight={`500`}
                          // padding={"8px 16px 8px 10px"}
                          br={`1px solid transparent`}
                        >
                          {item.updated}
                        </TableColumn>
                      </TableWrapper>
                    </Link>
                  ))
                : ""}

              <TableFooter id="TableFooter">

                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  src={DoublePaging}
                  onClick={() => {
                    if (Number(page) < 11) {
                      PageChange(1);
                    } else {
                      PageChange(Number(page) - 10);
                    }
                  }}
                />

                <Icon margin={`0 16px 0 0`} width={`calc(1rem * (20 / 14))`} cursor={`pointer`} src={OnePaging} onClick={prevHandler} />

                {PageList.length
                  ? PageList.map((item, idx) => (
                      <TableFooterPaging
                        key={`paging_number_${idx}`}
                        margin={`0 16px 0 0`}
                        color={`${num == item ? "#3383FF" : "#ACAFB5"}`}
                        onClick={() => PageChange(item)}
                      >
                        {item}
                      </TableFooterPaging>
                    ))
                  : ""}

                <Icon
                  margin={`0 16px 0 0`}
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  transform={`rotate(180deg)`}
                  src={OnePaging}
                  onClick={nextHandler}
                />

                <Icon
                  width={`calc(1rem * (20 / 14))`}
                  cursor={`pointer`}
                  transform={`rotate(180deg)`}
                  src={DoublePaging}
                  onClick={() => {
                    if (Number(page) + 10 > numPages) {
                      PageChange(numPages);
                    } else {
                      PageChange(Number(page) + 10);
                    }
                  }}
                />

              </TableFooter>

            </section>

          </TableContent>
        </Table>
      </DesignedMainLayout>

      {/* <DrawerModal open={sendOpen} setOpen={setSendOpen}>
        {selected.id && selected.number && (
          <ReviewDetail selected={selected} />
        )}
      </DrawerModal> */}
      {/* <DrawerModal open={open} setOpen={setOpen}>
        {selected && <ReviewDetail selected={selected} />}
      </DrawerModal> */}
    </>
  );
};

export default Review;

const Input = styled.input`
  width: ${({ width }) => `${width}`};
  display: inline-block;
`;
