import styled from "styled-components";
import base from "../styles/constants/base";
import colors from "../styles/constants/colors";

const Box = styled.div`
  position: relative;
  width: ${({ width }) => width};

  textarea {
    width: 100%;
    min-height: 270px;
    background-color: ${({ color }) =>
      color ? colors.lightBlue : colors.white};
    padding: 20px;
    border-radius: ${base.borderRadius.default}px;
    border: 2px solid ${colors.lightGray};
    resize: none;

    &:focus {
      border-radius: ${base.borderRadius.default}px;
      border: 2px solid ${colors.blue};
    }
  }
`;

const Textarea = ({
  color = null,
  placeholder,
  name,
  value,
  changeHandler,
  width = "100%",
}) => {
  return (
    <Box width={width} color={color}>
      <textarea
        name={name}
        value={value}
        onChange={changeHandler}
        placeholder={placeholder}
        maxLength="900"
      ></textarea>
    </Box>
  );
};

export default Textarea;
