import styled from "styled-components";
import colors from "../styles/constants/colors";
import typography from "../styles/constants/typography";

let StatusTypeOn = {
  banner: "on",
  partner: "open",
  lesson: "yes",
  activity: "yes",
  center: "1",
  review: "1",
  rest: "1",
  main_banner: "1",
  bulletin: "1",
  status: "1",
  calculate: "1",
};

let StatusTypeOff = {
  banner: "off",
  partner: "close_",
  lesson: "close",
  activity: "close",
  center: "2",
  review: "0",
  rest: "0",
  main_banner: "0",
  bulletin: "0",
  status: "0",
  calculate: "0",
};

const Box = styled.div`
  width: calc(1rem * 48 / 14);
  height: calc(1rem * 24 / 14);
  background-color: ${({ status, type }) => (status !== StatusTypeOn[type] ? "#DCDCDC" : "#0e6dff")};
  border: 2px solid ${({ status, type }) => (status !== StatusTypeOn[type] ? "#DCDCDC" : "#0e6dff")};
  color: ${colors.White};
  border-radius: 40px;
  font-size: ${typography.size.tiny}px;
  display: flex;

  button {
    position: absolute;
    width: calc(1rem * 18 / 14);
    height: calc(1rem * 18 / 14);
    color: ${colors.White};
    background-color: ${colors.White};
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const OpenButton = styled.button`
  right: 2px;
`;

const CloseButton = styled.button`
  left: 2px;
`;

const ToggleButton = ({ type, status, toggleHandler }) => {
  return (
    <Box onClick={toggleHandler} status={status} type={type}>
      <Wrapper>
        {status == StatusTypeOn[type] && <OpenButton />}

        {status == StatusTypeOff[type] && <CloseButton />}
      </Wrapper>
    </Box>
  );
};

export default ToggleButton;
