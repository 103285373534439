import { useNavigate, useParams } from "react-router-dom";
import BannerRowCard from "../../../components/banner/bannerRowCard";
import Carousel from "../../../components/carousel";
import MainHeader from "../../../components/header";
import Pagination from "../../../components/pagination";
import bannerList from "../../../data/banner";
import useList from "../../../hooks/useList";
import usePagination from "../../../hooks/usePagination";
import Index from "../../../layouts";
import MainLayout from "../../../layouts/mainLayout";
import { BannerSection, Footer, Main, EditButtonBox } from "./style";

import { useEffect, useState } from "react";
import base from "../../../styles/constants/base";
import flexbox from "../../../styles/func/flexbox";
import typography from "../../../styles/constants/typography";
import colors from "../../../styles/constants/colors";
import styled from "styled-components";
import axios from "axios";


// pagination
const Div = styled.div`
  width: 100%;
  height: 100%;
  ${flexbox("space-between")};
  font-weight: ${typography.weight.regular};
`;

const SectionButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: ${colors.white};
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};

  &:focus {
    box-shadow: ${base.boxShadow.default};
  }
`;

const List = styled.ul`
  ${flexbox()}
`;

const PageButton = styled.li`
  width: 40px;
  height: 40px;
  border: ${({ active }) => (active ? `2px solid ${colors.blue}` : null)};
  color: ${({ active }) => (active ? colors.blue : null)};
  ${flexbox()};
  border-radius: 50%;
  box-shadow: ${base.boxShadow.default};
  margin: 0 5px;
  cursor: pointer;
`;


const BannerPage = () => {
  const { num } = useParams();
  const navigate = useNavigate();
  const numCheck = num > 1 ;

  const bannerData = bannerList;

  const { list, editHandler } = useList(bannerData);

  const { offset, limit, currentPage, setCurrentPage } = usePagination(list, 6);

  const filteredOrder = list.filter((item) => item.order !== 0);

  const sortedBanner = filteredOrder.sort((a, b) => a < b);

  const goToRegisterHandler = () => {
    navigate("/banner/register");
  };

  const goToEditHandler = () => {
    navigate("/banner/edit");
  };

  const statusToggleHandler = (number) => {
    
    var bannerData = [...banner];
    var bannerStatus = bannerData[number].status
    var bannerNumber = bannerData[number].id

    if(bannerStatus == 'off') {
      bannerData[number].status = 'on'
    } else if(bannerStatus == 'on') {
      bannerData[number].status = 'off'
    }

    var token = window.localStorage.getItem('token')
  
    const frm = new FormData();
    frm.append("mode", "bannerOnOff");
    frm.append("token", token);
    frm.append("number", bannerNumber);

      axios.post("https://ai.semos.kr/semos_admin/notice/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then((data) => {
        var Value = data.data;
        var status = Value.status
        var statusMessage = Value.status_message

        if(status == 'success') {
          alert(statusMessage)
          setBanner(bannerData)
        } else if(status == 'waring') {
          alert(statusMessage)
        } else if(status == 'fail') {
          alert(statusMessage)
        }

      });


  };

      const [banner, setBanner] = useState("");
      const [bannerImg, setBannerImg] = useState("");
      const [bannerCount, setBannerCount] = useState(0);
      const [page, setPage] = useState(num);

      // FAQ 리스트 로드
      useEffect(() => {
    
        var token = window.localStorage.getItem('token')
  
        const frm = new FormData();
        frm.append("mode", numCheck ? "banner_update" : "banner");
        frm.append("token", token);
        frm.append("page", num);
  
          axios.post("https://ai.semos.kr/semos_admin/notice/data", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          }).then((data) => {
            var Value = data.data;
            var status = Value.status
            var statusMessage = Value.status_message
  
            if(status == 'success') {
  
              setBanner(Value.banner_list)
              setBannerCount(Value.banner_count)
              setBannerImg(Value.banner_img_list)
              setPage(num)
              navigate(`/banner_list/${num}`,  { replace: true})

            } else if(status == 'waring') {
              alert(statusMessage)
            }
  
          });
  
    }, []);


  const prevHandler = () => {
    if (page > 1) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "banner_update");
      frm.append("token", token);
      frm.append("page", page - 1);

        axios.post("https://ai.semos.kr/semos_admin/notice/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setBanner(Value.banner_list)
            setBannerCount(Value.banner_count)
            setPage(page - 1)
            navigate(`/banner_list/${page - 1}`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    } else {
      alert('첫 페이지입니다.');
    }
  };

  const nextHandler = () => {
    if (page < numPages) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "banner_update");
      frm.append("token", token);
      frm.append("page", page + 1);

        axios.post("https://ai.semos.kr/semos_admin/notice/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setBanner(Value.banner_list)
            setBannerCount(Value.banner_count)
            navigate(`/banner_list/${page + 1}`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    } else {
      alert('마지막 페이지입니다.');
    }
  };

  const PageChange = (number) => {
    if ( number < (numPages + 1) && number > 0 ) {
      var token = window.localStorage.getItem('token')

      const frm = new FormData();
      frm.append("mode", "banner_update");
      frm.append("token", token);
      frm.append("page", number);

        axios.post("https://ai.semos.kr/semos_admin/notice/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message

          if(status == 'success') {
            setBanner(Value.banner_list)
            setBannerCount(Value.banner_count)
            setPage(number)
            navigate(`/banner_list/${number}`,  { replace: true})
          } else if(status == 'waring') {
            alert(statusMessage)
          }

        });
    }
  };

  // 페이지 갯수 제한
  const numPages = Math.ceil(bannerCount / 6);
  let PageList = [];
  
  if(page < 6) {
    //PageList = [1,2,3,4,5,6,7,8,9,10];
    for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
      PageList.push(i+1);
    }

  } else {
    if( (numPages - 5) < page ) {
      var startPage = numPages - 9;
      if(startPage < 1) {
        startPage = 1;
      }

      for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
        PageList.push(startPage + i);
      }

    } else {
      var startPage = page - 4;
      if(startPage < 1) {
        startPage = 1;
      }
      
      for(var i=0; i < (numPages > 9 ? 10 : numPages); i++) {
        PageList.push(startPage + i);
      }
    }
  }

  return (
    <MainLayout heightType={true} button={{ type: "plus", clickHandler: goToRegisterHandler }}>
      <BannerSection>
        <p>배너(768*248/5MB 이하/JPG)</p>

        <Carousel imageList={bannerImg} />
      </BannerSection>

      <MainHeader title="배너 리스트">
        <EditButtonBox>
          <button type="button" onClick={goToEditHandler}>
            수정하기
          </button>
        </EditButtonBox>
      </MainHeader>

      <Main>
        <Index
          type="banner"
          indexList={["NO.", "이름", "링크", "등록일", "순서", "상태"]}
        />
        <ul>
          {banner ? banner.map((item, idx) => (
            <BannerRowCard
              key={idx}
              data={item}
              idx={idx}
              statusToggleHandler={() => statusToggleHandler(idx)}
              status={item.status}
            />
          )) : ""}
        </ul>
      </Main>

      <Footer>
        {/*<Pagination
          total={list.length}
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />*/}

          <Div>
            <SectionButton onClick={prevHandler}>&lt;</SectionButton>

            <List>
              {PageList.length ? PageList.map((item, idx) => 
                ( <PageButton
                    key={idx}
                    onClick={() => PageChange(item)}
                    aria-current={page == item ? "page" : null}
                    active={page == item ? true : false}
                  >
                    {item}
                  </PageButton>
                )) : ""}
            </List>

            <SectionButton onClick={nextHandler}>&gt;</SectionButton>
          </Div>

      </Footer>
    </MainLayout>
  );
};

export default BannerPage;
