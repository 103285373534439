import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import useWidth from "../hooks/useWidth";
import base from "../styles/constants/base";
import { breakpoint } from "../styles/constants/responsive";
import flexbox from "../styles/func/flexbox";
import Header from "./header";
import Navbar from "./navbar";

const Container = styled.div`
  width: 100%;

  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - ${base.height.header}px);

  ${({ islayout }) =>
    islayout
      ? css`
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          /* ${flexbox()} */
        `
      : null}
`;

const Notice = styled.p`
  text-align: center;
`;

const GlobalLayout = ({ children }) => {
  const { width } = useWidth();
  const { pathname } = useLocation();

  return (
    <Container isnav={pathname !== "/" ? true : false} islayout={pathname.includes("dashboard") || pathname.includes("partner_list") ? false : true}>
      {pathname !== "/" && (
        <>
          <Header />
          <Navbar />
        </>
      )}

      {/*width < breakpoint.lg && <Notice>데스크탑을 지원합니다😍</Notice>*/}
      {/*width >= breakpoint.lg && <>{children}</>*/}
      {children}
    </Container>
  );
};

export default GlobalLayout;
