const salesList = [
  {
    id: 109,
    number: 9381,
    buyer: "고크리스틴",
    lesson: "[프리다이빙] 크루들과 함께하는 원데이...",
    price: "99,000",
    date: "2022-03-25",
    status: "check",
  },
  {
    id: 109,
    number: 9381,
    buyer: "고크리스틴",
    lesson: "[프리다이빙] 크루들과 함께하는 원데이...",
    price: "99,000",
    date: "2022-03-25",
    status: "check",
  },
  {
    id: 109,
    number: 9381,
    buyer: "고크리스틴",
    lesson: "[프리다이빙] 크루들과 함께하는 원데이...",
    price: "99,000",
    date: "2022-03-25",
    status: "check",
  },
  {
    id: 109,
    number: 9381,
    buyer: "고크리스틴",
    lesson: "[프리다이빙] 크루들과 함께하는 원데이...",
    price: "99,000",
    date: "2022-03-25",
    status: "fix",
  },
  {
    id: 109,
    number: 9381,
    buyer: "고크리스틴",
    lesson: "[프리다이빙] 크루들과 함께하는 원데이...",
    price: "99,000",
    date: "2022-03-25",
    status: "fix",
  },
  {
    id: 109,
    number: 9381,
    buyer: "고크리스틴",
    lesson: "[프리다이빙] 크루들과 함께하는 원데이...",
    price: "99,000",
    date: "2022-03-25",
    status: "check",
  },
  {
    id: 109,
    number: 9381,
    buyer: "고크리스틴",
    lesson: "[프리다이빙] 크루들과 함께하는 원데이...",
    price: "99,000",
    date: "2022-03-25",
    status: "cancel",
  },
  {
    id: 109,
    number: 9381,
    buyer: "고크리스틴",
    lesson: "[프리다이빙] 크루들과 함께하는 원데이...",
    price: "99,000",
    date: "2022-03-25",
    status: "fix",
  },
  {
    id: 109,
    number: 9381,
    buyer: "고크리스틴",
    lesson: "[프리다이빙] 크루들과 함께하는 원데이...",
    price: "99,000",
    date: "2022-03-25",
    status: "fix",
  },
  {
    id: 109,
    number: 9381,
    buyer: "고크리스틴",
    lesson: "[프리다이빙] 크루들과 함께하는 원데이...",
    price: "99,000",
    date: "2022-03-25",
    status: "check",
  },
  {
    id: 109,
    number: 9381,
    buyer: "고크리스틴",
    lesson: "[프리다이빙] 크루들과 함께하는 원데이...",
    price: "99,000",
    date: "2022-03-25",
    status: "cancel",
  },
  {
    id: 109,
    number: 9381,
    buyer: "고크리스틴",
    lesson: "[프리다이빙] 크루들과 함께하는 원데이...",
    price: "99,000",
    date: "2022-03-25",
    status: "fix",
  },
  {
    id: 109,
    number: 9381,
    buyer: "고크리스틴",
    lesson: "[프리다이빙] 크루들과 함께하는 원데이...",
    price: "99,000",
    date: "2022-03-25",
    status: "fix",
  },
  {
    id: 109,
    number: 9381,
    buyer: "고크리스틴",
    lesson: "[프리다이빙] 크루들과 함께하는 원데이...",
    price: "99,000",
    date: "2022-03-25",
    status: "check",
  },
  {
    id: 109,
    number: 9381,
    buyer: "고크리스틴",
    lesson: "[프리다이빙] 크루들과 함께하는 원데이...",
    price: "99,000",
    date: "2022-03-25",
    status: "cancel",
  },
];

export default salesList;
