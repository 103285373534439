import React, { useState, useEffect } from "react";
import styled from "styled-components";
import colors from "../../styles/constants/colors";

import Arrow from "../../assets/icon/selectArrow.png";

const W = window.innerWidth;

const ActivityPriceInfo = ({ open: detailOpen, data, setData }) => {
  const [open, setOpen] = useState({
    min: false,
    max: false,
  });

  const [range, setRange] = useState({
    min: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ],
    max: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ],
  });

  const localeStringMaker = (value) => {
    if (value != 0) {
      var copy = value?.replace(/\D/g, "");
      copy = copy?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      var copy = 0;
    }

    return copy;
  };

  //가격 수정
  const priceChange = (value, idx, type) => {
    let copy = [...data.price];
    var copyV = value;
    var str = "";
    copyV = copyV.split(",");
    for (let i = 0; i < copyV.length; i++) {
      str = str + copyV[i];
    }

    if (type == "event") {
      copy[idx].event_price = str;
    } else if(type == "weekend") {
      copy[idx].weekend_price = str;
    } else {
      copy[idx].price = str;

      var discount = data.discount;
      var discountPercent = 0;

      if (discount > 99) {
        discount = 99;
      } else if (discount < 0) {
        discount = "";
      } else if (discount == 0) {
        discount = 0;
      }

      if (discount != 0) {
        discountPercent = 1 - discount / 100;
      } else {
        discountPercent = 1;
      }

      if (str != 0) {
        var thisDiscountPrice = Math.round(str * discountPercent);
      } else {
        var thisDiscountPrice = "0";
      }

      copy[idx]["event_price"] = String(thisDiscountPrice);
    }
    copy[idx].change = "yes";
    setData((prev) => ({ ...prev, price: copy }));
  };

  useEffect(() => {
    var min = data.min_capacity;
    var arr = [];
    for (let i = min; i < 21; i++) {
      arr.push(i);
    }
    setRange((prev) => ({ ...prev, max: arr }));

    if (min > data.max_capacity) {
      setData((prev) => ({ ...prev, max_capacity: min }));
    }
  }, [data.min_capacity]);

  useEffect(() => {
    let PriceList = data.price;
    let PriceValue = {};
    for (let i = 0; i < Number(data.max_capacity); i++) {
      if (!PriceList[i]) {
        PriceList.push({
          id: "new",
          people: String(i + 1),
          price: "",
          change: "yes",
        });
      }
    }
    setData((prev) => ({ ...prev, price: PriceList }));
  }, [data.min_capacity, data.max_capacity, data.price]);

  const capaPick = (el, type) => {
    setData((prev) => ({ ...prev, [type]: el }));
  };

  const SetEvent = (status) => {
    if (status == "0") {
      setData((prev) => ({ ...prev, event: "1" }));
    } else {
      setData((prev) => ({ ...prev, event: "0" }));
    }
  };

  const SetDiscount = (value) => {
    if (value == 0) {
      var copy = 0;
    } else {
      var copy = value?.replace(/\D/g, "");
    }

    if (copy > 99) {
      copy = 99;
    } else if (copy < 0) {
      copy = "";
    } else if (copy == 0) {
      copy = 0;
    }

    setData((prev) => ({ ...prev, discount: copy }));

    var priceCopy = [...data.price];
    var discountPercent = 0;
    if (copy != 0) {
      discountPercent = 1 - copy / 100;
    } else {
      discountPercent = 1;
    }

    var priceKey = Object.keys(priceCopy);

    for (var i = 0; i < priceKey.length; i++) {
      let people = priceKey[i];
      let thisPrice = priceCopy[people]["price"];

      if (thisPrice != 0 && discountPercent != 0) {
        var thisDiscountPrice = Math.round(thisPrice * discountPercent);
        priceCopy[people]["event_price"] = String(thisDiscountPrice);
        priceCopy[people]["change"] = "yes";
      }
    }

    setData((prev) => ({ ...prev, price: priceCopy }));
  };

  return (
    <div id="price">
      <TitleBox>
        <Styler
          pt={36}
          pl={36}
          pb={16}
          pr={36}
          flex={true}
          justify="space-between"
          align="center"
        >
          <Title className="f16">액티비티 가격 정보</Title>
        </Styler>
      </TitleBox>
      <Styler pl={36} pr={36}>
        <Styler flex={true} pb={16}>
          <SmallTitle>예약제한</SmallTitle>
        </Styler>
        <Styler flex={true} justify="space-between">
          <CapacityControlBox>
            <CapacityText>최소</CapacityText>

            <DropDownContainer>
              <DropDown
                onClick={() => setOpen((prev) => ({ ...prev, min: !open.min }))}
              >
                <CapacityNumber>
                  {data.min_capacity
                    ? `${data.min_capacity}인`
                    : "최소 인원 선택"}
                </CapacityNumber>
                <SelectIcon open={open.min} src={Arrow} />
              </DropDown>
              {open.min && (
                <DropMenu open={open}>
                  {range.min.map((el, i) => (
                    <MenuItem
                      key={`${el}_${i}_range`}
                      el={el}
                      onClick={() => {
                        capaPick(el, "min_capacity");
                        setOpen((prev) => ({ ...prev, min: !open.min }));
                      }}
                    >
                      {el}인
                    </MenuItem>
                  ))}
                </DropMenu>
              )}
            </DropDownContainer>
          </CapacityControlBox>
          <div style={{ width: "28px" }} />
          <CapacityControlBox>
            <CapacityText>최대</CapacityText>
            <DropDownContainer>
              <DropDown
                onClick={() => setOpen((prev) => ({ ...prev, max: !open.max }))}
              >
                <CapacityNumber>
                  {data.max_capacity
                    ? `${data.max_capacity}인`
                    : "최대 인원 선택"}
                </CapacityNumber>
                <SelectIcon open={open.max} src={Arrow} />
              </DropDown>
              {open.max && (
                <DropMenu open={open}>
                  {range.max.map((el, i) => (
                    <MenuItem
                      key={`${el}_${i}_maxRange`}
                      el={el}
                      onClick={() => {
                        capaPick(el, "max_capacity");
                        setOpen((prev) => ({ ...prev, max: !open.max }));
                      }}
                    >
                      {el}인
                    </MenuItem>
                  ))}
                </DropMenu>
              )}
            </DropDownContainer>
          </CapacityControlBox>
        </Styler>
        <Styler flex={true} align="center" pt={8}>
          <CapacityText>최소 연령</CapacityText>
          <TextInputDiv>
            <TextInput
              type="tel"
              value={data.min_age}
              placeholder="최소 연령을 입력해 주세요"
              onChange={(e) =>
                setData((prev) => ({ ...prev, min_age: e.target.value }))
              }
            />
          </TextInputDiv>
        </Styler>
        <Styler
          flex={true}
          align={"flex-start"}
          justify={"space-between"}
          pb={14}
          pt={24}
        >
          <SmallTitle>판매금액</SmallTitle>

          <RigntControlContainer>
            <RigntControlTitle>할인율</RigntControlTitle>

            <RigntControlInput
              onChange={(e) => SetDiscount(e.target.value)}
              value={data ? data.discount : "0"}
              placeholder={`00`}
              type="tel"
              maxLength="3"
            />

            <RigntControlUnit>%</RigntControlUnit>

            <RigntControlButton
              onClick={() => SetEvent(data.event)}
              status={data ? data.event == 1 : false}
            >
              {data
                ? data.event == 1
                  ? `ON 적용 중`
                  : `OFF 적용 안됨`
                : `OFF 적용 안됨`}
            </RigntControlButton>
          </RigntControlContainer>
        </Styler>

        <Styler align="center" pb={26}>

        <Styler
                  flex={true}
                  justify="space-between"
                  align="center"
                  pb={10}
                >
                  <TimeText>{`인원`}</TimeText>

                  <Styler
                    width="100%"
                    style={{ marginRight: 8 }}
                    flex={true}
                    align="center"
                  >
                    <TextInputDiv>
                      <TextInput
                        style={{textAlign : "center"}}
                        type="tel"
                        value={'평일 가격'}
                        placeholder=""
                        disabled={true}
                      />
                    </TextInputDiv>
                  </Styler>

                  <Styler
                    width="100%"
                    style={{ marginRight: 8 }}
                    flex={true}
                    align="center"
                  >
                    <TextInputDiv>
                      <TextInput
                        style={{textAlign : "center"}}
                        type="tel"
                        value={'주말 가격'}
                        placeholder="인당 판매금액 작성하기(주말)"
                        disabled={true}
                      />
                    </TextInputDiv>
                  </Styler>

                  <Styler width="100%" flex={true} align="center">
                    <TextInputDiv>
                      <TextInputChange
                        style={{textAlign : "center"}}
                        type="tel"
                        disabled={true}
                        status={true}
                        value={'이벤트 가격'}
                        placeholder=""
                      />
                    </TextInputDiv>
                  </Styler>
                </Styler>


          {data.price?.map(
            (item, index) =>
              (Number(item["people"]) >= Number(data.min_capacity) &&
              Number(item["people"]) <= Number(data.max_capacity)) && (
                <Styler
                  flex={true}
                  justify="space-between"
                  align="center"
                  pb={10}
                >
                  <TimeText>{`${item.people}인`}</TimeText>

                  <Styler
                    width="100%"
                    style={{ marginRight: 8 }}
                    flex={true}
                    align="center"
                  >
                    <TextInputDiv>
                      <TextInput
                        type="tel"
                        value={localeStringMaker(item.price)}
                        placeholder="인당 판매금액 작성하기(평일)"
                        onChange={(e) =>
                          priceChange(e.target.value, index, "standard")
                        }
                      />
                    </TextInputDiv>
                  </Styler>

                  <Styler
                    width="100%"
                    style={{ marginRight: 8 }}
                    flex={true}
                    align="center"
                  >
                    <TextInputDiv>
                      <TextInput
                        type="tel"
                        value={localeStringMaker(item.weekend_price)}
                        placeholder="인당 판매금액 작성하기(주말)"
                        onChange={(e) =>
                          priceChange(e.target.value, index, "weekend")
                        }
                      />
                    </TextInputDiv>
                  </Styler>

                  <Styler width="100%" flex={true} align="center">
                    <TextInputDiv>
                      <TextInputChange
                        type="tel"
                        disabled={
                          data ? (data.event == 1 ? false : true) : false
                        }
                        status={data ? data.event == 1 : false}
                        value={localeStringMaker(item.event_price)}
                        placeholder="할인가"
                        onChange={(e) =>
                          priceChange(e.target.value, index, "event")
                        }
                      />
                    </TextInputDiv>
                  </Styler>
                </Styler>
              )
          )}
        </Styler>
      </Styler>


      {(data.status == '승인 요청') &&
        <>
          <div style={{backgroundColor : colors.Red03, height : 2, width : '100%', marginTop : 20}} />
          <TitleBox>
            <Styler
              pt={36}
              pl={36}
              pb={16}
              pr={36}
              flex={true}
              justify="space-between"
              align="center"
            >
              <Title style={{color : colors.Red03}} className="f16">액티비티 가격 정보 (승인 요청)</Title>
            </Styler>
          </TitleBox>
          <Styler pl={36} pr={36}>
            <Styler flex={true} pb={16}>
              <SmallTitle>예약제한</SmallTitle>
            </Styler>
            <Styler flex={true} justify="space-between">
              <CapacityControlBox>
                <CapacityText>최소</CapacityText>

                <DropDownContainer>
                  <DropDown
                    onClick={() => setOpen((prev) => ({ ...prev, min_copy: !open.min_copy }))}
                  >
                    <CapacityNumber>
                      {data.min_capacity_copy
                        ? `${data.min_capacity_copy}인`
                        : "최소 인원 선택"}
                    </CapacityNumber>
                    <SelectIcon open={open.min_copy} src={Arrow} />
                  </DropDown>
                  {open.min_copy && (
                    <DropMenu open={open}>
                      {range.min_copy.map((el, i) => (
                        <MenuItem
                          key={`${el}_${i}_range_copy`}
                          el={el}
                          onClick={() => {
                            capaPick(el, "min_capacity_copy");
                            setOpen((prev) => ({ ...prev, min_copy: !open.min_copy }));
                          }}
                        >
                          {el}인
                        </MenuItem>
                      ))}
                    </DropMenu>
                  )}
                </DropDownContainer>
              </CapacityControlBox>
              <div style={{ width: "28px" }} />
              <CapacityControlBox>
                <CapacityText>최대</CapacityText>
                <DropDownContainer>
                  <DropDown
                    onClick={() => setOpen((prev) => ({ ...prev, max_copy: !open.max_copy }))}
                  >
                    <CapacityNumber>
                      {data.max_capacity_copy
                        ? `${data.max_capacity_copy}인`
                        : "최대 인원 선택"}
                    </CapacityNumber>
                    <SelectIcon open={open.max_copy} src={Arrow} />
                  </DropDown>
                  {open.max_copy && (
                    <DropMenu open={open}>
                      {range.max_copy.map((el, i) => (
                        <MenuItem
                          key={`${el}_${i}_maxRange_copy`}
                          el={el}
                          onClick={() => {
                            capaPick(el, "max_capacity_copy");
                            setOpen((prev) => ({ ...prev, max_copy: !open.max_copy }));
                          }}
                        >
                          {el}인
                        </MenuItem>
                      ))}
                    </DropMenu>
                  )}
                </DropDownContainer>
              </CapacityControlBox>
            </Styler>
            <Styler flex={true} align="center" pt={8}>
              <CapacityText>최소 연령</CapacityText>
              <TextInputDiv>
                <TextInput
                  type="tel"
                  value={data.min_age_copy}
                  placeholder="최소 연령을 입력해 주세요"
                  onChange={(e) =>
                    setData((prev) => ({ ...prev, min_age_copy: e.target.value }))
                  }
                />
              </TextInputDiv>
            </Styler>
            <Styler
              flex={true}
              align={"flex-start"}
              justify={"space-between"}
              pb={14}
              pt={24}
            >
              <SmallTitle>판매금액</SmallTitle>

              <RigntControlContainer>
                <RigntControlTitle>할인율</RigntControlTitle>

                <RigntControlInput
                  onChange={(e) => SetDiscount(e.target.value)}
                  value={data ? data.discount : "0"}
                  placeholder={`00`}
                  type="tel"
                  maxLength="3"
                />

                <RigntControlUnit>%</RigntControlUnit>

                <RigntControlButton
                  onClick={() => SetEvent(data.event)}
                  status={data ? data.event == 1 : false}
                >
                  {data
                    ? data.event == 1
                      ? `ON 적용 중`
                      : `OFF 적용 안됨`
                    : `OFF 적용 안됨`}
                </RigntControlButton>
              </RigntControlContainer>
            </Styler>

            <Styler align="center" pb={26}>

            <Styler
                      flex={true}
                      justify="space-between"
                      align="center"
                      pb={10}
                    >
                      <TimeText>{`인원`}</TimeText>

                      <Styler
                        width="100%"
                        style={{ marginRight: 8 }}
                        flex={true}
                        align="center"
                      >
                        <TextInputDiv>
                          <TextInput
                            style={{textAlign : "center"}}
                            type="tel"
                            value={'평일 가격'}
                            placeholder=""
                            disabled={true}
                          />
                        </TextInputDiv>
                      </Styler>

                      <Styler
                        width="100%"
                        style={{ marginRight: 8 }}
                        flex={true}
                        align="center"
                      >
                        <TextInputDiv>
                          <TextInput
                            style={{textAlign : "center"}}
                            type="tel"
                            value={'주말 가격'}
                            placeholder="인당 판매금액 작성하기(주말)"
                            disabled={true}
                          />
                        </TextInputDiv>
                      </Styler>

                      <Styler width="100%" flex={true} align="center">
                        <TextInputDiv>
                          <TextInputChange
                            style={{textAlign : "center"}}
                            type="tel"
                            disabled={true}
                            status={true}
                            value={'이벤트 가격'}
                            placeholder=""
                          />
                        </TextInputDiv>
                      </Styler>
                    </Styler>


              {data.price_copy?.map(
                (item, index) =>
                  (Number(item["people"]) >= Number(data.min_capacity_copy) &&
                  Number(item["people"]) <= Number(data.max_capacity_copy)) && (
                    <Styler
                      flex={true}
                      justify="space-between"
                      align="center"
                      pb={10}
                    >
                      <TimeText>{`${item.people}인`}</TimeText>

                      <Styler
                        width="100%"
                        style={{ marginRight: 8 }}
                        flex={true}
                        align="center"
                      >
                        <TextInputDiv>
                          <TextInput
                            type="tel"
                            value={localeStringMaker(item.price)}
                            placeholder="인당 판매금액 작성하기(평일)"
                            onChange={(e) =>
                              priceChange(e.target.value, index, "standard_copy")
                            }
                          />
                        </TextInputDiv>
                      </Styler>

                      <Styler
                        width="100%"
                        style={{ marginRight: 8 }}
                        flex={true}
                        align="center"
                      >
                        <TextInputDiv>
                          <TextInput
                            type="tel"
                            value={localeStringMaker(item.weekend_price)}
                            placeholder="인당 판매금액 작성하기(주말)"
                            onChange={(e) =>
                              priceChange(e.target.value, index, "weekend_copy")
                            }
                          />
                        </TextInputDiv>
                      </Styler>

                      <Styler width="100%" flex={true} align="center">
                        <TextInputDiv>
                          <TextInputChange
                            type="tel"
                            disabled={
                              data ? (data.event == 1 ? false : true) : false
                            }
                            status={data ? data.event == 1 : false}
                            value={localeStringMaker(item.event_price)}
                            placeholder="할인가"
                            onChange={(e) =>
                              priceChange(e.target.value, index, "event_copy")
                            }
                          />
                        </TextInputDiv>
                      </Styler>
                    </Styler>
                  )
              )}
            </Styler>
          </Styler>
          <div style={{backgroundColor : colors.Red03, height : 2, width : '100%', marginTop : 20}} />
        </>
      }
    </div>
  );
};

export default ActivityPriceInfo;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  padding-top: ${({ pt }) => `calc(1rem * ${pt} / 14)`};
  padding-bottom: ${({ pb }) => `calc(1rem * ${pb} / 14)`};
  padding-left: ${({ pl }) => `calc(1rem * ${pl} / 14)`};
  padding-right: ${({ pr }) => `calc(1rem * ${pr} / 14)`};
  height: ${({ height }) => height}px;
  width: ${({ width }) => width};
`;

const TitleBox = styled.div`
  border-top: 1px solid ${colors.Gr02};
`;

const Title = styled.div`
  font-weight: 600;

  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${({ red }) => (red ? colors.Red03 : colors.Gr05)};
`;

const Indicator = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr04};
`;

const Index = styled.span`
  color: ${({ red }) => (red ? colors.Red03 : colors.Gr05)};
`;

const SmallTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ status }) => (status == "empty" ? colors.Red03 : colors.Gr05)};
  line-height: 142%;
  font-weight: 500;
`;

const CapacityControlBox = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
`;

const RigntControlContainer = styled.div`
  max-width: 50%;
  display: inline-flex;
  align-items: center;
`;

const RigntControlTitle = styled.span`
  display: inline-block;
  color: ${colors.Gr04};
  font-size: 14px;
  margin-right: 12px;
`;

const RigntControlInput = styled.input`
  display: inline-block;
  color: ${colors.Gr04};
  padding: 8px 12px;
  border: 1px solid ${colors.Gr02};
  text-align: center;
  max-width: 60px;
`;

const RigntControlUnit = styled.span`
  padding: 0 12px 0 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  color: ${colors.Gr04};
  display: inline-block;
`;

const RigntControlButton = styled.span`
  padding: 6px 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: inline-block;
  cursor: pointer;
  border-radius: 4px;
  background-color: ${({ status }) => (status ? colors.Bl02 : colors.Gr01)};
  color: ${({ status }) => (status ? colors.Bl07 : colors.Gr03)};
`;

const CapacityText = styled.div`
  color: ${({ status }) => (status == "empty" ? colors.Red03 : colors.Gr04)};
  text-align: center;
  line-height: 142%;
  letter-spacing: -0.02em;
  font-weight: 500;
  margin-right: 14px;
  white-space: nowrap;
  height: 40.83px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DropDownContainer = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
`;

const DropDown = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.2px solid ${colors.Gr02};
  padding: calc(1rem * (9 / 14)) 0;
  /* width: ${W / 2 - (34 + 42)}px; */
`;

const CapacityNumber = styled.div`
  font-weight: 500;
  font-size: calc(1rem * 13 / 14);
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
`;

const SelectIcon = styled.img`
  width: calc(1rem * 17 / 14);
  height: calc(1rem * 17 / 14);
  margin-left: 4px;
  transform: ${({ open }) => (open ? "rotate(-180deg)" : "")};
  transition: all 0.75s;
`;

const DropMenu = styled.div`
  border: 1.2px solid ${colors.Gr02};
  width: 100%;
  /* width: ${W / 2 - (34 + 42)}px; */
  z-index: 3;
  position: absolute;
  background-color: ${colors.White};
  max-height: 200px;
  overflow: auto;
  margin-top: 8px;

  @keyframes dropdown {
    0% {
      transform: translateY(-16px);
    }
    100% {
      transform: translateY(0);
    }
  }

  animation: dropdown 0.5s ease;
`;

const MenuItem = styled.div`
  padding: 10px 0;
  border-bottom: 1.2px solid ${colors.Gr02};
  text-align: center;
  font-weight: 500;
  font-size: calc(1rem * 13 / 14);
  line-height: 142%;
  letter-spacing: -0.02em;

  ${({ el }) => el == 20 && `border-bottom: 0px`};
`;

const TextInputDiv = styled.div`
  border: 1px solid ${({ red }) => (red ? colors.Red03 : colors.Gr02)};
  /* width: fit-content; */
  width: 100%;
`;

const TextInput = styled.input`
  padding: 9px 14px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  width: 100%;

  &::placeholder {
    color: ${colors.Gr03};
  }
`;

const TextInputChange = styled.input`
  padding: 9px 14px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 142%;
  letter-spacing: -0.02em;
  width: 100%;
  background-color: ${({ status }) => (status ? colors.White : colors.Gr01)};
  color: ${({ status }) => (status ? colors.Gr06 : colors.Gr06)};

  &::placeholder {
    color: ${colors.Gr03};
  }
`;

const PriceUnit = styled.div`
  font-weight: 500;
  font-size: 1rem;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  margin-left: 12px;
`;

const TimeText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  padding: 10px 0;
  margin-right: 8px;
  background-color: ${colors.Gr01};
  white-space: nowrap;
  font-weight: 500;
  font-size: calc(1rem * 13 / 14);
  line-height: 142%;
  color: ${colors.Gr05};
  border: 1.2px solid ${colors.Gr02};
`;
