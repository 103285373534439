import { useState } from "react";
import styled from "styled-components";

// icon 
import DropDownIcon from "../../../assets/icon/dropDown.svg";

const DropDown = ({Handler, year, month, day, type}) => { 

  const YearList = ["2020", "2021", "2022", "2023", "2024", "2025"];
  const MonthList = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  const [DayList, setDayList] = useState([]);

  const [status, setStatus] = useState({
    year : false,
    month : false,
    day : false,
  });

  return (

    <DropDownContainer>

      {year ? 
        <DropDownContent active={status.year}>
          <span onClick={() => setStatus({...status, year : !status.year})} className="DropDownValue font_17 weight_500">
            <span>{`${year}년`}</span>
            <img src={DropDownIcon}/>
          </span>

          <div className="DropDownOptionContainer font_15 weight_400">
            {YearList.map((item, idx) => (
              <span onClick={() => {
                Handler("year", type, item)
                setStatus({...status, year : !status.year})
              }} className={item == year ? "color_blue weight_500" : ""} key={`year_${idx}`}>{`${item}년`}</span>
            ))}
          </div>
        </DropDownContent> 
      : ""}

      {month ? 
        <DropDownContent active={status.month}>
          <span onClick={() => setStatus({...status, month : !status.month})} className="DropDownValue font_17 weight_500">
            <span>{`${month}월`}</span>
            <img src={DropDownIcon}/>
          </span>

          <div className="DropDownOptionContainer font_15 weight_400">
            {MonthList.map((item, idx) => (
              <span onClick={() => {
                Handler("month", type, item)
                setStatus({...status, month : !status.month})
              }} className={item == month ? "color_blue weight_500" : ""} key={`month_${idx}`}>{`${item}월`}</span>
            ))}
          </div>
        </DropDownContent>
      : ""}

      {day ? 
        <DropDownContent active={status.day}>
          <span onClick={() => setStatus({...status, day : !status.day})} className="DropDownValue font_17 weight_500">
            <span>{`${day}일`}</span>
            <img src={DropDownIcon}/>
          </span>

          <div className="DropDownOptionContainer font_15 weight_400">
            {DayList.map((item, idx) => (
              <span onClick={() => {
                Handler("day", type, item)
                setStatus({...status, day : !status.day})
              }} className={item == day ? "color_blue weight_500" : ""} key={`day_${idx}`}>{`${item}일`}</span>
            ))}
          </div>
        </DropDownContent>
      : ""}

    </DropDownContainer>

  );

};


const DropDownContainer = styled.div`
  display : inline-flex;
  white-space : nowrap;
  max-width : 100%;
  z-index : 2;
`;

const DropDownContent = styled.div`
    display : inline-block;
    position : relative;
    margin-left : 10px;
    border : 1px solid rgba(132, 140, 148, .5);
    border-radius : 4px;
    width : ${({ width }) => width};
    z-index : 2;

  .DropDownValue {
    padding : 5px 7px;
    width : 100%;
    display : inline-flex;
    cursor : pointer;
    
    span {
      margin-right : 5px;
    }

    img {
      display : inline-block;
      width : 15px;
    }
  }

  .DropDownOptionContainer {
    display : ${({ active }) => active ? "inline-flex" : "none"};
    flex-wrap : wrap;
    padding : 5px 8px;
    width : calc(100% + 2.5px);
    position : absolute;
    bottom : -1px;
    right : -1.25px;
    transform : translate(0, 100%);
    background-color : #ffffff;
    border-radius : 0 0 4px 4px;
    border : solid rgba(132, 140, 148, .5) 1px;
    border-top : unset !important;
    max-height : 100px;
    overflow : hidden auto;

    span {
      width : 100%;
      display : inline-flex;
      justify-content : end;
      padding : 3px 0;
      border-bottom : solid rgba(132, 140, 148, .3) 1px;
      cursor : pointer;
    }
  }
`;

export default DropDown;