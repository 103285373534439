import MainHeader from "../../../components/header";
import ImageInput from "../../../components/imageInput";
import useImage from "../../../hooks/useImage";
import useInput from "../../../hooks/useInput";
import MainLayout from "../../../layouts/mainLayout";
import Input from "../../../modules/input";
import Select from "../../../modules/select";
import { Main, Box } from "./style";
import { useEffect, useState } from "react";
import axios from "axios";

const BannerRegisterPage = () => {
  const { value: bannerValue, inputChangeHandler: bannerInputChangeHandler } =
    useInput({
      name: "",
      link: "",
      order: "",
    });

  const { image, imageChangeHandler } = useImage({
    banner: {
      base: "",
      files: "",
    },
  });

  const [imgData, setImg] = useState("");

  const setImageFromFile = ({ file, setImageUrl  }) => {

      var uploadFileSize = file.size

      var maxFileSize = 1024 * 1024 * 5

      if(uploadFileSize > maxFileSize) {
        alert('업로드 가능한 이미지 최대 용량은 5MB입니다.\n이미지 용량을 확인해주세요')
    
      } else {
          let reader = new FileReader();
          reader.onload = function () {
          setImageUrl({ result: reader.result });
          };

          reader.readAsDataURL(file);
      }

  };

  const saveHandler = () => {
    if(window.confirm('배너를 등록하시겠습니까?')) {
        var token = window.localStorage.getItem('token')
  
        axios.post("https://ai.semos.kr/semos_admin/notice/data",  {
          token: token,
          mode : 'banner_upload',
          name : bannerValue.name,
          link : bannerValue.link,
          order : bannerValue.order,
          img : imgData
  
      }, {
          headers: { 'Content-Type': 'multipart/form-data' }
      }).then(data => {
          var Value = data.data;
          var status = Value.status
          var statusMessage = Value.status_message
  
          if(status == 'success') {
            //setPartner(Value.partner_data)
            alert('배너 등록이 완료되었습니다.')
            window.location.replace('/banner')
          } else if(status == 'waring') {
            alert(statusMessage)
          }
  
        });
      }
  };

  return (
    <MainLayout bottomButton={{ text: "등록하기", clickHandler: saveHandler }}>
      <MainHeader title="배너" />

      <Main>
        <section>
          <ImageInput
            width="570px"
            height="180px"
            name="banner"
            preview={imgData}
            changeHandler={setImg}
            imagePro={setImageFromFile}
          >
            <p>(768*248/5MB 이하/JPG)</p>
          </ImageInput>
        </section>

        <Box>
          <p>이름</p>
          <Input
            placeholder="이름을 입력해 주세요. (최대 30자)"
            type="text"
            name="name"
            changeHandler={bannerInputChangeHandler}
            value={bannerValue.name}
          />
        </Box>

        <Box>
          <p>링크</p>
          <Input
            placeholder="'https://semos.kr'을 제외하고 입력해 주세요."
            type="text"
            name="link"
            changeHandler={bannerInputChangeHandler}
            value={bannerValue.link}
          />
        </Box>

        <Box>
          <p>순서</p>
          <Select
            name="order"
            width="110px"
            changeHandler={bannerInputChangeHandler}
            optionList={[
              {
                text: 1,
                value: 1,
              },
              {
                text: 2,
                value: 2,
              },
              {
                text: 3,
                value: 3,
              },
            ]}
          />
        </Box>
      </Main>
    </MainLayout>
  );
};

export default BannerRegisterPage;
