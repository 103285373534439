import { useEffect } from "react";
import { useState } from "react";

export const getCurrentMonth = () => {
  const today = new Date();

  const year = today.getFullYear();

  const month = today.getMonth();

  return `${year}-${month + 1 < 10 ? `0${month + 1}` : month + 1}`;
};

export const getCurrentYear = () => {
  const today = new Date();

  const year = today.getFullYear();

  return year;
};

const useDate = (data) => {
  const today = new Date();

  const [filter, setFilter] = useState(data);

  const changeHandler = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (filter.filter) {
      if (
        (filter.filter === "w" && filter.date.length < 8) ||
        (filter.filter === "d" && filter.date.length < 8)
      ) {
        setFilter((prev) => ({
          ...prev,
          date: `${today.getFullYear()}-${
            today.getMonth() + 1 < 10
              ? `0${today.getMonth() + 1}`
              : today.getMonth() + 1
          }-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()}`,
        }));
      } else {
        if (filter.filter === "m" && filter.date.length > 8) {
          setFilter((prev) => ({
            ...prev,
            date: `${today.getFullYear()}-${
              today.getMonth() + 1 < 10
                ? `0${today.getMonth() + 1}`
                : today.getMonth() + 1
            }`,
          }));
        }
      }
    }
  }, [filter]);

  return { value: filter, changeHandler };
};

export default useDate;
